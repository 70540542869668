import React, { useEffect, useState, useRef } from 'react'
import { Modal, Space, Avatar, theme, Input, Empty, message, Typography, Dropdown, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClose,
  faPaperPlane,
  faUserMd,
  faUserNurse,
  faUserTie,
  faSearch,
  faFilter,
  faEllipsisVertical,
  faPen,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { handleError } from '../../lib/utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { createTeamChat, readTeamChat, updateTeamChat } from '../utils/utils'
import { useSelector } from 'react-redux'

dayjs.extend(relativeTime)

const { Title, Text } = Typography

const glowAnimation = {
  '@keyframes glow': {
    '0%': {
      boxShadow: '0 4px 12px rgba(24,144,255,0.15)'
    },
    '50%': {
      boxShadow: '0 4px 20px rgba(24,144,255,0.35)'
    },
    '100%': {
      boxShadow: '0 4px 12px rgba(24,144,255,0.15)'
    }
  }
}

const TeamChatForm = ({ modalProps, setModalProps }) => {
  const state = useSelector((state) => state)
  const [data, setData] = useState({ userRef: modalProps.userRef, data: [] })
  const [chatExists, setChatExists] = useState(false)
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('')
  const messagesEndRef = useRef(null)
  const { token } = theme.useToken()
  const [editingMessageId, setEditingMessageId] = useState(null)
  const [editText, setEditText] = useState('')

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [data])

  useEffect(() => {
    if (modalProps.isOpen && modalProps.userRef) {
      handleGetData()
    }
  }, [modalProps.isOpen, modalProps.userRef])

  const handleGetData = async () => {
    setLoading(true)
    try {
      const response = await readTeamChat(modalProps.userRef)
      if (response) {
        setData(response)
        setChatExists(true)
      } else {
        setData({ userRef: modalProps.userRef, data: [] })
        setChatExists(false)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const handleSendMessage = async () => {
    if (!text.trim()) return
    setLoading(true)
    try {
      const tmpData = JSON.parse(JSON.stringify(data))
      let response = null

      tmpData.data.push({
        createdBy: `${state.auth.agiliteUser.firstName} ${state.auth.agiliteUser.lastName}`,
        text: text.trim(),
        createdAt: new Date(),
        createdRef: state.auth.agiliteUser._id
      })

      setText('')
      setData(tmpData)

      response = chatExists ? await updateTeamChat(tmpData) : await createTeamChat(tmpData)

      if (response) {
        setData(response)
        setChatExists(true)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const handleEditMessage = async (messageIndex, newText) => {
    if (!newText.trim()) return
    setLoading(true)
    try {
      const tmpData = JSON.parse(JSON.stringify(data))
      tmpData.data[messageIndex].text = newText.trim()
      const response = await updateTeamChat(tmpData)
      setData(response)
      setEditingMessageId(null)
      setEditText('')
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const handleDeleteMessage = async (messageIndex) => {
    setLoading(true)
    try {
      const tmpData = JSON.parse(JSON.stringify(data))
      tmpData.data.splice(messageIndex, 1)
      const response = await updateTeamChat(tmpData)
      setData(response)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const getRoleIcon = (userName) => {
    if (userName.toLowerCase().includes('doctor')) return faUserMd
    if (userName.toLowerCase().includes('nurse')) return faUserNurse
    return faUserTie
  }

  const getRoleColor = (userName) => {
    if (userName.toLowerCase().includes('doctor')) return '#1890ff'
    if (userName.toLowerCase().includes('nurse')) return '#722ed1'
    if (userName.toLowerCase().includes('reception')) return '#13c2c2'
    return '#52c41a'
  }

  const getRoleBackgroundColor = (userName) => {
    if (userName.toLowerCase().includes('doctor')) return '#e6f7ff'
    if (userName.toLowerCase().includes('nurse')) return '#f9f0ff'
    if (userName.toLowerCase().includes('reception')) return '#e6fffb'
    return '#f6ffed'
  }

  const formatMessageDate = (date) => {
    const messageDate = dayjs(date)
    const now = dayjs()

    if (messageDate.isSame(now, 'day')) {
      return `Today at ${messageDate.format('h:mm A')}`
    }
    if (messageDate.isSame(now.subtract(1, 'day'), 'day')) {
      return `Yesterday at ${messageDate.format('h:mm A')}`
    }
    if (messageDate.isAfter(now.subtract(7, 'day'))) {
      return messageDate.fromNow()
    }
    return messageDate.format('MMM D, YYYY [at] h:mm A')
  }

  const isOwnMessage = (item) => {
    return (
      item.createdRef === state.auth.agiliteUser._id ||
      item.createdBy === `${state.auth.agiliteUser.firstName} ${state.auth.agiliteUser.lastName}`
    )
  }

  const commonButtonStyles = {
    background: 'transparent',
    border: 'none',
    padding: '8px',
    cursor: 'pointer',
    borderRadius: '4px',
    color: token.colorTextSecondary
  }

  return (
    <Modal
      maskClosable={true}
      closable={false}
      open={modalProps.isOpen}
      onCancel={() => {
        setModalProps({ ...modalProps, isOpen: false })
        setText('')
        setData({ userRef: '', data: [] })
      }}
      title={
        <div
          style={{
            padding: '20px 24px',
            borderBottom: `1px solid ${token.colorBorderSecondary}`,
            margin: '-20px -24px 0',
            background: 'linear-gradient(135deg, #334155 0%, #3B82F6 100%)',
            borderRadius: '8px 8px 0 0',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            boxShadow: '0 2px 8px rgba(0,0,0,0.03)'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Space direction='vertical' size={2}>
              <Title
                level={4}
                style={{
                  margin: 0,
                  fontSize: '20px',
                  color: '#ffffff',
                  textShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}
              >
                Team Chat
              </Title>
              <Text
                style={{
                  fontSize: '13px',
                  lineHeight: '1.5',
                  color: 'rgba(255,255,255,0.85)',
                  textShadow: '0 1px 2px rgba(0,0,0,0.1)'
                }}
              >
                Patient Notes & Communication
              </Text>
            </Space>
            <Space size={4}>
              <Tooltip title='Search Messages'>
                <button
                  style={{
                    ...commonButtonStyles,
                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#ffffff',
                    background: 'rgba(255,255,255,0.1)',
                    backdropFilter: 'blur(4px)',
                    transition: 'all 0.3s ease'
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(255,255,255,0.2)'
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(255,255,255,0.1)'
                  }}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </Tooltip>
              <Tooltip title='Filter Messages'>
                <button
                  style={{
                    ...commonButtonStyles,
                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#ffffff',
                    background: 'rgba(255,255,255,0.1)',
                    backdropFilter: 'blur(4px)',
                    transition: 'all 0.3s ease'
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(255,255,255,0.2)'
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = 'rgba(255,255,255,0.1)'
                  }}
                >
                  <FontAwesomeIcon icon={faFilter} />
                </button>
              </Tooltip>
              <button
                onClick={() => {
                  setModalProps({ ...modalProps, isOpen: false })
                  setText('')
                  setData({ userRef: '', data: [] })
                }}
                style={{
                  ...commonButtonStyles,
                  width: '32px',
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#ffffff',
                  background: 'rgba(255,255,255,0.1)',
                  backdropFilter: 'blur(4px)',
                  transition: 'all 0.3s ease'
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = 'rgba(255,70,70,0.2)'
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = 'rgba(255,255,255,0.1)'
                }}
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            </Space>
          </div>
        </div>
      }
      footer={null}
      width={700}
      bodyStyle={{
        padding: 0,
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        background: '#f5f5f5',
        borderRadius: '0 0 8px 8px'
      }}
      style={{
        top: 20,
        boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
      }}
      className='team-chat-modal'
    >
      <div
        style={{
          flex: 1,
          overflowY: 'auto',
          padding: '20px 24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          background: '#f5f5f5'
        }}
      >
        {loading ? (
          <AgiliteSkeleton spinnerTip='Loading messages...' />
        ) : (
          <>
            {data?.data?.length === 0 ? (
              <Empty
                description={
                  <Text type='secondary' style={{ fontSize: '14px' }}>
                    No messages yet. Start the conversation!
                  </Text>
                }
                style={{ margin: '40px 0' }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ) : (
              data.data.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    background: getRoleBackgroundColor(item.createdBy),
                    padding: '16px',
                    borderRadius: '12px',
                    borderLeft: `4px solid ${getRoleColor(item.createdBy)}`,
                    position: 'relative'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Space align='start' size={12}>
                      <Avatar
                        size={40}
                        icon={<FontAwesomeIcon icon={getRoleIcon(item.createdBy)} />}
                        style={{
                          backgroundColor: getRoleColor(item.createdBy)
                        }}
                      />
                      <div>
                        <Text
                          strong
                          style={{
                            fontSize: '15px',
                            color: getRoleColor(item.createdBy)
                          }}
                        >
                          {item.createdBy}
                        </Text>
                        <Text
                          type='secondary'
                          style={{
                            fontSize: '13px',
                            display: 'block'
                          }}
                        >
                          {formatMessageDate(item.createdAt)}
                        </Text>
                      </div>
                    </Space>

                    {isOwnMessage(item) && (
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: 'edit',
                              label: 'Edit message',
                              icon: <FontAwesomeIcon icon={faPen} />,
                              onClick: () => {
                                setEditingMessageId(index)
                                setEditText(item.text)
                              }
                            },
                            {
                              key: 'delete',
                              danger: true,
                              label: 'Delete message',
                              icon: <FontAwesomeIcon icon={faTrash} />,
                              onClick: () => handleDeleteMessage(index)
                            }
                          ]
                        }}
                        trigger={['click']}
                        placement='bottomRight'
                      >
                        <button
                          style={{
                            position: 'absolute',
                            top: '16px',
                            right: '16px',
                            background: 'rgba(0,0,0,0.05)',
                            border: 'none',
                            cursor: 'pointer',
                            padding: '6px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: '28px',
                            minHeight: '28px',
                            zIndex: 1
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEllipsisVertical}
                            style={{
                              color: token.colorTextSecondary,
                              fontSize: '16px'
                            }}
                          />
                        </button>
                      </Dropdown>
                    )}
                  </div>

                  <div style={{ marginLeft: '52px' }}>
                    {editingMessageId === index ? (
                      <div
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'flex-end'
                        }}
                      >
                        <Input.TextArea
                          value={editText}
                          onChange={(e) => setEditText(e.target.value)}
                          placeholder='Edit message...'
                          autoSize={{ minRows: 1, maxRows: 4 }}
                          style={{
                            flex: 1,
                            borderRadius: '8px',
                            resize: 'none'
                          }}
                          autoFocus
                        />
                        <Space>
                          <button
                            onClick={() => handleEditMessage(index, editText)}
                            style={{
                              padding: '4px 12px',
                              borderRadius: '6px',
                              border: 'none',
                              background: token.colorPrimary,
                              color: '#fff',
                              cursor: 'pointer'
                            }}
                          >
                            Save
                          </button>
                          <button
                            onClick={() => {
                              setEditingMessageId(null)
                              setEditText('')
                            }}
                            style={{
                              padding: '4px 12px',
                              borderRadius: '6px',
                              border: `1px solid ${token.colorBorder}`,
                              background: '#fff',
                              cursor: 'pointer'
                            }}
                          >
                            Cancel
                          </button>
                        </Space>
                      </div>
                    ) : (
                      <div
                        style={{
                          color: token.colorText,
                          fontSize: '14px',
                          lineHeight: '1.6',
                          background: '#ffffff',
                          padding: '12px 16px',
                          borderRadius: '8px',
                          border: `1px solid ${token.colorBorderSecondary}`
                        }}
                      >
                        {item.text}
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
            <div ref={messagesEndRef} />
          </>
        )}
      </div>

      <div
        style={{
          borderTop: `1px solid ${token.colorBorderSecondary}`,
          padding: '16px 24px',
          background: '#ffffff',
          marginTop: 'auto',
          boxShadow: '0 -2px 8px rgba(0,0,0,0.05)',
          borderRadius: '0 0 8px 8px'
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 50px',
            gap: '12px',
            alignItems: 'end',
            maxWidth: '100%',
            position: 'relative'
          }}
        >
          <Input.TextArea
            placeholder='Type your message...'
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                handleSendMessage()
              }
            }}
            autoSize={{ minRows: 1, maxRows: 4 }}
            style={{
              borderRadius: '20px',
              resize: 'none',
              padding: '12px 20px',
              fontSize: '15px',
              border: '2px solid #334155',
              background: '#ffffff',
              transition: 'all 0.3s ease',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08), 0 0 1px rgba(0, 0, 0, 0.1)'
            }}
            onFocus={(e) => {
              e.target.style.borderColor = '#3B82F6'
              e.target.style.boxShadow = '0 4px 12px rgba(59, 130, 246, 0.15), 0 0 0 2px rgba(59, 130, 246, 0.1)'
            }}
            onBlur={(e) => {
              e.target.style.borderColor = '#334155'
              e.target.style.boxShadow = '0 2px 8px rgba(0, 0, 0, 0.08), 0 0 1px rgba(0, 0, 0, 0.1)'
            }}
          />
          <button
            onClick={handleSendMessage}
            disabled={!text.trim()}
            style={{
              backgroundColor: text.trim() ? '#3B82F6' : '#f0f0f0',
              border: 'none',
              borderRadius: '50%',
              height: '46px',
              width: '46px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: text.trim() ? 'pointer' : 'not-allowed',
              transition: 'all 0.3s ease',
              padding: 0,
              transform: 'translateY(-2px)',
              boxShadow: text.trim() ? '0 4px 12px rgba(59,130,246,0.15)' : 'none',
              animation: text.trim() ? 'glow 2s infinite ease-in-out' : 'none'
            }}
            onMouseEnter={(e) => {
              if (text.trim()) {
                e.currentTarget.style.transform = 'translateY(-4px)'
                e.currentTarget.style.boxShadow = '0 6px 16px rgba(59,130,246,0.25)'
                e.currentTarget.style.backgroundColor = '#2563EB'
              }
            }}
            onMouseLeave={(e) => {
              if (text.trim()) {
                e.currentTarget.style.transform = 'translateY(-2px)'
                e.currentTarget.style.boxShadow = '0 4px 12px rgba(59,130,246,0.15)'
                e.currentTarget.style.backgroundColor = '#3B82F6'
              }
            }}
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              color={text.trim() ? 'white' : '#999'}
              style={{
                fontSize: '18px',
                transform: 'rotate(45deg) translateX(-2px)',
                transition: 'all 0.3s ease'
              }}
            />
          </button>
        </div>
        <Text
          type='secondary'
          style={{
            fontSize: '12px',
            marginTop: '8px',
            display: 'block',
            textAlign: 'center',
            color: '#6B7280',
            fontStyle: 'italic'
          }}
        >
          Press Enter to send, Shift + Enter for new line
        </Text>
      </div>
    </Modal>
  )
}

export default TeamChatForm
