// Common forms shown in the UI
export const COMMON_DOSAGE_FORMS = [
  { value: 'tablet', label: 'Tablet', codes: ['tab', 'tbi', 'srt', 'slt', 'ect', 'fct', 'fcr', 'oct', 'chu', 'dsp', 'eft', 'odt'] },
  { value: 'capsule', label: 'Capsule', codes: ['cap', 'cab', 'src', 'cpc'] },
  { value: 'syrup', label: 'Syrup', codes: ['syr'] },
  { value: 'suspension', label: 'Suspension', codes: ['sus'] },
  { value: 'injection', label: 'Injection', codes: ['inj', 'poi', 'amp', 'pfs', 'pfp', 'inf'] },
  { value: 'cream', label: 'Cream', codes: ['cre', 'cro'] },
  { value: 'inhaler', label: 'Inhaler', codes: ['inh', 'acc', 'cps', 'neb', 'udv'] },
  { value: 'drops_eye', label: 'Eye Drops', codes: ['opd', 'eed', 'een'] }
]

// Complete mapping of all presentation codes
export const DOSAGE_FORMS = [
  // Oral Solid Forms
  { value: 'tablet', label: 'Tablet', codes: ['tab', 'tbi', 'srt', 'slt', 'ect', 'fct', 'fcr', 'oct', 'chu', 'dsp', 'eft', 'odt'] },
  { value: 'capsule', label: 'Capsule', codes: ['cap', 'cab', 'src', 'cpc'] },
  { value: 'lozenge', label: 'Lozenge', codes: ['loz'] },
  { value: 'wafer', label: 'Wafer', codes: ['waf'] },
  { value: 'chewing_gum', label: 'Chewing Gum', codes: ['chg'] },
  { value: 'granules', label: 'Granules', codes: ['gra'] },
  { value: 'powder_oral', label: 'Powder (Oral)', codes: ['pow'] },
  { value: 'sachet', label: 'Sachets', codes: ['sac'] },

  // Liquid Forms
  { value: 'syrup', label: 'Syrup', codes: ['syr'] },
  { value: 'suspension', label: 'Suspension', codes: ['sus'] },
  { value: 'solution', label: 'Solution', codes: ['sol', 'sln'] },
  { value: 'drops_oral', label: 'Drops (Oral)', codes: ['drp'] },
  { value: 'liquid', label: 'Liquid', codes: ['liq', 'lit'] },

  // Injectables
  { value: 'injection', label: 'Injection', codes: ['inj', 'poi', 'amp', 'pfs', 'pfp', 'inf'] },
  { value: 'implant', label: 'Implant', codes: ['imp'] },

  // Inhalation Forms
  { value: 'inhaler', label: 'Inhaler', codes: ['inh', 'acc', 'cps', 'neb', 'udv'] },
  { value: 'gas', label: 'Gas', codes: ['gas'] },

  // Topical Forms
  { value: 'cream', label: 'Cream', codes: ['cre', 'cro'] },
  { value: 'ointment', label: 'Ointment', codes: ['oin', 'oio'] },
  { value: 'gel', label: 'Gel', codes: ['gel', 'geo', 'jel'] },
  { value: 'lotion', label: 'Lotion', codes: ['lot', 'scl'] },
  { value: 'patch', label: 'Patch', codes: ['pat', 'ptd'] },
  { value: 'paint', label: 'Paint', codes: ['pai'] },
  { value: 'powder_external', label: 'Powder (External)', codes: ['pdr', 'por'] },
  { value: 'spray_external', label: 'Spray (External)', codes: ['spo', 'spr'] },
  { value: 'shampoo', label: 'Shampoo', codes: ['sha'] },
  { value: 'soap', label: 'Soap', codes: ['soa'] },
  { value: 'pad', label: 'Pad', codes: ['psd', 'pad'] },
  { value: 'plaster', label: 'Plaster', codes: ['pls'] },

  // Eye/Ear/Nose Forms
  { value: 'drops_eye', label: 'Eye Drops', codes: ['opd', 'eed', 'een'] },
  { value: 'drops_ear', label: 'Ear Drops', codes: ['ear', 'eed', 'een'] },
  { value: 'drops_external', label: 'Drops (External)', codes: ['dro'] },
  { value: 'eye_ointment', label: 'Eye Ointment', codes: ['eyo'] },
  { value: 'eye_gel', label: 'Eye Gel', codes: ['opg'] },
  { value: 'nasal_spray', label: 'Nasal Spray', codes: ['nas', 'nss', 'aqs'] },
  { value: 'nasal_gel', label: 'Nasal Gel', codes: ['nag'] },
  { value: 'nose_drops', label: 'Nose Drops', codes: ['nod', 'een'] },

  // Vaginal Forms
  { value: 'vaginal_tablet', label: 'Vaginal Tablet', codes: ['vat'] },
  { value: 'vaginal_capsule', label: 'Vaginal Capsule', codes: ['pvc'] },
  { value: 'vaginal_cream', label: 'Vaginal Cream', codes: ['vcr'] },
  { value: 'vaginal_gel', label: 'Vaginal Gel', codes: ['vag'] },
  { value: 'vaginal_ring', label: 'Vaginal Ring', codes: ['vri'] },
  { value: 'vaginal_combo', label: 'Vaginal Combination Pack', codes: ['vcp'] },

  // Other Forms
  { value: 'suppository', label: 'Suppository', codes: ['sup'] },
  { value: 'enema', label: 'Enema', codes: ['ene', 'ens'] },
  { value: 'douche', label: 'Douche', codes: ['dou'] },
  { value: 'mouthwash', label: 'Mouthwash', codes: ['mow'] },
  { value: 'paste', label: 'Paste', codes: ['pas'] },
  { value: 'vaccine', label: 'Vaccine', codes: ['vac'] },
  { value: 'dialysate', label: 'Dialysate', codes: ['dia'] },
  { value: 'cartridge', label: 'Cartridge', codes: ['cat'] },
  { value: 'iud', label: 'Intra-uterine Device', codes: ['iud'] },
  { value: 'other', label: 'Other', codes: ['oth'] }
]
