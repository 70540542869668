// src/BluetoothContext.js
import React from 'react'

const BluetoothContext = React.createContext({
  bleDevice: null,
  bleCommandCharacteristic: null,
  bleNotifyCharacteristic: null,
  requestDevice: () => {},
  connectDevice: () => {},
  disconnectDevice: () => {},
  connectionStatus: 'disconnected',
  connectionAttempts: 0,
  lastError: null,
  engine: null
})

export default BluetoothContext
