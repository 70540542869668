import { faPlus, faRefresh, faSearch, faFileAlt, faFileMedical, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, DatePicker, Input, Space, theme, Divider, Tooltip } from 'antd'
import React, { useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import { MODAL_STATES } from '../utils/constants'

const { RangePicker } = DatePicker

const SearchBox = memo(({ token, value, onChange }) => (
  <div style={{ display: 'grid', gridTemplateColumns: '1fr 35px', width: 450, maxWidth: '100%' }}>
    <Input
      placeholder='Search documents...'
      style={{ borderRadius: 0, boxSizing: 'border-box' }}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      allowClear
    />
    <div
      style={{
        background: token.colorPrimary,
        color: 'white',
        height: 35,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <FontAwesomeIcon icon={faSearch} />
    </div>
  </div>
))

const DocumentActions = ({
  handleReadDocuments,
  loadingDocuments,
  searchQuery,
  setSearchQuery,
  dateRange,
  setDateRange,
  setSelectionRequired,
  viewOldSickNotes,
  viewOldReferralLetters,
  handleSelection
}) => {
  const { token } = theme.useToken()

  const handleRefresh = useCallback(() => {
    handleReadDocuments()
  }, [handleReadDocuments])

  const handleDateRangeChange = useCallback(
    (dates) => {
      setDateRange(dates || [])
    },
    [setDateRange]
  )

  // Refined button styles
  const buttonStyles = {
    actionButton: {
      marginRight: 12,
      borderRadius: 6,
      height: 42,
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      fontWeight: 500,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease',
      padding: '0 16px'
    },
    sickNote: {
      background: '#1890ff', // Ant Design primary blue
      borderColor: '#1890ff',
      color: 'white',
      '&:hover': {
        background: '#40a9ff',
        borderColor: '#40a9ff'
      }
    },
    referral: {
      background: '#722ed1', // Ant Design purple
      borderColor: '#722ed1',
      color: 'white',
      '&:hover': {
        background: '#9254de',
        borderColor: '#9254de'
      }
    },
    legacy: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      border: '1px solid #d9d9d9',
      color: 'rgba(0, 0, 0, 0.65)',
      background: '#f5f5f5',
      borderRadius: 6,
      padding: '4px 12px',
      transition: 'all 0.3s ease'
    },
    refresh: {
      background: token.colorSuccess,
      borderColor: token.colorSuccess,
      borderRadius: 6,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
    },
    iconStyle: {
      fontSize: 16
    }
  }

  return (
    <div>
      {/* Create Document header */}
      <Divider orientation='left' style={{ margin: '12px 0' }}>
        <small style={{ color: token.colorTextSecondary, fontWeight: 'normal' }}>
          Create Document
          <Tooltip title='Create new documents or manage existing ones'>
            <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 8, fontSize: 14, color: '#8c8c8c' }} />
          </Tooltip>
        </small>
      </Divider>

      {/* Top row with document creation buttons */}
      <div style={{ marginBottom: 32 }}>
        <Space size='middle'>
          <Tooltip title='Create a new sick note'>
            <Button
              type='primary'
              onClick={() => handleSelection(MODAL_STATES.SICK_NOTE)}
              style={{ ...buttonStyles.actionButton, ...buttonStyles.sickNote }}
              disabled={loadingDocuments}
            >
              <FontAwesomeIcon icon={faFileMedical} style={buttonStyles.iconStyle} />
              <span>New Sick Note</span>
            </Button>
          </Tooltip>

          <Tooltip title='Create a new referral letter'>
            <Button
              type='primary'
              onClick={() => handleSelection(MODAL_STATES.REFERRAL)}
              style={{ ...buttonStyles.actionButton, ...buttonStyles.referral }}
              disabled={loadingDocuments}
            >
              <FontAwesomeIcon icon={faFileAlt} style={buttonStyles.iconStyle} />
              <span>New Referral Letter</span>
            </Button>
          </Tooltip>
        </Space>
      </div>

      {/* Document Management header */}
      <Divider orientation='left' style={{ margin: '16px 0 16px 0' }}>
        <small style={{ color: token.colorTextSecondary, fontWeight: 'normal' }}>
          Document Management
          <Tooltip title='Search, filter, and manage your documents'>
            <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 8, fontSize: 14, color: '#8c8c8c' }} />
          </Tooltip>
        </small>
      </Divider>

      {/* Search and filter options */}
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 16, marginBottom: 16 }}>
        <Space size='middle' wrap>
          <SearchBox token={token} value={searchQuery} onChange={setSearchQuery} />

          <RangePicker
            value={dateRange}
            onChange={handleDateRangeChange}
            disabled={loadingDocuments}
            style={{ borderRadius: 4 }}
          />

          <Space>
            <Tooltip title='Access sick notes from previous versions'>
              <Button onClick={viewOldSickNotes} style={{ ...buttonStyles.legacy }}>
                <FontAwesomeIcon icon={faFileMedical} style={{ color: '#1890ff', fontSize: 14 }} />
                <span>Legacy Sick Notes</span>
              </Button>
            </Tooltip>

            <Tooltip title='Access referral letters from previous versions'>
              <Button onClick={viewOldReferralLetters} style={{ ...buttonStyles.legacy }}>
                <FontAwesomeIcon icon={faFileAlt} style={{ color: '#722ed1', fontSize: 14 }} />
                <span>Legacy Referrals</span>
              </Button>
            </Tooltip>
          </Space>

          <Tooltip title='Refresh documents'>
            <Button
              disabled={loadingDocuments}
              type='primary'
              style={{ ...buttonStyles.refresh }}
              onClick={handleRefresh}
              icon={<FontAwesomeIcon icon={faRefresh} />}
            />
          </Tooltip>
        </Space>
      </div>
    </div>
  )
}

DocumentActions.propTypes = {
  handleReadDocuments: PropTypes.func.isRequired,
  loadingDocuments: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  dateRange: PropTypes.array.isRequired,
  setDateRange: PropTypes.func.isRequired,
  setSelectionRequired: PropTypes.func.isRequired,
  viewOldSickNotes: PropTypes.func,
  viewOldReferralLetters: PropTypes.func,
  handleSelection: PropTypes.func.isRequired
}

SearchBox.propTypes = {
  token: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

SearchBox.displayName = 'SearchBox'

export default memo(DocumentActions)
