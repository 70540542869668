import { Form, Card } from 'antd'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CustomRow from '../../../../../reusable-components/CustomRow'
import { CATEGORIES, CATEGORY_COLORS } from '../utils/enums'
import { categoryIcons } from '../utils/constants'
import FormSection from './FormSection'

const FormSections = ({
  selectedDataPoints = [],
  isAddingTemplate,
  activeTab,
  onSubmit,
  form,
  filterCategories,
  categoryOrder
}) => {
  // Memoize category items to prevent unnecessary recalculations
  const getCategoryItems = useMemo(() => {
    return (category) => {
      if (!Array.isArray(selectedDataPoints)) return []

      return selectedDataPoints
        .map((jsonData) => {
          try {
            return JSON.parse(jsonData)
          } catch (e) {
            console.error('Failed to parse JSON data:', e, jsonData)
            return null
          }
        })
        .filter((selection) => selection && selection.category === category)
    }
  }, [selectedDataPoints])

  // Memoize filtered categories
  const filteredCategories = useMemo(() => {
    let categories = Object.values(CATEGORIES).filter((category) => category !== CATEGORIES.TEMPLATES)

    // Apply filter if provided
    if (filterCategories) {
      categories = categories.filter((category) => filterCategories.includes(category))
    }

    // Apply custom order if provided
    if (categoryOrder && categoryOrder.length) {
      categories.sort((a, b) => {
        const indexA = categoryOrder.indexOf(a)
        const indexB = categoryOrder.indexOf(b)

        // If both categories are in the order array, sort by their position
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB
        }

        // If only one category is in the order array, prioritize it
        if (indexA !== -1) return -1
        if (indexB !== -1) return 1

        // If neither category is in the order array, maintain original order
        return 0
      })
    }

    return categories
  }, [filterCategories, categoryOrder])

  return (
    <Card size='small' styles={{ body: { minHeight: 300, maxHeight: '65vh', overflow: 'auto' } }}>
      <Form form={form} layout='vertical'>
        <CustomRow wrap gutter={[8, 8]}>
          {filteredCategories.map((category) => (
            <FormSection
              key={category}
              category={category}
              headerColor={CATEGORY_COLORS[category]}
              icon={categoryIcons[category]}
              title={category.charAt(0).toUpperCase() + category.slice(1)}
              selectedItems={getCategoryItems(category)}
              activeTab={activeTab}
              isAddingTemplate={isAddingTemplate}
              form={form}
            />
          ))}
        </CustomRow>
      </Form>
    </Card>
  )
}

FormSections.propTypes = {
  selectedDataPoints: PropTypes.arrayOf(PropTypes.string),
  isAddingTemplate: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  filterCategories: PropTypes.arrayOf(PropTypes.string),
  categoryOrder: PropTypes.arrayOf(PropTypes.string)
}

export default React.memo(FormSections)
