import DayJS from 'dayjs'
import Store from '../../../../store'

const templates = {
  dataTemplate: () => {
    return {
      height: null,
      weight: null,
      bmi: null,
      oxygenSaturation: null,
      temperature: null,
      pulse: null,
      bloodGlucose: null,
      cholesterol: null,
      bloodPressure: {
        systolic: null,
        diastolic: null
      },
      types: [],
      createdBy: Store.getState().auth.agiliteUser.firstName + ' ' + Store.getState().auth.agiliteUser.lastName,
      date: new Date().toISOString(),
      userRef: Store.getState().auth.agiliteUser._id
    }
  },
  columnTemplate: () => {
    return [
      {
        title: 'Types',
        dataIndex: 'type',
        key: 'type',
        render: (text, record) => {
          return record.types.join(', ')
        }
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, record) => {
          return DayJS(record.date).format('YYYY-MM-DD HH:mm:ss')
        }
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        key: 'createdBy'
      }
    ]
  }
}

export default templates
