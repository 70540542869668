import React from 'react'
import { Button, Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faCalendarPlus,
  faChartLine,
  faHistory,
  faLightbulb 
} from '@fortawesome/free-solid-svg-icons'
import '../../../styling/QuickActions.css'

const { Text } = Typography

const QuickActions = ({ onActionClick }) => {
  const quickActions = [
    {
      id: 'book',
      title: 'Book Test',
      icon: faCalendarPlus,
      color: '#65D2D8'
    },
    {
      id: 'results',
      title: 'Results',
      icon: faChartLine,
      color: '#B5D777'
    },
    {
      id: 'history',
      title: 'History',
      icon: faHistory,
      color: '#FDB462'
    },
    {
      id: 'insights',
      title: 'Insights',
      icon: faLightbulb,
      color: '#EF8D9C'
    }
  ]

  return (
    <div className='cs-overview__action-strip'>
      {quickActions.map(action => (
        <Button
          key={action.id}
          onClick={() => onActionClick(action.id)}
          className='cs-overview__action-button'
        >
          <div 
            className='cs-overview__action-icon' 
            style={{ backgroundColor: `${action.color}20` }}
          >
            <FontAwesomeIcon 
              icon={action.icon} 
              style={{ color: action.color }} 
            />
          </div>
          <Text className='cs-overview__action-label'>
            {action.title}
          </Text>
        </Button>
      ))}
    </div>
  )
}

export default QuickActions 