import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Col, Empty, Table, message, theme } from 'antd'
import { activityHistoryTemplates, handleGetQuantity } from './utils/templates'
import { handleError, hexToRGBA } from '../../lib/utils'
import { uniqBy } from 'lodash'
import { readSystemUsers } from '../system-users/utils/utils'

const LocationActivity = ({ item, location }) => {
  const [locationData, setLocationData] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    handleSetLocationData()
    //eslint-disable-next-line
  }, [item, location])

  const handleSetLocationData = async () => {
    let activity = [...item.activity]
    let userIds = []
    let users = []

    setLoading(true)
    try {
      if (activity.length > 0) {
        userIds = uniqBy(activity.map((act) => act.createdBy))
        users = await readSystemUsers({ _id: { $in: userIds } }, null, null, 'firstName lastName')
        activity = activity.map((act) => {
          return {
            ...act,
            user: users.find((user) => user._id === act.createdBy)
          }
        })
        setLocationData(activity)
      }
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }

  const { token } = theme.useToken()

  return (
    <CustomRow>
      <Col span={24}>
        <Table
          loading={loading}
          dataSource={locationData}
          locale={{
            emptyText: <Empty description='No Activity Found' />
          }}
          summary={() => {
            let total = handleGetQuantity(location.activity)

            return (
              <>
                {loading ? (
                  <></>
                ) : (
                  <Table.Summary.Row style={{ background: hexToRGBA(token.colorPrimary, 0.0925) }}>
                    <Table.Summary.Cell>
                      <b>Stock Total: </b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <b style={{ color: total > 0 ? 'green' : total < 0 ? 'red' : '' }}>{total}</b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
              </>
            )
          }}
          columns={activityHistoryTemplates.columnTemplate()}
          scroll={{ x: 1000 }}
        />
      </Col>
    </CustomRow>
  )
}

export default LocationActivity
