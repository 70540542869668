const EntityEnums = {
  keys: {},
  titles: {},
  profileKeys: {
    ENTITIES: 'entities',
    PAYMENT_METHODS: 'payment_methods',
    PROCEDURES: 'procedures_performed'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update'
  },
  keywords: {
    VIRTUAL_SERVICES: 'virtual_consultations'
  }
}

export default EntityEnums
