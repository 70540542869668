import { useState, useEffect } from 'react'
import { calculateEstQuantity } from '../utils/calculators'
import { getQuantitySuffix } from '../utils/formatters'

export const useEstimatedQuantity = (frequency, duration, presentationCode, dose, dispensingVolumePacksize) => {
  const [estimatedQuantity, setEstimatedQuantity] = useState(null)
  const [quantitySuffix, setQuantitySuffix] = useState('')
  const [showEstQuantity, setShowEstQuantity] = useState(false)

  useEffect(() => {
    if (frequency && duration) {
      const quantity = calculateEstQuantity(frequency, duration, dose, dispensingVolumePacksize)
      setEstimatedQuantity(quantity)
      setQuantitySuffix(getQuantitySuffix(presentationCode, quantity))
      setShowEstQuantity(true)
    } else {
      setEstimatedQuantity(null)
      setQuantitySuffix('')
      setShowEstQuantity(false)
    }
  }, [frequency, duration, presentationCode, dose, dispensingVolumePacksize])

  return {
    estimatedQuantity,
    quantitySuffix,
    showEstQuantity,
    setShowEstQuantity
  }
}
