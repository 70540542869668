import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Button, Collapse, Avatar, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faClock, faUserDoctor } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import '../styling/MobileBookingResults.css'
import { useSelector } from 'react-redux'
import { convertDateTimeSAST } from '../../../../lib/utils'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'

const { Panel } = Collapse

const MobBookingResults = ({ bookingDetails, setBookingDetails, dataResults, patient, onNext, onBack }) => {
  const state = useSelector((state) => state)

  const [selectedClinic, setSelectedClinic] = useState(null)
  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [finalResultsArray, setFinalResultsArray] = useState([])

  const serviceInfo =
    state.services.data.find((item) => item._id === bookingDetails.service) ||
    state.virtualServices.data.find((item) => item._id === bookingDetails.service)

  useEffect(() => {
    const tmpResults = []

    dataResults.forEach((clinic) => {
      if (clinic.availabilityEntries.length > 0) {
        tmpResults.push(transformAvailabilityData(clinic))
      }
    })

    setFinalResultsArray(tmpResults)
    // eslint-disable-next-line
  }, [dataResults])

  const transformAvailabilityData = (data) => {
    return {
      clinicRef: data.clinicRef,
      clinicName: data.clinicName,
      availabilityEntries: data.availabilityEntries.map((dayEntry) => ({
        date: dayjs(dayEntry.date).format('YYYY-MM-DD'),
        entries: dayEntry.entries.map((entry) => ({
          _id: entry._id,
          firstName: entry.firstName,
          lastName: entry.lastName,
          profession: entry.profession,
          profileImage: entry.profileImage || null,
          availability: entry.availability,
          phoneNumber: entry.phoneNumber
        }))
      }))
    }
  }

  const handleTimeSelect = (clinic, doctor, time) => {
    setSelectedClinic(clinic)
    setSelectedDoctor(doctor)
    setSelectedTime(time)
  }

  const handleMakeBooking = () => {
    const tmpBookingDetails = JSON.parse(JSON.stringify(bookingDetails))
    const serviceInfo =
      state.services.data.find((item) => item._id === bookingDetails.service) ||
      state.virtualServices.data.find((item) => item._id === bookingDetails.service)

    setBookingDetails({
      ...tmpBookingDetails,
      doctor: selectedDoctor,
      medicalProfRef: selectedDoctor._id,
      userRef: patient._id,
      bookingDate: convertDateTimeSAST(new Date(tmpBookingDetails.bookingDate), true),
      dateCreated: convertDateTimeSAST(new Date()),
      startTime: selectedTime,
      endTime: dayjs(selectedTime, 'HH:mm').add(serviceInfo.timeslotInterval, 'minutes').format('HH:mm'),
      clinicRef: selectedClinic.clinicRef,
      clinicTermsRef: selectedClinic.clinicRef
      // paymentMethods: paymentMethods, // TODO: add payment methods
    })

    onNext()
  }

  const groupTimeSlots = (slots) => {
    return {
      morning: slots.filter((time) => time < '12:00'),
      afternoon: slots.filter((time) => time >= '12:00' && time < '17:00'),
      evening: slots.filter((time) => time >= '17:00')
    }
  }

  const { token } = theme.useToken()

  return (
    <motion.div
      className='mobile-booking-results'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {finalResultsArray.length > 0 && (
        <div className='results-header'>
          <h2>Available Doctors</h2>
        </div>
      )}
      <div className='doctors-list'>
        {finalResultsArray.length === 0 ? (
          <div
            style={{
              textAlign: 'center',
              padding: '2rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1rem'
            }}
          >
            <motion.div
              animate={{
                scale: [1, 1.2, 1],
                opacity: [1, 0.8, 1]
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: 'easeInOut'
              }}
              style={{ fontSize: '3rem', color: 'rgba(16, 178, 225, 0.9)' }}
            >
              <FontAwesomeIcon icon={faUserDoctor} />
            </motion.div>
            <h3>No Doctors Available</h3>
            <p>We couldn't find any doctors available for {serviceInfo?.name || 'the selected service'}</p>
            <p>on {dayjs(bookingDetails.bookingDate).format('MMM D, YYYY')}</p>
          </div>
        ) : (
          finalResultsArray.map((clinic) => (
            <>
              {clinic.availabilityEntries.map((entry) => {
                return (
                  <>
                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>{dayjs(entry.date).format('MMM D, YYYY')}</p>

                    {entry.entries.map((doctor) => {
                      return (
                        <div key={doctor._id} className='doctor-card'>
                          <div className='doctor-info'>
                            <Avatar
                              size={64}
                              icon={<FontAwesomeIcon style={{ color: token.colorPrimary }} icon={faUserDoctor} />}
                              className='doctor-avatar'
                            />
                            <div className='doctor-details'>
                              <h3>
                                Dr. {doctor.firstName} {doctor.lastName}
                              </h3>
                              <p>{doctor.availability.length} slots available</p>
                            </div>
                          </div>

                          <Collapse ghost>
                            <Panel header='View Available Times' key='1' className='time-slots-panel'>
                              {Object.entries(groupTimeSlots(doctor.availability)).map(
                                ([period, slots]) =>
                                  slots.length > 0 && (
                                    <div key={period}>
                                      <h4 style={{ marginBottom: '12px' }}>
                                        {period.charAt(0).toUpperCase() + period.slice(1)}
                                      </h4>
                                      <div className='time-slots'>
                                        {slots.map((time) => (
                                          <Button
                                            key={`${doctor._id}-${time}`}
                                            onClick={() => handleTimeSelect(clinic, doctor, time)}
                                            style={{
                                              backgroundColor:
                                                selectedDoctor?._id === doctor._id && selectedTime === time
                                                  ? token.colorPrimary
                                                  : '#f5f5f5',
                                              color:
                                                selectedDoctor?._id === doctor._id && selectedTime === time
                                                  ? 'white'
                                                  : 'black',
                                              borderColor:
                                                selectedDoctor?._id === doctor._id && selectedTime === time
                                                  ? token.colorPrimary
                                                  : 'black'
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faClock}
                                              className='time-icon'
                                              style={{
                                                color:
                                                  selectedDoctor?._id === doctor._id && selectedTime === time
                                                    ? 'white'
                                                    : 'inherit'
                                              }}
                                            />
                                            {time}
                                          </Button>
                                        ))}
                                      </div>
                                    </div>
                                  )
                              )}
                            </Panel>
                          </Collapse>
                        </div>
                      )
                    })}
                  </>
                )
              })}
            </>
          ))
        )}
      </div>
      <PrevNextFloatingButtons
        nextText='Pay & Book'
        prevText='Back'
        onPrev={onBack}
        onNext={handleMakeBooking}
        onNextDisabled={!selectedDoctor || !selectedTime}
      />
    </motion.div>
  )
}

export default MobBookingResults
