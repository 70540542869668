export const dashboardData = {
  user: {
    id: 'user-001',
    name: 'John Doe',
    email: 'john@example.com'
  },
  upcomingTests: {
    due: [
      {
        id: 'test-001',
        testName: 'Complete Blood Count',
        dueDate: '2024-04-01',
        priority: 'high',
        reason: 'Annual checkup'
      }
    ],
    recommended: [
      {
        id: 'test-002',
        testName: 'Lipid Panel',
        dueDate: '2024-04-15',
        priority: 'medium',
        reason: 'Follow-up'
      }
    ]
  },
  recentResults: [
    {
      id: 'result-001',
      testName: 'Complete Blood Count',
      date: '2024-03-01',
      status: 'normal',
      reportUrl: '#',
      metrics: {
        current: { value: 5.7, unit: 'mmol/L' },
        previous: { value: 5.9, unit: 'mmol/L' },
        change: { value: -3.4, trend: 'improving' },
        referenceRange: { min: 4.0, max: 6.0, unit: 'mmol/L' }
      },
      components: [
        { name: 'Red Blood Cells', value: 4.8, min: 4.0, max: 5.5, unit: 'M/µL' },
        { name: 'White Blood Cells', value: 7.2, min: 4.5, max: 11.0, unit: 'K/µL' },
        { name: 'Platelets', value: 250, min: 150, max: 450, unit: 'K/µL' },
        { name: 'Hemoglobin', value: 14.2, min: 13.5, max: 17.5, unit: 'g/dL' },
        { name: 'Hematocrit', value: 42, min: 38.8, max: 50.0, unit: '%' }
      ],
      history: [
        { date: '2023-10', value: 5.2 },
        { date: '2023-11', value: 5.8 },
        { date: '2023-12', value: 5.5 },
        { date: '2024-01', value: 6.2 },
        { date: '2024-02', value: 5.9 },
        { date: '2024-03', value: 5.7 }
      ]
    },
    {
      id: 'result-002',
      testName: 'Lipid Panel',
      date: '2024-02-15',
      status: 'attention',
      reportUrl: '#',
      metrics: {
        current: { value: 6.2, unit: 'mmol/L' },
        previous: { value: 5.8, unit: 'mmol/L' },
        change: { value: 6.9, trend: 'worsening' },
        referenceRange: { min: 3.9, max: 5.5, unit: 'mmol/L' }
      },
      history: [
        { date: '2023-09', value: 5.5 },
        { date: '2023-10', value: 5.6 },
        { date: '2023-11', value: 5.8 },
        { date: '2023-12', value: 5.9 },
        { date: '2024-01', value: 6.0 },
        { date: '2024-02', value: 6.2 }
      ]
    }
  ],
  insights: {
    healthScore: 85,
    completionRate: 75,
    trends: {
      improving: ['Cholesterol', 'Blood Sugar'],
      attention: ['Iron']
    }
  }
} 