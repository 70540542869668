import { useRef } from 'react'

const useMobileCameraCapture = ({ onAfterCapture }) => {
  const fileInputRef = useRef(null)

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        const base64Data = reader.result
        if (onAfterCapture) {
          onAfterCapture(base64Data, file)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
        }
      }
      reader.onerror = (error) => {
        console.error('Error reading file:', error)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleCameraClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  return { handleFileChange, fileInputRef, handleCameraClick }
}

export default useMobileCameraCapture
