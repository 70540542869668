import React, { useEffect, useState } from 'react'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import { Col } from 'antd'

import BookingsSearch from './AvailabilitySearchMain'
import HomeVisits from './Search Engine Components/HomeVisitIntro'
import VirtualVisits from './Search Engine Components/VirtualVisitIntro'
import BookingFinalize from './Search Engine Components/FinalizeAppointment'
import BookingConfirmation from './Search Engine Components/AppointmentConfirmationView'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { deviceDetect } from 'react-device-detect'
import CustomRow from '../../reusable-components/CustomRow'
import { useSelector } from 'react-redux'

const NewBooking = ({
  isHomeVisit,
  isReceptionBooking,
  isVirtualVisit,
  targetPatient,
  targetBooking,
  onAfterSuccess,
  onAfterFailed
}) => {
  const state = useSelector((state) => state)
  const [bookingDetails, setBookingDetails] = useState({
    dateCreated: null,
    service: null,
    medicalProfRef: null,
    userRef: null,
    endTime: null,
    province: isReceptionBooking
      ? state.clinics.data.find((clinic) => clinic._id === state.auth.agiliteUser.extraData.clinics[0]).province
      : null,
    bookingDate: null
  })
  const [currentStep, setCurrentStep] = useState(0)
  const [steps, setSteps] = useState([])
  const [homeVisitState, setHomeVisitState] = useState(isHomeVisit || false)
  const [virtualVisitState, setVirtualVisitState] = useState(isVirtualVisit || false)
  const [patient, setPatient] = useState(targetPatient ? targetPatient : null)
  const [isNewPatient, setIsNewPatient] = useState(false)
  const [previousSearch, setPreviousSearch] = useState(null)
  const [mobileSteps, setMobileSteps] = useState(1)

  useEffect(() => {
    generateSteps()
    // eslint-disable-next-line
  }, [isNewPatient, currentStep, patient, bookingDetails, homeVisitState, mobileSteps, virtualVisitState])

  const nextStep = () => {
    setCurrentStep(currentStep + 1)
    window.scrollTo(0, 0)
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
    window.scrollTo(0, 0)
  }

  const generateSteps = () => {
    let tmpSteps = []

    if (isHomeVisit) {
      tmpSteps = [
        {
          title: 'Introduction',
          content: <HomeVisits nextStep={nextStep} />
        },
        {
          title: 'Search',
          content: (
            <BookingsSearch
              setPreviousSearch={setPreviousSearch}
              previousSearch={previousSearch}
              patient={patient}
              setPatient={setPatient}
              isHomeVisit={homeVisitState}
              setCurrentStep={setCurrentStep}
              prevStep={prevStep}
              nextStep={nextStep}
              currentStep={currentStep}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              isReceptionBooking={isReceptionBooking}
              mobileSteps={mobileSteps}
              setMobileSteps={setMobileSteps}
            />
          )
        },
        {
          title: 'Provide Info',
          content: (
            <BookingFinalize
              patient={patient}
              isHomeVisit={homeVisitState}
              prevStep={prevStep}
              nextStep={nextStep}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              isReceptionBooking={isReceptionBooking}
              mobileSteps={mobileSteps}
              setMobileSteps={setMobileSteps}
            />
          )
        },
        {
          title: 'Complete',
          content: (
            <BookingConfirmation
              mobileSteps={mobileSteps}
              setMobileSteps={setMobileSteps}
              bookingDetails={bookingDetails}
              patient={patient}
              isReceptionBooking={isReceptionBooking}
            />
          )
        }
      ]
    } else if (isVirtualVisit) {
      tmpSteps = [
        {
          title: 'Introduction',
          content: <VirtualVisits nextStep={nextStep} />
        },
        {
          title: 'Search',
          content: (
            <BookingsSearch
              onAfterFailed={onAfterFailed}
              onAfterSuccess={onAfterSuccess}
              setPreviousSearch={setPreviousSearch}
              previousSearch={previousSearch}
              patient={patient}
              setPatient={setPatient}
              isHomeVisit={homeVisitState}
              setCurrentStep={setCurrentStep}
              prevStep={prevStep}
              nextStep={nextStep}
              currentStep={currentStep}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              isReceptionBooking={isReceptionBooking}
              mobileSteps={mobileSteps}
              setMobileSteps={setMobileSteps}
              isVirtualVisit={true}
            />
          )
        },
        {
          title: 'Provide Info',
          content: (
            <BookingFinalize
              // Nurse booking Params
              isScheduledBooking={targetPatient ? true : false}
              onAfterSuccessfulBooking={onAfterSuccess}
              onAfterFailedBooking={onAfterFailed}
              targetBooking={targetBooking}
              // Common params
              patient={targetPatient ? targetPatient : patient}
              isHomeVisit={homeVisitState}
              prevStep={prevStep}
              nextStep={nextStep}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              isReceptionBooking={isReceptionBooking}
              mobileSteps={mobileSteps}
              setMobileSteps={setMobileSteps}
              isVirtualVisit={true}
            />
          )
        },
        {
          title: 'Complete',
          content: (
            <BookingConfirmation
              mobileSteps={mobileSteps}
              setMobileSteps={setMobileSteps}
              bookingDetails={bookingDetails}
              patient={patient}
              isReceptionBooking={isReceptionBooking}
              isVirtualVisit={true}
            />
          )
        }
      ]
    } else {
      tmpSteps = [
        {
          title: 'Search',
          content: (
            <BookingsSearch
              setPreviousSearch={setPreviousSearch}
              previousSearch={previousSearch}
              patient={patient}
              setPatient={setPatient}
              isHomeVisit={homeVisitState}
              setCurrentStep={setCurrentStep}
              prevStep={prevStep}
              nextStep={nextStep}
              currentStep={currentStep}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              isReceptionBooking={isReceptionBooking}
              mobileSteps={mobileSteps}
              setMobileSteps={setMobileSteps}
            />
          )
        },
        {
          title: 'Provide Info',
          content: (
            <>
              <BookingFinalize
                patient={patient}
                setPatient={setPatient}
                isHomeVisit={homeVisitState}
                prevStep={prevStep}
                nextStep={nextStep}
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                isReceptionBooking={isReceptionBooking}
                mobileSteps={mobileSteps}
                setMobileSteps={setMobileSteps}
              />
            </>
          )
        },
        {
          title: 'Complete',
          content: (
            <>
              <BookingConfirmation
                mobileSteps={mobileSteps}
                setMobileSteps={setMobileSteps}
                bookingDetails={bookingDetails}
                patient={patient}
                setPatient={setPatient}
                isReceptionBooking={isReceptionBooking}
              />
            </>
          )
        }
      ]
    }

    setSteps(tmpSteps)
  }

  const checkRelevantStep = () => {
    if (isVirtualVisit) {
      return currentStep === 3
    }
    if (isReceptionBooking) {
      return currentStep === 3
    }
    if (isHomeVisit) {
      return currentStep === 3
    }

    return currentStep === 2
  }

  return (
    <ContainerCard
      backFunction={
        currentStep === 0 || deviceDetect().isMobile ? undefined : checkRelevantStep() ? undefined : prevStep
      }
      title={homeVisitState ? 'New Home Visit' : isVirtualVisit ? 'New Virtual Consultation' : 'New Clinic Booking'}
    >
      <CustomRow>
        <Col span={24}>{steps && steps.length > 0 ? <>{steps[currentStep].content}</> : <AgiliteSkeleton />}</Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default NewBooking
