import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils, faHeartPulse, faFileText, faCalendar, faUserDoctor } from '@fortawesome/free-solid-svg-icons'
import '../styling/MobileQuickButtons.css'
import MacroTrackerWrapper from '../../../AI Tools/Meal Tracker'
import PatAppointments from '../../../Appointments'
import PatMedHist from '../../../Medical History'
import CoreEnums from '../../../../../core/utils/enums'
import coreReducer from '../../../../../core/utils/reducer'
import MobHealthStatsWrapper from '../../../Health Stats/Mobile/Components/MobHealthStatsWrapper'
import { theme } from 'antd'
import MobPatCareSyncWrapper from '../../../CareSyncProd/components/mobile/MobPatCareSyncWrapper'
import PatCareSync from '../../../CareSync'

// Create a mobile wrapper component\

const MobQuickButtons = ({ onButtonClick }) => {
  const authState = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const handleQuickNavigate = (key, label) => {
    let component = <></>
    switch (key) {
      case CoreEnums.tabKeys.PAT_MACRO_TRACKER:
        component = <MacroTrackerWrapper userRef={authState.agiliteUser._id} />
        break
      case CoreEnums.tabKeys.MEDICAL_HISTORY:
        component = <PatMedHist />
        break
      case CoreEnums.tabKeys.PAT_APPOINTMENTS:
        component = <PatAppointments />
        break
      case CoreEnums.tabKeys.PAT_HEALTH_STATS:
        component = <MobHealthStatsWrapper />
        break
      case CoreEnums.tabKeys.PAT_CARE_SYNC:
        component = <PatCareSync />
        break
      // case CoreEnums.tabKeys.PAT_CARE_SYNC_PROD:
      //   component = <MobPatCareSyncWrapper />
      //   break
    }
    dispatch(
      coreReducer.actions.addTab({
        key: key,
        closable: true,
        label: label,
        children: component // Use the wrapper
      })
    )
  }
  const buttons = [
    {
      icon: faUtensils,
      label: CoreEnums.tabTitles.PAT_MACRO_TRACKER,
      key: CoreEnums.tabKeys.PAT_MACRO_TRACKER
    },
    {
      icon: faHeartPulse,
      label: CoreEnums.tabTitles.PAT_HEALTH_STATS,
      key: CoreEnums.tabKeys.PAT_HEALTH_STATS
    },
    {
      icon: faFileText,
      label: CoreEnums.tabTitles.MEDICAL_HISTORY,
      key: CoreEnums.tabKeys.MEDICAL_HISTORY
    },
    {
      icon: faCalendar,
      label: CoreEnums.tabTitles.PAT_APPOINTMENTS,
      key: CoreEnums.tabKeys.PAT_APPOINTMENTS
    },
    {
      icon: faUserDoctor,
      label: CoreEnums.tabTitles.PAT_CARE_SYNC,
      key: CoreEnums.tabKeys.PAT_CARE_SYNC
    }
    // {
    //   icon: faUserDoctor,
    //   label: CoreEnums.tabTitles.PAT_CARE_SYNC_PROD,
    //   key: CoreEnums.tabKeys.PAT_CARE_SYNC_PROD
    // }
  ]
  const { token } = theme.useToken()
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', justifyContent: 'center' }}>
      {buttons.map((button, index) => (
        <div
          key={index}
          className='quick-button-card'
          style={{
            width: 85,
            height: 85,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
          onClick={() => {
            handleQuickNavigate(button.key, button.label)
          }}
        >
          <div className='icon-container'>
            <FontAwesomeIcon style={{ fontSize: 20, color: token.colorPrimary }} icon={button.icon} />
          </div>
          <div style={{ fontSize: 9 }}>{button.label}</div>
        </div>
      ))}
    </div>
  )
}

export default MobQuickButtons
