import React, { useEffect, useState } from 'react'
import { ContainerCard } from '../../../../reusable-components/AgiliteCards'
import { Col } from 'antd'
import BookingFinalize from './MobClinicBookingFinalize'
import BookingConfirmation from './MobClinicBookingConfirmation'
import { useSelector } from 'react-redux'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import CustomRow from '../../../../reusable-components/CustomRow'
import MobClinicBookingSearch from './MobClinicBookingSearch'

const MobClinicBookingSearchWrapper = ({
  isHomeVisit,
  isReceptionBooking,
  isVirtualVisit,
  targetPatient,
  isCaresync,
  testKey
}) => {
  const state = useSelector((state) => state)
  const [bookingDetails, setBookingDetails] = useState({
    dateCreated: null,
    service: null,
    medicalProfRef: null,
    userRef: null,
    endTime: null,
    province: isReceptionBooking
      ? state.clinics.data.find((clinic) => clinic._id === state.auth.agiliteUser.extraData.clinics[0]).province
      : null,
    bookingDate: null
  })
  const [currentStep, setCurrentStep] = useState(0)
  const [steps, setSteps] = useState([])
  const [patient, setPatient] = useState(targetPatient ? targetPatient : null)
  const [previousSearch, setPreviousSearch] = useState(null)
  const [mobileSteps, setMobileSteps] = useState(1)

  useEffect(() => {
    generateSteps()
    // eslint-disable-next-line
  }, [currentStep, patient, bookingDetails, mobileSteps])

  const nextStep = () => {
    setCurrentStep(currentStep + 1)
    window.scrollTo(0, 0)
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
    window.scrollTo(0, 0)
  }

  const generateSteps = () => {
    let tmpSteps = []

    tmpSteps = [
      {
        title: 'Search',
        content: (
          <MobClinicBookingSearch
            setPreviousSearch={setPreviousSearch}
            previousSearch={previousSearch}
            patient={patient}
            setPatient={setPatient}
            isHomeVisit={isHomeVisit}
            setCurrentStep={setCurrentStep}
            prevStep={prevStep}
            nextStep={nextStep}
            currentStep={currentStep}
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            isReceptionBooking={isReceptionBooking}
            mobileSteps={mobileSteps}
            setMobileSteps={setMobileSteps}
            isCaresync={isCaresync}
            testKey={testKey}
          />
        )
      },
      {
        title: 'Provide Info',
        content: (
          <>
            <BookingFinalize
              patient={patient}
              setPatient={setPatient}
              isHomeVisit={isHomeVisit}
              prevStep={prevStep}
              nextStep={nextStep}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              isReceptionBooking={isReceptionBooking}
              mobileSteps={mobileSteps}
              setMobileSteps={setMobileSteps}
            />
          </>
        )
      },
      {
        title: 'Complete',
        content: (
          <>
            <BookingConfirmation
              mobileSteps={mobileSteps}
              setMobileSteps={setMobileSteps}
              bookingDetails={bookingDetails}
              patient={patient}
              setPatient={setPatient}
              isReceptionBooking={isReceptionBooking}
            />
          </>
        )
      }
    ]

    setSteps(tmpSteps)
  }

  return (
    <ContainerCard
      style={{ paddingBottom: 120 }}
      title={isHomeVisit ? 'New Home Visit' : isVirtualVisit ? 'New Virtual Consultation' : 'New Clinic Booking'}
    >
      <CustomRow>
        <Col span={24}>{steps && steps.length > 0 ? <>{steps[currentStep].content}</> : <AgiliteSkeleton />}</Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default MobClinicBookingSearchWrapper
