import React from 'react'
import { Col } from 'antd'
import CustomRow from '../../../../reusable-components/CustomRow'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'

const LoadingState = ({ message }) => (
  <CustomRow>
    <Col span={24}>
      <AgiliteSkeleton skActive spinnerTip={message} />
    </Col>
  </CustomRow>
)

export default LoadingState 