import React from 'react'

import ListView from './ServicesTable'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'

const ServicesWrapper = () => {
  return (
    <ContainerCard
      title={
        <>
          <h3>Services</h3>
        </>
      }
    >
      <ListView />
    </ContainerCard>
  )
}

export default ServicesWrapper
