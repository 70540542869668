import React from 'react'
import { Card, Table, Button, Tooltip, Space, Col, Modal, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { hexToRGBA } from '../../../../lib/utils'
import CustomRow from '../../../../reusable-components/CustomRow'

const RepeatHistory = ({
  script,
  handleBypassNextView,
  handleGenerateScriptTemplate,
  loading,
  printingScript,
  data
}) => {
  const { token } = theme.useToken()

  return (
    <Col span={12}>
      <Card
        style={{ minHeight: '100%', borderRadius: 0 }}
        headStyle={{ borderRadius: 0, background: hexToRGBA(token.colorPrimary, 0.2) }}
        size='small'
        type='inner'
        title={<center>Repeat history</center>}
      >
        <Table
          dataSource={[
            dayjs(script.createdAt).format('DD MMMM YYYY'),
            ...script.logs.map((log) => dayjs(log).format('DD MMMM YYYY'))
          ]}
          columns={[
            { title: 'Date', render: (text) => text },
            {
              title: 'Actions',
              render: () => (
                <Tooltip title='Print PDF'>
                  <Button
                    loading={printingScript}
                    disabled={loading}
                    onClick={() => handleGenerateScriptTemplate(data)}
                  >
                    <FontAwesomeIcon icon={faFilePdf} />
                  </Button>
                </Tooltip>
              )
            }
          ]}
        />
        <Space>
          <p>
            <b>Next Due: </b>
            {dayjs(script.nextDue).format('DD MMMM YYYY')}
          </p>
          <Space>
            <small>
              <i>Already supplied?</i>{' '}
              <i
                onClick={() => {
                  Modal.confirm({
                    title: 'Confirm Skip Rescript',
                    width: 950,
                    content: (
                      <CustomRow style={{ maxWidth: '100%' }}>
                        <Col span={24}>
                          Are you sure you want to skip rescript for the{' '}
                          <b>{dayjs(script.nextDue).format('DD MMMM YYYY')}</b>? This action can't be undone.
                        </Col>
                        <Col span={24}>
                          <p>
                            <b>Next due: </b>
                            {dayjs(script.nextDue)
                              .add(script.scheduleInterval * 30, 'days')
                              .format('DD MMMM YYYY')}
                          </p>
                        </Col>
                      </CustomRow>
                    ),
                    onOk: () => handleBypassNextView(),
                    okText: 'Skip',
                    cancelText: 'Cancel',
                    okButtonProps: { style: { background: token.colorError } }
                  })
                }}
                style={{ color: 'red', cursor: 'pointer' }}
              >
                skip
              </i>
            </small>
          </Space>
        </Space>
      </Card>
    </Col>
  )
}

export default RepeatHistory 