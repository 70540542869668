import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Input, message, Row, Select, Space, Switch } from 'antd'
import { handleError } from '../../../lib/utils'
import { createBillingProfile, getBillingProfile, MedPraxSchemeSearch, updateBillingProfile } from '../utils'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../../core/utils/reducer'
import CoreEnums from '../../../../core/utils/enums'

const BillingProfile = () => {
  const dispatch = useDispatch()

  const [formLoading, setFormLoading] = useState(false)
  const [schemesLoading, setSchemesLoading] = useState(false)
  const [schemeOps, setSchemeOps] = useState([])
  const [isNew, setIsNew] = useState(true)
  const [formData, setFormData] = useState({})

  useEffect(() => {
    handleGetSchemes()
    handleGetData()
    // eslint-disable-next-line
  }, [])

  const handleGetData = async () => {
    setFormLoading(true)
    try {
      const response = await getBillingProfile()

      if (response) {
        form.setFieldsValue(response)
        setIsNew(false)
        setFormData(response)
      } else {
        form.setFieldsValue({
          practiceName: '',
          disciplineType: 'General Practitioner',
          disciplinePrefix: '14',
          network: false,
          ipa: false,
          dispensing: false,
          roomsDefault: '',
          schemeContracts: []
        })
        setIsNew(true)
        setFormData({})
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setFormLoading(false)
  }

  const handleGetSchemes = async (page = 1) => {
    let response = []
    let tmpOps = []

    setSchemesLoading(true)

    try {
      response = await MedPraxSchemeSearch('', page)

      tmpOps = response.schemes.pageResult

      if (page < response.schemes.totalPages) {
        handleGetSchemes(page + 1)
      }

      setSchemeOps((prev) => [...prev, ...tmpOps])
    } catch (e) {
      message.error(handleError(e, true))
    }

    setSchemesLoading(false)
  }

  const handleSave = () => {
    setFormLoading(true)

    try {
      form.validateFields().then(async () => {
        isNew
          ? await createBillingProfile(form.getFieldsValue())
          : await updateBillingProfile(formData._id, form.getFieldsValue())
      })

      message.success('Billing Profile saved successfully')
    } catch (e) {
      message.error(handleError(e, true))
    }

    setFormLoading(false)
  }

  const handleCancel = () => {
    dispatch(coreReducer.actions.closeTab({ targetKey: CoreEnums.tabKeys.BILLING_PROFILE, removeBreadcrumb: true }))
  }

  const [form] = Form.useForm()

  return (
    <>
      <Row justify='center'>
        <Col span={24}>
          <Card title={<h2>Billing Profile</h2>} type='inner' bordered={false} loading={formLoading}>
            <Form layout='vertical' form={form} initialValues={{}}>
              <Row justify='center' gutter={[12, 12]}>
                <Col xs={24} lg={12}>
                  <Card title={<h3>General</h3>} size='small'>
                    <Form.Item name='practiceName' label='Practice Name'>
                      <Input />
                    </Form.Item>
                    <Form.Item name='disciplineType' label='Discipline Type'>
                      <Select
                        options={[{ label: 'General Practitioner', value: 'General Practitioner' }]}
                        onChange={(value) => {
                          if (value === 'General Practitioner') {
                            form.setFieldsValue({
                              disciplinePrefix: '14'
                            })
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item name='disciplinePrefix' label='Discipline Prefix'>
                      <Input readOnly />
                    </Form.Item>
                    <Form.Item name='network' label='Network'>
                      <Switch checkedChildren='Yes' unCheckedChildren='No' />
                    </Form.Item>
                    <Form.Item name='ipa' label='IPA'>
                      <Switch checkedChildren='Yes' unCheckedChildren='No' />
                    </Form.Item>
                    <Form.Item name='dispensing' label='Dispensing'>
                      <Switch checkedChildren='Yes' unCheckedChildren='No' />
                    </Form.Item>
                    <Form.Item name='roomsDefault' label='Practice Rate - Rooms: Default Base Rate or %'>
                      <Input />
                    </Form.Item>
                  </Card>
                </Col>
                <Col xs={24} lg={12}>
                  <Card loading={schemesLoading} title={<h3>Scheme Contracts</h3>} size='small'>
                    <Form.Item name='schemeContracts' label='Contracted In Schemes'>
                      <Select
                        showSearch
                        mode='multiple'
                        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        options={schemeOps.map((scheme) => {
                          return { label: `${scheme.name} (${scheme.code})`, value: scheme.code }
                        })}
                      />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
      <Row justify='center' style={{ marginTop: 10 }}>
        <Col>
          <Space>
            <Button danger onClick={handleCancel}>
              Cancel
            </Button>
            <Button type='primary' onClick={handleSave}>
              Save
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  )
}

export default BillingProfile
