import { message, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { getPrevTab } from '../../../../core/utils/utils'
import { getPatient } from '../utils/utils'

import MedicalHistoryForm from './medical-history-form'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import CustomRow from '../../../reusable-components/CustomRow'
import MedicalHistoryReducer from '../utils/medical-history-reducer'
import { handleError } from '../../../lib/utils'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'

const MedicalHistory = ({ readOnly, summary, isPatient }) => {
  const patientState = useSelector((state) => state.medicalHistory)
  const [loading, setLoading] = useState(true)
  // const [submitLoading, setSubmitLoading] = useState()
  // const [showModal, setShowModal] = useState(false)
  // const [handleSubmit, setHandleSubmit] = useState()
  const [notSaved, setNotSaved] = useState()
  const dispatch = useDispatch()
  // const { token } = theme.useToken()

  useEffect(() => {
    const unloadCallback = (event) => {
      const e = event || window.event
      e.preventDefault()
      if (e) {
        e.returnValue = ''
      }
      return ''
    }

    if (notSaved) {
      window.addEventListener('beforeunload', unloadCallback)
      return () => {
        //cleanup function
        window.removeEventListener('beforeunload', unloadCallback)
      }
    }
  }, [notSaved])

  const refreshView = async () => {
    setLoading(true)

    let data = []
    try {
      data = await getPatient()
      dispatch(MedicalHistoryReducer.actions.setPatient(data))
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  useEffect(() => {
    refreshView()
    // eslint-disable-next-line
  }, [])

  const updateParentData = (newData) => {
    if (newData) {
      dispatch(MedicalHistoryReducer.actions.setPatient(newData))
    }
  }

  if (summary) {
    if (patientState.patient?.medicalHistory) {
      return <></>
    } else {
      return (
        <Col span={24}>
          <AgiliteSkeleton skActive spinnerTip='Loading Medical History...' />
        </Col>
      )
    }
  } else {
    return (
      <ContainerCard
        title={<div style={{ fontSize: '24px' }}>Medical History</div>}
        // backFunction={getPrevTab(
        //   token,
        //   notSaved,
        //   handleSubmit,
        //   submitLoading,
        //   setSubmitLoading,
        //   showModal,
        //   setShowModal
        // )}
      >
        <CustomRow justify='center'>
          <Col xs={24} sm={20} md={18} lg={16} xl={14} xxl={12}>
            <MedicalHistoryForm
              wrapperLoad={loading}
              refreshView={refreshView}
              readOnly={readOnly}
              setNotSaved={setNotSaved}
              // setHandleSubmit={setHandleSubmit}
              data={patientState?.patient}
              isPatient={isPatient}
              updateParentData={updateParentData}
            />
          </Col>
        </CustomRow>
      </ContainerCard>
    )
  }
}

export default MedicalHistory
