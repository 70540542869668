import React, { useState, useEffect } from 'react'
import { Typography, Button, Divider } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Lottie from 'lottie-react'
// TODO: Add animation
// import bloodTestAnimation from '../../../../../../../assets/animations/Animation - 1737545657718.json'
import {
  faVial,
  faLocationDot,
  faClock,
  faCalendarCheck,
  faCircleCheck,
  faFlask
} from '@fortawesome/free-solid-svg-icons'
import '../../../styling/ConfirmationView.css'

const { Title, Text } = Typography

const ConfirmationView = ({ booking, onComplete }) => {
  const [isConfirming, setIsConfirming] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const { selectedTest, location, timeSlot } = booking

  const handleConfirm = () => {
    setIsConfirming(true)
    setTimeout(() => {
      setIsConfirming(false)
      setIsConfirmed(true)
    }, 5000)
    onComplete()
  }

  return (
    <div className='cs-confirmation'>
      {isConfirming ? (
        <div className='cs-confirmation__animation'>
          {/* <Lottie animationData={bloodTestAnimation} loop={true} className='cs-confirmation__lottie' /> */}
          <Text className='cs-confirmation__processing'>Scheduling your blood test...</Text>
        </div>
      ) : isConfirmed ? (
        <div className='cs-confirmation__success'>
          <div className='cs-confirmation__success-icon'>
            <FontAwesomeIcon icon={faCircleCheck} />
          </div>
          <Title level={3} className='cs-confirmation__success-title'>
            Test Scheduled Successfully!
          </Title>
          <Text className='cs-confirmation__success-text'>We look forward to seeing you at your appointment</Text>
        </div>
      ) : (
        <>
          <div className='cs-confirmation__header'>
            <div className='cs-confirmation__icon'>
              <FontAwesomeIcon icon={faCalendarCheck} />
            </div>
            <Title level={4} className='cs-confirmation__title'>
              Confirm Your Appointment
            </Title>
            <Text className='cs-confirmation__subtitle'>Please review the details below</Text>
          </div>

          <div className='cs-confirmation__details'>
            {/* Test Details */}
            <div className='cs-confirmation__section'>
              <div className='cs-confirmation__section-header'>
                <FontAwesomeIcon icon={faFlask} className='cs-confirmation__section-icon' />
                <Text className='cs-confirmation__section-title'>Test Details</Text>
              </div>
              <div className='cs-confirmation__card'>
                <div className='cs-confirmation__test-icon'>
                  <FontAwesomeIcon icon={faVial} />
                </div>
                <div className='cs-confirmation__info'>
                  <Text className='cs-confirmation__primary'>{selectedTest.name}</Text>
                  <Text className='cs-confirmation__secondary'>{selectedTest.description}</Text>
                  <div className='cs-confirmation__prep-note'>
                    <FontAwesomeIcon icon={faCircleCheck} className='cs-confirmation__check' />
                    <Text className='cs-confirmation__prep'>{selectedTest.preparation}</Text>
                  </div>
                </div>
              </div>
            </div>

            {/* Location Details */}
            <div className='cs-confirmation__section'>
              <div className='cs-confirmation__section-header'>
                <FontAwesomeIcon icon={faLocationDot} className='cs-confirmation__section-icon' />
                <Text className='cs-confirmation__section-title'>Location</Text>
              </div>
              <div className='cs-confirmation__card'>
                <div className='cs-confirmation__info'>
                  <Text className='cs-confirmation__primary'>{location.name}</Text>
                  <Text className='cs-confirmation__secondary'>{location.address}</Text>
                  <Text className='cs-confirmation__distance'>{location.distance}</Text>
                </div>
              </div>
            </div>

            {/* Time Details */}
            <div className='cs-confirmation__section'>
              <div className='cs-confirmation__section-header'>
                <FontAwesomeIcon icon={faClock} className='cs-confirmation__section-icon' />
                <Text className='cs-confirmation__section-title'>Date & Time</Text>
              </div>
              <div className='cs-confirmation__card'>
                <div className='cs-confirmation__info'>
                  <Text className='cs-confirmation__primary'>
                    {new Date(timeSlot.date).toLocaleDateString('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </Text>
                  <Text className='cs-confirmation__secondary'>{timeSlot.time}</Text>
                </div>
              </div>
            </div>
          </div>

          <Divider className='cs-confirmation__divider' />

          <div className='cs-confirmation__actions'>
            <Button type='primary' size='large' onClick={handleConfirm} className='cs-confirmation__submit'>
              Confirm Booking
            </Button>
            <Text className='cs-confirmation__note'>You will receive a confirmation email with these details</Text>
          </div>
        </>
      )}
    </div>
  )
}

export default ConfirmationView
