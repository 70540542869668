import React, { useState } from 'react'
import MobileChronicMedications from './MobChronicMedicationsCarousel'
import '../Styling/MobileHealthStats.css'
import usePatientHealthStats from '../../hooks/usePatHealthStats'
import MobPatHealthMetrics from './MobPatHealthMetrics'
import usePatChronicMeds from '../../hooks/usePatChronicMeds'
import MobMedicalHistoryLoadingOverlay from '../../../Medical History/Mobile/components/LoadingOverlay'
import LinkTop from '../../../../Linktop/lib'
import { useSelector } from 'react-redux'
import { Button, Col, Modal } from 'antd'
import CustomRow from '../../../../reusable-components/CustomRow'

const MobHealthStatsWrapper = () => {
  const authState = useSelector((state) => state.auth)
  const { loadingHealthMetrics, handleGetPatHealthStats } = usePatientHealthStats()
  const { loadingChronicMedications } = usePatChronicMeds()
  const [linktopModalOpen, setLinktopModalOpen] = useState(false)
  const [bluetoothError, setBluetoothError] = useState(null)
  //loadingChronicMedications || loadingHealthMetrics

  const handleOnAfterSuccess = () => {
    console.log('Refreshing')
    handleGetPatHealthStats() // refresh to fetch new readings // Work on replacing with real time redux update
    setLinktopModalOpen(false)
  }

  const handleOpenLinkTop = () => {
    // Check if we're in a browser environment first
    if (typeof navigator === 'undefined') {
      setBluetoothError('Browser environment not available')
      return
    }

    // Check if Bluetooth API is available
    if (!navigator.bluetooth) {
      setBluetoothError(
        'Bluetooth is not available in your browser or device. Please use a compatible browser like Chrome.'
      )
      return
    }

    setLinktopModalOpen(true)
    setBluetoothError(null)
  }

  return (
    <div className='mobile-health-stats'>
      {loadingChronicMedications || loadingHealthMetrics ? (
        <MobMedicalHistoryLoadingOverlay
          loading={true}
          text={loadingChronicMedications ? 'Loading chronic meds... ' : 'Loading health metrics...'}
        />
      ) : (
        <>
          <CustomRow>
            <Col span={24}>
              <div style={{ textAlign: 'center', fontSize: 12, color: '#8C8C8C', marginTop: 12 }}>
                Medical statistics from connected devices <br />
                will be displayed here.{' '}
              </div>
            </Col>
            <Col span={24}>
              <center>
                <Button type='primary' onClick={handleOpenLinkTop}>
                  Open Home Device
                </Button>
                {bluetoothError && <div style={{ color: 'red', marginTop: 8, fontSize: 12 }}>{bluetoothError}</div>}
              </center>
            </Col>
          </CustomRow>
          {/* <div style={{ textAlign: 'center', fontSize: 12, color: '#8C8C8C', marginTop: 12 }}>
            Connect your health devices to
            <br /> capture your data.
            <sup style={{ color: 'goldenrod', fontSize: 4 }}>COMING SOON</sup>
          </div> */}
          <MobPatHealthMetrics />
          <div style={{ textAlign: 'center', fontSize: 12, color: '#8C8C8C', marginTop: 12 }}>
            Any Chronic Medications captured <br /> will be displayed here.
          </div>
          <MobileChronicMedications />
        </>
      )}
      {/* {loadingHealthMetrics ? <AgiliteSkeleton sk
      {Active /> : <MobPatHealthMetrics />}
      {loadingChronicMedications ? <AgiliteSkeleton skActive /> : <MobileChronicMedications />} */}
      <Modal closable={false} maskClosable={false} width={1920} open={linktopModalOpen} footer={false}>
        <LinkTop
          handleOnAfterSuccess={handleOnAfterSuccess}
          userRef={authState.agiliteUser._id} // will always be the current user
          bookingRef={null} // no Booking ref because we are not using the booking page
          setModalOpen={setLinktopModalOpen}
        />
      </Modal>
    </div>
  )
}

export default MobHealthStatsWrapper
