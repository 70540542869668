import React from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Col } from 'antd'
import CustomButton from '../../reusable-components/CustomButton'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { deviceDetect } from 'react-device-detect'
import { useSelector } from 'react-redux'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'

const CheckinWelcome = ({ setView, MetaLogo, configLoading }) => {
  const coreState = useSelector((state) => state.core)
  return (
    <CustomRow
      justify={deviceDetect().isBrowser ? 'end' : 'center'}
      style={{ alignItems: 'center', minHeight: '100vh' }}
    >
      <Col
        className='checkin-container basic-card'
        style={{
          marginRight: deviceDetect().isBrowser ? 80 : 0,
          alignItems: 'center',
          display: 'flex',
          minHeight: '70vh',
          width: 500,
          boxShadow: '0 0 3px 0 #ccc'
        }}
      >
        <CustomRow justify='center'>
          {coreState?.entity ? (
            <>
              <Col span={24}>
                <center>
                  <img src={coreState.entity.entityLogo} alt='Meta-Clinic Logo' style={{ width: 341 }} />
                </center>
              </Col>
              <Col>
                <h1 style={{ width: 350, maxWidth: '100%', textAlign: 'center', lineHeight: '48px' }}>
                  Welcome to {coreState?.entity?.name}, Let us help you check in for your Appointment
                </h1>
              </Col>{' '}
            </>
          ) : (
            <Col span={24}>
              <AgiliteSkeleton skActive />
            </Col>
          )}
          <Col span={24}>
            <center>
              <CustomButton
                onClick={() => {
                  setView('otp')
                }}
                text='BEGIN CHECKIN'
                type='secondary'
                icon={faChevronRight}
                iconPosition='end'
                loading={configLoading}
              />
            </center>
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default CheckinWelcome
