import { Card, Select, Space, Table, theme } from 'antd'
import React from 'react'
import { hexToRGBA } from '../../../../lib/utils'
import { CarePlanTemplatesNewRecordDataColumnTemplate } from '../../../utils/templates'

const DataTrackerForm = ({ handleAddTracking, handleUpdateTracker, dataPoints, data, handleRemoveTracker }) => {
  const filterChosenDataPoints = (item) => {
    let index = -1
    index = data.findIndex((i) => i.dataKey === item.key)
    return index === -1
  }

  const { token } = theme.useToken()
  return (
    <Card>
      <h1 style={{ color: token.colorPrimary }}>HEALTH TRACKERS</h1>
      <p>Please select any health readings you want to track.</p>
      <Card
        size='small'
        style={{ marginTop: 12 }}
        headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
        bodyStyle={{ padding: 0 }}
        title={
          <Space>
            <h2>Health Tracking</h2>{' '}
            <Select
              placeholder='- Select Visit Trackers -'
              value={null}
              style={{ width: 500 }}
              onChange={(selection) => {
                handleAddTracking('data', selection)
              }}
              showSearch
              options={dataPoints
                .filter((i) => filterChosenDataPoints(i))
                .map((dataPoint) => ({ label: dataPoint.label, value: JSON.stringify(dataPoint) }))}
            />
          </Space>
        }
      >
        <Table
          size='small'
          bordered
          dataSource={data}
          columns={CarePlanTemplatesNewRecordDataColumnTemplate(handleUpdateTracker, dataPoints, handleRemoveTracker)}
        />
      </Card>
    </Card>
  )
}

export default DataTrackerForm
