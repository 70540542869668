import { createSlice } from '@reduxjs/toolkit'
const careSyncTestsReducer = createSlice({
  name: 'careSyncTestsReducer',
  initialState: { data: [] },
  reducers: {
    setRecords: (state, action) => {
      state.data = action.payload
    }
  }
})

const careSyncTestResultsReducer = createSlice({
  name: 'careSyncTestResultsReducer',
  initialState: { data: [] },
  reducers: {
    setRecords: (state, action) => {
      state.data = action.payload
    }
  }
})

const careSyncDataPointsReducer = createSlice({
  name: 'careSyncDataPointsReducer',
  initialState: { data: [] },
  reducers: {
    setRecords: (state, action) => {
      state.data = action.payload
    }
  }
})

export { careSyncTestsReducer, careSyncTestResultsReducer, careSyncDataPointsReducer }
