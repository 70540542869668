import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Card, Col, Row, Space, theme, Tooltip, Badge, Typography } from 'antd'
import { hexToRGBA } from '../../../../lib/utils'
import { StandardDataPointGraph } from '../../../examination-utils/standard-graph'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRulerVertical, faWeightScale, faRuler, faChartLine, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import '../styles/GeneralStats.css'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getStatStatus, formatStatValue, getStatReferenceText } from '../utils/general-stats-utils'

// Extend dayjs with relative time plugin
dayjs.extend(relativeTime)

const { Title } = Typography

// Constants
const SYMPTOM_TAG_COLOR = '#FF69B4' // Hot pink color for badges

/**
 * GeneralStats component for displaying and managing patient general statistics
 * @param {Object} props - Component props
 * @param {Array<Object>} props.dataPoints - Array of data point templates
 * @param {Array<Object>} props.patientDataEntries - Array of patient data entries
 * @param {Object} props.bookingData - Current booking data
 * @returns {JSX.Element} GeneralStats component
 */
const GeneralStats = ({ dataPoints, patientDataEntries, bookingData }) => {
  const [selectedStat, setSelectedStat] = useState(null)
  const { token } = theme.useToken()

  // Icons for each stat type
  const statIcons = {
    height: faRulerVertical,
    weight: faWeightScale,
    waist: faRuler,
    bmi: faChartLine
  }

  // Memoized function to filter and sort patient data by key
  const getStatData = useCallback(
    (key) => {
      if (!patientDataEntries?.length) return []
      return patientDataEntries
        .filter((entry) => entry?.key === key)
        .sort((a, b) => new Date(b.createdAt || b.dateCreated) - new Date(a.createdAt || a.dateCreated))
    },
    [patientDataEntries]
  )

  // Memoize individual stat data to prevent unnecessary recalculations
  const heightData = useMemo(() => getStatData('height'), [getStatData])
  const weightData = useMemo(() => getStatData('weight'), [getStatData])
  const waistData = useMemo(() => getStatData('waist'), [getStatData])
  const bmiData = useMemo(() => getStatData('bmi'), [getStatData])

  // Memoized list of general statistics with their metadata
  const generalStatsList = useMemo(
    () => [
      { title: 'Height', key: 'height', data: heightData, suffix: 'cm', icon: statIcons.height },
      { title: 'Weight', key: 'weight', data: weightData, suffix: 'kg', icon: statIcons.weight },
      { title: 'Waist', key: 'waist', data: waistData, suffix: 'cm', icon: statIcons.waist },
      { title: 'BMI', key: 'bmi', data: bmiData, suffix: '', icon: statIcons.bmi }
    ],
    [heightData, weightData, waistData, bmiData, statIcons]
  )

  // Initialize and update selected stat
  useEffect(() => {
    if (!selectedStat && generalStatsList?.length) {
      // Find the first stat with data
      const statWithData = generalStatsList.find((s) => s.data && s.data.length > 0)
      setSelectedStat(statWithData || generalStatsList[0])
      return
    }

    if (selectedStat) {
      const updatedStat = generalStatsList.find((stat) => stat.key === selectedStat.key)
      // Only update if the data has actually changed to prevent infinite loops
      if (updatedStat && JSON.stringify(updatedStat.data) !== JSON.stringify(selectedStat.data)) {
        setSelectedStat(updatedStat)
      }
    }
  }, [generalStatsList, selectedStat])

  /**
   * Formats and returns the latest statistic value with its unit and status
   * @param {Object} stat - Statistic object containing data and metadata
   * @returns {Object} Formatted statistic value, status, and timestamp
   */
  const formatStatValueWithStatus = useCallback((stat) => {
    if (!stat?.data?.length) return { text: 'No data', status: 'normal' }

    const latestData = stat.data[0]
    const status = getStatStatus(stat.key, latestData.value)

    return {
      text: formatStatValue(stat.key, latestData.value),
      status,
      timestamp: latestData.createdAt || latestData.dateCreated,
      referenceText: getStatReferenceText(stat.key)
    }
  }, [])

  const handleStatSelection = useCallback((stat) => {
    if (stat.data?.length > 0) {
      setSelectedStat(stat)
    }
  }, [])

  // Add a debug log to help identify when the component is re-rendering
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.debug('GeneralStats component rendered', {
        hasSelectedStat: !!selectedStat,
        selectedStatKey: selectedStat?.key,
        hasAnyData: generalStatsList.some((stat) => stat.data && stat.data.length > 0)
      })
    }
  }, [selectedStat, generalStatsList])

  const renderStatTabs = useMemo(() => {
    return (
      <div className='general-stats-tabs'>
        {generalStatsList.map((stat) => {
          const isSelected = selectedStat?.key === stat.key
          const hasData = stat.data?.length > 0
          const formattedValue = formatStatValueWithStatus(stat)

          return (
            <div
              key={stat.key}
              onClick={() => handleStatSelection(stat)}
              className={`general-stats-tab ${isSelected ? 'active' : ''} ${!hasData ? 'disabled' : ''}`}
              style={isSelected ? { borderBottomColor: token.colorPrimary } : {}}
            >
              <div className='general-stats-title'>
                <FontAwesomeIcon icon={stat.icon} style={{ marginRight: 6 }} />
                {stat.title}
              </div>
              <div className={`general-stats-value ${formattedValue.status}`}>
                {formattedValue.text}
                {hasData && formattedValue.status !== 'normal' && (
                  <Badge status={formattedValue.status === 'danger' ? 'error' : 'warning'} style={{ marginLeft: 4 }} />
                )}
              </div>
              {hasData && formattedValue.timestamp && (
                <div style={{ fontSize: '11px', color: '#999', marginTop: 2 }}>
                  {dayjs(formattedValue.timestamp).fromNow()}
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }, [generalStatsList, selectedStat, token.colorPrimary, formatStatValueWithStatus, handleStatSelection])

  const renderEmptyState = useMemo(() => {
    return (
      <div className='general-stats-empty'>
        <div className='general-stats-empty-icon'>
          <FontAwesomeIcon icon={faChartLine} />
        </div>
        <div className='general-stats-empty-text'>No general statistics have been recorded for this patient yet.</div>
      </div>
    )
  }, [])

  const renderReferenceRange = useMemo(() => {
    if (!selectedStat || !selectedStat.data || selectedStat.data.length === 0) return null

    return <div className='general-stats-reference'>{getStatReferenceText(selectedStat.key)}</div>
  }, [selectedStat])

  const hasAnyData = useMemo(
    () => generalStatsList.some((stat) => stat.data && stat.data.length > 0),
    [generalStatsList]
  )

  return (
    <Card
      styles={{
        header: {
          background: hexToRGBA(token.colorPrimary, 0.08),
          borderBottom: `1px solid ${hexToRGBA(token.colorPrimary, 0.1)}`,
          padding: '12px 16px'
        },
        body: {
          padding: '16px'
        }
      }}
      size='small'
      title={
        <Space>
          <FontAwesomeIcon
            icon={faChartLine}
            style={{
              color: token.colorPrimary,
              fontSize: '16px'
            }}
          />
          <Title level={5} style={{ margin: 0 }}>
            General Stats
          </Title>
          {hasAnyData && (
            <Badge
              count={generalStatsList.filter((s) => s.data && s.data.length > 0).length}
              style={{
                backgroundColor: SYMPTOM_TAG_COLOR,
                marginLeft: 8
              }}
            />
          )}
        </Space>
      }
      className='general-stats-card'
      style={{
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden'
      }}
    >
      <Row>
        <Col span={24}>{renderStatTabs}</Col>
        <Col span={24}>
          {hasAnyData
            ? selectedStat &&
              dataPoints?.find((point) => point.key === selectedStat.key) && (
                <>
                  <div className='general-stats-graph-container'>
                    <StandardDataPointGraph
                      data={{ records: selectedStat.data, key: selectedStat.key }}
                      dataTemplate={dataPoints.find((point) => point.key === selectedStat.key)}
                    />
                  </div>
                  {renderReferenceRange}
                </>
              )
            : renderEmptyState}
        </Col>
      </Row>
    </Card>
  )
}

export default React.memo(GeneralStats)
