import React, { useState } from 'react'

// UI Component imports from Ant Design and other libraries
import CustomRow from '../../../reusable-components/CustomRow'
import { Button, Card, Col, Empty, Modal, Row, Space, Spin, theme, Typography, Divider, Tooltip } from 'antd'

import CustomButton from '../../../reusable-components/CustomButton'
import ReactQuill from 'react-quill'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChainBroken,
  faCheckCircle,
  faCircleXmark,
  faNoteSticky,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'

// Custom component imports for various medical functionalities
import DiagnosisCapture from './components/DiagnosisCapture'

import ClinicalNotesTemplates from './components/ClinicalNotesTemplates'

import CustomLoadingIcon from '../../../reusable-components/CustomLoadingIcon'

// Utility and helper function imports
import { hexToRGBA } from '../../../lib/utils'

// Style imports
import './notes.css'
import '../../../Examination/examination-utils/style.css'
import ClinicalNotesControls from './components/ClinicalNotesControls'
import HistoricalNotes from './components/HistoricalNotes'
import { useNoteState } from './hooks/useNoteState'
import TranscribeAi from './components/TranscribeAi'

const { Title } = Typography

// Memoized components to prevent unnecessary re-renders
const MemoizedDiagnosisCapture = React.memo(DiagnosisCapture)
const MemoizedReactQuill = React.memo(ReactQuill)

// Quill editor modules and formats
const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean']
  ]
}

const quillFormats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet']

const ClinicalNotes = ({ bookingData, userRef, noBooking, setModalOpen }) => {
  // Redux state and theme access
  const { token } = theme.useToken()
  const [instructionsModalVisible, setInstructionsModalVisible] = useState(false)

  const {
    noteState,
    setNoteState,
    showClinicalNotesTemplates,
    setShowClinicalNotesTemplates,
    showHistorical,
    setShowHistorical,
    checkForExistingNote,
    handleCreateNote,
    handleUpdateNote,
    showAiTranscription,
    setShowAiTranscription,
    handleAutoGenerateNotes,
    transcriptionState,
    debouncedNoteCapture
  } = useNoteState({ bookingData, userRef })

  const renderCurrentNote = () => (
    <Row>
      <Col span={24}>
        {console.log('Note State:', noteState)}
        <div style={{ display: 'grid', gridTemplateColumns: '225px 1fr', position: 'relative' }}>
          <ClinicalNotesControls
            handleAutoGenerateNotes={handleAutoGenerateNotes}
            handleCreateNote={handleCreateNote}
            setShowAiTranscription={setShowAiTranscription}
            setShowClinicalNotesTemplates={setShowClinicalNotesTemplates}
            noBooking={noBooking}
            handleUpdateNote={handleUpdateNote}
            setShowHistorical={setShowHistorical}
            noteState={noteState}
            setNoteState={setNoteState}
          />

          {/* Vertical divider between controls and editor */}
          <Divider
            type='vertical'
            style={{
              height: '100%',
              position: 'absolute',
              left: '225px',
              top: 0,
              margin: 0,
              borderLeft: `1px solid ${hexToRGBA(token.colorBorder, 0.5)}`
            }}
          />

          <div style={{ width: '100%', height: '100%', paddingLeft: 16 }}>
            <CustomLoadingIcon
              loading={noteState.isAutoGenerating || noteState.isSubmitting}
              loadingText={
                noteState.isCreating
                  ? 'Creating Note...'
                  : noteState.isAutoGenerating
                  ? 'AI is Generating Clinical Notes...'
                  : 'Loading Notes...'
              }
              content={
                !noteState.noteId ? (
                  <Row gutter={[0, 12]}>
                    <Col span={24}>
                      <Empty description='You have no notes captured for this booking' />
                    </Col>
                    <Col span={24}>
                      <center>
                        <Button type='primary' style={{ background: token.colorSuccess }} onClick={handleCreateNote}>
                          Add Note
                        </Button>
                      </center>
                    </Col>
                  </Row>
                ) : (
                  <div className='quill-wrapper' style={{ width: '100%', height: '100%' }}>
                    <MemoizedReactQuill
                      key={`editor-${noteState.noteId}`}
                      id={`${bookingData?._id}-notes-capture-area`}
                      style={{
                        display: showHistorical ? 'none' : '',
                        width: '100%',
                        height: 'auto',
                        position: 'relative',
                        zIndex: 2
                      }}
                      modules={quillModules}
                      formats={quillFormats}
                      placeholder="Start typing your notes here. Your notes save automatically. To create a new note, use the 'Archive & New' button."
                      onChange={(e) => {
                        if (!noteState.isCreating) {
                          setNoteState((prev) => ({ ...prev, isUpdating: true, currentNote: e }))
                          debouncedNoteCapture(noteState.noteId, e)
                        }
                      }}
                      value={noteState.currentNote + transcriptionState.tmpTranscript}
                    />
                  </div>
                )
              }
            />
          </div>
        </div>
      </Col>
    </Row>
  )
  return (
    <>
      <CustomRow style={{ marginTop: 12 }}>
        {noBooking && (
          <Col span={24}>
            <FontAwesomeIcon
              size='2x'
              color={token.colorError}
              style={{ float: 'right', cursor: 'pointer' }}
              onClick={() => setModalOpen(false)}
              icon={faCircleXmark}
            />
          </Col>
        )}
        {!noBooking && (
          <Col span={24}>
            <MemoizedDiagnosisCapture bookingData={bookingData} />
          </Col>
        )}
        <Col span={24} style={{ minHeight: '100%', position: 'relative' }}>
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr auto', gap: 12 }}>
            <Row>
              <Col span={24}>
                <Card
                  styles={{
                    header: {
                      background: hexToRGBA(token.colorPrimary, 0.08),
                      borderBottom: `1px solid ${hexToRGBA(token.colorPrimary, 0.1)}`,
                      padding: '12px 16px'
                    },
                    body: !noteState.noteId ? { padding: 0 } : { padding: 0 }
                  }}
                  size='small'
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
                    overflow: 'hidden',
                    marginBottom: '16px'
                  }}
                  title={
                    <Space>
                      <FontAwesomeIcon
                        icon={faNoteSticky}
                        style={{
                          color: token.colorPrimary,
                          fontSize: '16px'
                        }}
                      />
                      <Title level={5} style={{ margin: 0 }}>
                        Clinical Notes
                      </Title>
                    </Space>
                  }
                  extra={
                    <>
                      {!showHistorical && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip title='View Instructions'>
                            <Button
                              type='text'
                              icon={<FontAwesomeIcon icon={faQuestionCircle} />}
                              onClick={() => setInstructionsModalVisible(true)}
                              style={{ marginRight: 12 }}
                            />
                          </Tooltip>
                          {noteState.isUpdating || noteState.isSubmitting ? (
                            <Space style={{ alignItems: 'center' }}>
                              <small>{noteState.isSubmitting ? 'LOADING NOTE' : 'SAVING'}</small>
                              <Spin spinning />
                            </Space>
                          ) : (
                            <>
                              <div style={{ alignItems: 'center', display: 'flex', gap: 4 }}>
                                <small>{noteState.fetchingError || noteState.updatingError ? 'ERROR' : 'SAVED'}</small>
                                <FontAwesomeIcon
                                  fontSize={20}
                                  icon={
                                    noteState.fetchingError || noteState.updatingError ? faChainBroken : faCheckCircle
                                  }
                                  color={
                                    noteState.fetchingError || noteState.updatingError
                                      ? token.colorError
                                      : token.colorSuccess
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </>
                  }
                >
                  {showHistorical || noBooking ? (
                    <HistoricalNotes
                      bookingData={bookingData}
                      userRef={userRef}
                      noteState={noteState}
                      setShowHistorical={setShowHistorical}
                    />
                  ) : (
                    renderCurrentNote()
                  )}
                </Card>
                {(noteState.fetchingError || noteState.updatingError) && (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      background: hexToRGBA(token.colorError, 0.2),
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 30
                    }}
                  >
                    <center>
                      <Space direction='vertical'>
                        <h2>Oops something went wrong.</h2>
                        <p>{noteState.fetchingError || noteState.updatingError}</p>
                        <CustomButton
                          size='small'
                          text='Retry'
                          type='success'
                          onClick={() => {
                            if (noteState.fetchingError) {
                              setNoteState((prev) => ({ ...prev, fetchingError: '', isSubmitting: true }))
                              checkForExistingNote()
                            } else {
                              setNoteState((prev) => ({ ...prev, updatingError: '', isUpdating: true }))
                              handleUpdateNote(noteState.noteId, noteState.currentNote)
                            }
                          }}
                        />
                      </Space>
                    </center>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Col>
        <TranscribeAi
          setNoteState={setNoteState}
          setModalOpen={setShowAiTranscription}
          modalOpen={showAiTranscription}
        />
        <Modal
          footer={false}
          maskClosable={false}
          closable={false}
          width={950}
          open={showClinicalNotesTemplates}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          onCancel={() => {
            setShowClinicalNotesTemplates(false)
          }}
          destroyOnClose
        >
          <ClinicalNotesTemplates
            setModalOpen={(value) => setShowClinicalNotesTemplates((prev) => value)}
            setValue={(value) => setNoteState((prev) => ({ ...prev, currentNote: value }))}
            debouncedNoteCapture={debouncedNoteCapture}
            setUpdatingNotes={(value) => setNoteState((prev) => ({ ...prev, isUpdating: value }))}
            noteId={noteState.noteId}
          />
        </Modal>
      </CustomRow>

      {/* Instructions Modal */}
      <Modal
        title={
          <Space>
            <FontAwesomeIcon icon={faQuestionCircle} style={{ color: token.colorPrimary }} />
            <span>Clinical Notes Instructions</span>
          </Space>
        }
        open={instructionsModalVisible}
        onCancel={() => setInstructionsModalVisible(false)}
        footer={[
          <Button key='close' type='primary' onClick={() => setInstructionsModalVisible(false)}>
            Got it
          </Button>
        ]}
        width={600}
      >
        <div style={{ padding: '16px 0' }}>
          <h3 style={{ marginBottom: '16px' }}>How to Use Clinical Notes</h3>

          <div style={{ marginBottom: '24px' }}>
            <h4 style={{ color: token.colorPrimary, marginBottom: '8px' }}>Getting Started</h4>
            <p>Start typing in the editor area. Your notes are saved automatically as you type.</p>
          </div>

          <div style={{ marginBottom: '24px' }}>
            <h4 style={{ color: token.colorPrimary, marginBottom: '8px' }}>Button Functions</h4>
            <ul style={{ paddingLeft: '20px' }}>
              <li style={{ marginBottom: '8px' }}>
                <strong>Archive & New</strong> - Archives your current note and creates a new blank note. Your archived
                note will be accessible from the history tab.
              </li>
              <li style={{ marginBottom: '8px' }}>
                <strong>Templates</strong> - Opens a panel to select and insert pre-defined note templates.
              </li>
              <li style={{ marginBottom: '8px' }}>
                <strong>Voice Dictation</strong> - Allows you to dictate notes using your microphone.
              </li>
              <li style={{ marginBottom: '8px' }}>
                <strong>Show History</strong> - View previously archived notes for this patient.
              </li>
            </ul>
          </div>

          <div style={{ marginBottom: '24px' }}>
            <h4 style={{ color: token.colorPrimary, marginBottom: '8px' }}>AI Features</h4>
            <ul style={{ paddingLeft: '20px' }}>
              <li style={{ marginBottom: '8px' }}>
                <strong>Generate Note (AI)</strong> - Automatically generates a clinical note based on patient data.
              </li>
              <li style={{ marginBottom: '8px' }}>
                <strong>Transcribe (AI)</strong> - Record audio and have AI transcribe and format it into a clinical
                note. This feature allows you to record your voice and then uses AI to convert it into a properly
                formatted clinical note.
              </li>
            </ul>
          </div>

          <div style={{ backgroundColor: token.colorInfoBg, padding: '12px', borderRadius: '4px' }}>
            <p style={{ margin: 0 }}>
              <strong>Note:</strong> All notes are automatically saved as you type. There's no need to manually save.
            </p>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ClinicalNotes
