export const AssistantEnums = {
  routes: {
    INITIATE_THREAD: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/friendly-assistant/query`,
    RUN_THREAD: '',
    CONTINUE_THREAD: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/friendly-assistant/continue`,
    READ_THREAD: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/friendly-assistant/response`,
    CHECK_THREAD_STATUS: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/friendly-assistant/run-status`
  },
  assistant_ids: {
    REFERRAL_LETTER: 'asst_QzDnRXMu0Sqo6GYOvuYbYET6',
    CLINICAL_NOTES: 'asst_vb4ONL43qHqwZuTlLd1XGNzZ',
    MACRO_TRACKER: 'asst_LtZK0rHmyugNgRB82H6oBiW6',
    PROTOCOLS: 'asst_DvR4JnQUS7Vao8GXzkyLAcEq',
    PROTOCOLS_ALT: 'asst_Nx7e443mKtmJWW3vvBsIQgHH',
    CONSULT_SUMMARY: 'asst_Vg1EvoAbZbh6IMKyeoZVQyov'
  }
}
