export const getExaminationContext = (patientDetails) => {
  if (!patientDetails) {
    return {
      role: 'AI Medical Assistant',
      context: `You are an AI Medical Assistant designed to help healthcare professionals during patient examinations.
      
      Your capabilities include:
      - Capturing vital signs
      - Recording symptoms
      - Suggesting tests based on symptoms
      - Generating medical reports
      - Providing clinical decision support
      
      Please use appropriate medical terminology and maintain patient confidentiality.
      Alert medical professionals of any concerning findings.`,
      capabilities: [
        'Capture and interpret vital signs',
        'Record patient symptoms with severity and duration',
        'Suggest appropriate diagnostic tests',
        'Generate structured medical reports',
        'Provide evidence-based clinical decision support'
      ]
    }
  }

  // Log detailed information about the patient details
  console.log('Formatting context with patient details:', {
    name: `${patientDetails.firstName} ${patientDetails.lastName}`,
    age: patientDetails.age,
    gender: patientDetails.gender,
    vitalSignsCount: patientDetails.vitalSigns?.length || 0,
    symptomsCount: patientDetails.symptoms?.length || 0,
    labTestsCount: patientDetails.labTests?.length || 0
  })

  // Format vital signs for display with better handling of missing or invalid data
  let vitalSignsSection = 'VITAL SIGNS: None recorded'

  if (patientDetails.vitalSigns && patientDetails.vitalSigns.length > 0) {
    // Check specifically for blood pressure
    const bloodPressureVital = patientDetails.vitalSigns.find(
      (vital) => vital.name && vital.name.toLowerCase().includes('blood pressure')
    )

    vitalSignsSection = 'VITAL SIGNS:'
    patientDetails.vitalSigns.forEach((vital) => {
      const name = vital.name || 'Unknown measurement'
      const value = vital.value !== undefined && vital.value !== null ? vital.value : 'Not recorded'
      const unit = vital.unit || ''

      // Special handling for blood pressure to include raw data if available
      if (name.toLowerCase().includes('blood pressure') && vital.rawData) {
        const systolic = vital.rawData.systolic || '?'
        const diastolic = vital.rawData.diastolic || '?'
        const formattedBP = `\n- ${name}: ${value} ${unit} (Systolic: ${systolic}, Diastolic: ${diastolic})`

        vitalSignsSection += formattedBP
      } else {
        vitalSignsSection += `\n- ${name}: ${value} ${unit}`
      }
    })
  }

  // Format symptoms for display
  const symptomsSection =
    patientDetails.symptoms && patientDetails.symptoms.length > 0
      ? `SYMPTOMS:
${patientDetails.symptoms.map((symptom) => `- ${symptom}`).join('\n')}`
      : 'SYMPTOMS: None recorded'

  // Format lab tests for display
  const labTestsSection =
    patientDetails.labTests && patientDetails.labTests.length > 0
      ? `LAB TESTS:
${patientDetails.labTests.map((test) => `- ${test.name || test.id}`).join('\n')}`
      : 'LAB TESTS: None ordered'

  const contextString = `You are an AI Medical Assistant designed to help healthcare professionals during patient examinations.

PATIENT DETAILS:
- Name: ${patientDetails.firstName} ${patientDetails.lastName}
- Age: ${patientDetails.age || 'Unknown'} years
- Gender: ${patientDetails.gender || 'Unknown'}

${vitalSignsSection}

${symptomsSection}

${labTestsSection}

Your capabilities include:
- Capturing vital signs
- Recording symptoms
- Suggesting tests based on symptoms
- Generating medical reports
- Providing clinical decision support

Please use appropriate medical terminology and maintain patient confidentiality.
Alert medical professionals of any concerning findings.

Initial greeting: "Hello, I'm your AI Medical Assistant. I'm here to help with the examination of ${
    patientDetails.firstName
  } ${patientDetails.lastName}."`

  return {
    role: 'AI Medical Assistant',
    context: contextString,
    capabilities: [
      'Capture and interpret vital signs',
      'Record patient symptoms with severity and duration',
      'Suggest appropriate diagnostic tests',
      'Generate structured medical reports',
      'Provide evidence-based clinical decision support'
    ]
  }
}
