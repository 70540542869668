import React from 'react'
import { Card, Typography } from 'antd'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { Text, Title } = Typography

const HealthWarnings = ({ warnings = [] }) => {
  if (!warnings.length) return null

  return (
    <div className="health-warnings">
      <Title level={4}>
        <FontAwesomeIcon icon={faTriangleExclamation} style={{ marginRight: 8 }} />
        Health Warnings
      </Title>
      {warnings.map((warning, index) => (
        <Card 
          key={index} 
          className="warning-card"
          size="small"
          style={{ 
            marginBottom: 8,
            background: 'rgba(255, 77, 79, 0.1)',
            border: '1px solid rgba(255, 77, 79, 0.2)'
          }}
        >
          <Text strong style={{ color: '#ff4d4f' }}>
            {warning.text}
          </Text>
          <div style={{ fontSize: '12px', marginTop: 4, color: '#666' }}>
            Added: {new Date(warning.timestamp).toLocaleDateString()}
          </div>
        </Card>
      ))}
    </div>
  )
}

export default HealthWarnings 