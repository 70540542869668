import Agilite from 'agilite'
import { handleError } from '../../../lib/utils'
import store from '../../../../store'
import patHealthStatsEnums from '../utils/enums'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const getPatHealthStats = () => {
  const userRef = store.getState().auth.agiliteUser?._id
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const qry = { userRef: userRef }
        let response = null

        response = await agilite.Connectors.execute(
          patHealthStatsEnums.profileKeys.CLINICAL_DATA_RECORDS,
          patHealthStatsEnums.routeKeys.READ,
          {
            filter: JSON.stringify(qry),
            page: null,
            pageLimit: null
          }
        )

        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}
