import React from 'react'
import { Card, Collapse, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faVial,
  faDroplet,
  faHeartPulse,
  faLungs,
  faWeightScale,
  faBrain,
  faFlask
} from '@fortawesome/free-solid-svg-icons'
import '../styling/LabTestsTab.css'

const { Panel } = Collapse

export const LabTestsTab = ({ data }) => {
  const getTestCategory = (test) => {
    const categories = {
      blood: ['Complete Blood Count', 'HbA1c', 'Iron Studies', 'B12', 'Folate'],
      heart: ['Lipid Panel', 'Cardiovascular', 'Blood Pressure'],
      hormones: ['Thyroid', 'Testosterone', 'Cortisol'],
      organs: ['Liver Function', 'Kidney Function', 'Metabolic'],
      vitamins: ['Vitamin D', 'Zinc', 'Magnesium', 'Iron'],
      general: ['Body Composition', 'Weight', 'Measurements']
    }

    for (const [category, tests] of Object.entries(categories)) {
      if (tests.some((t) => test.includes(t))) return category
    }
    return 'other'
  }

  const getTestIcon = (category) => {
    switch (category) {
      case 'blood':
        return faDroplet
      case 'heart':
        return faHeartPulse
      case 'organs':
        return faLungs
      case 'general':
        return faWeightScale
      case 'hormones':
        return faBrain
      case 'vitamins':
        return faFlask
      default:
        return faVial
    }
  }

  // Add data validation
  if (!data || typeof data !== 'object') {
    console.error('Invalid lab tests data:', data)
    return (
      <div className='lab-tests'>
        <Card className='overview-card'>
          <h3>Lab Tests Overview</h3>
          <p>No lab test data available</p>
        </Card>
      </div>
    )
  }

  // Convert data object to array of [frequency, tests] pairs if needed
  const testSchedule = Array.isArray(data) ? data : Object.entries(data)

  return (
    <div className='lab-tests'>
      <Card className='overview-card'>
        <h3>Lab Tests Overview</h3>
        <p className='description'>
          Regular monitoring of these biomarkers helps track your health progress and adjust your plan accordingly.
        </p>
      </Card>

      <Collapse defaultActiveKey={['quarterly']} className='test-schedule'>
        {testSchedule.map(([frequency, tests]) => {
          // Ensure tests is an array
          const testList = Array.isArray(tests) ? tests : []

          return (
            <Panel
              header={
                <div className='panel-header'>
                  <span className='frequency'>{frequency}</span>
                  <span className='test-count'>{testList.length} tests</span>
                </div>
              }
              key={frequency}
            >
              <div className='test-grid'>
                {testList.map((test, index) => {
                  const category = getTestCategory(test)
                  return (
                    <div key={index} className='test-item'>
                      <FontAwesomeIcon icon={getTestIcon(category)} />
                      <span className='test-name'>{test}</span>
                      <Tag className={`category-tag ${category}`}>{category}</Tag>
                    </div>
                  )
                })}
              </div>
            </Panel>
          )
        })}
      </Collapse>
    </div>
  )
}
