import dayjs from 'dayjs'

import { Space, Tooltip } from 'antd'
import { FilterFilled } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCancel,
  faClinicMedical,
  faCreditCard,
  faEye,
  faHouse,
  faMessage,
  faMoneyBillTransfer,
  faPeopleArrows,
  faStethoscope,
  faVideoCamera
} from '@fortawesome/free-solid-svg-icons'
import { generateStatus } from '../../../lib/booking-utils'
import CoreEnums from '../../../../core/utils/enums'
import store from '../../../../store'
import { isAdmin, isDoctor, isNurse, isReception } from '../../../lib/profile-utils'

const templates = {
  columnTemplate: (
    token,
    bookings,
    isSinglePatient,
    cancelBooking,
    authState,
    filteredValues,
    handleReassignment,
    rangeFilter,
    handleSetPaymentMethod,
    services,
    setTeamChatProps,
    handleViewRecord,
    isBilling
  ) => {
    return [
      {
        title: <h2>Patient</h2>,
        hidden: isSinglePatient,
        dataIndex: 'patientName',
        key: 'patientName',
        render: (text) => {
          if (text) {
            return text
          } else {
            return <span style={{ color: token.colorError }}>Patient Not Found</span>
          }
        },
        sorter: (a, b) => {
          return a.patientName.localeCompare(b.patientName)
        }
      },
      {
        hidden: authState.agiliteUser?.extraData.role.type !== 'reception' || isBilling,
        title: <h2>OTP</h2>,
        dataIndex: 'otp',
        key: 'otp'
      },
      {
        title: <h2>Clinic</h2>,
        dataIndex: 'clinicName',
        key: 'clinicName',
        hidden:
          authState.agiliteUser?.extraData.role.type === 'reception' ||
          authState.agiliteUser?.extraData.role.type === 'medical_professional',
        render: (text) => {
          if (text) {
            return text
          } else {
            return <span style={{ color: token.colorError }}>Clinic Not Found</span>
          }
        },
        // filters: uniqBy(
        //   bookings.map((booking) => {
        //     return { text: booking.clinicName, value: booking.clinicName }
        //   }),
        //   'text'
        // ),
        // onFilter: (value, record) => {
        //   return record.clinicName.indexOf(value) === 0
        // },
        filterIcon: (
          <Tooltip title='Filter by Clinic Name'>
            <FilterFilled />
          </Tooltip>
        )
      },
      {
        title: <h2>Medical Professional</h2>,
        dataIndex: 'professionalName',
        key: 'professionalName',
        hidden:
          authState.agiliteUser?.extraData.role.type === 'medical_professional' &&
          authState.agiliteUser?.extraData.profession === 'doctor',
        render: (text) => {
          if (text) {
            return text
          } else {
            return <span style={{ color: token.colorError }}>Medical Professional Not Found</span>
          }
        },
        // filters: uniqBy(
        //   bookings.map((booking) => {
        //     return { text: booking.professionalName, value: booking.professionalName }
        //   }),
        //   'text'
        // ),
        // filteredValue: filteredValues?.professionalName,
        // onFilter: (value, record) => {
        //   return record.professionalName.indexOf(value) === 0
        // },
        filterIcon: (
          <Tooltip title='Filter by Medical Professional'>
            <FilterFilled />
          </Tooltip>
        )
      },
      {
        title: 'Reason for Visit',
        render: (record) => {
          return record.chiefComplaint
        }
      },
      {
        title: 'Main Member',
        render: (record) => {
          if (record.patientRecord.mainMemberId) {
            return 'No'
          } else {
            return 'Yes'
          }
        }
      },
      {
        title: <h2>Profession</h2>,
        dataIndex: 'profession',
        key: 'profession',
        hidden:
          isSinglePatient ||
          authState.agiliteUser?.extraData.role.type === 'reception' ||
          (authState.agiliteUser?.extraData.role.type === 'medical_professional' &&
            authState.agiliteUser?.extraData.profession === 'doctor')
        // filters: uniqBy(
        //   bookings.map((booking) => {
        //     return { text: booking.profession, value: booking.profession }
        //   }),
        //   'text'
        // ),
        // onFilter: (value, record) => {
        //   return record.profession.indexOf(value) === 0
        // },
        // filterIcon: (
        //   <Tooltip title='Filter by Profession'>
        //     <FilterFilled />
        //   </Tooltip>
        // )
      },
      {
        title: <h2>Date</h2>,
        dataIndex: 'bookingDate',
        key: 'bookingDate',
        hidden: rangeFilter === 'today',
        render: (text) => {
          return dayjs(text).format('YYYY-MM-DD')
        },
        sorter: (a, b) => {
          return dayjs(a.bookingDate).format('YYYY-MM-DD') > dayjs(b.bookingDate).format('YYYY-MM-DD')
        }
      },
      {
        title: <h2>Time</h2>,
        dataIndex: 'startTime',
        key: 'bookingTime',
        sorter: (a, b) => {
          return a.startTime > b.startTime
        }
      },
      {
        title: <h2>Type</h2>,
        hidden: false,
        key: 'isVirtualVisit',
        render: (text, record) => {
          if (record.isVirtualVisit) {
            return (
              <>
                Virtual <FontAwesomeIcon icon={faVideoCamera} color={token.colorWarning} />
              </>
            )
          } else if (record.clinicRef === 'Home Visit') {
            return (
              <>
                Home Visit <FontAwesomeIcon icon={faHouse} color={token.colorPrimary} />
              </>
            )
          } else {
            return (
              <>
                Clinic <FontAwesomeIcon icon={faClinicMedical} color={token.colorSecondary} />
              </>
            )
          }
        },
        responsive: ['sm']
      },
      {
        title: <h2>Status</h2>,
        dataIndex: 'status',
        hidden: isBilling,
        key: 'status',
        render: (text, record) => {
          return generateStatus(record?.status, token, record?.wasNoShow)
        }
        // filters: [
        //   { text: 'Check-In', value: CoreEnums.bookingStatuses.checkin },
        //   { text: 'Screening', value: CoreEnums.bookingStatuses.screening },
        //   { text: 'Procedure', value: CoreEnums.bookingStatuses.procedure },
        //   { text: 'Diagnosis', value: CoreEnums.bookingStatuses.diagnosis },
        //   { text: 'Reception', value: CoreEnums.bookingStatuses.billing },
        //   { text: 'Reception Data Capture', value: CoreEnums.bookingStatuses.data_capture },
        //   { text: 'Cancelled', value: CoreEnums.bookingStatuses.cancelled },
        //   { text: 'Completed', value: CoreEnums.bookingStatuses.completed },
        //   { text: 'No Show', value: 'noshow' }
        // ],
        // filteredValue: filteredValues?.status,
        // onFilter: (value, record) => {
        //   if (value === 'No Show') {
        //     return record.wasNoShow
        //   } else {
        //     return record.status.indexOf(value) === 0
        //   }
        // },
        // filterIcon: (
        //   <Tooltip title='Filter by Status'>
        //     <FilterFilled />
        //   </Tooltip>
        // )
      },
      {
        title: <h2>Service</h2>,
        key: 'service',
        // filters: services?.map((service) => {
        //   return {
        //     text: service.name,
        //     value: service._id
        //   }
        // }),
        // filteredValue: filteredValues?.service,
        render: (text, record) => {
          return <>{record.serviceRecord?.name}</>
        }
      },
      {
        title: <h2>Payment</h2>,
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        hidden: authState.agiliteUser?.extraData.role.type !== 'reception',
        responsive: ['sm']
      },
      {
        fixed: 'right',
        title: <h2>Actions</h2>,
        render: (text, record) => {
          const handleCancelAction = () => {
            if (record.status === CoreEnums.bookingStatuses.checkin) {
              if (
                authState.agiliteUser?.extraData.role.type === 'patient' ||
                authState.agiliteUser?.extraData.role.type === 'reception'
              ) {
                return true
              }
            } else if (record.status === CoreEnums.bookingStatuses.screening) {
              if (record.clinicRef === 'Home Visit' || authState.agiliteUser?.extraData.role.type === 'reception') {
                return true
              }
            } else if (record.callpayGatewayReference && record.status !== CoreEnums.bookingStatuses.cancelled) {
              return true
            }
          }

          return (
            <Space style={{ gap: 12 }}>
              <Tooltip
                placement='left'
                title={
                  isAdmin()
                    ? 'View Record'
                    : isReception()
                    ? 'Bill Patient'
                    : isDoctor || isNurse
                    ? 'Start Consult'
                    : ''
                }
              >
                <FontAwesomeIcon
                  fontSize={20}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleViewRecord(record)
                  }}
                  icon={isAdmin() ? faEye : isReception() ? faCreditCard : isDoctor || isNurse ? faStethoscope : faEye}
                  style={{ color: '#ccc', transition: 'color 300ms ease' }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.color = token.colorSuccess
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.color = '#ccc'
                  }}
                />
              </Tooltip>

              {authState.agiliteUser?.extraData.profession === 'doctor' &&
              record.status === CoreEnums.bookingStatuses.checkin &&
              handleReassignment ? (
                <Tooltip title='Reassign Booking' placement='left'>
                  <FontAwesomeIcon
                    fontSize={20}
                    size='lg'
                    id='cancel-booking'
                    icon={faPeopleArrows}
                    onMouseOver={(e) => {
                      e.currentTarget.style.color = token.colorPrimary
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.color = '#ccc'
                    }}
                    style={{ color: '#ccc', transition: 'color 300ms ease' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleReassignment(record, store.getState().auth.agiliteUser.extraData.profession)
                    }}
                  />
                </Tooltip>
              ) : undefined}
              {authState.agiliteUser?.extraData.role.type === 'reception' &&
              record.status !== CoreEnums.bookingStatuses.completed ? (
                <Tooltip title='Change Payment Method' placement='left'>
                  <FontAwesomeIcon
                    fontSize={20}
                    id='change-payment-method'
                    onMouseOver={(e) => {
                      e.currentTarget.style.color = token.colorPrimary
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.color = '#ccc'
                    }}
                    style={{ color: '#ccc', transition: 'color 300ms ease' }}
                    icon={faMoneyBillTransfer}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleSetPaymentMethod(record)
                    }}
                  />
                </Tooltip>
              ) : undefined}

              <Tooltip title='Team Chat' placement='left'>
                <FontAwesomeIcon
                  fontSize={20}
                  onClick={(e) => {
                    e.stopPropagation()
                    setTeamChatProps({ userRef: record.userRef, isOpen: true })
                  }}
                  icon={faMessage}
                  style={{ color: '#ccc', transition: 'color 300ms ease' }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.color = token.colorSecondary
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.color = '#ccc'
                  }}
                />
              </Tooltip>
              {handleCancelAction() ? (
                <>
                  {authState.agiliteUser?.extraData.role.type === 'patient' ||
                  authState.agiliteUser?.extraData.role.type === 'reception' ? (
                    <Tooltip title='Cancel Booking' placement='left'>
                      <FontAwesomeIcon
                        fontSize={20}
                        size='lg'
                        id='cancel-booking'
                        onMouseOver={(e) => {
                          e.currentTarget.style.color = token.colorError
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.color = '#ccc'
                        }}
                        style={{ color: '#ccc', transition: 'color 300ms ease' }}
                        icon={faCancel}
                        onClick={(e) => {
                          e.stopPropagation()
                          cancelBooking(record)
                        }}
                      />
                    </Tooltip>
                  ) : undefined}
                </>
              ) : undefined}
            </Space>
          )
        },
        responsive: ['md']
      }
    ]
  }
}

export default templates
