import { Space, Button, Tooltip, theme, Divider } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faChain,
  faRefresh,
  faHeartPulse,
  faChartLine,
  faVial,
  faProcedures,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { RobotOutlined } from '@ant-design/icons'
import WebRTCAssistant from '../../../P AI Voice Assistant/lib/components/WebRTCAssistant'
import { getExaminationContext } from '../AI Assistant/config/aiAssistantContexts'
import { getExaminationTools } from '../AI Assistant/utils/examinationAITools'
import dayjs from 'dayjs'

const AssessmentActions = ({
  fetchPatientData,
  connectionStatus,
  setCaptureModalOpen,
  setLinktopModalOpen,
  patientData,
  bookingData,
  patientDataEntries,
  visibleSections,
  toggleSectionVisibility
}) => {
  const { token } = theme.useToken()
  const { loading: dataPointsLoading } = useSelector((state) => state.dataPoints)
  const { loading: templatesLoading } = useSelector((state) => state.examTemplates)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [aiContext, setAiContext] = useState('')

  const isLoading = dataPointsLoading || templatesLoading

  const buttonStyles = {
    sync: {
      background: token.colorSuccess
    },
    realtimeStatus: {
      padding: '4px 12px',
      borderRadius: 8,
      alignItems: 'center',
      background: connectionStatus.background
    },
    sectionToggle: {
      marginRight: 8,
      borderRadius: 8,
      height: 36,
      boxShadow: 'none',
      transition: 'all 0.3s ease',
      fontWeight: 500,
      border: 'none'
    },
    sectionToggleActive: {
      background: '#4A6CF7',
      color: 'white',
      boxShadow: '0 2px 8px rgba(74, 108, 247, 0.25)'
    },
    sectionToggleInactive: {
      background: '#F0F5FF',
      color: '#4A6CF7'
    },
    sectionToggleContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 8
    },
    divider: {
      margin: '12px 0'
    },
    infoIcon: {
      marginLeft: 8,
      fontSize: 14,
      color: token.colorTextSecondary,
      cursor: 'help'
    }
  }

  const calculateAge = (dateOfBirth) => {
    return dayjs().diff(dayjs(dateOfBirth), 'year')
  }

  // Enhanced function to extract vital signs from patient data entries
  const extractVitalSigns = () => {
    if (!patientDataEntries || !patientDataEntries.length) {
      console.log('No patient data entries available')
      return []
    }

    console.log('Patient data entries count:', patientDataEntries.length)

    // Dump the first few entries to see their structure
    if (patientDataEntries.length > 0) {
      console.log('Sample patient data entry:', JSON.stringify(patientDataEntries[0], null, 2))
    }

    // Common vital sign data point names - expanded list with variations
    const vitalSignNames = [
      'Blood Pressure',
      'BP',
      'Systolic',
      'Diastolic',
      'bloodPressure',
      'Heart Rate',
      'HR',
      'Pulse',
      'heartRate',
      'Temperature',
      'Temp',
      'temperature',
      'Respiratory Rate',
      'RR',
      'Respiration',
      'respiratoryRate',
      'Oxygen Saturation',
      'SpO2',
      'O2 Sat',
      'Oxygen',
      'oxygenSaturation',
      'Weight',
      'weight',
      'Height',
      'height',
      'BMI',
      'bmi'
    ]

    // Log all entries that might contain blood pressure data
    const potentialBPEntries = patientDataEntries.filter(
      (entry) =>
        entry &&
        ((entry.dataPointName && entry.dataPointName.toLowerCase().includes('blood pressure')) ||
          (entry.dataPointName && entry.dataPointName.toLowerCase().includes('bp')) ||
          (entry.key && entry.key === 'bloodPressure') ||
          (entry.checkinData && entry.checkinData.bloodPressure))
    )

    console.log('Potential blood pressure entries found:', potentialBPEntries.length)
    if (potentialBPEntries.length > 0) {
      console.log('Blood pressure entries:', JSON.stringify(potentialBPEntries, null, 2))
    }

    // More flexible matching for vital signs
    const extractedVitals = patientDataEntries
      .filter((entry) => {
        // Check if entry has dataPointName property
        if (!entry) {
          return false
        }

        // Check for blood pressure in checkinData
        if (entry.checkinData && entry.checkinData.bloodPressure) {
          return true
        }

        // Check for bloodPressure key
        if (entry.key === 'bloodPressure') {
          return true
        }

        // Check if entry has dataPointName property
        if (!entry.dataPointName) {
          return false
        }

        // Check if the dataPointName matches any of the vital sign names
        const match = vitalSignNames.some((name) => {
          const entryName = entry.dataPointName.toLowerCase()
          return entryName.includes(name.toLowerCase())
        })

        return match
      })
      .map((entry) => {
        // Log each matched vital sign
        console.log('Matched vital sign:', entry.dataPointName || entry.key, entry.value, entry.unit)

        // Check for blood pressure in checkinData
        if (entry.checkinData && entry.checkinData.bloodPressure) {
          const { systolic, diastolic } = entry.checkinData.bloodPressure
          console.log('Found blood pressure in checkinData:', systolic, diastolic)
          return {
            name: 'Blood Pressure',
            value: `${systolic || '?'}/${diastolic || '?'}`,
            unit: 'mmHg',
            rawData: {
              systolic,
              diastolic
            }
          }
        }

        // Check for bloodPressure key
        if (entry.key === 'bloodPressure') {
          console.log('Found entry with bloodPressure key:', entry)
          // Handle different formats of blood pressure data
          if (entry.value) {
            if (Array.isArray(entry.value)) {
              // Format: [systolic, diastolic] or [{value: systolic}, {value: diastolic}]
              return {
                name: 'Blood Pressure',
                value: `${entry.value[0]?.value || entry.value[0] || '?'}/${
                  entry.value[1]?.value || entry.value[1] || '?'
                }`,
                unit: entry.unit || 'mmHg',
                rawData: {
                  systolic: entry.value[0]?.value || entry.value[0],
                  diastolic: entry.value[1]?.value || entry.value[1]
                }
              }
            } else if (typeof entry.value === 'object') {
              // Format: {systolic: value, diastolic: value}
              return {
                name: 'Blood Pressure',
                value: `${entry.value.systolic || '?'}/${entry.value.diastolic || '?'}`,
                unit: entry.unit || 'mmHg',
                rawData: {
                  systolic: entry.value.systolic,
                  diastolic: entry.value.diastolic
                }
              }
            } else if (typeof entry.value === 'string' && entry.value.includes('/')) {
              // Format: "systolic/diastolic"
              const [systolic, diastolic] = entry.value.split('/').map((v) => parseInt(v.trim(), 10))
              return {
                name: 'Blood Pressure',
                value: `${systolic || '?'}/${diastolic || '?'}`,
                unit: entry.unit || 'mmHg',
                rawData: {
                  systolic,
                  diastolic
                }
              }
            }
          }
        }

        // Special handling for blood pressure which has systolic and diastolic values
        if (
          entry.dataPointName &&
          (entry.dataPointName.toLowerCase().includes('blood pressure') ||
            entry.dataPointName.toLowerCase().includes('bp'))
        ) {
          console.log('Processing blood pressure entry:', entry)

          // Check if blood pressure is already in the correct format
          if (entry.value && typeof entry.value === 'object' && Array.isArray(entry.value)) {
            // Already in array format, ensure it has the right structure
            return {
              name: 'Blood Pressure',
              value: `${entry.value[0]?.value || entry.value[0] || '?'}/${
                entry.value[1]?.value || entry.value[1] || '?'
              }`,
              unit: entry.unit || 'mmHg',
              rawData: {
                systolic: entry.value[0]?.value || entry.value[0],
                diastolic: entry.value[1]?.value || entry.value[1]
              }
            }
          }
          // Check if blood pressure is in object format with systolic/diastolic properties
          else if (
            entry.value &&
            typeof entry.value === 'object' &&
            (entry.value.systolic !== undefined || entry.value.diastolic !== undefined)
          ) {
            return {
              name: 'Blood Pressure',
              value: `${entry.value.systolic || '?'}/${entry.value.diastolic || '?'}`,
              unit: entry.unit || 'mmHg',
              rawData: {
                systolic: entry.value.systolic,
                diastolic: entry.value.diastolic
              }
            }
          }
          // Check if blood pressure is in string format like "120/80"
          else if (entry.value && typeof entry.value === 'string' && entry.value.includes('/')) {
            const [systolic, diastolic] = entry.value.split('/').map((v) => parseInt(v.trim(), 10))
            return {
              name: 'Blood Pressure',
              value: `${systolic || '?'}/${diastolic || '?'}`,
              unit: entry.unit || 'mmHg',
              rawData: {
                systolic,
                diastolic
              }
            }
          }
        }

        // Default handling for other vital signs
        return {
          name: entry.dataPointName || entry.key,
          value: entry.value,
          unit: entry.unit || ''
        }
      })

    console.log('Extracted vital signs count:', extractedVitals.length)
    if (extractedVitals.length > 0) {
      console.log('Extracted vital signs:', JSON.stringify(extractedVitals, null, 2))
    } else {
      console.log('No vital signs extracted from patient data entries')
    }

    return extractedVitals
  }

  // Create patient details object
  useEffect(() => {
    if (patientData) {
      // Extract vital signs first to ensure they're available for logging
      const vitalSigns = extractVitalSigns()

      // Log blood pressure specifically for debugging
      const bloodPressureReading = vitalSigns.find((vital) => vital.name.toLowerCase().includes('blood pressure'))

      if (bloodPressureReading) {
        console.log('Blood pressure reading found:', JSON.stringify(bloodPressureReading, null, 2))
      } else {
        console.log('No blood pressure reading found in vital signs')

        // Check if blood pressure is in the booking data
        if (bookingData && bookingData.checkinData && bookingData.checkinData.bloodPressure) {
          console.log(
            'Blood pressure found in booking data:',
            JSON.stringify(bookingData.checkinData.bloodPressure, null, 2)
          )

          // Add blood pressure from booking data
          const { systolic, diastolic } = bookingData.checkinData.bloodPressure
          if (systolic && diastolic) {
            const bpVital = {
              name: 'Blood Pressure',
              value: `${systolic}/${diastolic}`,
              unit: 'mmHg',
              rawData: {
                systolic,
                diastolic
              }
            }
            console.log('Adding blood pressure from booking data:', bpVital)
            vitalSigns.push(bpVital)
          }
        } else {
          console.log('No blood pressure found in booking data')
        }
      }

      const details = {
        firstName: patientData.firstName,
        lastName: patientData.lastName,
        age: patientData.dateOfBirth ? calculateAge(patientData.dateOfBirth) : null,
        gender: patientData.gender || 'Unknown',
        vitalSigns: vitalSigns,
        symptoms: bookingData?.symptoms || [],
        labTests:
          bookingData?.careSyncTests?.map((testId) => ({
            id: testId,
            name: testId // Ideally this would be the test name, not just the ID
          })) || []
      }

      console.log('Patient details for AI:', JSON.stringify(details, null, 2))
      const contextObj = getExaminationContext(details)
      console.log('AI Assistant context object:', contextObj)
      console.log('AI Assistant context string length:', contextObj.context.length)
      console.log('AI Assistant context preview:', contextObj.context.substring(0, 200) + '...')
      setAiContext(contextObj.context)
    }
  }, [patientData, patientDataEntries, bookingData])

  const handleAIAction = (action) => {
    console.log('AI Action:', action)

    // Handle specific AI actions here
    if (action && action.name) {
      switch (action.name) {
        case 'captureVitals':
          console.log('Capturing vital signs:', action.parameters)
          // Handle capturing vital signs
          break

        case 'captureBloodPressure':
          console.log('Capturing blood pressure:', action.parameters)
          // Extract systolic and diastolic values
          const { systolic, diastolic, unit = 'mmHg' } = action.parameters

          if (systolic && diastolic) {
            console.log(`Blood Pressure: ${systolic}/${diastolic} ${unit}`)
            // Here you would typically save this to your patient data
            // This is where you would integrate with your data saving mechanism
          } else {
            console.warn('Invalid blood pressure values provided')
          }
          break

        case 'recordSymptom':
          console.log('Recording symptom:', action.parameters)
          // Handle recording symptoms
          break

        case 'generateReport':
          console.log('Generating report:', action.parameters)
          // Handle report generation
          break

        case 'suggestTests':
          console.log('Suggesting tests:', action.parameters)
          // Handle test suggestions
          break

        default:
          console.log('Unknown action:', action.name)
      }
    }
  }

  const handleAIWarning = (warning) => {
    console.log('AI Warning:', warning)
  }

  // Create a default context for when patient data is not available
  const defaultContext = `You are an AI Medical Assistant designed to help healthcare professionals during patient examinations.
  
  Your capabilities include:
  - Capturing vital signs
  - Recording symptoms
  - Suggesting tests based on symptoms
  - Generating medical reports
  - Providing clinical decision support
  
  Please use appropriate medical terminology and maintain patient confidentiality.
  Alert medical professionals of any concerning findings.
  
  Initial greeting: "Hello! I'm your AI Medical Assistant. How can I help you today?"`

  return (
    <div>
      <Divider style={buttonStyles.divider} orientation='left'>
        <small style={{ color: token.colorTextSecondary, fontWeight: 'normal' }}>Quick Actions</small>
      </Divider>

      <Space style={{ marginBottom: 12 }} size='middle'>
        <Button
          disabled={isLoading}
          type='primary'
          onClick={() => setCaptureModalOpen(true)}
          icon={<FontAwesomeIcon icon={faPlus} />}
        >
          New Capture
        </Button>

        <Tooltip title='Refresh'>
          <Button
            onClick={fetchPatientData}
            type='primary'
            style={buttonStyles.sync}
            icon={<FontAwesomeIcon icon={faRefresh} />}
          >
            Sync
          </Button>
        </Tooltip>

        <Tooltip title={connectionStatus.title}>
          <Space style={buttonStyles.realtimeStatus}>
            <small>Real Time Updates</small>
            <FontAwesomeIcon
              icon={connectionStatus.icon}
              size='sm'
              color={connectionStatus.color}
              style={{ cursor: 'help' }}
            />
          </Space>
        </Tooltip>

        <WebRTCAssistant
          systemContext={aiContext || defaultContext}
          customTools={getExaminationTools()}
          onAction={handleAIAction}
          onWarning={handleAIWarning}
          buttonProps={{
            type: 'text',
            icon: <RobotOutlined />,
            style: { color: token.colorPrimary }
          }}
        />
      </Space>

      <Divider style={buttonStyles.divider} orientation='left'>
        <small style={{ color: token.colorTextSecondary, fontWeight: 'normal' }}>
          Assessment Sections
          <Tooltip title='These buttons toggle the visibility of different assessment sections. Click to show or hide a section.'>
            <FontAwesomeIcon icon={faInfoCircle} style={buttonStyles.infoIcon} />
          </Tooltip>
        </small>
      </Divider>

      <div style={buttonStyles.sectionToggleContainer}>
        <Tooltip title={visibleSections.vitalSigns ? 'Hide Vital Signs' : 'Show Vital Signs'}>
          <Button
            onClick={() => toggleSectionVisibility('vitalSigns')}
            icon={<FontAwesomeIcon icon={faHeartPulse} style={{ marginRight: 8, fontSize: 14 }} />}
            style={{
              ...buttonStyles.sectionToggle,
              ...(visibleSections.vitalSigns ? buttonStyles.sectionToggleActive : buttonStyles.sectionToggleInactive)
            }}
          >
            Vital Signs
          </Button>
        </Tooltip>

        <Tooltip title={visibleSections.generalStats ? 'Hide General Stats' : 'Show General Stats'}>
          <Button
            onClick={() => toggleSectionVisibility('generalStats')}
            icon={<FontAwesomeIcon icon={faChartLine} style={{ marginRight: 8, fontSize: 14 }} />}
            style={{
              ...buttonStyles.sectionToggle,
              ...(visibleSections.generalStats ? buttonStyles.sectionToggleActive : buttonStyles.sectionToggleInactive)
            }}
          >
            General Stats
          </Button>
        </Tooltip>

        <Tooltip title={visibleSections.bloodTests ? 'Hide Blood Tests' : 'Show Blood Tests'}>
          <Button
            onClick={() => toggleSectionVisibility('bloodTests')}
            icon={<FontAwesomeIcon icon={faVial} style={{ marginRight: 8, fontSize: 14 }} />}
            style={{
              ...buttonStyles.sectionToggle,
              ...(visibleSections.bloodTests ? buttonStyles.sectionToggleActive : buttonStyles.sectionToggleInactive)
            }}
          >
            Blood Tests
          </Button>
        </Tooltip>

        <Tooltip title={visibleSections.procedures ? 'Hide Procedures' : 'Show Procedures'}>
          <Button
            onClick={() => toggleSectionVisibility('procedures')}
            icon={<FontAwesomeIcon icon={faProcedures} style={{ marginRight: 8, fontSize: 14 }} />}
            style={{
              ...buttonStyles.sectionToggle,
              ...(visibleSections.procedures ? buttonStyles.sectionToggleActive : buttonStyles.sectionToggleInactive)
            }}
          >
            Procedures
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

AssessmentActions.propTypes = {
  fetchPatientData: PropTypes.func.isRequired,
  connectionStatus: PropTypes.shape({
    title: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired
  }).isRequired,
  setCaptureModalOpen: PropTypes.func.isRequired,
  setLinktopModalOpen: PropTypes.func.isRequired,
  patientData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    gender: PropTypes.string
  }),
  bookingData: PropTypes.object,
  patientDataEntries: PropTypes.array,
  visibleSections: PropTypes.shape({
    vitalSigns: PropTypes.bool.isRequired,
    generalStats: PropTypes.bool.isRequired,
    bloodTests: PropTypes.bool.isRequired,
    procedures: PropTypes.bool.isRequired
  }).isRequired,
  toggleSectionVisibility: PropTypes.func.isRequired
}

export default memo(AssessmentActions)
