const bookingState = {
  dashboardData: [],
  data: [],
  notes: {
    nurse: '',
    doctor: '',
    patient: ''
  }
}

export default bookingState
