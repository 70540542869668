import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  message,
  theme,
  Spin
} from 'antd'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { eachHalfHour, handleError } from '../../lib/utils'
import dayjs from 'dayjs'
import AvailabilityCalendar from './availability-calendar'
import AvailabilitySchedule from './availability-schedule'
import { readClinics } from '../../Admin/clinics/utils/utils'
import { readMedicalProfessionalUsers, readProfessions } from '../../Admin/medical-professionals/utils/utils'
import { createAvailability, readAvailability, updateAvailability } from './utils/utils'
import { uniqBy } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faSearch,
  faUser,
  faUserDoctor,
  faUserNurse,
  faUtensils,
  faHospital
} from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import CustomRow from '../../reusable-components/CustomRow'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import availabilityReducer from './utils/reducer'

const Availability = ({ isManager }) => {
  const dispatch = useDispatch()
  const { token } = theme.useToken()
  const clinicState = useSelector((state) => state.clinics)
  const authState = useSelector((state) => state.auth)
  const availability = useSelector((state) => state.availability.data)
  const isMedProf = authState.agiliteUser && authState.agiliteUser.extraData.role.type === 'medical_professional'
  const [facet, setFacet] = useState('clinic-select')
  const [medicalProfessionals, setMedicalProfessionals] = useState([])
  const [selectedProfessional, setSelectedProfessional] = useState()
  const [rangePicker, setRangePicker] = useState(false)
  const [rangeFrom, setRangeFrom] = useState(null)
  const [date, setDate] = useState()
  const [rangeTo, setRangeTo] = useState(null)
  const [currentDay, setCurrentDay] = useState({
    date: null,
    timeSlots: []
  })
  const [clinics, setClinics] = useState([])
  const [professions, setProfessions] = useState([])
  const [selectedClinic, setSelectedClinic] = useState(null)
  const [selectedClinicName, setSelectedClinicName] = useState(null)
  const [selectedProfession, setSelectedProfession] = useState(
    isMedProf ? authState.agiliteUser.extraData.profession : null
  )
  const [loading, setLoading] = useState(false)
  const [selectedProfDetails, setSelectedProfDetails] = useState()
  const [searchQry, setSearchQry] = useState('')

  const [clinicsLoading, setClinicsLoading] = useState(false)
  const [panelValue, setPanelValue] = useState(dayjs())
  const [professionalsLoading, setProfessionalsLoading] = useState(false)
  const [savingSchedule, setSavingSchedule] = useState(false)
  const [scheduleLoading, setScheduleLoading] = useState(false)
  const [isVirtual, setIsVirtual] = useState(false)

  const onPanelClick = (value) => {
    if (!selectedClinic || (!isMedProf && !selectedProfession)) {
      return
    }

    setDate(value)

    if (isMedProf && !isManager) {
      const medProf = authState.agiliteUser
      const medProfId = authState.agiliteUser._id

      setSelectedProfDetails(medProf)
      viewTimeslots(medProfId)
      handleGenerateTimeslots(value)
      setFacet('schedule')
    } else {
      setFacet('med-prof-select')
    }
  }

  const viewTimeslots = async (value) => {
    setScheduleLoading(true)
    try {
      setSelectedProfessional(value)
      await handleGenerateTimeslots(value)
    } catch (e) {
      message.error(handleError(e))
    }
    setScheduleLoading(false)
  }

  useEffect(() => {
    handleGenerateTimeslots(date)
    // eslint-disable-next-line
  }, [selectedProfessional])

  useEffect(() => {
    handleGetData()
    // eslint-disable-next-line
  }, [selectedProfession])

  useEffect(() => {
    handleGetData()
    // eslint-disable-next-line
  }, [selectedClinic, clinicState.data])

  useEffect(() => {
    handleGetData()
    // eslint-disable-next-line
  }, [isVirtual])

  const handleGetData = useCallback(async () => {
    setClinicsLoading(true)
    setLoading(true)

    try {
      const promises = []

      if (clinics.length === 0) {
        promises.push(handleReturnPromise(readClinics))
      } else {
        promises.push(Promise.resolve(clinics))
      }

      if (!isMedProf && professions.length === 0) {
        promises.push(handleReturnPromise(readProfessions))
      } else {
        promises.push(Promise.resolve(professions))
      }

      if (selectedClinic && selectedProfession) {
        promises.push(
          handleReturnPromise(readMedicalProfessionalUsers, {
            'extraData.profession': selectedProfession,
            'extraData.clinics': { $in: [selectedClinic] },
            'extraData.isActive': true
          })
        )
        promises.push(
          handleReturnPromise(readAvailability, {
            clinicRef: selectedClinic,
            profession: selectedProfession,
            isVirtual: isVirtual
          })
        )
      } else {
        promises.push(Promise.resolve([]))
        promises.push(Promise.resolve([]))
      }

      const [clinicsData, professionsData, medProfsData, availabilityData] = await Promise.all(promises)

      let currentClinic = selectedClinic
      let currentProfession = selectedProfession

      if (clinics.length === 0) {
        const clinicsList = isMedProf
          ? clinicsData.filter((i) => authState.agiliteUser.extraData.clinics.includes(i._id))
          : clinicsData

        setClinics(clinicsList)

        if (!selectedClinic && clinicsList.length > 0) {
          currentClinic = clinicsList[0]._id
          setSelectedClinic(currentClinic)
          setSelectedClinicName(clinicsList[0].name)
        }
      }

      if (!isMedProf && professions.length === 0) {
        setProfessions(professionsData)

        if (!selectedProfession && professionsData.length > 0) {
          currentProfession = professionsData[0].value
          setSelectedProfession(currentProfession)
        }
      }

      if (currentClinic && currentProfession) {
        setMedicalProfessionals(medProfsData)
        dispatch(availabilityReducer.actions.setRecords(availabilityData))

        // Check if currently selected professional is in the new list of medical professionals
        const selectedProfStillValid = medProfsData.some((prof) => prof._id === selectedProfessional)

        if (!selectedProfStillValid) {
          setSelectedProfessional(null)
          setSelectedProfDetails(null)
          // If we're in schedule view, switch to med-prof-select
          if (facet === 'schedule') {
            setFacet('med-prof-select')
          }
        }
      }
    } catch (e) {
      message.error(handleError(e))
    }
    setClinicsLoading(false)
    setLoading(false)
  }, [
    selectedClinic,
    selectedProfession,
    clinics.length,
    professions.length,
    isMedProf,
    authState.agiliteUser,
    dispatch,
    facet,
    selectedProfessional,
    isVirtual
  ])

  const handleReturnPromise = (func, qry) => {
    return new Promise((resolve, reject) => {
      let result = null

      ;(async () => {
        try {
          result = await func(qry)
          resolve(result)
        } catch (e) {
          reject(e)
        }
      })()
    })
  }

  const handleGenerateTimeslots = (value) => {
    const dateStr = dayjs(value).format('YYYY-MM-DD')
    const profEntry = availability.find(
      (entry) =>
        entry.clinicRef === selectedClinic &&
        entry.medicalProfessional === selectedProfessional &&
        entry.isVirtual === isVirtual
    )

    const existingTimeslots = profEntry?.availability.find((day) => day.date === dateStr)?.timeSlots || []

    const tmpTimeslots = eachHalfHour('06:00', '22:00').map((time) => {
      const formattedTime = time.length === 4 ? '0' + time : time
      const existing = existingTimeslots.find((slot) => slot.time === formattedTime)

      return {
        time: formattedTime,
        linkedProfessional: existing ? selectedProfessional : null,
        blocked: existing?.blocked || false,
        blockReason: existing?.blockReason,
        blockedBy: existing?.blockedBy,
        unblockedBy: existing?.unblockedBy
      }
    })

    setCurrentDay({ date: dateStr, timeSlots: tmpTimeslots })
  }

  const handleTimeSlotChange = (time, value) => {
    const tmpTimeslots = currentDay.timeSlots.concat()
    const itemIndex = tmpTimeslots.findIndex((i) => i.time === time)

    if (value) {
      tmpTimeslots[itemIndex].linkedProfessional = selectedProfessional
    } else {
      tmpTimeslots[itemIndex].linkedProfessional = null
    }
    setCurrentDay({ ...currentDay, timeSlots: tmpTimeslots })
  }

  const saveDaySchedule = async () => {
    setSavingSchedule(true)
    try {
      const tmpCurrentDay = JSON.parse(JSON.stringify(currentDay))

      const filteredTimeSlots = tmpCurrentDay.timeSlots
        .filter((slot) => slot.linkedProfessional === selectedProfessional)
        .map(({ linkedProfessional, ...slot }) => slot)

      const dayToSave = {
        ...tmpCurrentDay,
        timeSlots: filteredTimeSlots
      }

      const existingEntry = availability.find(
        (i) =>
          i.clinicRef === selectedClinic && i.medicalProfessional === selectedProfessional && i.isVirtual === isVirtual
      )

      if (existingEntry) {
        const newAvailability = [...existingEntry.availability]
        const dayIndex = newAvailability.findIndex((i) => i.date === dayToSave.date)

        if (dayIndex !== -1) {
          newAvailability[dayIndex] = dayToSave
        } else {
          newAvailability.push(dayToSave)
        }

        const updatedEntry = {
          ...existingEntry,
          availability: newAvailability
        }

        await updateAvailability(
          {
            clinicRef: selectedClinic,
            medicalProfessional: selectedProfessional,
            isVirtual: isVirtual
          },
          {
            availability: newAvailability
          }
        )

        const newState = availability.map((entry) =>
          entry.clinicRef === selectedClinic && entry.medicalProfessional === selectedProfessional
            ? updatedEntry
            : entry
        )
        dispatch(availabilityReducer.actions.setRecords(newState))
      } else {
        const newEntry = {
          clinicRef: selectedClinic,
          profession: selectedProfession,
          medicalProfessional: selectedProfessional,
          isVirtual: isVirtual,
          availability: [dayToSave]
        }

        await createAvailability(newEntry)

        const newState = [...availability, newEntry]
        dispatch(availabilityReducer.actions.setRecords(newState))
      }

      message.success('Schedule saved successfully')
    } catch (e) {
      message.error(handleError(e, true))
    }
    setSavingSchedule(false)
  }

  const filterNoAvailability = (arr) => {
    const tmpArr = arr.concat()
    return tmpArr.filter((i) => i.linkedProfessional !== null)
  }

  const handleRangeSelect = (isClear) => {
    const tmpTimeslots = currentDay.timeSlots.concat()
    let timeSlots = null

    if (!isClear) {
      if (!rangeFrom || !rangeTo) {
        return message.error('Please select a valid range')
      }

      timeSlots = eachHalfHour(rangeFrom, rangeTo)

      tmpTimeslots.forEach((slot) => {
        slot.linkedProfessional = null
      })

      timeSlots.forEach((slot) => {
        if (slot.length === 4) {
          slot = '0' + slot
        }

        const itemIndex = tmpTimeslots.findIndex((i) => i.time === slot)

        if (itemIndex !== -1) {
          tmpTimeslots[itemIndex].linkedProfessional = selectedProfessional
        }
      })
    } else {
      tmpTimeslots.forEach((slot) => {
        slot.linkedProfessional = null
      })
    }

    setCurrentDay({ ...currentDay, timeSlots: tmpTimeslots })
    setRangeFrom()
    setRangeTo()
    setRangePicker()
  }

  const dateCellRender = (value) => {
    const dateStr = dayjs(value).format('YYYY-MM-DD')
    const availableProfs = availability
      .filter((entry) => entry.clinicRef === selectedClinic && entry.isVirtual === isVirtual)
      .filter((entry) => {
        const daySchedule = entry.availability.find((day) => day.date === dateStr)
        return daySchedule && daySchedule.timeSlots.length > 0
      })
      .map((entry) => {
        const daySchedule = entry.availability.find((day) => day.date === dateStr)
        const timeSlots = [...daySchedule.timeSlots].sort((a, b) => a.time.localeCompare(b.time))

        return {
          _id: entry.medicalProfessional,
          timeSlots
        }
      })

    return (
      <Row
        justify='center'
        style={{
          height: '100%',
          width: '100%',
          padding: '3px',
          alignItems: 'center',
          borderRadius: 5
        }}
        onClick={() => onPanelClick(value)}
        key={value}
      >
        <Col span={24}>
          {loading ? (
            <Card
              title={dayjs(value).format('DD')}
              size='small'
              style={{ height: 150 }}
              bodyStyle={{ height: 100, overflow: 'scroll' }}
              headStyle={{
                backgroundColor:
                  dayjs(value).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? token.colorPrimary : '',
                color: dayjs(value).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? 'white' : ''
              }}
            >
              <Skeleton />
            </Card>
          ) : (
            <Card
              style={{ height: 150 }}
              bodyStyle={{ height: 100, overflowY: 'auto' }}
              headStyle={{
                backgroundColor:
                  dayjs(value).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? token.colorPrimary : '',
                color: dayjs(value).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') ? 'white' : ''
              }}
              title={dayjs(value).format('DD')}
              size='small'
            >
              {availableProfs.length > 0 ? (
                <>
                  {isMedProf && !isManager ? (
                    <>
                      {availableProfs
                        .filter((i) => i._id === authState.agiliteUser._id)
                        .map((entry) => {
                          return (
                            <Row key={entry._id} justify='center' style={{ alignItems: 'center', minHeight: '100%' }}>
                              <Col span={24}>
                                <center style={{ fontSize: 20 }}>
                                  {entry.timeSlots[0].time} - {entry.timeSlots[entry.timeSlots.length - 1].time}
                                </center>
                              </Col>
                            </Row>
                          )
                        })}
                    </>
                  ) : (
                    <>
                      {availableProfs.map((entry) => {
                        const medProf = medicalProfessionals.find((mp) => mp._id === entry._id)
                        if (!medProf) return null

                        return (
                          <Row key={entry._id} justify='center'>
                            <Col span={24}>
                              <center>
                                {medProf.firstName} {medProf.lastName} <br />({entry.timeSlots[0].time} -{' '}
                                {entry.timeSlots[entry.timeSlots.length - 1].time})
                              </center>
                              <Divider style={{ borderColor: '#cccccc', margin: 4 }} />
                            </Col>
                          </Row>
                        )
                      })}
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    color: token.colorError,
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}
                >
                  No Availability
                </div>
              )}
            </Card>
          )}
        </Col>
      </Row>
    )
  }

  const handleBlockOut = (time) => {
    const tmpTimeslots = JSON.parse(JSON.stringify(currentDay.timeSlots))
    const itemIndex = tmpTimeslots.findIndex((i) => i.time === time && i.linkedProfessional === selectedProfessional)
    if (itemIndex !== -1) {
      tmpTimeslots[itemIndex].blocked = true
    } else {
      return
    }
    setCurrentDay({ ...currentDay, timeSlots: tmpTimeslots })
  }

  const handleDelete = (time) => {
    const tmpTimeslots = JSON.parse(JSON.stringify(currentDay.timeSlots))
    const itemIndex = tmpTimeslots.findIndex((i) => i.time === time && i.linkedProfessional === selectedProfessional)
    if (itemIndex !== -1) {
      tmpTimeslots[itemIndex].linkedProfessional = null
    } else {
      return
    }
    setCurrentDay({ ...currentDay, timeSlots: tmpTimeslots })
  }

  const handleBack = () => {
    if (facet === 'schedule') {
      setFacet('calendar')
      setSelectedProfessional(null)
    }
  }

  const getProfessionIcon = (profession) => {
    switch (profession?.toLowerCase()) {
      case 'doctor':
        return faUserDoctor
      case 'nurse':
        return faUserNurse
      case 'dietitian':
        return faUtensils
      default:
        return faUserDoctor
    }
  }

  const filteredMedicalProfessionals = useMemo(() => {
    return medicalProfessionals.filter((mp) =>
      searchQry ? `${mp.firstName} ${mp.lastName}`.toLowerCase().includes(searchQry.toLowerCase()) : true
    )
  }, [medicalProfessionals, searchQry])

  const generateTimeslots = useMemo(() => {
    if (!date || !selectedProfessional) return []

    const dateStr = dayjs(date).format('YYYY-MM-DD')
    const profEntry = availability.find(
      (entry) => entry.clinicRef === selectedClinic && entry.medicalProfessional === selectedProfessional
    )

    const existingTimeslots = profEntry?.availability.find((day) => day.date === dateStr)?.timeSlots || []
    const timeSlotMap = new Map(existingTimeslots.map((slot) => [slot.time, slot]))

    return eachHalfHour('06:00', '22:00').map((time) => {
      const formattedTime = time.length === 4 ? '0' + time : time
      const existing = timeSlotMap.get(formattedTime)

      return {
        time: formattedTime,
        linkedProfessional: existing ? selectedProfessional : null,
        blocked: existing?.blocked || false,
        blockReason: existing?.blockReason,
        blockedBy: existing?.blockedBy,
        unblockedBy: existing?.unblockedBy
      }
    })
  }, [date, selectedProfessional, selectedClinic, availability])

  const renderVirtualToggle = () => (
    <Col flex='none'>
      <Select
        value={isVirtual}
        onChange={(value) => {
          setIsVirtual(value)
          if (facet === 'schedule') {
            setFacet('calendar')
          }
          setSelectedProfessional(null)
          setSelectedProfDetails(null)
        }}
        options={[
          { value: false, label: 'In-Person' },
          { value: true, label: 'Virtual' }
        ]}
        style={{ width: 120 }}
      />
    </Col>
  )

  return (
    <>
      {medicalProfessionals ? (
        <Modal
          onCancel={() => {
            setRangePicker(false)
            setRangeFrom()
            setRangeTo()
          }}
          open={rangePicker}
          onOk={() => {
            handleRangeSelect(false)
          }}
          okText='Submit'
          closable={false}
          maskClosable={false}
          okButtonProps={{ style: { backgroundColor: token.colorSuccess } }}
          cancelButtonProps={{ danger: true }}
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              From: <br />
              <Select
                value={rangeFrom}
                placeholder='-Select From Time-'
                options={eachHalfHour('06:00', '22:00').map((time) => {
                  if (time.length === 4) {
                    time = '0' + time
                  }
                  return {
                    value: time,
                    label: time
                  }
                })}
                onChange={(value) => {
                  setRangeFrom(value)
                }}
              />
            </Col>
            <Col span={24}>
              To: <br />
              <Select
                placeholder='-Select To Time-'
                value={rangeTo}
                options={eachHalfHour('06:00', '22:00').map((time) => {
                  if (time.length === 4) {
                    time = '0' + time
                  }
                  return {
                    value: time,
                    label: time
                  }
                })}
                onChange={(value) => {
                  setRangeTo(value)
                }}
              />
            </Col>
          </Row>
        </Modal>
      ) : null}

      <CustomRow>
        <Col span={24}>
          <Card
            size='small'
            style={{ marginTop: 12 }}
            title={
              <Row gutter={[12, 12]} align='middle' style={{ justifyContent: 'flex-start' }}>
                {(facet === 'med-prof-select' || facet === 'schedule') && (
                  <Col flex='none'>
                    <Button
                      type='primary'
                      onClick={() => {
                        setFacet('calendar')
                        setSelectedProfessional(null)
                        setSearchQry('')
                      }}
                    >
                      <Space>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        Back to Calendar
                      </Space>
                    </Button>
                  </Col>
                )}
                <Col flex='none'>
                  <Select
                    loading={clinicsLoading}
                    placeholder={
                      <span>
                        <FontAwesomeIcon icon={faHospital} style={{ marginRight: 8, color: token.colorPrimary }} />
                        Select Clinic
                      </span>
                    }
                    style={{ width: 200 }}
                    value={selectedClinic}
                    onChange={(value, option) => {
                      setSelectedClinic(value)
                      setSelectedClinicName(option.label)
                    }}
                    options={clinics.map((clinic) => ({
                      value: clinic._id,
                      label: (
                        <span>
                          <FontAwesomeIcon icon={faHospital} style={{ marginRight: 8, color: token.colorPrimary }} />
                          {clinic.name}
                        </span>
                      )
                    }))}
                    dropdownStyle={{ maxHeight: 400 }}
                  />
                </Col>
                {!isMedProf && (
                  <Col flex='none'>
                    <Select
                      placeholder={
                        <span>
                          <FontAwesomeIcon icon={faUserDoctor} style={{ marginRight: 8, color: token.colorPrimary }} />
                          Select Profession
                        </span>
                      }
                      style={{ width: 200 }}
                      value={selectedProfession}
                      onChange={(value) => setSelectedProfession(value)}
                      options={professions.map((prof) => ({
                        value: prof.value,
                        label: (
                          <span>
                            <FontAwesomeIcon
                              icon={getProfessionIcon(prof.value)}
                              style={{ marginRight: 8, color: token.colorPrimary }}
                            />
                            {prof.label}
                          </span>
                        )
                      }))}
                      dropdownStyle={{ maxHeight: 400 }}
                    />
                  </Col>
                )}
                {renderVirtualToggle()}
              </Row>
            }
          >
            <CustomRow justify='center'>
              {!selectedClinic ? (
                <Empty description='Please select a clinic to view availability' />
              ) : !selectedProfession ? (
                <Empty description='Please select a profession to view availability' />
              ) : facet === 'schedule' || facet === 'med-prof-select' ? (
                facet === 'schedule' ? (
                  <AvailabilitySchedule
                    professionalDetails={selectedProfDetails}
                    selectedProfession={selectedProfession}
                    medicalProfessionals={medicalProfessionals}
                    setFacet={setFacet}
                    setSelectedProfessional={setSelectedProfessional}
                    loading={loading}
                    saveDaySchedule={saveDaySchedule}
                    handleBlockOut={handleBlockOut}
                    handleDelete={handleDelete}
                    handleTimeslotChange={handleTimeSlotChange}
                    setRangePicker={setRangePicker}
                    currentDay={currentDay}
                    handleRangeSelect={handleRangeSelect}
                    isMedProf={isMedProf}
                    savingSchedule={savingSchedule}
                    professionalsLoading={professionalsLoading}
                    date={date}
                    setDate={setDate}
                    handleGenerateTimeslots={handleGenerateTimeslots}
                  />
                ) : (
                  <Row gutter={[12, 12]} style={{ width: '100%' }}>
                    <Col xs={24} md={8}>
                      <Card className='basic-card' size='small'>
                        <Input
                          placeholder='Search medical professionals'
                          suffix={<FontAwesomeIcon icon={faSearch} />}
                          value={searchQry}
                          onChange={(e) => setSearchQry(e.target.value)}
                          style={{ marginBottom: 12 }}
                        />
                        {professionalsLoading ? (
                          <AgiliteSkeleton skActive spinnerTip='Loading medical professionals...' />
                        ) : medicalProfessionals.length === 0 ? (
                          <Empty description='No Medical Professionals Found' />
                        ) : (
                          <div style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                            {filteredMedicalProfessionals.map((mp) => (
                              <Card
                                key={mp._id}
                                size='small'
                                hoverable={!loading && !scheduleLoading && !professionalsLoading}
                                style={{
                                  marginBottom: 8,
                                  cursor:
                                    loading || scheduleLoading || professionalsLoading ? 'not-allowed' : 'pointer',
                                  opacity: loading || scheduleLoading || professionalsLoading ? 0.7 : 1,
                                  ...(selectedProfessional === mp._id
                                    ? {
                                        backgroundColor: token.colorSuccess,
                                        borderColor: token.colorSuccess
                                      }
                                    : {})
                                }}
                                onClick={() => {
                                  if (!loading && !scheduleLoading && !professionalsLoading) {
                                    setSelectedProfDetails(mp)
                                    viewTimeslots(mp._id)
                                  }
                                }}
                              >
                                <Row align='middle' gutter={[8, 0]}>
                                  <Col flex='none'>
                                    <FontAwesomeIcon
                                      icon={getProfessionIcon(selectedProfession)}
                                      style={{
                                        fontSize: 16,
                                        color: selectedProfessional === mp._id ? 'white' : undefined
                                      }}
                                    />
                                  </Col>
                                  <Col flex='auto'>
                                    <span
                                      style={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        margin: 0,
                                        display: 'block',
                                        color: selectedProfessional === mp._id ? 'white' : undefined
                                      }}
                                    >
                                      {mp.firstName} {mp.lastName}
                                    </span>
                                  </Col>
                                </Row>
                              </Card>
                            ))}
                          </div>
                        )}
                      </Card>
                    </Col>

                    <Col xs={24} md={16}>
                      {selectedProfessional ? (
                        <Card className='basic-card'>
                          <Spin spinning={loading || scheduleLoading || professionalsLoading}>
                            <AvailabilitySchedule
                              professionalDetails={selectedProfDetails}
                              selectedProfession={selectedProfession}
                              medicalProfessionals={medicalProfessionals}
                              setFacet={setFacet}
                              setSelectedProfessional={setSelectedProfessional}
                              loading={loading}
                              saveDaySchedule={saveDaySchedule}
                              handleBlockOut={handleBlockOut}
                              handleDelete={handleDelete}
                              handleTimeslotChange={handleTimeSlotChange}
                              setRangePicker={setRangePicker}
                              currentDay={currentDay}
                              handleRangeSelect={handleRangeSelect}
                              isMedProf={isMedProf}
                              savingSchedule={savingSchedule}
                              professionalsLoading={professionalsLoading}
                              date={date}
                              setDate={setDate}
                              handleGenerateTimeslots={handleGenerateTimeslots}
                            />
                          </Spin>
                        </Card>
                      ) : (
                        <Empty description='Select a medical professional to view/edit their schedule' />
                      )}
                    </Col>
                  </Row>
                )
              ) : (
                <AvailabilityCalendar
                  dateCellRender={dateCellRender}
                  onPanelClick={onPanelClick}
                  loading={loading}
                  panelValue={panelValue}
                  setPanelValue={setPanelValue}
                />
              )}
            </CustomRow>
          </Card>
        </Col>
      </CustomRow>
    </>
  )
}

export default Availability
