export const getInviteLabel = (type) => {
  switch (type) {
    case 'entity_admin':
      return 'Entity Admin'
    case 'system':
      return 'System User'
    case 'medical':
      return 'Medical Professional'
    case 'patient':
      return 'Patient'
    default:
      return 'User'
  }
}

export const getInviteId = (type) => {
  switch (type) {
    case 'entity_admin':
      return 'entityAdmin_add_btn'
    case 'system':
      return 'systemUser_add_btn'
    case 'medical':
      return 'medicalProf_add_btn'
    case 'patient':
      return 'patient_add_btn'
    default:
      return 'User'
  }
}
