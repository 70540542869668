import React, { useState, memo } from 'react'
import { motion } from 'framer-motion'
import MobileBookingDetailsCapture from './MobBookingDetailsCapture'
import '../styling/MobileVirtualBooking.css'
import MobBookingResults from './MobBookingResults'
import MobBookingConfirmation from './MobBookingConfirmation'
import MobVirtualVisitsIntro from './MobVirtualVisitsIntro'
import { useSelector } from 'react-redux'

const MobVirtualBookingWrapper = memo(({ isVirtualVisit, isReceptionBooking, isHomeVisit }) => {
  const agiliteUser = useSelector((state) => state.auth.agiliteUser)
  const [bookingDetails, setBookingDetails] = useState({
    dateCreated: null,
    service: null,
    medicalProfRef: null,
    userRef: agiliteUser.userId,
    province: null,
    bookingDate: new Date()
  })

  const [dataResults, setDataResults] = useState([])
  const [patient, setPatient] = useState(agiliteUser)
  const [step, setStep] = useState(0)

  const handleBack = () => {
    setStep((prev) => {
      return prev - 1
    })
  }

  const cleanUpFunction = () => {
    setBookingDetails({
      dateCreated: null,
      service: null,
      medicalProfRef: null,
      userRef: null,
      province: null,
      bookingDate: new Date()
    })
    setDataResults([])
    setPatient(null)
    setStep(0)
  }

  const renderStep = () => {
    switch (step) {
      case 0:
        return <MobVirtualVisitsIntro nextStep={() => setStep(1)} />
      case 1:
        return (
          <MobileBookingDetailsCapture
            cleanUpFunction={cleanUpFunction}
            patient={patient}
            setDataResults={setDataResults}
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            isVirtualVisit={isVirtualVisit}
            isReceptionBooking={isReceptionBooking}
            isHomeVisit={isHomeVisit}
            setPatient={setPatient}
            onBack={handleBack}
            onNext={() => setStep(2)}
          />
        )
      case 2:
        return (
          <MobBookingResults
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            dataResults={dataResults}
            patient={patient}
            isVirtualVisit={isVirtualVisit}
            onNext={() => setStep(3)}
            onBack={handleBack}
          />
        )
      case 3:
        return (
          <MobBookingConfirmation
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
            patient={patient}
            onBack={handleBack}
            isVirtualVisit={isVirtualVisit}
          />
        )
      default:
        return null
    }
  }

  return (
    <motion.div
      className='mobile-virtual-booking'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>{renderStep()}</div>
    </motion.div>
  )
})

export default MobVirtualBookingWrapper
