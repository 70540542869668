import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col } from 'antd'

import MobEditMode from './MobEditMode'
import { firebaseAuth } from '../../../..'
import PatProfileEnums from '../utils/enums'
import MobDisplayMode from './MobDisplayMode'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import CustomRow from '../../../reusable-components/CustomRow'

const MobProfileWrapper = ({ isReception, record, refreshView }) => {
  const authState = useSelector((state) => state.auth)
  const [isEditMode, setIsEditMode] = useState(isReception)
  const [userData, setUserData] = useState(record ? record : authState.agiliteUser)
  const [image, setImage] = useState(null)

  useEffect(() => {
    handleGetImage()
    // eslint-disable-next-line
  }, [userData])

  useEffect(() => {
    setUserData(record ? record : authState.agiliteUser)
    // eslint-disable-next-line
  }, [authState.agiliteUser])

  const handleGetImage = () => {
    let image = null
    if (userData?.profileImage) {
      image = userData.profileImage
    } else if (firebaseAuth?.currentUser?.photoURL) {
      image = firebaseAuth.currentUser.photoURL
    } else {
      image = PatProfileEnums.images.DEFAULT_IMAGE
    }
    setImage(image)
  }

  return (
    <ContainerCard title='Profile'>
      <CustomRow style={{ justifyContent: 'center' }}>
        <Col xs={24} sm={24} md={20} lg={20} xl={16} xxl={16}>
          <>
            {isEditMode ? (
              <MobEditMode
                firebaseAuth={firebaseAuth}
                userData={userData}
                setIsEditMode={setIsEditMode}
                refreshView={() => {
                  handleGetImage()
                  setUserData(record ? record : authState.agiliteUser)
                }}
                setUserData={setUserData}
                authState={authState}
                image={image}
              />
            ) : (
              <MobDisplayMode setIsEditMode={setIsEditMode} userData={userData} image={image} />
            )}
          </>
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default MobProfileWrapper
