import { Button, Card, Col, DatePicker, Divider, Empty, Popconfirm, Select, Space, message, theme } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import CustomRow from '../../../../reusable-components/CustomRow'
import { faPills, faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined } from '@ant-design/icons'
import { handleError, hexToRGBA } from '../../../../lib/utils'
import { debounce } from 'lodash'
import { ProductSearchAgilite } from '../../../../Scripting/utils/utils'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

const MedHistGeneralMeds = ({ saveFunction, loading, setFacet, isDependant, dependantData }) => {
  const medsState = useSelector((state) => state.medicalHistory.patient.meds.list)
  const [nappiLoading, setNappiLoading] = useState(false)
  const [medsList, setMedsList] = useState(isDependant && dependantData ? dependantData.meds.list : medsState)
  const [nappiSearchFilter, setNappiSearchFilter] = useState('')
  const [nappiData, setNappiData] = useState([])
  const { token } = theme.useToken()
  const handleSubmit = () => {
    let tmpData = {}
    tmpData.meds = {}
    tmpData.meds.list = medsList
    saveFunction(tmpData)
  }

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await ProductSearchAgilite(searchQuery)
        const finalData = []

        for (const entry of tmpData) {
          finalData.push({
            type: entry.type,
            ...entry.data
          })
        }
        setNappiData(finalData)
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setNappiLoading(false)
  }

  useEffect(() => {
    setNappiLoading(true)
    debouncedNappiFilter(nappiSearchFilter)

    // eslint-disable-next-line
  }, [nappiSearchFilter])

  // eslint-disable-next-line
  const debouncedNappiFilter = useCallback(
    debounce((value) => {
      getNappiData(value)
    }, 1000),
    []
  )

  const handleRemoveMedication = (medIndex) => {
    let tmpMedsList = [...medsList]
    tmpMedsList.splice(medIndex, 1)
    setMedsList(tmpMedsList)
  }

  const handleAddMedication = (value) => {
    let tmpValue = JSON.parse(value)
    let tmpMedsList = [...medsList]
    tmpMedsList.push({
      med: {
        nappiCode: tmpValue.nappiCode,
        code: tmpValue.code,
        name: tmpValue.name,
        strengthMetric1: tmpValue.strengthMetric1,
        rxUnit: tmpValue.rxUnit,
        unitOfMeasure1: tmpValue.unitOfMeasure1
      },
      startDate: dayjs(),
      endDate: ''
    })

    setMedsList(tmpMedsList)
  }

  const handleUpdateMedication = (date, medIndex, key) => {
    let tmpMedsList = [...medsList]
    tmpMedsList[medIndex][key] = date
    setMedsList(tmpMedsList)
  }

  return (
    <CustomRow gutter={[0, 12]}>
      <div
        style={{
          width: '100%',
          padding: 8,
          background: hexToRGBA(token.colorWarning, 0.1),
          marginTop: 1
        }}
      >
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <Button
            style={{
              backgroundColor: token.colorError,
              color: 'white'
            }}
            onClick={() => {
              setFacet('')
            }}
            loading={loading}
          >
            Close
          </Button>
          <Button
            style={{
              backgroundColor: token.colorSuccess,
              color: 'white'
            }}
            onClick={() => {
              handleSubmit()
            }}
            loading={loading}
          >
            Save Changes
          </Button>
        </Space>
      </div>
      <Col span={24}>
        <div style={{ padding: '8px 12px 8px 12px' }}>
          <FontAwesomeIcon icon={faWarning} /> Make sure to only add general medications not associated with chronic
          illnesses. Chronic medications can be capture in the{' '}
          <Popconfirm
            title='Confirm leave'
            description='You are about to navigate to the Chronic Illnesses section. Any unsaved changes will be lost.'
            okText='LEAVE'
            cancelText='STAY'
            okButtonProps={{ style: { background: token.colorError }, type: 'primary' }}
            cancelButtonProps={{ type: 'primary' }}
            overlayStyle={{ width: 400, maxWidth: '90%' }}
            onConfirm={() => {
              setFacet('chronicIllnesses')
            }}
          >
            <Button style={{ padding: 'none', border: 'none', width: 'auto', color: token.colorPrimary }}>
              Chronic Illnesses
            </Button>{' '}
          </Popconfirm>
          section.
        </div>
        <div
          style={{
            padding: '8px 12px 8px 12px',
            display: 'flex',
            justifyContent: 'space-between',
            background: '#f5f5f5',
            alignItems: 'center'
          }}
        >
          <Select
            value={null}
            notFoundContent={nappiLoading ? <AgiliteSkeleton skActive spinnerTip='Search...' /> : undefined}
            onSearch={(searchValue) => {
              setNappiSearchFilter(searchValue)
            }}
            suffixIcon={<SearchOutlined />}
            onSelect={(value) => {
              handleAddMedication(value)
              setNappiData([])
            }}
            showSearch
            loading={nappiLoading}
            options={
              nappiLoading
                ? []
                : nappiData.map((med) => {
                    return {
                      label: med.code + ' - ' + med.name,
                      value: JSON.stringify(med)
                    }
                  })
            }
            style={{
              width: '100%',
              border: 'none',
              background: 'transparent',
              outline: 'none'
            }}
            placeholder='Search for medications to add...'
            maxLength={50}
          />
        </div>
        {medsList.length === 0 ? (
          <Empty
            style={{ marginTop: 24, marginBottom: 24 }}
            image={<FontAwesomeIcon icon={faPills} />}
            description='Use the search box above to find any medications and add it to your general medications history.'
          />
        ) : undefined}
        {medsList.map((medication, medIndex) => {
          return (
            <>
              <Card
                size='default'
                type='inner'
                title={
                  <div
                    style={{
                      width: '100%',
                      display: 'grid',
                      gap: 8,
                      gridTemplateColumns: '1fr auto',
                      whiteSpace: 'wrap'
                    }}
                  >
                    <p>
                      {medication.med.name} {medication.med.strengthMetric1} {medication.med.unitOfMeasure1}{' '}
                      {medication.med.rxUnit}
                    </p>
                  </div>
                }
                style={{ borderRadius: 0 }}
                headStyle={{
                  borderRadius: 0,
                  background: hexToRGBA(token.colorPrimary, 0.1),
                  paddingTop: 4,
                  paddingBottom: 4
                }}
              >
                <Space style={{ width: '100%' }}>
                  <p>Start Date:</p>
                  <DatePicker
                    onChange={(date) => {
                      handleUpdateMedication(date, medIndex, 'startDate')
                    }}
                    value={dayjs(medication.startDate ? medication.startDate : undefined)}
                  />
                </Space>
                <Space style={{ width: '100%', marginTop: 12 }}>
                  <p>End Date:</p>
                  {medication.endDate ? (
                    <DatePicker
                      onChange={(date) => {
                        handleUpdateMedication(date, medIndex, 'endDate')
                      }}
                      value={dayjs(medication.startDate ? medication.endDate : undefined)}
                    />
                  ) : (
                    <Popconfirm
                      overlayStyle={{ maxWidth: '90%', width: 400 }}
                      title={`End medication period for ${medication.med.name}`}
                      description='This action can not be undone.'
                      okText='END USE'
                      cancelText='CONTINUE USE'
                      cancelButtonProps={{ type: 'primary' }}
                      okButtonProps={{ type: 'primary', style: { background: token.colorError } }}
                      onConfirm={() => {
                        handleUpdateMedication(dayjs(), medIndex, 'endDate')
                      }}
                    >
                      <Button>Click to end</Button>
                    </Popconfirm>
                  )}
                </Space>
                <Divider style={{ margin: '24px 0px 8px 0px' }} />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                  <Popconfirm
                    overlayStyle={{ maxWidth: '90%', width: 400 }}
                    title={`Confirm Removing ${medication.med.name}`}
                    description='Once removed, this action can`t be undone.'
                    okText='REMOVE'
                    cancelText='KEEP'
                    cancelButtonProps={{ type: 'primary' }}
                    okButtonProps={{ type: 'primary', style: { background: token.colorError } }}
                    onConfirm={() => {
                      handleRemoveMedication(medIndex)
                    }}
                  >
                    <Button style={{ border: 'none', background: 'transparent', color: token.colorError }}>
                      Remove
                    </Button>
                  </Popconfirm>
                </div>
              </Card>

              <div style={{ width: '100%', height: 10, background: '#ccc' }}></div>
            </>
          )
        })}

        <center style={{ marginTop: 24, marginBottom: 24 }}>
          <Button
            style={{
              backgroundColor: token.colorSuccess,
              color: 'white',
              marginRight: 10
            }}
            onClick={() => {
              handleSubmit()
            }}
            loading={loading}
          >
            Save Changes
          </Button>
        </center>
      </Col>
    </CustomRow>
  )
}

export default MedHistGeneralMeds
