import React, { useState } from 'react'
import { Button, Divider, Popconfirm, message, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPlusCircle, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { hexToRGBA } from '../../../../../lib/utils'

const TemplateList = ({
  dataTemplates,
  searchTerm,
  setSearchTerm,
  activeTemplate,
  token,
  onAddTemplate,
  onSelectTemplate,
  onSelectCustomTemplate,
  onDeleteTemplate,
  isDeletingTemplate
}) => {
  // State to track which delete button is being hovered
  const [hoveredTemplateId, setHoveredTemplateId] = useState(null)
  const [hoveredInfoIcon, setHoveredInfoIcon] = useState(false)

  // Filter templates based on search term
  const filteredTemplates = dataTemplates.filter(template => 
    template.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div style={{ padding: 10, marginTop: 36, display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {/* Add Template button with green plus icon - styled differently */}
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '12px' }}>
        <Button 
          onClick={onAddTemplate}
          style={{ 
            borderColor: token.colorSuccess,
            color: token.colorSuccess,
            width: 'auto',
            padding: '0 15px'
          }}
          icon={<FontAwesomeIcon icon={faPlusCircle} style={{ color: token.colorSuccess, marginRight: '8px' }} />}
        >
          Create Template
        </Button>
      </div>

      {/* Available Templates heading with Divider */}
      <Divider orientation="left" style={{ margin: '8px 0 16px 0' }}>
        <small style={{ color: token.colorTextSecondary, fontWeight: 'normal' }}>Available Templates</small>
      </Divider>

      {/* Template selection section */}
      <div>
        {/* Custom Template button with info icon */}
        <div style={{ 
          display: 'flex', 
          marginBottom: '8px',
          width: '100%',
          alignItems: 'center'
        }}>
          <Button
            type={activeTemplate === null ? 'primary' : 'default'}
            onClick={onSelectCustomTemplate}
            style={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: activeTemplate === null ? token.colorPrimary : undefined,
              color: activeTemplate === null ? '#fff' : undefined,
              width: '100%',
              marginRight: '8px'
            }}
          >
            <span style={{ flex: 1, textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              Custom Template
            </span>
            {activeTemplate === null && <FontAwesomeIcon icon={faCheckCircle} />}
          </Button>
          
          <Tooltip title="Create a custom template with your preferred data points">
            <Button 
              type="text"
              icon={
                <FontAwesomeIcon 
                  icon={faInfoCircle} 
                  style={{ 
                    color: hoveredInfoIcon ? token.colorPrimary : token.colorTextQuaternary,
                    fontSize: '14px'
                  }} 
                />
              } 
              onClick={(e) => e.stopPropagation()}
              onMouseEnter={() => setHoveredInfoIcon(true)}
              onMouseLeave={() => setHoveredInfoIcon(false)}
              style={{ 
                minWidth: '32px',
                height: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                padding: 0,
                background: 'transparent'
              }}
            />
          </Tooltip>
        </div>

        {/* Template list */}
        {filteredTemplates.map((template) => (
          <div 
            key={template._id || template.name} 
            style={{ 
              display: 'flex', 
              marginBottom: '8px',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <Button
              type={activeTemplate?.name === template.name ? 'primary' : 'default'}
              onClick={() => onSelectTemplate(template)}
              style={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: activeTemplate?.name === template.name ? token.colorPrimary : undefined,
                color: activeTemplate?.name === template.name ? '#fff' : undefined,
                width: '100%',
                marginRight: '8px'
              }}
            >
              <span style={{ flex: 1, textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {template.name}
              </span>
              {activeTemplate?.name === template.name && (
                <FontAwesomeIcon icon={faCheckCircle} style={{ marginLeft: '8px' }} />
              )}
            </Button>
            
            <Popconfirm
              title="Delete Template"
              description={`Are you sure you want to delete "${template.name}"?`}
              onConfirm={(e) => {
                e.stopPropagation()
                onDeleteTemplate(template._id)
              }}
              okText="Yes"
              cancelText="No"
              placement="left"
            >
              <Button 
                type="text"
                icon={
                  <FontAwesomeIcon 
                    icon={faTrash} 
                    style={{ 
                      color: isDeletingTemplate === template._id 
                        ? token.colorError 
                        : hoveredTemplateId === template._id 
                          ? token.colorError 
                          : token.colorTextQuaternary,
                      fontSize: '14px'
                    }} 
                  />
                } 
                loading={isDeletingTemplate === template._id}
                onClick={(e) => e.stopPropagation()}
                onMouseEnter={() => setHoveredTemplateId(template._id)}
                onMouseLeave={() => setHoveredTemplateId(null)}
                style={{ 
                  minWidth: '32px',
                  height: '32px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: 'none',
                  padding: 0,
                  background: 'transparent'
                }}
              />
            </Popconfirm>
          </div>
        ))}
      </div>
      
      {/* Show message if no templates match search */}
      {filteredTemplates.length === 0 && searchTerm && (
        <div style={{ padding: '10px', textAlign: 'center', color: token.colorTextSecondary }}>
          No templates match your search
        </div>
      )}
    </div>
  )
}

export default React.memo(TemplateList)
