import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Card, Col, Form, Input, Row, Select, Space, Spin, message, theme } from 'antd'
import CustomButton from '../../reusable-components/CustomButton'
import TextArea from 'antd/es/input/TextArea'
import { handleError, hexToRGBA, stringToColor } from '../../lib/utils'
import ReactQuill from 'react-quill'
import '../styling/referral.css'
import { readDataPointTemplates } from '../../Data-Point-Templates/utils'
import { createReferralLetter, groupData, readClinicalEntries } from '../examination-utils/examination-lib'
import { readClinicDataPoints } from '../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { assistantInitiate } from '../../AI Tools/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { AssistantEnums } from '../../AI Tools/assitants-enums'
import { generateAIResponse } from '../../AI Tools/assistants-lib'
import { readMedicalHistory } from '../../Admin/patients/utils/utils'
import { useSelector } from 'react-redux'
import { downloadReferralLetter } from '../../Medical Vault/diagnosis-history/utils/utils'
import ReferralLetterDisplay from './referral-letter-display'

const RefferalLetterCapture = ({ bookingData, userRef, setModalOpen, patient }) => {
  // State
  const authState = useSelector((state) => state.auth)
  const [autogenConfigRequired, setAutogenConfigRequired] = useState(false)
  const [autogenConfig, setAutogenConfig] = useState({
    specialistType: null,
    specialistName: null,
    language: null,
    additionalPrompts: ''
  })
  const [content, setContent] = useState('')
  const [includedDataKeys, setIncludedDataKeys] = useState([])
  const [templateSelectionRequired, setTemplateSelectionRequired] = useState(false)

  // Data
  const [dataPoints, setDataPoints] = useState([])
  const [dataTemplates, setDataTemplates] = useState([])

  // Error Handling
  const [dataTemplateFetchingError, setDataTemplateFetchingError] = useState('')

  // Loading
  const [awaitingAi, setAwaitingAi] = useState(false)
  const [loadingDataAndTemplates, setLoadingDataAndTemplates] = useState(false)
  const [referralLoading, setReferralLoading] = useState(false)

  // Facet control
  const [isHistroical, setIsHistorical] = useState(false)

  const handleSubmitReferral = async () => {
    if (!autogenConfig.specialistName) return message.error('Please provide specialists name')
    if (!autogenConfig.specialistType) return message.error('Please provide specialist type')
    const signature = authState.agiliteUser.extraData.signature
    const mpNumber = authState.agiliteUser.extraData.mpNumber

    setReferralLoading(true)
    if (!signature) {
      setReferralLoading(false)
      return message.error(
        'No Signature has been set. Please set your Signature in your Personal Details Medical Profile.'
      )
    }

    if (!mpNumber) {
      setReferralLoading(false)
      return message.error(
        'No MP Number has been set. Please set your MP Number in your Personal Details Medical Profile.'
      )
    }

    try {
      await createReferralLetter({
        bookingRef: bookingData ? bookingData._id : '',
        userRef: bookingData ? bookingData.userRef : userRef,
        specialistType: autogenConfig.specialistType,
        specialistName: autogenConfig.specialistName,
        content
      })
      await downloadReferralLetter(
        bookingData,
        bookingData ? bookingData.patientRecord : patient,
        content,
        signature,
        'referral_letter'
      )
      handleReset()
      setModalOpen(false)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setReferralLoading(false)
  }

  const handleAiAutoGenerateReferral = async () => {
    let tmpContent = ''
    let tmpClinicalData = null
    let aiResponse = null
    const payload = {}

    setAwaitingAi(true)
    try {
      tmpClinicalData = await readClinicalEntries({
        key: { $in: includedDataKeys },
        userRef: bookingData.userRef ? bookingData.userRef : userRef
      })
      payload.config = autogenConfig
      payload.patientDetails = (() => {
        if (bookingData?.patientRecord) {
          const { firstName, lastName, dateOfBirth, gender } = bookingData.patientRecord
          return {
            fullName: `${firstName} ${lastName}`,
            dateOfBirth,
            gender
          }
        }
        return null
      })()

      payload.clinicalData = (() => {
        let groupedData = groupData(tmpClinicalData)
        groupedData.forEach((dataKey) => {
          dataKey.records = dataKey.records.map((record) => ({
            value: record.value,
            date: record.dateCreated
          }))
        })
        return groupedData
      })()

      payload.medicalHistory = await readMedicalHistory({ userRef: userRef ? userRef : bookingData.userRef })

      // TODO: convert keys
      const tmpData = await assistantInitiate(JSON.stringify(payload), AssistantEnums.assistant_ids.REFERRAL_LETTER)
      aiResponse = await generateAIResponse(tmpData)
      tmpContent = aiResponse?.data?.data[0]?.content[0]?.text?.value

      setContent(tmpContent)
      setAutogenConfigRequired(false)
      handleReset()
    } catch (e) {
      message.error(handleError(e))
    }
    setAwaitingAi(false)
  }

  const handleAutogenConfig = (value, key) => {
    let tmpAutogenConfig = JSON.parse(JSON.stringify(autogenConfig))
    tmpAutogenConfig[key] = value
    setAutogenConfig(tmpAutogenConfig)
  }

  useEffect(() => {
    if (autogenConfigRequired) {
      fetchDatapointData()
    }
  }, [autogenConfigRequired])

  const fetchDatapointData = async () => {
    let tmpDataPoints = []
    let tmpDataTemplates = []

    setLoadingDataAndTemplates(true)
    try {
      tmpDataPoints = await readClinicDataPoints()
      tmpDataTemplates = await readDataPointTemplates()

      setDataTemplateFetchingError('')
      setDataPoints(tmpDataPoints)
      setDataTemplates(tmpDataTemplates)
    } catch (e) {
      setDataTemplateFetchingError(handleError(e))
    }
    setLoadingDataAndTemplates(false)
  }

  // eslint-disable-next-line

  const handleTemplateSelection = (keys) => {
    const tmpIncludedDataKeys = []
    keys.forEach((key) => {
      const dataReference = dataPoints.find((i) => i.key === key)
      tmpIncludedDataKeys.push(dataReference.key)
    })
    setIncludedDataKeys(tmpIncludedDataKeys)
    setTemplateSelectionRequired(false)
  }

  const handleReset = () => {
    setDataPoints([])
    setDataTemplates([])
    setIncludedDataKeys([])
    setAutogenConfigRequired(false)
  }

  const handleModalClose = () => {
    handleReset()
    setModalOpen(false)
  }

  const SAOfficialLanguages = [
    'Afrikaans',
    'English',
    'IsiNdebele',
    'IsiXhosa',
    'IsiZulu',
    'Sepedi',
    'Sesotho',
    'Setswana',
    'SiSwati',
    'Tshivenda',
    'Xitsonga'
  ]

  const specialistTypes = [
    'Allergist',
    'Anesthesiologist',
    'Cardiologist',
    'Dermatologist',
    'Endocrinologist',
    'Gastroenterologist',
    'Hematologist',
    'Infectious Disease Specialist',
    'Internist',
    'Nephrologist',
    'Neurologist',
    'Obstetrician/Gynecologist',
    'Oncologist',
    'Ophthalmologist',
    'Orthopedic Surgeon',
    'Otolaryngologist (ENT)',
    'Pediatrician',
    'Plastic Surgeon',
    'Psychiatrist',
    'Pulmonologist',
    'Radiologist',
    'Rheumatologist',
    'Surgeon',
    'Urologist',
    'Geriatrician',
    'General Practitioner',
    'Family Medicine Physician',
    'Pathologist',
    'Chiropractor',
    'Podiatrist',
    'Emergency Medicine Specialist',
    'Sports Medicine Specialist',
    'Occupational Medicine Specialist',
    'Rehabilitation Specialist',
    'Pain Management Specialist',
    'Sleep Medicine Specialist',
    'Hospitalist',
    'Nuclear Medicine Specialist',
    'Geneticist',
    'Neurosurgeon',
    'Thoracic Surgeon',
    'Vascular Surgeon',
    'Colorectal Surgeon',
    'Bariatric Surgeon',
    'Maxillofacial Surgeon',
    'Transplant Surgeon',
    'Trauma Surgeon',
    'Hand Surgeon',
    'Hepatologist',
    'Immunologist',
    'Medical Geneticist',
    'Perinatologist',
    'Phlebologist',
    'Proctologist',
    'Reproductive Endocrinologist',
    'Spine Surgeon',
    'Toxicologist',
    'Critical Care Medicine Specialist',
    'Clinical Pharmacologist',
    'Adolescent Medicine Specialist',
    'Geriatric Psychiatrist',
    'Forensic Psychiatrist',
    'Child and Adolescent Psychiatrist',
    'Consultation-Liaison Psychiatrist',
    'Addiction Psychiatrist',
    'Pediatric Surgeon',
    'Neonatologist',
    'Pediatric Cardiologist',
    'Pediatric Endocrinologist',
    'Pediatric Gastroenterologist',
    'Pediatric Hematologist/Oncologist',
    'Pediatric Infectious Disease Specialist',
    'Pediatric Nephrologist',
    'Pediatric Neurologist',
    'Pediatric Pulmonologist',
    'Pediatric Rheumatologist',
    'Pediatric Urologist',
    'Pediatric Otolaryngologist',
    'Pediatric Dermatologist',
    'Pediatric Emergency Medicine Specialist',
    'Pediatric Geneticist',
    'Pediatric Ophthalmologist',
    'Pediatric Orthopedic Surgeon',
    'Pediatric Radiologist',
    'Pediatric Rehabilitation Specialist',
    'Pediatric Sleep Medicine Specialist'
  ]

  const { token } = theme.useToken()

  return (
    <>
      {isHistroical ? (
        <CustomRow>
          <Col span={24}>
            <CustomButton
              icon={faCaretLeft}
              type='primary'
              size='small'
              text='View Historical Referrals'
              disabled={awaitingAi}
              onClick={() => {
                setIsHistorical(false)
              }}
            />
          </Col>
          <Col span={24}>
            <ReferralLetterDisplay bookingData={bookingData} userRef={userRef ? userRef : bookingData.userRef} />
          </Col>
        </CustomRow>
      ) : (
        <CustomRow>
          <Col span={24}>
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Space>
                {autogenConfigRequired ? (
                  <Space>
                    <CustomButton
                      icon={faCaretLeft}
                      type='primary'
                      size='small'
                      text='Back'
                      disabled={awaitingAi}
                      hasPopconfirm={true}
                      popConfirmDescription={'All changes will be lost.'}
                      popConfirmTitle={'Please confirm:'}
                      onClick={() => {
                        setAutogenConfigRequired(false)
                        setAutogenConfig({
                          ...autogenConfig,
                          language: null,
                          additionalPrompts: ''
                        })
                      }}
                    />
                  </Space>
                ) : (
                  <Space>
                    <CustomButton
                      size='small'
                      text='Auto Generate Referral (Ai)'
                      onClick={() => {
                        setAutogenConfigRequired(true)
                      }}
                      style={{
                        background: 'linear-gradient(45deg, #13B1E1, #F14F8A)'
                      }}
                      type='primary'
                      disabled={awaitingAi}
                    />
                    <CustomButton
                      icon={faCaretRight}
                      iconPosition='end'
                      type='primary'
                      size='small'
                      text='View Historical Referrals'
                      disabled={awaitingAi}
                      onClick={() => {
                        setIsHistorical(true)
                      }}
                    />
                  </Space>
                )}
              </Space>
              <FontAwesomeIcon
                style={{ float: 'right', cursor: 'pointer' }}
                fontSize={24}
                icon={faXmarkCircle}
                color={token.colorError}
                onClick={() => handleModalClose()}
              />
            </Space>
          </Col>

          {autogenConfigRequired ? undefined : (
            <Col span={24}>
              <Space>
                <Input
                  value={autogenConfig.specialistName}
                  placeholder='Specialist name'
                  onChange={(e) => {
                    handleAutogenConfig(e.target.value, 'specialistName')
                  }}
                />
                <Select
                  value={autogenConfig.specialistType}
                  placeholder='- Select specialist type -'
                  showSearch
                  optionFilterProp='label'
                  options={specialistTypes.map((spec) => ({ value: spec, label: spec }))}
                  onChange={(selection) => {
                    handleAutogenConfig(selection, 'specialistType')
                  }}
                />
              </Space>
            </Col>
          )}
          <Col span={24}>
            {awaitingAi ? (
              <AgiliteSkeleton spinnerTip='' />
            ) : (
              <>
                {autogenConfigRequired ? (
                  <>
                    {dataTemplateFetchingError ? (
                      <div
                        style={{
                          zIndex: 200,
                          height: '100%',
                          width: '100%',
                          background: hexToRGBA(token.colorError, 0.2),
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 30
                        }}
                      >
                        <center>
                          <Space direction='vertical'>
                            <h2>Oops something went wrong.</h2>
                            <p>{dataTemplateFetchingError}</p>
                            <CustomButton
                              size='small'
                              text='Retry'
                              type='success'
                              onClick={() => {
                                setDataTemplateFetchingError('')
                                setLoadingDataAndTemplates(true)
                                fetchDatapointData()
                              }}
                            />
                          </Space>
                        </center>
                      </div>
                    ) : undefined}
                    <Row
                      gutter={[0, 12]}
                      style={{ position: 'relative', opacity: dataTemplateFetchingError ? 0.2 : 1 }}
                    >
                      <Col span={24}>
                        <Space>
                          <Input
                            value={autogenConfig.specialistName}
                            placeholder='Specialist name'
                            onChange={(e) => {
                              handleAutogenConfig(e.target.value, 'specialistName')
                            }}
                          />
                          <Select
                            value={autogenConfig.specialistType}
                            placeholder='- Select specialist type -'
                            showSearch
                            optionFilterProp='label'
                            options={specialistTypes.map((spec) => ({ value: spec, label: spec }))}
                            onChange={(selection) => {
                              handleAutogenConfig(selection, 'specialistType')
                            }}
                          />
                          <Select
                            value={autogenConfig.language}
                            placeholder='- Select language -'
                            options={SAOfficialLanguages.map((language) => ({ value: language, label: language }))}
                            onChange={(selection) => {
                              handleAutogenConfig(selection, 'language')
                            }}
                          />
                        </Space>
                      </Col>
                      <Col span={24}>
                        <TextArea
                          placeholder='Additional instructions...'
                          value={autogenConfig.additionalPrompts}
                          onChange={(e) => {
                            handleAutogenConfig(e.target.value, 'additionalPrompts')
                          }}
                          rows={10}
                        />
                      </Col>
                      <Col span={24}>
                        {loadingDataAndTemplates ? (
                          <AgiliteSkeleton skActive spinnerTip='' />
                        ) : (
                          <>
                            <Space direction='vertical' style={{ width: '100%' }}>
                              <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                                <h2>
                                  {templateSelectionRequired
                                    ? 'Please select data points to include:'
                                    : 'Please select a data template to use:'}
                                </h2>{' '}
                                <CustomButton
                                  size='small'
                                  type={templateSelectionRequired ? 'danger' : 'primary'}
                                  text={templateSelectionRequired ? 'Cancel' : 'Choose Template'}
                                  onClick={() => {
                                    setTemplateSelectionRequired(!templateSelectionRequired)
                                  }}
                                />
                              </Space>
                              {templateSelectionRequired ? (
                                <Row gutter={[0, 8]}>
                                  {dataTemplates.map((template) => {
                                    return (
                                      <Col span={24}>
                                        <Card
                                          size='small'
                                          type='inner'
                                          title={template.name}
                                          extra={
                                            <CustomButton
                                              text='Select'
                                              type={'primary'}
                                              size='small'
                                              onClick={() => {
                                                handleTemplateSelection(template.keys)
                                              }}
                                            />
                                          }
                                        >
                                          <Space wrap>
                                            {template.keys.map((key) => {
                                              const dataReference = dataPoints.find((i) => i.key === key)
                                              return (
                                                <div
                                                  style={{
                                                    color: '#000000',
                                                    padding: '4px 8px',
                                                    background: hexToRGBA(stringToColor(key), 0.1),
                                                    borderRadius: 4,
                                                    border: `1px ${stringToColor(key)} solid`
                                                  }}
                                                >
                                                  {dataReference.label}
                                                </div>
                                              )
                                            })}
                                          </Space>
                                        </Card>
                                      </Col>
                                    )
                                  })}
                                </Row>
                              ) : (
                                <Select
                                  value={includedDataKeys}
                                  placeholder='- Select -'
                                  mode='multiple'
                                  style={{ width: '100%' }}
                                  showSearch
                                  optionFilterProp='label'
                                  onChange={(array) => {
                                    setIncludedDataKeys(array)
                                  }}
                                  options={dataPoints.map((dataPoint) => ({
                                    value: dataPoint.key,
                                    label: dataPoint.label
                                  }))}
                                />
                              )}
                            </Space>
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Form>
                      <Form.Item
                        style={{ margin: 0 }}
                        name={['referral', 'comment']}
                        rules={[{ required: true, message: 'Please provide a refferal comment' }]}
                      >
                        <Spin spinning={awaitingAi} tip='Generating AI Referral...'>
                          <ReactQuill
                            value={content}
                            onChange={(html) => {
                              setContent(html)
                            }}
                          />
                        </Spin>
                      </Form.Item>
                    </Form>
                  </>
                )}
              </>
            )}
          </Col>
          <Col span={24}>
            {autogenConfigRequired ? (
              <CustomButton
                style={{ float: 'right' }}
                type='success'
                size='small'
                disabled={awaitingAi}
                text='Confirm and Generate'
                onClick={() => {
                  if (!autogenConfig?.specialistName) {
                    return message.error("Please provide the specialist's name")
                  }
                  if (!autogenConfig?.language) {
                    return message.error('Please provide your preferred language')
                  }
                  if (!autogenConfig?.specialistType) {
                    return message.error('Please provide the specialist type')
                  }
                  handleAiAutoGenerateReferral()
                }}
              />
            ) : (
              <CustomButton
                loading={referralLoading}
                style={{ float: 'right' }}
                size='small'
                type='success'
                text={'Submit Referral'}
                onClick={() => {
                  handleSubmitReferral()
                }}
              />
            )}
          </Col>
        </CustomRow>
      )}
    </>
  )
}

export default RefferalLetterCapture
