import { Form, message } from 'antd'
import { useState } from 'react'

const useCheckin = ({ data, checkinQuestions, setCheckinQuestions, setIsModalOpen }) => {
  const [isNewQuestion, setIsNewQuestion] = useState()
  const [isPreview, setIsPreview] = useState()
  const [tmpQuestion, setTmpQuestion] = useState({ text: null, isNew: null })
  const [recordData, setRecordData] = useState()

  const [checkinForm] = Form.useForm()
  const checkinTemplate = {
    doctorsText: null,
    enableOptions: false,
    options: [],
    optionsHeading: null,
    otherOptionText: null,
    questionText: null,
    showOther: false
  }

  const onFinish = () => {
    const tmpCheckinTemplate = JSON.parse(JSON.stringify(checkinTemplate))
    for (var value in recordData) {
      tmpCheckinTemplate[value] = recordData[value]
    }
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    tmpCheckinQuestions.push(tmpCheckinTemplate)
    setCheckinQuestions(tmpCheckinQuestions)
    checkinForm.resetFields()
    setIsModalOpen(false)
  }

  const handleCheckinChange = (value, index, key) => {
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    tmpCheckinQuestions[index][key] = value
    setCheckinQuestions(tmpCheckinQuestions)
  }

  const handleQuestionDelete = (questionIndex) => {
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    tmpCheckinQuestions.splice(questionIndex, 1)
    setCheckinQuestions(tmpCheckinQuestions)
  }

  const addCheckinOption = (questionIndex) => {
    setTmpQuestion({
      ...tmpQuestion,
      questionIndex: questionIndex,
      isNew: true
    })
    setIsNewQuestion(true)
  }
  const editCheckinOption = (questionIndex, optionIndex) => {
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    setTmpQuestion({
      ...tmpCheckinQuestions[questionIndex].options[optionIndex],
      questionIndex: questionIndex,
      optionIndex: optionIndex,
      isNew: false
    })
    setIsNewQuestion(true)
  }

  const handleCheckinOptionSave = () => {
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    const questionIndex = tmpQuestion.questionIndex
    const optionIndex = tmpQuestion.optionIndex
    delete tmpQuestion.questionIndex
    delete tmpQuestion.optionIndex
    delete tmpQuestion.isNew
    if (!tmpQuestion.text) {
      message.error('Please provide option text')
    } else {
      if (tmpCheckinQuestions[questionIndex].options[optionIndex]) {
        tmpCheckinQuestions[questionIndex].options[optionIndex] = tmpQuestion
        setCheckinQuestions(tmpCheckinQuestions)
        setTmpQuestion({ text: null })
        setIsNewQuestion(false)
      } else {
        tmpCheckinQuestions[questionIndex].options.push(tmpQuestion)
        setCheckinQuestions(tmpCheckinQuestions)
        setTmpQuestion({ text: null })
        setIsNewQuestion(false)
      }
    }
  }

  const handleTmpQuestionChange = (action, value) => {
    const tmpTmpQuestion = JSON.parse(JSON.stringify(tmpQuestion))
    if (action === 'text') {
      tmpTmpQuestion.text = value
    }
    setTmpQuestion(tmpTmpQuestion)
  }

  const deleteCheckinOption = (questionIndex, optionIndex) => {
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    tmpCheckinQuestions[questionIndex].options.splice(optionIndex, 1)
    setCheckinQuestions(tmpCheckinQuestions)
  }
  return {
    isPreview,
    setIsPreview,
    isNewQuestion,
    setIsNewQuestion,
    tmpQuestion,
    setTmpQuestion,
    handleCheckinOptionSave,
    onFinish,
    setRecordData,
    checkinForm,
    handleTmpQuestionChange,
    handleQuestionDelete,
    addCheckinOption,
    editCheckinOption,
    deleteCheckinOption,
    handleCheckinChange
  }
}

export default useCheckin
