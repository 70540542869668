import React, { useEffect, useState } from 'react'
import SystemUsersWrapper from './SystemUsersWrapper'

const SystemUsersContainer = ({ type }) => {
  const [viewType, setViewType] = useState()

  // Mapping object for viewType based on type
  const viewTypeMapping = {
    system: 'System User',
    medical: 'Medical Professional',
    patient: 'Patient'
  }

  useEffect(() => {
    // Set viewType using the mapping object, default to 'System User'
    setViewType(viewTypeMapping[type] || 'System User')
    // eslint-disable-next-line
  }, [type]) // Add 'type' as a dependency to ensure effect runs when 'type' changes

  return <SystemUsersWrapper viewType={viewType} type={type} />
}

export default SystemUsersContainer
