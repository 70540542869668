import Agilite from 'agilite'
import SubscriptionEnums from './enums'
import { handleError } from '../../lib/utils'
import Store from '../../../store'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const getSubscriptions = (qry) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null
      try {
        response = await agilite.Connectors.execute(
          SubscriptionEnums.profileKeys.SUBSCRIPTIONS,
          SubscriptionEnums.routeKeys.READ,
          { filter: JSON.stringify(qry) }
        )

        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const createSubscription = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null
      try {
        response = await agilite.Connectors.execute(
          SubscriptionEnums.profileKeys.SUBSCRIPTIONS,
          SubscriptionEnums.routeKeys.CREATE,
          { data: JSON.stringify(data) }
        )

        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

// Private Functions
export const checkSubscriptions = (key) => {
  const subscription = Store.getState().subscriptions.data.find((sub) => sub.key === key)
  return subscription && subscription.isActive
}
