import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  readMacroTrackerMetaData,
  readMacroTrackerRecipes,
  readMacroTrackers,
  readSavedMacroTrackerMeals
} from '../utils/macro-tracker-utils'
import dayjs from 'dayjs'
import patMacroTrackerReducer from '../utils/reducer'
import { handleError } from '../../../../../../lib/utils'
import { message } from 'antd'

const useMobileTracker = ({ userRef }) => {
  const authState = useSelector((state) => state.auth)

  const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false)
  const [showGraphs, setShowGraphs] = useState(false)
  const [showHealthyAlternatives, setShowHealthyAlternatives] = useState(false)

  const [capturedImage, setCapturedImage] = useState(null)
  const [dateRange, setDateRange] = useState([dayjs().subtract(7, 'days'), dayjs()])
  const [limits, setLimits] = useState(null)
  const [savedMeals, setSavedMeals] = useState([])

  const [isCapturing, setIsCapturing] = useState(false)
  const [trackerDataLoading, setTackerDataLoading] = useState(true)
  const [limitsLoading, setLimitsLoading] = useState(true)
  const [savedMealsLoading, setSavedMealsLoading] = useState(true)
  const [recipesLoading, setRecipesLoading] = useState(true)

  const loading = limitsLoading || savedMealsLoading || trackerDataLoading || recipesLoading

  const dispatch = useDispatch()

  useEffect(() => {
    handleGetMetaData()
    handleGetSavedMeals()
    handleGetTrackerData()
    handleFetchRecipes()
    // eslint-disable-next-line
  }, [dateRange])

  useEffect(() => {
    // Prevent body scroll when image upload modal is open
    if (imageUploadModalOpen || showGraphs || showHealthyAlternatives) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [imageUploadModalOpen, showGraphs, showHealthyAlternatives])

  useEffect(() => {
    if (!imageUploadModalOpen) {
      setIsCapturing(false)
    }
  }, [imageUploadModalOpen])

  const handleGetMetaData = async () => {
    setLimitsLoading(true)
    try {
      const data = await readMacroTrackerMetaData({
        userRef: userRef || authState.agiliteUser._id
      })
      if (data.length > 0) {
        dispatch(patMacroTrackerReducer.actions.setLimits(data[0]))
      }
    } catch (error) {
      console.error(error)
    }
    setLimitsLoading(false)
  }

  const handleGetSavedMeals = async () => {
    setSavedMealsLoading(true)
    try {
      const data = await readSavedMacroTrackerMeals({
        userRef: userRef || authState.agiliteUser._id
      })
      dispatch(patMacroTrackerReducer.actions.setSavedMeals(data))
    } catch (error) {
      console.error(error)
    }
    setSavedMealsLoading(false)
  }

  const handleGetTrackerData = async () => {
    setTackerDataLoading(true)
    try {
      const filter = {
        userRef: userRef || authState.agiliteUser._id,
        createdAt: {
          $gte: dateRange[0].toDate(),
          $lte: dateRange[1].toDate()
        }
      }
      const data = await readMacroTrackers(filter)
      dispatch(patMacroTrackerReducer.actions.setTrackerData(data))
    } catch (error) {
      console.error(error)
    }
    setTackerDataLoading(false)
  }

  const handleFetchRecipes = async () => {
    setRecipesLoading(true)
    try {
      const data = await readMacroTrackerRecipes({
        userRef: userRef ? userRef : authState.agiliteUser._id
      })
      dispatch(patMacroTrackerReducer.actions.setHealthyAlternatives(data))
    } catch (error) {
      message.error(handleError(error))
    }
    setRecipesLoading(false)
  }

  const handleCameraCapture = async (base64Data, file) => {
    setIsCapturing(true)
    setCapturedImage({ base64: base64Data, file })
    setImageUploadModalOpen(true)
  }

  return {
    imageUploadModalOpen,
    setImageUploadModalOpen,
    savedMeals,
    setSavedMeals,
    limits,
    handleGetMetaData,
    capturedImage,
    setCapturedImage,
    showGraphs,
    dateRange,
    setDateRange,
    setShowGraphs,
    setShowHealthyAlternatives,
    showHealthyAlternatives,
    handleCameraCapture,
    isCapturing,
    loading
  }
}

export default useMobileTracker
