import '../style/LoadingOverlay.css'
import metaIcon from '../../../../../assets/images/landing-image.png'
import React, { useState, useEffect } from 'react'
import { hexToRGBA } from '../../../../lib/utils'
import { theme } from 'antd'

const MobMedicalHistoryLoadingOverlay = ({ loading, saveLoading, text = '', icon, animated = false }) => {
  const [displayText, setDisplayText] = useState('')
  const [isTyping, setIsTyping] = useState(true)

  useEffect(() => {
    if (!text) return

    let currentIndex = 0
    let typingInterval

    const startTyping = async () => {
      setIsTyping(true)
      currentIndex = 0
      setDisplayText('')

      if (!animated) {
        setDisplayText(text)
        return
      }

      const typeNextChar = () => {
        if (currentIndex < text.length) {
          setDisplayText(text.substring(0, currentIndex + 1))
          currentIndex++
        } else {
          clearInterval(typingInterval)
          setTimeout(() => {
            setDisplayText('')
            currentIndex = 0
            startTyping()
          }, 1000)
        }
      }

      typingInterval = setInterval(typeNextChar, 75)
    }

    if (loading || saveLoading) {
      startTyping()
    }

    return () => {
      clearInterval(typingInterval)
    }
  }, [text, loading, saveLoading, animated])
  const { token } = theme.useToken()
  return (
    <>
      {(loading || saveLoading) && (
        <div className='loading-overlay' style={{ background: 'linear-gradient(to bottom, #f5f5f5, #ffffff)' }}>
          <div className='loading-content'>
            {icon ? (
              <div
                style={{
                  animation: 'flipHorizontal 2s linear infinite'
                }}
              >
                {icon}
              </div>
            ) : (
              <img
                src={metaIcon}
                alt='meta-icon'
                style={{
                  width: 50,
                  animation: 'flipHorizontal 2s linear infinite'
                }}
              />
            )}
            <span style={{ color: '#000000' }}>
              {displayText}
              <span style={{ color: 'transparent' }}>L</span>
              {/* the L is being used as a control for the height of the component to prevent weird size changes */}
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export default MobMedicalHistoryLoadingOverlay
