import React, { useState } from 'react'
import { Popover, List, Button, Badge, Typography, Empty } from 'antd'
import { BellOutlined, DeleteOutlined } from '@ant-design/icons'
import { useNotifications } from '../context/NotificationsContext'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import styles from '../styles/NotificationsPopover.module.css'

dayjs.extend(relativeTime)

const { Text } = Typography

const NotificationsPopover = () => {
  const [visible, setVisible] = useState(false)
  const {
    notifications,
    unreadCount,
    markAsRead,
    clearNotifications
  } = useNotifications()

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible)
    if (newVisible && unreadCount > 0) {
      markAsRead()
    }
  }

  const content = (
    <div className={styles.notificationsContainer}>
      <div className={styles.notificationsHeader}>
        <Text strong>Notifications ({notifications.length})</Text>
        {notifications.length > 0 && (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => {
              clearNotifications()
              setVisible(false)
            }}
          >
            Clear all
          </Button>
        )}
      </div>
      <div className={styles.notificationsList}>
        <List
          dataSource={notifications}
          locale={{
            emptyText: <Empty description="No notifications" />
          }}
          renderItem={(notification) => (
            <List.Item
              className={`${styles.notificationItem} ${
                notification.unread ? styles.unread : ''
              }`}
            >
              <List.Item.Meta
                title={notification.title}
                description={
                  <div>
                    <div>{notification.description}</div>
                    <div className={styles.notificationTime}>
                      {dayjs(notification.timestamp).fromNow()}
                    </div>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  )

  return (
    <Popover
      content={content}
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      placement="bottomRight"
      overlayClassName={styles.notificationsPopover}
      arrow={false}
      destroyTooltipOnHide
    >
      <div className={styles.notificationBell}>
        <Badge count={unreadCount} offset={[-2, 2]}>
          <BellOutlined style={{ fontSize: '24px' }} />
        </Badge>
      </div>
    </Popover>
  )
}

export default NotificationsPopover 