import { Card, Col, Form, Input, Modal, Row, theme, message, Button, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { useTemplates } from './hooks/useTemplates'
import { useDataPoints } from './hooks/useDataPoints'
import { useTabManagement } from './hooks/useTabManagement'
import { useFormHandling } from './hooks/useFormHandling'
import { useState } from 'react'
import { FORM_GRID_BREAKPOINTS, LIST_GRID_BREAKPOINTS, MODAL_WIDTH } from './utils/constants'
import { getUnfilledCount, formatValidationError } from './utils/helpers'
import { hexToRGBA } from '../../../../lib/utils'
import ModalHeader from './components/ModalHeader'
import TabsHeader from './components/TabsHeader'
import FormSections from './components/FormSections'
import DataPointsList from './components/DataPointsList'
import TemplateList from './components/TemplateList'
import SearchBar from './components/SearchBar'

const { Text } = Typography

// Define steps for template creation
const TEMPLATE_STEPS = {
  NAME: 0,
  VITALS: 1,
  GENERAL: 2,
  POC: 3,
  RAPID: 4,
  REVIEW: 5
}

const ExamDataCapture = ({
  websocketConnection,
  userRef,
  bookingData,
  modalOpen = false,
  setModalOpen,
  handleRefresh,
  hasLabReport = false,
  onAfterSuccess,
  onAfterCancel
}) => {
  const { token } = theme.useToken()
  const { agiliteUser } = useSelector((state) => state.auth)
  const [dataForm] = Form.useForm()
  const [currentTemplateStep, setCurrentTemplateStep] = useState(TEMPLATE_STEPS.NAME)

  const { data: dataTemplates } = useSelector((state) => state.examTemplates)

  const {
    selectedDataPoints,
    availableDataPoints,
    selectedKeys,
    toggleDataPoint,
    setSelectedDataPoints,
    setSelectedKeys
  } = useDataPoints(hasLabReport)

  const {
    activeTemplate,
    isAddingTemplate,
    newTemplateName,
    setActiveTemplate,
    setIsAddingTemplate,
    setNewTemplateName,
    handleSaveNewTemplate,
    handleDeleteTemplate,
    isDeletingTemplate
  } = useTemplates()

  const { activeTab, searchTerm, setActiveTab, setSearchTerm } = useTabManagement()

  const resetForm = () => {
    setSelectedDataPoints([])
    setActiveTemplate(null)
    setIsAddingTemplate(false)
    setNewTemplateName('')
    setSelectedKeys([])
    dataForm.resetFields()
    setActiveTab('templates')
    setCurrentTemplateStep(TEMPLATE_STEPS.NAME)
    setModalOpen(false)
    onAfterCancel?.()
  }

  const { handleDataCapture } = useFormHandling({
    availableDataPoints,
    agiliteUser,
    bookingData,
    userRef,
    websocketConnection,
    handleRefresh,
    onAfterSuccess,
    resetForm
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async () => {
    setIsSubmitting(true)

    try {
      if (isAddingTemplate) {
        const newTemplate = await handleSaveNewTemplate(selectedKeys)
        if (newTemplate) {
          setIsAddingTemplate(false)
          setActiveTemplate(newTemplate)
          setCurrentTemplateStep(TEMPLATE_STEPS.NAME)
          setActiveTab('templates')
        }
      } else {
        await dataForm.validateFields()
        await handleDataCapture(dataForm.getFieldsValue())
      }
    } catch (error) {
      if (error.errorFields) {
        message.error(formatValidationError(error, availableDataPoints))
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  // Handle navigation between template creation steps
  const handleNextStep = () => {
    if (currentTemplateStep === TEMPLATE_STEPS.NAME) {
      setCurrentTemplateStep(TEMPLATE_STEPS.VITALS)
      setActiveTab('vitals')
    } else if (currentTemplateStep === TEMPLATE_STEPS.VITALS) {
      setCurrentTemplateStep(TEMPLATE_STEPS.GENERAL)
      setActiveTab('general')
    } else if (currentTemplateStep === TEMPLATE_STEPS.GENERAL) {
      setCurrentTemplateStep(TEMPLATE_STEPS.POC)
      setActiveTab('poc')
    } else if (currentTemplateStep === TEMPLATE_STEPS.POC) {
      setCurrentTemplateStep(TEMPLATE_STEPS.RAPID)
      setActiveTab('rapid')
    } else if (currentTemplateStep === TEMPLATE_STEPS.RAPID) {
      setCurrentTemplateStep(TEMPLATE_STEPS.REVIEW)
      setActiveTab('preview')
    } else if (currentTemplateStep === TEMPLATE_STEPS.REVIEW) {
      // On the last step, Complete button should save the template
      handleSubmit()
    }
  }

  const handlePreviousStep = () => {
    if (currentTemplateStep === TEMPLATE_STEPS.VITALS) {
      setCurrentTemplateStep(TEMPLATE_STEPS.NAME)
      setActiveTab('templates')
    } else if (currentTemplateStep === TEMPLATE_STEPS.GENERAL) {
      setCurrentTemplateStep(TEMPLATE_STEPS.VITALS)
      setActiveTab('vitals')
    } else if (currentTemplateStep === TEMPLATE_STEPS.POC) {
      setCurrentTemplateStep(TEMPLATE_STEPS.GENERAL)
      setActiveTab('general')
    } else if (currentTemplateStep === TEMPLATE_STEPS.RAPID) {
      setCurrentTemplateStep(TEMPLATE_STEPS.POC)
      setActiveTab('poc')
    } else if (currentTemplateStep === TEMPLATE_STEPS.REVIEW) {
      setCurrentTemplateStep(TEMPLATE_STEPS.RAPID)
      setActiveTab('rapid')
    }
  }

  // Handle back button in template creation
  const handleTemplateBack = () => {
    if (currentTemplateStep === TEMPLATE_STEPS.NAME) {
      // Instead of closing the modal, go back to templates screen
      setIsAddingTemplate(false)
      setNewTemplateName('')
      setActiveTab('templates')
    } else {
      handlePreviousStep()
    }
  }

  // Get the current step title
  const getCurrentStepTitle = () => {
    switch (currentTemplateStep) {
      case TEMPLATE_STEPS.NAME:
        return 'Step 1: Enter Template Name'
      case TEMPLATE_STEPS.VITALS:
        return 'Step 2: Add Vitals'
      case TEMPLATE_STEPS.GENERAL:
        return 'Step 3: Add General Stats'
      case TEMPLATE_STEPS.POC:
        return 'Step 4: Add Point of Care'
      case TEMPLATE_STEPS.RAPID:
        return 'Step 5: Add Rapid Tests'
      case TEMPLATE_STEPS.REVIEW:
        return 'Step 6: Review Template'
      default:
        return ''
    }
  }

  // Get the instruction text for the current step
  const getStepInstructionText = () => {
    switch (currentTemplateStep) {
      case TEMPLATE_STEPS.VITALS:
        return 'Select vital signs to include in your template by clicking the plus/minus icons.'
      case TEMPLATE_STEPS.GENERAL:
        return 'Select general statistics to include in your template by clicking the plus/minus icons.'
      case TEMPLATE_STEPS.POC:
        return 'Select point of care tests to include in your template by clicking the plus/minus icons.'
      case TEMPLATE_STEPS.RAPID:
        return 'Select rapid tests to include in your template by clicking the plus/minus icons.'
      case TEMPLATE_STEPS.REVIEW:
        return 'Review your template selections before completing.'
      default:
        return ''
    }
  }

  return (
    <Modal destroyOnClose closable={false} maskClosable={false} width={MODAL_WIDTH} open={modalOpen} footer={false}>
      <ModalHeader
        isAddingTemplate={isAddingTemplate}
        activeTemplate={activeTemplate}
        resetForm={resetForm}
        token={token}
      />

      <TabsHeader
        activeTab={activeTab}
        onTabChange={(newTab) => {
          setSearchTerm('')
          setActiveTab(newTab)
        }}
        isAddingTemplate={isAddingTemplate}
        isSubmitting={isSubmitting}
        onCancel={resetForm}
        onSubmit={handleSubmit}
        token={token}
        getUnfilledCount={(category) => getUnfilledCount(category, selectedDataPoints, dataForm)}
        newTemplateName={newTemplateName}
        selectedDataPoints={selectedDataPoints}
        form={dataForm}
      />

      <Row gutter={[12, 12]}>
        <Col {...LIST_GRID_BREAKPOINTS}>
          {isAddingTemplate && (
            <div style={{ marginBottom: 16 }}>
              {currentTemplateStep === TEMPLATE_STEPS.NAME && (
                <div style={{ marginBottom: 8, fontWeight: 'bold', color: token.colorPrimary }}>
                  Step 1: Enter Template Name
                </div>
              )}
              
              {currentTemplateStep === TEMPLATE_STEPS.NAME && (
                <Input
                  placeholder='Enter template name'
                  value={newTemplateName}
                  onChange={(e) => setNewTemplateName(e.target.value)}
                  style={{ marginBottom: 10 }}
                />
              )}
              
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
                <Button 
                  onClick={handleTemplateBack}
                  style={{ minWidth: '80px' }}
                >
                  Back
                </Button>
                <div style={{ 
                  fontWeight: 'bold', 
                  color: '#00BCD4', 
                  fontSize: '16px',
                  textAlign: 'center'
                }}>
                  {currentTemplateStep !== TEMPLATE_STEPS.NAME ? getCurrentStepTitle() : ''}
                </div>
                <Button 
                  type="primary" 
                  onClick={handleNextStep}
                  disabled={currentTemplateStep === TEMPLATE_STEPS.NAME && !newTemplateName}
                  loading={currentTemplateStep === TEMPLATE_STEPS.REVIEW && isSubmitting}
                  style={{ 
                    minWidth: '80px',
                    ...(currentTemplateStep === TEMPLATE_STEPS.NAME && !newTemplateName 
                      ? { backgroundColor: 'white', borderColor: '#d9d9d9', color: 'rgba(0, 0, 0, 0.45)' } 
                      : { backgroundColor: '#00BCD4', borderColor: '#00BCD4' })
                  }}
                >
                  {currentTemplateStep === TEMPLATE_STEPS.REVIEW ? 'Complete' : 'Next'}
                </Button>
              </div>
              
              {currentTemplateStep !== TEMPLATE_STEPS.NAME && (
                <div style={{ 
                  padding: '8px 0', 
                  color: '#666',
                  fontSize: '14px',
                  marginTop: 16,
                  marginBottom: 8
                }}>
                  {getStepInstructionText()}
                </div>
              )}
            </div>
          )}
          <Card
            size='small'
            styles={{ body: { minHeight: 300, maxHeight: '65vh', overflow: 'auto' } }}
            style={{ position: 'relative', overflow: 'hidden' }}
          >
            {/* Only show search bar when not in review step and not in name step */}
            {!(isAddingTemplate && (currentTemplateStep === TEMPLATE_STEPS.REVIEW || currentTemplateStep === TEMPLATE_STEPS.NAME)) && (
              <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} token={token} />
            )}
            
            {activeTab === 'templates' && !isAddingTemplate ? (
              <TemplateList
                dataTemplates={dataTemplates}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                activeTemplate={activeTemplate}
                token={token}
                onAddTemplate={() => {
                  setIsAddingTemplate(true)
                  setActiveTemplate(null)
                  setSelectedDataPoints([])
                  setSelectedKeys([])
                  setNewTemplateName('')
                  setActiveTab('templates')
                  setCurrentTemplateStep(TEMPLATE_STEPS.NAME)
                }}
                onSelectTemplate={(template) => {
                  setActiveTemplate(template)
                  // Convert template keys to selectedDataPoints format
                  const templateDataPoints = template.keys
                    .map((key) => {
                      const dataPoint = availableDataPoints.find((dp) => dp.key === key)
                      return dataPoint ? JSON.stringify(dataPoint) : null
                    })
                    .filter(Boolean)

                  setSelectedDataPoints(templateDataPoints)
                  setSelectedKeys(template.keys)
                }}
                onSelectCustomTemplate={() => {
                  setActiveTemplate(null)
                  setSelectedDataPoints([])
                  setSelectedKeys([])
                }}
                onDeleteTemplate={handleDeleteTemplate}
                isDeletingTemplate={isDeletingTemplate}
              />
            ) : (
              <div style={{ marginTop: 36 }}>
                {/* Hide the content area completely when in Step 1 (NAME) */}
                {isAddingTemplate && currentTemplateStep === TEMPLATE_STEPS.NAME ? (
                  <div></div> // Empty div to hide content
                ) : currentTemplateStep === TEMPLATE_STEPS.REVIEW ? (
                  <div style={{ padding: '0' }}>
                    <div style={{ 
                      borderBottom: `2px solid ${token.colorPrimary}`,
                      paddingBottom: '10px',
                      marginBottom: '12px'
                    }}>
                      <h3 style={{ 
                        fontSize: '18px', 
                        fontWeight: 600, 
                        color: token.colorPrimary,
                        margin: '0 0 4px 0'
                      }}>
                        Template Summary
                      </h3>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ fontWeight: 500, width: '100px' }}>Name:</span>
                          <span style={{ 
                            fontWeight: 600, 
                            color: token.colorTextSecondary,
                            fontSize: '15px'
                          }}>{newTemplateName}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ fontWeight: 500, width: '100px' }}>Data Points:</span>
                          <span style={{ 
                            fontWeight: 600, 
                            color: '#FF4D4F',
                            background: hexToRGBA('#FF4D4F', 0.1),
                            padding: '2px 10px',
                            borderRadius: '12px',
                            fontSize: '14px'
                          }}>{selectedKeys.length}</span>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                      {/* Vital Signs Section */}
                      <div style={{ 
                        background: hexToRGBA('#FF6B6B', 0.05), 
                        borderRadius: '8px',
                        padding: '12px 15px',
                        border: '1px solid #FFE5E5'
                      }}>
                        <h4 style={{ 
                          margin: '0 0 10px 0', 
                          color: '#FF6B6B',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          fontSize: '16px'
                        }}>
                          <span style={{ 
                            background: '#FF6B6B', 
                            width: '10px', 
                            height: '10px', 
                            borderRadius: '50%',
                            display: 'inline-block'
                          }}></span>
                          Vital Signs
                        </h4>
                        {selectedKeys.filter(key => {
                          const dataPoint = availableDataPoints.find(dp => dp.key === key)
                          return dataPoint && dataPoint.category === 'vitals'
                        }).length > 0 ? (
                          <ul style={{ 
                            margin: '0', 
                            paddingLeft: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '6px'
                          }}>
                            {selectedKeys.filter(key => {
                              const dataPoint = availableDataPoints.find(dp => dp.key === key)
                              return dataPoint && dataPoint.category === 'vitals'
                            }).map(key => {
                              const dataPoint = availableDataPoints.find(dp => dp.key === key)
                              return (
                                <li key={key} style={{ color: '#555' }}>
                                  {dataPoint?.label || key}
                                </li>
                              )
                            })}
                          </ul>
                        ) : (
                          <p style={{ margin: '0', color: '#888', fontStyle: 'italic' }}>
                            No vital signs selected
                          </p>
                        )}
                      </div>
                      
                      {/* General Stats Section */}
                      <div style={{ 
                        background: hexToRGBA('#4ECDC4', 0.05), 
                        borderRadius: '8px',
                        padding: '12px 15px',
                        border: '1px solid #E0F7F6'
                      }}>
                        <h4 style={{ 
                          margin: '0 0 10px 0', 
                          color: '#4ECDC4',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          fontSize: '16px'
                        }}>
                          <span style={{ 
                            background: '#4ECDC4', 
                            width: '10px', 
                            height: '10px', 
                            borderRadius: '50%',
                            display: 'inline-block'
                          }}></span>
                          General Stats
                        </h4>
                        {selectedKeys.filter(key => {
                          const dataPoint = availableDataPoints.find(dp => dp.key === key)
                          return dataPoint && dataPoint.category === 'general'
                        }).length > 0 ? (
                          <ul style={{ 
                            margin: '0', 
                            paddingLeft: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '6px'
                          }}>
                            {selectedKeys.filter(key => {
                              const dataPoint = availableDataPoints.find(dp => dp.key === key)
                              return dataPoint && dataPoint.category === 'general'
                            }).map(key => {
                              const dataPoint = availableDataPoints.find(dp => dp.key === key)
                              return (
                                <li key={key} style={{ color: '#555' }}>
                                  {dataPoint?.label || key}
                                </li>
                              )
                            })}
                          </ul>
                        ) : (
                          <p style={{ margin: '0', color: '#888', fontStyle: 'italic' }}>
                            No general stats selected
                          </p>
                        )}
                      </div>

                      {/* Point of Care Section */}
                      <div style={{ 
                        background: hexToRGBA('#FFD166', 0.05), 
                        borderRadius: '8px',
                        padding: '12px 15px',
                        border: '1px solid #FFF5E0'
                      }}>
                        <h4 style={{ 
                          margin: '0 0 10px 0', 
                          color: '#F0B429',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          fontSize: '16px'
                        }}>
                          <span style={{ 
                            background: '#F0B429', 
                            width: '10px', 
                            height: '10px', 
                            borderRadius: '50%',
                            display: 'inline-block'
                          }}></span>
                          Point of Care
                        </h4>
                        {selectedKeys.filter(key => {
                          const dataPoint = availableDataPoints.find(dp => dp.key === key)
                          return dataPoint && dataPoint.category === 'poc'
                        }).length > 0 ? (
                          <ul style={{ 
                            margin: '0', 
                            paddingLeft: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '6px'
                          }}>
                            {selectedKeys.filter(key => {
                              const dataPoint = availableDataPoints.find(dp => dp.key === key)
                              return dataPoint && dataPoint.category === 'poc'
                            }).map(key => {
                              const dataPoint = availableDataPoints.find(dp => dp.key === key)
                              return (
                                <li key={key} style={{ color: '#555' }}>
                                  {dataPoint?.label || key}
                                </li>
                              )
                            })}
                          </ul>
                        ) : (
                          <p style={{ margin: '0', color: '#888', fontStyle: 'italic' }}>
                            No point of care tests selected
                          </p>
                        )}
                      </div>

                      {/* Rapid Tests Section */}
                      <div style={{ 
                        background: hexToRGBA('#6A0572', 0.05), 
                        borderRadius: '8px',
                        padding: '12px 15px',
                        border: '1px solid #F0E0F7'
                      }}>
                        <h4 style={{ 
                          margin: '0 0 10px 0', 
                          color: '#6A0572',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          fontSize: '16px'
                        }}>
                          <span style={{ 
                            background: '#6A0572', 
                            width: '10px', 
                            height: '10px', 
                            borderRadius: '50%',
                            display: 'inline-block'
                          }}></span>
                          Rapid Tests
                        </h4>
                        {selectedKeys.filter(key => {
                          const dataPoint = availableDataPoints.find(dp => dp.key === key)
                          return dataPoint && dataPoint.category === 'rapid'
                        }).length > 0 ? (
                          <ul style={{ 
                            margin: '0', 
                            paddingLeft: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '6px'
                          }}>
                            {selectedKeys.filter(key => {
                              const dataPoint = availableDataPoints.find(dp => dp.key === key)
                              return dataPoint && dataPoint.category === 'rapid'
                            }).map(key => {
                              const dataPoint = availableDataPoints.find(dp => dp.key === key)
                              return (
                                <li key={key} style={{ color: '#555' }}>
                                  {dataPoint?.label || key}
                                </li>
                              )
                            })}
                          </ul>
                        ) : (
                          <p style={{ margin: '0', color: '#888', fontStyle: 'italic' }}>
                            No rapid tests selected
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <DataPointsList
                    filteredPoints={availableDataPoints
                      .filter((point) =>
                        hasLabReport
                          ? point.hasLabReport &&
                            (point.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              point.key.toLowerCase().includes(searchTerm.toLowerCase())) &&
                            point.category.toLowerCase() === activeTab.toLowerCase()
                          : (point.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              point.key.toLowerCase().includes(searchTerm.toLowerCase())) &&
                            point.category.toLowerCase() === activeTab.toLowerCase()
                      )
                      .sort((a, b) => {
                        // First sort by selection status
                        const aSelected = selectedDataPoints.includes(JSON.stringify(a))
                        const bSelected = selectedDataPoints.includes(JSON.stringify(b))
                        if (aSelected !== bSelected) {
                          return bSelected ? 1 : -1 // Selected items first
                        }
                        // Then sort alphabetically by label
                        return a.label.localeCompare(b.label)
                      })}
                    selectedDataPoints={selectedDataPoints}
                    onToggleDataPoint={toggleDataPoint}
                    token={token}
                    isAddingTemplate={isAddingTemplate}
                    activeTab={activeTab}
                  />
                )}
              </div>
            )}
          </Card>
        </Col>
        <Col {...FORM_GRID_BREAKPOINTS}>
          <FormSections
            selectedDataPoints={selectedDataPoints}
            isAddingTemplate={isAddingTemplate}
            activeTab={activeTab === 'preview' ? 'templates' : activeTab} // Show all items in review step
            onSubmit={handleDataCapture}
            form={dataForm}
            filterCategories={null} // Don't filter categories to show all items
            categoryOrder={['vitals', 'general', 'poc', 'rapid']} // Ensure POC comes before Rapid
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default ExamDataCapture
