import ObjectId from 'bson-objectid'
import { Col, Input, InputNumber, Row, Select, Spin, Table, message, theme } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { handleError } from '../../../lib/utils'
import CustomRow from '../../../reusable-components/CustomRow'
import {
  MedPraxICD10Search,
  MedPraxMaterialSearch,
  MedPraxProcedureSearch,
  MedPraxProductSearch
} from '../../../Scripting/utils/utils'
import { debounce } from 'lodash'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import {
  createBillingRecord,
  deleteBillingRecord,
  getConsultationTypes,
  getProcedurePrice,
  getProductPrice,
  updateBillingRecord
} from '../../utils/utils'
import { isReception } from '../../../lib/profile-utils'
import { useSelector } from 'react-redux'
import { getBillingProfile } from '../../../Admin/billing-profile/utils'

const BillingTool = ({
  itemSelection,
  setItemSelection,
  readOnly = false,
  bookingData,
  isMacroForm = false,
  billingRecordsLoading,
  billingWebSocket,
  isInvoiceForm = false,
  isInvoicePaymentForm = false,
  hideAdd = false
}) => {
  const authState = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [diagnosisData, setDiagnosisData] = useState([])
  const [consultationTypes, setConsultationTypes] = useState([])
  const [priceLoading, setPriceLoading] = useState({})
  const [billingProfile, setBillingProfile] = useState({})

  const { token } = theme.useToken()

  useEffect(() => {
    handleGetBillingProfile()
  }, [])

  useEffect(() => {
    if (!readOnly && !hideAdd && !billingRecordsLoading) {
      const tmpData = [...itemSelection]

      tmpData.push({
        type: undefined,
        code: undefined,
        name: undefined,
        qty: 0,
        icd10: undefined,
        medicalAid: '0',
        patient: '0',
        total: '0'
      })
      setItemSelection(tmpData)
    }

    handleGetConsultationTypes()
    // eslint-disable-next-line
  }, [billingRecordsLoading])

  const handleGetBillingProfile = async () => {
    try {
      const response = await getBillingProfile()
      setBillingProfile(response)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleGetConsultationTypes = async () => {
    let tmpData = []

    try {
      tmpData = await getConsultationTypes()
      setConsultationTypes(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const getDiagnosis = async (query) => {
    let tmpData = []

    setLoading(true)

    try {
      tmpData = await MedPraxICD10Search(query)
      setDiagnosisData(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const getData = async (query, type, filters) => {
    let tmpData = []

    setLoading(true)

    try {
      if (query) {
        switch (type) {
          case 'Procedure':
            tmpData = await MedPraxProcedureSearch(query, null, null, filters)
            break
          case 'Material':
            tmpData = await MedPraxMaterialSearch(query, null, null, filters)
            break
          case 'Medicine':
            tmpData = await MedPraxProductSearch(query, null, null, filters)
            break
          case 'Consultation':
            tmpData = await MedPraxProcedureSearch(query, null, null, filters, 'And')
            break
        }

        setSearchData(tmpData)
      } else {
        setSearchData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleSelectChange = (props) => {
    const tmpData = [...itemSelection]
    const tmpValue = JSON.parse(props.value)
    let tmpRecord = null

    tmpData[props.index] = {
      ...tmpData[props.index],
      type: tmpValue.type,
      code: tmpValue.code,
      name: tmpValue.name ? tmpValue.name : tmpValue.description,
      medicalAid: '0',
      patient: '0',
      total: '0',
      qty: 1,
      packSize: tmpValue.standardPacksize,
      nappiCode: tmpValue.nappiCode
    }

    tmpRecord = { ...props.record, ...tmpData[props.index] }

    tmpData.push({
      type: undefined,
      code: undefined,
      name: undefined,
      qty: 0,
      icd10: undefined,
      medicalAid: '0',
      patient: '0',
      total: '0',
      packSize: undefined,
      nappiCode: undefined
    })

    setItemSelection(tmpData)

    if (tmpData[props.index].type === 'Medicine' || tmpData[props.index].type === 'Material') {
      handleGetPrice(props.index, tmpData, tmpRecord)
    }

    if (tmpData[props.index].type === 'Procedure' || tmpData[props.index].type === 'Consultation') {
      handleGetProcedurePrice(props.index, tmpData, tmpRecord)
    }

    handleUpdateBillingRecord(tmpRecord)

    clearSearch()
  }

  const handleGetPrice = async (index, tmpData, tmpRecord) => {
    let priceResponse = null

    setPriceLoading({ ...priceLoading, [index]: true })

    try {
      priceResponse = await getProductPrice(
        tmpData[index].type.toLowerCase(),
        tmpData[index].code,
        bookingData.patientRecord.medicalAid.planOptionCode || '95C'
      )

      tmpData[index].medicalAid = (priceResponse.price - priceResponse.patientLiable).toFixed(2)
      tmpData[index].patient = priceResponse.patientLiable
      tmpData[index].total = priceResponse.price

      setItemSelection(tmpData)

      handleUpdateBillingRecord({ ...tmpRecord, ...tmpData[index] })
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPriceLoading({ ...priceLoading, [index]: false })
  }

  const handleGetProcedurePrice = async (index, tmpData, tmpRecord) => {
    let priceResponse = null
    let isContract = false

    setPriceLoading({ ...priceLoading, [index]: true })

    try {
      if (billingProfile.schemeContracts.includes(bookingData.patientRecord.medicalAid.schemeCode)) {
        isContract = true
      }

      priceResponse = await getProcedurePrice(
        tmpData[index].code,
        bookingData.patientRecord.medicalAid.planOptionCode || '95C',
        isContract,
        billingProfile
      )

      tmpData[index].medicalAid = priceResponse.price
      tmpData[index].patient = 0
      tmpData[index].total = priceResponse.price

      setItemSelection(tmpData)

      handleUpdateBillingRecord({ ...tmpRecord, ...tmpData[index] })
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPriceLoading({ ...priceLoading, [index]: false })
  }

  const clearSearch = () => {
    setSearchData([...consultationTypes])
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query, type, filters) => {
      if (query) {
        getData(query, type, filters)
      } else {
        setLoading(false)
      }
    }, 1000),
    []
  )

  // eslint-disable-next-line
  const debouncedICD10Filter = useCallback(
    debounce((query) => {
      if (query) {
        getDiagnosis(query)
      } else {
        setLoading(false)
      }
    }, 1000),
    []
  )

  const handleCreateBillingRecord = async (props) => {
    try {
      const tmpData = [...itemSelection]
      const tmpRecord = { _id: ObjectId().toHexString(), ...tmpData[props.index] }

      if (!isMacroForm) {
        tmpRecord.bookingRef = bookingData._id
      }

      tmpRecord.type = props.value
      tmpRecord.icd10 = bookingData?.diagnosis ? bookingData.diagnosis : []

      tmpData[props.index] = tmpRecord
      setItemSelection(tmpData)

      setSearchData([])

      // Without await cause client doesn't need to wait
      if (!isMacroForm) {
        billingWebSocket.socket.send(
          JSON.stringify({ bookingRef: bookingData._id, userRef: authState.agiliteUser._id })
        )
        createBillingRecord(tmpRecord)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleUpdateBillingRecord = async (record) => {
    try {
      // Without await cause client doesn't need to wait
      if (!isMacroForm) {
        billingWebSocket.socket.send(
          JSON.stringify({ bookingRef: bookingData._id, userRef: authState.agiliteUser._id })
        )
        updateBillingRecord(record._id, record)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleDeleteBillingRecord = async (record) => {
    try {
      // Without await cause client doesn't need to wait
      if (!isMacroForm) {
        billingWebSocket.socket.send(
          JSON.stringify({ bookingRef: bookingData._id, userRef: authState.agiliteUser._id })
        )
        deleteBillingRecord(record._id)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  return (
    <CustomRow gutter={[0, 0]}>
      <Col span={24}>
        <Table
          loading={billingRecordsLoading}
          pagination={false}
          size='small'
          locale={{ emptyText: 'No billing items added' }}
          dataSource={[...itemSelection]}
          footer={() => {
            return (
              <Table
                showHeader={false}
                size='small'
                bordered={false}
                pagination={false}
                columns={[
                  { width: '10%' },
                  { width: '10%' },
                  {},
                  { width: '5%' },
                  { width: '10%' },
                  {
                    width: '5%',
                    render: () => (
                      <center>
                        <b>Totals</b>
                      </center>
                    )
                  },
                  {
                    width: '7%',
                    dataIndex: 'total',
                    render: (text) => <Input size='small' value={text} readOnly />
                  },
                  {
                    width: '7%',
                    dataIndex: 'medicalAid',
                    render: (text) => <Input size='small' value={text} readOnly />
                  },
                  {
                    width: '7%',
                    dataIndex: 'patient',
                    render: (text) => <Input size='small' value={text} readOnly />
                  },
                  { width: '5%', hidden: isInvoicePaymentForm }
                ]}
                dataSource={[
                  {
                    total: itemSelection.reduce((a, b) => a + parseFloat(b.total), 0).toFixed(2),
                    medicalAid: itemSelection.reduce((a, b) => a + parseFloat(b.medicalAid), 0).toFixed(2),
                    patient: itemSelection.reduce((a, b) => a + parseFloat(b.patient), 0).toFixed(2)
                  }
                ]}
              />
            )
          }}
          columns={[
            {
              title: 'Type',
              dataIndex: 'type',
              key: 'type',
              filters: [
                { text: 'Consultation', value: 'Consultation' },
                { text: 'Procedure', value: 'Procedure' },
                { text: 'Medicine', value: 'Medicine' },
                { text: 'Material', value: 'Material' }
              ],
              onFilter: (value, record) => record.type === value || record.type === undefined,
              render: (text, record, index) => {
                if (record.type) {
                  return (
                    <Input
                      value={text}
                      style={{
                        width: '100%',
                        backgroundColor:
                          record.type === 'Material'
                            ? '#0288d1'
                            : record.type === 'Medicine'
                            ? '#03a9f4'
                            : record.type === 'Consultation'
                            ? '#ff4081'
                            : '#f44336',

                        color: '#fff'
                      }}
                      readOnly
                    />
                  )
                } else {
                  return (
                    <Select
                      showSearch
                      placeholder={<span>Type</span>}
                      value={text}
                      style={{
                        width: '100%'
                      }}
                      dropdownStyle={{ width: 300 }}
                      onChange={(value) => {
                        handleCreateBillingRecord({ index, value })
                      }}
                    >
                      <Select.Option value='Consultation'>Consultation</Select.Option>
                      <Select.Option value='Procedure'>Procedure</Select.Option>
                      <Select.Option value='Medicine'>Medicine</Select.Option>
                      <Select.Option value='Material'>Material</Select.Option>
                    </Select>
                  )
                }
              },
              width: '10%'
            },
            {
              title: 'Code',
              dataIndex: 'code',
              key: 'code',
              render: (text, record, index) => {
                if (record.code) {
                  return (
                    <Input
                      value={text}
                      style={{
                        width: '100%'
                      }}
                      readOnly
                    />
                  )
                } else {
                  return (
                    <Select
                      showSearch
                      disabled={!record.type}
                      placeholder={!record.type ? <span>Select a Type...</span> : <span>Code</span>}
                      value={undefined}
                      style={{ width: '100%' }}
                      dropdownStyle={{ minWidth: 500 }}
                      onChange={(value) => {
                        handleSelectChange({ value, index, type: record.type, record })
                      }}
                      onSearch={(value) => {
                        setLoading(true)
                        debouncedFilter(
                          value,
                          record.type,
                          record.type === 'Procedure'
                            ? [
                                {
                                  propertyName: 'Code',
                                  operation: 'startsWith',
                                  value: value
                                }
                              ]
                            : record.type === 'Consultation'
                            ? [
                                {
                                  propertyName: 'Code',
                                  operation: 'startsWith',
                                  value: value
                                },
                                {
                                  propertyName: 'Description',
                                  operation: 'contains',
                                  value: 'consultation'
                                }
                              ]
                            : [
                                {
                                  propertyName: 'NappiCode',
                                  operation: 'startsWith',
                                  value: value
                                }
                              ]
                        )
                      }}
                      options={
                        !loading
                          ? searchData.map((i) => {
                              return { label: i.code, value: JSON.stringify({ ...i, type: record.type }) }
                            })
                          : []
                      }
                      optionRender={(item) => {
                        return (
                          <Row>
                            <Col span={24}>
                              <Row>
                                <Col span={24}>
                                  {record.type === 'Medicine' || record.type === 'Material'
                                    ? JSON.parse(item.data.value).nappiCode
                                    : JSON.parse(item.data.value).code}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <small>
                                    {JSON.parse(item.data.value).name
                                      ? JSON.parse(item.data.value).name
                                      : JSON.parse(item.data.value).description}
                                  </small>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )
                      }}
                      notFoundContent={loading ? <AgiliteSkeleton skActive spinnerTip='Search...' /> : undefined}
                    />
                  )
                }
              },
              width: '10%'
            },
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              render: (text, record, index) => {
                if (record.name) {
                  return <Input value={text} style={{ width: '100%' }} readOnly />
                } else {
                  return (
                    <Select
                      showSearch
                      disabled={!record.type}
                      placeholder={!record.type ? <span>Select a Type...</span> : <span>Name</span>}
                      value={undefined}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        handleSelectChange({ value, index, type: record.type })
                      }}
                      onSearch={(value) => {
                        setLoading(true)
                        debouncedFilter(
                          value,
                          record.type,
                          record.type === 'Procedure'
                            ? [
                                {
                                  propertyName: 'Description',
                                  operation: 'startsWith',
                                  value: value
                                },
                                {
                                  propertyName: 'Description',
                                  operation: 'contains',
                                  value: value
                                }
                              ]
                            : record.type === 'Consultation'
                            ? [
                                {
                                  propertyName: 'Description',
                                  operation: 'startsWith',
                                  value: value
                                },
                                {
                                  propertyName: 'Description',
                                  operation: 'contains',
                                  value: 'consultation'
                                }
                              ]
                            : [
                                {
                                  propertyName: 'Name',
                                  operation: 'startsWith',
                                  value: value
                                },
                                {
                                  propertyName: 'Name',
                                  operation: 'contains',
                                  value: value
                                }
                              ]
                        )
                      }}
                      options={
                        !loading
                          ? searchData.map((i) => {
                              return {
                                label: i.name ? i.name : i.description,
                                value: JSON.stringify({ ...i, type: record.type })
                              }
                            })
                          : []
                      }
                      optionRender={(item) => {
                        return (
                          <Row>
                            <Col span={24}>
                              <Row>
                                <Col span={24}>
                                  {JSON.parse(item.data.value).name
                                    ? JSON.parse(item.data.value).name
                                    : JSON.parse(item.data.value).description}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <small>
                                    {record.type === 'Medicine' || record.type === 'Material'
                                      ? JSON.parse(item.data.value).nappiCode
                                      : JSON.parse(item.data.value).code}
                                  </small>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )
                        // }
                      }}
                      notFoundContent={loading ? <AgiliteSkeleton skActive spinnerTip='Search...' /> : undefined}
                    />
                  )
                }
              }
            },

            {
              title: 'Qty',
              dataIndex: 'qty',
              key: 'qty',
              render: (text, record, index) => {
                return (
                  <InputNumber
                    disabled={!record.type || readOnly}
                    value={text}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      const tmpData = [...itemSelection]
                      tmpData[index].qty = value
                      setItemSelection(tmpData)

                      handleUpdateBillingRecord(record)
                    }}
                  />
                )
              },
              width: '5%',
              hidden: isInvoicePaymentForm
            },
            {
              title: 'ICD10',
              dataIndex: 'icd10',
              key: 'icd10',
              render: (text, record, index) => {
                return (
                  <Select
                    disabled={!record.type || readOnly}
                    showSearch
                    placeholder={!record.type ? <span>Select a Type...</span> : <span>ICD10</span>}
                    value={text}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      const tmpData = [...itemSelection]
                      tmpData[index].icd10 = value
                      setItemSelection(tmpData)

                      handleUpdateBillingRecord(record)
                    }}
                    onSearch={(value) => {
                      setLoading(true)
                      debouncedICD10Filter(value)
                    }}
                    mode='multiple'
                    filterOption={false}
                    options={
                      !loading
                        ? diagnosisData.map((i) => {
                            return {
                              label: i.code,
                              value: i.code,
                              data: JSON.stringify(i)
                            }
                          })
                        : []
                    }
                    optionRender={(item) => {
                      return (
                        <Row>
                          <Col span={24}>
                            <Row>
                              <Col span={24}>{JSON.parse(item.data.data).code}</Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <small>{JSON.parse(item.data.data).description}</small>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )
                    }}
                    dropdownStyle={{ minWidth: 500 }}
                    notFoundContent={loading ? <AgiliteSkeleton skActive spinnerTip='Search...' /> : undefined}
                  />
                )
              },
              width: '10%',
              hidden: isInvoicePaymentForm
            },
            {
              title: 'Pack Size',
              dataIndex: 'packSize',
              key: 'packSize',
              render: (text, record, index) => {
                return <Input readOnly value={text} style={{ width: '100%' }} />
              },
              width: '5%',
              hidden: isInvoicePaymentForm
            },
            {
              title: 'Total',
              dataIndex: 'total',
              key: 'total',
              render: (text, record, index) => {
                if (priceLoading[index]) {
                  return (
                    <center>
                      <Spin />
                    </center>
                  )
                } else {
                  if (
                    record.type === 'Medicine' ||
                    record.type === 'Material' ||
                    record.type === 'Procedure' ||
                    record.type === 'Consultation'
                  ) {
                    return (
                      <Input
                        size='small'
                        value={(record.qty * parseFloat(text && text !== '' ? text : '0')).toFixed(2)}
                        style={{ width: '100%' }}
                        readOnly
                      />
                    )
                  } else {
                    return null
                  }
                }
              },
              width: '7%'
            },
            {
              title: 'Medical Aid',
              dataIndex: 'medicalAid',
              key: 'medicalAid',
              render: (text, record, index) => {
                if (priceLoading[index]) {
                  return (
                    <center>
                      <Spin />
                    </center>
                  )
                } else {
                  if (
                    record.type === 'Medicine' ||
                    record.type === 'Material' ||
                    record.type === 'Procedure' ||
                    record.type === 'Consultation'
                  ) {
                    return (
                      <Input
                        size='small'
                        value={(record.qty * parseFloat(text && text !== '' ? text : '0')).toFixed(2)}
                        style={{ width: '100%' }}
                        readOnly
                      />
                    )
                  } else {
                    return null
                  }
                }
              },
              width: '7%'
            },
            {
              title: 'Patient',
              dataIndex: 'patient',
              key: 'patient',
              render: (text, record, index) => {
                if (priceLoading[index]) {
                  return (
                    <center>
                      <Spin />
                    </center>
                  )
                } else {
                  if (
                    record.type === 'Medicine' ||
                    record.type === 'Material' ||
                    record.type === 'Procedure' ||
                    record.type === 'Consultation'
                  ) {
                    return (
                      <Input
                        size='small'
                        value={(record.qty * parseFloat(text && text !== '' ? text : '0')).toFixed(2)}
                        style={{ width: '100%' }}
                        readOnly
                      />
                    )
                  } else {
                    return null
                  }
                }
              },
              width: '7%'
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (text, record, index) => {
                if (record.code && record.type) {
                  if (!readOnly) {
                    return (
                      <Row justify='space-around'>
                        <Col>
                          <FontAwesomeIcon
                            style={{
                              color: token.colorError
                            }}
                            icon={faTrash}
                            onClick={() => {
                              const tmpData = [...itemSelection]
                              tmpData.splice(index, 1)
                              setItemSelection(tmpData)
                              clearSearch()

                              handleDeleteBillingRecord(record)
                            }}
                          />
                        </Col>
                      </Row>
                    )
                  }
                } else {
                  return (
                    <Row justify='space-around'>
                      <Col>
                        <FontAwesomeIcon
                          style={{
                            color: token.colorError
                          }}
                          icon={faTrash}
                          onClick={() => {
                            const tmpData = [...itemSelection]
                            tmpData[index] = {
                              type: undefined,
                              code: undefined,
                              name: undefined,
                              medicalAid: '0',
                              patient: '0',
                              total: '0',
                              qty: 0,
                              icd10: undefined
                            }
                            setItemSelection(tmpData)
                            clearSearch()

                            if (record._id) {
                              handleDeleteBillingRecord(record)
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  )
                }
              },
              width: '5%',
              hidden: isInvoicePaymentForm
            }
          ]}
        />
        {isReception() && !isInvoiceForm ? (
          <center style={{ margin: 10, color: 'red' }}>PLEASE REMEMBER TO ADD A CONSULTATION</center>
        ) : null}
      </Col>
    </CustomRow>
  )
}

export default BillingTool
