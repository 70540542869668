import {
  faBookMedical,
  faCancel,
  faCircleCheck,
  faCircleXmark,
  faEye,
  faFilePdf,
  faPlus,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Row, Space } from 'antd'
import dayjs from 'dayjs'

export const VisitComplianceTableTemplates = (token, hideCompliance, setAddingVisit, currentPatient, setViewingPDF) => {
  return {
    columnTemplate: [
      {
        title: 'Tracker',
        dataIndex: 'label',
        key: 'label'
      },
      {
        title: 'Consults',
        render: (record) => {
          let visits = currentPatient?.visitTracking.filter((visit) => visit.visitKey === record.key)
          return (
            <Space direction='vertical'>
              {visits?.map((visit) => (
                <Space>
                  {dayjs(visit.visitDate).format('DD MMM YYYY')} <FontAwesomeIcon icon={faXmark} color='red' />
                </Space>
              ))}
              <p
                style={{ color: token.colorSuccess }}
                onClick={() => {
                  setAddingVisit(record)
                }}
              >
                Add Visit <FontAwesomeIcon icon={faPlus} />
              </p>
            </Space>
          )
        }
      },
      {
        title: 'Reports',
        render: (record) => {
          let visits = currentPatient?.visitTracking.filter((visit) => visit.visitKey === record.key)
          return (
            <>
              {/* eslint-disable-next-line */}
              {visits.map((visit) => {
                if (visit.files.length > 0) {
                  return (
                    <Row>
                      <Col span={24}>
                        <Row>
                          {visit.files.map((file, index) => {
                            return (
                              <Col span={24}>
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    color: 'inherit',
                                    transition: 'color 0.3s'
                                  }}
                                  onClick={() => {
                                    setViewingPDF(file.file)
                                  }}
                                  onMouseEnter={(e) => (e.currentTarget.style.color = token.colorPrimary)}
                                  onMouseLeave={(e) => (e.currentTarget.style.color = 'inherit')}
                                >
                                  {file.name} <FontAwesomeIcon icon={faFilePdf} />
                                </span>
                              </Col>
                            )
                          })}
                        </Row>
                      </Col>
                    </Row>
                  )
                }
              })}
            </>
          )
        }
      },
      {
        title: 'Compliant',
        dataIndex: 'isCompliant',
        hidden: hideCompliance,
        key: 'isCompliant',
        render: (boolean) => (
          <FontAwesomeIcon
            color={boolean ? token.colorSuccess : token.colorError}
            icon={boolean ? faCircleCheck : faCircleXmark}
          />
        )
      }
    ]
  }
}

export const DataComplianceTableTemplates = (token, hideCompliance) => {
  return {
    columnTemplate: [
      {
        title: 'Tracker',
        dataIndex: 'label',
        key: 'label'
      },
      {
        title: 'Due',
        dataIndex: 'outstandingTests',
        key: 'outstandingTests'
      },
      {
        title: 'Compliant',
        dataIndex: 'isCompliant',
        hidden: hideCompliance,
        key: 'isCompliant',
        render: (boolean) => (
          <FontAwesomeIcon
            color={boolean ? token.colorSuccess : token.colorError}
            icon={boolean ? faCircleCheck : faCircleXmark}
          />
        )
      }
    ]
  }
}

export const PatientCarePlansTemplates = (setCurrentRecord) => {
  return {
    columnTemplate: [
      {
        title: 'Care Plan',
        render: (record) => record.template.name
      },
      {
        title: 'Start Date',
        render: (record) => dayjs(record.audit.createdAt).format('DD MMM YYYY')
      },
      {
        render: (record) => (
          <Space>
            <Button
              onClick={() => {
                setCurrentRecord(record)
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </Space>
        )
      }
    ]
  }
}

export const EnrolledPatientsTemplates = (handleCancelEnrollment, setCurrentPatient) => {
  return {
    columnTemplate: [
      {
        title: 'Patient',
        render: (record) => `${record.patientProfile.firstName} ${record.patientProfile.lastName}`
      },
      {
        title: 'ID Number',
        render: (record) => `${record.patientProfile.idNo}`
      },
      {
        title: 'Enrolled',
        render: (record) => dayjs(record.enrolledOn).format('DD MMM YYYY')
      },
      {
        render: (record) => (
          <Space>
            <Button
              onClick={() => {
                setCurrentPatient(record)
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
            <Button>
              <FontAwesomeIcon icon={faBookMedical} />
            </Button>
            <Button
              onClick={() => {
                handleCancelEnrollment(record)
              }}
            >
              <FontAwesomeIcon icon={faCancel} />
            </Button>
          </Space>
        )
      }
    ]
  }
}
