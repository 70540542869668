import { Col, Space, Tag, theme } from 'antd'
import React from 'react'
import {
  bloodPressureMethod,
  bloodSugarMethod,
  bmiMethod,
  cholesterolMethod,
  heartRateMethod,
  oxygenSatMethod,
  temperatureMethod
} from './utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDroplet, faGauge, faHeartPulse, faLungs, faPerson, faThermometer0 } from '@fortawesome/free-solid-svg-icons'

const DoctorPreview = ({ data, scrollGraph }) => {
  const { token } = theme.useToken()
  const doctorSummariesStyle = {
    justifyContent: 'center',
    width: '100%',
    columnGap: 36,
    padding: 24,
    // border: '1px rgba(0,0,0,0.2) solid',
    boxShadow: '0 0 5px 0 rgba(0,0,0,0.25)',
    borderRadius: 12,
    minWidth: 370
  }
  return (
    <>
      <Col span={24}>
        <h2 style={{ padding: 16, borderRadius: 12, background: token.colorSecondary, color: '#ffffff' }}>
          Most Recent Stats
        </h2>
      </Col>
      <Col span={24}>
        <center>
          <Space
            wrap
            style={{
              justifyContent: 'center'
            }}
          >
            {data[data.length - 1]?.examinationData?.checkinData['bloodPressure'] ? (
              <Space
                wrap
                style={doctorSummariesStyle}
                onClick={() => {
                  scrollGraph('bloodPressure')
                }}
              >
                {Object.keys(
                  bloodPressureMethod([
                    data[data.length - 1].examinationData?.checkinData['bloodPressure'].systolic,
                    data[data.length - 1].examinationData?.checkinData['bloodPressure'].diastolic
                  ])
                ).map((key) => {
                  const object = bloodPressureMethod([
                    data[data.length - 1].examinationData?.checkinData['bloodPressure'].systolic,
                    data[data.length - 1].examinationData?.checkinData['bloodPressure'].diastolic
                  ])[key]
                  if (object.statusTest()) {
                    return (
                      <div
                        style={{
                          width: 90,
                          height: 90,
                          display: 'flex',
                          justifyContent: 'center',
                          borderRadius: 12,
                          alignItems: 'center',
                          background: object.backgroundColor
                        }}
                      >
                        <FontAwesomeIcon color={object.color} fontSize={48} icon={faGauge} />
                      </div>
                    )
                  }
                  return null
                })}

                <div style={{ textAlign: 'center' }}>
                  {Object.keys(
                    bloodPressureMethod([
                      data[data.length - 1].examinationData?.checkinData['bloodPressure'].systolic,
                      data[data.length - 1].examinationData?.checkinData['bloodPressure'].diastolic
                    ])
                  ).map((key) => {
                    const object = bloodPressureMethod([
                      data[data.length - 1].examinationData?.checkinData['bloodPressure'].systolic,
                      data[data.length - 1].examinationData?.checkinData['bloodPressure'].diastolic
                    ])[key]
                    if (object.statusTest()) {
                      return (
                        <>
                          <p style={{ fontSize: 20 }}>
                            {data[data.length - 1].examinationData?.checkinData['bloodPressure'].systolic} /{' '}
                            {data[data.length - 1].examinationData?.checkinData['bloodPressure'].diastolic}
                          </p>
                          <h2>Blood Pressure</h2>
                          <Tag color={object.backgroundColor} style={{ color: '#000000' }}>
                            {object.label}
                          </Tag>
                        </>
                      )
                    }
                    return null
                  })}
                </div>
              </Space>
            ) : undefined}
            {data[data.length - 1]?.examinationData?.checkinData['pulse'] ? (
              <Space
                wrap
                style={doctorSummariesStyle}
                onClick={() => {
                  scrollGraph('pulse')
                }}
              >
                {Object.keys(heartRateMethod(data[data.length - 1].examinationData?.checkinData['pulse'])).map(
                  (key) => {
                    const object = heartRateMethod(data[data.length - 1].examinationData?.checkinData['pulse'])[key]
                    if (object.statusTest) {
                      return (
                        <div
                          style={{
                            width: 90,
                            height: 90,
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: 12,
                            alignItems: 'center',
                            background: object.backgroundColor
                          }}
                        >
                          <FontAwesomeIcon color={object.color} fontSize={48} icon={faHeartPulse} />
                        </div>
                      )
                    }
                    return null
                  }
                )}

                <div style={{ textAlign: 'center' }}>
                  {Object.keys(heartRateMethod(data[data.length - 1].examinationData?.checkinData['pulse'])).map(
                    (key) => {
                      const object = heartRateMethod(data[data.length - 1].examinationData?.checkinData['pulse'])[key]
                      if (object.statusTest) {
                        return (
                          <>
                            <p style={{ fontSize: 20 }}>
                              {data[data.length - 1].examinationData?.checkinData['pulse']}{' '}
                              <span style={{ opacity: 0.5 }}>bpm</span>
                            </p>
                            <h2>Heart Rate</h2>
                            <Tag color={object.backgroundColor} style={{ color: '#000000' }}>
                              {object.label}
                            </Tag>
                          </>
                        )
                      }
                      return null
                    }
                  )}
                </div>
              </Space>
            ) : undefined}
            {data[data.length - 1]?.examinationData?.checkinData['cholesterol'] ? (
              <Space
                wrap
                style={doctorSummariesStyle}
                onClick={() => {
                  scrollGraph('cholesterol')
                }}
              >
                {Object.keys(cholesterolMethod(data[data.length - 1].examinationData?.checkinData['cholesterol'])).map(
                  (key) => {
                    const object = cholesterolMethod(data[data.length - 1].examinationData?.checkinData['cholesterol'])[
                      key
                    ]
                    if (object.statusTest) {
                      return (
                        <div
                          style={{
                            width: 90,
                            height: 90,
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: 12,
                            alignItems: 'center',
                            background: object.backgroundColor
                          }}
                        >
                          <FontAwesomeIcon color={object.color} fontSize={48} icon={faDroplet} />
                        </div>
                      )
                    }
                    return null
                  }
                )}

                <div style={{ textAlign: 'center' }}>
                  {Object.keys(
                    cholesterolMethod(data[data.length - 1].examinationData?.checkinData['cholesterol'])
                  ).map((key) => {
                    const object = cholesterolMethod(data[data.length - 1].examinationData?.checkinData['cholesterol'])[
                      key
                    ]
                    if (object.statusTest) {
                      return (
                        <>
                          <p style={{ fontSize: 20 }}>
                            {data[data.length - 1]?.examinationData?.checkinData['cholesterol']}{' '}
                            <span style={{ opacity: 0.5 }}>mg/dL</span>
                          </p>
                          <h2>Cholesterol</h2>
                          <Tag color={object.backgroundColor} style={{ color: '#000000' }}>
                            {object.title}
                          </Tag>
                        </>
                      )
                    }
                    return null
                  })}
                </div>
              </Space>
            ) : undefined}
            {data[data.length - 1]?.examinationData?.checkinData['sugarLevel'] ? (
              <Space
                wrap
                style={doctorSummariesStyle}
                onClick={() => {
                  scrollGraph('sugarLevel')
                }}
              >
                {Object.keys(bloodSugarMethod(data[data.length - 1].examinationData?.checkinData['sugarLevel'])).map(
                  (key) => {
                    const object = bloodSugarMethod(data[data.length - 1].examinationData?.checkinData['sugarLevel'])[
                      key
                    ]
                    if (object.statusTest) {
                      return (
                        <div
                          style={{
                            width: 90,
                            height: 90,
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: 12,
                            alignItems: 'center',
                            background: object.backgroundColor
                          }}
                        >
                          <FontAwesomeIcon color={object.color} fontSize={48} icon={faDroplet} />
                        </div>
                      )
                    }
                    return null
                  }
                )}

                <div style={{ textAlign: 'center' }}>
                  {Object.keys(bloodSugarMethod(data[data.length - 1].examinationData?.checkinData['sugarLevel'])).map(
                    (key) => {
                      const object = bloodSugarMethod(data[data.length - 1].examinationData?.checkinData['sugarLevel'])[
                        key
                      ]
                      if (object.statusTest) {
                        return (
                          <>
                            <p style={{ fontSize: 20 }}>
                              {data[data.length - 1].examinationData?.checkinData['sugarLevel']}{' '}
                              <span style={{ opacity: 0.5 }}>mg/dL</span>
                            </p>
                            <h2>Sugar Level</h2>
                            <Tag color={object.backgroundColor} style={{ color: '#000000' }}>
                              {object.title}
                            </Tag>
                          </>
                        )
                      }
                      return null
                    }
                  )}
                </div>
              </Space>
            ) : undefined}
            {data[data.length - 1]?.examinationData?.checkinData['bmi'] ? (
              <Space
                wrap
                style={doctorSummariesStyle}
                onClick={() => {
                  scrollGraph('bmi')
                }}
              >
                {Object.keys(bmiMethod(data[data.length - 1].examinationData?.checkinData['bmi'])).map((key) => {
                  const object = bmiMethod(data[data.length - 1].examinationData?.checkinData['bmi'])[key]
                  if (object.statusTest) {
                    return (
                      <div
                        style={{
                          width: 90,
                          height: 90,
                          display: 'flex',
                          justifyContent: 'center',
                          borderRadius: 12,
                          alignItems: 'center',
                          background: object.backgroundColor
                        }}
                      >
                        <FontAwesomeIcon color={object.color} fontSize={48} icon={faPerson} />
                      </div>
                    )
                  }
                  return null
                })}

                <div style={{ textAlign: 'center' }}>
                  {Object.keys(bmiMethod(data[data.length - 1].examinationData?.checkinData['bmi'])).map((key) => {
                    const object = bmiMethod(data[data.length - 1].examinationData?.checkinData['bmi'])[key]
                    if (object.statusTest) {
                      return (
                        <>
                          <p style={{ fontSize: 20 }}>
                            {data[data.length - 1].examinationData?.checkinData['bmi']}{' '}
                            <span style={{ opacity: 0.5 }}>kg/m2</span>
                          </p>
                          <h2>BMI</h2>
                          <Tag color={object.backgroundColor} style={{ color: '#000000' }}>
                            {object.title}
                          </Tag>
                        </>
                      )
                    }
                    return null
                  })}
                </div>
              </Space>
            ) : undefined}
            {data[data.length - 1]?.examinationData?.checkinData['oxygenSaturation'] ? (
              <Space
                wrap
                style={doctorSummariesStyle}
                onClick={() => {
                  scrollGraph('oxygenSaturation')
                }}
              >
                {Object.keys(
                  oxygenSatMethod(data[data.length - 1].examinationData?.checkinData['oxygenSaturation'])
                ).map((key) => {
                  const object = oxygenSatMethod(
                    data[data.length - 1].examinationData?.checkinData['oxygenSaturation']
                  )[key]
                  if (object.statusTest) {
                    return (
                      <div
                        style={{
                          width: 90,
                          height: 90,
                          display: 'flex',
                          justifyContent: 'center',
                          borderRadius: 12,
                          alignItems: 'center',
                          background: object.backgroundColor
                        }}
                      >
                        <FontAwesomeIcon color={object.color} fontSize={48} icon={faLungs} />
                      </div>
                    )
                  }
                  return null
                })}

                <div style={{ textAlign: 'center' }}>
                  {Object.keys(
                    oxygenSatMethod(data[data.length - 1].examinationData?.checkinData['oxygenSaturation'])
                  ).map((key) => {
                    const object = oxygenSatMethod(
                      data[data.length - 1].examinationData?.checkinData['oxygenSaturation']
                    )[key]
                    if (object.statusTest) {
                      return (
                        <>
                          <p style={{ fontSize: 20 }}>
                            {data[data.length - 1]?.examinationData?.checkinData['oxygenSaturation']}{' '}
                            <span style={{ opacity: 0.5 }}>%</span>
                          </p>
                          <h2>Oxygen Saturation</h2>
                          <Tag color={object.backgroundColor} style={{ color: '#000000' }}>
                            {object.title}
                          </Tag>
                        </>
                      )
                    }
                    return null
                  })}
                </div>
              </Space>
            ) : undefined}
            {data[data.length - 1]?.examinationData?.checkinData['temperature'] ? (
              <Space
                wrap
                style={doctorSummariesStyle}
                onClick={() => {
                  scrollGraph('temperature')
                }}
              >
                {Object.keys(temperatureMethod(data[data.length - 1].examinationData?.checkinData['temperature'])).map(
                  (key) => {
                    const object = temperatureMethod(data[data.length - 1].examinationData?.checkinData['temperature'])[
                      key
                    ]
                    if (object.statusTest) {
                      return (
                        <div
                          style={{
                            width: 90,
                            height: 90,
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: 12,
                            alignItems: 'center',
                            background: object.backgroundColor
                          }}
                        >
                          <FontAwesomeIcon color={object.color} fontSize={48} icon={faThermometer0} />
                        </div>
                      )
                    }
                    return null
                  }
                )}

                <div style={{ textAlign: 'center' }}>
                  {Object.keys(
                    temperatureMethod(data[data.length - 1].examinationData?.checkinData['temperature'])
                  ).map((key) => {
                    const object = temperatureMethod(data[data.length - 1].examinationData?.checkinData['temperature'])[
                      key
                    ]
                    if (object.statusTest) {
                      return (
                        <>
                          <p style={{ fontSize: 20 }}>
                            {data[data.length - 1]?.examinationData?.checkinData['temperature']}{' '}
                            <span style={{ opacity: 0.5 }}>°C</span>
                          </p>
                          <h2>Temperature</h2>
                          <Tag color={object.backgroundColor} style={{ color: '#000000' }}>
                            {object.title}
                          </Tag>
                        </>
                      )
                    }
                    return null
                  })}
                </div>
              </Space>
            ) : undefined}
          </Space>
        </center>
      </Col>
    </>
  )
}

export default DoctorPreview
