import React from 'react'
import { Button, Col, Space, theme } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBank,
  faCalendarCheck,
  faCalendarPlus,
  faCheckCircle,
  faClock,
  faMobileAlt,
  faMoneyBill,
  faSearch,
  faSignInAlt,
  faVideoCamera
} from '@fortawesome/free-solid-svg-icons'
import CustomRow from '../../../reusable-components/CustomRow'

const VirtualVisits = ({ nextStep }) => {
  const handleHomeVisit = () => {
    nextStep()
  }
  const { token } = theme.useToken()
  return (
    <CustomRow justify='center'>
      <Col span={24}>
        <h2
          style={{
            padding: 12,
            borderRadius: 12,
            background: token.colorSecondary,
            color: '#ffffff',
            textAlign: 'center'
          }}
        >
          How Virtual Consults Work
        </h2>
      </Col>
      <Col span={24}>
        <CustomRow gutter={[12, 12]} className='basic-card'>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Space style={{ maxWidth: '100%', width: 400, alignItems: 'center' }}>
              <FontAwesomeIcon style={{ width: 50 }} icon={faCalendarPlus} size='2x' /> Select a Date
            </Space>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Space style={{ maxWidth: '100%', width: 400, alignItems: 'center' }}>
              <FontAwesomeIcon style={{ width: 50 }} icon={faSearch} size='2x' /> Find a Doctor
            </Space>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Space style={{ maxWidth: '100%', width: 400, alignItems: 'center' }}>
              <FontAwesomeIcon style={{ width: 50 }} icon={faClock} size='2x' /> Select preferred Timeslot
            </Space>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Space style={{ maxWidth: '100%', width: 400, alignItems: 'center' }}>
              <FontAwesomeIcon style={{ width: 50 }} icon={faCheckCircle} size='2x' /> Select reason for Consult
            </Space>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Space style={{ maxWidth: '100%', width: 400, alignItems: 'center' }}>
              <FontAwesomeIcon style={{ width: 50 }} icon={faMoneyBill} size='2x' /> Select Payment Method
            </Space>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Space style={{ maxWidth: '100%', width: 400, alignItems: 'center' }}>
              <FontAwesomeIcon style={{ width: 50 }} icon={faBank} size='2x' /> Proceed with Payment
            </Space>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Space style={{ maxWidth: '100%', width: 400, alignItems: 'center' }}>
              <FontAwesomeIcon style={{ width: 50 }} icon={faMobileAlt} size='2x' /> Receive confirmation SMS/E-Mail
            </Space>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Space style={{ maxWidth: '100%', width: 400, alignItems: 'center' }}>
              <FontAwesomeIcon style={{ width: 50 }} icon={faSignInAlt} size='2x' /> Log in 5 minutes before consult
            </Space>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Space style={{ maxWidth: '100%', width: 400, alignItems: 'center' }}>
              <FontAwesomeIcon style={{ width: 50 }} icon={faCalendarCheck} size='2x' /> Click on appointment in app
            </Space>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Space style={{ maxWidth: '100%', width: 400, alignItems: 'center' }}>
              <FontAwesomeIcon style={{ width: 50 }} icon={faVideoCamera} size='2x' /> Connect with doctor
            </Space>
          </Col>
          <Col style={{ textAlign: 'center' }} span={24}>
            <Button type='primary' onClick={handleHomeVisit}>
              Next
            </Button>
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default VirtualVisits
