import React, { useEffect, useState } from 'react'
import '../../analytics.css'
import { Spin } from 'antd'

const HeightWeight = ({ data, loading }) => {
  const [height, setHeight] = useState(0)
  const [weight, setWeight] = useState(0)

  useEffect(() => {
    setHeight(data?.examinationData?.checkinData?.height?.value)
    setWeight(data?.examinationData?.checkinData?.weight?.value)
  }, [data])

  return (
    <>
      <div className='ruler-graphic-custom-container'>
        <div className='ruler-graphic-custom-line-container'>
          <div className='ruler-graphic-custom-reg-line'></div>
          <div className='ruler-graphic-custom-reg-line'></div>
          <div className='ruler-graphic-custom-reg-line'></div>
          <div className='ruler-graphic-custom-active-line'></div>
          <div className='ruler-graphic-custom-reg-line'></div>
          <div className='ruler-graphic-custom-reg-line'></div>
          <div className='ruler-graphic-custom-reg-line'></div>
          <div className='ruler-graphic-custom-reg-line'></div>
        </div>
        <div className='ruler-graphic-custom-reading'>{loading ? <Spin spinning /> : height} cm</div>
        <div className='ruler-graphic-custom-label'>Height</div>
      </div>
      <div style={{ marginTop: 20 }} className='ruler-graphic-custom-container'>
        <div className='ruler-graphic-custom-line-container'>
          <div className='ruler-graphic-custom-reg-line'></div>
          <div className='ruler-graphic-custom-reg-line'></div>
          <div className='ruler-graphic-custom-reg-line'></div>
          <div className='ruler-graphic-custom-active-line'></div>
          <div className='ruler-graphic-custom-reg-line'></div>
          <div className='ruler-graphic-custom-reg-line'></div>
          <div className='ruler-graphic-custom-reg-line'></div>
          <div className='ruler-graphic-custom-reg-line'></div>
        </div>
        <div className='ruler-graphic-custom-reading'>{loading ? <Spin spinning /> : weight} kg</div>
        <div className='ruler-graphic-custom-label'>Weight</div>
      </div>
    </>
  )
}

export default HeightWeight
