const initialState = {
  patient: null,
  loading: false,
  error: null
}

export const setPatient = (data) => ({
  type: 'SET_PATIENT',
  payload: data
})

export const updatePatientSection = (section, data) => ({
  type: 'UPDATE_PATIENT_SECTION',
  payload: { section, data }
})

export const updateChronicIllnesses = (data) => ({
  type: 'UPDATE_CHRONIC_ILLNESSES',
  payload: data
})

export const setMedicalHistory = (data) => ({
  type: 'SET_MEDICAL_HISTORY',
  payload: data
})

export default function medicalHistoryReducer(state = initialState, action) {
  if (!state) return initialState

  switch (action.type) {
    case 'SET_PATIENT':
      return {
        ...state,
        patient: action.payload ? { ...action.payload } : null,
        loading: false,
        error: null
      }

    case 'UPDATE_PATIENT_SECTION':
      if (!state.patient) return state
      return {
        ...state,
        patient: {
          ...state.patient,
          [action.payload.section]: action.payload.data
        }
      }

    case 'UPDATE_CHRONIC_ILLNESSES':
      if (!state.patient) return state
      return {
        ...state,
        patient: {
          ...state.patient,
          chronicIllnesses: {
            ...state.patient.chronicIllnesses,
            list: action.payload.list || [],
            updatedAt: action.payload.updatedAt
          }
        }
      }

    case 'SET_MEDICAL_HISTORY':
      if (!state.patient) return state
      return {
        ...state,
        patient: {
          ...state.patient,
          ...action.payload
        }
      }

    default:
      return state
  }
}
