import Agilite from 'agilite'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const createCareSyncLabTest = (data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('caresync_lab_tests', 'create', {
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const readCareSyncLabTests = (filter, options, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('caresync_lab_tests', 'read', {
          filter: JSON.stringify(filter),
          options: JSON.stringify(options),
          page,
          pageLimit
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateCareSyncLabTest = (recordId, data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('caresync_lab_tests', 'update', {
          recordId,
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const deleteCareSyncLabTest = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('caresync_lab_tests', 'delete', {
          filter: JSON.stringify(filter)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const countCareSyncLabTests = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('caresync_lab_tests', 'count', {
          filter: JSON.stringify(filter)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
