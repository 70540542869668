import React, { useState, useEffect } from 'react'
import { Form, Button, message, Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBrain,
  faArrowLeft,
  faArrowRight,
  faHeadSideCoughSlash,
  faCloud,
  faHeartPulse,
  faShieldHeart,
  faBolt,
  faScaleBalanced,
  faUtensils
} from '@fortawesome/free-solid-svg-icons'
import '../style/MobFamilyHistory.css'
import '../style/MobMentalHealth.css'
import { updateMentalHealth } from '../../api/api'
import patMedHistReducer from '../../utils/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { handleError } from '../../../../lib/utils'
import MobSectionHeader from './MobSectionHeader'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'

const MENTAL_HEALTH_OPTIONS = [
  {
    value: 'Depression',
    label: 'Depression',
    icon: faCloud,
    color: '239, 68, 68'
  },
  {
    value: 'Anxiety',
    label: 'Anxiety',
    icon: faBolt,
    color: '168, 85, 247'
  },
  {
    value: 'Bipolar',
    label: 'Bipolar',
    icon: faScaleBalanced,
    color: '236, 72, 153'
  },
  {
    value: 'PTSD',
    label: 'PTSD',
    icon: faShieldHeart,
    color: '251, 146, 60'
  },
  {
    value: 'ADHD',
    label: 'ADHD',
    icon: faHeartPulse,
    color: '16, 178, 225'
  },
  {
    value: 'Eating Disorder',
    label: 'Eating Disorder',
    icon: faUtensils,
    color: '139, 92, 246'
  },
  {
    value: 'OCD',
    label: 'OCD',
    icon: faHeadSideCoughSlash,
    color: '81, 207, 102'
  }
]

const MobMentalHealth = ({ setFacet, setSaving }) => {
  const patMedHist = useSelector((state) => state.patMedHist.data)
  const mentalHealth = patMedHist.mentalHealth
  const [selectedOptions, setSelectedOptions] = useState(mentalHealth.list || [])
  const dispatch = useDispatch()

  const handleOptionClick = (optionValue) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(optionValue)
        ? prevSelected.filter((value) => value !== optionValue)
        : [...prevSelected, optionValue]
    )
  }

  const handleSubmit = async () => {
    setSaving(true)
    try {
      const newRecord = await updateMentalHealth(patMedHist._id, selectedOptions)
      dispatch(patMedHistReducer.actions.updateState(newRecord))
    } catch (error) {
      message.error(handleError(error))
    } finally {
      setSaving(false)
    }
  }

  const renderMentalHealthOptions = () => {
    return (
      <Row gutter={[16, 16]}>
        {MENTAL_HEALTH_OPTIONS.map((option) => {
          const isChecked = selectedOptions.includes(option.value)

          return (
            <Col span={24}>
              <div
                key={option.value}
                className='condition-card'
                onClick={() => handleOptionClick(option.value)}
                role='checkbox'
                aria-checked={isChecked}
                tabIndex={0}
                style={{
                  background: isChecked ? 'rgba(16, 178, 225, 0.15)' : '',
                  borderColor: isChecked ? '#10B2E1' : 'rgba(16, 178, 225, 0.15)'
                }}
              >
                <div className='condition-icon' style={{ color: `rgb(${option.color})` }}>
                  <FontAwesomeIcon icon={option.icon} />
                </div>
                <span className='condition-name'>{option.label}</span>
              </div>
            </Col>
          )
        })}
      </Row>
    )
  }

  return (
    <>
      {/* Heading */}
      <MobSectionHeader
        icon={faBrain}
        title='Mental Health'
        subtitle='Please select any conditions you have experienced'
      />

      {/* Mental Health Options */}
      <div className='lifestyle-sections'>
        <div className='lifestyle-card'>
          <div className='card-content'>{renderMentalHealthOptions()}</div>
        </div>
      </div>

      {/* Bottom Action Buttons */}
      <PrevNextFloatingButtons
        onPrev={() => {
          setFacet(null)
        }}
        onNext={handleSubmit}
        prevText='Cancel'
        nextText='Save'
      />
    </>
  )
}

export default MobMentalHealth
