import React, { useEffect, useState } from 'react'
import { Card, Space, Button, DatePicker, Select, message, Form, Row, Col, theme } from 'antd'
import { readClinics } from '../../Admin/clinics/utils/utils'
import { MedPraxSchemeSearch } from '../../Scripting/utils/utils'
import { handleError } from '../../lib/utils'
import { readMedicalProfessionalUsers } from '../../Admin/medical-professionals/utils/utils'
import store from '../../../store'
import {
  generateBillingReport,
  generateDetailedInvoiceReport,
  generateMedicalAidAgeAnalysis,
  generatePatientAgeAnalysis,
  generateSummarizedAgeAnalysis,
  generateTotalDebtorsAgeAnalysis
} from '../reports-utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'

const Reports = () => {
  const [reportType, setReportType] = useState('period_end_report')
  const [reportContent, setReportContent] = useState(null)
  const [data, setData] = useState({
    medicalProfs: [],
    schemes: [],
    venues: []
  })
  const [loading, setLoading] = useState(false)

  const reportOptions = [
    {
      label: 'Financial',
      options: [
        { label: 'Period End Report', value: 'period_end_report' },
        { label: 'Receipt Report', value: 'receipt_report' }
      ]
    },
    {
      label: 'Claims & Billing',
      options: [{ label: 'Detailed Invoice Report', value: 'detailed_invoice_report' }]
    },
    {
      label: 'Age Analysis',
      options: [
        { label: 'Summarized Age Analysis', value: 'summarized_age_analysis' },
        { label: 'Medical Aid Age Analysis', value: 'medical_aid_age_analysis' },
        { label: 'Patient Age Analysis', value: 'patient_age_analysis' },
        { label: 'Total Debtors Age Analysis', value: 'total_debtors_age_analysis' }
      ]
    }
  ]

  const handleGenerateReport = async () => {
    try {
      let pdfUrl = null
      setLoading(true)

      switch (reportType) {
        case 'detailed_invoice_report':
          pdfUrl = await generateDetailedInvoiceReport(form.getFieldsValue())
          break
        case 'summarized_age_analysis':
          pdfUrl = await generateSummarizedAgeAnalysis(form.getFieldsValue())
          break
        case 'medical_aid_age_analysis':
          pdfUrl = await generateMedicalAidAgeAnalysis(form.getFieldsValue())
          break
        case 'patient_age_analysis':
          pdfUrl = await generatePatientAgeAnalysis(form.getFieldsValue())
          break
        case 'total_debtors_age_analysis':
          pdfUrl = await generateTotalDebtorsAgeAnalysis(form.getFieldsValue())
          break
        default:
          pdfUrl = await generateBillingReport(form.getFieldsValue(), reportType)
          break
      }

      setReportContent(pdfUrl)
    } catch (e) {
      message.error(handleError(e, true))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [])

  const handleFetchData = async () => {
    try {
      const medicalProfs = await readMedicalProfessionalUsers(
        { 'extraData.entities': { $in: [store.getState().core.entity._id] } },
        'firstName lastName _id'
      )

      const schemes = await MedPraxSchemeSearch()

      const venues = await readClinics({}, false, 'name _id')

      // Sort Data
      medicalProfs.sort((a, b) => a.firstName.localeCompare(b.firstName))
      schemes.sort((a, b) => a.code.localeCompare(b.code))
      venues.sort((a, b) => a.name.localeCompare(b.name))

      // Format Data
      const medicalProfsOptions = medicalProfs.map((prof) => ({
        label: `${prof.firstName} ${prof.lastName}`,
        value: prof._id
      }))

      const schemesOptions = schemes.map((scheme) => ({
        label: scheme.name,
        value: scheme.code
      }))

      const venuesOptions = venues.map((venue) => ({
        label: venue.name,
        value: venue._id
      }))

      setData({ medicalProfs: medicalProfsOptions, schemes: schemesOptions, venues: venuesOptions })
    } catch (e) {
      message.error(handleError(e, theme))
    }
  }

  const handleReset = () => {
    setReportType('period_end_report')
    setReportContent('')
    form.resetFields()
  }

  const [form] = Form.useForm()

  return (
    <Card title='Billing Reports' className='reports-container'>
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Space>
          <div>
            <Space>
              <span style={{ color: 'red' }}>*</span>Report Type:
            </Space>
          </div>
          <Select
            style={{ width: 250 }}
            options={reportOptions}
            value={reportType}
            onChange={setReportType}
            placeholder='Select report type'
          />
        </Space>

        {/* Report Viewer */}
        <Card title='Report Viewer' style={{ marginTop: 5 }} size='small' type='inner'>
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Card style={{ backgroundColor: '#f0f2f5', height: '100%' }} size='small' type='inner'>
                {loading ? (
                  <center>
                    <AgiliteSkeleton skActive={true} spinnerTip='Generating report...' />
                  </center>
                ) : reportContent ? (
                  <iframe src={reportContent} style={{ width: '100%', height: '800px' }} title='PDF Report' />
                ) : (
                  <center>
                    <p>Waiting for report parameters...</p>
                  </center>
                )}
              </Card>
            </Col>
            <Col span={6}>
              <Card size='small' type='inner'>
                <Form
                  form={form}
                  layout='vertical'
                  disabled={!reportType}
                  initialValues={{
                    books: [],
                    periodStart: null,
                    periodEnd: null,
                    medicalProfs: [],
                    schemes: [],
                    venues: [],
                    includes: [],
                    snapshotDate: null,
                    overduePeriod: [],
                    accountStatus: []
                  }}
                  onFinish={(values) => {
                    handleGenerateReport(values)
                  }}
                  onFinishFailed={(errorInfo) => {
                    message.error('Please fill in all required fields')
                  }}
                >
                  {/* Common fields for all reports */}
                  <Form.Item name='books' label='Book' rules={[{ required: true, message: 'Please select a book' }]}>
                    <Select
                      mode='multiple'
                      placeholder='Select books'
                      options={[
                        { label: 'Medical Aid', value: 'medical_aid' },
                        { label: 'Private', value: 'private' }
                      ]}
                    />
                  </Form.Item>

                  {/* Period fields - not shown for Age Analysis reports */}
                  {!reportType.includes('age_analysis') && (
                    <>
                      <Form.Item
                        name='periodStart'
                        label='Period start date'
                        rules={[{ required: true, message: 'Please select a period start date' }]}
                      >
                        <DatePicker />
                      </Form.Item>
                      <Form.Item
                        name='periodEnd'
                        label='Period end date'
                        rules={[{ required: true, message: 'Please select a period end date' }]}
                      >
                        <DatePicker />
                      </Form.Item>
                    </>
                  )}

                  {/* Age Analysis specific fields */}
                  {reportType.includes('age_analysis') && (
                    <>
                      <Form.Item
                        name='snapshotDate'
                        label='Snapshot Date'
                        rules={[{ required: true, message: 'Please select a snapshot date' }]}
                      >
                        <DatePicker />
                      </Form.Item>
                      <Form.Item
                        name='overduePeriod'
                        label='Overdue Period'
                        rules={[{ required: true, message: 'Please select overdue periods' }]}
                      >
                        <Select
                          mode='multiple'
                          placeholder='Select overdue periods'
                          options={[
                            { label: 'Current', value: 'current' },
                            { label: '30 Days', value: '30_days' },
                            { label: '60 Days', value: '60_days' },
                            { label: '90 Days', value: '90_days' },
                            { label: '120 Days', value: '120_days' },
                            { label: '150+ Days', value: '150_plus_days' }
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        name='accountStatus'
                        label='Account Status'
                        rules={[{ required: true, message: 'Please select account status' }]}
                      >
                        <Select
                          mode='multiple'
                          placeholder='Select account status'
                          options={[
                            { label: 'Normal', value: 'normal' },
                            { label: 'Suspended', value: 'suspended' },
                            { label: 'Hand Over', value: 'handOver' },
                            { label: 'Bad Debt', value: 'badDebt' }
                          ]}
                        />
                      </Form.Item>
                    </>
                  )}

                  {/* Common fields for all reports */}
                  <Form.Item
                    name='medicalProfs'
                    label='Practitioner name'
                    rules={[{ required: true, message: 'Please select a practitioner name' }]}
                  >
                    <Select
                      mode='multiple'
                      placeholder='Select practitioners'
                      options={[{ label: <b>All</b>, value: 'ALL' }, ...data.medicalProfs]}
                      optionFilterProp='label'
                      showSearch
                    />
                  </Form.Item>
                  <Form.Item
                    name='schemes'
                    label='Scheme name'
                    rules={[{ required: true, message: 'Please select a scheme name' }]}
                  >
                    <Select
                      mode='multiple'
                      placeholder='Select schemes'
                      options={[{ label: <b>All</b>, value: 'ALL' }, ...data.schemes]}
                      optionFilterProp='label'
                      showSearch
                    />
                  </Form.Item>
                  <Form.Item
                    name='venues'
                    label='Practice venue'
                    rules={[{ required: true, message: 'Please select a practice venue' }]}
                  >
                    <Select
                      mode='multiple'
                      placeholder='Select venues'
                      options={[{ label: <b>All</b>, value: 'ALL' }, ...data.venues]}
                      optionFilterProp='label'
                      showSearch
                    />
                  </Form.Item>

                  {/* Detailed Invoice Report specific field */}
                  {reportType === 'detailed_invoice_report' && (
                    <Form.Item
                      name='includes'
                      label='Includes'
                      rules={[{ required: true, message: 'Please select what type of invoices to include' }]}
                    >
                      <Select
                        mode='multiple'
                        placeholder='Select Type of Invoices to Include'
                        options={[
                          { label: 'Finalized', value: 'finalized' },
                          { label: 'Pending', value: 'pending' },
                          { label: 'Finalized & Pending', value: 'all' }
                        ]}
                      />
                    </Form.Item>
                  )}

                  <Form.Item>
                    <Space>
                      <Button type='default' onClick={handleReset}>
                        Reset
                      </Button>
                      <Button type='primary' htmlType='submit'>
                        Generate
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Card>
      </Space>
    </Card>
  )
}

export default Reports
