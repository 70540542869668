import { Button } from 'antd'
import { Picker } from 'antd-mobile'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import './CustomDatePicker.css'

const DatePicker = ({
  allowPastDates = true,
  showDatePicker,
  setShowDatePicker,
  handleDateSelect,
  initialDate,
  showQuickSelect = false,
  customQuickSelectDays = [0, 1],
  quickSelectLabels = ['Today', 'Tomorrow'],
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  buttonStyle,
  popupStyle
}) => {
  // Use local state for picker value
  const [internalPickerValue, setInternalPickerValue] = useState(() => {
    const date = initialDate ? dayjs(initialDate) : dayjs()
    return [date.date(), date.month() + 1, date.year()]
  })

  const generateDateColumns = () => {
    const today = dayjs()
    const currentDay = today.date()
    const currentMonth = today.month() + 1
    const currentYear = today.year()

    // Get selected month and year from internal picker value
    const [_, selectedMonth, selectedYear] = internalPickerValue

    // Generate days
    const days = Array.from({ length: 31 }, (_, i) => ({
      label: (i + 1).toString(),
      value: i + 1
    })).filter((day) => {
      if (!allowPastDates) {
        if (selectedYear === currentYear && selectedMonth === currentMonth) {
          return day.value >= currentDay
        }
        if (selectedYear === currentYear && selectedMonth < currentMonth) {
          return false
        }
      }
      return true
    })

    // Generate months
    const months = Array.from({ length: 12 }, (_, i) => ({
      label: dayjs().month(i).format('MMMM'),
      value: i + 1
    })).filter((month) => {
      if (!allowPastDates && selectedYear === currentYear) {
        return month.value >= currentMonth
      }
      return true
    })

    // Generate years
    const startYear = allowPastDates ? 1900 : currentYear
    const endYear = currentYear + 100
    const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => ({
      label: (startYear + i).toString(),
      value: startYear + i
    }))

    return [days, months, years]
  }

  const handleConfirm = (val) => {
    // Convert array format back to dayjs for external use
    const [day, month, year] = val
    const dateObj = dayjs()
      .year(year)
      .month(month - 1)
      .date(day)
    handleDateSelect(dateObj)
  }

  const handleSelect = (val) => {
    // Update local state instead of external state
    setInternalPickerValue(val)
  }

  const defaultButtonStyle = {
    backgroundColor: '#00b6d8',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    padding: '4px 12px',
    ...buttonStyle
  }

  const handleQuickDateSelect = (daysToAdd) => {
    // Implementation would go here
    // You might want to pass this as a prop as well depending on your needs
  }

  return (
    <Picker
      visible={showDatePicker}
      onClose={() => setShowDatePicker(false)}
      value={internalPickerValue}
      onConfirm={handleConfirm}
      columns={generateDateColumns()}
      title={
        showQuickSelect && (
          <div
            style={{
              display: 'flex',
              gap: '8px',
              justifyContent: 'center',
              padding: '8px 0'
            }}
          >
            {customQuickSelectDays.map((days, index) => (
              <Button key={days} onClick={() => handleQuickDateSelect(days)} style={defaultButtonStyle}>
                {quickSelectLabels[index]}
              </Button>
            ))}
          </div>
        )
      }
      confirmText={confirmText}
      cancelText={cancelText}
      popupStyle={{
        // '--adm-color-background': '#1e2633',
        // '--adm-color-text': '#ffffff',
        '--adm-color-primary': '#00b6d8',
        '--adm-color-border': '#333333',
        '--adm-font-size-main': '16px',
        // '--adm-picker-color': '#ffffff',
        // '--adm-color-text-secondary': '#ffffff',
        ...popupStyle
      }}
      className='custom-date-picker'
      onSelect={handleSelect}
    />
  )
}

export default DatePicker
