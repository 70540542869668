import { Col, Modal, Row, Select, message, theme } from 'antd'
import React, { useState } from 'react'
import { handleError } from '../../../lib/utils'
import { useSelector } from 'react-redux'
import { executeBooking, getBookingState } from '../../utils/bpm-utils'
import { updateBooking } from '../../utils/utils'

const ReassignmentModal = ({ reAssignment, setReAssignment, handleRefresh }) => {
  const state = useSelector((state) => state)
  const [selectedProf, setSelectedProf] = useState(null)
  const [selectedProfName, setSelectedProfName] = useState(null)
  const [reAssignLoading, setReAssignLoading] = useState(false)

  const { token } = theme.useToken()
  return (
    <Modal
      title='Reassign Booking'
      width={500}
      open={reAssignment.open}
      okText='Re-assign'
      cancelText='Cancel'
      okButtonProps={{
        type: 'primary',
        style: { backgroundColor: !selectedProf ? '' : token.colorSuccess },
        disabled: !selectedProf
      }}
      cancelButtonProps={{ danger: true, disabled: reAssignLoading }}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
      onOk={() => {
        Modal.confirm({
          title: 'Confirmation',
          content: (
            <span>
              Are you sure you want to Re-assign this booking to <b>{selectedProfName}</b>?
            </span>
          ),
          okText: 'Yes',
          cancelText: 'No',
          className: token.themeControl,
          okButtonProps: { type: 'primary', style: { backgroundColor: token.colorSuccess } },
          cancelButtonProps: { danger: true },
          onOk: async () => {
            let processRecord = null
            setReAssignLoading(true)

            try {
              processRecord = await getBookingState([reAssignment.record.processRef])
              await updateBooking({ medicalProfRef: selectedProf }, { _id: reAssignment.record._id })
              await executeBooking(
                reAssignment.record.clinicRecord.bpmKey,
                [reAssignment.record.processRef],
                reAssignment.profession === 'doctor' ? 'reassign_doctor' : 'reassign_nurse',
                `${state.auth.agiliteUser.firstName} ${state.auth.agiliteUser.lastName}`,
                processRecord.key
              )

              message.success('Booking re-assigned successfully.')
              setReAssignment({ record: '', profession: '', professionals: [], open: false })
              setSelectedProf(null)
              setSelectedProfName(null)
              handleRefresh()
            } catch (e) {
              message.error(handleError(e, true))
            }

            setReAssignLoading(false)
          }
        })
      }}
      onCancel={() => {
        setReAssignment({ record: '', profession: '', professionals: [], open: false })
        setSelectedProf(null)
        setSelectedProfName(null)
      }}
    >
      <>
        Please select a {`${reAssignment?.profession?.charAt(0)?.toUpperCase()}${reAssignment.profession.slice(1)}`} to
        re-assign this booking to.
        <Row>
          <Col span={24}>
            <Select
              showSearch
              value={selectedProf}
              optionFilterProp='label'
              placeholder={`Select a ${reAssignment?.profession
                ?.charAt(0)
                .toUpperCase()}${reAssignment.profession.slice(1)}`}
              style={{ width: '100%', marginTop: 10 }}
              options={reAssignment.professionals
                .map((user) => {
                  return { label: `${user.firstName} ${user.lastName}`, value: user._id }
                })
                .filter((user) => user.value !== state.auth.agiliteUser._id)}
              onChange={(value, option) => {
                setSelectedProfName(option.label)
                setSelectedProf(value)
              }}
            />
          </Col>
        </Row>
      </>
    </Modal>
  )
}

export default ReassignmentModal
