import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Space, Table, theme, Card, Typography, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faEye, faTrash, faFile, faFileAlt, faFileMedical, faUser } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { documentLabels } from '../utils/enums'
import { hexToRGBA } from '../../../../lib/utils'
import { useSelector } from 'react-redux'

const { Title } = Typography

const DocumentsTable = ({ setSelectedDocument, documents, loadingDocuments, handleDeleteDocument }) => {
  const { token } = theme.useToken()
  // Get current user information from Redux store
  const currentUser = useSelector((state) => state.auth.agiliteUser)

  const columns = useMemo(
    () => [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (value) => {
          let icon, text, color

          switch (value) {
            case 'sickNote':
              icon = faFileMedical
              text = documentLabels.SICK_NOTE
              color = '#1890ff' // Blue
              break
            case 'script':
              icon = faFileAlt
              text = documentLabels.SCRIPT
              color = '#52c41a' // Green
              break
            case 'referral':
              icon = faFileAlt
              text = documentLabels.REFERRAL
              color = '#722ed1' // Purple
              break
            case 'labReport':
              icon = faFileAlt
              text = documentLabels.LAB_REPORT
              color = '#fa8c16' // Orange
              break
            default:
              icon = faFile
              text = value
              color = '#8c8c8c' // Gray
          }

          return (
            <Space>
              <FontAwesomeIcon icon={icon} style={{ color, fontSize: '14px' }} />
              <span>{text}</span>
            </Space>
          )
        }
      },
      {
        title: 'Created By',
        dataIndex: ['audit', 'createdBy'],
        key: 'createdBy',
        ellipsis: true,
        render: (value, record) => {
          let displayName = null
          let profession = null

          // If this document was created by the current user, use current user's name and profession
          if (currentUser && record.medicalProfRef === currentUser._id) {
            displayName = `${currentUser.firstName || ''} ${currentUser.lastName || ''}`.trim()
            profession = currentUser.extraData?.profession
          }
          // If medicalProfRef exists and contains a name, use it
          else if (record.medicalProfRef && typeof record.medicalProfRef === 'object') {
            displayName = `${record.medicalProfRef.firstName || ''} ${record.medicalProfRef.lastName || ''}`.trim()
            profession = record.medicalProfRef.extraData?.profession
          }
          // If medicalProfName exists, use it directly
          else if (record.medicalProfName) {
            displayName = record.medicalProfName
            // Try to get profession from record if available
            profession = record.medicalProfProfession || 'doctor' // Default to doctor if not specified
          }
          // If audit.createdByName exists, use it
          else if (record.audit && record.audit.createdByName) {
            displayName = record.audit.createdByName
            // Try to get profession from record if available
            profession = record.medicalProfProfession || 'doctor' // Default to doctor if not specified
          }

          // Add prefix based on profession
          if (displayName) {
            // Add "Dr." prefix for doctors
            const prefix = profession === 'doctor' ? 'Dr. ' : ''

            return (
              <Space>
                <FontAwesomeIcon icon={faUser} style={{ color: token.colorPrimary, fontSize: '14px' }} />
                <span>
                  {prefix}
                  {displayName}
                </span>
              </Space>
            )
          }

          // Show the medical professional icon with a generic label as a fallback
          return (
            <Space>
              <FontAwesomeIcon icon={faUser} style={{ color: '#8c8c8c', fontSize: '14px' }} />
              <span>Medical Professional</span>
            </Space>
          )
        }
      },
      {
        title: 'Created At',
        dataIndex: ['audit', 'createdAt'],
        key: 'createdAt',
        render: (value) => {
          if (!value) return '-'
          const date = dayjs(value)
          return (
            <div>
              <div>{date.format('DD MMM YYYY')}</div>
              <div style={{ fontSize: '12px', color: '#8c8c8c' }}>{date.format('hh:mm A')}</div>
            </div>
          )
        }
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => (
          <Space size='small'>
            <Tooltip title='View Document'>
              <Button
                type='text'
                size='small'
                onClick={() => {
                  setSelectedDocument(record.value)
                }}
                icon={<FontAwesomeIcon color={token.colorPrimary} icon={faEye} />}
              />
            </Tooltip>
            <Tooltip title='Download Document'>
              <Button
                type='text'
                size='small'
                onClick={() => {
                  const base64Data = record.value.split(',')[1]
                  const binaryData = atob(base64Data)
                  const bytes = new Uint8Array(binaryData.length)
                  for (let i = 0; i < binaryData.length; i++) {
                    bytes[i] = binaryData.charCodeAt(i)
                  }
                  const blob = new Blob([bytes], { type: 'application/pdf' })

                  const link = document.createElement('a')
                  link.href = URL.createObjectURL(blob)
                  link.download = `${record.name}.pdf`
                  link.click()

                  URL.revokeObjectURL(link.href)
                }}
                icon={<FontAwesomeIcon icon={faDownload} color={token.colorSuccess} />}
              />
            </Tooltip>
            <Tooltip title='Delete Document'>
              <Button
                type='text'
                size='small'
                onClick={() => {
                  handleDeleteDocument(record._id || record.id)
                }}
                icon={<FontAwesomeIcon color={token.colorError} icon={faTrash} />}
              />
            </Tooltip>
          </Space>
        )
      }
    ],
    // eslint-disable-next-line
    [documents, setSelectedDocument, handleDeleteDocument]
  )

  return (
    <Card
      styles={{
        header: {
          background: hexToRGBA(token.colorPrimary, 0.08),
          borderBottom: `1px solid ${hexToRGBA(token.colorPrimary, 0.1)}`,
          padding: '12px 16px'
        },
        body: {
          padding: '16px'
        }
      }}
      size='small'
      title={
        <Space>
          <FontAwesomeIcon
            icon={faFileAlt}
            style={{
              color: token.colorPrimary,
              fontSize: '16px'
            }}
          />
          <Title level={5} style={{ margin: 0 }}>
            Document List
          </Title>
          {documents.length > 0 && (
            <span
              style={{
                backgroundColor: '#1890ff',
                color: 'white',
                borderRadius: '10px',
                padding: '2px 8px',
                fontSize: '12px',
                marginLeft: '8px'
              }}
            >
              {documents.length}
            </span>
          )}
        </Space>
      }
      style={{
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden'
      }}
    >
      <Table
        size='small'
        bordered={false}
        columns={columns}
        dataSource={documents}
        rowKey={(record) => record.id || record._id}
        loading={loadingDocuments}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} documents`
        }}
        style={{
          borderRadius: '4px',
          overflow: 'hidden'
        }}
        rowClassName={() => 'document-table-row'}
      />
    </Card>
  )
}

DocumentsTable.propTypes = {
  setSelectedDocument: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      _id: PropTypes.string,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      audit: PropTypes.shape({
        createdBy: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  loadingDocuments: PropTypes.bool.isRequired,
  handleDeleteDocument: PropTypes.func.isRequired
}

export default DocumentsTable
