import React, { useEffect, useState, useCallback } from 'react'
import { Space, Button, theme, Switch, InputNumber, Popconfirm, Modal, Input, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrash, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import PrescriptionPreview from '../../Scripting tool/components/PrescriptionPreview'
import { createScriptTemplateEntry } from '../../../utils/utils'

const Footer = ({
  script,
  isNew,
  handleUpdateScheduleData,
  confirmReadOnly,
  totalCost,
  handleGenerateScriptTemplate,
  handleCreateScript,
  scriptBusy,
  loading,
  printingScript,
  selectedPatient,
  handleCancel
}) => {
  const { token } = theme.useToken()
  const isReadOnly = typeof confirmReadOnly === 'function' ? confirmReadOnly() : false
  const [previewVisible, setPreviewVisible] = useState(false)
  const [sendingScript, setSendingScript] = useState(false)
  const [saveTemplateModalVisible, setSaveTemplateModalVisible] = useState(false)
  const [templateName, setTemplateName] = useState('')
  const [savingTemplate, setSavingTemplate] = useState(false)

  // Set default name when component mounts if not already set
  useEffect(() => {
    if (handleUpdateScheduleData && (!script?.name || script.name === '') && selectedPatient) {
      const patientName = `${selectedPatient.firstName} ${selectedPatient.lastName}`
      const scriptDate = dayjs().format('YYYY-MM-DD')
      handleUpdateScheduleData(`${patientName} - ${scriptDate}`, 'name')
    }
  }, [script, handleUpdateScheduleData, selectedPatient])

  const handleScheduleChange = useCallback((checked) => {
    try {
      if (handleUpdateScheduleData) {
        // First update onSchedule
        handleUpdateScheduleData(checked, 'onSchedule')
        
        // Then set initial interval if turning on schedule
        if (checked) {
          setTimeout(() => {
            handleUpdateScheduleData(1, 'scheduleInterval')
          }, 0)
        }
      }
    } catch (error) {
      // Silent error handling in production
    }
  }, [handleUpdateScheduleData])

  const handleIntervalChange = useCallback((value) => {
    if (handleUpdateScheduleData) {
      handleUpdateScheduleData(value, 'scheduleInterval')
    }
  }, [handleUpdateScheduleData])

  const handleOpenPreview = useCallback(() => {
    setPreviewVisible(true)
  }, [])

  const handleClosePreview = useCallback(() => {
    setPreviewVisible(false)
  }, [])

  const handleSendScript = useCallback(async () => {
    setSendingScript(true)
    try {
      // Here you would implement the actual sending logic
      // For example: await sendScript(script)
      
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500))
      
      // Close the preview after sending
      setPreviewVisible(false)
      
      // Create the script
      if (handleCreateScript) {
        handleCreateScript(script, false)
      }
    } catch (error) {
      // Silent error handling in production
    } finally {
      setSendingScript(false)
    }
  }, [script, handleCreateScript])

  const handleSaveTemplate = async () => {
    if (!templateName.trim()) {
      message.error('Please enter a template name')
      return
    }

    setSavingTemplate(true)
    try {
      const templateData = {
        name: templateName.trim(),
        items: script.items
      }
      
      await createScriptTemplateEntry(templateData)
      message.success('Template saved successfully')
      setSaveTemplateModalVisible(false)
      setTemplateName('')
    } catch (error) {
      message.error('Failed to save template')
    } finally {
      setSavingTemplate(false)
    }
  }

  return (
    <>
      <div style={{ 
        width: '100%',
        background: 'white',
        padding: '16px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        borderRadius: '8px'
      }}>
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column',
          gap: '16px'
        }}>
          {/* Controls Row */}
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '16px'
          }}>
            {/* Scheduling Controls */}
            <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
              <Space>
                <p>Schedule</p>
                <Switch
                  disabled={isReadOnly}
                  checked={Boolean(script?.onSchedule)}
                  onChange={handleScheduleChange}
                />
              </Space>
              {script?.onSchedule && (
                <Space>
                  <p><b>Notify me every: </b></p>
                  <InputNumber
                    min={1}
                    max={12}
                    formatter={(value) => `${Math.floor(value)}`}
                    parser={(value) => Math.floor(value)}
                    readOnly={isReadOnly}
                    style={{ width: 150 }}
                    value={script?.scheduleInterval || 1}
                    onChange={handleIntervalChange}
                    addonAfter={(script?.scheduleInterval || 1) > 1 ? 'Months' : 'Month'}
                  />
                </Space>
              )}
            </div>

            {/* Action Buttons */}
            <Space>
              <Button
                type="default"
                icon={<FontAwesomeIcon icon={faSave} />}
                onClick={() => setSaveTemplateModalVisible(true)}
                disabled={scriptBusy || loading || printingScript || !script?.items?.length}
              >
                Save as template
              </Button>
              <Popconfirm
                title='Discard Script'
                description='Are you sure? All progress will be lost.'
                onConfirm={() => {
                  if (typeof handleCancel === 'function') {
                    handleCancel()
                  }
                }}
                okText='Yes'
                cancelText='No'
                okButtonProps={{ 
                  style: { background: token.colorError },
                  danger: true
                }}
              >
                <Button
                  type="default"
                  danger
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  disabled={scriptBusy || loading || printingScript}
                >
                  Discard script
                </Button>
              </Popconfirm>
              <Button
                type="primary"
                icon={<FontAwesomeIcon icon={faPaperPlane} />}
                onClick={handleOpenPreview}
                disabled={scriptBusy || loading || printingScript || !script?.items?.length}
              >
                Preview & Send
              </Button>
            </Space>
          </div>

          {/* Total Cost */}
          <div style={{ 
            fontSize: '16px',
            fontWeight: 500,
            color: token.colorTextSecondary,
            textAlign: 'right'
          }}>
            Total Cost: <span style={{ color: token.colorPrimary }}>R{totalCost.toFixed(2)}</span>
          </div>
        </div>
      </div>

      {/* Save Template Modal */}
      <Modal
        title="Save as Template"
        open={saveTemplateModalVisible}
        onCancel={() => {
          setSaveTemplateModalVisible(false)
          setTemplateName('')
        }}
        onOk={handleSaveTemplate}
        okText="Save Template"
        confirmLoading={savingTemplate}
        okButtonProps={{
          disabled: !templateName.trim() || !script?.items?.length
        }}
      >
        <div style={{ marginTop: 16 }}>
          <p>Enter a name for your template:</p>
          <Input
            placeholder="Template name"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            maxLength={100}
          />
        </div>
      </Modal>

      {/* Prescription Preview Modal */}
      <PrescriptionPreview
        visible={previewVisible}
        onClose={handleClosePreview}
        onSend={handleSendScript}
        script={script}
        patient={selectedPatient}
        loading={sendingScript}
      />
    </>
  )
}

export default React.memo(Footer) 