import { Skeleton } from 'antd'
import React from 'react'
import CustomLoadingIcon from './CustomLoadingIcon'

const AgiliteSkeleton = ({ spinnerTip = 'Loading...', skActive = true, skParagraph = true, skTitle = true }) => {
  return (
    <div>
      <CustomLoadingIcon
        loadingText={spinnerTip}
        content={<Skeleton active={skActive} paragraph={skParagraph} title={skTitle} />}
      />
    </div>
  )
}

export default AgiliteSkeleton
