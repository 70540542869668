import store from '../../../store'

const ProfileEnums = {
  keys: {
    PROFILE: 'profile',
    SIGN_OUT: 'sign_out'
  },
  titles: {
    PROFILE: 'User Profile',
    SIGN_OUT: 'Sign Out'
  },
  images: {
    DEFAULT_IMAGE: 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
  },
  profileValidators: {
    hasAvailabilityAccess: () => {
      return store.getState()?.auth?.agiliteUser?.extraData?.hasAvailabilityAccess
    }
  }
}

export default ProfileEnums
