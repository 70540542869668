import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCar,
  faCaretRight,
  faChevronDown,
  faClock,
  faCode,
  faKey,
  faLocationDot,
  faPhone,
  faUniversalAccess
} from '@fortawesome/free-solid-svg-icons'
import { motion, AnimatePresence } from 'framer-motion'
import CancelModal from './CancelModal'
import { Picker } from 'antd-mobile'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import coreReducer from '../../../../../core/utils/reducer'
import CoreEnums from '../../../../../core/utils/enums'
import { Space } from 'antd'
import MobUberVirtual from '../../../Virtual Consultation/Mobile/components/MobUberVirtual'
import store from '../../../../../store'

const ActionButton = ({ onClick, icon }) => (
  <button className='detail-action-button' onClick={onClick}>
    <FontAwesomeIcon icon={icon} />
  </button>
)

const formatDate = (dateStr, time) => {
  const date = new Date(dateStr)
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const dayName = days[date.getDay()]
  const day = date.getDate()
  const month = months[date.getMonth()]

  return `${dayName}, ${day} ${month} at ${time}`
}

const MobUpcomingAppointmentCard = ({ appointment }) => {
  const clinic = store.getState().clinics.data.find((i) => i._id === appointment.clinicRef)
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)
  const servicesState = useSelector((state) => state.services.data)
  const virtualServiceState = useSelector((state) => state.virtualServices.data)
  const [isExpanded, setIsExpanded] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showReschedule, setShowReschedule] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())

  const getServiceName = () => {
    let service = servicesState.find((service) => service._id === appointment.service)
    if (!service) {
      service = virtualServiceState.find((service) => service._id === appointment.service)
    }
    return service ? service.name : 'Unknown Service'
  }

  const handleLocationClick = (address) => {
    const encodedAddress = encodeURIComponent(address)
    window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, '_blank')
  }

  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber.replace(/\s+/g, '')}`
  }

  const handleDateSelect = (val) => {
    const [day, month, year] = val
    const newDate = new Date(year, month - 1, day)
    setSelectedDate(newDate)
    setShowReschedule(false)
  }

  const generateDateColumns = () => {
    const days = Array.from({ length: 31 }, (_, i) => ({
      label: (i + 1).toString(),
      value: i + 1
    }))

    const months = Array.from({ length: 12 }, (_, i) => ({
      label: dayjs().month(i).format('MMMM'),
      value: i + 1
    }))

    const currentYear = new Date().getFullYear()
    const years = Array.from({ length: 2 }, (_, i) => ({
      label: (currentYear + i).toString(),
      value: currentYear + i
    }))

    return [days, months, years]
  }

  const handleOpenVirtualConsultation = () => {
    dispatch(
      coreReducer.actions.addTab({
        key: CoreEnums.tabKeys.PAT_VIRTUAL_CONSULT_SESSION,
        closable: true,
        label: CoreEnums.tabTitles.PAT_VIRTUAL_CONSULT_SESSION,
        children: (
          <MobUberVirtual appointment={appointment} channelId={appointment._id} userId={authState.agiliteUser._id} />
        )
      })
    )
  }

  return (
    <div>
      <motion.div
        className='glass-appointment-card'
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        style={{ position: 'relative', background: '#fff' }}
      >
        <div className='card-preview' onClick={() => setIsExpanded(!isExpanded)}>
          <div className='appointment-header'>
            <div className='doctor-info'>
              <div>
                <h3>{`${appointment.medicalProfProfile.firstName} ${appointment.medicalProfProfile.lastName}`}</h3>
                <span className='specialty'>{getServiceName()}</span>
              </div>
              <Space style={{ justifyContent: 'flex-end' }} direction='vertical'>
                <div className='appointment-type'>{appointment.isVirtualVisit ? 'Virtual Visit' : 'In-Person'}</div>
              </Space>
            </div>
          </div>

          <div className='preview-details'>
            <div className='detail-item'>
              <FontAwesomeIcon icon={faClock} className='detail-icon' />
              <span className='detail-value'>{formatDate(appointment.bookingDate, appointment.startTime)}</span>
            </div>
          </div>

          {appointment.isVirtualVisit ? null : (
            <button className={`expand-button ${isExpanded ? 'expanded' : ''}`} aria-label='Toggle details'>
              <FontAwesomeIcon icon={faChevronDown} />
            </button>
          )}
        </div>

        <AnimatePresence>
          {isExpanded && clinic && !appointment.isVirtualVisit && (
            <motion.div
              className='expanded-content'
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className='appointment-details'>
                <div className='detail-item with-action'>
                  <div className='detail-content'>
                    <div className='detail-header'>
                      <FontAwesomeIcon icon={faKey} className='detail-icon' />
                      <span className='detail-label'>OTP</span>
                    </div>
                    <span className='detail-value'>{appointment.otp}</span>
                  </div>
                </div>

                {/* Travel details */}
                <div className='detail-item'>
                  <div className='detail-content'>
                    <div className='detail-header'>
                      <FontAwesomeIcon icon={faCar} className='detail-icon' />
                      <span className='detail-label'>Travel Details</span>
                    </div>
                    <span className='detail-value'>{clinic.physicalAddress}</span>
                  </div>
                </div>

                {/* Contact details */}
                <div className='detail-row'>
                  <div className='detail-item with-action'>
                    <div className='detail-content'>
                      <div className='detail-header'>
                        <FontAwesomeIcon icon={faPhone} className='detail-icon' />
                        <span className='detail-label'>Contact</span>
                      </div>
                      <span className='detail-value'>{clinic.phoneNumber}</span>
                    </div>
                    <ActionButton onClick={() => handlePhoneClick(appointment.contactNumber)} icon={faPhone} />
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        {appointment.isVirtualVisit ? (
          <div
            style={{
              padding: '10px 0',
              marginTop: '10px',
              textAlign: 'center',
              border: '1px solid #4CAF50',
              color: '#4CAF50',
              width: '100%',
              background: 'rgba(76, 175, 80, 0.1)'
            }}
            className='appointment-type'
            onClick={handleOpenVirtualConsultation}
          >
            Join Virtual Consultation <FontAwesomeIcon icon={faCaretRight} />
          </div>
        ) : undefined}
      </motion.div>
    </div>
  )
}

export default MobUpcomingAppointmentCard
