import React from 'react'
import { Button, Form, InputNumber } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import '../styles/medicationCard.css'

const FREQUENCY_OPTIONS = [
  { value: '6 hrly', label: '6 hrly' },
  { value: '8 hrly', label: '8 hrly' },
  { value: '12 hrly', label: '12 hrly' },
  { value: 'Daily', label: 'Daily' },
  { value: 'As needed', label: 'As needed' },
  { value: 'Once-off', label: 'Once-off' }
]

const FrequencyField = ({ selectedFrequency, onSelect, onClear, className, onOnceOffSelect }) => {
  const handleFrequencySelect = (value) => {
    onSelect(value)
    if (value === 'Once-off') {
      onOnceOffSelect?.()
    }
  }

  const handleCustomFrequency = (value) => {
    if (value) {
      handleFrequencySelect(`${value} hrly`)
    }
  }

  const getDisplayLabel = (frequency) => {
    const predefinedOption = FREQUENCY_OPTIONS.find(opt => opt.value === frequency)
    if (predefinedOption) {
      return predefinedOption.label
    }
    // For custom numeric frequencies, keep the hrly format
    if (frequency?.endsWith(' hrly')) {
      const number = frequency.split(' ')[0]
      return `${number} hrly`
    }
    return frequency
  }

  return (
    <div className="field-container">
      <div className="field-label">Frequency</div>
      <div className={`medication-field ${className || ''}`} style={{ border: className ? '1px solid #ff4d4f' : '1px solid #d9d9d9' }}>
        {!selectedFrequency ? (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              alignItems: 'center'
            }}
          >
            {FREQUENCY_OPTIONS.map((option) => (
              <Button
                key={option.value}
                type='text'
                onClick={() => handleFrequencySelect(option.value)}
                style={{ padding: '4px 15px', height: 'auto' }}
              >
                {option.label}
              </Button>
            ))}
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <InputNumber
                style={{ width: 100 }}
                placeholder="Hours..."
                min={1}
                max={24}
                onChange={handleCustomFrequency}
                onPressEnter={(e) => handleCustomFrequency(e.target.value)}
                onBlur={(e) => handleCustomFrequency(e.target.value)}
                controls={false}
              />
              <span>hrly</span>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}
          >
            <div className='medication-field-selected'>
              <CheckOutlined />
              <span>
                {getDisplayLabel(selectedFrequency)}
              </span>
            </div>
            <Button type='text' size='small' onClick={onClear} style={{ padding: '0 4px', height: 'auto' }}>
              ×
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default FrequencyField
