// src/index.js
import React from 'react'
import App from './App'
import BluetoothManager from './components/BluetoothManager'

const LinkTop = ({ userRef, bookingRef, setModalOpen, handleOnAfterSuccess }) => {
  return (
    <BluetoothManager>
      <App
        userRef={userRef}
        bookingRef={bookingRef}
        setModalOpen={setModalOpen}
        handleOnAfterSuccess={handleOnAfterSuccess}
      />
    </BluetoothManager>
  )
}

export default LinkTop
