import React, { useEffect } from 'react'
import { Select, theme, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useSearch } from '../hooks/useSearch'

const SearchBar = ({ onSelect, style = {} }) => {
  const { token } = theme.useToken()
  const { searchFilter, setSearchFilter, nappiOptions, loading, preloadMedications } = useSearch()

  // Force reload medications data if window cache is empty or corrupt
  useEffect(() => {
    if (!window.medicationsCache || !Array.isArray(window.medicationsCache) || window.medicationsCache.length === 0) {
      preloadMedications?.()
    }
  }, [preloadMedications])

  const handleSearch = (value) => {
    setSearchFilter(value)
  }

  const handleSelect = (product) => {
    try {
      if (!product) {
        console.error('SearchBar: No product selected')
        return
      }

      const parsedProduct = JSON.parse(product)

      onSelect(parsedProduct)
    } catch (error) {
      console.error('SearchBar: Error selecting product:', error)
      message.error('Error selecting product. Please try again.')
    }
  }

  return (
    <Select
      value={null}
      placeholder='Search drug by trade name'
      style={{
        width: style.width || 600,
        maxWidth: '100%',
        textAlign: 'left',
        height: 50,
        borderRadius: 100,
        overflow: 'hidden',
        border: `2px ${token.colorPrimary} solid`,
        boxShadow: '0 2px 20px 0px rgba(0, 0, 0, 0.1)',
        ...style
      }}
      suffixIcon={<SearchOutlined style={{ fontSize: 20, color: token.colorPrimary }} />}
      showSearch
      onSearch={handleSearch}
      onSelect={handleSelect}
      notFoundContent={loading ? 'Loading medications...' : 'Type to search...'}
      optionFilterProp='search'
      options={nappiOptions}
      filterOption={(input, option) => option?.search?.toLowerCase()?.includes(input.toLowerCase())}
    />
  )
}

export default React.memo(SearchBar)
