import React from 'react'
import { Button, Col, Row, Switch } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartPulse, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Templates from '../../../../Medical Vault/medical-history/utils/templates'
import '../style/MobLifestyle.css'
import useLifestyle from '../../hooks/useLifestyle'
import { LIFESTYLE_SECTIONS } from '../../utils/constants'
import MobSectionHeader from './MobSectionHeader'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'

const MobLifestyle = ({ setFacet, setSaving }) => {
  const { handleSubmit, handleValueChange, lifestyleData, setLifestyleData } = useLifestyle({
    setFacet,
    setSaving
  })

  const renderFormItem = (key, template, section) => {
    switch (template.type) {
      case 'switch':
        return (
          <Switch
            checkedChildren={template.checkedChildren}
            unCheckedChildren={template.uncheckedChildren}
            checked={lifestyleData[key]}
            onChange={(value) => handleValueChange(key, value)}
          />
        )
      case 'radioGroup':
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '8px' }}>
            {template.options.map((opt) => {
              const isChecked = lifestyleData[key] === opt.value

              return (
                <div
                  key={opt.value}
                  onClick={() => handleValueChange(key, opt.value)}
                  role='checkbox'
                  aria-checked={isChecked}
                  tabIndex={0}
                  style={{
                    width: '100%',
                    borderRadius: '16px',
                    padding: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    position: 'relative',
                    overflow: 'hidden',
                    minHeight: '76px',
                    userSelect: 'none',
                    backgroundColor: isChecked ? `rgba(${section.color}, 0.2)` : 'transparent',
                    border: `2px solid ${isChecked ? `rgb(${section.color})` : 'lightgray'}`
                  }}
                >
                  <div
                    style={{
                      width: '40px',
                      height: '40px',
                      background: `rgba(${section.color}, 0.1)`,
                      borderRadius: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: `rgb(${section.color})`,
                      fontSize: '18px',
                      flexShrink: 0
                    }}
                  >
                    <FontAwesomeIcon icon={section.icon} />
                  </div>
                  <span style={{ fontSize: '16px', fontWeight: '500', marginRight: '40px', flex: 1 }}>{opt.label}</span>
                </div>
              )
            })}
          </div>
        )
      default:
        return null
    }
  }

  const renderSection = (section) => {
    const template = Templates.dataModel[section.key]

    if (template.dependency) {
      const [_, dependencyField] = template.dependency
      if (!lifestyleData[dependencyField]) return null
    }

    const dependentFields = Object.entries(Templates.dataModel).filter(
      ([_, fieldTemplate]) => fieldTemplate.dependency && fieldTemplate.dependency[1] === section.key
    )

    return (
      <React.Fragment key={section.key}>
        <div
          style={{
            border: `1px solid rgba(${section.color}, 0.2)`,
            borderRadius: '16px',
            overflow: 'hidden',
            animation: 'fadeIn 0.3s ease-out',
            marginBottom: '16px'
          }}
          data-section={section.key}
        >
          <div
            style={{
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              background: `rgba(${section.color}, 0.1)`,
              borderBottom: `1px solid rgba(${section.color}, 0.2)`
            }}
          >
            <div
              style={{
                width: '40px',
                height: '40px',
                background: `rgba(${section.color}, 0.15)`,
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: `rgb(${section.color})`,
                fontSize: '18px'
              }}
            >
              <FontAwesomeIcon icon={section.icon} />
            </div>
            <h3 style={{ color: `rgb(${section.color})`, fontSize: '16px', fontWeight: '600', margin: 0 }}>
              {section.title}
            </h3>
          </div>

          <div style={{ padding: '16px' }}>
            <div style={{ marginBottom: '0 !important' }}>
              <label>{template.label}</label>
              {renderFormItem(section.key, template, section)}
            </div>
          </div>
        </div>

        {lifestyleData[section.key] &&
          dependentFields.map(([depKey, depTemplate]) => (
            <div
              key={depKey}
              style={{
                border: `1px solid rgba(${section.color}, 0.2)`,
                borderRadius: '16px',
                overflow: 'hidden',
                animation: 'fadeIn 0.3s ease-out',
                marginBottom: '16px',
                marginTop: '-8px',
                borderTop: 'none',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0
              }}
            >
              <div style={{ padding: '16px' }}>
                <div style={{ marginBottom: '0 !important' }}>
                  <label>{depTemplate.label}</label>
                  {renderFormItem(depKey, depTemplate, section)}
                </div>
              </div>
            </div>
          ))}
      </React.Fragment>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',

        position: 'relative',
        overflowY: 'auto',
        paddingBottom: '140px'
      }}
    >
      <MobSectionHeader
        icon={faHeartPulse}
        title='Lifestyle'
        subtitle='Tell us about your daily habits and lifestyle'
      />

      <Row gutter={[16, 16]}>
        {LIFESTYLE_SECTIONS.map((section) => (
          <Col span={24}>{renderSection(section)}</Col>
        ))}
      </Row>

      <PrevNextFloatingButtons
        onPrev={() => {
          setLifestyleData({
            diet: '',
            physicalActivity: '',
            sleepHours: '',
            waterIntake: '',
            screenTime: '',
            stress: '',
            alcoholConsumption: false,
            alcoholConsumptionLevel: '',
            smoking: false,
            smokingLevel: '',
            smokingYears: '',
            vaping: false,
            vapingLevel: '',
            vapingYears: ''
          })
          setFacet(null)
        }}
        onNext={handleSubmit}
        prevText='Cancel'
        nextText='Save'
      />
    </div>
  )
}

export default MobLifestyle
