import React, { useState, useEffect } from 'react'
import { Divider } from 'antd'
import { diagnosticTestCategories } from '../../../../mockdata/diagnosticTestsData'
import Header from '../Header'
import QuickInfo from './components/QuickInfo'
import TestDescription from './components/TestDescription'
import TestBenefits from './components/TestBenefits'
import PreparationRequirements from './components/PreparationRequirements'
import ReferenceRange from './components/ReferenceRange'
import WarningSection from './components/WarningSection'
import SpecimenModal from './components/SpecimenModal'
import './styling/TestDetail.css'

// Specimen code mapping
const specimenCodes = {
  'W': 'Whole Blood',
  'S': 'Serum',
  'P': 'Plasma',
  'U': 'Urine',
  'SA': 'Saliva',
  'NP': 'Nasal Pharyngeal',
  'OP': 'Oropharyngeal',
  'NP/OP': 'Nasal/Oropharyngeal Combined',
  'NS': 'Nasal Secretion',
  'TS': 'Throat Swab'
}

const TestDetail = ({ test, onBack }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  // Add useEffect to scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const formatPrice = (price) => `R${(price / 100).toFixed(2)}`
  
  // Find the category for this test
  const category = diagnosticTestCategories.find(cat => 
    cat.tests.some(t => t.code === test.code)
  )

  // Get only the specimen codes used in this test
  const getRelevantSpecimenCodes = () => {
    const testSpecimens = test.specimen.split('/')
    return Object.entries(specimenCodes)
      .filter(([code]) => testSpecimens.includes(code))
      .reduce((acc, [code, desc]) => ({
        ...acc,
        [code]: desc
      }), {})
  }

  return (
    <div>
      <Header 
        onBack={onBack}
        title={test.name}
        titleIcon={category?.icon}
        titleIconColor={category?.color}
        fontSize={20}
      />
      <div className="test-detail">
        <QuickInfo 
          test={test}
          onShowSpecimenModal={() => setIsModalVisible(true)}
          formatPrice={formatPrice}
        />

        <Divider className="test-detail-divider" />

        <div className="test-detail-content">
          <TestDescription description={test.longDescription} />
          <TestBenefits benefits={test.keyBenefits} />
          <PreparationRequirements requirements={test.preparationRequirements} />
          <ReferenceRange ranges={test.referenceRanges} />
          <WarningSection />
        </div>

        <SpecimenModal 
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          specimenCodes={getRelevantSpecimenCodes()}
        />
      </div>
    </div>
  )
}

export default TestDetail 