import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import coreReducer from '../../../../core/utils/reducer'
import { allTrim, handleError } from '../../../lib/utils'
import { Form, message } from 'antd'
import { createAgiliteUser, findAgiliteUser, updateAgiliteUser } from '../../../Auth/utils/utils'
import { sendSignInLinkToEmail } from 'firebase/auth'
import { firebaseAuth } from '../../../..'
import systemUsersReducer from '../utils/reducer'
import SystemUsersTemplates from '../utils/templates'

const useGeneralForm = ({ isNewRecord, activeRecords, dataIndex, refreshView, closeTab, type, setViewType }) => {
  const [clientForm] = Form.useForm()
  const authState = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const coreState = useSelector((state) => state.core)
  const [loading, setLoading] = useState(false)
  const [deactivatePrompt, setDeactivatePrompt] = useState(false)
  const [specialFormState, setSpecialFormState] = useState([])

  const [unfilledProfileFields, setUnfilledProfileFields] = useState(0)
  const [unfilledMedicalFields, setUnfilledMedicalFields] = useState(0)
  const [record, setRecord] = useState({})
  const clinicState = useSelector((state) => state.clinics.data)

  const formatNumber = useCallback((phoneNumber) => {
    return phoneNumber.charAt(0) === '0' ? `+27${phoneNumber.slice(1)}` : `+27${phoneNumber}`
  }, [])

  const handleSubmit = useCallback(async () => {
    const record = allTrim(clientForm.getFieldsValue(true))
    let phoneNumber = null
    let user = null

    try {
      setLoading(true)

      if (['medical_professional', 'reception'].includes(record.extraData.role.type)) {
        if (record.extraData.services.length < 1) {
          setLoading(false)
          return message.error('Please select at least one service')
        }
        if (record.extraData.clinics.length < 1) {
          setLoading(false)
          return message.error('Please select at least one clinic')
        }
      }

      if (isNewRecord) {
        if (record.phoneNumber) {
          phoneNumber = formatNumber(record.phoneNumber)
          user = await findAgiliteUser({ phoneNumber })
        }
        if (record.email) {
          user = await findAgiliteUser({ email: record.email })
        }

        if (user) {
          setLoading(false)
          return message.error('User already exists. Please revise.')
        } else {
          user = await createAgiliteUser({ ...record, phoneNumber }, false, authState.agiliteUser._id)

          if (record.email) {
            await sendSignInLinkToEmail(firebaseAuth, record.email, {
              url: window.location.href,
              handleCodeInApp: true
            })
          }

          if (phoneNumber) {
            // TODO: Implement SMS sending for sign-in link
          }

          message.success('User created successfully.')
        }
      } else {
        await updateAgiliteUser(activeRecords[dataIndex]?._id, {
          'extraData.isActive': record.extraData.isActive,
          'extraData.role.type': record.extraData.role.type,
          'extraData.role.isManager': record.extraData.role.isManager,
          'extraData.profession': record.extraData.profession,
          'extraData.services': record.extraData.services,
          'extraData.clinics': record.extraData.clinics,
          'extraData.entities': record.extraData.entities,
          'extraData.mpNumber': record.extraData.mpNumber,
          'extraData.qualification': record.extraData.qualification,
          'extraData.hasAvailabilityAccess': record.extraData.hasAvailabilityAccess
        })

        message.success(
          `User: ${activeRecords[dataIndex]?.firstName} ${activeRecords[dataIndex]?.lastName} - updated successfully`
        )
      }

      closeTab()

      if (coreState.tourOpen) {
        dispatch(coreReducer.actions.nextTourStep())
      }
    } catch (e) {
      message.error(handleError(e, true))
    } finally {
      setLoading(false)
    }
  }, [clientForm, isNewRecord, activeRecords, dataIndex, authState, coreState, dispatch, closeTab, formatNumber])

  const handleIsActive = useCallback(
    (value) => {
      setDeactivatePrompt(activeRecords[dataIndex]?.extraData.isActive && !value)
    },
    [activeRecords, dataIndex]
  )

  const handleRolesSelection = useCallback(() => {
    if (type === 'system' && isNewRecord) {
      return authState.roles.filter((role) => !['patient', 'medical_professional'].includes(role.value))
    }
    return authState.roles
  }, [authState.roles, type, isNewRecord])

  useEffect(() => {
    const viewTypeMap = {
      system: 'System User',
      medical: 'Medical Professional',
      patient: 'Patient'
    }
    setViewType(viewTypeMap[type] || 'System User')

    if (isNewRecord) {
      const roleType = type === 'medical' ? 'medical_professional' : type === 'patient' ? 'patient' : null
      if (roleType) {
        handleSetFormData({ extraData: { role: { type: roleType, isManager: false } } })
        clientForm.setFieldValue(['extraData', 'role', 'type'], roleType)
      }
    }
    // eslint-disable-next-line
  }, [type, isNewRecord, setViewType, clientForm])

  const handleSetFormData = useCallback(
    (data) => {
      const updatedRecords = [...activeRecords]
      updatedRecords[dataIndex] = {
        ...updatedRecords[dataIndex],
        ...data,
        extraData: { ...updatedRecords[dataIndex].extraData, ...data.extraData }
      }
      dispatch(systemUsersReducer.actions.setActiveRecords(updatedRecords))
    },
    [activeRecords, dataIndex, dispatch]
  )

  const profileRequiredFields = [
    ...(isNewRecord
      ? [
          SystemUsersTemplates.dataModel.firstName.key,
          SystemUsersTemplates.dataModel.lastName.key,
          SystemUsersTemplates.dataModel.email.key,
          SystemUsersTemplates.dataModel.phoneNumber.key
        ]
      : []),
    authState.agiliteUser?.extraData?.role?.type === 'super_admin' ? SystemUsersTemplates.dataModel.entities.key : null,
    'extraData.role.type',
    'extraData.clinics'
  ].filter(Boolean)

  const medicalRequiredFields = [
    'extraData.profession',
    ...(record?.extraData?.profession === 'doctor' ? ['extraData.mpNumber', 'extraData.qualification'] : []),
    'extraData.services'
  ]

  const calculateUnfilledFields = (fields, requiredFields) => {
    return requiredFields.reduce((count, field) => {
      if (typeof field !== 'string') return count

      let fieldValue = fields

      // Traverse nested fields
      const keys = field.split('.')
      for (let i = 0; i < keys.length; i++) {
        if (!fieldValue || typeof fieldValue !== 'object') {
          fieldValue = undefined
          break
        }
        fieldValue = fieldValue[keys[i]]
      }

      // Check if the field is an array and has at least one item
      if (Array.isArray(fieldValue)) {
        return fieldValue.length > 0 ? count : count + 1
      }

      // Check if the field is a non-empty string or a truthy value
      if (typeof fieldValue === 'string') {
        return fieldValue.trim() ? count : count + 1
      }
      return fieldValue ? count : count + 1
    }, 0)
  }

  const handleFieldsChange = () => {
    const allFields = clientForm.getFieldsValue()
    setRecord(allFields)
    setUnfilledProfileFields(calculateUnfilledFields(allFields, profileRequiredFields))
    setUnfilledMedicalFields(calculateUnfilledFields(allFields, medicalRequiredFields))
  }

  return {
    dispatch,
    handleIsActive,
    handleRolesSelection,
    handleSetFormData,
    handleSubmit,
    setSpecialFormState,
    deactivatePrompt,
    clientForm,
    loading,
    specialFormState,
    unfilledProfileFields,
    unfilledMedicalFields,
    record,
    setRecord,
    clinicState,
    setUnfilledProfileFields,
    setUnfilledMedicalFields,
    handleFieldsChange
  }
}

export default useGeneralForm
