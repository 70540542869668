import React, { useState, useEffect } from 'react'
import { Card, Radio, DatePicker, Button, message, Row, Col, Divider } from 'antd'
import dayjs from 'dayjs'
import { updateCovidInfo } from '../../../Patient/Medical History/api/api'
import { handleError } from '../../../lib/utils'

const CovidInfoTab = ({ covidInfo = {}, recordId, setSaving, updateMedicalHistory }) => {
  const [hadCovid, setHadCovid] = useState(false)
  const [fullyVaccinated, setFullyVaccinated] = useState(false)
  const [lastVaccinatedDate, setLastVaccinatedDate] = useState(null)

  // Initialize state from props
  useEffect(() => {
    if (covidInfo) {
      setHadCovid(covidInfo.hadCovid || false)
      setFullyVaccinated(covidInfo.fullyVaccinated || false)
      setLastVaccinatedDate(covidInfo.lastVaccinatedDate ? dayjs(covidInfo.lastVaccinatedDate) : null)
    }
  }, [covidInfo])

  const handleSave = async () => {
    setSaving(true)
    try {
      const updatedCovidInfo = {
        hadCovid,
        fullyVaccinated,
        lastVaccinatedDate: lastVaccinatedDate ? lastVaccinatedDate.toISOString() : null,
        updatedAt: new Date().toISOString()
      }

      const newData = await updateCovidInfo(recordId, updatedCovidInfo)
      updateMedicalHistory(newData)
      message.success('COVID-19 information updated successfully')
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setSaving(false)
    }
  }

  return (
    <div className='covid-info-tab'>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title='COVID-19 History' className='covid-card'>
            <div style={{ marginBottom: '16px' }}>
              <div style={{ marginBottom: '8px' }}>Has the patient had COVID-19?</div>
              <Radio.Group value={hadCovid} onChange={(e) => setHadCovid(e.target.value)}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
          </Card>
        </Col>

        <Col span={24}>
          <Card title='Vaccination Status' className='covid-card'>
            <div style={{ marginBottom: '16px' }}>
              <div style={{ marginBottom: '8px' }}>Is the patient fully vaccinated?</div>
              <Radio.Group value={fullyVaccinated} onChange={(e) => setFullyVaccinated(e.target.value)}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>

            {fullyVaccinated && (
              <div>
                <div style={{ marginBottom: '8px' }}>Date of last vaccination:</div>
                <DatePicker value={lastVaccinatedDate} onChange={setLastVaccinatedDate} style={{ width: '100%' }} />
              </div>
            )}
          </Card>
        </Col>

        <Col span={24}>
          <Button type='primary' onClick={handleSave} block>
            Save COVID-19 Information
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CovidInfoTab
