import React from 'react'
import { Card, Typography } from 'antd'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import '../../../../styling/TrendGraph.css'

const { Text } = Typography

const TrendGraph = ({ data = [], metrics, isDemo }) => {
  if (!data?.length) return null

  const formattedData = data.map((item) => ({
    ...item,
    value: typeof item.value === 'object' ? item.value.value : item.value
  }))

  return (
    <Card className='cs-trend-graph'>
      <Text type='secondary' className='cs-trend-graph__title'>
        Trend Over Time {isDemo && '(Demo Data)'}
      </Text>
      <div className='cs-trend-graph__container'>
        <ResponsiveContainer>
          <LineChart data={formattedData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <CartesianGrid strokeDasharray='3 3' stroke='rgba(255,255,255,0.1)' />
            <XAxis 
              dataKey='date' 
              stroke='rgba(255,255,255,0.65)' 
              tick={{ fill: 'rgba(255,255,255,0.65)' }}
              tickFormatter={date => new Date(date).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}
            />
            <YAxis stroke='rgba(255,255,255,0.65)' tick={{ fill: 'rgba(255,255,255,0.65)' }} />
            <Tooltip
              contentStyle={{
                background: '#141824',
                border: '1px solid rgba(16, 178, 225, 0.15)',
                borderRadius: '4px'
              }}
              labelStyle={{ color: 'rgba(255,255,255,0.85)' }}
              labelFormatter={date => new Date(date).toLocaleDateString()}
              className='cs-trend-graph__tooltip'
            />
            <Line
              type='monotone'
              dataKey='value'
              stroke='#10b2e1'
              strokeWidth={2}
              dot={{ fill: '#141824', stroke: '#10b2e1', strokeWidth: 2 }}
              activeDot={{ r: 6, stroke: '#10b2e1', strokeWidth: 2 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      {isDemo && (
        <Text type="secondary" style={{ fontSize: '11px', marginTop: '8px', display: 'block', textAlign: 'center' }}>
          Sample trend visualization. Actual results will show your real test history.
        </Text>
      )}
    </Card>
  )
}

export default TrendGraph
