import { useState, useEffect } from 'react'
import { Button, Col, message, Row, Space, theme, Popconfirm } from 'antd'
import {
  AudioMutedOutlined,
  AudioOutlined,
  VideoCameraOutlined,
  VideoCameraAddOutlined,
  DisconnectOutlined
} from '@ant-design/icons'

import {
  LocalUser,
  RemoteUser,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers
} from 'agora-rtc-react'
import { faPhoneSlash, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { handleError } from '../../../lib/utils'
import Draggable from 'react-draggable'
import { ResizableBox } from 'react-resizable'
import 'react-resizable/css/styles.css'

const MedProfLiveVideo = ({ channelName, uid, token, disconnectCallback }) => {
  const appId = process.env.REACT_APP_AGORA_APP_ID

  // set the connection state
  const [activeConnection, setActiveConnection] = useState(true)

  // track the mic/video state - Turn on Mic and Camera On
  const [micOn, setMic] = useState(true)
  const [cameraOn, setCamera] = useState(true)

  // get local video and mic tracks
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn)
  const { localCameraTrack } = useLocalCameraTrack(cameraOn)

  // Remote users
  const remoteUsers = useRemoteUsers()
  const { audioTracks } = useRemoteAudioTracks(remoteUsers)

  // Join the channel
  const agoraSession = useJoin(
    {
      appid: appId,
      channel: channelName,
      token,
      uid
    },
    activeConnection
  )

  const [sessionDuration, setSessionDuration] = useState(0)

  useEffect(() => {
    if (activeConnection) {
      if (localCameraTrack) {
        localCameraTrack.setEnabled(cameraOn)
      }

      if (localMicrophoneTrack) {
        localMicrophoneTrack.setEnabled(micOn)
      }
    }

    // eslint-disable-next-line
  }, [cameraOn, micOn, activeConnection])

  useEffect(() => {
    if (agoraSession.error && agoraSession.error.message) {
      message.error('Unknown error occurred. Please contact support.', 5)
      handleError(agoraSession.error.message, true)
    }
  }, [agoraSession])

  useEffect(() => {
    if (agoraSession.isConnected) {
      const startTime = Date.now()

      const durationInterval = setInterval(() => {
        const duration = Math.floor((Date.now() - startTime) / 1000)
        setSessionDuration(duration)
      }, 1000)

      return () => {
        clearInterval(durationInterval)
      }
    }
  }, [agoraSession.isConnected])

  usePublish([localMicrophoneTrack, localCameraTrack])
  audioTracks.forEach((track) => track.play())

  // Add useEffect to handle auto-disconnect
  useEffect(() => {
    let disconnectTimer
    let warningTimer

    if (activeConnection) {
      // Set warning timer for 5 minutes before disconnect
      warningTimer = setTimeout(() => {
        message.warning('Your session will end in 5 minutes', 5)
      }, 3300000) // 55 minutes (1 hour - 5 minutes)

      disconnectTimer = setTimeout(() => {
        handleDisconnect()
      }, 3600000) // 1 hour in milliseconds
    }

    return () => {
      if (disconnectTimer) {
        clearTimeout(disconnectTimer)
      }
      if (warningTimer) {
        clearTimeout(warningTimer)
      }
    }

    // eslint-disable-next-line
  }, [activeConnection])

  const handleDisconnect = () => {
    setActiveConnection(false)
    setMic(false)
    setCamera(false)

    if (disconnectCallback) {
      disconnectCallback()
    }
  }

  const { token: antdToken } = theme.useToken()

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`
  }

  return (
    <Draggable>
      <ResizableBox
        width={500}
        height={300}
        minConstraints={[300, 200]}
        maxConstraints={[800, 600]}
        style={{
          cursor: 'grab',

          position: 'fixed',
          overflow: 'hidden',
          zIndex: 100,
          borderRadius: 10,
          right: 10,
          bottom: 10
        }}
      >
        {/* Timer Display */}
        {/* Remote Users Grid */}
        <div
          style={{
            height: 75,
            width: 75,
            position: 'absolute',
            background: antdToken.colorPrimary,
            right: 20,
            top: 20,
            zIndex: 20,
            borderRadius: '50%',
            overflow: 'hidden'
          }}
        >
          <div
            style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            {cameraOn ? (
              <LocalUser
                audioTrack={localMicrophoneTrack}
                videoTrack={localCameraTrack}
                cameraOn={cameraOn}
                micOn={micOn}
                playAudio={false}
                playVideo={cameraOn}
              />
            ) : (
              <FontAwesomeIcon icon={faVideoSlash} color={antdToken.colorWhite} fontSize={20} />
            )}
          </div>
        </div>
        {/* Local Video - Bottom Right Corner */}
        <div
          style={{
            width: '100%',
            height: '100%',
            zIndex: 100,
            left: 0,
            top: 0
          }}
        >
          <div style={{ height: '100%', width: '100%' }}>
            {remoteUsers.length === 0 && <div></div>}
            {remoteUsers.map((user) => {
              return (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {user.hasVideo ? (
                    <RemoteUser user={user} style={{ width: '100%', height: '100%' }} />
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faVideoSlash} color={antdToken.colorWhite} fontSize={80} />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
        {/* Controls Toolbar */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: 20,
            padding: 10,
            background: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background
            backdropFilter: 'blur(10px)', // Apply blur effect
            WebkitBackdropFilter: 'blur(10px)' // For Safari support
          }}
        >
          <div style={{ color: 'white' }}>{formatTime(sessionDuration)}</div>
          <Space>
            <Button
              type={micOn ? 'primary' : 'default'}
              icon={micOn ? <AudioOutlined /> : <AudioMutedOutlined />}
              onClick={() => setMic((a) => !a)}
              shape='circle'
              size='large'
              style={{
                width: '50px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            />
            <Button
              type={cameraOn ? 'primary' : 'default'}
              icon={cameraOn ? <FontAwesomeIcon icon={faVideo} /> : <FontAwesomeIcon icon={faVideoSlash} />}
              onClick={() => setCamera((a) => !a)}
              shape='circle'
              size='large'
              style={{
                width: '50px',
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            />
            <Popconfirm
              title='Disconnect from call'
              description='Are you sure you want to disconnect?'
              onConfirm={handleDisconnect}
              okText='Yes'
              cancelText='No'
              okButtonProps={{ danger: true }}
            >
              <Button
                danger
                type='primary'
                icon={<FontAwesomeIcon icon={faPhoneSlash} />}
                shape='circle'
                size='large'
                style={{
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              />
            </Popconfirm>
          </Space>
        </div>
      </ResizableBox>
    </Draggable>
  )
}

export default MedProfLiveVideo
