import Agilite from 'agilite'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const readTeamChat = (userRef) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('team_chat', 'read', {
          filter: JSON.stringify({ userRef }),
          page: null,
          pageLimit: null,
          options: JSON.stringify({ lean: true })
        })

        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createTeamChat = (data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('team_chat', 'create', {
          data: JSON.stringify({ ...data })
        })

        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateTeamChat = (data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('team_chat', 'update', {
          recordId: data._id,
          data: JSON.stringify({ ...data }),
          options: JSON.stringify({ new: true })
        })

        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
