import React, { Suspense, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message, Spin } from 'antd'

import Store from '../../../../../store'
import coreReducer from '../../../../../core/utils/reducer'
import { readCompContr } from '../utils/utils'

import CompContrForm from './comp-contr-form'
import { ContainerCard } from '../../../../reusable-components/AgiliteCards'
import { debounce } from 'lodash'
import { handleError } from '../../../../lib/utils'
import CoreEnums from '../../../../../core/utils/enums'
import CompContrList from './comp-contr-list'
import CompContrReducer from '../utils/reducer'

const CompContrWrapper = () => {
  const dispatch = useDispatch()
  const viewData = useSelector((state) => state.compContr.data)
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const refreshView = async () => {
    let filter = null
    let data = []

    setLoading(true)

    try {
      if (searchQuery) {
        filter = { $text: { $search: searchQuery } }
      }

      data = await readCompContr(filter)
      dispatch(CompContrReducer.actions.setRecords(data))
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 1000),
    []
  )

  const handleSearch = (query) => {
    setLoading(true)
    debouncedFilter(query)
  }

  useEffect(() => {
    refreshView()
    // eslint-disable-next-line
  }, [searchQuery])

  const createRecord = () => {
    Store.dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.COMPANIES_CONTRACTORS}_new`,
        closable: true,
        label: 'Register Company/Contractor',
        children: (
          <Suspense fallback={<Spin />}>
            <CompContrForm isNewRecord refreshView={refreshView} />
          </Suspense>
        )
      })
    )
  }

  const editRecord = (record) => {
    Store.dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.COMPANIES_CONTRACTORS}_${record._id}`,
        closable: true,
        label: `Record: ${record.name}`,
        children: (
          <Suspense fallback={<Spin />}>
            <CompContrForm recordId={record._id} isNewRecord={false} refreshView={refreshView} />
          </Suspense>
        )
      })
    )
  }

  return (
    <ContainerCard title='Companies & Contractors'>
      <CompContrList
        loading={loading}
        data={viewData}
        createRecord={createRecord}
        editRecord={editRecord}
        refreshView={refreshView}
        handleSearch={handleSearch}
      />
    </ContainerCard>
  )
}

export default CompContrWrapper
