import React, { useEffect } from 'react'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import { Alert, Col, Collapse, Empty, Space } from 'antd'
import dayjs from 'dayjs'
import CustomRow from '../../../reusable-components/CustomRow'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../../core/utils/reducer'
import CollapsePanel from 'antd/es/collapse/CollapsePanel'
import { deviceDetect } from 'react-device-detect'

const DiagnosisOverview = ({ data }) => {
  const dispatch = useDispatch()
  // const [loadingScripts, setLoadingScripts] = useState(false)
  // const [scripts, setScripts] = useState([])

  useEffect(() => {
    // handleFecthMedications()
    // eslint-disable-next-line
  }, [])

  const closeTab = () => {
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: `Diagnosis: ${dayjs(data.bookingDate).format('YYYY-MM-DD')}`,
        removeBreadcrumb: true
      })
    )
  }
  // const handleFecthMedications = async () => {
  //   let tmpData = null
  //   setLoadingScripts(true)
  //   try {
  //     tmpData = await readScripts({ bookingRef: data._id })
  //     setScripts(tmpData)
  //   } catch (e) {
  //     message.error(handleError(e))
  //   }
  //   setLoadingScripts(false)
  // }
  // const { token } = theme.useToken()

  return (
    <ContainerCard
      title={`${dayjs(data.bookingDate).format('YYYY-MM-DD')} at ${data.startTime}`}
      backFunction={closeTab}
    >
      <CustomRow justify='center'>
        <Col xs={24} sm={24} md={24} lg={12}>
          <CustomRow className='basic-card'>
            {deviceDetect().isMobile && (
              <Col span={24}>
                <p>
                  You saw {data.medicalProfName} on {dayjs(data.bookingDate).format('YYYY-MM-DD')} at {data.startTime}.
                  A summary of your booking is below:
                </p>
              </Col>
            )}
            <Col span={24}>
              <Collapse
                defaultActiveKey={['diagnosis', 'scripts', 'notIntr']}
                style={{ border: '1px rgba(0,0,0,0.055) solid' }}
              >
                {/* Diagnosis Panel */}
                <CollapsePanel header='Diagnosis' key='diagnosis'>
                  <Space wrap>
                    {data.examinationData?.billing?.diagnosis?.length > 0 ? (
                      data.examinationData?.billing?.diagnosis?.map((diagnosis) => (
                        <Alert description={diagnosis.description || 'No Diagnosis'} />
                      ))
                    ) : (
                      <Alert description='No Diagnosis' />
                    )}
                  </Space>
                </CollapsePanel>

                {/* Scripts Panel */}
                {/* <CollapsePanel header='Scripts' key='scripts'>
                  {loadingScripts ? (
                    <AgiliteSkeleton spinnerTip='Loading medications...' skActive />
                  ) : scripts?.length > 0 ? (
                    <Collapse>
                      {scripts.map((script) => (
                        <CollapsePanel header={dayjs(script.createdAt).format('DD MMM YYYY - HH:mm')}>
                          {script.items.map((med, index) => (
                            <li key={index}>{generateMedLabel(med, index)}</li>
                          ))}
                        </CollapsePanel>
                      ))}
                    </Collapse>
                  ) : (
                    <Empty description='No medications have been prescribed' />
                  )}
                </CollapsePanel> */}

                {/* Notes & Instructions Panel */}
                <CollapsePanel header='Notes & Instructions' key='notIntr'>
                  {data.examinationData?.diagnosisData?.patientNotes?.map((note) => (
                    <div key={note.dateCreated} span={24}>
                      <small style={{ opacity: '0.45' }}>
                        {dayjs(note.dateCreated).format('YYYY MM DD') === dayjs(new Date()).format('YYYY MM DD')
                          ? 'Today'
                          : dayjs(note.dateCreated).format('YYYY - MM - DD')}
                        <span> at </span>
                        {dayjs(note.dateCreated).format('HH:mm')}
                        <span> by </span>
                        {note.createdBy}
                      </small>
                      <br />
                      <span>{note.value}</span>
                    </div>
                  ))}
                  {data.examinationData?.diagnosisData?.patientNotes?.length === 0 && (
                    <Col span={24}>
                      <Empty description='No Notes to show' />
                    </Col>
                  )}
                </CollapsePanel>
              </Collapse>
            </Col>
          </CustomRow>
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default DiagnosisOverview
