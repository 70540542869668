import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faCreditCard, 
  faUniversity, 
  faWallet,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons'
import '../styles/PaymentMethodSelection.css'

const orderSummaryData = {
  items: [
    {
      name: 'Virtual Consultation',
      price: 250.00
    },
    {
      name: 'Prescription Fee',
      price: 150.00
    }
  ],
  subtotal: 400.00,
  vat: 60.00, // 15% of subtotal
  total: 460.00
}

const PaymentMethodSelection = ({ onMethodSelect, amount, orderDetails }) => {
  const [isOrderExpanded, setIsOrderExpanded] = useState(false)

  const paymentMethods = [
    {
      id: 'card',
      name: 'Credit/Debit Card',
      icon: faCreditCard,
      description: 'Pay securely with your card',
      processingTime: 'Instant'
    },
    {
      id: 'eft',
      name: 'EFT Payment',
      icon: faUniversity,
      description: 'Direct bank transfer',
      processingTime: '24-48 hours'
    },
    {
      id: 'wallet',
      name: 'Digital Wallet',
      icon: faWallet,
      description: 'Pay with your digital wallet',
      processingTime: 'Instant'
    }
  ]

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className='payment-selection-container'
    >
      <div className='payment-header'>
        <h2>Select Payment Method</h2>
        <div className='amount-display'>Amount Due: R {amount.toFixed(2)}</div>
      </div>

      <div className='order-summary'>
        <div 
          className='order-summary-header'
          onClick={() => setIsOrderExpanded(!isOrderExpanded)}
        >
          <div className='header-content'>
            <h3>Order Summary</h3>
            <span className='total-preview'>R {orderSummaryData.total.toFixed(2)}</span>
          </div>
          <FontAwesomeIcon 
            icon={faChevronDown} 
            className={`expand-icon ${isOrderExpanded ? 'expanded' : ''}`}
          />
        </div>

        <AnimatePresence>
          {isOrderExpanded && (
            <motion.div 
              className='order-details'
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className='order-items'>
                {orderSummaryData.items.map((item, index) => (
                  <div key={index} className='order-item'>
                    <span className='item-name'>{item.name}</span>
                    <span className='item-price'>R {item.price.toFixed(2)}</span>
                  </div>
                ))}
              </div>
              <div className='order-totals'>
                <div className='subtotal'>
                  <span>Subtotal</span>
                  <span>R {orderSummaryData.subtotal.toFixed(2)}</span>
                </div>
                <div className='vat'>
                  <span>VAT (15%)</span>
                  <span>R {orderSummaryData.vat.toFixed(2)}</span>
                </div>
                <div className='total'>
                  <span>Total</span>
                  <span>R {orderSummaryData.total.toFixed(2)}</span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className='payment-methods'>
        {paymentMethods.map((method) => (
          <motion.div
            key={method.id}
            className='payment-method-card'
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => onMethodSelect(method.id)}
          >
            <FontAwesomeIcon icon={method.icon} className='method-icon' />
            <div className='method-details'>
              <h3>{method.name}</h3>
              <p>{method.description}</p>
              <span className='processing-time'>Processing: {method.processingTime}</span>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  )
}

export default PaymentMethodSelection
