import { Card, Col, message } from 'antd'
import React, { useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import CustomButton from '../../reusable-components/CustomButton'
import { generateUniqueUIDFromString, handleError } from '../../lib/utils'
import { generateAgoraRtcToken } from '../../Bookings/utils/utils'
import LiveVideoWrapper from '../../Agora/LiveVideoWrapper'
import { useSelector } from 'react-redux'

const VirtualCheckin = ({
  MetaLogo,
  bookingDetails,
  setView,
  setBookingId,
  setBookingService,
  setCurrentSelection,
  setPatientInput,
  setPatientSelection,
  setOtp
}) => {
  const authState = useSelector((state) => state.auth)
  const [joined, setJoined] = useState(false)
  const [loading, setLoading] = useState(false)
  const [agoraToken, setAgoraToken] = useState(null)
  const [agoraChannel, setAgoraChannel] = useState(null)
  const [agoraUid, setAgoraUid] = useState(null)

  const joinVirtualConsult = async () => {
    let agoraCreds = null
    let tmpUid = null

    setLoading(true)

    try {
      tmpUid = generateUniqueUIDFromString(authState.agiliteUser._id)

      agoraCreds = await generateAgoraRtcToken(bookingDetails._id, tmpUid)

      setAgoraToken(agoraCreds.token)
      setAgoraChannel(agoraCreds.channelName)
      setAgoraUid(tmpUid)
      setJoined(true)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  return (
    <>
      {!joined ? (
        <CustomRow
          gutter={[12, 12]}
          style={{ maxWidth: '100%', alignItems: 'center', minHeight: '100vh' }}
          justify='center'
        >
          <Col style={{ width: 1050, maxWidth: '90%' }}>
            <CustomRow className='basic-card'>
              <Col span={24}>
                <center>
                  <img src={MetaLogo} alt='Meta Logo' style={{ width: 341, maxWidth: '100%' }} />
                </center>
              </Col>
              <Col span={24}>
                <center>
                  <h2 style={{ width: 450, textAlign: 'center' }}>You have successfully checked in.</h2>
                </center>
              </Col>
              <Col span={24}>
                <center>
                  <h2 style={{ width: 450, textAlign: 'center' }}>You can now join the Virtual Consultation.</h2>
                </center>
              </Col>
              <Col span={24}>
                <center>
                  <CustomButton
                    type='secondary'
                    style={{ width: '50%' }}
                    loading={loading}
                    onClick={() => joinVirtualConsult()}
                    text={'Join Virtual Consultation'}
                  />
                </center>
              </Col>
            </CustomRow>
          </Col>
        </CustomRow>
      ) : (
        <CustomRow
          gutter={[12, 12]}
          style={{ maxWidth: '100%', alignItems: 'center', minHeight: '100vh' }}
          justify='center'
        >
          <Col span={18}>
            <Card>
              <LiveVideoWrapper channelName={agoraChannel} token={agoraToken} uid={agoraUid} />
            </Card>
          </Col>
        </CustomRow>
      )}
    </>
  )
}

export default VirtualCheckin
