import React from 'react'
import usePatientHealthStats from '../../hooks/usePatHealthStats'
import { LineChart, ResponsiveContainer, XAxis, YAxis, Line } from 'recharts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDroplet,
  faHeart,
  faWeightScale,
  faRuler,
  faHeartPulse,
  faLungs,
  faTemperatureHalf
} from '@fortawesome/free-solid-svg-icons'

const MobPatHealthMetrics = () => {
  const { handleScroll, activeIndex, patHealthMetrics, transformHealthData, getLatestReading } = usePatientHealthStats()

  return (
    <div>
      <div className='stats-carousel' onScroll={handleScroll}>
        {[
          {
            title: 'Blood Pressure',
            key: 'bloodPressure',
            unit: 'mmHg',
            icon: <FontAwesomeIcon icon={faHeart} color='#e74c3c' />,
            getValue: (metrics) => {
              const latest = getLatestReading(metrics, 'bloodPressure')
              if (!latest?.value) return '--/--'
              const systolic = latest.value.find((v) => v.key === 'systolic')?.value
              const diastolic = latest.value.find((v) => v.key === 'diastolic')?.value
              return systolic && diastolic ? `${systolic}/${diastolic}` : '--/--'
            },
            getDate: (metrics) => {
              const latest = getLatestReading(metrics, 'bloodPressure')
              return latest?.dateCreated
            }
          },
          {
            title: 'Temperature',
            key: 'temperature',
            unit: '°C',
            icon: <FontAwesomeIcon icon={faTemperatureHalf} color='#e67e22' />
          },
          {
            title: 'Pulse',
            key: 'pulse',
            unit: 'bpm',
            icon: <FontAwesomeIcon icon={faHeartPulse} color='#9b59b6' />
          },
          {
            title: 'Oxygen',
            key: 'oxygenSaturation',
            unit: '%',
            icon: <FontAwesomeIcon icon={faLungs} color='#2ecc71' />
          },
          {
            title: 'Blood Sugar',
            key: 'bloodSugar',
            unit: 'mmol/L',
            icon: <FontAwesomeIcon icon={faDroplet} color='#e74c3c' />
          },
          {
            title: 'Cholesterol',
            key: 'cholesterol',
            unit: 'mmol/L',
            icon: <FontAwesomeIcon icon={faHeart} color='#e91e63' />
          },
          {
            title: 'Weight',
            key: 'weight',
            unit: 'kg',
            icon: <FontAwesomeIcon icon={faWeightScale} color='#3498db' />
          },
          {
            title: 'Waist',
            key: 'waist',
            unit: 'cm',
            icon: <FontAwesomeIcon icon={faRuler} color='#f1c40f' />
          }
        ].map((metric, index) => (
          <div key={index} className='stats-card'>
            <div className='heart-rate-display'>
              <div className='metric-info'>
                <div className='metric-title'>{metric.title}</div>
                <div className='value-container'>
                  <span className='bpm-value'>
                    {metric.getValue
                      ? metric.getValue(patHealthMetrics)
                      : getLatestReading(patHealthMetrics, metric.key)?.value || '--'}
                  </span>
                  <span className='bpm-label'>{metric.unit}</span>
                </div>
              </div>
              <div className='heart-icon'>{metric.icon}</div>
            </div>
            <div className='date-display'>
              {metric.getDate
                ? new Date(metric.getDate(patHealthMetrics)).toLocaleDateString('en-GB', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })
                : new Date(getLatestReading(patHealthMetrics, metric.key)?.dateCreated).toLocaleDateString('en-GB', {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })}
            </div>
            <div className='graph-container'>
              <ResponsiveContainer width='90%' height={140}>
                <LineChart
                  data={transformHealthData(patHealthMetrics, metric.key)}
                  margin={{ top: 10, right: 10, left: -20, bottom: 0 }}
                >
                  <XAxis dataKey='time' axisLine={false} tickLine={false} tick={false} interval={0} />
                  <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 10 }} />
                  {metric.key === 'bloodPressure' ? (
                    <>
                      <Line
                        type='natural'
                        dataKey='systolic'
                        stroke='#e74c3c'
                        strokeWidth={3}
                        dot={false}
                        activeDot={false}
                      />
                      <Line
                        type='natural'
                        dataKey='diastolic'
                        stroke='#e74c3c'
                        strokeWidth={3}
                        dot={false}
                        activeDot={false}
                        strokeDasharray='5 5'
                      />
                    </>
                  ) : (
                    <Line
                      type='natural'
                      dataKey='value'
                      stroke='#10B2E1'
                      strokeWidth={3}
                      dot={false}
                      activeDot={false}
                    />
                  )}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        ))}
      </div>

      <div className='health-stats-indicators'>
        {[
          {
            title: 'Blood Pressure',
            key: 'bloodPressure',
            unit: 'mmHg',
            icon: <FontAwesomeIcon icon={faHeart} color='white' />
          },
          {
            title: 'Temperature',
            key: 'temperature',
            unit: '°C',
            icon: <FontAwesomeIcon icon={faTemperatureHalf} color='white' />
          },
          {
            title: 'Pulse',
            key: 'pulse',
            unit: 'bpm',
            icon: <FontAwesomeIcon icon={faHeartPulse} color='white' />
          },
          {
            title: 'Oxygen',
            key: 'oxygenSaturation',
            unit: '%',
            icon: <FontAwesomeIcon icon={faLungs} color='white' />
          },
          {
            title: 'Blood Sugar',
            key: 'bloodSugar',
            unit: 'mmol/L',
            icon: <FontAwesomeIcon icon={faDroplet} color='white' />
          },
          {
            title: 'Cholesterol',
            key: 'cholesterol',
            unit: 'mmol/L',
            icon: <FontAwesomeIcon icon={faHeart} color='white' />
          },
          {
            title: 'Weight',
            key: 'weight',
            unit: 'kg',
            icon: <FontAwesomeIcon icon={faWeightScale} color='white' />
          },
          {
            title: 'Waist',
            key: 'waste',
            unit: 'cm',
            icon: <FontAwesomeIcon icon={faRuler} color='white' />
          }
        ].map((metric, index) => (
          <div key={index} className={`health-stats-indicator ${activeIndex === index ? 'active' : ''}`} />
        ))}
      </div>
    </div>
  )
}

export default MobPatHealthMetrics
