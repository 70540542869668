import React from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Button, Card, Col, theme } from 'antd'
import { hexToRGBA } from '../../lib/utils'
import useHealthAlternatives from '../hooks/useHealthAlternatives'

const HealthAlternativeItem = ({ healthyAlternative, isNew, handleBack }) => {
  const { savingHealthyAlternative, handleSaveHealthyAlternative } = useHealthAlternatives()
  const { token } = theme.useToken()
  return (
    <CustomRow>
      <h1>{healthyAlternative.recipeName}</h1>
      <Card style={{ width: '100%', backgroundColor: '#f9f9f9', borderColor: '#d9d9d9' }}>
        <Col span={24}>
          <h2>Ingredients</h2>
          <ul>
            {healthyAlternative.ingredients.map((i) => {
              return (
                <li>
                  {i.quantity} {i.unit} {i.name}
                </li>
              )
            })}
          </ul>
        </Col>{' '}
      </Card>

      <Card
        style={{ width: '100%', backgroundColor: '#f9f9f9', borderColor: '#d9d9d9' }}
        headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
      >
        <Col span={24}>
          {' '}
          <h2>Preparation</h2>
          <ol>
            {healthyAlternative.preparation.map((i) => {
              return <li>{i}</li>
            })}
          </ol>
        </Col>{' '}
      </Card>
      <Col span={24}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 12 }}>
          <Button
            type='primary'
            style={{ background: token.colorError }}
            onClick={() => handleBack(false)}
            disabled={savingHealthyAlternative}
          >
            Back
          </Button>
          {isNew ? (
            <Button
              type='primary'
              style={{ background: token.colorSuccess }}
              onClick={() => handleSaveHealthyAlternative()}
              loading={savingHealthyAlternative}
            >
              Save Recipe
            </Button>
          ) : undefined}
        </div>
      </Col>
    </CustomRow>
  )
}

export default HealthAlternativeItem
