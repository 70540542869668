import { useState, useEffect } from 'react'
import { Col, message, Row, Space, theme } from 'antd'

import {
  LocalUser,
  RemoteUser,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers
} from 'agora-rtc-react'
import { handleError } from '../../../lib/utils'
import './PatVideo.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMicrophone,
  faMicrophoneSlash,
  faPhoneSlash,
  faUserDoctor,
  faVideo,
  faVideoSlash
} from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'

export const PatLiveVideo = ({ appointment, channelName, token, uid, disconnectCallback }) => {
  const { token: themeToken } = theme.useToken()
  const [localFocused, setLocalFocused] = useState(false)
  const appId = process.env.REACT_APP_AGORA_APP_ID

  // set the connection state
  const [activeConnection, setActiveConnection] = useState(true)

  // track the mic/video state - Turn on Mic and Camera On
  const [micOn, setMic] = useState(true)
  const [cameraOn, setCamera] = useState(true)

  // get local video and mic tracks
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn)
  const { localCameraTrack } = useLocalCameraTrack(cameraOn)

  // Remote users
  const remoteUsers = useRemoteUsers()
  const { audioTracks } = useRemoteAudioTracks(remoteUsers)

  // Join the channel
  const agoraSession = useJoin(
    {
      appid: appId,
      channel: channelName,
      token,
      uid
    },
    activeConnection
  )

  const [sessionDuration, setSessionDuration] = useState(0)

  useEffect(() => {
    if (activeConnection) {
      if (localCameraTrack) {
        localCameraTrack.setEnabled(cameraOn)
      }

      if (localMicrophoneTrack) {
        localMicrophoneTrack.setEnabled(micOn)
      }
    }

    // eslint-disable-next-line
  }, [cameraOn, micOn, activeConnection])

  useEffect(() => {
    if (agoraSession.error && agoraSession.error.message) {
      message.error('Unknown error occurred. Please contact support.', 5)
      handleError(agoraSession.error.message, true)
    }
  }, [agoraSession])

  useEffect(() => {
    if (agoraSession.isConnected) {
      const startTime = Date.now()

      const durationInterval = setInterval(() => {
        const duration = Math.floor((Date.now() - startTime) / 1000)
        setSessionDuration(duration)
      }, 1000)

      return () => {
        clearInterval(durationInterval)
      }
    }
  }, [agoraSession.isConnected])

  usePublish([localMicrophoneTrack, localCameraTrack])
  audioTracks.forEach((track) => track.play())

  // Add useEffect to handle auto-disconnect
  useEffect(() => {
    let disconnectTimer
    let warningTimer

    if (activeConnection) {
      // Set warning timer for 5 minutes before disconnect
      warningTimer = setTimeout(() => {
        message.warning('Your session will end in 5 minutes', 5)
      }, 3300000) // 55 minutes (1 hour - 5 minutes)

      disconnectTimer = setTimeout(() => {
        handleDisconnect()
      }, 3600000) // 1 hour in milliseconds
    }

    return () => {
      if (disconnectTimer) {
        clearTimeout(disconnectTimer)
      }
      if (warningTimer) {
        clearTimeout(warningTimer)
      }
    }

    // eslint-disable-next-line
  }, [activeConnection])

  const handleDisconnect = () => {
    setActiveConnection(false)
    setMic(false)
    setCamera(false)

    if (disconnectCallback) {
      disconnectCallback()
    }
  }

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`
  }

  const sharedBtnStyle = {
    height: 65,
    width: 65,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ffffff'
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <div
            style={{ background: cameraOn ? '' : themeToken.colorPrimary }}
            id='agora-local-user-box'
            className={localFocused ? 'focused' : 'unfocused'}
            onClick={() => {
              if (localFocused === false) {
                setLocalFocused(true)
              }
            }}
          >
            {cameraOn ? (
              <LocalUser
                audioTrack={localMicrophoneTrack}
                videoTrack={localCameraTrack}
                cameraOn={cameraOn}
                micOn={micOn}
                playAudio={false}
                playVideo={cameraOn}
              />
            ) : (
              <FontAwesomeIcon color='white' icon={faVideoSlash} style={{ fontSize: 30 }} />
            )}
          </div>
          <div
            id='agora-remote-user-box'
            className={localFocused ? 'unfocused' : 'focused'}
            onClick={() => {
              if (localFocused) {
                setLocalFocused(false)
              }
            }}
            style={!localFocused ? {} : { background: themeToken.colorPrimary }}
          >
            {remoteUsers.length > 0 ? (
              <>
                {remoteUsers.map((user) => {
                  return (
                    <div key={user.uid} style={{ width: '100%', height: '100%' }}>
                      <RemoteUser
                        style={{ display: user.hasVideo ? 'block' : 'none', width: '100%', height: '100%' }}
                        user={user}
                        playVideo={user.hasVideo}
                        playAudio={user.hasAudio}
                      />
                      <FontAwesomeIcon
                        style={{ display: user.hasVideo ? 'none' : 'block', fontSize: 30 }}
                        color='black'
                        icon={faVideoSlash}
                      />
                    </div>
                  )
                })}
              </>
            ) : (
              <div>
                <center>
                  <FontAwesomeIcon
                    style={{ fontSize: localFocused ? 30 : 100, marginBottom: localFocused ? 0 : 12 }}
                    color='black'
                    icon={faUserDoctor}
                  />
                </center>
                {!localFocused ? (
                  <>
                    <center>Please wait for the doctor to join the call.</center>
                    <center>
                      Doctor: {appointment?.medicalProfProfile?.firstName} {appointment?.medicalProfProfile?.lastName}
                    </center>
                    <center>Time: {appointment?.startTime}</center>
                  </>
                ) : null}
              </div>
            )}
          </div>
        </Col>
        <div id='agora-local-user-contols'>
          <div>
            <Space size='middle' style={{ width: '100%', justifyContent: 'center' }}>
              <div
                style={{
                  ...sharedBtnStyle,
                  backgroundColor: micOn ? themeToken.colorPrimary : ''
                }}
                onClick={() => setMic((a) => !a)}
              >
                {micOn ? (
                  <FontAwesomeIcon icon={faMicrophone} style={{ fontSize: 30 }} />
                ) : (
                  <FontAwesomeIcon icon={faMicrophoneSlash} style={{ fontSize: 30 }} />
                )}
              </div>
              <div
                style={{ ...sharedBtnStyle, backgroundColor: cameraOn ? themeToken.colorPrimary : '' }}
                onClick={() => setCamera((a) => !a)}
              >
                {cameraOn ? (
                  <FontAwesomeIcon icon={faVideo} style={{ fontSize: 30 }} />
                ) : (
                  <FontAwesomeIcon icon={faVideoSlash} style={{ fontSize: 30 }} />
                )}
              </div>
              <div
                style={{ ...sharedBtnStyle, backgroundColor: '#FF4D4D' }}
                id='endConnection'
                onClick={() => {
                  disconnectCallback()
                }}
              >
                <FontAwesomeIcon icon={faPhoneSlash} style={{ fontSize: 30 }} />
              </div>
            </Space>
          </div>
        </div>
      </Row>
    </>
  )
}
