export const getDashboardTools = () => [
  {
    type: 'function',
    function: {
      name: 'openLetterModal',
      description: 'Opens the letter generation modal to start creating a new letter',
      parameters: {
        type: 'object',
        properties: {},
        required: []
      }
    }
  },
  {
    type: 'function',
    function: {
      name: 'generateLetter',
      description: 'Generate a professional medical letter such as referrals, medical certificates, or patient summaries.',
      parameters: {
        type: 'object',
        properties: {
          title: {
            type: 'string',
            description: 'The title of the letter (e.g. "Medical Referral", "Medical Certificate")'
          },
          recipient: {
            type: 'string',
            description: 'The recipient of the letter (e.g. "Dr. Smith", "To Whom It May Concern")'
          },
          subject: {
            type: 'string',
            description: 'The subject line of the letter'
          },
          content: {
            type: 'string',
            description: 'The main content of the letter'
          },
          letterType: {
            type: 'string',
            enum: ['referral', 'certificate', 'summary', 'general'],
            description: 'The type of medical letter to generate'
          }
        },
        required: ['recipient', 'content', 'letterType']
      }
    }
  },
  {
    type: 'function',
    name: 'analyzeMetrics',
    description: 'Analyze specific practice metrics and provide insights',
    parameters: {
      type: 'object',
      properties: {
        metricType: {
          type: 'string',
          enum: ['revenue', 'appointments', 'utilization', 'quality', 'growth'],
          description: 'The type of metric to analyze'
        },
        timeframe: {
          type: 'string',
          enum: ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'],
          description: 'The timeframe for analysis'
        }
      },
      required: ['metricType']
    }
  },
  {
    type: 'function',
    name: 'generateReport',
    description: 'Generate a summary report of practice performance',
    parameters: {
      type: 'object',
      properties: {
        reportType: {
          type: 'string',
          enum: ['financial', 'operational', 'clinical', 'comprehensive'],
          description: 'The type of report to generate'
        }
      },
      required: ['reportType']
    }
  },
  {
    type: 'function',
    name: 'getRecommendations',
    description: 'Provide optimization recommendations based on current metrics',
    parameters: {
      type: 'object',
      properties: {
        area: {
          type: 'string',
          enum: ['revenue', 'efficiency', 'patient_care', 'growth'],
          description: 'The area to focus recommendations on'
        }
      },
      required: ['area']
    }
  }
] 