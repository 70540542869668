import React, { useEffect, useState } from 'react'
import HeartBeatGraph from './heart-beat-graph'

const Pulse = ({ data }) => {
  const [formattedData, setFormattedData] = useState([])

  useEffect(() => {
    let tmpData = data.concat()

    tmpData = tmpData.sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate))

    setFormattedData(tmpData)
  }, [data])

  return <HeartBeatGraph data={formattedData} />
}

export default Pulse
