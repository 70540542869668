import React, { useState, useEffect, useRef } from 'react'
import { Button, Tag, Input, Select, message, Row, Col, Card } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { debounce } from 'lodash'
import { MedPraxProductSearch } from '../../../Scripting/utils/utils'
import { updateAllergies } from '../../../Patient/Medical History/api/api'
import { handleError } from '../../../lib/utils'
import {
  COMMON_ALLERGIES,
  COMMON_FOOD_ALLERGIES,
  COMMON_GENERAL_ALLERGIES
} from '../../../Patient/Medical History/utils/constants'

const AllergiesTab = ({ allergies = {}, recordId, setSaving, updateMedicalHistory }) => {
  // State for selected allergies
  const [selectedAllergies, setSelectedAllergies] = useState([])
  const [selectedFoodAllergies, setSelectedFoodAllergies] = useState([])
  const [selectedGeneralAllergies, setSelectedGeneralAllergies] = useState([])

  // State for additional allergies
  const [additionalFoodAllergies, setAdditionalFoodAllergies] = useState([])
  const [additionalGeneralAllergies, setAdditionalGeneralAllergies] = useState([])
  const [additionalMedications, setAdditionalMedications] = useState([])

  // State for Nappi data
  const [nappiData, setNappiData] = useState([])
  const [nappiLoading, setNappiLoading] = useState(false)
  const [nappiSearchValue, setNappiSearchValue] = useState('')

  // Refs
  const foodInputRef = useRef(null)
  const generalInputRef = useRef(null)

  // Initialize state from props
  useEffect(() => {
    if (allergies) {
      setSelectedAllergies(allergies.medsList || [])
      setSelectedFoodAllergies(allergies.foodList || [])
      setSelectedGeneralAllergies(allergies.otherList || [])
      setAdditionalFoodAllergies(allergies.foodAdd || [])
      setAdditionalGeneralAllergies(allergies.otherAdd || [])
      setAdditionalMedications(allergies.medsAdd || [])
    }
  }, [allergies])

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery) {
        setNappiLoading(true)
        const tmpData = await MedPraxProductSearch(searchQuery)
        setNappiData(tmpData)
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setNappiLoading(false)
  }

  const debouncedNappiFilter = debounce((value) => getNappiData(value), 500)

  const handleOptionClick = (allergy) => {
    setSelectedAllergies((prev) => {
      const isSelected = prev.includes(allergy.id)
      return isSelected ? prev.filter((id) => id !== allergy.id) : [...prev, allergy.id]
    })
  }

  const handleFoodOptionClick = (allergy) => {
    setSelectedFoodAllergies((prev) => {
      const isSelected = prev.includes(allergy.id)
      return isSelected ? prev.filter((id) => id !== allergy.id) : [...prev, allergy.id]
    })
  }

  const handleGeneralOptionClick = (allergy) => {
    setSelectedGeneralAllergies((prev) => {
      const isSelected = prev.includes(allergy.id)
      return isSelected ? prev.filter((id) => id !== allergy.id) : [...prev, allergy.id]
    })
  }

  const addFoodAllergy = () => {
    const value = foodInputRef.current.input.value.trim()
    if (value && !additionalFoodAllergies.includes(value)) {
      setAdditionalFoodAllergies([...additionalFoodAllergies, value])
      foodInputRef.current.input.value = ''
    }
  }

  const addGeneralAllergy = () => {
    const value = generalInputRef.current.input.value.trim()
    if (value && !additionalGeneralAllergies.includes(value)) {
      setAdditionalGeneralAllergies([...additionalGeneralAllergies, value])
      generalInputRef.current.input.value = ''
    }
  }

  const removeFoodAllergy = (allergy) => {
    setAdditionalFoodAllergies(additionalFoodAllergies.filter((item) => item !== allergy))
  }

  const removeGeneralAllergy = (allergy) => {
    setAdditionalGeneralAllergies(additionalGeneralAllergies.filter((item) => item !== allergy))
  }

  const removeMedication = (med) => {
    setAdditionalMedications(additionalMedications.filter((item) => item !== med))
  }

  const handleSave = async () => {
    setSaving(true)
    try {
      const updatedAllergies = {
        medsAdd: additionalMedications,
        foodAdd: additionalFoodAllergies,
        otherAdd: additionalGeneralAllergies,
        medsList: selectedAllergies,
        foodList: selectedFoodAllergies,
        otherList: selectedGeneralAllergies
      }

      const newData = await updateAllergies(recordId, updatedAllergies)
      updateMedicalHistory(newData)
      message.success('Allergies updated successfully')
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setSaving(false)
    }
  }

  return (
    <div className='allergies-tab'>
      <Row gutter={[16, 16]}>
        {/* Medication Allergies Column */}
        <Col xs={24} md={8}>
          <Card
            title='Medication Allergies'
            headStyle={{ backgroundColor: '#1890ff', color: 'white' }}
            style={{ height: '100%' }}
            bodyStyle={{ padding: '12px' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '16px' }}>
              {COMMON_ALLERGIES.map((allergy) => (
                <Button
                  key={allergy.id}
                  type={selectedAllergies.includes(allergy.id) ? 'primary' : 'default'}
                  onClick={() => handleOptionClick(allergy)}
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    ...(selectedAllergies.includes(allergy.id)
                      ? {
                          backgroundColor: '#1890ff',
                          borderColor: '#1890ff'
                        }
                      : {})
                  }}
                >
                  {allergy.label}
                </Button>
              ))}
            </div>

            <Card
              title='Additional Medication Allergies'
              size='small'
              style={{ marginTop: '16px' }}
              headStyle={{ backgroundColor: '#e6f7ff' }}
            >
              <Select
                showSearch
                placeholder='Search medications...'
                loading={nappiLoading}
                onSearch={(value) => {
                  setNappiSearchValue(value)
                  debouncedNappiFilter(value)
                }}
                onChange={(value) => {
                  if (value && !additionalMedications.includes(value)) {
                    setAdditionalMedications([...additionalMedications, value])
                    setNappiSearchValue('')
                    setNappiData([])
                  }
                }}
                value={nappiSearchValue}
                options={nappiData.map((i) => ({
                  value: i.name,
                  label: i.name
                }))}
                style={{ width: '100%' }}
                notFoundContent={nappiLoading ? 'Searching...' : null}
              />
              <div style={{ marginTop: '8px', maxHeight: '120px', overflowY: 'auto' }}>
                {additionalMedications.map((med, index) => (
                  <Tag
                    key={index}
                    closable
                    onClose={() => removeMedication(med)}
                    style={{ margin: '2px' }}
                    color='blue'
                  >
                    {med}
                  </Tag>
                ))}
              </div>
            </Card>
          </Card>
        </Col>

        {/* Food Allergies Column */}
        <Col xs={24} md={8}>
          <Card
            title='Food Allergies'
            headStyle={{ backgroundColor: '#52c41a', color: 'white' }}
            style={{ height: '100%' }}
            bodyStyle={{ padding: '12px' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '16px' }}>
              {COMMON_FOOD_ALLERGIES.map((allergy) => (
                <Button
                  key={allergy.id}
                  type={selectedFoodAllergies.includes(allergy.id) ? 'primary' : 'default'}
                  onClick={() => handleFoodOptionClick(allergy)}
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    ...(selectedFoodAllergies.includes(allergy.id)
                      ? {
                          backgroundColor: '#52c41a',
                          borderColor: '#52c41a'
                        }
                      : {})
                  }}
                >
                  {allergy.label}
                </Button>
              ))}
            </div>

            <Card
              title='Additional Food Allergies'
              size='small'
              style={{ marginTop: '16px' }}
              headStyle={{ backgroundColor: '#f6ffed' }}
            >
              <Row gutter={[8, 0]}>
                <Col flex='auto'>
                  <Input ref={foodInputRef} placeholder='Enter food allergy...' onPressEnter={addFoodAllergy} />
                </Col>
                <Col>
                  <Button
                    type='primary'
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={addFoodAllergy}
                    style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              <div style={{ marginTop: '8px', maxHeight: '120px', overflowY: 'auto' }}>
                {additionalFoodAllergies.map((food, index) => (
                  <Tag
                    key={index}
                    closable
                    onClose={() => removeFoodAllergy(food)}
                    style={{ margin: '2px' }}
                    color='green'
                  >
                    {food}
                  </Tag>
                ))}
              </div>
            </Card>
          </Card>
        </Col>

        {/* General Allergies Column */}
        <Col xs={24} md={8}>
          <Card
            title='General Allergies'
            headStyle={{ backgroundColor: '#722ed1', color: 'white' }}
            style={{ height: '100%' }}
            bodyStyle={{ padding: '12px' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '16px' }}>
              {COMMON_GENERAL_ALLERGIES.map((allergy) => (
                <Button
                  key={allergy.id}
                  type={selectedGeneralAllergies.includes(allergy.id) ? 'primary' : 'default'}
                  onClick={() => handleGeneralOptionClick(allergy)}
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    ...(selectedGeneralAllergies.includes(allergy.id)
                      ? {
                          backgroundColor: '#722ed1',
                          borderColor: '#722ed1'
                        }
                      : {})
                  }}
                >
                  {allergy.label}
                </Button>
              ))}
            </div>

            <Card
              title='Additional General Allergies'
              size='small'
              style={{ marginTop: '16px' }}
              headStyle={{ backgroundColor: '#f9f0ff' }}
            >
              <Row gutter={[8, 0]}>
                <Col flex='auto'>
                  <Input
                    ref={generalInputRef}
                    placeholder='Enter general allergy...'
                    onPressEnter={addGeneralAllergy}
                  />
                </Col>
                <Col>
                  <Button
                    type='primary'
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={addGeneralAllergy}
                    style={{ backgroundColor: '#722ed1', borderColor: '#722ed1' }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              <div style={{ marginTop: '8px', maxHeight: '120px', overflowY: 'auto' }}>
                {additionalGeneralAllergies.map((allergy, index) => (
                  <Tag
                    key={index}
                    closable
                    onClose={() => removeGeneralAllergy(allergy)}
                    style={{ margin: '2px' }}
                    color='purple'
                  >
                    {allergy}
                  </Tag>
                ))}
              </div>
            </Card>
          </Card>
        </Col>

        {/* Save Button */}
        <Col span={24} style={{ marginTop: '16px' }}>
          <Button type='primary' onClick={handleSave} block size='large'>
            Save Allergies
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default AllergiesTab
