import React from 'react'
import { Col } from 'antd'
import './index.css'
import Login from './login'
import LoginBackground from '../../../assets/images/login-background.svg'
import CustomRow from '../../reusable-components/CustomRow'
import { deviceDetect } from 'react-device-detect'

const Landing = ({ isLogin, pathArray, setPathArray }) => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <img
          style={{
            width: !deviceDetect().isBrowser ? 'auto' : '100%',
            height: !deviceDetect().isBrowser ? '100%' : 'auto'
          }}
          src={LoginBackground}
          alt='Could not load'
        />
      </div>
      <CustomRow
        // className='background-test'
        justify={deviceDetect().isBrowser ? 'end' : 'center'}
        style={{
          minHeight: '100vh',
          alignItems: 'center',
          position: 'relative',
          paddingTop: 30,
          paddingBottom: 30
        }}
      >
        <Col>
          <CustomRow>
            <Col>
              <Login isLogin={isLogin} pathArray={pathArray} setPathArray={setPathArray} />
            </Col>
          </CustomRow>
        </Col>
      </CustomRow>
    </>
  )
}

export default Landing
