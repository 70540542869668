import React, { useState } from 'react'
import Checkin from './checkin'
import { deviceDetect } from 'react-device-detect'
import LoginBackground from '../../../assets/images/login-background.svg'
import './index.css'

const CheckinWrapper = () => {
  const [view, setView] = useState()
  return (
    <>
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          background: 'linear-gradient(237deg, #F62088 -3.52%, #10B2E1 83.42%, #3C97D0 103.75%)'
        }}
      >
        {!view ? (
          <img
            style={{
              width: !deviceDetect().isBrowser ? 'auto' : '100%',
              height: !deviceDetect().isBrowser ? '100%' : 'auto'
            }}
            src={LoginBackground}
            alt='Could not load'
          />
        ) : undefined}
      </div>
      <Checkin setView={setView} view={view} />
    </>
  )
}

export default CheckinWrapper
