import React from 'react'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReceipt } from '@fortawesome/free-solid-svg-icons'
import styles from './RunningTotal.module.css'

const RunningTotal = ({ steps, currentStep }) => {
  const calculateTotals = () => {
    let total = 0
    let medicalAidTotal = 0
    let patientTotal = 0

    // Add null check and ensure steps exist
    if (!steps || !Array.isArray(steps)) {
      return { total, medicalAidTotal, patientTotal }
    }

    // Only include steps up to current step
    for (let i = 0; i <= Math.min(currentStep, steps.length - 1); i++) {
      const step = steps[i]
      if (step?.billing) {  // Added optional chaining
        Object.values(step.billing).forEach(item => {
          if (item?.amount) {  // Added null check for amount
            total += item.amount
            if (item.coveredByMedicalAid) {
              medicalAidTotal += item.amount
            } else {
              patientTotal += item.amount
            }
          }
        })
      }
    }

    return { total, medicalAidTotal, patientTotal }
  }

  const { total, medicalAidTotal, patientTotal } = calculateTotals()

  return (
    <motion.div 
      className={styles.runningTotal}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className={styles.runningTotalHeader}>
        <FontAwesomeIcon icon={faReceipt} />
        <span>Running Total</span>
      </div>
      <div className={styles.runningTotalContent}>
        <div className={styles.runningTotalAmount}>
          <span>Total: R {total.toFixed(2)}</span>
        </div>
        <div className={styles.runningTotalSplit}>
          <div className={styles.medicalAidPortion}>
            Medical Aid: R {medicalAidTotal.toFixed(2)}
          </div>
          <div className={styles.patientPortion}>
            Patient: R {patientTotal.toFixed(2)}
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default RunningTotal 