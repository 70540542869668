import { Col, Form, Modal, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomRow from '../../../reusable-components/CustomRow'
import CustomButton from '../../../reusable-components/CustomButton'
import { handleError } from '../../../lib/utils'
import { readClinics } from '../../clinics/utils/utils'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'

const BeginExaminationModal = ({ examinationModalOpen, setExaminationModalOpen, beginAdhocExamination }) => {
  const authState = useSelector((state) => state.auth)
  const checkinState = useSelector((state) => state.auth?.config?.checkin || [])
  const [data, setData] = useState(null)
  const [examinationForm] = Form.useForm()
  const [secondaryQuestions, setSecondaryQuestions] = useState([])
  const [clinics, setClinics] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (data?.ailment) {
      handleSetSecondaryQuestions()
    }
    // eslint-disable-next-line
  }, [data])
  useEffect(() => {
    handleSetClinics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetClinics = async () => {
    setLoading(true)
    let clinics = []
    try {
      clinics = await readClinics({ _id: { $in: authState.agiliteUser.extraData.clinics } })
      setClinics(clinics)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const handleSetSecondaryQuestions = () => {
    const secondaryQuestions = checkinState.find((item) => item.doctorsText === data.ailment)
    setSecondaryQuestions(secondaryQuestions.options)
  }

  const handleFinish = async (data) => {
    setLoading(true)
    try {
      if (authState.agiliteUser.extraData.clinics.length === 1) {
        data.clinic = JSON.stringify(clinics[0])
      }
      await beginAdhocExamination(data)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  return (
    <Modal
      title='New Examination'
      onCancel={() => {
        setExaminationModalOpen(false)
      }}
      maskClosable={false}
      footer={false}
      destroyOnClose={true}
      open={examinationModalOpen}
    >
      <Form
        layout='vertical'
        form={examinationForm}
        onFieldsChange={() => {
          setData(examinationForm.getFieldsValue())
        }}
        onFinish={(data) => {
          handleFinish(data)
        }}
      >
        <CustomRow justify='end'>
          {loading ? (
            <Col span={24}>
              <AgiliteSkeleton skActive spinnerTip='' />
            </Col>
          ) : (
            <>
              <Col span={24}>
                {authState.agiliteUser.extraData.clinics.length > 1 ? (
                  <Form.Item label='Clinic' name='clinic' rules={[{ required: true, message: 'Clinic required.' }]}>
                    <Select
                      placeholder='- Select Clinic -'
                      options={clinics.map((clinic) => {
                        return {
                          label: clinic.name,
                          value: JSON.stringify(clinic)
                        }
                      })}
                    />
                  </Form.Item>
                ) : undefined}
                <Form.Item label='Ailment' name='ailment' rules={[{ required: true, message: 'Ailment required.' }]}>
                  <Select
                    placeholder='- Select Reason for Consultation -'
                    options={checkinState.map((option) => {
                      return {
                        label: option.questionText,
                        value: option.doctorsText
                      }
                    })}
                  />
                </Form.Item>
                {data?.ailment ? (
                  <Form.Item
                    label='Symptoms'
                    name='patientSelection'
                    rules={[{ required: true, message: 'Atleast one option required.' }]}
                  >
                    <Select
                      mode='multiple'
                      placeholder='- Select Symptoms -'
                      options={secondaryQuestions?.map((question) => {
                        return {
                          label: question.text,
                          value: question.text
                        }
                      })}
                    />
                  </Form.Item>
                ) : undefined}
                {/* TODO: This is temporary for demo purposes */}
                {/* {data?.patientSelection ? (
                  <Form.Item
                    label='Triage'
                    name='triage'
                    rules={[{ required: true, message: 'Atleast one option required.' }]}
                  >
                    <Select
                      placeholder='- Select Triage Colour -'
                      mode='tags'
                      maxTagCount={1}
                      optionRender={(option) => {
                        switch (option.key) {
                          case 'red':
                            return <Tag color='#FF0000'>{option.label}</Tag>
                          case 'yellow':
                            return (
                              <Tag color='#FFFF00' style={{ color: '#000' }}>
                                {option.label}
                              </Tag>
                            )
                          case 'green':
                            return (
                              <Tag color='#00FF00' style={{ color: '#000' }}>
                                {option.label}
                              </Tag>
                            )
                          case 'blue':
                            return <Tag color='#00AEFF'>{option.label}</Tag>
                          case 'white':
                            return <Tag>{option.label}</Tag>
                        }
                      }}
                      tagRender={(option) => {
                        switch (option.value) {
                          case 'red':
                            return <Tag color='#FF0000'>{option.label}</Tag>
                          case 'yellow':
                            return (
                              <Tag color='#FFFF00' style={{ color: '#000' }}>
                                {option.label}
                              </Tag>
                            )
                          case 'green':
                            return (
                              <Tag color='#00FF00' style={{ color: '#000' }}>
                                {option.label}
                              </Tag>
                            )
                          case 'blue':
                            return <Tag color='#00AEFF'>{option.label}</Tag>
                          case 'white':
                            return <Tag>{option.label}</Tag>
                        }
                      }}
                      options={[
                        {
                          label: 'Immediate Care Required',
                          value: 'red'
                        },
                        {
                          label: 'Serious',
                          value: 'yellow'
                        },
                        {
                          label: 'Minor Injury or Illness',
                          value: 'green'
                        },
                        {
                          label: 'Contagious',
                          value: 'blue'
                        },
                        {
                          label: 'Mental Health Assistance',
                          value: 'white'
                        }
                      ]}
                    />
                  </Form.Item>
                ) : undefined} */}
                {data?.patientSelection?.includes('Other') ? <> </> : undefined}
              </Col>
              <Col>
                <CustomButton
                  onClick={() => {
                    setData(null)
                    examinationForm.resetFields()
                    setExaminationModalOpen(false)
                  }}
                  type='danger'
                  size='small'
                  text='Cancel'
                  disabled={loading}
                />
              </Col>
              <Col>
                <CustomButton
                  onClick={() => {
                    examinationForm.submit()
                  }}
                  type='success'
                  size='small'
                  text='Confirm Selection'
                  disabled={loading}
                />
              </Col>
            </>
          )}
        </CustomRow>
      </Form>
    </Modal>
  )
}

export default BeginExaminationModal
