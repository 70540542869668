import React, { useEffect } from 'react'
import { Modal, Form, Input, Select, Space, DatePicker } from 'antd'
import { TASK_TYPE_CONFIG } from '../config/taskTypes'
import { STAFF_MEMBERS, CURRENT_USER, DATE_FORMAT } from '../config/constants'
import dayjs from 'dayjs'
import styles from '../styles/CreateTaskModal.module.css'

const { Option } = Select

const EditTaskModal = ({ 
  visible, 
  onCancel, 
  onSubmit, 
  form,
  initialValues
}) => {
  useEffect(() => {
    if (visible && initialValues) {
      form.setFieldsValue({
        ...initialValues,
        dueDate: dayjs(initialValues.dueDate, DATE_FORMAT)
      })
    }
  }, [visible, initialValues, form])

  return (
    <Modal
      title="Edit Task"
      open={visible}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      onOk={() => form.submit()}
      className={styles.taskModal}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
      >
        <Form.Item
          name="task"
          label="Task Description"
          rules={[{ required: true, message: 'Please enter task description' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="Task Type"
          rules={[{ required: true, message: 'Please select task type' }]}
        >
          <Select>
            {Object.entries(TASK_TYPE_CONFIG).map(([key, config]) => (
              <Option key={key} value={key}>
                <Space>
                  {config.icon}
                  {config.label}
                  <span className={styles.typeDescription}>
                    - {config.description}
                  </span>
                </Space>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="responsiblePerson"
          label="Responsible Person"
          rules={[{ required: true, message: 'Please select who is responsible for this task' }]}
        >
          <Select
            showSearch
            placeholder="Select staff member"
            optionFilterProp="children"
          >
            <Option key="self" value={CURRENT_USER}>
              You (Self-assigned)
            </Option>
            {STAFF_MEMBERS
              .filter(staff => staff.name !== CURRENT_USER)
              .map(staff => (
                <Option key={staff.id} value={staff.name}>
                  {staff.name} ({staff.role})
                </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="priority"
          label="Priority"
          rules={[{ required: true, message: 'Please select priority' }]}
        >
          <Select>
            <Option value="high">High</Option>
            <Option value="medium">Medium</Option>
            <Option value="low">Low</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="dueDate"
          label="Due Date"
          rules={[{ required: true, message: 'Please select due date' }]}
        >
          <DatePicker 
            format={DATE_FORMAT}
            className={styles.datePicker}
            disabledDate={(current) => {
              return current && current < dayjs().startOf('day')
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditTaskModal 