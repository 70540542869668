import axios from 'axios'

export const MedPraxICD10Search = async (searchQuery, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/medprax/icd10/search`, {
          body: {
            filters: [
              {
                propertyName: 'description',
                operation: 'StartsWith',
                value: searchQuery
              },
              {
                propertyName: 'code',
                operation: 'StartsWith',
                value: searchQuery
              },
              {
                propertyName: 'description',
                operation: 'Contains',
                value: searchQuery
              }
            ],
            filterJoin: 'Or',
            ascendingOrder: true,
            sortKey: 'code'
          },
          pageLimit: 100,
          page: 1
        })
        resolve(response.data.icd10s.pageResult)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
