import React from 'react'
import { Card, Col, theme } from 'antd'
import { hexToRGBA } from '../../../../../../lib/utils'
import CustomRow from '../../../../../../reusable-components/CustomRow'
import PrevNextFloatingButtons from '../../../../../Reusable Components/Buttons/PrevNextFloatingButtons'

const HealthAlternativeItem = ({ healthyAlternative, handleBack }) => {
  const { token } = theme.useToken()
  return (
    <>
      <CustomRow style={{ paddingBottom: 100 }}>
        <h1>{healthyAlternative.recipeName}</h1>
        <Card style={{ width: '100%' }}>
          <Col span={24}>
            <h2>Ingredients</h2>
            <ul>
              {healthyAlternative.ingredients.map((i) => {
                return (
                  <li style={{ marginBottom: '10px' }}>
                    {i.quantity} {i.unit} {i.name}
                  </li>
                )
              })}
            </ul>
          </Col>
        </Card>

        <Card style={{ width: '100%' }} headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}>
          <Col span={24}>
            <h2>Preparation</h2>
            <ol>
              {healthyAlternative.preparation.map((i) => {
                return <li style={{ marginBottom: '10px' }}>{i}</li>
              })}
            </ol>
          </Col>
        </Card>
      </CustomRow>
      <PrevNextFloatingButtons onNext={handleBack} nextText='Done' nextOnly={true} />
    </>
  )
}

export default HealthAlternativeItem
