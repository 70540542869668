import React from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Button, Col, Space, theme } from 'antd'
import CustomButton from '../../reusable-components/CustomButton'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

const ProfileDisplayMode = ({ userData, image, setIsEditMode, isReception, closeTab }) => {
  const { token } = theme.useToken()

  return (
    <CustomRow style={{ textAlign: 'center' }} className='basic-card'>
      <Col style={{ marginBottom: 10 }} span={24}>
        <center>
          <div
            style={{
              display: 'grid',
              height: 196,
              width: 196,
              alignItems: 'center',
              overflow: 'hidden',
              borderRadius: '50%',
              boxShadow: '0 0 5px 0 rgba(0,0,0,0.255)'
            }}
          >
            {/* eslint-disable-next-line */}
            <img
              referrerPolicy='no-referrer'
              alt='User Image'
              style={{ gridColumn: 1, gridRow: 1, width: '100%' }}
              src={image}
            />
          </div>
        </center>
      </Col>
      <Col span={24}>
        <Space direction='vertical' style={{ width: '100%' }}>
          <h2>{`${userData.firstName} ${userData.lastName}`.toUpperCase()}</h2>
          <p>{userData.email ? userData.email : 'No Linked Email'}</p>
          <p>{userData.phoneNumber ? userData.phoneNumber : 'No Linked Phone Number'}</p>
          {userData?.extraData?.role?.type === 'patient' ? (
            <p>{userData.medicalAid?.name ? userData.medicalAid.name : 'No Medical Aid'}</p>
          ) : undefined}
          {userData?.extraData?.profession === 'doctor' ? (
            <>
              {userData.extraData.signature ? (
                <center>
                  <div style={{ width: 200 }}>
                    <img style={{ width: 200 }} src={userData.extraData.signature} alt='Signature' />
                  </div>
                </center>
              ) : (
                <p>No signature set</p>
              )}
            </>
          ) : undefined}
          <center>
            <CustomButton
              onClick={() => {
                setIsEditMode(true)
              }}
              text='Edit Profile'
              icon={faEdit}
              type='primary'
              size='small'
            />
            {isReception ? (
              <Button
                onClick={() => {
                  closeTab()
                }}
                style={{ color: 'white', background: token.colorError, marginTop: 12 }}
              >
                Close Profile
              </Button>
            ) : undefined}
          </center>
        </Space>
      </Col>
    </CustomRow>
  )
}

export default ProfileDisplayMode
