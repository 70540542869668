import React, { useState, useEffect } from 'react'
import { Card, Button, Table, Modal, Select, DatePicker, message, Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { debounce } from 'lodash'
import dayjs from 'dayjs'
import { MedPraxProductSearch } from '../../../Scripting/utils/utils'
import { updateGeneralMeds } from '../../../Patient/Medical History/api/api'
import { handleError } from '../../../lib/utils'

const { confirm } = Modal

const GeneralMedsTab = ({ meds = {}, recordId, setSaving, updateMedicalHistory }) => {
  const [medsList, setMedsList] = useState([])
  const [nappiData, setNappiData] = useState([])
  const [nappiLoading, setNappiLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedMedication, setSelectedMedication] = useState(null)
  const [editingMedication, setEditingMedication] = useState(null)
  const [medicationDates, setMedicationDates] = useState({
    startDate: null,
    endDate: null
  })

  // Initialize state from props
  useEffect(() => {
    if (meds && meds.list) {
      setMedsList(meds.list || [])
    }
  }, [meds])

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery && searchQuery.length >= 3) {
        setNappiLoading(true)
        const data = await MedPraxProductSearch(searchQuery)
        setNappiData(data)
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    } finally {
      setNappiLoading(false)
    }
  }

  const debouncedNappiSearch = debounce(getNappiData, 500)

  const handleAddMedication = () => {
    if (!selectedMedication || !medicationDates.startDate) {
      message.error('Please select a medication and start date')
      return
    }

    const newMedication = {
      med: selectedMedication,
      startDate: medicationDates.startDate.toISOString(),
      endDate: medicationDates.endDate ? medicationDates.endDate.toISOString() : null
    }

    setMedsList([...medsList, newMedication])
    setSelectedMedication(null)
    setMedicationDates({ startDate: null, endDate: null })
    setModalVisible(false)
  }

  const handleUpdateMedication = () => {
    if (!medicationDates.startDate) {
      message.error('Please select a start date')
      return
    }

    const updatedList = medsList.map((med, index) => {
      if (index === editingMedication.index) {
        return {
          ...med,
          startDate: medicationDates.startDate.toISOString(),
          endDate: medicationDates.endDate ? medicationDates.endDate.toISOString() : null
        }
      }
      return med
    })

    setMedsList(updatedList)
    setEditingMedication(null)
    setMedicationDates({ startDate: null, endDate: null })
    setModalVisible(false)
  }

  const handleDeleteMedication = (index) => {
    confirm({
      title: 'Are you sure you want to delete this medication?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const updatedList = [...medsList]
        updatedList.splice(index, 1)
        setMedsList(updatedList)
      }
    })
  }

  const handleSave = async () => {
    setSaving(true)
    try {
      const newData = await updateGeneralMeds(recordId, medsList)
      updateMedicalHistory(newData)
      message.success('General medications updated successfully')
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setSaving(false)
    }
  }

  const columns = [
    {
      title: 'Medication',
      dataIndex: 'med',
      key: 'med',
      render: (med) => (
        <div>
          <div style={{ fontWeight: 'bold' }}>{med.name}</div>
          {med.dosageForm && <div>{med.dosageForm}</div>}
        </div>
      )
    },
    {
      title: 'Duration',
      key: 'duration',
      render: (_, record) => (
        <div>
          <div>Start: {dayjs(record.startDate).format('DD MMM YYYY')}</div>
          {record.endDate ? <div>End: {dayjs(record.endDate).format('DD MMM YYYY')}</div> : <div>Current</div>}
        </div>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record, index) => (
        <div>
          <Button
            type='primary'
            size='small'
            icon={<FontAwesomeIcon icon={faEdit} />}
            style={{ marginRight: '8px' }}
            onClick={() => {
              setEditingMedication({ ...record, index })
              setMedicationDates({
                startDate: dayjs(record.startDate),
                endDate: record.endDate ? dayjs(record.endDate) : null
              })
              setModalVisible(true)
            }}
          >
            Edit
          </Button>
          <Button
            danger
            size='small'
            icon={<FontAwesomeIcon icon={faTrash} />}
            onClick={() => handleDeleteMedication(index)}
          >
            Delete
          </Button>
        </div>
      )
    }
  ]

  return (
    <div className='general-meds-tab'>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Button
            type='primary'
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setModalVisible(true)}
            style={{ marginBottom: '16px' }}
          >
            Add Medication
          </Button>
        </Col>

        <Col span={24}>
          <Table dataSource={medsList} columns={columns} rowKey={(record, index) => index} pagination={false} />
        </Col>

        <Col span={24}>
          <Button type='primary' onClick={handleSave} block>
            Save Medications
          </Button>
        </Col>
      </Row>

      {/* Add/Edit Medication Modal */}
      <Modal
        title={editingMedication ? 'Edit Medication' : 'Add Medication'}
        open={modalVisible}
        onOk={editingMedication ? handleUpdateMedication : handleAddMedication}
        onCancel={() => {
          setModalVisible(false)
          setSelectedMedication(null)
          setEditingMedication(null)
          setMedicationDates({ startDate: null, endDate: null })
        }}
        okText={editingMedication ? 'Update' : 'Add'}
      >
        {!editingMedication && (
          <div style={{ marginBottom: '16px' }}>
            <label>Search Medication:</label>
            <Select
              showSearch
              placeholder='Search by name'
              loading={nappiLoading}
              onSearch={debouncedNappiSearch}
              onChange={(value) => setSelectedMedication(JSON.parse(value))}
              style={{ width: '100%' }}
              options={nappiData.map((item) => ({
                value: JSON.stringify(item),
                label: `${item.name} ${item.dosageForm ? `(${item.dosageForm})` : ''}`
              }))}
              notFoundContent={nappiLoading ? 'Searching...' : null}
            />
          </div>
        )}

        {editingMedication && (
          <div style={{ marginBottom: '16px' }}>
            <p>
              <strong>Medication:</strong> {editingMedication.med.name}
            </p>
            {editingMedication.med.dosageForm && (
              <p>
                <strong>Dosage Form:</strong> {editingMedication.med.dosageForm}
              </p>
            )}
          </div>
        )}

        <div style={{ marginBottom: '16px' }}>
          <label>Start Date:</label>
          <DatePicker
            value={medicationDates.startDate}
            onChange={(date) => setMedicationDates({ ...medicationDates, startDate: date })}
            style={{ width: '100%' }}
          />
        </div>

        <div>
          <label>End Date (optional):</label>
          <DatePicker
            value={medicationDates.endDate}
            onChange={(date) => setMedicationDates({ ...medicationDates, endDate: date })}
            style={{ width: '100%' }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default GeneralMedsTab
