export const getExaminationTools = () => [
  {
    type: 'function',
    function: {
      name: 'captureVitals',
      description: 'Capture patient vital signs and measurements',
      parameters: {
        type: 'object',
        properties: {
          vitalType: {
            type: 'string',
            enum: ['heart_rate', 'temperature', 'respiratory_rate', 'oxygen_saturation'],
            description: 'The type of vital sign to capture (use captureBloodPressure for blood pressure)'
          },
          value: {
            type: 'string',
            description: 'The measured value'
          },
          unit: {
            type: 'string',
            description: 'The unit of measurement'
          }
        },
        required: ['vitalType', 'value']
      }
    }
  },
  {
    type: 'function',
    function: {
      name: 'captureBloodPressure',
      description: 'Capture patient blood pressure readings with systolic and diastolic values',
      parameters: {
        type: 'object',
        properties: {
          systolic: {
            type: 'number',
            description: 'The systolic blood pressure value (top number)'
          },
          diastolic: {
            type: 'number',
            description: 'The diastolic blood pressure value (bottom number)'
          },
          unit: {
            type: 'string',
            description: 'The unit of measurement, typically mmHg',
            default: 'mmHg'
          }
        },
        required: ['systolic', 'diastolic']
      }
    }
  },
  {
    type: 'function',
    function: {
      name: 'recordSymptom',
      description: 'Record patient symptoms and observations',
      parameters: {
        type: 'object',
        properties: {
          symptom: {
            type: 'string',
            description: 'The symptom or observation to record'
          },
          severity: {
            type: 'string',
            enum: ['mild', 'moderate', 'severe'],
            description: 'The severity of the symptom'
          },
          duration: {
            type: 'string',
            description: 'How long the symptom has been present'
          }
        },
        required: ['symptom', 'severity']
      }
    }
  },
  {
    type: 'function',
    function: {
      name: 'generateReport',
      description: 'Generate a medical report or letter',
      parameters: {
        type: 'object',
        properties: {
          reportType: {
            type: 'string',
            enum: ['examination_notes', 'referral_letter', 'sick_note', 'prescription'],
            description: 'The type of report to generate'
          },
          content: {
            type: 'string',
            description: 'The content of the report'
          }
        },
        required: ['reportType', 'content']
      }
    }
  },
  {
    type: 'function',
    function: {
      name: 'suggestTests',
      description: 'Suggest relevant medical tests or procedures',
      parameters: {
        type: 'object',
        properties: {
          symptoms: {
            type: 'array',
            items: {
              type: 'string'
            },
            description: 'List of symptoms or conditions'
          },
          urgency: {
            type: 'string',
            enum: ['routine', 'urgent', 'emergency'],
            description: 'The urgency level of the suggested tests'
          }
        },
        required: ['symptoms']
      }
    }
  }
] 