const pdfGenerationEnums = {
  pdfGeneration: {
    SICK_LEAVE_STANDARD: 'sick_note_pdf',
    SICK_LEAVE_FAMILY_RESPONSIBILITY: 'sick_note_pdf_family_responsibility',
    SCRIPT_STANDARD: 'script_pdf',
    CRAFT_MY_PDF: 'craft_my_pdf',
    CREATE_REFERRAL: 'create_referral',
    CREATE_SCRIPT: 'create_script'
  },
  reportIds: {
    DIABETES_REPORT: 'generate_report'
  }
}

export default pdfGenerationEnums
