// React
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import coreReducer from '../../../core/utils/reducer'

// Utils
import dayjs from 'dayjs'
import { cgmAuth, cgmPost } from '../cgm_billing/utils'
import { findPreviousLatestBooking, updateAllBookingsState } from '../../lib/booking-utils'
import { findAgiliteUser } from '../../Auth/utils/utils'
import { isAdmin, isDoctor, isNurse } from '../../lib/profile-utils'
import { extractCGMError, generateUniqueUIDFromString, handleError } from '../../lib/utils'
import { readSystemUsers } from '../../Admin/system-users/utils/utils'
import { generateActions, getMainTitle } from '../utils/lib'
import {
  updateBooking,
  readBookings,
  readBillingRecords,
  generateAgoraRtcToken,
  getPlanSubOptions,
  goodXSubmit
} from '../utils/utils'
import { executeBooking, getBookingState } from '../utils/bpm-utils'

// Components
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import Billing from './Examination Components/billing'
import ClinicalNotes from '../../Examination/components/Clinical Notes/ClinicalNotesTool'
import CoreEnums from '../../../core/utils/enums'
import CustomRow from '../../reusable-components/CustomRow'
import EmployeeBookingsView from './Booking View Components/EmployeeBookingsListView'
import ExaminationWrapper from '../../Examination/components/Assessment/AssessmentWrapper'
import NurseToDoctor from './Examination Components/NurseToDoctor'
import ScriptsWrapper from '../../Scripting/components/scripting-wrapper'
import ServicesEnums from '../../Admin/services/utils/enums'
import PatientJourneyList from './Examination Components/PatientJourney'
import { Button, Col, Modal, Popconfirm, Space, Tabs, message, notification, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChartLine,
  faDiagnoses,
  faFileMedical,
  faFilePrescription,
  faMoneyBill,
  faNotesMedical,
  faRoute,
  faVideoCamera,
  faXmark,
  faFileAlt,
  faCalendarCheck,
  faStethoscope,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import { ContainerCard } from '../../reusable-components/AgiliteCards'

// Styling
import '../styles/index.css'
import { TabContentWrapper } from './Examination Components/TabContentWrapper'
import CustomButton from '../../reusable-components/CustomButton'
import DocumentsWrapper from '../../Examination/components/Documents/DocumentsWrapper'
import MedProfLiveVideoWrapper from './Agora/MedProfLiveVideoWrapper'
import { settleCallpayOriginalTransaction } from '../../PeachPayments/utils/utils'
import { createInvoice } from '../../Invoices/utils'

const PatientExaminationForm = ({ data, isPreviousBooking, workflowHistory, noAppointment }) => {
  const authState = useSelector((state) => state.auth)
  const state = useSelector((state) => state)

  const [activeTab, setActiveTab] = useState(isNurse() || isDoctor() ? 'assessment' : isAdmin() ? 'journey' : 'billing')
  const [agoraChannel, setAgoraChannel] = useState(null)
  const [agoraToken, setAgoraToken] = useState(null)
  const [agoraUid, setAgoraUid] = useState(null)
  const [billingItems, setBillingItems] = useState([])
  const [billingRecordsLoading, setBillingRecordsLoading] = useState(false)
  const [billingSyncRequired, setBillingSyncRequired] = useState(false)
  const [bookingData, setBookingData] = useState(data)
  const [completing, setCompleting] = useState(false)
  const [joined, setJoined] = useState(false)
  const [loading, setLoading] = useState(false)
  const [medProf, setMedProf] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [previousDataLoading, setPreviousDataLoading] = useState(false)
  const [previousLatestBooking, setPreviousLatestBooking] = useState(null)
  const [printingReport, setPrintingReport] = useState(false)
  const [saving, setSaving] = useState(false)
  const [controlWidth, setControlWidth] = useState(350)

  const { token } = theme.useToken()

  // Billing Web Socket
  const [billingWebSocket, setBillingWebSocket] = useState({
    connected: false,
    socket: null
  })

  const dispatch = useDispatch()

  const bookingSocketRef = useRef(null)

  useEffect(() => {
    if (!bookingSocketRef.current) {
      bookingSocketRef.current = new WebSocket(`${process.env.REACT_APP_NODE_RED_WS_URL}/billing`)
      bookingSocketRef.current.onopen = () => {
        setBillingWebSocket({
          connected: true,
          socket: bookingSocketRef.current
        })
      }
      bookingSocketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data)
        if (data.bookingRef === bookingData._id && data.userRef !== authState.agiliteUser._id) {
          setBillingSyncRequired(true)
        }
      }
      bookingSocketRef.current.onerror = (error) => {
        handleError(error, true)
      }
    }
    return () => {
      if (bookingSocketRef.current) {
        bookingSocketRef.current.close()
        setBillingWebSocket({
          connected: false,
          socket: null
        })
        bookingSocketRef.current = null
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleGetPatient()
    handleGetBillingRecords()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setBookingData(data)
  }, [data])

  const handleGetPatient = async () => {
    let tmpPatient = null
    try {
      tmpPatient = await findAgiliteUser({ _id: bookingData.patientRecord._id })
      setBookingData({ ...bookingData, patientRecord: tmpPatient })
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleGetBillingRecords = async () => {
    let tmpBillingRecords = []

    setBillingRecordsLoading(true)
    try {
      tmpBillingRecords = await readBillingRecords({ bookingRef: data._id })
      setBillingItems(tmpBillingRecords)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setBillingRecordsLoading(false)
  }

  useEffect(() => {
    const getMedProf = async () => {
      let users = null

      try {
        users = await readSystemUsers({ _id: data.medicalProfRef }, null, null, null, true)

        if (users.length > 0) {
          setMedProf(users[0])
        }
      } catch (e) {
        message.error(handleError(e, true))
      }
    }

    getMedProf()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleGetPreviousLatestBooking()
    // eslint-disable-next-line
  }, [])

  const handleGetPreviousLatestBooking = async () => {
    let tmpData = null

    setPreviousDataLoading(true)

    try {
      tmpData = await findPreviousLatestBooking(data)
      setPreviousLatestBooking(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPreviousDataLoading(false)
  }

  const closeTab = () => {
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: state.core.tabNavigation.activeKey,
        removeBreadcrumb: true
      })
    )
  }

  const handleSave = async (isCompleteAction, recordData, isReport, generationFunction) => {
    let processRecord = null
    let newRecord = null

    if (isCompleteAction) {
      setCompleting(true)
    } else {
      setSaving(true)
    }

    setLoading(true)

    const nurseNotes = document.getElementById(`${data._id}-notes-capture-area-nurse`)?.value
    const doctorsNotes = document.getElementById(`${data._id}-notes-capture-area-doctor`)?.value
    const patientNotes = document.getElementById(`${data._id}-notes-capture-area-patient`)?.value

    try {
      // Notes Validation
      if (nurseNotes) {
        throw new Error('There are Nurse Clinical Notes that have not been saved. Please revise.')
      }

      if (doctorsNotes) {
        throw new Error('There are Clinical Notes that have not been saved. Please revise.')
      }

      if (patientNotes) {
        throw new Error('There are Patient Instruction Notes that have not been saved. Please revise.')
      }

      let latestRecordStatus = await readBookings({ _id: data._id })

      if (latestRecordStatus[0]?.status !== bookingData.status && !noAppointment) {
        throw new Error(`Unable to complete. ${data.status} may have been bypassed by another practitioner.`)
      }

      if (isCompleteAction) {
        processRecord = await getBookingState([data.processRef])

        const commonParams = [`${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`, processRecord.key]

        switch (bookingData.status) {
          case CoreEnums.bookingStatuses.completed:
            // When the status is already completed, we know the doctor submitted updates
            processRecord = await executeBooking(
              bookingData.clinicRecord.bpmKey,
              data.processRef,
              'doctor_updates',
              ...commonParams
            )
            break
          case CoreEnums.bookingStatuses.screening:
            if (medProf.extraData.profession === 'nurse') {
              if (bookingData.isVirtualVisit) {
                // Submit to diagnosis
                processRecord = await executeBooking(
                  bookingData.clinicRecord.bpmKey,
                  data.processRef,
                  'submit',
                  ...commonParams
                )
              } else if (bookingData.serviceRecord?.type === ServicesEnums.labels.VITALITY_HEALTH_CHECK) {
                // Submit to Reception Data Capture
                processRecord = await executeBooking(
                  bookingData.clinicRecord.bpmKey,
                  data.processRef,
                  'submit_data_capture',
                  ...commonParams
                )
              } else {
                // Submit to Reception Billing
                processRecord = await executeBooking(
                  bookingData.clinicRecord.bpmKey,
                  data.processRef,
                  'submit_billing',
                  ...commonParams
                )
              }
            } else {
              // Submit to diagnosis
              processRecord = await executeBooking(
                bookingData.clinicRecord.bpmKey,
                data.processRef,
                'submit',
                ...commonParams
              )
            }

            break
          default:
            if (bookingData.nurseProcedureRequired && bookingData.status !== CoreEnums.bookingStatuses.procedure) {
              processRecord = await executeBooking(
                bookingData.clinicRecord.bpmKey,
                data.processRef,
                'nurse_procedure',
                ...commonParams
              )
            } else {
              // Check if Callpay settlement needs to be done
              if (medProf.extraData.profession === 'doctor' && bookingData.callpayGatewayReference) {
                await settleCallpayOriginalTransaction(bookingData.callpayGatewayReference)
              }

              processRecord = await executeBooking(
                bookingData.clinicRecord.bpmKey,
                data.processRef,
                'submit',
                ...commonParams
              )
            }
            break
        }
      }

      newRecord = await updateBooking(
        {
          status: isCompleteAction ? processRecord.processStage : bookingData.status,
          noCharge: bookingData.noCharge
        },
        { _id: data._id }
      )

      updateAllBookingsState(newRecord)

      if (isReport) {
        setPrintingReport(true)
        await generationFunction(newRecord)
      }

      if (isCompleteAction) {
        message.success('Changes submitted successfully.')
        closeTab()
      } else {
        message.success('Changes saved successfully.')
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setPrintingReport(false)
    setSaving(false)
    setCompleting(false)
    setLoading(false)
  }

  const startVirtualConsult = async () => {
    let agoraCreds = null
    let tmpUid = null

    try {
      tmpUid = generateUniqueUIDFromString(authState.agiliteUser._id)
      agoraCreds = await generateAgoraRtcToken(data._id, tmpUid)
      setAgoraToken(agoraCreds.token)
      setAgoraChannel(agoraCreds.channelName)
      setAgoraUid(tmpUid)
      setJoined(true)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleGenerateInvoice = async (goodXTicketNo) => {
    try {
      await createInvoice({
        userRef: bookingData.userRef,
        bookingRef: bookingData._id,
        medicalProfRef: bookingData.medicalProfRef,
        clinicRef: bookingData.clinicRef,
        createdAt: new Date(),
        invoiceType: bookingData.patientRecord?.medicalAid.name ? 'medical' : 'private',
        goodXStatus: '',
        invoiceNo: dayjs(new Date()).format('YYMMDDHHmmss'),
        goodXTicketNo
      })
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const finalizeBilling = async () => {
    setLoading(true)
    setCompleting(true)

    const invoiceID = dayjs(new Date()).format('YYYYMMDDHHmmss').toString()
    let processRecord = await getBookingState([bookingData.processRef])
    // let inventoryItems = []
    let cgmAuthRes = null
    let cgmPostRes = null
    let patientRequiredFields = ['dateOfBirth', 'idNo', 'firstName', 'lastName', 'gender', 'residentialAddress']
    let validationFailed = false
    let patient = null
    let mainMember = null
    let medicalProf = null
    let goodXTicketNo = ''
    // let medicalAidAmount = 0
    // let patientAmount = 0

    try {
      patient = await findAgiliteUser({ _id: bookingData.patientRecord._id })

      if (patient.mainMemberId) {
        mainMember = await findAgiliteUser({ _id: patient.mainMemberId })
      } else {
        mainMember = patient
      }

      medicalProf = await findAgiliteUser({ _id: bookingData.medicalProfRef })

      // TODO: THIS IS TEMPORARY
      // for (const item of billingItems) {
      //   medicalAidAmount += parseFloat(item.medicalAid)
      //   patientAmount += parseFloat(item.patient)
      // }

      // TODO: THIS IS TEMPORARY
      // await sendBill({ ...patient, ...data, medicalAidAmount, patientAmount })

      goodXTicketNo = await goodXSubmission(mainMember, patient, medicalProf)

      if (bookingData.clinicRecord.cgmEnabled && !bookingData.noCharge) {
        // Validation
        for (const entry of patientRequiredFields) {
          if (!patient[entry]) {
            validationFailed = true
          }
        }

        if (validationFailed) {
          setLoading(false)
          setCompleting(false)
          return notification.error({
            message: 'Patient Profile Incomplete',
            description: 'Please ensure that the patient profile is complete before proceeding with billing.',
            duration: 0
          })
        }

        cgmAuthRes = await cgmAuth(bookingData.userRef)
        cgmPostRes = await cgmPost(
          { ...bookingData, patientRecord: patient },
          invoiceID,
          billingItems,
          cgmAuthRes.access_token,
          medProf
        )

        if (cgmPostRes) {
          let throwError = false
          if (cgmPostRes.accountRegistration.status === 'ERROR') {
            throwError = true
          }
          if (cgmPostRes.patientRegistration.status === 'ERROR') {
            throwError = true
          }
          if (cgmPostRes?.billPatient?.status === 'ERROR') {
            throwError = true
          }
          if (throwError) {
            throw new Error(extractCGMError(cgmPostRes))
          }
        }
      }

      // BPM
      processRecord = await executeBooking(
        bookingData.clinicRecord.bpmKey,
        bookingData.processRef,
        'submit',
        `${state.auth.agiliteUser.firstName} ${state.auth.agiliteUser.lastName}`,
        processRecord.key
      )
      let newRecord = await updateBooking(
        { status: processRecord.processStage, cgmInvoiceId: invoiceID, goodXTicketNo },
        { _id: bookingData._id }
      )
      updateAllBookingsState(newRecord)
      message.success('Billing Completed')
      closeTab()
    } catch (e) {
      if (typeof e === 'string') {
        message.error(handleError(e, true, true), 10)
      } else {
        message.error(handleError(e, true), 10)
      }
    }

    setLoading(false)
    setCompleting(false)
  }

  const goodXSubmission = async (mainMember, patient, medicalProf) => {
    let goodXTicketNo = ''
    let planSubOption = null
    let planSubOptionsData = null

    if (mainMember.medicalAid.name && patient.medicalAid.name) {
      planSubOptionsData = await getPlanSubOptions(mainMember.medicalAid.planOptionCode)

      if (planSubOptionsData.planSubOptions.pageResult.length > 0) {
        planSubOption = planSubOptionsData.planSubOptions.pageResult[0]
      }

      goodXTicketNo = await goodXSubmit({
        ...bookingData,
        patientRecord: patient,
        mainMemberRecord: mainMember,
        billingItems: billingItems.filter((item) => item.code && item.name && item.type),
        medicalProf,
        planSubOption
      })
    }

    await handleGenerateInvoice(goodXTicketNo)

    return goodXTicketNo
  }

  const extraContent = {
    left: (
      <Button
        onClick={() => closeTab()}
        disabled={loading || saving || completing}
        style={{ marginLeft: 12, marginRight: 12 }}
      >
        <FontAwesomeIcon icon={faXmark} color={token.colorBorder} /> Close
      </Button>
    ),
    right: (
      <Space style={{ marginLeft: 12, marginRight: 12 }}>
        {bookingData.isVirtualVisit &&
        !joined &&
        authState?.agiliteUser?.extraData?.role.type === 'medical_professional' ? (
          <Popconfirm
            title='Confirmation'
            description='Are you sure you want to start the Virtual Consultation?'
            onConfirm={() => startVirtualConsult()}
            okText='Yes'
            cancelText='No'
            okButtonProps={{ type: 'primary', style: { backgroundColor: token.colorSuccess } }}
            cancelButtonProps={{ danger: true }}
            disabled={loading}
          >
            <Button disabled={loading} type='primary'>
              <FontAwesomeIcon icon={faVideoCamera} /> Start Virtual Consult
            </Button>
          </Popconfirm>
        ) : undefined}
        {generateActions(bookingData.status) && !isPreviousBooking ? (
          <CustomButton
            size='small'
            loading={completing}
            onClick={() => {
              if (authState?.agiliteUser?.extraData?.role?.type === 'reception') {
                finalizeBilling()
              } else {
                if (bookingData.consultationRequired && authState.agiliteUser.extraData.profession === 'nurse') {
                  setModalOpen(true)
                } else {
                  handleSave(true)
                }
              }
            }}
            disabled={
              saving || authState?.agiliteUser?.extraData?.role?.type === 'reception' ? billingSyncRequired : false
            }
            tooltip={
              authState?.agiliteUser?.extraData?.role?.type === 'reception'
                ? billingSyncRequired
                  ? 'Please Refresh before you can Finalize Billing'
                  : undefined
                : undefined
            }
            type='success'
            style={{ backgroundColor: '#22C55E', borderColor: '#22C55E' }}
            hasPopconfirm={true}
            popConfirmTitle='Confirmation'
            popConfirmDescription={`Are you sure you want to ${
              data.status === CoreEnums.bookingStatuses.billing
                ? 'finalize billing'
                : data.status === CoreEnums.bookingStatuses.data_capture
                ? 'complete the data capture'
                : data.status === CoreEnums.bookingStatuses.screening
                ? bookingData.consultationRequired && authState.agiliteUser.extraData.profession === 'nurse'
                  ? 'move on to the next step'
                  : data.medicalProfRef === authState.agiliteUser._id
                  ? 'complete the Examination without scheduling a virtual consult'
                  : 'complete the Examination'
                : data.status === CoreEnums.bookingStatuses.diagnosis
                ? 'complete the Diagnosis'
                : data.status === CoreEnums.bookingStatuses.procedure
                ? 'complete the Procedure'
                : 'submit updates'
            }?`}
            text={
              data.status === CoreEnums.bookingStatuses.billing
                ? 'FINALIZE BILLING'
                : data.status === CoreEnums.bookingStatuses.data_capture
                ? 'COMPLETE DATA CAPTURE'
                : data.status === CoreEnums.bookingStatuses.screening
                ? bookingData.consultationRequired && authState.agiliteUser.extraData.profession === 'nurse'
                  ? 'SCHEDULE CONSULT'
                  : 'COMPLETE EXAMINATION'
                : data.status === CoreEnums.bookingStatuses.completed
                ? 'SUBMIT UPDATES'
                : data.status === CoreEnums.bookingStatuses.procedure
                ? 'COMPLETE BOOKING'
                : 'Complete Consult'
            }
            icon={faCheckCircle}
            iconPosition='start'
          />
        ) : undefined}
      </Space>
    )
  }

  return (
    <ContainerCard
      id='scroll-detection'
      bodyStyle={{ paddingTop: 0 }}
      title={
        isPreviousBooking
          ? 'Previous Booking: ' + dayjs(data.bookingDate).format('DD-MM-YYYY')
          : getMainTitle(bookingData.status)
      }
    >
      <CustomRow justify='center' gutter={[0, 0]}>
        <Col span={12}>
          {joined ? (
            <MedProfLiveVideoWrapper
              channelName={agoraChannel}
              token={agoraToken}
              uid={agoraUid}
              disconnectCallback={() => {
                setJoined(false)
              }}
            />
          ) : undefined}
        </Col>
        <Col span={24}>
          <Tabs
            tabBarExtraContent={extraContent}
            activeKey={activeTab}
            onChange={(key) => {
              setActiveTab(key)
              if (key === 'billing') {
                handleGetBillingRecords()
              }
            }}
            tabBarStyle={{ background: 'white' }}
            className='no-margin'
          >
            {isNurse() || isDoctor() ? (
              <>
                <Tabs.TabPane
                  tab='Assessment'
                  key='assessment'
                  icon={<FontAwesomeIcon icon={faStethoscope} style={{ color: token.colorPrimary }} />}
                >
                  <CustomRow style={{ background: '#ffffff', paddingTop: 16, paddingBottom: 24 }}>
                    <Col span={24}>
                      <TabContentWrapper
                        controlWidth={controlWidth}
                        setControlWidth={setControlWidth}
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                        previousDataLoading={previousDataLoading}
                        previousLatestBooking={previousLatestBooking}
                        token={token}
                        tabTitle='Assessment'
                        tabIcon={<FontAwesomeIcon icon={faStethoscope} style={{ color: token.colorPrimary }} />}
                      >
                        <ExaminationWrapper bookingData={data} />
                      </TabContentWrapper>
                    </Col>
                  </CustomRow>
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab='Care Plans' key='carePlans'>
                  <CustomRow style={{ background: '#ffffff', paddingBottom: 24 }}>
                    <Col span={24}>
                      <PatientCarePlans userRef={bookingData?.userRef} />
                    </Col>
                  </CustomRow>
                </Tabs.TabPane> */}
                {/* <Tabs.TabPane tab='Protocol' key='protocol'>
                  <CustomRow style={{ background: '#ffffff', paddingBottom: 24 }}>
                    <Col span={24}>
                      <ProtocolAi bookingData={data} />
                    </Col>
                  </CustomRow>
                </Tabs.TabPane> */}
                <Tabs.TabPane
                  tab='Notes'
                  key='clinicalNotes'
                  icon={<FontAwesomeIcon icon={faNotesMedical} style={{ color: '#1890ff' }} />}
                >
                  <CustomRow style={{ background: '#ffffff', paddingTop: 16, paddingBottom: 24 }}>
                    <Col span={24}>
                      <TabContentWrapper
                        controlWidth={controlWidth}
                        setControlWidth={setControlWidth}
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                        previousDataLoading={previousDataLoading}
                        previousLatestBooking={previousLatestBooking}
                        token={token}
                        tabTitle='Clinical Notes & Diagnosis'
                        tabIcon={<FontAwesomeIcon icon={faNotesMedical} style={{ color: '#1890ff' }} />}
                      >
                        <ClinicalNotes bookingData={data} setBookingData={setBookingData} />
                      </TabContentWrapper>
                    </Col>
                  </CustomRow>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab='Documents'
                  key='documents'
                  icon={<FontAwesomeIcon icon={faFileAlt} style={{ color: '#52c41a' }} />}
                >
                  <CustomRow style={{ background: '#ffffff', paddingTop: 16, paddingBottom: 24 }}>
                    <Col span={24}>
                      <TabContentWrapper
                        controlWidth={controlWidth}
                        setControlWidth={setControlWidth}
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                        previousDataLoading={previousDataLoading}
                        previousLatestBooking={previousLatestBooking}
                        token={token}
                        tabTitle='Documents'
                        tabIcon={<FontAwesomeIcon icon={faFileAlt} style={{ color: '#52c41a' }} />}
                      >
                        <DocumentsWrapper bookingData={data} />
                      </TabContentWrapper>
                    </Col>
                  </CustomRow>
                </Tabs.TabPane>
              </>
            ) : null}
            {isDoctor() ? (
              <Tabs.TabPane
                tab='Scripting'
                key='scripts'
                icon={<FontAwesomeIcon icon={faFilePrescription} style={{ color: '#722ed1' }} />}
              >
                <CustomRow style={{ background: '#ffffff', paddingTop: 16, paddingBottom: 24 }}>
                  <Col span={24}>
                    <TabContentWrapper
                      controlWidth={controlWidth}
                      setControlWidth={setControlWidth}
                      bookingData={bookingData}
                      setBookingData={setBookingData}
                      previousDataLoading={previousDataLoading}
                      previousLatestBooking={previousLatestBooking}
                      token={token}
                      tabTitle='Scripting'
                      tabIcon={<FontAwesomeIcon icon={faFilePrescription} style={{ color: '#722ed1' }} />}
                    >
                      <ScriptsWrapper patientId={bookingData.userRef} bookingRef={bookingData._id} />
                    </TabContentWrapper>
                  </Col>
                </CustomRow>
              </Tabs.TabPane>
            ) : undefined}

            <Tabs.TabPane
              tab='Billing'
              key='billing'
              icon={<FontAwesomeIcon icon={faMoneyBill} style={{ color: '#faad14' }} />}
            >
              <CustomRow justify='center' style={{ background: '#ffffff', paddingTop: 16, paddingBottom: 24 }}>
                <Col span={24}>
                  <TabContentWrapper
                    controlWidth={controlWidth}
                    setControlWidth={setControlWidth}
                    bookingData={bookingData}
                    setBookingData={setBookingData}
                    previousDataLoading={previousDataLoading}
                    previousLatestBooking={previousLatestBooking}
                    token={token}
                    tabTitle='Billing'
                    tabIcon={<FontAwesomeIcon icon={faMoneyBill} style={{ color: '#faad14' }} />}
                  >
                    <Billing
                      isReception={authState.agiliteUser.extraData.role.type === 'reception'}
                      billingItems={billingItems}
                      setBillingItems={setBillingItems}
                      bookingData={bookingData}
                      setBookingData={setBookingData}
                      handleGetBillingRecords={handleGetBillingRecords}
                      billingRecordsLoading={billingRecordsLoading}
                      setBillingSyncRequired={setBillingSyncRequired}
                      billingWebSocket={billingWebSocket}
                    />{' '}
                  </TabContentWrapper>
                </Col>
              </CustomRow>
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab='Files' key='archive' icon={<FontAwesomeIcon icon={faFileArchive} />}>
              <CustomRow justify='center' style={{ background: '#ffffff', paddingBottom: 24 }}>
                <Col span={24}>
                  <TabContentWrapper
                    controlWidth={controlWidth}
                    setControlWidth={setControlWidth}
                    bookingData={bookingData}
                    setBookingData={setBookingData}
                    previousDataLoading={previousDataLoading}
                    previousLatestBooking={previousLatestBooking}
                    token={token}
                  >
                    <FileArchive bookingData={data} />{' '}
                  </TabContentWrapper>
                </Col>
              </CustomRow>
            </Tabs.TabPane> */}
            {(isDoctor() || isAdmin()) && !isPreviousBooking ? (
              <Tabs.TabPane
                tab='Bookings'
                key='previousBookings'
                icon={<FontAwesomeIcon icon={faCalendarCheck} style={{ color: '#13c2c2' }} />}
              >
                <CustomRow style={{ background: '#ffffff', paddingTop: 16 }}>
                  <Col span={24}>
                    <TabContentWrapper
                      controlWidth={controlWidth}
                      setControlWidth={setControlWidth}
                      bookingData={bookingData}
                      setBookingData={setBookingData}
                      previousDataLoading={previousDataLoading}
                      previousLatestBooking={previousLatestBooking}
                      token={token}
                      tabTitle='Bookings'
                      tabIcon={<FontAwesomeIcon icon={faCalendarCheck} style={{ color: '#13c2c2' }} />}
                    >
                      <EmployeeBookingsView userRef={bookingData.userRef} />{' '}
                    </TabContentWrapper>
                  </Col>
                </CustomRow>
              </Tabs.TabPane>
            ) : null}
            <Tabs.TabPane tab='Journey' key='patientJourney' icon={<FontAwesomeIcon icon={faRoute} />}>
              <CustomRow style={{ background: '#ffffff', paddingTop: 16, paddingBottom: 24 }}>
                <Col span={24}>
                  <TabContentWrapper
                    controlWidth={controlWidth}
                    setControlWidth={setControlWidth}
                    bookingData={bookingData}
                    setBookingData={setBookingData}
                    previousDataLoading={previousDataLoading}
                    previousLatestBooking={previousLatestBooking}
                    token={token}
                    tabTitle='Patient Journey'
                    tabIcon={<FontAwesomeIcon icon={faRoute} />}
                  >
                    <PatientJourneyList workflowHistory={workflowHistory} />
                  </TabContentWrapper>
                </Col>
              </CustomRow>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </CustomRow>
      {modalOpen ? (
        <NurseToDoctor
          setData={setBookingData}
          data={bookingData}
          loading={loading}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleSave={handleSave}
        />
      ) : undefined}
      <Modal
        open={printingReport}
        maskClosable={false}
        closable={false}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <CustomRow>
          <Col span={24}>
            <AgiliteSkeleton skActive spinnerTip='Printing report...' />
          </Col>
        </CustomRow>
      </Modal>
    </ContainerCard>
  )
}

export default PatientExaminationForm
