import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, InputNumber, Space } from 'antd'
import theme from '../../../core/utils/theme'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

export const CarePlanTemplatesColumnTemplate = () => {
  return [
    {
      title: 'name',
      key: 'name',
      dataIndex: 'name'
    }
  ]
}

export const CarePlanTemplatesNewRecordTemplate = (key, dataKey, dataLabel) => {
  switch (key) {
    case 'data':
      return {
        dataLabel,
        dataKey, // Reading Reference
        count: 1, // Determines number of readings required
        trackerType: 'data', // Determines how to handle the check
        intervalDays: 180 // Days required between readings to be valid
      }

    case 'visits':
      return {
        dataLabel,
        dataKey, // Reading Reference
        count: 1, // Determines number of readings required
        trackerType: 'visits', // Determines how to handle the check
        intervalDays: 180 // Days required between readings to be valid
      }
  }
}
const unCamelCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space between lowercase and uppercase letters
    .replace(/^./, (char) => char.toUpperCase()) // Capitalize the first letter
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
    .join(' ')
}
export const CarePlanTemplatesNewRecordVisitsColumnTemplate = (handleUpdateTracker, handleRemoveTracker) => {
  return [
    {
      title: 'Doctor Type',
      key: 'dataKey',
      dataIndex: 'dataKey',
      render: (text) => unCamelCase(text)
    },
    {
      title: 'Visits Required',
      key: 'count',
      dataIndex: 'count',
      render: (value, record, index) => {
        return (
          <InputNumber value={value} onChange={(number) => handleUpdateTracker(number, index, 'count', 'visits')} />
        )
      }
    },
    {
      title: 'Days Interval',
      key: 'intervalDays',
      dataIndex: 'intervalDays',
      render: (value, record, index) => {
        return (
          <InputNumber
            value={value}
            onChange={(number) => handleUpdateTracker(number, index, 'intervalDays', 'visits')}
          />
        )
      }
    },
    {
      render: (value, record, index) => {
        return (
          <Space>
            <Button>
              <FontAwesomeIcon
                color={theme.twitterBootstrap.danger}
                onClick={() => {
                  handleRemoveTracker(index, 'visits')
                }}
                icon={faTrash}
              />
            </Button>
          </Space>
        )
      }
    }
  ]
}

export const CarePlanTemplatesNewRecordDataColumnTemplate = (handleUpdateTracker, dataPoints, handleRemoveTracker) => {
  return [
    {
      title: 'Doctor Type',
      key: 'dataKey',
      dataIndex: 'dataKey',
      render: (text) => {
        const dataReference = dataPoints.find((i) => i.key === text)
        return dataReference.label
      }
    },
    {
      title: 'Readings Required',
      key: 'count',
      dataIndex: 'count',
      render: (value, record, index) => {
        return <InputNumber value={value} onChange={(number) => handleUpdateTracker(number, index, 'count', 'data')} />
      }
    },
    {
      title: 'Days Interval',
      key: 'intervalDays',
      dataIndex: 'intervalDays',
      render: (value, record, index) => {
        return (
          <InputNumber
            value={value}
            onChange={(number) => handleUpdateTracker(number, index, 'intervalDays', 'data')}
          />
        )
      }
    },
    {
      render: (value, record, index) => {
        return (
          <Space>
            <Button
              onClick={() => {
                handleRemoveTracker(index, 'data')
              }}
            >
              <FontAwesomeIcon color={theme.twitterBootstrap.danger} icon={faTrash} />
            </Button>
          </Space>
        )
      }
    }
  ]
}
