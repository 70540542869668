import { Alert, Button, Card, Col, Row, Select, Space, message, theme } from 'antd'
import { useEffect, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  PieChart,
  Pie,
  Cell,
  LineChart,
  CartesianGrid,
  Line
} from 'recharts'
import { handleError } from '../../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { useSelector } from 'react-redux'
import { readAnaltyicalData } from '../utils/utils'

const ClinicalAnalytics = () => {
  const authState = useSelector((state) => state.auth)
  const state = useSelector((state) => state)
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [clinics, setClinics] = useState([])
  const [selectedClinic, setSelectedClinic] = useState(null)
  const [analyticsData, setAnalyticsData] = useState(null)

  const { token } = theme.useToken()

  useEffect(() => {
    if (selectedClinic) {
      handleGetAnalyticalData()
    }
    // eslint-disable-next-line
  }, [selectedClinic])

  useEffect(() => {
    handleGetClinics()
    // eslint-disable-next-line
  }, [])

  const handleGetClinics = () => {
    const tmpClinics = []

    if (authState.agiliteUser.extraData.role.type === 'admin') {
      state.clinics.data.map((clinic) => {
        return tmpClinics.push({
          label: clinic.name,
          value: clinic._id
        })
      })

      setClinics(tmpClinics)
    } else {
      authState.agiliteUser.extraData.clinics.map((entry) => {
        const clinic = state.clinics.data.find((clinic) => clinic._id === entry)
        if (clinic) {
          return tmpClinics.push({
            label: clinic.name,
            value: clinic._id
          })
        } else {
          return null
        }
      })

      setClinics(tmpClinics)
    }

    if (tmpClinics.length > 0) {
      setSelectedClinic(tmpClinics[0].value)
    }
  }

  const handleGetAnalyticalData = async () => {
    setLoading(true)
    try {
      const tmpAnalytics = await readAnaltyicalData({ clinicRef: selectedClinic }, { sort: { dateCreated: -1 } })
      setAnalyticsData(tmpAnalytics)

      // Debug log
      console.log('Analytics Data:', tmpAnalytics)
      if (tmpAnalytics?.patientsByAgeGroup) {
        console.log('Patients by Age Group:', tmpAnalytics.patientsByAgeGroup)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoadingText('')
    setLoading(false)
  }

  const formatBMIData = () => {
    const tmpBmiData = []

    for (const key in analyticsData?.bmiBands) {
      tmpBmiData.push({
        name: key.toUpperCase(),
        total: analyticsData.bmiBands[key]
      })
    }

    return tmpBmiData
  }

  const formatBookingsByMonthData = () => {
    const tmpBookingsByMonthData = []
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    if (analyticsData?.bookingsByMonth) {
      analyticsData?.bookingsByMonth.forEach((amount, index) => {
        tmpBookingsByMonthData.push({
          name: months[index],
          total: amount
        })
      })
    }

    return tmpBookingsByMonthData
  }

  const formatPatientsByAgeGroupData = () => {
    if (!analyticsData?.patientsByAgeGroup) {
      console.log('No patientsByAgeGroup data available')
      return []
    }

    console.log('Formatting age group data:', analyticsData.patientsByAgeGroup)

    // Define the order of age groups
    const ageGroupOrder = ['<17', '7-10', '11-19', '20-29', '30-39', '40-49', '50-59', '60+']
    const result = []

    // Check the structure of patientsByAgeGroup
    if (Array.isArray(analyticsData.patientsByAgeGroup)) {
      // Handle array structure
      analyticsData.patientsByAgeGroup.forEach((item) => {
        if (item && item.label) {
          result.push({
            name: item.label,
            total: item.value || 0
          })
        }
      })

      // Sort according to ageGroupOrder
      result.sort((a, b) => {
        const indexA = ageGroupOrder.indexOf(a.name)
        const indexB = ageGroupOrder.indexOf(b.name)
        return (indexA === -1 ? 999 : indexA) - (indexB === -1 ? 999 : indexB)
      })
    } else {
      // Handle object structure
      // Create entries for all age groups, even if they have 0 values
      ageGroupOrder.forEach((ageGroup) => {
        // Find the matching data in analyticsData
        let found = false

        for (const key in analyticsData.patientsByAgeGroup) {
          const item = analyticsData.patientsByAgeGroup[key]
          if (item && item.label === ageGroup) {
            result.push({
              name: ageGroup,
              total: item.value || 0
            })
            found = true
            break
          }
        }

        // If no data found for this age group, add it with 0 value
        if (!found) {
          result.push({
            name: ageGroup,
            total: 0
          })
        }
      })
    }

    console.log('Formatted age group data:', result)
    return result
  }

  const formatProceduresByMonthData = () => {
    const tmpProceduresByMonthData = []
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    if (analyticsData?.proceduresByMonth) {
      analyticsData?.proceduresByMonth.forEach((amount, index) => {
        tmpProceduresByMonthData.push({
          name: months[index],
          total: amount
        })
      })
    }

    return tmpProceduresByMonthData
  }

  const formatScriptsByMonthData = () => {
    const tmpScriptsByMonth = []
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    if (analyticsData?.scriptsByMonth) {
      analyticsData?.scriptsByMonth.forEach((amount, index) => {
        tmpScriptsByMonth.push({
          name: months[index],
          total: amount
        })
      })
    }

    return tmpScriptsByMonth
  }

  const GenderColors = [token.colorPrimary, token.colorSecondary]
  const MedicalAidColors = [token.colorSuccess, token.colorPrimary]

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>{`${(
        percent * 100
      ).toFixed(0)}%`}</text>
    )
  }

  return (
    <Row style={{ margin: 10 }}>
      <Col span={24}>
        <Card
          size='small'
          extra={
            <Space>
              <Space>
                Clinic:
                <Select
                  placeholder='Select Clinic'
                  options={clinics}
                  onChange={(value) => setSelectedClinic(value)}
                  value={selectedClinic}
                  style={{ width: 200 }}
                />
              </Space>
              <Button onClick={() => handleGetAnalyticalData()}>
                <Space>
                  <FontAwesomeIcon icon={faRefresh} color={token.colorSuccess} />
                  Refresh
                </Space>
              </Button>
            </Space>
          }
        >
          {loading ? (
            <AgiliteSkeleton spinnerTip={`Loading ${loadingText}...`} />
          ) : (
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Card size='small' type='inner' style={{ padding: 10 }} styles={{ body: { padding: 5 } }}>
                  <center>Patients By Age Group</center>
                  {analyticsData?.patientsByAgeGroup ? (
                    <ResponsiveContainer width='100%' height={300}>
                      <BarChart
                        data={formatPatientsByAgeGroupData()}
                        margin={{
                          top: 20,
                          right: 10,
                          left: 10,
                          bottom: 60
                        }}
                        barSize={20}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' angle={0} interval={0} tick={{ fontSize: 10 }} tickLine={true}>
                          <Label value='Age Groups' offset={0} position='bottom' />
                        </XAxis>
                        <YAxis allowDecimals={false} tickLine={true} />
                        <Tooltip formatter={(value) => [`${value} patients`, 'Total']} />
                        <Legend verticalAlign='top' wrapperStyle={{ lineHeight: '40px' }} />
                        <Bar dataKey='total' name='Total Patients' fill={token.colorPrimary} radius={[4, 4, 0, 0]} />
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <div style={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Alert message='No age group data available' type='info' />
                    </div>
                  )}
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Card size='small' type='inner' style={{ padding: 10 }} styles={{ body: { padding: 5 } }}>
                  <Row justify='space-around'>
                    <Col span={11} style={{ height: 100 }}>
                      <Alert
                        message={
                          <>
                            <Row justify='center'>
                              <Col>Patients</Col>
                            </Row>
                            <Row justify='center'>
                              <Col>
                                <p style={{ fontSize: 30, color: token.colorSecondary }}>
                                  {analyticsData?.totalPatients || 0}
                                </p>
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    </Col>
                    <Col span={11} style={{ height: 100 }}>
                      <Alert
                        type='success'
                        message={
                          <>
                            <Row justify='center'>
                              <Col>Visits</Col>
                            </Row>
                            <Row justify='center'>
                              <Col>
                                <p style={{ fontSize: 30, color: token.colorPrimary }}>
                                  {analyticsData?.totalBookings || 0}
                                </p>
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    </Col>
                  </Row>
                  <Row justify='space-around' style={{ marginTop: 10 }}>
                    <Col span={11}>
                      <ResponsiveContainer width='100%' height={200}>
                        <PieChart>
                          <Pie
                            data={[
                              {
                                name: 'Male',
                                value: analyticsData?.maleVsFemale?.male || 0
                              },
                              {
                                name: 'Female',
                                value: analyticsData?.maleVsFemale?.female || 0
                              }
                            ]}
                            cx='50%'
                            cy='50%'
                            label={renderCustomizedLabel}
                            labelLine={false}
                            outerRadius={80}
                            fill='#8884d8'
                            dataKey='value'
                          >
                            <Cell key={`cell-${0}`} fill={GenderColors[1 % GenderColors.length]} />
                            <Cell key={`cell-${1}`} fill={GenderColors[2 % GenderColors.length]} />
                          </Pie>
                          <Legend wrapperStyle={{ bottom: 0 }} />
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Col>
                    <Col span={11}>
                      <ResponsiveContainer width='100%' height={200}>
                        <PieChart>
                          <Pie
                            data={[
                              {
                                name: 'Medical Aid',
                                value: analyticsData?.medicalAidVsPrivate?.medicalAid || 0
                              },
                              {
                                name: 'Private',
                                value: analyticsData?.medicalAidVsPrivate?.private || 0
                              }
                            ]}
                            cx='50%'
                            cy='50%'
                            label={renderCustomizedLabel}
                            labelLine={false}
                            outerRadius={80}
                            fill='#8884d8'
                            dataKey='value'
                          >
                            <Cell key={`cell-${0}`} fill={MedicalAidColors[0 % MedicalAidColors.length]} />
                            <Cell key={`cell-${1}`} fill={MedicalAidColors[1 % MedicalAidColors.length]} />
                          </Pie>
                          <Legend wrapperStyle={{ bottom: 0 }} />
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Card size='small' type='inner' style={{ padding: 10 }} styles={{ body: { padding: 5 } }}>
                  <center>Appointments By Month</center>
                  <ResponsiveContainer width='100%' height={300}>
                    <LineChart
                      data={formatBookingsByMonthData()}
                      margin={{
                        top: 30,
                        right: 10,
                        left: 0,
                        bottom: 40
                      }}
                    >
                      <XAxis dataKey='name' angle={-90} dy={15} dx={-5} style={{ padding: 10 }} />
                      <YAxis label={{ value: 'Bookings', angle: -90, dx: -20, style: { fontSize: 14 } }} />
                      <CartesianGrid stroke='#eee' strokeDasharray='5 5' />
                      <Line type='monotone' dataKey='total' stroke='#8884d8' />
                      <Tooltip />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Card size='small' type='inner' style={{ padding: 10 }} styles={{ body: { padding: 5 } }}>
                  <center>BMI Bands</center>
                  <ResponsiveContainer width='100%' height={300}>
                    <BarChart
                      data={formatBMIData()}
                      margin={{
                        top: 30,
                        right: 0,
                        left: 0,
                        bottom: 50
                      }}
                    >
                      <XAxis dataKey='name' tickCount={8} padding={{ left: 10, right: 10 }} tickMargin={30} />
                      <YAxis type='number' />
                      <Tooltip />
                      <Bar dataKey='total' fill={token.colorSecondary} />
                    </BarChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Card size='small' type='inner' style={{ padding: 10 }} styles={{ body: { padding: 5 } }}>
                  <center>Procedures Performed By Month</center>
                  <ResponsiveContainer width='100%' height={300}>
                    <BarChart
                      data={formatProceduresByMonthData()}
                      margin={{
                        top: 30,
                        right: 0,
                        left: 0,
                        bottom: 50
                      }}
                    >
                      <XAxis
                        dataKey='name'
                        angle={-90}
                        tickCount={8}
                        padding={{ left: 10, right: 10 }}
                        tickMargin={30}
                      />
                      <YAxis type='number' />
                      <Tooltip />
                      <Bar dataKey='total' fill={token.colorSuccess} />
                    </BarChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Card size='small' type='inner' style={{ padding: 10 }} styles={{ body: { padding: 5 } }}>
                  <center>Scripts By Month</center>
                  <ResponsiveContainer width='100%' height={300}>
                    <LineChart
                      data={formatScriptsByMonthData()}
                      margin={{
                        top: 30,
                        right: 10,
                        left: 0,
                        bottom: 40
                      }}
                    >
                      <XAxis dataKey='name' angle={-90} dy={15} dx={-5} style={{ padding: 10 }} />
                      <YAxis label={{ value: 'Bookings', angle: -90, dx: -20, style: { fontSize: 14 } }} />
                      <CartesianGrid stroke='#eee' strokeDasharray='5 5' />
                      <Line type='monotone' dataKey='total' stroke='#8884d8' />
                      <Tooltip />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  )
}

export default ClinicalAnalytics
