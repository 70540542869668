import React, { memo, useState } from 'react'
import { Col, Button, Table, Empty, Input, Space, theme, Row, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import PropTypes from 'prop-types'

import CustomRow from '../../../reusable-components/CustomRow'
import TeamChatForm from '../../../TeamChat/components/team-chat-form'
import CustomLoadingIcon from '../../../reusable-components/CustomLoadingIcon'
import coreReducer from '../../../../core/utils/reducer'
import SystemUsersTemplates from '../utils/templates'
import useSystemUsersTable from '../hooks/useSystemUsersTable'
import { getInviteId, getInviteLabel } from '../utils/constants'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { readBookings } from '../../../Bookings/utils/utils'
import PatientExaminationForm from '../../../Bookings/components/ExaminationWrapper'
import { getBookingState } from '../../../Bookings/utils/bpm-utils'
import CoreEnums from '../../../../core/utils/enums'

// Add spin animation
const spinAnimation = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

const SystemUsersTable = ({
  addPatient,
  createRecord,
  editRecord,
  loading,
  setLoading,
  type,
  handleSearch,
  clinics,
  page,
  pageSize,
  refreshView,
  setPage,
  setPageSize,
  total
}) => {
  const servicesState = useSelector((state) => state.services.data)
  const clinicsState = useSelector((state) => state.clinics.data)
  const [consultLoading, setConsultLoading] = useState(false)

  const { viewType, tableData, userFilters, teamChatProps, setTeamChatProps, state, coreState, dispatch } =
    useSystemUsersTable({ type, clinics })

  const { token } = theme.useToken()

  const handleCreateRecord = () => {
    createRecord()
    if (coreState.tourOpen) {
      dispatch(coreReducer.actions.nextTourStep())
    }
  }

  const handleStartConsult = async (record) => {
    if (!record || !record._id) return

    try {
      // Show loading state
      setConsultLoading(true)

      // Get the patient's last appointment
      const patientAppointments = await readBookings({ userRef: record._id })

      if (!patientAppointments || patientAppointments.length === 0) {
        message.info('No appointments found for this patient')
        setConsultLoading(false)
        return
      }

      // Sort appointments by date (newest first)
      const sortedAppointments = patientAppointments.sort((a, b) => {
        const dateA = new Date(a.bookingDate)
        const dateB = new Date(b.bookingDate)
        return dateB - dateA
      })

      const lastAppointment = sortedAppointments[0]

      // Get workflow history if available
      let workflowHistory = {}
      if (lastAppointment.processRef) {
        workflowHistory = await getBookingState([lastAppointment.processRef])
        workflowHistory.history.splice(0, 1)
      }

      // Create a new data object that includes the patient record
      const appointmentData = {
        ...lastAppointment,
        patientRecord: {
          ...record,
          medicalAid: record.medicalAid || {} // Ensure medicalAid exists
        },
        mainMemberProfile: {
          ...record,
          medicalAid: record.medicalAid || {} // Ensure medicalAid exists
        }
      }

      // Open the appointment in a new tab
      dispatch(
        coreReducer.actions.addTab({
          key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${lastAppointment._id}`,
          closable: true,
          label: `Booking: ${record.firstName} ${record.lastName}`,
          children: (
            <PatientExaminationForm data={appointmentData} refreshView={() => {}} workflowHistory={workflowHistory} />
          )
        })
      )
    } catch (error) {
      message.error('Failed to open patient consultation: ' + error.message)
    } finally {
      setConsultLoading(false)
    }
  }

  const getSearchPlaceholder = () => {
    if (type === 'system' || type === 'medical') {
      return 'Search for First Name, Last Name or Email...'
    }
    return 'Search for Name, ID/Passport, Email, Address...'
  }

  const renderAddButton = () => {
    if (type === 'patient') {
      return (
        <Button onClick={addPatient} type='primary' disabled={loading}>
          Add Patient
        </Button>
      )
    }

    const allowedRoles = ['admin', 'reception', 'super_admin']
    const userRole = state.auth.agiliteUser?.extraData?.role?.type

    if (allowedRoles.includes(userRole)) {
      return (
        <Button onClick={handleCreateRecord} type='primary' disabled={loading} id={getInviteId(type)}>
          Add {getInviteLabel(type)}
        </Button>
      )
    }

    return null
  }

  const getServices = (services) => {
    if (!services) return 'N/A'
    return services.map((service) => servicesState.find((s) => s._id === service)?.name).join(', ')
  }

  const getClinics = (clinics) => {
    if (!clinics) return 'N/A'
    return clinics.map((clinic) => clinicsState.find((c) => c._id === clinic)?.name).join(', ')
  }
  return (
    <>
      <style>{spinAnimation}</style>
      <CustomRow gutter={[12, 12]} style={{ marginBottom: 20 }} className='basic-card'>
        <Col span={24}>
          <Space wrap>
            {renderAddButton()}
            <Input
              style={{ width: 425 }}
              placeholder={getSearchPlaceholder()}
              suffix={<FontAwesomeIcon icon={faSearch} />}
              allowClear
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Button onClick={refreshView} style={{ background: token.colorSuccess }} type='primary' disabled={loading}>
              <FontAwesomeIcon icon={faRefresh} />
            </Button>
          </Space>
        </Col>

        <Col span={24}>
          <CustomLoadingIcon
            loading={loading}
            loadingText={`Loading ${type} profiles...`}
            content={
              <Table
                className={loading ? 'custom-loading-mask' : ''}
                size='small'
                rowKey='_id'
                dataSource={_.sortBy(tableData, ['firstName', 'lastName'])}
                pagination={{
                  current: page,
                  pageSize,
                  total,
                  onChange: (p, ps) => {
                    setPage(p)
                    setPageSize(ps)
                  }
                }}
                columns={[
                  ...SystemUsersTemplates.columnTemplate(
                    userFilters,
                    type,
                    clinics,
                    token,
                    setTeamChatProps,
                    editRecord,
                    handleStartConsult,
                    false,
                    consultLoading
                  ).filter((i) => !i.hidden)
                ]}
                expandedRowRender={(record) => {
                  return (
                    <Row style={{ background: 'white', padding: 8 }}>
                      <Col span={12}>
                        <div>
                          <Space direction='vertical' style={{ width: '100%' }}>
                            <Space>
                              <p style={{ width: 80 }}>
                                <b>Status:</b>
                              </p>
                              <p>
                                {record.extraData?.isActive ? (
                                  <span style={{ color: token.colorSuccess }}>Active</span>
                                ) : (
                                  <span style={{ color: token.colorError }}>Suspended</span>
                                )}
                              </p>
                            </Space>

                            {record.extraData?.role?.type !== 'patient' && (
                              <Space>
                                <p style={{ width: 80 }}>
                                  <b>Manager:</b>
                                </p>
                                <p>{record.extraData?.role?.isManager ? 'Yes' : 'No'}</p>
                              </Space>
                            )}

                            <Space>
                              <p style={{ width: 80 }}>
                                <b>Address:</b>
                              </p>
                              <p>{record.residentialAddress}</p>
                            </Space>
                            <Space>
                              <p style={{ width: 80 }}>
                                <b>DOB:</b>
                              </p>
                              <p>{dayjs(record.dateOfBirth).format('DD/MM/YYYY')}</p>
                            </Space>
                          </Space>
                        </div>
                      </Col>
                      <Col span={12}>
                        {record.extraData?.role?.type !== 'patient' ? (
                          <div>
                            <Space direction='vertical' style={{ width: '100%' }}>
                              {record.extraData.role.type === 'medical_professional' ? (
                                <>
                                  <Space>
                                    <p style={{ width: 110 }}>
                                      <b>Profession:</b>
                                    </p>
                                    <p>
                                      {record.extraData?.profession
                                        ? record.extraData.profession.charAt(0).toUpperCase() +
                                          record.extraData.profession.slice(1)
                                        : 'N/A'}
                                    </p>
                                  </Space>
                                  <Space>
                                    <p style={{ width: 110 }}>
                                      <b>Services</b>
                                    </p>
                                    <p>{getServices(record.extraData?.services)}</p>
                                  </Space>
                                </>
                              ) : (
                                <Space>
                                  <p style={{ width: 110 }}>
                                    <b>Role</b>
                                  </p>
                                  <p>
                                    {record.extraData.role.type.charAt(0).toUpperCase() +
                                      record.extraData.role.type.slice(1)}
                                  </p>
                                </Space>
                              )}
                              <Space>
                                <p style={{ width: 110 }}>
                                  <b>Clinics:</b>
                                </p>
                                <p>{record.extraData?.clinics ? getClinics(record.extraData?.clinics) : 'N/A'}</p>
                              </Space>
                            </Space>
                          </div>
                        ) : undefined}
                        {record.extraData?.role?.type === 'patient' && (
                          <div>
                            <Space direction='vertical' style={{ width: '100%' }}>
                              <Space>
                                <p style={{ width: 110 }}>
                                  <b>Main Member:</b>
                                </p>
                                <p>{record.mainMemberId ? record.mainMemberName : 'N/A'}</p>
                              </Space>
                              <Space>
                                <p style={{ width: 110 }}>
                                  <b>Medical Aid:</b>
                                </p>
                                <p>{record.medicalAid?.name ? record.medicalAid.name : 'N/A'}</p>
                              </Space>
                              <Space>
                                <p style={{ width: 110 }}>
                                  <b>Plan:</b>
                                </p>
                                <p>{record.medicalAid?.plan ? record.medicalAid.plan : 'N/A'}</p>
                              </Space>
                              <Space>
                                <p style={{ width: 110 }}>
                                  <b>Option:</b>
                                </p>
                                <p>{record.medicalAid?.planOption ? record.medicalAid.planOption : 'N/A'}</p>
                              </Space>

                              <Space>
                                <p style={{ width: 110 }}>
                                  <b>Dep Nr.:</b>
                                </p>
                                <p>{record.medicalAid?.dependantNumber ? record.medicalAid.dependantNumber : 'N/A'}</p>
                              </Space>
                            </Space>
                          </div>
                        )}
                      </Col>
                    </Row>
                  )
                }}
                locale={{ emptyText: <Empty description={`There are currently no registered ${viewType} to show.`} /> }}
                scroll={{ x: 1000 }}
                bordered
              />
            }
          />
        </Col>
      </CustomRow>
      <TeamChatForm modalProps={teamChatProps} setModalProps={setTeamChatProps} />
    </>
  )
}

SystemUsersTable.propTypes = {
  addPatient: PropTypes.func.isRequired,
  createRecord: PropTypes.func.isRequired,
  editRecord: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  clinics: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  refreshView: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired
}

export default memo(SystemUsersTable)
