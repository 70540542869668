// src/components/BatteryStatus.js
import React, { useContext, useEffect, useState } from 'react'
import BluetoothContext from '../BluetoothContext'
import { Spin } from 'antd'
import { isAndroid } from 'react-device-detect'
import { formatDeviceError, logDeviceError } from '../utils/errorHandling'

const Detection = {
  BATTERY: 'Battery'
}

const BatteryStatus = () => {
  const { engine, bleCommandCharacteristic, connectionStatus } = useContext(BluetoothContext)
  const [batteryLevel, setBatteryLevel] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const [isRetrying, setIsRetrying] = useState(false)

  useEffect(() => {
    // Only attempt to get battery status when connected
    if (connectionStatus === 'connected' && engine && bleCommandCharacteristic) {
      getBatteryStatus()
    }
  }, [connectionStatus, engine, bleCommandCharacteristic])

  useEffect(() => {
    if (!engine || !bleCommandCharacteristic) {
      console.warn('Engine or bleCommandCharacteristic not available for BatteryStatus')
      return
    }

    console.log('Registering Battery Listener')
    if (typeof engine.registBatteryListener === 'function') {
      engine.registBatteryListener({
        onProcess: (type, data) => {
          console.log('Battery onProcess:', type, data)
          if (bleCommandCharacteristic) {
            bleCommandCharacteristic.writeValue(data).catch((err) => {
              logDeviceError(err, 'BatteryStatus', 'writing to characteristic')
            })
          }
        },
        onPowerResult: (data) => {
          console.log('Battery Level:', data)
          setBatteryLevel(data)
          setIsLoading(false)
          setIsRetrying(false)
          setRetryCount(0) // Reset retry count on success
        },
        onException: (err) => {
          logDeviceError(err, 'BatteryStatus', 'getting battery level')
          setIsLoading(false)

          // For Android, retry a few times if we get an error
          if (isAndroid && retryCount < 3) {
            console.log(`Retrying battery status (attempt ${retryCount + 1})...`)
            setRetryCount((prev) => prev + 1)
            setIsRetrying(true)
            setTimeout(() => {
              getBatteryStatus()
              setIsRetrying(false)
            }, 1000)
          }
        }
      })
    } else {
      console.error('engine.registBatteryListener is not a function')
    }

    return () => {
      console.log('Unregistering Battery Listener')
      if (typeof engine.unregistBatteryListener === 'function') {
        engine.unregistBatteryListener()
      } else {
        console.warn('engine.unregistBatteryListener is not a function')
      }
    }
    // eslint-disable-next-line
  }, [engine, bleCommandCharacteristic])

  // Define getBatteryStatus function
  const getBatteryStatus = () => {
    console.log('Getting Battery Status')
    if (engine && bleCommandCharacteristic) {
      setIsLoading(true)

      // For Android, we might need a slight delay before requesting battery status
      if (isAndroid) {
        setTimeout(() => {
          engine.startDetect(Detection.BATTERY)
        }, 500)
      } else {
        engine.startDetect(Detection.BATTERY)
      }
    } else {
      console.warn('Engine or bleCommandCharacteristic is not available')
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <h3>Battery Status</h3>
      {isLoading ? (
        <p>
          {isRetrying ? 'Retrying battery check...' : 'Checking battery...'} <Spin size='small' />
        </p>
      ) : (
        <p>Battery Level: {batteryLevel !== null ? `${batteryLevel}%` : '--'}</p>
      )}
      {isAndroid && batteryLevel === null && !isLoading && (
        <p style={{ fontSize: '0.8em', color: '#888' }}>Note: Battery status may be limited on some Android devices</p>
      )}
    </div>
  )
}

export default BatteryStatus
