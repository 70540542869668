export const STORAGE_KEY = 'doctor_dashboard_tasks'
export const DATE_FORMAT = 'DD-MM-YYYY'
export const CURRENT_USER = 'Dr. Johnson' // In real app, this would come from auth context

// In a real app, this would come from your backend
export const STAFF_MEMBERS = [
  { id: 1, name: 'Dr. Johnson', role: 'Doctor' },
  { id: 2, name: 'Nurse Smith', role: 'Nurse' },
  { id: 3, name: 'Dr. Williams', role: 'Specialist' },
  { id: 4, name: 'Nurse Davis', role: 'Nurse' },
  { id: 5, name: 'Lab Tech Wilson', role: 'Lab Technician' }
]
