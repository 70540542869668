import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { getPatMedicalHistory } from '../Patient/Medical History/api/api'
import MedicalHistoryForm from './components/MedicalHistoryForm'
import LoadingOverlay from './components/LoadingOverlay'
import { getMedicalHistory } from './api/api'

const MedicalHistoryView = ({ patientId }) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [medicalHistory, setMedicalHistory] = useState(null)

  useEffect(() => {
    const fetchMedicalHistory = async () => {
      if (!patientId) return

      setLoading(true)
      try {
        // We'll need to modify the API to accept a patientId parameter
        const data = await getMedicalHistory(patientId)
        setMedicalHistory(data)
      } catch (error) {
        console.error('Error fetching medical history:', error)
        message.error('Failed to load patient medical history')
      } finally {
        setLoading(false)
      }
    }

    fetchMedicalHistory()
  }, [patientId])

  if (loading) {
    return <LoadingOverlay text='Loading patient medical history...' loading={loading} />
  }

  return (
    <MedicalHistoryForm
      medicalHistory={medicalHistory}
      setMedicalHistory={setMedicalHistory}
      patientId={patientId}
      saving={saving}
      setSaving={setSaving}
    />
  )
}

export default MedicalHistoryView
