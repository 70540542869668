import { faAdd, faClose, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Form, Input, message, Row, Space, Table, theme } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../core/utils/reducer'
import CoreEnums from '../../../core/utils/enums'
import MedicalAidPaymentForm from './medical-aid-payment-form'
import { handleError } from '../../lib/utils'
import { getInvoices, getRemittances } from '../utils'
import { readPatients } from '../../Admin/patients/utils/utils'
import { readBillingRecords } from '../../Bookings/utils/utils'

const RemittanceForm = ({ data, handleGetRemittances }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [remittanceData, setRemittanceData] = useState(data)

  useEffect(() => {
    handleRefresh()
    // eslint-disable-next-line
  }, [])

  const handleNewPayment = () => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.PAYMENT_REMITTANCE}_new`,
        closable: true,
        label: 'Create New Medical Aid Payment',
        children: <MedicalAidPaymentForm data={data} />
      })
    )
  }

  const handleRefresh = async () => {
    let tmpData = null

    setLoading(true)

    try {
      tmpData = await getRemittances({ _id: data._id })

      // Get Patient Records
      const patientIds = tmpData[0].payments.map((item) => item.userRef)
      const patients = await readPatients({ _id: { $in: patientIds } })

      // Get Invoice Records
      const invoiceIds = tmpData[0].payments.map((item) => item.invoiceRef)
      const invoices = await getInvoices({ _id: { $in: invoiceIds } })

      // Get Billing Records
      const billingIds = invoices.map((item) => item.bookingRef)
      const billingRecords = await readBillingRecords({ bookingRef: { $in: billingIds } })

      // Merge Patient Records
      tmpData[0].payments = tmpData[0].payments.map((item) => {
        const patientRecord = patients.find((patient) => patient._id === item.userRef)
        const invoiceRecord = invoices.find((invoice) => invoice._id === item.invoiceRef)
        const tmpBillingRecords = billingRecords.filter((record) => record.bookingRef === invoiceRecord.bookingRef)

        return {
          ...item,
          patientRecord,
          invoiceRecord,
          billingRecords: tmpBillingRecords
        }
      })

      setRemittanceData(tmpData[0])

      // Set Form Data
      form.setFieldsValue({
        ...tmpData[0],
        remittanceDate: tmpData[0].remittanceDate ? dayjs(tmpData[0].remittanceDate).format('YYYY-MM-DD') : null
      })
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleCloseTab = () => {
    handleGetRemittances()
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: `${CoreEnums.tabKeys.REMITTANCES}_${data._id}`,
        removeBreadcrumb: true
      })
    )
  }

  const { token } = theme.useToken()
  const [form] = Form.useForm()

  return (
    <Row>
      <Col span={24}>
        <Card
          type='inner'
          title={
            <h2 style={{ color: token.colorPrimary }}>
              Remittance: {remittanceData.medicalAid} - {remittanceData.receiptNumber}
            </h2>
          }
          extra={
            <Space>
              <Button type='primary' onClick={handleNewPayment}>
                <Space>
                  <FontAwesomeIcon icon={faAdd} />
                  New Medical Aid Payment
                </Space>
              </Button>
              <Button type='primary' style={{ backgroundColor: token.colorSuccess }} onClick={handleRefresh}>
                <Space>
                  <FontAwesomeIcon icon={faRefresh} />
                  Refresh
                </Space>
              </Button>
            </Space>
          }
        >
          <Form
            initialValues={{
              ...remittanceData,
              remittanceDate: remittanceData.remittanceDate
                ? dayjs(remittanceData.remittanceDate).format('YYYY-MM-DD')
                : null
            }}
            layout='vertical'
            form={form}
            disabled={loading}
          >
            <Row justify='space-between' gutter={[12, 12]}>
              <Col span={6}>
                <Form.Item name='total' label='Total'>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name='runningTotal' label='Running Total'>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name='practiceRefNo' label='Practice Reference Number'>
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name='remittanceDate' label='Remittance Date'>
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row style={{ marginTop: 10 }}>
            <Col span={24}>
              <Card
                size='small'
                loading={loading}
                title={<h2 style={{ color: token.colorSecondary }}>Remittance Payments</h2>}
              >
                <Table
                  size='small'
                  pagination={false}
                  bordered
                  columns={[
                    {
                      title: 'Account',
                      dataIndex: ['patientRecord', 'idNo'],
                      key: 'account'
                    },
                    {
                      title: 'Patient',
                      key: 'patient',
                      render: (text, record) => {
                        return `${record?.patientRecord?.firstName} ${record?.patientRecord?.lastName}`
                      }
                    },
                    {
                      title: 'Invoice Number',
                      dataIndex: ['invoiceRecord', 'invoiceNo'],
                      key: 'invoiceNo'
                    },
                    {
                      title: 'Invoice Total',
                      key: 'invoiceTotal',
                      render: (text, record) => {
                        if (record.billingRecords) {
                          return `R ${record.billingRecords
                            .reduce((acc, item) => parseFloat(acc) + parseFloat(item.total), 0)
                            .toFixed(2)}`
                        }
                      }
                    },
                    {
                      title: 'Patient Due',
                      key: 'patientDue',
                      render: (text, record) => {
                        if (record.billingRecords) {
                          return `R ${record.billingRecords
                            .reduce((acc, item) => parseFloat(acc) + parseFloat(item.patient), 0)
                            .toFixed(2)}`
                        }
                      }
                    },
                    {
                      title: 'Medical Aid Due',
                      key: 'medicalAidDue',
                      render: (text, record) => {
                        if (record.billingRecords) {
                          return `R ${record.billingRecords
                            .reduce((acc, item) => parseFloat(acc) + parseFloat(item.medicalAid), 0)
                            .toFixed(2)}`
                        }
                      }
                    },
                    {
                      title: 'Remittance Payment',
                      key: 'remittancePayment',
                      render: (text, record) => {
                        return `R ${record.paymentAmount}`
                      }
                    }
                  ]}
                  dataSource={remittanceData.payments}
                />
              </Card>
            </Col>
          </Row>
          <Row justify='center' style={{ marginTop: 10 }}>
            <Col>
              <Button danger onClick={handleCloseTab}>
                <Space>
                  <FontAwesomeIcon icon={faClose} /> Close
                </Space>
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}

export default RemittanceForm
