import React from 'react'
import { motion } from 'framer-motion'
import { createPortal } from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faCheckCircle, 
  faTimesCircle, 
  faClock, 
  faExclamationCircle 
} from '@fortawesome/free-solid-svg-icons'
import styles from './ApprovalRequest.module.css'

const ApprovalRequest = ({ approval, onApprove, onDecline }) => {
  return createPortal(
    <motion.div
      className={styles.overlay}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className={styles.approvalRequest}
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
      >
        <div className={styles.approvalHeader}>
          <h3>Approval Required</h3>
          <p>Doctor recommended procedure requires your approval</p>
        </div>

        <div className={styles.approvalDetails}>
          <div className={styles.approvalItem}>
            <div className={styles.approvalItemHeader}>
              <h4>{approval.title}</h4>
              <span className={styles.approvalItemCost}>
                R {approval.amount.toFixed(2)}
              </span>
            </div>

            <div className={styles.approvalItemDetails}>
              {approval.details && (
                <>
                  <div className={styles.approvalDescription}>
                    {approval.details.description.split('\n').map((line, index) => (
                      <p key={index}>{line}</p>
                    ))}
                  </div>

                  <div className={styles.approvalInfo}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    <p>{approval.details.recommendation}</p>
                  </div>

                  <div className={styles.approvalDuration}>
                    <FontAwesomeIcon icon={faClock} />
                    <p>{approval.details.duration}</p>
                  </div>
                </>
              )}

              <div className={styles.approvalItemDetail}>
                <span>Provider:</span>
                <span>{approval.provider}</span>
              </div>
              <div className={styles.approvalItemDetail}>
                <span>Coverage:</span>
                <span>{approval.coveredByMedicalAid ? 'Medical Aid' : 'Self Pay'}</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.approvalActions}>
          <button 
            className={`${styles.approvalButton} ${styles.approve}`}
            onClick={() => onApprove(approval.id)}
          >
            <FontAwesomeIcon icon={faCheckCircle} />
            Approve
          </button>
          <button 
            className={`${styles.approvalButton} ${styles.decline}`}
            onClick={() => onDecline(approval.id)}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
            Decline
          </button>
        </div>
      </motion.div>
    </motion.div>,
    document.body
  )
}

export default ApprovalRequest 