import React from 'react'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import BookingsListView from './bookings-list-view'

const BookingsWrapper = () => {
  return (
    <ContainerCard title='Bookings'>
      <BookingsListView />
    </ContainerCard>
  )
}

export default BookingsWrapper
