import React from 'react'
import { Tabs } from 'antd'
import CancelledPayment from './components/CancelledPayment'
import CardPayment from './components/CardPayment'
import ErrorScreen from './components/ErrorScreen'
import PaymentFailure from './components/PaymentFailure'
import PaymentMethodSelection from './components/PaymentMethodSelection'
import PaymentReceipt from './components/PaymentReceipt'
import PaymentSuccess from './components/PaymentSuccess'
import ProcessingPayment from './components/ProcessingPayment'

const PeachPaymentsWrapper = () => {
  // Mock data for components that require props
  const mockData = {
    amount: 1299.99,
    transactionDetails: {
      id: 'TX123456',
      date: '2024-03-20',
      amount: 'R 1,299.99',
      status: 'Completed',
      reference: 'REF123456'
    }
  }

  return (
    <Tabs
      defaultActiveKey='methodSelection'
      items={[
        {
          key: 'methodSelection',
          label: 'Payment Methods',
          children: <PaymentMethodSelection {...mockData} />
        },
        {
          key: 'cardPayment',
          label: 'Card Payment',
          children: <CardPayment {...mockData} />
        },

        {
          key: 'processing',
          label: 'Processing',
          children: <ProcessingPayment transactionId={mockData.transactionDetails.id} />
        },
        {
          key: 'success',
          label: 'Success',
          children: <PaymentSuccess {...mockData} />
        },
        {
          key: 'failure',
          label: 'Failure',
          children: <PaymentFailure {...mockData} />
        },
        {
          key: 'cancelled',
          label: 'Cancelled',
          children: <CancelledPayment {...mockData} />
        },

        {
          key: 'receipt',
          label: 'Receipt',
          children: <PaymentReceipt transactionDetails={mockData.transactionDetails} />
        },

        {
          key: 'error',
          label: 'Error',
          children: <ErrorScreen {...mockData} />
        }
      ]}
    />
  )
}

export default PeachPaymentsWrapper
