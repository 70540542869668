import React from 'react'
import { Space, Radio, Button, Badge, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faPrescriptionBottle, 
  faCalendarAlt,
  faPlus 
} from '@fortawesome/free-solid-svg-icons'

const ScriptingHeader = ({
  type,
  setType,
  totalDue,
  patientId,
  createNewScript,
  setModalOpen,
  token
}) => {
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative'
    }}>
      <div style={{ 
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        zIndex: 1
      }}>
        <span style={{ 
          fontSize: 20, 
          fontWeight: 500,
          color: token.colorTextHeading 
        }}>
          Prescriptions History 
        </span>
        {type === 'schedule' && totalDue > 0 && (
          <Badge 
            count={totalDue} 
            style={{ backgroundColor: token.colorWarning, marginLeft: 8 }}
          />
        )}
      </div>

      <Space size='middle' style={{ zIndex: 2, marginLeft: 'auto' }}>
        <Radio.Group
          value={type}
          onChange={(e) => setType(e.target.value)}
          buttonStyle='solid'
        >
          <Tooltip title='All Scripts'>
            <Radio.Button value=''>
              <FontAwesomeIcon icon={faPrescriptionBottle} />
              <span style={{ marginLeft: 8 }}>All</span>
            </Radio.Button>
          </Tooltip>
          <Tooltip title='Scheduled Scripts'>
            <Radio.Button value='schedule'>
              <FontAwesomeIcon icon={faCalendarAlt} />
              <span style={{ marginLeft: 8 }}>Scheduled</span>
              {totalDue > 0 && (
                <Badge 
                  count={totalDue}
                  style={{ marginLeft: 8 }}
                />
              )}
            </Radio.Button>
          </Tooltip>
        </Radio.Group>

        <Button
          type='primary'
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => patientId ? createNewScript(patientId) : setModalOpen(true)}
          style={{ 
            background: token.colorPrimary,
            borderRadius: 6
          }}
        >
          New Script
        </Button>
      </Space>
    </div>
  )
}

export default ScriptingHeader 