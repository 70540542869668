import Agilite from 'agilite'
import BookingEnums from './enums'
import dayjs from 'dayjs'
import { generateTitle } from '../../lib/utils'
import { readMedicalProfessionalUsers } from '../../Admin/medical-professionals/utils/utils'
import { readPatients } from '../../Admin/patients/utils/utils'
import axios from 'axios'
import { readClinics } from '../../Admin/clinics/utils/utils'
import { readServices } from '../../Admin/services/utils/utils'
import { readVirtualServices } from '../../Admin/virutal-services/utils/utils'
import { readSystemUsers } from '../../Admin/system-users/utils/utils'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const findOneBooking = (filter, options) => {
  return new Promise((resolve, reject) => {
    let response = null
    let queryOptions = null

    ;(async () => {
      try {
        if (options) {
          queryOptions = JSON.stringify(options)
        }

        response = await agilite.Connectors.execute(
          BookingEnums.profileKeys.BOOKINGS,
          BookingEnums.routeKeys.FIND_ONE,
          {
            filter: JSON.stringify(filter),
            page: null,
            pageLimit: null,
            options: queryOptions
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const readBookings = (filter, options, projection, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    let response = null
    let queryOptions = null

    ;(async () => {
      try {
        if (options) {
          queryOptions = JSON.stringify(options)
        }

        response = await agilite.Connectors.execute(BookingEnums.profileKeys.BOOKINGS, BookingEnums.routeKeys.READ, {
          filter: JSON.stringify(filter),
          page,
          pageLimit,
          options: queryOptions,
          projection
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const aggregateBookings = (pipeline) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          BookingEnums.profileKeys.BOOKINGS,
          BookingEnums.routeKeys.AGGREGATE,
          {
            pipeline: JSON.stringify(pipeline)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createBooking = (data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(BookingEnums.profileKeys.BOOKINGS, BookingEnums.routeKeys.CREATE, {
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateBooking = (data, filter) => {
  return new Promise((resolve, reject) => {
    let response = null
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(BookingEnums.profileKeys.BOOKINGS, BookingEnums.routeKeys.UPDATE, {
          filter: JSON.stringify(filter),
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const sendBill = (data) => {
  // TODO: THIS IS TEMPORARY
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        if (data.phoneNumber) {
          agilite.Connectors.execute('sms_portal', 'send', {
            data: JSON.stringify({
              messages: [
                {
                  content: `Dear ${data.firstName} ${
                    data.lastName
                  },\n\nYour medical aid has covered R${data.medicalAidAmount.toFixed(
                    2
                  )} of your visit. The remaining amount payable by you is R${data.patientAmount.toFixed(
                    2
                  )}.\n\nYou can securely settle your account by clicking the link below:\n\nhttps://callpay.co.za/pay/83674786278\n\nIf you have any questions or need assistance, please don't hesitate to contact us.\n\nThe Meta-Clinic Team`,
                  destination: data.phoneNumber
                }
              ]
            })
          })

          await axios.post(
            'https://graph.facebook.com/v21.0/395687273638794/messages',
            {
              messaging_product: 'whatsapp',
              to: data.phoneNumber,
              type: 'template',
              template: {
                name: 'test_billing_collections',
                language: {
                  code: 'en'
                },
                components: [
                  {
                    type: 'body',
                    parameters: [
                      { type: 'text', text: 'Mr' },
                      { type: 'text', text: `${data.firstName} ${data.lastName}` },
                      {
                        type: 'text',
                        text: `${(parseFloat(data.medicalAidAmount) + parseFloat(data.patientAmount)).toFixed(2)}`
                      }
                    ]
                  }
                ]
              }
            },
            {
              headers: {
                Authorization:
                  'Bearer EAAGNEH4qZCIoBOZCZASL0ZCwTQ8nljK3cVdBpF8sdC9pAZBZByCMaygQhi6pGEO1qZBZAAtT3PI6rr7R9ekQFzAVY1zRZANErH6fyeTUsq3lb3587cq3AoE4fZCD321YTpI9zwr6SmU61IdahYSih7hZATHJEhVPXQHEJsUTmHDllxqii3Wyal0OZAQpBrrvCqopxeEmZCr2LoUeS1eiC7Vp8KJ9Jdzi2ut7Qp0oyrbe197kZD',
                'Content-Type': 'application/json'
              }
            }
          )
        }

        resolve()
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const sendOTP = (otp, data, dependant, isDependant, clinicName) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        if (data.phoneNumber) {
          agilite.Connectors.execute('sms_portal', 'send', {
            data: JSON.stringify({
              messages: [
                {
                  content: `Hi ${data.firstName}.\nThis is your Booking Confirmation${
                    isDependant ? ` for ${dependant.firstName}` : ''
                  }.\n\nClinic: ${clinicName}\nDate: ${dayjs(data.bookingDate).format('YYYY-MM-DD')} ${
                    data.startTime
                  }-${
                    data.endTime
                  }\nOTP: ${otp}\n\nPlease arrive 15 Minutes before your booking time for screening purposes`,
                  destination: data.phoneNumber
                }
              ]
            })
          })
        }

        if (data.email) {
          agilite.Connectors.execute('sendgrid_new', 'send_dynamic', {
            apiKey: process.env.REACT_APP_SENDGRID_API_KEY,
            data: JSON.stringify({
              from: { email: 'noreply@meta-health.co.za' },
              personalizations: [
                {
                  to: [{ email: data.email }],
                  dynamic_template_data: {
                    firstName: data.firstName,
                    otp,
                    bookingDate: dayjs(data.bookingDate).format('DD MMM YYYY'),
                    startTime: data.startTime,
                    medicalProfName: `${data.medicalProf.firstName} ${data.medicalProf.lastName}`
                  }
                }
              ],
              template_id: 'd-ad0301d8918e484baa16ddaf4d57eed0'
            })
          })
        }

        resolve(otp)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getBookings = async (state, qry, userRef, queryOptions = {}) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let filter = null
      let tmpBookings = []
      let medProfIds = []
      let patientIds = []
      let tmpMedicalProfessionals = []
      let tmpPatients = []

      try {
        if (!state.auth.completeEmailSetup && state.auth.agiliteUser) {
          filter = { userRef: userRef ? userRef : state.auth.agiliteUser._id, ...qry }

          tmpBookings = await readBookings(filter, queryOptions)
          medProfIds = tmpBookings.map((booking) => booking.medicalProfRef)
          patientIds = tmpBookings.map((booking) => booking.userRef)
          tmpMedicalProfessionals = await readMedicalProfessionalUsers({
            _id: { $in: medProfIds }
          })
          tmpPatients = await readPatients({ _id: { $in: patientIds } })

          for (const booking of tmpBookings) {
            for (const medicalProf of tmpMedicalProfessionals) {
              if (booking.medicalProfRef === medicalProf._id) {
                booking.medicalProfName = medicalProf.firstName + ' ' + medicalProf.lastName
                booking.medicalProfImage = medicalProf.profileImage
              }
            }

            for (const patient of tmpPatients) {
              if (booking.userRef === patient._id) {
                booking.patientName = patient.firstName + ' ' + patient.lastName
              }
            }

            for (const clinic of state.clinics.data) {
              if (clinic._id === booking.clinicRef) {
                booking.clinicName = clinic.name
              }
            }

            for (const service of state.services.data) {
              if (service._id === booking.service) {
                booking.serviceName = service.name
                booking.serviceType = service.type
              }
            }

            for (const virtualService of state.virtualServices.data) {
              if (virtualService._id === booking.service) {
                booking.serviceName = virtualService.name
              }
            }
          }
        }

        resolve(tmpBookings)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getAppointments = async (state, qry) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      const userIds = []
      const professionalIds = []
      let filter = null
      let tmpAppointments = []
      let tmpPatients = []
      let tmpMedicalProfessionals = []
      let clinics = []
      let services = []
      let virtualServices = []

      try {
        if (!state.auth.completeEmailSetup && state.auth.agiliteUser) {
          if (
            state.auth.agiliteUser.extraData.profession === 'nurse' ||
            state.auth.agiliteUser.extraData.role.type === 'reception' ||
            state.auth.agiliteUser.extraData.role.isManager ||
            state.auth.agiliteUser.extraData.role.type === 'admin'
          ) {
            filter = { ...qry }
          } else {
            // medicalProfRef: state.auth.agiliteUser._id
            filter = { ...qry }
          }

          tmpAppointments = await readBookings(filter)
          clinics = await readClinics()
          services = await readServices()
          virtualServices = await readVirtualServices()

          for (const booking of tmpAppointments) {
            if (booking.userRef) userIds.push(booking.userRef)
            if (booking.medicalProfRef) professionalIds.push(booking.medicalProfRef)
          }

          tmpPatients = await readSystemUsers({ _id: { $in: userIds } }, null, null, null, true)
          tmpMedicalProfessionals = await readMedicalProfessionalUsers({
            _id: { $in: professionalIds }
          })

          for (const appointment of tmpAppointments) {
            for (const patient of tmpPatients) {
              if (appointment.userRef === patient._id) {
                appointment.patientName = patient.firstName + ' ' + patient.lastName
                appointment.patientRecord = patient
              }

              for (const clinic of clinics) {
                if (clinic._id === appointment.clinicRef) {
                  appointment.clinicName = clinic.name
                  appointment.clinicRecord = clinic
                }
              }
              const bookingService =
                services.find((i) => i._id === appointment.service) ||
                virtualServices.find((i) => i._id === appointment.service)
              appointment.serviceName = bookingService?.name ? bookingService?.name : 'Could Not Find Service'
              appointment.serviceType = bookingService?.type ? bookingService?.type : 'Could Not Find Service'
              appointment.serviceRecord = bookingService
            }

            for (const medicalProf of tmpMedicalProfessionals) {
              if (appointment.medicalProfRef === medicalProf._id) {
                appointment.medicalProfName = medicalProf.firstName + ' ' + medicalProf.lastName
              }
            }
          }
        }

        resolve(tmpAppointments)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const generateAgoraRtcToken = async (bookingId, userRef) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.get(`${process.env.REACT_APP_NODE_RED_URL}/api/agora/rtcToken`, {
          headers: { 'channel-name': bookingId, 'user-ref': userRef }
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getConsultationTypes = () => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Keywords.getValuesByProfileKey('consultation_types')
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const readBillingRecords = (qry) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('billing_records', 'read', { filter: JSON.stringify(qry) })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createBillingRecord = (data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('billing_records', 'create', { data: JSON.stringify(data) })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const countBillingRecords = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('billing_records', 'count', { filter: JSON.stringify(filter) })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateBillingRecord = (recordId, data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('billing_records', 'update', {
          recordId,
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const deleteBillingRecord = (recordId) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('billing_records', 'delete', { recordId })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getProductPrice = (productType, productCode, planOptionCode) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await axios.post(
          `${process.env.REACT_APP_NODE_RED_URL}/api/medprax/product_pricing`,
          {},
          {
            headers: {
              'product-type': productType,
              'product-code': productCode,
              'plan-option-code': planOptionCode
            }
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getProcedurePrice = (productCode, planOptionCode, isContract, billingProfile) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/medprax/tariff_pricing`, {
          // TODO: Discipline Code Defaulted to 014 (General Practitioner)
          disciplineCode: '014',
          isContract,
          isNetwork: billingProfile.network,
          isIpa: billingProfile.ipa,
          planOptionCode,
          tariffCode: productCode
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getPlanSubOptions = (planOptionCode) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await axios.get(`${process.env.REACT_APP_NODE_RED_URL}/api/medprax/scheme_plan_sub_options`, {
          headers: {
            'plan-option-code': planOptionCode
          }
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

const generateSystemReferenceNumber = (index) => {
  // Get current timestamp in milliseconds and convert to string
  const timestamp = Date.now().toString()

  // Take last 8 digits for invoice number
  const invoiceNumber = timestamp.slice(-8)

  // Generate 7 digit line number where last 6 must be numeric
  // Use index padded to 6 digits for uniqueness per line
  const lineNumber = `1${index.toString().padStart(6, '0')}`

  return `${invoiceNumber}/${lineNumber}`
}

const generateGoodXLineItems = (billingItems, bookingDate) => {
  return billingItems.map((item, index) => {
    let type = ''
    let id = index + 1

    switch (item.type) {
      case 'Procedure':
        type = 'ClaimLineTariffItem'
        break
      case 'Material':
      case 'Medicine':
        type = 'ClaimLineProductItem'
        break
      default:
        type = 'ClaimLineTariffItem'
        break
    }

    if (type === 'ClaimLineTariffItem') {
      return {
        $: {
          id: id
        },
        ClaimLineTariffItem: [
          {
            CommonClaimLineItemInfo: [
              {
                BillingPracticeLineItemId: [id],
                TradingPartyLineItemId: [item._id],
                SystemReferenceNumber: [generateSystemReferenceNumber(id)],
                TreatingProviderId: ['1'],
                TreatingDiagnosisId: ['1'],
                PatientId: ['1'],
                LaboratoryRequisitionNumber: [''],
                DateTimeOfTreatment: [new Date(bookingDate).toISOString()],
                EndDateTimeOfTreatment: [new Date(bookingDate).toISOString()],
                Quantity: [item.qty],
                QuantityType: ['6'],
                PlaceOfService: ['11'],
                ServiceLocation: [''],
                AuthorisationNumber: [''],
                DescriptionOfItem: [item.name],
                CommonClaimCode: [''],
                SystemItemCode: [''],
                ClaimItemFinancial: [
                  {
                    CommonClaimBaseFinancialInfo: [
                      {
                        SubmittedGrossAmount: [item.medicalAid],
                        SubmittedClaimAmount: [item.medicalAid]
                      }
                    ],
                    SubmittedBaseCost: [
                      {
                        _: item.medicalAid,
                        $: {
                          pricingTariffIndicator: ''
                        }
                      }
                    ],
                    SubmittedLineItemCollections: ['0.00']
                  }
                ]
              }
            ],
            ProcedureCode: [
              {
                _: item.code,
                $: {
                  proceduralCodingStandardIdentifier: 'NHRPL'
                }
              }
            ],
            CodeType: ['0'],
            GXUnitType: ['2']
          }
        ]
      }
    } else {
      return {
        $: {
          id: id
        },
        ClaimLineProductItem: [
          {
            CommonClaimLineItemInfo: [
              {
                BillingPracticeLineItemId: [id],
                TradingPartyLineItemId: [item._id],
                SystemReferenceNumber: [generateSystemReferenceNumber(id)],
                TreatingProviderId: ['1'],
                TreatingDiagnosisId: ['1'],
                PatientId: ['1'],
                LaboratoryRequisitionNumber: [''],
                DateTimeOfTreatment: [new Date(bookingDate).toISOString()],
                EndDateTimeOfTreatment: [new Date(bookingDate).toISOString()],
                Quantity: [item.qty],
                QuantityType: ['6'],
                PlaceOfService: [''],
                ServiceLocation: ['11'],
                AuthorisationNumber: [''],
                DescriptionOfItem: [item.name],
                CommonClaimCode: [''],
                SystemItemCode: [''],
                ClaimItemFinancial: [
                  {
                    CommonClaimBaseFinancialInfo: [
                      {
                        SubmittedGrossAmount: [item.medicalAid],
                        SubmittedClaimAmount: [item.medicalAid]
                      }
                    ],
                    SubmittedBaseCost: [
                      {
                        _: item.medicalAid,
                        $: {
                          pricingTariffIndicator: ''
                        }
                      }
                    ],
                    SubmittedLineItemCollections: ['0.00']
                  }
                ]
              }
            ],
            ProductCode: [
              {
                _: item.nappiCode,
                $: {
                  type: 'NAPPI'
                }
              }
            ],
            DailyDosage: [''],
            DaysOfTherapy: [
              {
                _: '0',
                $: {
                  basisOfDaysOfTherapy: '0'
                }
              }
            ],
            ProductType: ['2']
          }
        ]
      }
    }
  })
}

export const goodXSubmit = (data) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        const claimRequest = {
          ProviderClaimBatchEnvelope: {
            ProviderClaimMessageEnvelope: [
              {
                CommonMessageEnvelopeInfo: [
                  {
                    SystemInformation: [
                      {
                        SoftwareTitle: ['Meta-Clinic Portal'],
                        SoftwareVersion: [process.env.REACT_APP_VERSION]
                      }
                    ],
                    Payor: [
                      {
                        ClaimOption: [data.patientRecord.medicalAid.plan],
                        OptionOrPlanReference: [data.planSubOption.code],
                        Name: [data.patientRecord.medicalAid.name]
                      }
                    ]
                  }
                ],
                OriginalDocumentNumber: [data._id],
                ClaimTransactionType: ['PHISC01'],
                ProviderClaim: [
                  {
                    DispensingProviderClaim: [
                      {
                        CommonClaimHeaderInfo: [
                          {
                            ClaimNumber: [data._id],
                            ClaimTransactionType: ['PHISC01'],
                            DateTransactionCreated: [new Date().toISOString()],
                            BillingPractice: [
                              {
                                PCNSNumber: [data.clinicRecord.practiceNumber],
                                SpecialityCode: ['014'],
                                VATRegistrationNumber: [''],
                                AccountNumber: [''],
                                Name: [data.clinicRecord.name],
                                ContactInformation: [
                                  {
                                    AddressLineOne: [data.clinicRecord.physicalAddress],
                                    AddressLineTwo: [''],
                                    AddressLineThree: [''],
                                    AddressLineFour: [''],
                                    AddressLineFive: [''],
                                    TelephoneHome: [data.clinicRecord.phoneNumber],
                                    TelephoneWork: [data.clinicRecord.phoneNumber]
                                  }
                                ],
                                TreatingProvider: [
                                  {
                                    $: {
                                      id: '1'
                                    },
                                    CommonProviderInfo: [
                                      {
                                        RegistrationNumber: [
                                          {
                                            _: '',
                                            $: {
                                              typeOfBody: 'HPCSA'
                                            }
                                          }
                                        ],
                                        PCNSNumber: [data.clinicRecord.practiceNumber],
                                        SpecialityCode: ['014']
                                      }
                                    ],
                                    DispensingLicenseNumber: [''],
                                    FullName: [
                                      {
                                        Title: ['DR'],
                                        Initials: [data.medicalProf.firstName[0]],
                                        Surname: [data.medicalProf.lastName]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ],
                            Policy: [
                              {
                                PolicyNumber: [data.mainMemberRecord.medicalAid.number],
                                PolicyOwner: [
                                  {
                                    CommonPersonInfo: [
                                      {
                                        Initials: [data.mainMemberRecord.firstName[0]],
                                        Surname: [data.mainMemberRecord.lastName],
                                        IdentityNumber: [data.mainMemberRecord.idNo]
                                      }
                                    ],
                                    Title: [generateTitle(data.mainMemberRecord.gender)],
                                    ContactInformation: [
                                      {
                                        AddressLineOne: [data.mainMemberRecord.residentialAddress],
                                        AddressLineTwo: [''],
                                        AddressLineThree: [''],
                                        AddressLineFour: [''],
                                        AddressLineFive: [''],
                                        TelephoneHome: [''],
                                        TelephoneWork: [''],
                                        TelephoneMobile: [data.mainMemberRecord.phoneNumber],
                                        Email: [data.mainMemberRecord.email]
                                      }
                                    ]
                                  }
                                ],
                                Patient: [
                                  {
                                    $: {
                                      id: '1'
                                    },
                                    CommonPersonInfo: [
                                      {
                                        Initials: [data.patientRecord.firstName[0]],
                                        Surname: [data.patientRecord.lastName],
                                        IdentityNumber: [data.patientRecord.idNo]
                                      }
                                    ],
                                    FirstName: [data.patientRecord.firstName],
                                    DependantCode: [data.patientRecord.medicalAid.dependantNumber],
                                    DateOfBirth: [data.patientRecord.dateOfBirth],
                                    Gender: [data.patientRecord.gender],
                                    ContactInformation: [
                                      {
                                        AddressLineOne: [data.patientRecord.residentialAddress],
                                        AddressLineTwo: [''],
                                        AddressLineThree: [''],
                                        AddressLineFour: [''],
                                        AddressLineFive: [''],
                                        TelephoneHome: [''],
                                        TelephoneWork: [''],
                                        TelephoneMobile: [data.patientRecord.phoneNumber],
                                        Email: [data.patientRecord.email]
                                      }
                                    ]
                                  }
                                ],
                                BenefitCheckCode: [''],
                                BenefitCheckAmount: [
                                  data.billingItems
                                    .reduce((acc, item) => acc + parseFloat(item.medicalAid), 0)
                                    .toFixed(2)
                                ]
                              }
                            ],
                            Payor: [
                              {
                                ClaimOption: [data.patientRecord.medicalAid.plan],
                                OptionOrPlanReference: [data.planSubOption.code],
                                Name: [data.patientRecord.medicalAid.name]
                              }
                            ],
                            ClaimHeaderFinancial: [
                              {
                                CommonClaimBaseFinancialInfo: [
                                  {
                                    SubmittedGrossAmount: [
                                      data.billingItems
                                        .reduce((acc, item) => acc + parseFloat(item.medicalAid), 0)
                                        .toFixed(2)
                                    ],
                                    SubmittedClaimAmount: [
                                      data.billingItems
                                        .reduce((acc, item) => acc + parseFloat(item.medicalAid), 0)
                                        .toFixed(2)
                                    ]
                                  }
                                ],
                                SubmittedClinicalEncounterCollections: ['0.00']
                              }
                            ],
                            Diagnosis:
                              data.billingItems.length > 0
                                ? data.billingItems.map((item, index) => {
                                    return {
                                      $: {
                                        id: index + 1
                                      },
                                      DiagnosisCode: [
                                        {
                                          _: item.icd10.length > 0 ? item.icd10[0] : 'Z76.9',
                                          $: {
                                            sequence: index + 1
                                          }
                                        }
                                      ]
                                    }
                                  })
                                : [
                                    {
                                      $: {
                                        id: 1
                                      },
                                      DiagnosisCode: [
                                        {
                                          _: 'Z76.9',
                                          $: {
                                            sequence: 1
                                          }
                                        }
                                      ]
                                    }
                                  ]
                          }
                        ],
                        ClaimLineItem: generateGoodXLineItems(data.billingItems, data.bookingDate)
                      }
                    ]
                  }
                ]
              }
            ],
            ClaimBatchTrackingInformation: [
              {
                CommonBatchTrackingInformation: [
                  {
                    TrackingInformation: [
                      {
                        SendingTradingPartyBatchNumber: ['']
                      }
                    ]
                  }
                ]
              }
            ]
          }
        }

        response = await axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/goodx/createTicket`, claimRequest, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const goodXStatus = (ticketNo) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await axios.get(`${process.env.REACT_APP_NODE_RED_URL}/api/goodx/status`, {
          headers: {
            'ticket-no': ticketNo
          }
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const goodXResponse = (ticketNo) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await axios.get(`${process.env.REACT_APP_NODE_RED_URL}/api/goodx/response`, {
          headers: {
            'ticket-no': ticketNo
          }
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const goodXBenefitCheck = (bookingData, patient, clinic, amount, planSubOption) => {
  return new Promise((resolve, reject) => {
    const claimRequest = {
      ProviderClaimBatchEnvelope: {
        ProviderClaimMessageEnvelope: [
          {
            CommonMessageEnvelopeInfo: [
              {
                SystemInformation: [
                  {
                    SoftwareTitle: ['Meta-Clinic Portal'],
                    SoftwareVersion: [process.env.REACT_APP_VERSION]
                  }
                ],
                Payor: [
                  {
                    ClaimOption: [patient.medicalAid.planOption],
                    OptionOrPlanReference: [planSubOption.code],
                    Name: [patient.medicalAid.name]
                  }
                ]
              }
            ],
            OriginalDocumentNumber: [patient._id],
            ClaimTransactionType: ['GDX02'],
            MemberValidation: [
              {
                CommonClaimHeaderInfo: [
                  {
                    ClaimNumber: [bookingData._id],
                    ClaimTransactionType: ['GDX02'],
                    DateTransactionCreated: [new Date().toISOString()],
                    BillingPractice: [
                      {
                        PCNSNumber: [clinic.practiceNumber],
                        SpecialityCode: ['014'],
                        VATRegistrationNumber: [''],
                        AccountNumber: [''],
                        Name: [clinic.name],
                        ContactInformation: [
                          {
                            AddressLineOne: [clinic.physicalAddress],
                            AddressLineTwo: [''],
                            AddressLineThree: [''],
                            AddressLineFour: [''],
                            AddressLineFive: [''],
                            TelephoneHome: [clinic.phoneNumber],
                            TelephoneWork: [clinic.phoneNumber]
                          }
                        ]
                      }
                    ],
                    Policy: [
                      {
                        PolicyNumber: [patient.medicalAid.number],
                        BenefitCheckCode: [''],
                        BenefitCheckAmount: [amount.toFixed(2)]
                      }
                    ],
                    Payor: [
                      {
                        ClaimOption: [patient.medicalAid.planOption],
                        OptionOrPlanReference: [planSubOption.code],
                        Name: [patient.medicalAid.name]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ],
        ClaimBatchTrackingInformation: [
          {
            CommonBatchTrackingInformation: [
              {
                TrackingInformation: [
                  {
                    SendingTradingPartyBatchNumber: ['']
                  }
                ]
              }
            ]
          }
        ]
      }
    }

    ;(async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_NODE_RED_URL}/api/goodx/createTicket`,
          claimRequest,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const testAggregateBookings = async (pipeline) => {
  try {
    // Make the actual request
    const response = await fetch('/api/bookings/aggregate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ pipeline })
    })

    const data = await response.json()

    return data
  } catch (error) {
    console.error('Error in test aggregate:', error)
    throw error
  }
}
