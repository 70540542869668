import { Button, Popconfirm, Space, theme } from 'antd'
import React, { useState, useRef } from 'react'

const VoiceRecorder = ({ handleOnStop, setRecordingTracker, handlePause, handleResume }) => {
  const [recording, setRecording] = useState(false)
  const [paused, setPaused] = useState(false)
  const mediaRecorderRef = useRef(null)
  const audioChunksRef = useRef([])

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      mediaRecorderRef.current = new MediaRecorder(stream)

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data)
      }

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' })
        handleOnStop(audioBlob)

        audioChunksRef.current = []
      }

      mediaRecorderRef.current.start()
      setRecording(true)
      setRecordingTracker(true)
    } catch (error) {
      console.error('Error accessing media devices.', error)
    }
  }

  const stopRecording = () => {
    mediaRecorderRef.current.stop()
    setRecording(false)
    setRecordingTracker(false)
    setPaused(false)
  }

  const pauseRecording = () => {
    if (mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.pause()
      handlePause()
      setPaused(true)
    }
  }

  const resumeRecording = () => {
    if (mediaRecorderRef.current.state === 'paused') {
      mediaRecorderRef.current.resume()
      handleResume()
      setPaused(false)
    }
  }

  const { token } = theme.useToken()
  return (
    <Space wrap direction='vertical'>
      <Button
        style={{ width: 250, backgound: token.colorSuccess }}
        type='primary'
        onClick={startRecording}
        disabled={recording}
      >
        Start Recording
      </Button>
      <Popconfirm
        title='Are you sure you want to stop the recording? This will finalize the recording and generate the notes.'
        onConfirm={stopRecording}
        okText='Yes'
        cancelText='No'
      >
        <Button
          type='primary'
          style={{ width: 250, background: !recording ? '' : token.colorError }}
          disabled={!recording}
        >
          Stop Recording
        </Button>
      </Popconfirm>
      <Button
        type='primary'
        style={{ width: 250, background: !recording || paused ? '' : token.colorWarning }}
        onClick={pauseRecording}
        disabled={!recording || paused}
      >
        Pause Recording
      </Button>
      <Button
        type='primary'
        style={{ width: 250, background: !paused ? '' : token.colorPrimary }}
        onClick={resumeRecording}
        disabled={!paused}
      >
        Resume Recording
      </Button>
    </Space>
  )
}

export default VoiceRecorder
