import React from 'react'
import { isMobile } from 'react-device-detect'
import MedicalHistory from './medical-history'
import MobMedicalHistoryWrapper from '../../../Patient/Medical History/Mobile/components/MobMedicalHistoryWrapper'

const MedicalHistoryWrapper = ({ isPatient }) => {
  return isMobile ? <MobMedicalHistoryWrapper isPatient={isPatient} /> : <MedicalHistory isPatient={isPatient} />
}

export default MedicalHistoryWrapper
