import { createSlice } from '@reduxjs/toolkit'
import state from './state'

const todaysPatientsReducer = createSlice({
  name: 'todaysPatientsReducer',
  initialState: state,
  reducers: {
    setRecords: (state, action) => {
      state.data = action.payload
    }
  }
})

export default todaysPatientsReducer
