import React from 'react'
import { Col, Row, Space } from 'antd'
import { faCamera, faImage } from '@fortawesome/free-solid-svg-icons'
import '../style/MobileCameraCapture.css'
import useMobileCameraCapture from '../hooks/useMobileCameraCapture'
import PhotoTips from './PhotoTips'
import MobTransparentButton from '../../../../../Reusable Components/Buttons/MobTransparentButton'
import PrevNextFloatingButtons from '../../../../../Reusable Components/Buttons/PrevNextFloatingButtons'

const MobileCameraCapture = ({ onAfterCapture, onBack }) => {
  const { handleFileChange, fileInputRef, handleCameraClick } = useMobileCameraCapture({ onAfterCapture })

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <PhotoTips />
        </Col>
        <Col span={24}>
          <Space style={{ width: '100%' }} direction='vertical'>
            <MobTransparentButton functionCall={handleCameraClick} icon={faCamera} text={<>Camera</>} />
            <MobTransparentButton
              icon={faImage}
              functionCall={() => document.getElementById('gallery-upload').click()}
              text={<>Gallery</>}
            />
          </Space>
        </Col>
      </Row>
      <input
        type='file'
        accept='image/*'
        capture='environment'
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />

      <input type='file' accept='image/*' onChange={handleFileChange} id='gallery-upload' style={{ display: 'none' }} />

      <PrevNextFloatingButtons onPrev={onBack} prevOnly={true} prevText='Back' />
    </>
  )
}

export default MobileCameraCapture
