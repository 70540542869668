import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPatMedicalHistory } from '../../Medical History/api/api'
import patMedHistReducer from '../../Medical History/utils/reducer'
import { handleError } from '../../../lib/utils'
import { message } from 'antd'

const usePatChronicMeds = () => {
  const dispatch = useDispatch()
  const [loadingChronicMedications, setLoadingChronicMedications] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const listRef = useRef(null)
  const patMedHistState = useSelector((state) => state.patMedHist.data)
  const [chronicMeds, setChronicMeds] = useState([])

  useEffect(() => {
    const tmpChronicMeds = []

    if (!patMedHistState?.chronicIllnesses) {
      fetchPatMedHist()
      return
    }

    patMedHistState.chronicIllnesses.list.forEach((illness) =>
      illness.medications.forEach((medicationEntry) => tmpChronicMeds.push(medicationEntry))
    )

    setChronicMeds(tmpChronicMeds)
    // eslint-disable-next-line
  }, [patMedHistState])

  const handleScroll = (e) => {
    const container = e.target
    const itemWidth = container.children[0].offsetWidth + 16 // width + gap
    const scrollPosition = container.scrollLeft
    const newIndex = Math.round(scrollPosition / itemWidth)
    if (newIndex !== activeIndex) {
      setActiveIndex(newIndex)
    }
  }
  const fetchPatMedHist = async () => {
    setLoadingChronicMedications(true)
    try {
      const data = await getPatMedicalHistory()
      dispatch(patMedHistReducer.actions.setState(data))
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setLoadingChronicMedications(false)
    }
  }
  return {
    activeIndex,
    chronicMeds,
    listRef,
    handleScroll,
    loadingChronicMedications
  }
}

export default usePatChronicMeds
