import React, { useEffect } from 'react'
import { Button, Card, Col, Row, theme } from 'antd'
import { getPrevTab } from '../../core/utils/utils'
import { BrowserView, MobileView, deviceDetect } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

export const ContainerCard = (props) => {
  const { token } = theme.useToken()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Card
      id={props?.id}
      styles={{
        body: {
          padding: '0 0',
          background: deviceDetect().isMobile ? '' : 'transparent',
          marginTop: 1,
          paddingTop: deviceDetect().isMobile ? 8 : 8,
          ...props.bodyStyle
        },
        header: {
          backgroundColor: token.colorBgContainer,
          borderRadius: 0,
          padding: 0,
          minHeight: 36,
          display: deviceDetect().isMobile || props.hideTitle ? 'none' : '',
          ...props.headStyle
        }
      }}
      style={{ borderRadius: 0, border: 'none', background: 'transparent', ...props.style }}
      title={
        props.backFuncton ? (
          <Row style={{ alignItems: 'center' }}>
            {props.customBackComponent ? (
              props.customBackComponent
            ) : (
              <Col span={24}>
                {props.prevDisabled ? undefined : props.backFunction ? (
                  <Button
                    onClick={() => {
                      props.backFunction()
                    }}
                    type='primary'
                    style={{
                      marginLeft: 4,
                      cursor: 'pointer',
                      float: 'left',
                      color: 'white'
                    }}
                  >
                    <MobileView>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </MobileView>
                    <BrowserView>
                      <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: 10 }} />
                      Back
                    </BrowserView>
                  </Button>
                ) : (
                  getPrevTab(token)
                )}
              </Col>
            )}
            {/* <Col span={12}>
              <center>
                <h2>{props.title}</h2>
              </center>
            </Col>
            <Col span={6}></Col> */}
          </Row>
        ) : (
          ''
        )
      }
      loading={props.loading}
    >
      {props.children}
    </Card>
  )
}

export const PrimaryCard = (props) => {
  const { token } = theme.useToken()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Card
      title={<span style={{ fontSize: '18px' }}>{props.title}</span>}
      style={{ width: '100%', overflow: 'hidden', ...props.style }}
      styles={{
        header: { backgroundColor: token.colorSecondaryLight, fontSize: 18 },
        body: { padding: 4, ...props.bodyStyle }
      }}
      type='inner'
      size='small'
      extra={props.extra}
      loading={props.loading}
    >
      {props.children}
    </Card>
  )
}
