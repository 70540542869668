import React from 'react'
import { Card, Empty, Input, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import '../style/MobileHealthyAlternatives.css'
import useMobileHealthAlternatives from '../hooks/useMobileHealthAlternatives'
import HealthAlternativeItem from './HealthAlternativeItem'
import { useSelector } from 'react-redux'

const MobileHealthyAlternatives = ({ userRef, onBack }) => {
  const recipes = useSelector((state) => state.patMacroTracker.healthyAlternatives)
  const { currentRecipe, setCurrentRecipe, searchQuery, loading, setSearchQuery } = useMobileHealthAlternatives({
    userRef
  })

  const renderRecipesList = () => (
    <>
      <div className='list-header'>
        <Input
          placeholder='Search recipes...'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className='search-input'
          suffix={<FontAwesomeIcon icon={faSearch} />}
          allowClear
        />
      </div>

      {loading ? (
        <AgiliteSkeleton skActive spinnerTip='Loading Recipes...' />
      ) : recipes.length === 0 ? (
        <Empty description='No recipes found' />
      ) : (
        <div className='recipes-container'>
          <div className='recipes-list'>
            {recipes
              .filter(
                (recipe) =>
                  recipe.recipeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  recipe.recipeDescription?.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((recipe) => (
                <Card key={recipe._id} className='recipe-card' onClick={() => setCurrentRecipe(recipe)}>
                  <h3>{recipe.recipeName}</h3>
                  <p>{recipe.recipeDescription}</p>
                  <span className='recipe-date'>
                    Saved on{' '}
                    {new Date().toLocaleDateString('en-GB', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric'
                    })}
                  </span>
                </Card>
              ))}
          </div>
        </div>
      )}

      <div className='bottom-button-container'>
        <Button className='bottom-back-button' onClick={onBack}>
          Back
        </Button>
      </div>
    </>
  )

  return (
    <div className='mobile-healthy-alternatives'>
      {currentRecipe ? (
        <HealthAlternativeItem healthyAlternative={currentRecipe} handleBack={onBack} />
      ) : (
        renderRecipesList()
      )}
    </div>
  )
}

export default MobileHealthyAlternatives
