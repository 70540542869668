import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Progress, Space, theme, Button } from 'antd'
import React from 'react'
import VoiceRecorder from '../../../../reusable-components/VoiceRecorder'
import { faEarListen } from '@fortawesome/free-solid-svg-icons'
import { MAX_RECORDING_TIME } from '../utils/constants'
import ModalHeader from '../../../../reusable-components/ModalHeader'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import useTranscribeAi from '../hooks/useTranscribeAi'

const TranscribeAi = ({ setNoteState, modalOpen, setModalOpen }) => {
  const { token } = theme.useToken()

  const { transcriptionState, handleReset, handleAiTranscription, handleRetry, setTranscriptionState } =
    useTranscribeAi({
      setNoteState,
      modalOpen,
      setModalOpen
    })
  return (
    <Modal
      closable={false}
      maskClosable={false}
      destroyOnClose
      footer={false}
      open={modalOpen}
      bodyStyle={{ padding: 0 }}
    >
      <ModalHeader handleReset={handleReset} title='Transcribe AI' />
      {!transcriptionState.error && !transcriptionState.isProcessing && (
        <>
          <Space wrap>
            <Progress
              type='circle'
              percent={(transcriptionState.recordingTime / MAX_RECORDING_TIME) * 100}
              size={50}
              style={{
                background: token.colorPrimary,
                borderRadius: '50%',
                border: '1px solid #ffffff'
              }}
              format={(percent) => (
                <span style={{ color: '#ffffff' }}>
                  {`${Math.floor((MAX_RECORDING_TIME - transcriptionState.recordingTime) / 60000)}:${Math.floor(
                    ((MAX_RECORDING_TIME - transcriptionState.recordingTime) % 60000) / 1000
                  )
                    .toString()
                    .padStart(2, '0')}`}
                </span>
              )}
            />
            <FontAwesomeIcon
              className={transcriptionState.isRecording ? 'pulse' : ''}
              fontSize={50}
              icon={faEarListen}
              color={token.colorPrimary}
            />
          </Space>
          <div
            style={{
              marginTop: 24,
              marginBottom: 24,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              borderRadius: 12
            }}
          >
            <VoiceRecorder
              handlePause={() => setTranscriptionState((prev) => ({ ...prev, isRecording: false }))}
              handleResume={() => setTranscriptionState((prev) => ({ ...prev, isRecording: true }))}
              setRecordingTracker={(value) => setTranscriptionState((prev) => ({ ...prev, isRecording: value }))}
              handleOnStop={(file) => {
                setTranscriptionState((prev) => ({ ...prev, file }))
                handleAiTranscription(file)
              }}
            />
          </div>
        </>
      )}

      {transcriptionState.isProcessing && (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <AgiliteSkeleton skActive spinnerTip='Busy Processing...' />
        </div>
      )}
      {transcriptionState.error && (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button type='primary' onClick={handleRetry}>
            Retry Submission
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default TranscribeAi
