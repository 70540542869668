import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useWorkoutProgress } from './hooks/useWorkoutProgress'
import { WorkoutCard } from './WorkoutCard'
import { CompletionModal } from './CompletionModal'
import { ExerciseModal } from './ExerciseModal'
import { WorkoutHeader } from './WorkoutHeader'
import { exerciseDetails } from './config/exerciseDetails'
import './WorkoutPlanTabWrapper.css'

const successSound = new Audio('/assets/sounds/success.mp3')

const DUMMY_WORKOUT_PLAN = {
  id: 'plan_1',
  pointsPerWorkout: 50,
  workoutDays: {
    monday: {
      workouts: [
        {
          id: 'workout_1',
          name: 'Morning Strength',
          type: 'strength',
          difficulty: 'intermediate',
          verified: true,
          pointsPerWorkout: 50,
          duration: 45,
          calories: 300,
          completed: false,
          exercises: [
            {
              id: 'ex_1',
              name: 'Push-ups',
              sets: 3,
              reps: 12
            },
            {
              id: 'ex_2',
              name: 'Squats',
              sets: 4,
              reps: 15
            }
          ]
        }
      ]
    },
    wednesday: {
      workouts: [
        {
          id: 'workout_2',
          name: 'Cardio Blast',
          type: 'cardio',
          difficulty: 'beginner',
          verified: true,
          pointsPerWorkout: 50,
          duration: 30,
          calories: 250,
          completed: false,
          exercises: [
            {
              id: 'ex_3',
              name: 'Running',
              duration: '20 min'
            },
            {
              id: 'ex_4',
              name: 'Jumping Jacks',
              duration: '10 min'
            }
          ]
        }
      ]
    },
    friday: {
      workouts: [
        {
          id: 'workout_3',
          name: 'Pool Workout',
          type: 'swimming',
          difficulty: 'advanced',
          verified: false,
          pointsPerWorkout: 50,
          duration: 60,
          calories: 400,
          completed: false,
          exercises: [
            {
              id: 'ex_5',
              name: 'Freestyle Laps',
              duration: '30 min'
            },
            {
              id: 'ex_6',
              name: 'Water Treading',
              duration: '15 min'
            }
          ]
        }
      ]
    }
  }
}

export const WorkoutPlanTabWrapper = ({ onUpdateProgress, onShowLeaderboard }) => {
  const authState = useSelector((state) => state.auth)
  const { streak, lastWorkout, saveWorkoutCompletion, isExerciseCompleted, exerciseProgress } = useWorkoutProgress(
    authState.agiliteUser._id
  )

  const [activeDay, setActiveDay] = useState(null)
  const [selectedExercise, setSelectedExercise] = useState(null)
  const [showExerciseModal, setShowExerciseModal] = useState(false)
  const [completing, setCompleting] = useState(false)
  const [earnedPoints, setEarnedPoints] = useState(0)
  const [totalPoints, setTotalPoints] = useState(0)
  const [animatePoints, setAnimatePoints] = useState(false)
  const [showCompletionModal, setShowCompletionModal] = useState(false)

  const plan = DUMMY_WORKOUT_PLAN

  useEffect(() => {
    setTotalPoints(authState.agiliteUser.points || 0)
  }, [authState.agiliteUser])

  useEffect(() => {
    if (animatePoints) {
      const timer = setTimeout(() => setAnimatePoints(false), 500)
      return () => clearTimeout(timer)
    }
  }, [animatePoints])

  const handleExerciseClick = (exercise, workoutId) => {
    setSelectedExercise({
      ...exercise,
      id: exercise.id || `${workoutId}_${exercise.name.replace(/\s+/g, '_')}`,
      workoutId,
      completed: isExerciseCompleted(exercise.id),
      details: exerciseDetails[exercise.name]
    })
    setShowExerciseModal(true)
  }

  const handleExerciseComplete = async (exercise) => {
    try {
      setCompleting(true)
      const completionData = {
        userId: authState.agiliteUser._id,
        exerciseId: exercise.id,
        workoutId: exercise.workoutId,
        timestamp: new Date().toISOString(),
        type: 'single_exercise'
      }

      const saved = await saveWorkoutCompletion(completionData)
      if (saved) {
        successSound.play().catch(() => {})
        setSelectedExercise((prev) => ({
          ...prev,
          completed: true
        }))
        setShowExerciseModal(false)

        const exercisePoints = 10
        setEarnedPoints(exercisePoints)
        setTimeout(() => {
          setTotalPoints((prev) => prev + exercisePoints)
          setAnimatePoints(true)
          setShowCompletionModal(true)
        }, 500)

        // Check if all exercises in workout are completed
        const workout = Object.values(plan.workoutDays)
          .flatMap((day) => day.workouts)
          .find((w) => w.id === exercise.workoutId)

        if (workout && workout.exercises.every((ex) => isExerciseCompleted(ex.id))) {
          const workoutPoints = plan.pointsPerWorkout || 50
          setTimeout(() => {
            setEarnedPoints(workoutPoints)
            setTotalPoints((prev) => prev + workoutPoints)
            setAnimatePoints(true)
            setShowCompletionModal(true)
          }, 2000)
        }
      }
    } catch (error) {
      console.error('Error completing exercise:', error)
    } finally {
      setCompleting(false)
    }
  }

  return (
    <div className='workout-plan-tab'>
      <WorkoutHeader
        streak={streak}
        totalPoints={totalPoints}
        animatePoints={animatePoints}
        onShowLeaderboard={() => onShowLeaderboard(plan.id)}
      />

      <div className='workouts-container'>
        {Object.entries(plan.workoutDays).map(([day, { workouts }]) => (
          <WorkoutCard
            key={day}
            day={day}
            workouts={workouts}
            isActive={activeDay === day}
            onDayChange={setActiveDay}
            onExerciseClick={handleExerciseClick}
            isExerciseCompleted={isExerciseCompleted}
          />
        ))}
      </div>

      <ExerciseModal
        exercise={selectedExercise}
        visible={showExerciseModal}
        onClose={() => setShowExerciseModal(false)}
        onComplete={handleExerciseComplete}
        completing={completing}
      />

      <CompletionModal
        visible={showCompletionModal}
        earnedPoints={earnedPoints}
        onClose={() => setShowCompletionModal(false)}
      />
    </div>
  )
}
