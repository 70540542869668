// src/components/DeviceInfo.js
import React, { useContext, useEffect, useState } from 'react'
import BluetoothContext from '../BluetoothContext'
import { Spin, Button } from 'antd'
import { isAndroid } from 'react-device-detect'

const DeviceInfo = () => {
  const { bleDevice, connectionStatus } = useContext(BluetoothContext)
  const [deviceInfo, setDeviceInfo] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [retryCount, setRetryCount] = useState(0)

  useEffect(() => {
    // Only attempt to get device info when connected
    if (connectionStatus === 'connected' && bleDevice) {
      getDeviceInfo()
    }
  }, [connectionStatus, bleDevice])

  const getDeviceInfo = async () => {
    if (!bleDevice) {
      console.warn('No device connected')
      return
    }

    setIsLoading(true)

    try {
      console.log('Fetching Device Info...')

      // For Android, we might need to handle connection differently
      let server
      if (isAndroid) {
        // On Android, check if already connected first
        if (bleDevice.gatt.connected) {
          server = bleDevice.gatt
        } else {
          server = await bleDevice.gatt.connect()
        }
      } else {
        server = await bleDevice.gatt.connect()
      }

      // Add a small delay for Android to ensure connection is stable
      if (isAndroid) {
        await new Promise((resolve) => setTimeout(resolve, 500))
      }

      const service = await server.getPrimaryService('0000180a-0000-1000-8000-00805f9b34fb')
      console.log('Device Info Service obtained:', service)

      const characteristics = await service.getCharacteristics()
      console.log('Device Info Characteristics obtained:', characteristics)

      const info = {}

      for (const characteristic of characteristics) {
        try {
          const value = await characteristic.readValue()
          const decoder = new TextDecoder('utf-8')
          const data = decoder.decode(value)

          console.log('Characteristic UUID:', characteristic.uuid, 'Data:', data)

          switch (characteristic.uuid) {
            case '00002a26-0000-1000-8000-00805f9b34fb':
              info.firmwareRevision = data
              break
            case '00002a27-0000-1000-8000-00805f9b34fb':
              info.hardwareRevision = data
              break
            case '00002a28-0000-1000-8000-00805f9b34fb':
              info.softwareRevision = data
              break
            default:
              break
          }
        } catch (charError) {
          console.warn(`Error reading characteristic ${characteristic.uuid}:`, charError)
        }
      }

      setDeviceInfo(info)
      setIsLoading(false)
    } catch (error) {
      console.error('Device Info Error:', error)
      setIsLoading(false)

      // For Android, retry a few times if we get an error
      if (isAndroid && retryCount < 3) {
        console.log(`Retrying device info (attempt ${retryCount + 1})...`)
        setRetryCount((prev) => prev + 1)
        setTimeout(getDeviceInfo, 1000)
      }
    }
  }

  return (
    <div>
      <h3>Device Information</h3>
      {isLoading ? (
        <p>
          Loading device info... <Spin size='small' />
        </p>
      ) : (
        <div>
          <p>Firmware Revision: {deviceInfo.firmwareRevision || '--'}</p>
          <p>Hardware Revision: {deviceInfo.hardwareRevision || '--'}</p>
          <p>Software Revision: {deviceInfo.softwareRevision || '--'}</p>

          {isAndroid && Object.keys(deviceInfo).length === 0 && (
            <Button size='small' onClick={getDeviceInfo}>
              Refresh Device Info
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default DeviceInfo
