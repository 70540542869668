import { useCallback, useEffect, useMemo, useState } from 'react'
import { handleError } from '../../../../lib/utils'
import { readDocuments, deleteDocument } from '../utils/api'
import { message, Modal } from 'antd'
import { MODAL_STATES } from '../utils/constants'
import { useSelector } from 'react-redux'

export const useDocuments = ({ userRef }) => {
  // Document state
  const [documents, setDocuments] = useState([])
  const [loadingDocuments, setLoadingDocuments] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)

  // Get current user information from Redux store
  const currentUser = useSelector((state) => state.auth.agiliteUser)

  // Filter state
  const [searchQuery, setSearchQuery] = useState('')
  const [dateRange, setDateRange] = useState([])
  const [selectionRequired, setSelectionRequired] = useState(false)

  // Initialize modal state with frozen object for immutability
  const initialModalState = useMemo(
    () => Object.freeze(Object.keys(MODAL_STATES).reduce((acc, key) => ({ ...acc, [MODAL_STATES[key]]: false }), {})),
    []
  )
  const [modalState, setModalState] = useState(initialModalState)

  const handleReadDocuments = useCallback(async () => {
    setLoadingDocuments(true)
    try {
      const data = await readDocuments({ userRef })

      // Process documents to add medical professional information
      const processedDocuments = data.map((doc) => {
        // If the document was created by the current user, add their information
        if (doc.medicalProfRef === currentUser?._id) {
          return {
            ...doc,
            medicalProfName: `${currentUser.firstName} ${currentUser.lastName}`.trim(),
            medicalProfProfession: currentUser.extraData?.profession || 'doctor' // Default to doctor if not specified
          }
        }
        return doc
      })

      setDocuments(processedDocuments)
    } catch (error) {
      message.error(handleError(error))
    } finally {
      setLoadingDocuments(false)
    }
  }, [userRef, currentUser])

  // Handle document deletion
  const handleDeleteDocument = useCallback(
    async (documentId) => {
      Modal.confirm({
        title: 'Delete Document',
        content: 'Are you sure you want to delete this document? This action cannot be undone.',
        okText: 'Yes, Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: async () => {
          try {
            await deleteDocument(documentId)
            message.success('Document deleted successfully')
            // Refresh the documents list
            handleReadDocuments()
          } catch (error) {
            message.error(handleError(error))
          }
        }
      })
    },
    [handleReadDocuments]
  )

  // Fetch documents on mount
  useEffect(() => {
    handleReadDocuments()
  }, [handleReadDocuments])

  // Modal state management
  const toggleModalState = useCallback((modalKey) => {
    setModalState((prevState) =>
      Object.freeze({
        ...prevState,
        [modalKey]: !prevState[modalKey]
      })
    )
  }, [])

  const handleModalReset = useCallback(() => {
    setModalState(initialModalState)
  }, [initialModalState])

  // Filter documents based on search query and date range
  const filteredDocuments = useMemo(() => {
    if (!documents.length) return []

    return documents.filter((doc) => {
      if (!doc) return false

      const searchTerm = searchQuery.toLowerCase()
      const matchesName = !searchQuery || (doc.name && doc.name.toLowerCase().includes(searchTerm))

      const switchOnDocType = (type) => {
        switch (type) {
          case 'referral':
            return 'Referral'
          case 'sickNote':
            return 'Sick Note'
          case 'labReport':
            return 'Lab Report'
        }
      }
      const matchesType =
        !searchQuery || (switchOnDocType(doc.type) && switchOnDocType(doc.type).toLowerCase().includes(searchTerm))

      const matchesDateRange =
        !dateRange.length ||
        dateRange.length !== 2 ||
        (doc.audit?.createdAt && doc.audit.createdAt >= dateRange[0] && doc.audit.createdAt <= dateRange[1])

      return (matchesName || matchesType) && matchesDateRange
    })
  }, [documents, searchQuery, dateRange])

  return {
    setDocuments,
    documents: filteredDocuments,
    handleReadDocuments,
    handleDeleteDocument,
    loadingDocuments,
    selectedDocument,
    setSelectedDocument,
    handleModalReset,
    toggleModalState,
    modalState,
    searchQuery,
    setSearchQuery,
    dateRange,
    setDateRange,
    selectionRequired,
    setSelectionRequired
  }
}
