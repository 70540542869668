import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, DatePicker, Form, Row, Space, Switch } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import PropTypes from 'prop-types'
import { useSickNote } from './hooks/useSickNote'
import React from 'react'
import dayjs from 'dayjs'

const SickNoteWrapper = ({ setModalOpen, bookingData, userRef, patient, handleAfterSuccess }) => {
  const {
    loading,
    familyResponsibility,
    sickLeaveForm,
    handleGenerateSickLeaveTemplate,
    handleFamilyResponsibilityChange,
    formValues,
    handleFieldChange
  } = useSickNote({ setModalOpen, bookingData, patient, handleAfterSuccess })

  // Set default values for the form
  React.useEffect(() => {
    sickLeaveForm.setFieldsValue({
      name: `Sick Note (${dayjs().format('DD-MM-YYYY')})`,
      startDate: dayjs(),
      endDate: dayjs().add(3, 'day'),
      familyResponsibility: false
    })
  }, [sickLeaveForm])

  return (
    <Card style={{ width: '100%', margin: '0 auto' }}>
      <Form
        onFinish={handleGenerateSickLeaveTemplate}
        form={sickLeaveForm}
        layout='vertical'
        className='sick-note-form'
        onFieldsChange={handleFieldChange}
        style={{ width: '100%' }}
      >
        <h2
          style={{ marginBottom: '24px', fontSize: '1.5rem', borderBottom: '1px solid #f0f0f0', paddingBottom: '12px' }}
        >
          Create Sick Note
        </h2>

        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
          {/* Hidden name field with default value */}
          <Form.Item name='name' hidden>
            <TextArea />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='startDate'
                label='Start Date'
                rules={[{ required: true, message: 'Please select a start date' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format='DD MMMM YYYY'
                  placeholder='Select Start Date'
                  className='date-picker'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='endDate'
                label='End Date'
                rules={[{ required: true, message: 'Please select an end date' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format='DD MMMM YYYY'
                  placeholder='Select End Date'
                  className='date-picker'
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label='Family Responsibility Leave' name='familyResponsibility' valuePropName='checked'>
            <Switch
              checkedChildren='Yes'
              unCheckedChildren='No'
              onChange={handleFamilyResponsibilityChange}
              value={familyResponsibility}
            />
          </Form.Item>

          {familyResponsibility && (
            <Form.Item label='Comments' name='comments' help={`Maximum ${80} characters`}>
              <TextArea placeholder='Please provide additional details...' maxLength={80} rows={4} showCount />
            </Form.Item>
          )}
        </div>

        <Row justify='end' gutter={16} style={{ marginTop: 32, borderTop: '1px solid #f0f0f0', paddingTop: '20px' }}>
          <Col>
            <Space size='middle'>
              <Button onClick={() => setModalOpen(false)}>Cancel</Button>
              <Button
                type='primary'
                onClick={() => sickLeaveForm.submit()}
                loading={loading}
                icon={<FontAwesomeIcon icon={faCheck} />}
              >
                Generate Sick Note
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

SickNoteWrapper.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  bookingData: PropTypes.object.isRequired,
  userRef: PropTypes.string,
  patient: PropTypes.object.isRequired,
  handleAfterSuccess: PropTypes.func.isRequired
}

export default React.memo(SickNoteWrapper)
