import React from 'react'
import { Form, Button, Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faArrowLeft, faArrowRight, faHeartPulse } from '@fortawesome/free-solid-svg-icons'
import Templates from '../../../../Medical Vault/medical-history/utils/templates'
import '../style/MobFamilyHistory.css'
import useFamilyHistory from '../../hooks/useFamilyHistory'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import MobSectionHeader from './MobSectionHeader'

const MobFamilyHistory = ({ clientForm, setFacet, setSaving }) => {
  const { handleOptionClick, handleSubmit, selectedConditions } = useFamilyHistory({ clientForm, setFacet, setSaving })
  return (
    <>
      <MobSectionHeader
        icon={faUsers}
        title='Family History'
        subtitle='Do any of your immediate family members have these conditions?'
      />

      {/* Conditions List */}
      <Row gutter={[16, 16]}>
        {Templates.dataModel.familyHistoryList.options.map((condition, index) => (
          <Col span={24}>
            <div
              key={index}
              className='condition-card'
              role='checkbox'
              onClick={() => handleOptionClick(condition)}
              aria-checked={selectedConditions.includes(condition.value)}
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleOptionClick(condition)
                }
              }}
            >
              <div className='condition-icon'>
                <FontAwesomeIcon icon={faHeartPulse} />
              </div>
              <span className='condition-name'>{condition.label}</span>
              <Form.Item name={['familyHistory', 'list']} style={{ display: 'none' }}>
                <input type='checkbox' />
              </Form.Item>
            </div>
          </Col>
        ))}
      </Row>

      {/* Bottom Action Buttons */}
      <PrevNextFloatingButtons
        onPrev={() => {
          clientForm.resetFields()
          setFacet(null)
        }}
        onNext={handleSubmit}
        prevText='Cancel'
        nextText='Save'
      />
    </>
  )
}

export default MobFamilyHistory
