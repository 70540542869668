import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useSystemUsersTable = ({ type, clinics }) => {
  const [viewType, setViewType] = useState(type)
  const [data, setData] = useState([])
  const state = useSelector((state) => state)
  const coreState = useSelector((state) => state.core)
  const dispatch = useDispatch()

  const systemUsersData = useSelector((state) => state.systemUsers.data)
  const medicalProfessionalsData = useSelector((state) => state.medicalProfessionals.data)
  const patientsData = useSelector((state) => state.patients.data)
  const [tableData, setTableData] = useState()
  const [teamChatProps, setTeamChatProps] = useState({ isOpen: false, userRef: '' })

  const [userFilters, setUserFilters] = useState({
    firstName: [],
    lastName: [],
    email: []
  })

  useEffect(() => {
    switch (type) {
      case 'system':
        setViewType('System Users')
        setTableData(systemUsersData)
        setData(systemUsersData)
        break
      case 'medical':
        setViewType('Medical Professionals')
        setTableData(medicalProfessionalsData)
        setData(medicalProfessionalsData)
        break
      case 'patient':
        setViewType('Patients')

        setTableData(patientsData)
        setData(patientsData)
        break
      default:
        setViewType('System Users')
        setTableData(systemUsersData)
        setData(systemUsersData)
    }
    // eslint-disable-next-line
  }, [systemUsersData, medicalProfessionalsData, patientsData])

  useEffect(() => {
    generateFilters()
    // eslint-disable-next-line
  }, [data])

  const generateFilters = () => {
    let tmpRoles = JSON.parse(JSON.stringify(state.auth.roles))
    let tmpProfessions = JSON.parse(JSON.stringify(state.auth.professions))
    let tmpClinics = clinics
    let tmpServices = [...JSON.parse(JSON.stringify(state.services.data))]
    if (state.core.entity.virtualConsultations.enabled) {
      state.virtualServices.data.forEach((service) => {
        let tmpService = JSON.parse(JSON.stringify(service)) // create instance
        if (tmpService) {
          tmpService.name = `${service.name} (Virtual)`
          tmpServices.push(tmpService)
        }
      })
    }

    tmpRoles = tmpRoles.filter((i) => i.value !== 'patient' && i.value !== 'medical_professional')
    const filters = {
      roles: tmpRoles.map((role) => {
        return {
          text: role.label,
          value: role.value
        }
      }),
      professions: tmpProfessions.map((role) => {
        return {
          text: role.label,
          value: role.value
        }
      }),
      services: tmpServices.map((service) => {
        return {
          text: service.name,
          value: service._id
        }
      }),
      clinics: tmpClinics.map((clinic) => {
        return {
          text: clinic.name,
          value: clinic._id
        }
      })
    }

    setUserFilters(filters)
  }
  return {
    viewType,
    setViewType,
    tableData,
    setTableData,
    data,
    setData,
    userFilters,
    generateFilters,
    teamChatProps,
    setTeamChatProps,
    state,
    coreState,
    dispatch,
    systemUsersData,
    medicalProfessionalsData,
    patientsData
  }
}

export default useSystemUsersTable
