import React, { useEffect, useRef } from 'react'
import { Card, Col, theme, Space, Button } from 'antd'
import CustomRow from '../../../../reusable-components/CustomRow'
import { hexToRGBA } from '../../../../lib/utils'
import MedicationCard from '../../Scripting tool/components/MedicationCard'
import SearchBar from '../../Scripting tool/components/SearchBar'
import ControlPanel from '../../Scripting tool/components/ControlPanel'
import Footer from './Footer'
import FeatureButton from '../../../../reusable-components/FeatureButton'

const MedicationList = ({
  medsList,
  currentMed,
  dischemDeliveryApplicable,
  selectedDischem,
  dischemType,
  setDischemModalOpen,
  setCurrentDescFocus,
  onAdd,
  onRemove,
  onDosageChange,
  onPropertyChange,
  onEditMed,
  rptRepeat,
  setRptRepeat,
  rptICD,
  setRptICD,
  script,
  isNew,
  isEditMode,
  isCopy,
  handleUpdateScheduleData,
  confirmReadOnly,
  setIsCopy,
  setIsEditMode,
  handleGenerateScriptTemplate,
  handleCreateScript,
  scriptBusy,
  loading,
  printingScript,
  setShowClipboard,
  selectedPatient,
  handleCancel
}) => {
  const { token } = theme.useToken()
  const scrollContainerRef = useRef(null)
  const mainSearchRef = useRef(null)
  const bottomSearchRef = useRef(null)
  const contentRef = useRef(null)

  // Calculate total SEP
  const calculateTotalSEP = () => {
    return medsList.reduce((total, med) => {
      return total + (med.singleExitPrice || 0)
    }, 0)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollContainerRef.current || !mainSearchRef.current || !bottomSearchRef.current || !contentRef.current)
        return

      const container = scrollContainerRef.current
      const mainSearch = mainSearchRef.current
      const bottomSearch = bottomSearchRef.current
      const content = contentRef.current
      const mainSearchRect = mainSearch.getBoundingClientRect()
      const contentRect = content.getBoundingClientRect()

      // Show bottom search when main search is below viewport
      if (mainSearchRect.bottom > window.innerHeight) {
        bottomSearch.style.transform = 'translateY(0)'
        bottomSearch.style.opacity = '1'
        bottomSearch.style.pointerEvents = 'auto'
        mainSearch.style.opacity = '0'
        mainSearch.style.pointerEvents = 'none'
        // Match the width and position of the content area
        bottomSearch.style.width = `${contentRect.width}px`
        bottomSearch.style.left = `${contentRect.left}px`
      } else {
        bottomSearch.style.transform = 'translateY(100%)'
        bottomSearch.style.opacity = '0'
        bottomSearch.style.pointerEvents = 'none'
        mainSearch.style.opacity = '1'
        mainSearch.style.pointerEvents = 'auto'
      }
    }

    const container = scrollContainerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
      window.addEventListener('resize', handleScroll)
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
        window.removeEventListener('resize', handleScroll)
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const SearchWithTemplates = ({ style }) => (
    <Space.Compact style={{ width: '100%', ...style, alignItems: 'center' }}>
      <SearchBar onSelect={onAdd} style={{ flex: 1 }} />
      <Button
        onClick={() => setShowClipboard(true)}
        style={{
          background: '#3498db',
          border: 'none',
          height: '32px',
          borderRadius: '20px',
          padding: '0 16px',
          marginLeft: '12px',
          color: 'white',
          fontSize: '13px',
          boxShadow: '0 2px 4px rgba(52, 152, 219, 0.2)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.background = '#2980b9'
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.background = '#3498db'
        }}
      >
        + Template
      </Button>
    </Space.Compact>
  )

  return (
    <div style={{ position: 'relative' }} ref={contentRef}>
      {/* Main content wrapper */}
      <div
        ref={scrollContainerRef}
        style={{
          height: 'calc(100vh - 100px)',
          overflowY: 'auto',
          paddingBottom: '80px'
        }}
      >
        <h2
          style={{
            color: token.colorPrimary,
            textAlign: 'center',
            margin: '0 0 16px 0',
            fontSize: '20px',
            fontWeight: 500
          }}
        >
          Create prescription
        </h2>

        {!medsList?.length ? (
          // Show search bar directly under header when no medications
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SearchWithTemplates style={{ width: 800 }} />
          </div>
        ) : (
          // Show card with medications and search bar at bottom when medications exist
          <Card
            type='inner'
            size='small'
            title={<center>Script Items</center>}
            style={{ minHeight: '100%' }}
            headStyle={{
              background: hexToRGBA(token.colorPrimary, 0.1),
              paddingTop: 12,
              paddingBottom: 12
            }}
            bodyStyle={{ padding: 0 }}
          >
            <ControlPanel rptRepeat={rptRepeat} setRptRepeat={setRptRepeat} rptICD={rptICD} setRptICD={setRptICD} />

            <CustomRow
              gutter={[12, 12]}
              style={{
                minHeight: '100%',
                alignContent: 'flex-start',
                marginTop: dischemDeliveryApplicable ? 0 : 12,
                marginBottom: 12
              }}
            >
              {dischemDeliveryApplicable && (
                <Col
                  span={24}
                  style={{ background: hexToRGBA(!selectedDischem ? token.colorError : token.colorSuccess, 0.2) }}
                >
                  <p style={{ paddingTop: 4, paddingBottom: 4 }}>
                    {!selectedDischem ? (
                      <center>
                        Dischem <strong>{dischemType === 'C' ? 'Collection' : 'Delivery'}</strong> requested but no
                        store specified, please review.{' '}
                        <strong
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setDischemModalOpen(true)
                          }}
                        >
                          <u>Click here</u>
                        </strong>{' '}
                        to correct.
                      </center>
                    ) : (
                      <center>
                        {dischemType === 'C' ? (
                          <>
                            Dischem <strong>Collection</strong> by patient at{' '}
                            <strong>{JSON.parse(selectedDischem)?.address}</strong> will be requsted.
                          </>
                        ) : (
                          <>
                            Dischem <strong>Delivery</strong> from{' '}
                            <strong>{JSON.parse(selectedDischem)?.address}</strong> will be requested.
                          </>
                        )}
                      </center>
                    )}
                  </p>
                </Col>
              )}

              {medsList.map((med, index) => (
                <Col span={24} key={index}>
                  <MedicationCard
                    med={med}
                    index={index}
                    isEditing={index === currentMed}
                    onEdit={() => onEditMed(index)}
                    onRemove={() => onRemove(index)}
                    onClose={() => onEditMed(null)}
                    onDosageChange={onDosageChange}
                    onPropertyChange={onPropertyChange}
                    setCurrentDescFocus={setCurrentDescFocus}
                  />
                </Col>
              ))}

              {/* Search bar and footer */}
              <Col span={24} style={{ marginTop: '16px' }} ref={mainSearchRef}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px'
                  }}
                >
                  <SearchWithTemplates />
                  <Footer
                    script={script}
                    isNew={isNew}
                    isEditMode={isEditMode}
                    isCopy={isCopy}
                    handleUpdateScheduleData={handleUpdateScheduleData}
                    confirmReadOnly={confirmReadOnly}
                    totalCost={calculateTotalSEP()}
                    handleGenerateScriptTemplate={handleGenerateScriptTemplate}
                    handleCreateScript={handleCreateScript}
                    scriptBusy={scriptBusy}
                    loading={loading}
                    printingScript={printingScript}
                    selectedPatient={selectedPatient}
                    handleCancel={handleCancel}
                  />
                </div>
              </Col>
            </CustomRow>
          </Card>
        )}
      </div>

      {/* Bottom search bar that appears when original is out of view */}
      {medsList?.length > 0 && (
        <div
          ref={bottomSearchRef}
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '16px',
            background: 'transparent',
            boxShadow: '0 -2px 8px rgba(0,0,0,0.1)',
            zIndex: 1000,
            transform: 'translateY(100%)',
            opacity: 0,
            transition: 'transform 0.2s ease, opacity 0.2s ease',
            pointerEvents: 'none'
          }}
        >
          <SearchWithTemplates />
        </div>
      )}
    </div>
  )
}

export default MedicationList
