import React from 'react'
import { Card, Col, Space, Row, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMedkit } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { hexToRGBA } from '../../../../lib/utils'
import { medAdminTypes } from '../../../utils/templates'

const ScriptItems = ({ script, data }) => {
  const { token } = theme.useToken()

  return (
    <Col xs={24} sm={24} md={script.onSchedule ? 12 : 24} lg={script.onSchedule ? 12 : 24}>
      <Card
        style={{ minHeight: '100%', borderRadius: 0 }}
        headStyle={{ borderRadius: 0, background: hexToRGBA(token.colorPrimary, 0.2) }}
        size='small'
        type='inner'
        title={
          <center>
            <Space direction='vertical' style={{ gap: 0 }}>
              <h2>Script Items</h2>{' '}
              <small>
                Scripted on {dayjs(data.createdAt).format('DD MMM YYYY')} at{' '}
                {dayjs(data.createdAt).format('HH:mm')}
              </small>
            </Space>
          </center>
        }
      >
        <Row gutter={[0, 24]}>
          {script.items.map((med) => (
            <Col span={24} key={med.code}>
              <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <div>
                  <Space style={{ gap: 0 }}>
                    {medAdminTypes.find((i) => i.key === med.presentationCode)?.icon ? (
                      <div style={{ color: med.isChronic ? '#F39C12' : token.colorPrimary, width: 22 }}>
                        {medAdminTypes.find((i) => i.key === med.presentationCode)?.icon}{' '}
                      </div>
                    ) : (
                      <div style={{ color: med.isChronic ? '#F39C12' : token.colorPrimary, width: 22 }}>
                        <FontAwesomeIcon icon={faMedkit} />
                      </div>
                    )}
                    {`${med.name ? med.name : ''} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
                      med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
                    } ${med.presentationCode ? med.presentationCode : ''}`}
                  </Space>
                  <div
                    style={{
                      paddingLeft: 20,
                      borderLeft: `2px ${med.isChronic ? token.colorWarning : token.colorPrimary} solid`
                    }}
                  >
                    <small>{med.rpt ? <>Repeat: {med.rpt}</> : <>No Repeat</>}</small>
                    <br />
                    <small>
                      {med.scriptingDosage ? med.scriptingDosage : 'No scripting dosage specified.'}
                    </small>
                    <br />
                    <small>
                      {med.icd ? (
                        <>
                          ICD: {med.icd.code} - {med.icd.description}
                        </>
                      ) : (
                        <>No ICD</>
                      )}
                    </small>
                    <br />
                    {med.isChronic && <small style={{ color: '#F39C12' }}>Chronic</small>}
                  </div>
                </div>
              </Space>
            </Col>
          ))}
        </Row>
      </Card>
    </Col>
  )
}

export default ScriptItems 