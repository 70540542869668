import { Card, Select, Space, Table, theme } from 'antd'
import React from 'react'
import { hexToRGBA, toCamelCase } from '../../../../lib/utils'
import { CarePlanTemplatesNewRecordVisitsColumnTemplate } from '../../../utils/templates'
import { SpecialistTypes } from './utility'

const VisitTrackers = ({ handleAddTracking, visits, handleUpdateTracker, handleRemoveTracker }) => {
  const { token } = theme.useToken()
  const filterChosenVisits = (item) => {
    let index = -1
    index = visits.findIndex((i) => i.dataKey === toCamelCase(item))
    return index === -1
  }

  return (
    <Card>
      <h1 style={{ color: token.colorPrimary }}>VISIT TRACKERS</h1>
      <p>Please select any doctor / specialist visits you want to track.</p>
      <Card
        size='small'
        style={{ marginTop: 12 }}
        headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
        bodyStyle={{ padding: 0 }}
        title={
          <Space>
            <h2>Doctor Visits Tracking</h2>{' '}
            <Select
              placeholder='- Select Visit Trackers -'
              value={null}
              style={{ width: 500 }}
              onChange={(selection) => {
                handleAddTracking('visits', selection, selection)
              }}
              showSearch
              options={SpecialistTypes.filter((i) => filterChosenVisits(i)).map((type) => ({
                label: type,
                value: JSON.stringify({ key: toCamelCase(type), label: type })
              }))}
            />
          </Space>
        }
      >
        <Table
          size='small'
          bordered
          dataSource={visits}
          columns={CarePlanTemplatesNewRecordVisitsColumnTemplate(handleUpdateTracker, handleRemoveTracker)}
        />
      </Card>
    </Card>
  )
}

export default VisitTrackers
