import { useState } from 'react'
import { CATEGORIES } from '../utils/enums'

export const useTabManagement = () => {
  const [activeTab, setActiveTab] = useState(CATEGORIES.TEMPLATES)
  const [searchTerm, setSearchTerm] = useState('')

  return {
    activeTab,
    setActiveTab,
    searchTerm,
    setSearchTerm
  }
}
