import { useState, useEffect } from 'react'
import { Form } from 'antd'
import { normalizePresentationCode } from '../utils/normalizers'
import { DOSAGE_FORMS } from '../constants/dosageForms'
import { useEstimatedQuantity } from './useEstimatedQuantity'

export const useMedicationForm = (med) => {
  const [form] = Form.useForm()

  // Field visibility states
  const [showRoute, setShowRoute] = useState(false)
  const [showDose, setShowDose] = useState(false)
  const [showFrequency, setShowFrequency] = useState(false)
  const [showDuration, setShowDuration] = useState(false)

  // Selected value states
  const [selectedDosageForm, setSelectedDosageForm] = useState(undefined)
  const [currentDosageForm, setCurrentDosageForm] = useState(undefined)
  const [selectedRoute, setSelectedRoute] = useState(undefined)
  const [selectedDose, setSelectedDose] = useState(undefined)
  const [selectedFrequency, setSelectedFrequency] = useState(undefined)
  const [selectedDuration, setSelectedDuration] = useState(undefined)

  // Get estimated quantity calculations
  const { estimatedQuantity, quantitySuffix, showEstQuantity, setShowEstQuantity } = useEstimatedQuantity(
    form.getFieldValue('frequency'),
    form.getFieldValue('duration'),
    med?.presentationCode,
    form.getFieldValue('dose'),
    med?.dispensingVolumePacksize
  )

  // Handle initial values
  useEffect(() => {
    // Reset form and states when medication changes
    form.resetFields()

    // Reset visibility states
    setShowRoute(false)
    setShowDose(false)
    setShowFrequency(false)
    setShowDuration(false)
    setShowEstQuantity(false)

    // Reset selection states
    setSelectedRoute(undefined)
    setSelectedFrequency(undefined)
    setSelectedDuration(undefined)

    // For existing medications with valid presentation code
    const normalizedCode = normalizePresentationCode(med?.presentationCode)
    if (normalizedCode) {
      setSelectedDosageForm(normalizedCode)
      setCurrentDosageForm(normalizedCode)
      setShowRoute(true)
      form.setFieldsValue({
        dosageForm: normalizedCode,
        repeat: med.rpt || 'No Repeat'
      })

      // Auto-select route if only one available
      const availableRoutes = getAvailableRoutes(normalizedCode)
      if (availableRoutes.length === 1) {
        const defaultRoute = availableRoutes[0].value
        setSelectedRoute(defaultRoute)
        setShowDose(true)

        // Handle different dosage forms
        if (['tablet', 'capsule'].includes(normalizedCode)) {
          setSelectedDose('1')
          setShowFrequency(true)
          form.setFieldsValue({
            route: defaultRoute,
            dose: '1'
          })
        } else if (!['suspension', 'syrup', 'tablet', 'capsule'].includes(normalizedCode)) {
          const defaultDose = getDefaultDose(normalizedCode)
          setSelectedDose(defaultDose)
          setShowFrequency(true)
          form.setFieldsValue({
            route: defaultRoute,
            dose: defaultDose
          })
        } else {
          form.setFieldsValue({
            route: defaultRoute
          })
        }
      }
    } else {
      setSelectedDosageForm(undefined)
      setCurrentDosageForm(undefined)
      form.setFieldsValue({
        repeat: 'No Repeat'
      })
    }

    // Set dose if it exists in medication data and is not a suspension/syrup/tablet/capsule
    if (med?.strengthMetric1 && 
        !['suspension', 'syrup', 'tablet', 'capsule'].includes(normalizedCode)) {
      setShowDose(true)
      setSelectedDose(med.strengthMetric1)
      setShowFrequency(true)
      form.setFieldsValue({
        dose: med.strengthMetric1
      })
    }
  }, [med])

  // Get available routes based on dosage form
  const getAvailableRoutes = (dosageForm) => {
    if (!dosageForm) return []

    const COMMON_ROUTES = {
      ORAL: [{ value: 'Oral', label: 'Oral' }],
      SUBLINGUAL: [{ value: 'Sublingual', label: 'Sublingual' }],
      BUCCAL: [{ value: 'Buccal', label: 'Buccal' }],
      TOPICAL: [{ value: 'Topical', label: 'Topical' }],
      OPHTHALMIC: [{ value: 'Ophthalmic', label: 'Ophthalmic (Eye)' }],
      OTIC: [{ value: 'Otic', label: 'Otic (Ear)' }],
      NASAL: [{ value: 'Nasal', label: 'Nasal' }],
      INHALATION: [{ value: 'Inhalation', label: 'Inhalation' }],
      RECTAL: [{ value: 'Rectal', label: 'Rectal' }],
      VAGINAL: [{ value: 'Vaginal', label: 'Vaginal' }],
      INJECTION: [
        { value: 'Intramuscular', label: 'Intramuscular (IM)' },
        { value: 'Intravenous', label: 'Intravenous (IV)' },
        { value: 'Subcutaneous', label: 'Subcutaneous (SC)' },
        { value: 'Intradermal', label: 'Intradermal' },
        { value: 'Intra-articular', label: 'Intra-articular' }
      ]
    }

    switch (dosageForm?.toLowerCase()) {
      // Oral Solid Forms
      case 'tablet':
      case 'capsule':
      case 'lozenge':
      case 'wafer':
      case 'granules':
      case 'powder_oral':
      case 'sachet':
        return [...COMMON_ROUTES.ORAL, ...COMMON_ROUTES.SUBLINGUAL]

      case 'chewing_gum':
        return COMMON_ROUTES.ORAL

      // Liquid Forms
      case 'syrup':
      case 'suspension':
      case 'solution':
      case 'drops_oral':
      case 'liquid':
        return COMMON_ROUTES.ORAL

      // Injectables
      case 'injection':
        return COMMON_ROUTES.INJECTION

      case 'implant':
        return [{ value: 'Subcutaneous', label: 'Subcutaneous (SC)' }]

      // Inhalation Forms
      case 'inhaler':
      case 'gas':
        return COMMON_ROUTES.INHALATION

      // Topical Forms
      case 'cream':
      case 'ointment':
      case 'gel':
      case 'lotion':
      case 'paint':
      case 'powder_external':
      case 'spray_external':
      case 'shampoo':
      case 'soap':
      case 'pad':
      case 'plaster':
        return [...COMMON_ROUTES.TOPICAL, ...COMMON_ROUTES.RECTAL, ...COMMON_ROUTES.VAGINAL]

      case 'patch':
        return [...COMMON_ROUTES.TOPICAL]

      // Eye/Ear/Nose Forms
      case 'drops_eye':
      case 'eye_ointment':
      case 'eye_gel':
        return COMMON_ROUTES.OPHTHALMIC

      case 'drops_ear':
        return COMMON_ROUTES.OTIC

      case 'drops_external':
        return [...COMMON_ROUTES.OPHTHALMIC, ...COMMON_ROUTES.OTIC, ...COMMON_ROUTES.NASAL]

      case 'nasal_spray':
      case 'nasal_gel':
      case 'nose_drops':
        return COMMON_ROUTES.NASAL

      // Vaginal Forms
      case 'vaginal_tablet':
      case 'vaginal_capsule':
      case 'vaginal_cream':
      case 'vaginal_gel':
      case 'vaginal_ring':
      case 'vaginal_combo':
        return COMMON_ROUTES.VAGINAL

      // Other Forms
      case 'suppository':
        return COMMON_ROUTES.RECTAL

      case 'enema':
      case 'douche':
        return [...COMMON_ROUTES.RECTAL]

      case 'mouthwash':
        return COMMON_ROUTES.ORAL

      case 'paste':
        return [...COMMON_ROUTES.ORAL, ...COMMON_ROUTES.TOPICAL]

      case 'vaccine':
        return [...COMMON_ROUTES.INJECTION]

      case 'dialysate':
        return [{ value: 'Peritoneal', label: 'Peritoneal' }]

      case 'cartridge':
        return [...COMMON_ROUTES.INJECTION]

      case 'iud':
        return [{ value: 'Intrauterine', label: 'Intrauterine' }]

      default:
        return []
    }
  }

  // Get default dose based on dosage form
  const getDefaultDose = (dosageForm) => {
    switch (dosageForm) {
      case 'tablet':
      case 'capsule':
        return '1'
      case 'inhaler':
        return 1
      case 'drops':
        return 0.5
      case 'cream':
        return 10
      case 'nasal_spray':
        return 1
      default:
        return 10
    }
  }

  return {
    form,
    showRoute,
    setShowRoute,
    showDose,
    setShowDose,
    showFrequency,
    setShowFrequency,
    showDuration,
    setShowDuration,
    showEstQuantity,
    setShowEstQuantity,
    selectedDosageForm,
    setSelectedDosageForm,
    currentDosageForm,
    setCurrentDosageForm,
    selectedRoute,
    setSelectedRoute,
    selectedDose,
    setSelectedDose,
    selectedFrequency,
    setSelectedFrequency,
    selectedDuration,
    setSelectedDuration,
    estimatedQuantity,
    quantitySuffix,
    getAvailableRoutes,
    getDefaultDose
  }
}
