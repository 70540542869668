import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { readMacroTrackerRecipes, saveMacroTrackerRecipe } from '../utils/macro-tracker-utils'
import { handleError } from '../../lib/utils'
import { message } from 'antd'
import dayjs from 'dayjs'

const useHealthAlternatives = ({ handleBack, healthyAlternative, userRef, setModalOpen }) => {
  const authState = useSelector((state) => state.auth)
  const [savingHealthyAlternative, setSavingHealthAlternative] = useState(false)
  const [currentRecipe, setCurrentRecipe] = useState(null)
  const [healthyAlternativesList, setSavingHealthyAlternativesList] = useState([])
  const [loading, setLoading] = useState(false)

  const handleSaveHealthyAlternative = async () => {
    setSavingHealthAlternative(true)

    const payload = JSON.parse(JSON.stringify(healthyAlternative))

    payload.userRef = authState.agiliteUser._id
    payload.createdAt = new Date(dayjs())

    try {
      await saveMacroTrackerRecipe(payload)
    } catch (error) {
      message.error(handleError(error))
    }
    handleBack(true)
    setSavingHealthAlternative(false)
  }

  useEffect(() => {
    handleReadHealthyAlternatives()
    // eslint-disable-next-line
  }, [])

  const handleReadHealthyAlternatives = async () => {
    let tmpData = []
    setLoading(true)
    try {
      tmpData = await readMacroTrackerRecipes({ userRef: userRef ? userRef : authState.agiliteUser._id })

      setSavingHealthyAlternativesList(tmpData)
    } catch (error) {
      message.error(handleError(error))
    }
    setLoading(false)
  }

  const handleReset = (close) => {
    setCurrentRecipe('')
    setSavingHealthyAlternativesList([])
    if (close) {
      setModalOpen()
    }
  }
  return {
    savingHealthyAlternative,
    handleSaveHealthyAlternative,
    handleReset,
    currentRecipe,
    loading,
    setCurrentRecipe,
    healthyAlternativesList
  }
}

export default useHealthAlternatives
