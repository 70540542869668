import dayjs from 'dayjs'
import store from '../../store'
import { readClinics } from '../Admin/clinics/utils/utils'
import { readPatients } from '../Admin/patients/utils/utils'
import { readBillingRecords } from '../Bookings/utils/utils'
import { generateCraftMyPdfReport, getInvoices, getPatientPayments } from './utils'

// Add this new function before any of the report generation functions
const filterInvoicesByCriteria = (invoice, data) => {
  // Check if invoice matches the books filter
  const matchesBooks =
    (data.books.includes('medical_aid') && data.books.includes('private')) ||
    (data.books.includes('medical_aid') && invoice.patient?.medicalAid?.name) ||
    (data.books.includes('private') && !invoice.patient?.medicalAid?.name)

  // Check if invoice matches the schemes filter
  const matchesSchemes = data.schemes.includes('ALL') || data.schemes.includes(invoice.patient?.medicalAid?.schemeCode)

  // Check if invoice matches medical professionals filter
  const matchesMedicalProfs = data.medicalProfs.includes('ALL') || data.medicalProfs.includes(invoice.medicalProfRef)

  // Invoice must match all conditions
  return matchesBooks && matchesSchemes && matchesMedicalProfs
}

export const generateBillingReport = (data, reportType) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const qry = {}

        if (!data.venues.includes('ALL')) {
          qry.clinicRef = { $in: data.venues }
        }

        qry.createdAt = {
          $gte: dayjs(data.periodStart).startOf('day').toDate(),
          $lte: dayjs(data.periodEnd).endOf('day').toDate()
        }

        const tmpPatientPayments = await getPatientPayments(qry)
        let pdfHtml = null

        // Get unique references for batch fetching
        const uniqueRefs = tmpPatientPayments.reduce(
          (acc, payment) => {
            if (payment.invoiceRef) acc.invoices.add(payment.invoiceRef)
            if (payment.clinicRef) acc.clinics.add(payment.clinicRef)
            if (payment.userRef) acc.patients.add(payment.userRef)
            return acc
          },
          {
            invoices: new Set(),
            clinics: new Set(),
            patients: new Set()
          }
        )

        // Batch fetch all related data using API calls
        const [invoices, clinics, patients] = await Promise.all([
          getInvoices({ _id: { $in: Array.from(uniqueRefs.invoices) } }),
          readClinics({ _id: { $in: Array.from(uniqueRefs.clinics) } }),
          readPatients({ _id: { $in: Array.from(uniqueRefs.patients) } })
        ])

        // Create lookup maps for O(1) access
        const invoiceLookup = invoices.reduce((acc, invoice) => {
          acc[invoice._id] = invoice
          return acc
        }, {})

        const clinicLookup = clinics.reduce((acc, clinic) => {
          acc[clinic._id] = clinic
          return acc
        }, {})

        const patientLookup = patients.reduce((acc, patient) => {
          acc[patient._id] = patient
          return acc
        }, {})

        // Enrich patient payments with related data
        let enrichedPayments = tmpPatientPayments.map((payment) => ({
          ...payment,
          invoice: invoiceLookup[payment.invoiceRef] || null,
          clinic: clinicLookup[payment.clinicRef] || null,
          patient: patientLookup[payment.userRef] || null
        }))

        enrichedPayments = enrichedPayments.filter((payment) => {
          // Check if invoice matches the books filter
          const matchesBooks =
            (data.books.includes('medical_aid') && data.books.includes('private')) ||
            (data.books.includes('medical_aid') && payment.patient?.medicalAid?.name) ||
            (data.books.includes('private') && !payment.patient?.medicalAid?.name)

          // Check if invoice matches the schemes filter
          const matchesSchemes =
            data.schemes.includes('ALL') || data.schemes.includes(payment.patient?.medicalAid?.schemeCode)

          const matchesMedicalProfs =
            data.medicalProfs.includes('ALL') || data.medicalProfs.includes(payment.invoice?.medicalProfRef)

          // Invoice must match all conditions
          return matchesBooks && matchesSchemes && matchesMedicalProfs
        })

        switch (reportType) {
          case 'period_end_report':
            pdfHtml = await generatePeriodEndReportExtended(enrichedPayments, data)
            break
          case 'receipt_report':
            pdfHtml = await generateReceiptReport(enrichedPayments, data)
            break
          default:
            break
        }

        resolve(pdfHtml)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const generatePeriodEndReportExtended = async (payments, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const htmlTemplateStart = `<!DOCTYPE html>
<html>
  <head>  
    <title>Period End Report</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        margin: 20px;
        font-size: 12px;
        padding: 0;
      }

      .report-header {
        text-align: center;
        background-color: #f8f9fa;
        padding: 20px;
        border-bottom: 2px solid #dee2e6;
        margin-bottom: 30px;
      }

      .report-title {
        font-size: 24px;
        color: #2c3e50;
        margin: 0 0 15px 0;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .report-info {
        display: flex;
        justify-content: space-between;
        max-width: 600px;
        margin: 0 auto;
        font-size: 14px;
      }

      .info-group {
        text-align: left;
      }

      .info-label {
        font-weight: bold;
        color: #6c757d;
        margin-right: 8px;
      }

      .info-value {
        color: #2c3e50;
      }
      
      table {
        width: 100%;
        border-collapse: collapse;
        margin: 10px 0;
      }
      
      th, td {
        border: 1px solid #000;
        padding: 4px 8px;
        text-align: left;
      }
      
      th {
        background-color: #f4f4f4;
      }
      
      .section-header {
        font-size: 18px;
        color: #2c3e50;
        margin: 25px 0 15px 0;
        padding-bottom: 8px;
        border-bottom: 2px solid #e9ecef;
        font-weight: bold;
      }
      
      .total-row {
        font-weight: bold;
        background-color: #f8f9fa;
      }
      
      .amount {
        text-align: right;
      }

      .footer {
        margin-top: 30px;
        text-align: center;
        font-size: 12px;
        color: #6c757d;
        border-top: 1px solid #dee2e6;
        padding-top: 15px;
      }
    </style>
  </head>
  <body>
    <div class="report-header">
      <h1 class="report-title">Period End Report</h1>
      <div class="report-info">
        <div class="info-group">
          <span class="info-label">Period:</span>
          <span class="info-value">${dayjs(data.periodStart).format('DD/MM/YYYY')} - ${dayjs(data.periodEnd).format(
          'DD/MM/YYYY'
        )}</span>
        </div>
        <div class="info-group">
          <span class="info-label">Practice:</span>
          <span class="info-value">${payments[0]?.clinic?.name || 'META - CLINIC'}</span>
        </div>
      </div>
    </div>

    <h3 class="section-title">Income</h3>
    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Income</th>
            <th>Type</th>
            <th>Quantity</th>
            <th>VAT</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>`

        const generationName = `${store.getState().auth.agiliteUser.firstName} ${
          store.getState().auth.agiliteUser.lastName
        }`
        const generationDate = new Date().toLocaleDateString('en-ZA', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })

        // Aggregate items by type and payment category
        const aggregatedItems = payments.reduce((acc, payment) => {
          payment.items.forEach((item) => {
            // Handle Medical Aid amounts
            if (parseFloat(item.medicalAid) > 0) {
              const medicalAidKey = `${item.type}_medical_aid`
              if (!acc[medicalAidKey]) {
                acc[medicalAidKey] = {
                  type: item.type,
                  category: 'Medical Aid',
                  quantity: 0,
                  total: 0
                }
              }
              acc[medicalAidKey].quantity += 1
              acc[medicalAidKey].total += parseFloat(item.medicalAid)
            }

            // Handle Patient amounts
            if (parseFloat(item.patient) > 0) {
              const patientKey = `${item.type}_patient`
              if (!acc[patientKey]) {
                acc[patientKey] = {
                  type: item.type,
                  category: 'Patient',
                  quantity: 0,
                  total: 0
                }
              }
              acc[patientKey].quantity += 1
              acc[patientKey].total += parseFloat(item.patient)
            }
          })
          return acc
        }, {})

        // Sort entries by type and category
        const sortedEntries = Object.entries(aggregatedItems).sort((a, b) => {
          if (a[1].type !== b[1].type) {
            return a[1].type.localeCompare(b[1].type)
          }
          return a[1].category.localeCompare(b[1].category)
        })

        // Generate rows with subtotals
        let currentType = null
        let subtotalQuantity = 0
        let subtotalAmount = 0
        let rows = []

        // Track totals for final summary
        let medicalAidTotal = { quantity: 0, amount: 0 }
        let patientTotal = { quantity: 0, amount: 0 }

        sortedEntries.forEach(([key, data], index) => {
          // If we're starting a new type, reset the subtotals
          if (currentType !== data.type) {
            // Add subtotal row for previous type (if not first type)
            if (currentType !== null) {
              rows.push(`
          <tr>
            <td colspan="2"><strong>${currentType} subtotal</strong></td>
            <td>${subtotalQuantity}</td>
            <td>0.00</td>
            <td><strong>${subtotalAmount.toFixed(2)}</strong></td>
          </tr>
        `)
            }
            currentType = data.type
            subtotalQuantity = 0
            subtotalAmount = 0
          }

          // Add regular row
          rows.push(`
      <tr>
        <td>${data.type}</td>
        <td>${data.category}</td>
        <td>${data.quantity}</td>
        <td>0.00</td>
        <td>${data.total.toFixed(2)}</td>
      </tr>
    `)

          // Update subtotals
          subtotalQuantity += data.quantity
          subtotalAmount += data.total

          // Update final summary totals
          if (data.category === 'Medical Aid') {
            medicalAidTotal.quantity += data.quantity
            medicalAidTotal.amount += data.total
          } else {
            patientTotal.quantity += data.quantity
            patientTotal.amount += data.total
          }

          // If this is the last entry, add the final type subtotal
          if (index === sortedEntries.length - 1) {
            rows.push(`
        <tr>
          <td colspan="2"><strong>${currentType} subtotal</strong></td>
          <td>${subtotalQuantity}</td>
          <td>0.00</td>
          <td><strong>${subtotalAmount.toFixed(2)}</strong></td>
        </tr>
      `)
          }
        })

        // Add final summary rows
        rows.push(`
    <tr class="total-row">
      <td colspan="2"><strong>Total</strong></td>
      <td>${medicalAidTotal.quantity + patientTotal.quantity}</td>
      <td>0.00</td>
      <td><strong>${(medicalAidTotal.amount + patientTotal.amount).toFixed(2)}</strong></td>
    </tr>
  `)

        const paymentRows = rows.join('')

        // Modify the discounts aggregation
        const aggregatedDiscounts = payments.reduce((acc, payment) => {
          payment.items.forEach((item) => {
            const key = item.type // Group by type (Medicine, Consultation, etc.)
            if (!acc[key]) {
              acc[key] = {
                type: item.type,
                quantity: 0,
                total: 0
              }
            }
            // Always increment quantity and add discount (even if 0)
            acc[key].quantity += 1
            acc[key].total += parseFloat(item.discount || 0)
          })
          return acc
        }, {})

        // Generate discount rows with subtotals
        let discountTotal = { quantity: 0, amount: 0 }
        let discountRows = []

        // Add rows for each type
        Object.entries(aggregatedDiscounts)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .forEach(([type, data]) => {
            // Add regular row
            discountRows.push(`
        <tr>
          <td>${type}</td>
          <td>Patient</td>
          <td>${data.quantity}</td>
          <td>0.00</td>
          <td>${data.total.toFixed(2)}</td>
        </tr>
      `)

            // Add type subtotal
            discountRows.push(`
        <tr>
          <td colspan="2"><strong>${type} subtotal</strong></td>
          <td>${data.quantity}</td>
          <td>0.00</td>
          <td><strong>${data.total.toFixed(2)}</strong></td>
        </tr>
      `)

            discountTotal.quantity += data.quantity
            discountTotal.amount += data.total
          })

        // Add summary rows
        discountRows.push(`
    <tr class="total-row">
      <td colspan="2"><strong>Total</strong></td>
      <td>${discountTotal.quantity}</td>
      <td>0.00</td>
      <td><strong>${discountTotal.amount.toFixed(2)}</strong></td>
    </tr>
  `)

        const discountTable = `
    <table>
    <hr />
      <h3>Discounts</h3>
      <thead>
        <tr>
          <th>Discounts</th>
          <th>Type</th>
          <th>Quantity</th>
          <th>VAT</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        ${discountRows.join('')}
      </tbody>
    </table>`

        // Aggregate payment methods
        const aggregatedPayments = payments.reduce((acc, payment) => {
          const method = payment.paymentMethod || 'unknown'
          if (!acc[method]) {
            acc[method] = {
              quantity: 0,
              total: 0
            }
          }
          acc[method].quantity += 1
          acc[method].total += parseFloat(payment.paymentAmount || 0)
          return acc
        }, {})

        // Generate payment method rows
        let paymentTotal = { quantity: 0, amount: 0 }
        let paymentMethodRows = []

        // Add rows for each payment method
        Object.entries(aggregatedPayments)
          .sort((a, b) => a[0].localeCompare(b[0]))
          .forEach(([method, data]) => {
            // Format method name to be capitalized
            let formattedMethod = ''

            switch (method) {
              case 'eft':
                formattedMethod = 'Bank Transfer (EFT)'
                break
              case 'card':
              case 'onlineCard':
                formattedMethod = 'Online Credit/Debit Card'
                break
              case 'cash':
                formattedMethod = 'Cash'
                break
              default:
                break
            }

            // Add regular row
            paymentMethodRows.push(`
        <tr>
          <td>${formattedMethod}</td>
          <td>${data.quantity}</td>
          <td>0.00</td>
          <td>${data.total.toFixed(2)}</td>
        </tr>
      `)

            paymentTotal.quantity += data.quantity
            paymentTotal.amount += data.total
          })

        // Add total row
        paymentMethodRows.push(`
    <tr class="total-row">
      <td><strong>Total</strong></td>
      <td>${paymentTotal.quantity}</td>
      <td>0.00</td>
      <td><strong>${paymentTotal.amount.toFixed(2)}</strong></td>
    </tr>
  `)

        const paymentMethodTable = `
      <hr />
    <h3>Payments Received</h3>
    <table>
      <thead>
        <tr>
          <th>Payment Method</th>
          <th>Quantity</th>
          <th>VAT</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        ${paymentMethodRows.join('')}
      </tbody>
    </table>`

        const htmlTemplateEnd = `
            <div class="footer">
              Generated by ${generationName}, ${generationDate}
            </div>
          </body>
        </html>`

        // Update the final HTML assembly with the correct variable names
        const completeHtml = htmlTemplateStart + paymentRows + discountTable + paymentMethodTable + htmlTemplateEnd

        const pdfReport = await generateCraftMyPdfReport(completeHtml, '9d677b23b345706a')
        resolve(pdfReport)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const generateReceiptReport = async (payments, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const generationName = `${store.getState().auth.agiliteUser.firstName} ${
          store.getState().auth.agiliteUser.lastName
        }`
        const generationDate = new Date().toLocaleDateString('en-ZA', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })

        // Group payments by payment method and reversed status
        const groupedPayments = payments.reduce((acc, payment) => {
          const category = payment.paymentMethod || 'unknown'
          if (!acc[category]) {
            acc[category] = []
          }
          acc[category].push(payment)
          return acc
        }, {})

        const htmlTemplateStart = `<!DOCTYPE html>
<html>
  <head>
    <title>Receipt Report</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        margin: 20px;
        font-size: 12px;
        padding: 0;
      }

      .report-header {
        text-align: center;
        background-color: #f8f9fa;
        padding: 20px;
        border-bottom: 2px solid #dee2e6;
        margin-bottom: 30px;
      }

      .report-title {
        font-size: 24px;
        color: #2c3e50;
        margin: 0 0 15px 0;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .report-info {
        display: flex;
        justify-content: space-between;
        max-width: 600px;
        margin: 0 auto;
        font-size: 14px;
      }

      .info-group {
        text-align: left;
      }

      .info-label {
        font-weight: bold;
        color: #6c757d;
        margin-right: 8px;
      }

      .info-value {
        color: #2c3e50;
      }
      
      table {
        width: 100%;
        border-collapse: collapse;
        margin: 10px 0;
      }
      
      th, td {
        border: 1px solid #000;
        padding: 4px 8px;
        text-align: left;
      }
      
      th {
        background-color: #f4f4f4;
      }
      
      .section-header {
        font-size: 18px;
        color: #2c3e50;
        margin: 25px 0 15px 0;
        padding-bottom: 8px;
        border-bottom: 2px solid #e9ecef;
        font-weight: bold;
      }
      
      .total-row {
        font-weight: bold;
        background-color: #f8f9fa;
      }
      
      .amount {
        text-align: right;
      }

      .footer {
        margin-top: 30px;
        text-align: center;
        font-size: 12px;
        color: #6c757d;
        border-top: 1px solid #dee2e6;
        padding-top: 15px;
      }
    </style>
  </head>
  <body>
    <div class="report-header">
      <h1 class="report-title">Receipt Report</h1>
      <div class="report-info">
        <div class="info-group">
          <span class="info-label">Period:</span>
          <span class="info-value">${dayjs(data.periodStart).format('DD/MM/YYYY')} - ${dayjs(data.periodEnd).format(
          'DD/MM/YYYY'
        )}</span>
        </div>
        <div class="info-group">
          <span class="info-label">Practice:</span>
          <span class="info-value">${payments[0]?.clinic?.name || 'META - CLINIC'}</span>
        </div>
      </div>
    </div>`

        // Function to generate table for a payment method
        const generatePaymentTable = (methodPayments, title) => {
          if (!methodPayments || methodPayments.length === 0) return ''

          let total = 0
          const rows = methodPayments
            .map((payment) => {
              const amount = parseFloat(payment.paymentAmount) || 0
              total += amount

              // Get patient name from the enriched data
              const patientName = payment.patient
                ? `${payment.patient.firstName} ${payment.patient.lastName}`
                : payment.userRef || ''

              // Get invoice number from the enriched data
              const accNo = payment.patient.billingId

              // Format dates
              const receiptDate = new Date(payment.receiptDate).toLocaleDateString()
              const transactionDate = new Date(payment.createdAt).toLocaleDateString()

              return `
              <tr>
                <td>${receiptDate}</td>
                <td>${transactionDate}</td>
                <td>${accNo}</td>
                <td>${payment.userSchemeCode || 'PRIVATE'}</td>
                <td>${patientName}</td>
                <td>${payment.receiptNumber}</td>
                <td class="amount">${amount.toFixed(2)}</td>
              </tr>`
            })
            .join('')

          return `
            <div class="section-header">${title}</div>
            <table>
              <thead>
                <tr>
                  <th>Receipt / Remittance date</th>
                  <th>Transaction date</th>
                  <th>Acc no / Filing ref</th>
                  <th>Scheme name</th>
                  <th>Patient</th>
                  <th>Receipt no</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                ${rows}
                <tr class="total-row">
                  <td colspan="6">Total</td>
                  <td class="amount">${total.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>`
        }

        // Generate tables for each payment method
        const eftTable = generatePaymentTable(groupedPayments.eft, 'Bank Transfer (EFT)')
        const cashTable = generatePaymentTable(groupedPayments.cash, 'Cash')
        const cardTable = generatePaymentTable(groupedPayments.card, 'Credit/Debit Card')
        const onlineCardTable = generatePaymentTable(groupedPayments.onlineCard, 'Online Credit/Debit Card')
        const reversedTable = generatePaymentTable(groupedPayments.reversed, 'Reversed Receipts')

        // Calculate grand total (excluding reversed payments)
        // const grandTotal = Object.entries(groupedPayments)
        //   .filter(([method]) => method !== 'reversed')
        //   .reduce((total, [_, methodPayments]) => {
        //     return total + methodPayments.reduce((sum, payment) => sum + (parseFloat(payment.paymentAmount) || 0), 0)
        //   }, 0)

        // const reversedTotal = (groupedPayments.reversed || []).reduce(
        //   (total, payment) => total + (parseFloat(payment.paymentAmount) || 0),
        //   0
        // )

        const htmlTemplateEnd = `
            <div class="footer">
              Generated by ${generationName}, ${generationDate}
            </div>
          </body>
        </html>`

        const completeHtml =
          htmlTemplateStart + eftTable + cashTable + cardTable + onlineCardTable + reversedTable + htmlTemplateEnd

        const pdfReport = await generateCraftMyPdfReport(completeHtml, '9d677b23b345706a')
        resolve(pdfReport)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const generateDetailedInvoiceReport = async (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const generationName = `${store.getState().auth.agiliteUser.firstName} ${
          store.getState().auth.agiliteUser.lastName
        }`
        const generationDate = new Date().toLocaleDateString('en-ZA', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })

        const qry = {}

        if (!data.medicalProfs.includes('ALL')) {
          qry.medicalProfRef = { $in: data.medicalProfs }
        }

        qry.createdAt = {
          $gte: dayjs(data.periodStart).startOf('day').toDate(),
          $lte: dayjs(data.periodEnd).endOf('day').toDate()
        }

        if (!data.venues.includes('ALL')) {
          qry.clinicRef = { $in: data.venues }
        }

        if (data.includes.includes('finalized')) {
          qry.status = { $ne: 'pending' }
        }

        if (data.includes.includes('pending')) {
          qry.status = { $eq: 'pending' }
        }

        // Fetch invoices

        const invoices = await getInvoices(qry)

        // Get unique references for batch fetching
        const uniqueRefs = invoices.reduce(
          (acc, invoice) => {
            if (invoice.userRef) acc.patients.add(invoice.userRef)
            if (invoice.bookingRef) acc.bookings.add(invoice.bookingRef)
            return acc
          },
          {
            patients: new Set(),
            bookings: new Set()
          }
        )

        // Batch fetch all related data
        const [patients, billingRecords] = await Promise.all([
          readPatients({ _id: { $in: Array.from(uniqueRefs.patients) } }),
          readBillingRecords({ bookingRef: { $in: Array.from(uniqueRefs.bookings) } })
        ])

        // Create lookup maps for O(1) access
        const patientLookup = patients.reduce((acc, patient) => {
          acc[patient._id] = patient
          return acc
        }, {})

        const billingRecordsLookup = billingRecords.reduce((acc, record) => {
          if (!acc[record.bookingRef]) {
            acc[record.bookingRef] = []
          }
          acc[record.bookingRef].push(record)
          return acc
        }, {})

        // Enrich invoices with related data
        let enrichedInvoices = invoices.map((invoice) => ({
          ...invoice,
          patient: patientLookup[invoice.userRef] || null,
          billingRecords: billingRecordsLookup[invoice.bookingRef] || []
        }))

        enrichedInvoices = enrichedInvoices.filter((invoice) => filterInvoicesByCriteria(invoice, data))

        const htmlTemplateStart = `<!DOCTYPE html>
<html>
  <head>
    <title>Detailed Invoice Report</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        margin: 20px;
        font-size: 12px;
        padding: 0;
      }

      .report-header {
        text-align: center;
        background-color: #f8f9fa;
        padding: 20px;
        border-bottom: 2px solid #dee2e6;
        margin-bottom: 30px;
      }

      .report-title {
        font-size: 24px;
        color: #2c3e50;
        margin: 0 0 15px 0;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .report-info {
        display: flex;
        justify-content: space-between;
        max-width: 600px;
        margin: 0 auto;
        font-size: 14px;
      }

      .info-group {
        text-align: left;
      }

      .info-label {
        font-weight: bold;
        color: #6c757d;
        margin-right: 8px;
      }

      .info-value {
        color: #2c3e50;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin: 10px 0;
        font-size: 11px;
      }

      th, td {
        border: 1px solid #dee2e6;
        padding: 6px;
        text-align: left;
      }

      th {
        background-color: #e9ecef;
        font-weight: bold;
        color: #495057;
      }

      tr:nth-child(even) {
        background-color: #f8f9fa;
      }

      .numeric {
        text-align: right;
      }

      .total-row {
        font-weight: bold;
        background-color: #e9ecef;
      }

      .invoice-total {
        font-weight: bold;
        background-color: #f8f9fa;
      }

      .medical-aid-info {
        font-size: 10px;
        color: #666;
      }

      .footer {
        margin-top: 30px;
        text-align: center;
        font-size: 12px;
        color: #6c757d;
        border-top: 1px solid #dee2e6;
        padding-top: 15px;
      }

      .summary-box {
        border: 1px solid #dee2e6;
        padding: 10px;
        margin-top: 20px;
        background-color: #f8f9fa;
        display: inline-block;
      }

      .scheme-info {
        font-style: italic;
        color: #666;
      }
    </style>
  </head>
  <body>
    <div class="report-header">
      <h1 class="report-title">Detailed Invoice Report</h1>
      <div class="report-info">
        <div class="info-group">
          <span class="info-label">Period:</span>
          <span class="info-value">${dayjs(data.periodStart).format('DD/MM/YYYY')} - ${dayjs(data.periodEnd).format(
          'DD/MM/YYYY'
        )}</span>
        </div>
        <div class="info-group">
          <span class="info-label">Practice:</span>
          <span class="info-value">${store.getState().core.entity.name || 'META - CLINIC'}</span>
        </div>
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Invoice No</th>
          <th>Patient Details</th>
          <th>Medical Aid</th>
          <th>Code</th>
          <th>Description</th>
          <th>Qty</th>
          <th>Medical Aid</th>
          <th>Patient</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>`

        const rows = enrichedInvoices
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .map((invoice) => {
            let invoiceRows = []
            let invoiceMedDue = 0
            let invoicePatDue = 0
            let invoiceTotal = 0

            // Generate rows for each billing record
            invoice.billingRecords.forEach((record) => {
              const medDue = parseFloat(record.medicalAid) || 0
              const patDue = parseFloat(record.patient) || 0
              const total = parseFloat(record.total) || 0

              invoiceMedDue += medDue
              invoicePatDue += patDue
              invoiceTotal += total

              const patientDetails = invoice.patient
                ? `${invoice.patient.firstName} ${invoice.patient.lastName}<br>
                 <span class="medical-aid-info">ID: ${invoice.patient.idNo || ''}</span>`
                : ''

              const medicalAidInfo =
                invoice.invoiceType === 'medical' && invoice.patient?.medicalAid?.name
                  ? `${invoice.patient.medicalAid.name}<br>
                 <span class="scheme-info">
                   ${invoice.patient.medicalAid.plan || ''} ${invoice.patient.medicalAid.planOption || ''}<br>
                   No: ${invoice.patient.medicalAid.number || ''}
                 </span>`
                  : 'PRIVATE'

              const truncatedDescription =
                record.name?.length > 50 ? record.name.substring(0, 50) + '...' : record.name || ''

              invoiceRows.push(`
                <tr>
                  <td>${new Date(invoice.createdAt).toLocaleDateString()}</td>
                  <td>${invoice.invoiceNo}</td>
                  <td>${patientDetails}</td>
                  <td>${medicalAidInfo}</td>
                  <td>${record.code}</td>
                  <td>${truncatedDescription}</td>
                  <td class="numeric">${record.qty}</td>
                  <td class="numeric">${medDue.toFixed(2)}</td>
                  <td class="numeric">${patDue.toFixed(2)}</td>
                  <td class="numeric">${total.toFixed(2)}</td>
                </tr>`)
            })

            // Always add invoice total row
            invoiceRows.push(`
              <tr class="invoice-total">
                <td colspan="7">Invoice Total</td>
                <td class="numeric">${invoiceMedDue.toFixed(2)}</td>
                <td class="numeric">${invoicePatDue.toFixed(2)}</td>
                <td class="numeric">${invoiceTotal.toFixed(2)}</td>
              </tr>
              <tr><td colspan="10" style="border: none; height: 10px;"></td></tr>`)

            return invoiceRows.join('')
          })
          .join('')

        const htmlTemplateEnd = `
          </tbody>
        </table>

        <div class="footer">
          Generated by ${generationName}, ${generationDate}
        </div>
      </body>
    </html>`

        const completeHtml = htmlTemplateStart + rows + htmlTemplateEnd

        const pdfReport = await generateCraftMyPdfReport(completeHtml, '9d677b23b345706a')
        resolve(pdfReport)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const generateSummarizedAgeAnalysis = async (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const generationName = `${store.getState().auth.agiliteUser.firstName} ${
          store.getState().auth.agiliteUser.lastName
        }`
        const generationDate = new Date().toLocaleDateString('en-ZA', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })

        const qry = {}

        if (!data.venues.includes('ALL')) {
          qry.clinicRef = { $in: data.venues }
        }

        // TODO: Need filter for overdue period and snapshotted period
        // TODO: Need filter for account status (accStatus in patientPayments)

        // Get all invoices
        let invoices = await getInvoices(qry)

        // Get unique references for batch fetching
        const uniqueRefs = invoices.reduce(
          (acc, invoice) => {
            if (invoice.userRef) acc.patients.add(invoice.userRef)
            if (invoice.bookingRef) acc.bookings.add(invoice.bookingRef)
            return acc
          },
          {
            patients: new Set(),
            bookings: new Set()
          }
        )

        // Batch fetch all related data
        const [patients, billingRecords, patientPayments] = await Promise.all([
          readPatients({ _id: { $in: Array.from(uniqueRefs.patients) } }),
          readBillingRecords({ bookingRef: { $in: Array.from(uniqueRefs.bookings) } }),
          getPatientPayments({ invoiceRef: { $in: invoices.map((invoice) => invoice._id) } })
        ])

        // Create lookup maps for O(1) access
        const patientLookup = patients.reduce((acc, patient) => {
          acc[patient._id] = patient
          return acc
        }, {})

        const billingRecordsLookup = billingRecords.reduce((acc, record) => {
          if (!acc[record.bookingRef]) {
            acc[record.bookingRef] = []
          }
          acc[record.bookingRef].push(record)
          return acc
        }, {})

        const patientPaymentsLookup = patientPayments.reduce((acc, payment) => {
          if (!acc[payment.invoiceRef]) {
            acc[payment.invoiceRef] = []
          }
          acc[payment.invoiceRef].push(payment)
          return acc
        }, {})

        let enrichedInvoices = invoices.map((invoice) => ({
          ...invoice,
          patient: patientLookup[invoice.userRef] || null,
          billingRecords: billingRecordsLookup[invoice.bookingRef] || [],
          patientPayments: patientPaymentsLookup[invoice._id] || []
        }))

        enrichedInvoices = enrichedInvoices.filter((invoice) => filterInvoicesByCriteria(invoice, data))

        // Group invoices by medical aid scheme
        const schemeGroups = enrichedInvoices.reduce((acc, invoice) => {
          const patient = patientLookup[invoice.userRef]
          const schemeName = patient?.medicalAid?.name || 'PRIVATE'

          if (!acc[schemeName]) {
            acc[schemeName] = {
              unallocated: 0, // TODO: Calculate unallocated payments
              medicalAidOutstanding: 0,
              patientOutstanding: 0,
              totalOutstanding: 0,
              current: 0,
              days30: 0,
              days60: 0,
              days90: 0,
              days120: 0,
              days150: 0
            }
          }

          const billingRecords = billingRecordsLookup[invoice.bookingRef] || []
          const invoiceDate = new Date(invoice.createdAt)
          const today = new Date()
          const daysDiff = Math.floor((today - invoiceDate) / (1000 * 60 * 60 * 24))

          // Calculate medical aid and patient outstanding amounts
          let medicalAidDue = 0
          let patientDue = 0

          billingRecords.forEach((record) => {
            medicalAidDue += parseFloat(record.medicalAid) || 0
            patientDue += parseFloat(record.patient) || 0
          })

          // Update totals based on age of invoice
          if (daysDiff <= 30) {
            acc[schemeName].current += medicalAidDue + patientDue
          } else if (daysDiff <= 60) {
            acc[schemeName].days30 += medicalAidDue + patientDue
          } else if (daysDiff <= 90) {
            acc[schemeName].days60 += medicalAidDue + patientDue
          } else if (daysDiff <= 120) {
            acc[schemeName].days90 += medicalAidDue + patientDue
          } else if (daysDiff <= 150) {
            acc[schemeName].days120 += medicalAidDue + patientDue
          } else {
            acc[schemeName].days150 += medicalAidDue + patientDue
          }

          acc[schemeName].medicalAidOutstanding += medicalAidDue
          acc[schemeName].patientOutstanding += patientDue
          acc[schemeName].totalOutstanding = acc[schemeName].medicalAidOutstanding + acc[schemeName].patientOutstanding

          return acc
        }, {})

        const htmlTemplateStart = `<!DOCTYPE html>
<html>
  <head>
    <title>Summarized Age Analysis</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        margin: 20px;
        padding: 0;
      }

      .report-header {
        text-align: center;
        background-color: #f8f9fa;
        padding: 20px;
        border-bottom: 2px solid #dee2e6;
        margin-bottom: 30px;
      }

      .report-title {
        font-size: 24px;
        color: #2c3e50;
        margin: 0 0 15px 0;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .report-info {
        display: flex;
        justify-content: space-between;
        max-width: 600px;
        margin: 0 auto;
        font-size: 14px;
      }

      .info-group {
        text-align: left;
      }

      .info-label {
        font-weight: bold;
        color: #6c757d;
        margin-right: 8px;
      }

      .info-value {
        color: #2c3e50;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
        font-size: 10px;
      }

      th, td {
        border: 1px solid #000;
        padding: 8px;
        text-align: right;
      }

      th:first-child, td:first-child {
        text-align: left;
      }

      th {
        background-color: #f4f4f4;
        font-weight: bold;
      }

      .total-row {
        font-weight: bold;
        background-color: #f8f9fa;
      }

      .footer {
        margin-top: 30px;
        text-align: center;
        font-size: 12px;
        color: #6c757d;
        border-top: 1px solid #dee2e6;
        padding-top: 15px;
      }
    </style>
  </head>
  <body>
    <div class="report-header">
      <h1 class="report-title">Summarized Age Analysis</h1>
      <div class="report-info">
        <div class="info-group">
          <span class="info-label">As at:</span>
          <span class="info-value">${new Date().toLocaleDateString('en-ZA', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}</span>
        </div>
        <div class="info-group">
          <span class="info-label">Practice:</span>
          <span class="info-value">${store.getState().core.entity.name || 'META - CLINIC'}</span>
        </div>
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th>Entity</th>
          <th>Unallocated</th>
          <th>Medical Aid</th>
          <th>Patient</th>
          <th>Total</th>
          <th>Current</th>
          <th>30 Days</th>
          <th>60 Days</th>
          <th>90 Days</th>
          <th>120 Days</th>
          <th>150+ Days</th>
        </tr>
      </thead>
      <tbody>`

        // Generate rows for each scheme
        const rows = Object.entries(schemeGroups)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(
            ([scheme, data]) => `
        <tr>
          <td>${scheme}</td>
          <td>${data.unallocated.toFixed(2)}</td>
          <td>${data.medicalAidOutstanding.toFixed(2)}</td>
          <td>${data.patientOutstanding.toFixed(2)}</td>
          <td>${data.totalOutstanding.toFixed(2)}</td>
          <td>${data.current.toFixed(2)}</td>
          <td>${data.days30.toFixed(2)}</td>
          <td>${data.days60.toFixed(2)}</td>
          <td>${data.days90.toFixed(2)}</td>
          <td>${data.days120.toFixed(2)}</td>
          <td>${data.days150.toFixed(2)}</td>
        </tr>
      `
          )
          .join('')

        // Calculate totals
        const totals = Object.values(schemeGroups).reduce(
          (acc, data) => {
            acc.unallocated += data.unallocated
            acc.medicalAidOutstanding += data.medicalAidOutstanding
            acc.patientOutstanding += data.patientOutstanding
            acc.totalOutstanding += data.totalOutstanding
            acc.current += data.current
            acc.days30 += data.days30
            acc.days60 += data.days60
            acc.days90 += data.days90
            acc.days120 += data.days120
            acc.days150 += data.days150
            return acc
          },
          {
            unallocated: 0,
            medicalAidOutstanding: 0,
            patientOutstanding: 0,
            totalOutstanding: 0,
            current: 0,
            days30: 0,
            days60: 0,
            days90: 0,
            days120: 0,
            days150: 0
          }
        )

        const totalRow = `
        <tr class="total-row">
          <td>Total</td>
          <td>${totals.unallocated.toFixed(2)}</td>
          <td>${totals.medicalAidOutstanding.toFixed(2)}</td>
          <td>${totals.patientOutstanding.toFixed(2)}</td>
          <td>${totals.totalOutstanding.toFixed(2)}</td>
          <td>${totals.current.toFixed(2)}</td>
          <td>${totals.days30.toFixed(2)}</td>
          <td>${totals.days60.toFixed(2)}</td>
          <td>${totals.days90.toFixed(2)}</td>
          <td>${totals.days120.toFixed(2)}</td>
          <td>${totals.days150.toFixed(2)}</td>
        </tr>`

        const htmlTemplateEnd = `
          ${rows}
          ${totalRow}
        </tbody>
      </table>

      <div class="footer">
        Generated by ${generationName}, ${generationDate}
      </div>
    </body>
  </html>`

        const completeHtml = htmlTemplateStart + htmlTemplateEnd

        const pdfReport = await generateCraftMyPdfReport(completeHtml, '9d677b23b345706a')
        resolve(pdfReport)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const generateMedicalAidAgeAnalysis = async (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const generationName = `${store.getState().auth.agiliteUser.firstName} ${
          store.getState().auth.agiliteUser.lastName
        }`
        const generationDate = new Date().toLocaleDateString('en-ZA', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })

        const qry = {}

        if (!data.venues.includes('ALL')) {
          qry.clinicRef = { $in: data.venues }
        }

        // TODO: Need filter for overdue period and snapshotted period
        // TODO: Need filter for account status (accStatus in patientPayments)

        // Get all invoices
        const invoices = await getInvoices(qry)

        // Get unique references for batch fetching
        const uniqueRefs = invoices.reduce(
          (acc, invoice) => {
            if (invoice.userRef) acc.patients.add(invoice.userRef)
            if (invoice.bookingRef) acc.bookings.add(invoice.bookingRef)
            return acc
          },
          {
            patients: new Set(),
            bookings: new Set()
          }
        )

        // Batch fetch all related data
        const [patients, billingRecords, patientPayments] = await Promise.all([
          readPatients({ _id: { $in: Array.from(uniqueRefs.patients) } }),
          readBillingRecords({ bookingRef: { $in: Array.from(uniqueRefs.bookings) } }),
          getPatientPayments({ invoiceRef: { $in: invoices.map((invoice) => invoice._id) } })
        ])

        // Create lookup maps for O(1) access
        const patientLookup = patients.reduce((acc, patient) => {
          acc[patient._id] = patient
          return acc
        }, {})

        const billingRecordsLookup = billingRecords.reduce((acc, record) => {
          if (!acc[record.bookingRef]) {
            acc[record.bookingRef] = []
          }
          acc[record.bookingRef].push(record)
          return acc
        }, {})

        const patientPaymentsLookup = patientPayments.reduce((acc, payment) => {
          if (!acc[payment.invoiceRef]) {
            acc[payment.invoiceRef] = []
          }
          acc[payment.invoiceRef].push(payment)
          return acc
        }, {})

        let enrichedInvoices = invoices.map((invoice) => ({
          ...invoice,
          patient: patientLookup[invoice.userRef] || null,
          billingRecords: billingRecordsLookup[invoice.bookingRef] || [],
          patientPayments: patientPaymentsLookup[invoice._id] || []
        }))

        enrichedInvoices = enrichedInvoices.filter((invoice) => filterInvoicesByCriteria(invoice, data))

        // Group invoices by medical aid scheme and patient
        const schemeGroups = enrichedInvoices.reduce((acc, invoice) => {
          const patient = patientLookup[invoice.userRef]
          // Skip if patient has no medical aid scheme
          if (!patient?.medicalAid?.name) {
            return acc
          }
          const schemeName = patient.medicalAid.name

          if (!acc[schemeName]) {
            acc[schemeName] = {
              patients: {},
              schemeTotal: {
                unallocated: 0,
                outstanding: 0,
                current: 0,
                days30: 0,
                days60: 0,
                days90: 0,
                days120: 0,
                days150: 0
              }
            }
          }

          const patientKey = invoice.userRef
          if (!acc[schemeName].patients[patientKey]) {
            acc[schemeName].patients[patientKey] = {
              accNo: patient?.billingId || '',
              mainMember: `${patient?.firstName || ''} ${patient?.lastName || ''}`,
              contactDetails: `${patient?.email || ''}${patient?.email && patient?.phoneNumber ? ', ' : ''}${
                patient?.phoneNumber || ''
              }`,
              maNo: patient?.medicalAid?.number || '',
              unallocated: 0,
              outstanding: 0,
              current: 0,
              days30: 0,
              days60: 0,
              days90: 0,
              days120: 0,
              days150: 0
            }
          }

          const billingRecords = billingRecordsLookup[invoice.bookingRef] || []
          const invoiceDate = new Date(invoice.createdAt)
          const today = new Date()
          const daysDiff = Math.floor((today - invoiceDate) / (1000 * 60 * 60 * 24))

          // Calculate medical aid outstanding amount
          let medicalAidDue = 0
          billingRecords.forEach((record) => {
            medicalAidDue += parseFloat(record.medicalAid) || 0
          })

          // Update patient totals based on age of invoice
          const patientData = acc[schemeName].patients[patientKey]
          if (daysDiff <= 30) {
            patientData.current += medicalAidDue
          } else if (daysDiff <= 60) {
            patientData.days30 += medicalAidDue
          } else if (daysDiff <= 90) {
            patientData.days60 += medicalAidDue
          } else if (daysDiff <= 120) {
            patientData.days90 += medicalAidDue
          } else if (daysDiff <= 150) {
            patientData.days120 += medicalAidDue
          } else {
            patientData.days150 += medicalAidDue
          }

          patientData.outstanding += medicalAidDue

          // Update scheme totals
          const schemeTotal = acc[schemeName].schemeTotal
          if (daysDiff <= 30) {
            schemeTotal.current += medicalAidDue
          } else if (daysDiff <= 60) {
            schemeTotal.days30 += medicalAidDue
          } else if (daysDiff <= 90) {
            schemeTotal.days60 += medicalAidDue
          } else if (daysDiff <= 120) {
            schemeTotal.days90 += medicalAidDue
          } else if (daysDiff <= 150) {
            schemeTotal.days120 += medicalAidDue
          } else {
            schemeTotal.days150 += medicalAidDue
          }

          schemeTotal.outstanding += medicalAidDue

          return acc
        }, {})

        const htmlTemplateStart = `<!DOCTYPE html>
<html>
  <head>
    <title>Medical Aid Age Analysis</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        margin: 20px;
        padding: 0;
      }

      .report-header {
        text-align: center;
        background-color: #f8f9fa;
        padding: 20px;
        border-bottom: 2px solid #dee2e6;
        margin-bottom: 30px;
      }

      .report-title {
        font-size: 24px;
        color: #2c3e50;
        margin: 0 0 15px 0;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .report-info {
        display: flex;
        justify-content: space-between;
        max-width: 600px;
        margin: 0 auto;
        font-size: 14px;
      }

      .info-group {
        text-align: left;
      }

      .info-label {
        font-weight: bold;
        color: #6c757d;
        margin-right: 8px;
      }

      .info-value {
        color: #2c3e50;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
        font-size: 8px;
      }

      th, td {
        border: 1px solid #000;
        padding: 6px 4px;
        text-align: right;
      }

      th:first-child, td:first-child,
      th:nth-child(2), td:nth-child(2),
      th:nth-child(3), td:nth-child(3),
      th:nth-child(4), td:nth-child(4),
      th:nth-child(5), td:nth-child(5) {
        text-align: left;
      }

      th {
        background-color: #f4f4f4;
        font-weight: bold;
        padding: 8px 4px;
        font-size: 9px;
      }

      .scheme-header {
        font-weight: bold;
        background-color: #f8f9fa;
        font-size: 9px;
      }

      .scheme-total {
        font-weight: bold;
        background-color: #f8f9fa;
        font-size: 9px;
      }

      .footer {
        margin-top: 30px;
        text-align: center;
        font-size: 12px;
        color: #6c757d;
        border-top: 1px solid #dee2e6;
        padding-top: 15px;
      }
    </style>
  </head>
  <body>
    <div class="report-header">
      <h1 class="report-title">Medical Aid Age Analysis</h1>
      <div class="report-info">
        <div class="info-group">
          <span class="info-label">As at:</span>
          <span class="info-value">${new Date().toLocaleDateString('en-ZA', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}</span>
        </div>
        <div class="info-group">
          <span class="info-label">Practice:</span>
          <span class="info-value">${store.getState().core.entity.name || 'META - CLINIC'}</span>
        </div>
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th>Acc No</th>
          <th>Main member</th>
          <th>Contact details</th>
          <th>MA No</th>
          <th>Unallocated</th>
          <th>Outstanding</th>
          <th>Current</th>
          <th>30 Days</th>
          <th>60 Days</th>
          <th>90 Days</th>
          <th>120 Days</th>
          <th>150+ Days</th>
        </tr>
      </thead>
      <tbody>`

        // Generate rows for each scheme and its patients
        const rows = Object.entries(schemeGroups)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([scheme, data]) => {
            const patientRows = Object.values(data.patients)
              .sort((a, b) => a.mainMember.localeCompare(b.mainMember))
              .map(
                (patient) => `
              <tr>
                <td>${patient.accNo}</td>
                <td>${patient.mainMember}</td>
                <td>${patient.contactDetails}</td>
                <td>${patient.maNo}</td>
                <td>${patient.unallocated.toFixed(2)}</td>
                <td>${patient.outstanding.toFixed(2)}</td>
                <td>${patient.current.toFixed(2)}</td>
                <td>${patient.days30.toFixed(2)}</td>
                <td>${patient.days60.toFixed(2)}</td>
                <td>${patient.days90.toFixed(2)}</td>
                <td>${patient.days120.toFixed(2)}</td>
                <td>${patient.days150.toFixed(2)}</td>
              </tr>
            `
              )
              .join('')

            return `
            <tr class="scheme-header">
              <td colspan="13">${scheme}</td>
            </tr>
            ${patientRows}
            <tr class="scheme-total">
              <td colspan="4">Scheme total</td>
              <td>${data.schemeTotal.unallocated.toFixed(2)}</td>
              <td>${data.schemeTotal.outstanding.toFixed(2)}</td>
              <td>${data.schemeTotal.current.toFixed(2)}</td>
              <td>${data.schemeTotal.days30.toFixed(2)}</td>
              <td>${data.schemeTotal.days60.toFixed(2)}</td>
              <td>${data.schemeTotal.days90.toFixed(2)}</td>
              <td>${data.schemeTotal.days120.toFixed(2)}</td>
              <td>${data.schemeTotal.days150.toFixed(2)}</td>
            </tr>
          `
          })
          .join('')

        const htmlTemplateEnd = `
          ${rows}
        </tbody>
      </table>
    </div>

    <div class="footer">
      Generated by ${generationName}, ${generationDate}
    </div>
  </body>
</html>`

        const completeHtml = htmlTemplateStart + htmlTemplateEnd

        const pdfReport = await generateCraftMyPdfReport(completeHtml, '9d677b23b345706a')
        resolve(pdfReport)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const generatePatientAgeAnalysis = async (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const generationName = `${store.getState().auth.agiliteUser.firstName} ${
          store.getState().auth.agiliteUser.lastName
        }`
        const generationDate = new Date().toLocaleDateString('en-ZA', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })

        const qry = {}

        if (!data.venues.includes('ALL')) {
          qry.clinicRef = { $in: data.venues }
        }

        // TODO: Need filter for overdue period and snapshotted period
        // TODO: Need filter for account status (accStatus in patientPayments)

        // Get all invoices
        const invoices = await getInvoices(qry)

        // Get unique references for batch fetching
        const uniqueRefs = invoices.reduce(
          (acc, invoice) => {
            if (invoice.userRef) acc.patients.add(invoice.userRef)
            if (invoice.bookingRef) acc.bookings.add(invoice.bookingRef)
            return acc
          },
          {
            patients: new Set(),
            bookings: new Set()
          }
        )

        // Batch fetch all related data
        const [patients, billingRecords, patientPayments] = await Promise.all([
          readPatients({ _id: { $in: Array.from(uniqueRefs.patients) } }),
          readBillingRecords({ bookingRef: { $in: Array.from(uniqueRefs.bookings) } }),
          getPatientPayments({ invoiceRef: { $in: invoices.map((invoice) => invoice._id) } })
        ])

        // Create lookup maps for O(1) access
        const patientLookup = patients.reduce((acc, patient) => {
          acc[patient._id] = patient
          return acc
        }, {})

        const billingRecordsLookup = billingRecords.reduce((acc, record) => {
          if (!acc[record.bookingRef]) {
            acc[record.bookingRef] = []
          }
          acc[record.bookingRef].push(record)
          return acc
        }, {})

        const patientPaymentsLookup = patientPayments.reduce((acc, payment) => {
          if (!acc[payment.invoiceRef]) {
            acc[payment.invoiceRef] = []
          }
          acc[payment.invoiceRef].push(payment)
          return acc
        }, {})

        let enrichedInvoices = invoices.map((invoice) => ({
          ...invoice,
          patient: patientLookup[invoice.userRef] || null,
          billingRecords: billingRecordsLookup[invoice.bookingRef] || [],
          patientPayments: patientPaymentsLookup[invoice._id] || []
        }))

        enrichedInvoices = enrichedInvoices.filter((invoice) => filterInvoicesByCriteria(invoice, data))

        // Track patient outstanding amounts
        const patientTotals = {}
        const reportTotal = {
          unallocated: 0,
          outstanding: 0,
          current: 0,
          days30: 0,
          days60: 0,
          days90: 0,
          days120: 0,
          days150: 0
        }

        enrichedInvoices.forEach((invoice) => {
          if (!invoice.patient) return

          const patientKey = invoice.userRef
          if (!patientTotals[patientKey]) {
            patientTotals[patientKey] = {
              accNo: invoice.patient?.billingId || '',
              surname: invoice.patient?.lastName || '',
              initials: invoice.patient?.firstName?.charAt(0) || '',
              contactDetails: `${invoice.patient?.email || ''}${
                invoice.patient?.email && invoice.patient?.phoneNumber ? ', ' : ''
              }${invoice.patient?.phoneNumber || ''}`,
              unallocated: 0,
              outstanding: 0,
              current: 0,
              days30: 0,
              days60: 0,
              days90: 0,
              days120: 0,
              days150: 0
            }
          }

          const billingRecords = billingRecordsLookup[invoice.bookingRef] || []
          const invoiceDate = new Date(invoice.createdAt)
          const today = new Date()
          const daysDiff = Math.floor((today - invoiceDate) / (1000 * 60 * 60 * 24))

          // Calculate patient outstanding amount
          let patientDue = 0
          billingRecords.forEach((record) => {
            patientDue += parseFloat(record.patient) || 0
          })

          // Update patient totals based on age of invoice
          const patientData = patientTotals[patientKey]
          if (daysDiff <= 30) {
            patientData.current += patientDue
            reportTotal.current += patientDue
          } else if (daysDiff <= 60) {
            patientData.days30 += patientDue
            reportTotal.days30 += patientDue
          } else if (daysDiff <= 90) {
            patientData.days60 += patientDue
            reportTotal.days60 += patientDue
          } else if (daysDiff <= 120) {
            patientData.days90 += patientDue
            reportTotal.days90 += patientDue
          } else if (daysDiff <= 150) {
            patientData.days120 += patientDue
            reportTotal.days120 += patientDue
          } else {
            patientData.days150 += patientDue
            reportTotal.days150 += patientDue
          }

          patientData.outstanding += patientDue
          reportTotal.outstanding += patientDue
        })

        const htmlTemplateStart = `<!DOCTYPE html>
<html>
  <head>
    <title>Patient Age Analysis</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        margin: 20px;
        padding: 0;
      }

      .report-header {
        text-align: center;
        background-color: #f8f9fa;
        padding: 20px;
        border-bottom: 2px solid #dee2e6;
        margin-bottom: 30px;
      }

      .report-title {
        font-size: 24px;
        color: #2c3e50;
        margin: 0 0 15px 0;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .report-info {
        display: flex;
        justify-content: space-between;
        max-width: 600px;
        margin: 0 auto;
        font-size: 14px;
      }

      .info-group {
        text-align: left;
      }

      .info-label {
        font-weight: bold;
        color: #6c757d;
        margin-right: 8px;
      }

      .info-value {
        color: #2c3e50;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
        font-size: 8px;
      }

      th, td {
        border: 1px solid #000;
        padding: 6px 4px;
        text-align: right;
      }

      th:first-child, td:first-child,
      th:nth-child(2), td:nth-child(2),
      th:nth-child(3), td:nth-child(3),
      th:nth-child(4), td:nth-child(4) {
        text-align: left;
      }

      th {
        background-color: #f4f4f4;
        font-weight: bold;
        padding: 8px 4px;
        font-size: 9px;
      }

      .total-row {
        font-weight: bold;
        background-color: #f8f9fa;
        font-size: 9px;
      }

      .footer {
        margin-top: 30px;
        text-align: center;
        font-size: 12px;
        color: #6c757d;
        border-top: 1px solid #dee2e6;
        padding-top: 15px;
      }
    </style>
  </head>
  <body>
    <div class="report-header">
      <h1 class="report-title">Patient Age Analysis</h1>
      <div class="report-info">
        <div class="info-group">
          <span class="info-label">As at:</span>
          <span class="info-value">${new Date().toLocaleDateString('en-ZA', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}</span>
        </div>
        <div class="info-group">
          <span class="info-label">Practice:</span>
          <span class="info-value">${store.getState().core.entity.name || 'META - CLINIC'}</span>
        </div>
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th>Acc No</th>
          <th>Surname, Initials</th>
          <th>Contact details</th>
          <th>Unallocated</th>
          <th>Outstanding</th>
          <th>Current</th>
          <th>30 Days</th>
          <th>60 Days</th>
          <th>90 Days</th>
          <th>120 Days</th>
          <th>150+ Days</th>
        </tr>
      </thead>
      <tbody>`

        // Generate rows for all patients sorted alphabetically
        const rows = Object.values(patientTotals)
          .sort((a, b) => a.surname.localeCompare(b.surname))
          .map(
            (patient) => `
            <tr>
              <td>${patient.accNo}</td>
              <td>${patient.surname}, ${patient.initials}</td>
              <td>${patient.contactDetails}</td>
              <td>${patient.unallocated.toFixed(2)}</td>
              <td>${patient.outstanding.toFixed(2)}</td>
              <td>${patient.current.toFixed(2)}</td>
              <td>${patient.days30.toFixed(2)}</td>
              <td>${patient.days60.toFixed(2)}</td>
              <td>${patient.days90.toFixed(2)}</td>
              <td>${patient.days120.toFixed(2)}</td>
              <td>${patient.days150.toFixed(2)}</td>
            </tr>
          `
          )
          .join('')

        // Add total row
        const totalRow = `
          <tr class="total-row">
            <td colspan="3">Total</td>
            <td>${reportTotal.unallocated.toFixed(2)}</td>
            <td>${reportTotal.outstanding.toFixed(2)}</td>
            <td>${reportTotal.current.toFixed(2)}</td>
            <td>${reportTotal.days30.toFixed(2)}</td>
            <td>${reportTotal.days60.toFixed(2)}</td>
            <td>${reportTotal.days90.toFixed(2)}</td>
            <td>${reportTotal.days120.toFixed(2)}</td>
            <td>${reportTotal.days150.toFixed(2)}</td>
          </tr>
        `

        const htmlTemplateEnd = `
          ${rows}
          ${totalRow}
        </tbody>
      </table>

      <div class="footer">
        Generated by ${generationName}, ${generationDate}
      </div>
    </body>
  </html>`

        const completeHtml = htmlTemplateStart + htmlTemplateEnd

        const pdfReport = await generateCraftMyPdfReport(completeHtml, '9d677b23b345706a')
        resolve(pdfReport)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const generateTotalDebtorsAgeAnalysis = async (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const generationName = `${store.getState().auth.agiliteUser.firstName} ${
          store.getState().auth.agiliteUser.lastName
        }`
        const generationDate = new Date().toLocaleDateString('en-ZA', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })

        const qry = {}

        if (!data.venues.includes('ALL')) {
          qry.clinicRef = { $in: data.venues }
        }

        // TODO: Need filter for overdue period and snapshotted period
        // TODO: Need filter for account status (accStatus in patientPayments)

        // Get all invoices
        const invoices = await getInvoices(qry)

        // Get unique references for batch fetching
        const uniqueRefs = invoices.reduce(
          (acc, invoice) => {
            if (invoice.userRef) acc.patients.add(invoice.userRef)
            if (invoice.bookingRef) acc.bookings.add(invoice.bookingRef)
            return acc
          },
          {
            patients: new Set(),
            bookings: new Set()
          }
        )

        // Batch fetch all related data
        const [patients, billingRecords, patientPayments] = await Promise.all([
          readPatients({ _id: { $in: Array.from(uniqueRefs.patients) } }),
          readBillingRecords({ bookingRef: { $in: Array.from(uniqueRefs.bookings) } }),
          getPatientPayments({ invoiceRef: { $in: invoices.map((invoice) => invoice._id) } })
        ])

        // Create lookup maps for O(1) access
        const patientLookup = patients.reduce((acc, patient) => {
          acc[patient._id] = patient
          return acc
        }, {})

        const billingRecordsLookup = billingRecords.reduce((acc, record) => {
          if (!acc[record.bookingRef]) {
            acc[record.bookingRef] = []
          }
          acc[record.bookingRef].push(record)
          return acc
        }, {})

        const patientPaymentsLookup = patientPayments.reduce((acc, payment) => {
          if (!acc[payment.invoiceRef]) {
            acc[payment.invoiceRef] = []
          }
          acc[payment.invoiceRef].push(payment)
          return acc
        }, {})

        let enrichedInvoices = invoices.map((invoice) => ({
          ...invoice,
          patient: patientLookup[invoice.userRef] || null,
          billingRecords: billingRecordsLookup[invoice.bookingRef] || [],
          patientPayments: patientPaymentsLookup[invoice._id] || []
        }))

        enrichedInvoices = enrichedInvoices.filter((invoice) => filterInvoicesByCriteria(invoice, data))

        // Group invoices by medical aid scheme and patient
        const schemeGroups = enrichedInvoices.reduce((acc, invoice) => {
          const patient = patientLookup[invoice.userRef]
          if (!patient) return acc

          const schemeName = patient?.medicalAid?.name || 'PRIVATE'

          if (!acc[schemeName]) {
            acc[schemeName] = {
              patients: {},
              schemeTotal: {
                unallocated: 0,
                medicalAidOutstanding: 0,
                patientOutstanding: 0,
                totalOutstanding: 0,
                current: 0,
                days30: 0,
                days60: 0,
                days90: 0,
                days120: 0,
                days150: 0
              }
            }
          }

          const patientKey = invoice.userRef
          if (!acc[schemeName].patients[patientKey]) {
            acc[schemeName].patients[patientKey] = {
              accNo: patient?.billingId || '',
              mainMember: `${patient?.lastName || ''}, ${patient?.firstName?.charAt(0) || ''}`,
              contactDetails: `${patient?.email || ''}${patient?.email && patient?.phoneNumber ? ', ' : ''}${
                patient?.phoneNumber || ''
              }`,
              unallocated: 0,
              medicalAidOutstanding: 0,
              patientOutstanding: 0,
              totalOutstanding: 0,
              current: 0,
              days30: 0,
              days60: 0,
              days90: 0,
              days120: 0,
              days150: 0
            }
          }

          const billingRecords = billingRecordsLookup[invoice.bookingRef] || []
          const invoiceDate = new Date(invoice.createdAt)
          const today = new Date()
          const daysDiff = Math.floor((today - invoiceDate) / (1000 * 60 * 60 * 24))

          // Calculate medical aid and patient outstanding amounts
          let medicalAidDue = 0
          let patientDue = 0
          billingRecords.forEach((record) => {
            medicalAidDue += parseFloat(record.medicalAid) || 0
            patientDue += parseFloat(record.patient) || 0
          })

          const totalDue = medicalAidDue + patientDue

          // Update patient totals based on age of invoice
          const patientData = acc[schemeName].patients[patientKey]
          if (daysDiff <= 30) {
            patientData.current += totalDue
          } else if (daysDiff <= 60) {
            patientData.days30 += totalDue
          } else if (daysDiff <= 90) {
            patientData.days60 += totalDue
          } else if (daysDiff <= 120) {
            patientData.days90 += totalDue
          } else if (daysDiff <= 150) {
            patientData.days120 += totalDue
          } else {
            patientData.days150 += totalDue
          }

          patientData.medicalAidOutstanding += medicalAidDue
          patientData.patientOutstanding += patientDue
          patientData.totalOutstanding = patientData.medicalAidOutstanding + patientData.patientOutstanding

          // Update scheme totals
          const schemeTotal = acc[schemeName].schemeTotal
          if (daysDiff <= 30) {
            schemeTotal.current += totalDue
          } else if (daysDiff <= 60) {
            schemeTotal.days30 += totalDue
          } else if (daysDiff <= 90) {
            schemeTotal.days60 += totalDue
          } else if (daysDiff <= 120) {
            schemeTotal.days90 += totalDue
          } else if (daysDiff <= 150) {
            schemeTotal.days120 += totalDue
          } else {
            schemeTotal.days150 += totalDue
          }

          schemeTotal.medicalAidOutstanding += medicalAidDue
          schemeTotal.patientOutstanding += patientDue
          schemeTotal.totalOutstanding = schemeTotal.medicalAidOutstanding + schemeTotal.patientOutstanding

          return acc
        }, {})

        const htmlTemplateStart = `<!DOCTYPE html>
<html>
  <head>
    <title>Total Debtors Age Analysis</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        margin: 20px;
        padding: 0;
      }

      .report-header {
        text-align: center;
        background-color: #f8f9fa;
        padding: 20px;
        border-bottom: 2px solid #dee2e6;
        margin-bottom: 30px;
      }

      .report-title {
        font-size: 24px;
        color: #2c3e50;
        margin: 0 0 15px 0;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .report-info {
        display: flex;
        justify-content: space-between;
        max-width: 600px;
        margin: 0 auto;
        font-size: 14px;
      }

      .info-group {
        text-align: left;
      }

      .info-label {
        font-weight: bold;
        color: #6c757d;
        margin-right: 8px;
      }

      .info-value {
        color: #2c3e50;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
        font-size: 8px;
      }

      th, td {
        border: 1px solid #000;
        padding: 6px 4px;
        text-align: right;
      }

      th:first-child, td:first-child,
      th:nth-child(2), td:nth-child(2),
      th:nth-child(3), td:nth-child(3),
      th:nth-child(4), td:nth-child(4) {
        text-align: left;
      }

      th {
        background-color: #f4f4f4;
        font-weight: bold;
        padding: 8px 4px;
        font-size: 9px;
      }

      .scheme-header {
        font-weight: bold;
        background-color: #f8f9fa;
        font-size: 9px;
      }

      .total {
        font-weight: bold;
        background-color: #f8f9fa;
        font-size: 9px;
      }

      .footer {
        margin-top: 30px;
        text-align: center;
        font-size: 12px;
        color: #6c757d;
        border-top: 1px solid #dee2e6;
        padding-top: 15px;
      }
    </style>
  </head>
  <body>
    <div class="report-header">
      <h1 class="report-title">Total Debtors Age Analysis</h1>
      <div class="report-info">
        <div class="info-group">
          <span class="info-label">As at:</span>
          <span class="info-value">${new Date().toLocaleDateString('en-ZA', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}</span>
        </div>
        <div class="info-group">
          <span class="info-label">Practice:</span>
          <span class="info-value">${store.getState().core.entity.name || 'META - CLINIC'}</span>
        </div>
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th>Acc No</th>
          <th>Main member</th>
          <th>Contact details</th>
          <th>Unallocated</th>
          <th>Medical Aid Outstanding</th>
          <th>Patient Outstanding</th>
          <th>Total Outstanding</th>
          <th>Current</th>
          <th>30 Days</th>
          <th>60 Days</th>
          <th>90 Days</th>
          <th>120 Days</th>
          <th>150+ Days</th>
        </tr>
      </thead>
      <tbody>`

        // Generate rows for each scheme and its patients
        const rows = Object.entries(schemeGroups)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([scheme, data]) => {
            const patientRows = Object.values(data.patients)
              .sort((a, b) => a.mainMember.localeCompare(b.mainMember))
              .map(
                (patient) => `
              <tr>
                <td>${patient.accNo}</td>
                <td>${patient.mainMember}</td>
                <td>${patient.contactDetails}</td>
                <td>${patient.unallocated.toFixed(2)}</td>
                <td>${patient.medicalAidOutstanding.toFixed(2)}</td>
                <td>${patient.patientOutstanding.toFixed(2)}</td>
                <td>${patient.totalOutstanding.toFixed(2)}</td>
                <td>${patient.current.toFixed(2)}</td>
                <td>${patient.days30.toFixed(2)}</td>
                <td>${patient.days60.toFixed(2)}</td>
                <td>${patient.days90.toFixed(2)}</td>
                <td>${patient.days120.toFixed(2)}</td>
                <td>${patient.days150.toFixed(2)}</td>
              </tr>
            `
              )
              .join('')

            return `
            <tr class="scheme-header">
              <td colspan="13">${scheme}</td>
            </tr>
            ${patientRows}
            <tr class="total">
              <td colspan="3">Total</td>
              <td>${data.schemeTotal.unallocated.toFixed(2)}</td>
              <td>${data.schemeTotal.medicalAidOutstanding.toFixed(2)}</td>
              <td>${data.schemeTotal.patientOutstanding.toFixed(2)}</td>
              <td>${data.schemeTotal.totalOutstanding.toFixed(2)}</td>
              <td>${data.schemeTotal.current.toFixed(2)}</td>
              <td>${data.schemeTotal.days30.toFixed(2)}</td>
              <td>${data.schemeTotal.days60.toFixed(2)}</td>
              <td>${data.schemeTotal.days90.toFixed(2)}</td>
              <td>${data.schemeTotal.days120.toFixed(2)}</td>
              <td>${data.schemeTotal.days150.toFixed(2)}</td>
            </tr>
          `
          })
          .join('')

        const htmlTemplateEnd = `
          ${rows}
        </tbody>
      </table>

      <div class="footer">
        Generated by ${generationName}, ${generationDate}
      </div>
    </body>
  </html>`

        const completeHtml = htmlTemplateStart + htmlTemplateEnd

        const pdfReport = await generateCraftMyPdfReport(completeHtml, '9d677b23b345706a')
        resolve(pdfReport)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
