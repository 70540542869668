import { normalizePresentationCode } from './normalizers'

export const getQuantitySuffix = (presentationCode, quantity) => {
  const normalizedForm = normalizePresentationCode(presentationCode)
  switch (normalizedForm) {
    case 'tablet':
      return quantity > 1 ? 'tablets' : 'tablet'
    case 'capsule':
      return quantity > 1 ? 'capsules' : 'capsule'
    case 'suspension':
      return 'ml'
    case 'syrup':
      return 'ml'
    case 'inhaler':
      return quantity > 1 ? 'puffs' : 'puff'
    case 'drops':
      return quantity > 1 ? 'drops' : 'drop'
    case 'cream':
      return quantity > 1 ? 'tubes' : 'tube'
    case 'ointment':
      return quantity > 1 ? 'tubes' : 'tube'
    case 'nasal_spray':
      return quantity > 1 ? 'sprays' : 'spray'
    default:
      return quantity > 1 ? 'units' : 'unit'
  }
}
