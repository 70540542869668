import React, { memo } from 'react'
import { List, Space, Button, Tooltip, Badge, Tag } from 'antd'
import { CheckOutlined, DeleteOutlined, ClockCircleOutlined, UserOutlined, EditOutlined } from '@ant-design/icons'
import { TASK_TYPE_CONFIG } from '../config/taskTypes'
import { STATUS_CONFIG, STATUS } from '../config/taskStatus'
import { getPriorityColor, getDisplayName, capitalizeFirstLetter } from '../utils/taskUtils'
import styles from '../styles/TaskItem.module.css'
import dayjs from 'dayjs'

const TaskItem = ({ task, onComplete, onDelete, onEdit, canDelete }) => {
  const getTypeTag = () => {
    const config = TASK_TYPE_CONFIG[task.taskType]
    return (
      <Tag icon={config?.icon} color={config?.color} className={styles.typeTag}>
        {config?.label}
      </Tag>
    )
  }

  const getStatusTag = () => {
    const config = STATUS_CONFIG[task.status]
    return (
      <Tag color={config?.color} className={styles.statusTag}>
        {config?.text}
      </Tag>
    )
  }

  return (
    <List.Item className={`${styles.taskItem} ${styles[task.status]}`}>
      <div className={styles.taskContent}>
        <div className={styles.taskMain}>
          <div className={styles.taskHeader}>
            {getTypeTag()}
            {getStatusTag()}
          </div>
          <div className={styles.taskDescription}>
            <Badge
              color={task.priority ? getPriorityColor(task.priority) : '#1890ff'}
              text={task.description}
              className={styles.taskText}
            />
          </div>
          <div className={styles.taskMeta}>
            <div className={styles.taskMetaGroup}>
              <span className={styles.taskDate}>
                <ClockCircleOutlined /> Due: {dayjs(task.dueDate).format('DD-MM-YYYY')}
              </span>
            </div>
            <div className={styles.taskMetaGroup}>
              <span className={styles.taskCreator}>
                <UserOutlined /> Created by:{' '}
                {getDisplayName(task.assignedBy.firstName + ' ' + task.assignedBy.lastName)}
              </span>
              <span className={styles.taskResponsible}>
                Assigned to: {getDisplayName(task.assignedTo.firstName + ' ' + task.assignedTo.lastName)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Space className={styles.taskActions} align='center' size='middle'>
        {task.status !== STATUS.COMPLETED && (
          <Tooltip title='Mark as Done'>
            <Button
              type='text'
              icon={<CheckOutlined />}
              className={styles.completeButton}
              onClick={() => onComplete(task._id)}
            />
          </Tooltip>
        )}
        {canDelete && (
          <>
            <Tooltip title='Edit Task'>
              <Button type='text' icon={<EditOutlined />} className={styles.editButton} onClick={() => onEdit(task)} />
            </Tooltip>
            <Tooltip title='Delete Task'>
              <Button type='text' danger icon={<DeleteOutlined />} onClick={() => onDelete(task.id)} />
            </Tooltip>
          </>
        )}
      </Space>
    </List.Item>
  )
}

export default memo(TaskItem)
