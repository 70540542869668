import React from 'react'
import { Button, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { hexToRGBA } from '../lib/utils'

const ModalHeader = ({ handleReset, title, icon }) => {
  const { token } = theme.useToken()

  return (
    <>
      <div
        style={{
          width: '100%',
          padding: '16px 24px',
          background: '#fff',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          top: 0,
          left: 0,
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          borderBottom: '1px solid #f0f0f0',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.03)'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              style={{
                fontSize: '20px',
                color: token.colorPrimary
              }}
            />
          )}
          {title && (
            <h2
              style={{
                margin: 0,
                fontSize: '18px',
                fontWeight: '600',
                color: '#1a1a1a'
              }}
            >
              {title}
            </h2>
          )}
        </div>
        <Button
          onClick={handleReset}
          style={{
            background: 'transparent',
            border: 'none',
            padding: '4px 8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#666',
            transition: 'all 0.2s ease',
            ':hover': {
              background: hexToRGBA(token.colorError, 0.1),
              color: token.colorError,
              borderRadius: '8px'
            }
          }}
        >
          <FontAwesomeIcon icon={faXmark} style={{ fontSize: '20px' }} />
        </Button>
      </div>
      <div
        style={{
          marginTop: '64px',
          width: '100%',
          position: 'relative',
          padding: '0 24px'
        }}
      />
    </>
  )
}

export default ModalHeader
