import Agilite from 'agilite'
import MedicalHistoryEnums from '../utils/enums'
import MedicalHistoryFormTemplates from '../utils/templates'
import store from '../../../../store'
import { message } from 'antd'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const getPatMedicalHistory = () => {
  const userRef = store.getState().auth.agiliteUser?._id
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const qry = { userRef: userRef }
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.READ,
          {
            filter: JSON.stringify(qry),
            page: null,
            pageLimit: null
          }
        )

        if (response.data.length > 0) {
          resolve(response.data[0])
        } else {
          response = await createMedicalHistory({
            userRef: store.getState().auth.agiliteUser._id,
            medicalHistory: MedicalHistoryFormTemplates.dataTemplate
          })

          resolve(response)
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createMedicalHistory = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateAllergies = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.UPDATE_ONE,
          {
            recordId,
            data: JSON.stringify({ allergies: { ...data, updatedAt: new Date() } })
          }
        )

        resolve(response.data)
        message.success('Allergies updated successfully')
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateChronicIllnesses = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.UPDATE_ONE,
          {
            recordId,
            data: JSON.stringify({ chronicIllnesses: { updatedAt: new Date(), list: data } })
          }
        )
        resolve(response.data)
        message.success('Chronic illnesses updated successfully')
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateGeneralMeds = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.UPDATE_ONE,
          {
            recordId,
            data: JSON.stringify({ meds: { updatedAt: new Date(), list: data } })
          }
        )
        resolve(response.data)
        message.success('General meds updated successfully')
      } catch (e) {
        reject(e)
      }
    })()
  })
}
export const updateFamMedHistory = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.UPDATE_ONE,
          {
            recordId,
            data: JSON.stringify({ familyHistory: { updatedAt: new Date(), list: data } })
          }
        )
        resolve(response.data)
        message.success('Family history updated successfully')
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateCovidInfo = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.UPDATE_ONE,
          {
            recordId,
            data: JSON.stringify({ covidInfo: { ...data, updatedAt: new Date() } })
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateLifestyle = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.UPDATE_ONE,
          {
            recordId,
            data: JSON.stringify({ lifestyle: { ...data, updatedAt: new Date() } })
          }
        )
        resolve(response.data)
        message.success('Lifestyle updated successfully')
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateSurgeries = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.UPDATE_ONE,
          {
            recordId,
            data: JSON.stringify({ surgicalHistory: { updatedAt: new Date(), list: data } })
          }
        )
        resolve(response.data)
        message.success('Surgical history updated successfully')
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateMentalHealth = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.UPDATE_ONE,
          {
            recordId,
            data: JSON.stringify({ mentalHealth: { updatedAt: new Date(), list: data } })
          }
        )
        resolve(response.data)
        message.success('Mental health updated successfully')
      } catch (e) {
        reject(e)
      }
    })()
  })
}
