import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getPatMedicalHistory } from './api/api'
import patMedHistReducer from './utils/reducer'
import { message } from 'antd'
import MobMedicalHistory from './Mobile/components/MobMedicalHistory'

const PatMedHist = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const initializeData = async () => {
      setLoading(true)
      try {
        const data = await getPatMedicalHistory()

        dispatch(patMedHistReducer.actions.setState(data))
      } catch (error) {
        message.error(error)
      }
      setLoading(false)
    }
    initializeData()
    // eslint-disable-next-line
  }, [])
  return <MobMedicalHistory loading={loading} />
}

export default PatMedHist
