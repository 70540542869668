import axios from 'axios'
import { AssistantEnums } from '../../../Patient/CareSync/mobile/AI CareSync/CareSync Suggest AI/assistants-enums'
import store from '../../../../store'

export const getEphemeralToken = async () => {
  try {
    const userRef = store.getState().auth.agiliteUser._id
    const entityRef = store.getState().core.entity._id

    // Request ephemeral token through our Node-RED endpoint
    const response = await axios.post(AssistantEnums.routes.GET_REALTIME_TOKEN, {
      entityRef,
      userRef
    })

    if (!response.data?.token) {
      throw new Error('No token received from server')
    }

    // Return in the format expected by WebRTC component
    return {
      client_secret: {
        value: response.data.token
      }
    }
  } catch (error) {
    console.error('Error getting ephemeral token:', error)
    throw new Error(`Failed to get token: ${error.message}`)
  }
}
