import { Button, Card, Col, Form, Input, message, Row, Select, Space, theme } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import CustomRow from '../../../../reusable-components/CustomRow'
import { generateFormItem, handleError, hexToRGBA } from '../../../../lib/utils'
import MedicalHistoryFormTemplates from '../../utils/templates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import { MedPraxProductSearch } from '../../../../Scripting/utils/utils'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash'

const MedHistAllergies = ({ clientForm }) => {
  const [nappiLoading, setNappiLoading] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [nappiData, setNappiData] = useState([])

  const { token } = theme.useToken()

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await MedPraxProductSearch(searchQuery)
        setNappiData(tmpData)
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setNappiLoading(false)
  }

  useEffect(() => {
    setNappiLoading(true)
    debouncedNappiFilter(searchFilter)

    // eslint-disable-next-line
  }, [searchFilter])

  // eslint-disable-next-line
  const debouncedNappiFilter = useCallback(
    debounce((value) => {
      getNappiData(value)
    }, 1000),
    []
  )

  return (
    <CustomRow gutter={[0, 0]}>
      <Col span={24}>
        <Card
          title='Medications'
          size='default'
          bodyStyle={{ padding: 0 }}
          headStyle={{ borderRadius: 0, background: token.colorPrimary, color: '#fff' }}
          style={{ border: 'none', borderRadius: 0 }}
        >
          <br></br>
          <div style={{ padding: 8 }}>
            {generateFormItem(MedicalHistoryFormTemplates.dataModel.allergiesMeds, clientForm)}
          </div>
          <Card
            title='Additional Medications'
            size='small'
            style={{ border: 'none', borderRadius: 0 }}
            headStyle={{ borderRadius: 0, background: hexToRGBA(token.colorPrimary, 0.1) }}
          >
            <Form.List name={['allergies', 'medsAdd']}>
              {(fields, { add, remove }) => (
                <CustomRow gutter={[8, 8]} justify='center' style={{ alignItems: 'center' }}>
                  <Col span={24}>
                    <Row wrap style={{ width: '100%' }} gutter={[0, 8]}>
                      <>
                        {fields.map(({ key, name, index, ...restField }) => (
                          <Col span={24}>
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 100px',
                                gap: 8,
                                width: '100%',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Form.Item
                                style={{ width: '100%', margin: 0 }}
                                {...restField}
                                name={[name, 'name']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing medication name...'
                                  }
                                ]}
                              >
                                <Select
                                  notFoundContent={
                                    nappiLoading ? <AgiliteSkeleton skActive spinnerTip='Search...' /> : undefined
                                  }
                                  onSearch={(searchValue) => {
                                    setSearchFilter(searchValue)
                                  }}
                                  onSelect={() => {
                                    setNappiData([])
                                  }}
                                  showSearch
                                  loading={nappiLoading}
                                  options={
                                    nappiLoading
                                      ? []
                                      : uniqBy(nappiData, 'name').map((i) => {
                                          return { value: i.name, label: i.name }
                                        })
                                  }
                                  autoFocus
                                  style={{
                                    width: '100%',
                                    border: 'none',
                                    background: 'transparent',
                                    outline: 'none'
                                  }}
                                  placeholder='e.g. Mypaid Forte'
                                  maxLength={50}
                                />
                              </Form.Item>
                              <Space>
                                <Button onClick={() => remove(name)} style={{ background: token.colorError }}>
                                  <FontAwesomeIcon icon={faTrash} color='white' />
                                </Button>
                                <Button onClick={() => add()} style={{ background: token.colorSuccess }}>
                                  <FontAwesomeIcon icon={faPlus} color='white' />
                                </Button>
                              </Space>
                            </div>
                          </Col>
                        ))}
                      </>

                      {fields.length < 1 ? (
                        <Button onClick={() => add()} style={{ background: token.colorSuccess }}>
                          <FontAwesomeIcon icon={faPlus} color='white' />
                        </Button>
                      ) : undefined}
                    </Row>
                  </Col>
                </CustomRow>
              )}
            </Form.List>
          </Card>
        </Card>{' '}
        <br></br>
        <Card
          title='Foods'
          size='default'
          bodyStyle={{ padding: 0 }}
          headStyle={{ borderRadius: 0, background: token.colorPrimary, color: '#ffffff' }}
          style={{ border: 'none', borderRadius: 0 }}
        >
          {' '}
          <br></br>
          <div style={{ padding: 8 }}>
            {generateFormItem(MedicalHistoryFormTemplates.dataModel.allergiesFood, clientForm)}
          </div>
          <Card
            title='Additional Foods'
            size='small'
            style={{ border: 'none', borderRadius: 0 }}
            headStyle={{ borderRadius: 0, background: hexToRGBA(token.colorPrimary, 0.1) }}
          >
            <Form.List name={['allergies', 'foodAdd']}>
              {(fields, { add, remove }) => (
                <CustomRow gutter={[8, 8]} justify='center' style={{ alignItems: 'center' }}>
                  <Col span={24}>
                    <Row wrap style={{ width: '100%' }} gutter={[0, 8]}>
                      <>
                        {fields.map(({ key, name, index, ...restField }) => (
                          <Col span={24}>
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 100px',
                                gap: 8,
                                width: '100%',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Form.Item
                                style={{ width: '100%', margin: 0 }}
                                {...restField}
                                name={[name, 'name']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing food name...'
                                  }
                                ]}
                              >
                                <Input
                                  autoFocus
                                  style={{
                                    width: '100%',
                                    border: 'none',
                                    background: 'transparent',
                                    outline: 'none'
                                  }}
                                  placeholder='e.g. Strawberries'
                                />
                              </Form.Item>
                              <Space>
                                <Button onClick={() => remove(name)} style={{ background: token.colorError }}>
                                  <FontAwesomeIcon icon={faTrash} color='white' />
                                </Button>
                                <Button onClick={() => add()} style={{ background: token.colorSuccess }}>
                                  <FontAwesomeIcon icon={faPlus} color='white' />
                                </Button>
                              </Space>
                            </div>
                          </Col>
                        ))}
                      </>

                      {fields.length < 1 ? (
                        <Button onClick={() => add()} style={{ background: token.colorSuccess }}>
                          <FontAwesomeIcon icon={faPlus} color='white' />
                        </Button>
                      ) : undefined}
                    </Row>
                  </Col>
                </CustomRow>
              )}
            </Form.List>
          </Card>
          <br></br>
        </Card>
        <Card
          title='General'
          size='default'
          bodyStyle={{ padding: 0 }}
          headStyle={{ borderRadius: 0, background: token.colorPrimary, color: '#fff' }}
          style={{ border: 'none', borderRadius: 0 }}
        >
          <br></br>
          <div style={{ padding: 8 }}>
            {generateFormItem(MedicalHistoryFormTemplates.dataModel.allergiesOther, clientForm)}
          </div>
          <br></br>
          <Card
            title='Additional Other'
            size='small'
            style={{ border: 'none', borderRadius: 0 }}
            headStyle={{ borderRadius: 0, background: hexToRGBA(token.colorPrimary, 0.1) }}
          >
            <Form.List name={['allergies', 'otherAdd']}>
              {(fields, { add, remove }) => (
                <CustomRow gutter={[8, 8]} justify='center' style={{ alignItems: 'center' }}>
                  <Col span={24}>
                    <Row wrap style={{ width: '100%' }} gutter={[0, 8]}>
                      <>
                        {fields.map(({ key, name, index, ...restField }) => (
                          <Col span={24}>
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 100px',
                                gap: 8,
                                width: '100%',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Form.Item
                                style={{ width: '100%', margin: 0 }}
                                {...restField}
                                name={[name, 'name']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing allergy name...'
                                  }
                                ]}
                              >
                                <Input
                                  autoFocus
                                  style={{
                                    width: '100%',
                                    border: 'none',
                                    background: 'transparent',
                                    outline: 'none'
                                  }}
                                  placeholder='e.g. wasp sting'
                                />
                              </Form.Item>
                              <Space>
                                <Button onClick={() => remove(name)} style={{ background: token.colorError }}>
                                  <FontAwesomeIcon icon={faTrash} color='white' />
                                </Button>
                                <Button onClick={() => add()} style={{ background: token.colorSuccess }}>
                                  <FontAwesomeIcon icon={faPlus} color='white' />
                                </Button>
                              </Space>
                            </div>
                          </Col>
                        ))}
                      </>

                      {fields.length < 1 ? (
                        <Button onClick={() => add()} style={{ background: token.colorSuccess }}>
                          <FontAwesomeIcon icon={faPlus} color='white' />
                        </Button>
                      ) : undefined}
                    </Row>
                  </Col>
                </CustomRow>
              )}
            </Form.List>
          </Card>
        </Card>
        <br></br>
      </Col>
    </CustomRow>
  )
}

export default MedHistAllergies
