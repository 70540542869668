import { message } from 'antd'
import { useEffect, useState } from 'react'
import { handleError } from '../../../../lib/utils'
import { isNurse } from '../../../../lib/profile-utils'
import { readClinicalNotes } from '../../../examination-utils/examination-lib'

export const useHistoricalNotes = ({ bookingData, userRef, noteState }) => {
  const [historicalNotes, setHistoricalNotes] = useState([])
  const [historicalNotesLoading, setHistoricalNotesLoading] = useState(true) // Initialize as true
  const [currentHistoricalNoteIndex, setCurrentHistoricalNoteIndex] = useState(0)

  const handleReadHistoricalNotes = async () => {
    if (!bookingData?.userRef && !userRef) {
      setHistoricalNotesLoading(false) // Make sure to set loading false if we return early
      return
    }

    setHistoricalNotesLoading(true)

    try {
      const query = { userRef: bookingData?.userRef || userRef }
      if (isNurse()) {
        query.isDoctor = false
      }

      const notes = await readClinicalNotes(query)
      const sortedNotes =
        notes
          ?.filter((note) => note._id !== noteState.noteId)
          ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) || []

      setHistoricalNotes(sortedNotes)
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setHistoricalNotesLoading(false)
    }
  }

  useEffect(() => {
    handleReadHistoricalNotes()
    // eslint-disable-next-line
  }, [bookingData?.userRef, userRef]) // Add dependencies to re-fetch when these change

  return {
    historicalNotes,
    historicalNotesLoading,
    currentHistoricalNoteIndex,
    setCurrentHistoricalNoteIndex,
    handleReadHistoricalNotes
  }
}
