import { Select, Space, Tooltip } from 'antd'
import dayjs from 'dayjs'
import store from '../../../store'
import { FilterFilled } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-solid-svg-icons'

export const CRMTableTemplates = (handleChangeStatus, filteredValues, setTeamChatProps, token) => {
  return {
    columnTemplate: [
      {
        title: 'Patient',
        key: 'patientRecord',
        dataIndex: 'patientRecord',
        render: (e) => `${e.firstName} ${e.lastName}`
      },
      {
        title: 'DOB',
        key: ['patientRecord', 'dateOfBirth'],
        dataIndex: ['patientRecord', 'dateOfBirth']
      },
      {
        title: 'Contact',
        key: 'patientRecord',
        dataIndex: 'patientRecord',
        render: (e) => (
          <Space direction='vertical'>
            <span>{e.phoneNumber ? e.phoneNumber : 'No contact'}</span>
            <span>{e.email ? e.email : 'No email'}</span>
          </Space>
        )
      },

      {
        title: 'Medical Aid',
        key: ['patientRecord', 'medicalAid'],
        dataIndex: ['patientRecord', 'medicalAid'],
        render: (e) => {
          if (e?.name) {
            return e?.name
          } else {
            return 'n/a'
          }
        }
      },
      {
        title: 'Medical Professional',
        key: 'medProf',
        dataIndex: 'professionalName'
      },
      {
        title: 'Clinic',
        key: 'clinicRef',
        dataIndex: 'clinicRef',
        render: (e) => {
          let clinicRecord = store.getState().clinics.data.find((i) => i._id === e)
          return clinicRecord ? clinicRecord.name : 'Not Found'
        }
      },
      {
        title: 'Booking Date',
        key: 'bookingDate',
        dataIndex: 'bookingDate',
        render: (e) => dayjs(e).format('DD-MM-YYYY')
      },
      {
        title: 'Status',
        dataIndex: 'crmStatus',
        key: 'crmStatus',
        filters: ['Pending', 'Completed', 'Patient Unreachable', 'Archived', 'Doctor to Contact'].map((i) => ({
          text: i,
          value: i
        })),
        filteredValue: filteredValues?.crmStatus,
        onFilter: (value, record) => {
          return record.crmStatus.indexOf(value) === 0
        },
        filterIcon: (
          <Tooltip title='Filter by Status'>
            <FilterFilled />
          </Tooltip>
        ),
        render: (value, record) => {
          return (
            <Select
              value={value}
              options={['Pending', 'Completed', 'Patient Unreachable', 'Archived', 'Doctor to Contact'].map((i) => ({
                value: i,
                Label: 9
              }))}
              onChange={(e) => {
                handleChangeStatus(record._id, e)
              }}
            />
          )
        }
      },
      {
        title: 'Actions',
        render: (value, record) => (
          <center>
            <Tooltip title='Team Chat'>
              <FontAwesomeIcon
                onClick={(e) => {
                  e.stopPropagation()
                  setTeamChatProps({ userRef: record.userRef, isOpen: true })
                }}
                icon={faMessage}
                color={token.colorSecondary}
              />
            </Tooltip>
          </center>
        )
      }
    ]
  }
}
