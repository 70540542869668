import React, { Suspense, useEffect, useState } from 'react'
import store from '../../../../store'
import coreReducer from '../../../../core/utils/reducer'
import CoreEnums from '../../../../core/utils/enums'
import ServicesForm from '../components/ServicesForm'
import { message, Spin } from 'antd'
import ServicesTemplate from '../utils/templates'
import { handleError } from '../../../lib/utils'
import servicesReducer from '../utils/reducer'
import { readServices } from '../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { uniqBy } from 'lodash'

const useServices = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [filters, setFilters] = useState({
    serviceType: []
  })
  const viewData = useSelector((state) => state.services.data)

  const refreshView = async () => {
    let data = []

    setLoading(true)

    try {
      data = await readServices()
      dispatch(servicesReducer.actions.setRecords(data))
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  useEffect(() => {
    refreshView()
    // eslint-disable-next-line
  }, [])

  const createRecord = () => {
    store.dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.SERVICES}_new`,
        closable: true,
        label: 'Add Service',
        children: (
          <Suspense fallback={<Spin />}>
            <ServicesForm data={ServicesTemplate.dataTemplate()} isNewRecord refreshView={refreshView} />
          </Suspense>
        )
      })
    )
  }

  const editRecord = (record) => {
    store.dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.SERVICES}_${record._id}`,
        closable: true,
        label: `Service: ${record.name}`,
        children: (
          <Suspense fallback={<Spin />}>
            <ServicesForm data={record} isNewRecord={false} refreshView={refreshView} />
          </Suspense>
        )
      })
    )
  }

  useEffect(() => {
    generateFilters()
    // eslint-disable-next-line
  }, [viewData])

  const generateFilters = () => {
    const tmpFilters = {
      serviceType: []
    }

    viewData.forEach((item) => {
      tmpFilters.serviceType.push({ text: item.type, value: item.type })
    })

    tmpFilters.type = uniqBy(tmpFilters.type, 'text')

    setFilters(tmpFilters)
  }

  return {
    loading,
    viewData,
    refreshView,
    createRecord,
    editRecord,
    filters
  }
}

export default useServices
