import React, { useEffect, useState } from 'react'
import CustomRow from '../../../../reusable-components/CustomRow'
import { Button, Card, Col, Input, message, Row, Space, Table, theme } from 'antd'
import { handleError, hexToRGBA, stringToColor } from '../../../../lib/utils'
import { readClinicDataPoints } from '../../../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import { readClinicalEntries } from '../../../examination-utils/examination-lib'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import dayjs from 'dayjs'
import CustomButton from '../../../../reusable-components/CustomButton'
import ExaminationDataCapture from '../Data Capturing/ExamDataCapture'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { generateReport } from '../../../../Admin/Reporting/reporting-utils'
import { useSelector } from 'react-redux'
import { handleCalculateAge } from '../../../../Bookings/utils/lib'
import { createDocument } from '../../Documents/utils/api'

const LabResults = ({ setModalOpen, userRef, dataTemplates, bookingData, bookingWebSocket }) => {
  const coreState = useSelector((state) => state.core)
  const authState = useSelector((state) => state.auth)
  const [facet, setFacet] = useState('type_selection')
  const [type, setType] = useState(null)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [captureModalOpen, setCaptureModalOpen] = useState(false)

  // Data
  const [selection, setSelection] = useState([])
  const [patientDataEntries, setPatientDataEntries] = useState([])
  const [dataPoints, setDataPoints] = useState([])

  // Loading
  const [generatingReport, setGeneratingReport] = useState(false)
  const [fetchingData, setFetchingData] = useState(false)

  useEffect(() => {
    switch (type) {
      case 0:
        handleLoadData()
        setFacet('data_selection')
        break
      case 1:
        setCaptureModalOpen(true)
        setFacet('new_capture')
        break
      default:
        setFacet('type_selection')
    }

    // eslint-disable-next-line
  }, [type])

  const handleLoadData = async () => {
    let tmpDataPoints = []
    let tmpData = []

    const tmpDataQry = {}
    const tmpDataPointQry = {}

    setFetchingData(true)
    try {
      // Fetch POC data entries
      tmpDataPointQry.hasLabReport = true
      tmpDataPoints = await readClinicDataPoints(tmpDataPointQry)
      // Fetch data points temeplates

      tmpDataQry.userRef = bookingData ? bookingData.userRef : userRef
      tmpDataQry.key = { $in: tmpDataPoints.map((i) => i.key) }

      tmpData = await readClinicalEntries(tmpDataQry)

      setPatientDataEntries(tmpData)
      setDataPoints(tmpDataPoints)
    } catch (error) {
      message.error(handleError(error))
    } finally {
      setFetchingData(false)
    }
  }
  const { token } = theme.useToken()

  const groupData = (data) => {
    let tmpData = JSON.parse(JSON.stringify(data)) // .filter((i) => i.category === categoriesFilter)

    tmpData = tmpData.reduce((acc, record) => {
      const { key } = record
      if (!acc[key]) {
        acc[key] = { key, records: [] }
      }
      acc[key].records.push(record)
      return acc
    }, {})

    tmpData = Object.values(tmpData).map((item) => item)

    tmpData.forEach((dataPoint) => {
      dataPoint.records.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
    })

    return tmpData
  }

  const getDataPointLabel = (key, isSubfield, subfieldKey) => {
    let dataPointTarget = ''

    if (isSubfield) {
      let parentField = dataPoints.find((i) => i.key === key)
      dataPointTarget = parentField.subFields.find((i) => i.key === subfieldKey)
    } else {
      dataPointTarget = dataPoints.find((i) => i.key === key)
    }

    if (!dataPointTarget) return 'Unknown Data Point'

    return dataPointTarget.label
  }

  const groupSubfields = (subFieldData, dataTemplateReference) => {
    let groupedSubfields = []
    let subfieldRecords = subFieldData.records
    dataTemplateReference.subFields.forEach((subFieldEntry) => {
      let tmpData = []
      subfieldRecords.forEach((subfieldRecord) => {
        let targetDataPoint = subfieldRecord.value.find((i) => i.key === subFieldEntry.key)
        tmpData.push({
          ...subfieldRecord,
          value: targetDataPoint.value
        })
      })
      groupedSubfields.push({
        key: subFieldEntry.key,
        records: tmpData
      })
    })

    return groupedSubfields
  }

  const customRowClick = (row_props) => {
    const keyIndex = expandedRowKeys.indexOf(row_props.key)
    const newExpandedRowKeys = [...expandedRowKeys]
    if (keyIndex === -1) {
      newExpandedRowKeys.push(row_props.key)
    } else {
      newExpandedRowKeys.splice(keyIndex, 1)
    }
    setExpandedRowKeys(newExpandedRowKeys)
  }

  const handleNestedDataDisplay = (subFieldData, subFieldDataPointReference) => {
    if (subFieldData.records[0]?.value !== null && Array.isArray(subFieldData.records[0]?.value)) {
      return (
        <Space>
          <span>{subFieldData.records[0]?.value.join(', ')}</span>
          <span style={{ opacity: 0.5 }}>{subFieldDataPointReference?.suffix}</span>
        </Space>
      )
    }
    return (
      <Space>
        <span>{subFieldData.records[0]?.value}</span>
        <span style={{ opacity: 0.5 }}>{subFieldDataPointReference?.suffix}</span>
      </Space>
    )
  }

  const handleGenerateReport = async (tmpData, keys) => {
    let tmpDataPoints = []
    let tmpDataEntries = []
    const payload = []

    setGeneratingReport(true)

    const dataPointQuery = {
      key: { $in: keys }
    }
    try {
      const sectionMargin = '0 0 40px 0'
      const h2Margin = '12px 0px'
      const h3Margin = '8px 0px'
      const pMargin = '8px 0px'
      const tableBorder = '1px solid #ddd'

      const generateHTMLContent = (data) => {
        let htmlContent = `<style>.test-section{margin:${sectionMargin};}.data-table{width:100%;border-collapse:collapse;margin-bottom:20px;}.data-table th,.data-table td{border:${tableBorder};padding:8px;}h2{margin: ${h2Margin}; font-weight: bold}h3{margin:${h3Margin}}p{margin:${pMargin}}</style>`

        data.forEach((testItem) => {
          htmlContent += `<div class="test-section"><h2 style="padding: 8px; background: ${hexToRGBA(
            testItem.color,
            0.4
          )}">${testItem.label}</h2><p><strong>Current Result:</strong> ${testItem.current.value} ${
            testItem.suffix
          }</p>`

          // Historical Readings
          if (testItem.entries && testItem.entries.length > 0) {
            htmlContent += `<h3>Historical Readings</h3><table class="data-table"><tr><th style="background-color:${hexToRGBA(
              testItem.color,
              0.2
            )};text-align:left;">Date</th><th style="background-color:${hexToRGBA(
              testItem.color,
              0.2
            )};text-align:left;">Value</th></tr>`
            testItem.entries.forEach((entry) => {
              // Format the date to a readable format
              const date = new Date(entry.dateCreated)
              const formattedDate = date.toLocaleString('en-US', { timeZone: 'UTC' })
              htmlContent += `<tr><td>${formattedDate}</td><td>${entry.value} ${testItem.suffix}</td></tr>`
            })
            htmlContent += '</table>'
          } else {
            htmlContent += '<p>No historical readings available.</p>'
          }

          // Reference Range Data
          if (testItem.referenceRangeData && testItem.referenceRangeData.length > 0) {
            htmlContent += `<h3>Reference Range</h3><table class="data-table"><tr><th style="background-color:${hexToRGBA(
              testItem.color,
              0.2
            )};text-align:left;">Range</th><th style="background-color:${hexToRGBA(
              testItem.color,
              0.2
            )};text-align:left;">Value</th></tr>`
            testItem.referenceRangeData.forEach((refRange) => {
              htmlContent += `<tr><td>${refRange.label}</td><td style="">${refRange.value}</td></tr>`
            })
            htmlContent += '</table>'
          } else {
            htmlContent += '<p>No reference range data available.</p>'
          }

          htmlContent += '</div>'
        })
        return htmlContent
      }

      tmpDataEntries = await readClinicalEntries(dataPointQuery)
      tmpDataPoints = await readClinicDataPoints(dataPointQuery)

      for (const entry of tmpDataPoints) {
        const tmpEntries = tmpDataEntries.filter((i) => i.key === entry.key)
        const current = tmpEntries[tmpEntries.length - 1]
        tmpEntries.splice(tmpEntries.length - 1, 1)
        payload.push({
          label: entry.label,
          entries: tmpEntries,
          referenceRangeData: JSON.parse(entry.data).referenceRangeData,
          current,
          suffix: entry.suffix,
          color: stringToColor(entry.key)
        })
      }

      const patientProfile = bookingData.patientRecord
      const patientName = `${patientProfile.firstName} ${patientProfile.lastName}`
      const id = patientProfile.idNo
      const dob = dayjs(patientProfile.dateOfBirth).format('DD MMM YYYY')
      const age = handleCalculateAge(bookingData)
      const sex = patientProfile.gender
      const performed = dayjs().format('DD MMM YYYY')
      const phone = patientProfile.phoneNumber

      const doctorsName = `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`

      const clinicDetails = bookingData.clinicRecord
      const clinicName = clinicDetails.name
      const clinicAddress = clinicDetails.address
      const clinicEmail = clinicDetails.email
      const clinicPhone = clinicDetails.phoneNumber
      const practiceName = clinicDetails.practiceName
      const regNo = clinicDetails.practiceRegNumber
      const practiceNo = clinicDetails.practiceNumber
      const address = clinicDetails.address
      const clinicLogo = clinicDetails.clinicLogo?.split(',')[1] || coreState.entity?.entityLogo?.split(',')[1]

      await generateReport(
        bookingData,
        {
          HTMLCONTENT: generateHTMLContent(payload),
          clinicName,
          clinicEmail,
          patientName,
          dob,
          doctorsName,
          id,
          age,
          performed,
          phone,
          sex,
          address,
          clinicAddress,
          clinicPhone,
          practiceName,
          regNo,
          practiceNo,
          clinicLogo
        },
        '6a777b2346a41108',
        '+27817141452'
      )

      handleReset()
      setModalOpen(false)
    } catch (error) {
      message.error(handleError(error))
    }
    setGeneratingReport(false)
  }

  const handleReset = () => {
    setType(null)
    setDataPoints([])
    setPatientDataEntries([])
    setSelection([])
  }

  if (generatingReport) {
    return (
      <CustomRow>
        <Col span={24}>
          <AgiliteSkeleton skActive spinnerTip='Generating Report...' />
        </Col>
      </CustomRow>
    )
  } else {
    switch (facet) {
      case 'type_selection':
        return (
          <>
            <div
              style={{
                width: '100%',
                padding: 8,
                background: hexToRGBA(token.colorSecondary, 0.2),
                position: 'absolute',
                top: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                left: 0
              }}
            >
              <h1>LAB REPORT</h1>
              <Button
                onClick={() => {
                  handleReset()
                  setModalOpen(false)
                }}
                style={{ float: 'right', fontSize: 24, background: 'transparent', border: 'none' }}
                disabled={type !== null}
              >
                <FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} />
              </Button>
            </div>

            <CustomRow style={{ marginTop: 48 }}>
              <Col span={24}>How would you like to generate this report?</Col>
              <Col span={12}>
                <Card
                  size='small'
                  title={<h1>From Existing</h1>}
                  class='custom-hover'
                  bodyStyle={{ paddingBottom: 50 }}
                >
                  <p>Generate from historical data captured, all data will be date stamped for reference</p>
                  <Button
                    onClick={() => {
                      setType(0)
                    }}
                    type='primary'
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 0,
                      borderBottom: 'none',
                      borderLeft: 'none',
                      borderRight: 'none'
                    }}
                  >
                    Select
                  </Button>
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  size='small'
                  title={<h1>New Capture</h1>}
                  style={{ minHeight: '100%' }}
                  bodyStyle={{ paddingBottom: 50 }}
                >
                  <p> Capture new data and generate report from the newly captured data</p>
                  <Button
                    onClick={() => {
                      setType(1)
                    }}
                    type='primary'
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 0,
                      borderBottom: 'none',
                      borderLeft: 'none',
                      borderRight: 'none'
                    }}
                  >
                    Select
                  </Button>
                </Card>
              </Col>
            </CustomRow>
          </>
        )
      case 'new_capture':
        return (
          <ExaminationDataCapture
            hasLabReport
            socket={null}
            modalOpen={captureModalOpen}
            setModalOpen={setCaptureModalOpen}
            dataTemplates={dataTemplates}
            bookingWebSocket={bookingWebSocket}
            bookingData={bookingData}
            userRef={userRef}
            onAfterSuccess={(tmpData) => {
              const keys = tmpData.map((i) => i.key)
              handleGenerateReport(tmpData, keys)
            }}
            onAfterCancel={() => {
              setCaptureModalOpen(false)
              setType(null)
            }}
          />
        )
      case 'data_selection':
        return (
          <div>
            <Col span={24}>
              <Card
                headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
                size='small'
                title={
                  <Space>
                    Search by label:
                    <Input placeholder='Search..' style={{ width: 350 }} />
                  </Space>
                }
              >
                <Row gutter={[0, 12]}>
                  <Col span={24}>Please select the readings you want to include on your report.</Col>
                  <Col style={{ maxHeight: '60vh', overflow: 'scroll' }} span={24}>
                    {fetchingData ? (
                      <AgiliteSkeleton skActive spinnerTip='Loading...' />
                    ) : (
                      <Table
                        rowSelection={{
                          fixed: 'right',
                          columnTitle: 'Include?',
                          onSelect: (row) => {
                            let tmpSelection = [...selection]
                            let selectIndex = tmpSelection.findIndex((i) => i === row.key)
                            if (selectIndex === -1) {
                              tmpSelection.push(row.key)
                            } else {
                              tmpSelection.splice(selectIndex, 1)
                            }
                            setSelection(tmpSelection)
                          },
                          selectedRowKeys: selection,
                          hideSelectAll: true
                        }}
                        onRow={(row_props) => {
                          return {
                            onClick: () => {
                              customRowClick(row_props)
                            }
                          }
                        }}
                        dataSource={groupData(patientDataEntries)}
                        columns={[
                          {
                            title: 'Clinical Reading',
                            render: (data) => {
                              return getDataPointLabel(data.key)
                            }
                          },
                          {
                            title: 'Value',
                            render: (data) => {
                              const dataPointReference = dataPoints.find((i) => data.key === i.key)
                              if (dataPointReference.nestedSubFields) return 'Drop down to see historical entries.'
                              if (data.records[0]?.value !== null && Array.isArray(data.records[0]?.value)) {
                                return (
                                  <Space>
                                    <span>
                                      {data.records[0]?.value.map((i) => i.value).join(dataPointReference.delimiter)}
                                    </span>
                                    <span style={{ opacity: 0.5 }}>{dataPointReference?.suffix}</span>
                                  </Space>
                                )
                              } else {
                                return (
                                  <Space>
                                    <span>{data.records[0]?.value}</span>
                                    <span style={{ opacity: 0.5 }}>{dataPointReference?.suffix}</span>
                                  </Space>
                                )
                              }
                            }
                          },
                          {
                            title: 'Most recent test date',
                            render: (data) => {
                              return dayjs(data.records[0]?.dateCreated).format('DD MMM YYYY - HH:mm')
                            }
                          }
                        ]}
                        pagination={false}
                        size='small'
                        bordered={true}
                        expandable={{
                          columnTitle: 'Expand',
                          expandRowByClick: true,
                          expandedRowRender: (data) => {
                            const dataTemplateReference = dataPoints.find((i) => data.key === i.key)

                            if (dataTemplateReference.nestedSubFields) {
                              return (
                                <Table
                                  onRow={(row_props) => {
                                    return {
                                      onClick: (e) => {
                                        customRowClick(row_props)
                                      }
                                    }
                                  }}
                                  size='small'
                                  dataSource={groupSubfields(data, dataTemplateReference)}
                                  columns={[
                                    {
                                      title: 'Data Point',
                                      render: (subFieldData) => {
                                        return getDataPointLabel(data.key, true, subFieldData.key)
                                      }
                                    },
                                    {
                                      title: 'Reading',
                                      render: (subFieldData) => {
                                        const subFieldDataPointReference = dataTemplateReference.subFields.find(
                                          (i) => subFieldData.key === i.key
                                        )
                                        return handleNestedDataDisplay(subFieldData, subFieldDataPointReference)
                                      }
                                    },
                                    {
                                      title: 'Date',
                                      render: (subFieldData) => {
                                        return dayjs(subFieldData.records[0]?.dateCreated).format('DD MMM YYYY - HH:mm')
                                      }
                                    }
                                  ]}
                                  pagination={false}
                                  bordered={true}
                                  expandable={{
                                    expandRowByClick: true,
                                    expandedRowRender: (subFieldData) => {
                                      return (
                                        <Table
                                          size='small'
                                          dataSource={subFieldData.records}
                                          columns={[
                                            {
                                              title: 'Reading',
                                              render: (readingData) => {
                                                const subFieldDataPointReference = dataTemplateReference.subFields.find(
                                                  (i) => subFieldData.key === i.key
                                                )
                                                if (readingData.value !== null && Array.isArray(readingData.value)) {
                                                  return (
                                                    <Space>
                                                      <span>{readingData.value.join('/')}</span>
                                                      <span style={{ opacity: 0.5 }}>
                                                        {subFieldDataPointReference?.suffix}
                                                      </span>
                                                    </Space>
                                                  )
                                                } else {
                                                  return (
                                                    <Space>
                                                      <span>{readingData.value}</span>
                                                      <span style={{ opacity: 0.5 }}>
                                                        {subFieldDataPointReference?.suffix}
                                                      </span>
                                                    </Space>
                                                  )
                                                }
                                              }
                                            },
                                            {
                                              title: 'Date',
                                              render: (subFieldData) => {
                                                return dayjs(subFieldData.dateCreated).format('DD MMM YYYY - HH:mm')
                                              }
                                            }
                                          ]}
                                          pagination={false}
                                          bordered={true}
                                        />
                                      )
                                    }
                                  }}
                                />
                              )
                            }
                            return (
                              <Table
                                size='small'
                                dataSource={data.records}
                                columns={[
                                  {
                                    title: 'Reading',
                                    render: (readingData) => {
                                      const dataPointReference = dataPoints.find((i) => data.key === i.key)
                                      if (readingData.value !== null && Array.isArray(readingData.value)) {
                                        return (
                                          <Space>
                                            <span>{readingData.value.map((i) => i.value).join('/')}</span>
                                            <span style={{ opacity: 0.5 }}>{dataPointReference?.suffix}</span>
                                          </Space>
                                        )
                                      } else {
                                        return (
                                          <Space>
                                            <span>{readingData.value}</span>
                                            <span style={{ opacity: 0.5 }}>{dataPointReference?.suffix}</span>
                                          </Space>
                                        )
                                      }
                                    }
                                  },
                                  {
                                    title: 'Date',
                                    render: (data) => {
                                      return dayjs(data.dateCreated).format('DD MMM YYYY - HH:mm')
                                    }
                                  }
                                ]}
                                pagination={false}
                                bordered={true}
                              />
                            )
                          }
                        }}
                      />
                    )}
                  </Col>
                  <Col span={24}>
                    <Space style={{ float: 'right' }}>
                      <CustomButton
                        size={'small'}
                        type='standard'
                        text='Cancel'
                        onClick={() => {
                          handleReset(null)
                        }}
                      />
                      <CustomButton
                        size={'small'}
                        type='success'
                        text='Confirm Selection and Generate Report'
                        onClick={() => {
                          handleGenerateReport([], selection)
                        }}
                      />
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>
          </div>
        )
      default:
        return <div>Oops somethign went wrong please click to restart</div>
    }
  }
}

export default LabResults
