import React from 'react'
import { Typography, Tag, Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faClock, 
  faVial,
  faCertificate,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { specimenTypes } from '../../../../../mockdata/diagnosticTestsData'
import '../styling/QuickInfo.css'

const { Text } = Typography

const QuickInfo = ({ test, onShowSpecimenModal, formatPrice }) => {
  return (
    <div className="test-detail-quick-info">
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className="test-detail-info-group">
            <div className="test-detail-section-label">
              <Text>Certifications</Text>
            </div>
            <div className="test-detail-certifications">
              <div className="test-detail-cert">
                <FontAwesomeIcon icon={faCertificate} />
                <span>SAHPRA</span>
              </div>
              {test.certification === 'CE' && (
                <div className="test-detail-cert">
                  <FontAwesomeIcon icon={faCertificate} />
                  <span>CE</span>
                </div>
              )}
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="test-detail-info-group">
            <div className="test-detail-section-label">
              <Text>
                Specimen Type
                <FontAwesomeIcon 
                  icon={faInfoCircle} 
                  className="info-icon"
                  onClick={onShowSpecimenModal}
                />
              </Text>
            </div>
            <div className="test-detail-info-item">
              <FontAwesomeIcon icon={faVial} />
              <div className="test-detail-specimen-tags">
                {test.specimen.split('/').map(code => (
                  <Tag key={code} className="test-detail-specimen-tag" title={specimenTypes[code]}>
                    {code}
                  </Tag>
                ))}
              </div>
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="test-detail-info-group">
            <div className="test-detail-section-label">
              <Text>Processing Time</Text>
            </div>
            <div className="test-detail-info-item">
              <FontAwesomeIcon icon={faClock} />
              <Text>{test.cycleTime}</Text>
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="test-detail-info-group">
            <div className="test-detail-section-label">
              <Text>Price</Text>
            </div>
            <div className="test-detail-info-item">
              <Text className="test-detail-price">{formatPrice(test.price)}</Text>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default QuickInfo 