export const SAOfficialLanguages = [
  'Afrikaans',
  'English',
  'IsiNdebele',
  'IsiXhosa',
  'IsiZulu',
  'Sepedi',
  'Sesotho',
  'Setswana',
  'SiSwati',
  'Tshivenda',
  'Xitsonga'
]

export const specialistTypes = [
  'Allergist',
  'Anesthesiologist',
  'Cardiologist',
  'Dermatologist',
  'Endocrinologist',
  'Gastroenterologist',
  'Hematologist',
  'Infectious Disease Specialist',
  'Internist',
  'Nephrologist',
  'Neurologist',
  'Obstetrician/Gynecologist',
  'Oncologist',
  'Ophthalmologist',
  'Orthopedic Surgeon',
  'Otolaryngologist (ENT)',
  'Pediatrician',
  'Plastic Surgeon',
  'Psychiatrist',
  'Pulmonologist',
  'Radiologist',
  'Rheumatologist',
  'Surgeon',
  'Urologist',
  'Geriatrician',
  'General Practitioner',
  'Family Medicine Physician',
  'Pathologist',
  'Chiropractor',
  'Podiatrist',
  'Emergency Medicine Specialist',
  'Sports Medicine Specialist',
  'Occupational Medicine Specialist',
  'Rehabilitation Specialist',
  'Pain Management Specialist',
  'Sleep Medicine Specialist',
  'Hospitalist',
  'Nuclear Medicine Specialist',
  'Geneticist',
  'Neurosurgeon',
  'Thoracic Surgeon',
  'Vascular Surgeon',
  'Colorectal Surgeon',
  'Bariatric Surgeon',
  'Maxillofacial Surgeon',
  'Transplant Surgeon',
  'Trauma Surgeon',
  'Hand Surgeon',
  'Hepatologist',
  'Immunologist',
  'Medical Geneticist',
  'Perinatologist',
  'Phlebologist',
  'Proctologist',
  'Reproductive Endocrinologist',
  'Spine Surgeon',
  'Toxicologist',
  'Critical Care Medicine Specialist',
  'Clinical Pharmacologist',
  'Adolescent Medicine Specialist',
  'Geriatric Psychiatrist',
  'Forensic Psychiatrist',
  'Child and Adolescent Psychiatrist',
  'Consultation-Liaison Psychiatrist',
  'Addiction Psychiatrist',
  'Pediatric Surgeon',
  'Neonatologist',
  'Pediatric Cardiologist',
  'Pediatric Endocrinologist',
  'Pediatric Gastroenterologist',
  'Pediatric Hematologist/Oncologist',
  'Pediatric Infectious Disease Specialist',
  'Pediatric Nephrologist',
  'Pediatric Neurologist',
  'Pediatric Pulmonologist',
  'Pediatric Rheumatologist',
  'Pediatric Urologist',
  'Pediatric Otolaryngologist',
  'Pediatric Dermatologist',
  'Pediatric Emergency Medicine Specialist',
  'Pediatric Geneticist',
  'Pediatric Ophthalmologist',
  'Pediatric Orthopedic Surgeon',
  'Pediatric Radiologist',
  'Pediatric Rehabilitation Specialist',
  'Pediatric Sleep Medicine Specialist'
]
