import React, { useEffect, useCallback } from 'react'
import { Layout, Space, message } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { batch, useDispatch, useSelector } from 'react-redux'
import { isSignInWithEmailLink, onAuthStateChanged, signInWithEmailLink } from 'firebase/auth'
import { useNavigate, useLocation } from 'react-router-dom'
import { BrowserView, MobileView, deviceDetect } from 'react-device-detect'

import AuthEnums from '../../custom/Auth/utils/enums'
import authReducer from '../../custom/Auth/utils/reducer'
import { firebaseAuth } from '../..'
import { findAgiliteUser, updateAgiliteUser } from '../../custom/Auth/utils/utils'
import coreReducer from '../utils/reducer'

// Components
import MetaClinicDashboard from '../../custom/Dashboard/meta-clinic-dashboard'
import EmailSignUpForm from '../../custom/Auth/components/FormComponents/emailSignUpForm'
import { readServices } from '../../custom/Admin/services/utils/utils'
import { readClinics } from '../../custom/Admin/clinics/utils/utils'
import clinicsCRUDReducer from '../../custom/Admin/clinics/utils/reducer'
import { readConfig } from '../../custom/Admin/config/utils/utils'
import { readTerms } from '../../custom/Admin/terms-conditions/utils/utils'
import servicesReducer from '../../custom/Admin/services/utils/reducer'
import { readProfessions, readRoles } from '../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import Dependants from '../../custom/Profile/components/dependants/dependants-list-view'
import CoreEnums from '../utils/enums'
import { handleError } from '../../custom/lib/utils'
import { readVirtualServices } from '../../custom/Admin/virutal-services/utils/utils'
import virtualServicesReducer from '../../custom/Admin/virutal-services/utils/reducer'
import { setPatient } from '../../custom/Medical Vault/medical-history/utils/medical-history-reducer'
import { getPatient } from '../../custom/Medical Vault/medical-history/utils/utils'
import { getSubscriptions } from '../../custom/Subscriptions/utils/utils'
import SubscriptionsReducer from '../../custom/Subscriptions/utils/reducer'
import MetaIconLogo from '../../assets/images/landing-image.png'
import { readSystemUsers } from '../../custom/Admin/system-users/utils/utils'
import DependentsReducer from '../../custom/Profile/components/dependants/utils/depentdents-reducer'

import './toolsbar.css'

// JWT
import { isExpired, decodeToken } from 'react-jwt'
import { MenuOutlined } from '@ant-design/icons'
import { isPatient } from '../../custom/lib/profile-utils'

const Toolbar = (props) => {
  const coreState = useSelector((state) => state.core)
  const authState = useSelector((state) => state.auth)
  const appTitle = `${useSelector((state) => state.core.title)} Portal`.toUpperCase()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const handleDependencies = useCallback(
    async (agiliteUser) => {
      try {
        const [
          rolesResponse,
          professionsResponse,
          servicesResponse,
          virtualServicesResponse,
          clinicsResponse,
          termsResponse,
          configResponse,
          medicalHistoryResponse,
          subscriptionsResponse,
          dependantsResponse
        ] = await Promise.all([
          readRoles(),
          readProfessions(),
          readServices({ isActive: true }),
          readVirtualServices({ isActive: true }),
          readClinics({ isActive: true }),
          readTerms(),
          readConfig(),
          getPatient(),
          agiliteUser && agiliteUser._id ? getSubscriptions({ userRef: agiliteUser._id }) : undefined,
          agiliteUser && agiliteUser._id ? readSystemUsers({ mainMemberId: agiliteUser._id }) : undefined
        ])

        batch(() => {
          dispatch(authReducer.actions.setRoles(rolesResponse))
          dispatch(authReducer.actions.setProfessions(professionsResponse))
          dispatch(servicesReducer.actions.setRecords(servicesResponse))
          dispatch(virtualServicesReducer.actions.setRecords(virtualServicesResponse))
          dispatch(clinicsCRUDReducer.actions.setRecords(clinicsResponse))
          dispatch(authReducer.actions.setTerms(termsResponse))
          dispatch(setPatient(medicalHistoryResponse))
          dispatch(SubscriptionsReducer.actions.setSubscriptions(subscriptionsResponse))
          dispatch(DependentsReducer.actions.setRecords(dependantsResponse))
        })

        if (configResponse.length > 0) {
          const config = configResponse[0]
          dispatch(authReducer.actions.setConfig(config))
        } else {
          message.warning('No Configurations found')
        }
      } catch (e) {
        message.error(handleError(e, true))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    async function init() {
      const entity = coreState.entity
      let agiliteUser = null
      let email = null
      let providerId = null

      try {
        if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
          email =
            window.localStorage.getItem(AuthEnums.localStorage.EMAIL_FOR_SIGN_IN) ||
            window.prompt('Please provide your email for confirmation')
          await signInWithEmailLink(firebaseAuth, email, window.location.href)
          window.localStorage.removeItem(AuthEnums.localStorage.EMAIL_FOR_SIGN_IN)
          const pathArray = window.location.pathname.split('/').filter((p) => p !== '') || []
          navigate(`/${pathArray[0]}${AuthEnums.routes.ROOT}`)
        }

        onAuthStateChanged(
          firebaseAuth,
          async (user) => {
            try {
              if (!['/checkin', '/payment-status'].includes(window.location.pathname)) {
                dispatch(authReducer.actions.setLoading(true))

                if (user) {
                  batch(() => {
                    dispatch(authReducer.actions.setUser(user))
                    dispatch(authReducer.actions.setLoggedIn(true))
                  })

                  providerId = firebaseAuth.currentUser.providerData[0].providerId

                  if (providerId === AuthEnums.signInTypes.GOOGLE || providerId === AuthEnums.signInTypes.PASSWORD) {
                    agiliteUser = user.phoneNumber
                      ? await findAgiliteUser({ phoneNumber: user.phoneNumber })
                      : await findAgiliteUser({ email: user.email })

                    if (agiliteUser) {
                      if (
                        entity &&
                        agiliteUser.extraData.role.type !== 'patient' &&
                        agiliteUser.extraData.role.type !== 'super_admin' &&
                        !agiliteUser.extraData.entities.includes(entity._id)
                      ) {
                        dispatch(authReducer.actions.setUser(null))
                        dispatch(authReducer.actions.setLoggedIn(false))
                        throw new Error(CoreEnums.errorMessages.ENTITY_NOT_AUTH)
                      }

                      if (!agiliteUser.extraData?.isActive) {
                        throw new Error('Account Disabled')
                      }
                    }

                    if (!agiliteUser || !agiliteUser.idNo) {
                      dispatch(authReducer.actions.setCompleteEmailSignUp(true))
                      if (agiliteUser) {
                        dispatch(authReducer.actions.setAgiliteUser(agiliteUser))
                      }
                    } else {
                      dispatch(authReducer.actions.setAgiliteUser(agiliteUser))
                    }
                  } else {
                    if (user.phoneNumber) {
                      agiliteUser = await findAgiliteUser({ phoneNumber: user.phoneNumber })
                    }

                    if (agiliteUser) {
                      if (!agiliteUser.extraData?.isActive) {
                        throw new Error('Account Disabled')
                      }
                      dispatch(authReducer.actions.setAgiliteUser(agiliteUser))
                    }
                  }

                  if (agiliteUser && !agiliteUser.extraData.inviteStatus) {
                    dispatch(authReducer.actions.setCompleteEmailSignUp(true))
                  }

                  batch(() => {
                    dispatch(authReducer.actions.setLoading(false))

                    if (agiliteUser) {
                      if (
                        agiliteUser.extraData.role.type === 'admin' ||
                        agiliteUser.extraData.role.type === 'reception'
                      ) {
                        if (agiliteUser.extraData.role.type === 'admin') {
                          dispatch(coreReducer.actions.enableDisableRightMenu(true))
                        }

                        if (deviceDetect().isBrowser) {
                          dispatch(coreReducer.actions.hideTabs(false))
                        } else {
                          dispatch(coreReducer.actions.hideTabs(true))
                        }
                      } else {
                        if (agiliteUser.extraData.role.type !== 'medical_professional') {
                          dispatch(coreReducer.actions.enableDisableLeftMenu(true))
                          dispatch(coreReducer.actions.hideTabs(true))
                        }
                      }

                      if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
                        dispatch(coreReducer.actions.enableDisableRightMenu(true))
                      }
                    }

                    handleDependencies(agiliteUser)

                    dispatch(coreReducer.actions.enableDisableTabs(true))
                    dispatch(coreReducer.actions.setRootContent(MetaClinicDashboard))
                  })

                  if (agiliteUser && agiliteUser.extraData.role.type === 'patient') {
                    if (!agiliteUser.extraData.entities.includes(entity._id)) {
                      await updateAgiliteUser(agiliteUser._id, {
                        ...agiliteUser,
                        extraData: {
                          ...agiliteUser.extraData,
                          entities: [...agiliteUser.extraData.entities, entity._id]
                        }
                      })
                    }
                  }
                } else {
                  dispatch(authReducer.actions.setLoading(false))
                  dispatch(authReducer.actions.setUser(null))
                  dispatch(authReducer.actions.setLoggedIn(false))
                }
              }
            } catch (e) {
              message.error(handleError(e, true))
              dispatch(authReducer.actions.setLoading(false))
            }
          },
          (error) => {
            console.error(error)
          }
        )
      } catch (e) {
        message.error(handleError(e, true))
        dispatch(authReducer.actions.setLoading(false))
      }
    }

    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    let decodedToken = null
    let isExpiredToken = null

    if (token) {
      decodedToken = decodeToken(token)
      isExpiredToken = isExpired(token)
    }

    if (coreState.entity) {
      if (!isExpiredToken && decodedToken && decodedToken?.userRef) {
        handleAutoSignIn(decodedToken.userRef)
        dispatch(coreReducer.actions.setSignOutDisabled(true))
      } else {
        init()
      }
    }

    // eslint-disable-next-line
  }, [coreState.entity])

  const handleAutoSignIn = async (userRef) => {
    dispatch(authReducer.actions.setLoading(true))
    try {
      const agiliteUser = await findAgiliteUser({ _id: userRef })

      if (agiliteUser) {
        dispatch(authReducer.actions.setLoggedIn(true))
        dispatch(authReducer.actions.setUser(agiliteUser))
        dispatch(authReducer.actions.setAgiliteUser(agiliteUser))
        dispatch(coreReducer.actions.enableDisableTabs(true))
        dispatch(coreReducer.actions.enableDisableLeftMenu(true))
        dispatch(coreReducer.actions.hideTabs(true))
        dispatch(coreReducer.actions.setRootContent(MetaClinicDashboard))
        handleDependencies(agiliteUser)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    dispatch(authReducer.actions.setLoading(false))
  }

  const handleOpenFamilyAccounts = useCallback(() => {
    dispatch(
      coreReducer.actions.addTab({
        key: CoreEnums.tabKeys.DEPENDANTS,
        closable: true,
        label: CoreEnums.tabTitles.DEPENDANTS,
        children: <Dependants />
      })
    )
  }, [dispatch])

  const getActiveTabTitle = useCallback(() => {
    const tabKeysEnum = CoreEnums.tabKeys
    const titleEnum = CoreEnums.tabTitles

    const key =
      Object.keys(tabKeysEnum).find((k) => tabKeysEnum[k] === coreState.tabNavigation?.activeKey) ||
      Object.keys(tabKeysEnum).find((k) => tabKeysEnum[k] === coreState.tabNavigation?.activeKey.split('_')[0])

    return key ? titleEnum[key] : 'Title Not Found'
  }, [coreState.tabNavigation])

  const getMenuColor = () => {
    const tabKeysEnum = CoreEnums.tabKeys
    const menuColorsEnum = CoreEnums.menuColors

    const key = Object.keys(tabKeysEnum).find(
      (k) =>
        tabKeysEnum[k] === coreState.tabNavigation?.activeKey ||
        tabKeysEnum[k] === coreState.tabNavigation?.activeKey?.split('_')[0]
    )

    return key ? menuColorsEnum[key] : menuColorsEnum.DEFAULT
  }

  return (
    <Layout
      style={
        authState.loggedIn
          ? { margin: 0, position: 'sticky', top: 0, zIndex: 10 }
          : { margin: 0, display: 'none', position: 'sticky', top: 0, zIndex: 10 }
      }
    >
      <Header
        style={{
          background:
            deviceDetect().isMobile && authState?.agiliteUser?.extraData?.role?.type === 'patient' ? 'white' : '',
          fontSize: '13pt',
          color: 'white',
          paddingLeft: 20,
          paddingRight: 20,
          boxShadow: deviceDetect().isMobile ? '0 2px 8px rgba(0,0,0,0.15)' : 'none'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>
            <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
              <MobileView>
                <img src={MetaIconLogo} style={{ height: 30, display: 'block' }} alt='Meta-Health Logo' />
              </MobileView>
              {props.leftMenuEnabled && !deviceDetect().isBrowser && !isPatient() ? (
                <MenuOutlined
                  style={{ float: 'left', cursor: 'pointer', fontSize: 24 }}
                  onClick={props.onLeftMenuOpen}
                />
              ) : null}
              <BrowserView>
                <div
                  style={{
                    width: coreState.sideBarExpanded ? 275 : 80,
                    transition: '300ms ease',
                    height: '100%',
                    top: 0,
                    left: 0,
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: coreState.sideBarExpanded ? 8 : 0,
                    boxSizing: 'content-box',
                    background: '#2A3649'
                  }}
                >
                  {coreState.sideBarExpanded ? (
                    <center>
                      <b
                        style={{
                          margin: 0,
                          height: '100%',
                          width: '100%'
                        }}
                      >
                        {authState?.agiliteUser?.extraData?.role.type === 'super_admin' ? 'SUPER ADMIN' : appTitle}
                      </b>
                    </center>
                  ) : (
                    <img
                      src={coreState?.entity?.entityIcon}
                      style={{ width: 40, display: 'block' }}
                      alt='Meta-Health Logo'
                    />
                  )}
                </div>
              </BrowserView>
              <MobileView>
                <b
                  className='TITLE_OVERIDE'
                  style={{
                    background: `linear-gradient(90deg,  ${'#333333'}, ${getMenuColor()})`
                  }}
                >
                  {getActiveTabTitle().toUpperCase()}
                </b>
              </MobileView>
            </div>
            <BrowserView>
              <b
                className='TITLE_OVERIDE'
                style={{
                  left: coreState.sideBarExpanded ? 275 : 80,
                  background: `linear-gradient(90deg,  ${'#ffffff'}, ${getMenuColor()})`
                }}
              >
                {getActiveTabTitle().toUpperCase()}
              </b>
            </BrowserView>
          </div>
          <div>
            {location.pathname !== '/checkin' || authState.loggedIn ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                {!deviceDetect().isMobile && authState?.agiliteUser?.extraData.role.type === 'patient' ? (
                  <Space style={{ cursor: 'pointer', alignItems: 'center' }} onClick={handleOpenFamilyAccounts}>
                    <FontAwesomeIcon icon={faUsers} /> Family Accounts
                  </Space>
                ) : null}
                {props.customMenus.content}
              </div>
            ) : null}
          </div>
        </div>
      </Header>
      <EmailSignUpForm />
    </Layout>
  )
}

export default Toolbar
