import React from 'react'
import { Modal, Card, Row, Col, Space, Switch, Radio, Select, theme } from 'antd'

const DischemModal = ({
  open,
  onCancel,
  dischemDeliveryApplicable,
  setDischemDeliveryApplicable,
  dischemType,
  setDischemType,
  dischemPaymentType,
  setDischemPaymentType,
  dischems,
  selectedDischem,
  setSelectedDischem
}) => {
  const { token } = theme.useToken()

  return (
    <Modal
      width={650}
      title={
        <div style={{ padding: 8, background: 'linear-gradient(45deg, #00A049,  #F3EC1C)' }}>
          <h1 style={{ color: '#ffffff' }}>Dischem Delivery/Collection</h1>
        </div>
      }
      closable={false}
      open={open}
      onCancel={onCancel}
      okButtonProps={{ style: { background: 'linear-gradient(45deg, #00A049,  #F3EC1C)' } }}
      onOk={onCancel}
      okText='Confirm'
    >
      <Card size='small' type='inner'>
        <Row>
          <Col>
            <Space>
              Patient Requesting Delivery/Collection
              <Switch
                checkedChildren='Yes'
                unCheckedChildren='No'
                style={{ background: dischemDeliveryApplicable ? '#00A049' : '' }}
                onChange={(value) => setDischemDeliveryApplicable(value)}
              />
            </Space>
          </Col>
        </Row>
        {dischemDeliveryApplicable && (
          <>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <Radio.Group buttonStyle='solid' value={dischemType} onChange={(e) => setDischemType(e.target.value)}>
                  <Radio.Button
                    style={dischemType === 'C' ? { background: '#00A049', borderColor: '#00A049' } : {}}
                    value='C'
                  >
                    Collection
                  </Radio.Button>
                  <Radio.Button
                    style={dischemType === 'D' ? { background: '#00A049', borderColor: '#00A049' } : {}}
                    value='D'
                  >
                    Delivery
                  </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <span>Payment Type</span>
                <br />
                <Radio.Group
                  style={{ marginTop: 5 }}
                  buttonStyle='solid'
                  value={dischemType === 'C' ? 'In-Store' : dischemPaymentType}
                  onChange={(e) => setDischemPaymentType(e.target.value)}
                >
                  {dischemType !== 'C' ? (
                    <>
                      <Radio.Button
                        style={dischemPaymentType === 'Cash' ? { background: '#00A049', borderColor: '#00A049' } : {}}
                        value='Cash'
                      >
                        Cash
                      </Radio.Button>
                      <Radio.Button
                        style={dischemPaymentType === 'Card' ? { background: '#00A049', borderColor: '#00A049' } : {}}
                        value='Card'
                      >
                        Card
                      </Radio.Button>
                      <Radio.Button
                        style={
                          dischemPaymentType === 'Medical Aid' ? { background: '#00A049', borderColor: '#00A049' } : {}
                        }
                        value='Medical Aid'
                      >
                        Medical Aid
                      </Radio.Button>
                    </>
                  ) : (
                    <Radio.Button
                      style={dischemType === 'C' ? { background: '#00A049', borderColor: '#00A049' } : {}}
                      value='In-Store'
                    >
                      In-Store
                    </Radio.Button>
                  )}
                </Radio.Group>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <Space wrap direction='vertical'>
                  <span>
                    <span style={{ color: token.colorError }}>*</span>Dischem Store{' '}
                    <span style={{ color: token.colorError }}>(required)</span>:
                  </span>
                  <Select
                    style={{ width: 500, maxWidth: '100%' }}
                    showSearch
                    allowClear
                    optionFilterProp='value'
                    value={selectedDischem}
                    placeholder='Please search for a Dischem Store'
                    options={dischems.map((i) => ({
                      label: i.name,
                      value: JSON.stringify(i)
                    }))}
                    onChange={(value) => setSelectedDischem(value)}
                    optionRender={(item) => (
                      <Space wrap direction='vertical' style={{ width: 500 }}>
                        <b>{item.label}</b>
                        <small>{JSON.parse(item.value).address}</small>
                      </Space>
                    )}
                  />
                </Space>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </Modal>
  )
}

export default DischemModal 