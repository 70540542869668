import React, { useEffect, useState } from 'react'
import { Button, Col, Collapse, Space, Spin, message, theme } from 'antd'
import { deviceDetect } from 'react-device-detect'
import dayjs from 'dayjs'
import CustomRow from '../../../reusable-components/CustomRow'
import ProfileEnums from '../../../Profile/utils/enums'
import CustomButton from '../../../reusable-components/CustomButton'
import { handleVerifyAvailability } from '../../utils/lib'
import { handleError, hexToRGBA } from '../../../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import CollapsePanel from 'antd/es/collapse/CollapsePanel'

const BookingResults = ({ result, isVirtualVisit, active, nextStep, handleMakeBooking, bookingDetails }) => {
  const { token } = theme.useToken()
  const [verifying, setVerifying] = useState(false)
  const [sortedResult, setSortedResult] = useState(null)

  useEffect(() => {
    handleSetSortedResult()
    // eslint-disable-next-line
  }, [])

  const handleSetSortedResult = () => {
    let finalSortedDays = []
    let tmpSortedResult = JSON.parse(JSON.stringify(result))
    let tmpSortedDays = tmpSortedResult.availabilityEntries
    tmpSortedDays.forEach((day) => {
      let daysEntries = JSON.parse(JSON.stringify(day.entries))
      let sortedDaysEntries = daysEntries.sort((a, b) => {
        let aSorted = a.availability.sort((a, b) => {
          return a.localeCompare(b)
        })
        let bSorted = b.availability.sort((a, b) => {
          return a.localeCompare(b)
        })
        return aSorted[0].localeCompare(bSorted[0])
      })
      finalSortedDays.push({ ...day, entries: sortedDaysEntries })
    })

    setSortedResult({ ...tmpSortedResult, availabilityEntries: finalSortedDays })
  }

  const handleSorting = (availabilityArray) => {
    let sortedArray = []
    let unsortedArray = JSON.parse(JSON.stringify(availabilityArray))
    let timePeriodSorted = [
      { title: 'Morning Slots', timeSlots: [] },
      { title: 'Afternoon Slots', timeSlots: [] },
      { title: 'Evening Slots', timeSlots: [] }
    ]

    sortedArray = unsortedArray.sort((a, b) => {
      return a.localeCompare(b)
    })

    sortedArray.forEach((item) => {
      if (item.localeCompare('12:00') === -1 && item.localeCompare('05:59' === 1)) {
        timePeriodSorted[0].timeSlots.push(item)
      } else if (item.localeCompare('11:59') === 1 && item.localeCompare('18:00') === -1) {
        timePeriodSorted[1].timeSlots.push(item)
      } else {
        timePeriodSorted[2].timeSlots.push(item)
      }
    })

    return timePeriodSorted
  }

  return (
    <Spin spinning={verifying} tip='Verifying Availability...'>
      <div style={{ width: '100%', padding: 8, marginBottom: 4, background: hexToRGBA(token.colorSecondary, 0.2) }}>
        <h2>
          {sortedResult?.clinicName}, {(result.distance / 1000).toFixed(2)} km away
        </h2>
      </div>
      <Collapse defaultActiveKey={[0]} style={{ border: '2px rgba(0,0,0,0.055) solid' }}>
        {sortedResult?.availabilityEntries.map((dataResult, index) => {
          return (
            <CollapsePanel header={dayjs(dataResult.date).format('DD MMMM YYYY').toUpperCase()} key={index} style={{}}>
              {dataResult.entries.map((availabilityEntry) => {
                return (
                  <CustomRow
                    className='basic-card'
                    style={{
                      alignItems: 'space-between',
                      boxShadow: '0 0 5px 0 rgba(0,0,0, 0.255)'
                    }}
                    gutter={[0, 16]}
                  >
                    <Col span={24}>
                      <div style={{ display: 'flex', gap: 12, flexWrap: 'wrap', alignItems: 'center' }}>
                        <div
                          style={{
                            height: 70,
                            width: 70,
                            display: 'flex',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          {availabilityEntry.profileImage ? (
                            <img
                              style={{
                                height: '100%'
                              }}
                              src={availabilityEntry.profileImage}
                              alt='Profile'
                            />
                          ) : (
                            <img
                              style={{
                                height: '100%'
                              }}
                              src={ProfileEnums.images.DEFAULT_IMAGE}
                              alt='Profile Placeholder'
                            />
                          )}
                        </div>
                        <div style={{ maxWidth: '100%' }}>
                          <h2>
                            {availabilityEntry.profession === 'doctor'
                              ? 'Dr.'
                              : availabilityEntry.profession === 'nurse'
                              ? 'Sr.'
                              : ''}{' '}
                            {availabilityEntry.firstName} {availabilityEntry.lastName}
                          </h2>
                          {!isVirtualVisit ? (
                            <p style={{ color: 'rgba(56, 57, 56, 1)', maxWidth: '100%' }}>
                              {result.clinicName},<br /> {(result.distance / 1000).toFixed(2)} km away
                            </p>
                          ) : undefined}
                        </div>
                      </div>
                    </Col>
                    <Col span={24}>
                      <Collapse style={{ border: '2px rgba(0,0,0,0.055) solid' }}>
                        {handleSorting(availabilityEntry.availability).map((period, index) => (
                          <>
                            {period.timeSlots.length > 0 ? (
                              <CollapsePanel header={period.title} key={index}>
                                {deviceDetect().isMobile ? (
                                  <small>
                                    Scroll slots to view more <FontAwesomeIcon icon={faArrowRight} />
                                  </small>
                                ) : undefined}
                                <Space
                                  wrap={!deviceDetect().isMobile}
                                  style={{ overflow: 'scroll', width: '100%', marginTop: 8 }}
                                >
                                  {period.timeSlots.map((item, index) => (
                                    <Button
                                      key={index}
                                      style={{
                                        width: 75,
                                        borderColor: token.colorBorderSecondary
                                      }}
                                      type={
                                        active.timeslot === item &&
                                        active.key ===
                                          dayjs(dataResult.date).format('DD MMMM YYYY') +
                                            availabilityEntry._id +
                                            result.clinicRef
                                          ? 'primary'
                                          : 'default'
                                      }
                                      onClick={() => {
                                        handleMakeBooking(
                                          dataResult.date,
                                          availabilityEntry,
                                          item,
                                          dayjs(dataResult.date).format('DD MMMM YYYY') +
                                            availabilityEntry._id +
                                            result.clinicRef,
                                          result.clinicRef,
                                          result.clinicPaymentMethods
                                        )
                                      }}
                                    >
                                      {item}
                                    </Button>
                                  ))}
                                </Space>
                              </CollapsePanel>
                            ) : undefined}
                          </>
                        ))}
                      </Collapse>
                    </Col>
                    {deviceDetect().isMobile ? undefined : (
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <CustomButton
                          style={
                            active.key !==
                            dayjs(dataResult.date).format('DD MMMM YYYY') + availabilityEntry._id + result.clinicRef
                              ? { width: '100%' }
                              : { background: token.colorPrimary, color: 'white', width: '100%' }
                          }
                          disabled={
                            active.key !==
                            dayjs(dataResult.date).format('DD MMMM YYYY') + availabilityEntry._id + result.clinicRef
                          }
                          loading={verifying}
                          onClick={async () => {
                            try {
                              await handleVerifyAvailability(bookingDetails, setVerifying)
                              nextStep()
                            } catch (e) {
                              message.error(handleError(e))
                            }
                          }}
                          text='Next'
                        />
                      </Col>
                    )}
                  </CustomRow>
                )
              })}
            </CollapsePanel>
          )
        })}
      </Collapse>
    </Spin>
  )
}

export default BookingResults
