import React, { useState, useEffect } from 'react'
import { Card, Button, Checkbox, message, Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBrain,
  faCloud,
  faBolt,
  faScaleBalanced,
  faShieldHeart,
  faHeartPulse,
  faUtensils,
  faHeadSideCoughSlash
} from '@fortawesome/free-solid-svg-icons'
import { updateMentalHealth } from '../../../Patient/Medical History/api/api'
import { handleError } from '../../../lib/utils'

const MENTAL_HEALTH_OPTIONS = [
  {
    value: 'Depression',
    label: 'Depression',
    icon: faCloud,
    color: '239, 68, 68'
  },
  {
    value: 'Anxiety',
    label: 'Anxiety',
    icon: faBolt,
    color: '168, 85, 247'
  },
  {
    value: 'Bipolar',
    label: 'Bipolar',
    icon: faScaleBalanced,
    color: '236, 72, 153'
  },
  {
    value: 'PTSD',
    label: 'PTSD',
    icon: faShieldHeart,
    color: '251, 146, 60'
  },
  {
    value: 'ADHD',
    label: 'ADHD',
    icon: faHeartPulse,
    color: '16, 178, 225'
  },
  {
    value: 'Eating Disorder',
    label: 'Eating Disorder',
    icon: faUtensils,
    color: '139, 92, 246'
  },
  {
    value: 'OCD',
    label: 'OCD',
    icon: faHeadSideCoughSlash,
    color: '81, 207, 102'
  }
]

const MentalHealthTab = ({ mentalHealth = {}, recordId, setSaving, updateMedicalHistory }) => {
  const [selectedConditions, setSelectedConditions] = useState([])

  // Initialize state from props
  useEffect(() => {
    if (mentalHealth && mentalHealth.list) {
      setSelectedConditions(mentalHealth.list || [])
    }
  }, [mentalHealth])

  const handleConditionChange = (condition) => {
    setSelectedConditions((prev) => {
      const isSelected = prev.includes(condition)
      return isSelected ? prev.filter((item) => item !== condition) : [...prev, condition]
    })
  }

  const handleSave = async () => {
    setSaving(true)
    try {
      const newData = await updateMentalHealth(recordId, selectedConditions)
      updateMedicalHistory(newData)
      message.success('Mental health information updated successfully')
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setSaving(false)
    }
  }

  return (
    <div className='mental-health-tab'>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            title={
              <span>
                <FontAwesomeIcon icon={faBrain} style={{ marginRight: '8px' }} />
                Mental Health Conditions
              </span>
            }
            className='mental-health-card'
          >
            <p>Select any mental health conditions the patient has experienced:</p>

            <Row gutter={[16, 16]}>
              {MENTAL_HEALTH_OPTIONS.map((option) => (
                <Col span={12} key={option.value}>
                  <div className='condition-item'>
                    <Checkbox
                      checked={selectedConditions.includes(option.value)}
                      onChange={() => handleConditionChange(option.value)}
                    >
                      <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <FontAwesomeIcon icon={option.icon} style={{ color: `rgb(${option.color})` }} />
                        {option.label}
                      </span>
                    </Checkbox>
                  </div>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Button type='primary' onClick={handleSave} block>
            Save Mental Health Information
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default MentalHealthTab
