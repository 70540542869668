import { Button, Col, Empty, Row, Space, Table, message } from 'antd'
import React from 'react'
import templates from '../utils/templates'
import { useState } from 'react'
import { useEffect } from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { handleError } from '../../../lib/utils'
import { readHealthTrackingEntries } from '../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import coreReducer from '../../../../core/utils/reducer'
import CoreEnums from '../../../../core/utils/enums'
import HealthTrackingEntryForm from './form'
import dayjs from 'dayjs'

const HealthTrackingEntriesView = () => {
  const authState = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleReadData()
    // eslint-disable-next-line
  }, [])

  const handleReadData = async () => {
    let tmpData = []

    try {
      setLoading(true)
      tmpData = await readHealthTrackingEntries({ userRef: authState.agiliteUser._id })
      setData(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleNewEntry = () => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.HEALTH_TRACKING_ENTRIES}_new}`,
        closable: true,
        label: 'New Health Tracking Entry',
        children: (
          <HealthTrackingEntryForm data={templates.dataTemplate()} isNewRecord handleReadData={handleReadData} />
        )
      })
    )
  }

  const handleViewEntry = (record) => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.HEALTH_TRACKING_ENTRIES}_${record._id}`,
        closable: true,
        label: `Health Tracking Entry - ${dayjs(record.date).format('YYYY-MM-DD HH:mm:ss')}`,
        children: <HealthTrackingEntryForm data={record} isNewRecord={false} handleReadData={handleReadData} />
      })
    )
  }

  return (
    <Row style={{ marginTop: 20 }} justify='center'>
      <Col span={22}>
        <CustomRow className='basic-card' justify='center'>
          <Col span={24}>
            <div style={{ marginBottom: 20 }}>
              <Space>
                <Button type='primary' onClick={handleReadData}>
                  <Space>
                    <FontAwesomeIcon icon={faRefresh} /> Refresh
                  </Space>
                </Button>
                <Button type='primary' onClick={handleNewEntry}>
                  New Entry
                </Button>
              </Space>
            </div>
            <Table
              columns={templates.columnTemplate()}
              loading={loading}
              rowKey={(record) => record._id}
              dataSource={JSON.parse(JSON.stringify(data))}
              pagination={false}
              scroll={{ x: 950 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    handleViewEntry(record)
                  }
                }
              }}
              locale={{ emptyText: <Empty description='There are currently no Health Tracking Entries to show' /> }}
            />
          </Col>
        </CustomRow>
      </Col>
    </Row>
  )
}

export default HealthTrackingEntriesView
