import { useEffect, useState, useCallback, useMemo } from 'react'

const useBillingMacroForm = ({ setVisible, isNew, macro = {}, setMacro, macros = [], setMacros, index }) => {
  const [macroName, setMacroName] = useState(macro.name || '')

  useEffect(() => {
    const tmpMacro = isNew
      ? {
          isActive: true,
          name: '',
          items: [{ type: undefined, code: undefined, name: undefined, icd10: undefined, qty: 0 }]
        }
      : {
          ...macro,
          items: [...macro.items, { type: undefined, code: undefined, name: undefined, icd10: undefined, qty: 0 }]
        }

    setMacro(tmpMacro)
    setMacroName(tmpMacro.name)
    // eslint-disable-next-line
  }, [])

  const handleOk = useCallback(() => {
    const tmpMacro = {
      ...macro,
      items: macro.items.filter((item) => item.type && item.code && item.name && item.qty > 0)
    }

    const updatedMacros = isNew ? [...macros, tmpMacro] : macros.map((m, i) => (i === index ? tmpMacro : m))

    setMacros(updatedMacros)
    setVisible(false)
    // eslint-disable-next-line
  }, [])

  const handleCancel = useCallback(() => {
    setVisible(false)
    setMacro({ isActive: true, name: '', items: [] })
    // eslint-disable-next-line
  }, [])

  const handleFieldChange = useCallback(
    (field, value) => {
      setMacro((prevMacro) => ({ ...prevMacro, [field]: value }))
    },
    [setMacro]
  )

  const handleItemSelection = useCallback(
    (items) => {
      setMacro((prevMacro) => ({ ...prevMacro, items }))
    },
    [setMacro]
  )

  return useMemo(
    () => ({
      macroName,
      handleOk,
      handleCancel,
      handleFieldChange,
      handleItemSelection
    }),
    [macroName, handleOk, handleCancel, handleFieldChange, handleItemSelection]
  )
}

export default useBillingMacroForm
