import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faAngleRight,
  faSearch,
  faPlug,
  faCreditCard,
  faHandHoldingMedical,
  faPrescriptionBottleMedical
} from '@fortawesome/free-solid-svg-icons'
import '../styling/MobInfoCarousel.css'

const tips = [
  {
    icon: faSearch,
    title: 'Search',
    description: 'Find and select the healthcare provider that matches your needs',
    animation: 'search-pulse'
  },
  {
    icon: faCreditCard,
    title: 'Payment',
    description: 'Complete your secure online payment to confirm appointment',
    animation: 'payment-slide'
  },
  {
    icon: faPlug,
    title: 'Connect',
    description: 'Ensure stable internet and join your virtual consultation',
    animation: 'connect-bounce'
  },
  {
    icon: faHandHoldingMedical,
    title: 'Heal',
    description: 'Receive personalized care and treatment recommendations',
    animation: 'heal-fade'
  },
  {
    icon: faPrescriptionBottleMedical,
    title: 'Prescriptions',
    description: 'Get your prescriptions sent directly to your preferred pharmacy',
    animation: 'prescription-float'
  }
]

const MobInfoCarousel = () => {
  const [currentTip, setCurrentTip] = useState(0)
  const [isPaused, setIsPaused] = useState(false)

  // Auto-cycle tips every 6 seconds
  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setCurrentTip((prev) => (prev === tips.length - 1 ? 0 : prev + 1))
      }, 6000) // 6 seconds

      return () => clearInterval(interval)
    }
  }, [isPaused])

  const handleTouchStart = () => {
    setIsPaused(true) // Pause auto-cycle on touch
  }

  const handleTouchEnd = () => {
    setIsPaused(false) // Resume auto-cycle after touch
  }

  const nextTip = () => {
    setCurrentTip((prev) => (prev === tips.length - 1 ? 0 : prev + 1))
    setIsPaused(true)
    setTimeout(() => setIsPaused(false), 6000) // Changed from 8000 to 6000
  }

  const prevTip = () => {
    setCurrentTip((prev) => (prev === 0 ? tips.length - 1 : prev - 1))
    setIsPaused(true)
    setTimeout(() => setIsPaused(false), 6000) // Changed from 8000 to 6000
  }

  return (
    <>
      <div className='tip-content' onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        <div className='navigation-button left' onClick={prevTip}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>

        <div className='tip-main'>
          <div className={`tip-icon ${tips[currentTip].animation}`}>
            <FontAwesomeIcon icon={tips[currentTip].icon} />
          </div>
          <h3>{tips[currentTip].title}</h3>
          <p>{tips[currentTip].description}</p>
        </div>

        <div className='navigation-button right' onClick={nextTip}>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </div>

      <div className='progress-dots'>
        {tips.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentTip ? 'active' : ''}`}
            onClick={() => {
              setCurrentTip(index)
              setIsPaused(true)
              setTimeout(() => setIsPaused(false), 6000) // Changed from 8000 to 6000
            }}
          />
        ))}
      </div>
    </>
  )
}

export default MobInfoCarousel
