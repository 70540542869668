const templates = {
  dataModel: {
    firstName: {
      dependency: null,
      type: 'input',
      key: 'firstName',
      label: 'First Name',
      required: true,
      placeholder: 'e.g. Jane',
      validationMsg: 'First name can`t be blank',
      formProps: {
        style: { marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }
      }
    },
    lastName: {
      dependency: null,
      type: 'input',
      key: 'lastName',
      label: 'Last Name',
      required: true,
      placeholder: 'e.g. Doe',
      validationMsg: 'Last name can`t be blank',
      formProps: {
        style: { marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }
      }
    },
    gender: {
      dependency: null,
      type: 'radioGroupButtons',
      key: 'gender',
      label: 'Gender',
      options: [
        {
          value: 'male',
          label: 'Male'
        },
        {
          value: 'female',
          label: 'Female'
        },
        {
          value: 'other',
          label: 'Other'
        }
      ]
    },
    race: {
      dependency: null,
      type: 'radioGroupButtons',
      key: 'race',
      label: 'Race',
      validationMsg: 'Please select your Race.',
      options: [
        {
          value: 'African',
          label: 'African'
        },
        {
          value: 'Coloured',
          label: 'Coloured'
        },
        {
          value: 'Indian',
          label: 'Indian'
        },
        {
          value: 'Other',
          label: 'Other'
        },
        {
          value: 'White',
          label: 'White'
        }
      ]
    },
    idNo: {
      dependency: null,
      type: 'input',
      key: 'idNo',
      label: 'Identification',
      required: true,
      placeholder: 'e.g. 980607*******',
      validationMsg: 'Identification can`t be blank',
      formProps: {
        style: { marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }
      }
    },
    medicalAidName: {
      dependency: null,
      type: 'input',
      key: 'idNo',
      label: 'Identification',
      required: true,
      placeholder: 'e.g. 980607*******',
      validationMsg: 'Identification can`t be blank',
      formProps: {
        style: { marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }
      }
    },
    medicalAidPlan: {
      dependency: null,
      type: 'input',
      key: 'idNo',
      label: 'Identification',
      required: true,
      placeholder: 'e.g. 980607*******',
      validationMsg: 'Identification can`t be blank',
      formProps: {
        style: { marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }
      }
    },
    medicalAidPlanOption: {
      dependency: null,
      type: 'input',
      key: 'idNo',
      label: 'Identification',
      required: true,
      placeholder: 'e.g. 980607*******',
      validationMsg: 'Identification can`t be blank',
      formProps: {
        style: { marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }
      }
    },
    medicalAidNumber: {
      dependency: null,
      type: 'input',
      key: ['medicalAid', 'schemeCode'],
      label: 'Identification',
      required: true,
      placeholder: 'e.g. 980607*******',
      validationMsg: 'Identification can`t be blank',
      formProps: {
        style: { marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }
      }
    },
    medicalAidDependantNumber: {
      dependency: null,
      type: 'input',
      key: 'idNo',
      label: 'Identification',
      required: true,
      placeholder: 'e.g. 980607*******',
      validationMsg: 'Identification can`t be blank',
      formProps: {
        style: { marginTop: 0, marginBottom: 0, width: 'calc(50% - 4px)', minWidth: 200 }
      }
    }
  },

  dataTemplate: {}
}

export default templates
