import { Button, Col, Space, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { handleError } from '../../lib/utils'
import { readClinicDataPoints } from './data-points-utils/data-point-utils'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import CustomRow from '../../reusable-components/CustomRow'
import DataPointsListView from './data-points-list-view'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { DataPointsEnums } from './data-points-utils/data-points-enums'
import DataPointsForm from './data-points-form'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../core/utils/reducer'
import CoreEnums from '../../../core/utils/enums'
import { DataPointDataTemplate } from './data-points-utils/data-point-templates'

const DataPointsWrapper = ({ type }) => {
  const dispatch = useDispatch()
  const [dataPoints, setDataPoints] = useState([])
  const [loading, setLoading] = useState(false)

  const { token } = theme.useToken()

  useEffect(() => {
    fetchClinicalDataPoints()
    // eslint-disable-next-line
  }, [])

  const fetchClinicalDataPoints = async () => {
    const qry =
      type === 'standard' ? { $or: [{ category: 'standard' }, { category: { $exists: false } }] } : { category: type }
    let tmpDataPoints = []

    setLoading(true)
    try {
      tmpDataPoints = await readClinicDataPoints(qry)
      setDataPoints(tmpDataPoints)
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }

  const handleOpenForm = (isNew, record) => {
    dispatch(
      coreReducer.actions.addTab({
        key: isNew ? `${CoreEnums.tabKeys.DATA_POINTS}_new` : `${CoreEnums.tabKeys.DATA_POINTS}_${record._id}`,
        closable: true,
        label: isNew ? 'New Data Point' : `Data Point: ${record.key}`,
        children: (
          <DataPointsForm
            type={type}
            isNew={isNew}
            record={isNew ? DataPointDataTemplate() : record}
            refreshView={fetchClinicalDataPoints}
          />
        )
      })
    )
  }

  return (
    <ContainerCard title={DataPointsEnums.titles.DATA_POINTS}>
      <CustomRow justify='space-between'>
        <Col>
          <Button onClick={() => handleOpenForm(true)} disabled={loading}>
            <Space>
              <FontAwesomeIcon icon={faAdd} color={token.colorSuccess} />
              <span>Add</span>
            </Space>
          </Button>
        </Col>
        <Col>
          <Button onClick={() => fetchClinicalDataPoints()} disabled={loading}>
            <Space>
              <FontAwesomeIcon icon={faRefresh} color={token.colorSuccess} />
              <span>Refresh</span>
            </Space>
          </Button>
        </Col>
      </CustomRow>
      <CustomRow style={{ marginTop: 10 }}>
        <Col span={24}>
          <DataPointsListView
            loading={loading}
            dataPoints={dataPoints}
            refreshView={fetchClinicalDataPoints}
            handleOpenForm={handleOpenForm}
          />
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default DataPointsWrapper
