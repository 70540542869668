const HealthTrackingEntryEnums = {
  profileKeys: {
    HEALHT_TRACKING_ENTRIES: 'health_tracking_entries'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update',
    COUNT: 'count',
    FIND_BY_ID: 'find_by_id'
  },
  trackerTitles: {
    HEIGHT: 'Height',
    WEIGHT: 'Weight',
    BMI: 'BMI',
    OXYGEN_SATURATION: 'Oxygen Saturation',
    TEMPERATURE: 'Temperature',
    PULSE: 'Pulse',
    BLOOD_GLUCOSE: 'Blood Glucose',
    CHOLESTEROL: 'Cholesterol'
  },
  trackerKeys: {
    HEIGHT: 'height',
    WEIGHT: 'weight',
    BMI: 'bmi',
    OXYGEN_SATURATION: 'oxygenSaturation',
    TEMPERATURE: 'temperature',
    PULSE: 'pulse',
    BLOOD_GLUCOSE: 'bloodGlucose',
    CHOLESTEROL: 'cholesterol',
    BLOOD_PRESSURE_SYSTOLIC: ['bloodPressure', 'systolic'],
    BLOOD_PRESSURE_DIASTOLIC: ['bloodPressure', 'diastolic']
  }
}
export default HealthTrackingEntryEnums
