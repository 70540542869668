import React, { useState, useEffect } from 'react'
import { Card, Button, Checkbox, message, Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartPulse } from '@fortawesome/free-solid-svg-icons'
import { updateFamMedHistory } from '../../../Patient/Medical History/api/api'
import { handleError } from '../../../lib/utils'
import Templates from '../../../Medical Vault/medical-history/utils/templates'

const FamilyHistoryTab = ({ familyHistory = {}, recordId, setSaving, updateMedicalHistory }) => {
  const [selectedConditions, setSelectedConditions] = useState([])
  const familyHistoryOptions = Templates.dataModel.familyHistoryList.options || []

  // Initialize state from props
  useEffect(() => {
    if (familyHistory && familyHistory.list) {
      setSelectedConditions(familyHistory.list || [])
    }
  }, [familyHistory])

  const handleConditionChange = (condition) => {
    setSelectedConditions((prev) => {
      const isSelected = prev.includes(condition)
      return isSelected ? prev.filter((item) => item !== condition) : [...prev, condition]
    })
  }

  const handleSave = async () => {
    setSaving(true)
    try {
      const newData = await updateFamMedHistory(recordId, selectedConditions)
      updateMedicalHistory(newData)
      message.success('Family history updated successfully')
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setSaving(false)
    }
  }

  return (
    <div className='family-history-tab'>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title='Family Medical History' className='family-history-card'>
            <p>Select any conditions that run in the patient's immediate family:</p>

            <div className='conditions-list'>
              {familyHistoryOptions.map((option) => (
                <div key={option.value} className='condition-item'>
                  <Checkbox
                    checked={selectedConditions.includes(option.value)}
                    onChange={() => handleConditionChange(option.value)}
                  >
                    <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <FontAwesomeIcon icon={faHeartPulse} style={{ color: '#ff4d4f' }} />
                      {option.label}
                    </span>
                  </Checkbox>
                </div>
              ))}
            </div>
          </Card>
        </Col>

        <Col span={24}>
          <Button type='primary' onClick={handleSave} block>
            Save Family History
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default FamilyHistoryTab
