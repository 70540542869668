import { DOSAGE_FORMS } from '../constants/dosageForms'

export const normalizePresentationCode = (code) => {
  if (!code) return undefined

  const normalizedCode = code.toLowerCase().trim()
  
  // First try to find an exact match in DOSAGE_FORMS
  const matchingForm = DOSAGE_FORMS.find((form) => form.codes.includes(normalizedCode) || form.value === normalizedCode)
  
  if (matchingForm) {
    return matchingForm.value
  }

  // If no match found, try to map to one of the common forms based on the code
  if (normalizedCode.startsWith('tab')) return 'tablet'
  if (normalizedCode.startsWith('cap')) return 'capsule'
  if (normalizedCode.startsWith('syr') || normalizedCode.startsWith('sus')) return 'syrup'
  if (normalizedCode.startsWith('inj') || normalizedCode.startsWith('amp')) return 'injection'
  if (normalizedCode.startsWith('cre') || normalizedCode.startsWith('oin')) return 'cream'
  if (normalizedCode.startsWith('inh') || normalizedCode.startsWith('neb')) return 'inhaler'

  return undefined
}
