import axios from 'axios'
import Agilite from 'agilite'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const createCallpayTransaction = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const response = await agilite.Connectors.execute('callpay_transactions', 'create', {
          data: JSON.stringify({
            ...data,
            createdAt: new Date(),
            modifiedAt: new Date()
          })
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateCallpayTransaction = (filter, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const response = await agilite.Connectors.execute('callpay_transactions', 'update', {
          filter: JSON.stringify(filter),
          data: JSON.stringify({
            ...data,
            modifiedAt: new Date()
          })
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const findCallpayTransaction = (filter) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const response = await agilite.Connectors.execute('callpay_transactions', 'read_one', {
          filter: JSON.stringify(filter)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const initCallpayPayment = (patientRecord, amount, paymentType = 'credit_card', isAuthorization = false) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const merchantReference = new Date().getTime().toString()
        // Get the base URL from current location, use static URL if on localhost
        const baseUrl =
          window.location.hostname === 'localhost' ? 'https://meta-clinic-portal-qa.web.app' : window.location.origin

        const callpayResponse = await axios.post(
          `${process.env.REACT_APP_NODE_RED_URL}/api/callpay/payment-key`,
          {
            amount,
            merchant_reference: merchantReference,
            customer_reference: `${patientRecord._id}_${merchantReference}`,
            payment_type: paymentType,
            success_url: `${baseUrl}/payment-status`,
            error_url: `${baseUrl}/payment-status`,
            pending_url: `${baseUrl}/payment-status`,
            is_authorisation: isAuthorization === true ? '1' : '0'
          },
          {
            headers: {
              'api-key': process.env.REACT_APP_AGILITE_API_KEY
            }
          }
        )

        // Not await to avoid blocking the main thread
        const transactionResponse = await createCallpayTransaction({
          merchantReference,
          customerReference: `${patientRecord._id}_${merchantReference}`,
          paymentKey: callpayResponse.data.key,
          data: callpayResponse.data
        })

        resolve({
          ...callpayResponse.data,
          ...transactionResponse
        })
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const findCallpayOriginalTransaction = (merchantReference) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_NODE_RED_URL}/api/callpay/transaction`,
          {
            reference: merchantReference
          },
          {
            headers: {
              'api-key': process.env.REACT_APP_AGILITE_API_KEY
            }
          }
        )

        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const settleCallpayOriginalTransaction = (gatewayReference) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_NODE_RED_URL}/api/callpay/settle`,
          {
            gateway_reference: gatewayReference
          },
          {
            headers: {
              'api-key': process.env.REACT_APP_AGILITE_API_KEY
            }
          }
        )

        await updateCallpayTransaction(
          {
            merchantReference: response.data.transaction.merchant_reference
          },
          {
            data: response.data
          }
        )

        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const reverseCallpayOriginalTransaction = (gatewayReference) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_NODE_RED_URL}/api/callpay/reverse`,
          {
            gateway_reference: gatewayReference
          },
          {
            headers: {
              'api-key': process.env.REACT_APP_AGILITE_API_KEY
            }
          }
        )

        await updateCallpayTransaction(
          {
            merchantReference: response.data.transaction.merchant_reference
          },
          {
            data: response.data
          }
        )

        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
