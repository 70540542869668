import React, { useState } from 'react'
import { Tabs } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDumbbell,
  faUtensils,
  faChartLine,
  faVial,
  faChartPie,
  faWeight,
  faCalendarCheck
} from '@fortawesome/free-solid-svg-icons'

import '../styling/PlanTabsContent.css'
import { DailyTasksTab } from '../../Results/components/Daily Tasks/DailyTasksTab'
import { HealthTrackingTab } from '../../Results/components/Health Tracking/HealthTrackingTab'
import { NutritionPlanTab } from '../../Results/components/NutritionPlanTab/NutritionPlanTab'
import { ProgressTrackingTab } from '../../Results/components/ProgressTrackingTab'
import { LabTestsTab } from '../../Results/components/LabTestsTab'
import { HealthScoreContainer } from '../../HealthScore/components'
import { WorkoutPlanTab } from '../../Results/components/WorkoutPlanTab'

const { TabPane } = Tabs

const TabErrorBoundary = ({ children, tabName }) => {
  try {
    return children
  } catch (error) {
    console.error(`Error rendering ${tabName} tab:`, error)
    return (
      <div className="tab-error">
        Failed to load {tabName} content. Please try again.
      </div>
    )
  }
}

// Add plan prop back and provide default value
export const PlanTabsContent = ({ plan = {}, onShowLeaderboard }) => {
  const [loadingTab, setLoadingTab] = useState(null)

  const getData = (key) => {
    switch(key) {
      case 'health': 
        const weightTracking = plan?.healthTracking?.weight?.current
        const healthTrackingMetrics = {
          weight: weightTracking,
          height: plan?.healthTracking?.height?.current,
          ...plan?.healthScore?.metrics,
          ...plan?.healthScore?.measurements
        }

        return {
          ...plan?.healthScore,
          metrics: {
            height: healthTrackingMetrics.height,
            weight: healthTrackingMetrics.weight,
            nextCheckup: plan?.nextCheckup
          }
        }
      case 'tasks': 
        return plan?.dailyTasks || []
      case 'tracking': 
        return {
          data: plan?.healthTracking || {},
          metrics: plan?.healthScore?.metrics || {}
        }
      case 'workout': 
        return plan?.workoutPlan || {}
      case 'nutrition': 
        return plan?.mealPlan || {}
      case 'progress': 
        return plan?.progressTracking || {}
      case 'labs': 
        return plan?.healthScore?.categories?.bloodwork || {}
      default: 
        return undefined
    }
  }

  const renderTabContent = (key, component) => {
    try {
      return component
    } catch (error) {
      console.error(`Error rendering ${key} tab:`, error)
      return <div>Error loading {key} content</div>
    }
  }

  const handleTabChange = (key) => {
    setLoadingTab(key)
    setTimeout(() => setLoadingTab(null), 500)
  }

  const items = [
    {
      key: 'health',
      label: (
        <span className='tab-label'>
          <FontAwesomeIcon icon={faChartPie} className='tab-icon' />
          <span>Health Score</span>
        </span>
      ),
      children: (
        <TabErrorBoundary tabName="Health Score">
          <HealthScoreContainer 
            healthScore={getData('health')} 
            healthTracking={plan?.healthTracking}
          />
        </TabErrorBoundary>
      )
    },
    {
      key: 'tasks',
      label: (
        <span className='tab-label'>
          <FontAwesomeIcon icon={faCalendarCheck} className='tab-icon' />
          <span>Daily Tasks</span>
        </span>
      ),
      children: <DailyTasksTab tasks={getData('tasks')} />
    },
    {
      key: 'tracking',
      label: (
        <span className='tab-label'>
          <FontAwesomeIcon icon={faWeight} className='tab-icon' />
          <span>Health Tracking</span>
        </span>
      ),
      children: renderTabContent('tracking', 
        <HealthTrackingTab 
          data={getData('tracking').data} 
          metrics={getData('tracking').metrics} 
        />
      )
    },
    {
      key: 'workout',
      label: (
        <span className='tab-label'>
          <FontAwesomeIcon icon={faDumbbell} className='tab-icon' />
          <span>Workout</span>
        </span>
      ),
      children: renderTabContent('workout', 
        <WorkoutPlanTab 
          plan={getData('workout')}
          onShowLeaderboard={onShowLeaderboard}
        />
      )
    },
    {
      key: 'nutrition',
      label: (
        <span className='tab-label'>
          <FontAwesomeIcon icon={faUtensils} className='tab-icon' />
          <span>Nutrition</span>
        </span>
      ),
      children: renderTabContent('nutrition', 
        <NutritionPlanTab plan={getData('nutrition')} />
      )
    },
    {
      key: 'progress',
      label: (
        <span className='tab-label'>
          <FontAwesomeIcon icon={faChartLine} className='tab-icon' />
          <span>Progress</span>
        </span>
      ),
      children: renderTabContent('progress', 
        <ProgressTrackingTab data={getData('progress')} />
      )
    },
    {
      key: 'labs',
      label: (
        <span className='tab-label'>
          <FontAwesomeIcon icon={faVial} className='tab-icon' />
          <span>Lab Tests</span>
        </span>
      ),
      children: renderTabContent('labs', 
        <LabTestsTab data={getData('labs')} />
      )
    }
  ]

  return (
    <div className='plan-tabs-content'>
      <Tabs
        defaultActiveKey='health'
        items={items}
        destroyInactiveTabPane={false}
        onChange={handleTabChange}
        className='scrollable-tabs'
      />
    </div>
  )
}
