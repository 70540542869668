/**
 * @fileoverview SearchBar component for filtering data points and templates
 * Provides a search input with icon that filters the list of available items
 */

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const SearchBar = ({ searchTerm, setSearchTerm, token }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        borderBottom: `1px ${token.colorBorder} solid`,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0
      }}
    >
      <input
        value={searchTerm}
        placeholder='Search'
        style={{ width: '100%', borderRadius: 0, border: 'none', paddingLeft: 10 }}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div
        style={{
          height: 35,
          width: 35,
          display: 'grid',
          alignItems: 'center',
          justifyContent: 'center',
          background: token.colorPrimary,
          color: '#ffffff'
        }}
      >
        <FontAwesomeIcon icon={faSearch} />
      </div>
    </div>
  )
}

export default React.memo(SearchBar)
