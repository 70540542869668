import React from 'react'
import { theme } from 'antd'
import { useICD10 } from '../../hooks/useICD10'
import { useMedicationForm } from './hooks/useMedicationForm'
import MedicationDisplay from './components/MedicationDisplay'
import MedicationForm from './components/MedicationForm'
import './styles/medicationCard.css'

const MedicationCard = ({ med, index, isEditing, onEdit, onRemove, onClose, onDosageChange, onPropertyChange }) => {
  const { token } = theme.useToken()
  const { icd10Loading, icd10Data, setICD10SearchFilter } = useICD10()

  const {
    form,
    showRoute,
    showDose,
    showFrequency,
    showDuration,
    showEstQuantity,
    selectedDosageForm,
    currentDosageForm,
    selectedRoute,
    selectedDose,
    selectedFrequency,
    selectedDuration,
    estimatedQuantity,
    quantitySuffix,
    getAvailableRoutes,
    getDefaultDose,
    setSelectedDosageForm,
    setCurrentDosageForm,
    setSelectedRoute,
    setSelectedDose,
    setSelectedFrequency,
    setSelectedDuration,
    setShowRoute,
    setShowDose,
    setShowFrequency,
    setShowDuration,
    setShowEstQuantity
  } = useMedicationForm(med)

  const formState = {
    showRoute,
    showDose,
    showFrequency,
    showDuration,
    showEstQuantity,
    selectedDosageForm,
    currentDosageForm,
    selectedRoute,
    selectedDose,
    selectedFrequency,
    selectedDuration,
    estimatedQuantity,
    quantitySuffix,
    getAvailableRoutes
  }

  const handlers = {
    handleDosageFormSelect: (value, customLabel) => {
      const dosageForm = value === 'other' ? customLabel : value
      setSelectedDosageForm(value)
      setCurrentDosageForm(value)
      setShowRoute(true)
      form.setFieldsValue({ 
        dosageForm: value,
        customDosageForm: value === 'other' ? customLabel : undefined,
        route: undefined 
      })
      setSelectedRoute(undefined)
    },
    handleDosageFormClear: () => {
      form.setFieldsValue({
        dosageForm: undefined,
        customDosageForm: undefined,
        route: undefined,
        dose: undefined
      })
      setSelectedDosageForm(undefined)
      setCurrentDosageForm(undefined)
      setSelectedRoute(undefined)
      setSelectedDose(undefined)
    },
    handleRouteSelect: (value) => {
      setSelectedRoute(value)
      setShowDose(true)
      form.setFieldsValue({ route: value })
    },
    handleRouteClear: () => {
      form.setFieldsValue({ route: undefined })
      setSelectedRoute(undefined)
    },
    handleDoseSelect: (value) => {
      if (value === undefined) return
      setSelectedDose(value)
      setShowFrequency(true)
      form.setFieldsValue({ dose: value })
    },
    handleDoseClear: () => {
      form.setFieldsValue({ dose: undefined })
      setSelectedDose(undefined)
      setShowDose(true)
    },
    handleDoseInputChange: (value) => {
      if (value === null || value === '') {
        form.setFieldsValue({ dose: undefined })
        setSelectedDose(undefined)
      } else {
        form.setFieldsValue({ dose: value })
      }
    },
    handleDoseInputComplete: (value) => {
      if (value) {
        setSelectedDose(value)
        setShowFrequency(true)
        form.setFieldsValue({ dose: value })
      }
    },
    handleFrequencySelect: (value) => {
      setSelectedFrequency(value)
      setShowDuration(true)
      form.setFieldsValue({ frequency: value })
    },
    handleFrequencyClear: () => {
      form.setFieldsValue({ frequency: undefined })
      setSelectedFrequency(undefined)
    },
    handleDurationSelect: (value) => {
      setSelectedDuration(value)
      form.setFieldsValue({ duration: value })
    },
    handleDurationClear: () => {
      form.setFieldsValue({ duration: undefined })
      setSelectedDuration(undefined)
    },
    handleRepeatChange: (value) => {
      form.setFieldsValue({ repeat: value })
    },
    handleICD10Select: (value) => {
      form.setFieldsValue({ icd10: value })
    }
  }

  const icd10Props = {
    icd10Loading,
    icd10Data,
    setICD10SearchFilter
  }

  if (!isEditing) {
    return (
      <MedicationDisplay
        med={med}
        index={index}
        onEdit={onEdit}
        selectedRoute={selectedRoute}
        estimatedQuantity={estimatedQuantity}
      />
    )
  }

  return (
    <div
      style={{
        overflow: 'hidden',
        border: `2px ${med.isChronic ? '#F39C12' : token.colorPrimary} solid`,
        borderRadius: 12
      }}
    >
      <MedicationForm
        med={med}
        index={index}
        onRemove={onRemove}
        onClose={onClose}
        onDosageChange={onDosageChange}
        onPropertyChange={onPropertyChange}
        form={form}
        formState={formState}
        handlers={handlers}
        icd10Props={icd10Props}
      />
    </div>
  )
}

export default MedicationCard
