import { Button, Card, Col, DatePicker, Divider, Empty, Input, Popconfirm, Space, message, theme } from 'antd'
import React, { useState } from 'react'
import CustomRow from '../../../../reusable-components/CustomRow'
import { faFileMedical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { hexToRGBA } from '../../../../lib/utils'
import dayjs from 'dayjs'

const MedHistSurgeries = ({ loading, saveFunction, dependantData, isDependant, setFacet }) => {
  const surgicalHistoryState = useSelector((state) => state.medicalHistory.patient.surgicalHistory.list)
  const [inputValue, setInputValue] = useState('')
  const [surgeryList, setSurgeryList] = useState(
    dependantData && isDependant ? dependantData.surgicalHistory.list : surgicalHistoryState
  )
  const { token } = theme.useToken()

  const handleSubmit = () => {
    let tmpData = {}
    tmpData.surgicalHistory = {}
    tmpData.surgicalHistory.list = surgeryList
    saveFunction(tmpData)
  }
  const handleAddSurgery = () => {
    if (!inputValue) return message.error('Please provide a description')
    let tmpSurgeryList = [...surgeryList]
    tmpSurgeryList.push({
      description: inputValue,
      date: undefined
    })
    setSurgeryList(tmpSurgeryList)
    setInputValue('')
  }

  const handleUpdateSurgery = (date, surgeryIndex, key) => {
    let tmpSurgeryList = [...surgeryList]
    tmpSurgeryList[surgeryIndex][key] = date
    setSurgeryList(tmpSurgeryList)
  }

  const handleRemoveSurgery = (medIndex) => {
    let tmpSurgeryList = [...surgeryList]
    tmpSurgeryList.splice(medIndex, 1)
    setSurgeryList(tmpSurgeryList)
  }
  return (
    <CustomRow gutter={[0, 0]} justify='center'>
      <div
        style={{
          width: '100%',
          padding: 8,
          background: hexToRGBA(token.colorWarning, 0.1),
          marginTop: 1
        }}
      >
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <Button
            style={{
              backgroundColor: token.colorError,
              color: 'white'
            }}
            onClick={() => {
              setFacet('')
            }}
            loading={loading}
          >
            Close
          </Button>
          <Button
            style={{
              backgroundColor: token.colorSuccess,
              color: 'white'
            }}
            onClick={() => {
              handleSubmit()
            }}
            loading={loading}
          >
            Save Changes
          </Button>
        </Space>
      </div>
      <Col span={24}>
        <div
          style={{
            paddingLeft: 12,
            paddingRight: 12,
            display: 'grid',
            marginTop: 8,
            marginBottom: 8,
            gridTemplateColumns: '1fr 100px',
            gap: 8,
            alignItems: 'center'
          }}
        >
          <Input
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value)
            }}
            placeholder='Start typing surgery description...'
          />
          <Button
            type='primary'
            onClick={() => {
              handleAddSurgery()
            }}
          >
            Add
          </Button>
        </div>
        <Card
          title='Surgery List'
          headStyle={{ borderRadius: 0, background: token.colorPrimary, color: '#fff' }}
          bodyStyle={{ padding: 0 }}
          style={{ borderRadius: 0 }}
          size='default'
        >
          {surgeryList.length === 0 ? (
            <Empty
              style={{ marginTop: 24, marginBottom: 24 }}
              image={<FontAwesomeIcon icon={faFileMedical} />}
              description='Use the input box above to capture any surgeries you have undergone.'
            />
          ) : undefined}
          {surgeryList.map((surgery, surgeryIndex) => {
            return (
              <>
                <Card
                  size='small'
                  type='inner'
                  title={
                    <div
                      style={{
                        width: '100%',
                        display: 'grid',
                        gap: 8,
                        gridTemplateColumns: '1fr auto',
                        whiteSpace: 'wrap'
                      }}
                    >
                      <p>{surgery.description?.toUpperCase()}</p>
                    </div>
                  }
                  style={{ borderRadius: 0 }}
                  headStyle={{
                    borderRadius: 0,
                    background: hexToRGBA(token.colorPrimary, 0.1),
                    paddingTop: 4,
                    paddingBottom: 4
                  }}
                >
                  <Space style={{ width: '100%' }}>
                    <p>Year:</p>
                    <DatePicker
                      picker='year'
                      onChange={(date) => {
                        handleUpdateSurgery(date, surgeryIndex, 'date')
                      }}
                      value={surgery.date ? dayjs(surgery.date) : undefined}
                    />
                  </Space>

                  <Divider style={{ margin: '24px 0px 8px 0px' }} />
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <Popconfirm
                      overlayStyle={{ maxWidth: '90%', width: 400 }}
                      title={`Confirm Removing ${surgery.description}`}
                      description='Once removed, this action can`t be undone.'
                      okText='REMOVE'
                      cancelText='KEEP'
                      cancelButtonProps={{ type: 'primary' }}
                      okButtonProps={{ type: 'primary', style: { background: token.colorError } }}
                      onConfirm={() => {
                        handleRemoveSurgery(surgeryIndex)
                      }}
                    >
                      <Button style={{ border: 'none', background: 'transparent', color: token.colorError }}>
                        Remove
                      </Button>
                    </Popconfirm>
                  </div>
                </Card>

                <div style={{ width: '100%', height: 10, background: '#ccc' }}></div>
              </>
            )
          })}
        </Card>
      </Col>
      <center style={{ marginTop: 24, marginBottom: 24 }}>
        <Button
          style={{
            backgroundColor: token.colorSuccess,
            color: 'white',
            marginRight: 10
          }}
          onClick={() => {
            handleSubmit()
          }}
          loading={loading}
        >
          Save Changes
        </Button>
      </center>
    </CustomRow>
  )
}

export default MedHistSurgeries
