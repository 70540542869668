import { assistantCheckRunStatus, assistantReadThread } from './utils'

const MAX_RETRIES = 15 // Maximum number of retries (30 seconds total with 2-second interval)

const generateAIResponse = (tmpData) => {
  return new Promise((resolve, reject) => {
    let retryCount = 0
    console.log('Starting generateAIResponse with data:', tmpData)

    const intervalId = setInterval(async () => {
      try {
        if (retryCount >= MAX_RETRIES) {
          clearInterval(intervalId)
          reject(new Error('Timeout waiting for AI response after 30 seconds'))
          return
        }

        retryCount++
        console.log(`Checking run status (attempt ${retryCount}/${MAX_RETRIES})...`)

        let response = await checkStatus(tmpData.thread_id, tmpData.id)
        console.log('Run status response:', response)

        if (response.status === 'completed') {
          clearInterval(intervalId)
          console.log('Run completed, fetching thread content...')

          try {
            response = await assistantReadThread(tmpData.thread_id)
            console.log('Thread content received:', response)
            resolve(response)
          } catch (readError) {
            console.error('Error reading thread content:', readError)
            reject(readError)
          }
        } else if (response.status === 'failed') {
          clearInterval(intervalId)
          console.error('Run failed with status:', response)
          reject(new Error(`OpenAI run failed: ${response.last_error?.message || 'Unknown error'}`))
        } else if (response.status === 'cancelled') {
          clearInterval(intervalId)
          console.error('Run was cancelled:', response)
          reject(new Error('OpenAI run was cancelled'))
        } else if (response.status === 'expired') {
          clearInterval(intervalId)
          console.error('Run expired:', response)
          reject(new Error('OpenAI run expired'))
        } else {
          console.log(`Run status: ${response.status}, waiting...`)
        }
      } catch (error) {
        clearInterval(intervalId)
        console.error('Error checking run status:', error)
        reject(error)
      }
    }, 2000)
  })
}

const checkStatus = async (thread_id, run_id) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        if (!thread_id || !run_id) {
          reject(new Error('Missing thread_id or run_id for status check'))
          return
        }

        console.log(`Checking status for thread: ${thread_id}, run: ${run_id}`)
        let runState = await assistantCheckRunStatus(thread_id, run_id)

        if (!runState) {
          reject(new Error('Empty response from status check'))
          return
        }

        resolve(runState)
      } catch (e) {
        console.error('Error in checkStatus:', e)
        reject(e)
      }
    })()
  })
}

export { generateAIResponse }
