// src/components/BluetoothManager.js
import React, { useState, useEffect, useRef } from 'react'
import BluetoothContext from '../BluetoothContext'
import Engine from '../lib/hc03lib'
import { message } from 'antd'
import { isAndroid } from 'react-device-detect'

const PATH_WORKER = `${process.env.PUBLIC_URL}/work.bundle.js`

const BluetoothManager = ({ children }) => {
  const [bleDevice, setBleDevice] = useState(null)
  const [bleCommandCharacteristic, setBleCommandCharacteristic] = useState(null)
  const [bleNotifyCharacteristic, setBleNotifyCharacteristic] = useState(null)
  const [connectionStatus, setConnectionStatus] = useState('disconnected') // 'disconnected', 'connecting', 'connected'

  // useRef to store the engine instance
  const engineRef = useRef(null)
  // Track connection attempts
  const connectionAttemptsRef = useRef(0)
  // Track if device is connected
  const isConnectedRef = useRef(false)

  // Add a new state for connection attempts tracking
  const [connectionAttempts, setConnectionAttempts] = useState(0)
  // Add a state for last error message
  const [lastError, setLastError] = useState(null)

  useEffect(() => {
    // Initialize the engine only once
    if (!engineRef.current) {
      engineRef.current = Engine.getEngine(PATH_WORKER)

      // Add global error handler for the engine
      if (engineRef.current) {
        engineRef.current.registBLEListener({
          onException: (err) => {
            const { code, message } = err
            console.error(`BLE Error: Code ${code}, Message: ${message}`)
            setLastError(`BLE Error: ${message}`)
          }
        })
      }
    }

    // Cleanup function to terminate engine if needed
    return () => {
      if (engineRef.current && engineRef.current.terminate) {
        engineRef.current.terminate()
      }
    }
  }, [])

  const requestDevice = async () => {
    try {
      setConnectionStatus('connecting')
      setLastError(null)

      // Different options for Android vs other platforms
      const requestOptions = {
        filters: [{ namePrefix: 'HC0' }],
        optionalServices: ['0000ff27-0000-1000-8000-00805f9b34fb', '0000180a-0000-1000-8000-00805f9b34fb']
      }

      // On Android, we might need to add additional services or use acceptAllDevices in some cases
      if (isAndroid) {
        // Keep the same options but log that we're on Android
        console.log('Requesting device on Android platform')
      }

      // First check if Bluetooth is available
      await checkBluetoothAvailability()

      const device = await navigator.bluetooth.requestDevice(requestOptions)

      setBleDevice(device)

      // Add event listener for disconnection
      device.addEventListener('gattserverdisconnected', onDisconnected)

      // Automatically connect after device selection
      connectDevice(device)
    } catch (error) {
      setConnectionStatus('disconnected')
      const errorMessage = getBluetoothErrorMessage(error)
      setLastError(errorMessage)
      console.error('Device request error:', errorMessage)
    }
  }

  const connectDevice = async (deviceToConnect = null) => {
    const device = deviceToConnect || bleDevice

    if (!device) {
      setConnectionStatus('disconnected')
      setLastError('No device selected')
      return
    }

    try {
      setConnectionStatus('connecting')
      setLastError(null)
      setConnectionAttempts((prev) => prev + 1)

      // For Android, we might need to retry connection
      const maxRetries = isAndroid ? 3 : 1
      let server = null

      for (let attempt = 0; attempt < maxRetries; attempt++) {
        try {
          console.log(`Connection attempt ${attempt + 1} of ${maxRetries}`)
          server = await device.gatt.connect()
          break // If successful, exit the retry loop
        } catch (err) {
          if (attempt === maxRetries - 1) throw err // Rethrow on last attempt
          console.log('Connection attempt failed, retrying in 1 second...')
          await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait before retry
        }
      }

      if (!server) {
        throw new Error('Failed to connect after multiple attempts')
      }

      isConnectedRef.current = true

      // Add a small delay for Android to ensure connection stability
      if (isAndroid) {
        await new Promise((resolve) => setTimeout(resolve, 500))
      }

      const service = await server.getPrimaryService('0000ff27-0000-1000-8000-00805f9b34fb')

      const characteristics = await service.getCharacteristics()

      let commandCharFound = false
      let notifyCharFound = false

      for (const char of characteristics) {
        if (char.uuid === '0000fff1-0000-1000-8000-00805f9b34fb') {
          setBleCommandCharacteristic(char)
          commandCharFound = true
        } else if (char.uuid === '0000fff4-0000-1000-8000-00805f9b34fb') {
          setBleNotifyCharacteristic(char)
          notifyCharFound = true
          await setupNotification(char)
        }
      }

      if (!commandCharFound || !notifyCharFound) {
        if (isAndroid) {
          setLastError('Device connected but some features may be limited')
        }
      }

      setConnectionStatus('connected')
      // Reset connection attempts on successful connection
      setConnectionAttempts(0)
    } catch (error) {
      setConnectionStatus('disconnected')
      isConnectedRef.current = false
      const errorMessage = getBluetoothErrorMessage(error)
      setLastError(errorMessage)
      console.error('Connection failed:', errorMessage)
    }
  }

  const setupNotification = async (characteristic) => {
    try {
      // For Android, we might need to retry notification setup
      const maxRetries = isAndroid ? 3 : 1

      for (let attempt = 0; attempt < maxRetries; attempt++) {
        try {
          await characteristic.startNotifications()
          break // If successful, exit the retry loop
        } catch (err) {
          if (attempt === maxRetries - 1) throw err // Rethrow on last attempt
          await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait before retry
        }
      }

      // Remove existing listener to prevent duplicates
      characteristic.removeEventListener('characteristicvaluechanged', handleCharacteristicValueChanged)

      // Add the new listener
      characteristic.addEventListener('characteristicvaluechanged', handleCharacteristicValueChanged)
    } catch (error) {
      if (isAndroid) {
        // On Android, we might still be able to use the device with limited functionality
        message.warning('Notification setup failed. Some features may not work properly.')
      }
    }
  }

  const handleCharacteristicValueChanged = (event) => {
    const value = event.target.value // DataView

    // Create a copy of the buffer to prevent detachment
    const bufferCopy = value.buffer.slice(0)

    // Send the copy to the engine
    if (engineRef.current) {
      try {
        engineRef.current.pushRawData(bufferCopy)
      } catch (error) {}
    } else {
    }
  }

  const onDisconnected = (event) => {
    isConnectedRef.current = false
    setConnectionStatus('disconnected')

    // Only clear state if we're not attempting to reconnect
    if (connectionAttemptsRef.current <= 0) {
      setBleDevice(null)
      setBleCommandCharacteristic(null)
      setBleNotifyCharacteristic(null)
    }

    // For Android, we might want to attempt automatic reconnection
    if (isAndroid && bleDevice && connectionAttemptsRef.current < 3) {
      setTimeout(() => {
        if (bleDevice && !isConnectedRef.current) {
          connectDevice()
        }
      }, 2000)
    } else {
      connectionAttemptsRef.current = 0
    }
  }

  // Disconnect Function
  const disconnectDevice = () => {
    if (bleDevice && bleDevice.gatt.connected) {
      connectionAttemptsRef.current = 0 // Reset connection attempts
      bleDevice.gatt.disconnect()
      // Cleanup state
      setBleDevice(null)
      setBleCommandCharacteristic(null)
      setBleNotifyCharacteristic(null)
      setConnectionStatus('disconnected')
      isConnectedRef.current = false
    } else {
      // Clean up state anyway
      setBleDevice(null)
      setBleCommandCharacteristic(null)
      setBleNotifyCharacteristic(null)
      setConnectionStatus('disconnected')
      isConnectedRef.current = false
    }
  }

  // Add a retry mechanism for connections
  const connectWithRetry = async (device, maxRetries = 3, delay = 1000) => {
    let lastError

    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        const server = await device.gatt.connect()
        return server
      } catch (error) {
        lastError = error

        // Wait before retrying
        if (attempt < maxRetries - 1) {
          await new Promise((resolve) => setTimeout(resolve, delay))
        }
      }
    }

    // If we get here, all retries failed
    throw new Error(`Failed to connect after ${maxRetries} attempts: ${lastError.message}`)
  }

  // Add a function to check if Bluetooth is available and enabled
  const checkBluetoothAvailability = async () => {
    if (!navigator.bluetooth) {
      throw new Error('Web Bluetooth API is not available in this browser. Please use Chrome or Edge.')
    }

    try {
      // This will prompt for Bluetooth permission if not already granted
      await navigator.bluetooth.getAvailability()
      return true
    } catch (error) {
      throw new Error('Bluetooth may be turned off or not available on this device.')
    }
  }

  // Add a function to provide user-friendly error messages
  const getBluetoothErrorMessage = (error) => {
    if (error.message.includes('User cancelled')) {
      return 'Connection cancelled by user.'
    } else if (error.message.includes('Device not found')) {
      return 'Device not found. Make sure it is powered on and in range.'
    } else if (error.message.includes('Connection failed')) {
      return 'Connection failed. Try moving closer to the device or restarting it.'
    } else if (error.message.includes('GATT Server is disconnected')) {
      return 'Device disconnected. Please try reconnecting.'
    } else {
      return `Bluetooth error: ${error.message}`
    }
  }

  return (
    <BluetoothContext.Provider
      value={{
        bleDevice,
        bleCommandCharacteristic,
        bleNotifyCharacteristic,
        requestDevice,
        connectDevice,
        disconnectDevice,
        connectionStatus,
        connectionAttempts,
        lastError,
        engine: engineRef.current
      }}
    >
      {children}
    </BluetoothContext.Provider>
  )
}

export default BluetoothManager
