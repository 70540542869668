import React, { useState } from 'react'
import { Col, Row, theme, Form, Input, Button, message, Modal, Popover, Select } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons'

// Components
import dayjs from 'dayjs'
import CustomRow from '../../../reusable-components/CustomRow'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import usePatEditProfile from '../hooks/usePatEditProfile'
import PrevNextFloatingButtons from '../../Reusable Components/Buttons/PrevNextFloatingButtons'
import '../styles/MobileEditMode.css'
import templates from '../utils/templates'
import GoogleAddressMobile from '../../../reusable-components/GoogleAddressMobile'
import DatePicker from '../../Reusable Components/DatePicker'

const ProfileEditMode = ({ isReception, setIsEditMode, userData, setUserData, image }) => {
  const {
    handleSubmit,
    form,
    schemeLoading,
    plansLoading,
    planOptionsLoading,
    planOptionData,
    debouncedSchemeFilter,
    handleGetMedicalAidPlanOptions,
    handleGetMedicalAidPlans,
    plansData,
    schemeData,
    loading,
    handleUploadClick,
    handleFileChange,
    handleLoginPhoneCode,
    handleValidateSave,
    setCountry,
    countries,
    countriesLoading,
    countryObject,
    residentVisitor,
    setResidentVisitor,
    newImage,
    inputRef,
    confirmationOpen,
    setConfirmationOpen,
    confirmationCode,
    setConfirmationCode,
    submitDisabled,
    setSchemeData,
    setPlansData,
    setPlanOptionData
  } = usePatEditProfile({ userData, setUserData, setIsEditMode })

  const [showDatePicker, setShowDatePicker] = useState(false)
  const [pickerValue, setPickerValue] = useState(userData.dateOfBirth ? dayjs(userData.dateOfBirth) : undefined)
  const handleDateSelect = (val) => {
    setPickerValue(val)
    form.setFieldValue('dateOfBirth', val)
    setShowDatePicker(false)
  }

  const { token } = theme.useToken()
  return (
    <div className='mobile-edit-container' style={{ paddingTop: 0 }}>
      <div className='mobile-edit-content'>
        <Form
          onFinishFailed={(e) => {
            message.error(e.errorFields[0].errors[0])
          }}
          onFinish={handleSubmit}
          disabled={loading}
          form={form}
          layout='vertical'
          initialValues={{
            ...userData,
            phoneNumber: userData.phoneNumber ? userData.phoneNumber.replace('+27', '') : null,
            dateOfBirth: userData.dateOfBirth ? dayjs(userData.dateOfBirth) : null,
            residentVisitor: residentVisitor,
            medicalAid: {
              name: userData.medicalAid?.name ? userData.medicalAid.name : undefined,
              schemeCode: userData.medicalAid?.schemeCode ? userData.medicalAid.schemeCode : undefined,
              plan: userData.medicalAid?.plan ? userData.medicalAid.plan : undefined,
              planCode: userData.medicalAid?.planCode ? userData.medicalAid.planCode : undefined,
              planOption: userData.medicalAid?.planOption ? userData.medicalAid.planOption : undefined,
              planOptionCode: userData.medicalAid?.planOptionCode ? userData.medicalAid.planOptionCode : undefined,
              number: userData.medicalAid?.number ? userData.medicalAid.number : '',
              dependantNumber: userData.medicalAid?.dependantNumber ? userData.medicalAid.dependantNumber : ''
            }
          }}
        >
          <CustomRow justify='center'>
            <Col style={{ marginBottom: 10 }}>
              <center>
                <input
                  disabled={isReception}
                  type='file'
                  accept='image/*'
                  ref={inputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <div className='profile-image-container'>
                  <img referrerPolicy='no-referrer' alt='User Profile' src={newImage ? newImage : image} />
                  {!isReception && (
                    <div className='profile-image-overlay' onClick={loading ? null : handleUploadClick}>
                      <FontAwesomeIcon color='white' icon={faEdit} size='2x' />
                    </div>
                  )}
                </div>
              </center>
            </Col>
          </CustomRow>

          <div className='form-section'>
            <h3>Personal Details</h3>

            <Form.Item
              label={templates.dataModel.firstName.label}
              name='firstName'
              rules={[
                {
                  required: templates.dataModel.firstName.required,
                  message: templates.dataModel.firstName.validationMsg
                }
              ]}
            >
              <Input placeholder={templates.dataModel.firstName.placeholder} />
            </Form.Item>

            <Form.Item
              label={templates.dataModel.lastName.label}
              name='lastName'
              rules={[
                { required: templates.dataModel.lastName.required, message: templates.dataModel.lastName.validationMsg }
              ]}
            >
              <Input placeholder={templates.dataModel.lastName.placeholder} />
            </Form.Item>

            <Form.Item label='Email Address' name='email'>
              <Input disabled={isReception ? false : true} placeholder='e.g. jane.doe@gmail.com' />
            </Form.Item>

            <Form.Item
              label={templates.dataModel.idNo.label}
              name='idNo'
              rules={[{ required: templates.dataModel.idNo.required, message: templates.dataModel.idNo.validationMsg }]}
            >
              <Input placeholder={templates.dataModel.idNo.placeholder} />
            </Form.Item>

            <Form.Item
              name='residentVisitor'
              label='RSA Resident / Visitor'
              rules={[{ required: true, message: 'Please indicate if you are a RSA Resident or Visitor' }]}
            >
              <Select
                suffixIcon={<CaretDownOutlined style={{ color: 'white' }} />}
                popupClassName='dark-select-dropdown'
                placeholder='RSA Resident or Visitor'
                options={[
                  { label: 'RSA Resident', value: 'resident' },
                  { label: 'Visiting From Abroad', value: 'visitor' }
                ]}
                defaultValue={residentVisitor}
                onChange={(value) => {
                  if (value === 'resident') {
                    setCountry('ZA')
                  }
                  setResidentVisitor(value)
                }}
              />
            </Form.Item>

            <Form.Item
              name='country'
              label='Country Of Residence'
              rules={[{ required: true, message: 'Please provide your Country Of Residence' }]}
            >
              <Select
                suffixIcon={<CaretDownOutlined style={{ color: 'white' }} />}
                popupClassName='dark-select-dropdown'
                showSearch
                optionFilterProp='label'
                loading={countriesLoading}
                placeholder='e.g South Africa'
                options={countries.map((e) => ({
                  label: e.name,
                  value: e.code
                }))}
                onChange={(value) => {
                  setCountry(value)
                }}
              />
            </Form.Item>

            <Form.Item
              name='gender'
              label={templates.dataModel.gender.label}
              rules={[{ required: true, message: 'Please select your gender' }]}
            >
              <Select
                suffixIcon={<CaretDownOutlined style={{ color: 'white' }} />}
                popupClassName='dark-select-dropdown'
                placeholder='Select gender'
                options={templates.dataModel.gender.options}
              />
            </Form.Item>

            <Form.Item
              name='race'
              label={templates.dataModel.race.label}
              rules={[{ required: true, message: templates.dataModel.race.validationMsg }]}
            >
              <Select
                suffixIcon={<CaretDownOutlined style={{ color: 'white' }} />}
                popupClassName='dark-select-dropdown'
                placeholder='Select race'
                options={templates.dataModel.race.options}
              />
            </Form.Item>
            <Form.Item label='Date Of Birth' name='dateOfBirth'>
              <div onClick={() => setShowDatePicker(true)}>
                <Input
                  placeholder='Select date of birth'
                  value={pickerValue ? pickerValue.format('DD/MM/YYYY') : ''}
                  readOnly
                  suffix={<FontAwesomeIcon icon={faCalendar} style={{ color: token.colorPrimary }} />}
                />
              </div>
              <DatePicker
                showDatePicker={showDatePicker}
                setShowDatePicker={setShowDatePicker}
                pickerValue={pickerValue}
                setPickerValue={setPickerValue}
                handleDateSelect={handleDateSelect}
                allowPastDates={true}
                showQuickSelect={false}
                confirmText='Confirm'
                cancelText='Cancel'
              />
            </Form.Item>

            <Popover
              placement='topRight'
              content='One-time Pin will be sent to your Cellphone'
              title={<span style={{ color: token.colorPrimary }}>Note</span>}
              trigger='focus'
            >
              <Form.Item label='Cellphone Number' name='phoneNumber'>
                <Input
                  maxLength={15}
                  onChange={(e) => {
                    if (/^[0-9]*$/g.test(e.target.value) === true) {
                      form.setFieldValue('phoneNumber', e.target.value)
                    }
                  }}
                  addonBefore={`${countryObject ? countryObject.phoneCode : '+27'}`}
                  placeholder='8288*****'
                />
              </Form.Item>
            </Popover>

            <Form.Item label='Residential Address' name='residentialAddress'>
              <GoogleAddressMobile
                selectProps={{
                  placeholder: form.getFieldValue('residentialAddress')
                    ? form.getFieldValue('residentialAddress')
                    : 'Residential Address',
                  onChange: (e) => {
                    form.setFieldValue('residentialAddress', e.label)
                  }
                }}
              />
            </Form.Item>
          </div>

          <div className='form-section'>
            <h3>Medical Aid Details</h3>

            <Form.Item name={['medicalAid', 'schemeCode']} style={{ display: 'none' }}>
              <Input />
            </Form.Item>
            <Form.Item name={['medicalAid', 'planCode']} style={{ display: 'none' }}>
              <Input />
            </Form.Item>
            <Form.Item name={['medicalAid', 'planOptionCode']} style={{ display: 'none' }}>
              <Input />
            </Form.Item>

            <Form.Item
              requiredMark='optional'
              label={`${
                !residentVisitor || residentVisitor === 'resident' ? 'Medical Aid Name' : 'Travel Insurance Provider'
              }`}
              name={['medicalAid', 'name']}
            >
              {!residentVisitor || residentVisitor === 'resident' ? (
                <Select
                  suffixIcon={<CaretDownOutlined style={{ color: 'white' }} />}
                  clearIcon={<CloseOutlined style={{ color: '#ff4d4f' }} />}
                  popupClassName='dark-select-dropdown'
                  showSearch
                  allowClear
                  placeholder='Search for a Medical Aid...'
                  disabled={false}
                  onSearch={(value) => {
                    debouncedSchemeFilter(value)
                  }}
                  onChange={(e, option) => {
                    form.setFieldValue(['medicalAid', 'schemeCode'], option?.data ? JSON.parse(option.data).code : '')
                    setSchemeData([])
                  }}
                  options={schemeData.map((i) => ({
                    label: i.name,
                    value: i.name,
                    data: JSON.stringify(i)
                  }))}
                  notFoundContent={
                    schemeLoading ? (
                      <AgiliteSkeleton skActive spinnerTip='Searching Schemes...' />
                    ) : (
                      <div
                        style={{
                          padding: '20px',
                          textAlign: 'center',
                          color: 'white'
                        }}
                      >
                        <div>No results found please adjust your search</div>
                      </div>
                    )
                  }
                />
              ) : (
                <Input placeholder='e.g. Discovery Health Scheme' />
              )}
            </Form.Item>

            <Form.Item
              required={form.getFieldValue(['medicalAid', 'schemeCode'])}
              label={`${
                !residentVisitor || residentVisitor === 'resident' ? 'Medical Aid Plan' : 'Travel Insurance Plan'
              }`}
              name={['medicalAid', 'plan']}
            >
              {!residentVisitor || residentVisitor === 'resident' ? (
                <Select
                  suffixIcon={<CaretDownOutlined style={{ color: 'white' }} />}
                  clearIcon={<CloseOutlined style={{ color: '#ff4d4f' }} />}
                  popupClassName='dark-select-dropdown'
                  showSearch
                  allowClear
                  onClear={() => {
                    form.setFieldValue(['medicalAid', 'planCode'], '')
                  }}
                  placeholder='Select a Medical Aid Plan...'
                  onChange={(e, option) => {
                    form.setFieldValue(['medicalAid', 'pl anCode'], option?.data ? JSON.parse(option.data).code : '')
                    setPlansData([])
                  }}
                  onClick={() => {
                    handleGetMedicalAidPlans(form.getFieldValue(['medicalAid', 'schemeCode']))
                  }}
                  options={plansData.map((i) => ({
                    label: i.option,
                    value: i.option,
                    data: JSON.stringify(i)
                  }))}
                  notFoundContent={
                    plansLoading ? (
                      <AgiliteSkeleton skActive spinnerTip='Searching Scheme Plans...' />
                    ) : (
                      <div
                        style={{
                          padding: '20px',
                          textAlign: 'center',
                          color: 'white'
                        }}
                      >
                        <div>No results found please adjust your search</div>
                      </div>
                    )
                  }
                />
              ) : (
                <Input placeholder='e.g. Coastal Saver' />
              )}
            </Form.Item>

            <Form.Item
              required={form.getFieldValue(['medicalAid', 'planCode'])}
              label={`${
                !residentVisitor || residentVisitor === 'resident'
                  ? 'Medical Aid Plan Option'
                  : 'Travel Insurance Plan Option'
              }`}
              name={['medicalAid', 'planOption']}
            >
              {!residentVisitor || residentVisitor === 'resident' ? (
                <Select
                  suffixIcon={<CaretDownOutlined style={{ color: 'white' }} />}
                  clearIcon={<CloseOutlined style={{ color: '#ff4d4f' }} />}
                  popupClassName='dark-select-dropdown'
                  showSearch
                  allowClear
                  onClear={() => {
                    form.setFieldValue(['medicalAid', 'planOptionCode'], '')
                  }}
                  placeholder='Select a Medical Aid Plan Option...'
                  onChange={(e, option) => {
                    form.setFieldValue(
                      ['medicalAid', 'planOptionCode'],
                      option?.data ? JSON.parse(option.data).code : ''
                    )
                    setPlanOptionData([])
                  }}
                  onClick={() => {
                    handleGetMedicalAidPlanOptions(form.getFieldValue(['medicalAid', 'planCode']))
                  }}
                  options={planOptionData.map((i) => ({
                    label: i.option,
                    value: i.option,
                    data: JSON.stringify(i)
                  }))}
                  notFoundContent={
                    planOptionsLoading ? (
                      <AgiliteSkeleton skActive spinnerTip='Searching Scheme Plan Options...' />
                    ) : (
                      <div
                        style={{
                          padding: '20px',
                          textAlign: 'center',
                          color: 'white'
                        }}
                      >
                        <div>No results found please adjust your search</div>
                      </div>
                    )
                  }
                />
              ) : (
                <Input placeholder='e.g. Coastal Saver' />
              )}
            </Form.Item>

            <Form.Item
              required={form.getFieldValue(['medicalAid', 'schemeCode'])}
              label='Medical Aid Number'
              name={['medicalAid', 'number']}
            >
              <Input placeholder={templates.dataModel.medicalAidNumber.placeholder} />
            </Form.Item>

            <Form.Item
              required={form.getFieldValue(['medicalAid', 'schemeCode'])}
              label='Dependant Number'
              name={['medicalAid', 'dependantNumber']}
            >
              <Input placeholder={templates.dataModel.medicalAidDependantNumber.placeholder} />
            </Form.Item>
          </div>
        </Form>
      </div>
      {!confirmationOpen ? (
        <PrevNextFloatingButtons
          onPrevDisabled={loading}
          onNextLoading={loading}
          nextText='Save'
          prevText='Cancel'
          onPrev={() => {
            setIsEditMode(false)
          }}
          onNext={() => {
            handleValidateSave()
          }}
        />
      ) : undefined}

      <Modal title='One-time Pin' footer={null} open={confirmationOpen} onCancel={() => setConfirmationOpen(false)}>
        <Row justify='center'>
          <Col>
            <p>Please provide the One-time Pin sent to your Cellphone</p>
            <Input
              value={confirmationCode}
              onChange={(e) => {
                if (/^[0-9]*$/g.test(e.target.value) === true) {
                  setConfirmationCode(e.target.value)
                }
              }}
            />
          </Col>
        </Row>

        <Row justify='space-between' style={{ marginTop: 20 }}>
          <Col>
            <Button
              disabled={submitDisabled}
              onClick={() => {
                const data = form.getFieldsValue()
                handleSubmit(data)
              }}
              type='primary'
            >
              Resend Code
            </Button>
          </Col>
          <Col>
            <Button
              disabled={submitDisabled}
              onClick={handleLoginPhoneCode}
              type='primary'
              style={{ backgroundColor: token.colorSuccess }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Modal>

      <div id='recaptcha-container'></div>
    </div>
  )
}

export default ProfileEditMode
