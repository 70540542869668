import {
  faBookMedical,
  faEarListen,
  faHistory,
  faPencil,
  faRobot,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import DictaphoneControls from '../../../../lib/dictaphone-controls'
import { hexToRGBA } from '../../../../lib/utils'
import { Button, Popconfirm, Tooltip, Divider, Space } from 'antd'
import Dictaphone from '../../../../lib/dictaphone'
import { ERROR_MESSAGES } from '../utils/constants'

const buttonBaseStyle = {
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
}

const gradientButtonStyle = (isDisabled) => ({
  ...buttonBaseStyle,
  background: isDisabled ? '' : `linear-gradient(45deg, ${hexToRGBA('#13B1E1', 0.2)}, ${hexToRGBA('#F14F8A', 0.2)})`
})

const blueButtonStyle = (isDisabled) => ({
  ...buttonBaseStyle,
  background: isDisabled ? '' : hexToRGBA('#13B1E1', 0.2)
})

const iconStyle = {
  marginRight: '8px',
  width: '16px',
  display: 'inline-flex',
  justifyContent: 'center'
}

const dividerStyle = {
  margin: '12px 0'
}

const infoIconStyle = {
  marginLeft: 8,
  fontSize: 14,
  color: '#8c8c8c',
  cursor: 'help'
}

const ClinicalNotesControls = ({
  setShowClinicalNotesTemplates,
  noBooking,
  handleUpdateNote,
  setShowHistorical,
  noteState,
  setNoteState,
  handleCreateNote,
  handleAutoGenerateNotes,
  setShowAiTranscription
}) => {
  const isDisabled = useMemo(() => {
    return (
      noteState.isAutoGenerating ||
      noteState.updatingError ||
      noteState.fetchingError ||
      noteState.isUpdating ||
      noteState.isCreating ||
      noteState.isSubmitting ||
      !noteState.noteId
    )
  }, [noteState])

  const [dictaphoneState, setDictaphoneState] = useState({
    isRecording: false,
    tmpTranscript: ''
  })

  const handleTranscriptionStart = () => {
    setDictaphoneState((prev) => ({ ...prev, isRecording: true }))
  }

  const handleAIListeningStart = () => {
    setShowAiTranscription(true)
  }

  const handleTemplatesOpen = () => {
    setShowClinicalNotesTemplates(true)
  }

  const handleNoteStateUpdate = (value) => {
    setNoteState((prev) => ({ ...prev, currentNote: value }))
  }

  const handleTranscriptUpdate = (value) => {
    setDictaphoneState((prev) => ({ ...prev, tmpTranscript: value }))
  }

  const handleRecordingUpdate = (value) => {
    setDictaphoneState((prev) => ({ ...prev, isRecording: value }))
  }

  if (noBooking) return null

  return (
    <div style={{ padding: '0 8px' }}>
      {/* Navigation Section */}
      <Divider style={dividerStyle} orientation='left'>
        <small style={{ color: '#8c8c8c', fontWeight: 'normal' }}>Navigation</small>
      </Divider>

      <Tooltip title='View previously saved notes for this patient' placement='right'>
        <Button disabled={isDisabled} style={buttonBaseStyle} onClick={() => setShowHistorical(true)} block>
          <FontAwesomeIcon icon={faHistory} style={iconStyle} />
          Show History
        </Button>
      </Tooltip>

      {/* Editing Section */}
      <Divider style={dividerStyle} orientation='left'>
        <small style={{ color: '#8c8c8c', fontWeight: 'normal' }}>
          Editing
          <Tooltip title='Tools for creating and editing clinical notes'>
            <FontAwesomeIcon icon={faInfoCircle} style={infoIconStyle} />
          </Tooltip>
        </small>
      </Divider>

      <Space direction='vertical' style={{ width: '100%', gap: '8px' }}>
        {noteState.noteId && (
          <Tooltip
            title={
              !noteState.currentNote
                ? ERROR_MESSAGES.NO_NOTE_EMPTY
                : 'This will archive your current note and create a new blank note'
            }
            placement='right'
          >
            <Popconfirm
              overlayStyle={{ maxWidth: 450 }}
              onConfirm={handleCreateNote}
              okText='I am sure'
              title='Archive Current Note'
              description={ERROR_MESSAGES.ADD_NOTE_CONFIRM}
            >
              <Button style={blueButtonStyle(isDisabled)} disabled={!noteState.currentNote || isDisabled} block>
                <FontAwesomeIcon icon={faPencil} style={iconStyle} />
                Archive & New
              </Button>
            </Popconfirm>
          </Tooltip>
        )}

        <Tooltip title='Access and insert pre-defined note templates' placement='right'>
          <Button disabled={isDisabled} style={blueButtonStyle(isDisabled)} onClick={handleTemplatesOpen} block>
            <FontAwesomeIcon icon={faBookMedical} style={iconStyle} />
            Templates
          </Button>
        </Tooltip>

        {dictaphoneState.isRecording ? (
          <Tooltip title='Recording in progress - click to stop' placement='right'>
            <div style={{ width: '100%' }}>
              <Dictaphone
                buttonStyle={blueButtonStyle(isDisabled)}
                noteId={noteState.noteId}
                handleUpdateNote={handleUpdateNote}
                value={noteState.currentNote}
                setValue={handleNoteStateUpdate}
                tmpTranscript={dictaphoneState.tmpTranscript}
                setTmpTranscript={handleTranscriptUpdate}
                setIsRecording={handleRecordingUpdate}
                isRecording={dictaphoneState.isRecording}
                disabled={isDisabled}
                iconPosition='start'
                block
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            title='Speech-to-text: Speak into your microphone to convert your voice directly into text'
            placement='right'
          >
            <div style={{ width: '100%' }}>
              <DictaphoneControls
                buttonStyle={blueButtonStyle(isDisabled)}
                disabled={isDisabled}
                startListening={handleTranscriptionStart}
                iconPosition='start'
                block
              />
            </div>
          </Tooltip>
        )}
      </Space>

      {/* AI Tools Section */}
      <Divider style={dividerStyle} orientation='left'>
        <small style={{ color: '#8c8c8c', fontWeight: 'normal' }}>
          AI Tools
          <Tooltip title='AI-powered tools to help with note creation'>
            <FontAwesomeIcon icon={faInfoCircle} style={infoIconStyle} />
          </Tooltip>
        </small>
      </Divider>

      <Space direction='vertical' style={{ width: '100%', gap: '8px' }}>
        <Tooltip title='Use AI to automatically generate a clinical note based on patient data' placement='right'>
          <Button style={gradientButtonStyle(isDisabled)} disabled={isDisabled} onClick={handleAutoGenerateNotes} block>
            <FontAwesomeIcon icon={faRobot} style={iconStyle} />
            Generate Note (AI)
          </Button>
        </Tooltip>

        <Tooltip title='Record audio and have AI transcribe and format it into a clinical note' placement='right'>
          <Button disabled={isDisabled} style={gradientButtonStyle(isDisabled)} onClick={handleAIListeningStart} block>
            <FontAwesomeIcon icon={faEarListen} style={iconStyle} />
            Transcribe (AI)
          </Button>
        </Tooltip>
      </Space>
    </div>
  )
}

ClinicalNotesControls.propTypes = {
  setShowClinicalNotesTemplates: PropTypes.func.isRequired,
  noBooking: PropTypes.bool,
  handleUpdateNote: PropTypes.func.isRequired,
  setShowHistorical: PropTypes.func.isRequired,
  noteState: PropTypes.object.isRequired,
  setNoteState: PropTypes.func.isRequired,
  handleCreateNote: PropTypes.func.isRequired,
  handleAutoGenerateNotes: PropTypes.func.isRequired,
  setShowAiTranscription: PropTypes.func.isRequired
}

export default memo(ClinicalNotesControls)
