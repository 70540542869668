import React from 'react'
import { Button, Col, theme } from 'antd'
import step_1 from '../../assets/HOME VISITS STEP 1.png'
import step_2 from '../../assets/HOME VISITS STEP 2.png'
import step_3 from '../../assets/HOME VISITS STEP 3.png'
import step_4 from '../../assets/HOME VISITS STEP 4.png'
import step_5 from '../../assets/HOME VISITS STEP 5.png'
import CustomRow from '../../../reusable-components/CustomRow'

const HomeVisits = ({ nextStep }) => {
  const { token } = theme.useToken()

  const imgStyle = {
    maxWidth: '100%',
    border: `1px ${token.colorBgBase} solid`,
    width: 450
  }

  const handleHomeVisit = () => {
    nextStep()
  }
  return (
    <CustomRow justify='center'>
      <Col span={24}>
        <h2
          style={{
            padding: 12,
            borderRadius: 12,
            background: token.colorSecondary,
            textAlign: 'center',
            color: 'white'
          }}
        >
          How it works
        </h2>
      </Col>
      <Col>
        <CustomRow gutter={[12, 12]} justify='center' style={{ margin: '20px 0' }} className='basic-card'>
          <Col style={{ textAlign: 'center' }} span={24}>
            <Button type='primary' onClick={handleHomeVisit}>
              Next
            </Button>
          </Col>
          <Col>
            <img style={imgStyle} src={step_1} alt='Home visit info card step_1' />
          </Col>
          <Col>
            <img style={imgStyle} src={step_2} alt='Home visit info card step_2' />
          </Col>
          <Col>
            <img style={imgStyle} src={step_3} alt='Home visit info card step_3' />
          </Col>
          <Col>
            <img style={imgStyle} src={step_4} alt='Home visit info card step_4' />
          </Col>
          <Col>
            <img style={imgStyle} src={step_5} alt='Home visit info card step_5' />
          </Col>
          <Col style={{ textAlign: 'center' }} span={24}>
            <Button type='primary' onClick={handleHomeVisit}>
              Next
            </Button>
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default HomeVisits
