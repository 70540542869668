const MedicalProfessionalEnums = {
  keys: {},
  titles: {},
  profileKeys: {
    AUTH_USERS: 'auth_users',
    MEDICAL_PROFESSIONALS: 'medical_professionals'
  },
  routeKeys: {
    FIND: 'find',
    FIND_ONE: 'find_one',
    CREATE: 'create',
    UPDATE: 'update'
  }
}

export default MedicalProfessionalEnums
