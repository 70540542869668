import React from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { Button, Col, DatePicker, Input, Space, Table, theme } from 'antd'
import { hexToRGBA } from '../../../lib/utils'
import SystemUsersTemplates from '../../../Admin/system-users/utils/templates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { DataComplianceTableTemplates } from './utils/templates'
import useEnrollmentSearch from './hooks/useEnrollmentSearch'

const EnrollmentSearch = ({ template, setModalOpen, onAfterSuccess, isMonitoring }) => {
  const {
    setSearchQuery,
    patient,
    setPatient,
    searchResults,
    handleEnrollPatient,
    searching,
    enrolling,
    enrollmentOverride,
    setEnrollmentOverride,
    handleTeamMemberSelection,
    teamMembers,
    handlePatientQuery,
    handleReset
  } = useEnrollmentSearch({ template, setModalOpen, onAfterSuccess })

  const { token } = theme.useToken()
  return (
    <>
      <div
        style={{
          width: '100%',
          padding: 8,
          background: hexToRGBA(token.colorPrimary, 0.2),
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          top: 0,
          left: 0
        }}
      >
        <h2>NEW ENROLLMENT</h2>
        <Button style={{ fontSize: 24, background: 'transparent', border: 'none' }}>
          <FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} />
        </Button>
      </div>
      <CustomRow style={{ marginTop: 48 }}>
        {!patient ? (
          <>
            <Col span={24}>
              Search:{' '}
              <Input
                placeholder='Patient Name or ID...'
                onChange={(e) => handlePatientQuery(e.target.value)}
                style={{ width: 350, maxWidth: '100%' }}
              />
            </Col>
            <Col span={24}>
              <Table
                size='small'
                bordered
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      setSearchQuery('')
                      setPatient(record)
                    }
                  }
                }}
                loading={searching}
                dataSource={searchResults}
                columns={SystemUsersTemplates.columnTemplate([], 'patient', [], token, null, null, true).filter(
                  (i) => !i.hidden
                )}
              />
            </Col>
          </>
        ) : (
          <>
            <Col span={24}>
              You are about to enroll {patient.firstName} {patient.lastName} into the{' '}
              <span style={{ color: token.colorPrimary }}>{template.name}</span> care plan.{' '}
            </Col>
            <Col span={24}>
              The enrollment date will be:{' '}
              <DatePicker
                allowClear={false}
                onChange={(date) => setEnrollmentOverride(date)}
                value={enrollmentOverride}
              />
            </Col>
            <Col span={12}>
              <h2 style={{ marginBottom: 12 }}>Test Compliance</h2>
              <Table
                pagination={false}
                size='small'
                bordered
                columns={DataComplianceTableTemplates(token, true).columnTemplate.filter((i) => !i.hidden)}
                // dataSource={template}
              />
            </Col>
            <Col span={12}>
              <h2 style={{ marginBottom: 12 }}>Visit Compliance</h2>
              <Table
                pagination={false}
                size='small'
                bordered
                columns={DataComplianceTableTemplates(token, true).columnTemplate.filter((i) => !i.hidden)}
                // dataSource={}
              />
            </Col>
          </>
        )}

        <Col span={24}>
          <Space style={{ float: 'right' }}>
            <Button
              disabled={enrolling || searching}
              onClick={() => {
                if (patient) {
                  setPatient(null)
                } else {
                  handleReset()
                }
              }}
            >
              Cancel
            </Button>
            <Button
              // loading={enrolling || searching}
              type='primary'
              style={{ background: token.colorSuccess }}
              onClick={() => {
                handleEnrollPatient()
              }}
            >
              Enroll Patient
            </Button>
          </Space>
        </Col>
      </CustomRow>
    </>
  )
}

export default EnrollmentSearch
