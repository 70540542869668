import ObjectId from 'bson-objectid'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  message,
  theme,
  Tag
} from 'antd'
import React, { useEffect, useState } from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import BillingTool from './BillingTool'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faFileLines, faRefresh, faSearch, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

import './styles/Billing.css'
import { isReception } from '../../../lib/profile-utils'
import CoreEnums from '../../../../core/utils/enums'
import { handleError } from '../../../lib/utils'
import { createBillingRecord, getPlanSubOptions, goodXBenefitCheck, goodXResponse } from '../../utils/utils'
import { useSelector } from 'react-redux'

const Billing = ({
  billing,
  billingItems,
  setBillingItems,
  bookingData,
  setBookingData,
  handleGetBillingRecords,
  billingRecordsLoading,
  setBillingSyncRequired,
  billingWebSocket
}) => {
  const authState = useSelector((state) => state.auth)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedMacros, setSelectedMacros] = useState([])
  const [readOnly, setReadOnly] = useState(false)
  const [filterQuery, setFilterQuery] = useState('')
  const [benefitCheckLoading, setBenefitCheckLoading] = useState(false)
  const [benefitCheckResponse, setBenefitCheckResponse] = useState(null)
  const { token } = theme.useToken()

  useEffect(() => {
    if (isReception()) {
      if (bookingData?.status !== CoreEnums.bookingStatuses.billing) {
        setReadOnly(true)
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleCheckboxChange = (checked, macro) => {
    const tmpSelectedMacros = [...selectedMacros]
    const tmpIndex = tmpSelectedMacros.findIndex((item) => item.name === macro.name)

    if (tmpIndex === -1 && checked) {
      tmpSelectedMacros.push(macro)
    } else if (tmpIndex !== -1 && !checked) {
      tmpSelectedMacros.splice(tmpIndex, 1)
    }

    setSelectedMacros(tmpSelectedMacros)
  }

  const handleAddMacro = () => {
    const tmpBillingItems = [...billingItems]
    const tmpLastItem = tmpBillingItems[tmpBillingItems.length - 1]
    const entries = []

    try {
      tmpBillingItems.pop()

      selectedMacros.forEach((macro) => {
        macro.items.forEach((item) => {
          const record = {
            _id: ObjectId().toHexString(),
            bookingRef: bookingData._id,
            code: item.code,
            name: item.name,
            qty: item.qty,
            type: item.type
          }

          tmpBillingItems.push(record)
          entries.push(record)
        })
      })

      if (tmpLastItem) {
        tmpBillingItems.push(tmpLastItem)
      }

      setBillingItems(tmpBillingItems)
      setSelectedMacros([])
      setModalOpen(false)

      // No need to await this function
      createBillingRecord(entries)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const filteredMacros = () => {
    let tmpMacros = [...bookingData?.clinicRecord?.billingMacros]

    if (filterQuery) {
      tmpMacros = tmpMacros.filter((object) => object.name?.toLowerCase().search(filterQuery?.toLowerCase()) > -1)
    }

    return tmpMacros.map((i) => i.name)
  }

  const handleGoodXBenefitCheck = async () => {
    let planSubOptionsData = null
    let planSubOption = null

    setBenefitCheckLoading(true)

    try {
      planSubOptionsData = await getPlanSubOptions(bookingData.patientRecord.medicalAid.planOptionCode)

      if (planSubOptionsData.planSubOptions.pageResult.length > 0) {
        const tmpIndex = planSubOptionsData.planSubOptions.pageResult.findIndex(
          (option) => option.option === 'Acute Meds'
        )

        if (tmpIndex !== -1) {
          planSubOption = planSubOptionsData.planSubOptions.pageResult[tmpIndex]
        } else {
          planSubOption = planSubOptionsData.planSubOptions.pageResult[0]
        }
      }

      const ticketNo = await goodXBenefitCheck(
        bookingData,
        bookingData.patientRecord,
        bookingData.clinicRecord,
        billingItems.reduce((acc, curr) => acc + parseFloat(curr.medicalAid), 0),
        planSubOption
      )
      const response = await goodXResponse(ticketNo)
      setBenefitCheckResponse(response)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setBenefitCheckLoading(false)
  }

  return (
    <div>
      <CustomRow>
        <Col span={24}>
          <Row justify='space-between'>
            <Col>
              <Space>
                <Button
                  disabled={readOnly}
                  type='primary'
                  style={{ marginBottom: 10, float: 'left' }}
                  onClick={() => setModalOpen(true)}
                >
                  <Space>
                    <FontAwesomeIcon icon={faFileLines} />
                    <span>Billing Macros</span>
                  </Space>
                </Button>
                {isReception() && bookingData.patientRecord.medicalAid.name ? (
                  <Button
                    type='primary'
                    style={{ marginBottom: 10, float: 'left', backgroundColor: token.colorSuccess }}
                    onClick={() => handleGoodXBenefitCheck()}
                    loading={benefitCheckLoading}
                  >
                    <Space>
                      <FontAwesomeIcon icon={faCheck} />
                      <span>Benefit Check</span>
                    </Space>
                  </Button>
                ) : undefined}
              </Space>
            </Col>
            {authState.agiliteUser._id === bookingData.medicalProfRef ? (
              <Col>
                <Space>
                  <span>No Charge</span>
                  <Switch
                    value={bookingData.noCharge}
                    onChange={(value) => setBookingData({ ...bookingData, noCharge: value })}
                    unCheckedChildren='No'
                    checkedChildren='Yes'
                  />
                </Space>
              </Col>
            ) : null}
            <Col>
              <Button
                disabled={readOnly}
                style={{ marginBottom: 10, float: 'right' }}
                onClick={() => {
                  handleGetBillingRecords()
                  setBillingSyncRequired(false)
                }}
              >
                <Space>
                  <FontAwesomeIcon icon={faRefresh} color={token.colorSuccess} />
                  <span>Refresh</span>
                </Space>
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <BillingTool
            billing={billing}
            itemSelection={billingItems}
            setItemSelection={setBillingItems}
            readOnly={readOnly}
            bookingData={bookingData}
            isMacroForm={false}
            billingRecordsLoading={billingRecordsLoading}
            billingWebSocket={billingWebSocket}
          />
        </Col>
        <Modal
          title='Billing Macros'
          destroyOnClose
          closeIcon={<FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} />}
          closable={true}
          open={modalOpen}
          okText='Add'
          onOk={() => {
            setFilterQuery('')
            handleAddMacro()
          }}
          onCancel={() => {
            setFilterQuery('')
            setModalOpen(false)
          }}
          className='responsive-modal-macros'
        >
          <Row>
            <Col span={24} style={{ marginBottom: 12 }}>
              <Space wrap style={{ width: '100%' }}>
                <p>Search:</p>
                <Input
                  placeholder='Type to search...'
                  value={filterQuery}
                  style={{ width: 350, maxWidth: '100%' }}
                  onChange={(e) => {
                    setFilterQuery(e.target.value)
                  }}
                  addonAfter={<FontAwesomeIcon icon={faSearch} />}
                />
                <Button
                  style={{ color: token.colorError }}
                  onClick={() => {
                    setFilterQuery('')
                  }}
                >
                  Clear
                </Button>
              </Space>
            </Col>
            <Col span={24}>
              <Collapse>
                {/* eslint-disable-next-line */}
                {bookingData?.clinicRecord?.billingMacros.map((macro, index) => {
                  if (macro.isActive) {
                    return (
                      <Collapse.Panel
                        style={{ display: filteredMacros().includes(macro.name) ? '' : 'none' }}
                        header={macro.name}
                        key={index}
                        extra={
                          <Checkbox
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                            onChange={(e) => {
                              handleCheckboxChange(e.target.checked, macro)
                            }}
                          />
                        }
                      >
                        <Table
                          dataSource={macro.items}
                          size='small'
                          bordered={true}
                          pagination={false}
                          columns={[
                            { title: 'Type', dataIndex: 'type', key: 'type' },
                            { title: 'Code', dataIndex: 'code', key: 'code' },
                            { title: 'Name', dataIndex: 'name', key: 'name' },
                            { title: 'QTY', dataIndex: 'qty', key: 'qty' }
                          ]}
                        />
                      </Collapse.Panel>
                    )
                  }
                })}
              </Collapse>
            </Col>
          </Row>
        </Modal>
      </CustomRow>
      <Modal
        title='Benefit Check Response'
        open={!!benefitCheckResponse}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setBenefitCheckResponse(null)}
        width={1000}
      >
        {benefitCheckResponse && (
          <div>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Card
                  title='Medical Aid Details'
                  size='small'
                  type='inner'
                  styles={{ header: { backgroundColor: token.colorPrimary, color: token.colorWhite } }}
                >
                  <p>
                    <strong>Scheme: </strong>
                    {
                      benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].MemberValidation[0]
                        .CommonClaimHeaderInfo[0].Payor[0].Name[0]
                    }
                  </p>
                  <p>
                    <strong>Policy Number: </strong>
                    {
                      benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].MemberValidation[0]
                        .CommonClaimHeaderInfo[0].Policy[0].PolicyNumber[0]
                    }
                  </p>
                  <p>
                    <strong>Benefit Check Amount: </strong>R{' '}
                    {
                      benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].MemberValidation[0]
                        .CommonClaimHeaderInfo[0].Policy[0].BenefitCheckAmount[0]
                    }
                  </p>
                  <p>
                    <strong>Claim Option: </strong>
                    {
                      benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].MemberValidation[0]
                        .CommonClaimHeaderInfo[0].Payor[0].ClaimOption[0]
                    }
                  </p>
                  <p>
                    <strong>Option/Plan Reference: </strong>
                    {
                      benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].MemberValidation[0]
                        .CommonClaimHeaderInfo[0].Payor[0].OptionOrPlanReference[0]
                    }
                  </p>
                </Card>
              </Col>

              <Col span={24}>
                <Card
                  title='Practice Details'
                  size='small'
                  type='inner'
                  styles={{ header: { backgroundColor: token.colorPrimary, color: token.colorWhite } }}
                >
                  <p>
                    <strong>Practice Name: </strong>
                    {
                      benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].MemberValidation[0]
                        .CommonClaimHeaderInfo[0].BillingPractice[0].Name[0]
                    }
                  </p>
                  <p>
                    <strong>PCNS Number: </strong>
                    {
                      benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].MemberValidation[0]
                        .CommonClaimHeaderInfo[0].BillingPractice[0].PCNSNumber[0]
                    }
                  </p>
                  <p>
                    <strong>Speciality Code: </strong>
                    {
                      benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].MemberValidation[0]
                        .CommonClaimHeaderInfo[0].BillingPractice[0].SpecialityCode[0]
                    }
                  </p>
                  <p>
                    <strong>Address: </strong>
                    {
                      benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].MemberValidation[0]
                        .CommonClaimHeaderInfo[0].BillingPractice[0].ContactInformation[0].AddressLineOne[0]
                    }
                  </p>
                  <p>
                    <strong>Telephone: </strong>
                    {
                      benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].MemberValidation[0]
                        .CommonClaimHeaderInfo[0].BillingPractice[0].ContactInformation[0].TelephoneWork[0]
                    }
                  </p>
                </Card>
              </Col>

              <Col span={24}>
                <Card
                  title='Response Details'
                  size='small'
                  type='inner'
                  styles={{ header: { backgroundColor: token.colorSecondary, color: token.colorWhite } }}
                >
                  <Row gutter={[12, 12]}>
                    <Col span={24}>
                      <p>
                        <strong>Transaction ID: </strong>
                        {
                          benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0]
                            .ResponseNumber[0].Transaction[0].$.Id
                        }
                      </p>
                      <p>
                        <strong>Claim Number: </strong>
                        {
                          benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0]
                            .MemberValidation[0].CommonClaimHeaderInfo[0].ClaimNumber[0]
                        }
                      </p>
                      <p>
                        <strong>Transaction Status: </strong>
                        {
                          benefitCheckResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0]
                            .PrimaryTrackingStatus[0]
                        }
                      </p>
                      <p>
                        <strong>Result Code: </strong>
                        {benefitCheckResponse.ProviderClaimResponse.MemberValidationResponse[0].ResponseDetail[0]
                          .ResultCode[0] === '01' ? (
                          <span style={{ color: token.colorError }}>01</span>
                        ) : (
                          <span style={{ color: token.colorSuccess }}>02</span>
                        )}
                      </p>
                      <p>
                        <strong>Result Description: </strong>
                        {benefitCheckResponse.ProviderClaimResponse.MemberValidationResponse[0].ResponseDetail[0]
                          .ResultCode[0] === '01' ? (
                          <span style={{ color: token.colorError }}>
                            {
                              benefitCheckResponse.ProviderClaimResponse.MemberValidationResponse[0].ResponseDetail[0]
                                .ResultDescription[0]
                            }
                          </span>
                        ) : (
                          <span style={{ color: token.colorSuccess }}>
                            {
                              benefitCheckResponse.ProviderClaimResponse.MemberValidationResponse[0].ResponseDetail[0]
                                .ResultDescription[0]
                            }
                          </span>
                        )}
                      </p>
                      <p>
                        <strong>Comments: </strong>
                        {
                          benefitCheckResponse.ProviderClaimResponse.MemberValidationResponse[0].ResponseDetail[0]
                            .Comments[0]
                        }
                      </p>
                    </Col>

                    <Col span={24}>
                      <Collapse defaultActiveKey={[]}>
                        {benefitCheckResponse.ProviderClaimResponse.MemberValidationResponse[0].ResponseDetail[0].Patient?.map(
                          (patient, index) => (
                            <Collapse.Panel
                              key={patient.$.id}
                              header={
                                <Space>
                                  <strong style={{ color: token.colorPrimary }}>{`Patient ${index + 1}:`}</strong>
                                  <span>
                                    {`${patient.CommonPersonInfo[0].Title[0]} ${patient.FirstName[0]} ${patient.CommonPersonInfo[0].Surname[0]}`}
                                  </span>
                                  <Tag
                                    color={
                                      patient.MedAidStatusDesc[0].toLowerCase().includes('valid') ? 'success' : 'error'
                                    }
                                  >
                                    {patient.MedAidStatusDesc[0]}
                                  </Tag>
                                </Space>
                              }
                            >
                              <div style={{ padding: '0 12px' }}>
                                <Row gutter={[24, 12]}>
                                  <Col xs={24} sm={12}>
                                    <Card size='small' title='Personal Details' bordered={false}>
                                      <p>
                                        <strong>ID Number: </strong>
                                        {patient.CommonPersonInfo[0].IdentityNumber[0]}
                                      </p>
                                      <p>
                                        <strong>Dependant Code: </strong>
                                        {patient.DependantCode[0]}
                                      </p>
                                      <p>
                                        <strong>Dependant Type: </strong>
                                        {patient.DependantType[0]}
                                      </p>
                                    </Card>
                                  </Col>
                                  <Col xs={24} sm={12}>
                                    <Card
                                      size='small'
                                      title='Benefit Status'
                                      bordered={false}
                                      styles={{
                                        header: {
                                          background: patient.MedAidBenefitDesc[0].toLowerCase().includes('enough')
                                            ? token.colorSuccessBg
                                            : token.colorErrorBg
                                        }
                                      }}
                                    >
                                      <p
                                        style={{
                                          color: patient.MedAidBenefitDesc[0].toLowerCase().includes('enough')
                                            ? token.colorSuccess
                                            : token.colorError
                                        }}
                                      >
                                        {patient.MedAidBenefitDesc[0]}
                                      </p>
                                    </Card>
                                  </Col>

                                  {patient.AuthData[0].limitavailable && (
                                    <Col span={24}>
                                      <Card size='small' title='Available Limits' bordered={false}>
                                        <Table
                                          dataSource={patient.AuthData[0].limitavailable}
                                          size='small'
                                          pagination={false}
                                          columns={[
                                            {
                                              title: 'Description',
                                              dataIndex: ['LimitDescription', 0],
                                              key: 'description',
                                              render: (text) => text.replace('*', '')
                                            },
                                            {
                                              title: 'Available',
                                              dataIndex: ['Available', 0],
                                              key: 'available',
                                              align: 'right',
                                              render: (value) => (
                                                <span
                                                  style={{
                                                    color:
                                                      parseFloat(value) > 0 ? token.colorSuccess : token.colorError,
                                                    fontWeight: 'bold'
                                                  }}
                                                >
                                                  R {parseFloat(value).toFixed(2)}
                                                </span>
                                              )
                                            }
                                          ]}
                                        />
                                      </Card>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            </Collapse.Panel>
                          )
                        )}
                      </Collapse>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default Billing
