import Agilite from 'agilite'
import store from '../../store'
import pdfGenerationEnums from '../Medical Vault/diagnosis-history/utils/enums'
import reportGenerationEnums from '../Admin/Reporting/reporting-enums'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const getInvoices = (filter = {}, page = 1, pageLimit = 10, projection = '') => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        filter = {
          ...filter,
          entityRef: store.getState().core.entity._id
        }

        const response = await agilite.Connectors.execute('invoices', 'read', {
          filter: JSON.stringify(filter),
          page,
          pageLimit,
          projection
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const countInvoices = (filter = {}) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        filter = {
          ...filter,
          entityRef: store.getState().core.entity._id
        }

        const response = await agilite.Connectors.execute('invoices', 'count', {
          filter: JSON.stringify(filter)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createInvoice = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        data = {
          ...data,
          entityRef: store.getState().core.entity._id
        }

        const response = await agilite.Connectors.execute('invoices', 'create', {
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateInvoice = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const response = await agilite.Connectors.execute('invoices', 'update', {
          recordId,
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createPatientPayment = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        data = {
          ...data,
          entityRef: store.getState().core.entity._id
        }

        const response = await agilite.Connectors.execute('patient_payments', 'create', {
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getPatientPayments = (filter, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        filter = {
          ...filter,
          entityRef: store.getState().core.entity._id
        }

        const response = await agilite.Connectors.execute('patient_payments', 'read', {
          filter: JSON.stringify(filter),
          page,
          pageLimit
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getRemittances = (filter, page, pageSize) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        filter = {
          ...filter,
          entityRef: store.getState().core.entity._id
        }

        const response = await agilite.Connectors.execute('medical_aid_remittances', 'read', {
          filter: JSON.stringify(filter),
          page,
          pageSize
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createRemittance = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        data = {
          ...data,
          entityRef: store.getState().core.entity._id
        }

        const response = await agilite.Connectors.execute('medical_aid_remittances', 'create', {
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateRemittance = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const response = await agilite.Connectors.execute('medical_aid_remittances', 'update', {
          recordId,
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const countRemittances = (filter = {}) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        filter = {
          ...filter,
          entityRef: store.getState().core.entity._id
        }

        const response = await agilite.Connectors.execute('medical_aid_remittances', 'count', {
          filter: JSON.stringify(filter)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const generateCraftMyPdfReport = (payload, templateId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let pdfResponse = null
      try {
        pdfResponse = await agilite.Connectors.execute(
          pdfGenerationEnums.pdfGeneration.CRAFT_MY_PDF,
          reportGenerationEnums.routes.CREATE,
          {
            data: JSON.stringify({ data: payload }),
            templateId: JSON.stringify(templateId)
          }
        )

        if (pdfResponse.data && pdfResponse.data.status === 'success') {
          resolve(pdfResponse.data.file)
        } else {
          reject('Error generating PDF')
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}
