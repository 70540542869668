const ClinicEnums = {
  keys: {},
  titles: {},
  profileKeys: {
    COMPANIES_CONTRACTORS: 'companies_contractors'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update'
  }
}

export default ClinicEnums
