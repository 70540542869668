import React from 'react'
import { Space, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faMedkit, 
  faPills, 
  faCapsules, 
  faSyringe, 
  faEyeDropper, 
  faPumpMedical,
  faSprayCan,
  faVial,
  faPrescriptionBottle,
  faFlaskVial
} from '@fortawesome/free-solid-svg-icons'
import { medAdminTypes } from '../../../../../utils/templates'
import { normalizePresentationCode } from '../utils/normalizers'
import { DOSAGE_FORMS } from '../constants/dosageForms'
import { getQuantitySuffix } from '../utils/formatters'

const formatMedicationName = (name) => {
  if (!name) return ''
  
  // Split by spaces and handle each word
  return name.split(' ').map(word => {
    // Convert word to lowercase first
    word = word.toLowerCase()
    
    // Special case for words that should remain uppercase
    if (word.match(/^[A-Z0-9/]+$/)) {
      return word.toUpperCase()
    }
    
    // Capitalize first letter of each word, unless it's a lowercase conjunction/preposition
    if (!['and', 'with', 'in', 'of', 'for'].includes(word)) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
    
    return word
  }).join(' ')
}

const getDosageFormIcon = (dosageForm) => {
  switch (dosageForm?.toLowerCase()) {
    case 'tablet':
      return <FontAwesomeIcon icon={faPills} />
    case 'capsule':
      return <FontAwesomeIcon icon={faCapsules} />
    case 'injection':
      return <FontAwesomeIcon icon={faSyringe} />
    case 'suspension':
    case 'syrup':
      return <FontAwesomeIcon icon={faPrescriptionBottle} />
    case 'inhaler':
      return <FontAwesomeIcon icon={faPumpMedical} />
    case 'drops':
      return <FontAwesomeIcon icon={faEyeDropper} />
    case 'cream':
    case 'ointment':
      return <FontAwesomeIcon icon={faFlaskVial} />
    case 'nasal_spray':
      return <FontAwesomeIcon icon={faSprayCan} />
    default:
      return <FontAwesomeIcon icon={faMedkit} />
  }
}

const getScheduleTagColor = (schedule) => {
  const scheduleNum = parseInt(schedule)
  if (scheduleNum <= 3) return { background: '#f6ffed', color: '#237804', border: '1px solid #b7eb8f' }
  if (scheduleNum === 4) return { background: '#fffbe6', color: '#d46b08', border: '1px solid #ffe58f' }
  return { background: '#fff0f6', color: '#c41d7f', border: '1px solid #ffadd2' }
}

const getPackSizeUnit = (presentationCode) => {
  const normalizedForm = normalizePresentationCode(presentationCode)
  switch (normalizedForm) {
    case 'tablet':
      return 'tab'
    case 'capsule':
      return 'caps'
    case 'suspension':
    case 'syrup':
      return 'ml'
    case 'inhaler':
      return 'doses'
    case 'drops':
      return 'ml'
    case 'cream':
    case 'ointment':
      return 'g'
    case 'nasal_spray':
      return 'ml'
    default:
      return 'units'
  }
}

const MedicationDisplay = ({ med, index, onEdit, selectedRoute, estimatedQuantity }) => {
  const { token } = theme.useToken()
  
  // Check if all required dosage instructions are complete
  const isDosageComplete = med.scriptingDosage && 
    med.scriptingDosage !== 'No scripting dosage specified.' &&
    (
      // More flexible checks for duration
      med.scriptingDosage.includes('for') || 
      med.scriptingDosage.includes('days') || 
      med.scriptingDosage.includes('weeks') || 
      med.scriptingDosage.includes('months')
    ) && 
    (
      // More flexible checks for dose
      med.scriptingDosage.includes('Take') || 
      med.scriptingDosage.includes('take') || 
      med.scriptingDosage.includes('ml') || 
      med.scriptingDosage.includes('puff') || 
      med.scriptingDosage.includes('tablet') || 
      med.scriptingDosage.includes('tablets') || 
      med.scriptingDosage.includes('cap') || 
      med.scriptingDosage.includes('mg') || 
      /\d+\s*(tab|caps|ml|mg|g|mcg|dose|puff|drop|unit)/i.test(med.scriptingDosage)
    ) && 
    (
      // More flexible checks for frequency
      med.scriptingDosage.includes('hrly') || 
      med.scriptingDosage.includes('hourly') || 
      med.scriptingDosage.includes('Daily') || 
      med.scriptingDosage.includes('daily') || 
      med.scriptingDosage.includes('needed') || 
      med.scriptingDosage.includes('as needed') || 
      med.scriptingDosage.includes('times') || 
      med.scriptingDosage.includes('per day') || 
      med.scriptingDosage.includes('weekly') || 
      med.scriptingDosage.includes('monthly') || 
      /\d+\s*times/i.test(med.scriptingDosage) || 
      /\d+\s*per day/i.test(med.scriptingDosage)
    )

  const formattedName = formatMedicationName(med.name)
  const dosageForm = normalizePresentationCode(med.presentationCode)

  return (
    <div 
      onClick={onEdit}
      style={{
        padding: '16px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        border: `1px solid ${isDosageComplete ? '#d9d9d9' : token.colorError}`,
        borderRadius: '8px',
        margin: '4px 0',
        background: '#fff'
      }}
      className="medication-item"
    >
      <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <div style={{ flex: 1 }}>
          <Space style={{ gap: 0 }}>
            <div style={{ color: med.isChronic ? '#F39C12' : token.colorPrimary, width: 22 }}>
              {getDosageFormIcon(dosageForm)}
            </div>
            <Space align="center" size={4}>
              <span style={{ fontWeight: 500 }}>
                {`${index + 1}. ${formattedName} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
                  med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
                }${selectedRoute ? ` (${selectedRoute})` : ''}${med.schedule ? `, ${med.schedule}` : ''} - ${DOSAGE_FORMS.find((f) => f.value === dosageForm)?.label || med.presentationCode}`}
              </span>
              {med.drugSchedule && (
                <div
                  style={{
                    padding: '1px 6px',
                    borderRadius: '4px',
                    fontSize: '14px',
                    fontWeight: '600',
                    ...getScheduleTagColor(med.drugSchedule)
                  }}
                >
                  S{med.drugSchedule}
                </div>
              )}
              {med.dispensingVolumePacksize && (
                <div
                  style={{
                    padding: '1px 6px',
                    borderRadius: '4px',
                    fontSize: '14px',
                    fontWeight: '600',
                    background: '#e6f4ff',
                    color: '#1677ff',
                    border: '1px solid #91caff'
                  }}
                >
                  {med.dispensingVolumePacksize}{getPackSizeUnit(med.presentationCode)}
                </div>
              )}
            </Space>
          </Space>
          {isDosageComplete ? (
            <div
              style={{
                paddingLeft: 22,
                marginTop: 4,
                color: 'rgba(0, 0, 0, 0.65)',
                fontStyle: 'italic'
              }}
            >
              <div>
                {med.scriptingDosage} 
                {estimatedQuantity ? ` - Est. quantity: ${estimatedQuantity} ${getQuantitySuffix(med.presentationCode, estimatedQuantity)}` : ''}
              </div>
              {med.additionalNotes && (
                <div>
                  Comments: {med.additionalNotes}
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                paddingLeft: 22,
                marginTop: 4,
                color: token.colorError,
                fontStyle: 'italic'
              }}
            >
              Dosage instructions incomplete
            </div>
          )}
        </div>
        <div
          className="edit-text"
          style={{
            color: isDosageComplete ? (med.isChronic ? '#F39C12' : token.colorPrimary) : token.colorError,
            fontSize: '13px'
          }}
        >
          Edit
        </div>
      </Space>
    </div>
  )
}

export default MedicationDisplay
