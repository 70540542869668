import React, { useState } from 'react'
import { Col, Layout, message, Tabs } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faFileAlt, faVial } from '@fortawesome/free-solid-svg-icons'
import ResultsHistory from './Results/ResultsHistory/ResultsHistory'
import HealthInsights from './Health-Insights/HealthInsights'
import QuickBook from './QuickBook/QuickBook'
import CareSyncOverview from './Overview/CareSyncOverview'
import ResultDetail from './Results/ResultDetail/ResultDetail'
import { dashboardData } from '../../mockdata/dashboardData'
import '../styling/PatCSMobileWrapper.css'
import careSyncIcon from '../../../../../assets/images/caresync_icon.png'
import CustomRow from '../../../../reusable-components/CustomRow'
import QuickActionsScreen from './QuickActions/QuickActionsScreen'
import TestMenu from './TestMenu'
import { useDispatch, useSelector } from 'react-redux'
import { readCareSyncLabTests } from '../../../../Super-Admin/CareSync/LabTests/utils/utils'
import { readClinicalEntries } from '../../../../Examination/examination-utils/examination-lib'
import { readClinicDataPoints } from '../../../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import { useEffect } from 'react'
import { careSyncDataPointsReducer, careSyncTestResultsReducer, careSyncTestsReducer } from '../../utils/reducers'

const PatCSMobileWrapper = () => {
  const [activeTab, setActiveTab] = useState('overview')
  const [showBookingSuccess, setShowBookingSuccess] = useState(false)
  const [preselectedTest, setPreselectedTest] = useState(null)
  const [selectedResult, setSelectedResult] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const { recentResults } = dashboardData

  const handleBookingComplete = () => {
    setActiveTab('overview')
    setShowBookingSuccess(true)
    setTimeout(() => setShowBookingSuccess(false), 5000)
  }

  const handleOverviewAction = (action, data = null) => {
    if (action === 'quickbook') {
      setPreselectedTest(data)
      setActiveTab('book')
    } else if (action === 'resultDetail') {
      const index = recentResults.findIndex((r) => r.id === data.id)
      setSelectedResult(data)
      setSelectedIndex(index)
    } else {
      setPreselectedTest(null)
      setActiveTab(action)
    }
  }

  const handleNext = () => {
    if (selectedIndex < recentResults.length - 1) {
      const nextIndex = selectedIndex + 1
      setSelectedIndex(nextIndex)
      setSelectedResult(recentResults[nextIndex])
    }
  }

  const handlePrevious = () => {
    if (selectedIndex > 0) {
      const prevIndex = selectedIndex - 1
      setSelectedIndex(prevIndex)
      setSelectedResult(recentResults[prevIndex])
    }
  }

  // CARESYNC PROD PREP
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const authState = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const fetchData = async () => {
    try {
      setLoading(true)
      const tmpTests = await readCareSyncLabTests()

      const tmpTestResults = await readClinicalEntries({
        userRef: authState.agiliteUser._id,
        category: 'poc'
      })
      const tmpDataPoints = await readClinicDataPoints({
        key: { $in: tmpTestResults.map((result) => result.key) }
      })

      dispatch(careSyncTestsReducer.actions.setRecords(tmpTests))
      dispatch(careSyncTestResultsReducer.actions.setRecords(tmpTestResults))
      dispatch(careSyncDataPointsReducer.actions.setRecords(tmpDataPoints))
    } catch (error) {
      message.error('Error')
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  // END CARESYNC PROD PREP

  const renderContent = () => {
    if (activeTab === 'quickActions') {
      return (
        <QuickActionsScreen
          onActionClick={(action) => {
            setActiveTab(action)
          }}
          onBack={() => setActiveTab('overview')}
        />
      )
    }

    if (activeTab === 'tests') {
      return <TestMenu onBack={() => setActiveTab('overview')} />
    }

    return (
      <Layout className='caresync-container'>
        <Tabs
          activeKey={activeTab}
          onChange={(key) => {
            setPreselectedTest(null)
            setActiveTab(key)
          }}
          items={[
            {
              key: 'overview',
              label: (
                <span className='tab-label'>
                  <img src={careSyncIcon} alt='CareSync' className='cs-tab-icon' />
                  Overview
                </span>
              ),
              children: selectedResult ? (
                <ResultDetail
                  result={selectedResult}
                  onBack={() => {
                    setSelectedResult(null)
                    setSelectedIndex(null)
                  }}
                  onNext={handleNext}
                  onPrevious={handlePrevious}
                  hasNext={selectedIndex < recentResults.length - 1}
                  hasPrevious={selectedIndex > 0}
                />
              ) : (
                <CareSyncOverview
                  showBookingSuccess={showBookingSuccess}
                  onTabChange={handleOverviewAction}
                  setActiveTab={setActiveTab}
                />
              )
            },
            // {
            //   key: 'book',
            //   label: (
            //     <span className='tab-label'>
            //       <FontAwesomeIcon icon={faVial} />
            //       Book Test
            //     </span>
            //   ),
            //   children: (
            //     <QuickBook
            //       onComplete={handleBookingComplete}
            //       onBack={() => setActiveTab('overview')}
            //       preselectedTest={preselectedTest}
            //     />
            //   )
            // },
            {
              key: 'results',
              label: (
                <span className='tab-label'>
                  <FontAwesomeIcon icon={faFileAlt} />
                  Results
                </span>
              ),
              children: <ResultsHistory />
            }
            // {
            //   key: 'insights',
            //   label: (
            //     <span className='tab-label'>
            //       <FontAwesomeIcon icon={faChartLine} />
            //       Insights
            //     </span>
            //   ),
            //   children: <HealthInsights />
            // }
          ]}
          className='caresync-tabs'
          animated={{ tabPane: true }}
        />
      </Layout>
    )
  }

  return renderContent()
}

export default PatCSMobileWrapper
