import { Form } from 'antd'
import React, { useEffect, useState } from 'react'

import CheckinQuestionarre from '../../../Auth/components/checkin-questionnarre'

const ServiceCheckinPreview = ({ data }) => {
  const [currentSelection, setCurrentSelection] = useState()
  const [patientSelection, setPatientSelection] = useState([])
  const [patientInput, setPatientInput] = useState()

  useEffect(() => {
    setPatientSelection([])
    setPatientInput(null)
  }, [currentSelection])

  const [clientForm] = Form.useForm()
  const handleSubmit = () => {}
  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked ? [...patientSelection, tag] : patientSelection.filter((t) => t !== tag)
    clientForm.setFieldsValue({
      patientSelection: []
    })
    setPatientSelection(nextSelectedTags)
  }

  return (
    <CheckinQuestionarre
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      bookingService={data}
      currentSelection={currentSelection}
      setCurrentSelection={setCurrentSelection}
      patientSelection={patientSelection}
      setPatientSelection={setPatientSelection}
      patientInput={patientInput}
      setPatientInput={setPatientInput}
    />
  )
}
export default ServiceCheckinPreview
