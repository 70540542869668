import React from 'react'
import { Button, Form, InputNumber } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import '../styles/medicationCard.css'

const getCommonDosages = (dosageForm) => {
  const tabletCapsuleOptions = [
    { value: '1', label: 'Take 1' },
    { value: '2', label: 'Take 2' },
    { value: '3', label: 'Take 3' }
  ]

  switch (dosageForm?.toLowerCase()) {
    case 'tablet':
    case 'capsule':
      return tabletCapsuleOptions
    case 'suspension':
    case 'syrup':
      return [
        { value: '5', label: '5 ml' },
        { value: '10', label: '10 ml' },
        { value: '15', label: '15 ml' },
        { value: '20', label: '20 ml' }
      ]
    case 'injection':
      return [
        { value: '1', label: '1 ml' },
        { value: '2', label: '2 ml' },
        { value: '3', label: '3 ml' },
        { value: '5', label: '5 ml' }
      ]
    case 'inhaler':
      return [
        { value: '1', label: '1 puff' },
        { value: '2', label: '2 puffs' }
      ]
    case 'drops':
      return [
        { value: '1', label: '1 drop' },
        { value: '2', label: '2 drops' },
        { value: '3', label: '3 drops' }
      ]
    case 'cream':
    case 'ointment':
      return [
        { value: '1', label: 'Apply thin layer' }
      ]
    default:
      return tabletCapsuleOptions
  }
}

const getUnitSuffix = (dosageForm, unitOfMeasure) => {
  switch (dosageForm?.toLowerCase()) {
    // Tablet/Capsule Forms
    case 'tablet':
      return 'tab(s)'
    case 'capsule':
      return 'capsule(s)'
    
    // Liquid Forms
    case 'suspension':
    case 'syrup':
    case 'solution':
    case 'liquid':
    case 'enema':
    case 'douche':
    case 'mouthwash':
      return 'ml'
    
    // Injectable Forms
    case 'injection':
    case 'implant':
      return unitOfMeasure || 'ml'
    
    // Inhalation Forms
    case 'inhaler':
    case 'gas':
      return 'puff(s)'
    
    // Drop Forms
    case 'drops_eye':
    case 'drops_ear':
    case 'drops_external':
    case 'drops_oral':
    case 'nose_drops':
      return 'drop(s)'
    
    // Topical Forms
    case 'cream':
    case 'ointment':
    case 'gel':
    case 'lotion':
    case 'paint':
    case 'eye_ointment':
    case 'eye_gel':
    case 'nasal_gel':
    case 'vaginal_cream':
    case 'vaginal_gel':
      return 'application(s)'
    
    // Spray Forms
    case 'spray_external':
    case 'nasal_spray':
      return 'spray(s)'
    
    // Powder Forms
    case 'powder_oral':
    case 'powder_external':
    case 'granules':
      return 'measure(s)'
    
    // Patch/Pad Forms
    case 'patch':
    case 'pad':
    case 'plaster':
      return 'patch(es)'
    
    // Special Forms
    case 'suppository':
      return 'suppository(ies)'
    case 'vaginal_ring':
      return 'ring(s)'
    case 'vaginal_tablet':
      return 'tab(s)'
    case 'lozenge':
      return 'lozenge(s)'
    case 'chewing_gum':
      return 'piece(s)'
    case 'sachet':
      return 'sachet(s)'
    case 'wafer':
      return 'wafer(s)'
    
    // Default case
    default:
      return unitOfMeasure || ''
  }
}

const DoseField = ({ 
  selectedDose, 
  defaultDose, 
  scriptingDosage,
  unitOfMeasure, 
  dosageForm,
  onSelect, 
  onClear, 
  onInputChange, 
  onInputComplete, 
  className 
}) => {
  const commonDosages = getCommonDosages(dosageForm)
  const showUnitSuffix = true
  const suffix = getUnitSuffix(dosageForm, unitOfMeasure)
  const isLiquidForm = ['suspension', 'syrup', 'injection'].includes(dosageForm?.toLowerCase())
  const isTabletOrCapsule = ['tablet', 'capsule'].includes(dosageForm?.toLowerCase())

  const formatDoseDisplay = (dose) => {
    if (isTabletOrCapsule) {
      const unit = dose === '1' ? suffix.replace('(s)', '') : suffix.replace('(s)', 's')
      return `Take ${dose} ${unit}`
    }
    if (isLiquidForm) {
      return `${dose} ml`
    }
    return `${dose} ${suffix}`
  }

  return (
    <div className="field-container">
      <div className="field-label">Dose</div>
      <div className={`medication-field ${className || ''}`}>
        {!selectedDose ? (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              alignItems: 'center'
            }}
          >
            {!isTabletOrCapsule && !isLiquidForm && scriptingDosage && (
              <Button
                type='text'
                onClick={() => onSelect(scriptingDosage)}
                style={{ padding: '4px 15px', height: 'auto' }}
              >
                {scriptingDosage} {suffix}
              </Button>
            )}
            {!isTabletOrCapsule && !isLiquidForm && defaultDose && defaultDose !== scriptingDosage && (
              <Button
                type='text'
                onClick={() => onSelect(defaultDose)}
                style={{ padding: '4px 15px', height: 'auto' }}
              >
                {defaultDose} {suffix}
              </Button>
            )}
            {commonDosages.map(option => (
              <Button
                key={option.value}
                type='text'
                onClick={() => onSelect(option.value)}
                style={{ padding: '4px 15px', height: 'auto' }}
              >
                {option.label}
              </Button>
            ))}
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <InputNumber
                style={{ width: 100 }}
                placeholder={isLiquidForm ? 'ml...' : 'Custom...'}
                onChange={onInputChange}
                onPressEnter={(e) => onInputComplete(e.target.value)}
                onBlur={(e) => onInputComplete(e.target.value)}
                controls={false}
              />
              {showUnitSuffix && <span>{suffix}</span>}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}
          >
            <div className='medication-field-selected'>
              <CheckOutlined />
              <span>{formatDoseDisplay(selectedDose)}</span>
            </div>
            <Button type='text' size='small' onClick={onClear} style={{ padding: '0 4px', height: 'auto' }}>
              ×
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default DoseField
