import React, { memo } from 'react'
import { Col, Input, Modal, Row, Space, Switch, Typography } from 'antd'
import BillingTool from '../../../Bookings/components/Examination Components/BillingTool'
import useBillingMacroForm from '../hooks/useBillingMacroForm'
import PropTypes from 'prop-types'

const { Text } = Typography

const BillingMacroForm = memo(({ visible, setVisible, isNew, macro, setMacro, macros, setMacros, index }) => {
  const { macroName, handleOk, handleCancel, handleFieldChange, handleItemSelection } = useBillingMacroForm({
    isNew,
    macro,
    setMacro,
    macros,
    setMacros,
    index,
    setVisible
  })

  return (
    <Modal
      title={isNew ? 'New Macro' : `Edit ${macroName} Macro`}
      destroyOnClose
      open={visible}
      closable={false}
      onOk={handleOk}
      onCancel={handleCancel}
      width='70%'
      maskClosable={false}
      centered
    >
      <Row style={{ marginBottom: 10 }}>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Space direction='vertical' style={{ width: '100%' }} size='small'>
            <div>
              <Text strong>Is Active:</Text>
              <Switch
                checked={macro.isActive}
                onChange={(checked) => handleFieldChange('isActive', checked)}
                checkedChildren='Yes'
                unCheckedChildren='No'
                style={{ marginLeft: 8 }}
              />
            </div>
            <div>
              <Text strong>Name:</Text>
              <Input
                value={macro.name}
                onChange={(e) => handleFieldChange('name', e.target.value)}
                placeholder='Enter macro name'
                maxLength={50}
                allowClear
                style={{ marginTop: 4 }}
              />
            </div>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BillingTool
            billing={{}}
            itemSelection={macro.items}
            setItemSelection={handleItemSelection}
            readOnly={false}
            isMacroForm={true}
          />
        </Col>
      </Row>
    </Modal>
  )
})

BillingMacroForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  macro: PropTypes.shape({
    isActive: PropTypes.bool,
    name: PropTypes.string,
    items: PropTypes.array
  }).isRequired,
  setMacro: PropTypes.func.isRequired,
  macros: PropTypes.array.isRequired,
  setMacros: PropTypes.func.isRequired,
  index: PropTypes.number
}

BillingMacroForm.displayName = 'BillingMacroForm'

export default BillingMacroForm
