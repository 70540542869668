import React from 'react'
import {
  faChainBroken,
  faRefresh,
  faCheckCircle,
  faNotesMedical,
  faSearch,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Select, Space, Spin, Tooltip, theme, Tag, Typography, Alert, Badge } from 'antd'

import useDiagnosis from '../hooks/useDiagnosis'
import { DIANGOSIS_ERROR_MESSAGE } from '../utils/constants'
import { hexToRGBA } from '../../../../lib/utils'

const { Title, Text } = Typography

// Constants
const DIAGNOSIS_TAG_COLOR = '#4169E1' // Royal blue color for diagnosis tags

// Styles for consistent card appearance and responsiveness
const cardStyles = {
  width: '100%',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
  overflow: 'hidden',
  marginBottom: '16px'
}

const DiagnosisCapture = ({ bookingData }) => {
  const {
    selectedDiagnoses,
    icd10Data,
    isLoading,
    isSaveError,
    handleDiagnosisSelect,
    handleDiagnosisRemove,
    updateDiagnoses,
    fetchIcd10Data,
    setState
  } = useDiagnosis({ bookingData })

  const { token } = theme.useToken()

  // Memoized diagnosis tags render function
  const DiagnosisTags = React.memo(() => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: selectedDiagnoses.length > 0 ? 16 : 8,
        gap: 8,
        width: '100%'
      }}
    >
      {selectedDiagnoses.map((diagnosis) => (
        <Tag
          key={diagnosis.code}
          closable
          onClose={() => handleDiagnosisRemove(diagnosis)}
          style={{
            backgroundColor: hexToRGBA(DIAGNOSIS_TAG_COLOR, 0.08),
            borderColor: hexToRGBA(DIAGNOSIS_TAG_COLOR, 0.2),
            color: DIAGNOSIS_TAG_COLOR,
            borderRadius: '16px',
            padding: '4px 8px 4px 12px',
            marginBottom: '5px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '13px',
            boxShadow: '0 1px 2px rgba(0,0,0,0.05)'
          }}
          closeIcon={
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                fontSize: '10px',
                color: DIAGNOSIS_TAG_COLOR
              }}
            />
          }
        >
          <span style={{ fontWeight: 500 }}>{diagnosis.description}</span>
          <span
            style={{
              marginLeft: 4,
              opacity: 0.7,
              fontSize: '11px',
              fontWeight: 400
            }}
          >
            ({diagnosis.code})
          </span>
        </Tag>
      ))}
    </div>
  ))

  return (
    <Card
      styles={{
        header: {
          background: hexToRGBA(token.colorPrimary, 0.2), // Match the clinical notes card header
          borderBottom: `1px solid ${hexToRGBA(token.colorPrimary, 0.1)}`,
          padding: '12px 16px'
        },
        body: {
          padding: '16px'
        }
      }}
      size='small'
      title={
        <Space>
          <FontAwesomeIcon
            icon={faNotesMedical}
            style={{
              color: token.colorPrimary,
              fontSize: '16px'
            }}
          />
          <Title level={5} style={{ margin: 0 }}>
            Diagnosis
          </Title>
          {selectedDiagnoses.length > 0 && (
            <Badge
              count={selectedDiagnoses.length}
              style={{
                backgroundColor: DIAGNOSIS_TAG_COLOR,
                marginLeft: 8
              }}
            />
          )}
        </Space>
      }
      extra={
        <Space>
          {isLoading && <Spin size='small' />}
          {isSaveError && (
            <>
              <Tooltip title='Error Saving Diagnosis'>
                <FontAwesomeIcon icon={faChainBroken} color={token.colorError} />
              </Tooltip>
              <Button
                type='primary'
                danger
                size='small'
                onClick={() => updateDiagnoses(selectedDiagnoses)}
                icon={<FontAwesomeIcon icon={faRefresh} />}
              >
                Retry
              </Button>
            </>
          )}
          {!isSaveError && !isLoading && selectedDiagnoses.length > 0 && (
            <Tooltip title='Diagnosis Captured Successfully'>
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{
                  color: token.colorSuccess,
                  fontSize: '16px'
                }}
              />
            </Tooltip>
          )}
        </Space>
      }
      style={cardStyles}
    >
      <div
        style={{
          width: '100%',
          background: isSaveError ? hexToRGBA(token.colorError, 0.05) : 'transparent',
          padding: isSaveError ? 16 : 0,
          borderRadius: isSaveError ? '4px' : 0
        }}
      >
        <DiagnosisTags />

        <Select
          style={{
            width: '100%',
            borderRadius: '6px'
          }}
          value={null}
          onSearch={fetchIcd10Data}
          onSelect={handleDiagnosisSelect}
          onClear={() => setState((prev) => ({ ...prev, icd10Data: [] }))}
          onDropdownVisibleChange={(visible) => {
            if (!visible) setState((prev) => ({ ...prev, icd10Data: [] }))
          }}
          dropdownStyle={{ minWidth: '100%', maxWidth: '100%' }}
          options={icd10Data.map((item) => ({
            value: JSON.stringify(item),
            label: `${item.code}: ${item.description}`
          }))}
          filterOption={false}
          placeholder={
            <Space>
              <FontAwesomeIcon icon={faSearch} style={{ opacity: 0.5 }} />
              <span>Search and select diagnosis codes to add</span>
            </Space>
          }
          optionFilterProp='label'
          showSearch
          showArrow
          listHeight={300}
          notFoundContent={
            isLoading ? (
              <div style={{ padding: '12px', textAlign: 'center' }}>
                <Spin size='small' />
              </div>
            ) : (
              <div style={{ padding: '12px', textAlign: 'center' }}>
                <Text type='secondary'>No matching diagnosis codes found</Text>
              </div>
            )
          }
        />

        {selectedDiagnoses.length > 0 && (
          <Text type='secondary' style={{ display: 'block', marginTop: 8, fontSize: '12px' }}>
            {selectedDiagnoses.length} diagnosis code{selectedDiagnoses.length !== 1 ? 's' : ''} selected
          </Text>
        )}

        {isSaveError && (
          <Alert
            message='Error Saving Diagnosis'
            description={DIANGOSIS_ERROR_MESSAGE}
            type='error'
            showIcon
            style={{ marginTop: 16 }}
          />
        )}
      </div>
    </Card>
  )
}

// Add CSS for responsive behavior
const responsiveStyles = `
@media (max-width: 768px) {
  .ant-select-dropdown {
    max-width: 90vw !important;
    min-width: 90vw !important;
  }
}`

// Add the styles to the document
if (typeof document !== 'undefined') {
  const styleElement = document.createElement('style')
  styleElement.innerHTML = responsiveStyles
  document.head.appendChild(styleElement)
}

export default React.memo(DiagnosisCapture)
