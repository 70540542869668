import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Button, App, Space, message } from 'antd'
import { AudioOutlined, AudioMutedOutlined } from '@ant-design/icons'
import { getEphemeralToken } from '../api/realtime-api'
import PropTypes from 'prop-types'

// First, move getAvailableTools outside the component to avoid initialization issues
const getAvailableTools = (customTools = []) => {
  const baseTools = [
    {
      type: 'function',
      name: 'navigateToTab',
      description: 'Navigates to a specific tab',
      parameters: {
        type: 'object',
        properties: {
          tab: {
            type: 'string',
            description: 'The tab to navigate to'
          }
        },
        required: ['tab']
      }
    }
  ]

  // Ensure all custom tools have the required type property
  const processedCustomTools = customTools.map((tool) => ({
    type: 'function',
    ...tool
  }))

  return [...baseTools, ...processedCustomTools]
}

// Add singleton instance tracking
let activeInstance = null

const WebRTCAssistant = ({ systemContext, customTools = [], onAction }) => {
  const { modal } = App.useApp()
  const peerConnection = useRef(null)
  const dataChannel = useRef(null)
  const audioElement = useRef(new Audio())
  const [isConnecting, setIsConnecting] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [isListening, setIsListening] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const MAX_RETRIES = 3
  const [isAISpeaking, setIsAISpeaking] = useState(false)
  const [userSpeaking, setUserSpeaking] = useState(false)
  const audioContext = useRef(null)
  const gainNode = useRef(null)
  const [sessionId, setSessionId] = useState(null)
  const sessionInitSent = useRef(false)
  const localStream = useRef(null)

  // Add a ref to track initialization status
  const initializationStatus = useRef({
    dataChannelReady: false,
    connectionReady: false
  })

  // Add ref to track connection stability
  const connectionStability = useRef({
    isStable: false,
    stabilityTimeout: null
  })

  // Prevent multiple instances
  React.useEffect(() => {
    if (activeInstance) {
      console.warn('Multiple instances of WebRTCAssistant detected. Only one instance should be active at a time.')
      cleanup(true)
      return
    }
    activeInstance = true
    return () => {
      activeInstance = null
    }
  }, [])

  const cleanup = (isUserInitiated = false) => {
    if (!isUserInitiated && isConnectedRef.current) {
      return
    }

    // First, try to gracefully stop audio playback
    try {
      if (audioElement.current) {
        audioElement.current.onplay = null
        audioElement.current.onpause = null
        audioElement.current.onwaiting = null
        audioElement.current.onstalled = null
        audioElement.current.onsuspend = null
        audioElement.current.oncanplay = null
        audioElement.current.onerror = null

        audioElement.current.pause()

        if (audioElement.current.srcObject) {
          const tracks = audioElement.current.srcObject.getTracks()
          tracks.forEach((track) => {
            track.stop()
            track.enabled = false
          })
          audioElement.current.srcObject = null
        }

        audioElement.current.src = ''
        audioElement.current.load()
        audioElement.current = null
      }
    } catch (error) {
      console.error('Error during audio cleanup:', error)
    }

    // Clean up audio context
    try {
      if (audioContext.current) {
        audioContext.current.close().catch(console.error)
        audioContext.current = null
      }
    } catch (error) {
      console.error('Error during audio context cleanup:', error)
    }

    if (gainNode.current) {
      try {
        gainNode.current.disconnect()
        gainNode.current = null
      } catch (error) {
        console.error('Error during gain node cleanup:', error)
      }
    }

    // Clean up data channel
    if (dataChannel.current) {
      try {
        dataChannel.current.onclose = null
        dataChannel.current.onerror = null
        dataChannel.current.onmessage = null
        dataChannel.current.close()
        dataChannel.current = null
      } catch (error) {
        console.error('Error during data channel cleanup:', error)
      }
    }

    // Clean up peer connection
    if (peerConnection.current) {
      try {
        peerConnection.current.onconnectionstatechange = null
        peerConnection.current.oniceconnectionstatechange = null
        peerConnection.current.ontrack = null

        const transceivers = peerConnection.current.getTransceivers()
        transceivers.forEach((transceiver) => {
          if (transceiver.stop) {
            transceiver.stop()
          }
        })

        peerConnection.current.close()
        peerConnection.current = null
      } catch (error) {
        console.error('Error during peer connection cleanup:', error)
      }
    }

    // Reset all state

    initializationStatus.current = {
      dataChannelReady: false,
      connectionReady: false
    }
    sessionInitSent.current = false
    setSessionId(null)
    setIsConnected(false)
    setIsListening(false)
    setRetryCount(0)
    setIsAISpeaking(false)
    setUserSpeaking(false)
  }

  const setupAudioContext = () => {
    try {
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 48000,
        latencyHint: 'interactive'
      })

      if (audioContext.current.state === 'suspended') {
        audioContext.current.resume()
      }

      // Create separate nodes for input and output
      gainNode.current = audioContext.current.createGain()
      gainNode.current.gain.value = 0.7 // Output volume for AI voice

      // Only connect gain node to destination (for AI output)
      gainNode.current.connect(audioContext.current.destination)

      console.log('Audio context setup complete:', {
        state: audioContext.current.state,
        sampleRate: audioContext.current.sampleRate,
        baseLatency: audioContext.current.baseLatency,
        outputLatency: audioContext.current.outputLatency
      })
    } catch (error) {
      console.error('Error setting up audio context:', error)
    }
  }

  // Update message handler to use a ref for connection state
  const isConnectedRef = useRef(false)
  useEffect(() => {
    isConnectedRef.current = isConnected
  }, [isConnected])

  useEffect(() => {
    return cleanup
  }, [])

  // Add back the handleConnectionStateChange function and connection state handler
  const handleConnectionStateChange = () => {
    const state = peerConnection.current?.connectionState

    if (state === 'connected') {
      initializationStatus.current.connectionReady = true
    } else if (state === 'disconnected' || state === 'failed') {
      connectionStability.current.isStable = false
      cleanup()
    }
  }

  // Add this function to check and request microphone permissions
  const checkMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          channelCount: 1,
          sampleRate: 48000,
          sampleSize: 16,
          volume: 1.0
        }
      })

      // Store the stream reference
      localStream.current = stream

      // Create source node but DON'T connect it to the audio output
      if (audioContext.current) {
        const source = audioContext.current.createMediaStreamSource(stream)
        // We don't connect the source to anything - it will be handled by WebRTC
      }

      return true
    } catch (error) {
      console.error('Error accessing microphone:', error)
      return false
    }
  }

  // Add the initWebRTC function
  const initWebRTC = async () => {
    try {
      cleanup()
      setIsConnecting(true)
      setupAudioContext()

      // Get ephemeral token
      const tokenResponse = await getEphemeralToken()

      if (!tokenResponse?.client_secret?.value) {
        throw new Error('Invalid token format received')
      }

      const EPHEMERAL_KEY = tokenResponse.client_secret.value

      // Create peer connection
      peerConnection.current = new RTCPeerConnection({
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:stun1.l.google.com:19302' },
          { urls: 'stun:stun2.l.google.com:19302' }
        ]
      })

      // Monitor connection state changes
      peerConnection.current.onconnectionstatechange = handleConnectionStateChange

      peerConnection.current.oniceconnectionstatechange = () => {}

      // Set up audio
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
            sampleRate: 48000
          }
        })

        // Don't connect the stream to the audio context
        // Just add it to the peer connection
        stream.getTracks().forEach((track) => {
          peerConnection.current.addTrack(track, stream)
        })
      } catch (error) {
        console.error('Error accessing microphone:', error)
        message.error('Please enable microphone access')
        cleanup()
        return
      }

      // Handle incoming audio
      peerConnection.current.ontrack = (event) => {
        try {
          if (audioElement.current) {
            audioElement.current.pause()
            audioElement.current.srcObject = null
          }

          audioElement.current = new Audio()
          audioElement.current.srcObject = event.streams[0]
          audioElement.current.autoplay = true
          audioElement.current.playsInline = true

          const playPromise = audioElement.current.play()
          if (playPromise !== undefined) {
            playPromise.catch((error) => {
              console.error('Error playing audio:', error)
              document.addEventListener(
                'click',
                () => {
                  audioElement.current?.play().catch((e) => console.error('Play after interaction failed:', e))
                },
                { once: true }
              )
            })
          }
        } catch (error) {
          console.error('Error setting up audio track:', error)
        }
      }

      // Set up data channel
      setupDataChannel()

      // Create and set local description
      const offer = await peerConnection.current.createOffer({
        offerToReceiveAudio: true
      })
      await peerConnection.current.setLocalDescription(offer)

      // Wait for ICE gathering
      await Promise.race([
        new Promise((resolve) => {
          const checkState = () => {
            if (peerConnection.current?.iceGatheringState === 'complete') {
              resolve()
            }
          }
          peerConnection.current.addEventListener('icegatheringstatechange', checkState)
          checkState()
        }),
        new Promise((_, reject) => setTimeout(() => reject(new Error('ICE gathering timeout')), 5000))
      ]).catch((error) => {})

      // Connect to OpenAI
      const baseUrl = 'https://api.openai.com/v1/realtime'
      const model = 'gpt-4o-realtime-preview-2024-12-17'

      const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
        method: 'POST',
        body: peerConnection.current.localDescription.sdp,
        headers: {
          Authorization: `Bearer ${EPHEMERAL_KEY}`,
          'Content-Type': 'application/sdp',
          'OpenAI-Beta': 'realtime=v1'
        }
      })

      if (!sdpResponse.ok) {
        const errorText = await sdpResponse.text()
        throw new Error(`SDP response error: ${sdpResponse.status} - ${errorText}`)
      }

      const answer = {
        type: 'answer',
        sdp: await sdpResponse.text()
      }
      await peerConnection.current.setRemoteDescription(answer)
    } catch (error) {
      console.error('Error initializing WebRTC:', error)
      cleanup()
      modal.error({
        title: 'Connection Error',
        content: 'Failed to connect. Please try again.'
      })
    } finally {
      setIsConnecting(false)
    }
  }

  // Define handleMessage
  const handleMessage = useCallback(
    (event) => {
      try {
        const data = JSON.parse(event.data)

        // Handle AI response with action
        if (data.type === 'response.audio_transcript.done') {
          const transcript = data.transcript
          if (transcript) {
            // Check if the transcript indicates opening the letter modal
            if (transcript.toLowerCase().includes('letter modal') || transcript.toLowerCase().includes('open letter')) {
              onAction?.('openLetterModal')
            }
          }
        }

        // Handle other message types
        switch (data.type) {
          case 'session.created':
            console.log('Session created, initializing...', {
              sessionId: data.session.id,
              sessionData: data.session
            })
            setSessionId(data.session.id)
            break

          case 'session.updated':
            break

          case 'input_audio_buffer.speech_started':
            setUserSpeaking(true)
            break

          case 'input_audio_buffer.speech_stopped':
            setUserSpeaking(false)
            break

          case 'output_audio_buffer.audio_started':
            setIsAISpeaking(true)
            break

          case 'response.audio.done':
            setIsAISpeaking(false)
            break

          case 'error':
            console.error('Error from AI:', data.error)
            if (data.error?.message) {
              message.error(`AI Error: ${data.error.message}`)
            }
            break

          // Ignore these common events to reduce noise
          case 'input_audio_buffer.committed':
          case 'rate_limits.updated':
          case 'conversation.item.created':
          case 'response.audio_transcript.delta':
          case 'response.content_part.done':
          case 'response.output_item.done':
          case 'response.done':
          case 'output_audio_buffer.audio_stopped':
            break

          default:
            // Only log unhandled events that aren't in the ignore list
            if (!data.type.startsWith('response.') && !data.type.startsWith('conversation.')) {
            }
            break
        }
      } catch (error) {
        console.error('=== Error in handleMessage ===')
        console.error('Error parsing/handling message:', error)
        console.error('Raw message data:', event.data)
        console.error('Stack trace:', error.stack)
      }
    },
    [onAction]
  )

  // Define sendSessionInit
  const sendSessionInit = useCallback(async () => {
    if (!dataChannel.current || sessionInitSent.current) {
      return
    }

    try {
      // Log the system context

      // Check if the system context contains vital signs
      if (systemContext) {
        const hasVitalSigns =
          systemContext.includes('VITAL SIGNS:') && !systemContext.includes('VITAL SIGNS: None recorded')

        // Extract and log the vital signs section
        if (hasVitalSigns) {
          const vitalSignsMatch = systemContext.match(/VITAL SIGNS:\n([\s\S]*?)(?=\n\n)/)
          if (vitalSignsMatch && vitalSignsMatch[1]) {
            // Check specifically for blood pressure
            const hasBP = vitalSignsMatch[1].toLowerCase().includes('blood pressure')

            if (hasBP) {
              // Extract the blood pressure line
              const bpMatch = vitalSignsMatch[1].match(/- Blood Pressure:.*$/im)
              if (bpMatch) {
              } else {
              }
            }
          }
        }
      }

      // First send system context
      const systemContextMessage = {
        type: 'conversation.item.create',
        item: {
          type: 'message',
          role: 'system',
          content: [
            {
              type: 'input_text',
              text:
                systemContext ||
                `You are an AI Assistant.

INITIAL GREETING:
Only say "Hello! How can I help you today?"

INTERACTION GUIDELINES:
- Keep responses focused and concise
- Only explain features when asked "What can you do?"

Remember: Always wait for explicit questions before providing information.`
            }
          ]
        }
      }

      await dataChannel.current.send(JSON.stringify(systemContextMessage))

      // Start the conversation
      const startMessage = {
        type: 'response.create'
      }
      await dataChannel.current.send(JSON.stringify(startMessage))

      sessionInitSent.current = true
    } catch (error) {
      console.error('Error in sendSessionInit:', error)
      throw error
    }
  }, [systemContext])

  // Then update the setupDataChannel function
  const setupDataChannel = useCallback(() => {
    if (!peerConnection.current) {
      console.error('setupDataChannel: No peer connection available')
      return
    }

    dataChannel.current = peerConnection.current.createDataChannel('text', {
      ordered: true
    })

    dataChannel.current.onopen = () => {
      initializationStatus.current.dataChannelReady = true
      setIsConnected(true)

      // Send session init first
      sendSessionInit()
    }

    dataChannel.current.onclose = () => {
      cleanup()
    }

    dataChannel.current.onerror = (error) => {
      console.error('=== Data Channel Error ===')
      console.error('Error object:', error)
      console.error('Error type:', error.type)
      console.error('Error message:', error.message)
      console.error('Channel state:', dataChannel.current?.readyState)
      console.error('Stack:', new Error().stack)
    }

    dataChannel.current.onmessage = handleMessage
  }, [handleMessage, sendSessionInit])

  return (
    <Space direction='vertical' align='center' style={{ width: '100%' }}>
      <div
        style={{
          padding: '10px',
          borderRadius: '8px',
          backgroundColor: '#f0f2f5',
          textAlign: 'center',
          marginBottom: '10px'
        }}
      >
        {userSpeaking && (
          <div style={{ color: '#1890ff' }}>
            <AudioOutlined style={{ fontSize: '24px' }} spin />
            <div>Listening...</div>
          </div>
        )}
        {isAISpeaking && (
          <div style={{ color: '#52c41a' }}>
            <AudioOutlined style={{ fontSize: '24px' }} spin />
            <div>AI Speaking...</div>
          </div>
        )}
        {!userSpeaking && !isAISpeaking && isConnected && (
          <div style={{ color: '#8c8c8c' }}>
            <AudioMutedOutlined style={{ fontSize: '24px' }} />
            <div>Ready</div>
          </div>
        )}
      </div>

      <Button
        type='primary'
        onClick={async () => {
          if (isConnected) {
            cleanup(true)
          } else {
            const hasMicAccess = await checkMicrophonePermission()
            if (hasMicAccess) {
              setRetryCount(0)
              initWebRTC()
            }
          }
        }}
        loading={isConnecting}
        icon={<AudioOutlined />}
        style={{ width: '100%' }}
      >
        {isConnecting
          ? `Connecting (Attempt ${retryCount}/${MAX_RETRIES})...`
          : isConnected
          ? isListening
            ? 'AI Assistant Active'
            : 'AI Assistant Connected'
          : 'Start AI Assistant'}
      </Button>
    </Space>
  )
}

WebRTCAssistant.propTypes = {
  systemContext: PropTypes.string,
  customTools: PropTypes.arrayOf(PropTypes.object),
  onAction: PropTypes.func
}

export default WebRTCAssistant
