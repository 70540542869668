import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { initCallpayPayment } from '../utils/utils'
import { handleError } from '../../lib/utils'
import MobMedicalHistoryLoadingOverlay from '../../Patient/Medical History/Mobile/components/LoadingOverlay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { findCallpayOriginalTransaction } from '../utils/utils'
import { findCallpayTransaction } from '../utils/utils'

const EmbeddedPayment = ({ patientRecord, amount, paymentType, isAuthorization, callback }) => {
  const [loading, setLoading] = useState(true)
  const [paymentResponse, setPaymentResponse] = useState(null)
  const [iframeLoading, setIframeLoading] = useState(true)

  useEffect(() => {
    handleInitCheckout()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let intervalId = null

    if (paymentResponse?.merchantReference) {
      intervalId = setInterval(async () => {
        try {
          const transaction = await findCallpayTransaction({
            merchantReference: paymentResponse.merchantReference
          })

          if (
            transaction?.data?.status?.toLowerCase() === 'authorised' ||
            transaction?.data?.status?.toLowerCase() === 'complete'
          ) {
            const originalTransaction = await findCallpayOriginalTransaction(paymentResponse.merchantReference)

            if (originalTransaction.status === 'authorised' || originalTransaction.status === 'complete') {
              if (callback) {
                callback(transaction.data.gateway_reference)
              }
              clearInterval(intervalId)
            }
          }
        } catch (error) {
          message.error(handleError(error, true))
        }
      }, 5000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [paymentResponse, isAuthorization, callback])

  const handleInitCheckout = async () => {
    try {
      const response = await initCallpayPayment(patientRecord, amount, paymentType, isAuthorization)
      setPaymentResponse(response)
    } catch (e) {
      message.error(handleError(e, true))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {!loading && paymentResponse?.url ? (
        <>
          <iframe
            src={paymentResponse.url}
            title='Callpay Checkout'
            style={{
              width: '100%',
              height: '100vh',
              display: iframeLoading ? 'none' : 'block'
            }}
            onLoad={() => setIframeLoading(false)}
          />
          {iframeLoading && (
            <MobMedicalHistoryLoadingOverlay
              loading={true}
              saveLoading={true}
              text='Loading Payment Form...'
              loadingText='Loading Payment Form...'
              icon={<FontAwesomeIcon icon={faCreditCard} />}
            />
          )}
        </>
      ) : (
        <MobMedicalHistoryLoadingOverlay
          loading={true}
          saveLoading={true}
          text='Initializing Payment...'
          loadingText='Initializing Payment...'
          icon={<FontAwesomeIcon icon={faCreditCard} />}
        />
      )}
    </>
  )
}

export default EmbeddedPayment
