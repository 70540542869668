import React, { useState, useEffect } from 'react'
import { Card, Button, Table, Tag, Modal, Select, DatePicker, Input, message, Row, Col, Divider } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { debounce } from 'lodash'
import dayjs from 'dayjs'
import { MedPraxICD10Search } from '../../../MedPrax/ICD10-API'
import { MedPraxProductSearch } from '../../../Scripting/utils/utils'
import { updateChronicIllnesses } from '../../../Patient/Medical History/api/api'
import { handleError } from '../../../lib/utils'

const { confirm } = Modal

const ChronicIllnessesTab = ({ chronicIllnesses = {}, recordId, setSaving, updateMedicalHistory }) => {
  const [illnessList, setIllnessList] = useState([])
  const [icd10Data, setIcd10Data] = useState([])
  const [nappiData, setNappiData] = useState([])
  const [icd10Loading, setIcd10Loading] = useState(false)
  const [nappiLoading, setNappiLoading] = useState(false)
  const [illnessModalVisible, setIllnessModalVisible] = useState(false)
  const [medicationModalVisible, setMedicationModalVisible] = useState(false)
  const [selectedIllness, setSelectedIllness] = useState(null)
  const [selectedMedication, setSelectedMedication] = useState(null)
  const [editingIllness, setEditingIllness] = useState(null)
  const [editingMedication, setEditingMedication] = useState(null)
  const [diagnosisDate, setDiagnosisDate] = useState(null)
  const [medicationDates, setMedicationDates] = useState({
    startDate: null,
    endDate: null
  })

  // Initialize state from props
  useEffect(() => {
    if (chronicIllnesses && chronicIllnesses.list) {
      setIllnessList(chronicIllnesses.list || [])
    }
  }, [chronicIllnesses])

  const getICD10Data = async (searchQuery) => {
    try {
      if (searchQuery && searchQuery.length >= 3) {
        setIcd10Loading(true)
        const data = await MedPraxICD10Search(searchQuery)
        setIcd10Data(data)
      } else {
        setIcd10Data([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    } finally {
      setIcd10Loading(false)
    }
  }

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery && searchQuery.length >= 3) {
        setNappiLoading(true)
        const data = await MedPraxProductSearch(searchQuery)
        setNappiData(data)
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    } finally {
      setNappiLoading(false)
    }
  }

  const debouncedICD10Search = debounce(getICD10Data, 500)
  const debouncedNappiSearch = debounce(getNappiData, 500)

  const handleAddIllness = () => {
    if (!selectedIllness || !diagnosisDate) {
      message.error('Please select an illness and diagnosis date')
      return
    }

    const newIllness = {
      ...selectedIllness,
      dateDiagnosed: diagnosisDate.toISOString(),
      medications: []
    }

    setIllnessList([...illnessList, newIllness])
    setSelectedIllness(null)
    setDiagnosisDate(null)
    setIllnessModalVisible(false)
  }

  const handleUpdateIllness = () => {
    if (!editingIllness || !diagnosisDate) {
      message.error('Please select a diagnosis date')
      return
    }

    const updatedList = illnessList.map((illness, index) => {
      if (index === editingIllness.index) {
        return {
          ...illness,
          dateDiagnosed: diagnosisDate.toISOString()
        }
      }
      return illness
    })

    setIllnessList(updatedList)
    setEditingIllness(null)
    setDiagnosisDate(null)
    setIllnessModalVisible(false)
  }

  const handleDeleteIllness = (index) => {
    confirm({
      title: 'Are you sure you want to delete this illness?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const updatedList = [...illnessList]
        updatedList.splice(index, 1)
        setIllnessList(updatedList)
      }
    })
  }

  const handleAddMedication = () => {
    if (!selectedMedication || !medicationDates.startDate) {
      message.error('Please select a medication and start date')
      return
    }

    const updatedList = illnessList.map((illness, index) => {
      if (index === selectedIllness.index) {
        return {
          ...illness,
          medications: [
            ...illness.medications,
            {
              med: selectedMedication,
              startDate: medicationDates.startDate.toISOString(),
              endDate: medicationDates.endDate ? medicationDates.endDate.toISOString() : null
            }
          ]
        }
      }
      return illness
    })

    setIllnessList(updatedList)
    setSelectedMedication(null)
    setMedicationDates({ startDate: null, endDate: null })
    setMedicationModalVisible(false)
  }

  const handleUpdateMedication = () => {
    if (!medicationDates.startDate) {
      message.error('Please select a start date')
      return
    }

    const updatedList = illnessList.map((illness, index) => {
      if (index === editingMedication.illnessIndex) {
        const updatedMedications = [...illness.medications]
        updatedMedications[editingMedication.medIndex] = {
          ...updatedMedications[editingMedication.medIndex],
          startDate: medicationDates.startDate.toISOString(),
          endDate: medicationDates.endDate ? medicationDates.endDate.toISOString() : null
        }
        return {
          ...illness,
          medications: updatedMedications
        }
      }
      return illness
    })

    setIllnessList(updatedList)
    setEditingMedication(null)
    setMedicationDates({ startDate: null, endDate: null })
    setMedicationModalVisible(false)
  }

  const handleDeleteMedication = (illnessIndex, medIndex) => {
    confirm({
      title: 'Are you sure you want to delete this medication?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const updatedList = illnessList.map((illness, index) => {
          if (index === illnessIndex) {
            const updatedMedications = [...illness.medications]
            updatedMedications.splice(medIndex, 1)
            return {
              ...illness,
              medications: updatedMedications
            }
          }
          return illness
        })
        setIllnessList(updatedList)
      }
    })
  }

  const handleSave = async () => {
    setSaving(true)
    try {
      const newData = await updateChronicIllnesses(recordId, illnessList)
      updateMedicalHistory(newData)
      message.success('Chronic illnesses updated successfully')
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setSaving(false)
    }
  }

  const columns = [
    {
      title: 'Illness',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => (
        <div>
          <div style={{ fontWeight: 'bold' }}>{text}</div>
          <div>Code: {record.code}</div>
          <div>Diagnosed: {dayjs(record.dateDiagnosed).format('DD MMM YYYY')}</div>
        </div>
      )
    },
    {
      title: 'Medications',
      dataIndex: 'medications',
      key: 'medications',
      render: (medications, record, index) => (
        <div>
          {medications && medications.length > 0 ? (
            <div>
              {medications.map((med, medIndex) => (
                <Tag key={medIndex} color='blue' style={{ margin: '2px' }}>
                  {med.med.name} ({dayjs(med.startDate).format('MMM YYYY')}
                  {med.endDate ? ` - ${dayjs(med.endDate).format('MMM YYYY')}` : ' - Current'})
                  <Button
                    type='text'
                    size='small'
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    onClick={() => {
                      setEditingMedication({
                        illnessIndex: index,
                        medIndex,
                        medication: med
                      })
                      setMedicationDates({
                        startDate: dayjs(med.startDate),
                        endDate: med.endDate ? dayjs(med.endDate) : null
                      })
                      setMedicationModalVisible(true)
                    }}
                  />
                  <Button
                    type='text'
                    size='small'
                    danger
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={() => handleDeleteMedication(index, medIndex)}
                  />
                </Tag>
              ))}
            </div>
          ) : (
            <Button
              type='dashed'
              size='small'
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => {
                setSelectedIllness({ index })
                setMedicationModalVisible(true)
              }}
            >
              Add Medication
            </Button>
          )}
        </div>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record, index) => (
        <div>
          <Button
            type='primary'
            size='small'
            icon={<FontAwesomeIcon icon={faEdit} />}
            style={{ marginRight: '8px' }}
            onClick={() => {
              setEditingIllness({ ...record, index })
              setDiagnosisDate(dayjs(record.dateDiagnosed))
              setIllnessModalVisible(true)
            }}
          >
            Edit
          </Button>
          <Button
            type='primary'
            size='small'
            icon={<FontAwesomeIcon icon={faPlus} />}
            style={{ marginRight: '8px' }}
            onClick={() => {
              setSelectedIllness({ index })
              setMedicationModalVisible(true)
            }}
          >
            Add Med
          </Button>
          <Button
            danger
            size='small'
            icon={<FontAwesomeIcon icon={faTrash} />}
            onClick={() => handleDeleteIllness(index)}
          >
            Delete
          </Button>
        </div>
      )
    }
  ]

  return (
    <div className='chronic-illnesses-tab'>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Button
            type='primary'
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setIllnessModalVisible(true)}
            style={{ marginBottom: '16px' }}
          >
            Add Chronic Illness
          </Button>
        </Col>

        <Col span={24}>
          <Table dataSource={illnessList} columns={columns} rowKey={(record, index) => index} pagination={false} />
        </Col>

        <Col span={24}>
          <Button type='primary' onClick={handleSave} block>
            Save Chronic Illnesses
          </Button>
        </Col>
      </Row>

      {/* Add/Edit Illness Modal */}
      <Modal
        title={editingIllness ? 'Edit Chronic Illness' : 'Add Chronic Illness'}
        open={illnessModalVisible}
        onOk={editingIllness ? handleUpdateIllness : handleAddIllness}
        onCancel={() => {
          setIllnessModalVisible(false)
          setSelectedIllness(null)
          setEditingIllness(null)
          setDiagnosisDate(null)
        }}
        okText={editingIllness ? 'Update' : 'Add'}
      >
        {!editingIllness && (
          <div style={{ marginBottom: '16px' }}>
            <label>Search Illness (ICD-10):</label>
            <Select
              showSearch
              placeholder='Search by name or code'
              loading={icd10Loading}
              onSearch={debouncedICD10Search}
              onChange={(value) => setSelectedIllness(JSON.parse(value))}
              style={{ width: '100%' }}
              options={icd10Data.map((item) => ({
                value: JSON.stringify(item),
                label: `${item.description} (${item.code})`
              }))}
              notFoundContent={icd10Loading ? 'Searching...' : null}
            />
          </div>
        )}

        {editingIllness && (
          <div style={{ marginBottom: '16px' }}>
            <p>
              <strong>Illness:</strong> {editingIllness.description}
            </p>
            <p>
              <strong>Code:</strong> {editingIllness.code}
            </p>
          </div>
        )}

        <div>
          <label>Diagnosis Date:</label>
          <DatePicker value={diagnosisDate} onChange={setDiagnosisDate} style={{ width: '100%' }} />
        </div>
      </Modal>

      {/* Add/Edit Medication Modal */}
      <Modal
        title={editingMedication ? 'Edit Medication' : 'Add Medication'}
        open={medicationModalVisible}
        onOk={editingMedication ? handleUpdateMedication : handleAddMedication}
        onCancel={() => {
          setMedicationModalVisible(false)
          setSelectedMedication(null)
          setEditingMedication(null)
          setMedicationDates({ startDate: null, endDate: null })
        }}
        okText={editingMedication ? 'Update' : 'Add'}
      >
        {!editingMedication && (
          <div style={{ marginBottom: '16px' }}>
            <label>Search Medication:</label>
            <Select
              showSearch
              placeholder='Search by name'
              loading={nappiLoading}
              onSearch={debouncedNappiSearch}
              onChange={(value) => setSelectedMedication(JSON.parse(value))}
              style={{ width: '100%' }}
              options={nappiData.map((item) => ({
                value: JSON.stringify(item),
                label: `${item.name} ${item.dosageForm ? `(${item.dosageForm})` : ''}`
              }))}
              notFoundContent={nappiLoading ? 'Searching...' : null}
            />
          </div>
        )}

        {editingMedication && (
          <div style={{ marginBottom: '16px' }}>
            <p>
              <strong>Medication:</strong> {editingMedication.medication.med.name}
            </p>
          </div>
        )}

        <div style={{ marginBottom: '16px' }}>
          <label>Start Date:</label>
          <DatePicker
            value={medicationDates.startDate}
            onChange={(date) => setMedicationDates({ ...medicationDates, startDate: date })}
            style={{ width: '100%' }}
          />
        </div>

        <div>
          <label>End Date (optional):</label>
          <DatePicker
            value={medicationDates.endDate}
            onChange={(date) => setMedicationDates({ ...medicationDates, endDate: date })}
            style={{ width: '100%' }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default ChronicIllnessesTab
