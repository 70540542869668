import React, { Suspense, memo, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message, Spin } from 'antd'

import Store from '../../../../store'
import coreReducer from '../../../../core/utils/reducer'
import Templates from '../utils/templates'
import { readEntities } from '../utils/utils'
import entittiesCRUDReducer from '../utils/reducer'

import ListView from './entities-list'
import BasicForm from './entities-form'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import { debounce } from 'lodash'
import { handleError } from '../../../lib/utils'
import CoreEnums from '../../../../core/utils/enums'

const _AppWrapper = () => {
  const dispatch = useDispatch()
  const viewData = useSelector((state) => state.entities.data)
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const refreshView = async () => {
    let filter = null
    let data = []

    setLoading(true)

    try {
      if (searchQuery) {
        filter = { $text: { $search: searchQuery } }
      }

      data = await readEntities(filter)
      dispatch(entittiesCRUDReducer.actions.setRecords(data))
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 1000),
    []
  )

  const handleSearch = (query) => {
    setLoading(true)
    debouncedFilter(query)
  }

  useEffect(() => {
    refreshView()
    // eslint-disable-next-line
  }, [searchQuery])

  const createRecord = () => {
    Store.dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.ENTITIES}_new}`,
        closable: true,
        label: 'Create Entity',
        children: (
          <Suspense fallback={<Spin />}>
            <BasicForm data={Templates.dataTemplate()} isNewRecord refreshView={refreshView} />
          </Suspense>
        )
      })
    )
  }

  const editRecord = (record) => {
    Store.dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.ENTITIES}_${record._id}`,
        closable: true,
        label: `Record: ${record.name}`,
        children: (
          <Suspense fallback={<Spin />}>
            <BasicForm data={record} isNewRecord={false} refreshView={refreshView} />
          </Suspense>
        )
      })
    )
  }

  return (
    <ContainerCard title='Entities'>
      <ListView
        loading={loading}
        data={viewData}
        createRecord={createRecord}
        editRecord={editRecord}
        refreshView={refreshView}
        handleSearch={handleSearch}
      />
    </ContainerCard>
  )
}

const AppWrapper = memo(_AppWrapper)

export default AppWrapper
