import React from 'react'
import useCheckin from '../hooks/useCheckin'
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Switch,
  theme
} from 'antd'
import CheckinPreview from '../../virutal-services/components/virtual-services-preview-checkin'
import TextArea from 'antd/es/input/TextArea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { PlusOutlined } from '@ant-design/icons'
import { PrimaryCard } from '../../../reusable-components/AgiliteCards'

const CheckinForm = ({ data, isModalOpen, setIsModalOpen, checkinQuestions, setCheckinQuestions }) => {
  const {
    isPreview,
    setIsPreview,
    isNewQuestion,
    setIsNewQuestion,
    tmpQuestion,
    setTmpQuestion,
    handleCheckinOptionSave,
    onFinish,
    setRecordData,
    checkinForm,
    handleTmpQuestionChange,
    handleQuestionDelete,
    addCheckinOption,
    editCheckinOption,
    deleteCheckinOption,
    handleCheckinChange
  } = useCheckin({ data, checkinQuestions, setCheckinQuestions, isModalOpen, setIsModalOpen })
  const { token } = theme.useToken()
  return (
    <>
      <Modal
        width={'100vw'}
        title={<center>Checkin Questionnaire Preview</center>}
        closable={false}
        destroyOnClose
        maskClosable={false}
        open={isPreview}
        footer={false}
      >
        <CheckinPreview data={checkinQuestions} />
        <center style={{ marginTop: 12 }}>
          <Button
            onClick={() => {
              setIsPreview(false)
            }}
          >
            Close Preview
          </Button>
        </center>
      </Modal>
      <Modal
        title='Checkin Question Option'
        closable={false}
        destroyOnClose
        maskClosable={false}
        open={isNewQuestion}
        okText='Confirm'
        onOk={() => [handleCheckinOptionSave()]}
        onCancel={() => {
          setTmpQuestion({ text: null })
          setIsNewQuestion(false)
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            Option Text:
            <br />
            <small>This is the text that will be displayed to the user for selection.</small>
          </Col>
          <Col span={24}>
            <TextArea
              onChange={(e) => {
                handleTmpQuestionChange('text', e.target.value)
              }}
              value={tmpQuestion.text}
            />
          </Col>
        </Row>
      </Modal>
      <Modal footer={false} closable={false} destroyOnClose maskClosable={false} open={isModalOpen}>
        <Card type='inner' size='small' title='New Question'>
          <Form
            layout='vertical'
            form={checkinForm}
            onFieldsChange={() => {
              setRecordData(checkinForm.getFieldsValue())
            }}
            name='dynamic_form_complex'
            onFinish={onFinish}
            style={{
              maxWidth: 600
            }}
            autoComplete='off'
          >
            <Form.Item
              name='questionText'
              label={
                <Row>
                  <Col span={24}>
                    <b>Main Item Text</b>
                  </Col>
                  <Col span={24}>
                    <small>Main selection item text prompting the users selection.</small>
                  </Col>
                </Row>
              }
              rules={[
                {
                  required: true,
                  message: 'Missing text'
                }
              ]}
            >
              <Input placeholder='(e.g) I am feeling ill.' maxLength={50} />
            </Form.Item>
            <Divider style={{ borderColor: token.colorSecondaryLight }} />
            <Form.Item
              label={
                <Row>
                  <Col span={24}>
                    <b>Professional Text</b>
                  </Col>
                  <Col span={24}>
                    <small>Indicator displayed to the doctor for the selection.</small>
                  </Col>
                </Row>
              }
              name='doctorsText'
              rules={[
                {
                  required: true,
                  message: 'Missing text'
                }
              ]}
            >
              <Input placeholder='(e.g) Sick.' maxLength={50} />
            </Form.Item>
            <Divider style={{ borderColor: token.colorSecondaryLight }} />
            <Form.Item
              label={
                <Row>
                  <Col span={24}>
                    <b>Secondary Options:</b>
                  </Col>
                  <Col span={24}>
                    <small>Enable or disabled the use of secondary options.</small>
                  </Col>
                </Row>
              }
              name='enableOptions'
            >
              <Switch defaultChecked={false} checkedChildren='Enabled' unCheckedChildren='Disabled' />
            </Form.Item>
            {checkinForm.getFieldsValue().enableOptions ? (
              <>
                <Divider style={{ borderColor: token.colorSecondaryLight }} />
                <Form.Item
                  label={
                    <Row>
                      <Col span={24}>
                        <b>Secondary Options Label</b>
                      </Col>
                      <Col span={24}>
                        <small>Choose an appropriate heading to prompt the user to select relevant options.</small>
                      </Col>
                    </Row>
                  }
                  name='optionsHeading'
                  rules={[
                    {
                      required: true,
                      message: 'Missing text'
                    }
                  ]}
                >
                  <Input placeholder='(e.g) Please select your symptoms from the list.' maxLength={50} />
                </Form.Item>
                <Divider style={{ borderColor: token.colorSecondaryLight }} />
                <Form.List name='options'>
                  {(fields, { add, remove }) => (
                    <>
                      <Row style={{ marginBottom: 12 }} gutter={[12, 12]}>
                        <Col span={24}>
                          {
                            <Row>
                              <Col span={24}>
                                <b>Secondary Options Items:</b>
                              </Col>
                              <Col span={24}>
                                <small>Allow a user to select from a collection of options provided.</small>
                              </Col>
                            </Row>
                          }
                        </Col>
                        {fields.map((field) => {
                          return (
                            <Col span={24}>
                              <Card
                                type='inner'
                                size='small'
                                extra={
                                  <Button
                                    onClick={() => {
                                      if (fields.length > 1) {
                                        remove(field.name)
                                      } else {
                                        message.error('You have to provide atleast 1 option')
                                      }
                                    }}
                                  >
                                    <FontAwesomeIcon color={token.colorError} icon={faTrash} />
                                  </Button>
                                }
                                key={field.key}
                              >
                                <Row>
                                  <Col span={24}>
                                    <Form.Item
                                      {...field}
                                      label='Option text:'
                                      name={[field.name, 'text']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Missing text'
                                        }
                                      ]}
                                    >
                                      <Input placeholder='(e.g) Headaches' maxLength={50} />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          )
                        })}
                      </Row>
                      <Form.Item>
                        <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                          Add options
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Divider style={{ borderColor: token.colorSecondaryLight }} />
                <Form.Item
                  label={
                    <Row>
                      <Col span={24}>
                        <b>Other Option:</b>
                      </Col>
                      <Col span={24}>
                        <small>Allow user to provide additional information via text field on selection.</small>
                      </Col>
                    </Row>
                  }
                  name='showOther'
                >
                  <Switch defaultChecked={false} checkedChildren='Enabled' unCheckedChildren='Disabled' />
                </Form.Item>

                {checkinForm.getFieldsValue().showOther ? (
                  <>
                    <Divider style={{ borderColor: token.colorSecondaryLight }} />
                    <Form.Item
                      label={
                        <Row>
                          <Col span={24}>
                            <b>Additional Field Label</b>
                          </Col>
                          <Col span={24}>
                            <small>Provide an appropriate label prompting the user to fill in the field.</small>
                          </Col>
                        </Row>
                      }
                      name='otherOptionText'
                      rules={[
                        {
                          required: true,
                          message: 'Missing text'
                        }
                      ]}
                    >
                      <TextArea placeholder='(e.g) List your symptoms.' maxLength={100} />
                    </Form.Item>
                  </>
                ) : null}
              </>
            ) : null}

            <Form.Item>
              <Row justify='center'>
                <Space wrap>
                  <Button
                    onClick={() => {
                      checkinForm.resetFields()
                      setIsModalOpen(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type='primary' htmlType='submit'>
                    Add Question
                  </Button>
                </Space>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
      <Row justify='center'>
        <Col span={24}>
          <PrimaryCard title='Checkin  Questionnaire' bodyStyle={{ padding: 4 }} type='inner' size='small'>
            <Row style={{ margin: '12px 0' }} justify={'center'}>
              <Space wrap>
                <Button
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                  type='primary'
                >
                  Add Question
                </Button>
                {checkinQuestions.length > 0 ? (
                  <Button
                    onClick={() => {
                      setIsPreview(true)
                    }}
                  >
                    Preview
                  </Button>
                ) : null}
              </Space>
            </Row>

            {checkinQuestions.map((question, index) => (
              <Card
                style={{ marginBottom: 8 }}
                title={`Question ${index + 1}`}
                extra={
                  <Button
                    onClick={() => {
                      handleQuestionDelete(index)
                    }}
                  >
                    <FontAwesomeIcon color={token.colorError} icon={faTrash} />
                  </Button>
                }
                type='inner'
                size='small'
              >
                <Row>
                  <Col span={24}>
                    <b>Main Item Text:</b>
                    <br></br>
                    <small>Main selection item text prompting the users selection.</small>
                    <TextArea
                      onChange={(e) => {
                        handleCheckinChange(e.target.value, index, 'questionText')
                      }}
                      style={{ marginTop: 8, width: '100%' }}
                      value={question.questionText}
                    />
                  </Col>
                  <Divider style={{ borderColor: token.colorSecondaryLight }} />
                  <Col span={24}>
                    <b>Professional Text:</b>
                    <br></br>
                    <small>Indicator displayed to the doctor for the selection.</small>
                    <TextArea
                      onChange={(e) => {
                        handleCheckinChange(e.target.value, index, 'doctorsText')
                      }}
                      style={{ marginTop: 8, width: '100%' }}
                      value={question.doctorsText}
                    />
                  </Col>
                  <Divider style={{ borderColor: token.colorSecondaryLight }} />
                  <Col span={24}>
                    <Row style={{ alignItems: 'center' }} justify='space-between'>
                      <Col>
                        <b>Secondary Options:</b>
                        <br />
                        <small>Enable or disabled the use of secondary options.</small>
                      </Col>
                      <Col>
                        <Switch
                          checked={question.enableOptions}
                          checkedChildren='Enabled'
                          unCheckedChildren='Disabled'
                          onChange={(e) => {
                            handleCheckinChange(e, index, 'enableOptions')
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {question.enableOptions ? (
                    <>
                      <Divider style={{ borderColor: token.colorSecondaryLight }} />
                      <Col span={24}>
                        <b>Secondary Options Label:</b>
                        <br></br>
                        <small>Provide an appropriate label prompting the user to select from the options.</small>
                        <TextArea
                          onChange={(e) => {
                            handleCheckinChange(e.target.value, index, 'optionsHeading')
                          }}
                          style={{ marginTop: 8, width: '100%' }}
                          value={question.optionsHeading}
                        />
                      </Col>
                      <Divider style={{ borderColor: token.colorSecondaryLight }} />
                      <Col span={24}>
                        <Row>
                          <Col span={24}>
                            <b>Secondary Options Items:</b>
                          </Col>
                          <Col span={24}>
                            <small>Allow a user to select from a collection of options provided.</small>
                          </Col>
                          <Col span={24}>
                            <Space wrap style={{ marginTop: 12, width: '100%' }}>
                              {question.options?.map((sight, optionIndex) => (
                                <Popconfirm
                                  title={'Action required'}
                                  description='Please select an option'
                                  okText='Edit'
                                  cancelText='Delete'
                                  onCancel={() => {
                                    deleteCheckinOption(index, optionIndex)
                                  }}
                                  onConfirm={() => {
                                    editCheckinOption(index, optionIndex)
                                  }}
                                >
                                  <Button>{sight.text}</Button>
                                </Popconfirm>
                              ))}
                              <Button
                                onClick={() => {
                                  addCheckinOption(index)
                                }}
                              >
                                <FontAwesomeIcon color={token.colorSuccess} icon={faPlus} />
                              </Button>
                            </Space>
                          </Col>
                        </Row>
                      </Col>
                      <Divider style={{ borderColor: token.colorSecondaryLight }} />
                      <Col span={24}>
                        <Row gutter={[8, 8]} style={{ alignItems: 'center' }} justify='space-between'>
                          <Col>
                            <b>Other Option:</b>
                            <br />
                            <small>Allow user to provide additional information via text field on selection.</small>
                          </Col>
                          <Col>
                            <Switch
                              checked={question.showOther}
                              checkedChildren='Enabled'
                              unCheckedChildren='Disabled'
                              onChange={(e) => {
                                handleCheckinChange(e, index, 'showOther')
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {question.showOther ? (
                        <>
                          <Divider style={{ borderColor: token.colorSecondaryLight }} />
                          <Col span={24}>
                            <Row>
                              <Col span={24}>
                                <b>Additional Field Label:</b>
                              </Col>
                              <Col span={24}>
                                <small>Provide an appropriate label prompting the user to fill in the field.</small>
                              </Col>
                            </Row>
                            <TextArea
                              onChange={(e) => {
                                handleCheckinChange(e.target.value, index, 'otherOptionText')
                              }}
                              style={{ marginTop: 8, width: '100%' }}
                              value={question.otherOptionText}
                            />
                          </Col>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Row>
              </Card>
            ))}
            {checkinQuestions.length === 0 ? (
              <Row justify={'center'}>
                <Empty
                  description='
                              No questions set - Default configuraton will be used.
                          '
                />
              </Row>
            ) : null}
            {checkinQuestions.length > 0 ? (
              <Row style={{ margin: '12px 0' }} justify={'center'}>
                <Space wrap>
                  <Button
                    onClick={() => {
                      setIsModalOpen(true)
                    }}
                  >
                    Add Question
                  </Button>
                  <Button
                    onClick={() => {
                      setIsPreview(true)
                    }}
                  >
                    Preview
                  </Button>
                </Space>
              </Row>
            ) : null}
          </PrimaryCard>
        </Col>
      </Row>
    </>
  )
}

export default CheckinForm
