import React from 'react'
import { handleCalculateAge } from '../../Bookings/utils/lib'
import { Divider, Tooltip, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClinicMedical, faCopy, faVideoCamera } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

const DayEventContent = ({ patientRecord, service, recordData, isListView }) => {
  const sideBarExpanded = useSelector((state) => state.core.sideBarExpanded)
  const eventHours = dayjs(recordData.endTime, 'HH:mm').diff(dayjs(recordData.startTime, 'HH:mm'), 'hour')
  const eventMinutes = dayjs(recordData.endTime, 'HH:mm').diff(dayjs(recordData.startTime, 'HH:mm'), 'minute') % 60
  return (
    <div
      style={{
        display: 'flex',
        width: isListView ? `calc(100vw - ${sideBarExpanded ? 275 : 80}px)` : '100%',
        transition: '300ms ease',
        justifyContent: isListView ? 'left' : 'space-between',
        flexWrap: 'wrap',
        overflow: 'hidden',
        height: '100%'
      }}
    >
      {isListView ? (
        <div
          direction='vertical'
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 0,
            width: 60,
            marginRight: 2,
            maxWidth: '30%',
            minHeight: '100%',
            justifyContent: 'space-between'
          }}
        >
          {recordData.startTime} <Divider type='vertical' style={{ height: '100%' }} />
        </div>
      ) : undefined}
      <div
        direction='vertical'
        style={{ display: 'flex', flexDirection: 'column', gap: 0, width: 400, maxWidth: '30%' }}
      >
        <span style={{ fontSize: 10 }}>
          {service?.isVirtual ? 'VIRTUAL ' : ''}
          {service?.name?.toUpperCase()} ({eventHours}h:{eventMinutes}m){' '}
          <FontAwesomeIcon icon={service?.isVirtual ? faVideoCamera : faClinicMedical} />
        </span>
        <b style={{ fontSize: 10 }}>{recordData?.status.toUpperCase()}</b>
        <p style={{ fontSize: 10 }}>
          OTP: {recordData.otp}{' '}
          <Tooltip title='Copy OTP'>
            <FontAwesomeIcon
              style={{ cursor: 'copy' }}
              onClick={(e) => {
                e.stopPropagation()
                navigator.clipboard.writeText(recordData.otp)
                message.info('OTP Copied to clipboard.')
              }}
              icon={faCopy}
            />
          </Tooltip>
        </p>
      </div>
      <div
        direction='vertical'
        style={{ display: 'flex', flexDirection: 'column', gap: 0, width: 300, maxWidth: '30%' }}
      >
        <b style={{ fontSize: 10 }}>
          {patientRecord.gender === 'male' ? 'MR' : 'MS'}{' '}
          {`${patientRecord.firstName} ${patientRecord.lastName}`.toUpperCase()}
        </b>
        <span style={{ fontSize: 10 }}>
          {patientRecord.phoneNumber ? patientRecord.phoneNumber : patientRecord.tmpPhoneNumber || 'N/A'}
        </span>
        <span style={{ fontSize: 10 }}>
          {handleCalculateAge({ patientRecord })}, {patientRecord?.gender?.toUpperCase()}
        </span>
      </div>
      <div
        direction='vertical'
        style={{ display: 'flex', flexDirection: 'column', gap: 0, width: 300, maxWidth: '30%' }}
      >
        <span style={{ fontSize: 10 }}>MEDICAL AID:</span>
        <b style={{ fontSize: 10 }}>
          {patientRecord?.medicalAid?.name ? patientRecord.medicalAid.name.toUpperCase() : 'PRIVATE'}
        </b>

        <span style={{ fontSize: 10 }}>
          {patientRecord.medicalAid?.plan
            ? `${patientRecord.medicalAid.plan}, ${patientRecord.medicalAid.dependantNumber}`.toUpperCase()
            : 'N/A'}
        </span>
      </div>
    </div>
  )
}

export default DayEventContent
