import React from 'react'
import { Row, Col, Typography, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../style/MobSectionHeader.css'
import { hexToRGBA } from '../../../../lib/utils'

const { Title, Text } = Typography

const MobSectionHeader = ({ icon, title, subtitle }) => {
  const { token } = theme.useToken()
  return (
    <div className='section-header-card' style={{ background: hexToRGBA(token.colorPrimary, 0.1) }}>
      <Row align='middle' gutter={[12, 4]}>
        <Col>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              width: '100%',
              gap: '12px',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                padding: 8,
                boxShadow: `0 0 10px 0 ${token.colorPrimary}`,
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <FontAwesomeIcon icon={icon} style={{ color: token.colorPrimary }} />
            </div>

            <div>
              <Title level={4} className='section-header-title'>
                {title}
              </Title>
              {subtitle && <Text className='section-header-subtitle'>{subtitle}</Text>}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default MobSectionHeader
