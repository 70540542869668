export const AssistantEnums = {
  routes: {
    INITIATE_THREAD: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/friendly-assistant/query`,
    RUN_THREAD: '',
    CONTINUE_THREAD: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/friendly-assistant/continue`,
    READ_THREAD: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/friendly-assistant/response`,
    CHECK_THREAD_STATUS: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/friendly-assistant/run-status`,
    GET_REALTIME_TOKEN: `${process.env.REACT_APP_NODE_RED_URL}/api/assistants/realtime/token`
  },
  assistant_ids: {
    CARESYNC_SUGGEST: 'asst_05g21BDBpZkWv7G57lzNWNO5'
  }
} 