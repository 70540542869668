import { useCallback, useEffect, useRef, useState } from 'react'
import { Form, message, Modal, theme } from 'antd'
import { debounce } from 'lodash'
import {
  MedPraxSchemePlanOptionSearch,
  MedPraxSchemePlanSearch,
  MedPraxSchemeSearch
} from '../../../Scripting/utils/utils'
import { allTrim, handleError } from '../../../lib/utils'
import { useDispatch, useSelector } from 'react-redux'
import { PhoneAuthProvider, RecaptchaVerifier, updatePhoneNumber } from 'firebase/auth'

import { getCountries, updateAgiliteUser } from '../../../Auth/utils/utils'
import Resizer from 'react-image-file-resizer'
import PatProfileEnums from '../utils/enums'
import authReducer from '../../../Auth/utils/reducer'
import { firebaseAuth } from '../../../..'

const usePatEditProfile = ({ userData, setUserData, setIsEditMode }) => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.auth)
  const [newImage, setNewImage] = useState(null)
  const inputRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState(null)
  const [verificationId, setVerificationId] = useState(null)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [specialFormState, setSpecialFormState] = useState([
    { key: 'firstName', value: userData.firstName },
    { key: 'lastName', value: userData.lastName }
  ])
  const [residentVisitor, setResidentVisitor] = useState(
    userData.country ? (userData?.country === 'ZA' ? 'resident' : 'visitor') : 'resident'
  )
  const [country, setCountry] = useState('ZA')
  const [countries, setCountries] = useState([])
  const [countriesLoading, setCountriesLoading] = useState(false)
  const [countryObject, setCountryObject] = useState()

  // Medical Aid
  const [schemeLoading, setSchemeLoading] = useState(false)
  const [schemeData, setSchemeData] = useState([])
  const [plansLoading, setPlansLoading] = useState(false)
  const [plansData, setPlansData] = useState([])
  const [planOptionsLoading, setPlanOptionsLoading] = useState(false)
  const [planOptionData, setPlanOptionData] = useState([])

  useEffect(() => {
    let tmpIndex = -1

    tmpIndex = countries.findIndex((e) => e.code === country)

    if (tmpIndex > -1) {
      setCountryObject(countries[tmpIndex])
    } else {
      setCountryObject(null)
    }

    // eslint-disable-next-line
  }, [country])

  useEffect(() => {
    async function handleLoginPhoneRecaptcha() {
      window.recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, 'recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          setSubmitDisabled(false)
        },
        'expired-callback': (response) => {
          setSubmitDisabled(true)
          setErrorMessage('Verification Expired. Please reload the page')
        }
      })
    }

    handleLoginPhoneRecaptcha()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleGetCountries()
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])

  const handleGetCountries = async () => {
    let tmpCountries = []

    setCountriesLoading(true)

    try {
      tmpCountries = await getCountries()
      setCountries(tmpCountries)
    } catch (e) {
      message.error(e)
    }

    setCountriesLoading(false)
  }

  const handleUploadClick = () => {
    let promptText = null
    let enableCancel = false

    if (userData?.profileImage) {
      promptText = 'Do you want Remove or Change your profile picture?'
      enableCancel = true
    } else if (firebaseAuth?.currentUser?.photoURL) {
      promptText = 'Do you want Change your profile picture?'
      enableCancel = false
    } else {
      promptText = 'Do you want Change your profile picture?'
      enableCancel = false
    }

    Modal.confirm({
      title: 'Confirmation',
      content: promptText,
      okText: 'Change',
      cancelText: 'Remove',
      onOk: () => inputRef.current?.click(),
      onCancel: () => {
        setUserData({ ...userData, profileImage: '' })

        if (firebaseAuth.currentUser.photoURL) {
          setNewImage(firebaseAuth.currentUser.photoURL)
        } else {
          setNewImage(PatProfileEnums.images.DEFAULT_IMAGE)
        }
      },
      cancelButtonProps: { style: { display: enableCancel ? '' : 'none', background: 'transparent' }, danger: true },
      closable: true,
      maskClosable: true,
      className: token.themeControl
    })
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64'
      )
    })

  const handleFileChange = async (e) => {
    let file = null

    try {
      if (!e.target.files) {
        return
      }

      file = await resizeFile(e.target.files[0])

      // file = await readBase64(e.target.files[0])
      setUserData({ ...userData, profileImage: file })
      setNewImage(file)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const formatNumber = (phoneNumber) => {
    if (phoneNumber.charAt(0) === '0') {
      phoneNumber = `${countryObject ? countryObject.phoneCode : '+27'}${phoneNumber.slice(1, 10)}`
    } else {
      phoneNumber = `${countryObject ? countryObject.phoneCode : '+27'}${phoneNumber}`
    }

    return phoneNumber
  }

  const handleSubmit = async () => {
    const formData = allTrim(form.getFieldsValue())
    let tmpPhoneNumber = null
    setLoading(true)

    try {
      if (!formData.phoneNumber && state.agiliteUser.phoneNumber) {
        setLoading(false)
        return message.error('You cannot remove your cellphone number, please revise')
      }

      if (formData.phoneNumber) {
        tmpPhoneNumber = formatNumber(formData.phoneNumber)
      }

      // Check if phone number has changed

      if (tmpPhoneNumber && tmpPhoneNumber !== state.agiliteUser.phoneNumber) {
        // Verify phone number
        await handleLoginPhone(tmpPhoneNumber)
      } else {
        await handleSubmitExtended()
      }

      setIsEditMode(false)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const handleSubmitExtended = async () => {
    const formData = allTrim(form.getFieldsValue())
    let phoneNumber = form.getFieldValue('phoneNumber')
    let res = null

    try {
      if (phoneNumber) {
        phoneNumber = formatNumber(phoneNumber)
        form.setFieldValue('phoneNumber', phoneNumber.replace(countryObject ? countryObject.phoneCode : '+27', ''))
      }

      res = await updateAgiliteUser(userData._id, {
        ...userData,
        ...formData,
        phoneNumber,
        extraData: {
          ...userData.extraData,
          ...formData.extraData
        }
      })

      dispatch(authReducer.actions.setAgiliteUser(res))

      message.success('Profile updated successfully.')
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleLoginPhone = async (phoneNumber) => {
    const provider = new PhoneAuthProvider(firebaseAuth)
    let tmpVerificationId = null

    try {
      tmpVerificationId = await provider.verifyPhoneNumber(phoneNumber, window.recaptchaVerifier)
      setVerificationId(tmpVerificationId)
      setConfirmationOpen(true)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleLoginPhoneCode = async () => {
    let phoneCredential = null

    setLoading(true)

    try {
      setConfirmationOpen(false)

      phoneCredential = PhoneAuthProvider.credential(verificationId, confirmationCode)
      await updatePhoneNumber(firebaseAuth.currentUser, phoneCredential)
      await handleSubmitExtended()
    } catch (e) {
      setConfirmationOpen(true)
      message.error(handleError(e, true))
    }

    setConfirmationCode('')
    setLoading(false)
  }

  //   const handleSignOut = async () => {
  //     setLoading(true)

  //     try {
  //       await signOut(firebaseAuth)
  //       batch(() => {
  //         dispatch(CoreReducer.actions.resetTabs())
  //         dispatch(CoreReducer.actions.resetState())
  //         dispatch(CoreReducer.actions.enableDisableLeftMenu(false))
  //         dispatch(CoreReducer.actions.enableDisableRightMenu(false))
  //         dispatch(CoreReducer.actions.enableDisableTabs(false))
  //         dispatch(CoreReducer.actions.setRootContent(Router))
  //         dispatch(AuthReducer.actions.setUser(null))
  //         dispatch(AuthReducer.actions.setAgiliteUser(null))
  //         dispatch(AuthReducer.actions.setLoggedIn(false))
  //       })
  //     } catch (e) {
  //       handleError(e, true)
  //     }

  //     setLoading(false)
  //   }

  const handleGetMedicalAids = async (query) => {
    let tmpData = []

    setSchemeLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemeSearch(query)
        setSchemeData(tmpData)
      } else {
        setSchemeData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setSchemeLoading(false)
  }

  const handleGetMedicalAidPlans = async (query) => {
    let tmpData = []

    setPlansLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemePlanSearch(query)
        setPlansData(tmpData)
      } else {
        setPlansData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPlansLoading(false)
  }

  const handleGetMedicalAidPlanOptions = async (query) => {
    let tmpData = []

    setPlanOptionsLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemePlanOptionSearch(query)
        setPlanOptionData(tmpData)
      } else {
        setPlanOptionData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPlanOptionsLoading(false)
  }

  // eslint-disable-next-line
  const debouncedSchemeFilter = useCallback(
    debounce((query) => {
      if (query) {
        handleGetMedicalAids(query)
      } else {
        setSchemeLoading(false)
      }
    }, 1000),
    []
  )

  const handleValidateSave = () => {
    if (form.getFieldValue(['medicalAid', 'schemeCode'])) {
      if (!form.getFieldValue(['medicalAid', 'planCode'])) {
        return message.error('Please select a Medical Aid Plan')
      } else if (!form.getFieldValue(['medicalAid', 'planOptionCode'])) {
        return message.error('Please select a Medical Aid Plan Option')
      } else if (!form.getFieldValue(['medicalAid', 'number'])) {
        return message.error('Please provide your Medical Aid Number')
      } else if (!form.getFieldValue(['medicalAid', 'dependantNumber'])) {
        return message.error('Please provide your Medical Aid Dependant Number')
      }
    }

    form.submit()
  }

  const { token } = theme.useToken()
  const [form] = Form.useForm()

  return {
    handleSubmit,
    form,
    schemeLoading,
    plansLoading,
    planOptionsLoading,
    planOptionData,
    debouncedSchemeFilter,
    handleGetMedicalAidPlanOptions,
    handleGetMedicalAidPlans,
    plansData,
    schemeData,
    loading,
    handleUploadClick,
    handleFileChange,
    handleLoginPhoneCode,
    handleValidateSave,
    setCountry,
    countries,
    countriesLoading,
    countryObject,
    residentVisitor,
    setResidentVisitor,
    newImage,
    inputRef,
    confirmationOpen,
    setConfirmationOpen,
    confirmationCode,
    setConfirmationCode,
    submitDisabled,
    setSchemeData,
    setPlansData,
    setPlanOptionData
  }
}

export default usePatEditProfile
