export const calculateEstQuantity = (frequency, duration, dose, dispensingVolumePacksize) => {
  if (!frequency || !duration) return null

  // For "As needed" frequency, return the dispensingVolumePacksize
  if (frequency === 'As needed' && dispensingVolumePacksize) {
    return parseInt(dispensingVolumePacksize)
  }

  // For Once-off frequency, return the exact dose amount
  if (frequency === 'Once-off') {
    return parseInt(dose) || 1
  }

  const daysInDuration = parseInt(duration)
  let dosesPerDay = 0
  const doseAmount = parseInt(dose) || 1 // Default to 1 if dose is not a number

  // Calculate doses per day based on frequency
  if (frequency.endsWith(' hrly')) {
    // Extract the number from "X hrly" format
    const hours = parseInt(frequency.split(' ')[0])
    if (hours > 0) {
      dosesPerDay = 24 / hours // Calculate doses per day based on hours
    }
  } else {
    // Handle predefined frequencies
    switch (frequency) {
      case '6 hrly':
        dosesPerDay = 4 // 24/6 = 4 doses per day
        break
      case '8 hrly':
        dosesPerDay = 3 // 24/8 = 3 doses per day
        break
      case '12 hrly':
        dosesPerDay = 2 // 24/12 = 2 doses per day
        break
      case 'Daily':
        dosesPerDay = 1 // 24/24 = 1 dose per day
        break
      default:
        dosesPerDay = 1 // Default to 1 if frequency not recognized
    }
  }

  return Math.round(daysInDuration * dosesPerDay * doseAmount)
}
