import Axios from 'axios'
import Agilite from 'agilite'
import store from '../../../store'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const MedPraxSchemeSearch = async (searchQuery, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await Axios.post(`${process.env.REACT_APP_NODE_RED_URL}/api/medprax/schemes`, {
          body: {
            filters: [
              {
                propertyName: 'name',
                operation: 'Contains',
                value: searchQuery ? searchQuery : ''
              }
            ],
            filterJoin: 'And',
            ascendingOrder: true,
            sortKey: 'Code'
          },
          pageLimit: 1000,
          page
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const getBillingProfile = (filter = {}) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        filter = {
          ...filter,
          entityRef: store.getState().core.entity._id
        }

        const response = await agilite.Connectors.execute('billing_profiles', 'find_one', {
          filter: JSON.stringify(filter)
        })

        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createBillingProfile = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const response = await agilite.Connectors.execute('billing_profiles', 'create', {
          data: JSON.stringify({ entityRef: store.getState().core.entity._id, ...data })
        })

        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateBillingProfile = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const response = await agilite.Connectors.execute('billing_profiles', 'update', {
          recordId,
          data: JSON.stringify(data)
        })

        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
