import { Col, Space, Tag, Input } from 'antd'
import React from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import CustomButton from '../../reusable-components/CustomButton'

const CheckinQuestionarre = ({
  loading,
  handleChange,
  handleSubmit,
  bookingService,
  currentSelection,
  setCurrentSelection,
  patientSelection,
  setBookingService,
  setPatientSelection,
  patientInput,
  setPatientInput,
  setView,
  setOtp
}) => {
  const { CheckableTag } = Tag
  const selectionItemStyle = {
    borderColor: 'grey',
    padding: 12
  }
  return (
    <CustomRow
      gutter={[12, 12]}
      style={{ maxWidth: '100%', alignItems: 'center', minHeight: '100vh' }}
      justify='center'
    >
      <Col style={{ width: 1050, maxWidth: '90%' }}>
        <CustomRow className='basic-card' style={{ paddingTop: 48 }}>
          <Col span={24}>
            <CustomRow style={{ alignItems: 'baseline' }}>
              <Col span={24}>
                <h2 style={{ textAlign: 'center' }}>Help us best serve you today:</h2>
              </Col>
              <Col span={24}>
                <p style={{ textAlign: 'center' }}>Tell us a bit about your reason for visiting today:*</p>
              </Col>
              <Col span={24}>
                <Space wrap style={{ justifyContent: 'center', width: '100%' }}>
                  {bookingService.map((item) => (
                    <CheckableTag
                      style={selectionItemStyle}
                      checked={currentSelection ? currentSelection.doctorsText === item.doctorsText : false}
                      type={
                        currentSelection
                          ? currentSelection.doctorsText === item.doctorsText
                            ? 'primary'
                            : 'default'
                          : 'default'
                      }
                      onClick={() => {
                        setCurrentSelection(item)
                      }}
                    >
                      {item.questionText}
                    </CheckableTag>
                  ))}
                </Space>
              </Col>
            </CustomRow>
          </Col>
          {currentSelection && currentSelection.enableOptions ? (
            <Col span={24}>
              <CustomRow style={{ marginTop: 12 }}>
                <Col span={24}>
                  <h2 style={{ textAlign: 'center' }}>{currentSelection.optionsHeading}</h2>
                </Col>
                <Col span={24}>
                  <Space style={{ justifyContent: 'center', width: '100%' }} wrap>
                    {currentSelection.options.map((tag) => (
                      <CheckableTag
                        key={tag.text}
                        style={selectionItemStyle}
                        checked={patientSelection.includes(tag.text)}
                        onChange={(checked) => handleChange(tag.text, checked)}
                      >
                        {tag.text}
                      </CheckableTag>
                    ))}
                    {currentSelection.showOther ? (
                      <CheckableTag
                        key='Other'
                        style={{ borderColor: 'grey', padding: 12 }}
                        checked={patientSelection.includes('Other')}
                        onChange={(checked) => handleChange('Other', checked)}
                      >
                        Other
                      </CheckableTag>
                    ) : null}
                  </Space>
                </Col>
                {patientSelection.includes('Other') ? (
                  <>
                    <Col span={24}>
                      <h2 style={{ textAlign: 'center' }}>{currentSelection.otherOptionText}</h2>
                    </Col>
                    <Col span={24}>
                      <Input
                        style={{ padding: 12 }}
                        placeholder='Type here...'
                        value={patientInput}
                        onChange={(e) => {
                          setPatientInput(e.target.value)
                        }}
                      />
                    </Col>
                  </>
                ) : null}
                {handleSubmit ? (
                  <>
                    {' '}
                    <Col span={24}>
                      <center>
                        <CustomButton
                          type='secondary'
                          onClick={() => {
                            handleSubmit()
                          }}
                          disabled={
                            currentSelection
                              ? currentSelection.enableOptions
                                ? !patientSelection.length > 0 || (patientSelection.includes('Other') && !patientInput)
                                : false
                              : true
                          }
                          loading={loading}
                          style={{ width: '100%' }}
                          text='Complete Checkin'
                        />
                      </center>
                    </Col>
                    <Col span={24}>
                      <center>
                        <CustomButton
                          loading={loading}
                          onClick={() => {
                            setView('')
                            setOtp('')
                            setPatientInput('')
                            setCurrentSelection('')
                            setPatientSelection([])
                            setBookingService('')
                          }}
                          disabled={loading}
                          style={{ width: '100%' }}
                          text='Cancel Checkin'
                        />
                      </center>
                    </Col>
                  </>
                ) : null}
              </CustomRow>
            </Col>
          ) : null}
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default CheckinQuestionarre
