import { Button, Card, Col, Table, theme } from 'antd'
import React from 'react'
import CustomRow from '../../../../reusable-components/CustomRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { hexToRGBA } from '../../../../lib/utils'

const Summary = ({ general, data, visits, isMonitoring, setCurrentStep }) => {
  const { token } = theme.useToken()
  return (
    <Card size='small'>
      <CustomRow>
        <Col span={8}>
          <Card
            headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
            size='small'
            title='General'
            extra={
              <Button type='primary' onClick={() => setCurrentStep(0)}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            }
          >
            <h1>{general.name || <span style={{ color: token.colorError }}>NO NAME PROVIDED</span>} </h1>
            <p>{general.description || <span style={{ color: token.colorError }}>No description provided</span>} </p>
          </Card>
        </Col>
        <Col span={16}>
          <Card
            title='Health Trackers'
            headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
            size='small'
            extra={
              <Button type='primary' onClick={() => setCurrentStep(1)}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            }
          >
            {data.length === 0 ? (
              <p style={{ color: token.colorError }}>No Health Trackers selected.</p>
            ) : (
              <Table
                size='small'
                pagination={false}
                showHeader={false}
                columns={[{ title: 'Tracker', dataIndex: 'dataLabel' }]}
                dataSource={data}
              />
            )}
          </Card>
          {!isMonitoring ? (
            <Card
              onClick={() => setCurrentStep(3)}
              title='Visit Trackers'
              style={{ marginTop: 12 }}
              headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
              size='small'
              extra={
                <Button type='primary'>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              }
            >
              {data.length === 0 ? <p style={{ color: token.colorError }}>No Visit Trackers selected.</p> : <></>}
            </Card>
          ) : undefined}
        </Col>
      </CustomRow>
    </Card>
  )
}

export default Summary
