import React, { useState } from 'react'
import { Card, Col, Space, Table, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import CustomRow from '../../../../reusable-components/CustomRow'
import { hexToRGBA } from '../../../../lib/utils'
import FeatureButton from '../../../../reusable-components/FeatureButton'
import CustomButton from '../../../../reusable-components/CustomButton'

const SidePanel = ({
  patientChronicDetails,
  setShowClipboard,
  setDischemModalOpen,
  handleCancel,
  handleCreateScript,
  handleSave,
  scriptBusy,
  loading,
  printingScript,
  saving,
  script,
  isNew,
  isCopy,
  isEditMode,
  dischemDeliveryApplicable,
  selectedDischem,
  dischemType,
  dischemPaymentType
}) => {
  const [showChronic, setShowChronic] = useState(false)
  const [showAllergies, setShowAllergies] = useState(false)
  const { token } = theme.useToken()

  if (showChronic || showAllergies) {
    return (
      <Card
        bodyStyle={{ padding: 0 }}
        headStyle={{ background: hexToRGBA(showChronic ? '#F39C12' : '#FF6A88', 0.2) }}
        size='small'
        title={
          <>
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              icon={faChevronLeft}
              onClick={() => {
                setShowChronic(false)
                setShowAllergies(false)
              }}
            />{' '}
            {showChronic ? ' Chronic Details' : showAllergies ? 'Allergies' : 'Dischem Delivery/Collection'}
          </>
        }
      >
        {showAllergies ? (
          <>
            {patientChronicDetails?.allergies?.medsList?.length > 0 ? (
              <Table
                size='small'
                pagination={false}
                style={{ marginTop: 1 }}
                showHeader={false}
                dataSource={patientChronicDetails?.allergies?.medsList}
                columns={[{ render: (entry) => entry }]}
              />
            ) : (
              <CustomRow style={{ padding: 12, minHeight: 200 }}>
                <Col span={24}>
                  <center>No recorded allergies to date.</center>
                </Col>
              </CustomRow>
            )}
          </>
        ) : undefined}

        {showChronic ? (
          <>
            {patientChronicDetails ? (
              <>
                <Table
                  style={{ marginTop: 1 }}
                  showHeader={false}
                  size='small'
                  dataSource={patientChronicDetails?.chronicIllnesses?.list}
                  pagination={false}
                  columns={[
                    {
                      title: 'Chronic Illness',
                      render: (record) => `${record.description} - ${record.code}`
                    }
                  ]}
                  expandable={{
                    defaultExpandAllRows: false,
                    expandedRowRender: (record) => (
                      <Table
                        size='small'
                        style={{ borderRadius: 0, borderBottom: 0 }}
                        headStyle={{ borderRadius: 0 }}
                        pagination={false}
                        dataSource={record.medications.map((med) => ({
                          ...med.med,
                          isChronic: true,
                          icd: {
                            code: record.code,
                            description: record.description
                          }
                        }))}
                        columns={[
                          {
                            title: 'Medication',
                            render: (med) => (
                              <>{`${med.name ? med.name : ''} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
                                med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
                              } ${med.presentationCode ? med.presentationCode : ''} - ${
                                med.standardPacksize ? med.standardPacksize : ''
                              }`}</>
                            )
                          }
                        ]}
                      />
                    ),
                    rowExpandable: (record) => record?.medications?.length > 0
                  }}
                  rowKey={(record, index) => index}
                />
                <div style={{ height: 78, display: 'flex', justifyContent: 'space-between' }}>
                  <small style={{ marginTop: 4, marginLeft: 20 }}>
                    <FontAwesomeIcon icon={faArrowUp} /> Click to expand / collapse
                  </small>
                </div>
              </>
            ) : (
              <CustomRow style={{ padding: 12, minHeight: 200 }}>
                <Col span={24}>
                  <center>No record chronic illnesses to date.</center>
                </Col>
              </CustomRow>
            )}
          </>
        ) : undefined}
      </Card>
    )
  }

  return (
    <Space wrap direction='vertical' style={{ maxWidth: 500, minWidth: 250 }}>
      {/* Script Control Buttons */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '16px',
          marginBottom: '40px'
        }}
      >
        <CustomButton
          onClick={() => {
            if (handleCancel) {
              handleCancel()
            }
          }}
          hasPopconfirm={isNew || isCopy}
          popConfirmDescription='Are you sure? All progress will be lost.'
          popConfirmTitle='Confirm Cancel'
          type='error'
          size='small'
          text='CLOSE'
          disabled={scriptBusy || loading || printingScript}
          style={{ width: '50%' }}
          okButtonProps={{
            style: { background: token.colorError },
            danger: true
          }}
        />
        {(isNew || isCopy || isEditMode) && (
          <CustomButton
            onClick={() => {
              if (isEditMode) {
                handleSave()
              } else {
                handleCreateScript(
                  script,
                  dischemDeliveryApplicable,
                  selectedDischem ? JSON.parse(selectedDischem).practiceNo : null,
                  dischemType,
                  dischemPaymentType
                )
              }
            }}
            loading={loading || printingScript || saving}
            type='success'
            size='small'
            hasPopconfirm={!isEditMode}
            popConfirmDescription={
              script?.onSchedule && isNew
                ? 'Are you sure? This will log the initial script date.'
                : 'Are you sure? Scripts can`t be edited or deleted once created.'
            }
            popConfirmTitle='Finalise Script'
            text={isEditMode ? 'SAVE CHANGES' : 'CONFIRM'}
            disabled={
              scriptBusy ||
              script?.items?.length === 0 ||
              !script ||
              !script?.items ||
              (dischemDeliveryApplicable && (!dischemType || !selectedDischem))
            }
            style={{ width: '50%' }}
          />
        )}
      </div>

      {/* Feature Buttons */}
      <FeatureButton
        background='linear-gradient(45deg, #00A049,  #F3EC1C)'
        backgroundHover='#00A049'
        text='DIS-CHEM'
        onClick={() => setDischemModalOpen(true)}
      />
      <FeatureButton
        background='linear-gradient(45deg,  #FF6A88, #FF99AC)'
        backgroundHover='#FF6A88'
        text='ALLERGIES'
        onClick={() => setShowAllergies(true)}
      />
      <FeatureButton
        background='linear-gradient(45deg, #F39C12, #F8D800)'
        backgroundHover='#F39C12'
        text='CHRONIC ILLNESSES'
        onClick={() => setShowChronic(true)}
      />
    </Space>
  )
}

export default SidePanel
