import React, { useEffect } from 'react'
import { Modal, Typography, Button } from 'antd'

const { Title, Text, Paragraph } = Typography

const LetterModal = ({ isVisible, onClose, letterData }) => {
  useEffect(() => {}, [isVisible, letterData])

  // Don't return null when there's no letter data, just don't show the content
  return (
    <Modal
      title='Generated Letter'
      open={isVisible}
      onCancel={() => {
        onClose()
      }}
      width={700}
      footer={[
        <Button
          key='copy'
          type='primary'
          onClick={() => {
            navigator.clipboard.writeText(letterData?.content || '')
          }}
          disabled={!letterData}
        >
          Copy to Clipboard
        </Button>,
        <Button
          key='close'
          onClick={() => {
            onClose()
          }}
        >
          Close
        </Button>
      ]}
    >
      {letterData ? (
        <div style={{ padding: '20px' }}>
          <Title level={4}>{letterData.title}</Title>

          <div style={{ marginBottom: '20px' }}>
            <Text strong>To: </Text>
            <Text>{letterData.recipient}</Text>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <Text strong>Date: </Text>
            <Text>{letterData.date}</Text>
          </div>

          {letterData.subject && (
            <div style={{ marginBottom: '20px' }}>
              <Text strong>Subject: </Text>
              <Text>{letterData.subject}</Text>
            </div>
          )}

          <Paragraph style={{ whiteSpace: 'pre-line', marginBottom: '20px' }}>{letterData.content}</Paragraph>

          <div>
            <Text strong>Sincerely,</Text>
            <br />
            <Text>{letterData.signature}</Text>
          </div>
        </div>
      ) : (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <Text type='secondary'>Loading letter content...</Text>
        </div>
      )}
    </Modal>
  )
}

export default LetterModal
