import React from 'react'
import { Modal, Form, Input, DatePicker, Select, Button, Space, Divider, message } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import styles from './styles/PreAdmissionModal.module.css'

const { Option } = Select
const { TextArea } = Input

const PreAdmissionModal = ({ visible, onCancel, form, onCreateTask }) => {
  const handleSendToPatient = () => {
    form.validateFields().then(values => {
      // Show success message
      message.success({
        content: 'Pre-admission form sent to patient successfully!',
        duration: 4
      })

      // Create a task for the pre-admission
      const taskData = {
        task: `Hospital pre-admission for ${values.firstName} ${values.lastName}`,
        type: 'follow_up',
        priority: 'high',
        dueDate: values.admissionDate,
        responsiblePerson: 'Dr. Johnson'
      }

      // Create the task
      onCreateTask(taskData)

      // Close modal and reset form
      onCancel()
      form.resetFields()
    })
  }

  return (
    <Modal
      title="Hospital Pre-Admission Form"
      open={visible}
      onCancel={onCancel}
      width={800}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="send"
          type="primary"
          icon={<SendOutlined />}
          onClick={handleSendToPatient}
        >
          Send to Patient
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        className={styles.preAdmissionForm}
      >
        <Divider orientation="left">Patient Information</Divider>
        <div className={styles.formRow}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="dateOfBirth"
            label="Date of Birth"
            rules={[{ required: true }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </div>

        <div className={styles.formRow}>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: 'email' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="insuranceProvider"
            label="Insurance Provider"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>

        <Divider orientation="left">Admission Details</Divider>
        <div className={styles.formRow}>
          <Form.Item
            name="admissionDate"
            label="Expected Admission Date"
            rules={[{ required: true }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="admissionType"
            label="Admission Type"
            rules={[{ required: true }]}
          >
            <Select>
              <Option value="elective">Elective</Option>
              <Option value="emergency">Emergency</Option>
              <Option value="maternity">Maternity</Option>
              <Option value="surgery">Surgery</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="department"
            label="Department"
            rules={[{ required: true }]}
          >
            <Select>
              <Option value="cardiology">Cardiology</Option>
              <Option value="orthopedics">Orthopedics</Option>
              <Option value="neurology">Neurology</Option>
              <Option value="oncology">Oncology</Option>
              <Option value="general">General Medicine</Option>
            </Select>
          </Form.Item>
        </div>

        <Divider orientation="left">Medical Information</Divider>
        <div className={styles.formRow}>
          <Form.Item
            name="primaryDiagnosis"
            label="Primary Diagnosis"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="allergies"
            label="Known Allergies"
          >
            <Input />
          </Form.Item>
        </div>

        <Form.Item
          name="currentMedications"
          label="Current Medications"
        >
          <TextArea rows={3} />
        </Form.Item>

        <Form.Item
          name="specialInstructions"
          label="Special Instructions/Notes"
        >
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PreAdmissionModal 