import React, { useState, useEffect } from 'react'
import { Card, Button, Table, Modal, Input, DatePicker, message, Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { updateSurgeries } from '../../../Patient/Medical History/api/api'
import { handleError } from '../../../lib/utils'

const { confirm } = Modal

const SurgeriesTab = ({ surgicalHistory = {}, recordId, setSaving, updateMedicalHistory }) => {
  const [surgeryList, setSurgeryList] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [surgeryDescription, setSurgeryDescription] = useState('')
  const [surgeryDate, setSurgeryDate] = useState(null)
  const [editingSurgery, setEditingSurgery] = useState(null)

  // Initialize state from props
  useEffect(() => {
    if (surgicalHistory && surgicalHistory.list) {
      setSurgeryList(surgicalHistory.list || [])
    }
  }, [surgicalHistory])

  const handleAddSurgery = () => {
    if (!surgeryDescription.trim() || !surgeryDate) {
      message.error('Please enter a surgery description and date')
      return
    }

    const newSurgery = {
      description: surgeryDescription.trim().toUpperCase(),
      date: surgeryDate.toISOString()
    }

    setSurgeryList([...surgeryList, newSurgery])
    setSurgeryDescription('')
    setSurgeryDate(null)
    setModalVisible(false)
  }

  const handleUpdateSurgery = () => {
    if (!surgeryDescription.trim() || !surgeryDate) {
      message.error('Please enter a surgery description and date')
      return
    }

    const updatedList = surgeryList.map((surgery, index) => {
      if (index === editingSurgery.index) {
        return {
          ...surgery,
          description: surgeryDescription.trim().toUpperCase(),
          date: surgeryDate.toISOString()
        }
      }
      return surgery
    })

    setSurgeryList(updatedList)
    setSurgeryDescription('')
    setSurgeryDate(null)
    setEditingSurgery(null)
    setModalVisible(false)
  }

  const handleDeleteSurgery = (index) => {
    confirm({
      title: 'Are you sure you want to delete this surgery?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const updatedList = [...surgeryList]
        updatedList.splice(index, 1)
        setSurgeryList(updatedList)
      }
    })
  }

  const handleSave = async () => {
    setSaving(true)
    try {
      const newData = await updateSurgeries(recordId, surgeryList)
      updateMedicalHistory(newData)
      message.success('Surgical history updated successfully')
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setSaving(false)
    }
  }

  const columns = [
    {
      title: 'Surgery',
      dataIndex: 'description',
      key: 'description',
      render: (text) => <div style={{ fontWeight: 'bold' }}>{text}</div>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => <div>{dayjs(date).format('DD MMM YYYY')}</div>
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record, index) => (
        <div>
          <Button
            type='primary'
            size='small'
            icon={<FontAwesomeIcon icon={faEdit} />}
            style={{ marginRight: '8px' }}
            onClick={() => {
              setEditingSurgery({ ...record, index })
              setSurgeryDescription(record.description)
              setSurgeryDate(dayjs(record.date))
              setModalVisible(true)
            }}
          >
            Edit
          </Button>
          <Button
            danger
            size='small'
            icon={<FontAwesomeIcon icon={faTrash} />}
            onClick={() => handleDeleteSurgery(index)}
          >
            Delete
          </Button>
        </div>
      )
    }
  ]

  return (
    <div className='surgeries-tab'>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Button
            type='primary'
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              setSurgeryDescription('')
              setSurgeryDate(null)
              setModalVisible(true)
            }}
            style={{ marginBottom: '16px' }}
          >
            Add Surgery
          </Button>
        </Col>

        <Col span={24}>
          <Table dataSource={surgeryList} columns={columns} rowKey={(record, index) => index} pagination={false} />
        </Col>

        <Col span={24}>
          <Button type='primary' onClick={handleSave} block>
            Save Surgical History
          </Button>
        </Col>
      </Row>

      {/* Add/Edit Surgery Modal */}
      <Modal
        title={editingSurgery ? 'Edit Surgery' : 'Add Surgery'}
        open={modalVisible}
        onOk={editingSurgery ? handleUpdateSurgery : handleAddSurgery}
        onCancel={() => {
          setModalVisible(false)
          setSurgeryDescription('')
          setSurgeryDate(null)
          setEditingSurgery(null)
        }}
        okText={editingSurgery ? 'Update' : 'Add'}
      >
        <div style={{ marginBottom: '16px' }}>
          <label>Surgery Description:</label>
          <Input
            value={surgeryDescription}
            onChange={(e) => setSurgeryDescription(e.target.value)}
            placeholder='Enter surgery description'
            style={{ width: '100%' }}
          />
        </div>

        <div>
          <label>Surgery Date:</label>
          <DatePicker value={surgeryDate} onChange={setSurgeryDate} style={{ width: '100%' }} />
        </div>
      </Modal>
    </div>
  )
}

export default SurgeriesTab
