import React from 'react'
import MobPatAppointmentsCard from './MobPastAppointmentCad'
import { Col, Row } from 'antd'
import '../styling/PastAppointments.css'

const PastAppointments = ({ data }) => {
  return (
    <Row gutter={[16, 16]} style={{ height: '100%', overflow: 'auto', paddingBottom: '100px' }}>
      {[...data].reverse().map((appointment) => (
        <Col span={24}>
          <MobPatAppointmentsCard key={appointment.id} appointment={appointment} />
        </Col>
      ))}
    </Row>
  )
}

export default PastAppointments
