import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

export const useDataPoints = (hasLabReport) => {
  const availableDataPoints = useSelector((state) => state.dataPoints.data)
  const [selectedDataPoints, setSelectedDataPoints] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])

  const toggleDataPoint = (dataPoint) => {
    const dataPointStr = JSON.stringify(dataPoint)
    const isSelected = selectedDataPoints.includes(dataPointStr)

    let newSelectedPoints = [...selectedDataPoints]
    let newSelectedKeys = [...selectedKeys]

    if (isSelected) {
      // Remove the data point
      newSelectedPoints = selectedDataPoints.filter((point) => point !== dataPointStr)
      newSelectedKeys = selectedKeys.filter((key) => key !== dataPoint.key)

      // If removing height or weight, also remove BMI
      if (dataPoint.key === 'height' || dataPoint.key === 'weight') {
        const bmiPoint = availableDataPoints.find((dp) => dp.key === 'bmi')
        if (bmiPoint) {
          newSelectedPoints = newSelectedPoints.filter((point) => point !== JSON.stringify(bmiPoint))
          newSelectedKeys = newSelectedKeys.filter((key) => key !== 'bmi')
        }
      }
    } else {
      // Add the data point
      newSelectedPoints.push(dataPointStr)
      newSelectedKeys.push(dataPoint.key)

      // If adding height or weight, check if we should add BMI
      if (dataPoint.key === 'height' || dataPoint.key === 'weight') {
        const hasHeight = newSelectedKeys.includes('height')
        const hasWeight = newSelectedKeys.includes('weight')

        if (hasHeight && hasWeight) {
          const bmiPoint = availableDataPoints.find((dp) => dp.key === 'bmi')
          if (bmiPoint && !newSelectedKeys.includes('bmi')) {
            newSelectedPoints.push(JSON.stringify(bmiPoint))
            newSelectedKeys.push('bmi')
          }
        }
      }
    }

    setSelectedDataPoints(newSelectedPoints)
    setSelectedKeys(newSelectedKeys)
  }

  return {
    selectedDataPoints,
    setSelectedDataPoints,
    availableDataPoints,
    selectedKeys,
    setSelectedKeys,
    toggleDataPoint
  }
}
