import Agilite from 'agilite'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const readProceduresPerformed = (qry, options, projection, page, pageLimit) => {
  return new Promise((resolve, reject) => {
    let response = []

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('procedures_performed', 'read', {
          filter: JSON.stringify(qry),
          options: JSON.stringify(options),
          projection,
          page,
          pageLimit
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createProcedurePerformed = (data) => {
  return new Promise((resolve, reject) => {
    let response = []

    ;(async () => {
      try {
        response = await agilite.Connectors.execute('procedures_performed', 'update', {
          filter: JSON.stringify({
            bookingRef: data.bookingRef,
            key: data.key
          }),
          options: JSON.stringify({
            upsert: true,
            new: true
          }),
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
