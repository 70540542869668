import React, { memo } from 'react'
import { Modal, Form, Input, Select, Space, DatePicker } from 'antd'
import { TASK_TYPE_CONFIG } from '../config/taskTypes'
import { STAFF_MEMBERS, CURRENT_USER, DATE_FORMAT } from '../config/constants'
import dayjs from 'dayjs'
import styles from '../styles/CreateTaskModal.module.css'
import { useSelector } from 'react-redux'

const { Option } = Select

const CreateTaskModal = ({ visible, onCancel, onSubmit, form, medicalProfs }) => {
  const authState = useSelector((state) => state.auth)
  const getRole = (staff) => {
    switch (staff.extraData.role.type) {
      case 'medical_professional':
        if (staff.extraData.profession === 'nurse') {
          return 'Nurse'
        } else if (staff.extraData.profession === 'doctor') {
          return 'Doctor'
        }
        break
      case 'reception':
        return 'Receptionist'
      case 'admin':
        return 'Admin'
      case 'super_admin':
        return 'Super Admin'
      default:
        return 'Unknown'
    }
  }
  return (
    <Modal
      title='Create New Task'
      open={visible}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      onOk={() => form.submit()}
      className={styles.taskModal}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(values) => {
          const formattedValues = {
            ...values,
            status: 'active',
            dueDate: values.dueDate.toISOString()
          }
          onSubmit(formattedValues)
        }}
        initialValues={{
          assignee: authState.agiliteUser._id,
          status: 'active'
        }}
      >
        <Form.Item
          name='description'
          label='Task Description'
          rules={[{ required: true, message: 'Please enter task description' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name='taskType' label='Task Type' rules={[{ required: true, message: 'Please select task type' }]}>
          <Select>
            {Object.entries(TASK_TYPE_CONFIG).map(([key, config]) => (
              <Option key={key} value={key}>
                <Space>
                  {config.icon}
                  {config.label}
                  <span className={styles.typeDescription}>- {config.description}</span>
                </Space>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='assignee'
          label='Assignee'
          rules={[{ required: true, message: 'Please select who is responsible for this task' }]}
        >
          <Select showSearch placeholder='Select staff member' optionFilterProp='children'>
            <Option key={authState.agiliteUser._id} value={authState.agiliteUser._id}>
              You (Self-assigned)
            </Option>
            {medicalProfs
              .filter((staff) => staff._id !== authState.agiliteUser._id)
              .map((staff) => (
                <Option key={staff._id} value={staff.name}>
                  {staff.firstName} {staff.lastName} ({getRole(staff)})
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name='dueDate' label='Due Date' rules={[{ required: true, message: 'Please select due date' }]}>
          <DatePicker
            format={DATE_FORMAT}
            className={styles.datePicker}
            disabledDate={(current) => {
              return current && current < dayjs().startOf('day')
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default memo(CreateTaskModal)
