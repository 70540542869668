import React from 'react'
import { Col, Button, Table, Empty, Space } from 'antd'

import { faRefresh } from '@fortawesome/free-solid-svg-icons'

import Templates from '../utils/templates'
import { sortBy } from 'lodash'
import CustomButton from '../../../reusable-components/CustomButton'
import CustomRow from '../../../reusable-components/CustomRow'
import { useDispatch, useSelector } from 'react-redux'
import coreReducer from '../../../../core/utils/reducer'
import useServices from '../hooks/useServices'

const ServicesTable = () => {
  const coreState = useSelector((state) => state.core)
  const dispatch = useDispatch()

  const { loading, viewData, refreshView, createRecord, editRecord, filters } = useServices()

  return (
    <CustomRow justify='center'>
      <Col span={24}>
        <CustomRow className='basic-card'>
          <Col span={24}>
            <Space wrap>
              <CustomButton
                size='small'
                text='Refresh'
                icon={faRefresh}
                onClick={() => {
                  refreshView()
                }}
                type='primary'
                disabled={loading}
              />
              <Col>
                <Button
                  id='service_add_btn'
                  onClick={() => {
                    createRecord()

                    if (coreState.tourOpen) {
                      dispatch(coreReducer.actions.nextTourStep())
                    }
                  }}
                  disabled={loading}
                  type='primary'
                >
                  Add Service
                </Button>
              </Col>
            </Space>
          </Col>
          <Col span={24}>
            <Table
              size='small'
              bordered
              id='services_list_view'
              loading={loading}
              rowKey={(record) => record._id}
              dataSource={sortBy(viewData, 'name')}
              pagination={{ defaultPageSize: 10, showSizeChanger: true }}
              scroll={{ x: 950 }}
              columns={Templates.columnTemplate(filters)}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => editRecord(record)
                }
              }}
              locale={{ emptyText: <Empty description='There are currently no services to show.' /> }}
            />
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default ServicesTable
