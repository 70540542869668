import React, { useState } from 'react'
import { Button, Card, Col, Space, Switch } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCheckCircle, faStar, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import MobileMacroRings from './MobileMacroRings'
import MobileCameraCapture from './MobileCameraCapture'
import '../style/MobileNewImageUpload.css'
import useMobileNewImage from '../hooks/useMobileNewImage'
import CustomRow from '../../reusable-components/CustomRow'
import MobileHealthyAlternatives from './MobileHealthyAlternatives'

const MobileNewImageUpload = ({ setModalOpen, savedMeals, setSavedMeals, limits, handleRefresh }) => {
  const [showHealthyAlternative, setShowHealthyAlternative] = useState(false)
  
  const {
    contactMacroAssistantAi,
    contactingAi,
    macroData,
    servings,
    setServings,
    aiError,
    setSaveToFavourites,
    saveToFavourites,
    uploadedImage,
    handleCreateNewMacroEntry,
    saving,
    HAMSaved,
    setHAMSaved
  } = useMobileNewImage({
    setModalOpen,
    savedMeals,
    setSavedMeals,
    limits,
    handleRefresh
  })

  // Handle healthy alternative view
  const handleHealthyAlternativeClick = () => {
    setShowHealthyAlternative(true)
  }

  // Handle back from healthy alternative
  const handleBackFromHA = () => {
    setShowHealthyAlternative(false)
    setHAMSaved(true) // Show success message when coming back
  }

  if (showHealthyAlternative && macroData?.healthyAlternative) {
    return (
      <MobileHealthyAlternatives
        healthyAlternative={macroData.healthyAlternative}
        onBack={handleBackFromHA}
        userRef={null}
      />
    )
  }

  return (
    <div className='mobile-new-image'>
      {contactingAi ? (
        <div className="loading-container">
          <AgiliteSkeleton 
            skActive 
            spinnerTip='Analysing meal...'
          />
        </div>
      ) : (
        <>
          {macroData ? (
            <>
              <Card className='section-header'>
                <h2>New Meal Entry</h2>
              </Card>

              {macroData.healthyAlternative && !HAMSaved && (
                <Button
                  type='primary'
                  className='healthy-alt-button'
                  onClick={handleHealthyAlternativeClick}
                >
                  View healthy alternative <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              )}

              {HAMSaved && (
                <Card className='success-message'>
                  <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                    <b>Healthy Alternative Added to Recipes!</b>
                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#52c41a' }} />
                  </Space>
                </Card>
              )}

              <div className='preview-section'>
                <img src={uploadedImage} alt='Meal preview' className='meal-preview' />
              </div>

              <Card className='meal-description'>
                <p>{macroData.description || 'No description available'}</p>
              </Card>

              <MobileMacroRings 
                macroData={macroData} 
                servings={servings} 
                setServings={setServings} 
                limits={limits}
                noBottomPadding={true} 
              />

              <Card className='save-options'>
                <Space>
                  Save to favourite meals?
                  <Switch
                    onChange={setSaveToFavourites}
                    value={saveToFavourites}
                    checkedChildren={<FontAwesomeIcon icon={faStar} />}
                    unCheckedChildren='No'
                  />
                </Space>
              </Card>

              <Card className='disclaimer-note'>
                <p className='note-title'>Please note:</p>
                <p className='note-text'>
                  The nutrient data provided by this AI tool is intended for informational purposes only and may not be
                  fully accurate. It should not be used as a substitute for professional dietary advice. We recommend
                  consulting a qualified healthcare provider for personalized guidance.
                </p>
              </Card>

              <div className='action-buttons'>
                <Button onClick={() => setModalOpen(false)} className='cancel-button' disabled={saving}>
                  <FontAwesomeIcon icon={faArrowLeft} /> Back
                </Button>
                <Button type='primary' onClick={handleCreateNewMacroEntry} loading={saving} className='confirm-button'>
                  <FontAwesomeIcon icon={faCheckCircle} /> Confirm
                </Button>
              </div>
            </>
          ) : (
            <div className='capture-section'>
              <CustomRow>
                <Col span={24}>
                  <p style={{ color: 'white' }}></p>
                </Col>
                <Col span={24}>
                  <MobileCameraCapture onAfterCapture={contactMacroAssistantAi} />
                </Col>

                <Col span={24}> {aiError && <Card className='error-message'>{aiError}. Please try again.</Card>}</Col>
              </CustomRow>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default MobileNewImageUpload
