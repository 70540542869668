import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  theme,
  Tooltip
} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { handleError } from '../../lib/utils'
import { countRemittances, createRemittance, getRemittances, updateRemittance } from '../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faCheckCircle, faEye, faRefresh } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../core/utils/reducer'
import CoreEnums from '../../../core/utils/enums'
import MedicalAidPaymentForm from './medical-aid-payment-form'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import RemittanceForm from './remittance-form'
import { debounce } from 'lodash'
import { MedPraxSchemeSearch } from '../../Scripting/utils/utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'

const MedicalAidPaymentList = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [filters, setFilters] = useState({
    status: '',
    invoiceType: '',
    createdAt: '',
    search: ''
  })

  const [remittanceLoading, setRemittanceLoading] = useState(false)
  const [remittanceModalVisible, setRemittanceModalVisible] = useState(false)

  const [schemeData, setSchemeData] = useState([])
  const [schemeLoading, setSchemeLoading] = useState(false)

  useEffect(() => {
    handleCountRemittances()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleGetRemittances()
    // eslint-disable-next-line
  }, [filters, page, pageSize])

  const handleCountRemittances = async () => {
    let tmpTotal = 0

    try {
      tmpTotal = await countRemittances()
      setTotal(tmpTotal)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleGetRemittances = async () => {
    let tmpData = []
    let tmpTotal = 0
    let tmpFilters = JSON.parse(JSON.stringify(filters))

    setLoading(true)

    try {
      // Filters
      for (const key in tmpFilters) {
        if (!tmpFilters[key]) {
          delete tmpFilters[key]
        }

        if (key === 'createdAt' && tmpFilters[key]) {
          tmpFilters[key] = {
            $lte: dayjs(new Date(tmpFilters[key])).set('hour', 23).set('minute', 59).set('second', 59).toDate(),
            $gte: dayjs(new Date(tmpFilters[key])).set('hour', 0).set('minute', 0).set('second', 0).toDate()
          }
        }

        if (key === 'search' && tmpFilters[key]) {
          tmpFilters.$or = [
            { receiptNumber: { $regex: tmpFilters[key], $options: 'i' } },
            { medicalAid: { $regex: tmpFilters[key], $options: 'i' } }
          ]
          delete tmpFilters[key]
        }
      }

      tmpTotal = await countRemittances(tmpFilters)
      setTotal(tmpTotal)

      tmpData = await getRemittances(tmpFilters, page, pageSize)

      setData(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleOpenRemittance = (record) => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.REMITTANCES}_${record._id}`,
        closable: true,
        label: `Remittance: ${record.createdAt}`,
        children: <RemittanceForm data={record} isNew={false} handleGetRemittances={handleGetRemittances} />
      })
    )
  }

  const handleFinalizeRemittance = async (record) => {
    try {
      await updateRemittance(record._id, { finalized: true, finalizedDate: new Date() })
      handleGetRemittances()
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleSaveRemittance = async () => {
    let tmpData = null

    setRemittanceLoading(true)

    try {
      tmpData = await createRemittance({
        ...remittanceForm.getFieldsValue(),
        createdAt: new Date(),
        finalized: false
      })
      remittanceForm.resetFields()
      setRemittanceModalVisible(false)

      dispatch(
        coreReducer.actions.addTab({
          key: `${CoreEnums.tabKeys.PAYMENT_REMITTANCE}_new`,
          closable: true,
          label: 'Create New Medical Aid Payment',
          children: <MedicalAidPaymentForm data={tmpData} handleGetRemittances={handleGetRemittances} />
        })
      )
    } catch (e) {
      message.error(handleError(e, true))
    }

    setRemittanceLoading(false)
  }

  const handleGetMedicalAids = async (query) => {
    let tmpData = []

    setSchemeLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemeSearch(query)
        setSchemeData(tmpData)
      } else {
        setSchemeData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setSchemeLoading(false)
  }

  // eslint-disable-next-line
  const debouncedSchemeFilter = useCallback(
    debounce((query) => {
      if (query) {
        handleGetMedicalAids(query)
      } else {
        setSchemeLoading(false)
      }
    }, 1000),
    []
  )

  const { token } = theme.useToken()
  const [remittanceForm] = Form.useForm()

  return (
    <CustomRow>
      <Col span={24}>
        <Card
          type='inner'
          size='small'
          extra={
            <Space>
              <Button
                type='primary'
                onClick={() => setRemittanceModalVisible(true)}
                style={{ backgroundColor: token.colorSecondary }}
              >
                <Space>
                  <FontAwesomeIcon icon={faAdd} />
                  New Remittance
                </Space>
              </Button>
            </Space>
          }
        >
          <Row justify='space-between' style={{ marginBottom: 10 }}>
            <Col span={4}>
              <DatePicker
                placeholder='Date'
                style={{ width: '100%' }}
                onChange={(date) => setFilters({ ...filters, createdAt: date })}
              />
            </Col>
            <Col span={4}>
              <Input
                placeholder='Search'
                style={{ width: '100%' }}
                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
              />
            </Col>
            <Col span={4}>
              <Button
                style={{ float: 'right', backgroundColor: token.colorSuccess }}
                type='primary'
                onClick={handleGetRemittances}
              >
                <Space>
                  <FontAwesomeIcon icon={faRefresh} />
                  Refresh
                </Space>
              </Button>
            </Col>
          </Row>
          <Table
            loading={loading}
            dataSource={data}
            bordered
            size='small'
            columns={[
              {
                title: 'Creation Date',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
                sorter: (a, b) => a.createdAt - b.createdAt,
                width: '10%'
              },
              {
                title: 'Medical Aid Scheme',
                dataIndex: 'medicalAid',
                key: 'medicalAid',
                width: '10%',
                sorter: (a, b) => a.medicalAid - b.medicalAid
              },
              {
                title: 'Receipt Number',
                dataIndex: 'receiptNumber',
                key: 'receiptNumber',
                sorter: (a, b) => a.receiptNumber.localeCompare(b.receiptNumber)
              },
              {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                sorter: (a, b) => a.total.localeCompare(b.total)
              },
              {
                title: 'Outstanding',
                dataIndex: 'runningTotal',
                key: 'runningTotal',
                sorter: (a, b) => a.runningTotal.localeCompare(b.runningTotal)
              },
              {
                title: 'Finalized Date',
                dataIndex: 'finalizedDate',
                key: 'finalizedDate',
                render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss')
              },
              {
                title: 'Finalized',
                dataIndex: 'finalized',
                key: 'finalized',
                render: (text) =>
                  text ? (
                    <center>
                      <CheckCircleOutlined style={{ fontSize: 20, color: token.colorSuccess }} />
                    </center>
                  ) : (
                    <center>
                      <CloseCircleOutlined style={{ fontSize: 20, color: token.colorError }} />
                    </center>
                  ),
                width: '5%'
              },
              {
                title: 'Actions',
                key: 'status',
                width: '10%',
                render: (text, record) => {
                  return (
                    <center>
                      <Space style={{ gap: 24 }}>
                        <Tooltip title='View Remittance'>
                          <FontAwesomeIcon
                            style={{ color: '#aaa' }}
                            icon={faEye}
                            onClick={() => handleOpenRemittance(record)}
                          />
                        </Tooltip>
                        <Tooltip title='Finalize Remittance'>
                          <FontAwesomeIcon
                            style={{ color: '#aaa' }}
                            icon={faCheckCircle}
                            onClick={() => handleFinalizeRemittance(record)}
                          />
                        </Tooltip>
                      </Space>
                    </center>
                  )
                }
              }
            ]}
            pagination={{
              total: total,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true
            }}
            onChange={(pagination) => {
              setPage(pagination.current)
              setPageSize(pagination.pageSize)
            }}
          />
        </Card>
      </Col>
      <Modal
        title='Create Payment Remittance'
        closable={false}
        destroyOnClose={true}
        okText='Save'
        cancelButtonProps={{ danger: true }}
        okButtonProps={{ loading: remittanceLoading }}
        onCancel={() => {
          remittanceForm.resetFields()
          setRemittanceModalVisible(false)
        }}
        open={remittanceModalVisible}
        onOk={() => {
          remittanceForm
            .validateFields()
            .then(async () => {
              handleSaveRemittance()
            })
            .catch((e) => {})
        }}
        width={800}
      >
        <Form
          layout='vertical'
          form={remittanceForm}
          initialValues={{
            receiptNumber: dayjs(new Date()).format('YYMMDDHHmmss')
          }}
        >
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                name='medicalAid'
                label='Medical Aid'
                rules={[{ required: true, message: 'Medical Aid is required' }]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder='Search for a Medical Aid...'
                  onSearch={(value) => {
                    debouncedSchemeFilter(value)
                  }}
                  onChange={(e, option) => {
                    remittanceForm.setFieldValue('medicalAid', option?.data ? JSON.parse(option.data).name : '')
                    setSchemeData([])
                  }}
                  options={schemeData.map((i) => {
                    return {
                      label: i.name,
                      value: i.name,
                      data: JSON.stringify(i)
                    }
                  })}
                  notFoundContent={
                    schemeLoading ? <AgiliteSkeleton skActive spinnerTip='Searching Schemes...' /> : undefined
                  }
                />
              </Form.Item>
              <Form.Item
                name='remittanceDate'
                label='Remittance Date'
                rules={[{ required: true, message: 'Remittance Date is required' }]}
              >
                <DatePicker style={{ width: '100%' }} placeholder='Remittance Date' />
              </Form.Item>
              <Form.Item
                name='receiptNumber'
                label='Receipt Number'
                rules={[{ required: true, message: 'Receipt Number is required' }]}
              >
                <Input readOnly style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='total' label='Total' rules={[{ required: true, message: 'Total is required' }]}>
                <Input addonBefore='R' style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name='runningTotal'
                label='Running Total'
                rules={[{ required: true, message: 'Running Total is required' }]}
              >
                <Input addonBefore='R' style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name='practiceRefNo' label='Practice Reference Number'>
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </CustomRow>
  )
}

export default MedicalAidPaymentList
