import { Col, Empty, Input, Row, Table, message, theme } from 'antd'
import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import { getSubscriptions } from '../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import CustomButton from '../../reusable-components/CustomButton'
import { faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons'
import CustomRow from '../../reusable-components/CustomRow'
import { readMedicalHistory, readPatients } from '../../Admin/patients/utils/utils'
import AdminSubscriptionsReducer from '../utils/reducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { debounce } from 'lodash'
import coreReducer from '../../../core/utils/reducer'
import AdminSubscriptionsForm from './admin-subscriptions-form'
import { handleError } from '../../lib/utils'
import { readEntities } from '../../Admin/entities/utils/utils'
import { getMedHistProgress } from '../../Medical Vault/medical-history/utils/utils'

const AdminSubscriptionsList = () => {
  const dispatch = useDispatch()
  const subscriptionState = useSelector((state) => state.adminSubscriptions)
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [entities, setEntities] = useState([])

  useEffect(() => {
    handleGetSubscriptions()
    // eslint-disable-next-line
  }, [searchQuery])

  const handleGetSubscriptions = async () => {
    let qry = null
    let subscriptions = []
    let idArray = []
    let users = []
    let userRecords = []
    let subUsers = []
    let entityRecords = []

    setLoading(true)

    try {
      if (searchQuery) {
        qry = {
          $or: [
            {
              $expr: {
                $regexMatch: {
                  input: { $concat: ['$firstName', ' ', '$lastName'] },
                  regex: searchQuery,
                  options: 'i'
                }
              }
            },
            { phoneNumber: { $regex: searchQuery, $options: 'i' } },
            { email: { $regex: searchQuery, $options: 'i' } },
            { idNo: { $regex: searchQuery, $options: 'i' } }
          ]
        }
      }

      subscriptions = await getSubscriptions()

      for (const entry of subscriptions) {
        idArray.push(entry.userRef)
      }

      users = await readPatients({ _id: { $in: idArray }, ...qry })
      userRecords = await readMedicalHistory({ userRef: { $in: idArray } })
      entityRecords = await readEntities()

      for (const entry of users) {
        subUsers.push({
          ...entry,
          subscription: subscriptions.find((sub) => sub.userRef === entry._id),
          medical: userRecords.find((record) => record.userRef === entry._id)
        })
      }

      for (const entry of subUsers) {
        entry.subscription.entityName = entityRecords.find((entity) => entity._id === entry.subscription.entityRef).name
      }

      setEntities(entityRecords)
      dispatch(AdminSubscriptionsReducer.actions.setAdminSubs(subUsers))
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleSearch = (value) => {
    setLoading(true)
    debouncedFilter(value)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      if (query) {
        setSearchQuery(query)
      } else {
        handleGetSubscriptions()
      }
    }, 1000),
    []
  )

  const handleRecordClick = (record) => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${record._id}-tab`,
        closable: true,
        label: `Patient: ${record.firstName} ${record.lastName}`,
        children: (
          <AdminSubscriptionsForm loading={loading} record={record} handleGetSubscriptions={handleGetSubscriptions} />
        )
      })
    )
  }

  const { token } = theme.useToken()

  const columnStyle = {
    width: '100%',
    height: '100%',
    padding: 5,
    textAlign: 'center'
  }

  return (
    <Row justify='center'>
      <Col span={24}>
        <ContainerCard title='Meta-Link Subscription Administration'>
          <Row justify='center'>
            <Col span={24}>
              <CustomRow justify='center' className='basic-card'>
                <Col span={8}>
                  <CustomButton
                    text='Refresh'
                    size='small'
                    icon={faRefresh}
                    onClick={handleGetSubscriptions}
                    type='primary'
                    disabled={loading}
                  />
                </Col>
                <Col span={8}>
                  <Input
                    addonBefore={<FontAwesomeIcon icon={faSearch} />}
                    placeholder='Search By First Name, Last Name, ID No, Email or Phone...'
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </Col>
                <Col span={8}>
                  <center>Total Records: {subscriptionState.data.length}</center>
                </Col>
                <Col span={24}>
                  <Table
                    size='small'
                    loading={loading}
                    columns={[
                      { title: 'First Name', dataIndex: 'firstName', key: 'firstName' },
                      { title: 'Last Name', dataIndex: 'lastName', key: 'lastName' },
                      { title: 'ID/Passport Number', dataIndex: 'idNo', key: 'idNo', width: '10%' },
                      { title: 'Phone', dataIndex: 'phoneNumber', key: 'phoneNumber', width: '10%' },
                      { title: 'Email', dataIndex: 'email', key: 'email' },
                      {
                        title: 'Entity / Company',
                        key: 'entityRef',
                        render: (text, record) => {
                          return record.subscription.entityName
                        },
                        filters: entities.map((entity) => {
                          return {
                            text: entity.name,
                            value: entity._id
                          }
                        }),
                        onFilter: (value, record) => record.subscription.entityRef === value
                      },
                      {
                        title: 'Medical History',
                        dataIndex: ['medical', 'medicalHistory', 'updatedAt'],
                        key: 'medicalHistory',
                        render: (text) => (text ? 'Yes' : 'No'),
                        filters: [
                          { text: 'Yes', value: true },
                          { text: 'No', value: false }
                        ],
                        onFilter: (value, record) => {
                          return getMedHistProgress(record.medical.medicalHistory) === '100'
                        },
                        width: '10%'
                      },
                      {
                        title: 'Staff',
                        dataIndex: ['subscription', 'isStaff'],
                        key: 'isStaff',
                        render: (text) => (text ? 'Yes' : 'No'),
                        filters: [
                          { text: 'Yes', value: true },
                          { text: 'No', value: false }
                        ],
                        onFilter: (value, record) => record.subscription.isStaff === value,
                        width: '6%'
                      },
                      {
                        title: 'Active',
                        dataIndex: ['subscription', 'isActive'],
                        key: 'isActive',
                        render: (text) => (text ? 'Yes' : 'No'),
                        filters: [
                          { text: 'Yes', value: true },
                          { text: 'No', value: false }
                        ],
                        onFilter: (value, record) => record.subscription.isActive === value,
                        width: '6%'
                      },
                      {
                        title: 'AidCall Verified',
                        dataIndex: ['subscription', 'verified'],
                        key: 'verified',
                        render: (text) =>
                          text ? (
                            <div
                              style={{
                                backgroundColor: token.colorSuccess,
                                ...columnStyle
                              }}
                            >
                              Yes
                            </div>
                          ) : (
                            <div
                              style={{
                                backgroundColor: token.colorWarning,
                                ...columnStyle
                              }}
                            >
                              No
                            </div>
                          ),
                        filters: [
                          { text: 'Yes', value: true },
                          { text: 'No', value: false }
                        ],
                        onFilter: (value, record) => record.subscription.verified === value,
                        width: '10%'
                      }
                    ]}
                    dataSource={subscriptionState.data}
                    locale={{ emptyText: <Empty description='No User Subscriptions Found' /> }}
                    pagination={{ defaultPageSize: 50, showPrevNextJumpers: true, showSizeChanger: true }}
                    onRow={(record) => {
                      return {
                        onClick: () => handleRecordClick(record)
                      }
                    }}
                  />
                </Col>
              </CustomRow>
            </Col>
          </Row>
        </ContainerCard>
      </Col>
    </Row>
  )
}

export default AdminSubscriptionsList
