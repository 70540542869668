import React from 'react'
import { Col, Row } from 'antd'
import { faChartLine, faBook, faPlus } from '@fortawesome/free-solid-svg-icons'
import MobileMacroOverview from './MobileMacroOverview'
import MobileNewImageUpload from './MobNewImageUpload'
import MobileMacroGraphs from './MobileMacroGraphs'
import '../style/MobileMacroTracker.css'
import useMobileTracker from '../hooks/useMobileTracker'
import MobTransparentButton from '../../../../../Reusable Components/Buttons/MobTransparentButton'
import MobMedicalHistoryLoadingOverlay from '../../../../../Medical History/Mobile/components/LoadingOverlay'
import MobileHealthyAlternatives from './MobHealthyAlternatives'

const MobMacroTracker = ({ userRef, isDoctor }) => {
  const {
    imageUploadModalOpen,
    setImageUploadModalOpen,
    savedMeals,
    setSavedMeals,
    limits,
    handleGetMetaData,
    capturedImage,
    setCapturedImage,
    showGraphs,
    trackerData,
    dateRange,
    setDateRange,
    setShowGraphs,
    setShowHealthyAlternatives,
    showHealthyAlternatives,
    handleCameraCapture,
    isCapturing,
    loading
  } = useMobileTracker({ userRef })

  return (
    <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
      <Col span={24}>
        {loading ? (
          <MobMedicalHistoryLoadingOverlay loading text='Busy,,,' />
        ) : imageUploadModalOpen ? (
          <MobileNewImageUpload
            setModalOpen={setImageUploadModalOpen}
            savedMeals={savedMeals}
            setSavedMeals={setSavedMeals}
            limits={limits}
            handleRefresh={handleGetMetaData}
            capturedImage={capturedImage}
            setCapturedImage={setCapturedImage}
            onBack={() => setImageUploadModalOpen(false)}
          />
        ) : showGraphs ? (
          <MobileMacroGraphs
            trackerData={trackerData}
            dateRange={dateRange}
            setDateRange={setDateRange}
            onBack={() => setShowGraphs(false)}
          />
        ) : showHealthyAlternatives ? (
          <MobileHealthyAlternatives
            userRef={userRef}
            isDoctor={isDoctor}
            onBack={() => setShowHealthyAlternatives(false)}
          />
        ) : (
          <div style={{ paddingBottom: '100px' }}>
            <MobileMacroOverview
              limits={limits}
              setImageUploadModalOpen={setImageUploadModalOpen}
              setShowGraphs={setShowGraphs}
              setShowHealthyAlternatives={setShowHealthyAlternatives}
              onCameraCapture={handleCameraCapture}
              handleGetMetaData={handleGetMetaData}
            />
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <MobTransparentButton
                  text=' View Progress'
                  icon={faChartLine}
                  functionCall={() => setShowGraphs(true)}
                />
              </Col>
              <Col span={12}>
                <MobTransparentButton
                  // additionalProps={{ disabled: true }}
                  text='View Recipes'
                  icon={faBook}
                  functionCall={() => setShowHealthyAlternatives(true)}
                />
              </Col>
              <Col span={24}>
                {!isCapturing && (
                  <MobTransparentButton
                    text='Take Photos'
                    icon={faPlus}
                    functionCall={() => setImageUploadModalOpen(true)}
                  />
                )}
              </Col>
            </Row>
          </div>
        )}
      </Col>
    </Row>
  )
}

export default MobMacroTracker
