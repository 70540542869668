import { deviceDetect } from 'react-device-detect'
import { hexToRGBA } from '../../custom/lib/utils'
import store from '../../store'
import CoreEnums from './enums'

const genericConfig = {
  colorError: '#FF4D4F',
  colorWarning: '#FFC53D'
}

const lightConfig = {
  primaryColor: '#10B2E1',
  primaryMainLight: 'rgba(218, 243, 250,0.54)',
  secondaryColor: '#F14F8B',
  baseBg: '#F4F7FA',
  containerBg: '#FFFFFF',
  baseText: '#000000',
  baseBorder: '#ccc',
  darkerBg: '#f5f5f5',
  accent1: '#12BFA6',
  menuBg: '#1C2536'
}

const darkConfig = {
  primaryColor: '#1565C0',
  secondaryColor: '',
  baseBg: '#0F111A',
  containerBg: '#141824',
  baseText: '#E3E6EC',
  baseBorder: 'grey',
  darkerBg: '#f5f5f5',
  accent1: '#12BFA6'
}

const getTableColor = (customTheme) => {
  const tabKeysEnum = CoreEnums?.tabKeys
  const menuColors = CoreEnums?.menuColors

  const key =
    Object.keys(tabKeysEnum).find((k) => tabKeysEnum[k] === store?.getState()?.core?.tabNavigation?.activeKey) ||
    Object.keys(tabKeysEnum).find(
      (k) => tabKeysEnum[k] === store?.getState()?.core?.tabNavigation?.activeKey.split('_')[0]
    )

  return key ? menuColors[key] : customTheme.secondaryColor
}

const lightTheme = (customTheme = lightConfig) => {
  return {
    token: {
      themeControl: 'light',
      colorPrimary: customTheme.primaryColor,
      colorPrimaryLight: lightConfig.primaryMainLight,
      colorHeader: '#1C2536',
      colorSecondary: customTheme.secondaryColor,
      colorBgBase: lightConfig.baseBg,
      colorBgContainer: lightConfig.containerBg,
      colorTextBase: lightConfig.baseText,
      // Changes base color of all text
      colorFillAlter: lightConfig.darkerBg,
      // Changes card header background colour
      colorBorderSecondary: lightConfig.baseBorder,
      // Card border color
      colorAccent1: lightConfig.accent1,
      colorTextPlaceholder: '#808080'
    },
    components: {
      Collapse: {
        headerBg: hexToRGBA(customTheme.primaryColor, 0.2),
        colorTextHeading: '#000',
        colorBorder: '#fff'
      },
      Input: {
        // Changes the background of the input field
        colorBgContainer: lightConfig.baseBg
      },
      InputNumber: {
        // Changes the background of the input field
        colorBgContainer: lightConfig.baseBg
      },
      DatePicker: {
        // Changes the background of the datepicker
        colorBgContainer: lightConfig.baseBg,
        colorTextDisabled: lightConfig.baseText
      },
      Select: {
        // Changes the background of the select field
        colorBgContainer: lightConfig.baseBg,
        colorTextQuaternary: lightConfig.baseText
      },
      Button: {
        // Gets rid of the white block underneath the buttons
        controlOutlineWidth: 0
      },
      Table: {
        colorFillAlter: hexToRGBA(getTableColor(customTheme), 0.2), // There is no way to change the row hover colour without changing the header colour will have to be done through custom CSS
        controlItemBgActiveHover: lightConfig.primaryMainLight,
        controlItemBgActive: lightConfig.primaryMainLight,
        colorTextHeading: '#000000',
        colorFillContent: hexToRGBA(getTableColor(customTheme), 0.2),
        colorFillSecondary: hexToRGBA(getTableColor(customTheme), 0.2)
      },
      Dropdown: {
        // Changes Dropdown background colour
        colorBgElevated:
          store?.getState()?.auth?.agiliteUser?.extraData?.role?.type === 'patient' && deviceDetect().isMobile
            ? '#ffffff'
            : '#1C2536',
        colorText:
          store?.getState()?.auth?.agiliteUser?.extraData?.role?.type === 'patient' && deviceDetect().isMobile
            ? ''
            : 'white',
        // boxShadowSecondary: '0px 0px 5px 0px ' + lightConfig.accent1,
        controlItemBgHover:
          store?.getState()?.auth?.agiliteUser?.extraData?.role?.type === 'patient' && deviceDetect().isMobile
            ? ''
            : 'rgba(255,255,255,0.10)'
      },
      Radio: {
        colorBorder: '#313749',
        colorPrimary: customTheme.primaryColor,
        colorPrimaryHover: customTheme.primaryColor
      },
      Menu: {
        colorPrimary: 'white', // Text colour
        colorBgContainer: lightConfig.menuBg, // background colour of entire menu
        colorFillAlter: lightConfig.baseBg, // Background colour of dropdown menu sub items
        colorFillContent: '#86A9FF',
        lineWidth: 0, // Removes weird border to left of menu
        controlItemBgActive: 'rgba(255,255,255,0.10)', // Active item background colour
        colorBgTextHover: 'rgba(255,255,255,0.10)', // Background colour transition effect on item hover
        colorItemTextHover: 'white',
        colorItemText: 'white',
        subMenuItemBg: '#1C2536',
        colorBgElevated: '#1C2536' // Background color for popup menu when collapsed
      },
      Layout: {
        colorBgHeader: '#1C2536',
        colorBgTrigger: '#2A3649'
      },
      Modal: {
        colorBgElevated: lightConfig.containerBg,
        colorBgMask: 'rgba(0, 0, 0, 0.7)'
      },
      Tabs: {
        colorFillAlter: 'rgba(153, 178, 204, 0.40)',
        colorBgContainer: hexToRGBA(customTheme.primaryColor, 0.4),
        colorPrimary: '#000000',
        colorPrimaryHover: '#000000'
      }
    }
  }
}

const darkTheme = {
  token: {
    themeControl: 'dark',
    colorPrimary: darkConfig.accent1,
    colorBgBase: darkConfig.baseBg,
    colorBgContainer: '#141824',
    colorTextBase: darkConfig.baseText,
    // Changes base color of all text
    colorFillAlter: '#1b2030',
    // Changes card header background colour
    colorBorderSecondary: '#313749',
    // Card border color
    colorAccent1: darkConfig.accent1,
    googleAddressControl: '#313749',
    colorTextPlaceholder: '#808080'
  },
  components: {
    Checkbox: {
      colorBorder: '#313749'
    },
    Input: {
      // Changes the background of the input field
      colorBgContainer: '#313749'
    },
    InputNumber: {
      colorBgContainer: '#313749'
    },
    DatePicker: {
      // Changes the background of the datepicker
      colorBgContainer: '#313749',
      colorTextDisabled: darkConfig.baseText
    },
    Select: {
      // Changes the background of the select field
      colorBgContainer: '#313749',
      colorBgElevated: '#313749',
      controlItemBgHover: darkConfig.primaryColor,
      controlItemBgActive: darkConfig.primaryColor,
      colorTextQuaternary: darkConfig.baseText
    },
    Button: {
      // Gets rid of the white block underneath the buttons
      controlOutlineWidth: 0
    },
    Table: {
      // colorFillAlter: 'red' // There is no way to change the row hover colour without changing the header colour will have to be done through custom CSS
      controlItemBgActiveHover: '#313749',
      controlItemBgActive: '#313749'
    },
    Dropdown: {
      // Changes Dropdown background colour
      colorBgElevated: darkConfig.containerBg,
      boxShadowSecondary: '0px 0px 5px 0px ' + darkConfig.accent1,

      controlItemBgHover: darkConfig.accent1
    },
    Radio: {
      colorBorder: '#313749'
    },
    Tag: {
      colorBorder: '#86A9FF'
    },
    Tooltip: {
      colorBgDefault: darkConfig.containerBg,
      colorTextLightSolid: darkConfig.baseText
    },
    Menu: {
      colorPrimary: 'white', // Text colour
      colorBgContainer: darkConfig.baseBg, // background colour of entire menu
      colorFillAlter: darkConfig.baseBg, // Background colour of dropdown menu sub items
      colorFillContent: '#86A9FF',
      lineWidth: 0, // Removes weird border to left of menu
      controlItemBgActive: darkConfig.accent1, // Active item background colour
      colorBgTextHover: darkConfig.accent1, // Background colour transition effect on item hover
      colorBgElevated: '#1C2536' // Background color for popup menu when collapsed
    },
    Modal: {
      colorBgElevated: darkConfig.containerBg,
      colorBgMask: 'rgba(0, 0, 0, 0.7)'
    }
  }
}

export { lightTheme, darkTheme, genericConfig }
