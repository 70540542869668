import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { faChainBroken, faRefresh, faCheckCircle, faNotesMedical, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Select, Space, Spin, Tooltip, message, theme, Tag, Typography, Alert, Badge } from 'antd'

import debounce from 'lodash/debounce'

import { handleError, hexToRGBA } from '../../../../lib/utils'
import { findBookingByIDUpdate } from '../../../examination-utils/examination-lib'
import { readBookings } from '../../../../Bookings/utils/utils'
import { SymptomsArray } from './SymptomsList'

const { Title, Text } = Typography

// Constants
const DEBOUNCE_DELAY = 500 // Reduced from 1000ms for better responsiveness
const ERROR_MESSAGE = 'There was a problem saving this symptom. Please try again.'
const SUCCESS_MESSAGE = 'Symptoms Captured Successfully'
const SYMPTOM_TAG_COLOR = '#FF69B4' // Hot pink color for symptom tags

/**
 * SymptomsCapture Component
 * Allows users to select and manage symptoms for a booking
 * @param {Object} props
 * @param {Object} props.bookingData - Booking information containing ID and symptoms
 */
const SymptomsCapture = ({ bookingData }) => {
  // State management
  const [selectedSymptoms, setSelectedSymptoms] = useState([])
  const [isSaveError, setIsSaveError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const { token } = theme.useToken()

  // Fetch existing symptoms on component mount
  useEffect(() => {
    let isMounted = true

    const fetchExistingSymptoms = async () => {
      if (!bookingData?._id) return

      setIsLoading(true)
      try {
        const fetchedSymptoms = await readBookings({ _id: bookingData._id }, null, 'symptoms')
        if (isMounted) {
          setSelectedSymptoms(fetchedSymptoms[0]?.symptoms || [])
        }
      } catch (error) {
        if (isMounted) {
          message.error(handleError(error))
        }
      } finally {
        if (isMounted) {
          setIsLoading(false)
        }
      }
    }

    fetchExistingSymptoms()

    return () => {
      isMounted = false
    }
  }, [bookingData?._id])

  // Optimized debounced function to handle symptom changes
  const debouncedSymptomsChange = useCallback(
    debounce(async (updatedSymptoms) => {
      if (!bookingData?._id) return

      setIsLoading(true)
      setIsSaveError(false)

      try {
        await findBookingByIDUpdate(bookingData._id, { symptoms: updatedSymptoms })
      } catch (error) {
        setIsSaveError(true)
        message.error(ERROR_MESSAGE)
      } finally {
        setIsLoading(false)
      }
    }, DEBOUNCE_DELAY),
    [bookingData?._id]
  )

  // Memoized filtered symptoms with optimized search
  const filteredSymptoms = useMemo(() => {
    const searchLower = searchQuery.toLowerCase()
    return SymptomsArray.filter(
      (symptom) => !selectedSymptoms.includes(symptom) && (!searchLower || symptom.toLowerCase().includes(searchLower))
    )
  }, [searchQuery, selectedSymptoms])

  // Optimized symptom selection handler
  const handleSymptomSelect = useCallback(
    (symptom) => {
      if (!symptom || selectedSymptoms.includes(symptom)) return

      const updatedSymptoms = [...selectedSymptoms, symptom]
      setSelectedSymptoms(updatedSymptoms)
      debouncedSymptomsChange(updatedSymptoms)
      setSearchQuery('')
    },
    [selectedSymptoms, debouncedSymptomsChange]
  )

  // Optimized symptom removal handler
  const handleSymptomRemove = useCallback(
    (symptomToRemove) => {
      const updatedSymptoms = selectedSymptoms.filter((symptom) => symptom !== symptomToRemove)
      setSelectedSymptoms(updatedSymptoms)
      debouncedSymptomsChange(updatedSymptoms)
    },
    [selectedSymptoms, debouncedSymptomsChange]
  )

  // Parse symptom text to extract name and code
  const parseSymptom = useCallback((symptom) => {
    // Check if the symptom has a category prefix (e.g., "General: ")
    const hasCategoryPrefix = symptom.includes(': ')
    
    // Check if the symptom has a code in parentheses (e.g., "(R63.4)")
    const hasCode = symptom.includes('(') && symptom.includes(')')
    
    let category = ''
    let name = symptom
    let code = ''
    
    if (hasCategoryPrefix) {
      const parts = symptom.split(': ')
      category = parts[0]
      name = parts[1]
    }
    
    if (hasCode) {
      const matches = name.match(/(.*)\s*(\([^)]+\))/)
      if (matches) {
        name = matches[1].trim()
        code = matches[2]
      }
    }
    
    return { category, name, code, full: symptom }
  }, [])

  // Memoized symptom tags renderer with improved styling
  const renderSymptomTags = useMemo(
    () => (
      <div style={{ 
        display: 'flex', 
        flexWrap: 'wrap', 
        marginBottom: selectedSymptoms.length > 0 ? 16 : 8, 
        gap: 8,
        width: '100%' 
      }}>
        {selectedSymptoms.map((symptom) => {
          const { category, name, code } = parseSymptom(symptom)
          
          return (
            <Tag
              key={symptom}
              closable
              onClose={() => handleSymptomRemove(symptom)}
              style={{
                backgroundColor: hexToRGBA(SYMPTOM_TAG_COLOR, 0.08),
                borderColor: hexToRGBA(SYMPTOM_TAG_COLOR, 0.2),
                color: SYMPTOM_TAG_COLOR,
                borderRadius: '16px',
                padding: '4px 8px 4px 12px',
                marginBottom: '5px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '13px',
                boxShadow: '0 1px 2px rgba(0,0,0,0.05)'
              }}
              closeIcon={
                <FontAwesomeIcon 
                  icon={faTimes} 
                  style={{ 
                    fontSize: '10px',
                    color: SYMPTOM_TAG_COLOR
                  }} 
                />
              }
            >
              <span style={{ fontWeight: 500 }}>{name}</span>
              {code && (
                <span style={{ 
                  marginLeft: 4, 
                  opacity: 0.7, 
                  fontSize: '11px',
                  fontWeight: 400
                }}>
                  {code}
                </span>
              )}
            </Tag>
          )
        })}
      </div>
    ),
    [selectedSymptoms, handleSymptomRemove, parseSymptom]
  )

  return (
    <Card
      styles={{ 
        header: { 
          background: hexToRGBA(token.colorPrimary, 0.08),
          borderBottom: `1px solid ${hexToRGBA(token.colorPrimary, 0.1)}`,
          padding: '12px 16px'
        },
        body: {
          padding: '16px'
        }
      }}
      size='small'
      title={
        <Space>
          <FontAwesomeIcon 
            icon={faNotesMedical} 
            style={{ 
              color: token.colorPrimary,
              fontSize: '16px'
            }} 
          />
          <Title level={5} style={{ margin: 0 }}>Symptoms</Title>
          {selectedSymptoms.length > 0 && (
            <Badge 
              count={selectedSymptoms.length} 
              style={{ 
                backgroundColor: SYMPTOM_TAG_COLOR,
                marginLeft: 8
              }}
            />
          )}
        </Space>
      }
      extra={
        <Space>
          {isLoading && <Spin size="small" />}
          {isSaveError && (
            <>
              <Tooltip title='Error Saving Symptoms'>
                <FontAwesomeIcon icon={faChainBroken} color={token.colorError} />
              </Tooltip>
              <Button 
                type="primary" 
                danger 
                size="small"
                onClick={() => debouncedSymptomsChange(selectedSymptoms)}
                icon={<FontAwesomeIcon icon={faRefresh} />}
              >
                Retry
              </Button>
            </>
          )}
          {!isSaveError && !isLoading && selectedSymptoms.length > 0 && (
            <Tooltip title={SUCCESS_MESSAGE}>
              <FontAwesomeIcon 
                icon={faCheckCircle} 
                style={{ 
                  color: token.colorSuccess,
                  fontSize: '16px'
                }} 
              />
            </Tooltip>
          )}
        </Space>
      }
      style={{
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden'
      }}
    >
      <div
        style={{
          width: '100%',
          background: isSaveError ? hexToRGBA(token.colorError, 0.05) : 'transparent',
          padding: isSaveError ? 16 : 0,
          borderRadius: isSaveError ? '4px' : 0
        }}
      >
        {renderSymptomTags}

        <Select
          style={{ 
            width: '100%',
            borderRadius: '6px'
          }}
          value={null}
          onSearch={setSearchQuery}
          onSelect={handleSymptomSelect}
          onClear={() => setSearchQuery('')}
          onDropdownVisibleChange={(visible) => !visible && setSearchQuery('')}
          dropdownStyle={{ minWidth: 650 }}
          options={filteredSymptoms.map((symptom) => ({
            value: symptom,
            label: symptom
          }))}
          filterOption={false}
          placeholder={
            <Space>
              <FontAwesomeIcon icon={faSearch} style={{ opacity: 0.5 }} />
              <span>Search and select symptoms to add</span>
            </Space>
          }
          optionFilterProp='label'
          showSearch
          showArrow
          listHeight={300}
          notFoundContent={
            <div style={{ padding: '12px', textAlign: 'center' }}>
              <Text type="secondary">No matching symptoms found</Text>
            </div>
          }
        />
        
        {selectedSymptoms.length > 0 && (
          <Text type="secondary" style={{ display: 'block', marginTop: 8, fontSize: '12px' }}>
            {selectedSymptoms.length} symptom{selectedSymptoms.length !== 1 ? 's' : ''} selected
          </Text>
        )}

        {isSaveError && (
          <Alert
            message="Error Saving Symptoms"
            description="There was an issue saving the symptoms for this booking. Please try again."
            type="error"
            showIcon
            style={{ marginTop: 16 }}
          />
        )}
      </div>
    </Card>
  )
}

export default React.memo(SymptomsCapture)
