import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import PatientInfoSlider from '../PatientInfo'
import { hexToRGBA } from '../../../lib/utils'
import { Typography } from 'antd'

const { Title } = Typography

// Define TabContentWrapper outside of PatientExaminationForm
export const TabContentWrapper = React.memo(
  ({
    children,
    controlWidth,
    setControlWidth,
    bookingData,
    setBookingData,
    previousDataLoading,
    previousLatestBooking,
    token,
    tabTitle,
    tabIcon
  }) => {
    return (
      <>
        {/* <div style={{ width: '100%', background: '#f5f5f5', height: 25 }}></div> */}
        <div
          style={{
            transition: '300ms ease',
            display: 'grid',
            gridTemplateColumns: `${controlWidth}px 50px 1fr`,
            maxWidth: '100%'
          }}
        >
          <div style={{ overflow: 'hidden', width: '100%' }}>
            <PatientInfoSlider
              data={bookingData}
              setData={setBookingData}
              previousDataLoading={previousDataLoading}
              previousData={previousLatestBooking}
            />
          </div>
          <div
            style={{ background: '#f5f5f5', position: 'relative', cursor: 'pointer' }}
            onClick={() => {
              setControlWidth(controlWidth ? 0 : 350)
            }}
          >
            <div
              style={{
                width: '100%',
                height: 50,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: token.colorPrimary,
                color: 'white'
              }}
            >
              <FontAwesomeIcon icon={controlWidth ? faChevronLeft : faChevronRight} />
            </div>
            <p
              style={{
                width: 300,
                position: 'absolute',
                top: controlWidth ? 100 : 90,
                transform: 'rotate(-90deg)',
                left: -125
              }}
            >
              {controlWidth ? 'Collapse' : 'Expand'} Patient Info
            </p>
          </div>
          <div>
            <div
              style={{
                height: 50,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                boxSizing: 'border-box',
                background: hexToRGBA(token.colorSecondary, 0.1),
                borderBottom: `1px solid ${hexToRGBA(token.colorSecondary, 0.2)}`,
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                position: 'relative',
                zIndex: 1
              }}
            >
              {tabIcon && <span style={{ marginRight: 8, color: token.colorSecondary, fontSize: 18 }}>{tabIcon}</span>}
              <Title level={4} style={{ margin: 0, fontSize: 18, fontWeight: 500, color: '#000000' }}>
                {tabTitle || 'No Title'}
              </Title>
            </div>
            <div style={{ position: 'relative', zIndex: 0 }}>{children}</div>
          </div>
        </div>
      </>
    )
  }
)
