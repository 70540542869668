import React, { useState, useCallback } from 'react'
import CustomRow from '../../../../reusable-components/CustomRow'
import PropTypes from 'prop-types'
import { Col, Modal } from 'antd'

import ExaminationProceduresPerformed from '../../examination-procedures-performed'
import LabResults from '../Lab Results/lab-results'
import LabOrdersWrapper from '../Lab Orders/LabOrdersWrapper'
import VitalSigns from './VitalSigns'
import GeneralStats from './GeneralStats'
import { useSelector } from 'react-redux'
import ClinicalTesting from './ClinicalTesting'
import CareSyncLink from '../CareSync/CareSyncLink'
import Title from 'antd/es/skeleton/Title'

// Constants
const MODAL_WIDTH = 1920

/**
 * PatientDataDisplay Component
 * Displays and manages patient examination data, lab results, and vital signs
 */
const PatientDataDisplay = ({
  patientDataEntries,
  setPatientDataEntries,
  nonTemplateDataEntries,
  setNonTemplateDataEntries,
  websocketConnection,
  userRef,
  bookingData,
  readOnly,
  visibleSections = {
    vitalSigns: true,
    generalStats: true,
    bloodTests: true,
    procedures: true
  },
  setLinktopModalOpen
}) => {
  // State Management
  const { loading: dataPointsLoading } = useSelector((state) => state.dataPoints)
  const { loading: templatesLoading } = useSelector((state) => state.examTemplates)
  const isLoading = dataPointsLoading || templatesLoading

  const dataPoints = useSelector((state) => state.dataPoints.data)
  const dataTemplates = useSelector((state) => state.examTemplates.data)
  const [labOrders, setLabOrders] = useState([])

  // Modal States
  const [labReportModalOpen, setLabReportModalOpen] = useState(false)
  const [labOrderModalOpen, setLabOrderModalOpen] = useState(false)

  const [careSyncModalOpen, setCareSyncModalOpen] = useState(false)

  // Memoized helper functions
  const filterPatientEntries = useCallback(
    (type) => {
      return patientDataEntries.filter((entry) => {
        const itemReference = dataPoints.find((point) => entry.key === point.key)
        return itemReference?.category === type
      })
    },
    [patientDataEntries, dataPoints]
  )

  const handleSuccessfulLabOrder = useCallback((data) => {
    setLabOrders((prev) => [...prev, data])
  }, [])

  // Memoized components and values

  const handleCareSyncEnd = (newEntries) => {
    setPatientDataEntries([...patientDataEntries, ...newEntries])
    setCareSyncModalOpen(false)
  }

  return (
    <CustomRow style={{ marginTop: 12 }}>
      <Col span={24}>
        <CustomRow>
          {visibleSections.vitalSigns && (
            <Col span={24}>
              <VitalSigns
                dataPoints={dataPoints.filter((point) => point.category === 'vitals')}
                otherDataEntries={nonTemplateDataEntries}
                patientDataEntries={filterPatientEntries('vitals')}
                setPatientDataEntries={setPatientDataEntries}
                bookingData={bookingData}
                setLinktopModalOpen={setLinktopModalOpen}
              />
            </Col>
          )}

          {visibleSections.generalStats && (
            <Col span={24}>
              <GeneralStats
                dataPoints={dataPoints.filter((point) => point.category === 'general')}
                otherDataEntries={nonTemplateDataEntries}
                patientDataEntries={filterPatientEntries('general')}
                setPatientDataEntries={setPatientDataEntries}
              />
            </Col>
          )}

          {visibleSections.bloodTests && (
            <Col span={24}>
              <ClinicalTesting
                setLabReportModalOpen={setLabReportModalOpen}
                setCareSyncModalOpen={setCareSyncModalOpen}
                dataPoints={dataPoints}
                patientDataEntries={patientDataEntries}
              />
            </Col>
          )}

          {!readOnly && visibleSections.procedures && (
            <Col span={24}>
              <ExaminationProceduresPerformed
                dataPoints={dataPoints.filter((point) => point.category === 'procedures')}
                otherDataEntries={nonTemplateDataEntries}
                patientDataEntries={filterPatientEntries('procedures')}
                setPatientDataEntries={setPatientDataEntries}
                bookingData={bookingData}
              />
            </Col>
          )}
        </CustomRow>
      </Col>

      <Modal width={MODAL_WIDTH} closable={false} maskClosable={false} footer={false} open={labReportModalOpen}>
        <LabResults
          setModalOpen={setLabReportModalOpen}
          userRef={bookingData ? bookingData.userRef : userRef}
          patientDataEntries={patientDataEntries}
          dataPoints={dataPoints}
          otherDataEntries={nonTemplateDataEntries}
          socket={null}
          modalOpen={labReportModalOpen}
          dataTemplates={dataTemplates}
          websocketConnection={websocketConnection}
          bookingData={bookingData}
        />
      </Modal>

      <Modal width={850} closable={false} maskClosable={false} footer={false} open={careSyncModalOpen}>
        <CareSyncLink patient={bookingData.patientRecord} handleCareSyncEnd={handleCareSyncEnd} />
      </Modal>

      <Modal width={MODAL_WIDTH} closable={false} maskClosable={false} footer={false} open={labOrderModalOpen}>
        <LabOrdersWrapper onAfterSuccess={handleSuccessfulLabOrder} setModalOpen={setLabOrderModalOpen} />
      </Modal>
    </CustomRow>
  )
}

PatientDataDisplay.propTypes = {
  patientDataEntries: PropTypes.array.isRequired,
  setPatientDataEntries: PropTypes.func.isRequired,
  nonTemplateDataEntries: PropTypes.array,
  setNonTemplateDataEntries: PropTypes.func,
  websocketConnection: PropTypes.object,
  userRef: PropTypes.string,
  bookingData: PropTypes.object,
  readOnly: PropTypes.bool,
  visibleSections: PropTypes.shape({
    vitalSigns: PropTypes.bool,
    generalStats: PropTypes.bool,
    bloodTests: PropTypes.bool,
    procedures: PropTypes.bool
  }),
  setLinktopModalOpen: PropTypes.func
}

export default React.memo(PatientDataDisplay)
