import { useState, useCallback, useMemo } from 'react'
import { CURRENT_USER } from '../config/constants'
import { STATUS } from '../config/taskStatus'
import { useSelector } from 'react-redux'

const useTaskFilters = (tasks) => {
  const authState = useSelector((state) => state.auth)
  const [filters, setFilters] = useState({
    assignee: 'all',
    taskType: 'all',
    status: 'all'
  })

  const handleFilterChange = useCallback((filterType, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterType]: value
    }))
  }, [])

  const filteredTasks = useMemo(() => {
    return tasks.filter((task) => {
      const assignedToMatch =
        filters.assignee === 'all' ||
        (filters.assignee === 'me' ? task.assignee === authState.agiliteUser._id : task.assignee === filters.assignee)
      const typeMatch = filters.taskType === 'all' || task.taskType === filters.taskType
      const statusMatch = filters.status === 'all' || filters.status === task.status

      return assignedToMatch && typeMatch && statusMatch
    })
  }, [tasks, filters, authState.agiliteUser._id])

  return {
    filters,
    handleFilterChange,
    filteredTasks
  }
}

export default useTaskFilters
