import React, { useState } from 'react'
import { Card, Col, Form, Input, Row, Select, Space, Spin, message, Divider, Tag, Tooltip, Typography } from 'antd'
import { hexToRGBA, stringToColor } from '../../../../lib/utils'
import CustomRow from '../../../../reusable-components/CustomRow'
import CustomButton from '../../../../reusable-components/CustomButton'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import TextArea from 'antd/es/input/TextArea'
import ReactQuill from 'react-quill'
import useReferral from './hooks/useReferral'
import { SAOfficialLanguages, specialistTypes } from './utils/constants'
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  UserOutlined,
  FileTextOutlined,
  GlobalOutlined,
  RobotOutlined,
  ThunderboltOutlined
} from '@ant-design/icons'
import '../../../../Examination/styling/referral.css'

const { Title, Text, Paragraph } = Typography

const ReferralLetterCapture = ({ bookingData, userRef, setModalOpen, patient, handleAfterSuccess }) => {
  const {
    autogenConfigRequired,
    autogenConfig,
    content,
    includedDataKeys,
    templateSelectionRequired,
    dataPoints,
    dataTemplates,
    awaitingAi,
    loadingDataAndTemplates,
    referralLoading,
    recentTemplates,
    validationErrors,
    setAutogenConfigRequired,
    handleAutogenConfig,
    handleTemplateSelection,
    handleAiAutoGenerateReferral,
    handleSubmitReferral,
    setIncludedDataKeys,
    setContent,
    applyRecentTemplate
  } = useReferral({ bookingData, userRef, setModalOpen, patient, handleAfterSuccess })

  const [selection, setSelection] = useState(null)
  const [form] = Form.useForm()
  const [previewMode, setPreviewMode] = useState(false)

  const handlePreview = () => {
    if (!content) {
      message.error("There's no content to preview")
      return
    }
    setPreviewMode(!previewMode)
  }

  const renderSelectionScreen = () => (
    <Col span={24} className='referral-selection-screen'>
      <h2 className='ai-config-title' style={{ textAlign: 'center', marginBottom: '36px' }}>
        How would you like to create the referral letter?
      </h2>
      <Row gutter={[24, 32]} justify='center'>
        <Col xs={24} sm={12} md={10} lg={8}>
          <Card
            hoverable
            className={`referral-option-card ${selection === 'ai' ? 'selected' : ''}`}
            onClick={() => {
              setSelection('ai')
              setAutogenConfigRequired(true)
            }}
          >
            <ThunderboltOutlined className='referral-option-icon' style={{ color: '#722ed1' }} />
            <Title level={3} style={{ fontSize: '20px', marginBottom: '12px' }}>
              AI-Assisted Generation
            </Title>
            <Paragraph style={{ color: '#666', marginBottom: 0, fontSize: '14px', lineHeight: '1.5' }}>
              Generate a smart referral letter using AI, based on patient data and your requirements
            </Paragraph>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={10} lg={8}>
          <Card
            hoverable
            className={`referral-option-card ${selection === 'manual' ? 'selected' : ''}`}
            onClick={() => setSelection('manual')}
          >
            <UserOutlined className='referral-option-icon' style={{ color: '#52c41a' }} />
            <Title level={3} style={{ fontSize: '20px', marginBottom: '12px' }}>
              Manual Creation
            </Title>
            <Paragraph style={{ color: '#666', marginBottom: 0, fontSize: '14px', lineHeight: '1.5' }}>
              Create your referral letter from scratch with complete control over the content
            </Paragraph>
          </Card>
        </Col>
      </Row>
    </Col>
  )

  const renderConfigScreen = () => (
    <Col span={24} className='referral-config-screen'>
      <h2 className='ai-config-title'>Configure AI Referral Generation</h2>
      <p className='ai-config-subtitle'>
        Configure the following details to generate a personalized referral letter tailored to your patient's needs.
      </p>

      {recentTemplates.length > 0 && (
        <div className='recent-templates-section'>
          <Divider className='ai-config-divider'>
            <span className='ai-config-divider-text'>Recent Templates</span>
          </Divider>
          <Row gutter={[16, 16]}>
            {recentTemplates.map((template, index) => (
              <Col xs={24} sm={12} md={8} key={index}>
                <Card
                  size='small'
                  hoverable
                  className='recent-template-card'
                  onClick={() => applyRecentTemplate(template)}
                  title={
                    <div className='recent-template-header'>
                      <Tooltip title={`Created: ${new Date(template.timestamp).toLocaleString()}`}>
                        <span className='recent-template-title'>{template.specialistName || 'Unnamed Template'}</span>
                      </Tooltip>
                    </div>
                  }
                  bodyStyle={{ padding: 0 }}
                >
                  <div className='recent-template-content'>
                    <div className='recent-template-detail'>
                      <span className='recent-template-label'>Specialist:</span>
                      <span>{template.specialistType || 'Not specified'}</span>
                    </div>
                    <div className='recent-template-detail'>
                      <span className='recent-template-label'>Language:</span>
                      <span>{template.language || 'Not specified'}</span>
                    </div>
                    <div className='recent-template-detail'>
                      <span className='recent-template-label'>Data Points:</span>
                      <span>{template.dataKeys.length}</span>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )}

      <Form form={form} layout='vertical'>
        <Divider className='ai-config-divider'>
          <span className='ai-config-divider-text'>Specialist Information</span>
        </Divider>

        <Row gutter={[24, 0]}>
          <Col xs={24} md={12}>
            <Form.Item
              label='Specialist Name'
              required
              validateStatus={validationErrors.specialistName ? 'error' : ''}
              help={validationErrors.specialistName ? "Please enter the specialist's name" : ''}
              className='ai-config-form-item'
            >
              <Input
                value={autogenConfig.specialistName}
                placeholder='Dr. Jane Smith'
                onChange={(e) => handleAutogenConfig(e.target.value, 'specialistName')}
                prefix={<UserOutlined />}
                className='ai-config-input'
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label='Specialist Type'
              required
              validateStatus={validationErrors.specialistType ? 'error' : ''}
              help={validationErrors.specialistType ? 'Please select a specialist type' : ''}
              className='ai-config-form-item'
            >
              <Select
                value={autogenConfig.specialistType}
                placeholder='Select specialist type'
                showSearch
                optionFilterProp='label'
                options={specialistTypes.map((spec) => ({ value: spec, label: spec }))}
                onChange={(selection) => handleAutogenConfig(selection, 'specialistType')}
                className='ai-config-select'
                dropdownStyle={{ borderRadius: '8px' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label='Language'
              required
              validateStatus={validationErrors.language ? 'error' : ''}
              help={validationErrors.language ? 'Please select a language' : ''}
              className='ai-config-form-item'
            >
              <Select
                value={autogenConfig.language}
                placeholder='Select language'
                options={SAOfficialLanguages.map((language) => ({ value: language, label: language }))}
                onChange={(selection) => handleAutogenConfig(selection, 'language')}
                className='ai-config-select'
                dropdownStyle={{ borderRadius: '8px' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label='Additional Instructions' className='ai-config-form-item'>
          <TextArea
            placeholder='Additional instructions or specific requirements for the AI to consider...'
            value={autogenConfig.additionalPrompts}
            onChange={(e) => handleAutogenConfig(e.target.value, 'additionalPrompts')}
            rows={5}
            className='ai-config-textarea'
          />
        </Form.Item>

        <Divider className='ai-config-divider'>
          <span className='ai-config-divider-text'>Patient Data</span>
        </Divider>

        <Form.Item
          label='Data Points to Include'
          required
          validateStatus={validationErrors.dataPoints ? 'error' : ''}
          help={validationErrors.dataPoints ? 'Please select at least one data point' : ''}
          className='ai-config-form-item'
        >
          {loadingDataAndTemplates ? (
            <AgiliteSkeleton skActive />
          ) : (
            <Space direction='vertical' style={{ width: '100%' }}>
              {templateSelectionRequired ? (
                <div className='data-templates-grid'>
                  {dataTemplates.map((template) => (
                    <Card
                      key={template.name}
                      hoverable
                      className='data-template-card'
                      onClick={() => handleTemplateSelection(template.keys)}
                    >
                      <div className='data-template-header'>
                        <h4 className='data-template-title'>{template.name}</h4>
                      </div>
                      <div className='data-template-content'>
                        {template.keys.slice(0, 5).map((key) => {
                          const dataReference = dataPoints.find((i) => i.key === key)
                          return (
                            <Tag key={key} color={stringToColor(key)} className='data-point-tag'>
                              {dataReference.label}
                            </Tag>
                          )
                        })}
                        {template.keys.length > 5 && (
                          <Tag className='data-point-tag'>+{template.keys.length - 5} more</Tag>
                        )}
                      </div>
                    </Card>
                  ))}
                </div>
              ) : (
                <>
                  <Select
                    value={includedDataKeys}
                    placeholder='Select data points'
                    mode='multiple'
                    style={{ width: '100%' }}
                    showSearch
                    optionFilterProp='label'
                    onChange={setIncludedDataKeys}
                    options={dataPoints.map((dataPoint) => ({
                      value: dataPoint.key,
                      label: dataPoint.label
                    }))}
                    className='ai-config-select'
                    dropdownStyle={{ borderRadius: '8px' }}
                  />
                  <div className='data-points-counter'>
                    <InfoCircleOutlined className='data-points-counter-icon' />
                    Selected {includedDataKeys.length} data points
                  </div>
                </>
              )}
            </Space>
          )}
        </Form.Item>
      </Form>

      <div className='ai-config-button-container'>
        <CustomButton
          type='default'
          size='middle'
          text='← Back'
          onClick={() => {
            setSelection(null)
            setAutogenConfigRequired(false)
          }}
          className='ai-config-back-button'
        />
        <CustomButton
          type='primary'
          size='middle'
          disabled={awaitingAi}
          loading={awaitingAi}
          text='Generate Referral →'
          onClick={() => {
            if (!autogenConfig?.specialistName) {
              message.error("Please provide the specialist's name")
              return
            }
            if (!autogenConfig?.language) {
              message.error('Please select a language')
              return
            }
            if (!autogenConfig?.specialistType) {
              message.error('Please select the specialist type')
              return
            }
            if (includedDataKeys.length === 0) {
              message.error('Please select at least one data point')
              return
            }
            handleAiAutoGenerateReferral()
          }}
          className='ai-config-generate-button'
          style={{
            background: awaitingAi ? '#d9d9d9' : 'linear-gradient(to right, #722ed1, #1890ff)',
            boxShadow: awaitingAi ? 'none' : '0 2px 6px rgba(114, 46, 209, 0.4)'
          }}
        />
      </div>
    </Col>
  )

  const renderEditorScreen = () => (
    <Col span={24} className='referral-editor-screen'>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <h2 className='ai-config-title' style={{ marginBottom: 0 }}>
          {previewMode ? 'Preview Referral Letter' : 'Edit Referral Letter'}
        </h2>
        <Space>
          <CustomButton
            type='default'
            size='middle'
            text={previewMode ? 'Edit' : 'Preview'}
            onClick={handlePreview}
            icon={previewMode ? <FileTextOutlined /> : <CheckCircleOutlined />}
            className='ai-config-back-button'
          />
        </Space>
      </div>

      <p className='ai-config-subtitle' style={{ marginBottom: '24px' }}>
        {previewMode
          ? 'Review your referral letter before generating the final document.'
          : 'Customize the referral letter using the rich text editor below.'}
      </p>

      <Form>
        <Form.Item
          style={{ marginBottom: 32 }}
          name={['referral', 'comment']}
          rules={[{ required: true, message: 'Please provide referral content' }]}
        >
          <Spin spinning={awaitingAi} tip='Generating AI Referral...'>
            {previewMode ? (
              <div className='referral-preview'>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            ) : (
              <ReactQuill value={content} onChange={setContent} className='referral-editor' />
            )}
          </Spin>
        </Form.Item>
      </Form>

      <div className='ai-config-button-container'>
        <Space>
          <CustomButton
            type='default'
            size='middle'
            text='← Back'
            onClick={() => {
              if (selection === 'ai') {
                setAutogenConfigRequired(true)
              } else {
                setSelection(null)
              }
              setPreviewMode(false)
            }}
            className='ai-config-back-button'
          />
        </Space>
        <Space>
          <Tooltip title='Cancel and close the form'>
            <CustomButton
              type='default'
              danger
              size='middle'
              text='Cancel'
              onClick={() => setModalOpen(false)}
              className='ai-config-back-button'
            />
          </Tooltip>
          <CustomButton
            type='primary'
            size='middle'
            loading={referralLoading}
            disabled={!content || referralLoading}
            text='Generate Referral Letter'
            onClick={handleSubmitReferral}
            className='ai-config-generate-button'
            style={{
              background: !content || referralLoading ? '#d9d9d9' : 'linear-gradient(to right, #722ed1, #1890ff)',
              boxShadow: !content || referralLoading ? 'none' : '0 2px 6px rgba(114, 46, 209, 0.4)'
            }}
          />
        </Space>
      </div>
    </Col>
  )

  return (
    <Card className='referral-card' bordered={false}>
      {awaitingAi ? (
        <div style={{ padding: '40px 0' }}>
          <AgiliteSkeleton skActive spinnerTip='Generating AI Referral...' />
        </div>
      ) : (
        <Row gutter={[0, 0]} style={{ width: '100%' }}>
          {!selection ? (
            renderSelectionScreen()
          ) : (
            <>{autogenConfigRequired ? renderConfigScreen() : renderEditorScreen()}</>
          )}
        </Row>
      )}
    </Card>
  )
}

export default ReferralLetterCapture
