import React from 'react'
import { Button, Card, Space } from 'antd'
import { Cell, Pie, PieChart } from 'recharts'
import { hexToRGBA } from '../../lib/utils'
import '../style/MobileMacroRings.css'

const MobileMacroRings = ({ 
  macroData, 
  limits, 
  servings,
  setServings,
  onBack, 
  onConfirm, 
  saving,
  noBottomPadding
}) => {
  const renderNutrientRing = (key, color, label) => {
    const value = macroData.macros[key] * servings
    const percentage = (value / limits?.macros[key]) * 100
    const ringData = [
      { value: percentage > 100 ? 100 : percentage },
      { value: percentage > 100 ? 0 : 100 - percentage }
    ]

    return (
      <div className='nutrient-ring'>
        <PieChart width={100} height={100}>
          <Pie data={ringData} innerRadius={35} outerRadius={45} paddingAngle={5} dataKey='value' stroke='none'>
            {ringData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={index === 0 ? color : hexToRGBA(color, 0.2)} />
            ))}
          </Pie>
        </PieChart>
        <div className='ring-content'>
          <span className='value'>{value.toFixed(1)}g</span>
          <span className='label'>{label}</span>
        </div>
      </div>
    )
  }

  const handleServingChange = (action) => {
    if (action === 'decrease' && servings > 1) {
      setServings(servings - 1)
    } else if (action === 'increase') {
      setServings(servings + 1)
    }
  }

  return (
    <div className={`mobile-macro-rings ${noBottomPadding ? 'no-bottom-padding' : ''}`}>
      <Card className='mobile-rings-card'>
        <div className='servings-control'>
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <span>Servings:</span>
            <Space>
              <Button onClick={() => handleServingChange('decrease')}>-</Button>
              <span className='servings-value'>{servings}</span>
              <Button onClick={() => handleServingChange('increase')}>+</Button>
            </Space>
          </Space>
        </div>

        <div className='rings-grid'>
          {renderNutrientRing('prot', '#ff8000', 'Protein')}
          {renderNutrientRing('fat', '#2ecc71', 'Fats')}
          {renderNutrientRing('carb', '#3498db', 'Carbs')}
          {renderNutrientRing('sug', '#ff1493', 'Sugar')}
        </div>

        <div className='info-grid compact'>
          <div className='info-column'>
            <strong>Total Calories</strong>
            <p>{(macroData.macros.cal * servings).toFixed(0)} kcal</p>
          </div>
          <div className='info-column'>
            <strong>Serving Size</strong>
            <p>{(macroData.macros.grams * servings).toFixed(0)} g</p>
          </div>
        </div>
      </Card>

      <div className='mobile-rings-button-container'>
        <Button 
          onClick={onBack}
          className='action-button'
        >
          Back
        </Button>
        <Button 
          onClick={onConfirm}
          className='action-button'
          loading={saving}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}

export default MobileMacroRings
