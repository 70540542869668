const reportGenerationEnums = {
  routes: {
    CREATE: 'generate_report'
  },
  templates: {
    DIABETES: '81577b230892d752',
    OLD_MED_HIST: '45d77b230817560a',
    VITALITY_HEALTH_CHECK: 'f6977b230bd0869c'
  }
}

export default reportGenerationEnums
