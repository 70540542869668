import { Button, Col, Space, Tag } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React from 'react'
import CustomRow from '../../../../reusable-components/CustomRow'

const HomeVisitCheckin = ({
  loading,
  handleChange,
  handleSubmit,
  bookingService,
  currentSelection,
  setCurrentSelection,
  patientSelection,
  patientInput,
  setPatientInput
}) => {
  const { CheckableTag } = Tag
  return (
    <CustomRow>
      <Col span={24}>
        <p>
          <b>Please select a reason for your visit:*</b>
        </p>
      </Col>
      <Col span={24}>
        <Space wrap>
          {bookingService.map((item) => (
            <Button
              type={
                currentSelection
                  ? currentSelection.doctorsText === item.doctorsText
                    ? 'primary'
                    : 'default'
                  : 'default'
              }
              onClick={() => {
                setCurrentSelection(item)
              }}
            >
              {item.questionText}
            </Button>
          ))}
        </Space>
      </Col>
      {currentSelection && currentSelection.enableOptions ? (
        <>
          <Col span={24}>
            <b>{currentSelection.optionsHeading}</b>
            <br></br>
            <small>Tap / Click on a option to select it</small>
          </Col>
          <Col span={24}>
            <Space size={[0, 8]} wrap>
              {currentSelection.options.map((tag) => (
                <CheckableTag
                  key={tag.text}
                  style={{ borderColor: 'grey', padding: '4px 15px' }}
                  checked={patientSelection.includes(tag.text)}
                  onChange={(checked) => handleChange(tag.text, checked)}
                >
                  {tag.text}
                </CheckableTag>
              ))}
              {currentSelection.showOther ? (
                <CheckableTag
                  key='Other'
                  style={{ borderColor: 'grey', padding: '4px 15px' }}
                  checked={patientSelection.includes('Other')}
                  onChange={(checked) => handleChange('Other', checked)}
                >
                  Other
                </CheckableTag>
              ) : null}
            </Space>
          </Col>
          {patientSelection.includes('Other') ? (
            <>
              <Col span={24}>{currentSelection.otherOptionText}</Col>
              <Col span={24}>
                <TextArea
                  onChange={(e) => {
                    setPatientInput(e.target.value)
                  }}
                />
              </Col>
            </>
          ) : null}
        </>
      ) : null}
      {handleSubmit ? (
        <Col span={24}>
          <Button
            loading={loading}
            onClick={() => {
              handleSubmit()
            }}
            disabled={
              currentSelection
                ? currentSelection.enableOptions
                  ? !patientSelection.length > 0 || (patientSelection.includes('Other') && !patientInput)
                  : false
                : true
            }
            style={{ float: 'right' }}
          >
            Finalize
          </Button>
        </Col>
      ) : null}
    </CustomRow>
  )
}

export default HomeVisitCheckin
