import { Calendar, Col } from 'antd'
import React from 'react'
import { deviceDetect } from 'react-device-detect'
import CustomRow from '../../reusable-components/CustomRow'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import './utils/style.css'
import { useSelector } from 'react-redux'

const AvailabilityCalendar = ({ dateCellRender, onPanelClick, loading, panelValue, setPanelValue }) => {
  const availability = useSelector((state) => state.availability.data)

  return (
    <CustomRow justify='center'>
      <Col span={24}>
        {loading ? (
          <Col span={24}>
            <AgiliteSkeleton skActive spinnerTip='Loading availability...' />
          </Col>
        ) : (
          <Calendar
            className='availability-calendar'
            value={panelValue}
            onPanelChange={(newValue) => setPanelValue(newValue)}
            fullscreen={deviceDetect().isMobile ? false : true}
            fullCellRender={deviceDetect().isMobile ? null : dateCellRender}
            style={{ borderRadius: 20 }}
            onSelect={(date, info) => {
              if (info.source === 'date') {
                onPanelClick(date)
              }
            }}
          />
        )}
      </Col>
    </CustomRow>
  )
}

export default AvailabilityCalendar
