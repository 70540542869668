import dayjs from 'dayjs'
import React from 'react'

const MonthHeader = ({ date }) => {
  const formattedDate = dayjs(date).format('ddd') // Get the formatted date (e.g., April 26)
  return (
    <div>
      <span>{formattedDate}</span>
    </div>
  )
}

export default MonthHeader
