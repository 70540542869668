const ClinicEnums = {
  keys: {},
  titles: {},
  profileKeys: {
    ADMINISTRATION_CLINICS: 'clinics'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update'
  }
}

export default ClinicEnums
