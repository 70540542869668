import { Button, Popconfirm, Space, Tooltip, theme } from 'antd'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBottleDroplet,
  faBox,
  faCapsules,
  faEye,
  faFileDownload,
  faPills,
  faSyringe,
  faTablets,
  faTrash,
  faVial,
  faUser
} from '@fortawesome/free-solid-svg-icons'

export const medAdminTypes = [
  {
    key: 'TAB',
    icon: <FontAwesomeIcon icon={faTablets} />
  },
  {
    key: 'SYR',
    icon: <FontAwesomeIcon icon={faSyringe} />
  },
  {
    key: 'BOT',
    icon: <FontAwesomeIcon icon={faBottleDroplet} />
  },
  {
    key: 'CAP',
    icon: <FontAwesomeIcon icon={faCapsules} />
  },
  {
    key: 'PIL',
    icon: <FontAwesomeIcon icon={faPills} />
  },
  {
    key: 'BOX',
    icon: <FontAwesomeIcon icon={faBox} />
  },
  {
    key: 'VIA',
    icon: <FontAwesomeIcon icon={faVial} />
  }
]
export const ScriptingFreq = [
  {
    label: 'qd',
    value: 'qd'
  },
  {
    label: 'bid',
    value: 'bid'
  },
  {
    label: 'tid',
    value: 'tid'
  },
  {
    label: 'qid',
    value: 'qid'
  },
  {
    label: 'qod',
    value: 'qod'
  },
  {
    label: 'hs',
    value: 'hs'
  },
  {
    label: 'ac',
    value: 'ac'
  },
  {
    label: 'pc',
    value: 'pc'
  },
  {
    label: 'prn',
    value: 'prn'
  },
  {
    label: 'stat',
    value: 'stat'
  },
  {
    label: 'ut dict',
    value: 'utDict'
  }
]

export const ScriptHistoryTableTemplate = (
  isSinglePatient,
  generateScriptPDF,
  printingScript,
  handleRemoveScript,
  scheduleView,
  viewScript,
  setCurrentScript,
  recitfyOldRecord
) => {
  return [
    {
      title: () => {
        if (scheduleView) {
          return 'Next Due Date'
        } else {
          return 'Date Scripted'
        }
      },
      render: (entry) => {
        const formatter = 'DD MMMM YYYY'
        const todaysDate = dayjs().format(formatter)
        const nextDueDate = entry.nextDue
        // Scheduled scripts
        if (entry.onSchedule) {
          if (entry.daysOverDue) {
            return `${entry.daysOverDue} Day(s) Ago`
          } else {
            if (dayjs(nextDueDate).format(formatter) === todaysDate) {
              return 'Today'
            }
            return dayjs(nextDueDate).format(formatter)
          }
        } else {
          // Regular scripts
          return dayjs(entry.createdAt).format(formatter)
        }
      }
    },
    {
      title: 'Patient',
      dataIndex: 'patient',
      key: 'patient',
      render: (patient) => {
        return patient.firstName + ' ' + patient.lastName
      },
      hidden: isSinglePatient
    },
    {
      title: 'Date of Birth',
      dataIndex: 'patient',
      key: 'dob',
      render: (patient) => {
        return patient.dateOfBirth ? dayjs(patient.dateOfBirth).format('DD MMMM YYYY') : 'N/A'
      },
      hidden: isSinglePatient
    },
    {
      title: 'Age',
      dataIndex: 'patient',
      key: 'age',
      render: (patient) => {
        if (!patient.dateOfBirth) return 'N/A'
        const age = dayjs().diff(dayjs(patient.dateOfBirth), 'year')
        return `${age} years`
      },
      hidden: isSinglePatient
    },
    {
      title: 'Medical Professional',
      dataIndex: 'medProf',
      hidden: !isSinglePatient,
      key: 'medProf',
      render: (medProf) => {
        return medProf.firstName + ' ' + medProf.lastName
      }
    },
    {
      title: 'Actions',

      render: (record) => {
        return (
          <Space>
            <Tooltip title='VIEW'>
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  if (record.document) setCurrentScript(record.document)
                  else recitfyOldRecord(record)
                }}
              >
                <FontAwesomeIcon color='green' icon={faEye} />
              </Button>
            </Tooltip>
            {scheduleView ? undefined : (
              <Tooltip title='DOWNLOAD PDF'>
                <Button
                  onClick={async (e) => {
                    e.stopPropagation()
                    let documentToDownload = record.document
                    if (!documentToDownload) {
                      documentToDownload = await recitfyOldRecord(record)
                    }
                    // Create blob and download
                    const blob = new Blob([documentToDownload], { type: 'application/pdf' })
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.download = `script_${record._id}.pdf`
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                  }}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                </Button>
              </Tooltip>
            )}
            <Tooltip title='VIEW PROFILE'>
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  // View profile functionality will be added later
                }}
                style={{ color: '#1890ff' }}
              >
                <FontAwesomeIcon icon={faUser} />
              </Button>
            </Tooltip>
            <Tooltip title='DELETE'>
              <Popconfirm
                title='Confirm Delete'
                description='Are you sure you want to permanently delete this script?'
                okButtonProps={{ danger: true }}
                cancelButtonProps={{ type: 'primary' }}
                okText='Delete'
                cancelText='Keep'
                onCancel={(e) => {
                  e.stopPropagation()
                }}
                onConfirm={(e) => {
                  e.stopPropagation()
                  handleRemoveScript(record._id)
                }}
              >
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <FontAwesomeIcon color='red' icon={faTrash} />
                </Button>
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      }
    }
  ]
}
