import { Col, Space } from 'antd'
import React from 'react'
import CustomRow from '../../../reusable-components/CustomRow'
import { heartRateMethod } from '../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartPulse } from '@fortawesome/free-solid-svg-icons'

const PulseLegend = () => {
  const keyStyle = {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    padding: 2,
    color: 'white',
    paddingLeft: 10,
    marginBottom: 4
  }
  return (
    <CustomRow gutter={[8, 8]}>
      <Col span={24}>
        <b>Key:</b>
        {Object.keys(heartRateMethod([])).map((key) => {
          const object = heartRateMethod([])[key]

          return (
            <div style={{ ...keyStyle, background: object.color }}>
              <Space wrap>
                <FontAwesomeIcon icon={faHeartPulse} /> <p>{object.description}</p>
              </Space>
            </div>
          )
        })}
      </Col>
    </CustomRow>
  )
}

export default PulseLegend
