import React from 'react'
import { Col } from 'antd'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import { useSelector } from 'react-redux'
import PatientBookingsView from './Booking View Components/PatientBookingsListView'
import CustomRow from '../../reusable-components/CustomRow'
import EmployeeBookingsView from './Booking View Components/EmployeeBookingsListView'

const AppointmentListViewWrapper = ({ isBilling }) => {
  const state = useSelector((state) => state)

  return (
    <ContainerCard title='Appointments'>
      <CustomRow>
        <Col span={24}>
          {state.auth?.agiliteUser && state.auth?.agiliteUser?.extraData?.role?.type === 'patient' ? (
            <PatientBookingsView />
          ) : (
            <EmployeeBookingsView isBilling={isBilling} />
          )}
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default AppointmentListViewWrapper
