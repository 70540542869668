const templates = {
  dataModel: {
    name: {
      label: 'Entity Name',
      key: 'name',
      required: true
    },
    description: {
      label: 'Description',
      key: 'description',
      required: false
    },
    primaryColor: {
      label: 'Primary Color (RGB, HEX, Color Name)',
      key: ['theme', 'primaryColor'],
      placeholder: 'Default: #10B2E1',
      required: false
    },
    secondaryColor: {
      label: 'Secondary Color (RGB, HEX, Color Name)',
      key: ['theme', 'secondaryColor'],
      placeholder: 'Default: #F14F8B',
      required: false
    },
    virtualConsultations: {
      enabled: {
        label: 'Virtual Consultations Enabled?',
        key: ['virtualConsultations', 'enabled']
      },
      entities: {
        label: 'Preferred Entities',
        key: ['virtualConsultations', 'entities']
      }
    },
    medicalHistory: {
      renalCareSpecific: {
        label: 'Renal Care Specific?',
        key: ['medicalHistory', 'renalCareSpecific']
      }
    },
    vitalLink: {
      enabled: {
        label: 'Meta-Link Enabled?',
        key: ['vitalLink', 'enabled']
      },
      localVitalLink: {
        label: 'Local Meta-Link?',
        key: ['vitalLink', 'localVitalLink']
      },
      firstAccomodationAddress: {
        label: 'Default First Accomodation Address (optional)',
        key: ['vitalLink', 'firstAccomodationAddress']
      },
      staffVitalLink: {
        label: 'Staff Meta-Link?',
        key: ['vitalLink', 'staffVitalLink']
      },
      netCashEnabled: {
        label: 'NetCash Enabled?',
        key: ['vitalLink', 'netCashEnabled']
      },
      localIndividualPrice: {
        label: 'Local Individual Price',
        key: ['vitalLink', 'local', 'individualSubPrice']
      },
      touristIndividualPrice: {
        label: 'Tourist Individual Price',
        key: ['vitalLink', 'tourist', 'individualSubPrice']
      },
      localCouplePrice: {
        label: 'Local Couple Price',
        key: ['vitalLink', 'local', 'coupleSubPrice']
      },
      touristCouplePrice: {
        label: 'Tourist Couple Price',
        key: ['vitalLink', 'tourist', 'coupleSubPrice']
      },
      localFamPrice: {
        label: 'Local Family Price',
        key: ['vitalLink', 'local', 'famSubPrice']
      },
      touristFamPrice: {
        label: 'Tourist Family Price',
        key: ['vitalLink', 'tourist', 'famSubPrice']
      }
    },
    configs: {
      paymentMethods: {
        label: 'Payment Methods',
        key: ['configs', 'paymentMethods']
      },
      nextAppointmentTests: {
        label: 'Next Appointment Tests?',
        key: ['configs', 'nextAppointmentTests']
      },
      procedures: {
        label: 'Procedures',
        key: ['configs', 'procedures']
      }
    },
    isUrlRoute: {
      label: 'Is URL Route? (e.g. /entity-name)',
      key: 'isUrlRoute'
    },
    customUrl: {
      label: 'Custom URL',
      key: 'customUrl',
      required: true
    }
  },

  dataTemplate: () => {
    return {
      name: '',
      description: ''
    }
  },

  columnTemplate: (editRecord, filters) => {
    return [
      {
        title: 'Entity Name',
        dataIndex: 'name',
        width: '20%',
        key: 'name',
        sorter: (a, b) => {
          return a.name.localeCompare(b.name)
        }
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '15%'
      },
      {
        title: 'Url',
        dataIndex: 'customUrl',
        key: 'customUrl',
        width: '15%'
      }
    ]
  }
}

export default templates
