import React, { useState } from 'react'
import { Card, Button, Divider, Row, Col, Typography, Modal, Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAllergies,
  faPills,
  faStethoscope,
  faVirus,
  faUserGroup,
  faPersonRunning,
  faBrain,
  faSyringe,
  faEdit
} from '@fortawesome/free-solid-svg-icons'
import AllergiesTab from './tabs/AllergiesTab'
import ChronicIllnessesTab from './tabs/ChronicIllnessesTab'
import GeneralMedsTab from './tabs/GeneralMedsTab'
import CovidInfoTab from './tabs/CovidInfoTab'
import FamilyHistoryTab from './tabs/FamilyHistoryTab'
import LifestyleTab from './tabs/LifestyleTab'
import MentalHealthTab from './tabs/MentalHealthTab'
import SurgeriesTab from './tabs/SurgeriesTab'
import LoadingOverlay from './LoadingOverlay'
import dayjs from 'dayjs'

const { Title, Text } = Typography

const MedicalHistoryForm = ({ medicalHistory, setMedicalHistory, patientId, saving, setSaving }) => {
  const [activeModal, setActiveModal] = useState(null)
  const [activeSection, setActiveSection] = useState('allergies')

  if (!medicalHistory) {
    return <div>No medical history found for this patient.</div>
  }

  const sections = [
    { key: 'allergies', title: 'Allergies', icon: faAllergies },
    { key: 'chronicIllnesses', title: 'Chronic', icon: faStethoscope },
    { key: 'meds', title: 'Meds', icon: faPills },
    { key: 'covidInfo', title: 'COVID', icon: faVirus },
    { key: 'familyHistory', title: 'Family', icon: faUserGroup },
    { key: 'lifestyle', title: 'Lifestyle', icon: faPersonRunning },
    { key: 'mentalHealth', title: 'Mental', icon: faBrain },
    { key: 'surgicalHistory', title: 'Surgeries', icon: faSyringe }
  ]

  const renderSectionCard = (title, icon, data, modalKey, lastUpdated) => {
    return (
      <Card
        className='section-card'
        title={
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FontAwesomeIcon icon={icon} style={{ marginRight: '10px', color: '#1890ff' }} />
              <span>{title}</span>
            </div>
            <div className='card-subtitle'>
              <Text type='secondary' style={{ fontSize: '12px' }}>
                Last updated: {lastUpdated ? dayjs(lastUpdated).format('DD MMM YYYY') : 'Never'}
              </Text>
            </div>
          </div>
        }
        extra={
          <Button type='primary' icon={<FontAwesomeIcon icon={faEdit} />} onClick={() => setActiveModal(modalKey)}>
            Edit
          </Button>
        }
      >
        <div className='section-content'>{renderSectionContent(modalKey, data)}</div>
      </Card>
    )
  }

  const renderSectionContent = (section, data) => {
    switch (section) {
      case 'allergies':
        return renderAllergiesContent(data)
      case 'chronicIllnesses':
        return renderChronicIllnessesContent(data)
      case 'meds':
        return renderMedsContent(data)
      case 'covidInfo':
        return renderCovidContent(data)
      case 'familyHistory':
        return renderFamilyHistoryContent(data)
      case 'lifestyle':
        return renderLifestyleContent(data)
      case 'mentalHealth':
        return renderMentalHealthContent(data)
      case 'surgicalHistory':
        return renderSurgeriesContent(data)
      default:
        return <Text>No data available</Text>
    }
  }

  const renderAllergiesContent = (allergies) => {
    if (!allergies) return <Text>No allergies recorded</Text>

    return (
      <div>
        <div className='content-section'>
          <Title level={5}>Medication Allergies</Title>
          {allergies.medsList && allergies.medsList.length > 0 ? (
            <ul>
              {allergies.medsList.map((id, index) => (
                <li key={index}>{capitalizeFirstLetter(id)}</li>
              ))}
            </ul>
          ) : (
            <Text>None recorded</Text>
          )}

          {allergies.medsAdd && allergies.medsAdd.length > 0 && (
            <>
              <Text strong>Additional medications:</Text>
              <ul>
                {allergies.medsAdd.map((med, index) => (
                  <li key={index}>{capitalizeFirstLetter(med)}</li>
                ))}
              </ul>
            </>
          )}
        </div>

        <Divider />

        <div className='content-section'>
          <Title level={5}>Food Allergies</Title>
          {allergies.foodList && allergies.foodList.length > 0 ? (
            <ul>
              {allergies.foodList.map((id, index) => (
                <li key={index}>{capitalizeFirstLetter(id)}</li>
              ))}
            </ul>
          ) : (
            <Text>None recorded</Text>
          )}

          {allergies.foodAdd && allergies.foodAdd.length > 0 && (
            <>
              <Text strong>Additional food allergies:</Text>
              <ul>
                {allergies.foodAdd.map((food, index) => (
                  <li key={index}>{capitalizeFirstLetter(food)}</li>
                ))}
              </ul>
            </>
          )}
        </div>

        <Divider />

        <div className='content-section'>
          <Title level={5}>General Allergies</Title>
          {allergies.otherList && allergies.otherList.length > 0 ? (
            <ul>
              {allergies.otherList.map((id, index) => (
                <li key={index}>{capitalizeFirstLetter(id)}</li>
              ))}
            </ul>
          ) : (
            <Text>None recorded</Text>
          )}

          {allergies.otherAdd && allergies.otherAdd.length > 0 && (
            <>
              <Text strong>Additional general allergies:</Text>
              <ul>
                {allergies.otherAdd.map((allergy, index) => (
                  <li key={index}>{capitalizeFirstLetter(allergy)}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    )
  }

  // Helper function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    if (!string) return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const renderChronicIllnessesContent = (chronicIllnesses) => {
    if (!chronicIllnesses || !chronicIllnesses.list || chronicIllnesses.list.length === 0) {
      return <Text>No chronic illnesses recorded</Text>
    }

    return (
      <div>
        {chronicIllnesses.list.map((illness, index) => (
          <div key={index} className='content-section'>
            <Title level={5}>
              {illness.description} ({illness.code})
            </Title>
            <Text>Diagnosed: {dayjs(illness.dateDiagnosed).format('DD MMM YYYY')}</Text>

            {illness.medications && illness.medications.length > 0 ? (
              <div style={{ marginTop: '10px' }}>
                <Text strong>Medications:</Text>
                <ul>
                  {illness.medications.map((med, medIndex) => (
                    <li key={medIndex}>
                      {med.med.name} - From: {dayjs(med.startDate).format('MMM YYYY')}
                      {med.endDate ? ` To: ${dayjs(med.endDate).format('MMM YYYY')}` : ' (Current)'}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div style={{ marginTop: '10px' }}>
                <Text>No medications recorded for this condition</Text>
              </div>
            )}

            {index < chronicIllnesses.list.length - 1 && <Divider />}
          </div>
        ))}
      </div>
    )
  }

  const renderMedsContent = (meds) => {
    if (!meds || !meds.list || meds.list.length === 0) {
      return <Text>No medications recorded</Text>
    }

    return (
      <div>
        <ul>
          {meds.list.map((med, index) => (
            <li key={index}>
              <Text strong>{med.med.name}</Text>
              {med.med.dosageForm && <Text> ({med.med.dosageForm})</Text>}
              <div>
                From: {dayjs(med.startDate).format('DD MMM YYYY')}
                {med.endDate ? ` To: ${dayjs(med.endDate).format('DD MMM YYYY')}` : ' (Current)'}
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const renderCovidContent = (covidInfo) => {
    if (!covidInfo) return <Text>No COVID-19 information recorded</Text>

    return (
      <div>
        <div className='content-section'>
          <Title level={5}>COVID-19 History</Title>
          <div>Had COVID-19: {covidInfo.hadCovid ? 'Yes' : 'No'}</div>
        </div>

        <Divider />

        <div className='content-section'>
          <Title level={5}>Vaccination Status</Title>
          <div>Fully Vaccinated: {covidInfo.fullyVaccinated ? 'Yes' : 'No'}</div>
          {covidInfo.fullyVaccinated && covidInfo.lastVaccinatedDate && (
            <div>Last Vaccination Date: {dayjs(covidInfo.lastVaccinatedDate).format('DD MMM YYYY')}</div>
          )}
        </div>
      </div>
    )
  }

  const renderFamilyHistoryContent = (familyHistory) => {
    if (!familyHistory || !familyHistory.list || familyHistory.list.length === 0) {
      return <Text>No family history recorded</Text>
    }

    return (
      <div>
        <Title level={5}>Family Conditions</Title>
        <ul>
          {familyHistory.list.map((condition, index) => (
            <li key={index}>{condition}</li>
          ))}
        </ul>
      </div>
    )
  }

  const renderLifestyleContent = (lifestyle) => {
    if (!lifestyle) return <Text>No lifestyle information recorded</Text>

    return (
      <div>
        <div className='content-section'>
          <Title level={5}>Diet & Exercise</Title>
          <div>Diet: {lifestyle.diet || 'Not specified'}</div>
          <div>Physical Activity: {lifestyle.physicalActivity || 'Not specified'}</div>
          <div>Sleep: {lifestyle.sleepHours ? `${lifestyle.sleepHours} hours/day` : 'Not specified'}</div>
          <div>Water Intake: {lifestyle.waterIntake ? `${lifestyle.waterIntake} glasses/day` : 'Not specified'}</div>
          <div>Screen Time: {lifestyle.screenTime ? `${lifestyle.screenTime} hours/day` : 'Not specified'}</div>
        </div>

        <Divider />

        <div className='content-section'>
          <Title level={5}>Habits</Title>
          <div>Alcohol Consumption: {lifestyle.alcoholConsumption ? 'Yes' : 'No'}</div>
          {lifestyle.alcoholConsumption && <div>Level: {lifestyle.alcoholConsumptionLevel || 'Not specified'}</div>}

          <div style={{ marginTop: '10px' }}>Smoking: {lifestyle.smoking ? 'Yes' : 'No'}</div>
          {lifestyle.smoking && (
            <>
              <div>Level: {lifestyle.smokingLevel || 'Not specified'}</div>
              <div>Years: {lifestyle.smokingYears || 'Not specified'}</div>
            </>
          )}

          <div style={{ marginTop: '10px' }}>Vaping: {lifestyle.vaping ? 'Yes' : 'No'}</div>
          {lifestyle.vaping && (
            <>
              <div>Level: {lifestyle.vapingLevel || 'Not specified'}</div>
              <div>Years: {lifestyle.vapingYears || 'Not specified'}</div>
            </>
          )}
        </div>
      </div>
    )
  }

  const renderMentalHealthContent = (mentalHealth) => {
    if (!mentalHealth || !mentalHealth.list || mentalHealth.list.length === 0) {
      return <Text>No mental health conditions recorded</Text>
    }

    return (
      <div>
        <Title level={5}>Mental Health Conditions</Title>
        <ul>
          {mentalHealth.list.map((condition, index) => (
            <li key={index}>{condition}</li>
          ))}
        </ul>
      </div>
    )
  }

  const renderSurgeriesContent = (surgicalHistory) => {
    if (!surgicalHistory || !surgicalHistory.list || surgicalHistory.list.length === 0) {
      return <Text>No surgical history recorded</Text>
    }

    return (
      <div>
        <ul>
          {surgicalHistory.list.map((surgery, index) => (
            <li key={index}>
              <Text strong>{surgery.description}</Text>
              <div>Date: {dayjs(surgery.date).format('DD MMM YYYY')}</div>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const renderModalContent = () => {
    switch (activeModal) {
      case 'allergies':
        return (
          <AllergiesTab
            allergies={medicalHistory.allergies}
            recordId={medicalHistory._id}
            setSaving={setSaving}
            updateMedicalHistory={(newData) => {
              setMedicalHistory({ ...medicalHistory, ...newData })
              setActiveModal(null)
            }}
          />
        )
      case 'chronicIllnesses':
        return (
          <ChronicIllnessesTab
            chronicIllnesses={medicalHistory.chronicIllnesses}
            recordId={medicalHistory._id}
            setSaving={setSaving}
            updateMedicalHistory={(newData) => {
              setMedicalHistory({ ...medicalHistory, ...newData })
              setActiveModal(null)
            }}
          />
        )
      case 'meds':
        return (
          <GeneralMedsTab
            meds={medicalHistory.meds}
            recordId={medicalHistory._id}
            setSaving={setSaving}
            updateMedicalHistory={(newData) => {
              setMedicalHistory({ ...medicalHistory, ...newData })
              setActiveModal(null)
            }}
          />
        )
      case 'covidInfo':
        return (
          <CovidInfoTab
            covidInfo={medicalHistory.covidInfo}
            recordId={medicalHistory._id}
            setSaving={setSaving}
            updateMedicalHistory={(newData) => {
              setMedicalHistory({ ...medicalHistory, ...newData })
              setActiveModal(null)
            }}
          />
        )
      case 'familyHistory':
        return (
          <FamilyHistoryTab
            familyHistory={medicalHistory.familyHistory}
            recordId={medicalHistory._id}
            setSaving={setSaving}
            updateMedicalHistory={(newData) => {
              setMedicalHistory({ ...medicalHistory, ...newData })
              setActiveModal(null)
            }}
          />
        )
      case 'lifestyle':
        return (
          <LifestyleTab
            lifestyle={medicalHistory.lifestyle}
            recordId={medicalHistory._id}
            setSaving={setSaving}
            updateMedicalHistory={(newData) => {
              setMedicalHistory({ ...medicalHistory, ...newData })
              setActiveModal(null)
            }}
          />
        )
      case 'mentalHealth':
        return (
          <MentalHealthTab
            mentalHealth={medicalHistory.mentalHealth}
            recordId={medicalHistory._id}
            setSaving={setSaving}
            updateMedicalHistory={(newData) => {
              setMedicalHistory({ ...medicalHistory, ...newData })
              setActiveModal(null)
            }}
          />
        )
      case 'surgicalHistory':
        return (
          <SurgeriesTab
            surgicalHistory={medicalHistory.surgicalHistory}
            recordId={medicalHistory._id}
            setSaving={setSaving}
            updateMedicalHistory={(newData) => {
              setMedicalHistory({ ...medicalHistory, ...newData })
              setActiveModal(null)
            }}
          />
        )
      default:
        return null
    }
  }

  const getModalTitle = () => {
    switch (activeModal) {
      case 'allergies':
        return 'Edit Allergies'
      case 'chronicIllnesses':
        return 'Edit Chronic Illnesses'
      case 'meds':
        return 'Edit Medications'
      case 'covidInfo':
        return 'Edit COVID-19 Information'
      case 'familyHistory':
        return 'Edit Family History'
      case 'lifestyle':
        return 'Edit Lifestyle Information'
      case 'mentalHealth':
        return 'Edit Mental Health Information'
      case 'surgicalHistory':
        return 'Edit Surgical History'
      default:
        return 'Edit Medical History'
    }
  }

  const renderActiveSection = () => {
    const section = sections.find((s) => s.key === activeSection)
    if (!section) return null

    return renderSectionCard(
      section.title,
      section.icon,
      medicalHistory[section.key],
      section.key,
      medicalHistory[section.key]?.updatedAt
    )
  }

  return (
    <div className='medical-history-container'>
      {/* <LoadingOverlay loading={saving} text='Saving changes...' /> */}

      <div className='medical-history-header'>
        <Title level={3}>Patient Medical History</Title>

        <Row gutter={[8, 8]} style={{ marginBottom: '16px' }}>
          {sections.map((section) => (
            <Col span={12} key={section.key}>
              <Button
                type={activeSection === section.key ? 'primary' : 'default'}
                onClick={() => setActiveSection(section.key)}
                icon={<FontAwesomeIcon icon={section.icon} />}
                size='small'
                style={{ width: '100%' }}
              >
                {section.title}
              </Button>
            </Col>
          ))}
        </Row>
      </div>

      <div className='section-content-container'>{renderActiveSection()}</div>

      <Modal
        title={getModalTitle()}
        open={!!activeModal}
        onCancel={() => setActiveModal(null)}
        footer={null}
        width={1920}
        destroyOnClose
      >
        {renderModalContent()}
      </Modal>
    </div>
  )
}

export default MedicalHistoryForm
