import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUserDoctor, 
  faArrowRight, 
  faArrowLeft,
  faHeadSideCough,
  faBandage,
  faAllergies,
  faCamera,
  faThermometer,
  faHeartPulse,
  faDroplet,
  faLungs,
  faCreditCard,
  faCheckCircle,
  faPhone,
  faMessage,
  faSyncAlt,
  faCheck
} from '@fortawesome/free-solid-svg-icons'
import '../styles/AskADocWizard.css'
import { Input, Upload } from 'antd'

const { TextArea } = Input

const CONSULTATION_TYPES = [
  {
    id: 'illness',
    icon: faHeadSideCough,
    title: 'Feeling Unwell',
    description: 'Cold, flu, fever or other symptoms'
  },
  {
    id: 'injury',
    icon: faBandage,
    title: 'Injury or Pain',
    description: 'Cuts, sprains, or general pain'
  },
  {
    id: 'skin',
    icon: faAllergies,
    title: 'Skin Condition',
    description: 'Rash, allergic reaction, or skin concerns'
  }
]

const SYMPTOMS_BY_TYPE = {
  illness: [
    { id: 'fever', label: 'Fever' },
    { id: 'cough', label: 'Cough' },
    { id: 'headache', label: 'Headache' },
    { id: 'soreThroat', label: 'Sore Throat' },
    { id: 'fatigue', label: 'Fatigue' },
    { id: 'bodyAche', label: 'Body Ache' },
    { id: 'nausea', label: 'Nausea' },
    { id: 'dizziness', label: 'Dizziness' },
    
  ],
  injury: [
    { id: 'swelling', label: 'Swelling' },
    { id: 'bruising', label: 'Bruising' },
    { id: 'stiffness', label: 'Stiffness' },
    { id: 'numbness', label: 'Numbness' },
    { id: 'weakness', label: 'Weakness' },
    { id: 'limited_movement', label: 'Limited Movement' },
    { id: 'sharp_pain', label: 'Sharp Pain' },
    { id: 'dull_pain', label: 'Dull Pain' },
   
  ],
  skin: [
    { id: 'itching', label: 'Itching' },
    { id: 'redness', label: 'Redness' },
    { id: 'swelling', label: 'Swelling' },
    { id: 'burning', label: 'Burning' },
    { id: 'rash', label: 'Rash' },
    { id: 'dryness', label: 'Dryness' },
    { id: 'blisters', label: 'Blisters' },
    { id: 'peeling', label: 'Peeling' },
    
  ]
}

const DURATION_OPTIONS = [
  { id: 'today', label: 'Today' },
  { id: 'yesterday', label: '1-2 Days' },
  { id: 'week', label: '3-7 Days' },
  { id: 'twoWeeks', label: '2 Weeks' },
  { id: 'month', label: '1 Month' },
  { id: 'moreThanMonth', label: '> 1 Month' }
]

export const AskADocWizard = () => {
  const [step, setStep] = useState('welcome')
  const [consultationType, setConsultationType] = useState(null)
  const [selectedOption, setSelectedOption] = useState(null)
  const [formData, setFormData] = useState({
    symptoms: [],
    duration: '',
    severity: 'mild',
    vitals: {
      temperature: '',
      heartRate: '',
      bloodPressure: {
        systolic: '',
        diastolic: ''
      },
      oxygenLevel: ''
    },
    images: []
  })

  const handleStart = () => {
    setStep('select-type')
  }

  const handleSelectType = (type) => {
    setConsultationType(type)
    setStep('details')
  }

  const handleBack = () => {
    setStep('select-type')
  }

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }))
  }

  const handleVitalsChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      vitals: {
        ...prev.vitals,
        [field]: value
      }
    }))
  }

  const handleContinueToPayment = () => {
    setStep('payment')
  }

  const handlePayment = () => {
    setStep('confirmation')
  }

  const handleSymptomToggle = (symptomId) => {
    setFormData(prev => ({
      ...prev,
      symptoms: prev.symptoms.includes(symptomId)
        ? prev.symptoms.filter(id => id !== symptomId)
        : [...prev.symptoms, symptomId]
    }))
  }

  const handleDeviceSync = () => {
    setFormData(prev => ({
      ...prev,
      vitals: {
        temperature: '38',
        heartRate: '98',
        bloodPressure: {
          systolic: '135',
          diastolic: '88'
        },
        oxygenLevel: '93'
      }
    }))
  }

  return (
    <div className="ask-doc-wizard">
      <AnimatePresence mode='wait'>
        {step === 'welcome' && (
          <motion.div 
            className="wizard-screen welcome-screen"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            <FontAwesomeIcon icon={faUserDoctor} className="welcome-icon" />
            <h1>Welcome to Meta-Consult</h1>
            <p>The future of healthcare, where you're in control of your health journey.</p>
            <p className="feature-text">
              ✓ Quick medical advice<br/>
              ✓ Secure consultations<br/>
              ✓ Digital prescriptions<br/>
              ✓ Instant pharmacy delivery
            </p>
            <button className="primary-button" onClick={handleStart}>
              Start Consultation
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </motion.div>
        )}

        {step === 'select-type' && (
          <motion.div 
            className="wizard-screen type-selection-screen"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            <h2>What brings you here today?</h2>
            <div className="consultation-types">
              {CONSULTATION_TYPES.map((type) => (
                <motion.button
                  key={type.id}
                  className="type-button"
                  onClick={() => handleSelectType(type)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <FontAwesomeIcon icon={type.icon} className="type-icon" />
                  <div className="type-content">
                    <h3>{type.title}</h3>
                    <p>{type.description}</p>
                  </div>
                </motion.button>
              ))}
            </div>
          </motion.div>
        )}

        {step === 'details' && (
          <motion.div 
            className="wizard-screen details-screen"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            <button className="back-button" onClick={handleBack}>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back
            </button>

            <h2>Tell us more about your {consultationType.title.toLowerCase()}</h2>

            <div className="form-container">
              <div className="form-section">
                <label>Select your symptoms</label>
                <div className="symptoms-grid">
                  {SYMPTOMS_BY_TYPE[consultationType.id].map((symptom) => (
                    <button
                      key={symptom.id}
                      className={`symptom-button ${formData.symptoms.includes(symptom.id) ? 'selected' : ''}`}
                      onClick={() => handleSymptomToggle(symptom.id)}
                    >
                      {symptom.label}
                    </button>
                  ))}
                </div>
              </div>

              <div className="form-section">
                <label>How long have you been experiencing this?</label>
                <div className="duration-grid">
                  {DURATION_OPTIONS.map((option) => (
                    <button
                      key={option.id}
                      className={`duration-button ${formData.duration === option.id ? 'selected' : ''}`}
                      onClick={() => handleInputChange('duration', option.id)}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              </div>

              <div className="form-section">
                <label>Severity</label>
                <div className="severity-selector">
                  {['mild', 'moderate', 'severe'].map((severity) => (
                    <button
                      key={severity}
                      className={`severity-button ${formData.severity === severity ? 'selected' : ''}`}
                      onClick={() => handleInputChange('severity', severity)}
                    >
                      {severity.charAt(0).toUpperCase() + severity.slice(1)}
                    </button>
                  ))}
                </div>
              </div>

              {consultationType.id === 'illness' && (
                <div className="vitals-section">
                  <div className="vitals-header">
                    <h3>Vital Signs</h3>
                    <button className="sync-button" onClick={handleDeviceSync}>
                      <FontAwesomeIcon icon={faSyncAlt} />
                      Sync Device
                    </button>
                  </div>
                  <div className="vitals-grid">
                    <div className="vital-input">
                      <FontAwesomeIcon icon={faThermometer} />
                      <Input 
                        className="form-input"
                        placeholder="Temperature"
                        suffix="°C"
                        value={formData.vitals.temperature}
                        onChange={(e) => handleVitalsChange('temperature', e.target.value)}
                      />
                    </div>
                    <div className="vital-input">
                      <FontAwesomeIcon icon={faHeartPulse} />
                      <Input 
                        className="form-input"
                        placeholder="Heart Rate"
                        suffix="bpm"
                        value={formData.vitals.heartRate}
                        onChange={(e) => handleVitalsChange('heartRate', e.target.value)}
                      />
                    </div>
                    <div className="vital-input blood-pressure">
                      <FontAwesomeIcon icon={faDroplet} />
                      <div className="bp-inputs">
                        <Input 
                          className="form-input"
                          placeholder="Systolic"
                          value={formData.vitals.bloodPressure.systolic}
                          onChange={(e) => handleVitalsChange('bloodPressure', { 
                            ...formData.vitals.bloodPressure,
                            systolic: e.target.value 
                          })}
                        />
                        <span>/</span>
                        <Input 
                          className="form-input"
                          placeholder="Diastolic"
                          value={formData.vitals.bloodPressure.diastolic}
                          onChange={(e) => handleVitalsChange('bloodPressure', {
                            ...formData.vitals.bloodPressure,
                            diastolic: e.target.value
                          })}
                        />
                        <span>mmHg</span>
                      </div>
                    </div>
                    <div className="vital-input">
                      <FontAwesomeIcon icon={faLungs} />
                      <Input 
                        className="form-input"
                        placeholder="Oxygen Level"
                        suffix="%"
                        value={formData.vitals.oxygenLevel}
                        onChange={(e) => handleVitalsChange('oxygenLevel', e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}

              {(consultationType.id === 'skin' || consultationType.id === 'injury') && (
                <div className="form-section">
                  <label>Upload Images</label>
                  <Upload.Dragger 
                    multiple
                    listType="picture"
                    showUploadList={{ showPreviewIcon: true }}
                    className="upload-area"
                  >
                    <p className="upload-icon">
                      <FontAwesomeIcon icon={faCamera} />
                    </p>
                    <p className="upload-text">Click or drag images to upload</p>
                  </Upload.Dragger>
                </div>
              )}

              <button 
                className="primary-button submit-button"
                onClick={() => setStep('review')}
              >
                Continue to Review
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </motion.div>
        )}

        {step === 'review' && (
          <motion.div 
            className="wizard-screen review-screen"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            <button className="back-button" onClick={() => setStep('details')}>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back
            </button>

            <h2>Review Your Consultation</h2>
            
            <div className="review-container">
              <div className="review-section">
                <h3>Consultation Options</h3>
                <div className="consultation-options">
                  <button 
                    className={`option-button ${selectedOption === 'message' ? 'selected' : ''}`}
                    onClick={() => setSelectedOption('message')}
                  >
                    <FontAwesomeIcon icon={faMessage} />
                    <div className="option-content">
                      <h4>Message Consultation</h4>
                      <p>Professional medical advice within 2 hours</p>
                      <div className="steps-included">
                        <div className="step-item">
                          <FontAwesomeIcon icon={faCheck} className="check-icon" />
                          <span>Doctor Review & Diagnosis</span>
                        </div>
                        <div className="step-item">
                          <FontAwesomeIcon icon={faCheck} className="check-icon" />
                          <span>Personalized Treatment Plan</span>
                        </div>
                        <div className="step-item">
                          <FontAwesomeIcon icon={faCheck} className="check-icon" />
                          <span>Digital Prescription to Dischem</span>
                        </div>
                      </div>
                      <div className="price">
                        <span>R150</span>
                        <span className="duration">per consultation</span>
                      </div>
                    </div>
                  </button>

                  <button 
                    className={`option-button ${selectedOption === 'call' ? 'selected' : ''}`}
                    onClick={() => setSelectedOption('call')}
                  >
                    <FontAwesomeIcon icon={faPhone} />
                    <div className="option-content">
                      <h4>Video/Voice Call</h4>
                      <p>Live consultation within 5 minutes</p>
                      <div className="steps-included">
                        <div className="step-item">
                          <FontAwesomeIcon icon={faCheck} className="check-icon" />
                          <span>Live Doctor Consultation</span>
                        </div>
                        <div className="step-item">
                          <FontAwesomeIcon icon={faCheck} className="check-icon" />
                          <span>Immediate Treatment Plan</span>
                        </div>
                        <div className="step-item">
                          <FontAwesomeIcon icon={faCheck} className="check-icon" />
                          <span>Digital Prescription to Dischem</span>
                        </div>
                      </div>
                      <div className="price">
                        <span>R250</span>
                        <span className="duration">per consultation</span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>

              <div className="review-section">
                <h3>Summary</h3>
                <div className="summary-content">
                  <div className="summary-item">
                    <span>Type:</span>
                    <span>{consultationType.title}</span>
                  </div>
                  <div className="summary-item">
                    <span>Symptoms:</span>
                    <div className="symptoms-list">
                      {formData.symptoms.map(symptom => (
                        <span key={symptom} className="symptom-tag">
                          {SYMPTOMS_BY_TYPE[consultationType.id].find(s => s.id === symptom)?.label}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="summary-item">
                    <span>Duration:</span>
                    <span>{formData.duration === 'today' ? 'Started today' : formData.duration}</span>
                  </div>
                  <div className="summary-item">
                    <span>Severity:</span>
                    <span className="severity-tag">{formData.severity}</span>
                  </div>
                </div>
              </div>

              <button 
                className="primary-button submit-button"
                onClick={handleContinueToPayment}
                disabled={!selectedOption}
              >
                Continue to Payment
                <FontAwesomeIcon icon={faCreditCard} />
              </button>
            </div>
          </motion.div>
        )}

        {step === 'payment' && (
          <motion.div 
            className="wizard-screen payment-screen"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            <button className="back-button" onClick={() => setStep('review')}>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back
            </button>

            <h2>Payment Details</h2>
            
            <div className="payment-container">
              <div className="payment-summary">
                <h3>Order Summary</h3>
                <div className="summary-item">
                  <span>{selectedOption === 'message' ? 'Message Consultation' : 'Video/Voice Call'}</span>
                  <span>R{selectedOption === 'message' ? '150' : '250'}</span>
                </div>
                <div className="total">
                  <span>Total</span>
                  <span>R{selectedOption === 'message' ? '150' : '250'}</span>
                </div>
              </div>

              <div className="card-details">
                <Input placeholder="Card Number" />
                <div className="card-row">
                  <Input placeholder="MM/YY" />
                  <Input placeholder="CVV" />
                </div>
              </div>

              <button 
                className="primary-button submit-button"
                onClick={handlePayment}
              >
                Pay Now
                <FontAwesomeIcon icon={faCreditCard} />
              </button>
            </div>
          </motion.div>
        )}

        {step === 'confirmation' && (
          <motion.div 
            className="wizard-screen confirmation-screen"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <div className="confirmation-content">
              <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
              <h2>Consultation Booked!</h2>
              <p>Your consultation has been successfully booked.</p>
              
              {selectedOption === 'message' ? (
                <p className="instruction">You will receive a response within 2 hours.</p>
              ) : (
                <p className="instruction">You will receive a call within 5 minutes.</p>
              )}

              <div className="consultation-id">
                <span>Consultation ID:</span>
                <span>META-{Math.random().toString(36).substr(2, 9).toUpperCase()}</span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
} 