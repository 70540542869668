import { createSlice } from '@reduxjs/toolkit'
import PatHealthStatsState from './state'

const patHealthStatsReducer = createSlice({
  name: 'patHealthStats',
  initialState: PatHealthStatsState,
  reducers: {
    setState: (state, action) => {
      state.data = action.payload
    },

    updateState: (state, action) => {
      state.data = action.payload
    }
  }
})

export default patHealthStatsReducer
