const AuthEnums = {
  keys: {},
  titles: {},
  facets: {
    OPTIONS: 'options',
    EMAIL_LINK: 'emailLink',
    EMAIL_LINK_SUCCESS: 'emailLinkSuccess',
    PHONE: 'phone',
    CODE_CONFIRM: 'codeConfirm'
  },
  signInTypes: {
    GOOGLE: 'google.com',
    PASSWORD: 'password'
  },
  routes: {
    ROOT: '/',
    LOGIN: '/login',
    CHECKIN: '/checkin',
    REGISTER: '/register',
    PAY_STATUS: '/payment-status',
    DASHBOARD: '/dashboard',
    PERSONAL_DETAILS: '/personal-details',
    MEDICAL_HISTORY: '/medical-history',
    MY_BOOKINGS: '/my-bookings',
    DIAGNOSIS_HISTORY: '/diagnosis-history',
    VIRTUAL_CONSULT: '/virtual-consult'
  },
  localStorage: {
    EMAIL_FOR_SIGN_IN: 'emailForSignIn'
  }
}

export default AuthEnums
