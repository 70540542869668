// src/App.js
import React, { useContext, useEffect, useState } from 'react'
import BluetoothContext from './BluetoothContext'
import OXDetection from './components/Detection/OXDetection'
import BTDetection from './components/Detection/BTDetection'
import BGDetection from './components/Detection/BGDetection'
import BPDetection from './components/Detection/BPDetection'
import DeviceInfo from './components/DeviceInfo'
import BatteryStatus from './components/BatteryStatus'
import CustomRow from '../../reusable-components/CustomRow'
import { Button, Card, Col, Popconfirm, Space, theme, Tooltip, Alert } from 'antd'
import CustomButton from '../../reusable-components/CustomButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle, faRotate } from '@fortawesome/free-solid-svg-icons'
import '../animations.css'
import { hexToRGBA } from '../../lib/utils'
import { deviceDetect, isAndroid } from 'react-device-detect'

function App({ userRef, bookingRef, setModalOpen, handleOnAfterSuccess }) {
  const {
    requestDevice,
    connectDevice,
    disconnectDevice,
    bleDevice,
    connectionStatus,
    lastError,
    engine
    // bleCommandCharacteristic,
    // bleNotifyCharacteristic
  } = useContext(BluetoothContext)
  const [facet, setFacet] = useState('ox')
  const [busy, setBusy] = useState(false)
  const [readings, setReadings] = useState([])
  const [connectionError, setConnectionError] = useState(null)

  console.log('CONNECT DEVICE', connectDevice)

  console.log('ENGINE', engine)

  console.log('CONNECTED', connectionStatus)
  useEffect(() => {
    if (bleDevice && connectionStatus !== 'connected') {
      connectDevice().catch((err) => {
        setConnectionError(`Connection error: ${err.message}`)
      })
    }

    // Clear connection error when connected
    if (connectionStatus === 'connected') {
      setConnectionError(null)
    }

    // eslint-disable-next-line
  }, [bleDevice, connectionStatus])

  const getTitle = () => {
    switch (facet) {
      case 'ox':
        return 'READING OXYGEN'
      case 'bt':
        return 'READING TEMPERATURE'
      case 'bg':
        return 'READING BLOOD GLUCOSE'
      case 'bp':
        return 'READING BLOOD PRESSURE'
      default:
        return 'SELECT READING TYPE'
    }
  }

  const { token } = theme.useToken()

  // Helper function to get connection status text and color
  const getConnectionStatusInfo = () => {
    switch (connectionStatus) {
      case 'connected':
        return { text: 'DEVICE CONNECTED', color: token.colorSuccess }
      case 'connecting':
        return { text: 'CONNECTING...', color: token.colorWarning, className: 'connection-status-blink' }
      case 'disconnected':
      default:
        return { text: 'DEVICE DISCONNECTED', color: token.colorError }
    }
  }

  const statusInfo = getConnectionStatusInfo()

  return (
    <div className='App'>
      <div
        style={{
          width: '100%',
          padding: 8,
          background: hexToRGBA(token.colorPrimary, 0.2),
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          top: 0,
          left: 0
        }}
      >
        <h2>LINK-TOP DEVICE</h2>
        {readings.length > 0 && !busy ? (
          <Popconfirm
            title='Are you sure?'
            description='Any unconfirmed readings will be lost.'
            onConfirm={() => {
              setReadings([])
              setModalOpen(false)
              handleOnAfterSuccess()
            }}
          >
            <Button style={{ fontSize: 24, background: 'transparent', border: 'none' }}>
              <FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} />
            </Button>
          </Popconfirm>
        ) : (
          <Tooltip title={busy ? 'Please wait for the operation to finish or cancel it!' : ''}>
            <Button
              onClick={() => {
                disconnectDevice()
                setModalOpen(false)
                handleOnAfterSuccess()
              }}
              style={{ float: 'right', fontSize: 24, background: 'transparent', border: 'none' }}
              disabled={busy}
            >
              <FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} />
            </Button>
          </Tooltip>
        )}
      </div>
      {/* Add error alert if there's a connection error */}
      {(connectionError || lastError) && (
        <Alert
          message='Connection Issue'
          description={connectionError || lastError}
          type='error'
          showIcon
          closable
          style={{ margin: '10px' }}
        />
      )}
      <CustomRow style={{ marginTop: 48 }}>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Card
            // headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}
            bodyStyle={{ overflow: 'hidden', padding: 24 }}
            size='small'
            title={
              connectionStatus === 'connected' ? (
                <p style={{ color: statusInfo.color }} className={statusInfo.className}>
                  {statusInfo.text}
                </p>
              ) : (
                <CustomButton
                  iconPosition={'end'}
                  type='primary'
                  style={{ background: '#027FF5' }}
                  size='small'
                  icon={'Connect'}
                  text='Connect'
                  onClick={requestDevice}
                  disabled={connectionStatus === 'connecting'}
                />
              )
            }
            extra={
              <Space>
                <img
                  src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYL5VcbRlIn84LKE_fVQ7-YQ3NQkp86EX_zg&s'
                  style={{ height: 20 }}
                  alt='Bluetooth icon'
                />
              </Space>
            }
          >
            <img
              className={!busy ? '' : 'linktop-pulse'}
              style={{ minWidth: '100%', width: 100, position: 'relative', boxSizing: 'border-box' }}
              src='https://linktop.com/wp-content/uploads/2021/12/linktop-HC03-Health-Monitor.png'
              alt='6-in-1 device'
            />
            {connectionStatus === 'connected' ? (
              <CustomRow>
                <Col span={24}>
                  <Button
                    onClick={disconnectDevice}
                    type={'primary'}
                    style={{ background: busy ? '' : token.colorError }}
                    disabled={busy || readings.length > 0}
                  >
                    Disconnect
                  </Button>
                </Col>
                <Col span={24}>
                  <DeviceInfo />
                </Col>
                <Col span={24}>
                  <BatteryStatus />
                </Col>
                {isAndroid && (
                  <Col span={24} style={{ marginTop: 10 }}>
                    <Button onClick={() => connectDevice()} icon={<FontAwesomeIcon icon={faRotate} />} disabled={busy}>
                      Refresh Connection
                    </Button>
                  </Col>
                )}
              </CustomRow>
            ) : (
              <center>
                <h1 className={statusInfo.className} style={{ color: statusInfo.color }}>
                  {statusInfo.text}
                </h1>
              </center>
            )}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16}>
          <Card size='small' title={getTitle()} headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }}>
            <div
              style={{ display: 'grid', gridTemplateColumns: deviceDetect().isMobile ? '1fr' : '1fr 250px', gap: 16 }}
            >
              <div>
                {facet === 'ox' ? (
                  <OXDetection
                    readings={readings}
                    setReadings={setReadings}
                    setBusy={setBusy}
                    userRef={userRef}
                    bookingRef={bookingRef}
                  />
                ) : undefined}
                {facet === 'bt' ? (
                  <BTDetection
                    readings={readings}
                    setReadings={setReadings}
                    setBusy={setBusy}
                    userRef={userRef}
                    bookingRef={bookingRef}
                  />
                ) : undefined}
                {/* {facet === 'bg' ? (
                  <BGDetection readings={readings} setReadings={setReadings} setBusy={setBusy} />
                ) : undefined} */}
                {facet === 'bp' ? (
                  <BPDetection
                    userRef={userRef}
                    bookingRef={bookingRef}
                    readings={readings}
                    setReadings={setReadings}
                    setBusy={setBusy}
                  />
                ) : undefined}
                {/* <ECGDetection /> */}
              </div>
              <div>
                <Space direction='vertical'>
                  <CustomButton
                    disabled={busy || readings.length > 0 || connectionStatus !== 'connected'}
                    onClick={() => {
                      setFacet('ox')
                    }}
                    text={'Oxygen'}
                    type={facet === 'ox' ? 'primary' : 'standard'}
                    style={{ width: 250 }}
                    size='small'
                  />
                  <CustomButton
                    disabled={busy || readings.length > 0 || connectionStatus !== 'connected'}
                    onClick={() => {
                      setFacet('bt')
                    }}
                    text={'Temperature'}
                    type={facet === 'bt' ? 'primary' : 'standard'}
                    style={{ width: 250 }}
                    size='small'
                  />
                  {/* <CustomButton
                    disabled={busy || readings.length > 0 || connectionStatus !== 'connected'}
                    onClick={() => {
                      setFacet('bg')
                    }}
                    text={'Blood Glucose'}
                    type={facet === 'bg' ? 'primary' : 'standard'}
                    style={{ width: 250 }}
                    size='small'
                  /> */}
                  <CustomButton
                    disabled={busy || readings.length > 0 || connectionStatus !== 'connected'}
                    onClick={() => {
                      setFacet('bp')
                    }}
                    text={'Blood Pressure'}
                    type={facet === 'bp' ? 'primary' : 'standard'}
                    style={{ width: 250 }}
                    size='small'
                  />
                </Space>
              </div>
            </div>
          </Card>
        </Col>
      </CustomRow>
    </div>
  )
}

export default App
