import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { readClinicDataPoints } from '../../../../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import { readDataPointTemplates } from '../../../../../Data-Point-Templates/utils'
import { handleError } from '../../../../../lib/utils'
import { message } from 'antd'
import { assistantInitiate } from '../../../../../AI Tools/utils'
import { AssistantEnums } from '../../../../../AI Tools/assitants-enums'
import { generateAIResponse } from '../../../../../AI Tools/assistants-lib'
import { readMedicalHistory } from '../../../../../Admin/patients/utils/utils'
import { groupData, readClinicalEntries } from '../../../../examination-utils/examination-lib'
import { MODAL_STATES } from '../../utils/constants'
import { createDocument, downloadReferralLetter } from '../../utils/api'
import { documentsTypes } from '../../utils/enums'
import dayjs from 'dayjs'

const useReferral = ({ userRef, bookingData, patient, handleAfterSuccess }) => {
  const authState = useSelector((state) => state.auth)
  const [autogenConfigRequired, setAutogenConfigRequired] = useState(false)
  const [autogenConfig, setAutogenConfig] = useState({
    specialistType: null,
    specialistName: null,
    language: null,
    additionalPrompts: ''
  })
  const [content, setContent] = useState('')
  const [includedDataKeys, setIncludedDataKeys] = useState([])
  const [templateSelectionRequired, setTemplateSelectionRequired] = useState(false)

  // Data
  const [dataPoints, setDataPoints] = useState([])
  const [dataTemplates, setDataTemplates] = useState([])
  const [recentTemplates, setRecentTemplates] = useState([])

  // Error Handling
  const [dataTemplateFetchingError, setDataTemplateFetchingError] = useState('')
  const [validationErrors, setValidationErrors] = useState({})

  // Loading
  const [awaitingAi, setAwaitingAi] = useState(false)
  const [loadingDataAndTemplates, setLoadingDataAndTemplates] = useState(false)
  const [referralLoading, setReferralLoading] = useState(false)

  // Facet control
  const [isHistroical, setIsHistorical] = useState(false)

  const validateReferralSubmission = useCallback(() => {
    const errors = {}
    const signature = authState.agiliteUser.extraData.signature
    const mpNumber = authState.agiliteUser.extraData.mpNumber

    if (!signature) {
      errors.signature =
        'No Signature has been set. Please set your Signature in your Personal Details Medical Profile.'
    }

    if (!mpNumber) {
      errors.mpNumber = 'No MP Number has been set. Please set your MP Number in your Personal Details Medical Profile.'
    }

    if (!content || content.trim() === '') {
      errors.content = 'Please provide content for the referral letter.'
    }

    setValidationErrors(errors)
    return Object.keys(errors).length === 0
  }, [authState.agiliteUser.extraData.signature, authState.agiliteUser.extraData.mpNumber, content])

  const handleSubmitReferral = async () => {
    if (!validateReferralSubmission()) {
      // Display the first error message
      const firstError = Object.values(validationErrors)[0]
      message.error(firstError)
      return
    }

    const signature = authState.agiliteUser.extraData.signature
    setReferralLoading(true)

    try {
      // Base64 response
      const response = await downloadReferralLetter(
        bookingData,
        bookingData ? bookingData.patientRecord : patient,
        content,
        signature,
        documentsTypes.REFERRAL
      )

      const newRecord = await createDocument(
        response,
        {
          name: `Referral Letter (${dayjs().format('DD-MM-YYYY')})`,
          userRef: bookingData ? bookingData.userRef : userRef,
          bookingRef: bookingData ? bookingData._id : ''
        },
        documentsTypes.REFERRAL
      )

      message.success('Referral letter generated successfully!')
      handleAfterSuccess(MODAL_STATES.REFERRAL_LETTER, newRecord)
    } catch (e) {
      message.error(handleError(e, true))
    }

    setReferralLoading(false)
  }

  const handleAiAutoGenerateReferral = async () => {
    let tmpContent = ''
    let tmpClinicalData = null
    let aiResponse = null
    const payload = {}

    setAwaitingAi(true)
    try {
      // Store the current configuration as a recent template
      const newRecentTemplate = {
        specialistType: autogenConfig.specialistType,
        specialistName: autogenConfig.specialistName,
        language: autogenConfig.language,
        dataKeys: [...includedDataKeys],
        timestamp: new Date().toISOString()
      }

      // Add to recent templates, keeping only the last 5
      setRecentTemplates((prev) => {
        const updated = [newRecentTemplate, ...prev].slice(0, 5)
        // Save to localStorage for persistence
        try {
          localStorage.setItem('recentReferralTemplates', JSON.stringify(updated))
        } catch (e) {
          console.error('Failed to save recent templates to localStorage', e)
        }
        return updated
      })

      tmpClinicalData = await readClinicalEntries({
        key: { $in: includedDataKeys },
        userRef: bookingData?.userRef ? bookingData.userRef : userRef
      })

      payload.config = autogenConfig
      payload.patientDetails = (() => {
        if (bookingData?.patientRecord) {
          const { firstName, lastName, dateOfBirth, gender } = bookingData.patientRecord
          return {
            fullName: `${firstName} ${lastName}`,
            dateOfBirth,
            gender
          }
        } else if (patient) {
          const { firstName, lastName, dateOfBirth, gender } = patient
          return {
            fullName: `${firstName} ${lastName}`,
            dateOfBirth,
            gender
          }
        }
        return null
      })()

      payload.clinicalData = (() => {
        let groupedData = groupData(tmpClinicalData)
        groupedData.forEach((dataKey) => {
          dataKey.records = dataKey.records.map((record) => ({
            value: record.value,
            date: record.dateCreated
          }))
        })
        return groupedData
      })()

      payload.medicalHistory = await readMedicalHistory({ userRef: userRef ? userRef : bookingData?.userRef })

      // Show loading message
      message.loading({ content: 'Generating referral letter with AI...', key: 'aiGeneration', duration: 0 })

      const tmpData = await assistantInitiate(JSON.stringify(payload), AssistantEnums.assistant_ids.REFERRAL_LETTER)
      aiResponse = await generateAIResponse(tmpData)
      tmpContent = aiResponse?.data?.data[0]?.content[0]?.text?.value

      if (!tmpContent) {
        throw new Error('Failed to generate content. Please try again or use manual creation.')
      }

      setContent(tmpContent)
      setAutogenConfigRequired(false)

      // Close the loading message and show success
      message.success({ content: 'Referral letter generated successfully!', key: 'aiGeneration', duration: 2 })
    } catch (e) {
      message.error({ content: handleError(e), key: 'aiGeneration', duration: 4 })
    }
    setAwaitingAi(false)
  }

  const handleAutogenConfig = (value, key) => {
    let tmpAutogenConfig = { ...autogenConfig }
    tmpAutogenConfig[key] = value
    setAutogenConfig(tmpAutogenConfig)
  }

  // Load recent templates from localStorage on initial load
  useEffect(() => {
    try {
      const savedTemplates = localStorage.getItem('recentReferralTemplates')
      if (savedTemplates) {
        setRecentTemplates(JSON.parse(savedTemplates))
      }
    } catch (e) {
      console.error('Failed to load recent templates from localStorage', e)
    }
  }, [])

  useEffect(() => {
    if (autogenConfigRequired) {
      fetchDatapointData()
    }
  }, [autogenConfigRequired])

  const fetchDatapointData = async () => {
    let tmpDataPoints = []
    let tmpDataTemplates = []

    setLoadingDataAndTemplates(true)
    try {
      // Use Promise.all to fetch data in parallel
      const [dataPointsResult, dataTemplatesResult] = await Promise.all([
        readClinicDataPoints(),
        readDataPointTemplates()
      ])

      tmpDataPoints = dataPointsResult
      tmpDataTemplates = dataTemplatesResult

      setDataTemplateFetchingError('')
      setDataPoints(tmpDataPoints)
      setDataTemplates(tmpDataTemplates)
    } catch (e) {
      const errorMessage = handleError(e)
      setDataTemplateFetchingError(errorMessage)
      message.error(`Error loading data templates: ${errorMessage}`)
    } finally {
      setLoadingDataAndTemplates(false)
    }
  }

  const handleTemplateSelection = (keys) => {
    const tmpIncludedDataKeys = []
    keys.forEach((key) => {
      const dataReference = dataPoints.find((i) => i.key === key)
      if (dataReference) {
        tmpIncludedDataKeys.push(dataReference.key)
      }
    })
    setIncludedDataKeys(tmpIncludedDataKeys)
    setTemplateSelectionRequired(false)
  }

  const handleReset = () => {
    setDataPoints([])
    setDataTemplates([])
    setIncludedDataKeys([])
    setAutogenConfigRequired(false)
    setValidationErrors({})
  }

  const applyRecentTemplate = (template) => {
    setAutogenConfig({
      specialistType: template.specialistType,
      specialistName: template.specialistName,
      language: template.language,
      additionalPrompts: autogenConfig.additionalPrompts // Keep any current additional prompts
    })
    setIncludedDataKeys(template.dataKeys)
  }

  return {
    authState,
    autogenConfigRequired,
    autogenConfig,
    content,
    includedDataKeys,
    templateSelectionRequired,
    dataPoints,
    dataTemplates,
    dataTemplateFetchingError,
    awaitingAi,
    loadingDataAndTemplates,
    referralLoading,
    recentTemplates,
    validationErrors,
    handleSubmitReferral,
    handleAiAutoGenerateReferral,
    handleAutogenConfig,
    handleTemplateSelection,
    handleReset,
    setAutogenConfigRequired,
    setAutogenConfig,
    setContent,
    setTemplateSelectionRequired,
    setDataTemplateFetchingError,
    setLoadingDataAndTemplates,
    fetchDatapointData,
    setIncludedDataKeys,
    isHistroical,
    setIsHistorical,
    applyRecentTemplate,
    validateReferralSubmission
  }
}

export default useReferral
