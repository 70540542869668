import dayjs from 'dayjs'
import Store from '../../../store'
import { deviceDetect } from 'react-device-detect'
import coreReducer from '../../../core/utils/reducer'
import NewBooking from '../components/AvailabilitySearchWrapper'
import CoreEnums from '../../../core/utils/enums'
import { readAllBookings } from '../../Admin/bookings/utils/utils'
import VirConsultWraper from '../../Patient/Virtual Consultation'
// ================================================
// BEGIN PATIENT EXAMINATION COMPONENTS FUNCTIONS
// ================================================
export const getMainTitle = (status) => {
  switch (status) {
    case CoreEnums.bookingStatuses.screening:
      return 'Screening'
    case CoreEnums.bookingStatuses.diagnosis:
      return 'Diagnosis'
    case CoreEnums.bookingStatuses.billing:
      return 'Billing'
    default:
      return 'Patient Examination'
  }
}

export const getSecondaryTitle = (status) => {
  switch (status) {
    case CoreEnums.bookingStatuses.screening:
      return 'Screening Information'
    case CoreEnums.bookingStatuses.diagnosis:
      return 'Diagnosis Information'
    case CoreEnums.bookingStatuses.billing:
      return 'Billing Information'
    default:
      return 'Patient Information'
  }
}

export const isValidStage = (status) => {
  const State = Store.getState()
  const role = State.auth.agiliteUser.extraData.role.type

  if (role === 'medical_professional') {
    if (State.auth.agiliteUser.extraData.profession === 'nurse') {
      return (
        status === CoreEnums.bookingStatuses.screening ||
        status === CoreEnums.bookingStatuses.procedure ||
        status === CoreEnums.bookingStatuses.completed
      )
    } else {
      return status === CoreEnums.bookingStatuses.diagnosis || status === CoreEnums.bookingStatuses.completed
    }
  }
  if (role === 'reception') {
    return (
      status === CoreEnums.bookingStatuses.billing ||
      status === CoreEnums.bookingStatuses.completed ||
      status === CoreEnums.bookingStatuses.data_capture
    )
  }
  if (role === 'admin') {
    return true
  }
  return false
}

export const handleCalculateAge = (data) => {
  if (data.patientRecord?.dateOfBirth)
    return Number(dayjs(new Date()).format('YYYY')) - Number(dayjs(data.patientRecord.dateOfBirth).format('YYYY'))
}

export const handleValidateSave = (data, checkinData, bookingData, billing) => {
  if (!checkinData && !bookingData.isVirtualVisit) {
    return 'No checkin data has been recorded. Please revise.'
  }

  // Validate controlled fields
  // procedure
  if (bookingData.nurseProcedureRequired) {
    if (!bookingData.nurseProcedureInstructions) {
      return 'Please record instructions for the procedure.'
    }
  }

  // Blood pressure
  if (!bookingData.isVirtualVisit) {
    if (handleCalculateAge(data) >= 18) {
      if (checkinData.bloodPressure?.systolic || checkinData.bloodPressure?.diastolic) {
        if (!checkinData.bloodPressure?.systolic || !checkinData.bloodPressure?.diastolic) {
          return 'Please record patient blood pressure'
        }
        if (
          checkinData.bloodPressure.diastolic > checkinData.bloodPressure?.systolic ||
          checkinData.bloodPressure.diastolic > 300 ||
          checkinData.bloodPressure.systolic > 300 ||
          checkinData.bloodPressure.diastolic < 1 ||
          checkinData.bloodPressure.systolic < 1
        ) {
          return 'Blood pressure reading is invalid.'
        }
      }
    }

    if (checkinData.height?.value > 300 && checkinData.height?.applicable) {
      return 'Patient height has been captured incorrectly.'
    }

    if (checkinData.weight?.value > 300 && checkinData.weight?.applicable) {
      return 'Patient weight has been captured incorrectly.'
    }

    if (checkinData.temperature && (checkinData.temperature < 32 || checkinData.temperature > 45)) {
      return 'Patient`s temperature reading is not valid.'
    }
  }

  // Diagnosis
  if (data.status === CoreEnums.bookingStatuses.diagnosis) {
    if (!billing.diagnosis || billing.diagnosis.length === 0) {
      return 'No diagnosis has been recorded. Please revise.'
    }
  }
}

export const generateActions = (status) => {
  const State = Store.getState()
  if (
    (status === CoreEnums.bookingStatuses.billing || status === CoreEnums.bookingStatuses.data_capture) &&
    State.auth.agiliteUser.extraData.role.type === 'reception' &&
    status !== CoreEnums.bookingStatuses.completed
  ) {
    return true
  }

  if (State.auth.agiliteUser.extraData.role.type === 'medical_professional') {
    if (status === CoreEnums.bookingStatuses.screening && State.auth.agiliteUser.extraData.profession === 'nurse') {
      return true
    }

    if (status === CoreEnums.bookingStatuses.procedure && State.auth.agiliteUser.extraData.profession === 'nurse') {
      return true
    }

    if (
      (status === CoreEnums.bookingStatuses.diagnosis || status === CoreEnums.bookingStatuses.completed) &&
      State.auth.agiliteUser.extraData.profession === 'doctor'
    ) {
      return true
    }
  }
}

// ================================================
// BEGIN PATIENT JOURNEY COMPONENTS FUNCTIONS
// ================================================
export const handleConvertTime = (start, index, workflowHistory) => {
  const timeValue = Math.ceil(dayjs(start).diff(workflowHistory.history[index - 1].submissionDate, 'minutes', true))
  let tmpValue = null

  if (timeValue >= 60) {
    tmpValue = (timeValue / 60).toFixed(2)

    return handleConvertHourMinute(tmpValue)
  } else {
    return `${timeValue} minute(s)`
  }
}

export const handleConvertTotalTime = (value) => {
  let tmpValue = value

  if (value >= 60) {
    tmpValue = (value / 60).toFixed(2)

    if (tmpValue.includes('.')) {
      return handleConvertHourMinute(tmpValue)
    }
  } else {
    return `${value} minute(s)`
  }
}

export const handleConvertHourMinute = (value) => {
  let hourValue = null
  let minuteValue = null

  hourValue = value.split('.')[0] + ' hour(s) and '

  if (parseInt(value.split('.')[1]) !== 0) {
    minuteValue = Math.ceil((value.split('.')[1] / 100) * 60) + ' minute(s)'
  }

  if (hourValue && minuteValue) {
    return `${hourValue + minuteValue}`
  } else if (hourValue) {
    return hourValue
  }
}

// ================================================
// BEGIN BOOKING SEARCH ENGINE COMPONENTS FUNCTIONS
// ================================================
export const handleMobileNextDisabled = (mobileSteps, location, active) => {
  if (mobileSteps === 1) {
    if (location && location.address) {
      return false
    } else {
      return true
    }
  }

  if (mobileSteps === 2) {
    if (!active.timeslot || !active.key) {
      return true
    } else {
      return false
    }
  }
}

export const handleMobileView = (key, mobileSteps) => {
  if (!deviceDetect().isMobile) {
    return true
  }
  if (deviceDetect().isMobile) {
    // if (key === 'address' && mobileSteps === 1) {
    //   return true
    // }
    if (key === 'details' && mobileSteps === 1) {
      return true
    }
    if (key === 'results' && mobileSteps === 2) {
      return true
    }
  }
}

// Check if slot is already booked
export const isAlreadyBooked = (slot, upcomingBookings) => {
  let isBooked = false
  upcomingBookings.forEach((upcomingBooking) => {
    // Remove any slots that are equal to any existing booking start times
    if (slot === upcomingBooking[0]) {
      isBooked = true
    }

    // Remove any slots in between exist booking ranges
    if (slot > upcomingBooking[0] && slot < upcomingBooking[1]) {
      isBooked = true
    }
  })
  return isBooked
}

export const willOverlap = (slot, booking, upcomingBookings) => {
  const state = Store.getState()
  const tmpService =
    state.services.data.find((item) => item._id === booking.service) ||
    state.virtualServices.data.find((item) => item._id === booking.service)
  // Ceil incase of 10 min or even 5 min slots returning decimals (potentially?)
  const slotCount = Math.ceil(tmpService.timeslotInterval / 15)

  let overlaps = false

  // if a medical professional already has a booking for 11:00 for a service that is longer than 15 min say 45min then
  // from 10:30 to 11 must also be unavailable because if these slots were booked they would overlap with the existing time slot (e.g 10:30 - 11:15 or 10:45 - 11:30 )
  let newTime = dayjs(slot, 'HH:mm')
    .add(15 * slotCount, 'minutes')
    .format('HH:mm')

  // Here im checking for each of the existing bookings whether the current slot if booked run past and overlap
  upcomingBookings.forEach((upcomingBooking) => {
    // Check if new time falls anywhere between the start - finish range
    if (newTime > upcomingBooking[0] && newTime < upcomingBooking[1]) {
      overlaps = true
    }

    // had to add this check for slots that fall before an existing slots start time but would only end after or on the
    // existing slots end time (meaning they still overlap but not caught by previous function)
    if (slot < upcomingBooking[0] && newTime >= upcomingBooking[1]) {
      overlaps = true
    }
  })
  return overlaps
}

export const handleClinicBooking = (isReceptionBooking, dispatcher, action) => {
  dispatcher(
    coreReducer.actions.addTab({
      key: `${CoreEnums.tabKeys.MY_BOOKINGS}_new_clinic_booking`,
      closable: true,
      label: 'New Clinic Booking',
      children: <NewBooking action={action} isReceptionBooking={isReceptionBooking} />
    })
  )
}
export const handleHomeVisit = (dispatcher) => {
  dispatcher(
    coreReducer.actions.addTab({
      key: `${CoreEnums.tabKeys.MY_BOOKINGS}_new_home_visit`,
      closable: true,
      label: 'New Home Visit',
      children: <NewBooking isHomeVisit={true} />
    })
  )
}

export const handleVirtualConsult = (isReceptionBooking, dispatcher) => {
  if (deviceDetect().isMobile) {
    dispatcher(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.MY_BOOKINGS}_new_virtual_consult_mobile`,
        closable: true,
        label: 'Virtual Consultation',
        children: <VirConsultWraper /> // Use the wrapper
      })
    )
  } else {
    // Desktop flow remains unchanged
    dispatcher(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.MY_BOOKINGS}_new_virtual_consult_booking`,
        closable: true,
        label: 'New Virtual Consultation',
        children: <NewBooking isReceptionBooking={isReceptionBooking} isVirtualVisit={true} />
      })
    )
  }
}

export const handleVerifyAvailability = (bookingDetails, setLoading) => {
  return new Promise((resolve, reject) => {
    setLoading(true)
    let qry = {}
    let slot = bookingDetails?.startTime
    let upcomingBookings = []

    ;(async () => {
      try {
        qry.bookingDate = dayjs(bookingDetails.bookingDate).format('YYYY-MM-DD')
        qry.medicalProfRef = bookingDetails.medicalProf._id
        qry.$nor = [{ status: CoreEnums.bookingStatuses.completed }, { status: CoreEnums.bookingStatuses.cancelled }]
        let existingBookings = await readAllBookings(qry)
        existingBookings.forEach((booking) => {
          upcomingBookings.push([booking.startTime, booking.endTime])
        })
        if (!isAlreadyBooked(slot, upcomingBookings) && !willOverlap(slot, bookingDetails, upcomingBookings)) {
          resolve()
        } else {
          throw new Error('Slot is no longer available. Please select another slot.')
        }
      } catch (e) {
        reject(e)
      }

      setLoading(false)
    })()
  })
}
