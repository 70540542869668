import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Space } from 'antd'
import dayjs from 'dayjs'

const DatePickerModal = ({ form, key, initialValue }) => {
  const [yearValue, setYearValue] = useState('')
  const [monthValue, setMonthValue] = useState('')
  const [dayValue, setDayValue] = useState('')
  const [valueControl, setValueControl] = useState(initialValue)

  useEffect(() => {
    if (initialValue) {
      setYearValue(dayjs(initialValue).format('YYYY'))
      setMonthValue(dayjs(initialValue).format('MM'))
      setDayValue(dayjs(initialValue).format('DD'))
    }
    form.setFieldValue('dateOfBirth', valueControl)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    form.setFieldValue('dateOfBirth', valueControl)
    // eslint-disable-next-line
  }, [valueControl])

  const disabledDate = (current) => {
    return current > dayjs().startOf('day')
  }

  const handleGenerateMonthValue = () => {
    if (yearValue && !monthValue) {
      return dayjs(`${yearValue}-01-01`)
    } else if (yearValue && monthValue && dayValue) {
      return dayjs(`${yearValue}-${monthValue}`)
    }
  }

  const handleGenerateDayValue = () => {
    if (valueControl) {
      return dayjs(valueControl)
    } else {
      if (yearValue && monthValue && !dayValue) {
        return dayjs(`${yearValue}-${monthValue}-01`)
      } else if (yearValue && monthValue && dayValue) {
        return dayjs(`${yearValue}-${monthValue}-${dayValue}`)
      }
    }
  }

  return (
    <Space>
      {yearValue ? undefined : (
        <DatePicker.YearPicker
          onChange={(value) => {
            setYearValue(dayjs(value).format('YYYY'))
            setValueControl(dayjs(new Date(value)).format('YYYY-MM-DD'))
            document.getElementById('month-picker').click()
          }}
          disabledDate={disabledDate}
          autoFocus={true}
          style={{ display: yearValue ? 'none' : 'block' }}
          allowClear={false}
          inputReadOnly={true}
        />
      )}
      <DatePicker.MonthPicker
        id='month-picker'
        onChange={(value) => {
          setMonthValue(dayjs(value).format('MM'))
          setValueControl(dayjs(new Date(value)).format('YYYY-MM-DD'))
          document.getElementById('day-picker').click()
        }}
        disabledDate={disabledDate}
        value={handleGenerateMonthValue()}
        autoFocus={true}
        style={{ display: yearValue && !monthValue ? 'block' : 'none' }}
        allowClear={false}
        inputReadOnly={true}
      />
      <DatePicker
        id='day-picker'
        onChange={(value) => {
          setDayValue(dayjs(value).format('DD'))
          setValueControl(dayjs(new Date(value)).format('YYYY-MM-DD'))
        }}
        value={handleGenerateDayValue()}
        format='YYYY-MM-DD'
        autoFocus={true}
        disabledDate={disabledDate}
        style={{ display: yearValue && monthValue ? 'block' : 'none' }}
        clearIcon={null}
        inputReadOnly={true}
        allowClear={false}
      />
      <Button
        onClick={() => {
          setYearValue('')
          setMonthValue('')
          setDayValue('')
          form.setFieldValue('dateOfBirth', '')
        }}
      >
        Reset
      </Button>
    </Space>
  )
}

export default DatePickerModal
