import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const columnTemplate = (token, handleDelete) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Is Active',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '10%',
      render: (text, record) => {
        return text ? <center>Yes</center> : <center>No</center>
      }
    },
    {
      title: 'Actions',
      width: '5%',
      render: (text, record) => {
        return (
          <FontAwesomeIcon
            onClick={(e) => {
              e.stopPropagation()
              handleDelete(record)
            }}
            icon={faTrash}
            color={token.colorError}
          />
        )
      }
    }
  ]
}

export const dataTemplate = () => {
  return {
    name: '',
    isActive: true,
    keys: []
  }
}
