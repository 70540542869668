// src/components/Detection/BTDetection.js
import React, { useContext, useEffect, useState, useRef } from 'react'
import BluetoothContext from '../../BluetoothContext'
import CustomButton from '../../../../reusable-components/CustomButton'
import { Button, Col, message, Space, Spin, theme } from 'antd'
import CustomRow from '../../../../reusable-components/CustomRow'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { createClinicDataRecords } from '../../../../Examination/examination-utils/examination-lib'

const Detection = {
  BT: 'Blood Temperature'
}

const BTDetection = ({ setBusy, userRef, bookingRef }) => {
  const authState = useSelector((state) => state.auth)
  const { engine, bleCommandCharacteristic } = useContext(BluetoothContext)
  const [temperature, setTemperature] = useState(null)
  const [error, setError] = useState(null)
  const [isDetecting, setIsDetecting] = useState(false)
  const [saving, setSaving] = useState(false)
  // useRef for writeQueue to maintain state across renders
  const writeQueue = useRef(Promise.resolve())

  useEffect(() => {
    setBusy(isDetecting)
  }, [isDetecting, setBusy])

  useEffect(() => {
    console.log(
      'BTDetection: useEffect called. engine:',
      engine,
      'bleCommandCharacteristic:',
      bleCommandCharacteristic,
      'isDetecting:',
      isDetecting
    )

    if (!engine || !bleCommandCharacteristic) {
      console.warn('BTDetection: Engine or bleCommandCharacteristic not available for BTDetection')
      return
    }

    if (isDetecting) {
      console.log('BTDetection: Registering BT Listener')
      if (typeof engine.registBTListener === 'function') {
        engine.registBTListener({
          onProcess: (type, data) => {
            console.log('BTDetection: onProcess:', { type, data })
            if (bleCommandCharacteristic) {
              // Chain write operations to prevent overlapping
              writeQueue.current = writeQueue.current
                .then(() => bleCommandCharacteristic.writeValue(data))
                .catch((err) => {
                  console.error('BTDetection: Error writing to bleCommandCharacteristic:', err)
                })
            }

            // Handle interim data if available
            // Assuming 'data' contains interim temperature readings
            // Adjust based on actual data structure from hc03lib
            if (type === 'interim') {
              const { temp } = data
              if (temp !== undefined) {
                setTemperature(temp.toFixed(2)) // Update interim temperature if needed
              }
            }
          },
          onResult: (result) => {
            console.log('BTDetection: onResult:', result)
            if (result && result !== undefined) {
              setTemperature(result.toFixed(2))
              setError(null) // Clear any previous errors
            } else {
              console.warn('BTDetection: Invalid BT result received:', result)
              setError('Invalid result received from device.')
            }

            // Stop detection after receiving final result
            if (engine && typeof engine.stopDetect === 'function') {
              engine.stopDetect(Detection.BT)
            }
            setIsDetecting(false)
          },
          onException: (err) => {
            console.error('BTDetection: Detection Error:', err)
            setError(`BT Detection Error: ${err.message || 'Unknown error'}`)
            // Stop detection after exception
            if (engine && typeof engine.stopDetect === 'function') {
              engine.stopDetect(Detection.BT)
            }
            setIsDetecting(false)
          }
        })

        // Start detection **after** listener is registered
        console.log('BTDetection: Starting Temperature Detection via engine.startDetect')
        engine.startDetect(Detection.BT)
      } else {
        console.error('BTDetection: engine.registBTListener is not a function')
      }
    }

    return () => {
      console.log('BTDetection: Unregistering BT Listener')
      if (engine && typeof engine.unregistBTListener === 'function') {
        engine.unregistBTListener()
      } else {
        console.warn('BTDetection: engine.unregistBTListener is not a function')
      }
    }
  }, [engine, bleCommandCharacteristic, isDetecting])

  // Define startDetection function
  const startDetection = () => {
    if (isDetecting) {
      console.warn('BTDetection: Detection is already running')
      return
    }
    console.log('BTDetection: Initiating Temperature Detection')
    if (engine && bleCommandCharacteristic) {
      setError(null)
      setTemperature(null)
      setIsDetecting(true)
      // Removed engine.startDetect from here
    } else {
      console.warn('BTDetection: Engine or bleCommandCharacteristic is not available')
      setError('Device is not ready. Please wait and try again.')
    }
  }

  // Define stopDetection function
  const stopDetection = () => {
    console.log('BTDetection: Stopping Temperature Detection')
    if (engine && bleCommandCharacteristic) {
      if (typeof engine.stopDetect === 'function') {
        engine.stopDetect(Detection.BT)
      } else {
        console.warn('BTDetection: engine.stopDetect is not a function')
      }
      setIsDetecting(false)
    } else {
      console.warn('BTDetection: Engine or bleCommandCharacteristic is not available')
    }
  }

  const { token } = theme.useToken()

  const handleConfirm = async () => {
    // Filter selected readings
    setSaving(true)
    console.log(temperature)
    try {
      await createClinicDataRecords({
        dateCreated: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        createdBy: authState.agiliteUser._id,
        userRef: userRef,
        bookingRef: bookingRef ? bookingRef : null,
        origin: 'linktop',
        category: 'vitals',
        key: 'temperature',
        value: temperature
      })
      setTemperature(null)
      message.success('Selected readings have been successfully sent to the database.')
    } catch (error) {
      console.error('Error confirming readings:', error)
      message.error('Failed to send readings to the database. Please try again.')
    }
    setSaving(false)
  }

  return (
    <>
      {!engine || !bleCommandCharacteristic ? (
        <p>Device is not ready. Please wait...</p>
      ) : (
        <CustomRow>
          <Col span={24}>{error && <p style={{ color: 'red' }}>{error}</p>}</Col>
          <Col span={24}>
            <p>
              Temperature: {isDetecting ? <Spin size='small' /> : temperature !== null ? `${temperature} °C` : '--'}
            </p>
          </Col>
          <Col span={24}>
            <Space>
              <CustomButton type='primary' onClick={startDetection} text='Start' disabled={isDetecting} size='small' />
              <CustomButton
                text='Stop'
                type='primary'
                style={{ background: !isDetecting ? '' : token.colorError }}
                onClick={stopDetection}
                disabled={!isDetecting}
                size='small'
              />
              <Button type='primary' block disabled={saving || !temperature} onClick={handleConfirm} loading={saving}>
                Save Results
              </Button>
            </Space>
          </Col>
        </CustomRow>
      )}
    </>
  )
}

export default BTDetection
