import { useState, useEffect, useCallback } from 'react'
import { medAdminTypes } from '../../../utils/templates'
import { ProductSearchAgilite } from '../../../utils/utils'
import { message } from 'antd'
import { handleError } from '../../../../lib/utils'

// Create a global cache to store the medications data
if (!window.medicationsCache) {
  window.medicationsCache = null
  window.isFetchingMedications = false
}

export const useSearch = () => {
  const [searchFilter, setSearchFilter] = useState('')
  const [nappiData, setNappiData] = useState([])
  const [loading, setLoading] = useState(false)
  const [medIndex, setMedIndex] = useState(window.medicationsCache || [])

  // Load medications on mount, but only if not already cached
  useEffect(() => {
    const loadMedications = async () => {
      if (!window.medicationsCache && !window.isFetchingMedications) {
        await preloadMedications()
      } else if (window.medicationsCache) {
        // Use cached data if available
        setMedIndex(window.medicationsCache)
      }
    }
    loadMedications()
  }, [])

  const preloadMedications = async () => {
    // Set global flag to prevent multiple simultaneous fetches
    window.isFetchingMedications = true
    setLoading(true)

    try {
      const today = new Date().toISOString().split('T')[0] // Get current date in YYYY-MM-DD format
      const cachedData = localStorage.getItem('medicationsCache')
      const cachedDate = localStorage.getItem('medicationsCacheDate')
      console.log('Cached data', cachedData)
      // Fetch if cache is empty or it's a new day
      if (!cachedData || cachedDate !== today) {
        console.log('Fetching medications')
        const tmpData = await ProductSearchAgilite(null, 'medicine', true)

        if (Array.isArray(tmpData) && tmpData.length > 0) {
          // Store in localStorage with date
          localStorage.setItem('medicationsCache', JSON.stringify(tmpData))
          localStorage.setItem('medicationsCacheDate', today)
          // Also store in window for immediate use
          window.medicationsCache = tmpData
          setMedIndex(tmpData)
        } else {
          console.error('Empty or invalid medications data received from API:', tmpData)
          message.error('Failed to load medications data. Please try again.')
        }
      } else {
        // Use cached data from localStorage
        const parsedData = JSON.parse(cachedData)
        window.medicationsCache = parsedData
        setMedIndex(parsedData)
      }
    } catch (e) {
      console.error('Error fetching medications:', e)
      message.error(handleError(e, true))
      // Reset flag on error
      window.isFetchingMedications = false
    } finally {
      setLoading(false)
      // Reset flag when done
      window.isFetchingMedications = false
    }
  }

  // Filter medications locally
  const filterMedIndex = useCallback(
    (searchQuery) => {
      if (!searchQuery || searchQuery.trim() === '') {
        setNappiData([])
        return
      }
      console.log('Window cache', window.medicationsCache)
      if (!medIndex || !Array.isArray(medIndex) || medIndex.length === 0) {
        // If medIndex is empty, try to load it
        if (!window.medicationsCache && !window.isFetchingMedications) {
          preloadMedications()
        }
        return
      }

      const searchLower = searchQuery.toLowerCase().trim()
      try {
        const filtered = medIndex
          .filter((med) => {
            if (!med) return false

            // Support both data structures (med.data and direct med object)
            const medData = med.data || med

            // Safely access properties with default empty strings
            const name = (medData.name || '').toLowerCase()
            const code = (medData.code || '').toLowerCase()
            const nappiCode = (medData.nappiCode || '').toLowerCase()

            const matches = name.includes(searchLower) || code.includes(searchLower) || nappiCode.includes(searchLower)

            return matches
          })
          .map((entry) => {
            if (entry.data) {
              return {
                type: entry.type,
                ...entry.data
              }
            }
            return entry
          })

        setNappiData(filtered)
      } catch (error) {
        console.error('Error during medication filtering:', error)
        // Even with an error, try to set some default results
        setNappiData([])
      }
    },
    [medIndex]
  )

  // Update filtered results when search changes
  useEffect(() => {
    filterMedIndex(searchFilter)
  }, [searchFilter, filterMedIndex])

  const getNappiOptions = useCallback(() => {
    let dataArray = []

    try {
      dataArray = nappiData.map((med) => {
        // Ensure we're using the correct data structure
        const medData = med.data || med

        return {
          search: `${medData.name || ''} ${medData.strengthMetric1 || ''} ${medData.unitOfMeasure1 || ''} ${
            medData.presentationCode || ''
          } - ${medData.standardPacksize || ''}`,
          label: (
            <>
              {medAdminTypes.find((i) => i.key === medData.presentationCode)?.icon} {medData.name || ''}{' '}
              {medData.strengthMetric1 || ''} {medData.unitOfMeasure1 || ''} {medData.presentationCode || ''} -{' '}
              {medData.standardPacksize || ''}
            </>
          ),
          value: JSON.stringify({
            code: medData.code,
            name: medData.name
          })
        }
      })

      if (searchFilter && searchFilter.trim() && dataArray.length === 0) {
        dataArray = [
          {
            search: searchFilter,
            label: searchFilter,
            value: JSON.stringify({
              name: searchFilter,
              code: searchFilter
            })
          }
        ]
      }
    } catch (error) {
      console.error('Error generating NAPPI options:', error)
      // Default to at least having the search term as an option on error
      if (searchFilter && searchFilter.trim()) {
        dataArray = [
          {
            search: searchFilter,
            label: searchFilter,
            value: JSON.stringify({
              name: searchFilter,
              code: searchFilter
            })
          }
        ]
      }
    }

    return dataArray
  }, [nappiData, searchFilter])

  return {
    searchFilter,
    setSearchFilter,
    nappiOptions: getNappiOptions(),
    loading,
    preloadMedications // Expose this function to force reload if needed
  }
}
