import React from 'react'
import MedicalHistoryForm from '../../Medical Vault/medical-history/components/medical-history-form'
import { Button, Col, Modal, Row, Space, message, theme } from 'antd'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../core/utils/reducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { handleError } from '../../lib/utils'
import { updateSubscription } from '../utils/utils'

const AdminSubscriptionsForm = ({ loading, record, handleGetSubscriptions }) => {
  const dispatch = useDispatch()

  const handleCloseRecord = () => {
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: `${record._id}-tab`,
        removeBreadcrumb: true
      })
    )
  }

  const handleVerifyAccount = () => {
    Modal.confirm({
      title: 'Verify Account',
      content: 'Are you sure you want to verify this account?',
      okText: 'Yes',
      cancelText: 'No',
      okButtonProps: {
        style: {
          backgroundColor: token.colorSuccess
        }
      },
      cancelButtonProps: {
        danger: true
      },
      onOk: async () => {
        try {
          await updateSubscription(record.subscription._id, { verified: true })
          handleGetSubscriptions()
          handleCloseRecord()
        } catch (e) {
          message.error(handleError(e, true))
        }
      }
    })
  }

  const { token } = theme.useToken()

  return (
    <Row justify='center'>
      <Col span={23}>
        <ContainerCard prevDisabled={false} backFunction={() => handleCloseRecord()} title={`${record.idNo}`}>
          {!record.subscription.verified ? (
            <Row justify='center' style={{ margin: '10px 0 10px 0' }}>
              <Col>
                <Button onClick={handleVerifyAccount} type='primary' style={{ backgroundColor: token.colorSuccess }}>
                  <Space>
                    <FontAwesomeIcon icon={faCheckCircle} /> Verify Account
                  </Space>
                </Button>
              </Col>
            </Row>
          ) : undefined}
          <MedicalHistoryForm
            editButton={false}
            setActiveTab={() => {}}
            setHasConfirmedMedicalHistory={() => {}}
            wrapperLoad={loading}
            summary={true}
            setSummary={() => {}}
            setNotSaved={null}
            setHandleSubmit={null}
            data={record.medical.medicalHistory}
            userRef={record._id}
            isPreviousBooking={false}
            isNurseExamination={false}
          />
        </ContainerCard>
      </Col>
    </Row>
  )
}

export default AdminSubscriptionsForm
