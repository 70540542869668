import React, { memo, useState } from 'react'
import { Select } from 'antd'
import { TASK_TYPE_CONFIG } from '../config/taskTypes'
import { STATUS } from '../config/taskStatus'
import { STAFF_MEMBERS, CURRENT_USER } from '../config/constants'
import styles from '../styles/TaskFilters.module.css'
import { useSelector } from 'react-redux'

const { Option } = Select

const TaskFilters = ({ filters, onFilterChange, medicalProfs }) => {
  const authState = useSelector((state) => state.auth)
  const getRole = (staff) => {
    switch (staff.extraData.role.type) {
      case 'medical_professional':
        if (staff.extraData.profession === 'nurse') {
          return 'Nurse'
        } else if (staff.extraData.profession === 'doctor') {
          return 'Doctor'
        }
        break
      case 'reception':
        return 'Receptionist'
      case 'admin':
        return 'Admin'
      case 'super_admin':
        return 'Super Admin'
      default:
        return 'Unknown'
    }
  }

  const [searchText, setSearchText] = useState('')
  return (
    <div className={styles.filterBar}>
      <Select
        value={filters.assignee}
        onChange={(value) => onFilterChange('assignee', value)}
        className={styles.filterSelect}
        placeholder='Assigned To'
        showSearch
        onSelect={() => setSearchText('')}
        onSearch={(value) => setSearchText(value)}
        searchValue={searchText}
      >
        <Option value='all'>All Staff</Option>
        <Option value='me'>Assigned to Me</Option>
        {medicalProfs
          .filter(
            (staff) =>
              staff._id !== authState.agiliteUser._id &&
              ((staff.firstName + ' ' + staff.lastName).toLowerCase().includes(searchText.toLowerCase()) ||
                getRole(staff).toLowerCase().includes(searchText.toLowerCase()))
          )
          .map((staff) => (
            <Option key={staff._id} value={staff._id}>
              {staff.firstName} {staff.lastName} ({getRole(staff)})
            </Option>
          ))}
      </Select>

      <Select
        value={filters.taskType}
        onChange={(value) => onFilterChange('taskType', value)}
        className={styles.filterSelect}
        placeholder='Task Type'
      >
        <Option value='all'>All Types</Option>
        {Object.entries(TASK_TYPE_CONFIG).map(([key, config]) => (
          <Option key={key} value={key}>
            {config.icon} {config.label}
          </Option>
        ))}
      </Select>
      {/* 
      <Select
        value={filters.status}
        onChange={(value) => onFilterChange('status', value)}
        className={styles.filterSelect}
        placeholder='Status'
      >
        <Option value='all'>All Status</Option>
        <Option value={STATUS.ACTIVE}>Active</Option>
        <Option value={STATUS.COMPLETED}>Completed</Option>
        <Option value={STATUS.OVERDUE}>Overdue</Option>
      </Select> */}
    </div>
  )
}

export default memo(TaskFilters)
