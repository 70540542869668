import { Button, Card, Col, DatePicker, Modal, Row, Space, Table, theme, Upload } from 'antd'
import React from 'react'
import { hexToRGBA } from '../../../lib/utils'
import CustomRow from '../../../reusable-components/CustomRow'
import { DataComplianceTableTemplates, VisitComplianceTableTemplates } from './utils/templates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { InboxOutlined } from '@ant-design/icons'
import usePatientOverview from './hooks/usePatientOverview'
import { DocumentViewer } from '../../../reusable-components/DocumentViewer'
import ModalHeader from '../../../reusable-components/ModalHeader'

const PatientCarePlanOverview = ({ currentPatient, setCurrentPatient, handleRefresh }) => {
  const { token } = theme.useToken()
  const {
    handleSaveVisit,
    handleFileUpload,
    addingVisit,
    setAddingVisit,
    newVisitDate,
    setNewVisitDate,
    uploadedFiles,
    pushingNewVisit,
    setUploadedFiles,
    viewingPDF,
    setViewingPDF
  } = usePatientOverview({ currentPatient, handleRefresh })
  return (
    <CustomRow>
      <Col span={24}>
        <FontAwesomeIcon icon={faExclamationCircle} color={token.colorWarning} /> Please note that compliance may take
        up to 24 hours to reflect from the time of submission
      </Col>
      <Col span={24}>
        <Card headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }} size='small' title='Test Compliance'>
          <Table
            size='small'
            bordered
            columns={DataComplianceTableTemplates(token).columnTemplate}
            dataSource={currentPatient.individualTestCompliances}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <Row style={{ background: 'white', padding: 8 }}>
                    <Col span={12}>
                      <div>
                        <Space direction='vertical' style={{ width: '100%' }}>
                          <Space>
                            <p style={{ width: 110 }}>
                              <b>Test Type:</b>
                            </p>
                            <p>{record.label}</p>
                          </Space>
                          <Space>
                            <p style={{ width: 110 }}>
                              <b>Compliance:</b>
                            </p>
                            <p>
                              <span style={{ color: record.isCompliant ? token.colorSuccess : token.colorError }}>
                                {record.isCompliant ? 'Compliant' : 'Non-Compliant'}
                              </span>
                            </p>
                          </Space>
                          <Space>
                            <p style={{ width: 110 }}>
                              <b>Tests Done:</b>
                            </p>
                            <p>
                              {record.testsCompleted} of {record.requiredTests} required
                            </p>
                          </Space>
                          <Space>
                            <p style={{ width: 110 }}>
                              <b>Outstanding:</b>
                            </p>
                            <p>{record.outstandingTests}</p>
                          </Space>
                        </Space>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div>
                        <Space direction='vertical' style={{ width: '100%' }}>
                          <Space style={{ alignItems: 'baseline' }}>
                            <p style={{ width: 110 }}>
                              <b>Test Dates:</b>
                            </p>
                            <Space direction='vertical'>
                              {record.testDates.map((date, i) => (
                                <p key={i}>{dayjs(date).format('DD/MM/YYYY')}</p>
                              ))}
                            </Space>
                          </Space>
                        </Space>
                      </div>
                    </Col>
                  </Row>
                )
              }
            }}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card headStyle={{ background: hexToRGBA(token.colorPrimary, 0.2) }} size='small' title='Visit Compliance'>
          <Table
            size='small'
            bordered
            columns={
              VisitComplianceTableTemplates(token, false, setAddingVisit, currentPatient, setViewingPDF).columnTemplate
            }
            dataSource={currentPatient.individualVisitCompliances}
            onRow={() => ({
              style: {
                verticalAlign: 'baseline'
              }
            })}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Button
          style={{ float: 'right' }}
          onClick={() => {
            setCurrentPatient(null)
          }}
        >
          Back
        </Button>
      </Col>
      <Modal
        style={{ padding: 0 }}
        closable={false}
        maskClosable={false}
        footer={false}
        open={viewingPDF}
        onCancel={() => setViewingPDF(false)}
        width={1920}
      >
        <ModalHeader title='PDF Viewer' handleReset={() => setViewingPDF(false)} />
        <DocumentViewer base64={viewingPDF} />
      </Modal>
      <Modal
        okButtonProps={{ loading: pushingNewVisit }}
        open={addingVisit}
        onOk={handleSaveVisit}
        onCancel={() => {
          setAddingVisit(false)
          setUploadedFiles([])
          setNewVisitDate(null)
        }}
        cancelButtonProps={{ disabled: pushingNewVisit }}
      >
        <p>Add Visit: {addingVisit?.label}</p>
        <p>Please select a date for when the record was consulted.</p>
        <DatePicker value={newVisitDate} onChange={(date) => setNewVisitDate(date)} />
        <p>Please provide any PDF reports if applicable</p>
        <Upload.Dragger {...handleFileUpload} fileList={uploadedFiles} multiple={true}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>Click or drag PDF files to this area to upload</p>
        </Upload.Dragger>
      </Modal>
    </CustomRow>
  )
}

export default PatientCarePlanOverview
