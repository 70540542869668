import React from 'react'
import MedicalHistoryFormTemplates from '../../utils/templates'
import { generateFormItem } from '../../../../lib/utils'
import CustomRow from '../../../../reusable-components/CustomRow'
import { Col } from 'antd'

const MedHistLifestyle = ({ clientForm }) => {
  return (
    <>
      <CustomRow className='basic-card'>
        <Col span={24}>
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.diet, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.physicalActivity, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.sleepHours, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.waterIntake, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.screenTime, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.stress, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.alcoholConsumption, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.alcoholConsumptionLevel, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.smoking, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.smokingLevel, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.smokingYears, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.vaping, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.vapingLevel, clientForm)}
          {generateFormItem(MedicalHistoryFormTemplates.dataModel.vapingYears, clientForm)}
        </Col>
      </CustomRow>
    </>
  )
}

export default MedHistLifestyle
