import { Form, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { readServices } from '../../Admin/services/utils/utils'
import { readBookings, updateBooking } from '../../Bookings/utils/utils'

import CheckinQuestionarre from './checkin-questionnarre'
import CheckinSuccess from './checkin-success'
import { readConfig } from '../../Admin/config/utils/utils'
import { handleError } from '../../lib/utils'
import { executeBooking, getBookingState } from '../../Bookings/utils/bpm-utils'
import CheckinWelcome from './checkin-welcome'
import CheckinOTPCapture from './checkin-otp-capture'

import MetaLogo from '../../../assets/images/MH-logo-v1 1.png'
import VirtualCheckin from './virtual-checkin'
import { readClinics } from '../../Admin/clinics/utils/utils'
import CoreEnums from '../../../core/utils/enums'
import { useSelector } from 'react-redux'

const Checkin = ({ view, setView }) => {
  const entityState = useSelector((state) => state.core.entity)
  const [checkinConfig, setCheckinConfig] = useState()
  const [services, setServices] = useState()
  const [bookingService, setBookingService] = useState()
  const [bookingDetails, setBookingDetails] = useState()
  const [loading, setLoading] = useState()
  const [otp, setOtp] = useState(null)
  const [currentSelection, setCurrentSelection] = useState()
  const [patientSelection, setPatientSelection] = useState([])
  const [patientInput, setPatientInput] = useState()
  const [bookingId, setBookingId] = useState()
  const [clinicData, setClinicData] = useState()
  const [configLoading, setConfigLoading] = useState(false)

  const [clientForm] = Form.useForm()

  useEffect(() => {
    getCheckinConfig()
    // eslint-disable-next-line
  }, [entityState])

  const getCheckinConfig = async () => {
    let tmpConfig = {}
    setConfigLoading(true)
    if (entityState) {
      try {
        tmpConfig = await readConfig()
        setCheckinConfig(tmpConfig[0].checkin)
      } catch (e) {
        message.error(handleError(e, true))
      }
      setConfigLoading(false)
    }
  }

  const getServices = async () => {
    let tmpServices = await readServices({ isActive: true })
    setServices(tmpServices)
  }

  useEffect(() => {
    if (bookingService) {
      setCurrentSelection(bookingService[0])
    }
  }, [bookingService])

  useEffect(() => {
    getServices()
  }, [])

  useEffect(() => {
    setPatientSelection([])
    setPatientInput(null)
  }, [currentSelection])

  useEffect(() => {
    handleGetClinic()
    // eslint-disable-next-line
  }, [bookingDetails])

  const handleGetClinic = async () => {
    let tmpClinics = []

    try {
      if (bookingDetails) {
        tmpClinics = await readClinics({ _id: bookingDetails.clinicRef })
      }

      if (tmpClinics.length > 0) {
        setClinicData(tmpClinics[0])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleVerify = async () => {
    setLoading(true)

    try {
      const bookings = await readBookings({
        otp: otp,
        status: CoreEnums.bookingStatuses.checkin,
        isVirtualVisit: false
      })
      const virtualVisits = await readBookings({
        otp: otp,
        isVirtualVisit: true,
        status: { $ne: CoreEnums.bookingStatuses.completed }
      })

      let tmpService = null

      if (virtualVisits.length === 0 && bookings.length === 0) {
        throw new Error('Invalid One-time Pin. Please revise')
      }

      if (virtualVisits[0]) {
        tmpService = services.find((item) => item._id === virtualVisits[0].service)
        setBookingId(virtualVisits[0]._id)
        setBookingDetails(virtualVisits[0])
        tmpService.checkin.length > 0 ? setBookingService(tmpService.checkin) : setBookingService(checkinConfig)
        setView('virtual-checkin')
      }

      if (bookings[0]) {
        tmpService = services.find((item) => item._id === bookings[0].service)
        setBookingId(bookings[0]._id)
        setBookingDetails(bookings[0])
        tmpService.checkin.length > 0 ? setBookingService(tmpService.checkin) : setBookingService(checkinConfig)
        setView('questions')
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleSubmit = async () => {
    let payload = {}
    let processRecord = null
    let currentUser = 'system'
    setLoading(true)

    try {
      payload.ailment = currentSelection.doctorsText

      if (patientSelection.length > 0) {
        payload.patientSelection = patientSelection
      }

      if (patientInput) {
        payload.patientInput = patientInput
      }

      payload = { checkinData: payload, status: { ...bookingDetails, checkedIn: true } }

      // BPM
      processRecord = await getBookingState([bookingDetails.processRef])

      if (
        processRecord.history[processRecord.history.length - 1].responsibleUsers[0] &&
        processRecord.history[processRecord.history.length - 1].responsibleUsers[0] !== ''
      ) {
        currentUser = processRecord.history[processRecord.history.length - 1].responsibleUsers[0]
      }

      processRecord = await executeBooking(
        clinicData?.bpmKey || 'booking_process',
        bookingDetails.processRef,
        'submit',
        currentUser,
        processRecord.key
      )

      await updateBooking({ ...payload, status: processRecord.processStage }, { _id: bookingId })

      message.success('Booking successful')
      setOtp('')
      setView('success')
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked ? [...patientSelection, tag] : patientSelection.filter((t) => t !== tag)

    clientForm.setFieldsValue({
      patientSelection: []
    })

    setPatientSelection(nextSelectedTags)
  }

  return (
    <>
      {view === 'otp' ? (
        <CheckinOTPCapture
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          checkinConfig={checkinConfig}
          services={services}
          handleVerify={handleVerify}
          setBookingService={setBookingService}
          MetaLogo={MetaLogo}
          setView={setView}
          setPatientInput={setPatientInput}
          setCurrentSelection={setCurrentSelection}
          setPatientSelection={setPatientSelection}
        />
      ) : view === 'virtual-checkin' ? (
        <VirtualCheckin
          bookingDetails={bookingDetails}
          MetaLogo={MetaLogo}
          setView={setView}
          setBookingId={setBookingId}
          setBookingService={setBookingService}
          setCurrentSelection={setCurrentSelection}
          setPatientInput={setPatientInput}
          setPatientSelection={setPatientSelection}
          setServices={setServices}
          setOtp={setOtp}
        />
      ) : view === 'questions' ? (
        <CheckinQuestionarre
          loading={loading}
          setBookingService={setBookingService}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          bookingService={bookingService}
          currentSelection={currentSelection}
          setCurrentSelection={setCurrentSelection}
          patientSelection={patientSelection}
          setPatientSelection={setPatientSelection}
          patientInput={patientInput}
          setPatientInput={setPatientInput}
          setOtp={setOtp}
          setView={setView}
        />
      ) : view === 'success' ? (
        <CheckinSuccess
          setView={setView}
          setBookingId={setBookingId}
          setBookingService={setBookingService}
          setCurrentSelection={setCurrentSelection}
          setPatientInput={setPatientInput}
          setPatientSelection={setPatientSelection}
          setServices={setServices}
          MetaLogo={MetaLogo}
        />
      ) : (
        <CheckinWelcome configLoading={configLoading} setView={setView} MetaLogo={MetaLogo} />
      )}
    </>
  )
}
export default Checkin
