import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { Col, Empty, Table, message } from 'antd'
import { locationTemplates } from './utils/templates'
import { handleError } from '../../lib/utils'
import { useSelector } from 'react-redux'

const InventoryActivity = ({ item, setLocation }) => {
  const clinicState = useSelector((state) => state.clinics.data)
  const [loading, setLoading] = useState(false)
  const [inventoryItemActivity, setInventoryItemActivity] = useState([])
  useEffect(() => {
    handleGetItemActivity()
    // eslint-disable-next-line
  }, [])

  const handleGetItemActivity = async () => {
    let tmpInventoryItemActivity = []
    setLoading(true)
    try {
      for (const clinic of clinicState) {
        const clinicActivity = []
        item.activity.forEach((i) => {
          if (i.locationRef === clinic._id) {
            clinicActivity.push(i)
          }
        })
        tmpInventoryItemActivity.push({
          clinic: clinic,
          activity: clinicActivity
        })
      }
      setInventoryItemActivity(tmpInventoryItemActivity)
    } catch (e) {
      message.error(handleError(e))
    }
    setLoading(false)
  }
  return (
    <CustomRow>
      <Col span={24}>
        <Table
          loading={loading}
          dataSource={inventoryItemActivity}
          locale={{
            emptyText: <Empty description='No Locations Found' />
          }}
          columns={locationTemplates.columnTemplate(setLocation)}
          scroll={{ x: 1000 }}
        />
      </Col>
    </CustomRow>
  )
}

export default InventoryActivity
