import { useSelector } from 'react-redux'
import { ContainerCard } from '../reusable-components/AgiliteCards'
import { Row } from 'antd'
import { deviceDetect } from 'react-device-detect'
import SubscriptionAdminDashboard from './Employee/subscription-admin-dashboard'
import PatDashboardWrapper from '../Patient/Dashboard'
import NewDoctorDashboard from './2025 New Dashboards/NewDoctorDashboard/NewDoctorDashboard'

const MetaClinicDashboard = () => {
  const state = useSelector((state) => state)
  const coreState = useSelector((state) => state.core)

  const checkRole = (role) => {
    switch (role) {
      case 'patient':
        return <PatDashboardWrapper />
      case 'admin':
        return <NewDoctorDashboard />
      case 'subscription_admin':
        return <SubscriptionAdminDashboard />
      case 'medical_professional':
        return <NewDoctorDashboard />
      default:
        return <NewDoctorDashboard />
    }
  }

  if (!state?.auth?.agiliteUser)
    return (
      <ContainerCard
        prevDisabled={true}
        title={deviceDetect().isMobile ? 'Dashboard' : `${coreState?.entity?.name} Dashboard`}
      >
        <Row justify='center' style={{ marginBottom: 100 }} gutter={[0, 20]}>
          Initialzing Portal...
        </Row>
      </ContainerCard>
    )
  else {
    return (
      <ContainerCard
        style={{ marginTop: 0, paddingTop: 12 }}
        prevDisabled={true}
        title={deviceDetect().isMobile ? 'Dashboard' : `${coreState?.entity.name} Dashboard`}
      >
        {checkRole(state?.auth?.agiliteUser?.extraData?.role?.type)}
      </ContainerCard>
    )
  }
}

export default MetaClinicDashboard
