import React from 'react'
import { Card, Col, Row } from 'antd'
import {
  faChartLine,
  faFileCirclePlus,
  faMailReply,
  faMoneyBillTransfer,
  faMoneyCheck,
  faMoneyCheckDollar,
  faUserCircle,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BillingDashboardImage from '../../../assets/images/billing-dasboard-background.jpg'
import CoreEnums from '../../../core/utils/enums'
import { handleDashboardCardClick } from '../dashboard-utils'

const BillingDashboard = () => {
  const cards = [
    { icon: faUserPlus, key: CoreEnums.tabKeys.PATIENTS, title: 'Add New Patient' },
    { icon: faFileCirclePlus, key: CoreEnums.tabKeys.INVOICES, title: 'Create New Invoice' },
    { icon: faMoneyBillTransfer, key: CoreEnums.tabKeys.INVOICES, title: 'Allocate Payment' },
    { icon: faMoneyCheck, key: CoreEnums.tabKeys.REMITTANCES, title: 'Create New Remittance' },
    { icon: faChartLine, key: CoreEnums.tabKeys.BILLING_REPORTS, title: 'Reports' },
    { icon: faMailReply, title: 'Latest Claim Responses' },
    { icon: faMoneyCheckDollar, title: 'Collections' },
    { icon: faUserCircle, title: 'Complete/View Profile' }
  ]

  return (
    <div
      style={{
        paddingTop: 20,
        backgroundImage: `url(${BillingDashboardImage})`, // Set the background image
        backgroundSize: 'cover', // Cover the entire area of the div
        backgroundRepeat: 'no-repeat', // Do not repeat the image
        backgroundPosition: 'center', // Center the image
        minHeight: '100vh' // Ensure the div takes up the full viewport height
      }}
    >
      <Row justify='space-around' gutter={[12, 24]} style={{ marginTop: 40 }}>
        {cards.map((card, index) => (
          <Col span={6} key={index}>
            <center>
              <div style={{ cursor: 'pointer' }}>
                <Card
                  hoverable
                  style={{
                    textAlign: 'center',
                    height: '150px',
                    borderRadius: '50%',
                    width: '150px',
                    backgroundColor: '#5D89B5'
                  }}
                  bordered={false}
                  onClick={() => handleDashboardCardClick(card.key)}
                >
                  <FontAwesomeIcon icon={card.icon} size='3x' color='#ddd' />
                  <p style={{ color: '#eee', marginTop: 5, fontSize: 14 }}>{card.title}</p>
                </Card>
              </div>
            </center>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default BillingDashboard
