import React, { createContext, useContext, useReducer, useCallback } from 'react'

const NotificationsContext = createContext()

const initialState = {
  notifications: [],
  unreadCount: 0
}

const ACTIONS = {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  MARK_AS_READ: 'MARK_AS_READ',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS'
}

const notificationsReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
        unreadCount: state.unreadCount + 1
      }
    case ACTIONS.MARK_AS_READ:
      return {
        ...state,
        notifications: state.notifications.map((notification) => ({
          ...notification,
          unread: false
        })),
        unreadCount: 0
      }
    case ACTIONS.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
        unreadCount: 0
      }
    default:
      return state
  }
}

export const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationsReducer, initialState)

  const addNotification = useCallback((notification) => {
    const newNotification = {
      id: Date.now(),
      timestamp: new Date().toISOString(),
      unread: true,
      ...notification
    }
    dispatch({ type: ACTIONS.ADD_NOTIFICATION, payload: newNotification })
  }, [])

  const markAsRead = useCallback(() => {
    dispatch({ type: ACTIONS.MARK_AS_READ })
  }, [])

  const clearNotifications = useCallback(() => {
    dispatch({ type: ACTIONS.CLEAR_NOTIFICATIONS })
  }, [])

  const value = {
    notifications: state.notifications,
    unreadCount: state.unreadCount,
    addNotification,
    markAsRead,
    clearNotifications
  }

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>
}

export const useNotifications = () => {
  const context = useContext(NotificationsContext)
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationsProvider')
  }
  return context
}
