import React from 'react'
import CarePlanManagement from './Management/CarePlansManagement'
import CustomRow from '../../reusable-components/CustomRow'
import { Col } from 'antd'
import { ContainerCard } from '../../reusable-components/AgiliteCards'

const CarePlansWrapper = ({ isMonitoring }) => {
  return (
    <ContainerCard>
      <CustomRow>
        <Col span={24}>
          <CarePlanManagement isMonitoring={isMonitoring} />
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default CarePlansWrapper
