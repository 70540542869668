import { message } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateLifestyle } from '../api/api'
import patMedHistReducer from '../utils/reducer'

const useLifestyle = ({ setFacet, setSaving }) => {
  const lifeStyleTemplate = {
    diet: '',
    physicalActivity: '',
    sleepHours: '',
    waterIntake: '',
    screenTime: '',
    stress: '',
    alcoholConsumption: false,
    alcoholConsumptionLevel: '',
    smoking: false,
    smokingLevel: '',
    smokingYears: '',
    vaping: false,
    vapingLevel: '',
    vapingYears: ''
  }
  const dispatch = useDispatch()
  const patMedHist = useSelector((state) => state.patMedHist.data)
  const lifestyle = useSelector((state) => state.patMedHist.data.lifestyle)
  const [lifestyleData, setLifestyleData] = useState({ ...lifeStyleTemplate, ...lifestyle })

  const handleValueChange = (key, value) => {
    setLifestyleData((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleSubmit = async () => {
    setSaving(true)
    try {
      const newRecord = await updateLifestyle(patMedHist._id, lifestyleData)
      dispatch(patMedHistReducer.actions.updateState(newRecord))
    } catch (error) {
      message.error('Failed to save lifestyle information')
    }
    setSaving(false)
  }

  return {
    handleSubmit,
    handleValueChange,
    lifestyleData,
    setLifestyleData
  }
}

export default useLifestyle
