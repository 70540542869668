import { Form, message, Modal, theme } from 'antd'
import { useEffect, useRef, useState, useCallback } from 'react'
import { createClinic, readBPMProfiles, updateClinic } from '../utils/utils'
import { sortBy } from 'lodash'
import { allTrim, handleError } from '../../../lib/utils'
import coreReducer from '../../../../core/utils/reducer'
import CoreEnums from '../../../../core/utils/enums'
import { useDispatch, useSelector } from 'react-redux'
import { geocodeByAddress } from 'react-google-places-autocomplete'
import ClinicTemplates from '../utils/templates'
import { INITIAL_MACRO_STATE } from '../utils/constants'

const useClinicsForm = ({ data, isNewRecord, refreshView, isWizard, handleWizardNextPrevious }) => {
  const dispatch = useDispatch()
  const coreState = useSelector((state) => state.core)
  const [loading, setLoading] = useState(false)
  const [deactivatePrompt, setDeactivatePrompt] = useState(false)
  const [specialFormState, setSpecialFormState] = useState([])
  const [physicalAddress, setPhysicalAddress] = useState(data?.physicalAddress)
  const inputRef = useRef(null)
  const [image, setImage] = useState(data?.clinicLogo || coreState.entity?.entityIcon)
  const [bpmProfiles, setBpmProfiles] = useState([])

  // Macros state
  const [macros, setMacros] = useState(data?.billingMacros || [])
  const [macro, setMacro] = useState(INITIAL_MACRO_STATE)
  const [macroIndex, setMacroIndex] = useState(null)
  const [macroFormOpen, setMacroFormOpen] = useState(false)
  const [isNewMacro, setIsNewMacro] = useState(false)

  const [clientForm] = Form.useForm()

  const readBase64 = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const handleGetBpmProfiles = useCallback(async () => {
    try {
      let tmpBpmProfiles = await readBPMProfiles()
      tmpBpmProfiles = sortBy(tmpBpmProfiles, ['name'])

      const formattedProfiles = tmpBpmProfiles.map((item) => ({
        ...item,
        label: item.data.name,
        value: item.data.key
      }))

      setBpmProfiles(formattedProfiles)
    } catch (error) {
      console.error('Error fetching BPM profiles:', error)
    }
  }, [])

  useEffect(() => {
    const initializeForm = () => {
      const formState = Object.entries(data || {}).map(([key, value]) => ({
        key,
        value
      }))
      setSpecialFormState(formState)
      handleGetBpmProfiles()
    }
    initializeForm()
    // eslint-disable-next-line
  }, [])

  const closeTab = useCallback(() => {
    refreshView()
    const targetKey = isNewRecord ? `${CoreEnums.tabKeys.CLINICS}_new}` : `${CoreEnums.tabKeys.CLINICS}_${data._id}`

    dispatch(
      coreReducer.actions.closeTab({
        targetKey,
        removeBreadcrumb: true
      })
    )
    // eslint-disable-next-line
  }, [])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const record = allTrim(clientForm.getFieldsValue())
      const clinicData = {
        ...record,
        clinicLogo: image,
        billingMacros: macros
      }

      if (isNewRecord) {
        await createClinic(clinicData)
      } else {
        await updateClinic({ _id: data._id, ...clinicData })
      }

      closeTab()

      if (coreState.tourOpen) {
        dispatch(coreReducer.actions.nextTourStep())
      }
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setLoading(false)
    }
  }

  const handleIsActive = useCallback(
    (value) => {
      setDeactivatePrompt(data.isActive && !value)
    },
    // eslint-disable-next-line
    []
  )

  const handleGetAddress = async (address) => {
    try {
      const [result] = await geocodeByAddress(address)
      const formattedAddress = result.formatted_address
      clientForm.setFieldValue(ClinicTemplates.dataModel.physicalAddress.key, formattedAddress)
      setPhysicalAddress(formattedAddress)
    } catch (error) {
      message.error(handleError(error, true))
    }
  }

  const handleUploadClick = useCallback(() => {
    const hasLogo = Boolean(data?.clinicLogo)
    const promptText = hasLogo
      ? "Do you want Remove or Change the Clinic's Logo?"
      : "Do you want Change the Clinic's Logo?"

    Modal.confirm({
      title: 'Confirmation',
      content: promptText,
      okText: 'Change',
      cancelText: 'Remove',
      onOk: () => inputRef.current?.click(),
      onCancel: () => setImage(coreState.entity.entityIcon),
      cancelButtonProps: {
        disabled: !hasLogo,
        danger: true
      },
      closable: true,
      maskClosable: true,
      className: theme.useToken().themeControl
    })
    // eslint-disable-next-line
  }, [])

  const handleFileChange = async (e) => {
    try {
      const files = e.target?.files
      if (!files?.length) return

      const base64File = await readBase64(files[0])
      setImage(base64File)
    } catch (error) {
      message.error(handleError(error, true))
    }
  }

  const handleMacroDelete = useCallback((index) => {
    setMacros((prev) => prev.filter((_, i) => i !== index))
  }, [])

  const handleMacroClick = useCallback((record, rowIndex) => {
    setMacro(record)
    setMacroFormOpen(true)
    setIsNewMacro(false)
    setMacroIndex(rowIndex)
  }, [])

  const handleNewMacro = useCallback(() => {
    setMacro(INITIAL_MACRO_STATE)
    setMacroFormOpen(true)
    setIsNewMacro(true)
    setMacroIndex(null)
  }, [])

  return {
    coreState,
    clientForm,
    macros,
    macro,
    macroIndex,
    macroFormOpen,
    isNewMacro,
    inputRef,
    physicalAddress,
    loading,
    deactivatePrompt,
    bpmProfiles,
    image,
    specialFormState,
    handleSubmit,
    handleFileChange,
    handleIsActive,
    handleGetAddress,
    handleUploadClick,
    setMacros,
    setMacro,
    setMacroIndex,
    setMacroFormOpen,
    setIsNewMacro,
    setPhysicalAddress,
    setLoading,
    setDeactivatePrompt,
    confirmDeactivation: handleSubmit,
    setBpmProfiles,
    setImage,
    closeTab,
    handleMacroDelete,
    handleMacroClick,
    handleNewMacro,
    setSpecialFormState
  }
}

export default useClinicsForm
