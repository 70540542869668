import React, { useCallback, useEffect, useState } from 'react'

import coreReducer from '../../../core/utils/reducer'
import BillingTool from '../../Bookings/components/Examination Components/BillingTool'
import CoreEnums from '../../../core/utils/enums'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import ObjectID from 'bson-objectid'
import dayjs from 'dayjs'

import { Button, Card, Col, DatePicker, Form, Input, message, Row, Select, Space, theme } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { handleError } from '../../lib/utils'
import { getPlanSubOptions, goodXSubmit, readBillingRecords, readBookings } from '../../Bookings/utils/utils'
import { readPatients } from '../../Admin/patients/utils/utils'
import { readClinics } from '../../Admin/clinics/utils/utils'
import { findAgiliteUser } from '../../Auth/utils/utils'
import { createInvoice, updateInvoice } from '../utils'
import { debounce } from 'lodash'
import { readMedicalProfessionalUsers } from '../../Admin/medical-professionals/utils/utils'

const InvoiceForm = ({ data, isNew = false, handleGetInvoices }) => {
  const dispatch = useDispatch()

  const authState = useSelector((state) => state.auth)

  const [loading, setLoading] = useState(true)
  const [formLoading, setFormLoading] = useState(true)
  const [searchLoading, setSearchLoading] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [searchType, setSearchType] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [invoiceData, setInvoiceData] = useState(data)
  const [items, setItems] = useState([])
  const [bookingRef, setBookingRef] = useState('')
  const [resubmitEnabled, setResubmitEnabled] = useState(false)

  const [billingWebSocket, setBillingWebSocket] = useState({
    connected: false,
    socket: null
  })

  useEffect(() => {
    const bookingSocket = new WebSocket(`${process.env.REACT_APP_NODE_RED_WS_URL}/billing`)

    bookingSocket.onopen = () => {
      setBillingWebSocket({
        connected: true,
        socket: bookingSocket
      })
    }

    bookingSocket.onmessage = (event) => {
      if (JSON.parse(event.data).bookingRef === data.bookingRef) {
        if (JSON.parse(event.data).userRef !== authState.agiliteUser._id) {
          message.info('Billing records have been updated by another user. Please Refresh')
        }
      }
    }

    bookingSocket.onerror = (error) => {
      handleError(error, true)
    }

    return () => {
      bookingSocket.close()

      setBillingWebSocket({
        connected: false,
        socket: null
      })
    }
    // eslint-disable-next-line
  }, [])

  const claimHeaderStatusCode =
    invoiceData?.claimResponse?.ProviderClaimResponse?.CommonProviderClaimResponseInfo[0]?.ClaimHeaderStatusCode[0]

  // Then update the useEffect
  useEffect(() => {
    switch (claimHeaderStatusCode) {
      case 'swREJ':
      case 'mfREJ':
        setResubmitEnabled(true)
        break

      default:
        setResubmitEnabled(false)
        break
    }
  }, [claimHeaderStatusCode])

  useEffect(() => {
    if (!isNew) {
      handleGetExtendedData()
    } else {
      setBookingRef(new ObjectID().toString())
      setFormLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (bookingRef) {
      handleGetBillingRecords()
    }

    // eslint-disable-next-line
  }, [bookingRef])

  const handleGetBillingRecords = async () => {
    let tmpItems = []

    setLoading(true)
    try {
      tmpItems = await readBillingRecords({ bookingRef })
      setItems(tmpItems)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const handleGetExtendedData = async () => {
    let tmpData = null
    let patientRecord = null
    let bookingRecord = null
    let medicalProfRecord = null
    let refMedProfRecord = null
    let assMedProfRecord = null
    let clinicRecord = null

    setFormLoading(true)

    try {
      await Promise.all([
        (async () => {
          tmpData = await readPatients({ _id: data.userRef })
          patientRecord = tmpData.length > 0 ? tmpData[0] : null
        })(),
        (async () => {
          if (data.medicalProfRef) {
            medicalProfRecord = await findAgiliteUser({ _id: data.medicalProfRef }, undefined, 'firstName lastName')
          }
        })(),
        (async () => {
          if (data.refMedProfRef) {
            refMedProfRecord = await findAgiliteUser({ _id: data.refMedProfRef }, undefined, 'firstName lastName')
          }
        })(),
        (async () => {
          if (data.assMedProfRef) {
            assMedProfRecord = await findAgiliteUser({ _id: data.assMedProfRef }, undefined, 'firstName lastName')
          }
        })(),
        (async () => {
          tmpData = await readClinics({ _id: data.clinicRef })
          clinicRecord = tmpData.length > 0 ? tmpData[0] : null
        })(),
        (async () => {
          tmpData = await readBookings({ _id: data.bookingRef })
          bookingRecord = tmpData.length > 0 ? tmpData[0] : null
          setBookingRef(data.bookingRef)
        })()
      ])

      setInvoiceData({
        ...data,
        patientRecord,
        bookingRecord,
        medicalProfRecord,
        refMedProfRecord,
        assMedProfRecord,
        clinicRecord
      })
    } catch (e) {
      message.error(handleError(e, true))
    } finally {
      setFormLoading(false)
    }
  }

  const handleCloseTab = () => {
    handleGetInvoices()
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: isNew ? `${CoreEnums.tabKeys.INVOICES}_new` : `${CoreEnums.tabKeys.INVOICES}_${data._id}`,
        removeBreadcrumb: true
      })
    )
  }

  // eslint-disable-next-line
  const debouncedUpdateInvoice = useCallback(
    debounce((id, fields) => {
      updateInvoice(id, fields)
    }, 3000),
    []
  )

  useEffect(() => {
    return () => {
      debouncedUpdateInvoice.cancel()
    }
  }, [debouncedUpdateInvoice])

  const handleFieldChange = (changedFields) => {
    try {
      setInvoiceData({ ...invoiceData, ...changedFields })

      if (!isNew) {
        debouncedUpdateInvoice(invoiceData._id, changedFields)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((query, type) => {
      if (query) {
        setSearchValue(query)
        setSearchType(type)
      }
    }, 2000),
    []
  )

  const performSearch = async () => {
    try {
      let tmpSearchData = []

      switch (searchType) {
        case 'Patient':
          tmpSearchData = await readPatients({
            $or: [
              { firstName: { $regex: searchValue, $options: 'i' } },
              { lastName: { $regex: searchValue, $options: 'i' } }
            ]
          })
          break
        case 'Practitioner':
          tmpSearchData = await readMedicalProfessionalUsers({
            $or: [
              { firstName: { $regex: searchValue, $options: 'i' } },
              { lastName: { $regex: searchValue, $options: 'i' } }
            ]
          })
          break
        case 'Clinic':
          tmpSearchData = await readClinics({
            name: { $regex: searchValue, $options: 'i' }
          })
          break
        default:
          break
      }

      setSearchData(tmpSearchData)
      setSearchLoading(false)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  useEffect(() => {
    if (searchValue) {
      performSearch()
    }
    // eslint-disable-next-line
  }, [searchValue])

  const handleSearch = (value, type) => {
    setSearchLoading(true)
    debouncedSearch(value, type)
  }

  const renderSearchSelect = (type) => {
    return (
      <Select
        style={{ width: '100%' }}
        showSearch
        allowClear
        placeholder={`Search for a ${type}`}
        onSearch={(value) => {
          handleSearch(value, type)
        }}
        filterOption={false}
        onBlur={() => {
          setSearchData([])
          setSearchLoading(false)
          setSearchValue('')
        }}
        onChange={(value, option) => {
          if (option) {
            if (type === 'Patient') {
              setInvoiceData({ ...invoiceData, patientRecord: JSON.parse(option.data) })
            } else if (type === 'Practitioner') {
              setInvoiceData({ ...invoiceData, medicalProfRecord: JSON.parse(option.data) })
            } else if (type === 'Clinic') {
              setInvoiceData({ ...invoiceData, clinicRecord: JSON.parse(option.data) })
            }
          }
        }}
        notFoundContent={
          searchLoading ? (
            <AgiliteSkeleton skActive spinnerTip='Searching...' />
          ) : (
            <Row>
              <Col span={24}>
                <p style={{ textAlign: 'center' }}>No data found</p>
              </Col>
              <Col span={24}>
                <h4 style={{ textAlign: 'center' }}>Type a {type} Name to start searching</h4>
              </Col>
            </Row>
          )
        }
        options={searchData.map((item) => {
          return {
            key: item._id,
            label: type === 'Clinic' ? item.name : `(${item.idNo}) ${item.firstName} ${item.lastName}`,
            value: item._id,
            data: JSON.stringify(item)
          }
        })}
      />
    )
  }

  const handleSubmit = async () => {
    try {
      await form.validateFields()
      await goodXSubmission()
    } catch (e) {}
  }

  const goodXSubmission = async () => {
    try {
      let goodXTicketNo = ''
      let planSubOption = null
      let planSubOptionsData = null

      let patient = null
      let mainMember = null
      let medicalProf = null
      let clinic = null

      setLoading(true)
      setFormLoading(true)

      patient = invoiceData.patientRecord
      medicalProf = invoiceData.medicalProfRecord
      clinic = invoiceData.clinicRecord

      if (patient.mainMemberId) {
        mainMember = await findAgiliteUser({ _id: patient.mainMemberId })
      } else {
        mainMember = patient
      }

      if (mainMember.medicalAid.name && patient.medicalAid.name) {
        planSubOptionsData = await getPlanSubOptions(mainMember.medicalAid.planOptionCode)

        if (planSubOptionsData.planSubOptions.pageResult.length > 0) {
          const tmpIndex = planSubOptionsData.planSubOptions.pageResult.findIndex(
            (option) => option.option === 'Acute Meds'
          )

          if (tmpIndex !== -1) {
            planSubOption = planSubOptionsData.planSubOptions.pageResult[tmpIndex]
          } else {
            planSubOption = planSubOptionsData.planSubOptions.pageResult[0]
          }
        }

        goodXTicketNo = await goodXSubmit({
          patientRecord: patient,
          mainMemberRecord: mainMember,
          clinicRecord: clinic,
          billingItems: items.filter((item) => item.code && item.name && item.type),
          medicalProf,
          planSubOption,
          bookingDate: form.getFieldValue('bookingDate')
        })
      }

      if (isNew) {
        await handleCreateInvoice(goodXTicketNo)
      } else {
        await handleResubmitInvoice(goodXTicketNo)
      }
    } catch (e) {
      message.error(handleError(e, true))
    } finally {
      setLoading(false)
      setFormLoading(false)
    }
  }

  const handleCreateInvoice = async (goodXTicketNo) => {
    setLoading(true)
    try {
      await createInvoice({
        userRef: form.getFieldValue('userRef'),
        bookingRef: bookingRef,
        medicalProfRef: form.getFieldValue('medicalProfRef'),
        clinicRef: form.getFieldValue('clinicRef'),
        createdAt: new Date(),
        invoiceType: goodXTicketNo ? form.getFieldValue('invoiceType') : 'private',
        status: '',
        invoiceNo: dayjs(new Date()).format('YYMMDDHHmmss'),
        goodXTicketNo
      })
      handleCloseTab()
      message.success('Invoice Successfully Generated')
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const handleResubmitInvoice = async (goodXTicketNo) => {
    setLoading(true)
    try {
      await updateInvoice(invoiceData._id, {
        userRef: invoiceData.patientRecord._id,
        bookingRef: bookingRef,
        medicalProfRef: invoiceData.medicalProfRecord._id,
        clinicRef: invoiceData.clinicRecord._id,
        createdAt: new Date(),
        invoiceType: invoiceData.invoiceType,
        status: '',
        invoiceNo: dayjs(new Date()).format('YYMMDDHHmmss'),
        goodXTicketNo
      })

      handleCloseTab()
      message.success('Invoice Successfully Resubmitted')
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const [form] = Form.useForm()
  const { token } = theme.useToken()

  return (
    <Row>
      <Col span={24}>
        <Card
          size='small'
          title={<span style={{ color: token.colorPrimary, fontSize: 18 }}>Invoice: {invoiceData.invoiceNo}</span>}
        >
          <Card
            size='small'
            bordered={false}
            title={<span style={{ color: token.colorSecondary, fontSize: 18 }}>Patient & Practitioner Details</span>}
            type='inner'
            loading={formLoading}
          >
            <Form
              initialValues={
                !formLoading
                  ? {
                      userRef: invoiceData.userRef
                        ? `${invoiceData.patientRecord.firstName} ${invoiceData.patientRecord.lastName} (${invoiceData.patientRecord.idNo})`
                        : undefined,
                      medicalProfRef: invoiceData.medicalProfRef
                        ? `${invoiceData.medicalProfRecord.firstName} ${invoiceData.medicalProfRecord.lastName}`
                        : undefined,
                      clinicRef: invoiceData.clinicRef ? invoiceData.clinicRecord.name : undefined,
                      bookingDate: invoiceData.bookingDate,
                      invoiceType: invoiceData.invoiceType,
                      authNo: invoiceData.authNo,
                      refMedProfRef: invoiceData.refMedProfRef
                        ? `${invoiceData.refMedProfRecord.firstName} ${invoiceData.refMedProfRecord.lastName}`
                        : undefined,
                      assMedProfRef: invoiceData.assMedProfRef
                        ? `${invoiceData.assMedProfRecord.firstName} ${invoiceData.assMedProfRecord.lastName}`
                        : undefined
                    }
                  : {}
              }
              form={form}
              onValuesChange={(changedFields) => handleFieldChange(changedFields)}
              layout='vertical'
              disabled={formLoading}
            >
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Form.Item
                    name='userRef'
                    label='Patient'
                    rules={[{ required: true, message: 'Patient is required' }]}
                  >
                    {isNew ? renderSearchSelect('Patient') : <Input readOnly />}
                  </Form.Item>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Form.Item
                        name='medicalProfRef'
                        label='Practitioner'
                        rules={[{ required: true, message: 'Practitioner is required' }]}
                      >
                        {isNew ? renderSearchSelect('Practitioner') : <Input readOnly />}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='bookingDate'
                        label='Date'
                        rules={[{ required: true, message: 'Date is required' }]}
                      >
                        {isNew ? <DatePicker style={{ width: '100%' }} /> : <Input readOnly />}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Form.Item
                        name='clinicRef'
                        label='Clinic'
                        rules={[{ required: true, message: 'Clinic is required' }]}
                      >
                        {isNew ? renderSearchSelect('Clinic') : <Input readOnly />}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='place' label='Place'>
                        <Select
                          disabled={!isNew}
                          defaultValue='outHospital'
                          options={[
                            { label: 'Out Hospital', value: 'outHospital' },
                            { label: 'In Hospital', value: 'inHospital' }
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='invoiceType'
                    label='Invoice Type'
                    rules={[{ required: true, message: 'Invoice Type is required' }]}
                  >
                    <Select
                      disabled={!isNew}
                      options={[
                        { label: 'Medical', value: 'medical' },
                        { label: 'Insurance', value: 'insurance' },
                        { label: 'Private', value: 'private' }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item name='authNo' label='Authorization Number'>
                    <Input disabled={!isNew} />
                  </Form.Item>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Form.Item name='refMedProfRef' label='Referring Doctor'>
                        {isNew ? renderSearchSelect('Practitioner') : <Input readOnly />}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='assMedProfRef' label='Assisting Doctor'>
                        {isNew ? renderSearchSelect('Practitioner') : <Input readOnly />}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card
            size='small'
            bordered={false}
            type='inner'
            title={<span style={{ color: token.colorSecondary, fontSize: 18 }}>Invoice Items</span>}
            extra={
              <Button
                type='primary'
                size='small'
                style={{ backgroundColor: token.colorSuccess, color: token.colorWhite }}
                onClick={handleGetBillingRecords}
                disabled={loading}
              >
                <Space>
                  <FontAwesomeIcon icon={faRefresh} />
                  Refresh
                </Space>
              </Button>
            }
          >
            <BillingTool
              isInvoiceForm={true}
              itemSelection={items}
              setItemSelection={setItems}
              bookingData={{
                _id: bookingRef,
                patientRecord: invoiceData.patientRecord
              }}
              isMacroForm={false}
              billingRecordsLoading={loading}
              billingWebSocket={billingWebSocket}
              hideAdd={false}
            />
          </Card>
        </Card>
        <Row justify='center' style={{ marginTop: 10 }} gutter={[12, 12]}>
          {isNew || (!isNew && resubmitEnabled) ? (
            <Col>
              <Button
                type='primary'
                onClick={() => handleSubmit()}
                disabled={loading || formLoading}
                loading={loading || formLoading}
              >
                {isNew ? 'Create Invoice' : 'Resubmit Invoice'}
              </Button>
            </Col>
          ) : undefined}
          <Col>
            <Button danger onClick={() => handleCloseTab()}>
              Close
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default InvoiceForm
