import { 
  ExperimentOutlined,
  CameraOutlined,
  FileSearchOutlined,
  PhoneOutlined,
  MedicineBoxOutlined
} from '@ant-design/icons'

export const TASK_TYPES = {
  LAB_RESULT: 'lab_result',
  IMAGING: 'imaging',
  REFERRAL: 'referral',
  FOLLOW_UP: 'follow_up',
  MEDICATION: 'medication'
}

export const TASK_TYPE_CONFIG = {
  [TASK_TYPES.LAB_RESULT]: {
    icon: <ExperimentOutlined />,
    label: 'Lab Result',
    color: '#722ed1',
    description: 'Follow up on laboratory test results'
  },
  [TASK_TYPES.IMAGING]: {
    icon: <CameraOutlined />,
    label: 'Imaging',
    color: '#13c2c2',
    description: 'Review imaging results (X-ray, MRI, CT, etc.)'
  },
  [TASK_TYPES.REFERRAL]: {
    icon: <FileSearchOutlined />,
    label: 'Referral',
    color: '#fa8c16',
    description: 'Process specialist referrals'
  },
  [TASK_TYPES.FOLLOW_UP]: {
    icon: <PhoneOutlined />,
    label: 'Follow-up Call',
    color: '#2f54eb',
    description: 'Follow up with patient via phone'
  },
  [TASK_TYPES.MEDICATION]: {
    icon: <MedicineBoxOutlined />,
    label: 'Medication Review',
    color: '#52c41a',
    description: 'Review patient medication'
  }
} 