import Agilite from 'agilite'
import ClinicalReportingEnums from './clinical-reporting-enums'
import store from '../../../../store'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const readClinicalReportTemplates = (qry, capturing) => {
  return new Promise((resolve, reject) => {
    let response = []
    let filter = { ...qry }

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ClinicalReportingEnums.profileKeys.DATA_POINT_TEMPLATES,
          ClinicalReportingEnums.routeKeys.READ,
          {
            filter: JSON.stringify({ ...filter, templateType: 'clinical_report' })
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createClinicalReportTemplate = (data) => {
  return new Promise((resolve, reject) => {
    let response = []

    const responsibleUser = store.getState().auth.agiliteUser._id
    const date = new Date()
    data.createdBy = responsibleUser
    data.modifiedBy = responsibleUser
    data.createdAt = date
    data.modifiedAt = date
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ClinicalReportingEnums.profileKeys.DATA_POINT_TEMPLATES,
          ClinicalReportingEnums.routeKeys.CREATE,
          {
            data: JSON.stringify({ ...data, templateType: 'clinical_report' })
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const updateClinicalReportTemplate = (recordId, data) => {
  return new Promise((resolve, reject) => {
    let response = []

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ClinicalReportingEnums.profileKeys.DATA_POINT_TEMPLATES,
          ClinicalReportingEnums.routeKeys.UPDATE,
          {
            recordId,
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const deleteDataPointTemplate = (recordId) => {
  return new Promise((resolve, reject) => {
    let response = []

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          ClinicalReportingEnums.profileKeys.DATA_POINT_TEMPLATES,
          ClinicalReportingEnums.routeKeys.DELETE,
          {
            recordId
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
