// macro_tracker
import Agilite from 'agilite'
import { MacroTrackerEnums } from './macro-tracker-enums'
import store from '../../../../../../../store'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

// Fetch all macro trackers
export const readMacroTrackers = (filter, options) => {
  return new Promise((resolve, reject) => {
    let response = null
    let queryOptions = null

    ;(async () => {
      try {
        if (options) {
          queryOptions = JSON.stringify(options)
        }

        response = await agilite.Connectors.execute(
          MacroTrackerEnums.profileKeys.MACRO_TRACKER,
          MacroTrackerEnums.routeKeys.READ,
          {
            filter: JSON.stringify(filter),
            page: null,
            pageLimit: null,
            options: queryOptions
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

// Create one entry of a macro tracker
export const createMacroTrackers = (payload) => {
  return new Promise((resolve, reject) => {
    let response = null
    payload.createdAt = new Date()
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          MacroTrackerEnums.profileKeys.MACRO_TRACKER,
          MacroTrackerEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(payload)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

// Dont worry about this
export const updateMacroTracker = (filter, options) => {
  return new Promise((resolve, reject) => {
    let response = null
    let queryOptions = null

    ;(async () => {
      try {
        if (options) {
          queryOptions = JSON.stringify(options)
        }

        response = await agilite.Connectors.execute(
          MacroTrackerEnums.profileKeys.MACRO_TRACKER,
          MacroTrackerEnums.routeKeys.UPDATE,
          {
            filter: JSON.stringify(filter),
            page: null,
            pageLimit: null,
            options: queryOptions
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

// Dont worry about this
export const removeMacroTrackers = (filter, options) => {
  return new Promise((resolve, reject) => {
    let response = null
    let queryOptions = null

    ;(async () => {
      try {
        if (options) {
          queryOptions = JSON.stringify(options)
        }

        response = await agilite.Connectors.execute(
          MacroTrackerEnums.profileKeys.MACRO_TRACKER,
          MacroTrackerEnums.routeKeys.DELETE,
          {
            filter: JSON.stringify(filter),
            page: null,
            pageLimit: null,
            options: queryOptions
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

// Dont worry about this
export const updateDailyLimits = (filter, payload) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          MacroTrackerEnums.profileKeys.MACRO_TRACKER_META_DATA,
          MacroTrackerEnums.routeKeys.UPSERT,
          {
            filter: JSON.stringify(filter),
            data: JSON.stringify(payload)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const readMacroTrackerMetaData = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          MacroTrackerEnums.profileKeys.MACRO_TRACKER_META_DATA,
          MacroTrackerEnums.routeKeys.READ,
          {
            filter: JSON.stringify(filter)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

// Dont worry about this
export const readSavedMacroTrackerMeals = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          MacroTrackerEnums.profileKeys.MACRO_TRACKER_SAVED_MEALS,
          MacroTrackerEnums.routeKeys.READ,
          {
            filter: JSON.stringify(filter)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const saveMacroTrackerMeal = (payload) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          MacroTrackerEnums.profileKeys.MACRO_TRACKER_SAVED_MEALS,
          MacroTrackerEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(payload)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const saveMacroTrackerRecipe = (newRecord) => {
  return new Promise((resolve, reject) => {
    let response = null
    const payload = { ...newRecord }
    payload.userRef = store.getState().auth.agiliteUser._id
    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          MacroTrackerEnums.profileKeys.MACRO_TRACKER_RECIPES,
          MacroTrackerEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(payload)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
export const readMacroTrackerRecipes = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          MacroTrackerEnums.profileKeys.MACRO_TRACKER_RECIPES,
          MacroTrackerEnums.routeKeys.READ,
          {
            filter: JSON.stringify(filter)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const removeSavedMacroTrackerRecipe = (recordId) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          MacroTrackerEnums.profileKeys.MACRO_TRACKER_RECIPES,
          MacroTrackerEnums.routeKeys.DELETE_BY_ID,
          {
            recordId
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const removeSavedMacroTrackerMeal = (recordId) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          MacroTrackerEnums.profileKeys.MACRO_TRACKER_SAVED_MEALS,
          MacroTrackerEnums.routeKeys.DELETE_BY_ID,
          {
            recordId
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
