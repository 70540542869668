import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Form,
  Button,
  theme,
  Popconfirm,
  Switch,
  message,
  Input,
  Modal,
  Space,
  Card,
  Divider,
  Empty
} from 'antd'
import { useDispatch } from 'react-redux'
/* import { UploadOutlined } from '@ant-design/icons' */

import coreReducer from '../../../../core/utils/reducer'
import Theme from '../../../../core/utils/theme'
import Templates from '../utils/templates'
import { createService, updateService } from '../utils/utils'
import { ContainerCard, PrimaryCard } from '../../../reusable-components/AgiliteCards'

import { PlusOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import CheckinPreview from './virtual-services-preview-checkin'
import { allTrim, handleError, specialFieldValidation } from '../../../lib/utils'
import CustomRow from '../../../reusable-components/CustomRow'

const VirtualServicesForm = ({ data, isNewRecord, refreshView }) => {
  const [checkinQuestions, setCheckinQuestions] = useState(data.checkin ? data.checkin : [])
  const [deactivatePrompt, setDeactivatePrompt] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState()
  const [isNewQuestion, setIsNewQuestion] = useState()
  const [isPreview, setIsPreview] = useState()
  const [loading, setLoading] = useState(false)
  const [recordData, setRecordData] = useState()
  const [tmpQuestion, setTmpQuestion] = useState({ text: null, isNew: null })
  const [specialFormState, setSpecialFormState] = useState([])

  const dispatch = useDispatch()
  const { token } = theme.useToken()
  const [clientForm] = Form.useForm()
  const [form] = Form.useForm()
  const checkinTemplate = {
    doctorsText: null,
    enableOptions: false,
    options: [],
    optionsHeading: null,
    otherOptionText: null,
    questionText: null,
    showOther: false
  }

  useEffect(() => {
    const tmpSpecialFormState = []
    for (const [key, value] of Object.entries(data)) {
      tmpSpecialFormState.push({ key: key, value: value })
    }
    setSpecialFormState(tmpSpecialFormState)
    // eslint-disable-next-line
  }, [])

  const handleSubmit = async () => {
    let record = allTrim(clientForm.getFieldsValue())
    record.checkin = allTrim(checkinQuestions)
    try {
      setLoading(true)

      record = {
        ...record
      }
      if (isNewRecord) {
        await createService(record)
      } else {
        await updateService({ _id: data._id, ...record })
      }

      closeTab()
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const closeTab = () => {
    refreshView()
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: isNewRecord ? 'add_service' : data._id,
        removeBreadcrumb: true
      })
    )
  }

  const handleIsActive = (value) => {
    if (data.isActive && !value) setDeactivatePrompt(true)
    else setDeactivatePrompt(false)
  }

  const onFinish = () => {
    const tmpCheckinTemplate = JSON.parse(JSON.stringify(checkinTemplate))
    for (var value in recordData) {
      tmpCheckinTemplate[value] = recordData[value]
    }
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    tmpCheckinQuestions.push(tmpCheckinTemplate)
    setCheckinQuestions(tmpCheckinQuestions)
    form.resetFields()
    setIsModalOpen(false)
  }

  const handleCheckinChange = (value, index, key) => {
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    tmpCheckinQuestions[index][key] = value
    setCheckinQuestions(tmpCheckinQuestions)
  }

  const handleQuestionDelete = (questionIndex) => {
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    tmpCheckinQuestions.splice(questionIndex, 1)
    setCheckinQuestions(tmpCheckinQuestions)
  }

  const addCheckinOption = (questionIndex) => {
    setTmpQuestion({
      ...tmpQuestion,
      questionIndex: questionIndex,
      isNew: true
    })
    setIsNewQuestion(true)
  }
  const editCheckinOption = (questionIndex, optionIndex) => {
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    setTmpQuestion({
      ...tmpCheckinQuestions[questionIndex].options[optionIndex],
      questionIndex: questionIndex,
      optionIndex: optionIndex,
      isNew: false
    })
    setIsNewQuestion(true)
  }

  const handleCheckinOptionSave = () => {
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    const questionIndex = tmpQuestion.questionIndex
    const optionIndex = tmpQuestion.optionIndex
    delete tmpQuestion.questionIndex
    delete tmpQuestion.optionIndex
    delete tmpQuestion.isNew
    if (!tmpQuestion.text) {
      message.error('Please provide option text')
    } else {
      if (tmpCheckinQuestions[questionIndex].options[optionIndex]) {
        tmpCheckinQuestions[questionIndex].options[optionIndex] = tmpQuestion
        setCheckinQuestions(tmpCheckinQuestions)
        setTmpQuestion({ text: null })
        setIsNewQuestion(false)
      } else {
        tmpCheckinQuestions[questionIndex].options.push(tmpQuestion)
        setCheckinQuestions(tmpCheckinQuestions)
        setTmpQuestion({ text: null })
        setIsNewQuestion(false)
      }
    }
  }

  const handleTmpQuestionChange = (action, value) => {
    const tmpTmpQuestion = JSON.parse(JSON.stringify(tmpQuestion))
    if (action === 'text') {
      tmpTmpQuestion.text = value
    }
    setTmpQuestion(tmpTmpQuestion)
  }

  const deleteCheckinOption = (questionIndex, optionIndex) => {
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    tmpCheckinQuestions[questionIndex].options.splice(optionIndex, 1)
    setCheckinQuestions(tmpCheckinQuestions)
  }

  const sectionHeadingStyle = {
    padding: 6,
    borderRadius: 6,
    background: token.colorSecondary,
    color: '#ffffff',
    textAlign: 'center'
  }

  return (
    <ContainerCard title={isNewRecord ? 'Add a New Service' : 'Viewing Service'}>
      <Modal
        width={'100vw'}
        title={<center>Checkin Questionnaire Preview</center>}
        closable={false}
        destroyOnClose
        maskClosable={false}
        open={isPreview}
        footer={false}
      >
        <CheckinPreview data={checkinQuestions} />
        <center style={{ marginTop: 12 }}>
          <Button
            onClick={() => {
              setIsPreview(false)
            }}
          >
            Close Preview
          </Button>
        </center>
      </Modal>
      <Modal
        title='Checkin Question Option'
        closable={false}
        destroyOnClose
        maskClosable={false}
        open={isNewQuestion}
        okText='Confirm'
        onOk={() => [handleCheckinOptionSave()]}
        onCancel={() => {
          setTmpQuestion({ text: null })
          setIsNewQuestion(false)
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            Option Text:
            <br />
            <small>This is the text that will be displayed to the user for selection.</small>
          </Col>
          <Col span={24}>
            <TextArea
              onChange={(e) => {
                handleTmpQuestionChange('text', e.target.value)
              }}
              value={tmpQuestion.text}
            />
          </Col>
        </Row>
      </Modal>
      <Modal footer={false} closable={false} destroyOnClose maskClosable={false} open={isModalOpen}>
        <Card type='inner' size='small' title='New Question'>
          <Form
            layout='vertical'
            form={form}
            onFieldsChange={() => {
              setRecordData(form.getFieldsValue())
            }}
            name='dynamic_form_complex'
            onFinish={onFinish}
            style={{
              maxWidth: 600
            }}
            autoComplete='off'
          >
            <Form.Item
              name='questionText'
              label={
                <Row>
                  <Col span={24}>
                    <b>Main Item Text</b>
                  </Col>
                  <Col span={24}>
                    <small>Main selection item text prompting the users selection.</small>
                  </Col>
                </Row>
              }
              rules={[
                {
                  required: true,
                  message: 'Missing text'
                }
              ]}
            >
              <Input placeholder='(e.g) I am feeling ill.' maxLength={50} />
            </Form.Item>
            <Divider style={{ borderColor: token.colorSecondaryLight }} />
            <Form.Item
              label={
                <Row>
                  <Col span={24}>
                    <b>Professional Text</b>
                  </Col>
                  <Col span={24}>
                    <small>Indicator displayed to the doctor for the selection.</small>
                  </Col>
                </Row>
              }
              name='doctorsText'
              rules={[
                {
                  required: true,
                  message: 'Missing text'
                }
              ]}
            >
              <Input placeholder='(e.g) Sick.' maxLength={50} />
            </Form.Item>
            <Divider style={{ borderColor: token.colorSecondaryLight }} />
            <Form.Item
              label={
                <Row>
                  <Col span={24}>
                    <b>Secondary Options:</b>
                  </Col>
                  <Col span={24}>
                    <small>Enable or disabled the use of secondary options.</small>
                  </Col>
                </Row>
              }
              name='enableOptions'
            >
              <Switch defaultChecked={false} checkedChildren='Enabled' unCheckedChildren='Disabled' />
            </Form.Item>
            {form.getFieldsValue().enableOptions ? (
              <>
                <Divider style={{ borderColor: token.colorSecondaryLight }} />
                <Form.Item
                  label={
                    <Row>
                      <Col span={24}>
                        <b>Secondary Options Label</b>
                      </Col>
                      <Col span={24}>
                        <small>Choose an appropriate heading to prompt the user to select relevant options.</small>
                      </Col>
                    </Row>
                  }
                  name='optionsHeading'
                  rules={[
                    {
                      required: true,
                      message: 'Missing text'
                    }
                  ]}
                >
                  <Input placeholder='(e.g) Please select your symptoms from the list.' maxLength={50} />
                </Form.Item>
                <Divider style={{ borderColor: token.colorSecondaryLight }} />
                <Form.List name='options'>
                  {(fields, { add, remove }) => (
                    <>
                      <Row style={{ marginBottom: 12 }} gutter={[12, 12]}>
                        <Col span={24}>
                          {
                            <Row>
                              <Col span={24}>
                                <b>Secondary Options Items:</b>
                              </Col>
                              <Col span={24}>
                                <small>Allow a user to select from a collection of options provided.</small>
                              </Col>
                            </Row>
                          }
                        </Col>
                        {fields.map((field) => {
                          return (
                            <Col span={24}>
                              <Card
                                type='inner'
                                size='small'
                                extra={
                                  <Button
                                    onClick={() => {
                                      if (fields.length > 1) {
                                        remove(field.name)
                                      } else {
                                        message.error('You have to provide atleast 1 option')
                                      }
                                    }}
                                  >
                                    <FontAwesomeIcon color={token.colorError} icon={faTrash} />
                                  </Button>
                                }
                                key={field.key}
                              >
                                <Row>
                                  <Col span={24}>
                                    <Form.Item
                                      {...field}
                                      label='Option text:'
                                      name={[field.name, 'text']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Missing text'
                                        }
                                      ]}
                                    >
                                      <Input placeholder='(e.g) Headaches' maxLength={50} />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          )
                        })}
                      </Row>
                      <Form.Item>
                        <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                          Add options
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Divider style={{ borderColor: token.colorSecondaryLight }} />
                <Form.Item
                  label={
                    <Row>
                      <Col span={24}>
                        <b>Other Option:</b>
                      </Col>
                      <Col span={24}>
                        <small>Allow user to provide additional information via text field on selection.</small>
                      </Col>
                    </Row>
                  }
                  name='showOther'
                >
                  <Switch defaultChecked={false} checkedChildren='Enabled' unCheckedChildren='Disabled' />
                </Form.Item>

                {form.getFieldsValue().showOther ? (
                  <>
                    <Divider style={{ borderColor: token.colorSecondaryLight }} />
                    <Form.Item
                      label={
                        <Row>
                          <Col span={24}>
                            <b>Additional Field Label</b>
                          </Col>
                          <Col span={24}>
                            <small>Provide an appropriate label prompting the user to fill in the field.</small>
                          </Col>
                        </Row>
                      }
                      name='otherOptionText'
                      rules={[
                        {
                          required: true,
                          message: 'Missing text'
                        }
                      ]}
                    >
                      <TextArea placeholder='(e.g) List your symptoms.' maxLength={100} />
                    </Form.Item>
                  </>
                ) : null}
              </>
            ) : null}

            <Form.Item>
              <Row justify='center'>
                <Space wrap>
                  <Button
                    onClick={() => {
                      form.resetFields()
                      setIsModalOpen(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type='primary' htmlType='submit'>
                    Add Question
                  </Button>
                </Space>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
      <CustomRow justify='center'>
        <Col xs={24} sm={24} md={20} lg={16} xl={14} xxl={12}>
          <CustomRow>
            <Col span={24}>
              <h2 style={sectionHeadingStyle}>{isNewRecord ? 'New Service' : data.name}</h2>
            </Col>
            <Col span={24}>
              <CustomRow className='basic-card'>
                <Col span={24}>
                  <Form
                    onFinish={handleSubmit}
                    name='form'
                    form={clientForm}
                    initialValues={{
                      ...Templates.dataTemplate(),
                      ...data
                    }}
                    layout='vertical'
                  >
                    <Row justify='center'>
                      <Col span={24}>
                        <Space wrap style={{ marginBottom: '20px' }}>
                          {Templates.dataModel.isActive.label}
                          <Form.Item
                            style={{ margin: 0 }}
                            name={Templates.dataModel.isActive.key}
                            valuePropName='checked'
                          >
                            <Switch
                              checkedChildren='Active'
                              unCheckedChildren='Inactive'
                              onChange={(e) => {
                                handleIsActive(e)
                              }}
                            />
                          </Form.Item>
                        </Space>
                        <Form.Item
                          name={Templates.dataModel.name.key}
                          label={Templates.dataModel.name.label}
                          rules={[
                            {
                              required: Templates.dataModel.name.required,
                              message: Templates.dataModel.name.validationMsg
                            }
                          ]}
                        >
                          <Input
                            maxLength={50}
                            onChange={(e) => {
                              specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                key: 'name',
                                event: e,
                                validationConfig: {
                                  letters: { allowed: true, onlyCaps: false },
                                  numbers: false,
                                  spaces: true
                                }
                              })
                            }}
                            placeholder={Templates.dataModel.name.placeholder}
                          />
                        </Form.Item>
                        <Form.Item
                          name={Templates.dataModel.rate.key}
                          label={Templates.dataModel.rate.label}
                          rules={[
                            {
                              required: Templates.dataModel.rate.required,
                              message: Templates.dataModel.rate.validationMsg
                            }
                          ]}
                        >
                          <Input
                            maxLength={5}
                            placeholder={Templates.dataModel.rate.placeholder}
                            addonBefore='R'
                            style={{ width: 200 }}
                            onChange={(e) => {
                              specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                key: Templates.dataModel.rate.key,
                                event: e,
                                validationConfig: {
                                  letters: { allowed: false, onlyCaps: false },
                                  numbers: true,
                                  spaces: false
                                }
                              })
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name={Templates.dataModel.timeslotInterval.key}
                          label={Templates.dataModel.timeslotInterval.label}
                          rules={[
                            {
                              required: Templates.dataModel.timeslotInterval.required,
                              message: Templates.dataModel.timeslotInterval.validationMsg
                            }
                          ]}
                        >
                          <Input
                            placeholder={Templates.dataModel.timeslotInterval.placeholder}
                            addonAfter='Minutes'
                            maxLength={3}
                            style={{ width: 200 }}
                            onChange={(e) => {
                              specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                key: Templates.dataModel.timeslotInterval.key,
                                event: e,
                                validationConfig: {
                                  letters: { allowed: false, onlyCaps: false },
                                  numbers: true,
                                  spaces: false
                                }
                              })
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify='center'>
                      <Col span={24}>
                        <PrimaryCard
                          title='Checkin  Questionnaire'
                          bodyStyle={{ padding: 4 }}
                          type='inner'
                          size='small'
                        >
                          <Row style={{ margin: '12px 0' }} justify={'center'}>
                            <Space wrap>
                              <Button
                                onClick={() => {
                                  setIsModalOpen(true)
                                }}
                                type='primary'
                              >
                                Add Question
                              </Button>
                              {checkinQuestions.length > 0 ? (
                                <Button
                                  onClick={() => {
                                    setIsPreview(true)
                                  }}
                                >
                                  Preview
                                </Button>
                              ) : null}
                            </Space>
                          </Row>
                          {checkinQuestions.map((question, index) => (
                            <Card
                              style={{ marginBottom: 8 }}
                              title={`Question ${index + 1}`}
                              extra={
                                <Button
                                  onClick={() => {
                                    handleQuestionDelete(index)
                                  }}
                                >
                                  <FontAwesomeIcon color={token.colorError} icon={faTrash} />
                                </Button>
                              }
                              type='inner'
                              size='small'
                            >
                              <Row>
                                <Col span={24}>
                                  <b>Main Item Text:</b>
                                  <br></br>
                                  <small>Main selection item text prompting the users selection.</small>
                                  <TextArea
                                    onChange={(e) => {
                                      handleCheckinChange(e.target.value, index, 'questionText')
                                    }}
                                    style={{ marginTop: 8, width: '100%' }}
                                    value={question.questionText}
                                  />
                                </Col>
                                <Divider style={{ borderColor: token.colorSecondaryLight }} />
                                <Col span={24}>
                                  <b>Professional Text:</b>
                                  <br></br>
                                  <small>Indicator displayed to the doctor for the selection.</small>
                                  <TextArea
                                    onChange={(e) => {
                                      handleCheckinChange(e.target.value, index, 'doctorsText')
                                    }}
                                    style={{ marginTop: 8, width: '100%' }}
                                    value={question.doctorsText}
                                  />
                                </Col>
                                <Divider style={{ borderColor: token.colorSecondaryLight }} />
                                <Col span={24}>
                                  <Row style={{ alignItems: 'center' }} justify='space-between'>
                                    <Col>
                                      <b>Secondary Options:</b>
                                      <br />
                                      <small>Enable or disabled the use of secondary options.</small>
                                    </Col>
                                    <Col>
                                      <Switch
                                        checked={question.enableOptions}
                                        checkedChildren='Enabled'
                                        unCheckedChildren='Disabled'
                                        onChange={(e) => {
                                          handleCheckinChange(e, index, 'enableOptions')
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                {question.enableOptions ? (
                                  <>
                                    <Divider style={{ borderColor: token.colorSecondaryLight }} />
                                    <Col span={24}>
                                      <b>Secondary Options Label:</b>
                                      <br></br>
                                      <small>
                                        Provide an appropriate label prompting the user to select from the options.
                                      </small>
                                      <TextArea
                                        onChange={(e) => {
                                          handleCheckinChange(e.target.value, index, 'optionsHeading')
                                        }}
                                        style={{ marginTop: 8, width: '100%' }}
                                        value={question.optionsHeading}
                                      />
                                    </Col>
                                    <Divider style={{ borderColor: token.colorSecondaryLight }} />
                                    <Col span={24}>
                                      <Row>
                                        <Col span={24}>
                                          <b>Secondary Options Items:</b>
                                        </Col>
                                        <Col span={24}>
                                          <small>Allow a user to select from a collection of options provided.</small>
                                        </Col>
                                        <Col span={24}>
                                          <Space wrap style={{ marginTop: 12, width: '100%' }}>
                                            {question.options?.map((sight, optionIndex) => (
                                              <Popconfirm
                                                title={'Action required'}
                                                description='Please select an option'
                                                okText='Edit'
                                                cancelText='Delete'
                                                onCancel={() => {
                                                  deleteCheckinOption(index, optionIndex)
                                                }}
                                                onConfirm={() => {
                                                  editCheckinOption(index, optionIndex)
                                                }}
                                              >
                                                <Button>{sight.text}</Button>
                                              </Popconfirm>
                                            ))}
                                            <Button
                                              onClick={() => {
                                                addCheckinOption(index)
                                              }}
                                            >
                                              <FontAwesomeIcon color={token.colorSuccess} icon={faPlus} />
                                            </Button>
                                          </Space>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Divider style={{ borderColor: token.colorSecondaryLight }} />
                                    <Col span={24}>
                                      <Row gutter={[8, 8]} style={{ alignItems: 'center' }} justify='space-between'>
                                        <Col>
                                          <b>Other Option:</b>
                                          <br />
                                          <small>
                                            Allow user to provide additional information via text field on selection.
                                          </small>
                                        </Col>
                                        <Col>
                                          <Switch
                                            checked={question.showOther}
                                            checkedChildren='Enabled'
                                            unCheckedChildren='Disabled'
                                            onChange={(e) => {
                                              handleCheckinChange(e, index, 'showOther')
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                    {question.showOther ? (
                                      <>
                                        <Divider style={{ borderColor: token.colorSecondaryLight }} />
                                        <Col span={24}>
                                          <Row>
                                            <Col span={24}>
                                              <b>Additional Field Label:</b>
                                            </Col>
                                            <Col span={24}>
                                              <small>
                                                Provide an appropriate label prompting the user to fill in the field.
                                              </small>
                                            </Col>
                                          </Row>
                                          <TextArea
                                            onChange={(e) => {
                                              handleCheckinChange(e.target.value, index, 'otherOptionText')
                                            }}
                                            style={{ marginTop: 8, width: '100%' }}
                                            value={question.otherOptionText}
                                          />
                                        </Col>
                                      </>
                                    ) : null}
                                  </>
                                ) : null}
                              </Row>
                            </Card>
                          ))}
                          {checkinQuestions.length === 0 ? (
                            <Row justify={'center'}>
                              <Empty
                                description='
                              No questions set - Default configuraton will be used.
                          '
                              />
                            </Row>
                          ) : null}
                          {checkinQuestions.length > 0 ? (
                            <Row style={{ margin: '12px 0' }} justify={'center'}>
                              <Space wrap>
                                <Button
                                  onClick={() => {
                                    setIsModalOpen(true)
                                  }}
                                >
                                  Add Question
                                </Button>
                                <Button
                                  onClick={() => {
                                    setIsPreview(true)
                                  }}
                                >
                                  Preview
                                </Button>
                              </Space>
                            </Row>
                          ) : null}
                        </PrimaryCard>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }} justify='center' gutter={[8, 8]}>
                      <Col>
                        <Form.Item noStyle>
                          <Button
                            style={{ backgroundColor: Theme.twitterBootstrap.danger, color: 'white' }}
                            onClick={closeTab}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item noStyle>
                          {deactivatePrompt ? (
                            <Popconfirm
                              okText='Yes'
                              cancelText='No'
                              okButtonProps={{ style: { background: token.colorSuccess, color: 'white' } }}
                              cancelButtonProps={{ style: { background: token.colorError, color: 'white' } }}
                              title={<h3 style={{ color: token.colorWarning, margin: 0 }}>WARNING</h3>}
                              description='You have switched this service to be inactive. Are you sure you want to proceed?'
                              onConfirm={() => {
                                clientForm.submit()
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: Theme.twitterBootstrap.success,
                                  color: 'white',
                                  marginRight: 10
                                }}
                                htmlType='submit'
                                loading={loading}
                              >
                                {isNewRecord ? 'Add Service' : 'Save Changes'}
                              </Button>
                            </Popconfirm>
                          ) : (
                            <Button
                              style={{
                                backgroundColor: Theme.twitterBootstrap.success,
                                color: 'white',
                                marginRight: 10
                              }}
                              htmlType='submit'
                              loading={loading}
                            >
                              {isNewRecord ? 'Add Service' : 'Save Changes'}
                            </Button>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </CustomRow>
            </Col>
          </CustomRow>
        </Col>
      </CustomRow>
    </ContainerCard>
  )
}

export default VirtualServicesForm
