import { deviceDetect } from 'react-device-detect'

const Option = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
]

const MedicalHistoryFormTemplates = {
  sections: {
    allergies: {
      title: 'Allergies',
      backButton: {
        text: 'Back'
      },
      continueButton: {
        text: 'Save & Continue'
      }
    },
    chronicIllnesses: {
      title: 'Chronic Illnesses'
    }
  },
  // DATA MODEL //
  dataModel: {
    // LIFESTYLE //
    alcoholConsumption: {
      dependency: null,
      type: 'switch',
      key: ['lifestyle', 'alcoholConsumption'],
      label: 'Do you consume alcohol?',
      options: Option,
      required: false,
      validationMsg: '',
      checkedChildren: 'Yes',
      uncheckedChildren: 'No'
    },
    alcoholConsumptionLevel: {
      type: 'radioGroup',
      dependency: ['lifestyle', 'alcoholConsumption'],
      key: ['lifestyle', 'alcoholConsumptionLevel'],
      label: <h6>Select the option that best describes your alcohol intake:</h6>,
      options: [
        {
          label: 'Low (Less than 3 drinks per week)',
          value: 'Low (Less than 3 drinks per week)'
        },
        {
          label: 'Moderate (3-7 drinks per week)',
          value: 'Moderate (3-7 drinks per week)'
        },
        {
          label: 'High (More than 7 drinks per week)',
          value: 'High (More than 7 drinks per week)'
        }
      ],
      required: true,
      validationMsg: 'Please specify alcohol intake'
    },
    smoking: {
      dependency: null,
      type: 'switch',
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      key: ['lifestyle', 'smoking'],
      label: 'Do you smoke cigarettes?',
      options: Option,
      required: false,
      validationMsg: ''
    },
    smokingLevel: {
      type: 'radioGroup',
      dependency: ['lifestyle', 'smoking'],
      key: ['lifestyle', 'smokingLevel'],
      label: <h6>Select the option that best describes your smoking level:</h6>,
      options: [
        {
          label: 'Low',
          value: 'Low'
        },
        {
          label: 'Moderate',
          value: 'Moderate'
        },
        {
          label: 'High',
          value: 'High'
        }
      ],
      required: true,
      validationMsg: 'Please specify smoking level'
    },
    smokingYears: {
      type: 'radioGroup',
      dependency: ['lifestyle', 'smoking'],
      key: ['lifestyle', 'smokingYears'],
      label: <h6>How many years have you smoked?</h6>,
      options: [
        {
          label: 'Less than 1 year',
          value: 'Less than 1 year'
        },
        {
          label: '1-2 years',
          value: '1-2 years'
        },
        {
          label: '3-5 years',
          value: '3-5 years'
        },
        {
          label: '6-10 years',
          value: '6-10 years'
        },
        {
          label: 'More than 10 years',
          value: 'More than 10 years'
        }
      ],
      required: true,
      validationMsg: 'Please provide number of years'
    },
    vaping: {
      dependency: null,
      type: 'switch',
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      key: ['lifestyle', 'vaping'],
      label: 'Do you vape?',
      options: Option,
      required: false
    },
    vapingLevel: {
      type: 'radioGroup',
      dependency: ['lifestyle', 'vaping'],
      key: ['lifestyle', 'vapingLevel'],
      label: <h6>Select the option that best describes your vaping levels:</h6>,
      options: [
        {
          label: 'Low',
          value: 'Low'
        },
        {
          label: 'Moderate',
          value: 'Moderate'
        },
        {
          label: 'High',
          value: 'High'
        }
      ],
      required: true,
      validationMsg: 'Please specify vaping level'
    },
    vapingYears: {
      type: 'radioGroup',
      dependency: ['lifestyle', 'vaping'],
      key: ['lifestyle', 'vapingYears'],
      label: <h6>How many years have you been vaping?</h6>,
      options: [
        {
          label: 'Less than 1 year',
          value: 'Less than 1 year'
        },
        {
          label: '1-2 years',
          value: '1-2 years'
        },
        {
          label: '3-5 years',
          value: '3-5 years'
        },
        {
          label: '6-10 years',
          value: '6-10 years'
        },
        {
          label: 'More than 10 years',
          value: 'More than 10 years'
        }
      ],
      required: true,
      validationMsg: 'Please provide years of vaping'
    },
    waterIntake: {
      dependency: null,
      type: 'radioGroup',
      key: ['lifestyle', 'waterIntake'],
      label: <h6>What is your daily water intake?</h6>,
      options: [
        {
          label: 'Less than 1 litre',
          value: 'Less than 1 litre/day'
        },
        {
          label: '1 - 2 litres',
          value: '1-2 litres/day'
        },
        {
          label: 'More than 2 litres',
          value: 'More than 2 litres/day'
        }
      ],
      required: true,
      validationMsg: 'Please specify water intake '
    },
    screenTime: {
      dependency: null,
      type: 'radioGroup',
      key: ['lifestyle', 'screenTime'],
      label: <h6>How much screen time do you have daily?</h6>,
      placeholder: '- Select -',
      options: [
        {
          label: 'Less than 2 hours',
          value: 'Less than 2 hours/day'
        },
        {
          label: '2-4 hours',
          value: '2-4 hours/day'
        },
        {
          label: '5 or more hours',
          value: '5 or more hours/day'
        }
      ],
      required: true,
      validationMsg: 'Please specify screen time'
    },
    diet: {
      type: 'radioGroup',
      dependency: null,
      key: ['lifestyle', 'diet'],
      label: <h6>What best describes your diet?</h6>,
      options: [
        {
          label: 'None',
          value: 'No Diet'
        },
        {
          label: 'Balanced',
          value: 'Balanced Diet'
        },
        {
          label: 'Low-Carb',
          value: 'Low-Carb Diet'
        },
        {
          label: 'Mediterranean',
          value: 'Mediterranean Diet'
        },
        {
          label: 'Vegetarian ',
          value: 'Vegetarian Diet'
        },
        {
          label: 'Vegan',
          value: 'Vegan Diet'
        },
        {
          label: 'Paleo',
          value: 'Paleo Diet'
        },
        {
          label: 'Keto',
          value: 'Ketogenic Diet'
        },
        {
          label: 'Gluten-Free',
          value: 'Gluten-Free Diet'
        },
        {
          label: 'Intermit. Fasting',
          value: 'Intermittent Fasting'
        }
      ],
      required: true,
      validationMsg: 'Please specify diet level'
    },
    physicalActivity: {
      dependency: null,
      type: 'radioGroup',
      key: ['lifestyle', 'physicalActivity'],
      label: <h6>How often do you exercise per week?</h6>,
      options: [
        {
          value: '1-2 days per week',
          label: '1-2 days'
        },
        {
          value: '3-4 days per week',
          label: '3-4 days'
        },
        {
          value: '5+ days per week',
          label: '5+ days'
        },
        {
          value: 'Never',
          label: 'Never'
        }
      ],
      required: true,
      placeholder: '- Select -',
      validationMsg: 'Please specify physical activity frequency'
    },
    sleepHours: {
      dependency: null,
      type: 'radioGroup',
      key: ['lifestyle', 'sleepHours'],
      label: <h6>On average, how many hours of sleep do you get per night?</h6>,
      options: [
        {
          label: 'Less than 5 hours',
          value: 'Less than 5 hours'
        },
        {
          label: 'Between 6 and 8 hours',
          value: 'Between 6 and 8 hours'
        },
        {
          label: 'More than 8 hours',
          value: 'More than 8 hours'
        }
      ],
      required: true,
      validationMsg: 'Please specify hours sleep',
      addonAfter: 'hours'
    },
    stress: {
      dependency: null,
      type: 'radioGroup',
      key: ['lifestyle', 'stress'],
      label: <h6>Select the option that best describes your stress levels:</h6>,
      required: true,
      options: [
        {
          label: 'Very Low',
          value: 'Very Low'
        },
        {
          label: 'Low',
          value: 'Low'
        },
        {
          label: 'Moderate',
          value: 'Moderate'
        },
        {
          label: 'High',
          value: 'High'
        },
        {
          label: 'Very High',
          value: 'Very High'
        }
      ],
      validationMsg: 'Please specify stress level'
    },
    // ALLERGIES //
    allergiesMeds: {
      type: 'checkboxGroup',
      key: ['allergies', 'medsList'],
      label: '',
      props: {
        colSpan: !deviceDetect().isMobile ? 'auto' : 24
      },
      formProps: {
        style: { marginBottom: 0 }
      },
      options: [
        { value: 'Aspirin', label: 'Aspirin' },
        { value: 'Penicillin', label: 'Penicillin' },
        { value: 'Ibuprofen', label: 'Ibuprofen' },
        { value: 'Insulin', label: 'Insulin' },
        { value: 'Novocaine', label: 'Novocaine' }
      ],
      required: false,
      validationMsg: ''
    },
    allergiesFood: {
      type: 'checkboxGroup',
      key: ['allergies', 'foodList'],
      label: '',
      props: {
        colSpan: !deviceDetect().isMobile ? 'auto' : 24
      },
      formProps: {
        style: { marginBottom: 0 }
      },
      options: [
        { value: 'Peanuts', label: 'Peanuts' },
        { value: 'Milk', label: 'Milk' },
        { value: 'Shellfish', label: 'Shellfish' },
        { value: 'Wheat', label: 'Wheat' },
        { value: 'Eggs', label: 'Eggs' }
      ],
      required: false,
      validationMsg: ''
    },
    allergiesOther: {
      type: 'checkboxGroup',
      key: ['allergies', 'otherList'],
      label: '',
      props: {
        colSpan: !deviceDetect().isMobile ? 'auto' : 24
      },
      formProps: {
        style: { marginBottom: 0 }
      },
      options: [
        { value: 'Pollen', label: 'Pollen' },
        { value: 'Bee stings', label: 'Bee stings' },
        { value: 'Cats/Dogs', label: 'Cats/Dogs' },
        { value: 'Mold', label: 'Mold' },
        { value: 'Latex', label: 'Latex' }
      ],
      required: false,
      validationMsg: ''
    },
    // RENAL CARE SPECIFIC //
    renalCare: {
      comorbidities: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'comorbidities'],
        label: 'Comorbidities',
        placeholder: 'e.g. Diabetes, Hypertension, etc.',
        rows: 3
      },
      hospitalAdmissions: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'hospitalAdmissions'],
        label: 'Hospital Admissions',
        placeholder: 'e.g. Hospital admissions, etc.',
        rows: 3
      },
      transfusions: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'transfusions'],
        label: 'Transfusions',
        placeholder: 'e.g. Blood transfusions, etc.',
        rows: 3
      },
      transplantHistory: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'transplantHistory'],
        label: 'Transplant History',
        placeholder: 'e.g. Heart Transplant, etc.',
        rows: 3
      },
      immuneModulatorTherapies: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'immuneModulatorTherapies'],
        label: 'Immune Modulator Therapies',
        placeholder: 'e.g. Immune Modulator Therapies, etc.',
        rows: 3
      },
      covidHistory: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'covidHistory'],
        label: 'Covid History',
        placeholder: 'e.g. Covid History, etc.',
        rows: 3
      },
      vaccinations: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'vaccinations'],
        label: 'Vaccinations',
        placeholder: 'e.g. Covid Vaccinations, etc.',
        rows: 3
      },
      antibioticExposure: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'antibioticExposure'],
        label: 'Antibiotic Exposure',
        placeholder: 'e.g. Antibiotic Exposure, etc.',
        rows: 3
      },
      initiationOfDialysis: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'initiationOfDialysis'],
        label: 'Initiation of Dialysis',
        placeholder: 'e.g. Initiation of Dialysis, etc.',
        rows: 3
      },
      indicationOfDialysis: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'indicationOfDialysis'],
        label: 'Indication of Dialysis',
        placeholder: 'e.g. Indication of Dialysis, etc.',
        rows: 3
      },
      modesOfRRT: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'modesOfRRT'],
        label: 'Modes Of RRT',
        placeholder: 'e.g. Modes of RRT, etc.',
        rows: 3
      },
      vascularAccessPoints: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'vascularAccessPoints'],
        label: 'Vascular Access Points',
        placeholder: 'e.g. Vascular Access Points, etc.',
        rows: 3
      },
      vascularAccessPointsImageName: {
        dependency: null,
        key: ['renalCare', 'vascularAccessPointsImageName']
      },
      vascularAccessPointsImage: {
        dependency: null,
        key: ['renalCare', 'vascularAccessPointsImage']
      },
      catheterRelatedInfections: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'catheterRelatedInfections'],
        label: 'Prior History Of Catheter Related Infections',
        placeholder: 'e.g. Catheter Related Infections, etc.',
        rows: 3
      },
      adverseEvents: {
        dependency: null,
        type: 'textArea',
        key: ['renalCare', 'adverseEvents'],
        label: 'Adverse Events',
        placeholder: 'e.g. Adverse Events, etc.',
        rows: 3
      }
    },
    isChronic: {
      dependency: null,
      type: 'switch',
      key: ['chronicIllnesses', 'isChronic'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Do you suffer from any chronic illnesses?',
      options: Option,
      required: false,
      validationMsg: ''
    },
    illnesses: {
      key: ['chronicIllnesses', 'illnesses'],
      label: 'Illnesses:',
      options: Option,
      required: false,
      validationMsg: ''
    },
    isMedicated: {
      dependency: null,
      type: 'switch',
      key: ['medications', 'isMedicated'],
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      label: 'Are you currently taking any medication?',
      options: Option,
      required: false,
      validationMsg: ''
    },
    medications: {
      key: ['medications', 'medications'],
      label: 'Medication:',
      options: Option,
      required: false,
      validationMsg: ''
    },

    hadSurgery: {
      dependency: null,
      type: 'switch',
      key: ['surgicalHistory', 'hadSurgery'],
      label: 'Have you had surgery?',
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      options: Option,
      required: false,
      validationMsg: ''
    },
    surgeries: {
      type: 'select',
      key: ['surgicalHistory', 'surgeries'],
      label: <h6>Select the applicable surgiries:</h6>,
      placeholder: '- Select -',
      options: [
        {
          label: 'Low (Less than 3 drinks per week)',
          value: 'Low (Less than 3 drinks per week)'
        },
        {
          label: 'Moderate (3-7 drinks per week)',
          value: 'Moderate (3-7 drinks per week)'
        },
        {
          label: 'High (More than 7 drinks per week)',
          value: 'High (More than 7 drinks per week)'
        }
      ],
      required: true,
      validationMsg: 'Please specify surgies'
    },

    familyHistoryList: {
      dependency: null,
      type: 'checkboxGroup',
      key: ['familyHistory', 'list'],
      label: (
        <h6>
          Have any of your immediate family members ever suffered from any of the following conditions before the age of
          55?
        </h6>
      ),
      options: [
        {
          label: 'Heart disease / heart attack',
          value: 'Heart disease / heart attack'
        },
        {
          label: 'Diabetes',
          value: 'Diabetes'
        },
        {
          label: 'Cancer',
          value: 'Cancer'
        },
        {
          label: 'Liver Disease',
          value: 'Liver Disease'
        },
        {
          label: 'Kidney Disease',
          value: 'Kidney Disease'
        },
        {
          label: 'Epilepsy',
          value: 'Epilepsy'
        },
        {
          label: 'Stroke',
          value: 'Stroke '
        }
      ],
      validationMsg: ''
    },
    mentalHealthList: {
      type: 'checkboxGroup',
      dependency: null,
      key: ['mentalHealth', 'list'],
      label: (
        <h6>
          {' '}
          Please select, if you or any of your immediate family members have ever suffered from any of the following
          conditions before the age of 55?
        </h6>
      ),
      options: [
        {
          label: 'Depression',
          value: 'Depression'
        },
        {
          label: 'Anxiety',
          value: 'Anxiety'
        },
        {
          label: 'Bipolar',
          value: 'Bipolar'
        },
        {
          label: 'PTSD',
          value: 'PTSD'
        },
        {
          label: 'ADHD',
          value: 'ADHD'
        },
        {
          label: 'Eating Disorder',
          value: 'Eating Disorder'
        },
        {
          label: 'OCD',
          value: 'OCD'
        }
      ],
      validationMsg: ''
    },

    hadCovid: {
      dependency: null,
      type: 'switch',
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      key: ['covidInfo', 'hadCovid'],
      label: 'Have you contracted Covid-19?',
      options: Option,
      required: false,
      validationMsg: ''
    },
    fullyVaccinated: {
      dependency: null,
      type: 'switch',
      key: ['covidInfo', 'fullyVaccinated'],
      label: 'Have you been fully vaccinated against Covid-19?',
      checkedChildren: 'Yes',
      uncheckedChildren: 'No',
      options: Option,
      required: false,
      validationMsg: ''
    },
    lastVaccinatedDate: {
      dependency: ['covidInfo', 'fullyVaccinated'],
      type: 'datePicker',
      key: ['covidInfo', 'lastVaccinatedDate'],
      label: <h6>When was your last Covid-19 vaccination?</h6>,
      required: true,
      validationMsg: 'Please provide vaccination date',
      customProps: {
        picker: 'year',
        format: 'YYYY'
      }
    }
  },
  // DATA TEMPLATE //
  dataTemplate: {
    lifestyle: {
      alcoholConsumption: false,
      alcoholConsumptionLevel: '',
      smoking: false,
      smokingLevel: '',
      smokingYears: '',
      vaping: false,
      vapingLevel: '',
      vapingYears: 0,
      diet: false,
      dietLevel: '',
      physicalActivity: '',
      waterIntake: '',
      screenTime: '',
      sleepHours: '',
      stress: ''
    },
    renalCare: {
      comorbidities: '',
      hospitalAdmissions: '',
      transfusions: '',
      transplantHistory: '',
      immuneModulatorTherapies: '',
      covidHistory: '',
      vaccinations: '',
      antibioticExposure: '',
      initiationOfDialysis: '',
      indicationOfDialysis: '',
      modesOfRRT: '',
      vascularAccessPoints: '',
      vascularAccessPointsImageName: '',
      vascularAccessPointsImage: '',
      catheterRelatedInfections: '',
      adverseEvents: ''
    },
    allergies: {
      food: [],
      meds: [],
      other: []
    },
    chronicIllnesses: {
      list: []
    },
    familyHistory: {
      list: []
    },
    mentalHealth: {
      list: []
    },
    medications: {
      list: []
    },
    surgicalHistory: {
      list: []
    },
    covidInfo: {
      hadCovid: false,
      fullyVaccinated: false,
      lastVaccinatedDate: ''
    }
  }
}

export default MedicalHistoryFormTemplates
