import { Card, Col, Space } from 'antd'
import React from 'react'
import { hexToRGBA } from '../../../../../lib/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomRow from '../../../../../reusable-components/CustomRow'
import RenderFormItem from './RenderFormItem'

const FormSection = ({ category, headerColor, icon, title, selectedItems, activeTab, isAddingTemplate, form }) => {
  if (!selectedItems.length) return null

  return (
    <Col
      span={24}
      style={{
        display: activeTab === category || activeTab === 'preview' || activeTab === 'templates' ? 'block' : 'none'
      }}
    >
      <Card
        styles={{ header: { background: hexToRGBA(headerColor, 0.2) } }}
        title={
          <Space>
            <FontAwesomeIcon icon={icon} style={{ color: headerColor }} />
            {title}
          </Space>
        }
        size='small'
        type='inner'
      >
        <CustomRow wrap gutter={[8, 8]}>
          {selectedItems
            .sort((a, b) => (a?.subFields?.length || 0) - (b?.subFields?.length || 0))
            .map((item) => (
              <Col span={8} key={item.key}>
                <RenderFormItem isAddingTemplate={isAddingTemplate} item={item} headerColor={headerColor} form={form} />
              </Col>
            ))}
        </CustomRow>
      </Card>
    </Col>
  )
}

export default FormSection
