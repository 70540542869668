import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { Card, Col, Row, Space, theme, Empty, Button, Badge, Typography, Input, Radio } from 'antd'
import { hexToRGBA } from '../../../../lib/utils'
import { StandardDataPointGraph } from '../../../examination-utils/standard-graph'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlask, faChevronLeft, faChevronRight, faFileMedical, faVial } from '@fortawesome/free-solid-svg-icons'

// Initialize dayjs plugins
dayjs.extend(relativeTime)

const { Title } = Typography

// Constants
const SYMPTOM_TAG_COLOR = '#FF69B4' // Hot pink color for badges

/**
 * ClinicalTesting component for displaying and managing laboratory test results
 * @param {Object} props - Component props
 * @param {Array<Object>} props.patientDataEntries - Array of patient test entries
 * @param {Function} props.setLabReportModalOpen - Function to open lab report modal
 * @param {Object} props.bookingData - Current booking data
 * @returns {JSX.Element} ClinicalTesting component
 */
const ClinicalTesting = React.memo(
  ({ patientDataEntries, setLabReportModalOpen, bookingData, setCareSyncModalOpen }) => {
    const [selectedTest, setSelectedTest] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [testType, setTestType] = useState('all')
    const { data: dataPoints } = useSelector((state) => state.dataPoints)
    const { token } = theme.useToken()
    const tabsContainerRef = useRef(null)

    // Memoize data point templates lookup for better performance
    const dataPointTemplatesMap = useMemo(() => {
      return dataPoints.reduce((acc, point) => {
        acc[point.key] = point
        return acc
      }, {})
    }, [dataPoints])

    const getTestData = useCallback(
      (key) => {
        if (!patientDataEntries?.length) return []
        const dataPointTemplate = dataPointTemplatesMap[key]
        if (!dataPointTemplate) return []

        const subFieldsMap = dataPointTemplate.subFields?.reduce((acc, field) => {
          acc[field.key] = field
          return acc
        }, {})

        return patientDataEntries
          .filter((entry) => entry?.key === key)
          .reduce((acc, entry) => {
            if (Array.isArray(entry.value)) {
              const processedValues = entry.value.map((v) => {
                const fieldTemplate = subFieldsMap?.[v.key]
                return {
                  ...v,
                  isNumeric: !isNaN(parseFloat(v.value)),
                  name: fieldTemplate?.label || v.name || 'Reading'
                }
              })

              acc.push({
                ...entry,
                value: processedValues,
                displayValue: processedValues.map((v) => v.value).join('/'),
                name: dayjs(entry.dateCreated).format('DD-MM-YYYY'),
                subfields: processedValues.map((v) => v.name)
              })
            } else if (!isNaN(parseFloat(entry.value))) {
              acc.push({
                ...entry,
                value: entry.value,
                displayValue: entry.value,
                name: dayjs(entry.dateCreated).format('DD-MM-YYYY')
              })
            }
            return acc
          }, [])
          .sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
      },
      [patientDataEntries, dataPointTemplatesMap]
    )

    const availableTests = useMemo(() => {
      const categoryFilters = {
        rapid: (point) => point.category === 'rapid',
        poc: (point) => point.category === 'poc',
        labs: (point) => point.category === 'labs',
        all: (point) => ['poc', 'rapid', 'labs'].includes(point.category)
      }

      const filterFn = categoryFilters[testType]

      // Create a map to track unique test keys
      const uniqueTests = new Map()

      dataPoints.filter(filterFn).forEach((point) => {
        const data = getTestData(point.key)
        if (data.length > 0 && !uniqueTests.has(point.key)) {
          uniqueTests.set(point.key, {
            title: point.label,
            key: point.key,
            data,
            suffix: point.suffix
          })
        }
      })

      // Convert map values to array
      return Array.from(uniqueTests.values())
    }, [dataPoints, getTestData, testType])

    const filteredTests = useMemo(() => {
      if (!searchTerm) return availableTests
      const lowerSearchTerm = searchTerm.toLowerCase()
      return availableTests.filter((test) => test.title.toLowerCase().includes(lowerSearchTerm))
    }, [availableTests, searchTerm])

    useEffect(() => {
      if (filteredTests.length > 0) {
        const testWithData = filteredTests.find((test) => test.data && test.data.length > 0)
        setSelectedTest(testWithData || null)
      } else {
        setSelectedTest(null)
      }
    }, [filteredTests, testType])

    const formatTestValue = useCallback(
      (test) => {
        if (!test?.data?.length) return { text: 'No data', status: 'normal', timestamp: null }
        const latestData = test.data[test.data.length - 1]

        if (Array.isArray(latestData.value)) {
          return {
            text: 'Click to view',
            status: 'normal',
            timestamp: latestData.dateCreated
          }
        }

        // Determine status based on reference ranges (simplified example)
        // In a real app, you would have more sophisticated logic here
        let status = 'normal'
        const numValue = parseFloat(latestData.displayValue)
        if (!isNaN(numValue)) {
          // This is a simplified example - you would have proper reference ranges per test
          const template = dataPointTemplatesMap[test.key]
          if (template?.min !== undefined && template?.max !== undefined) {
            if (numValue < template.min || numValue > template.max) {
              status = numValue < template.min * 0.8 || numValue > template.max * 1.2 ? 'danger' : 'warning'
            }
          }
        }

        return {
          text: `${latestData.displayValue}${test.suffix ? ` ${test.suffix}` : ''}`,
          status,
          timestamp: latestData.dateCreated
        }
      },
      [dataPointTemplatesMap]
    )

    const isNumericValue = useCallback((value) => {
      const numValue = parseFloat(value)
      return (
        !isNaN(numValue) && !String(value).includes('+') && !String(value).includes('-') && typeof numValue === 'number'
      )
    }, [])

    const checkAllNumeric = useCallback(
      (record) => {
        if (Array.isArray(record.value)) {
          return record.value.every((v) => v.isNumeric && isNumericValue(v.value))
        }
        return isNumericValue(record.value)
      },
      [isNumericValue]
    )

    const renderGraph = useMemo(() => {
      if (!selectedTest?.data.length) return null

      const isAllNumeric = selectedTest.data.every(checkAllNumeric)
      if (!isAllNumeric) return null

      return (
        <StandardDataPointGraph
          data={{
            records: selectedTest.data.map((record) => ({
              ...record,
              value: Array.isArray(record.value)
                ? record.value.map((v) => parseFloat(v.value))
                : parseFloat(record.value)
            })),
            key: selectedTest.key,
            suffix: selectedTest.suffix
          }}
          dataTemplate={{
            ...dataPointTemplatesMap[selectedTest.key],
            label: selectedTest.title,
            suffix: selectedTest.suffix
          }}
        />
      )
    }, [selectedTest, checkAllNumeric, dataPointTemplatesMap])

    // Scroll the tabs container left or right
    const scrollTabs = (direction) => {
      if (tabsContainerRef.current) {
        const container = tabsContainerRef.current
        const scrollAmount = direction === 'left' ? -200 : 200
        container.scrollBy({ left: scrollAmount, behavior: 'smooth' })
      }
    }

    const hasAnyData = filteredTests.length > 0
    const currentBookingTestCount = filteredTests.filter(
      (test) => test.data && test.data.length > 0 && test.data[0]?.bookingId === bookingData?._id
    ).length

    // Define status colors
    const statusColors = {
      normal: '#FF69B4', // Changed from token.colorPrimary to pink secondary color
      warning: token.colorWarning,
      danger: token.colorError
    }

    return (
      <Card
        title={
          <Space>
            <FontAwesomeIcon
              icon={faVial}
              style={{
                color: token.colorPrimary,
                fontSize: '16px'
              }}
            />
            <Title level={5} style={{ margin: 0 }}>
              Blood Tests
            </Title>
            {hasAnyData && (
              <Badge
                count={currentBookingTestCount}
                style={{
                  backgroundColor: SYMPTOM_TAG_COLOR,
                  marginLeft: 8
                }}
              />
            )}
          </Space>
        }
        size='small'
        styles={{
          header: {
            background: hexToRGBA(token.colorPrimary, 0.08),
            borderBottom: `1px solid ${hexToRGBA(token.colorPrimary, 0.1)}`,
            padding: '12px 16px'
          },
          body: {
            padding: '16px',
            overflow: 'hidden' // Prevent content from causing horizontal scroll
          }
        }}
        style={{
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
          overflow: 'hidden' // Ensure card content doesn't overflow
        }}
      >
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Space style={{ width: '100%', overflow: 'hidden' }} wrap>
              <Input.Search
                placeholder='Search tests...'
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: '300px' }}
              />
              <Radio.Group value={testType} onChange={(e) => setTestType(e.target.value)} buttonStyle='solid'>
                <Radio.Button value='all'>All Tests</Radio.Button>
                <Radio.Button value='rapid'>Rapid</Radio.Button>
                <Radio.Button value='poc'>POC</Radio.Button>
                <Radio.Button value='labs'>Labs</Radio.Button>
              </Radio.Group>
              <Button
                type='primary'
                onClick={() => {
                  setLabReportModalOpen(true)
                }}
              >
                Lab Report
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  setCareSyncModalOpen(true)
                }}
              >
                CareSync
              </Button>
            </Space>
          </Col>
          <Col span={24} style={{ overflow: 'hidden' }}>
            {filteredTests.length > 0 ? (
              <div style={{ position: 'relative', overflow: 'hidden' }}>
                {/* Scroll left button */}
                <Button
                  type='text'
                  icon={<FontAwesomeIcon icon={faChevronLeft} />}
                  onClick={() => scrollTabs('left')}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    background: 'rgba(255,255,255,0.8)',
                    boxShadow: '0 0 5px rgba(0,0,0,0.1)'
                  }}
                />
                <div
                  ref={tabsContainerRef}
                  style={{
                    display: 'flex',
                    overflowX: 'auto',
                    scrollbarWidth: 'none', // Hide scrollbar for Firefox
                    msOverflowStyle: 'none', // Hide scrollbar for IE/Edge
                    padding: '0 30px', // Add padding for scroll buttons
                    background: '#f5f5f5',
                    gap: 8,
                    border: '5px #f5f5f5 solid',
                    borderBottom: 'none',
                    width: '100%',
                    boxSizing: 'border-box' // Include padding in width calculation
                  }}
                  className='hide-scrollbar' // For custom styling in CSS
                >
                  {filteredTests.map((test) => {
                    const isSelected = selectedTest?.key === test.key
                    const formattedValue = formatTestValue(test)
                    const hasData = test.data && test.data.length > 0

                    return (
                      <div
                        key={test.key}
                        onClick={() => setSelectedTest(test)}
                        style={{
                          padding: 12,
                          borderBottom: '5px solid',
                          borderRadius: isSelected ? '0 0 4px 4px' : 0,
                          cursor: 'pointer',
                          borderColor: isSelected ? hexToRGBA(token.colorPrimary, 0.8) : '#f5f5f5',
                          background: isSelected ? '#ffffff' : '#f5f5f5',
                          transition: 'all 0.3s ease',
                          minWidth: 150,
                          flexShrink: 0 // Prevent shrinking
                        }}
                      >
                        <Space style={{ gap: 4, width: '100%' }} direction='vertical'>
                          <span style={{ wordBreak: 'break-word' }}>{test.title}</span>
                          <div
                            style={{
                              color: statusColors[formattedValue.status],
                              fontWeight: 'normal',
                              fontSize: '14px'
                            }}
                          >
                            {formattedValue.text}
                            {hasData && formattedValue.status !== 'normal' && (
                              <Badge
                                status={formattedValue.status === 'danger' ? 'error' : 'warning'}
                                style={{ marginLeft: 4 }}
                              />
                            )}
                          </div>
                          {hasData && formattedValue.timestamp && (
                            <div style={{ fontSize: '11px', color: '#999', marginTop: 2 }}>
                              {dayjs(formattedValue.timestamp).fromNow()}
                            </div>
                          )}
                        </Space>
                      </div>
                    )
                  })}
                </div>
                {/* Scroll right button */}
                <Button
                  type='text'
                  icon={<FontAwesomeIcon icon={faChevronRight} />}
                  onClick={() => scrollTabs('right')}
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    background: 'rgba(255,255,255,0.8)',
                    boxShadow: '0 0 5px rgba(0,0,0,0.1)'
                  }}
                />
              </div>
            ) : (
              <Empty description='Nothing has been recorded' />
            )}
          </Col>

          {selectedTest && (
            <>
              <Col span={24} style={{ overflow: 'hidden' }}>
                <div style={{ width: '100%', overflow: 'hidden' }}>{renderGraph}</div>
              </Col>
            </>
          )}
        </Row>
      </Card>
    )
  }
)

ClinicalTesting.displayName = 'ClinicalTesting'

export default ClinicalTesting
