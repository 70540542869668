import React from 'react'
import { Button, Form } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import '../styles/medicationCard.css'

const RouteField = ({ selectedRoute, availableRoutes, onSelect, onClear, className }) => {
  return (
    <div className="field-container">
      <div className="field-label">Route</div>
      <div className={`medication-field ${className || ''}`} style={{ border: className ? '1px solid #ff4d4f' : '1px solid #d9d9d9' }}>
        {!selectedRoute ? (
          <div className='medication-field-options'>
            {availableRoutes.map((route) => (
              <Button
                key={route.value}
                type='text'
                onClick={() => onSelect(route.value)}
                style={{ padding: '4px 15px', height: 'auto' }}
              >
                {route.label}
              </Button>
            ))}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}
          >
            <div className='medication-field-selected'>
              <CheckOutlined />
              <span>{selectedRoute}</span>
            </div>
            <Button type='text' size='small' onClick={onClear} style={{ padding: '0 4px', height: 'auto' }}>
              ×
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default RouteField
