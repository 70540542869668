import React, { useState } from 'react'
import { Form, Select, Button, Tag, Input, Modal, Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPills,
  faSearch,
  faShieldAlt,
  faAppleAlt,
  faAllergies,
  faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import uniqBy from 'lodash'
import '../style/MobAllergies.css'
import {
  ALLERGY_CATEGORIES,
  COMMON_ALLERGIES,
  COMMON_FOOD_ALLERGIES,
  COMMON_GENERAL_ALLERGIES
} from '../../utils/constants'
import useAllergies from '../../hooks/useAllergies'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import MobSectionHeader from './MobSectionHeader'

const MobAllergies = ({ clientForm, loading, setFacet, setSaving }) => {
  const {
    addFoodAllergy,
    addGeneralAllergy,
    additionalFoodAllergies,
    additionalGeneralAllergies,
    additionalMedications,
    debouncedNappiFilter,
    handleFoodOptionClick,
    handleGeneralOptionClick,
    handleOptionClick,
    handleSave,
    isModalVisible,
    nappiData,
    nappiLoading,
    removeFoodAllergy,
    removeGeneralAllergy,
    selectedAllergies,
    selectedCategory,
    selectedFoodAllergies,
    selectedGeneralAllergies,
    setAdditionalMedications,
    setIsModalVisible,
    setNappiData,
    setSelectedCategory
  } = useAllergies({ clientForm, setSaving })

  const [newFoodAllergy, setNewFoodAllergy] = useState('')
  const [newGeneralAllergy, setNewGeneralAllergy] = useState('')

  const addMedication = (name) => {
    if (name && !additionalMedications.includes(name)) {
      setAdditionalMedications([...additionalMedications, name])
    }
  }

  const removeMedication = (name) => {
    setAdditionalMedications(additionalMedications.filter((med) => med !== name))
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleAddFoodAllergy = () => {
    if (newFoodAllergy.trim()) {
      addFoodAllergy(newFoodAllergy.trim())
      setNewFoodAllergy('')
      setIsModalVisible(false)
    }
  }

  const handleAddGeneralAllergy = () => {
    if (newGeneralAllergy.trim()) {
      addGeneralAllergy(newGeneralAllergy.trim())
      setNewGeneralAllergy('')
      setIsModalVisible(false)
    }
  }

  return (
    <>
      {!selectedCategory ? (
        <>
          <MobSectionHeader
            icon={faShieldAlt}
            title='Allergies'
            subtitle='Select a category to manage your allergies'
          />
          <Row gutter={[16, 16]}>
            {ALLERGY_CATEGORIES.map((category) => (
              <Col span={24}>
                <div
                  key={category.id}
                  className='category-card'
                  onClick={() => setSelectedCategory(category.id)}
                  style={{ '--category-color': category.color }}
                >
                  <div className='category-content'>
                    <h3>
                      <FontAwesomeIcon icon={category.icon} />
                      {category.label}
                    </h3>
                    <p>{category.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </>
      ) : selectedCategory === 'medications' ? (
        <Row gutter={[16, 16]}>
          <MobSectionHeader icon={faPills} title='Medications' subtitle="Select any medications you're allergic to" />

          <Col span={24}>
            <div className='allergy-section allergies-grid'>
              {COMMON_ALLERGIES.map((allergy) => (
                <div
                  key={allergy.id}
                  className='allergy-card'
                  role='checkbox'
                  onClick={() => handleOptionClick(allergy)}
                  aria-checked={selectedAllergies.includes(allergy.id)}
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleOptionClick(allergy)
                    }
                  }}
                >
                  <div className='allergy-icon'>
                    <FontAwesomeIcon icon={faPills} />
                  </div>
                  <span className='allergy-name'>{allergy.label}</span>
                  <Form.Item
                    name={['allergies', 'meds', allergy.id]}
                    valuePropName='checked'
                    style={{ display: 'none' }}
                  >
                    <input type='checkbox' />
                  </Form.Item>
                </div>
              ))}
            </div>
          </Col>
          <>
            <Col span={24}>
              <Button
                style={{
                  width: '100%',
                  background: '#00A6E3',
                  borderRadius: '8px',
                  border: 'none',
                  height: '48px',
                  fontSize: '16px',
                  fontWeight: 500
                }}
                type='primary'
                onClick={showModal}
              >
                Add Medications
              </Button>
            </Col>

            <Col span={24}>
              <Row gutter={[16, 16]}>
                {additionalMedications.map((medication, index) => (
                  <Col span={24}>
                    <center>
                      {' '}
                      <Tag key={index} closable onClose={() => removeMedication(medication)} className='allergy-tag'>
                        {medication}
                      </Tag>
                    </center>
                  </Col>
                ))}
              </Row>
            </Col>

            <Modal
              title='Search and Add Medications'
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              className='mob-allergies-modal dark-modal'
            >
              <Select
                showSearch
                placeholder='Search medications...'
                loading={nappiLoading}
                onSearch={debouncedNappiFilter}
                onSelect={(value) => {
                  addMedication(value)
                  setNappiData([])
                }}
                options={uniqBy(nappiData, 'name').map((i) => ({
                  value: i.name,
                  label: i.name
                }))}
                className='mob-allergies-select allergy-search'
                notFoundContent={
                  nappiLoading ? (
                    <div className='search-loading'>
                      <FontAwesomeIcon icon={faPills} spin />
                      <span>Searching...</span>
                    </div>
                  ) : null
                }
              />
            </Modal>
          </>
        </Row>
      ) : selectedCategory === 'food' ? (
        <Row gutter={[16, 16]}>
          <MobSectionHeader icon={faAppleAlt} title='Food Allergies' subtitle="Select any foods you're allergic to" />
          <Col span={24}>
            <div className='allergy-section allergies-grid'>
              {COMMON_FOOD_ALLERGIES.map((allergy) => (
                <div
                  key={allergy.id}
                  className={`allergy-card ${selectedFoodAllergies.includes(allergy.id) ? 'selected' : ''}`}
                  role='checkbox'
                  onClick={() => handleFoodOptionClick(allergy)}
                  aria-checked={selectedFoodAllergies.includes(allergy.id)}
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleFoodOptionClick(allergy)
                    }
                  }}
                >
                  <div className='allergy-icon'>
                    <FontAwesomeIcon icon={faAppleAlt} />
                  </div>
                  <span className='allergy-name'>{allergy.label}</span>
                  <Form.Item
                    name={['allergies', 'food', allergy.id]}
                    valuePropName='checked'
                    style={{ display: 'none' }}
                  >
                    <input type='checkbox' />
                  </Form.Item>
                </div>
              ))}
            </div>
          </Col>

          <Col span={24}>
            <Button
              style={{
                width: '100%',
                background: '#00A6E3',
                borderRadius: '8px',
                border: 'none',
                height: '48px',
                fontSize: '16px',
                fontWeight: 500
              }}
              type='primary'
              onClick={() => setIsModalVisible(true)}
            >
              Add Food Allergies
            </Button>
          </Col>

          <Col span={24}>
            <Row gutter={[16, 16]}>
              {additionalFoodAllergies.map((food, index) => (
                <Col span={24} key={index}>
                  <center>
                    <Tag closable onClose={() => removeFoodAllergy(food)} className='allergy-tag'>
                      {food}
                    </Tag>
                  </center>
                </Col>
              ))}
            </Row>
          </Col>

          <Modal
            title='Search and Add Food Allergies'
            visible={isModalVisible}
            onOk={handleAddFoodAllergy}
            onCancel={() => setIsModalVisible(false)}
            className='mob-allergies-modal dark-modal'
          >
            <Input
              placeholder='Type food allergy and press Enter'
              value={newFoodAllergy}
              onChange={(e) => setNewFoodAllergy(e.target.value)}
              onPressEnter={handleAddFoodAllergy}
              className='allergy-input'
            />
          </Modal>
        </Row>
      ) : selectedCategory === 'general' ? (
        <Row gutter={[16, 16]}>
          <MobSectionHeader
            icon={faAllergies}
            title='General Allergies'
            subtitle='Select any environmental or other allergies'
          />
          <Col span={24}>
            <div className='allergy-section allergies-grid'>
              {COMMON_GENERAL_ALLERGIES.map((allergy) => (
                <div
                  key={allergy.id}
                  className='allergy-card'
                  role='checkbox'
                  onClick={() => handleGeneralOptionClick(allergy)}
                  aria-checked={selectedGeneralAllergies.includes(allergy.id)}
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleGeneralOptionClick(allergy)
                    }
                  }}
                >
                  <div className='allergy-icon'>
                    <FontAwesomeIcon icon={faAllergies} />
                  </div>
                  <span className='allergy-name'>{allergy.label}</span>
                  <Form.Item
                    name={['allergies', 'general', allergy.id]}
                    valuePropName='checked'
                    style={{ display: 'none' }}
                  >
                    <input type='checkbox' />
                  </Form.Item>
                </div>
              ))}
            </div>
          </Col>

          <Col span={24}>
            <Button
              style={{
                width: '100%',
                background: '#00A6E3',
                borderRadius: '8px',
                border: 'none',
                height: '48px',
                fontSize: '16px',
                fontWeight: 500
              }}
              type='primary'
              onClick={() => setIsModalVisible(true)}
            >
              Add General Allergies
            </Button>
          </Col>

          <Col span={24}>
            <Row gutter={[16, 16]}>
              {additionalGeneralAllergies.map((allergy, index) => (
                <Col span={24} key={index}>
                  <center>
                    <Tag closable onClose={() => removeGeneralAllergy(allergy)} className='allergy-tag'>
                      {allergy}
                    </Tag>
                  </center>
                </Col>
              ))}
            </Row>
          </Col>

          <Modal
            title='Search and Add General Allergies'
            visible={isModalVisible}
            onOk={handleAddGeneralAllergy}
            onCancel={() => setIsModalVisible(false)}
            className='mob-allergies-modal dark-modal'
          >
            <Input
              placeholder='Type general allergy and press Enter'
              value={newGeneralAllergy}
              onChange={(e) => setNewGeneralAllergy(e.target.value)}
              onPressEnter={handleAddGeneralAllergy}
              className='allergy-input'
            />
          </Modal>
        </Row>
      ) : (
        <div className='coming-soon'>
          <FontAwesomeIcon icon={selectedCategory === 'food' ? faAppleAlt : faAllergies} />
          <h3>{selectedCategory === 'food' ? 'Food Allergies' : 'General Allergies'}</h3>
          <p>This section is coming soon</p>
          <Button onClick={() => setSelectedCategory(null)} className='back-button'>
            Go Back
          </Button>
        </div>
      )}
      {!loading ? (
        <PrevNextFloatingButtons
          loading={loading}
          prevOnly={!selectedCategory}
          prevText={selectedCategory ? 'Go Back' : 'Cancel'}
          nextText={'Save'}
          onPrev={() => (selectedCategory ? setSelectedCategory(null) : setFacet(null))}
          onNext={() => handleSave(true)}
        />
      ) : undefined}
    </>
  )
}

export default MobAllergies
