import { createSlice, current } from '@reduxjs/toolkit'
import { dataPointsState, examTemplatesState } from './states'

const ExamTemplatesReducer = createSlice({
  name: 'examCapturingReducer',
  initialState: examTemplatesState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setRecords: (state, action) => {
      state.data = action.payload
      state.loading = false
      state.error = null
    },
    submitRecord: (state, action) => {
      state.data.push(action.payload)
    },
    updateRecord: (state, action) => {
      let tmpIndex = current(state).data.findIndex((record) => record.id === action.payload.id)
      state.data.splice(tmpIndex, 1, action.payload)
    },
    deleteRecord: (state, action) => {
      let tmpIndex = current(state).data.findIndex((record) => record.id === action.payload)
      state.data.splice(tmpIndex, 1)
    }
  }
})

const DataPointsReducer = createSlice({
  name: 'dataPointsReducer',
  initialState: dataPointsState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setRecords: (state, action) => {
      state.data = action.payload
      state.loading = false
      state.error = null
    },
    submitRecord: (state, action) => {
      state.data.push(action.payload)
    },
    updateRecord: (state, action) => {
      let tmpIndex = current(state).data.findIndex((record) => record.id === action.payload.id)
      state.data.splice(tmpIndex, 1, action.payload)
    },
    deleteRecord: (state, action) => {
      let tmpIndex = current(state).data.findIndex((record) => record.id === action.payload)
      state.data.splice(tmpIndex, 1)
    }
  }
})

export { ExamTemplatesReducer, DataPointsReducer }
