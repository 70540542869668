import React from 'react'
import { Spin } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPills } from '@fortawesome/free-solid-svg-icons'

const LoadingOverlay = ({ loading, text = '', icon }) => {
  if (!loading) return null

  return (
    <div className='loading-overlay' style={{ background: 'linear-gradient(to bottom, #f5f5f5, #ffffff)' }}>
      <div className='loading-content'>
        {icon ? (
          <div style={{ animation: 'flipHorizontal 2s linear infinite' }}>{icon}</div>
        ) : (
          <FontAwesomeIcon
            icon={faPills}
            style={{
              fontSize: 40,
              color: '#10b2e1',
              animation: 'flipHorizontal 2s linear infinite'
            }}
          />
        )}
        <span style={{ color: '#000000', fontSize: 18, fontWeight: 500 }}>{text || 'Loading...'}</span>
      </div>
    </div>
  )
}

export default LoadingOverlay
