export const getUnfilledCount = (category, selectedDataPoints, form) => {
  const selectedItems = selectedDataPoints
    .map((jsonData) => {
      try {
        return JSON.parse(jsonData)
      } catch (e) {
        console.error('Failed to parse JSON data:', e)
        return null
      }
    })
    .filter((selection) => selection && selection.category === category)

  const filledItems = selectedItems.filter((item) => form.getFieldValue(item.key))
  return selectedItems.length - filledItems.length
}

export const getFilteredDataPoints = (dataPoints, searchTerm, selectedDataPoints) => {
  const selectedSet = new Set(selectedDataPoints)

  return dataPoints
    .filter((point) => {
      const searchLower = searchTerm.toLowerCase()
      return point.label.toLowerCase().includes(searchLower) || point.key.toLowerCase().includes(searchLower)
    })
    .sort((a, b) => {
      // First sort by selection status
      const aSelected = selectedSet.has(JSON.stringify(a))
      const bSelected = selectedSet.has(JSON.stringify(b))

      if (aSelected && !bSelected) return -1
      if (!aSelected && bSelected) return 1

      // Then sort alphabetically by label
      return a.label.localeCompare(b.label)
    })
}

export const parseFieldData = (data) => {
  if (!data || typeof data !== 'string') return { options: [] }
  try {
    return JSON.parse(data)
  } catch (e) {
    console.error('Failed to parse field data:', e)
    return { options: [] }
  }
}

export const formatValidationError = (error, availableDataPoints) => {
  const { errorFields } = error
  if (!errorFields?.length) return 'Validation failed'

  const firstError = errorFields[0]
  const fieldKey = firstError.name[0]
  const dataPoint = availableDataPoints.find((dp) => dp.key === fieldKey)

  if (!dataPoint) return firstError.errors[0]

  const category = dataPoint.category.charAt(0).toUpperCase() + dataPoint.category.slice(1)
  return `${category}: ${firstError.errors[0]}`
}
