import React from 'react'
import { Button, Col, Row } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faBook, faPlus } from '@fortawesome/free-solid-svg-icons'
import MobileMacroOverview from './MobileMacroOverview'
import MobileNewImageUpload from './MobileNewImageUpload'
import MobileMacroGraphs from './MobileMacroGraphs'
import MobileHealthyAlternatives from './MobileHealthyAlternatives'
import '../style/MobileMacroTracker.css'
import useMobileTracker from '../hooks/useMobileTracker'

const MobileMacroTracker = ({ userRef, isDoctor }) => {
  const {
    imageUploadModalOpen,
    setImageUploadModalOpen,
    savedMeals,
    setSavedMeals,
    limits,
    handleGetMetaData,
    capturedImage,
    setCapturedImage,
    showGraphs,
    trackerData,
    loading,
    dateRange,
    setDateRange,
    setShowGraphs,
    setShowHealthyAlternatives,
    showHealthyAlternatives,
    handleCameraCapture,
    isCapturing
  } = useMobileTracker({ userRef })

  return (
    <Row>
      <Col span={24}>
        {imageUploadModalOpen ? (
          <MobileNewImageUpload
            setModalOpen={setImageUploadModalOpen}
            savedMeals={savedMeals}
            setSavedMeals={setSavedMeals}
            limits={limits}
            handleRefresh={handleGetMetaData}
            capturedImage={capturedImage}
            setCapturedImage={setCapturedImage}
          />
        ) : showGraphs ? (
          <MobileMacroGraphs
            trackerData={trackerData}
            loading={loading}
            dateRange={dateRange}
            setDateRange={setDateRange}
            onBack={() => setShowGraphs(false)}
          />
        ) : showHealthyAlternatives ? (
          <MobileHealthyAlternatives
            userRef={userRef}
            isDoctor={isDoctor}
            onBack={() => setShowHealthyAlternatives(false)}
          />
        ) : (
          <>
            <MobileMacroOverview
              limits={limits}
              setImageUploadModalOpen={setImageUploadModalOpen}
              setShowGraphs={setShowGraphs}
              setShowHealthyAlternatives={setShowHealthyAlternatives}
              onCameraCapture={handleCameraCapture}
              handleGetMetaData={handleGetMetaData}
            />

            <Button type='primary' onClick={() => setShowGraphs(true)}>
              <FontAwesomeIcon icon={faChartLine} /> View Progress
            </Button>

            <Button type='primary' onClick={() => setShowHealthyAlternatives(true)}>
              <FontAwesomeIcon icon={faBook} /> View Recipes
            </Button>
            {!isCapturing && (
              <Button type='primary' onClick={() => setImageUploadModalOpen(true)}>
                <FontAwesomeIcon icon={faPlus} /> Take Photos
              </Button>
            )}
          </>
        )}
      </Col>
    </Row>
  )
}

export default MobileMacroTracker
