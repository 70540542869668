import React from 'react'
import { Button, Card, Tag, theme, Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import '../../styling/TestCard.css'
import CoreEnums from '../../../../../../core/utils/enums'
import coreReducer from '../../../../../../core/utils/reducer'
import PatClinicConsultBooking from '../../../../Clinic Consultation'
import { useDispatch } from 'react-redux'
import { hexToRGBA } from '../../../../../lib/utils'

const { Text } = Typography

const formatPrice = (price) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ZAR'
  }).format(price)
}

const formatTestTime = (minutes) => {
  if (!minutes) return 'Processing time unavailable'

  if (minutes < 60) {
    return `${minutes} minutes`
  }

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  if (remainingMinutes === 0) {
    return `${hours} hour${hours > 1 ? 's' : ''}`
  }

  return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes} min`
}

const TestCard = ({ test, onClick, onViewDetails }) => {
  const { code, name, description, price, processingTime, availability, featured } = test
  const dispatch = useDispatch()
  const handleClinicBooking = (key) => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.MY_BOOKINGS}_new_clinic_booking`,
        closable: true,
        label: CoreEnums.tabKeys.MY_BOOKINGS,
        children: <PatClinicConsultBooking isCaresync={true} testKey={key} />
      })
    )
  }
  const { token } = theme.useToken()
  return (
    <Card>
      <div className='cs-test-card'>
        <div className='cs-test-card__header'>
          <div style={{ background: hexToRGBA(token.colorPrimary, 0.1) }} className='cs-test-card__title-section'>
            <Text className='cs-test-card__code'>{code}</Text>
            <Text className='cs-test-card__name'>{name}</Text>
          </div>
        </div>

        <div className='cs-test-card__content' onClick={onClick}>
          <div className='cs-test-card__details'>
            <div className='cs-test-card__description'>{description || 'No description available'}</div>

            <div className='cs-test-card__meta'>
              <div style={{ color: token.colorSuccess }}>{formatPrice(price)}</div>
              <div className='cs-test-card__processing-time'>{formatTestTime(processingTime)}</div>
            </div>
          </div>

          <div className='cs-test-card__status'>
            <center style={{ marginTop: 12 }}>
              <Button type='primary' onClick={() => handleClinicBooking(code)}>
                Book Now
              </Button>
            </center>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default TestCard
