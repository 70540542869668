import Agilite from 'agilite'
import store from '../../../../../store'
import CoreEnums from '../../../../../core/utils/enums'
import { documentsTypes } from './enums'
import { documentsConnectorProfile, pdfGenerationEnums } from './enums.js'
import dayjs from 'dayjs'
import { blobToBase64, handleError } from '../../../../lib/utils.js'
import { message } from 'antd'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const createDocument = (base64, data, type) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null
    let timestamp = new Date()

    const payload = {}

    ;(async () => {
      payload.name = data.name
      payload.value = base64
      payload.type = type
      payload.audit = {
        createdAt: timestamp,
        createdBy: store.getState().auth.agiliteUser._id,
        modifiedAt: timestamp,
        modifiedBy: store.getState().auth.agiliteUser._id
      }
      payload.userRef = data.userRef
      payload.medicalProfRef = store.getState().auth.agiliteUser._id
      payload.bookingRef = data.bookingRef

      try {
        response = await agilite.Connectors.execute(
          documentsConnectorProfile.profileKeys.DOCUMENTS,
          documentsConnectorProfile.routeKeys.CREATE,
          {
            data: JSON.stringify(payload)
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

// export const updateCarePlanTemplate = (data) => {
//   return new Promise((resolve, reject) => {
//     let response = null
//     let errorMessage = null
//     let timestamp = Date()

//     ;(async () => {
//       try {
//         data.modifiedAt = timestamp
//         data.modifiedBy = store.getState().auth.agiliteUser._id

//         response = await agilite.Connectors.execute(
//           CarePlansEnums.profileKeys.CARE_PLANS_TEMPLATES,
//           CarePlansEnums.routeKeys.UPDATE,
//           {
//             recordId: data._id,
//             data: JSON.stringify(data)
//           }
//         )
//         resolve(response.data)
//       } catch (e) {
//         if (e.response?.data?.errorMessage) {
//           errorMessage = e.response.data.errorMessage
//         } else if (e.message) {
//           errorMessage = e.message
//         } else {
//           errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
//         }

//         reject(errorMessage)
//       }
//     })()
//   })
// }

export const readDocuments = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          documentsConnectorProfile.profileKeys.DOCUMENTS,
          documentsConnectorProfile.routeKeys.READ,
          {
            filter: JSON.stringify(filter)
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const deleteDocument = (documentId) => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(
          documentsConnectorProfile.profileKeys.DOCUMENTS,
          documentsConnectorProfile.routeKeys.DELETE,
          {
            recordId: documentId
          }
        )

        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const createMiscDocument = async (base64) => {
  let newRecord = null
  try {
    newRecord = await createDocument(base64, documentsTypes.OTHER)
    return newRecord
  } catch (error) {
    message.error(handleError(error))
  }
}

export const createScript = async (base64) => {
  let newRecord = null
  try {
    newRecord = await createDocument(base64, documentsTypes.SCRIPT)
    return newRecord
  } catch (error) {
    message.error(handleError(error))
  }
}

export const createReferral = async (base64, record) => {
  let newRecord = null
  try {
    newRecord = await createDocument(base64, record, documentsTypes.REFERRAL)
    return newRecord
  } catch (error) {
    message.error(handleError(error))
  }
}

export const createSickNote = async (base64, data) => {
  let newRecord = null
  try {
    newRecord = await createDocument(base64, data, documentsTypes.SICK_NOTE)
    return newRecord
  } catch (error) {}
}

export const generateSickNote = (data, record, signature, template, patient) => {
  const handleGeneratePronoun1 = () => {
    if (patient) {
      switch (patient.gender) {
        case 'female':
          return 'she'
        default:
          return 'he'
      }
    } else {
      switch (data.patientRecord.gender) {
        case 'female':
          return 'she'
        default:
          return 'he'
      }
    }
  }
  const handleGeneratePronoun2 = () => {
    if (patient) {
      switch (patient.gender) {
        case 'female':
          return 'her'
        default:
          return 'his'
      }
    } else {
      switch (data.patientRecord.gender) {
        case 'female':
          return 'her'
        default:
          return 'his'
      }
    }
  }

  return new Promise((resolve, reject) => {
    ;(async () => {
      let templateResponse = null
      let pdfResponse = null
      let fileResponse = null
      let templateData = null

      try {
        if (data) {
          const clinicDetails = store.getState().clinics.data.find((clinic) => clinic._id === data.clinicRef)
          templateData = {
            bookingDate: dayjs(data.bookingDate).format('DD MMMM YYYY'),
            startDate: dayjs(record.startDate).format('DD MMM YYYY'),
            endDate: dayjs(record.endDate).format('DD MMM YYYY'),
            pronoun1: handleGeneratePronoun1(),
            pronoun2: handleGeneratePronoun2(),
            signature,
            submitted: dayjs(new Date()).format('DD MMM YYYY'),
            patientName: data.patientRecord.firstName + ' ' + data.patientRecord.lastName,
            professionalName:
              store.getState().auth.agiliteUser.firstName + ' ' + store.getState().auth.agiliteUser.lastName,
            practiceName: clinicDetails?.practiceName,
            practiceRegNo: clinicDetails?.practiceRegNumber,
            practiceNo: clinicDetails?.practiceNumber,
            clinicAddress: clinicDetails?.address,
            clinicPhone: clinicDetails?.phoneNumber,
            logo: store.getState().core.entity.entityLogo,
            familyResponsibility: record?.comments ? record?.comments : '',
            firstLine: record?.familyResponsibility ? 40 : 20,
            secondLine: record?.familyResponsibility ? 40 : 20,
            qualification: store.getState().auth.agiliteUser.extraData.qualification
          }
        } else {
          const clinicDetails = store
            .getState()
            .clinics.data.find((clinic) => store.getState().auth.agiliteUser.extraData.clinics.includes(clinic._id))

          templateData = {
            bookingDate: dayjs().format('DD MMMM YYYY'),
            startDate: dayjs().format('DD MMM YYYY'),
            endDate: dayjs().format('DD MMM YYYY'),
            pronoun1: handleGeneratePronoun1(),
            pronoun2: handleGeneratePronoun2(),
            signature,
            submitted: dayjs(new Date()).format('DD MMM YYYY'),
            patientName: patient.firstName + ' ' + patient.lastName,
            professionalName:
              store.getState().auth.agiliteUser.firstName + ' ' + store.getState().auth.agiliteUser.lastName,
            practiceName: clinicDetails?.practiceName,
            practiceRegNo: clinicDetails?.practiceRegNumber,
            practiceNo: clinicDetails?.practiceNumber,
            clinicAddress: clinicDetails?.address,
            clinicPhone: clinicDetails?.phoneNumber,
            logo: store.getState().core.entity.entityLogo,
            familyResponsibility: record?.comments ? record?.comments : '',
            firstLine: record?.familyResponsibility ? 40 : 20,
            secondLine: record?.familyResponsibility ? 40 : 20,
            qualification: store.getState().auth.agiliteUser.extraData.qualification
          }
        }
        templateResponse = await agilite.Templates.execute(template, templateData)
        pdfResponse = await agilite.Utils.generatePDF(templateResponse.data)
        fileResponse = await agilite.Files.getFile(pdfResponse.data._id)

        const file = new Blob([fileResponse.data], { type: 'application/pdf' })
        const base64data = await blobToBase64(file)

        resolve(base64data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
export const viewStoredPDF = () => {
  const base64PDF = localStorage.getItem('pdfFile')
  if (!base64PDF) {
    console.error('No PDF file found in localStorage')
    return null
  }

  // Create blob from base64
  const byteCharacters = atob(base64PDF.split(',')[1])
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: 'application/pdf' })

  // Return the URL instead of opening in new tab
  return URL.createObjectURL(file)
}
export const downloadReferralLetter = (booking, patientRecord, content, signature, template) => {
  const authUser = store.getState().auth.agiliteUser
  let clinicDetails = null
  if (booking) {
    clinicDetails = store.getState().clinics.data.find((clinic) => clinic._id === booking.clinicRef)
  } else {
    clinicDetails = store.getState().clinics.data.find((clinic) => authUser.extraData.clinics.includes(clinic._id))
  }

  return new Promise((resolve, reject) => {
    ;(async () => {
      let pdfResponse = null
      let templateData = {
        patientName: `${patientRecord.firstName}  ${patientRecord.lastName}`,
        idNo: patientRecord.idNo,
        phoneNumber: patientRecord.phoneNumber,
        medAidName: patientRecord.medicalAid.name,
        medAidPlan: patientRecord.medicalAid.plan,
        medAidNumber: patientRecord.medicalAid.number,
        dependantNumber: patientRecord.medicalAid.dependantNumber,
        date: dayjs(new Date()).format('DD MMM YYYY'),
        practiceName: clinicDetails?.practiceName,
        practiceNo: clinicDetails?.practiceNumber,
        regNo: clinicDetails?.practiceRegNumber,
        clinicAddress: clinicDetails?.address,
        clinicPhone: clinicDetails?.phoneNumber,
        referralData: content.replace(/<p>\s*<br\s*\/?>\s*<\/p>/gi, ''), // Handle both <br> and <br />,
        medProfName: authUser.firstName + ' ' + authUser.lastName,
        qualification: authUser.extraData.qualification,
        entityLogo: store.getState().core.entity.entityLogo.split(',')[1],
        entityIcon: store.getState().core.entity.entityIcon.split(',')[1],
        signatureData: signature.split(',')[1]
      }

      try {
        pdfResponse = await agilite.Connectors.execute(
          pdfGenerationEnums.pdfGeneration.CRAFT_MY_PDF,
          pdfGenerationEnums.pdfGeneration.CREATE_REFERRAL,
          {
            data: JSON.stringify(templateData)
          }
        )

        if (pdfResponse.data && pdfResponse.data.status === 'success') {
          // Convert the PDF URL to base64
          const response = await fetch(pdfResponse.data.file)
          const blob = await response.blob()
          const base64data = await blobToBase64(blob)

          // Resolve with the new record
          resolve(base64data)
        } else {
          reject('Error generating PDF')
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}
const hasValue = (value, placeholder) => {
  if (value) {
    return value
  } else {
    return placeholder
  }
}
// export const sendScript = (agiliteUser, medications) => {
//   return new Promise((resolve, reject) => {
//     ;(async () => {
//       let file = null

//       try {
//         file = await downloadScript(medications)
//         file = await blobToBase64(file)

//         await agilite.Connectors.execute('sendgrid_new', 'send_dynamic', {
//           apiKey: process.env.REACT_APP_SENDGRID_API_KEY,
//           data: JSON.stringify({
//             from: { email: 'noreply@meta-health.co.za' },
//             personalizations: [
//               {
//                 to: [{ email: agiliteUser.email }]
//               }
//             ],
//             template_id: 'd-a8d1c44dcde445beb7965d8a26cacdcc',
//             attachments: [
//               {
//                 content: file.split('base64,')[1],
//                 filename: 'script.pdf',
//                 type: 'application/pdf',
//                 disposition: 'attachment'
//               }
//             ]
//           })
//         })

//         resolve()
//       } catch (e) {
//         reject(e)
//       }
//     })()
//   })
// }
