import React from 'react'
import { motion } from 'framer-motion'
import { Button } from 'antd'
import { ExclamationCircleOutlined, ReloadOutlined, HomeOutlined } from '@ant-design/icons'
import '../styles/ErrorScreen.css'

const ErrorScreen = ({ error, onRetry, onBack }) => {
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      className='error-screen-container'
    >
      <div className='error-card'>
        <ExclamationCircleOutlined className='error-icon' />
        <h2>An Error Occurred</h2>
        <p className='error-message'>
          {error?.message || 'Something went wrong. Please try again or contact support if the problem persists.'}
        </p>
        
        <div className='action-buttons'>
          <Button 
            className='primary-button'
            onClick={onRetry}
            icon={<ReloadOutlined />}
          >
            Try Again
          </Button>
          <Button 
            className='secondary-button'
            onClick={onBack}
            icon={<HomeOutlined />}
          >
            Back to Home
          </Button>
        </div>

        <div className='support-info'>
          Need help? <a href="mailto:support@meta-health.co.za">Contact Support</a>
        </div>
      </div>
    </motion.div>
  )
}

export default ErrorScreen
