import Agilite from 'agilite'
import CoreEnums from '../../../../core/utils/enums'
import MedicalProfessionalEnums from './enums'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const readMedicalProfessionalUsers = (qry = {}, projection = '') => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        qry['extraData.role.type'] = 'medical_professional'

        response = await agilite.Connectors.execute(
          MedicalProfessionalEnums.profileKeys.AUTH_USERS,
          MedicalProfessionalEnums.routeKeys.FIND,
          {
            filter: JSON.stringify(qry),
            projection: projection,
            page: null,
            pageLimit: null
          }
        )
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}

export const readProfessions = () => {
  return new Promise((resolve, reject) => {
    let response = null
    let errorMessage = null

    ;(async () => {
      try {
        response = await agilite.Keywords.getValuesByProfileKey('professions')
        resolve(response.data)
      } catch (e) {
        if (e.response?.data?.errorMessage) {
          errorMessage = e.response.data.errorMessage
        } else if (e.message) {
          errorMessage = e.message
        } else {
          errorMessage = CoreEnums.errorMessages.UNKNOWN_ERROR
        }

        reject(errorMessage)
      }
    })()
  })
}
