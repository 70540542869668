import Agilite from 'agilite'
import MedicalHistoryEnums from '../../Patient/Medical History/utils/enums'
import MedicalHistoryFormTemplates from '../../Patient/Medical History/utils/templates'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const getMedicalHistory = (patientId) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const qry = { userRef: patientId }
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.READ,
          {
            filter: JSON.stringify(qry),
            page: null,
            pageLimit: null
          }
        )

        if (response.data.length > 0) {
          resolve(response.data[0])
        } else {
          // If no medical history exists for this patient, create a new one
          response = await createMedicalHistory({
            userRef: patientId,
            medicalHistory: MedicalHistoryFormTemplates.dataTemplate
          })
          resolve(response)
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const createMedicalHistory = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute(
          MedicalHistoryEnums.profileKeys.PATIENTS,
          MedicalHistoryEnums.routeKeys.CREATE,
          {
            data: JSON.stringify(data)
          }
        )
        resolve(response.data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

// We can reuse the existing update functions from the Patient Medical History API
// They already accept a recordId parameter which we'll use to update the specific patient's record
