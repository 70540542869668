import CoreEnums from '../../../core/utils/enums'
import coreReducer from '../../../core/utils/reducer'

// Components
import VirConsultWraper from '.'

export const beginVirtBooking = (dispatcher) => {
  dispatcher(
    coreReducer.actions.addTab({
      key: `${CoreEnums.tabKeys.SCHEDULE_VIRTUAL_VISIT}`,
      closable: true,
      label: 'Virtual Consultation',
      children: <VirConsultWraper />
    })
  )
}
