import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Select,
  theme
} from 'antd'
import { batch, useDispatch, useSelector } from 'react-redux'
import { PhoneAuthProvider, RecaptchaVerifier, signOut, updatePhoneNumber } from 'firebase/auth'

import { createAgiliteUser, findAgiliteUser, getCountries, updateAgiliteUser } from '../../utils/utils'
import authReducer from '../../utils/reducer'
import { firebaseAuth } from '../../../..'
import { allTrim, handleCalculateGender, handleError, specialFieldValidation } from '../../../lib/utils'
import coreReducer from '../../../../core/utils/reducer'
import { deviceDetect } from 'react-device-detect'
import { readConfig } from '../../../Admin/config/utils/utils'
import GoogleAddress from '../../../reusable-components/GoogleAddress'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import dayjs from 'dayjs'
import Router from '../router'

const EmailSignUpForm = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.auth)
  const coreState = useSelector((state) => state.core)
  const [loading, setLoading] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmationCode, setConfirmationCode] = useState(null)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [verificationId, setVerificationId] = useState(null)
  const [email, setEmail] = useState('')
  const [idType, setIdType] = useState('id')
  const [specialFormState, setSpecialFormState] = useState([])
  const [residentVisitor, setResidentVisitor] = useState()
  const [country, setCountry] = useState('ZA')
  const [countries, setCountries] = useState([])
  const [countriesLoading, setCountriesLoading] = useState(false)
  const [countryObject, setCountryObject] = useState()

  useEffect(() => {
    if (state.completeEmailSignUp) {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear()
      }

      async function handleLoginPhoneRecaptcha() {
        window.recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, 'recaptcha-container', {
          size: 'invisible',
          callback: (response) => {
            setSubmitDisabled(false)
          },
          'expired-callback': (response) => {
            setSubmitDisabled(true)
            setErrorMessage('Verification Expired. Please reload the page')
          }
        })
      }

      handleGetCountries()

      handleLoginPhoneRecaptcha()
    }
    // eslint-disable-next-line
  }, [state.completeEmailSignUp])

  useEffect(() => {
    let tmpIndex = -1

    tmpIndex = countries.findIndex((e) => e.code === country)

    if (tmpIndex > -1) {
      setCountryObject(countries[tmpIndex])
    } else {
      setCountryObject(null)
    }

    // eslint-disable-next-line
  }, [country])

  const handleGetCountries = async () => {
    let tmpCountries = []

    setCountriesLoading(true)

    try {
      tmpCountries = await getCountries()
      setCountries(tmpCountries)
    } catch (e) {
      message.error(e)
    }

    setCountriesLoading(false)
  }

  useEffect(() => {
    setEmail(firebaseAuth?.currentUser?.email)
    // eslint-disable-next-line
  }, [firebaseAuth.currentUser])

  const handleSubmit = async (data) => {
    let agiliteUser = null

    setLoading(true)

    try {
      if (!state.agiliteUser) {
        if (!data.phoneNumber) {
          throw new Error('Please provide a valid phone number.')
        }
      }

      if (data.phoneNumber) {
        data.phoneNumber = formatNumber(data.phoneNumber)

        if (data.idNo !== state.agiliteUser?.idNo) {
          agiliteUser = await findAgiliteUser({ idNo: data.idNo, phoneNumber: data.phoneNumber })
        } else {
          agiliteUser = await findAgiliteUser({ phoneNumber: data.phoneNumber })
        }

        if (agiliteUser) {
          setLoading(false)
          return message.error(
            'An account with this ID/Passport Number or Cellphone Number is already in use. Please Log In or contact support.'
          )
        }

        await handleLoginPhone(data.phoneNumber)
      } else {
        await handleSubmitExtended(data)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleSubmitExtended = async (data) => {
    let agiliteUser = null
    let configResponse = null
    let config = null
    let tmpData = null

    try {
      agiliteUser = await findAgiliteUser({ idNo: data.idNo })

      if (agiliteUser && data.phoneNumber) {
        // Agilit-e User Found by ID
        // Form contains cellphone number
        tmpData = JSON.parse(JSON.stringify(allTrim(data)))

        if (idType === 'id') {
          tmpData.gender = handleCalculateGender(data.idNo)
        }

        tmpData.dateOfBirth = dayjs(tmpData.dateOfBirth).format('YYYY-MM-DD')

        agiliteUser = await updateAgiliteUser(agiliteUser._id, {
          ...tmpData,
          email: email,
          'extraData.inviteStatus': true
        })
      } else if (agiliteUser && data.idNo !== state.agiliteUser?.idNo) {
        // When we get here it means that an account with the ID No they entered was found
        // but the ID No they entered is different from the one that was used to invite them (Reception Invite)
        setLoading(false)
        return message.error('An account with this ID/Passport Number is already in use. Sign In or contact support.')
      } else {
        agiliteUser = await findAgiliteUser({ email })

        if (agiliteUser && !agiliteUser.idNo) {
          // Agilit-e User Found by email but user has no ID No

          // NOTE: When we get here it means that the user was invited by a role other than reception
          // because ID No is required for reception invites but not for other roles
          tmpData = JSON.parse(JSON.stringify(allTrim(data)))

          if (idType === 'id') {
            tmpData.gender = handleCalculateGender(data.idNo)
          }

          tmpData.dateOfBirth = dayjs(tmpData.dateOfBirth).format('YYYY-MM-DD')

          let tmpEntities = agiliteUser.extraData.entities

          if (!tmpEntities.includes(coreState.entity._id)) {
            tmpEntities.push(coreState.entity._id)
          }

          agiliteUser = await updateAgiliteUser(agiliteUser._id, {
            ...tmpData,
            'extraData.inviteStatus': true,
            'extraData.entities': tmpEntities
          })
        } else {
          if (state.user && state.user.phoneNumber) {
            agiliteUser = await findAgiliteUser({ phoneNumber: state.user.phoneNumber })
          }

          tmpData = JSON.parse(JSON.stringify(allTrim(data)))

          if (idType === 'id') {
            tmpData.gender = handleCalculateGender(data.idNo)
          }

          tmpData.dateOfBirth = dayjs(tmpData.dateOfBirth).format('YYYY-MM-DD')

          if (agiliteUser) {
            let tmpEntities = agiliteUser.extraData.entities

            if (!tmpEntities.includes(coreState.entity._id)) {
              tmpEntities.push(coreState.entity._id)
            }

            agiliteUser = await updateAgiliteUser(agiliteUser._id, {
              ...tmpData,
              'extraData.inviteStatus': true,
              'extraData.entities': tmpEntities
            })
          } else {
            agiliteUser = await createAgiliteUser({
              ...tmpData,
              email: email,
              extraData: {
                inviteStatus: true
              }
            })
          }
        }
      }

      configResponse = await readConfig()

      if (configResponse.length > 0) {
        config = configResponse[0]
        dispatch(authReducer.actions.setConfig(config))
      } else {
        message.warning('No Configurations found')
      }

      batch(() => {
        if (agiliteUser.extraData.role.type === 'admin') {
          // Power Role
          dispatch(coreReducer.actions.enableDisableRightMenu(true))
          // dispatch(coreReducer.actions.enableDisableLeftMenu(true))

          if (deviceDetect().isBrowser) {
            dispatch(coreReducer.actions.hideTabs(false))
          } else {
            dispatch(coreReducer.actions.hideTabs(true))
          }
        } else {
          // User Role
          dispatch(coreReducer.actions.enableDisableLeftMenu(true))
          dispatch(coreReducer.actions.hideTabs(true))
        }

        dispatch(authReducer.actions.setAgiliteUser(agiliteUser))
        dispatch(authReducer.actions.setCompleteEmailSignUp(false))
      })
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleLoginPhone = async (phoneNumber) => {
    const provider = new PhoneAuthProvider(firebaseAuth)
    let tmpVerificationId = null

    try {
      tmpVerificationId = await provider.verifyPhoneNumber(phoneNumber, window.recaptchaVerifier)
      setVerificationId(tmpVerificationId)
      setConfirmationOpen(true)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleLoginPhoneCode = async () => {
    const data = form.getFieldsValue()
    let phoneCredential = null

    setLoading(true)

    try {
      setConfirmationOpen(false)

      data.phoneNumber = formatNumber(data.phoneNumber)

      phoneCredential = PhoneAuthProvider.credential(verificationId, confirmationCode)
      await updatePhoneNumber(firebaseAuth.currentUser, phoneCredential)

      await handleSubmitExtended(data)
    } catch (e) {
      setConfirmationOpen(true)
      message.error(handleError(e, true))
    }

    setConfirmationCode('')
    setLoading(false)
  }

  const handleSignOut = async () => {
    try {
      await signOut(firebaseAuth)
      batch(() => {
        dispatch(coreReducer.actions.resetState())
        dispatch(coreReducer.actions.enableDisableLeftMenu(false))
        dispatch(coreReducer.actions.enableDisableRightMenu(false))
        dispatch(coreReducer.actions.enableDisableTabs(false))
        dispatch(coreReducer.actions.setRootContent(Router))
        dispatch(authReducer.actions.setUser(null))
        dispatch(authReducer.actions.setAgiliteUser(null))
        dispatch(authReducer.actions.setLoggedIn(false))
        dispatch(authReducer.actions.setCompleteEmailSignUp(false))
      })
    } catch (e) {
      handleError(e, true)
    }
  }

  const formatNumber = (phoneNumber) => {
    if (phoneNumber.charAt(0) === '0') {
      phoneNumber = `${countryObject ? countryObject.phoneCode : '+27'}${phoneNumber.slice(1, phoneNumber.length)}`
    } else {
      phoneNumber = `${countryObject ? countryObject.phoneCode : '+27'}${phoneNumber}`
    }

    return phoneNumber
  }

  const [form] = Form.useForm()
  const { token } = theme.useToken()

  return (
    <Modal
      title={<center>{state.agiliteUser?.firstName ? 'Confirm Details' : 'Complete Your Registration'}</center>}
      open={state.completeEmailSignUp}
      footer={null}
      closable={false}
    >
      <center>
        {state.agiliteUser?.firstName ? (
          <span>
            Verify your details below and tap the <b>Confirm Details</b> button.
          </span>
        ) : (
          <span>
            Fill in the required information below and tap the <b>Complete Registration</b> button.
          </span>
        )}
      </center>
      {loading ? (
        <AgiliteSkeleton spinnerTip='Logging in...' />
      ) : (
        <Form
          form={form}
          name='completeSignUp'
          initialValues={{
            firstName: '',
            lastName: '',
            idNo: '',
            gender: '',
            race: '',
            residentialAddress: '',
            phoneNumber: '',
            email: '',
            ...state.agiliteUser,
            dateOfBirth: state.agiliteUser?.dateOfBirth ? dayjs(state.agiliteUser?.dateOfBirth) : ''
          }}
          onFinish={handleSubmit}
          autoComplete='off'
          layout='vertical'
          style={{ marginTop: 10 }}
        >
          <Form.Item
            label='First Name'
            name='firstName'
            rules={[{ required: true, message: 'Please provide your First Name' }]}
          >
            <Input
              placeholder='e.g. Jane'
              maxLength={50}
              onChange={(e) => {
                specialFieldValidation(form, specialFormState, setSpecialFormState, {
                  key: 'firstName',
                  event: e,
                  validationConfig: { letters: { allowed: true, onlyCaps: false }, numbers: false, spaces: true }
                })
              }}
            />
          </Form.Item>
          <Form.Item
            label='Last Name'
            name='lastName'
            rules={[{ required: true, message: 'Please provide your Last Name' }]}
          >
            <Input
              placeholder='e.g. Doe'
              maxLength={50}
              onChange={(e) => {
                specialFieldValidation(form, specialFormState, setSpecialFormState, {
                  key: 'lastName',
                  event: e,
                  validationConfig: { letters: { allowed: true, onlyCaps: false }, numbers: false, spaces: true }
                })
              }}
            />
          </Form.Item>
          <Radio.Group
            style={{ marginBottom: 8 }}
            onChange={(e) => {
              setIdType(e.target.value)
              setCountry('ZA')
              setResidentVisitor(null)
              form.setFieldValue('idNo', null)
            }}
            value={idType}
          >
            <Radio value={'id'}>South African ID (RSA Resident)</Radio>
            <Radio value={'pass'}>Passport</Radio>
          </Radio.Group>
          {idType === 'id' ? (
            <Form.Item
              name='idNo'
              rules={[
                { required: true, message: 'Please provide your ID Number.' },
                { min: 13, message: 'Invalid South African ID' }
              ]}
            >
              <Input
                placeholder='e.g. 790725*******'
                maxLength={13}
                onChange={(e) => {
                  specialFieldValidation(form, specialFormState, setSpecialFormState, {
                    key: 'idNo',
                    event: e,
                    validationConfig: { letters: { allowed: false, onlyCaps: false }, numbers: true, spaces: false }
                  })
                }}
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item name='idNo' rules={[{ required: true, message: 'Please provide your Passport Number.' }]}>
                <Input
                  placeholder='e.g. AZ330B*******'
                  maxLength={35}
                  onChange={(e) => {
                    specialFieldValidation(form, specialFormState, setSpecialFormState, {
                      key: 'idNo',
                      event: e,
                      validationConfig: { letters: { allowed: true, onlyCaps: true }, numbers: true, spaces: false }
                    })
                  }}
                />
              </Form.Item>
              <Form.Item
                label='Gender'
                name='gender'
                rules={[{ required: true, message: 'Please select your Gender.' }]}
              >
                <Radio.Group
                  onChange={(e) => {
                    form.setFieldValue('gender', e.target.value)
                  }}
                  buttonStyle='solid'
                >
                  <Radio.Button value='female'>Female</Radio.Button>
                  <Radio.Button value='male'>Male</Radio.Button>
                  <Radio.Button value='other'>Other</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </>
          )}
          {idType === 'pass' ? (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name='residentVisitor'
                label='RSA Resident / Visitor'
                rules={[{ required: true, message: 'Please if you are a RSA Resident or Visitor' }]}
              >
                <Select
                  placeholder='RSA Resident or Visitor'
                  options={[
                    { label: 'RSA Resident', value: 'resident' },
                    { label: 'Visiting From Abroad', value: 'visitor' }
                  ]}
                  onChange={(value) => {
                    if (value === 'resident') {
                      setCountry('ZA')
                    }

                    setResidentVisitor(value)
                  }}
                />
              </Form.Item>
            </Col>
          ) : undefined}
          {residentVisitor === 'visitor' ? (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name='country'
                label='Country Of Residence'
                rules={[{ required: true, message: 'Please provide your Country Of Residence' }]}
              >
                <Select
                  showSearch
                  optionFilterProp='label'
                  loading={countriesLoading}
                  placeholder='e.g South Africa'
                  options={countries.map((e) => {
                    return {
                      label: e.name,
                      value: e.code
                    }
                  })}
                  onChange={(value) => {
                    setCountry(value)
                  }}
                />
              </Form.Item>
            </Col>
          ) : undefined}
          {country === 'ZA' ? (
            <Form.Item label='Race' name='race' rules={[{ required: true, message: 'Please select your Race.' }]}>
              <Radio.Group
                onChange={(e) => {
                  form.setFieldValue('race', e.target.value)
                }}
                buttonStyle='solid'
              >
                <Radio.Button value='African'>African</Radio.Button>
                <Radio.Button value='Coloured'>Coloured</Radio.Button>
                <Radio.Button value='Indian'>Indian</Radio.Button>
                <Radio.Button value='White'>White</Radio.Button>
                <Radio.Button value='Other'>Other</Radio.Button>
              </Radio.Group>
            </Form.Item>
          ) : undefined}
          <Form.Item
            label='Date Of Birth'
            name='dateOfBirth'
            rules={[{ required: true, message: 'Please specify your Date Of Birth.' }]}
          >
            <DatePicker form={form} key='dateOfBirth' />
          </Form.Item>
          {state.user && state.user.phoneNumber ? undefined : (
            <Popover
              placement='topRight'
              content='One-time Pin will be sent to your Cellphone'
              title={<span style={{ color: token.colorPrimary }}>Note</span>}
              trigger='focus'
            >
              <Form.Item
                label='Cellphone Number (optional)'
                name='phoneNumber'
                rules={[{ required: false, message: '' }]}
              >
                <Input
                  addonBefore={`${countryObject ? countryObject.phoneCode : '+27'}`}
                  placeholder='8288*****'
                  maxLength={15}
                  minLength={9}
                  onChange={(e) => {
                    specialFieldValidation(form, specialFormState, setSpecialFormState, {
                      key: 'phoneNumber',
                      event: e,
                      validationConfig: { letters: { allowed: false, onlyCaps: true }, numbers: true }
                    })
                  }}
                />
              </Form.Item>
            </Popover>
          )}
          <Form.Item
            label='Physical Address'
            name='residentialAddress'
            rules={[{ required: true, message: 'Please provide a Physical Address.' }]}
          >
            <GoogleAddress
              selectProps={{
                placeholder: form.getFieldValue('residentialAddress')
                  ? form.getFieldValue('residentialAddress')
                  : 'e.g. 5 Doe Street, Gqeberha, South Africa',
                onChange: async (e) => {
                  form.setFieldValue('residentialAddress', e.label)
                  form.validateFields(['residentialAddress'])
                }
              }}
            />
          </Form.Item>
          {state?.agiliteUser?.extraData?.role?.type === 'patient' || !state?.agiliteUser?.extraData?.role?.type ? (
            <>
              <Form.Item
                requiredMark='optional'
                label={`${
                  !residentVisitor || residentVisitor === 'resident' ? 'Medical Aid Name' : 'Travel Insurance Provider'
                }`}
                name={['medicalAid', 'name']}
              >
                <Input placeholder='e.g. Discovery Health Scheme' />
              </Form.Item>
              <Form.Item
                requiredMark='optional'
                label={`${
                  !residentVisitor || residentVisitor === 'resident' ? 'Medical Aid Plan' : 'Travel Insurance Plan'
                }`}
                name={['medicalAid', 'plan']}
              >
                <Input placeholder='e.g. Coastal Saver' />
              </Form.Item>
              <Form.Item
                requiredMark='optional'
                label={`${
                  !residentVisitor || residentVisitor === 'resident' ? 'Medical Aid Number' : 'Travel Insurance Number'
                }`}
                name={['medicalAid', 'number']}
              >
                <Input placeholder='e.g. 335***' />
              </Form.Item>
              <Form.Item requiredMark='optional' label='Dependant Number' name={['medicalAid', 'dependantNumber']}>
                <Input placeholder='e.g. 03' />
              </Form.Item>{' '}
            </>
          ) : undefined}

          <Row justify='space-between'>
            <Col>
              <Popconfirm
                title='Confirmation'
                description='Are you sure you want to Sign Out?'
                okText='Yes'
                cancelText='No'
                okButtonProps={{
                  type: 'primary',
                  style: { backgroundColor: token.colorSuccess }
                }}
                cancelButtonProps={{
                  danger: true
                }}
                onConfirm={() => {
                  handleSignOut()
                }}
              >
                <Button disabled={submitDisabled || loading} type='primary' danger={true}>
                  Sign Out
                </Button>
              </Popconfirm>
            </Col>
            <Col>
              <Button
                disabled={submitDisabled || loading}
                htmlType='submit'
                type='primary'
                style={{ backgroundColor: token.colorSuccess }}
              >
                {state.agiliteUser?.firstName ? 'Confirm Details' : 'Complete Registration'}
              </Button>
            </Col>
          </Row>
          <center></center>
          <center>{errorMessage ? errorMessage : undefined}</center>
        </Form>
      )}
      <div id='recaptcha-container'></div>
      <Modal
        title='Confirmation Code'
        footer={null}
        open={confirmationOpen}
        onCancel={() => {
          setConfirmationOpen(false)
          setConfirmationCode('')
        }}
        closable={false}
        maskClosable={false}
      >
        <Row justify='center'>
          <Col>
            Please provide the Confirmation Code sent to your Cellphone and tap on <b>Submit</b>.
            <Input
              value={confirmationCode}
              onChange={(e) => {
                if (/^[0-9]*$/g.test(e.target.value) === true) {
                  setConfirmationCode(e.target.value)
                }
              }}
              placeholder='e.g. 123456'
            />
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginTop: 20 }}>
          <Col>
            <Button
              disabled={submitDisabled || loading}
              onClick={() => {
                const data = form.getFieldsValue()
                handleSubmit(data)
              }}
              type='primary'
            >
              Resend Code
            </Button>
          </Col>
          <Col>
            <Button
              disabled={submitDisabled || loading}
              type='primary'
              onClick={() => handleLoginPhoneCode()}
              style={{ backgroundColor: token.colorSuccess }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Modal>
    </Modal>
  )
}

export default EmailSignUpForm
