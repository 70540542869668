import { useState, useEffect, useCallback } from 'react'
import { message } from 'antd'
import { handleError } from '../../../../lib/utils'
import { ProductSearchAgilite } from '../../../utils/utils'
import { medAdminTypes } from '../../../utils/templates'

// Import the global cache from useSearch.js if it exists, otherwise create it here
// This ensures we're using the same cache across both hooks
if (typeof window !== 'undefined' && !window.medicationsCache) {
  window.medicationsCache = null
  window.isFetchingMedications = false
}

export const useMedications = (initialItems = []) => {
  const [medsList, setMedsList] = useState(initialItems)
  const [currentDescFocus, setCurrentDescFocus] = useState('')
  const [currentMed, setCurrentMed] = useState(null)
  const [showClipboard, setShowClipboard] = useState(false)
  const [preloading, setPreloading] = useState(false)
  const [medIndex, setMedIndex] = useState(window.medicationsCache || [])
  const [rptRepeat, setRptRepeat] = useState(true)
  const [rptICD, setRptICD] = useState(true)
  const [rptRepeatCount, setRptRepeatCount] = useState(0)
  const [rptICDValue, setRptICDValue] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')
  const [nappiData, setNappiData] = useState([])

  // Update medsList when initialItems changes
  useEffect(() => {
    if (JSON.stringify(medsList) !== JSON.stringify(initialItems)) {
      setMedsList(initialItems)
    }
  }, [initialItems])

  // Load medications data when component mounts
  useEffect(() => {
    if (!window.medicationsCache && !window.isFetchingMedications) {
      preloadMedications()
    } else if (window.medicationsCache) {
      // Use cached data if available
      setMedIndex(window.medicationsCache)
      setPreloading(false)
    }
  }, [])

  const preloadMedications = async () => {
    // Set global flag to prevent multiple simultaneous fetches
    window.isFetchingMedications = true
    setPreloading(true)

    try {
      // Only fetch if cache is empty
      if (!window.medicationsCache) {
        const tmpData = await ProductSearchAgilite(null, 'medicine', true)

        if (Array.isArray(tmpData) && tmpData.length > 0) {
          // Store in global cache
          window.medicationsCache = tmpData
          setMedIndex(tmpData)
        } else {
          console.error('Empty or invalid medications data received from API:', tmpData)
          message.error('Failed to load medications data. Please try again.')
        }
      }
    } catch (e) {
      console.error('Error fetching medications in useMedications:', e)
      message.error(handleError(e, true))
      // Reset flag on error
      window.isFetchingMedications = false
    } finally {
      setPreloading(false)
      // Reset flag when done
      window.isFetchingMedications = false
    }
  }

  const filterMedIndex = useCallback(
    (searchQuery) => {
      if (!searchQuery || searchQuery.trim() === '') {
        setNappiData([])
        return
      }

      if (!medIndex || !Array.isArray(medIndex) || medIndex.length === 0) {
        // If medIndex is empty, try to load it
        if (!window.medicationsCache && !window.isFetchingMedications) {
          preloadMedications()
        }
        return
      }

      try {
        const searchLower = searchQuery.toLowerCase().trim()
        const filtered = medIndex
          .filter((med) => {
            if (!med) return false

            // Support both data structures (med.data and direct med object)
            const medData = med.data || med

            // Safely access properties with default empty strings
            const name = (medData.name || '').toLowerCase()
            const code = (medData.code || '').toLowerCase()
            const nappiCode = (medData.nappiCode || '').toLowerCase()

            const matches = name.includes(searchLower) || code.includes(searchLower) || nappiCode.includes(searchLower)

            return matches
          })
          .map((entry) => {
            if (entry.data) {
              return {
                type: entry.type,
                ...entry.data
              }
            }
            return entry
          })

        setNappiData(filtered)
      } catch (error) {
        console.error('Error during medication filtering in useMedications:', error)
        // Even with an error, try to set some default results
        setNappiData([])
      }
    },
    [medIndex]
  )

  useEffect(() => {
    filterMedIndex(searchFilter)
  }, [searchFilter, filterMedIndex])

  const handleAdd = useCallback(
    async (product) => {
      if (!product || !product.code) {
        console.error('Invalid product data:', product)
        message.error('Invalid product data. Please try again.')
        return
      }

      try {
        const fullMedDetails = await ProductSearchAgilite(product.code, 'medicine')

        if (!fullMedDetails || !Array.isArray(fullMedDetails) || fullMedDetails.length === 0) {
          throw new Error('Could not fetch medication details')
        }

        const fullProduct = {
          type: fullMedDetails[0].type,
          ...fullMedDetails[0].data
        }

        let tmpMedList = [...medsList]
        let exists = tmpMedList.find((i) => i.code === fullProduct.code)
        if (exists) {
          message.error('Medication already added.')
          return
        }

        if (rptICD && rptICDValue) {
          fullProduct.icd = rptICDValue
        }

        if (rptRepeat && rptRepeatCount) {
          fullProduct.rpt = rptRepeatCount
        }

        tmpMedList.push(fullProduct)
        setCurrentMed(tmpMedList.length - 1)
        setMedsList(tmpMedList)
      } catch (e) {
        console.error('Error adding medication:', e)
        message.error(handleError(e, true))
      }
    },
    [medsList, rptICD, rptICDValue, rptRepeat, rptRepeatCount]
  )

  const handleRemove = useCallback((index) => {
    setCurrentDescFocus(null)
    setMedsList((prev) => {
      const tmpMedList = [...prev]
      tmpMedList.splice(index, 1)
      return tmpMedList
    })
  }, [])

  const handleScriptDosageChange = useCallback((newValue, index) => {
    setMedsList((prev) => {
      const tmpMedList = [...prev]
      tmpMedList[index].scriptingDosage = newValue
      return tmpMedList
    })
  }, [])

  const handleExtraPropertyChange = useCallback((value, prop, index) => {
    if (prop === 'icd') {
      setRptICDValue(value)
    }
    if (prop === 'rpt' || prop === 'repeat') {
      setRptRepeatCount(value)
    }

    setMedsList((prev) => {
      const tmpMedList = [...prev]
      // Ensure the index exists
      if (index >= 0 && index < tmpMedList.length) {
        // Handle both repeat and rpt properties
        if (prop === 'repeat' || prop === 'rpt') {
          tmpMedList[index].repeat = value
          tmpMedList[index].rpt = value // Keep both properties in sync
        } else {
          tmpMedList[index][prop] = value
        }
      } else {
        console.warn(`Invalid index ${index} for medication list of length ${tmpMedList.length}`)
      }
      return tmpMedList
    })
  }, [])

  const getNappiOptions = useCallback(() => {
    let dataArray = []

    try {
      dataArray = nappiData.map((med) => {
        // Ensure we're using the correct data structure
        const medData = med.data || med

        return {
          search: `${medData.name || ''} ${medData.strengthMetric1 || ''} ${medData.unitOfMeasure1 || ''} ${
            medData.presentationCode || ''
          } - ${medData.standardPacksize || ''}`,
          label: (
            <>
              {medAdminTypes.find((i) => i.key === medData.presentationCode)?.icon} {medData.name || ''}{' '}
              {medData.strengthMetric1 || ''} {medData.unitOfMeasure1 || ''} {medData.presentationCode || ''} -{' '}
              {medData.standardPacksize || ''}
            </>
          ),
          value: JSON.stringify({
            code: medData.code,
            name: medData.name
          })
        }
      })

      // Prepend search filter as an option if it exists
      if (searchFilter && searchFilter.trim() && dataArray.length === 0) {
        dataArray = [
          {
            search: searchFilter,
            label: searchFilter,
            value: JSON.stringify({
              name: searchFilter,
              code: searchFilter
            })
          },
          ...dataArray
        ]
      }
    } catch (error) {
      console.error('Error generating NAPPI options in useMedications:', error)
      // Default to at least having the search term as an option on error
      if (searchFilter && searchFilter.trim()) {
        dataArray = [
          {
            search: searchFilter,
            label: searchFilter,
            value: JSON.stringify({
              name: searchFilter,
              code: searchFilter
            })
          }
        ]
      }
    }

    return dataArray
  }, [nappiData, searchFilter])

  return {
    medsList,
    setMedsList,
    currentDescFocus,
    setCurrentDescFocus,
    currentMed,
    setCurrentMed,
    showClipboard,
    setShowClipboard,
    preloading,
    handleAdd,
    handleRemove,
    handleScriptDosageChange,
    handleExtraPropertyChange,
    rptRepeat,
    setRptRepeat,
    rptICD,
    setRptICD,
    searchFilter,
    setSearchFilter,
    nappiOptions: getNappiOptions(),
    preloadMedications // Expose this function to force reload if needed
  }
}
