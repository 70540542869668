import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Table,
  theme,
  Tooltip
} from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../core/utils/reducer'
import { handleError } from '../../lib/utils'
import { readPatients } from '../../Admin/patients/utils/utils'
import CoreEnums from '../../../core/utils/enums'
import { getInvoices, updateRemittance } from '../utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { debounce } from 'lodash'
import { readBillingRecords } from '../../Bookings/utils/utils'

const MedicalAidPaymentForm = ({ data, handleGetRemittances }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [formData, setFormData] = useState(data)
  const [items, setItems] = useState([])
  const [invoiceRef, setInvoiceRef] = useState('')

  const [searchData, setSearchData] = useState([])
  const [searchLoading, setSearchLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const [invoices, setInvoices] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleAllocatePayment = () => {
    let tmpPayments = data.payments ? data.payments : []
    let runningTotal = data.runningTotal

    form
      .validateFields()
      .then(async () => {
        tmpPayments.push({
          invoiceRef: invoiceRef,
          userRef: form.getFieldValue('account'),
          accStatus: form.getFieldValue('accountStatus'),
          receiptDate: form.getFieldValue('receiptDate'),
          paymentAmount: form.getFieldValue('paymentAmount')
        })

        runningTotal = (parseFloat(runningTotal) - parseFloat(form.getFieldValue('paymentAmount'))).toFixed(2)

        setSubmitLoading(true)
        try {
          await updateRemittance(data._id, {
            runningTotal,
            payments: tmpPayments
          })
          message.success('Payment successfully allocated')
          handleCloseTab()
        } catch (error) {
          message.error(handleError(error, true))
        }
        setSubmitLoading(false)
      })
      .catch((e) => {})
  }

  const handleCloseTab = () => {
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: `${CoreEnums.tabKeys.PAYMENT_REMITTANCE}_new`,
        removeBreadcrumb: true
      })
    )
    handleGetRemittances()
  }

  const [form] = Form.useForm()
  const { token } = theme.useToken()

  const handleFieldChange = (changedFields) => {
    try {
      setFormData({ ...formData, ...changedFields })
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleTableFieldChange = (prop, index, value) => {
    const tmpItems = JSON.parse(JSON.stringify(items))
    tmpItems[index][prop] = value

    try {
      // When the payment amount on a row is changed
      if (prop === 'payment') {
        const tmpPayment = value
        const tmpPatient = tmpItems[index].patient

        if (tmpPayment > tmpPatient) {
          throw new Error('Payment amount cannot be greater than the Patient Liable amount')
        } else {
          tmpItems[index].patientBalance = (tmpPatient - tmpPayment).toFixed(2)
        }
      }

      // When the discount amount on a row is changed
      if (prop === 'discount') {
        const tmpDiscount = value
        const tmpPatient = tmpItems[index].patient

        if (tmpDiscount > tmpPatient) {
          throw new Error('Discount amount cannot be greater than the Patient Liable amount')
        } else {
          tmpItems[index].patientBalance = (tmpPatient - tmpDiscount).toFixed(2)
        }
      }

      setItems(tmpItems)
    } catch (e) {
      message.error(e.message)
    }
  }

  const generateTotalsData = () => {
    const data = [
      {
        total: items.reduce((a, b) => a + parseFloat(b.total), 0).toFixed(2),
        medicalAid: items.reduce((a, b) => a + parseFloat(b.medicalAid), 0).toFixed(2),
        patient: items.reduce((a, b) => a + parseFloat(b.patient), 0).toFixed(2),
        payment: items.reduce((a, b) => a + parseFloat(b.payment), 0).toFixed(2),
        discount: items.reduce((a, b) => a + parseFloat(b.discount), 0).toFixed(2)
      }
    ]

    for (const entry of data) {
      for (const prop in entry) {
        if (isNaN(entry[prop])) {
          entry[prop] = 0
        }
      }
    }

    return data
  }

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query) {
        setSearchValue(query)
      }
    }, 2000),
    []
  )

  const performSearch = async () => {
    try {
      let tmpSearchData = []

      tmpSearchData = await readPatients({
        $or: [
          { firstName: { $regex: searchValue, $options: 'i' } },
          { lastName: { $regex: searchValue, $options: 'i' } }
        ],
        'extraData.role.type': 'patient'
      })

      setSearchData(tmpSearchData)
      setSearchLoading(false)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  useEffect(() => {
    if (searchValue) {
      performSearch()
    }
    // eslint-disable-next-line
  }, [searchValue])

  const handleSearch = (value, type) => {
    setSearchLoading(true)
    debouncedSearch(value, type)
  }

  const handleAccountChange = async (value) => {
    setLoading(true)

    try {
      if (value) {
        const tmpInvoices = await getInvoices({ userRef: value, status: { $ne: 'completed' } })
        setInvoices(tmpInvoices)

        if (tmpInvoices.length > 0) {
          setIsModalVisible(true)
        } else {
          message.warning('No pending invoices found for this account')
        }
      } else {
        setItems([])
        setInvoices([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleInvoiceSelect = async (selectedInvoice) => {
    try {
      const tmpBillingRecords = await readBillingRecords({ bookingRef: selectedInvoice.bookingRef })
      setInvoiceRef(selectedInvoice._id)
      setItems(tmpBillingRecords)
      setIsModalVisible(false)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  return (
    <Row>
      <Col span={24}>
        <Card
          size='small'
          title={<span style={{ color: token.colorPrimary, fontSize: 18 }}>New Medical Aid Payment</span>}
        >
          <Card
            size='small'
            bordered={false}
            title={<span style={{ color: token.colorSecondary, fontSize: 18 }}>Account Details</span>}
            type='inner'
          >
            <Form
              form={form}
              initialValues={{
                ...data,
                receiptDate: dayjs(new Date())
              }}
              onValuesChange={handleFieldChange}
              layout='vertical'
            >
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Form.Item name='medicalAid' label='Medical Aid'>
                        <Input readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='receiptNumber' label='Receipt Number'>
                        <Input readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Form.Item name='total' label='Total'>
                        <Input addonBefore='R' readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='runningTotal' label='Running Total'>
                        <Input addonBefore='R' readOnly />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Form.Item
                        name='account'
                        label='Account'
                        rules={[{ required: true, message: 'Please select an Account' }]}
                      >
                        <Select
                          style={{ width: '100%' }}
                          showSearch
                          allowClear
                          placeholder='Search an Account'
                          onSearch={(value) => {
                            handleSearch(value)
                          }}
                          onChange={(value) => {
                            handleAccountChange(value)
                          }}
                          filterOption={false}
                          onBlur={() => {
                            setSearchLoading(false)
                            setSearchValue('')
                          }}
                          notFoundContent={
                            searchLoading ? (
                              <AgiliteSkeleton skActive spinnerTip='Searching...' />
                            ) : (
                              <Row>
                                <Col span={24}>
                                  <p style={{ textAlign: 'center' }}>No data found</p>
                                </Col>
                                <Col span={24}>
                                  <h4 style={{ textAlign: 'center' }}>Type a Patient Name to start searching</h4>
                                </Col>
                              </Row>
                            )
                          }
                          options={searchData.map((item) => {
                            return {
                              key: item._id,
                              label: `(${item.idNo ? item.idNo : 'No ID'}) - ${item.firstName} ${item.lastName}`,
                              value: `${item._id}`
                            }
                          })}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='receiptDate'
                        label='Receipt Date'
                        rules={[{ required: true, message: 'Please select a Receipt Date' }]}
                      >
                        <DatePicker style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <Form.Item
                        name='accountStatus'
                        label='Account Status'
                        rules={[{ required: true, message: 'Please select an Account Status' }]}
                      >
                        <Select
                          options={[
                            { label: 'Normal', value: 'normal' },
                            { label: 'Suspended', value: 'suspended' },
                            { label: 'Hand Over', value: 'handOver' },
                            { label: 'Bad Debt', value: 'badDebt' }
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='paymentAmount'
                        label='Payment Amount'
                        rules={[{ required: true, message: 'Please provide a Payment Amount' }]}
                      >
                        <Input type='number' prefix='R' />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
          <Card
            size='small'
            bordered={false}
            type='inner'
            title={<span style={{ color: token.colorSecondary, fontSize: 18 }}>Items</span>}
          >
            <Table
              pagination={false}
              size='small'
              loading={loading}
              tableLayout='fixed'
              columns={[
                { title: 'Item Type', dataIndex: 'type', key: 'type', width: '8%' },
                { title: 'Code', dataIndex: 'code', key: 'code', width: '5%' },
                { title: 'Description', dataIndex: 'name', key: 'description', width: '40%' },
                {
                  title: 'Total',
                  dataIndex: 'total',
                  key: 'total',
                  width: '6%',
                  render: (text) => <Input style={{ backgroundColor: '#ddd' }} value={text} readOnly />
                },
                {
                  title: 'Medical Aid',
                  dataIndex: 'medicalAid',
                  key: 'medicalAid',
                  width: '6%',
                  render: (text) => <Input style={{ backgroundColor: '#ddd' }} value={text} readOnly />
                },
                {
                  title: 'Patient Liable',
                  dataIndex: 'patientBalance',
                  key: 'patient',
                  width: '6%',
                  render: (text) => <Input style={{ backgroundColor: '#FCA19C' }} value={text} readOnly />
                },
                {
                  title: 'Payment',
                  dataIndex: 'payment',
                  key: 'payment',
                  width: '6%',
                  render: (text, record, index) => {
                    const paymentValue = form.getFieldValue('paymentAmount')

                    return (
                      <Tooltip open={paymentValue ? false : undefined} title='Please enter a Payment Amount first'>
                        <InputNumber
                          onKeyDown={(e) => {
                            const tmpItems = JSON.parse(JSON.stringify(items))
                            const currentItem = tmpItems[index]

                            switch (e.key.toUpperCase()) {
                              case 'M':
                                e.preventDefault()
                                if (parseFloat(currentItem.patientBalance) > 0) {
                                  currentItem.medicalAid = (
                                    parseFloat(currentItem.medicalAid) + parseFloat(currentItem.patientBalance)
                                  ).toFixed(2)
                                  currentItem.patientBalance = '0'
                                }
                                break
                              case 'P':
                                e.preventDefault()
                                if (parseFloat(currentItem.medicalAid) > 0) {
                                  currentItem.patientBalance = (
                                    parseFloat(currentItem.patientBalance) + parseFloat(currentItem.medicalAid)
                                  ).toFixed(2)
                                  currentItem.medicalAid = '0'
                                }
                                break
                              case 'D':
                                e.preventDefault()
                                if (parseFloat(currentItem.patientBalance) > 0) {
                                  currentItem.discount = (
                                    parseFloat(currentItem.discount) + parseFloat(currentItem.patientBalance)
                                  ).toFixed(2)
                                  currentItem.patientBalance = '0'
                                }
                                break
                              case 'R':
                                e.preventDefault()
                                currentItem.patientBalance = currentItem.oldPatient
                                currentItem.patient = currentItem.oldPatient
                                currentItem.medicalAid = currentItem.oldMedicalAid
                                currentItem.payment = '0'
                                currentItem.discount = '0'
                                break
                              case ' ': // Space key
                                e.preventDefault()
                                if (paymentValue && parseFloat(currentItem.medicalAid) > 0) {
                                  if (parseFloat(paymentValue) >= parseFloat(currentItem.medicalAid)) {
                                    currentItem.payment = currentItem.medicalAid
                                    currentItem.medicalAid = '0'
                                  }
                                }
                                break
                              default:
                                break
                            }

                            setItems(tmpItems)
                          }}
                          value={text}
                          onChange={(value) => {
                            if (paymentValue) {
                              handleTableFieldChange('payment', index, value)
                            }
                          }}
                        />
                      </Tooltip>
                    )
                  }
                },
                {
                  title: 'Discount',
                  dataIndex: 'discount',
                  key: 'discount',
                  width: '6%',
                  render: (text, record, index) => (
                    <Input value={text} onChange={(e) => handleTableFieldChange('discount', index, e.target.value)} />
                  )
                },
                {
                  title: 'Comments',
                  dataIndex: 'comments',
                  key: 'comments',
                  width: '10%',
                  render: (text, record, index) => (
                    <Input.TextArea
                      rows={1}
                      value={text}
                      onChange={(e) => handleTableFieldChange('comments', index, e.target.value)}
                    />
                  )
                }
              ]}
              dataSource={items}
              footer={() => {
                return (
                  <Table
                    showHeader={false}
                    size='small'
                    pagination={false}
                    columns={[
                      { width: '8%' },
                      { width: '5%' },
                      { width: '40%', render: () => <b style={{ float: 'right' }}>Total</b> },
                      { width: '6%', dataIndex: 'total', render: (text) => <b>{text}</b> },
                      { width: '6%', dataIndex: 'medicalAid', render: (text) => <b>{text}</b> },
                      { width: '6%', dataIndex: 'patient', render: (text) => <b>{text}</b> },
                      { width: '6%', dataIndex: 'payment', render: (text) => <b>{text}</b> },
                      { width: '6%', dataIndex: 'discount', render: (text) => <b>{text}</b> },
                      { width: '10%' }
                    ]}
                    dataSource={generateTotalsData()}
                  />
                )
              }}
            />
          </Card>
        </Card>
        <Row justify='space-around' style={{ marginTop: 10 }}>
          <Col>P = Move to Patient Liable</Col>
          <Col>M = Move to Medical Aid</Col>
          <Col>D = Discount</Col>
          <Col>R = Reset</Col>
          <Col>Space - Allocate</Col>
        </Row>
        <Row justify='center' style={{ marginTop: 10 }} gutter={[12, 12]}>
          <Col>
            <Button
              type='primary'
              disabled={items.length === 0}
              onClick={() => handleAllocatePayment()}
              loading={submitLoading}
            >
              Allocate Payment
            </Button>
          </Col>
          <Col>
            <Button danger onClick={() => handleCloseTab()}>
              Close
            </Button>
          </Col>
        </Row>
      </Col>
      <Modal
        title='Select Invoice'
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
      >
        <Table
          size='small'
          pagination={false}
          columns={[
            {
              title: 'Invoice No',
              dataIndex: 'invoiceNo',
              key: 'invoiceNo'
            },
            {
              title: 'Date',
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm')
            },
            {
              title: 'Invoice Type',
              dataIndex: 'invoiceType',
              key: 'invoiceType',
              render: (text, record) => {
                return record.invoiceType === 'medical'
                  ? 'Medical Aid'
                  : record.invoiceType === 'insurance'
                  ? 'Insurance'
                  : 'Private'
              }
            },
            {
              title: 'Action',
              key: 'action',
              render: (_, record) => (
                <Button type='primary' onClick={() => handleInvoiceSelect(record)}>
                  Select
                </Button>
              )
            }
          ]}
          dataSource={invoices}
        />
      </Modal>
    </Row>
  )
}

export default MedicalAidPaymentForm
