import { useState, useEffect } from 'react'
import { Button, Col, message, Row, Space, theme, Popconfirm } from 'antd'
import {
  AudioMutedOutlined,
  AudioOutlined,
  VideoCameraOutlined,
  VideoCameraAddOutlined,
  DisconnectOutlined
} from '@ant-design/icons'

import {
  LocalUser,
  RemoteUser,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers
} from 'agora-rtc-react'
import { handleError } from '../lib/utils'
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const LiveVideo = ({ channelName, uid, token, disconnectCallback }) => {
  const appId = process.env.REACT_APP_AGORA_APP_ID

  // set the connection state
  const [activeConnection, setActiveConnection] = useState(true)

  // track the mic/video state - Turn on Mic and Camera On
  const [micOn, setMic] = useState(true)
  const [cameraOn, setCamera] = useState(true)

  // get local video and mic tracks
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn)
  const { localCameraTrack } = useLocalCameraTrack(cameraOn)

  // Remote users
  const remoteUsers = useRemoteUsers()
  const { audioTracks } = useRemoteAudioTracks(remoteUsers)

  // Join the channel
  const agoraSession = useJoin(
    {
      appid: appId,
      channel: channelName,
      token,
      uid
    },
    activeConnection
  )

  const [sessionDuration, setSessionDuration] = useState(0)

  useEffect(() => {
    if (activeConnection) {
      if (localCameraTrack) {
        localCameraTrack.setEnabled(cameraOn)
      }

      if (localMicrophoneTrack) {
        localMicrophoneTrack.setEnabled(micOn)
      }
    }

    // eslint-disable-next-line
  }, [cameraOn, micOn, activeConnection])

  useEffect(() => {
    if (agoraSession.error && agoraSession.error.message) {
      message.error('Unknown error occurred. Please contact support.', 5)
      handleError(agoraSession.error.message, true)
    }
  }, [agoraSession])

  useEffect(() => {
    if (agoraSession.isConnected) {
      const startTime = Date.now()

      const durationInterval = setInterval(() => {
        const duration = Math.floor((Date.now() - startTime) / 1000)
        setSessionDuration(duration)
      }, 1000)

      return () => {
        clearInterval(durationInterval)
      }
    }
  }, [agoraSession.isConnected])

  usePublish([localMicrophoneTrack, localCameraTrack])
  audioTracks.forEach((track) => track.play())

  // Add useEffect to handle auto-disconnect
  useEffect(() => {
    let disconnectTimer
    let warningTimer

    if (activeConnection) {
      // Set warning timer for 5 minutes before disconnect
      warningTimer = setTimeout(() => {
        message.warning('Your session will end in 5 minutes', 5)
      }, 3300000) // 55 minutes (1 hour - 5 minutes)

      disconnectTimer = setTimeout(() => {
        handleDisconnect()
      }, 3600000) // 1 hour in milliseconds
    }

    return () => {
      if (disconnectTimer) {
        clearTimeout(disconnectTimer)
      }
      if (warningTimer) {
        clearTimeout(warningTimer)
      }
    }

    // eslint-disable-next-line
  }, [activeConnection])

  const handleDisconnect = () => {
    setActiveConnection(false)
    setMic(false)
    setCamera(false)

    if (disconnectCallback) {
      disconnectCallback()
    }
  }

  const { token: antdToken } = theme.useToken()

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`
  }

  return (
    <div style={{ position: 'relative', height: '700px' }}>
      {/* Timer Display */}
      <div
        style={{
          position: 'absolute',
          bottom: 20,
          left: 20,
          zIndex: 100,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '4px',
          fontSize: '16px'
        }}
      >
        {formatTime(sessionDuration)}
      </div>

      {/* Remote Users Grid */}
      <div style={{ height: '98%', width: '100%' }}>
        <div
          id='remoteVideoGrid'
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '10px',
            height: '100%'
          }}
        >
          {remoteUsers.length === 0 && (
            <div className='remote-video-container' style={{ height: '100%', marginTop: 30 }}>
              <Row justify='center' align='middle'>
                <Col>
                  <div>Please wait for the other participants to join...</div>
                </Col>
              </Row>
              <Row justify='center' align='middle'>
                <Col>
                  <small>Experiencing issues? Please contact support.</small>
                </Col>
              </Row>
              <Row justify='center' align='middle'>
                <Col>
                  <small>
                    <a href='mailto:support@meta-clinic.co.za'>support@meta-clinic.co.za</a>
                  </small>
                </Col>
              </Row>
            </div>
          )}
          {remoteUsers.map((user) => {
            return (
              <div key={user.uid} className='remote-video-container' style={{ height: '100%' }}>
                {user.hasVideo ? (
                  <RemoteUser user={user} style={{ width: '100%', height: '100%' }} />
                ) : (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      backgroundColor: antdToken.colorPrimary,
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <FontAwesomeIcon icon={faVideoSlash} color={antdToken.colorWhite} fontSize={80} />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>

      {/* Local Video - Bottom Right Corner */}
      <div style={{ position: 'absolute', bottom: 20, right: 20, width: '250px', height: '180px', zIndex: 100 }}>
        <div
          id='localVideo'
          style={{ width: '100%', height: '100%', backgroundColor: '#f0f0f0', borderRadius: '8px', overflow: 'hidden' }}
        >
          {cameraOn ? (
            <LocalUser
              audioTrack={localMicrophoneTrack}
              videoTrack={localCameraTrack}
              cameraOn={cameraOn}
              micOn={micOn}
              playAudio={false}
              playVideo={cameraOn}
            />
          ) : (
            <div
              style={{
                height: '100%',
                width: '100%',
                backgroundColor: antdToken.colorPrimary,
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <FontAwesomeIcon icon={faVideoSlash} color={antdToken.colorWhite} fontSize={80} />
            </div>
          )}
        </div>
      </div>
      {/* Controls Toolbar */}
      <Row justify='center' align='middle' style={{ marginTop: 10 }}>
        <Col>
          <Space>
            <Button
              type={micOn ? 'primary' : 'default'}
              icon={micOn ? <AudioOutlined /> : <AudioMutedOutlined />}
              onClick={() => setMic((a) => !a)}
            >
              {micOn ? 'Mute' : 'Unmute'}
            </Button>
            <Button
              type={cameraOn ? 'primary' : 'default'}
              icon={cameraOn ? <VideoCameraOutlined /> : <VideoCameraAddOutlined />}
              onClick={() => setCamera((a) => !a)}
            >
              {cameraOn ? 'Disable Video' : 'Enable Video'}
            </Button>
            <Popconfirm
              title='Disconnect from call'
              description='Are you sure you want to disconnect?'
              onConfirm={handleDisconnect}
              okText='Yes'
              cancelText='No'
              okButtonProps={{ danger: true }}
            >
              <Button danger type='primary' icon={<DisconnectOutlined />}>
                Disconnect
              </Button>
            </Popconfirm>
          </Space>
        </Col>
      </Row>
    </div>
  )
}
