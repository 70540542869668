import Agilite from 'agilite'
import BookingEnums from '../../../Bookings/utils/enums'
import { readSystemUsers } from '../../system-users/utils/utils'
import { readClinics } from '../../clinics/utils/utils'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const countBookings = (filter) => {
  return new Promise((resolve, reject) => {
    let response = null

    ;(async () => {
      try {
        response = await agilite.Connectors.execute(BookingEnums.profileKeys.BOOKINGS, BookingEnums.routeKeys.COUNT, {
          filter: JSON.stringify(filter)
        })
        resolve(response.data.result)
      } catch (e) {
        reject(e)
      }
    })()
  })
}

export const readAllBookings = (filter, page, pageLimit, options) => {
  return new Promise((resolve, reject) => {
    let data = []
    let authUserResponse = null
    let userIds = []
    let professionalIds = []

    ;(async () => {
      try {
        const [clinicResponse, bookingResponse] = await Promise.all([
          readClinics(),
          agilite.Connectors.execute(BookingEnums.profileKeys.BOOKINGS, BookingEnums.routeKeys.READ, {
            filter: JSON.stringify(filter),
            page,
            pageLimit,
            options: JSON.stringify(options)
          })
        ])

        for (const booking of bookingResponse.data) {
          if (booking.userRef) userIds.push(booking.userRef)
          if (booking.medicalProfRef) professionalIds.push(booking.medicalProfRef)
        }

        authUserResponse = await readSystemUsers(
          {
            $or: [{ _id: { $in: userIds } }, { _id: { $in: professionalIds } }]
          },
          null,
          null,
          'firstName lastName idNo dateOfBirth gender race extraData residentialAddress email phoneNumber medicalAid mainMemberId billingId',
          true
        )

        const authUserMap = Object.fromEntries(authUserResponse.map((user) => [user._id, user]))
        const clinicMap = Object.fromEntries(clinicResponse.map((clinic) => [clinic._id, clinic]))

        for (const booking of bookingResponse.data) {
          const authUser = authUserMap[booking.userRef]
          const medUser = authUserMap[booking.medicalProfRef]
          const clinic = clinicMap[booking.clinicRef]

          data.push({
            patientName: authUser ? `${authUser.firstName} ${authUser.lastName}` : '',
            patientAddress: authUser ? authUser.residentialAddress : '',
            clinicName: clinic ? clinic.name : 'Home Visit',
            professionalName: medUser ? `${medUser.firstName} ${medUser.lastName}` : '',
            profession: medUser
              ? `${medUser.extraData.profession.charAt(0).toUpperCase()}${medUser.extraData.profession.slice(1)}`
              : '',
            ...booking,
            clinicRecord: clinic,
            patientRecord: authUser ? authUser : {}
          })
        }

        resolve(data)
      } catch (e) {
        reject(e)
      }
    })()
  })
}
