import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Typography, List, Tag, Spin, Empty } from 'antd'
import {
  MedicineBoxOutlined,
  AlertOutlined,
  HeartOutlined,
  HistoryOutlined,
  ScissorOutlined,
  TeamOutlined,
  CoffeeOutlined
} from '@ant-design/icons'
import { getPatient } from '../../Medical Vault/medical-history/utils/utils'

const { Title, Text } = Typography

const PatientInfoMedHist = ({ userRef }) => {
  const [loading, setLoading] = useState(true)
  const [medicalHistory, setMedicalHistory] = useState(null)

  useEffect(() => {
    const fetchMedicalHistory = async () => {
      if (!userRef) {
        setLoading(false)
        return
      }

      try {
        setLoading(true)
        const patientData = await getPatient(userRef)

        setMedicalHistory(patientData.medicalHistory || {})
      } catch (error) {
        console.error('Error fetching medical history:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchMedicalHistory()
  }, [userRef])

  // Helper function to check if a section has data
  const hasData = (section) => {
    if (!medicalHistory || !medicalHistory[section]) return false

    if (section === 'allergies') {
      const allergies = medicalHistory.allergies
      return (
        (allergies?.medications && allergies.medications.length > 0) ||
        (allergies?.food && allergies.food.length > 0) ||
        (allergies?.general && allergies.general.length > 0)
      )
    }

    if (section === 'chronicIllnesses') {
      return medicalHistory.chronicIllnesses?.list && medicalHistory.chronicIllnesses.list.length > 0
    }

    if (section === 'medications') {
      return medicalHistory.medications && medicalHistory.medications.length > 0
    }

    if (section === 'surgicalHistory') {
      return medicalHistory.surgeries && medicalHistory.surgeries.length > 0
    }

    if (section === 'familyHistory') {
      const familyHistory = medicalHistory.familyHistory
      if (!familyHistory) return false

      const relations = [
        'father',
        'mother',
        'paternalGrandfather',
        'paternalGrandmother',
        'maternalGrandfather',
        'maternalGrandmother'
      ]

      return relations.some(
        (relation) => familyHistory[relation]?.conditions && familyHistory[relation].conditions.length > 0
      )
    }

    if (section === 'lifestyle') {
      return (
        medicalHistory.lifestyle && (medicalHistory.lifestyle.smoking || medicalHistory.lifestyle.alcoholConsumption)
      )
    }

    return false
  }

  // Allergies Section
  const AllergiesSection = () => {
    if (!hasData('allergies')) return null

    const { medications = [], food = [], general = [] } = medicalHistory.allergies

    return (
      <Card
        title={
          <>
            <AlertOutlined /> Allergies
          </>
        }
        style={{ marginBottom: 16 }}
        size='small'
      >
        {medications.length > 0 && (
          <div style={{ marginBottom: 16 }}>
            <Text strong>Medication Allergies:</Text>
            <List
              dataSource={medications}
              renderItem={(item) => (
                <List.Item>
                  <Tag color='red'>{item.name || item}</Tag>
                  {item.reaction && <Text type='secondary'> - {item.reaction}</Text>}
                  {item.severity && <Text type='secondary'> ({item.severity})</Text>}
                </List.Item>
              )}
            />
          </div>
        )}

        {food.length > 0 && (
          <div style={{ marginBottom: 16 }}>
            <Text strong>Food Allergies:</Text>
            <List
              dataSource={food}
              renderItem={(item) => (
                <List.Item>
                  <Tag color='orange'>{item}</Tag>
                </List.Item>
              )}
            />
          </div>
        )}

        {general.length > 0 && (
          <div>
            <Text strong>General Allergies:</Text>
            <List
              dataSource={general}
              renderItem={(item) => (
                <List.Item>
                  <Tag color='purple'>{item}</Tag>
                </List.Item>
              )}
            />
          </div>
        )}

        {!medications.length && !food.length && !general.length && <Empty description='No allergies recorded' />}
      </Card>
    )
  }

  // Chronic Illnesses Section
  const ChronicIllnessesSection = () => {
    if (!hasData('chronicIllnesses')) return null

    return (
      <Card
        title={
          <>
            <HeartOutlined /> Chronic Conditions
          </>
        }
        style={{ marginBottom: 16 }}
        size='small'
      >
        <List
          dataSource={medicalHistory.chronicIllnesses.list}
          renderItem={(item) => (
            <List.Item>
              <Tag color='purple'>{item.name || item}</Tag>
              {item.diagnosisDate && <Text type='secondary'> - Diagnosed: {item.diagnosisDate}</Text>}
            </List.Item>
          )}
          locale={{ emptyText: <Empty description='No chronic conditions recorded' /> }}
        />
      </Card>
    )
  }

  // Surgical History Section
  const SurgicalHistorySection = () => {
    if (!hasData('surgicalHistory')) return null

    return (
      <Card
        title={
          <>
            <ScissorOutlined /> Surgical History
          </>
        }
        style={{ marginBottom: 16 }}
        size='small'
      >
        <List
          dataSource={medicalHistory.surgeries}
          renderItem={(item) => (
            <List.Item>
              <Tag color='cyan'>{item.procedure}</Tag>
              {item.date && <Text type='secondary'> - Date: {new Date(item.date).toLocaleDateString()}</Text>}
              {item.notes && <Text type='secondary'> - {item.notes}</Text>}
            </List.Item>
          )}
          locale={{ emptyText: <Empty description='No surgical history recorded' /> }}
        />
      </Card>
    )
  }

  // Medications Section
  const MedicationsSection = () => {
    if (!hasData('medications')) return null

    // Group medications by type
    const acute = medicalHistory.medications.filter((med) => med.type === 'acute')
    const chronic = medicalHistory.medications.filter((med) => med.type === 'chronic')

    return (
      <Card
        title={
          <>
            <MedicineBoxOutlined /> Current Medications
          </>
        }
        style={{ marginBottom: 16 }}
        size='small'
      >
        {chronic.length > 0 && (
          <div style={{ marginBottom: 16 }}>
            <Text strong>Chronic Medications:</Text>
            <List
              dataSource={chronic}
              renderItem={(item) => (
                <List.Item>
                  <Tag color='green'>{item.name}</Tag>
                  {item.dosage && <Text type='secondary'> - Dosage: {item.dosage}</Text>}
                  {item.frequency && <Text type='secondary'> ({item.frequency})</Text>}
                </List.Item>
              )}
            />
          </div>
        )}

        {acute.length > 0 && (
          <div>
            <Text strong>Acute Medications:</Text>
            <List
              dataSource={acute}
              renderItem={(item) => (
                <List.Item>
                  <Tag color='cyan'>{item.name}</Tag>
                  {item.dosage && <Text type='secondary'> - Dosage: {item.dosage}</Text>}
                  {item.frequency && <Text type='secondary'> ({item.frequency})</Text>}
                </List.Item>
              )}
            />
          </div>
        )}

        {!chronic.length && !acute.length && <Empty description='No medications recorded' />}
      </Card>
    )
  }

  // Family History Section
  const FamilyHistorySection = () => {
    if (!hasData('familyHistory')) return null

    const relations = [
      { key: 'father', label: 'Father' },
      { key: 'mother', label: 'Mother' },
      { key: 'paternalGrandfather', label: 'Grandfather (Paternal)' },
      { key: 'paternalGrandmother', label: 'Grandmother (Paternal)' },
      { key: 'maternalGrandfather', label: 'Grandfather (Maternal)' },
      { key: 'maternalGrandmother', label: 'Grandmother (Maternal)' }
    ]

    const relationsWithData = relations.filter(
      (relation) => medicalHistory.familyHistory[relation.key]?.conditions?.length > 0
    )

    return (
      <Card
        title={
          <>
            <TeamOutlined /> Family History
          </>
        }
        style={{ marginBottom: 16 }}
        size='small'
      >
        {relationsWithData.length > 0 ? (
          relationsWithData.map((relation) => (
            <div key={relation.key} style={{ marginBottom: 16 }}>
              <Text strong>{relation.label}:</Text>
              <div style={{ marginTop: 8 }}>
                {medicalHistory.familyHistory[relation.key].conditions.map((condition, index) => (
                  <Tag color='orange' key={index} style={{ margin: '0 8px 8px 0' }}>
                    {condition}
                  </Tag>
                ))}
              </div>
            </div>
          ))
        ) : (
          <Empty description='No family history recorded' />
        )}
      </Card>
    )
  }

  // Lifestyle Section
  const LifestyleSection = () => {
    if (!hasData('lifestyle')) return null

    const { smoking, alcoholConsumption, smokingLevel, alcoholConsumptionLevel } = medicalHistory.lifestyle

    return (
      <Card
        title={
          <>
            <CoffeeOutlined /> Lifestyle
          </>
        }
        style={{ marginBottom: 16 }}
        size='small'
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text strong>Smoking: </Text>
            <Text>{smoking ? `Yes${smokingLevel ? ` - ${smokingLevel}` : ''}` : 'No'}</Text>
          </Col>

          <Col span={24}>
            <Text strong>Alcohol: </Text>
            <Text>
              {alcoholConsumption ? `Yes${alcoholConsumptionLevel ? ` - ${alcoholConsumptionLevel}` : ''}` : 'No'}
            </Text>
          </Col>
        </Row>
      </Card>
    )
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size='large' />
        <div style={{ marginTop: 16 }}>Loading medical history...</div>
      </div>
    )
  }

  if (!medicalHistory) {
    return <Empty description='No medical history available for this patient' style={{ padding: '50px' }} />
  }

  const hasMedicalData =
    hasData('allergies') ||
    hasData('chronicIllnesses') ||
    hasData('medications') ||
    hasData('surgicalHistory') ||
    hasData('familyHistory') ||
    hasData('lifestyle')

  if (!hasMedicalData) {
    return <Empty description='No medical history data available for this patient' style={{ padding: '50px' }} />
  }

  return (
    <div style={{ padding: '0 16px' }}>
      <AllergiesSection />
      <ChronicIllnessesSection />
      <MedicationsSection />
      <SurgicalHistorySection />
      <FamilyHistorySection />
      <LifestyleSection />
    </div>
  )
}

export default PatientInfoMedHist
