import React, { useEffect } from 'react'
import CustomRow from '../../../../reusable-components/CustomRow'
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  theme
} from 'antd'
import { useSelector } from 'react-redux'
import Templates from '../../utils/templates'
import useGeneralForm from '../../hooks/useGeneralForm'
import { faInfoCircle, faKey, faUser, faUserDoctor, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { hexToRGBA, specialFieldValidation } from '../../../../lib/utils'
import RenderFormItems from './RenderFormItems'

const SystemUsersGerneral = React.memo(
  ({
    type,
    isNewRecord,
    activeRecords,
    dataIndex,
    setViewType,
    refreshView,
    entities,
    services,
    servicesLoading,
    closeTab
  }) => {
    const authState = useSelector((state) => state.auth)
    const { token } = theme.useToken()
    const {
      handleIsActive,
      handleRolesSelection,
      handleSubmit,
      setSpecialFormState,
      deactivatePrompt,
      clientForm,
      loading,
      specialFormState,
      unfilledMedicalFields,
      unfilledProfileFields,
      record,
      handleFieldsChange
    } = useGeneralForm({
      isNewRecord,
      activeRecords,
      dataIndex,
      refreshView,
      type,
      setViewType,
      closeTab
    })

    useEffect(() => {
      handleFieldsChange()
      // eslint-disable-next-line
    }, [clientForm])

    return (
      <>
        <CustomRow justify='center' className='basic-card'>
          <Col span={24}>
            <Form
              id='medProf_form'
              style={{ maxWidth: '100%', margin: '0 auto' }}
              onFinish={handleSubmit}
              onFinishFailed={(e) => {
                message.error('Required fields are incomplete. Please fill in all mandatory fields and submit again.')
              }}
              name='form'
              form={clientForm}
              onFieldsChange={handleFieldsChange}
              initialValues={{
                ...Templates.dataTemplate(),
                ...activeRecords[dataIndex],
                extraData: {
                  ...Templates.dataTemplate().extraData,
                  ...activeRecords[dataIndex]?.extraData,
                  services: activeRecords[dataIndex]?.extraData?.services || [],
                  profession: activeRecords[dataIndex]?.extraData?.profession || ''
                },
                phoneNumber: activeRecords[dataIndex]?.phoneNumber
                  ? activeRecords[dataIndex]?.phoneNumber.replace('+27', '')
                  : ''
              }}
              layout='vertical'
            >
              <Tabs
                hideAdd
                tabBarExtraContent={{
                  left: (
                    <Button onClick={closeTab} style={{ marginRight: 12 }}>
                      <FontAwesomeIcon icon={faXmark} color={token.colorError} />
                      Close
                    </Button>
                  ),
                  right: (
                    <>
                      {(authState.agiliteUser.extraData.role.type === 'admin' ||
                        authState.agiliteUser.extraData.role.type === 'super_admin') && (
                        <Col>
                          <Form.Item noStyle>
                            {deactivatePrompt ? (
                              <Popconfirm
                                okText='Yes'
                                cancelText='No'
                                okButtonProps={{
                                  style: { background: token.colorSuccess, color: 'white' }
                                }}
                                cancelButtonProps={{
                                  style: { background: token.colorError, color: 'white' }
                                }}
                                title={<h3 style={{ color: token.colorWarning, margin: 0 }}>WARNING</h3>}
                                description='You have switched this system user account to be inactive. Are you sure you want to proceed?'
                                onConfirm={() => {
                                  clientForm.submit()
                                }}
                              >
                                <Button
                                  style={{
                                    backgroundColor: token.colorSuccess,
                                    color: 'white',
                                    marginRight: 10
                                  }}
                                  htmlType='submit'
                                  loading={loading}
                                >
                                  {isNewRecord ? 'Invite' : 'Save Changes'}
                                </Button>
                              </Popconfirm>
                            ) : (
                              <Button
                                style={{
                                  backgroundColor: token.colorSuccess,
                                  color: 'white',
                                  marginRight: 10
                                }}
                                htmlType='submit'
                                loading={loading}
                              >
                                {isNewRecord ? 'Create Profile' : 'Save Changes'}
                              </Button>
                            )}
                          </Form.Item>
                        </Col>
                      )}
                    </>
                  )
                }}
              >
                <Tabs.TabPane
                  tab={
                    <span>
                      <FontAwesomeIcon icon={faUser} color={token.colorPrimary} /> Profile
                      {unfilledProfileFields > 0 && (
                        <Badge
                          count={unfilledProfileFields}
                          style={{ backgroundColor: token.colorError, marginLeft: 8 }}
                        />
                      )}
                    </span>
                  }
                  key='general'
                >
                  <Row justify='center' gutter={[24, 24]} style={{ marginTop: 12 }}>
                    <Col span={24}>
                      {authState.agiliteUser.extraData.role.type === 'super_admin' && (
                        <Form.Item
                          name={Templates.dataModel.entities.key}
                          label={<span>Linked Entities:</span>}
                          rules={[
                            {
                              required: Templates.dataModel.entities.required,
                              message: 'Select Admin linked entities'
                            }
                          ]}
                        >
                          <Select
                            mode='multiple'
                            placeholder='-Select entities-'
                            maxLength={1}
                            filterOption={(input, option) => {
                              return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            options={entities.map((entity) => ({
                              label: entity.name,
                              value: entity._id
                            }))}
                          />
                        </Form.Item>
                      )}

                      <RenderFormItems
                        activeRecord={activeRecords[dataIndex]}
                        isNewRecord={isNewRecord}
                        clientForm={clientForm}
                        specialFormState={specialFormState}
                        setSpecialFormState={setSpecialFormState}
                        handleIsActive={handleIsActive}
                        handleRolesSelection={handleRolesSelection}
                        type={type}
                        record={record}
                      />
                    </Col>
                  </Row>
                </Tabs.TabPane>
                {(activeRecords[dataIndex]?.extraData.role.type === 'medical_professional' || type === 'medical') && (
                  <Tabs.TabPane
                    tab={
                      <span>
                        <FontAwesomeIcon icon={faUserDoctor} color={token.colorSecondary} /> Medical Profile
                        {unfilledMedicalFields > 0 && (
                          <Badge
                            count={unfilledMedicalFields}
                            style={{ backgroundColor: token.colorError, marginLeft: 8 }}
                          />
                        )}
                      </span>
                    }
                    key='professional-profile'
                  >
                    {(activeRecords[dataIndex]?.extraData.role.type === 'medical_professional' ||
                      type === 'medical') && (
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Card
                            size='small'
                            style={{ width: '100%' }}
                            styles={{ header: { background: hexToRGBA(token.colorSecondary, 0.2) } }}
                            title={
                              <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                                Professional Info
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </Space>
                            }
                          >
                            <div
                              style={{
                                alignItems: 'baseline',
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: '100%',
                                gap: 12
                              }}
                            >
                              <Form.Item
                                style={{ width: 350, maxWidth: '100%' }}
                                name={Templates.dataModel.profession.key}
                                label={Templates.dataModel.profession.label}
                                rules={[
                                  {
                                    required: Templates.dataModel.profession.required,
                                    message: Templates.dataModel.profession.validationMsg
                                  }
                                ]}
                              >
                                <Select
                                  options={[{ value: '', label: '-Select a profession-' }, ...authState.professions]}
                                />
                              </Form.Item>

                              {(activeRecords[dataIndex]?.extraData.profession === 'doctor' ||
                                record?.extraData?.profession === 'doctor') &&
                                (record?.extraData?.profession === 'doctor' || !record?.extraData?.profession) && (
                                  <>
                                    <Form.Item
                                      style={{ width: 350, maxWidth: '100%' }}
                                      name={Templates.dataModel.mpNumber.key}
                                      label={Templates.dataModel.mpNumber.label}
                                      rules={[
                                        {
                                          required: Templates.dataModel.mpNumber.required,
                                          message: Templates.dataModel.mpNumber.validationMsg
                                        }
                                      ]}
                                    >
                                      <Input
                                        onChange={(e) => {
                                          specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                                            key: 'extraData.mpNumber',
                                            event: e,
                                            validationConfig: {
                                              letters: { allowed: false, onlyCaps: false },
                                              numbers: true,
                                              spaces: false
                                            }
                                          })
                                        }}
                                        maxLength={50}
                                        placeholder={Templates.dataModel.mpNumber.placeholder}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      style={{ width: 350, maxWidth: '100%' }}
                                      name={Templates.dataModel.qualification.key}
                                      label={Templates.dataModel.qualification.label}
                                      rules={[
                                        {
                                          required: Templates.dataModel.qualification.required,
                                          message: Templates.dataModel.qualification.validationMsg
                                        }
                                      ]}
                                    >
                                      <Input placeholder={Templates.dataModel.qualification.placeholder} />
                                    </Form.Item>
                                  </>
                                )}
                              <Form.Item
                                style={{ width: 700, maxWidth: '100%' }}
                                name={Templates.dataModel.services.key}
                                label={
                                  <span>
                                    <span style={{ color: token.colorError }}>* </span>Services:
                                  </span>
                                }
                              >
                                <Select
                                  loading={servicesLoading}
                                  placeholder='-Select services-'
                                  mode='multiple'
                                  showSearch
                                  optionFilterProp='label'
                                  options={services}
                                />
                              </Form.Item>
                            </div>
                          </Card>
                        </Col>
                        <Col span={12}>
                          <Card
                            size='small'
                            styles={{ header: { background: hexToRGBA(token.colorSecondary, 0.2) } }}
                            title={
                              <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                                Access
                                <FontAwesomeIcon icon={faKey} />
                              </Space>
                            }
                          >
                            <Space wrap>
                              <Form.Item
                                name={['extraData', 'hasAvailabilityAccess']}
                                label={
                                  <span>
                                    <span style={{ color: token.colorError }}>* </span>Has Access to Availability:
                                  </span>
                                }
                                valuePropName='checked'
                              >
                                <Switch checkedChildren='Yes' unCheckedChildren='No' />
                              </Form.Item>
                              <Form.Item
                                name={Templates.dataModel.isManager.key}
                                label={Templates.dataModel.isManager.label}
                                valuePropName='checked'
                                rules={[
                                  {
                                    required: Templates.dataModel.isManager.required,
                                    message: Templates.dataModel.isManager.validationMsg
                                  }
                                ]}
                              >
                                <Switch checkedChildren='Yes' unCheckedChildren='No' />
                              </Form.Item>
                            </Space>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </Tabs.TabPane>
                )}
              </Tabs>
            </Form>
          </Col>
        </CustomRow>
      </>
    )
  }
)

export default SystemUsersGerneral
