import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  theme,
  Tooltip,
  Tag,
  Divider
} from 'antd'
import React, { useEffect, useState } from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import { handleError } from '../../lib/utils'
import { countInvoices, getInvoices, updateInvoice } from '../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faCheckCircle, faEye, faPencil, faPrint, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { readPatients } from '../../Admin/patients/utils/utils'
import dayjs from 'dayjs'
import { goodXResponse, goodXStatus, readBookings } from '../../Bookings/utils/utils'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../core/utils/reducer'
import CoreEnums from '../../../core/utils/enums'
import InvoiceForm from './invoice-form'
import PatientPaymentForm from './patient-payment-form'

const InvoicesList = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [filters, setFilters] = useState({
    status: '',
    invoiceType: '',
    createdAt: '',
    search: ''
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [claimResponse, setClaimResponse] = useState(null)
  const [showFinancials, setShowFinancials] = useState(false)

  useEffect(() => {
    handleGetInvoices()
    // eslint-disable-next-line
  }, [filters, page, pageSize])

  const handleGetInvoices = async () => {
    let tmpData = []
    let patientIds = []
    let bookingIds = []
    let clinicIds = []
    let medicalProfIds = []
    let patientRecords = []
    let bookingRecords = []
    let tmpTotal = 0
    let tmpFilters = JSON.parse(JSON.stringify(filters))

    setLoading(true)

    try {
      // Filters
      for (const key in tmpFilters) {
        if (!tmpFilters[key]) {
          delete tmpFilters[key]
        }

        if (key === 'createdAt' && tmpFilters[key]) {
          tmpFilters[key] = {
            $lte: dayjs(new Date(tmpFilters[key])).set('hour', 23).set('minute', 59).set('second', 59).toDate(),
            $gte: dayjs(new Date(tmpFilters[key])).set('hour', 0).set('minute', 0).set('second', 0).toDate()
          }
        }

        if (key === 'search' && tmpFilters[key]) {
          tmpFilters.$or = [
            { invoiceNo: { $regex: tmpFilters[key], $options: 'i' } },
            { claimStatus: { $regex: tmpFilters[key], $options: 'i' } },
            { status: { $regex: tmpFilters[key], $options: 'i' } }
          ]
          delete tmpFilters[key]
        }
      }

      tmpTotal = await countInvoices(tmpFilters)
      setTotal(tmpTotal)

      tmpData = await getInvoices(tmpFilters, page, pageSize)

      for (const entry of tmpData) {
        if (entry.goodXTicketNo) {
          entry.goodXStatus = await goodXStatus(entry.goodXTicketNo)

          if (entry.goodXStatus === 'Processed_successful') {
            entry.claimResponse = await goodXResponse(entry.goodXTicketNo)
            entry.claimStatus =
              entry.claimResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].ClaimHeaderStatusCode[0]
          } else {
            entry.claimResponse = null
            entry.claimStatus = 'N/A'
          }
        } else {
          entry.goodXStatus = 'Private'
          entry.claimResponse = null
          entry.claimStatus = 'N/A'
        }
      }

      for (const entry of tmpData) {
        // Patient IDs
        patientIds.push(entry.userRef)

        // Medical Prof IDs
        medicalProfIds.push(entry.medicalProfRef)
        medicalProfIds.push(entry.refMedProfRef)
        medicalProfIds.push(entry.assMedProfRef)

        // Booking IDs
        if (entry.bookingRef) {
          bookingIds.push(entry.bookingRef)
        }

        // Clinic IDs
        clinicIds.push(entry.clinicRef)
      }

      patientRecords = await readPatients({ _id: { $in: patientIds } }, {}, 'firstName lastName idNo')

      for (const entry of tmpData) {
        const patientRecord = patientRecords.find((x) => x._id === entry.userRef)

        if (patientRecord) {
          entry.fullName = patientRecord.firstName + ' ' + patientRecord.lastName
          entry.idNo = patientRecord.idNo
        }
      }

      bookingRecords = await readBookings({ _id: { $in: bookingIds } }, {}, 'bookingDate')

      for (const entry of tmpData) {
        const bookingRecord = bookingRecords.find((x) => x._id === entry.bookingRef)

        if (bookingRecord) {
          entry.bookingDate = bookingRecord.bookingDate
        } else {
          entry.bookingDate = 'N/A'
        }
      }

      setData(JSON.parse(JSON.stringify(tmpData)))
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleOpenInvoice = (record) => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.INVOICES}_${record._id}`,
        closable: true,
        label: `Invoice: ${record.fullName}`,
        children: <InvoiceForm data={record} isNew={false} handleGetInvoices={handleGetInvoices} />
      })
    )
  }

  const handleCreateInvoice = () => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.INVOICES}_new`,
        closable: true,
        label: 'New Invoice',
        children: <InvoiceForm data={{}} isNew={true} handleGetInvoices={handleGetInvoices} />
      })
    )
  }

  const handleCreateNewPatientPayment = (record) => {
    dispatch(
      coreReducer.actions.addTab({
        key: `${CoreEnums.tabKeys.PATIENT_PAYMENTS}_new`,
        closable: true,
        label: 'New Patient Payment',
        children: <PatientPaymentForm data={record} />
      })
    )
  }

  const handleMarkAsComplete = async (record) => {
    try {
      await updateInvoice(record._id, { status: 'completed' })
      message.success('Invoice marked as completed')
      handleGetInvoices()
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleViewClaimResponses = async (record) => {
    setIsModalOpen(true)
    setClaimResponse(record.claimResponse)
  }

  const renderClaimResponseModal = () => {
    const { token } = theme.useToken()

    const cardStyle = {
      marginBottom: 5,
      boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
      borderRadius: 8
    }

    return (
      <Modal
        title={
          <div
            style={{
              borderBottom: `1px solid ${token.colorBorderSecondary}`,
              padding: '16px 24px',
              margin: '-20px -24px 20px'
            }}
          >
            <h3 style={{ margin: 0, color: token.colorTextHeading }}>Claim Response Details</h3>
          </div>
        }
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false)
          setClaimResponse(null)
          setShowFinancials(false)
        }}
        footer={null}
        width={800}
      >
        <Space direction='vertical' style={{ width: '100%' }}>
          {claimResponse && (
            <>
              <Card
                type='inner'
                title='Claim & Patient Information'
                size='small'
                style={cardStyle}
                styles={{ header: { backgroundColor: token.colorPrimary, color: token.colorWhite } }}
              >
                <span style={{ fontWeight: 'bold', color: token.colorPrimary }}>Claim Information</span>
                <p>
                  <strong>Claim Number:</strong>{' '}
                  {
                    claimResponse.ProviderClaimResponse.ProviderClaim[0].DispensingProviderClaim[0]
                      .CommonClaimHeaderInfo[0].ClaimNumber[0]
                  }
                </p>
                <p>
                  <strong>Transaction Date:</strong>{' '}
                  {dayjs(
                    claimResponse.ProviderClaimResponse.ProviderClaim[0].DispensingProviderClaim[0]
                      .CommonClaimHeaderInfo[0].DateTransactionCreated[0]
                  ).format('YYYY-MM-DD')}
                </p>
                <p>
                  <strong>Gross Amount:</strong> R
                  {
                    claimResponse.ProviderClaimResponse.ProviderClaim[0].DispensingProviderClaim[0]
                      .CommonClaimHeaderInfo[0].ClaimHeaderFinancial[0].CommonClaimBaseFinancialInfo[0]
                      .SubmittedGrossAmount[0]
                  }
                </p>
                <hr />
                <span style={{ fontWeight: 'bold', color: token.colorPrimary }}>Patient Information</span>
                <p>
                  <strong>Name:</strong>{' '}
                  {
                    claimResponse.ProviderClaimResponse.ProviderClaim[0].DispensingProviderClaim[0]
                      .CommonClaimHeaderInfo[0].Policy[0].Patient[0].FirstName[0]
                  }{' '}
                  {
                    claimResponse.ProviderClaimResponse.ProviderClaim[0].DispensingProviderClaim[0]
                      .CommonClaimHeaderInfo[0].Policy[0].Patient[0].CommonPersonInfo[0].Surname[0]
                  }
                </p>
                <p>
                  <strong>ID Number:</strong>{' '}
                  {
                    claimResponse.ProviderClaimResponse.ProviderClaim[0].DispensingProviderClaim[0]
                      .CommonClaimHeaderInfo[0].Policy[0].Patient[0].CommonPersonInfo[0].IdentityNumber[0]
                  }
                </p>
                <p>
                  <strong>Medical Aid:</strong>{' '}
                  {
                    claimResponse.ProviderClaimResponse.ProviderClaim[0].DispensingProviderClaim[0]
                      .CommonClaimHeaderInfo[0].Payor[0].Name[0]
                  }
                </p>
                <p>
                  <strong>Plan:</strong>{' '}
                  {
                    claimResponse.ProviderClaimResponse.ProviderClaim[0].DispensingProviderClaim[0]
                      .CommonClaimHeaderInfo[0].Payor[0].ClaimOption[0]
                  }
                </p>
              </Card>

              <Card
                type='inner'
                title='Response Details'
                size='small'
                style={cardStyle}
                styles={{ header: { backgroundColor: token.colorSecondary, color: token.colorWhite } }}
              >
                <p>
                  <strong>Primary Status:</strong>{' '}
                  {claimResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].PrimaryTrackingStatus[0]}
                </p>
                <p>
                  <strong>Claim Status:</strong>{' '}
                  {claimResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].ClaimHeaderStatusCode[0] ===
                  'mfREJ' ? (
                    <span style={{ color: token.colorError }}>Medical Aid Rejected</span>
                  ) : claimResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0]
                      .ClaimHeaderStatusCode[0] === 'mfAPP' ? (
                    <span style={{ color: token.colorSuccess }}>Medical Aid Approved</span>
                  ) : claimResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0]
                      .ClaimHeaderStatusCode[0] === 'swACC' ? (
                    <span style={{ color: token.colorWarning }}>Switch Acknowledged</span>
                  ) : claimResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0]
                      .ClaimHeaderStatusCode[0] === 'mfACK' ? (
                    <span style={{ color: token.colorWarning }}>Medical Aid Acknowledged</span>
                  ) : claimResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0]
                      .ClaimHeaderStatusCode[0] === 'swREJ' ? (
                    <span style={{ color: token.colorError }}>Switch Rejected</span>
                  ) : (
                    claimResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].ClaimHeaderStatusCode[0]
                  )}
                </p>
                <p>
                  <strong>Status Code:</strong>{' '}
                  {claimResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].ClaimHeaderStatusCode[0]}
                </p>
                <p>
                  <strong>Transaction ID:</strong>{' '}
                  {
                    claimResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].ResponseNumber[0]
                      .Transaction[0].$.Id
                  }
                </p>
                <Divider style={{ margin: '5px 0' }} />
                {claimResponse.ProviderClaimResponse.CommonProviderClaimResponseInfo[0].ProcessReasonCode?.map(
                  (reason, index) => (
                    <div key={index} style={cardStyle}>
                      <p>
                        <strong>Priority:</strong> {reason.Priority[0]}
                      </p>
                      <p>
                        <strong>Description:</strong> {reason.Description[0]}
                      </p>
                    </div>
                  )
                )}
              </Card>

              <Button type='primary' onClick={() => setShowFinancials(!showFinancials)} style={{ marginBottom: 10 }}>
                {showFinancials ? 'Hide Financial Details' : 'Show Financial Details'}
              </Button>

              {showFinancials && (
                <Card
                  type='inner'
                  title='Financial Details'
                  size='small'
                  style={cardStyle}
                  styles={{ header: { backgroundColor: token.colorWarning, color: token.colorWhite } }}
                >
                  {claimResponse.ProviderClaimResponse.ClaimFinancialOutcome?.[0]?.CommonFinancialOutcome?.[0] && (
                    <>
                      <span style={{ fontWeight: 'bold', color: token.colorWarning }}>Overall Claim Amounts</span>
                      <Row gutter={[16, 8]}>
                        <Col span={12}>
                          <p>
                            <strong>Submitted Amount:</strong> R
                            {
                              claimResponse.ProviderClaimResponse.ProviderClaim[0].DispensingProviderClaim[0]
                                .CommonClaimHeaderInfo[0].ClaimHeaderFinancial[0].CommonClaimBaseFinancialInfo[0]
                                .SubmittedGrossAmount[0]
                            }
                          </p>
                        </Col>
                        <Col span={12}>
                          <p>
                            <strong>Approved Amount:</strong> R
                            {
                              claimResponse.ProviderClaimResponse.ClaimFinancialOutcome[0].CommonFinancialOutcome[0]
                                .ApprovedClaimAmount[0]
                            }
                          </p>
                        </Col>
                      </Row>

                      <Divider style={{ margin: '10px 0' }} />
                      <span style={{ fontWeight: 'bold', color: token.colorWarning }}>Extended Financials</span>
                      <Row gutter={[16, 8]}>
                        <Col span={8}>
                          <p>
                            <strong>Levy:</strong> R
                            {claimResponse.ProviderClaimResponse.ClaimFinancialOutcome[0].ExtendedFinancials[0].Levy[0]}
                          </p>
                        </Col>
                        <Col span={8}>
                          <p>
                            <strong>Co-Payment:</strong> R
                            {
                              claimResponse.ProviderClaimResponse.ClaimFinancialOutcome[0].ExtendedFinancials[0]
                                .CoPayment[0]
                            }
                          </p>
                        </Col>
                        <Col span={8}>
                          <p>
                            <strong>Overcharge:</strong> R
                            {
                              claimResponse.ProviderClaimResponse.ClaimFinancialOutcome[0].ExtendedFinancials[0]
                                .OverCharge[0]
                            }
                          </p>
                        </Col>
                      </Row>

                      <Divider style={{ margin: '10px 0' }} />
                      <span style={{ fontWeight: 'bold', color: token.colorWarning }}>Line Items</span>
                      {claimResponse.ProviderClaimResponse.ClaimLineResponse.map((line, index) => {
                        const lineItem =
                          claimResponse.ProviderClaimResponse.ProviderClaim[0].DispensingProviderClaim[0].ClaimLineItem[
                            index
                          ]
                        const description =
                          lineItem.ClaimLineTariffItem?.[0]?.CommonClaimLineItemInfo?.[0]?.DescriptionOfItem?.[0] ||
                          lineItem.ClaimLineProductItem?.[0]?.CommonClaimLineItemInfo?.[0]?.DescriptionOfItem?.[0]
                        return (
                          <Card key={index} size='small' style={{ marginTop: 8 }}>
                            <p>
                              <strong>Description:</strong> {description}
                            </p>
                            <Row gutter={[16, 8]}>
                              <Col span={12}>
                                <p>
                                  <strong>Submitted:</strong> R
                                  {lineItem.ClaimLineTariffItem?.[0]?.CommonClaimLineItemInfo?.[0]
                                    ?.ClaimItemFinancial?.[0]?.CommonClaimBaseFinancialInfo?.[0]
                                    ?.SubmittedGrossAmount?.[0] ||
                                    lineItem.ClaimLineProductItem?.[0]?.CommonClaimLineItemInfo?.[0]
                                      ?.ClaimItemFinancial?.[0]?.CommonClaimBaseFinancialInfo?.[0]
                                      ?.SubmittedGrossAmount?.[0]}
                                </p>
                              </Col>
                              <Col span={12}>
                                <p>
                                  <strong>Approved:</strong> R
                                  {line.LineItemFinancialOutcome[0].CommonFinancialOutcome[0].ApprovedClaimAmount[0]}
                                </p>
                              </Col>
                            </Row>
                            <p>
                              <strong>Status:</strong>{' '}
                              <Tag color={line.ProcessStatus[0] === 'Approved' ? token.colorSuccess : token.colorError}>
                                {line.ProcessStatus[0]}
                              </Tag>
                            </p>
                          </Card>
                        )
                      })}
                    </>
                  )}
                </Card>
              )}
            </>
          )}
        </Space>
      </Modal>
    )
  }

  const { token } = theme.useToken()

  return (
    <CustomRow>
      <Col span={24}>
        <Card
          type='inner'
          size='small'
          extra={
            <Space>
              <Button type='primary' onClick={handleCreateInvoice}>
                <Space>
                  <FontAwesomeIcon icon={faAdd} />
                  New Invoice
                </Space>
              </Button>
            </Space>
          }
        >
          <Row justify='space-around' style={{ marginBottom: 10 }}>
            <Col span={4}>
              <Select
                placeholder='Status'
                style={{ width: '100%' }}
                options={[
                  { label: 'Pending', value: 'pending' },
                  { label: 'Completed', value: 'completed' }
                ]}
                onChange={(value) => setFilters({ ...filters, status: value })}
              />
            </Col>
            <Col span={4}>
              <Select
                placeholder='Invoice Type'
                style={{ width: '100%' }}
                options={[
                  { label: 'Medical', value: 'medical' },
                  { label: 'Insurance', value: 'insurance' },
                  { label: 'Private', value: 'private' }
                ]}
                onChange={(value) => setFilters({ ...filters, invoiceType: value })}
              />
            </Col>
            <Col span={4}>
              <DatePicker
                placeholder='Date'
                style={{ width: '100%' }}
                onChange={(date) => {
                  setFilters({ ...filters, createdAt: date })
                }}
              />
            </Col>
            <Col span={4}>
              <Input
                placeholder='Search'
                style={{ width: '100%' }}
                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
              />
            </Col>
            <Col span={4}>
              <Button
                style={{ float: 'right', backgroundColor: token.colorSuccess }}
                type='primary'
                onClick={handleGetInvoices}
              >
                <Space>
                  <FontAwesomeIcon icon={faRefresh} />
                  Refresh
                </Space>
              </Button>
            </Col>
          </Row>
          <Table
            loading={loading}
            dataSource={data}
            bordered
            size='small'
            columns={[
              {
                title: 'Invoice Number',
                dataIndex: 'invoiceNo',
                key: 'invoiceNo',
                width: '10%',
                sorter: (a, b) => a.invoiceNo - b.invoiceNo
              },
              {
                title: 'Full Name',
                dataIndex: 'fullName',
                key: 'fullName',
                sorter: (a, b) => a.fullName.localeCompare(b.fullName)
              },
              {
                title: 'ID Number',
                dataIndex: 'idNo',
                key: 'idNo',
                sorter: (a, b) => a.idNo.localeCompare(b.idNo)
              },
              {
                title: 'Treatment Date',
                dataIndex: 'bookingDate',
                key: 'bookingDate',
                render: (text, record) => {
                  return record.bookingDate === 'N/A' ? 'N/A' : dayjs(text).format('YYYY-MM-DD HH:mm:ss')
                },
                sorter: (a, b) => a.bookingDate - b.bookingDate,
                width: '10%'
              },
              {
                title: 'Invoice Type',
                dataIndex: 'invoiceType',
                key: 'invoiceType',
                render: (text) => {
                  switch (text) {
                    case 'medical':
                      return 'Medical Aid'
                    case 'insurance':
                      return 'Insurance'
                    case 'private':
                      return 'Private'
                  }
                }
              },
              {
                title: 'Switch Status',
                dataIndex: 'goodXStatus',
                key: 'status',
                render: (text, record) => {
                  if (record.status === 'completed') {
                    return <Tag color={token.colorSuccess}>Completed</Tag>
                  }

                  if (!text) return <Tag color={token.colorPrimary}>Submitted</Tag>

                  const returnText = text
                    .split(/[_\s]/) // Split by underscore or space
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ')

                  return returnText === 'Processed Successful' ? (
                    <Tag color={token.colorPrimary}>Processed</Tag>
                  ) : (
                    <Tag color={token.colorWarning}>{returnText}</Tag>
                  )
                }
              },
              {
                title: 'Claim Status',
                dataIndex: 'claimStatus',
                key: 'claimStatus',
                render: (text) => {
                  if (!text) return <Tag color={token.colorPrimary}>Pending</Tag>

                  const statusMap = {
                    mfREJ: { text: 'Medical Aid Rejected', color: token.colorError },
                    mfAPP: { text: 'Medical Aid Approved', color: token.colorSuccess },
                    swACC: { text: 'Switch Acknowledged', color: token.colorWarning },
                    mfACK: { text: 'Medical Aid Acknowledged', color: token.colorWarning },
                    swREJ: { text: 'Switch Rejected', color: token.colorError }
                  }

                  const status = statusMap[text] || { text: text, color: token.colorPrimary }
                  return <Tag color={status.color}>{status.text}</Tag>
                }
              },
              {
                title: 'Invoice Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => {
                  return (
                    <center>
                      {text === 'completed' ? (
                        <FontAwesomeIcon style={{ fontSize: 18 }} icon={faCheckCircle} color={token.colorSuccess} />
                      ) : (
                        <></>
                      )}
                    </center>
                  )
                }
              },
              {
                title: 'Creation Date',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
                sorter: (a, b) => a.createdAt - b.createdAt,
                width: '10%'
              },
              {
                title: 'Actions',
                key: 'status',
                width: '10%',
                render: (text, record) => {
                  return (
                    <center>
                      <Space style={{ gap: 12 }}>
                        <Tooltip title='View Invoice'>
                          <FontAwesomeIcon
                            style={{ color: '#aaa' }}
                            icon={faPencil}
                            onClick={() => handleOpenInvoice(record)}
                          />
                        </Tooltip>
                        {record.goodXStatus === 'Processed_successful' || record.status === 'completed' ? (
                          <Tooltip title='View Claim Responses'>
                            <FontAwesomeIcon
                              style={{ color: '#aaa' }}
                              icon={faEye}
                              onClick={() => handleViewClaimResponses(record)}
                            />
                          </Tooltip>
                        ) : undefined}
                        <Tooltip title='Print Invoice'>
                          <FontAwesomeIcon
                            style={{ color: '#aaa' }}
                            icon={faPrint}
                            onClick={() => handleCreateNewPatientPayment(record)}
                          />
                        </Tooltip>
                        {record.status !== 'completed' ? (
                          <Tooltip title='Mark Invoice as manually Completed'>
                            <FontAwesomeIcon
                              style={{ color: '#aaa' }}
                              icon={faCheckCircle}
                              onClick={() => handleMarkAsComplete(record)}
                            />
                          </Tooltip>
                        ) : undefined}
                      </Space>
                    </center>
                  )
                }
              }
            ]}
            pagination={{
              total: total,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true
            }}
            onChange={(pagination) => {
              setPage(pagination.current)
              setPageSize(pagination.pageSize)
            }}
          />
        </Card>
      </Col>
      {renderClaimResponseModal()}
    </CustomRow>
  )
}

export default InvoicesList
