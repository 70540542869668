import React from 'react'
import { Typography, Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import '../styling/ReferenceRange.css'

const { Title, Text } = Typography

const formatInterpretation = (interpretation) => {
  // Return null if interpretation is undefined
  if (!interpretation) return null
  
  // Split into main parts
  const parts = interpretation.split('.')
  
  return parts.map((part, index) => {
    if (part.toLowerCase().includes('pregnancy')) {
      // Extract all trimester ranges including shortened formats
      const ranges = []
      const trimesterPattern = /(?:(\d+(?:st|nd|rd))\s*(?:trimester)?:\s*(\d+-\d+))/g
      let match

      while ((match = trimesterPattern.exec(part)) !== null) {
        ranges.push({
          trimester: match[1],
          values: match[2]
        })
      }

      if (ranges.length > 0) {
        return (
          <div key={index} className="interpretation-section">
            <Text className="interpretation-title">Pregnancy Ranges:</Text>
            {ranges.map((range, i) => (
              <div key={i} className="interpretation-item">
                <Text className="interpretation-label">{`${range.trimester} trimester`}</Text>
                <Text className="interpretation-value">{`${range.values} ng/mL`}</Text>
              </div>
            ))}
          </div>
        )
      }
    } else if (part.toLowerCase().includes('age')) {
      // Handle age-based ranges
      const ranges = []
      const agePattern = /Age\s*(?:<|>)?\s*(\d+(?:-\d+)?)\s*:\s*(?:<)?\s*(\d+(?:\.\d+)?)/g
      let match

      while ((match = agePattern.exec(part)) !== null) {
        ranges.push({
          age: match[1],
          value: match[2]
        })
      }

      if (ranges.length > 0) {
        return (
          <div key={index} className="interpretation-section">
            <Text className="interpretation-title">Age-Specific Ranges:</Text>
            {ranges.map((range, i) => (
              <div key={i} className="interpretation-item">
                <Text className="interpretation-label">{`Age ${range.age}`}</Text>
                <Text className="interpretation-value">{`<${range.value} ng/mL`}</Text>
              </div>
            ))}
          </div>
        )
      }
    } else if (part.toLowerCase().includes('adults')) {
      const adultPart = part.trim()
      return (
        <div key={index} className="interpretation-section">
          <Text className="interpretation-title">Adult Range:</Text>
          <div className="interpretation-item">
            <Text>{adultPart}</Text>
          </div>
        </div>
      )
    } else if (part.trim()) {
      // Handle any additional notes
      return (
        <div key={index} className="interpretation-section">
          <Text>{part.trim()}</Text>
        </div>
      )
    }
    return null
  }).filter(Boolean)
}

const ReferenceRange = ({ ranges }) => {
  if (!ranges) return null

  return (
    <section className="test-detail-section">
      <Title level={4}>Reference Ranges</Title>
      <div className="reference-range-card">
        <Row gutter={[16, 16]} className="range-key">
          <Col span={8}>
            <div className="range-key-item low">
              <FontAwesomeIcon icon={faCircle} className="key-icon" />
              <div className="key-text">
                <Text className="key-label">Low</Text>
                <Text className="key-value">{`<${ranges.low}`}</Text>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="range-key-item normal">
              <FontAwesomeIcon icon={faCircle} className="key-icon" />
              <div className="key-text">
                <Text className="key-label">Normal</Text>
                <Text className="key-value">{`${ranges.low}-${ranges.high}`}</Text>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="range-key-item high">
              <FontAwesomeIcon icon={faCircle} className="key-icon" />
              <div className="key-text">
                <Text className="key-label">High</Text>
                <Text className="key-value">{`>${ranges.high}`}</Text>
              </div>
            </div>
          </Col>
        </Row>
        
        <div className="range-unit">
          <Text>Unit: {ranges.unit}</Text>
        </div>

        <div className="range-notes">
          {formatInterpretation(ranges.interpretation)}
        </div>
      </div>
    </section>
  )
}

export default ReferenceRange 