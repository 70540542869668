import { LoadingOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Card, Col, message, Row, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import PaymentBackground from '../../../assets/images/Payment-Background.jpg'
import CustomRow from '../../reusable-components/CustomRow'
import { updateCallpayTransaction } from '../../PeachPayments/utils/utils'
import { handleError } from '../../lib/utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'

const PaymentStatus = () => {
  const [validReferrer, setValidReferrer] = useState(false)
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)

  const status = searchParams.get('status')

  const { token } = theme.useToken()

  const getStatusConfig = (status) => {
    const configs = {
      complete: {
        icon: <CheckCircleOutlined style={{ fontSize: 80, color: token.colorSuccess }} />,
        title: 'Payment Successful',
        message: 'Your transaction has been completed successfully',
        headerColor: token.colorSuccess
      },
      failed: {
        icon: <CloseCircleOutlined style={{ fontSize: 80, color: token.colorError }} />,
        title: 'Payment Failed',
        message: 'Your transaction has failed',
        headerColor: token.colorError
      },
      refunded: {
        icon: <CheckCircleOutlined style={{ fontSize: 80, color: token.colorInfo }} />,
        title: 'Payment Refunded',
        message: 'Your transaction has been refunded',
        headerColor: token.colorInfo
      },
      'partially-refunded': {
        icon: <CheckCircleOutlined style={{ fontSize: 80, color: token.colorInfo }} />,
        title: 'Payment Partially Refunded',
        message: 'Your transaction has been partially refunded',
        headerColor: token.colorInfo
      },
      authorised: {
        icon: <LoadingOutlined style={{ fontSize: 80, color: token.colorWarning }} />,
        title: 'Payment Authorised',
        message: 'Your transaction has been authorised',
        headerColor: token.colorWarning
      },
      reversed: {
        icon: <CloseCircleOutlined style={{ fontSize: 80, color: token.colorError }} />,
        title: 'Payment Reversed',
        message: 'Your authorised transaction has been reversed',
        headerColor: token.colorError
      },
      pending: {
        icon: <LoadingOutlined style={{ fontSize: 80, color: token.colorWarning }} />,
        title: 'Payment Pending',
        message: 'Your transaction is pending',
        headerColor: token.colorWarning
      }
    }

    return configs[status?.toLowerCase()] || configs.pending
  }

  useEffect(() => {
    const checkReferrerAndUpdateTransaction = async () => {
      const allowedReferrers = ['agent.callpay.com']
      const referrer = document.referrer
      const merchantReference = searchParams.get('merchant_reference')

      let isValidReferrer = true

      if (referrer) {
        try {
          const referrerUrl = new URL(referrer)
          const referrerHost = referrerUrl.hostname
          isValidReferrer = allowedReferrers.includes(referrerHost)
        } catch (error) {
          isValidReferrer = false
        }
      }

      setValidReferrer(isValidReferrer)

      if (isValidReferrer && merchantReference) {
        // Convert searchParams to object
        const queryParams = Object.fromEntries(searchParams.entries())

        try {
          await updateCallpayTransaction(
            {
              merchantReference
            },
            { data: queryParams }
          )
        } catch (e) {
          message.error(handleError(e, true))
        }
      }

      setLoading(false)
    }

    checkReferrerAndUpdateTransaction()
    // eslint-disable-next-line
  }, [])

  const statusConfig = getStatusConfig(status)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundImage: `url(${PaymentBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    >
      <CustomRow justify='center' style={{ width: '100%' }}>
        <Col xs={24} sm={24} md={20} lg={16} xl={12} xxl={10}>
          {loading ? (
            <div style={{ backgroundColor: token.colorWhite, padding: 20 }}>
              <AgiliteSkeleton skActive={false} spinnerTip='Loading...' />
            </div>
          ) : validReferrer ? (
            <Card
              headStyle={{ backgroundColor: statusConfig.headerColor }}
              title={<center style={{ color: '#fff', fontSize: 20 }}>{statusConfig.title}</center>}
            >
              <Row justify='center'>
                <Col>{statusConfig.icon}</Col>
                <Col style={{ marginTop: 10, marginBottom: 20 }} span={24}>
                  <center>
                    <b>{statusConfig.message}</b>
                  </center>
                </Col>
              </Row>
            </Card>
          ) : (
            <Card>
              <h2 style={{ textAlign: 'center' }}>Invalid Request</h2>
              <h4 style={{ textAlign: 'center' }}>
                <i>
                  Contact support if you believe this is an error:{' '}
                  <a href='mailto:support@meta-clinic.co.za'>support@meta-clinic.co.za</a>
                </i>
              </h4>
            </Card>
          )}
        </Col>
      </CustomRow>
    </div>
  )
}

export default PaymentStatus
