import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row, theme, Form, Input, Button, message, Modal, Popover, Spin, Select, Space, Collapse } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { PhoneAuthProvider, RecaptchaVerifier, updatePhoneNumber } from 'firebase/auth'
import SignatureCanvas from 'react-signature-canvas'
import Resizer from 'react-image-file-resizer'

// Utils
import { firebaseAuth } from '../../..'
import { getCountries, updateAgiliteUser } from '../../Auth/utils/utils'
import { allTrim, handleError, specialFieldValidation } from '../../lib/utils'
import AuthReducer from '../../Auth/utils/reducer'
import ProfileEnums from '../utils/enums'
import Templates from '../utils/templates'

// Components
import GoogleAddress from '../../reusable-components/GoogleAddress'
import dayjs from 'dayjs'
import DatePickerModal from '../../reusable-components/DatePickerModal'
import { generateFormItem } from '../../lib/utils'
import CustomRow from '../../reusable-components/CustomRow'
import CollapsePanel from 'antd/es/collapse/CollapsePanel'
import { debounce } from 'lodash'
import {
  MedPraxSchemePlanOptionSearch,
  MedPraxSchemePlanSearch,
  MedPraxSchemeSearch
} from '../../Scripting/utils/utils'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'

const EmployeePatientView = ({
  isReception,
  setFormData,
  refreshView,
  setIsEditMode,
  userData,
  setUserData,
  image
}) => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state.auth)
  const [newImage, setNewImage] = useState(null)
  const inputRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [confirmationCode, setConfirmationCode] = useState(null)
  const [verificationId, setVerificationId] = useState(null)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const canvasRef = useRef()
  const [signatureSaved, setSignatureSaved] = useState(state.agiliteUser.extraData.signature ? true : false)
  const [signature, setSignature] = useState(state.agiliteUser.extraData.signature)
  const [specialFormState, setSpecialFormState] = useState([
    { key: 'firstName', value: userData.firstName },
    { key: 'lastName', value: userData.lastName }
  ])
  const [residentVisitor, setResidentVisitor] = useState(
    userData.country ? (userData?.country === 'ZA' ? 'resident' : 'visitor') : 'resident'
  )
  const [country, setCountry] = useState('ZA')
  const [countries, setCountries] = useState([])
  const [countriesLoading, setCountriesLoading] = useState(false)
  const [countryObject, setCountryObject] = useState()

  // Medical Aid
  const [schemeLoading, setSchemeLoading] = useState(false)
  const [schemeData, setSchemeData] = useState([])
  const [plansLoading, setPlansLoading] = useState(false)
  const [plansData, setPlansData] = useState([])
  const [planOptionsLoading, setPlanOptionsLoading] = useState(false)
  const [planOptionData, setPlanOptionData] = useState([])

  useEffect(() => {
    let tmpIndex = -1

    tmpIndex = countries.findIndex((e) => e.code === country)

    if (tmpIndex > -1) {
      setCountryObject(countries[tmpIndex])
    } else {
      setCountryObject(null)
    }

    // eslint-disable-next-line
  }, [country])

  useEffect(() => {
    async function handleLoginPhoneRecaptcha() {
      window.recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, 'recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          setSubmitDisabled(false)
        },
        'expired-callback': (response) => {
          setSubmitDisabled(true)
          setErrorMessage('Verification Expired. Please reload the page')
        }
      })
    }

    handleLoginPhoneRecaptcha()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleGetCountries()
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])

  const handleGetCountries = async () => {
    let tmpCountries = []

    setCountriesLoading(true)

    try {
      tmpCountries = await getCountries()
      setCountries(tmpCountries)
    } catch (e) {
      message.error(e)
    }

    setCountriesLoading(false)
  }

  const handleUploadClick = () => {
    let promptText = null
    let enableCancel = false

    if (userData?.profileImage) {
      promptText = 'Do you want Remove or Change your profile picture?'
      enableCancel = true
    } else if (firebaseAuth?.currentUser?.photoURL) {
      promptText = 'Do you want Change your profile picture?'
      enableCancel = false
    } else {
      promptText = 'Do you want Change your profile picture?'
      enableCancel = false
    }

    Modal.confirm({
      title: 'Confirmation',
      content: promptText,
      okText: 'Change',
      cancelText: 'Remove',
      onOk: () => inputRef.current?.click(),
      onCancel: () => {
        setUserData({ ...userData, profileImage: '' })

        if (firebaseAuth.currentUser.photoURL) {
          setNewImage(firebaseAuth.currentUser.photoURL)
        } else {
          setNewImage(ProfileEnums.images.DEFAULT_IMAGE)
        }
      },
      cancelButtonProps: { style: { display: enableCancel ? '' : 'none', background: 'transparent' }, danger: true },
      closable: true,
      maskClosable: true,
      className: token.themeControl
    })
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64'
      )
    })

  const handleFileChange = async (e) => {
    let file = null

    try {
      if (!e.target.files) {
        return
      }

      file = await resizeFile(e.target.files[0])

      // file = await readBase64(e.target.files[0])
      setUserData({ ...userData, profileImage: file })
      setNewImage(file)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const formatNumber = (phoneNumber) => {
    if (phoneNumber.charAt(0) === '0') {
      phoneNumber = `${countryObject ? countryObject.phoneCode : '+27'}${phoneNumber.slice(1, 10)}`
    } else {
      phoneNumber = `${countryObject ? countryObject.phoneCode : '+27'}${phoneNumber}`
    }

    return phoneNumber
  }

  const handleSubmit = async () => {
    const formData = allTrim(form.getFieldsValue())
    let tmpPhoneNumber = null
    setLoading(true)

    try {
      if (isReception) {
        if (!formData.phoneNumber && userData.phoneNumber) {
          setLoading(false)
          return message.error('You cannot remove patient cellphone number, please revise')
        }
      } else {
        if (!formData.phoneNumber && state.agiliteUser.phoneNumber) {
          setLoading(false)
          return message.error('You cannot remove your cellphone number, please revise')
        }
      }

      if (formData.phoneNumber) {
        tmpPhoneNumber = formatNumber(formData.phoneNumber)
      }

      // Check if phone number has changed
      if (isReception) {
        await handleSubmitExtended()
      } else {
        if (tmpPhoneNumber && tmpPhoneNumber !== state.agiliteUser.phoneNumber) {
          // Verify phone number
          await handleLoginPhone(tmpPhoneNumber)
        } else {
          await handleSubmitExtended()
        }
      }
      setIsEditMode(false)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  const handleSubmitExtended = async () => {
    const formData = allTrim(form.getFieldsValue())
    let phoneNumber = form.getFieldValue('phoneNumber')
    let res = null

    try {
      if (phoneNumber) {
        phoneNumber = formatNumber(phoneNumber)
        form.setFieldValue('phoneNumber', phoneNumber.replace(countryObject ? countryObject.phoneCode : '+27', ''))
      }

      res = await updateAgiliteUser(userData._id, {
        ...userData,
        ...formData,
        phoneNumber,
        extraData: {
          ...userData.extraData,
          ...formData.extraData
        }
      })

      if (isReception) {
        setFormData(res)
        setUserData(res)
        refreshView()
      } else {
        dispatch(AuthReducer.actions.setAgiliteUser(res))
      }
      message.success('Profile updated successfully.')
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleLoginPhone = async (phoneNumber) => {
    const provider = new PhoneAuthProvider(firebaseAuth)
    let tmpVerificationId = null

    try {
      tmpVerificationId = await provider.verifyPhoneNumber(phoneNumber, window.recaptchaVerifier)
      setVerificationId(tmpVerificationId)
      setConfirmationOpen(true)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleLoginPhoneCode = async () => {
    let phoneCredential = null

    setLoading(true)

    try {
      setConfirmationOpen(false)

      phoneCredential = PhoneAuthProvider.credential(verificationId, confirmationCode)
      await updatePhoneNumber(firebaseAuth.currentUser, phoneCredential)
      await handleSubmitExtended()
    } catch (e) {
      setConfirmationOpen(true)
      message.error(handleError(e, true))
    }

    setConfirmationCode('')
    setLoading(false)
  }

  //   const handleSignOut = async () => {
  //     setLoading(true)

  //     try {
  //       await signOut(firebaseAuth)
  //       batch(() => {
  //         dispatch(CoreReducer.actions.resetTabs())
  //         dispatch(CoreReducer.actions.resetState())
  //         dispatch(CoreReducer.actions.enableDisableLeftMenu(false))
  //         dispatch(CoreReducer.actions.enableDisableRightMenu(false))
  //         dispatch(CoreReducer.actions.enableDisableTabs(false))
  //         dispatch(CoreReducer.actions.setRootContent(Router))
  //         dispatch(AuthReducer.actions.setUser(null))
  //         dispatch(AuthReducer.actions.setAgiliteUser(null))
  //         dispatch(AuthReducer.actions.setLoggedIn(false))
  //       })
  //     } catch (e) {
  //       handleError(e, true)
  //     }

  //     setLoading(false)
  //   }

  const handleSaveSig = async () => {
    let response = null
    setLoading(true)

    try {
      response = await updateAgiliteUser(userData._id, { 'extraData.signature': signature })
      dispatch(AuthReducer.actions.setAgiliteUser(response))
      setSignatureSaved(true)
      message.success('Signature saved successfully.')
    } catch (e) {
      message.error(handleError(e, true))
    }

    setLoading(false)
  }

  const handleGetMedicalAids = async (query) => {
    let tmpData = []

    setSchemeLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemeSearch(query)
        setSchemeData(tmpData)
      } else {
        setSchemeData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setSchemeLoading(false)
  }

  const handleGetMedicalAidPlans = async (query) => {
    let tmpData = []

    setPlansLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemePlanSearch(query)
        setPlansData(tmpData)
      } else {
        setPlansData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPlansLoading(false)
  }

  const handleGetMedicalAidPlanOptions = async (query) => {
    let tmpData = []

    setPlanOptionsLoading(true)

    try {
      if (query) {
        tmpData = await MedPraxSchemePlanOptionSearch(query)
        setPlanOptionData(tmpData)
      } else {
        setPlanOptionData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setPlanOptionsLoading(false)
  }

  // eslint-disable-next-line
  const debouncedSchemeFilter = useCallback(
    debounce((query) => {
      if (query) {
        handleGetMedicalAids(query)
      } else {
        setSchemeLoading(false)
      }
    }, 1000),
    []
  )

  const { token } = theme.useToken()
  const [form] = Form.useForm()

  return (
    <>
      <CustomRow className='basic-card'>
        <Col span={24}>
          <CustomRow justify='center'>
            <Col style={{ marginBottom: 10 }}>
              <center>
                <input
                  disabled={isReception}
                  type='file'
                  accept='image/*'
                  ref={inputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <div
                  style={{
                    display: 'grid',
                    height: 128,
                    width: 128,
                    alignItems: 'center',
                    overflow: 'hidden',
                    borderRadius: '50%'
                  }}
                >
                  {/* eslint-disable-next-line */}
                  <img
                    referrerPolicy='no-referrer'
                    alt='User Image'
                    style={{ gridColumn: 1, gridRow: 1, width: '100%' }}
                    src={newImage ? newImage : image}
                  />
                  <div
                    style={{
                      display: isReception ? 'none' : 'flex',
                      gridColumn: 1,
                      gridRow: 1,
                      boxSizing: 'border-box',
                      justify: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                      zIndex: 1,
                      opacity: 0.5,
                      backgroundColor: token.colorBgMask,
                      cursor: 'pointer'
                    }}
                    onClick={loading ? null : handleUploadClick}
                  >
                    <FontAwesomeIcon color={token.colorWhite} icon={faEdit} style={{ width: 128 }} size='2x' />
                  </div>
                </div>
              </center>
            </Col>
          </CustomRow>
          <Form
            onFinishFailed={(e) => {
              message.error(e.errorFields[0].errors[0])
            }}
            onFinish={handleSubmit}
            disabled={loading}
            form={form}
            layout='vertical'
            initialValues={{
              ...userData,
              phoneNumber: userData.phoneNumber ? userData.phoneNumber.replace('+27', '') : null,
              dateOfBirth: userData.dateOfBirth ? dayjs(userData.dateOfBirth) : null,
              residentVisitor: residentVisitor,
              medicalAid: {
                name: userData.medicalAid?.name ? userData.medicalAid.name : undefined,
                schemeCode: userData.medicalAid?.schemeCode ? userData.medicalAid.schemeCode : undefined,
                plan: userData.medicalAid?.plan ? userData.medicalAid.plan : undefined,
                planCode: userData.medicalAid?.planCode ? userData.medicalAid.planCode : undefined,
                planOption: userData.medicalAid?.planOption ? userData.medicalAid.planOption : undefined,
                planOptionCode: userData.medicalAid?.planOptionCode ? userData.medicalAid.planOptionCode : undefined,
                number: userData.medicalAid?.number ? userData.medicalAid.number : '',
                dependantNumber: userData.medicalAid?.dependantNumber ? userData.medicalAid.dependantNumber : ''
              }
            }}
          >
            <CustomRow justify='center'>
              <Col span={24}>
                <center>
                  <Space wrap>
                    <Button
                      loading={loading}
                      style={{ background: token.colorSuccess, color: 'white' }}
                      onClick={() => {
                        form.submit()
                      }}
                      type='primary'
                    >
                      Save Changes
                    </Button>
                  </Space>
                </center>
              </Col>
              <Col xs={24} sm={24} md={24} lg={20}>
                <CustomRow>
                  <Col span={24}>
                    <Collapse defaultActiveKey={[1, 2, 3]} style={{ border: '2px rgba(0,0,0,0.055) solid' }}>
                      <CollapsePanel key={1} header='Personal Details'>
                        <CustomRow className='basic-card'>
                          <Col span={24}>
                            {process.env.REACT_APP_ENVIRONMENT === 'dev' ? (
                              <>
                                <Form.Item
                                  label='Role'
                                  name={['extraData', 'role', 'type']}
                                  rules={[{ required: true, message: 'Role can`t be blank' }]}
                                >
                                  <Select
                                    options={[
                                      { label: 'Admin', value: 'admin' },
                                      { label: 'Reception', value: 'reception' },
                                      { label: 'Medical Professional', value: 'medical_professional' },
                                      { label: 'Patient', value: 'patient' }
                                    ]}
                                  />
                                </Form.Item>
                                <Form.Item label='Profession' name={['extraData', 'profession']}>
                                  <Select
                                    options={[
                                      { label: '- None -', value: '' },
                                      { label: 'Doctor', value: 'doctor' },
                                      { label: 'Nurse', value: 'nurse' }
                                    ]}
                                  />
                                </Form.Item>
                              </>
                            ) : undefined}

                            {generateFormItem(
                              Templates.dataModel.firstName,
                              form,
                              specialFormState,
                              setSpecialFormState
                            )}
                            {generateFormItem(
                              Templates.dataModel.lastName,
                              form,
                              specialFormState,
                              setSpecialFormState
                            )}

                            <Form.Item label='Email Address' name='email'>
                              <Input disabled={isReception ? false : true} placeholder='e.g. jane.doe@gmail.com' />
                            </Form.Item>
                            <Form.Item
                              label='Identification'
                              name='idNo'
                              rules={[{ required: true, message: 'Please provide your ID / Passport No' }]}
                            >
                              <Input placeholder='e.g. 790725*******' />
                            </Form.Item>
                            <Form.Item
                              name='residentVisitor'
                              label='RSA Resident / Visitor'
                              rules={[{ required: true, message: 'Please if you are a RSA Resident or Visitor' }]}
                            >
                              <Select
                                placeholder='RSA Resident or Visitor'
                                options={[
                                  { label: 'RSA Resident', value: 'resident' },
                                  { label: 'Visiting From Abroad', value: 'visitor' }
                                ]}
                                defaultValue={residentVisitor}
                                onChange={(value) => {
                                  if (value === 'resident') {
                                    setCountry('ZA')
                                  }

                                  setResidentVisitor(value)
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              name='country'
                              label='Country Of Residence'
                              rules={[{ required: true, message: 'Please provide your Country Of Residence' }]}
                            >
                              <Select
                                showSearch
                                optionFilterProp='label'
                                loading={countriesLoading}
                                placeholder='e.g South Africa'
                                options={countries.map((e) => {
                                  return {
                                    label: e.name,
                                    value: e.code
                                  }
                                })}
                                onChange={(value) => {
                                  setCountry(value)
                                }}
                              />
                            </Form.Item>
                            {generateFormItem(Templates.dataModel.gender, form, null, null)}
                            {generateFormItem(Templates.dataModel.race, form, null, null)}

                            <Form.Item label='Date Of Birth' name='dateOfBirth'>
                              <DatePickerModal
                                initialValue={userData.dateOfBirth ? userData.dateOfBirth : null}
                                form={form}
                                key={'dateOfBirth'}
                              />
                            </Form.Item>
                            <Popover
                              placement='topRight'
                              content='One-time Pin will be sent to your Cellphone'
                              title={<span style={{ color: token.colorPrimary }}>Note</span>}
                              trigger='focus'
                            >
                              <Form.Item label='Cellphone Number' name='phoneNumber'>
                                <Input
                                  maxLength={15}
                                  onChange={(e) => {
                                    specialFieldValidation(form, specialFormState, setSpecialFormState, {
                                      key: 'phoneNumber',
                                      event: e,
                                      validationConfig: {
                                        letters: { allowed: false, onlyCaps: false },
                                        numbers: true,
                                        spaces: false
                                      }
                                    })
                                  }}
                                  addonBefore={`${countryObject ? countryObject.phoneCode : '+27'}`}
                                  placeholder='8288*****'
                                />
                              </Form.Item>
                            </Popover>
                            <Form.Item label='Residential Address' name='residentialAddress'>
                              <GoogleAddress
                                selectProps={{
                                  placeholder: form.getFieldValue('residentialAddress')
                                    ? form.getFieldValue('residentialAddress')
                                    : 'Residential Address',
                                  onChange: (e) => {
                                    form.setFieldValue('residentialAddress', e.label)
                                  }
                                }}
                              />
                            </Form.Item>

                            <center>{errorMessage}</center>
                          </Col>
                        </CustomRow>
                      </CollapsePanel>
                      {state.agiliteUser.extraData.role.type === 'patient' || isReception ? (
                        <CollapsePanel key={2} header='Medical Aid'>
                          <CustomRow className='basic-card'>
                            <Col span={24}>
                              <Form.Item name={['medicalAid', 'schemeCode']} style={{ display: 'none' }}>
                                <Input />
                              </Form.Item>
                              <Form.Item name={['medicalAid', 'planCode']} style={{ display: 'none' }}>
                                <Input />
                              </Form.Item>
                              <Form.Item name={['medicalAid', 'planOptionCode']} style={{ display: 'none' }}>
                                <Input />
                              </Form.Item>
                              <Form.Item
                                requiredMark='optional'
                                label={`${
                                  !residentVisitor || residentVisitor === 'resident'
                                    ? 'Medical Aid Name'
                                    : 'Travel Insurance Provider'
                                }`}
                                name={['medicalAid', 'name']}
                              >
                                {!residentVisitor || residentVisitor === 'resident' ? (
                                  <Select
                                    showSearch
                                    allowClear
                                    placeholder='Search for a Medical Aid...'
                                    disabled={false}
                                    onSearch={(value) => {
                                      debouncedSchemeFilter(value)
                                    }}
                                    onChange={(e, option) => {
                                      form.setFieldValue(
                                        ['medicalAid', 'schemeCode'],
                                        option?.data ? JSON.parse(option.data).code : ''
                                      )
                                      setSchemeData([])
                                    }}
                                    options={schemeData.map((i) => {
                                      return {
                                        label: i.name,
                                        value: i.name,
                                        data: JSON.stringify(i)
                                      }
                                    })}
                                    notFoundContent={
                                      schemeLoading ? (
                                        <AgiliteSkeleton skActive spinnerTip='Searching Schemes...' />
                                      ) : undefined
                                    }
                                  />
                                ) : (
                                  <Input placeholder='e.g. Discovery Health Scheme' />
                                )}
                              </Form.Item>
                              <Form.Item
                                requiredMark='optional'
                                label={`${
                                  !residentVisitor || residentVisitor === 'resident'
                                    ? 'Medical Aid Plan'
                                    : 'Travel Insurance Plan'
                                }`}
                                name={['medicalAid', 'plan']}
                              >
                                {!residentVisitor || residentVisitor === 'resident' ? (
                                  <Select
                                    showSearch
                                    allowClear
                                    placeholder='Select a Medical Aid Plan...'
                                    disabled={form.getFieldValue(['medicalAid', 'schemeCode']) ? false : true}
                                    onChange={(e, option) => {
                                      form.setFieldValue(
                                        ['medicalAid', 'planCode'],
                                        option?.data ? JSON.parse(option.data).code : ''
                                      )
                                      setPlansData([])
                                    }}
                                    onClick={() => {
                                      handleGetMedicalAidPlans(form.getFieldValue(['medicalAid', 'schemeCode']))
                                    }}
                                    options={plansData.map((i) => {
                                      return {
                                        label: i.option,
                                        value: i.option,
                                        data: JSON.stringify(i)
                                      }
                                    })}
                                    notFoundContent={
                                      plansLoading ? (
                                        <AgiliteSkeleton skActive spinnerTip='Searching Scheme Plans...' />
                                      ) : undefined
                                    }
                                  />
                                ) : (
                                  <Input placeholder='e.g. Coastal Saver' />
                                )}
                              </Form.Item>
                              <Form.Item
                                requiredMark='optional'
                                label={`${
                                  !residentVisitor || residentVisitor === 'resident'
                                    ? 'Medical Aid Plan Option'
                                    : 'Travel Insurance Plan Option'
                                }`}
                                name={['medicalAid', 'planOption']}
                              >
                                {!residentVisitor || residentVisitor === 'resident' ? (
                                  <Select
                                    showSearch
                                    allowClear
                                    placeholder='Select a Medical Aid Plan Option...'
                                    disabled={form.getFieldValue(['medicalAid', 'planCode']) ? false : true}
                                    onChange={(e, option) => {
                                      form.setFieldValue(
                                        ['medicalAid', 'planOptionCode'],
                                        option?.data ? JSON.parse(option.data).code : ''
                                      )
                                      setPlanOptionData([])
                                    }}
                                    onClick={() => {
                                      handleGetMedicalAidPlanOptions(form.getFieldValue(['medicalAid', 'planCode']))
                                    }}
                                    options={planOptionData.map((i) => {
                                      return {
                                        label: i.option,
                                        value: i.option,
                                        data: JSON.stringify(i)
                                      }
                                    })}
                                    notFoundContent={
                                      planOptionsLoading ? (
                                        <AgiliteSkeleton skActive spinnerTip='Searching Scheme Plan Options...' />
                                      ) : undefined
                                    }
                                  />
                                ) : (
                                  <Input placeholder='e.g. Coastal Saver' />
                                )}
                              </Form.Item>
                              <Form.Item
                                requiredMark='optional'
                                label={`${
                                  !residentVisitor || residentVisitor === 'resident'
                                    ? 'Medical Aid Number'
                                    : 'Travel Insurance Number'
                                }`}
                                name={['medicalAid', 'number']}
                              >
                                <Input placeholder='e.g. 335***' />
                              </Form.Item>
                              <Form.Item
                                requiredMark='optional'
                                label='Dependant Number'
                                name={['medicalAid', 'dependantNumber']}
                              >
                                <Input placeholder='e.g. 01' />
                              </Form.Item>
                            </Col>
                          </CustomRow>
                        </CollapsePanel>
                      ) : undefined}
                    </Collapse>
                  </Col>
                </CustomRow>
              </Col>

              {state.agiliteUser.extraData.profession === 'doctor' ? (
                <Col xs={24} sm={24} md={24} lg={20}>
                  <Collapse defaultActiveKey={[1]}>
                    <CollapsePanel key={1} header='Professional Profile'>
                      <CustomRow>
                        <Col span={24}>
                          <CustomRow className='basic-card'>
                            <Col span={24}>
                              <Form.Item
                                label='MP Number'
                                name={['extraData', 'mpNumber']}
                                rules={[{ required: true, message: 'MP number can`t be blank' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label='Qualification'
                                name={['extraData', 'qualification']}
                                rules={[{ required: true, message: 'Qualification can`t be blank' }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </CustomRow>
                        </Col>
                        <Col span={24}>
                          <CustomRow className='basic-card'>
                            <Col span={24}>
                              <div>
                                <label>Singature</label>
                              </div>
                              <br />
                              <Space wrap style={{ marginBottom: 12 }}>
                                {signatureSaved ? (
                                  <Button
                                    type='primary'
                                    disabled={loading}
                                    onClick={() => {
                                      canvasRef.current.clear()
                                      setSignature('')
                                      setSignatureSaved(false)
                                    }}
                                  >
                                    Edit
                                  </Button>
                                ) : (
                                  <Button
                                    danger
                                    disabled={loading}
                                    onClick={() => {
                                      canvasRef.current.clear()
                                      setSignature('')
                                      setSignatureSaved(false)
                                    }}
                                  >
                                    Clear
                                  </Button>
                                )}
                                {signatureSaved ? undefined : (
                                  <Button
                                    disabled={signature === '' || loading}
                                    loading={loading}
                                    onClick={() => {
                                      handleSaveSig()
                                    }}
                                    type='primary'
                                  >
                                    Confirm
                                  </Button>
                                )}
                              </Space>
                              <Spin spinning={loading} tip='Saving Signature...'>
                                {signatureSaved ? <img src={signature} alt='Signature' /> : undefined}
                                <div
                                  style={
                                    signatureSaved
                                      ? { width: 0, height: 0, overflow: 'hidden', border: '1px grey solid' }
                                      : { width: 300, height: 150, border: '1px grey solid', overflow: 'hidden' }
                                  }
                                >
                                  <SignatureCanvas
                                    ref={canvasRef}
                                    canvasProps={{ className: 'sigCanvas', width: 300, height: 150 }}
                                    backgroundColor='#fff'
                                    onEnd={() => {
                                      setSignature(canvasRef.current.getTrimmedCanvas().toDataURL('image/png'))
                                    }}
                                  />
                                </div>
                              </Spin>
                            </Col>
                          </CustomRow>
                        </Col>
                      </CustomRow>
                    </CollapsePanel>
                  </Collapse>
                </Col>
              ) : undefined}
            </CustomRow>
          </Form>
        </Col>
      </CustomRow>
      <Modal title='One-time Pin' footer={null} open={confirmationOpen} onCancel={() => setConfirmationOpen(false)}>
        <Row justify='center'>
          <Col>
            Please provide the One-time Pin sent to your Cellphone
            <Input
              value={confirmationCode}
              onChange={(e) => {
                if (/^[0-9]*$/g.test(e.target.value) === true) {
                  setConfirmationCode(e.target.value)
                }
              }}
            />
          </Col>
        </Row>
        <Row justify='space-between' style={{ marginTop: 20 }}>
          <Col>
            <Button
              disabled={submitDisabled}
              onClick={() => {
                const data = form.getFieldsValue()
                handleSubmit(data)
              }}
              type='primary'
            >
              Resend Code
            </Button>
          </Col>
          <Col>
            <Button
              disabled={submitDisabled}
              onClick={() => handleLoginPhoneCode()}
              type='primary'
              style={{ backgroundColor: token.colorSuccess }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Modal>
      <div id='recaptcha-container'></div>
    </>
  )
}

export default EmployeePatientView
