import React from 'react'
import { hexToRGBA, specialFieldValidation } from '../../../../lib/utils'
import { Card, Col, Form, Input, Row, Select, Space, Switch, theme } from 'antd'
import { useSelector } from 'react-redux'
import { faContactBook } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SystemUsersTemplates from '../../utils/templates'

const RenderFormItems = ({
  activeRecord,
  isNewRecord,
  clientForm,
  specialFormState,
  setSpecialFormState,
  handleIsActive,
  handleRolesSelection,
  type,
  record
}) => {
  const state = useSelector((state) => state)
  const authState = useSelector((state) => state.auth)
  const { token } = theme.useToken()
  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Card
          size='small'
          styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}
          title={
            <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              Profile Details
              <Space>
                <Form.Item
                  style={{ margin: 0 }}
                  name={SystemUsersTemplates.dataModel.isActive.key}
                  valuePropName='checked'
                >
                  <Switch
                    disabled={
                      authState.agiliteUser.extraData.role.type !== 'admin' &&
                      authState.agiliteUser.extraData.role.type !== 'super_admin'
                    }
                    checkedChildren='Active'
                    unCheckedChildren='Inactive'
                    onChange={handleIsActive}
                  />
                </Form.Item>
              </Space>
            </Space>
          }
        >
          <Space wrap style={{ width: '100%' }}>
            <Form.Item
              style={{ width: 350, maxWidth: '100%' }}
              name={SystemUsersTemplates.dataModel.firstName.key}
              label={SystemUsersTemplates.dataModel.firstName.label}
              rules={[
                {
                  required: SystemUsersTemplates.dataModel.firstName.required,
                  message: SystemUsersTemplates.dataModel.firstName.validationMsg
                }
              ]}
            >
              <Input
                onChange={(e) => {
                  specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                    key: 'firstName',
                    event: e,
                    validationConfig: {
                      letters: { allowed: true, onlyCaps: false },
                      numbers: false,
                      spaces: true
                    }
                  })
                }}
                maxLength={50}
                readOnly={!isNewRecord}
                placeholder={SystemUsersTemplates.dataModel.firstName.placeholder}
              />
            </Form.Item>
            <Form.Item
              style={{ width: 350, maxWidth: '100%' }}
              name={SystemUsersTemplates.dataModel.lastName.key}
              label={SystemUsersTemplates.dataModel.lastName.label}
              rules={[
                {
                  required: SystemUsersTemplates.dataModel.lastName.required,
                  message: SystemUsersTemplates.dataModel.lastName.validationMsg
                }
              ]}
            >
              <Input
                onChange={(e) => {
                  specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                    key: 'lastName',
                    event: e,
                    validationConfig: {
                      letters: { allowed: true, onlyCaps: false },
                      numbers: false,
                      spaces: true
                    }
                  })
                }}
                maxLength={50}
                readOnly={!isNewRecord}
                placeholder={SystemUsersTemplates.dataModel.lastName.placeholder}
              />
            </Form.Item>
            {activeRecord?.extraData.role.type === 'medical_professional' || type === 'medical' ? (
              <Form.Item
                style={{ width: 350, maxWidth: '100%' }}
                name={['extraData', 'clinics']}
                label={
                  <span>
                    <span style={{ color: token.colorError }}>* </span>Linked Clinics:
                  </span>
                }
                rules={[
                  {
                    required: SystemUsersTemplates.dataModel.clinics.required,
                    message: 'Select Medical Professionals linked clinics'
                  }
                ]}
              >
                <Select
                  mode='multiple'
                  placeholder='-Select clinics-'
                  maxLength={1}
                  filterOption={(input, option) => {
                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  options={[
                    ...state.clinics.data.map((clinic) => ({
                      label: clinic.name,
                      value: clinic._id
                    }))
                  ]}
                />
              </Form.Item>
            ) : undefined}
            {activeRecord?.extraData.role.type === 'reception' || record?.extraData?.role?.type === 'reception' ? (
              <Form.Item
                style={{ width: 350, maxWidth: '100%' }}
                name={['extraData', 'clinics']}
                label={
                  <span>
                    <span style={{ color: token.colorError }}>* </span>Linked Clinic:
                  </span>
                }
                rules={[
                  {
                    required: SystemUsersTemplates.dataModel.clinics.required,
                    message: "Select Reception's linked clinic"
                  }
                ]}
              >
                <Select
                  placeholder='-Select clinic-'
                  onChange={(value) => {
                    clientForm.setFieldValue(SystemUsersTemplates.dataModel.clinics.key, [value])
                  }}
                  options={[
                    ...state.clinics.data.map((clinic) => ({
                      label: clinic.name,
                      value: clinic._id
                    }))
                  ]}
                />
              </Form.Item>
            ) : undefined}
            <Form.Item
              name={SystemUsersTemplates.dataModel.role.key}
              label={SystemUsersTemplates.dataModel.role.label}
              rules={[
                {
                  required: SystemUsersTemplates.dataModel.role.required,
                  message: SystemUsersTemplates.dataModel.role.validationMsg
                }
              ]}
            >
              <Select
                style={{ width: 350, maxWidth: '100%' }}
                disabled={type !== 'system'}
                options={[
                  {
                    label: '-Select a role-',
                    value: ''
                  },
                  ...handleRolesSelection()
                ]}
              />
            </Form.Item>
          </Space>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          size='small'
          styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}
          title={
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              Contact Details <FontAwesomeIcon icon={faContactBook} />
            </Space>
          }
        >
          <Space wrap>
            <Form.Item
              style={{ width: 350, maxWidth: '100%' }}
              name={SystemUsersTemplates.dataModel.email.key}
              label={SystemUsersTemplates.dataModel.email.label}
              rules={[
                {
                  required: isNewRecord ? SystemUsersTemplates.dataModel.email.required : false,
                  message: SystemUsersTemplates.dataModel.email.validationMsg
                }
              ]}
            >
              <Input
                type='email'
                maxLength={70}
                readOnly={!isNewRecord}
                placeholder={SystemUsersTemplates.dataModel.email.placeholder}
              />
            </Form.Item>
            <Form.Item
              style={{ width: 350, maxWidth: '100%' }}
              name={SystemUsersTemplates.dataModel.phoneNumber.key}
              label={SystemUsersTemplates.dataModel.phoneNumber.label}
              rules={[
                {
                  required: isNewRecord ? SystemUsersTemplates.dataModel.phoneNumber.required : false,
                  message: SystemUsersTemplates.dataModel.phoneNumber.validationMsg
                }
              ]}
            >
              <Input
                addonBefore='+27'
                placeholder='8288*****'
                maxLength={10}
                minLength={9}
                onChange={(e) => {
                  specialFieldValidation(clientForm, specialFormState, setSpecialFormState, {
                    key: 'phoneNumber',
                    event: e,
                    validationConfig: {
                      letters: { allowed: false, onlyCaps: true },
                      numbers: true
                    }
                  })
                }}
              />
            </Form.Item>
          </Space>
        </Card>
      </Col>
    </Row>
  )
}

export default RenderFormItems
