const ClinicalReportingEnums = {
  profileKeys: {
    DATA_POINT_TEMPLATES: 'data_point_templates'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update',
    COUNT: 'count',
    FIND_BY_ID: 'find_by_id',
    FIND_ONE: 'find_one',
    DELETE: 'delete'
  }
}

export default ClinicalReportingEnums
