import React from 'react'
import { Drawer, Menu, theme } from 'antd'

import Store from '../../store'
import coreReducer from '../utils/reducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'

const RightMenu = (props) => {
  const coreState = useSelector((state) => state.core)
  const { token } = theme.useToken()

  const handleCheckTabKey = () => {
    let key = null

    props.menuItems().forEach((item) => {
      if (coreState.tabNavigation.activeKey.indexOf(item.key) > -1) {
        key = item.key
      }
    })

    if (key) {
      return key
    } else {
      return coreState.tabNavigation.activeKey
    }
  }

  return (
    <Drawer
      title={<div style={{ color: props.secondaryLight }}>{props.rightMenuTitle}</div>}
      placement='right'
      closable={true}
      closeIcon={<FontAwesomeIcon icon={faClose} color='white' />}
      width={310}
      open={props.visible}
      onClose={props.onRightMenuClose}
      headerStyle={{
        backgroundColor: token.colorPrimary,
        color: 'white'
      }}
      bodyStyle={{
        padding: 0,
        paddingTop: 10
      }}
    >
      <Menu
        onClick={(event) => Store.dispatch(coreReducer.actions.menuItemClick(event))}
        mode='inline'
        selectedKeys={handleCheckTabKey()}
        defaultOpenKeys={props.expandedMenuItems}
        items={[...props.menuItems()]}
      />
    </Drawer>
  )
}

export default RightMenu
