/**
 * Utility functions and constants for general statistics
 */

/**
 * Reference ranges for general statistics
 * These are the normal ranges for adult patients
 */
export const GENERAL_STAT_REFERENCE_RANGES = {
  height: { 
    min: 150, // cm
    max: 200,
    unit: 'cm'
  },
  weight: { 
    min: 45, 
    max: 100,
    unit: 'kg'
  },
  waist: { 
    min: 70, 
    max: 94, // For men, 80 for women, using average
    unit: 'cm'
  },
  bmi: { 
    min: 18.5, 
    max: 24.9,
    unit: ''
  }
}

/**
 * Determines the status of a general statistic value compared to reference ranges
 * @param {string} statKey - The key of the statistic
 * @param {*} value - The value to check
 * @returns {string} Status: 'normal', 'warning', or 'danger'
 */
export const getStatStatus = (statKey, value) => {
  if (!statKey || value === undefined || value === null) return 'normal'
  
  const range = GENERAL_STAT_REFERENCE_RANGES[statKey]
  if (!range) return 'normal'
  
  const numValue = parseFloat(value)
  if (isNaN(numValue)) return 'normal'
  
  // BMI has specific ranges
  if (statKey === 'bmi') {
    if (numValue < 16 || numValue > 35) {
      return 'danger'
    } else if (numValue < 18.5 || numValue > 29.9) {
      return 'warning'
    }
    return 'normal'
  }
  
  // For other stats, use percentage deviation from normal range
  if (numValue > range.max + (range.max * 0.15) || numValue < range.min - (range.min * 0.15)) {
    return 'danger'
  } else if (numValue > range.max || numValue < range.min) {
    return 'warning'
  }
  return 'normal'
}

/**
 * Formats a general statistic value for display
 * @param {string} statKey - The key of the statistic
 * @param {*} value - The value to format
 * @returns {string} Formatted value with unit
 */
export const formatStatValue = (statKey, value) => {
  if (!statKey || value === undefined || value === null) return 'No data'
  
  const range = GENERAL_STAT_REFERENCE_RANGES[statKey]
  if (!range) return String(value)
  
  return `${value}${range.unit ? ` ${range.unit}` : ''}`
}

/**
 * Gets the reference range text for a general statistic
 * @param {string} statKey - The key of the statistic
 * @returns {string} Reference range text
 */
export const getStatReferenceText = (statKey) => {
  const range = GENERAL_STAT_REFERENCE_RANGES[statKey]
  if (!range) return ''
  
  if (statKey === 'bmi') {
    return `Normal range: ${range.min} - ${range.max} (Underweight: <18.5, Overweight: >25)`
  }
  
  return `Normal range: ${range.min} - ${range.max} ${range.unit}`
} 