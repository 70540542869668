import React from 'react'
import { motion } from 'framer-motion'
import { Button } from 'antd'
import { CloseOutlined, ReloadOutlined } from '@ant-design/icons'
import '../styles/PaymentFailure.css'

const PaymentFailure = ({ error, onRetry }) => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='payment-failure-container'>
      <div className='failure-card'>
        <div className='error-icon-wrapper'>
          <div className='error-circle'></div>
          <CloseOutlined className='error-icon' />
        </div>

        <h2>Payment Failed</h2>
        <p className='error-message'>An error occurred during payment processing</p>

        <div className='support-info'>
          Need help? Contact our support team at{' '}
          <a href='mailto:support@meta-health.co.za'>support@meta-health.co.za</a>
        </div>

        <div className='action-buttons'>
          <Button className='primary-button' onClick={onRetry} icon={<ReloadOutlined />}>
            Try Again
          </Button>
        </div>
      </div>
    </motion.div>
  )
}

export default PaymentFailure
