import React, { useEffect, useState } from 'react'
import { Card, Avatar } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'
import styles from '../styles/DailyAppointments.module.css'
import { getAppointments } from '../../../../Bookings/utils/utils'
import { useSelector } from 'react-redux'

const DailyAppointments = () => {
  const [selectedDate, setSelectedDate] = useState(new Date())

  const appointments = useSelector((state) => state.bookings.dashboardData)
  // Mock data for the week
  const weekDays = Array.from({ length: 7 }, (_, i) => {
    const date = new Date()
    date.setDate(date.getDate() + i)
    return date
  })

  const formatDate = (date) => {
    return {
      day: new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date),
      date: date.getDate()
    }
  }

  const isToday = (date) => {
    const today = new Date()
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  const filteredAppointments = appointments
    .filter((appointment) => {
      const appointmentDate = new Date(appointment.bookingDate)
      const isMatch =
        appointmentDate.getDate() === selectedDate.getDate() &&
        appointmentDate.getMonth() === selectedDate.getMonth() &&
        appointmentDate.getFullYear() === selectedDate.getFullYear()

      return isMatch
    })
    .sort((a, b) => {
      // Convert time strings to comparable values (assuming format "HH:mm")
      const timeA = a.startTime.split(':').map(Number)
      const timeB = b.startTime.split(':').map(Number)

      // Compare hours first
      if (timeA[0] !== timeB[0]) {
        return timeA[0] - timeB[0]
      }
      // If hours are equal, compare minutes
      return timeA[1] - timeB[1]
    })

  return (
    <Card className={styles.card}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <CalendarOutlined className={styles.calendarIcon} />
          <h3 className={styles.cardTitle}>Daily Appointments</h3>
        </div>
        <div className={styles.dateDisplay}>
          <span>
            {new Intl.DateTimeFormat('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            }).format(selectedDate)}
          </span>
        </div>
      </div>

      <div className={styles.dateSelector}>
        {weekDays.map((date, index) => {
          const { day, date: dateNum } = formatDate(date)
          return (
            <div
              key={index}
              className={`${styles.dateItem} ${isToday(date) ? styles.today : ''} ${
                date.getDate() === selectedDate.getDate() ? styles.selected : ''
              }`}
              onClick={() => setSelectedDate(date)}
            >
              <span className={styles.dayName}>{day}</span>
              <span className={styles.dayNumber}>{dateNum}</span>
            </div>
          )
        })}
      </div>

      <div className={styles.timeline}>
        {filteredAppointments.map((appointment) => (
          <div key={appointment._id} className={styles.appointmentSlot}>
            <div className={styles.timeColumn}>
              <span className={styles.time}>{appointment.startTime}</span>
              <div className={styles.timeConnector} />
            </div>
            <div className={styles.appointmentContent}>
              <Avatar className={styles.patientAvatar}>
                {appointment.patientRecord?.firstName?.[0]}
                {appointment.patientRecord?.lastName?.[0]}
              </Avatar>
              <div className={styles.appointmentInfo}>
                <span className={styles.patientName}>
                  {`${appointment.patientRecord?.firstName} ${appointment.patientRecord?.lastName}`}
                </span>
                <span className={styles.duration}>{`${appointment.startTime} - ${appointment.endTime}`}</span>
                <span className={styles.status}>{appointment.status}</span>
              </div>
            </div>
          </div>
        ))}

        {/* <div className={styles.breakSlot}>
          <div className={styles.timeColumn}>
            <span className={styles.time}>10:00</span>
            <div className={styles.timeConnector} />
          </div>
          <div className={styles.breakContent}>
            <span>Have a Break</span>
          </div>
        </div> */}
      </div>
    </Card>
  )
}

export default DailyAppointments
