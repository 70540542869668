import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleError } from '../../lib/utils'
import { readPatients } from '../../Admin/patients/utils/utils'
import { Col, Space, Spin, message, notification, theme } from 'antd'
import { faBell, faSearch } from '@fortawesome/free-solid-svg-icons'
import './patient-dashboard.css'

import BookingsWrapper from '../../Bookings/components/AppointmentListViewWrapper'
import BookingShortcut from '../../reusable-components/BookingShortcut'
import CustomButton from '../../reusable-components/CustomButton'
import CustomRow from '../../reusable-components/CustomRow'
import CoreReducer from '../../../core/utils/reducer'
import CoreEnums from '../../../core/utils/enums'
import Dependants from '../../Profile/components/dependants/dependants-list-view'
import DiagnosisHistory from '../../Medical Vault/diagnosis-history/components/diagnosis-history-wrapper'
import MedicalHistory from '../../Medical Vault/medical-history/components/medical-history'
import NextAppointmentBanner from '../../reusable-components/NextAppointmentBanner'
import PersonalDetails from './components/PersonalDetailsSummary'
import MedicalHistorySummary from './components/LifeStyleSummary'
import MedicalAnalysis from '../../Analytics/Patients/medical-analysis'
import Store from '../../../store'

import DashboardGreeting from '../Employee/components/dashboard-greeting'
import { deviceDetect } from 'react-device-detect'
import DependentsReducer from '../../Profile/components/dependants/utils/depentdents-reducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getMedHistProgress } from '../../Medical Vault/medical-history/utils/utils'

const PatientDashboard = () => {
  const state = useSelector((state) => state)
  const dependents = useSelector((state) => state.dependents.data)
  const dispatch = useDispatch()
  const [dependentsLoading, setDependentsLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [hasNotified, setHasNotified] = useState()

  const shortcutButtons = [
    {
      key: selectedUser ? CoreEnums.tabKeys.DEPENDANTS : CoreEnums.tabKeys.MEDICAL_HISTORY,
      children: selectedUser ? <Dependants /> : <MedicalHistory />,
      label: CoreEnums.tabTitles.MEDICAL_HISTORY
    },
    {
      key: CoreEnums.tabKeys.DIAGNOSIS_HISTORY,
      children: <DiagnosisHistory />,
      label: CoreEnums.tabTitles.DIAGNOSIS_HISTORY
    },
    {
      key: CoreEnums.tabKeys.MY_BOOKINGS,
      children: <BookingsWrapper />,
      label: CoreEnums.tabTitles.MY_BOOKINGS
    }
  ]

  const checkSelectedDependent = (key) => {
    if (selectedUser === key) {
      return 'active'
    }
  }

  const handleSetUser = (key) => {
    setSelectedUser(key)
  }

  // TODO: Fetch dependents
  // Turn this into a lib function
  // Possible name: Build Dependents
  // Find best way to only have to do this once on load and let state handle any additional changes to dependents
  useEffect(() => {
    if (state.auth.agiliteUser) {
      fetchDependents()
    }
    // eslint-disable-next-line
  }, [
    state.clinics.data,
    state.services.data,
    state.virtualServices.data,
    state.auth.agiliteUser,
    state.bookings.dashboardData
  ])

  const fetchDependents = async () => {
    let qry = {}
    let tmpDependents = []

    setDependentsLoading(true)

    try {
      qry.mainMemberId = state.auth.agiliteUser._id
      tmpDependents = await readPatients(qry)
    } catch (e) {
      message.error(handleError(e, true))
    }
    dispatch(DependentsReducer.actions.setRecords(tmpDependents))
    setDependentsLoading(false)
  }

  // TODO: Add dispatch to the "add member" button
  // When any changes are made to dependents UPDATE state
  // And run the fetch dependents logic again

  const shortcutLinkAction = (key, children, label) => {
    Store.dispatch(
      CoreReducer.actions.addTab({
        key,
        closable: true,
        label,
        children
      })
    )
  }

  const { token } = theme.useToken()

  useEffect(() => {
    if (!hasNotified) {
      if (state.medicalHistory?.patient) {
        if (getMedHistProgress(state.medicalHistory?.patient) !== '100') {
          setHasNotified(true)
          openNotification()
        } else {
          closeNotification()
        }
      }
    }

    // eslint-disable-next-line
  }, [state.medicalHistory?.patient])

  const openNotification = () => {
    notification.open({
      message: 'Medical History',
      description: <>Please complete your Medical History</>,
      icon: <FontAwesomeIcon icon={faBell} style={{ color: token.colorError }} />,
      placement: 'bottomLeft',
      duration: 0,
      key: 'medical-history'
    })
  }

  const closeNotification = () => {
    notification.destroy()
  }

  return (
    <>
      <CustomRow style={{ marginBottom: 24 }}>
        {deviceDetect().isMobile ? undefined : (
          <Col span={24}>
            <DashboardGreeting />
          </Col>
        )}
        <Col span={24}>
          <BookingShortcut showSOS />
        </Col>
      </CustomRow>
      <CustomRow>
        <Col xs={24} sm={24} md={24} lg={24} xl={15}>
          <CustomRow className='basic-card' gutter={[12, 12]}>
            <Col>
              {deviceDetect().isBrowser ? (
                <Space style={{ gap: 30 }}>
                  <div
                    onClick={() => {
                      handleSetUser()
                    }}
                    className={`dependent-select ${checkSelectedDependent()}`}
                  >
                    Myself
                  </div>
                  {dependentsLoading ? (
                    <>
                      {' '}
                      <Spin spinning /> Dependents Loading...
                    </>
                  ) : (
                    <>
                      {dependents.map((dependent) => {
                        return (
                          <div
                            onClick={() => {
                              handleSetUser(dependent._id)
                            }}
                            className={`dependent-select ${checkSelectedDependent(dependent._id)}`}
                          >
                            {dependent.firstName}
                          </div>
                        )
                      })}
                    </>
                  )}
                </Space>
              ) : undefined}
            </Col>
            <NextAppointmentBanner id={selectedUser} />

            {deviceDetect().isMobile ? undefined : (
              <Col span={24}>
                <CustomRow>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <PersonalDetails data={selectedUser ? dependents.find((i) => i._id === selectedUser) : null} />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <MedicalHistorySummary selectedUser={selectedUser} />
                  </Col>
                  <Col span={24}>
                    <Space style={{ justifyContent: 'left', width: '100%', flexWrap: 'wrap' }}>
                      {selectedUser ? (
                        <>
                          <CustomButton
                            onClick={() => {
                              shortcutLinkAction(
                                CoreEnums.tabKeys.DEPENDANTS,
                                <Dependants />,
                                CoreEnums.tabTitles.DEPENDANTS
                              )
                            }}
                            text={CoreEnums.tabTitles.DEPENDANTS}
                            icon={faSearch}
                            type='accent'
                          />
                        </>
                      ) : (
                        <>
                          {shortcutButtons.map((button) => {
                            return (
                              <CustomButton
                                onClick={() => {
                                  shortcutLinkAction(button.key, button.children, button.label)
                                }}
                                text={button.label}
                                icon={faSearch}
                                type='accent'
                              />
                            )
                          })}
                        </>
                      )}
                    </Space>
                  </Col>
                </CustomRow>
              </Col>
            )}
          </CustomRow>
        </Col>
        {deviceDetect().isMobile ? undefined : (
          <Col xs={24} sm={24} md={24} lg={24} xl={9} style={{ minHeight: '100%' }}>
            <CustomRow className='basic-card' gutter={[12, 12]}>
              <Col span={24}>
                <h1>Medical Analytics</h1>
              </Col>
              <MedicalAnalysis />
            </CustomRow>
          </Col>
        )}
      </CustomRow>
    </>
  )
}

export default PatientDashboard
