import {
  BarChartOutlined,
  CalendarOutlined,
  FileAddOutlined,
  FileSearchOutlined,
  UserOutlined
} from '@ant-design/icons'
import { faCheckCircle, faClose, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Space, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { deviceDetect } from 'react-device-detect'
import { getMedHistProgress } from '../../../../Medical Vault/medical-history/utils/utils'

export const columnTemplate = (token) => {
  return [
    {
      title: '',
      key: 'record',
      responsive: ['xs'],
      render: (text, record) => {
        return (
          <div style={deviceDetect().isMobile ? { textAlign: 'center' } : {}}>
            <b>
              {record.firstName} {record.lastName}
            </b>
            <br />

            <span>
              Date Of Birth: <b>{record.dateOfBirth ? dayjs(record.dateOfBirth).format('YYYY-MM-DD') : ''}</b>
            </span>
            <br />
            <span>
              <Space>
                <div>Medical History:</div>
                <div>
                  {getMedHistProgress(record.patientRecord) === '100' ? (
                    <FontAwesomeIcon icon={faCheckCircle} color='green' />
                  ) : (
                    <FontAwesomeIcon icon={faXmarkCircle} color='red' />
                  )}
                </div>
              </Space>
            </span>
          </div>
        )
      }
    },
    { title: 'First Name', dataIndex: 'firstName', key: 'firstName', responsive: ['md'] },
    { title: 'Last Name', dataIndex: 'lastName', key: 'lastName', responsive: ['md'] },
    { title: 'ID / Passport Number', dataIndex: 'idNo', key: 'idNo', responsive: ['md'] },
    {
      title: 'Date Of Birth',
      dataIndex: 'dateOfBirth',
      key: 'dob',
      responsive: ['md'],
      render: (text) => (text ? dayjs(text).format('YYYY-MM-DD') : '')
    },
    {
      title: 'Medical History',
      key: 'medicalHistory',
      responsive: ['md'],
      render: (value, record) => {
        if (getMedHistProgress(record.patientRecord) === '100') {
          return (
            <Space>
              <FontAwesomeIcon icon={faCheckCircle} style={{ color: token.colorSuccess }} /> Complete
            </Space>
          )
        } else {
          return (
            <Space>
              <FontAwesomeIcon icon={faClose} style={{ color: token.colorError }} /> Incomplete
            </Space>
          )
        }
      }
    },
    {
      title: 'View',
      dataIndex: '',
      key: '',
      responsive: ['md'],
      render: (text) => (
        <Space style={{ gap: 12, justifyContent: 'space-between', width: '100%' }}>
          <Tooltip title='Personal Details'>
            <UserOutlined />
          </Tooltip>

          <Tooltip title='Analytics'>
            <BarChartOutlined />
          </Tooltip>

          <Tooltip title='Appointments'>
            <CalendarOutlined />
          </Tooltip>

          <Tooltip title='Diagnosis History'>
            <FileSearchOutlined />
          </Tooltip>

          <Tooltip title='Medical History'>
            <FileAddOutlined />
          </Tooltip>
        </Space>
      )
    }
  ]
}

export const familyRelations = [
  {
    checks: ['Aunt'],
    value: 'Aunt'
  },
  {
    checks: ['Brother'],
    value: 'Brother'
  },
  {
    checks: ['Cousin'],
    value: 'Cousin'
  },
  {
    checks: ['Daughter'],
    value: 'Daughter'
  },
  {
    checks: ['Domestic Partner'],
    value: 'Domestic Partner'
  },
  {
    checks: ['Father', 'Dad', 'Pa', 'Papa'],
    value: 'Father'
  },
  {
    checks: ['Foster Parent'],
    value: 'Foster Parent'
  },
  {
    checks: ['Godfather'],
    value: 'Godfather'
  },
  {
    checks: ['Godmother'],
    value: 'Godmother'
  },
  {
    checks: ['Grandfather', 'Grand Father', 'Oupa', 'Grandpa', 'Oupie'],
    value: 'Grandfather'
  },
  {
    checks: ['Grandmother', 'Grand Mother', 'Grandma', 'Granny', 'Ouma'],
    value: 'Grandmother'
  },
  {
    checks: ['Guardian'],
    value: 'Guardian'
  },
  {
    checks: ['Half-brother', 'half brother'],
    value: 'Half-brother'
  },
  {
    checks: ['Half-sister', 'half sister'],
    value: 'Half-sister'
  },
  {
    checks: ['Husband'],
    value: 'Husband'
  },
  {
    checks: ['In-law'],
    value: 'In-law'
  },
  {
    checks: ['Mother', 'Mom', 'Ma', 'Mama'],
    value: 'Mother'
  },
  {
    checks: ['Nephew'],
    value: 'Nephew'
  },
  {
    checks: ['Niece'],
    value: 'Niece'
  },
  {
    checks: ['Partner'],
    value: 'Partner'
  },
  {
    checks: ['Sibling-in-law'],
    value: 'Sibling-in-law'
  },
  {
    checks: ['Sister'],
    value: 'Sister'
  },
  {
    checks: ['Son'],
    value: 'Son'
  },
  {
    checks: ['Spouse'],
    value: 'Spouse'
  },
  {
    checks: ['Stepdaughter'],
    value: 'Stepdaughter'
  },
  {
    checks: ['Stepfather'],
    value: 'Stepfather'
  },
  {
    checks: ['Stepmother'],
    value: 'Stepmother'
  },
  {
    checks: ['Stepson'],
    value: 'Stepson'
  },
  {
    checks: ['Uncle'],
    value: 'Uncle'
  },
  {
    checks: ['Wife'],
    value: 'Wife'
  }
]
