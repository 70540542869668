import React, { useState, useEffect } from 'react'
import { Card, Form, Select, Radio, InputNumber, Button, message, Row, Col, Divider } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonRunning, faWineGlass, faSmoking, faVial } from '@fortawesome/free-solid-svg-icons'
import { updateLifestyle } from '../../../Patient/Medical History/api/api'
import { handleError } from '../../../lib/utils'
import Templates from '../../../Medical Vault/medical-history/utils/templates'

const LifestyleTab = ({ lifestyle = {}, recordId, setSaving, updateMedicalHistory }) => {
  const [form] = Form.useForm()
  const lifestyleTemplate = Templates.dataModel

  // Initialize form with props
  useEffect(() => {
    if (lifestyle) {
      form.setFieldsValue(lifestyle)
    }
  }, [lifestyle, form])

  const handleSave = async () => {
    try {
      const values = await form.validateFields()
      setSaving(true)

      const newData = await updateLifestyle(recordId, {
        ...values,
        updatedAt: new Date().toISOString()
      })

      updateMedicalHistory(newData)
      message.success('Lifestyle information updated successfully')
    } catch (error) {
      if (error.errorFields) {
        message.error('Please complete all required fields')
      } else {
        message.error(handleError(error, true))
      }
    } finally {
      setSaving(false)
    }
  }

  return (
    <div className='lifestyle-tab'>
      <Form form={form} layout='vertical'>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card title='Diet & Exercise' className='lifestyle-card'>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item label='Diet' name='diet'>
                    <Select
                      placeholder='Select diet type'
                      options={[
                        { value: 'regular', label: 'Regular' },
                        { value: 'vegetarian', label: 'Vegetarian' },
                        { value: 'vegan', label: 'Vegan' },
                        { value: 'keto', label: 'Keto' },
                        { value: 'paleo', label: 'Paleo' },
                        { value: 'gluten-free', label: 'Gluten-Free' },
                        { value: 'other', label: 'Other' }
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label='Physical Activity' name='physicalActivity'>
                    <Select
                      placeholder='Select activity level'
                      options={[
                        { value: 'sedentary', label: 'Sedentary' },
                        { value: 'light', label: 'Light (1-2 days/week)' },
                        { value: 'moderate', label: 'Moderate (3-5 days/week)' },
                        { value: 'active', label: 'Active (6-7 days/week)' },
                        { value: 'very-active', label: 'Very Active (2x daily)' }
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label='Sleep (hours/day)' name='sleepHours'>
                    <InputNumber min={0} max={24} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label='Water Intake (glasses/day)' name='waterIntake'>
                    <InputNumber min={0} max={30} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label='Screen Time (hours/day)' name='screenTime'>
                    <InputNumber min={0} max={24} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={24}>
            <Card
              title={
                <span>
                  <FontAwesomeIcon icon={faWineGlass} style={{ marginRight: '8px' }} />
                  Alcohol Consumption
                </span>
              }
              className='lifestyle-card'
            >
              <Form.Item name='alcoholConsumption' valuePropName='checked'>
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.alcoholConsumption !== currentValues.alcoholConsumption
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue('alcoholConsumption') ? (
                    <Form.Item label='Consumption Level' name='alcoholConsumptionLevel'>
                      <Select
                        placeholder='Select consumption level'
                        options={[
                          { value: 'occasional', label: 'Occasional (few times a month)' },
                          { value: 'moderate', label: 'Moderate (1-2 drinks, few times a week)' },
                          { value: 'regular', label: 'Regular (1-2 drinks daily)' },
                          { value: 'heavy', label: 'Heavy (3+ drinks daily)' }
                        ]}
                      />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
            </Card>
          </Col>

          <Col span={24}>
            <Card
              title={
                <span>
                  <FontAwesomeIcon icon={faSmoking} style={{ marginRight: '8px' }} />
                  Smoking
                </span>
              }
              className='lifestyle-card'
            >
              <Form.Item name='smoking' valuePropName='checked'>
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.smoking !== currentValues.smoking}
              >
                {({ getFieldValue }) =>
                  getFieldValue('smoking') ? (
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label='Smoking Level' name='smokingLevel'>
                          <Select
                            placeholder='Select smoking level'
                            options={[
                              { value: 'light', label: 'Light (1-5 cigarettes/day)' },
                              { value: 'moderate', label: 'Moderate (5-15 cigarettes/day)' },
                              { value: 'heavy', label: 'Heavy (15+ cigarettes/day)' }
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label='Years Smoking' name='smokingYears'>
                          <InputNumber min={0} max={100} style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : null
                }
              </Form.Item>
            </Card>
          </Col>

          <Col span={24}>
            <Card
              title={
                <span>
                  <FontAwesomeIcon icon={faVial} style={{ marginRight: '8px' }} />
                  Vaping
                </span>
              }
              className='lifestyle-card'
            >
              <Form.Item name='vaping' valuePropName='checked'>
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.vaping !== currentValues.vaping}
              >
                {({ getFieldValue }) =>
                  getFieldValue('vaping') ? (
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label='Vaping Level' name='vapingLevel'>
                          <Select
                            placeholder='Select vaping level'
                            options={[
                              { value: 'light', label: 'Light (occasionally)' },
                              { value: 'moderate', label: 'Moderate (few times a day)' },
                              { value: 'heavy', label: 'Heavy (throughout the day)' }
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label='Years Vaping' name='vapingYears'>
                          <InputNumber min={0} max={100} style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : null
                }
              </Form.Item>
            </Card>
          </Col>

          <Col span={24}>
            <Button type='primary' onClick={handleSave} block>
              Save Lifestyle Information
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default LifestyleTab
