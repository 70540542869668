import React from 'react'
import { Modal, Button, Divider, Space, Typography, Row, Col, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHospital, faSignature } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { DOSAGE_FORMS } from './MedicationCard/constants/dosageForms'
import { normalizePresentationCode } from './MedicationCard/utils/normalizers'

// Import Meta-Health logo
import MetaHealthLogo from '../../../../../assets/images/MH-logo-v1 1-2.png'

const { Title, Text } = Typography

const PrescriptionPreview = ({ 
  visible, 
  onClose, 
  onSend, 
  script, 
  patient, 
  loading 
}) => {
  const authState = useSelector((state) => state.auth)
  const clinicState = useSelector((state) => state.clinics.data)
  const { token } = theme.useToken()
  
  // Get the current date and time formatted
  const currentDateTime = dayjs().format('YYYY-MM-DD HH:mm')
  
  // Get doctor details from auth state
  const doctor = authState?.agiliteUser
  const doctorName = doctor ? `Dr. ${doctor.firstName} ${doctor.lastName}` : ''
  const doctorQualification = doctor?.extraData?.qualification || ''
  const doctorRegistrationNumber = doctor?.extraData?.mpNumber || ''
  
  // Get clinic details - clinics are stored in doctor.extraData.clinics array
  const clinicRef = doctor?.extraData?.clinics?.[0] // Get first clinic reference
  const clinic = clinicState?.find(c => c._id === clinicRef)
  const clinicName = clinic?.practiceName || clinic?.name || 'Meta-Clinic'
  const clinicPhone = clinic?.phoneNumber || ''
  const clinicPracticeNumber = clinic?.practiceNumber || ''
  const clinicRegNumber = clinic?.practiceRegNumber || ''
  const clinicAddress = clinic?.physicalAddress || clinic?.address || ''
  
  // Format dosing instructions to remove redundant "for days" text
  // and remove "Initial:" when there's only one dosage instruction
  const formatDosingInstructions = (instructions) => {
    if (!instructions) return ''
    
    // Remove redundant "for days" text
    let formatted = instructions.replace(/, for days/g, '')
    
    // Check if there's a "Followed by" section
    const hasFollowedBy = formatted.includes('Followed by:')
    
    // If there's no "Followed by" section, remove the "Initial:" prefix
    if (!hasFollowedBy && formatted.startsWith('Initial:')) {
      formatted = formatted.replace('Initial:', '').trim()
    }

    // Remove any instances of ", orally" or "orally"
    formatted = formatted.replace(/, orally/gi, '')
    formatted = formatted.replace(/orally/gi, '')
    
    return formatted
  }
  
  // Format medication name to ensure proper case (not all uppercase)
  const formatMedicationName = (name) => {
    if (!name) return ''
    return name.toLowerCase().replace(/\b\w/g, char => char.toUpperCase())
  }
  
  // Get the appropriate pack size unit based on dosage form
  const getPackSizeUnit = (med) => {
    if (!med.presentationCode) return 'units'
    
    const dosageForm = med.presentationCode.toLowerCase()
    if (dosageForm.includes('tablet')) return 'tablets'
    if (dosageForm.includes('capsule')) return 'capsules'
    if (dosageForm.includes('suspension') || dosageForm.includes('syrup') || dosageForm.includes('solution')) return 'ml'
    if (dosageForm.includes('cream') || dosageForm.includes('ointment') || dosageForm.includes('gel')) return 'g'
    if (dosageForm.includes('inhaler')) return 'doses'
    if (dosageForm.includes('drops')) return 'ml'
    
    return med.presentationCode.toLowerCase()
  }
  
  if (!script || !patient) return null

  return (
    <Modal
      title="Prescription Preview"
      open={visible}
      onCancel={onClose}
      width="80%"
      style={{ top: 0, height: '100vh', paddingBottom: 0 }}
      bodyStyle={{ 
        height: 'calc(100vh - 110px)', 
        overflowY: 'auto',
        paddingBottom: 0
      }}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
        <Button 
          key="send" 
          type="primary" 
          onClick={onSend} 
          loading={loading}
          icon={<FontAwesomeIcon icon={faSignature} />}
          disabled={true}
        >
          Sign and send now
        </Button>
      ]}
    >
      <div style={{ 
        border: `1px solid ${token.colorBorder}`, 
        borderRadius: token.borderRadius,
        padding: 24,
        background: 'white'
      }}>
        {/* Header with clinic logo and doctor details */}
        <Row gutter={[16, 16]} align="top">
          <Col xs={24} sm={8} md={8} style={{ textAlign: 'left' }}>
            <Title level={5} style={{ margin: 0, color: token.colorPrimary }}>
              {doctorName}
            </Title>
            <Text>{doctorQualification}</Text>
            <br />
            <Text>MP {doctorRegistrationNumber}</Text>
          </Col>
          <Col xs={24} sm={8} md={8} style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'flex-start',
            marginTop: -8 
          }}>
            <img 
              src={MetaHealthLogo} 
              alt="Meta-Health Logo" 
              style={{ 
                maxWidth: '160px', 
                width: '100%', 
                height: 'auto',
                objectFit: 'contain'
              }} 
            />
          </Col>
          <Col xs={24} sm={8} md={8} style={{ textAlign: 'right' }}>
            <Title level={5} style={{ margin: 0, color: token.colorPrimary }}>
              {clinicName}
            </Title>
            {clinicPracticeNumber && (
              <Text>Practice No: {clinicPracticeNumber}</Text>
            )}
            {clinicRegNumber && (
              <>
                <br />
                <Text>Reg No: {clinicRegNumber}</Text>
              </>
            )}
            {clinicPhone && (
              <>
                <br />
                <Text>Tel: {clinicPhone}</Text>
              </>
            )}
            {clinicAddress && (
              <>
                <br />
                <Text>{clinicAddress}</Text>
              </>
            )}
          </Col>
        </Row>

        <Divider style={{ 
          background: 'linear-gradient(to right, #1890ff, #52c41a)', 
          height: 2, 
          margin: '16px 0' 
        }} />

        {/* Patient Information */}
        <Row justify="space-between" style={{ marginBottom: 0 }}>
          <Col span={12}>
            <Text strong>Patient: {patient.firstName} {patient.lastName}</Text>
            <br />
            <Text>ID/Passport: {patient.idNo || 'N/A'}</Text>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Text>{currentDateTime}</Text>
            <br />
            <Text>{(patient.gender || 'GENDER').charAt(0).toUpperCase() + (patient.gender || 'GENDER').slice(1).toLowerCase()}, {patient.age || dayjs().diff(dayjs(patient.dateOfBirth), 'year')} yrs</Text>
          </Col>
        </Row>

        <Divider style={{ margin: '8px 0 16px 0' }} />

        {/* Medications */}
        <div>
          {script.items && script.items.map((med, index) => (
            <div key={index} style={{ 
              marginBottom: 8, 
              padding: '6px 10px',
              background: token.colorBgContainer,
              borderRadius: token.borderRadius,
              border: `1px solid ${token.colorBorderSecondary}`
            }}>
              {/* 1. Medication name, schedule and pack size */}
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '2px' }}>
                <Text style={{ margin: 0, lineHeight: 1.2 }}>
                  {index + 1}. {formatMedicationName(med.fullDescription || med.name)}{med.route ? ` (${med.route})` : ''}{med.drugSchedule ? `, S${med.drugSchedule}` : ''}{med.dispensingVolumePacksize ? ` - Packsize: ${med.dispensingVolumePacksize} ${getPackSizeUnit(med)}` : ''}
                </Text>
              </div>
              
              <Text type="secondary" style={{ display: 'block', margin: '2px 0', lineHeight: 1.2 }}>{med.activeIngredient}</Text>
              
              {/* 2. Dosing instructions */}
              <div style={{ marginTop: '4px' }}>
                <Text strong style={{ color: token.colorPrimary, margin: 0, lineHeight: 1.2 }}>Dosing instructions: </Text>
                <Text style={{ margin: 0, lineHeight: 1.2 }}>
                  {formatDosingInstructions(med.scriptingDosage)}
                  {' - Est. quantity: '}{med.quantity || med.estimatedQuantity} {med.quantitySuffix || getPackSizeUnit(med)}
                </Text>
              </div>
              
              {/* 3. Comments (Additional Notes) */}
              {med.additionalNotes && (
                <div style={{ marginTop: '2px' }}>
                  <Text strong style={{ margin: 0, lineHeight: 1.2 }}>Additional Notes: </Text>
                  <Text style={{ margin: 0, lineHeight: 1.2 }}>{med.additionalNotes}</Text>
                </div>
              )}
              
              {/* 4. Repeat */}
              {(med.repeat || med.rpt) && (med.repeat !== 'No Repeat' || med.rpt !== 'No Repeat') && (
                <div style={{ marginTop: '2px' }}>
                  <Text strong style={{ margin: 0, lineHeight: 1.2 }}>Repeat: </Text>
                  <Text style={{ margin: 0, lineHeight: 1.2 }}>
                    X {med.repeat || med.rpt} months, treatment for a total of {(parseInt(med.repeat || med.rpt) + 1)}/12 months
                  </Text>
                </div>
              )}
              
              {/* 5. ICD10 */}
              {med.icd && (
                <div style={{ marginTop: '4px' }}>
                  <Text strong>ICD-10: </Text>
                  <Text>{typeof med.icd === 'string' ? JSON.parse(med.icd).code : med.icd.code}</Text>
                </div>
              )}
              
              {/* 6. Do not substitute */}
              {med.noSubstitute && (
                <div style={{ marginTop: '4px' }}>
                  <Text strong style={{ color: token.colorError }}>DO NOT SUBSTITUTE</Text>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Digital Signature */}
        <div style={{ marginTop: 24 }}>
          <Text>Digitally signed using Meta-Health's Secure Electronic Signature</Text>
          <br />
          <Text>{dayjs().format('DD MMM YYYY HH:mm')}</Text>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            marginTop: 8 
          }}>
            <Text italic style={{ 
              fontFamily: 'Pacifico, "Great Vibes", "Pinyon Script", "Dancing Script", cursive',
              fontSize: 28,
              color: token.colorPrimary,
              letterSpacing: '1.5px',
              fontWeight: 400,
              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)'
            }}>
              {doctorName}
            </Text>
            <div style={{ 
              border: `2px solid ${token.colorPrimary}`,
              borderRadius: '50%',
              padding: 8,
              width: 60,
              height: 60,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: token.colorPrimary
            }}>
              <FontAwesomeIcon icon={faSignature} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PrescriptionPreview 