import { Col, Button, Table, Empty, Input, Space, Modal, message, Card, Radio, Switch, theme, Badge, Avatar, Tooltip } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faPrescriptionBottle, 
  faRefresh, 
  faSearch, 
  faPlus, 
  faCalendarAlt 
} from '@fortawesome/free-solid-svg-icons'

import { ScriptHistoryTableTemplate } from '../utils/templates'
import CustomRow from '../../reusable-components/CustomRow'
import { useCallback, useEffect, useState } from 'react'
import { readPatients } from '../../Admin/patients/utils/utils'
import { handleError } from '../../lib/utils'
import { removeScript } from '../utils/utils'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import CustomLoadingIcon from '../../reusable-components/CustomLoadingIcon'
import '../styles/scripting-history-list.css'
import PatientSearchModal from './PatientSearchModal'
import ScriptingTableControls from './ScriptingTableControls'
import ScriptingHeader from './ScriptingHeader'

const ScriptHistoryList = ({
  data,
  patientId,
  refreshView,
  createNewScript,
  viewScript,
  loading,
  setLoading,
  handleSearch,
  patientSearchQuery,
  handleGenerateScriptTemplate,
  printingScript,
  total,
  setPageSize,
  setPage,
  page,
  pageSize,
  type,
  setType,
  onlyDue,
  setOnlyDue,
  totalDue,
  setCurrentScript,
  recitfyOldRecord
}) => {
  const [modalOpen, setModalOpen] = useState()
  const [searchResults, setSearchResults] = useState([])
  const [searching, setSearching] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const { token } = theme.useToken()

  useEffect(() => {
    handlePatientSearch()
    // eslint-disable-next-line
  }, [searchQuery])

  const handlePatientSearch = async () => {
    let tmpUsers = []
    try {
      setSearching(true)
      if (searchQuery) {
        const qry = {
          'extraData.role.type': 'patient',
          $or: [
            {
              $expr: {
                $regexMatch: {
                  input: { $concat: ['$firstName', ' ', '$lastName'] },
                  regex: searchQuery,
                  options: 'i'
                }
              }
            },
            { phoneNumber: { $regex: searchQuery, $options: 'i' } },
            { email: { $regex: searchQuery, $options: 'i' } },
            { physicalAddress: { $regex: searchQuery, $options: 'i' } },
            { idNo: { $regex: searchQuery, $options: 'i' } }
          ]
        }
        tmpUsers = await readPatients(qry)
      }
      setSearchResults(tmpUsers)
    } catch (e) {
      message.error(handleError(e))
    } finally {
      setSearching(false)
    }
  }

  const handlePatientQuery = (query) => {
    setSearching(true)
    debouncedFilter(query)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 1000),
    []
  )

  const handleRemoveScript = async (recordId) => {
    setLoading(true)
    try {
      await removeScript(recordId)
      message.success('Removed Script')
      refreshView()
    } catch (e) {
      message.error(handleError(e))
      setLoading(false)
    }
  }

  const filterByDue = () => {
    const todaysDate = dayjs().format('YYYY-MM-DD')
    return data.filter(entry => {
      const nextDueDate = dayjs(entry.nextDue).format('YYYY-MM-DD')
      if (nextDueDate === todaysDate) return true
      if (todaysDate > nextDueDate) {
        entry.daysOverDue = dayjs(todaysDate).diff(nextDueDate, 'day')
        return true
      }
      return false
    })
  }

  const CustomEmptyState = () => (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <div style={{ textAlign: 'center' }}>
          <h3 style={{ color: token.colorTextSecondary, marginBottom: 8 }}>
            {type === 'schedule' ? 'No Due Scripts' : 'No Scripts Found'}
          </h3>
        </div>
      }
    />
  )

  return (
    <CustomRow justify='center'>
      <Col span={24}>
        <Card
          style={{ 
            borderRadius: 8,
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
          }}
          title={
            <ScriptingHeader 
              type={type}
              setType={setType}
              totalDue={totalDue}
              patientId={patientId}
              createNewScript={createNewScript}
              setModalOpen={setModalOpen}
              token={token}
            />
          }
          bodyStyle={{ padding: '16px 24px' }}
        >
          <div style={{ marginBottom: 24 }}>
            {!patientId && (
              <div style={{ marginBottom: 16, display: 'flex', gap: '8px' }}>
                <div style={{ width: 400 }}>
                  <Input
                    placeholder='Search patients by name or ID...'
                    defaultValue={patientSearchQuery}
                    allowClear
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ 
                      color: token.colorText
                    }}
                    className="search-input"
                    suffix={<FontAwesomeIcon icon={faSearch} style={{ color: token.colorTextSecondary }} />}
                  />
                </div>
                <Tooltip title='Refresh List'>
                  <Button
                    icon={<FontAwesomeIcon icon={faRefresh} />}
                    onClick={refreshView}
                    style={{ 
                      borderRadius: 6,
                      color: token.colorPrimary,
                      flexShrink: 0,
                      height: 40,
                      width: 40,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '16px'
                    }}
                  />
                </Tooltip>
              </div>
            )}

            <ScriptingTableControls 
              type={type}
              onlyDue={onlyDue}
              setOnlyDue={setOnlyDue}
              token={token}
            />

            <Table
              className={`scriptHistoryTable ${loading ? 'custom-loading-mask' : ''}`}
              loading={{
                spinning: loading,
                indicator: (
                  <div className="loadingContainer">
                    <CustomLoadingIcon loadingText='Loading...' />
                  </div>
                )
              }}
              size='middle'
              bordered={false}
              pagination={{
                current: page,
                pageSize: pageSize,
                total: total,
                onChange: (p, ps) => {
                  setPage(p)
                  setPageSize(ps)
                },
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} scripts`,
                style: { marginTop: 16 }
              }}
              rowKey={(record) => record._id}
              dataSource={loading ? [] : onlyDue && type === 'schedule' ? filterByDue() : data}
              scroll={{ x: 950 }}
              columns={ScriptHistoryTableTemplate(
                patientId ? true : false,
                handleGenerateScriptTemplate,
                printingScript,
                handleRemoveScript,
                type === 'schedule',
                viewScript,
                setCurrentScript,
                recitfyOldRecord
              ).filter((i) => !i.hidden)}
              locale={{ 
                emptyText: loading ? null : <CustomEmptyState />
              }}
              style={{
                borderRadius: 8,
                overflow: 'hidden',
                position: 'relative',
                minHeight: '400px'
              }}
            />
          </div>
        </Card>

        <PatientSearchModal 
          visible={modalOpen}
          onCancel={() => {
            setModalOpen(false)
            setSearchQuery('')
          }}
          onSearch={handlePatientQuery}
          loading={searching}
          searchResults={searchResults}
          onSelectPatient={(record) => {
            setModalOpen(false)
            setSearchQuery('')
            createNewScript(record)
          }}
        />
      </Col>
    </CustomRow>
  )
}

export default ScriptHistoryList
