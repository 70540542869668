import { Button, Col, Popconfirm, Switch, theme, Row, Space, DatePicker } from 'antd'
import React from 'react'
import CustomRow from '../../reusable-components/CustomRow'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import availabilityReducer from './utils/reducer'

const TimeSlot = React.memo(({ slot, onTimeSlotChange, selectedProfession, token }) => (
  <Col xs={24} md={12} lg={12}>
    <CustomRow
      gutter={12}
      style={{ alignItems: 'center', border: '1px rgba(0,0,0,0.2) solid' }}
      className='basic-card'
      justify='space-between'
    >
      <Col>
        <p>{slot.time}</p>
      </Col>
      <Col>
        {selectedProfession && (
          <Switch
            style={{ background: slot.linkedProfessional ? token.colorPrimary : token.colorError }}
            checked={slot.linkedProfessional}
            onChange={(value) => onTimeSlotChange(slot.time, value)}
          />
        )}
      </Col>
    </CustomRow>
  </Col>
))

const AvailabilitySchedule = ({
  handleTimeslotChange,
  setRangePicker,
  currentDay,
  selectedProfession,
  professionalDetails,
  handleRangeSelect,
  loading,
  isMedProf,
  savingSchedule,
  professionalsLoading,
  setFacet,
  setSelectedProfessional,
  date,
  setDate,
  handleGenerateTimeslots,
  saveDaySchedule
}) => {
  const dispatch = useDispatch()
  const availability = useSelector((state) => state.availability.data)
  const { token } = theme.useToken()

  const handleDateChange = async (newDate) => {
    if (!newDate) return
    setDate(newDate)
    await handleGenerateTimeslots(newDate)
  }

  return (
    <div style={{ width: '100%' }}>
      {/* Top toolbar */}
      <Row
        justify='space-between'
        align='middle'
        style={{
          marginBottom: 16,
          padding: '8px 16px',
          background: token.colorBgContainer,
          borderRadius: 8,
          boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
        }}
      >
        <Col>
          <Space direction='vertical' size={0}>
            {!isMedProf && (
              <h3 style={{ margin: 0 }}>
                {professionalDetails.firstName} {professionalDetails.lastName}
              </h3>
            )}
            <DatePicker
              value={dayjs(currentDay.date)}
              onChange={handleDateChange}
              allowClear={false}
              format='DD MMMM YYYY'
              bordered={false}
              style={{ padding: 0, width: '120%' }}
            />
          </Space>
        </Col>
        <Col>
          <Space>
            <Popconfirm
              title='Confirm'
              description='Are you sure you want to clear all timeslots?'
              onConfirm={() => handleRangeSelect(true)}
              okText='Yes'
              cancelText='No'
              okButtonProps={{ danger: true }}
              disabled={loading}
            >
              <Button size='small' disabled={loading} danger>
                Clear All
              </Button>
            </Popconfirm>
            <Button size='small' type='primary' onClick={() => setRangePicker(true)} disabled={loading}>
              Set Range
            </Button>
            <Button
              size='small'
              style={{ background: token.colorSuccess, color: 'white' }}
              onClick={saveDaySchedule}
              loading={savingSchedule}
            >
              {savingSchedule ? 'Saving...' : 'Save'}
            </Button>
          </Space>
        </Col>
      </Row>

      {/* Time slots grid */}
      <Row gutter={[8, 8]}>
        {professionalsLoading ? (
          <Col span={24}>
            <AgiliteSkeleton skActive spinnerTip='Loading schedule...' />
          </Col>
        ) : (
          currentDay.timeSlots.map((slot) => (
            <Col xs={12} sm={8} md={6} lg={4} key={slot.time}>
              <Row
                style={{
                  padding: '4px 8px',
                  background: token.colorBgContainer,
                  borderRadius: 4,
                  border: '1px solid ' + token.colorBorderSecondary,
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Col>
                  <span style={{ fontSize: 13 }}>{slot.time}</span>
                </Col>
                <Col>
                  {selectedProfession && (
                    <Switch
                      size='small'
                      style={{
                        background: slot.linkedProfessional ? token.colorPrimary : token.colorError,
                        minWidth: 28
                      }}
                      checked={slot.linkedProfessional}
                      onChange={(value) => handleTimeslotChange(slot.time, value)}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          ))
        )}
      </Row>
    </div>
  )
}

export default React.memo(AvailabilitySchedule)
