// src/components/Detection/OXDetection.js
import React, { useContext, useEffect, useState, useRef } from 'react'
import BluetoothContext from '../../BluetoothContext'
import { Button, message, Alert, Space } from 'antd'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { createClinicDataRecords } from '../../../../Examination/examination-utils/examination-lib'

const Process = {
  START: 0,
  END: 4
}

const Detection = {
  OX: 'Blood Oxygen'
}

const OXDetection = ({ userRef, bookingRef }) => {
  const authState = useSelector((state) => state.auth)
  const { engine, bleCommandCharacteristic } = useContext(BluetoothContext)
  const [spo2, setSpo2] = useState(null)
  const [hr, setHr] = useState(null)
  const [error, setError] = useState(null)
  const [isDetecting, setIsDetecting] = useState(false)
  const [saving, setSaving] = useState(false)

  // Use useRef for writeQueue to maintain its state across renders
  const writeQueue = useRef(Promise.resolve())

  useEffect(() => {
    if (!engine || !bleCommandCharacteristic) {
      console.warn('Engine or bleCommandCharacteristic not available for OXDetection')
      return
    }

    engine.registBLEListener({
      onException: (err) => {
        const { code, message } = err
        console.log('code=   ', code)
        console.log('message=   ', message)
      }
    })

    engine.registOXListener({
      onProcess: (type, data) => {
        if (bleCommandCharacteristic) {
          writeQueue.current = writeQueue.current
            .then(() => bleCommandCharacteristic.writeValue(data))
            .catch((err) => {
              console.error('Error writing to bleCommandCharacteristic:', err)
            })
        }
      },
      onResult: (spo2Value, hrValue) => {
        if (spo2Value !== undefined && hrValue !== undefined) {
          setSpo2(Math.round(spo2Value))
          setHr(Math.round(hrValue))
          setError(null)
          stopDetection()
        } else {
          console.warn('Invalid OX result received:', { spo2Value, hrValue })
          setError('Invalid result received from device.')
        }
      },
      onRawResult: (red) => {
        // Removed waveList handling since canvas is removed
      },
      onException: (err) => {
        console.error('OX Detection Exception:', err)
        setError(`OX Detection Error: ${err.message || 'Unknown error'}`)
        // Optionally stop detection to prevent further errors
        if (typeof engine.stopDetect === 'function') {
          engine.stopDetect(Detection.OX)
        } else {
          console.error('engine.stopDetect is not a function')
        }
      }
    })

    return () => {
      if (typeof engine.unregistOXListener === 'function') {
        engine.unregistOXListener()
      } else {
        console.error('engine.unregistOXListener is not a function')
      }
    }
  }, [engine, bleCommandCharacteristic])

  const startDetection = () => {
    if (engine && bleCommandCharacteristic) {
      setError(null)
      setIsDetecting(true)
      engine.startDetect(Detection.OX)
    } else {
      console.warn('Engine or bleCommandCharacteristic is not available')
    }
  }

  const stopDetection = () => {
    if (engine && bleCommandCharacteristic) {
      setIsDetecting(false)
      engine.stopDetect(Detection.OX)
    } else {
      console.warn('Engine or bleCommandCharacteristic is not available')
    }
  }

  const handleConfirm = async () => {
    // Filter selected readings
    setSaving(true)
    try {
      const promiseDataArray = [
        createClinicDataRecords({
          dateCreated: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          createdBy: authState.agiliteUser._id,
          userRef: userRef,
          bookingRef: bookingRef ? bookingRef : null,
          origin: 'linktop',
          category: 'vitals',
          key: 'oxygenSaturation',
          value: spo2
        }),
        createClinicDataRecords({
          dateCreated: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          createdBy: authState.agiliteUser._id,
          userRef: userRef,
          bookingRef: bookingRef ? bookingRef : null,
          origin: 'linktop',
          category: 'vitals',
          key: 'pulse',
          value: hr
        })
      ]
      await Promise.all(promiseDataArray)

      setSpo2(null)
      setHr(null)
      setHr(null)
      setSpo2(null)
      message.success('Selected readings have been successfully sent to the database.')
    } catch (error) {
      console.error('Error confirming readings:', error)
      message.error('Failed to send readings to the database. Please try again.')
    }
    setSaving(false)
  }

  return (
    <>
      {!engine || !bleCommandCharacteristic ? (
        <p>Device is not ready. Please wait...</p>
      ) : (
        <div style={{ maxWidth: '100%', padding: '20px' }}>
          <h2 style={{ marginBottom: '24px' }}>Blood Oxygen Detection</h2>

          <Space style={{ width: '100%' }} wrap>
            <Button type='primary' onClick={startDetection} disabled={isDetecting} style={{ marginRight: '12px' }}>
              Start Detection
            </Button>
            <Button danger onClick={stopDetection} disabled={!isDetecting}>
              Stop Detection
            </Button>
          </Space>

          {error && (
            <Alert message='Error' description={error} type='error' showIcon style={{ marginBottom: '24px' }} />
          )}

          <div
            style={{
              background: '#f5f5f5',
              padding: '20px',
              borderRadius: '8px',
              marginBottom: '24px'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                textAlign: 'center'
              }}
            >
              <div>
                <div style={{ fontSize: '14px', color: '#666' }}>SpO₂</div>
                <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{spo2 !== null ? `${spo2}%` : '--'}</div>
              </div>
              <div>
                <div style={{ fontSize: '14px', color: '#666' }}>Heart Rate</div>
                <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{hr !== null ? `${hr} bpm` : '--'}</div>
              </div>
            </div>
          </div>

          <Button type='primary' block disabled={saving || !spo2 || !hr} onClick={handleConfirm} loading={saving}>
            Save Results
          </Button>
        </div>
      )}
    </>
  )
}

export default OXDetection
