import React from 'react'
import { Button, Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faCalendarPlus,
  faChartLine,
  faHistory,
  faLightbulb,
  faVial,
  faHeartPulse,
  faKitMedical,
  faPrescription,
  faUserDoctor,
  faFileMedical,
  faStethoscope,
  faHospital
} from '@fortawesome/free-solid-svg-icons'
import '../../styling/QuickActionsScreen.css'

const { Text } = Typography

const QuickActionsScreen = ({ onActionClick, onBack }) => {
  const quickActions = [
    {
      id: 'book',
      title: 'Book Test',
      icon: faCalendarPlus,
      color: '#65D2D8'
    },
    {
      id: 'results',
      title: 'Results',
      icon: faChartLine,
      color: '#B5D777'
    },
    {
      id: 'history',
      title: 'History',
      icon: faHistory,
      color: '#FDB462'
    },
    {
      id: 'insights',
      title: 'Insights',
      icon: faLightbulb,
      color: '#EF8D9C'
    },
    {
      id: 'bloodwork',
      title: 'Blood Tests',
      icon: faVial,
      color: '#FF6B6B'
    },
    {
      id: 'vitals',
      title: 'Vitals',
      icon: faHeartPulse,
      color: '#4ECDC4'
    },
    {
      id: 'medications',
      title: 'Medications',
      icon: faPrescription,
      color: '#96CEB4'
    },
    {
      id: 'doctors',
      title: 'Doctors',
      icon: faUserDoctor,
      color: '#FF9F9F'
    },
    {
      id: 'reports',
      title: 'Reports',
      icon: faFileMedical,
      color: '#D4A5A5'
    },
    {
      id: 'checkup',
      title: 'Checkup',
      icon: faStethoscope,
      color: '#9FA5D5'
    },
    {
      id: 'emergency',
      title: 'Emergency',
      icon: faKitMedical,
      color: '#E3868F'
    },
    {
      id: 'facilities',
      title: 'Facilities',
      icon: faHospital,
      color: '#A5D5D4'
    }
  ]

  return (
    <div className='cs-quick-actions-screen'>
      <div className='cs-quick-actions-screen__header'>
        <Button type='link' onClick={onBack}>Back</Button>
        <Text strong>Quick Actions</Text>
      </div>
      
      <div className='cs-quick-actions-screen__grid'>
        {quickActions.map(action => (
          <Button
            key={action.id}
            onClick={() => onActionClick(action.id)}
            className='cs-quick-actions-screen__item'
          >
            <div 
              className='cs-quick-actions-screen__icon' 
              style={{ backgroundColor: `${action.color}20` }}
            >
              <FontAwesomeIcon 
                icon={action.icon} 
                style={{ color: action.color }} 
              />
            </div>
            <Text className='cs-quick-actions-screen__label'>
              {action.title}
            </Text>
          </Button>
        ))}
      </div>
    </div>
  )
}

export default QuickActionsScreen 