import React, { useEffect, useState } from 'react'
import { Layout, Row, Col } from 'antd'
import AppointmentOverview from './components/AppointmentOverview'
// import RevenueSummary from './components/RevenueSummary'
import Tasks from './components/Tasks'
import DailyAppointments from './components/DailyAppointments'
import { NotificationsProvider } from './context/NotificationsContext'
import styles from './styles/NewDoctorDashboard.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { aggregateBookings, getAppointments } from '../../../Bookings/utils/utils'
import { isAdmin, isDoctor, isNurse, isReception } from '../../../lib/profile-utils'
import dayjs from 'dayjs'
import bookingsReducer from '../../../Bookings/utils/booking-reducer'

const { Content } = Layout

const DashboardContent = () => {
  const state = useSelector((state) => state)
  const [loadingMetrics, setLoadingMetrics] = useState(false)
  const [metrics, setMetrics] = useState({})
  const [loadingApps, setLoadingApps] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    fetchAppointments()
    fetchMetrics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.clinics.data])

  const today = dayjs().startOf('day').toISOString()
  const tomorrow = dayjs().add(1, 'day').startOf('day').toISOString()
  const yesterday = dayjs().subtract(1, 'day').startOf('day').toISOString()

  // Get current day of week and month for fair comparisons
  const currentDayOfWeek = dayjs().day()
  const currentDayOfMonth = dayjs().date()

  const currentWeekStart = dayjs().startOf('week').toISOString()
  const lastWeekStart = dayjs().subtract(1, 'week').startOf('week').toISOString()
  const lastWeekEquivalentEnd = dayjs()
    .subtract(1, 'week')
    .startOf('week')
    .add(currentDayOfWeek, 'day')
    .endOf('day')
    .toISOString()

  const currentMonthStart = dayjs().startOf('month').toISOString()
  const nextMonthStart = dayjs().add(1, 'month').startOf('month').toISOString()
  const lastMonthStart = dayjs().subtract(1, 'month').startOf('month').toISOString()
  const lastMonthEquivalentEnd = dayjs()
    .subtract(1, 'month')
    .startOf('month')
    .add(currentDayOfMonth - 1, 'day')
    .endOf('day')
    .toISOString()

  const metricsPipeline = [
    {
      $match: {
        bookingDate: { $exists: true }
      }
    },
    {
      $facet: {
        today: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(today).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(tomorrow).toDate() }] }
                ]
              },
              status: { $ne: 'Cancelled' }
            }
          },
          { $count: 'count' }
        ],
        yesterday: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(yesterday).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(today).toDate() }] }
                ]
              },
              status: { $ne: 'Cancelled' }
            }
          },
          { $count: 'count' }
        ],
        thisWeek: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(currentWeekStart).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(tomorrow).toDate() }] }
                ]
              },
              status: { $ne: 'Cancelled' }
            }
          },
          { $count: 'count' }
        ],
        lastWeek: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(lastWeekStart).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(lastWeekEquivalentEnd).toDate() }] }
                ]
              },
              status: { $ne: 'Cancelled' }
            }
          },
          { $count: 'count' }
        ],
        thisMonth: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(currentMonthStart).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(tomorrow).toDate() }] }
                ]
              },
              status: { $ne: 'Cancelled' }
            }
          },
          { $count: 'count' }
        ],
        lastMonth: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(lastMonthStart).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(lastMonthEquivalentEnd).toDate() }] }
                ]
              },
              status: { $ne: 'Cancelled' }
            }
          },
          { $count: 'count' }
        ],
        todayCancellations: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(today).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(tomorrow).toDate() }] }
                ]
              },
              status: 'Cancelled'
            }
          },
          { $count: 'count' }
        ],
        yesterdayCancellations: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(yesterday).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(today).toDate() }] }
                ]
              },
              status: 'Cancelled'
            }
          },
          { $count: 'count' }
        ],
        thisWeekCancellations: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(currentWeekStart).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(tomorrow).toDate() }] }
                ]
              },
              status: 'Cancelled'
            }
          },
          { $count: 'count' }
        ],
        lastWeekCancellations: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(lastWeekStart).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(lastWeekEquivalentEnd).toDate() }] }
                ]
              },
              status: 'Cancelled'
            }
          },
          { $count: 'count' }
        ],
        todayVirtual: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(today).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(tomorrow).toDate() }] }
                ]
              },
              isVirtualVisit: true,
              status: { $ne: 'Cancelled' }
            }
          },
          { $count: 'count' }
        ],
        yesterdayVirtual: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(yesterday).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(today).toDate() }] }
                ]
              },
              isVirtualVisit: true,
              status: { $ne: 'Cancelled' }
            }
          },
          { $count: 'count' }
        ],
        thisWeekVirtual: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(currentWeekStart).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(tomorrow).toDate() }] }
                ]
              },
              isVirtualVisit: true,
              status: { $ne: 'Cancelled' }
            }
          },
          { $count: 'count' }
        ],
        lastWeekVirtual: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(lastWeekStart).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(lastWeekEquivalentEnd).toDate() }] }
                ]
              },
              isVirtualVisit: true,
              status: { $ne: 'Cancelled' }
            }
          },
          { $count: 'count' }
        ],
        thisMonthCancellations: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(currentMonthStart).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(tomorrow).toDate() }] }
                ]
              },
              status: 'Cancelled'
            }
          },
          { $count: 'count' }
        ],
        lastMonthCancellations: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(lastMonthStart).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(lastMonthEquivalentEnd).toDate() }] }
                ]
              },
              status: 'Cancelled'
            }
          },
          { $count: 'count' }
        ],
        thisMonthVirtual: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(currentMonthStart).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(tomorrow).toDate() }] }
                ]
              },
              isVirtualVisit: true,
              status: { $ne: 'Cancelled' }
            }
          },
          { $count: 'count' }
        ],
        lastMonthVirtual: [
          {
            $match: {
              $expr: {
                $and: [
                  { $gte: ['$bookingDate', { $toDate: dayjs(lastMonthStart).toDate() }] },
                  { $lt: ['$bookingDate', { $toDate: dayjs(lastMonthEquivalentEnd).toDate() }] }
                ]
              },
              isVirtualVisit: true,
              status: { $ne: 'Cancelled' }
            }
          },
          { $count: 'count' }
        ]
      }
    }
  ]

  const fetchMetrics = async () => {
    setLoadingMetrics(true)
    try {
      const pipeline = [...metricsPipeline]

      if (isDoctor()) {
        pipeline[0].$match.medicalProfRef = state.auth.agiliteUser._id
      } else if (isNurse()) {
        const clinicIds = state.auth.agiliteUser.extraData.clinics || []
        pipeline[0].$match.$or = [{ medicalProfRef: state.auth.agiliteUser._id }, { clinicRef: { $in: clinicIds } }]
      } else if (isReception()) {
        const clinicIds = state.auth.agiliteUser.extraData.clinics || []
        pipeline[0].$match.clinicRef = { $in: clinicIds }
      } else if (isAdmin()) {
        console.log('Clinic IDS', state.clinics.data)
        console.log('state', state)
        const clinicIds = state.clinics.data.map((clinic) => clinic._id) || []
        pipeline[0].$match.clinicRef = { $in: clinicIds }
      }

      const tmpMetrics = await aggregateBookings(pipeline)

      const data = tmpMetrics[0] || {}

      const todayCount = data.today?.[0]?.count || 0
      const yesterdayCount = data.yesterday?.[0]?.count || 0
      const todayPercentChange = yesterdayCount === 0 ? 0 : ((todayCount - yesterdayCount) / yesterdayCount) * 100

      const thisWeekCount = data.thisWeek?.[0]?.count || 0
      const lastWeekCount = data.lastWeek?.[0]?.count || 0
      const weekPercentChange = lastWeekCount === 0 ? 0 : ((thisWeekCount - lastWeekCount) / lastWeekCount) * 100

      const thisMonthCount = data.thisMonth?.[0]?.count || 0
      const lastMonthCount = data.lastMonth?.[0]?.count || 0
      const monthPercentChange = lastMonthCount === 0 ? 0 : ((thisMonthCount - lastMonthCount) / lastMonthCount) * 100

      const todayCancellations = data.todayCancellations?.[0]?.count || 0
      const yesterdayCancellations = data.yesterdayCancellations?.[0]?.count || 0
      const thisWeekCancellations = data.thisWeekCancellations?.[0]?.count || 0
      const lastWeekCancellations = data.lastWeekCancellations?.[0]?.count || 0

      const cancellationsPercentChange =
        yesterdayCancellations === 0
          ? 0
          : ((todayCancellations - yesterdayCancellations) / yesterdayCancellations) * 100

      const todayVirtual = data.todayVirtual?.[0]?.count || 0
      const yesterdayVirtual = data.yesterdayVirtual?.[0]?.count || 0
      const thisWeekVirtual = data.thisWeekVirtual?.[0]?.count || 0
      const lastWeekVirtual = data.lastWeekVirtual?.[0]?.count || 0

      // Calculate utilization rates
      const todayUtilizationRate = todayCount > 0 ? (todayCount / (todayCount + todayCancellations)) * 100 : 0
      const yesterdayUtilizationRate =
        yesterdayCount > 0 ? (yesterdayCount / (yesterdayCount + yesterdayCancellations)) * 100 : 0
      const thisWeekUtilizationRate =
        thisWeekCount > 0 ? (thisWeekCount / (thisWeekCount + thisWeekCancellations)) * 100 : 0
      const lastWeekUtilizationRate =
        lastWeekCount > 0 ? (lastWeekCount / (lastWeekCount + lastWeekCancellations)) * 100 : 0

      const utilizationPercentChange =
        yesterdayUtilizationRate === 0
          ? 0
          : ((todayUtilizationRate - yesterdayUtilizationRate) / yesterdayUtilizationRate) * 100

      const weekUtilizationPercentChange =
        lastWeekUtilizationRate === 0
          ? 0
          : ((thisWeekUtilizationRate - lastWeekUtilizationRate) / lastWeekUtilizationRate) * 100

      // Calculate virtual rates
      const todayVirtualRate = todayCount > 0 ? (todayVirtual / todayCount) * 100 : 0
      const yesterdayVirtualRate = yesterdayCount > 0 ? (yesterdayVirtual / yesterdayCount) * 100 : 0
      const thisWeekVirtualRate = thisWeekCount > 0 ? (thisWeekVirtual / thisWeekCount) * 100 : 0
      const lastWeekVirtualRate = lastWeekCount > 0 ? (lastWeekVirtual / lastWeekCount) * 100 : 0

      const virtualPercentChange =
        yesterdayVirtualRate === 0 ? 0 : ((todayVirtualRate - yesterdayVirtualRate) / yesterdayVirtualRate) * 100

      const weekVirtualPercentChange =
        lastWeekVirtualRate === 0 ? 0 : ((thisWeekVirtualRate - lastWeekVirtualRate) / lastWeekVirtualRate) * 100

      const thisMonthCancellations = data.thisMonthCancellations?.[0]?.count || 0
      const lastMonthCancellations = data.lastMonthCancellations?.[0]?.count || 0
      const thisMonthVirtual = data.thisMonthVirtual?.[0]?.count || 0
      const lastMonthVirtual = data.lastMonthVirtual?.[0]?.count || 0

      // Calculate monthly utilization rate
      const thisMonthUtilizationRate =
        thisMonthCount > 0 ? (thisMonthCount / (thisMonthCount + thisMonthCancellations)) * 100 : 0
      const lastMonthUtilizationRate =
        lastMonthCount > 0 ? (lastMonthCount / (lastMonthCount + lastMonthCancellations)) * 100 : 0
      const monthUtilizationPercentChange =
        lastMonthUtilizationRate === 0
          ? 0
          : ((thisMonthUtilizationRate - lastMonthUtilizationRate) / lastMonthUtilizationRate) * 100

      // Calculate monthly virtual rate
      const thisMonthVirtualRate = thisMonthCount > 0 ? (thisMonthVirtual / thisMonthCount) * 100 : 0
      const lastMonthVirtualRate = lastMonthCount > 0 ? (lastMonthVirtual / lastMonthCount) * 100 : 0
      const monthVirtualPercentChange =
        lastMonthVirtualRate === 0 ? 0 : ((thisMonthVirtualRate - lastMonthVirtualRate) / lastMonthVirtualRate) * 100

      const processedMetrics = {
        today: { count: todayCount, percentChange: todayPercentChange },
        thisWeek: { count: thisWeekCount, percentChange: weekPercentChange },
        thisMonth: { count: thisMonthCount, percentChange: monthPercentChange },
        utilizationRate: {
          daily: { rate: todayUtilizationRate, percentChange: utilizationPercentChange },
          weekly: { rate: thisWeekUtilizationRate, percentChange: weekUtilizationPercentChange },
          monthly: { rate: thisMonthUtilizationRate, percentChange: monthUtilizationPercentChange }
        },
        cancellations: {
          daily: { count: todayCancellations, percentChange: cancellationsPercentChange },
          weekly: {
            count: thisWeekCancellations,
            percentChange:
              lastWeekCancellations === 0
                ? 0
                : ((thisWeekCancellations - lastWeekCancellations) / lastWeekCancellations) * 100
          },
          monthly: {
            count: thisMonthCancellations,
            percentChange:
              lastMonthCancellations === 0
                ? 0
                : ((thisMonthCancellations - lastMonthCancellations) / lastMonthCancellations) * 100
          }
        },
        virtualConsults: {
          daily: { rate: todayVirtualRate, percentChange: virtualPercentChange },
          weekly: { rate: thisWeekVirtualRate, percentChange: weekVirtualPercentChange },
          monthly: { rate: thisMonthVirtualRate, percentChange: monthVirtualPercentChange }
        }
      }

      setMetrics(processedMetrics)
    } catch (error) {
      console.error('Error fetching metrics:', error)
    } finally {
      setLoadingMetrics(false)
    }
  }
  const fetchAppointments = async () => {
    try {
      let qry = {}
      setLoadingApps(true)
      if (isDoctor()) {
        qry = {
          medicalProfRef: state.auth.agiliteUser._id
        }
      }
      if (isNurse()) {
        qry = {
          clinicRef: state.auth.agiliteUser.extraData.clinics[0]
        }
      }
      if (isReception()) {
        qry = {
          clinicRef: state.auth.agiliteUser.extraData.clinics[0]
        }
      }
      if (isAdmin()) {
        qry = {
          clinicRef: { $in: state.clinics.data.map((clinic) => clinic._id) }
        }
      }
      qry.$and = [{ status: { $ne: 'cancelled' } }, { status: { $ne: 'completed' } }]
      const data = await getAppointments(state, qry)

      dispatch(bookingsReducer.actions.setDashboardRecords(data))
    } catch (error) {
      console.error('Error fetching appointments:', error)
    } finally {
      setLoadingApps(false)
    }
  }
  return (
    <Layout>
      <Content className={styles.container}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} style={{ width: '100%' }}>
            <AppointmentOverview
              refreshMetrics={fetchMetrics}
              refreshView={fetchAppointments}
              metrics={metrics}
              loadingMetrics={loadingMetrics}
              loadingApps={loadingApps}
            />
          </Col>
          <Col xs={24} md={12} style={{ width: '100%' }}>
            <Tasks />
            <DailyAppointments />
          </Col>
          {/* <Col xs={24}>
            <RevenueSummary />
          </Col> */}
        </Row>
      </Content>
    </Layout>
  )
}

const NewDoctorDashboard = () => {
  return (
    <NotificationsProvider>
      <DashboardContent />
    </NotificationsProvider>
  )
}

export default NewDoctorDashboard
