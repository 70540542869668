import React, { useState, useEffect } from 'react'
import {
  Card,
  Button,
  Avatar,
  Tag,
  List,
  Row,
  Col,
  Badge,
  message,
  Space,
  Typography,
  Skeleton,
  theme,
  Input
} from 'antd'
import {
  ClockCircleOutlined,
  FileTextOutlined,
  VideoCameraOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  UserOutlined,
  CalendarOutlined,
  PercentageOutlined,
  CloseCircleOutlined,
  BellOutlined,
  RobotOutlined,
  PlayCircleOutlined,
  ReloadOutlined
} from '@ant-design/icons'
import { useNotifications } from '../context/NotificationsContext'
import NotificationsPopover from './NotificationsPopover'
import WebRTCAssistant from '../../../../P AI Voice Assistant/lib/components/WebRTCAssistant'
import { getDashboardContext } from './DashBoard AI/config/aiAssistantContexts'
import { getDashboardTools } from './DashBoard AI/utils/dashboardAITools'
import LetterModal from './DashBoard AI/components/LetterModal'
import styles from '../styles/AppointmentOverview.module.css'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { executeBooking, getBookingState } from '../../../../Bookings/utils/bpm-utils'
import { updateAllBookingsState } from '../../../../lib/booking-utils'
import { updateBooking } from '../../../../Bookings/utils/utils'
import { readSystemUsers } from '../../../../Admin/system-users/utils/utils'
import { handleError } from '../../../../lib/utils'
import PatientExaminationForm from '../../../../Bookings/components/ExaminationWrapper'
import CoreEnums from '../../../../../core/utils/enums'
import coreReducer from '../../../../../core/utils/reducer'
import { isDoctor, isNurse } from '../../../../lib/profile-utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faSync } from '@fortawesome/free-solid-svg-icons'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'

const { Title, Text } = Typography

const METRIC_CONFIGS = {
  today: {
    icon: <CalendarOutlined />,
    label: 'Today',
    suffix: ''
  },
  thisWeek: {
    icon: <CalendarOutlined />,
    label: 'This Week',
    suffix: ''
  },
  thisMonth: {
    icon: <CalendarOutlined />,
    label: 'This Month',
    suffix: ''
  },
  utilizationRate: {
    icon: <PercentageOutlined />,
    label: 'Utilization',
    suffix: '%'
  },
  cancellations: {
    icon: <CloseCircleOutlined />,
    label: 'Cancelled',
    suffix: ''
  },
  virtualConsults: {
    icon: <VideoCameraOutlined />,
    label: 'Virtual',
    suffix: '%'
  }
}

const getStatusColor = (status) => {
  const colors = {
    RECEPTION: '#1890ff',
    'CHECK-IN': '#00bcd4',
    DIAGNOSIS: '#ffc107'
  }
  return colors[status] || '#1890ff'
}

const AppointmentOverview = ({ refreshView, metrics, loadingMetrics, loadingApps, refreshMetrics }) => {
  const appointments = useSelector((state) => state.bookings.dashboardData)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [letterData, setLetterData] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [summaryData, setSummaryData] = useState({
    upcoming: []
  })

  // Process appointments when component mounts or appointments change
  useEffect(() => {
    // Filter today's appointments only
    const upcoming = appointments
      .filter((apt) => {
        const aptDate = dayjs(apt.bookingDate)
        const isToday = aptDate.isSame(dayjs(), 'day')

        // Apply search filter if searchTerm exists
        if (searchTerm) {
          const searchQuery = searchTerm.toLowerCase()
          const patientName = `${apt.patientRecord?.firstName} ${apt.patientRecord?.lastName}`.toLowerCase()
          const medicalProfName = apt.medicalProfName?.toLowerCase() || ''
          return isToday && (patientName.includes(searchQuery) || medicalProfName.includes(searchQuery))
        }

        return isToday
      })
      .sort((a, b) => {
        // First compare dates
        const dateA = dayjs(a.bookingDate)
        const dateB = dayjs(b.bookingDate)
        const dateDiff = dateA.diff(dateB)

        // If same date, compare times
        if (dateDiff === 0) {
          const timeA = a.startTime.split(':').map(Number)
          const timeB = b.startTime.split(':').map(Number)
          return timeA[0] * 60 + timeA[1] - (timeB[0] * 60 + timeB[1])
        }

        return dateDiff
      })

    // Update summary data
    setSummaryData({
      upcoming
    })
  }, [appointments, searchTerm])

  const renderTrendIndicator = (trend, percent) => {
    if (percent === 0) return null

    const color = trend === 'up' ? '#52c41a' : '#ff4d4f'
    return (
      <Space>
        {trend === 'up' ? <ArrowUpOutlined style={{ color }} /> : <ArrowDownOutlined style={{ color }} />}
        <Text style={{ color }}>{Math.abs(percent)}%</Text>
      </Space>
    )
  }

  const renderMetricBox = (key, config) => {
    let value = 0
    let percentChange = 0
    let trend = 'up'

    // Get value from metrics based on key
    if (metrics && metrics[key]) {
      if (key === 'utilizationRate') {
        value = metrics[key].daily?.rate || 0
        percentChange = metrics[key].daily?.percentChange || 0
      } else if (key === 'virtualConsults') {
        value = metrics[key].daily?.rate || 0
        percentChange = metrics[key].daily?.percentChange || 0
      } else if (key === 'cancellations') {
        value = metrics[key].daily?.count || 0
        percentChange = metrics[key].daily?.percentChange || 0
      } else {
        value = metrics[key].count || 0
        percentChange = metrics[key].percentChange || 0
      }
      trend = percentChange >= 0 ? 'up' : 'down'
    }

    return (
      <Card size='small' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
        <Space direction='horizontal' size='middle' align='start'>
          <div>{config.icon}</div>
          <Space direction='vertical' size={0}>
            <Title level={3} style={{ margin: 0 }}>
              {value.toFixed(1)}
              {config.suffix}
            </Title>
            <Text type='secondary'>{config.label}</Text>
            {renderTrendIndicator(trend, Math.abs(percentChange).toFixed(1))}
          </Space>
        </Space>
      </Card>
    )
  }

  const authState = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [openingApp, setOpeningApp] = useState(false)
  const handleStartConsult = async (record, skipScreening) => {
    let mainMemberProfile = null
    let workflowHistory = {}
    let processRecord = null
    let newRecord = null

    setOpeningApp(true)

    try {
      if (skipScreening) {
        workflowHistory = await getBookingState([record.processRef])
        processRecord = await executeBooking(
          record.clinicRecord.bpmKey,
          record.processRef,
          'submit',
          `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`,
          workflowHistory.key
        )
        newRecord = await updateBooking({ status: processRecord.processStage }, { _id: record._id })
        workflowHistory = processRecord
        workflowHistory.history.splice(0, 1)
        updateAllBookingsState(newRecord)
      } else {
        if (record.processRef) {
          workflowHistory = await getBookingState([record.processRef])
          workflowHistory.history.splice(0, 1)
        }
      }
      if (record.patientRecord.mainMemberId) {
        mainMemberProfile = await readSystemUsers({ _id: record.patientRecord.mainMemberId })
      }
      dispatch(
        coreReducer.actions.addTab({
          key: `${CoreEnums.tabKeys.MY_BOOKINGS}_${record._id}`,
          closable: true,
          label: `Booking: ${record.patientName}`,
          children: (
            <PatientExaminationForm
              data={{
                ...record,
                ...newRecord,
                mainMemberProfile: mainMemberProfile ? mainMemberProfile[0] : undefined
              }}
              refreshView={refreshView}
              workflowHistory={workflowHistory}
            />
          )
        })
      )
    } catch (e) {
      message.error(handleError(e, true))
    }
    setOpeningApp(false)
  }

  const clinicServiceState = useSelector((state) => state.services.data)
  const virtualServiceState = useSelector((state) => state.virtualServices.data)

  const getService = (service) => {
    const tmpService =
      clinicServiceState.find((s) => s._id === service) || virtualServiceState.find((s) => s._id === service)
    if (tmpService) {
      return tmpService?.name || 'Unknown service'
    } else {
      return 'Unknown service'
    }
  }
  const { token } = theme.useToken()
  const renderAppointmentItem = (appointment) => {
    return (
      <List.Item>
        <div style={{ width: '100%' }}>
          <Space wrap style={{ gap: 16, width: '100%', justifyContent: 'space-between' }}>
            <Space style={{ gap: 16 }}>
              <Space direction='vertical' size={0}>
                <Space>
                  <ClockCircleOutlined />
                  <Text>{appointment.startTime}</Text>
                </Space>
                <Text>
                  at{' '}
                  <span style={{ color: getStatusColor(appointment.status) }}>
                    {appointment.status.charAt(0).toUpperCase() + appointment.status.slice(1).toLowerCase()}
                  </span>
                </Text>
              </Space>
              <Space size='middle'>
                <Avatar size={40} src={appointment.patientRecord?.profileImage}>
                  {appointment.patientRecord?.firstName?.[0]}
                  {appointment.patientRecord?.lastName?.[0]}
                </Avatar>
                <Space direction='vertical' size={0}>
                  <Text strong>{`${appointment.patientRecord?.firstName} ${appointment.patientRecord?.lastName}`}</Text>
                  <Text type='secondary'>Dr. {appointment.medicalProfName}</Text>
                  <Space>
                    <Text type='secondary'>{getService(appointment.service)}</Text>
                    {appointment.isVirtualVisit && <VideoCameraOutlined />}
                  </Space>
                </Space>
              </Space>
            </Space>

            {(isDoctor() || isNurse()) && (
              <Button
                style={{ justifySelf: 'right', backgroundColor: token.colorSuccess }}
                type='primary'
                icon={appointment.isVirtualVisit ? <VideoCameraOutlined /> : <PlayCircleOutlined />}
                onClick={() => handleStartConsult(appointment)}
              >
                Start Consult
              </Button>
            )}
          </Space>
        </div>
      </List.Item>
    )
  }

  const handleAIAction = (action) => {
    if (action === 'openLetterModal') {
      const newLetterData = {
        title: 'Sample Letter',
        recipient: 'Sample Recipient',
        date: new Date().toLocaleDateString(),
        subject: 'Sample Subject',
        content:
          'This is a sample letter content.\n\nPlease use voice commands to generate a real letter with the AI assistant.',
        signature: 'Dr. Alice'
      }
      setLetterData(newLetterData)
      setIsModalVisible(true)
    }
  }

  const handleAIWarning = (warning) => {}

  const handleModalClose = () => {
    setIsModalVisible(false)
    setLetterData(null)
  }

  const renderAppointmentSkeleton = () => {
    return (
      <List.Item style={{ padding: '16px 0', borderBottom: '1px solid #f0f0f0' }}>
        <Row style={{ width: '100%' }} align='middle' justify='space-between' wrap={false}>
          <Col span={24}>
            <Space style={{ gap: 16, justifyContent: 'space-between', width: '100%' }}>
              <Space style={{ gap: 16 }}>
                <Space direction='vertical' size={4} style={{ maxWidth: '200px' }}>
                  <Skeleton.Input active size='small' style={{ width: '100%' }} />
                  <Skeleton.Input active size='small' style={{ width: '80%' }} />
                </Space>
                <Space>
                  <Skeleton.Avatar active size={40} style={{ backgroundColor: '#f5f5f5' }} />
                  <Space direction='vertical' size={4} style={{ maxWidth: '200px' }}>
                    <Skeleton.Input active size='small' style={{ width: '100%' }} />
                    <Skeleton.Input active size='small' style={{ width: '80%' }} />
                  </Space>
                </Space>
              </Space>
              <Space>
                <Skeleton.Button active size='small' style={{ width: 120 }} />
              </Space>
            </Space>
          </Col>
        </Row>
      </List.Item>
    )
  }

  const renderMetricSkeleton = () => {
    return (
      <Card size='small' bodyStyle={{ padding: '12px' }} style={{ height: '100%' }}>
        <Space direction='horizontal' size='middle' align='start'>
          <Skeleton.Avatar active size='small' />
          <Space direction='vertical' size={0}>
            <Skeleton.Button active size='small' style={{ width: 80 }} />
            <Skeleton.Button active size='small' style={{ width: 60 }} />
            <Skeleton.Button active size='small' style={{ width: 40 }} />
          </Space>
        </Space>
      </Card>
    )
  }

  return (
    <Card bodyStyle={{ padding: '16px' }}>
      <Space direction='vertical' size='middle' style={{ width: '100%' }}>
        {/* Header Section */}
        <Row justify='space-between' align='middle'>
          <Col>
            <Space direction='vertical' size={0}>
              <Title level={2} style={{ margin: 0 }}>
                Hello,{' '}
                {authState.agiliteUser.extraData.role.type === 'medical_professional'
                  ? isNurse()
                    ? 'Nurse '
                    : 'Dr. '
                  : ''}
                {authState.agiliteUser.firstName}
              </Title>
              <Text type='secondary'>
                {isDoctor() || isNurse()
                  ? 'See the person who needs your help!'
                  : 'See the person who needs your help!'}
              </Text>
            </Space>
          </Col>
          <Col>
            <Space size='middle'>
              <WebRTCAssistant
                systemContext={getDashboardContext('Dr. Alice')}
                customTools={getDashboardTools()}
                onAction={handleAIAction}
                onWarning={handleAIWarning}
                buttonProps={{
                  type: 'text',
                  icon: <RobotOutlined />
                }}
              />
              <NotificationsPopover />
              <Avatar size={40} src='/assets/images/doctor-profile.jpg' icon={<UserOutlined />} />
            </Space>
          </Col>
        </Row>

        {/* Appointments Section */}
        <Card bodyStyle={{ padding: '12px' }}>
          <Space direction='vertical' style={{ width: '100%' }} size='small'>
            <Row justify='space-between' align='middle'>
              <Col>
                <Title level={4} style={{ margin: 0 }}>
                  Today's Appointments
                </Title>
              </Col>
              <Col>
                <Space>
                  <Input.Search
                    placeholder='Search patient or doctor'
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ width: 250 }}
                    allowClear
                  />
                  <Button
                    onClick={() => {
                      refreshView()
                      refreshMetrics()
                    }}
                    type='primary'
                    icon={<FontAwesomeIcon icon={faSync} />}
                  >
                    Sync
                  </Button>
                </Space>
              </Col>
            </Row>
            {loadingApps ? (
              <List style={{ width: '100%' }}>
                {[1, 2].map((key) => (
                  <React.Fragment key={key}>{renderAppointmentSkeleton()}</React.Fragment>
                ))}
              </List>
            ) : (
              <List
                dataSource={summaryData.upcoming}
                renderItem={renderAppointmentItem}
                style={{ maxHeight: '500px', overflowY: 'auto' }}
              />
            )}
          </Space>
        </Card>

        {/* Metrics Section */}
        <Card bodyStyle={{ padding: '12px' }}>
          <Space direction='vertical' style={{ width: '100%' }} size='small'>
            <Title level={4} style={{ margin: 0 }}>
              Appointments Overview
            </Title>
            {loadingMetrics ? (
              <Row gutter={[12, 12]}>
                {[1, 2, 3, 4, 5, 6].map((key) => (
                  <Col xs={24} sm={12} xl={8} key={key}>
                    {renderMetricSkeleton()}
                  </Col>
                ))}
              </Row>
            ) : (
              <Row gutter={[12, 12]}>
                {Object.entries(METRIC_CONFIGS).map(([key, config]) => (
                  <Col xs={24} sm={12} xl={8} key={key}>
                    {renderMetricBox(key, config)}
                  </Col>
                ))}
              </Row>
            )}
          </Space>
        </Card>
      </Space>

      <LetterModal isVisible={isModalVisible} onClose={handleModalClose} letterData={letterData} />
    </Card>
  )
}

export default AppointmentOverview
