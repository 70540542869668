import CoreEnums from '../../core/utils/enums'
import coreReducer from '../../core/utils/reducer'
import store from '../../store'
import SystemUsersWrapper from '../Admin/system-users/components/SystemUsersWrapper'
import InvoicesList from './components/invoices-list'
import MedicalAidPaymentList from './components/medical-aid-payment-list'
import Reports from './components/reports'

export const handleDashboardCardClick = (key) => {
  const tabContent = {
    key: null,
    closable: true,
    label: null,
    children: null
  }

  switch (key) {
    case CoreEnums.tabKeys.INVOICES:
      tabContent.key = CoreEnums.tabKeys.INVOICES
      tabContent.label = CoreEnums.tabTitles.INVOICES
      tabContent.children = <InvoicesList />
      break
    case CoreEnums.tabKeys.PATIENTS:
      tabContent.key = CoreEnums.tabKeys.PATIENTS
      tabContent.label = CoreEnums.tabTitles.PATIENTS
      tabContent.children = <SystemUsersWrapper viewType='Patient' type='patient' />
      break
    case CoreEnums.tabKeys.REMITTANCES:
      tabContent.key = CoreEnums.tabKeys.REMITTANCES
      tabContent.label = CoreEnums.tabTitles.REMITTANCES
      tabContent.children = <MedicalAidPaymentList />
      break
    case CoreEnums.tabKeys.BILLING_REPORTS:
      tabContent.key = CoreEnums.tabKeys.BILLING_REPORTS
      tabContent.label = CoreEnums.tabTitles.BILLING_REPORTS
      tabContent.children = <Reports />
      break
    default:
      break
  }

  if (key) {
    store.dispatch(coreReducer.actions.addTab(tabContent))
  }
}
