export const MacroTrackerEnums = {
  profileKeys: {
    MACRO_TRACKER: 'macro_tracker',
    MACRO_TRACKER_SAVED_MEALS: 'macro_tracker_saved_meals',
    MACRO_TRACKER_META_DATA: 'macro_tracker_meta_data',
    MACRO_TRACKER_RECIPES: 'macro_tracker_recipes'
  },
  routeKeys: {
    CREATE: 'create',
    READ: 'read',
    UPDATE: 'update',
    UPSERT: 'upsert',
    DELETE: 'delete',
    DELETE_BY_ID: 'delete_by_id'
  }
}
