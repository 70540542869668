import dayjs from 'dayjs'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { handleError } from '../../../lib/utils'
import { message } from 'antd'
import { MedPraxProductSearch } from '../../../Scripting/utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import patMedHistReducer from '../utils/reducer'
import { updateGeneralMeds } from '../api/api'

const useGeneralMeds = ({ setSaving }) => {
  const dispatch = useDispatch()
  const { _id: recordId } = useSelector((state) => state.patMedHist.data)
  const medsState = useSelector((state) => state.patMedHist?.data?.meds?.list || [])
  const [nappiLoading, setNappiLoading] = useState(false)
  const [medsList, setMedsList] = useState(medsState || [])
  const [nappiSearchFilter, setNappiSearchFilter] = useState('')
  const [nappiData, setNappiData] = useState([])
  const [showMedicationModal, setShowMedicationModal] = useState(false)
  const [editingMedication, setEditingMedication] = useState(null)
  const [tempMedDates, setTempMedDates] = useState({ startDate: null, endDate: null })

  const handleSubmit = async () => {
    setSaving(true)
    try {
      const newRecord = await updateGeneralMeds(recordId, medsList)
      dispatch(patMedHistReducer.actions.updateState(newRecord))
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setSaving(false)
    }
  }

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await MedPraxProductSearch(searchQuery)
        setNappiData(tmpData)
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setNappiLoading(false)
  }

  useEffect(() => {
    setNappiLoading(true)
    debouncedNappiFilter(nappiSearchFilter)
    // eslint-disable-next-line
  }, [nappiSearchFilter])

  const debouncedNappiFilter = useCallback(
    debounce((value) => {
      getNappiData(value)
    }, 1000),
    []
  )

  const handleRemoveMedication = (medIndex) => {
    let tmpMedsList = [...medsList]
    tmpMedsList.splice(medIndex, 1)
    setMedsList(tmpMedsList)
  }

  const handleAddMedication = (value, dates) => {
    let tmpValue = typeof value === 'string' ? JSON.parse(value) : value
    setMedsList((prevMedsList) => [
      ...prevMedsList,
      {
        med: tmpValue,
        startDate: dates.startDate,
        endDate: dates.endDate || null
      }
    ])
    setShowMedicationModal(false)
    setTempMedDates({ startDate: null, endDate: null })
  }

  const handleUpdateMedication = (medIndex, dates) => {
    let tmpMedsList = [...medsList]
    tmpMedsList[medIndex] = {
      ...tmpMedsList[medIndex],
      startDate: dates.startDate,
      endDate: dates.endDate
    }
    setMedsList(tmpMedsList)
    setShowMedicationModal(false)
    setEditingMedication(null)
    setTempMedDates({ startDate: null, endDate: null })
  }

  return {
    medsList,
    nappiData,
    nappiLoading,
    setNappiSearchFilter,
    handleAddMedication,
    handleUpdateMedication,
    handleRemoveMedication,
    handleSubmit,
    setNappiData,
    showMedicationModal,
    setShowMedicationModal,
    editingMedication,
    setEditingMedication,
    tempMedDates,
    setTempMedDates
  }
}

export default useGeneralMeds
