import Theme from './theme'

import { handleMenuAction, handleTabAction } from './controller'
import { leftMenuItems, rightMenuItems } from '../../setup'
import Router from '../../custom/Auth/components/router'
import ToolbarCustomMenus from '../../custom/Auth/components/ToolbarComponents/index'
import CoreEnums from './enums'

export const coreState = () => {
  return {
    entity: null,
    noEntityFound: false,
    sideBarExpanded: false,
    tourOpen: false,
    tourStep: 0,
    signOutDisabled: false,
    hiddenLeftMenuItems: [],
    title: process.env.REACT_APP_NAME,
    rootContent: Router,
    theme: Theme,
    leftMenu: {
      leftMenuTitle: CoreEnums.menuTitles.LEFT_MENU,
      leftMenuEnabled: true,
      menuItems: () => leftMenuItems(),
      visible: false,
      onLeftMenuOpen: () => handleMenuAction(CoreEnums.menuActions.LEFT),
      onLeftMenuClose: () => handleMenuAction(CoreEnums.menuActions.LEFT),
      expandedMenuItems: []
    },
    rightMenu: {
      rightMenuTitle: CoreEnums.menuTitles.RIGHT_MENU,
      rightMenuEnabled: false,
      menuItems: () => rightMenuItems,
      visible: false,
      onRightMenuOpen: () => handleMenuAction(CoreEnums.menuActions.RIGHT),
      onRightMenuClose: () => handleMenuAction(CoreEnums.menuActions.RIGHT),
      expandedMenuItems: []
    },
    toolbar: {
      enabled: true,
      title: process.env.REACT_APP_NAME,
      customMenus: {
        content: <ToolbarCustomMenus />
      }
    },
    tabNavigation: {
      enabled: false,
      hidden: true,
      rootTabKey: 'home',
      rootTabTitle: 'Dashboard',
      activeKey: 'home',
      lastActiveKey: [],
      animated: false,
      tabs: [],
      onTabChange: (key) => handleTabAction(CoreEnums.tabActions.CHANGE, key),
      onTabClose: (key) => handleTabAction(CoreEnums.tabActions.CLOSE, key)
    },
    breadcrumbs: [],
    editedTabs: []
  }
}
