import React, { useState } from 'react'
import { Modal, Input, Table, Space, Avatar, theme, Empty } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser } from '@fortawesome/free-solid-svg-icons'
import CustomLoadingIcon from '../../reusable-components/CustomLoadingIcon'
import dayjs from 'dayjs'

const PatientSearchModal = ({ 
  visible, 
  onCancel, 
  onSearch, 
  loading, 
  searchResults, 
  onSelectPatient 
}) => {
  const { token } = theme.useToken()
  const [hasSearched, setHasSearched] = useState(false)

  const handleSearch = (value) => {
    setHasSearched(true)
    onSearch(value)
  }

  const calculateAge = (dateOfBirth) => {
    if (!dateOfBirth) return 'N/A'
    return dayjs().diff(dayjs(dateOfBirth), 'year')
  }

  const CustomEmptyState = () => (
    <Empty
      image={
        <FontAwesomeIcon 
          icon={faSearch} 
          style={{ 
            fontSize: '48px', 
            color: token.colorTextQuaternary,
            marginBottom: 16
          }} 
        />
      }
      description={
        <div style={{ textAlign: 'center' }}>
          <h3 style={{ color: token.colorTextSecondary, marginBottom: 8 }}>
            {hasSearched ? 'No Patients Found' : 'Search for a Patient'}
          </h3>
          <p style={{ color: token.colorTextDescription }}>
            {hasSearched 
              ? 'Try searching with different terms'
              : 'Enter a patient name or ID number above to begin'}
          </p>
        </div>
      }
    />
  )

  return (
    <Modal
      title={
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '8px',
          color: token.colorTextHeading,
          fontSize: '18px'
        }}>
          <FontAwesomeIcon icon={faSearch} />
          <span>Search Patients</span>
        </div>
      }
      open={visible}
      width={800}
      onCancel={() => {
        setHasSearched(false)
        onCancel()
      }}
      footer={null}
      destroyOnClose
    >
      <div style={{ marginBottom: 24 }}>
        <Input.Search
          placeholder="Enter patient name, ID number, or contact details..."
          onChange={(e) => handleSearch(e.target.value)}
          style={{ 
            width: '100%',
            marginBottom: 16 
          }}
          size="large"
          allowClear
          loading={loading}
        />
        
        <div style={{ position: 'relative', minHeight: 200 }}>
          <Table
            loading={{
              spinning: loading,
              indicator: (
                <div style={{ 
                  position: 'absolute',
                  left: '50%',
                  top: '100px',
                  transform: 'translateX(-50%)'
                }}>
                  <CustomLoadingIcon loadingText='' />
                </div>
              )
            }}
            dataSource={searchResults}
            showHeader={hasSearched}
            columns={[
              { 
                title: 'Patient Name',
                key: 'name',
                render: (record) => (
                  <Space>
                    <Avatar 
                      icon={<FontAwesomeIcon icon={faUser} />}
                      style={{ 
                        backgroundColor: token.colorPrimary,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    />
                    <span style={{ fontWeight: 500 }}>{`${record.firstName} ${record.lastName}`}</span>
                  </Space>
                )
              },
              { 
                title: 'Date of Birth',
                key: 'dob',
                render: (record) => (
                  <span>
                    {record.dateOfBirth ? dayjs(record.dateOfBirth).format('DD MMMM YYYY') : 'N/A'}
                  </span>
                )
              },
              { 
                title: 'Age',
                key: 'age',
                render: (record) => (
                  <span>
                    {record.dateOfBirth ? `${calculateAge(record.dateOfBirth)} years` : 'N/A'}
                  </span>
                )
              }
            ]}
            onRow={(record) => ({
              onClick: () => onSelectPatient(record),
              style: { 
                cursor: 'pointer',
                transition: 'background-color 0.3s',
                ':hover': {
                  backgroundColor: token.colorBgTextHover
                }
              }
            })}
            pagination={false}
            locale={{ emptyText: <CustomEmptyState /> }}
            style={{ 
              borderRadius: 8,
              overflow: 'hidden'
            }}
            size="middle"
          />
        </div>
      </div>
    </Modal>
  )
}

export default PatientSearchModal 