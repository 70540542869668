import React from 'react'
import { Col, Result, Spin } from 'antd'
import CustomRow from '../../reusable-components/CustomRow'
import { useSelector } from 'react-redux'

const CheckinSuccess = ({
  setView,
  setBookingId,
  setBookingService,
  setCurrentSelection,
  setPatientInput,
  setPatientSelection,
  MetaLogo
}) => {
  const coreState = useSelector((state) => state.core)
  setTimeout(() => {
    setBookingId(null)
    setBookingService(null)
    setCurrentSelection(null)
    setPatientInput(null)
    setPatientSelection([])
    setView(null)
  }, 5000)

  return (
    <CustomRow
      gutter={[12, 12]}
      style={{ maxWidth: '100%', alignItems: 'center', minHeight: '100vh' }}
      justify='center'
    >
      <Col style={{ width: 1050, maxWidth: '90%' }}>
        <CustomRow className='basic-card'>
          <Col span={24}>
            <center>
              <img src={coreState.entity.entityLogo} alt='Meta Logo' style={{ width: 341, maxWidth: '100%' }} />
            </center>
            <Result
              status='success'
              title={
                <h1>
                  You have successfully Checked-in, <br /> a Nurse will assist you shortly
                </h1>
              }
              extra={[
                <Col span={24}>
                  <center>
                    Redirecting <br />
                    <Spin size='large' />
                  </center>
                </Col>
              ]}
            />
          </Col>
        </CustomRow>
      </Col>
    </CustomRow>
  )
}

export default CheckinSuccess
