import React from 'react'
import { Typography, Row, Col } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faClock,
  faBowlFood,
  faGlassWater
} from '@fortawesome/free-solid-svg-icons'
import '../styling/PreparationRequirements.css'

const { Title, Text } = Typography

const RequirementIcon = ({ icon, text, type }) => (
  <div className="requirement-item">
    <div className={`requirement-icon ${type}-icon`}>
      <FontAwesomeIcon icon={icon} />
    </div>
    <Text>{text}</Text>
  </div>
)

const PreparationRequirements = ({ requirements }) => {
  const { noFood, noDrink, duration, additionalNotes } = requirements

  return (
    <section className="test-detail-section test-prep-card">
      <Title level={4}>Preparation Requirements</Title>
      
      <div className="test-prep-content">
        <Row gutter={[16, 16]} className="test-prep-requirements">
          {noFood && (
            <Col span={8}>
              <RequirementIcon icon={faBowlFood} text="No Food" type="food" />
            </Col>
          )}
          {noDrink && (
            <Col span={8}>
              <RequirementIcon icon={faGlassWater} text="No Drinks" type="drink" />
            </Col>
          )}
          {duration !== '0' && (
            <Col span={8}>
              <RequirementIcon icon={faClock} text={`${duration}h Fast`} type="duration" />
            </Col>
          )}
        </Row>

        {additionalNotes && (
          <div className="test-prep-notes">
            <Text>{additionalNotes}</Text>
          </div>
        )}
      </div>
    </section>
  )
}

export default PreparationRequirements 