import dayjs from 'dayjs'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { alreadyEnrolled, enrollPatient } from '../../../utils/utils'
import { message } from 'antd'
import { handleError } from '../../../../lib/utils'
import { readSystemUsers } from '../../../../Admin/system-users/utils/utils'

const useEnrollmentSearch = ({ onAfterSuccess, template, setModalOpen }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [patient, setPatient] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [enrolling, setEnrolling] = useState(false)
  const [teamMembers, setTeamMembers] = useState([])
  const [searching, setSearching] = useState(false)
  const [enrollmentOverride, setEnrollmentOverride] = useState(dayjs())

  useEffect(() => {
    handlePatientSearch()
    // eslint-disable-next-line
  }, [searchQuery])

  const handleReset = () => {
    setPatient(null)
    setSearchQuery('')
    setSearchResults([])
    setModalOpen(false)
  }

  const handleEnrollPatient = async () => {
    let tmpResponse = null

    setEnrolling(true)
    try {
      const enrolled = await alreadyEnrolled(patient._id, template._id)
      if (enrolled) {
        return message.error('Patient already enrolled.')
      }
      tmpResponse = await enrollPatient(patient, teamMembers, template, enrollmentOverride)
      onAfterSuccess({ ...tmpResponse, patientProfile: patient })
      handleReset()
    } catch (error) {
      message.error(handleError(error))
    }
    setEnrolling(false)
  }
  const handlePatientSearch = async () => {
    try {
      let tmpUsers = []
      let qry = { 'extraData.role.type': 'patient' }
      setSearching(true)
      try {
        if (searchQuery) {
          const searchTerms = searchQuery.trim().toLowerCase().split(/\s+/)

          // Prepare regex patterns for partial matching
          const regexPatterns = searchTerms.map((term) => new RegExp(term, 'i'))
          qry = {
            ...qry,
            $or: [
              // Match against concatenated firstName and lastName with partial regexes
              {
                $expr: {
                  $regexMatch: {
                    input: {
                      $concat: [{ $toLower: '$firstName' }, ' ', { $toLower: '$lastName' }]
                    },
                    regex: searchTerms.map((term) => `.*${term}.*`).join(''),
                    options: 'i'
                  }
                }
              },
              // Match individually against firstName and lastName with partial regexes
              {
                $or: [
                  {
                    $expr: {
                      $regexMatch: {
                        input: { $toLower: '$firstName' },
                        regex: regexPatterns.join('|'),
                        options: 'i'
                      }
                    }
                  },
                  {
                    $expr: {
                      $regexMatch: {
                        input: { $toLower: '$lastName' },
                        regex: regexPatterns.join('|'),
                        options: 'i'
                      }
                    }
                  }
                ]
              },

              { phoneNumber: { $regex: searchQuery, $options: 'i' } },
              { email: { $regex: searchQuery, $options: 'i' } },
              { physicalAddress: { $regex: searchQuery, $options: 'i' } },
              { idNo: { $regex: searchQuery, $options: 'i' } }
            ]
          }
          tmpUsers = await readSystemUsers(qry)
        }
        setSearchResults(tmpUsers)
      } catch (e) {
        message.error(handleError(e))
      }
      setSearching(false)
    } catch (e) {}
  }

  const handlePatientQuery = (query) => {
    setSearching(true)
    debouncedFilter(query)
  }

  // eslint-disable-next-line
  const debouncedFilter = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 1000),
    []
  )
  const handleTeamMemberSelection = (newTeamMeber) => {
    let tmpTeamMembers = [...teamMembers, newTeamMeber]
    setTeamMembers(tmpTeamMembers)
  }

  return {
    setSearchQuery,
    patient,
    setPatient,
    searchResults,
    handleEnrollPatient,
    searching,
    enrolling,
    enrollmentOverride,
    setEnrollmentOverride,
    handleTeamMemberSelection,
    teamMembers,
    handlePatientQuery,
    handleReset
  }
}

export default useEnrollmentSearch
