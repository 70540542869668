/**
 * Utility functions and constants for vital signs
 */

/**
 * Reference ranges for vital signs
 * These are the normal ranges for adult patients
 */
export const VITAL_SIGN_REFERENCE_RANGES = {
  bloodPressure: { 
    min: [90, 60], // [systolic, diastolic]
    max: [140, 90],
    unit: 'mmHg'
  },
  pulse: { 
    min: 60, 
    max: 100,
    unit: 'bpm'
  },
  temperature: { 
    min: 36.1, 
    max: 37.2,
    unit: '°C'
  },
  respiratoryRate: { 
    min: 12, 
    max: 20,
    unit: '/min'
  },
  oxygenSaturation: { 
    min: 95, 
    max: 100,
    unit: '%'
  }
}

/**
 * Determines the status of a vital sign value compared to reference ranges
 * @param {string} vitalKey - The key of the vital sign
 * @param {*} value - The value to check
 * @returns {string} Status: 'normal', 'warning', or 'danger'
 */
export const getVitalSignStatus = (vitalKey, value) => {
  if (!vitalKey || value === undefined || value === null) return 'normal'
  
  const range = VITAL_SIGN_REFERENCE_RANGES[vitalKey]
  if (!range) return 'normal'
  
  if (vitalKey === 'bloodPressure' && Array.isArray(value)) {
    const systolic = parseFloat(value[0].value)
    const diastolic = parseFloat(value[1].value)
    
    if (isNaN(systolic) || isNaN(diastolic)) return 'normal'
    
    if (systolic > range.max[0] + 20 || diastolic > range.max[1] + 10 || 
        systolic < range.min[0] - 20 || diastolic < range.min[1] - 10) {
      return 'danger'
    } else if (systolic > range.max[0] || diastolic > range.max[1] || 
               systolic < range.min[0] || diastolic < range.min[1]) {
      return 'warning'
    }
    return 'normal'
  } else {
    const numValue = parseFloat(value)
    if (isNaN(numValue)) return 'normal'
    
    if (numValue > range.max + (range.max * 0.1) || numValue < range.min - (range.min * 0.1)) {
      return 'danger'
    } else if (numValue > range.max || numValue < range.min) {
      return 'warning'
    }
    return 'normal'
  }
}

/**
 * Formats a vital sign value for display
 * @param {string} vitalKey - The key of the vital sign
 * @param {*} value - The value to format
 * @returns {string} Formatted value with unit
 */
export const formatVitalSignValue = (vitalKey, value) => {
  if (!vitalKey || value === undefined || value === null) return 'No data'
  
  const range = VITAL_SIGN_REFERENCE_RANGES[vitalKey]
  if (!range) return String(value)
  
  if (vitalKey === 'bloodPressure' && Array.isArray(value)) {
    return `${value[0].value}/${value[1].value} ${range.unit}`
  }
  
  return `${value} ${range.unit}`
}

/**
 * Gets the reference range text for a vital sign
 * @param {string} vitalKey - The key of the vital sign
 * @returns {string} Reference range text
 */
export const getVitalSignReferenceText = (vitalKey) => {
  const range = VITAL_SIGN_REFERENCE_RANGES[vitalKey]
  if (!range) return ''
  
  if (vitalKey === 'bloodPressure') {
    return `Normal range: ${range.min[0]}/${range.min[1]} - ${range.max[0]}/${range.max[1]} ${range.unit}`
  }
  
  return `Normal range: ${range.min} - ${range.max} ${range.unit}`
} 