import React from 'react'
import DataPointItem from './DataPointItem'
import { Typography, Space } from 'antd'

const { Title, Text } = Typography

const DataPointsList = ({ filteredPoints, selectedDataPoints, onToggleDataPoint, token, isAddingTemplate, activeTab }) => {
  return (
    <div>
      {filteredPoints.map((dataPoint, index) => (
        <DataPointItem
          key={dataPoint.key + index}
          dataPoint={dataPoint}
          isSelected={selectedDataPoints.includes(JSON.stringify(dataPoint))}
          onToggle={onToggleDataPoint}
          token={token}
          isLast={index === filteredPoints.length - 1}
        />
      ))}
      
      {filteredPoints.length === 0 && (
        <div style={{ textAlign: 'center', padding: '20px 0', color: token.colorTextSecondary }}>
          No data points found matching your search
        </div>
      )}
    </div>
  )
}

export default DataPointsList
