import React from 'react'
import CustomButton from '../../../reusable-components/CustomButton'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'antd'

const PrevNextFloatingButtons = ({
  onPrev = () => {},
  onNext = () => {},
  onNextDisabled = false,
  onPrevDisabled = false,
  onNextLoading = false,
  onPrevLoading = false,
  nextText = 'Next',
  prevText = 'Back',
  nextOnly = false,
  prevOnly = false
}) => {
  return (
    <div
      style={{
        left: 0,
        position: 'fixed',
        bottom: 60,
        width: '100%',
        zIndex: 9999,
        background: 'rgba(255, 255, 255, 0.35  )',
        backdropFilter: 'blur(5px)',
        WebkitBackdropFilter: 'blur(10px)',
        padding: '16px'
      }}
    >
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <div
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: prevOnly || nextOnly ? '1fr' : '1fr 1fr',
              gap: 16
            }}
          >
            {nextOnly ? null : (
              <CustomButton
                size='small'
                icon={onPrevLoading ? false : faChevronLeft}
                iconPosition='start'
                text={prevText}
                onClick={onPrev}
                disabled={onPrevDisabled}
                loading={onPrevLoading}
                iconStyle={{ color: onPrevDisabled ? '#9e9e9e' : '#e91e63' }}
                style={{
                  background: onPrevDisabled ? 'rgba(158, 158, 158, 0.1)' : 'rgba(233, 30, 99, 0.1)',
                  border: `1px solid ${onPrevDisabled ? '#9e9e9e' : '#e91e63'}`,
                  color: onPrevDisabled ? '#9e9e9e' : '#e91e63',
                  borderRadius: '8px',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  fontSize: '14px',
                  fontWeight: 500
                }}
              />
            )}
            {prevOnly ? null : (
              <CustomButton
                icon={onNextLoading ? false : faChevronRight}
                iconPosition='end'
                onClick={onNext}
                size='small'
                disabled={onNextDisabled}
                text={nextText}
                loading={onNextLoading}
                iconStyle={{ color: onNextDisabled ? '#9e9e9e' : '#10b2e1' }}
                style={{
                  background: onNextDisabled ? 'rgba(158, 158, 158, 0.1)' : 'rgba(16, 178, 225, 0.1)',
                  border: `1px solid ${onNextDisabled ? '#9e9e9e' : '#10b2e1'}`,
                  color: onNextDisabled ? '#9e9e9e' : '#10b2e1',
                  borderRadius: '8px',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  fontSize: '14px',
                  fontWeight: 500
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PrevNextFloatingButtons
