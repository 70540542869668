import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography

const ResultsStep = () => {
  return (
    <div className="tour-step results-step">
      <div className="screen-preview results-preview">
        <div className="mock-results">
          <div className="mock-result-item">
            <div className="mock-result-header">
              <Text strong>Complete Blood Count</Text>
              <Text type="secondary">Latest Results</Text>
            </div>
            <div className="mock-metrics">
              <div className="mock-metric">
                <Text>Hemoglobin</Text>
                <Text type="success">14.2 g/dL</Text>
              </div>
              <div className="mock-metric">
                <Text>White Blood Cells</Text>
                <Text type="success">7.5 K/µL</Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Text className="feature-description">
        View your results with easy-to-understand explanations and track your 
        health progress over time.
      </Text>
    </div>
  )
}

export default ResultsStep 