/**
 * PatientInfo Component
 * Displays detailed patient information in a styled card layout with action buttons
 * and medical information sections.
 *
 * Features:
 * - Patient profile display with avatar
 * - Action buttons for editing, history, chat
 * - Medical information sections with patient details
 * - Medical aid information display
 * - Modal handling for various actions
 */
import React, { useState, useMemo, useCallback } from 'react'
import { Col, message, Modal, Space, Typography, Avatar, Card, Descriptions, Row, Button, theme, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { EditOutlined, UserOutlined, MedicineBoxOutlined, MessageOutlined, HistoryOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import EditPatientProfile from '../../Admin/system-users/components/EditPatient'
import { handleError, hexToRGBA } from '../../lib/utils'
import systemUsersReducer from '../../Admin/system-users/utils/reducer'
import NurseScreeningMedicalHistory from './Examination Components/MedicalHistoryView.js'
import TeamChatForm from '../../TeamChat/components/team-chat-form'
import PatientInfoMedHist from './PatientInfoMedHist'
import MedicalHistoryView from '../../Medical History'

const { Title, Text } = Typography

// Reusable card section component with consistent styling

export const PatientInfoSlider = ({ data, setData, noBooking }) => {
  const dispatch = useDispatch()
  const activePatientRecords = useSelector((state) => state.systemUsers.activeRecords)

  const { token } = theme.useToken()

  const PatientInfoSection = ({ title, children, extra }) => (
    <Card
      styles={{ header: { background: hexToRGBA(token.colorPrimary, 0.2) } }}
      title={
        <Title level={5} style={{ margin: 0 }}>
          {title}
        </Title>
      }
      extra={extra}
      size='small'
    >
      {children}
    </Card>
  )

  // State management
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editingMainMember, setEditingMainMember] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false)
  const [isMedHistModalVisible, setIsMedHistModalVisible] = useState(false)
  const [teamChatModalProps, setTeamChatModalProps] = useState({
    isOpen: false,
    userRef: null
  })

  // Memoized utility functions
  const calculateAge = useMemo(() => (dateOfBirth) => dayjs().diff(dayjs(dateOfBirth), 'year'), [])

  // Handlers
  const handleEditPatientProfile = useCallback(
    async (isMainMember) => {
      try {
        const updatedRecords = [...activePatientRecords]
        const profileToEdit = isMainMember ? data.mainMemberProfile : data.patientRecord
        const recordIndex = updatedRecords.findIndex((item) => item._id === profileToEdit._id)

        if (recordIndex === -1) {
          updatedRecords.push(profileToEdit)
        } else {
          updatedRecords[recordIndex] = profileToEdit
        }

        dispatch(systemUsersReducer.actions.setActiveRecords(updatedRecords))
        setEditingMainMember(isMainMember)
        setIsEditModalOpen(true)
      } catch (error) {
        message.error(handleError(error))
      }
    },
    [activePatientRecords, data, dispatch]
  )

  const handleEditModalClose = useCallback(
    (response, newRecord) => {
      if (response !== 'cancel' && newRecord) {
        setData((prevData) => ({
          ...prevData,
          [editingMainMember ? 'mainMemberProfile' : 'patientRecord']: newRecord
        }))
      }
      setIsEditModalOpen(false)
    },
    [editingMainMember, setData]
  )

  const handleModalClose = useCallback(() => {
    setIsModalVisible(false)
    setModalTitle('')
  }, [])

  // Data extraction and formatting
  const { patientRecord, mainMemberProfile } = data
  const { medicalAid } = patientRecord

  const capitalizeFirstLetter = useCallback((string) => {
    if (!string) return ''
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }, [])

  const formatCellNumber = useCallback((number) => {
    if (!number) return ''
    return number.startsWith('+27') ? `0${number.slice(3)}` : number
  }, [])

  const showModal = useCallback(
    (buttonName) => {
      switch (buttonName) {
        case 'MEDICAL HISTORY':
          setIsMedHistModalVisible(true)
          break
        case 'TEAM CHAT PRO':
          setTeamChatModalProps({
            isOpen: true,
            userRef: data.userRef
          })
          break
        case 'ALLERGIES':
          setModalTitle('Allergies')
          setIsModalVisible(true)
          break
        default:
          break
      }
    },
    [data.userRef]
  )

  const handleProfilePicture = () => {
    setIsUploadModalVisible(true)
  }

  const handleUploadModalClose = useCallback(() => {
    // Add new handler specifically for upload modal
    setIsUploadModalVisible(false)
  }, [])

  return (
    <>
      <Modal
        footer={null}
        destroyOnClose
        width={1150}
        open={isEditModalOpen}
        onCancel={() => setIsEditModalOpen(false)}
      >
        <EditPatientProfile
          refreshView={handleEditModalClose}
          isExamination
          dataId={editingMainMember ? mainMemberProfile._id : data.userRef}
        />
      </Modal>

      <Space direction='vertical' size={24} style={{ width: '100%', background: '#ffffff', padding: 8 }}>
        <Row align='middle' justify='space-between'>
          <Col span={24}>
            <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '150px 1fr', gap: 12 }}>
              <Avatar
                shape='square'
                src={patientRecord.profileImage}
                icon={
                  !patientRecord.profileImage && (
                    <UserOutlined color={token.colorPrimary} style={{ fontSize: '64px', color: token.colorPrimary }} />
                  )
                }
                style={{
                  background: hexToRGBA(token.colorPrimary, 0.2),
                  boxShadow: '0 4px 8px rgba(255, 255, 255, 0.1)',
                  border: `1px solid ${token.colorBorder}`,

                  cursor: 'pointer',
                  borderRadius: '8px',
                  width: '100%',
                  height: 150,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClick={handleProfilePicture}
              />
              <Space style={{ width: '100%' }} direction='vertical' size={16}>
                <Tooltip title='Edit patient information' placement='right' overlayStyle={{ maxWidth: 300 }}>
                  <Button
                    style={{
                      background: token.colorSecondary,
                      width: '100%',
                      marginBottom: 8,
                      marginTop: 24
                    }}
                    type='primary'
                    icon={<UserOutlined />}
                    disabled={false}
                    onClick={() => handleEditPatientProfile(false)}
                  >
                    Patient Info
                  </Button>
                </Tooltip>

                <Tooltip
                  title='Internal communication space for staff to share notes and observations about the patient'
                  placement='right'
                  overlayStyle={{ maxWidth: 300 }}
                >
                  <Button
                    style={{
                      background: token.colorSecondary,
                      width: '100%',
                      marginBottom: 8
                    }}
                    type='primary'
                    icon={<MessageOutlined />}
                    onClick={() => showModal('TEAM CHAT PRO')}
                  >
                    Team Chat
                  </Button>
                </Tooltip>
                {/* <Button
                  style={{ background: token.colorSecondary, width: '100%' }}
                  type='primary'
                  icon={<AlertOutlined />}
                  onClick={() => showModal('ALLERGIES')}
                >
                  Allergies
                </Button> */}
              </Space>
            </div>
          </Col>
          <Col flex='1'>
            <Text style={{ color: 'rgba(255, 255, 255, 0.65)' }}>{patientRecord.email}</Text>
          </Col>
        </Row>

        <NurseScreeningMedicalHistory userRef={data.userRef} />

        {/* <PatientInfoSection
          title='Patient'
          extra={null}
        >
          <Descriptions column={1} size='small'>
            <Descriptions.Item></Descriptions.Item>
            <Descriptions.Item label='Name'>
              {patientRecord.firstName} {patientRecord.lastName}
            </Descriptions.Item>
            <Descriptions.Item label='ID'>{patientRecord.idNo}</Descriptions.Item>
            <Descriptions.Item label='Gender'>{capitalizeFirstLetter(patientRecord.gender)}</Descriptions.Item>
            <Descriptions.Item label='Age'>{calculateAge(patientRecord.dateOfBirth)}</Descriptions.Item>
            <Descriptions.Item label='DOB'>{dayjs(patientRecord.dateOfBirth).format('DD MMM YYYY')}</Descriptions.Item>
            <Descriptions.Item label='Cell'>{formatCellNumber(patientRecord.phoneNumber)}</Descriptions.Item>
            <Descriptions.Item label='Race'>{patientRecord.race}</Descriptions.Item>
            <Descriptions.Item label='Country'>{patientRecord.country}</Descriptions.Item>
          </Descriptions>
        </PatientInfoSection>

        {mainMemberProfile && (
          <PatientInfoSection
            title='Main Member'
            extra={
              <Button type='primary' icon={<EditOutlined />} onClick={() => handleEditPatientProfile(true)}></Button>
            }
          >
            <Space direction='vertical' size='small' style={{ width: '100%' }}>
              <Descriptions column={1} size='small'>
                <Descriptions.Item label='Name'>
                  {mainMemberProfile.firstName} {mainMemberProfile.lastName}
                </Descriptions.Item>
                <Descriptions.Item label='ID'>{mainMemberProfile.idNo}</Descriptions.Item>
                <Descriptions.Item label='Cell'>{formatCellNumber(mainMemberProfile.phoneNumber)}</Descriptions.Item>
                <Descriptions.Item label='Email'>{mainMemberProfile.email}</Descriptions.Item>
              </Descriptions>
            </Space>
          </PatientInfoSection>
        )}

        <PatientInfoSection title='Medical Aid'>
          <Descriptions column={1} size='small'>
            <Descriptions.Item label='Name'>{medicalAid?.name}</Descriptions.Item>
            <Descriptions.Item label='Plan'>{medicalAid?.plan}</Descriptions.Item>
            <Descriptions.Item label='Number'>{medicalAid?.number}</Descriptions.Item>
            <Descriptions.Item label='Main Member'>{mainMemberProfile ? 'No' : 'Yes'}</Descriptions.Item>
            <Descriptions.Item label='Dependant No.'>{medicalAid?.dependantNumber}</Descriptions.Item>
          </Descriptions>
        </PatientInfoSection> */}
      </Space>

      <TeamChatForm modalProps={teamChatModalProps} setModalProps={setTeamChatModalProps} />

      <Modal
        width='80%'
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        closable={true}
        maskClosable={true}
        style={{
          top: 0,
          padding: 0,
          margin: '0 auto',
          // height: '100vh',
          maxWidth: '100vw'
        }}
        bodyStyle={{
          padding: 0,
          margin: 0,
          // height: '100vh',
          overflow: 'auto',
          background: '#f0f2f5'
        }}
      >
        {modalTitle === 'Medical History' && (
          <div style={{ height: '100%', width: '100%' }}>
            <MedicalHistoryView userRef={data.userRef} handleClose={handleModalClose} />
          </div>
        )}
      </Modal>

      <Modal
        title={<Title level={4}>Patient Medical History</Title>}
        width='80%'
        open={isMedHistModalVisible}
        onCancel={() => setIsMedHistModalVisible(false)}
        footer={null}
        closable={true}
        maskClosable={true}
        style={{
          top: 20,
          padding: 0,
          margin: '0 auto',
          maxWidth: '100vw'
        }}
        bodyStyle={{
          padding: 16,
          margin: 0,
          overflow: 'auto',
          background: '#f0f2f5'
        }}
      >
        <PatientInfoMedHist userRef={data.userRef} />
      </Modal>

      {/* <Modal
        title={<Title level={4}>UPLOAD PIC FUNCTIONALITY HERE</Title>}
        open={isUploadModalVisible}
        onCancel={handleUploadModalClose} // Changed from handleModalClose to handleUploadModalClose
        footer={null}
      >
      </Modal> */}
    </>
  )
}

export default React.memo(PatientInfoSlider)
