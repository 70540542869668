import React, { useState } from 'react'
import { Typography, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown, faDownload, faShare } from '@fortawesome/free-solid-svg-icons'
import '../../../styling/RecentResults.css'
import { useSelector } from 'react-redux'

const { Text } = Typography

const RecentResults = ({ onViewDetails }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const testTemplates = useSelector((state) => state.careSyncTests.data)
  const testResults = useSelector((state) => state.careSyncTestResults.data)

  // Create a new array, then sort and slice to get latest 2
  const latestResults = testResults
    ? [...testResults].sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)).slice(0, 2)
    : []

  return (
    <div className='cs-results-center'>
      <div className='cs-results-center__header'>
        <Text className='cs-results-center__title'>Recent Blood Work</Text>
        <Button type='link' className='cs-results-center__collapse-btn' onClick={() => setIsCollapsed(!isCollapsed)}>
          <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronDown} />
        </Button>
      </div>

      {!isCollapsed && (
        <div className='cs-results-center__tests'>
          <Text type='secondary' className='cs-results-center__description'>
            This section shows your most recent blood work results. For a complete history and detailed analysis, please
            visit the Results section in the top navigation.
          </Text>
          {latestResults?.map((result) => {
            const template = testTemplates?.find((t) => t.key === result.key)
            if (!template) return null

            const referenceRanges = JSON.parse(template?.data || '{}').referenceRangeData || []

            return (
              <div key={result._id} className='cs-results-center__test-item'>
                <div className='cs-results-center__main-content'>
                  <div className='cs-results-center__test-info'>
                    <Text className='cs-results-center__test-name'>{template.name}</Text>
                    <div className='cs-results-center__time-row'>
                      <div className='cs-results-center__times'>
                        <Text type='secondary' className='cs-results-center__time'>
                          {new Date(result.dateCreated).toLocaleDateString()}
                        </Text>
                        <div className='cs-results-center__reading'>
                          <div className='cs-results-center__value'>
                            {result.value}
                            <span className='cs-results-center__unit'>{template.suffix}</span>
                          </div>
                          <span className='cs-results-center__status-tag cs-results-center__status-tag--normal'>
                            Normal
                          </span>
                        </div>
                      </div>
                      <div className='cs-result-item__actions'>
                        {template.hasLabReport && (
                          <>
                            <Button
                              type='text'
                              className='cs-result-item__action-btn'
                              icon={<FontAwesomeIcon icon={faDownload} />}
                            />
                            <Button
                              type='text'
                              className='cs-result-item__action-btn'
                              icon={<FontAwesomeIcon icon={faShare} />}
                            />
                          </>
                        )}
                        <Button
                          type='link'
                          className='cs-result-item__detail-btn'
                          icon={<FontAwesomeIcon icon={faChevronRight} />}
                          onClick={() => onViewDetails(result)}
                        />
                      </div>
                    </div>
                    {referenceRanges.length > 0 && (
                      <Text type='secondary' style={{ fontSize: '12px' }}>
                        Reference: {referenceRanges[0].label}
                      </Text>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default RecentResults
