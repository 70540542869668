import { Alert, Col, Divider, Row, Space, Tag, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { readMedicalHistory } from '../../../Admin/patients/utils/utils'
import { handleError } from '../../../lib/utils'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'

const MedicalHistory = ({ selectedUser }) => {
  const state = useSelector((state) => state)
  const [medHistLoading, setMedHistLoading] = useState(false)
  const [data, setData] = useState([])
  useEffect(() => {
    handleGetMedHist(selectedUser ? selectedUser : state.auth.agiliteUser._id)
    // eslint-disable-next-line
  }, [selectedUser])
  const handleGetMedHist = async (id) => {
    let patientRecord = null

    setMedHistLoading(true)

    try {
      patientRecord = await readMedicalHistory({ userRef: id })
      setData(patientRecord)
    } catch (e) {
      message.error(handleError(e))
    }

    setMedHistLoading(false)
  }

  const containerStyle = {
    borderRadius: 12,
    boxShadow: '0 0 30px 0 #ECEDEE',
    padding: 12,
    minHeight: '100%'
  }
  const spaceStyle = {
    width: '100%'
  }
  const dividerStyle = {
    borderColor: 'rgba(204, 204, 204, 0.35)',
    margin: '12px 0'
  }

  const hasAllergies = () => {
    if (
      data[0]?.allergies?.foodList?.length > 0 ||
      data[0]?.allergies?.medsList?.length > 0 ||
      data[0]?.allergies?.otherList?.length > 0 ||
      data[0]?.allergies?.foodAdd?.length > 0 ||
      data[0]?.allergies?.medsAdd?.length > 0 ||
      data[0]?.allergies?.otherAdd?.length > 0
    ) {
      return true // Patient has at least one allergy
    } else {
      return false // Patient has no allergies
    }
  }

  return (
    <>
      {medHistLoading ? (
        <Row style={containerStyle}>
          <Col span={24}>
            <AgiliteSkeleton skActive spinnerTip='Loading Medical History...' />
          </Col>
        </Row>
      ) : (
        <Row style={containerStyle}>
          <Col span={24}>
            <h2 className='mb-1'>Lifestyle</h2>
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <span>Physical Activity</span>
                  </Col>
                  <Col span={12}>
                    <span style={{ color: '#A1A6AC' }}>
                      {data[0]?.lifestyle?.physicalActivity ? data[0]?.lifestyle?.physicalActivity : 'Not Captured'}
                    </span>
                  </Col>
                </Row>
                <Divider style={dividerStyle} />
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <span>Sleep Hours</span>
                  </Col>
                  <Col span={12}>
                    <span style={{ color: '#A1A6AC' }}>
                      {data[0]?.lifestyle?.sleepHours ? data[0]?.lifestyle?.sleepHours : 'Not Captured'}
                    </span>
                  </Col>
                </Row>
                <Divider style={dividerStyle} />
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <span>Stress Levels</span>
                  </Col>
                  <Col span={12}>
                    <span style={{ color: '#A1A6AC' }}>
                      {data[0]?.lifestyle?.stress ? data[0]?.lifestyle?.stress : 'Not Captured'}
                    </span>
                  </Col>
                </Row>
                <Divider style={dividerStyle} />
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <span>Alcohol Intake</span>
                  </Col>
                  <Col span={12}>
                    <span style={{ color: '#A1A6AC' }}>
                      {data[0]?.medicalHistory?.alcoholConsumption
                        ? data[0]?.lifestyle?.alcoholConsumptionLevel
                        : 'None'}
                    </span>
                  </Col>
                </Row>
                <Divider style={dividerStyle} />
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <span>Smoking</span>
                  </Col>
                  <Col span={12}>
                    <span style={{ color: '#A1A6AC' }}>
                      {data[0]?.lifestyle?.smoking
                        ? data[0]?.lifestyle?.smokingLevel + ', ' + data[0]?.lifestyle?.smokingYears
                        : 'None'}
                    </span>
                  </Col>
                </Row>
                <Divider style={dividerStyle} />
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <span>Vaping</span>
                  </Col>
                  <Col span={12}>
                    <span style={{ color: '#A1A6AC' }}>
                      {data[0]?.lifestyle?.vaping
                        ? data[0]?.lifestyle?.vapingLevel + ', ' + data[0]?.lifestyle?.vapingYears
                        : 'None'}
                    </span>
                  </Col>
                </Row>
                <Divider style={dividerStyle} />
              </Col>
            </Row>
            <h4 className='mb-1'>Allergies</h4>
            <Row>
              <Col span={24}>
                <Space style={spaceStyle} wrap>
                  {hasAllergies() ? (
                    <>
                      {data[0]?.allergies?.foodList?.length > 0 ? (
                        <>
                          {data[0]?.allergies?.foodList?.map((food) => {
                            return <Tag color='green'>{food}</Tag>
                          })}
                        </>
                      ) : undefined}
                      {data[0]?.allergies?.foodAdd?.length > 0 ? (
                        <>
                          {data[0]?.allergies?.foodAdd?.map((food) => {
                            return <Tag color='green'>{food.name}</Tag>
                          })}
                        </>
                      ) : undefined}
                      {data[0]?.allergies?.medsList?.length > 0 ? (
                        <>
                          {data[0]?.allergies?.medsList?.map((med) => {
                            return <Tag color='pink'>{med}</Tag>
                          })}
                        </>
                      ) : undefined}
                      {data[0]?.allergies?.medAdd?.length > 0 ? (
                        <>
                          {data[0]?.allergies?.medAdd?.map((med) => {
                            return <Tag color='pink'>{med.name}</Tag>
                          })}
                        </>
                      ) : undefined}
                      {data[0]?.allergies?.otherList?.length > 0 ? (
                        <>
                          {data[0]?.allergies?.otherList?.map((other) => {
                            return <Tag color='blue'>{other}</Tag>
                          })}
                        </>
                      ) : undefined}
                      {data[0]?.allergies?.otherAdd?.length > 0 ? (
                        <>
                          {data[0]?.allergies?.otherAdd?.map((other) => {
                            return <Tag color='blue'>{other.name}</Tag>
                          })}
                        </>
                      ) : undefined}
                    </>
                  ) : (
                    'None Captured'
                  )}
                </Space>
                <Divider style={dividerStyle} />
              </Col>
            </Row>
            <h4 className='mb-1'>Illnesses</h4>
            <Row>
              <Col span={24}>
                <span style={{ color: '#A1A6AC' }}>
                  {data[0]?.chronicIllnesses?.list?.length > 0 ? (
                    <>
                      {data[0].chronicIllnesses.list.map((illness) => {
                        return <Alert description={illness.description} />
                      })}
                    </>
                  ) : (
                    'None Captured'
                  )}
                </span>
                <Divider style={dividerStyle} />
              </Col>
            </Row>
            <h4 className='mb-1'>Medications</h4>
            <Row>
              <Col span={24}>
                <Space style={spaceStyle} wrap>
                  {data[0]?.meds?.list?.length > 0
                    ? data[0]?.meds?.list?.map((med) => <Alert description={med.med.name} />)
                    : 'None Captured'}
                </Space>
                <Divider style={dividerStyle} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  )
}

export default MedicalHistory
