import { Card, Col, message, Modal, theme } from 'antd'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import PatientDataDisplay from './Data Display/ExamDataDisplay'
import { handleError, hexToRGBA } from '../../../lib/utils'
import { readClinicalEntries } from '../../examination-utils/examination-lib'
import { readClinicDataPoints } from '../../../Super-Admin/Data Points/data-points-utils/data-point-utils'
import { readDataPointTemplates } from '../../../Data-Point-Templates/utils'
import SymptomsCapture from './Symptoms Capture/SymptomsCapture'
import CustomRow from '../../../reusable-components/CustomRow'
import { MODAL_WIDTH } from './Data Capturing/utils/constants'
import LinkTop from '../../../Linktop/lib'
import { faChain, faChainBroken } from '@fortawesome/free-solid-svg-icons'
import ExamDataCapture from './Data Capturing/ExamDataCapture'
import AssessmentActions from './AssessmentActions'
import { useDispatch, useSelector } from 'react-redux'
import { DataPointsReducer, ExamTemplatesReducer } from './reducers/reducers'
import AgiliteSkeleton from '../../../reusable-components/AgiliteSkeleton'
import CareSyncTests from './CareSyncTests'

const WEBSOCKET_RETRY_DELAY = 3000 // 3 seconds
const MAX_RETRIES = 5

const ExaminationWrapper = ({ bookingData, userRef, readOnly }) => {
  const dispatch = useDispatch()
  const { token } = theme.useToken()

  // State management
  const [websocketConnection, setWebsocketConnection] = useState({
    isConnected: false,
    socket: null,
    retryCount: 0
  })
  const [modalState, setModalState] = useState({
    capture: false,
    linktop: false
  })
  // Section visibility state - symptoms are always visible
  const [visibleSections, setVisibleSections] = useState({
    vitalSigns: true,
    generalStats: true,
    bloodTests: true,
    procedures: true
  })

  const { loading: dataPointsLoading } = useSelector((state) => state.dataPoints)
  const { loading: templatesLoading } = useSelector((state) => state.examTemplates)
  const [patientDataLoading, setPatientDataLoading] = useState(false)
  const [patientData, setPatientData] = useState({
    entries: [],
    nonTemplateEntries: []
  })

  // Toggle section visibility
  const toggleSectionVisibility = (section) => {
    setVisibleSections((prev) => ({
      ...prev,
      [section]: !prev[section]
    }))
  }

  // Memoized values
  const websocketUrl = useMemo(() => `${process.env.REACT_APP_NODE_RED_WS_URL}/bookings`, [])

  const connectionStatus = useMemo(
    () => ({
      title: websocketConnection.isConnected
        ? 'Connected to Realtime Update Server'
        : 'Disconnected from Realtime Update Server',
      background: hexToRGBA(websocketConnection.isConnected ? token.colorSuccess : token.colorError, 0.2),
      icon: websocketConnection.isConnected ? faChain : faChainBroken,
      color: websocketConnection.isConnected ? token.colorSuccess : token.colorError
    }),
    [websocketConnection.isConnected, token]
  )

  // WebSocket handlers
  const handleWebSocketMessage = useCallback(
    (event) => {
      const parsedData = JSON.parse(event.data)
      if (parsedData.bookingRef === bookingData?._id) {
        setPatientData((prev) => ({
          ...prev,
          entries: [...prev.entries, ...parsedData.data]
        }))
      }
    },
    [bookingData]
  )

  // Data fetching
  const fetchPatientData = useCallback(async () => {
    setPatientDataLoading(true)
    try {
      const data = await readClinicalEntries({
        userRef: bookingData?.userRef || userRef
      })
      console.log(`Fetched ${data.length} patient data entries`)

      // Log sample data for debugging - Comment out to prevent excessive logging
      /* 
      if (data.length > 0) {
        console.log('Sample patient data entry:', JSON.stringify(data[0], null, 2))
        
        // Check for vital signs in the data
        const vitalSignKeywords = ['Blood Pressure', 'Heart Rate', 'Temperature', 'Respiratory Rate', 'Oxygen', 'Weight', 'Height']
        const vitalSigns = data.filter(entry => 
          entry.dataPointName && vitalSignKeywords.some(keyword => 
            entry.dataPointName.includes(keyword)
          )
        )
        
        console.log(`Found ${vitalSigns.length} vital sign entries`)
        if (vitalSigns.length > 0) {
          console.log('Sample vital sign entry:', JSON.stringify(vitalSigns[0], null, 2))
        }
        
        // Specifically check for blood pressure entries
        const bloodPressureEntries = data.filter(entry => 
          (entry.dataPointName && 
           (entry.dataPointName.toLowerCase().includes('blood pressure') || 
            entry.dataPointName.toLowerCase().includes('bp'))) ||
          (entry.key && entry.key === 'bloodPressure') ||
          (entry.checkinData && entry.checkinData.bloodPressure)
        )
        
        console.log(`Found ${bloodPressureEntries.length} blood pressure entries`)
        if (bloodPressureEntries.length > 0) {
          console.log('Blood pressure entries:', JSON.stringify(bloodPressureEntries, null, 2))
        } else {
          console.log('No blood pressure entries found in patient data')
          
          // Check if blood pressure is in the booking data
          if (bookingData && bookingData.checkinData && bookingData.checkinData.bloodPressure) {
            console.log('Blood pressure found in booking data:', JSON.stringify(bookingData.checkinData.bloodPressure, null, 2))
          }
        }
      }
      */

      setPatientData((prev) => ({ ...prev, entries: data }))
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setPatientDataLoading(false)
    }
  }, [bookingData, userRef])

  const fetchDataPointTemplates = useCallback(async () => {
    const TEMPLATE_QUERY = { templateType: { $ne: 'clinical_report' } }
    dispatch(ExamTemplatesReducer.actions.setLoading(true))
    try {
      const templates = await readDataPointTemplates(TEMPLATE_QUERY)
      dispatch(ExamTemplatesReducer.actions.setRecords(templates))
    } catch (error) {
      dispatch(ExamTemplatesReducer.actions.setError(handleError(error)))
      message.error(handleError(error))
    }
    dispatch(ExamTemplatesReducer.actions.setLoading(false))
    // eslint-disable-next-line
  }, [])

  const fetchDataPoints = useCallback(async () => {
    dispatch(DataPointsReducer.actions.setLoading(true))
    try {
      const points = await readClinicDataPoints({})
      dispatch(DataPointsReducer.actions.setRecords(points))
    } catch (error) {
      dispatch(DataPointsReducer.actions.setError(handleError(error)))
      message.error(handleError(error))
    }

    dispatch(DataPointsReducer.actions.setLoading(false))
    // eslint-disable-next-line
  }, [])

  // WebSocket setup
  useEffect(() => {
    if (!bookingData) return

    let ws = null
    let reconnectTimeout = null

    const connectWebSocket = () => {
      ws = new WebSocket(websocketUrl)

      ws.onopen = () => {
        setWebsocketConnection((prev) => ({
          isConnected: true,
          socket: ws,
          retryCount: 0
        }))
      }

      ws.onmessage = handleWebSocketMessage

      ws.onerror = (error) => {
        handleError(error, true)
      }

      ws.onclose = (event) => {
        setWebsocketConnection((prev) => ({
          ...prev,
          isConnected: false,
          socket: null
        }))

        // Only attempt to reconnect if the closure wasn't intentional
        if (!event.wasClean && websocketConnection.retryCount < MAX_RETRIES) {
          reconnectTimeout = setTimeout(() => {
            setWebsocketConnection((prev) => ({
              ...prev,
              retryCount: prev.retryCount + 1
            }))
            connectWebSocket()
          }, WEBSOCKET_RETRY_DELAY)
        }
      }
    }

    connectWebSocket()

    return () => {
      if (ws) {
        ws.close()
      }
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout)
      }
      setWebsocketConnection({
        isConnected: false,
        socket: null,
        retryCount: 0
      })
    }
    // eslint-disable-next-line
  }, [])

  // Initial data loading
  useEffect(() => {
    const loadInitialData = async () => {
      await Promise.all([fetchDataPoints(), fetchDataPointTemplates(), fetchPatientData()])
    }

    loadInitialData()
    // eslint-disable-next-line
  }, [])

  return (
    <CustomRow>
      {patientDataLoading ? (
        <Col span={24} style={{ height: '100%' }}>
          <Card style={{ marginTop: 12 }}>
            <AgiliteSkeleton skActive spinnerTip='Loading Patient Data...' />
          </Card>
        </Col>
      ) : (
        <>
          {bookingData?.careSyncTests?.length > 0 ? (
            <Col span={24}>
              <CareSyncTests requiredTests={bookingData.careSyncTests} />
            </Col>
          ) : undefined}
          <Col span={24}>
            <AssessmentActions
              fetchPatientData={fetchPatientData}
              connectionStatus={connectionStatus}
              setCaptureModalOpen={(open) => setModalState((prev) => ({ ...prev, capture: open }))}
              setLinktopModalOpen={(open) => setModalState((prev) => ({ ...prev, linktop: open }))}
              patientData={bookingData?.patientRecord}
              bookingData={bookingData}
              patientDataEntries={patientData.entries}
              visibleSections={visibleSections}
              toggleSectionVisibility={toggleSectionVisibility}
            />
          </Col>
          {bookingData && (
            <Col md={24}>
              <div style={{ marginTop: '16px' }}>
                <SymptomsCapture bookingData={bookingData} />
              </div>
            </Col>
          )}
          <PatientDataDisplay
            readOnly={readOnly}
            fetchPatientData={fetchPatientData}
            patientDataEntries={patientData.entries}
            setPatientDataEntries={(entries) => setPatientData((prev) => ({ ...prev, entries }))}
            nonTemplateDataEntries={patientData.nonTemplateEntries}
            setNonTemplateDataEntries={(entries) =>
              setPatientData((prev) => ({ ...prev, nonTemplateEntries: entries }))
            }
            websocketConnection={websocketConnection}
            bookingData={bookingData}
            userRef={userRef}
            visibleSections={visibleSections}
            setLinktopModalOpen={(open) => setModalState((prev) => ({ ...prev, linktop: open }))}
          />
          <ExamDataCapture
            socket={null}
            modalOpen={modalState.capture}
            setModalOpen={(open) => setModalState((prev) => ({ ...prev, capture: open }))}
            websocketConnection={websocketConnection}
            bookingData={bookingData}
            userRef={userRef}
            handleRefresh={fetchPatientData}
          />
          <Modal
            width={MODAL_WIDTH}
            open={modalState.linktop}
            closable={false}
            maskClosable={false}
            footer={false}
            destroyOnClose
          >
            <LinkTop
              userRef={bookingData ? bookingData.userRef : userRef}
              bookingRef={bookingData?._id || ''}
              setModalOpen={(open) => setModalState((prev) => ({ ...prev, linktop: open }))}
            />
          </Modal>
        </>
      )}
    </CustomRow>
  )
}

export default React.memo(ExaminationWrapper)
