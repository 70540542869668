import React from 'react'
import { deviceDetect } from 'react-device-detect'

// Components
import MobVirConsultWrapper from './Mobile'
import NewBooking from '../../Bookings/components/AvailabilitySearchWrapper'

const VirConsultWraper = () => {
  if (deviceDetect().isMobile) return <MobVirConsultWrapper isVirtualVisit={true} />
  else return <NewBooking isVirtualVisit={true} />
}

export default VirConsultWraper
