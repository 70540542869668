import State from './state'
import { createSlice } from '@reduxjs/toolkit'

const medicalProfessionalReducer = createSlice({
  name: 'medicalProfessionalReducer',
  initialState: State,
  reducers: {
    setRecords: (state, action) => {
      state.data = action.payload
    }
  }
})

export default medicalProfessionalReducer
