export const getBloodPressureStatus = (type, value) => {
  let level = 'normal'
  let label = 'Normal'

  // Check diastolic
  if (value[0] < 120) {
    level = 'normal'
    label = 'Normal'
    if (value[1] < 89 && value[1] >= 80) {
      level = 'stage1'
      label = 'Hypertension Stage 1'
    }
    if (value[1] < 90 && value[1] >= 80) {
      level = 'stage1'
      label = 'Hypertension Stage 1'
    }
    if (value[1] >= 90) {
      level = 'stage2'
      label = 'Hypertension Stage 2'
    }
    if (value[1] >= 120) {
      level = 'crisis'
      label = 'Hypertensive Crisis'
    }
  }
  if (value[0] <= 129 && value[0] >= 120) {
    level = 'elevated'
    label = 'Elevated'
    if (value[1] < 90 && value[1] >= 80) {
      level = 'stage1'
      label = 'Hypertension Stage 1'
    }
    if (value[1] >= 90) {
      level = 'stage2'
      label = 'Hypertension Stage 2'
    }
    if (value[1] >= 120) {
      level = 'crisis'
      label = 'Hypertensive Crisis'
    }
  }
  if (value[0] <= 139 && value[0] >= 130) {
    level = 'stage1'
    label = 'Hypertension Stage 1'

    if (value[1] >= 90) {
      level = 'stage2'
      label = 'Hypertension Stage 2'
    }
    if (value[1] >= 120) {
      level = 'crisis'
      label = 'Hypertensive Crisis'
    }
  }
  if (value[0] < 180 && value[0] >= 140) {
    level = 'stage2'
    label = 'Hypertension Stage 2'
    if (value[1] >= 120) {
      level = 'crisis'
      label = 'Hypertensive Crisis'
    }
  }
  if (value[0] >= 180) {
    level = 'crisis'
    label = 'Hypertensive Crisis'
  }
  if (type === 'level') {
    return level
  } else {
    return label
  }
}

export const bloodPressureMethod = (value) => {
  const systolic = value[0]
  const diastolic = value[1]
  return {
    normal: {
      statusTest: () => systolic < 120 && diastolic < 80,
      systolicTest: () => systolic < 120,
      diastolicTest: () => diastolic < 70,
      systolicTitle: 'Below 120',
      diastolicTitle: 'Below 70',
      label: 'Normal',
      color: '#72C240',
      backgroundColor: '#D6FFBD'
    },
    elevated: {
      statusTest: () => systolic >= 120 && systolic <= 129 && diastolic < 80,
      systolicTest: () => systolic <= 129 && systolic >= 120,
      diastolicTest: () => diastolic < 80,
      systolicTitle: '120+',
      diastolicTitle: 'Below 80',
      label: 'Elevated',
      color: '#1FB0DD',
      backgroundColor: '#C5F1FF'
    },
    stage1: {
      statusTest: () =>
        (systolic >= 130 && systolic <= 139 && diastolic >= 80 && diastolic <= 89) ||
        (systolic < 130 && diastolic >= 80 && diastolic <= 89) ||
        (systolic >= 130 && systolic <= 139 && diastolic < 80),
      systolicTest: () => systolic < 140 && systolic >= 130,
      diastolicTest: () => diastolic < 90 && diastolic >= 80,
      systolicTitle: '130+',
      diastolicTitle: '80+',
      label: 'Hypertensive Stage 1',
      color: '#005FAD',
      backgroundColor: '#005fad69'
    },
    stage2: {
      statusTest: () =>
        (systolic >= 140 && systolic <= 179 && diastolic >= 90 && diastolic <= 119) ||
        (systolic < 140 && diastolic >= 90 && diastolic <= 119) ||
        (systolic >= 140 && systolic <= 179 && diastolic < 90),
      systolicTest: () => systolic <= 179 && systolic >= 140,
      diastolicTest: () => diastolic < 120 && diastolic >= 90,
      systolicTitle: '140+',
      diastolicTitle: '90+',
      label: 'Hypertensive Stage 2',
      color: '#F8B814',
      backgroundColor: '#f8b8146d'
    },
    crisis: {
      statusTest: () =>
        systolic >= 180 ||
        diastolic >= 120 ||
        (systolic < 180 && diastolic >= 120) ||
        (systolic >= 180 && diastolic < 120),
      systolicTest: () => systolic >= 180,
      diastolicTest: () => diastolic >= 120,
      systolicTitle: '180+',
      diastolicTitle: '120+',
      label: 'Hypertensive Crisis',
      color: '#F62088',
      backgroundColor: '#FEDEED'
    }
  }
}
export const getPulseStatus = (value) => {
  let level = ''
  if (value >= 100) {
    level = 'High'
  }
  if (value >= 60 && value < 100) {
    level = 'Normal'
  }
  if (value < 60) {
    level = 'Low'
  }
  return level
}

export const heartRateMethod = (value) => {
  return {
    low: {
      statusTest: value < 60,
      label: 'Low',
      color: '#1FB0DD',
      backgroundColor: '#C5F1FF',
      description: 'Less than 60 bpm'
    },
    normal: {
      statusTest: value >= 60 && value <= 100,
      label: 'Normal',
      color: '#72C240',
      backgroundColor: 'rgba(194, 226, 174, 0.41)',
      description: '60 - 100 bpm'
    },
    high: {
      statusTest: value > 100,
      label: 'High',
      color: '#F62088',
      backgroundColor: '#FEDEED',
      description: 'Over 100 bpm'
    }
  }
}

export const getSugarLevelStatus = (value) => {
  let level = ''
  if (value <= 3.9) {
    level = 'Low'
  }
  if (value > 3.9 && value <= 5.5) {
    level = 'Normal'
  }
  if (value > 5.5 && value <= 6.9) {
    level = 'Elevated'
  }
  if (value > 6.9) {
    level = 'High'
  }

  return level
}

export const getCholesterolStatus = (value) => {
  let level = ''
  if (value <= 4.1) {
    level = 'Low'
  }
  if (value >= 4.2 && value <= 5.2) {
    level = 'Normal'
  }
  if (value >= 5.3 && value <= 6.2) {
    level = 'Elevated'
  }
  if (value > 6.2) {
    level = 'High'
  }

  return level
}

export const temperatureMethod = (value) => {
  return {
    low: {
      statusTest: value < 35,
      title: 'Low',
      description: 'Lower than 35',
      color: '#1FB0DD',
      backgroundColor: '#D6F4FD'
    },
    normal: {
      statusTest: value >= 35 && value <= 37.5,
      title: 'Normal',
      description: 'From 35 to 37.5',
      color: '#72C240',
      backgroundColor: 'rgba(194, 226, 174, 0.41)'
    },
    elevated: {
      statusTest: value >= 37.6 && value <= 38.3,
      title: 'Elevated',
      description: 'From 37.6 to 38.3',
      color: '#F8B814',
      backgroundColor: ''
    },
    high: {
      statusTest: value > 38.3,
      title: 'High',
      description: 'Greater than 38.3',
      color: '#F62088',
      backgroundColor: '#FEDEED'
    }
  }
}

export const oxygenSatMethod = (value) => {
  return {
    normal: {
      statusTest: value >= 95,
      title: 'Normal',
      description: '',
      color: '#72C240',
      backgroundColor: 'rgba(194, 226, 174, 0.41)'
    },
    mild: {
      statusTest: value >= 90 && value < 95,
      title: 'Mild Hypoxemia',
      description: '',
      color: '#1FB0DD',
      backgroundColor: '#D6F4FD'
    },
    moderate: {
      statusTest: value >= 80 && value <= 90,
      title: 'Moderate Hypoxemia',
      description: '',
      color: '#F8B814',
      backgroundColor: ''
    },
    severe: {
      statusTest: value < 80,
      title: 'Severe Hypoxemia',
      description: '',
      color: '#F62088',
      backgroundColor: '#FEDEED'
    }
  }
}

export const bmiMethod = (value) => {
  return {
    underweight: {
      statusTest: value < 18.5,
      title: 'Underweight',
      description: 'Less than 18.5',
      color: '#1FB0DD',
      backgroundColor: 'rgba(194, 226, 174, 0.41)'
    },
    normal: {
      statusTest: value >= 18.5 && value <= 24.9,
      title: 'Normal',
      description: 'From 18.5 to 24.9',
      color: '#72C240',
      backgroundColor: ''
    },
    overweight: {
      statusTest: value >= 25 && value <= 29.9,
      title: 'Overweight',
      description: 'From 25 to 29.9',
      color: '#005FAD',
      backgroundColor: '#D6F4FD'
    },
    obese: {
      statusTest: value >= 29.9 && value <= 34.9,
      title: 'Obese',
      description: 'From 30 to 35',
      color: '#F8B814',
      backgroundColor: 'rgba(255, 229, 163, 1)'
    },
    extremelyObese: {
      statusTest: value > 35,
      title: 'Extremely Obese',
      description: 'More than 35',
      color: '#F62088',
      backgroundColor: '#FEDEED'
    }
  }
}
export const cholesterolMethod = (value) => {
  return {
    low: {
      statusTest: value < 4.2,
      title: 'Low',
      description: 'Less than 4.2',
      color: '#1FB0DD',
      backgroundColor: '#D6F4FD'
    },
    normal: {
      statusTest: value >= 4.2 && value < 5.3,
      title: 'Normal',
      description: 'From 4.2 to 5.2',
      color: '#72C240',
      backgroundColor: 'rgba(194, 226, 174, 0.41)'
    },
    elevated: {
      statusTest: value >= 5.3 && value <= 6.2,
      title: 'Elevated',
      description: 'From 5.3 to 6.2',
      color: '#F8B814',
      backgroundColor: 'rgba(255, 229, 163, 1)'
    },
    high: {
      statusTest: value > 6.2,
      title: 'High',
      description: 'More than 6.2',
      color: '#F62088',
      backgroundColor: '#FEDEED'
    }
  }
}
export const bloodSugarMethod = (value) => {
  return {
    low: {
      statusTest: value < 4,
      title: 'Low',
      description: 'Less than 4.0',
      color: '#1FB0DD',
      backgroundColor: '#D6F4FD'
    },
    normal: {
      statusTest: value >= 4 && value < 5.6,
      title: 'Normal',
      description: 'From 4.0 to 5.5',
      color: '#72C240',
      backgroundColor: 'rgba(194, 226, 174, 0.41)'
    },
    elevated: {
      statusTest: value >= 5.6 && value <= 6.9,
      title: 'Elevated',
      description: 'From 5.6 to 6.9',
      color: '#F8B814',
      backgroundColor: 'rgba(255, 229, 163, 1)'
    },
    high: {
      statusTest: value > 6.9,
      title: 'High',
      description: 'More than 6.9',
      color: '#F62088',
      backgroundColor: '#FEDEED'
    }
  }
}

export const filterByDateRange = (data, startDate, endDate) => {
  let tmpData = data.concat()
  tmpData = tmpData.filter((entry) => {
    let bookingDate = new Date(entry.bookingDate)
    bookingDate.setHours(0, 0, 0, 0) // set hours, minutes, seconds and milliseconds to 0
    bookingDate = bookingDate.getTime()

    startDate = new Date(startDate)
    startDate.setHours(0, 0, 0, 0)
    startDate = startDate.getTime()

    endDate = new Date(endDate)
    endDate.setHours(0, 0, 0, 0) // set hours, minutes, seconds and milliseconds to end of day
    endDate = endDate.getTime()

    return bookingDate >= startDate && bookingDate <= endDate
  })

  return tmpData
}
