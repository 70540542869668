import { faChain, faChainBroken, faRefresh, faTrash, faUpload, faProcedures } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Checkbox, Col, Divider, Row, Space, Tooltip, message, theme, Typography, Badge } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { handleError, hexToRGBA } from '../../lib/utils'
import { createProcedurePerformed, readProceduresPerformed } from '../examination-utils/examination-procedures-utils'
import dayjs from 'dayjs'
import { readProcedures } from '../../Admin/entities/utils/utils'
import { deleteFile, getFile, uploadFile } from '../../Admin/config/utils/utils'
import { countBillingRecords, createBillingRecord } from '../../Bookings/utils/utils'
import CustomLoadingIcon from '../../reusable-components/CustomLoadingIcon'

const { Title } = Typography

// Constants
const SYMPTOM_TAG_COLOR = '#FF69B4' // Hot pink color for badges

const ExaminationProceduresPerformed = ({ bookingData, userRef }) => {
  const state = useSelector((state) => state)
  const [webSocket, setWebSocket] = useState({
    connected: false,
    socket: null
  })

  // Procedures Performed
  const [procedureList, setProcedureList] = useState([])
  const [proceduresPerformed, setProceduresPerformed] = useState([])
  const [proceduresPerformedLoading, setProceduresPerformedLoading] = useState(false)
  const [keywordProcedures, setKeywordProcedures] = useState([])

  const { token } = theme.useToken()

  useEffect(() => {
    fetchProceduresPerformed()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const proceduresPermormedSocket = new WebSocket(`${process.env.REACT_APP_NODE_RED_WS_URL}/procedures-performed`)

    proceduresPermormedSocket.onopen = () => {
      setWebSocket({
        connected: true,
        socket: proceduresPermormedSocket
      })
    }

    proceduresPermormedSocket.onerror = (error) => {
      handleError(error, true)
    }

    proceduresPermormedSocket.onmessage = (event) => {
      if (JSON.parse(event.data).bookingRef === bookingData._id) {
        setProceduresPerformed(JSON.parse(event.data).data)
      }
    }

    return () => {
      proceduresPermormedSocket.close()

      setWebSocket({
        connected: true,
        socket: proceduresPermormedSocket
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setProcedureList(state.core.entity.configs.procedures ? state.core.entity.configs.procedures : [])
    handleGetKeywordProcedures()
    // eslint-disable-next-line
  }, [])

  const fetchProceduresPerformed = async () => {
    let tmpData = []

    setProceduresPerformedLoading(true)
    try {
      tmpData = await readProceduresPerformed({ bookingRef: bookingData._id, userRef })
      setProceduresPerformed(tmpData)
    } catch (e) {
      message.error(handleError(e))
    }
    setProceduresPerformedLoading(false)
  }

  const handleGetKeywordProcedures = async () => {
    try {
      let tmpData = []
      tmpData = await readProcedures()
      setKeywordProcedures(tmpData)
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleProcedureChange = async (key, value, fileRef, fileName) => {
    try {
      const tmpProceduresPerformed = [...proceduresPerformed]
      let currentProcedureIndex = tmpProceduresPerformed.findIndex((i) => i.key === key)

      createProcedurePerformed({
        bookingRef: bookingData._id,
        createdBy: state.auth.agiliteUser.firstName + ' ' + state.auth.agiliteUser.lastName,
        dateCreated: dayjs(new Date()),
        fileRef,
        fileName,
        key,
        value,
        userRef
      })

      if (currentProcedureIndex !== -1) {
        tmpProceduresPerformed[currentProcedureIndex].value = value
        tmpProceduresPerformed[currentProcedureIndex].fileRef = fileRef
        tmpProceduresPerformed[currentProcedureIndex].fileName = fileName
      } else {
        tmpProceduresPerformed.push({
          key,
          value,
          fileRef,
          fileName
        })
      }

      setProceduresPerformed(tmpProceduresPerformed)
      webSocket.socket.send(JSON.stringify({ bookingRef: bookingData._id, data: tmpProceduresPerformed }))

      // Billing Macros
      const tmpBillingItems = []
      let tmpMacroIndex = -1

      if (bookingData.clinicRecord?.billingMacros) {
        tmpMacroIndex = bookingData.clinicRecord.billingMacros.findIndex((i) => i.name === key)
      }

      if (tmpMacroIndex !== -1 && value) {
        if (bookingData.clinicRecord.billingMacros[tmpMacroIndex].isActive) {
          bookingData.clinicRecord.billingMacros[tmpMacroIndex].items.forEach((item) => {
            tmpBillingItems.push({
              code: item.code,
              name: item.name,
              qty: item.qty,
              type: item.type,
              bookingRef: bookingData._id,
              procedureKey: key
            })
          })
        }
      }

      const recordCount = await countBillingRecords({ bookingRef: bookingData._id, procedureKey: key })

      if (recordCount.result === 0) {
        createBillingRecord(tmpBillingItems)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const checkValue = (key) => {
    let returnValue = false

    if (proceduresPerformed.length > 0) {
      const procedureEntry = proceduresPerformed.find((i) => i.key === key)

      if (procedureEntry) {
        returnValue = procedureEntry.value
      } else {
        returnValue = false
      }
    } else {
      returnValue = false
    }

    return returnValue
  }

  const handleCheckFileUpload = (proc, index) => {
    let tmpIndex = -1

    tmpIndex = keywordProcedures.findIndex((i) => i.label === proc)

    if (tmpIndex !== -1) {
      if (keywordProcedures[tmpIndex].value === 'true') {
        const procedureEntry = proceduresPerformed.find((i) => i.key === proc)

        if (procedureEntry && procedureEntry.fileName && procedureEntry.fileRef) {
          return (
            <div key={index}>
              <Space>
                File: {/* eslint-disable-next-line */}
                <a
                  onClick={async () => {
                    try {
                      const fileResponse = await getFile(procedureEntry.fileRef)
                      const link = document.createElement('a')
                      const blob = new Blob([fileResponse])
                      const url = URL.createObjectURL(blob)
                      link.href = url
                      link.download = procedureEntry.fileName
                      link.click()
                      URL.revokeObjectURL(url)
                    } catch (e) {
                      message.error(handleError(e, true))
                    }
                  }}
                >
                  {procedureEntry.fileName}
                </a>
                <FontAwesomeIcon
                  onClick={() => {
                    deleteFile(procedureEntry.fileRef)
                    handleProcedureChange(proc, true, '', '')
                  }}
                  style={{ cursor: 'pointer' }}
                  color={token.colorError}
                  icon={faTrash}
                />
              </Space>
            </div>
          )
        } else {
          return (
            <div key={index}>
              <div onClick={() => document.getElementById(`upload-file-${proc}`).click()}>
                <Space>
                  <FontAwesomeIcon icon={faUpload} color={token.colorPrimary} size='lg' />
                  {/* eslint-disable-next-line */}
                  <a>Upload File</a>
                </Space>
              </div>
              <input
                id={`upload-file-${proc}`}
                style={{ display: 'none' }}
                type='file'
                multiple={false}
                onChange={async (e) => {
                  try {
                    const fileReader = new FileReader()

                    fileReader.onload = async (event) => {
                      try {
                        const arrayBuffer = event.target.result
                        const fileResponse = await handleFileUpload(e.target.files[0].name, arrayBuffer)
                        handleProcedureChange(proc, true, fileResponse._id, e.target.files[0].name)
                      } catch (e) {
                        message.error(handleError(e, true))
                      }
                    }

                    fileReader.readAsArrayBuffer(e.target.files[0])
                  } catch (e) {
                    message.error(handleError(e, true))
                  }
                }}
              />
            </div>
          )
        }
      } else {
        return null
      }
    } else {
      return null
    }
  }

  const handleFileUpload = async (name, file) => {
    let response = null
    try {
      response = await uploadFile(name, 'application/octet-stream', file, true)
      return response
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  // Count completed procedures
  const completedProceduresCount = proceduresPerformed.filter(proc => proc.value === true).length

  // Split procedures into two columns
  const splitProcedures = () => {
    if (!procedureList || procedureList.length === 0) return [[], []]
    
    const midpoint = Math.ceil(procedureList.length / 2)
    const firstColumn = procedureList.slice(0, midpoint)
    const secondColumn = procedureList.slice(midpoint)
    
    return [firstColumn, secondColumn]
  }

  const [leftColumnProcedures, rightColumnProcedures] = splitProcedures()

  // Render a single procedure item
  const renderProcedureItem = (proc, index) => {
    return (
      <div key={proc} style={{ marginBottom: '8px' }}>
        <Row style={{ alignItems: 'center' }}>
          <Col span={12}>
            <Checkbox
              onChange={(e) => handleProcedureChange(proc, e.target.checked)}
              className='custom-checkbox'
              checked={checkValue(proc)}
              style={{ marginTop: 5 }}
            >
              <span style={{ fontSize: 16 }}>{proc}</span>
            </Checkbox>
          </Col>
          <Col span={12}>{handleCheckFileUpload(proc, index)}</Col>
        </Row>
        <Divider style={{ margin: '8px 0 0 0' }} />
      </div>
    )
  }

  return (
    <Card
      styles={{ 
        header: { 
          background: hexToRGBA(token.colorPrimary, 0.08),
          borderBottom: `1px solid ${hexToRGBA(token.colorPrimary, 0.1)}`,
          padding: '12px 16px'
        },
        body: {
          padding: '16px'
        }
      }}
      size='small'
      title={
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Space>
            <FontAwesomeIcon 
              icon={faProcedures} 
              style={{ 
                color: token.colorPrimary,
                fontSize: '16px'
              }} 
            />
            <Title level={5} style={{ margin: 0 }}>Procedures Performed</Title>
            {completedProceduresCount > 0 && (
              <Badge 
                count={completedProceduresCount} 
                style={{ 
                  backgroundColor: SYMPTOM_TAG_COLOR,
                  marginLeft: 8
                }}
              />
            )}
          </Space>
          <Space>
            <div style={{
              height: '100%',
              padding: '4px 8px',
              background: hexToRGBA(webSocket.connected ? token.colorSuccess : token.colorError, 0.2),
              border: `1px ${webSocket.connected ? token.colorSuccess : token.colorError} solid`,
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center'
            }}>
              <small>Realtime Updates{' '}</small>
              <Tooltip
                title={
                  webSocket.connected ? 'Connected to Realtime Update Server' : 'Disconnected from Realtime Update Server'
                }
              >
                <FontAwesomeIcon
                  icon={webSocket.connected ? faChain : faChainBroken}
                  size='sm'
                  color={webSocket.connected ? token.colorSuccess : token.colorError}
                  style={{ cursor: 'help', marginLeft: '4px' }}
                />
              </Tooltip>
            </div>

            <Button 
              type='primary' 
              style={{ background: token.colorSuccess }} 
              onClick={() => fetchProceduresPerformed()}
              size="small"
            >
              <FontAwesomeIcon icon={faRefresh} />
            </Button>
          </Space>
        </div>
      }
      style={{
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
        marginTop: 10
      }}
    >
      <CustomLoadingIcon
        content={
          <div style={{ 
            background: token.colorBgContainer, 
            borderRadius: '4px',
            padding: '16px'
          }}>
            <Row gutter={[24, 0]}>
              {/* Left Column */}
              <Col xs={24} md={12}>
                {leftColumnProcedures.map((proc, index) => renderProcedureItem(proc, index))}
              </Col>
              
              {/* Right Column */}
              <Col xs={24} md={12}>
                {rightColumnProcedures.map((proc, index) => renderProcedureItem(proc, index + leftColumnProcedures.length))}
              </Col>
            </Row>
          </div>
        }
        loading={proceduresPerformedLoading}
        loadingText='Loading Procedures'
      />
    </Card>
  )
}

export default ExaminationProceduresPerformed
