import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { COMMON_DOSAGE_FORMS, DOSAGE_FORMS } from '../constants/dosageForms'
import '../styles/medicationCard.css'

const DosageFormField = ({ selectedDosageForm, onSelect, onClear, className }) => {
  const [otherValue, setOtherValue] = useState('')

  const handleOtherSubmit = () => {
    if (otherValue.trim()) {
      onSelect('other', otherValue.trim())
    }
  }

  return (
    <div className="field-container">
      <div className="field-label">Dosage Form</div>
      <div className={`medication-field ${className || ''}`} style={{ border: className ? '1px solid #ff4d4f' : '1px solid #d9d9d9' }}>
        {!selectedDosageForm ? (
          <div className='medication-field-options' style={{ gap: '4px' }}>
            {COMMON_DOSAGE_FORMS.map((form) => (
              <Button
                key={form.value}
                type='text'
                onClick={() => onSelect(form.value)}
                style={{ padding: '4px 12px', height: 'auto', margin: '2px' }}
              >
                {form.label}
              </Button>
            ))}
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px', margin: '2px' }}>
              <Input
                placeholder="Other..."
                value={otherValue}
                onChange={(e) => setOtherValue(e.target.value)}
                onPressEnter={handleOtherSubmit}
                style={{ width: 120 }}
              />
              <Button
                type='text'
                onClick={handleOtherSubmit}
                disabled={!otherValue.trim()}
                style={{ padding: '4px 12px', height: 'auto' }}
              >
                Add
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}
          >
            <div className='medication-field-selected'>
              <CheckOutlined />
              <span>
                {selectedDosageForm === 'other' ? 
                  otherValue : 
                  DOSAGE_FORMS.find((f) => f.value === selectedDosageForm)?.label}
              </span>
            </div>
            <Button type='text' size='small' onClick={() => {
              onClear()
              setOtherValue('')
            }} style={{ padding: '0 4px', height: 'auto' }}>
              ×
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default DosageFormField
