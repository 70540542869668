import React, { useState } from 'react'
import { Card, DatePicker, Space, Segmented, Button } from 'antd'
import { Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid, ComposedChart, Bar } from 'recharts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faBurger, 
  faDrumstickBite, 
  faCandyCane,
  faFire,
  faChartLine,
  faChartColumn
} from '@fortawesome/free-solid-svg-icons'
import '../style/MobileMacroGraphs.css'

const generateDummyData = () => {
  const data = []
  const startDate = new Date()
  startDate.setDate(startDate.getDate() - 30)

  for (let i = 0; i < 30; i++) {
    const date = new Date(startDate)
    date.setDate(startDate.getDate() + i)
    data.push({
      date: date.toISOString(),
      macros: {
        prot: Math.floor(Math.random() * (200 - 150) + 150),
        carb: Math.floor(Math.random() * (300 - 200) + 200),
        fat: Math.floor(Math.random() * (100 - 50) + 50),
        sug: Math.floor(Math.random() * (80 - 20) + 20)
      }
    })
  }
  return data
}

const xAxisConfig = {
  dataKey: 'date',
  tickFormatter: (tick) => {
    const date = new Date(tick)
    return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}`
  },
  stroke: 'rgba(255,255,255,0.5)',
  angle: -45,
  textAnchor: 'end',
  height: 35,
  interval: 4,
  minTickGap: 5,
  tick: {
    fontSize: 11,
    dy: 5,
    dx: -5,
    fill: 'rgba(255,255,255,0.5)'
  }
}

const MacroCard = ({ title, data, dataKey, color, icon }) => (
  <Card 
    className='macro-card'
    data-macro={title.toLowerCase()}
    bordered={false}
  >
    <div className='macro-header'>
      <h3>
        <span>{title}</span>
        <FontAwesomeIcon icon={icon} className='macro-icon' style={{ color }} />
      </h3>
    </div>
    <ResponsiveContainer width='100%' height={240}>
      <ComposedChart data={data} margin={{ top: 5, right: 10, bottom: 15, left: 10 }}>
        <CartesianGrid strokeDasharray='3 3' stroke='rgba(255,255,255,0.1)' />
        <XAxis {...xAxisConfig} />
        <YAxis stroke='rgba(255,255,255,0.5)' />
        <Tooltip content={({ active, payload, label }) => {
          if (active && payload && payload.length) {
            const date = new Date(label)
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`
            return (
              <div className='custom-tooltip'>
                <p className='date'>{formattedDate}</p>
                <div className='tooltip-item' style={{ color }}>
                  <span className='value'>{payload[0].value}g</span>
                </div>
              </div>
            )
          }
          return null
        }} />
        <Bar 
          dataKey={dataKey} 
          fill={color} 
          opacity={0.3} 
          radius={[4, 4, 0, 0]}
        />
        <Line 
          type='monotone' 
          dataKey={dataKey} 
          stroke={color} 
          strokeWidth={2}
          dot={false}
          activeDot={{ r: 4 }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  </Card>
)

const MobileMacroGraphs = ({ dateRange, setDateRange, onBack }) => {
  const [viewMode, setViewMode] = useState('combined')
  const [isHeaderVisible, setIsHeaderVisible] = useState(true)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const { RangePicker } = DatePicker

  // Handle touch events
  const onTouchStart = (e) => {
    setTouchStart(e.touches[0].clientY)
  }

  const onTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientY)
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    
    const distance = touchStart - touchEnd
    const isSwipeUp = distance > 20   // Minimum distance for swipe
    const isSwipeDown = distance < -20

    if (isSwipeUp) {
      setIsHeaderVisible(false)
    } else if (isSwipeDown) {
      setIsHeaderVisible(true)
    }
    
    setTouchStart(null)
    setTouchEnd(null)
  }

  const macroConfigs = [
    { title: 'Protein', dataKey: 'macros.prot', color: '#ff8000', icon: faDrumstickBite },
    { title: 'Carbs', dataKey: 'macros.carb', color: '#3498db', icon: faBurger },
    { title: 'Fats', dataKey: 'macros.fat', color: '#2ecc71', icon: faFire },
    { title: 'Sugar', dataKey: 'macros.sug', color: '#ff1493', icon: faCandyCane }
  ]

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = new Date(label)
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`
      return (
        <div className='custom-tooltip'>
          <p className='date'>{formattedDate}</p>
          {payload.map((entry, index) => (
            <div key={`item-${index}`} className='tooltip-item' style={{ color: entry.color }}>
              <span className='label'>{entry.name}</span>
              <span className='value'>{entry.value}g</span>
            </div>
          ))}
        </div>
      )
    }
    return null
  }

  // Use dummy data instead of trackerData prop
  const dummyData = generateDummyData()

  return (
    <div 
      className='mobile-macro-graphs'
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <div className={`graphs-header ${!isHeaderVisible ? 'hidden' : ''}`}>
        <Space direction='vertical' className='date-picker'>
          <RangePicker 
            value={dateRange} 
            onChange={setDateRange} 
            allowClear={false}
            format="DD-MM-YYYY"
          />
        </Space>

        <Segmented
          className='view-mode-toggle'
          value={viewMode}
          onChange={setViewMode}
          block={true}
          options={[
            {
              value: 'combined',
              label: (
                <div className='segmented-label'>
                  <FontAwesomeIcon icon={faChartColumn} />
                  <span>Combined</span>
                </div>
              )
            },
            {
              value: 'individual',
              label: (
                <div className='segmented-label'>
                  <FontAwesomeIcon icon={faChartLine} />
                  <span>Individual</span>
                </div>
              )
            }
          ]}
        />
      </div>

      {viewMode === 'combined' ? (
        <Card 
          className='combined-graph-card'
          bordered={false}
        >
          <h3>Daily Macronutrient Distribution</h3>
          <ResponsiveContainer width='100%' height={340}>
            <ComposedChart data={dummyData} margin={{ top: 5, right: 10, bottom: 15, left: 10 }}>
              <CartesianGrid strokeDasharray='3 3' stroke='rgba(255,255,255,0.1)' />
              <XAxis {...xAxisConfig} />
              <YAxis 
                stroke='rgba(255,255,255,0.5)'
                label={{ 
                  value: 'Grams', 
                  angle: -90, 
                  position: 'insideLeft',
                  style: { fill: 'rgba(255,255,255,0.5)' }
                }}
              />
              <Tooltip content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  const totalGrams = payload.reduce((sum, entry) => sum + entry.value, 0)
                  const totalCalories = (
                    payload[0].value * 4 + // Protein: 4 cal/g
                    payload[1].value * 4 + // Carbs: 4 cal/g
                    payload[2].value * 9 + // Fats: 9 cal/g
                    payload[3].value * 4   // Sugar: 4 cal/g
                  ).toFixed(0)
                  
                  return (
                    <div className='custom-tooltip'>
                      <p className='date'>{new Date(label).toLocaleDateString()}</p>
                      <div className='tooltip-calories'>
                        <span className='label'>Total Calories</span>
                        <span className='value'>{totalCalories} kcal</span>
                      </div>
                      <div className='tooltip-total'>
                        <span className='label'>Total Grams</span>
                        <span className='value'>{totalGrams}g</span>
                      </div>
                      <div className='tooltip-divider'></div>
                      {payload.map((entry, index) => (
                        <div key={`item-${index}`} className='tooltip-item' style={{ color: entry.color }}>
                          <span className='label'>{entry.name}</span>
                          <span className='value'>{entry.value}g</span>
                        </div>
                      ))}
                    </div>
                  )
                }
                return null
              }} />
              {macroConfigs.map(({ title, dataKey, color }) => (
                <Bar 
                  key={dataKey}
                  dataKey={dataKey} 
                  name={title}
                  stackId="macros"
                  fill={color}
                  radius={[4, 4, 0, 0]}
                />
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        </Card>
      ) : (
        <div className='individual-graphs'>
          {macroConfigs.map((config) => (
            <MacroCard 
              key={config.dataKey} 
              data={dummyData} 
              {...config} 
            />
          ))}
        </div>
      )}

      <div className='bottom-button-container'>
        <Button className='bottom-back-button' onClick={onBack}>
          Back
        </Button>
      </div>
    </div>
  )
}

export default MobileMacroGraphs
