import { faChain, faChainBroken } from '@fortawesome/free-solid-svg-icons'
import { Button, Col, Table, theme } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { handleError, hexToRGBA } from '../../../../lib/utils'
import CustomRow from '../../../../reusable-components/CustomRow'

const CareSyncLink = ({ patient, handleCareSyncEnd }) => {
  // State management
  const [websocketConnection, setWebsocketConnection] = useState({
    isConnected: false,
    socket: null,
    retryCount: 0
  })

  // Memoized values
  const websocketUrl = useMemo(() => 'https://nodered-metahealth.agilite.io/api/ws/caresync', [])
  const { token } = theme.useToken()
  const connectionStatus = useMemo(
    () => ({
      title: websocketConnection.isConnected ? 'Connected to CareSync' : 'Disconnected from CareSync',
      background: hexToRGBA(websocketConnection.isConnected ? token.colorSuccess : token.colorError, 0.2),
      icon: websocketConnection.isConnected ? faChain : faChainBroken,
      color: websocketConnection.isConnected ? token.colorSuccess : token.colorError
    }),
    [websocketConnection.isConnected, token]
  )

  // WebSocket handlers
  const handleWebSocketMessage = useCallback((event) => {
    const parsedData = JSON.parse(event.data)
    if (parsedData.userRef === patient._id) {
      setCareSyncRecords((prev) => [...prev, parsedData])
    }
    // eslint-disable-next-line
  }, [])
  const [careSyncRecords, setCareSyncRecords] = useState([])
  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value'
    }
  ]

  useEffect(() => {
    if (!patient) return

    let ws = null
    let reconnectTimeout = null

    const connectWebSocket = () => {
      ws = new WebSocket(websocketUrl)

      ws.onopen = () => {
        setWebsocketConnection((prev) => ({
          isConnected: true,
          socket: ws,
          retryCount: 0
        }))
      }

      ws.onmessage = handleWebSocketMessage

      ws.onerror = (error) => {
        handleError(error, true)
      }

      ws.onclose = (event) => {
        setWebsocketConnection((prev) => ({
          ...prev,
          isConnected: false,
          socket: null
        }))

        // Only attempt to reconnect if the closure wasn't intentional
        if (!event.wasClean && websocketConnection.retryCount < 10) {
          reconnectTimeout = setTimeout(() => {
            setWebsocketConnection((prev) => ({
              ...prev,
              retryCount: prev.retryCount + 1
            }))
            connectWebSocket()
          }, 30000)
        }
      }
    }

    connectWebSocket()

    return () => {
      if (ws) {
        ws.close()
      }
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout)
      }
      setWebsocketConnection({
        isConnected: false,
        socket: null,
        retryCount: 0
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <CustomRow>
      <Col span={24}>
        <p style={{ color: connectionStatus.color }}>{connectionStatus.title}</p>
        <p>
          <b>Patient: </b>
          {patient.firstName} {patient.lastName}
        </p>
        <p>
          <b>Patient Care Sync ID:</b> {patient.billingId}
        </p>
        <p> Please make sure to use the correct patient care sync ID for this patient.</p>
      </Col>
      <Col span={24}>
        <Table dataSource={careSyncRecords} columns={columns} />
      </Col>
      <Col span={24}>
        <Button
          style={{ float: 'right' }}
          onClick={() => {
            handleCareSyncEnd(careSyncRecords)
          }}
          type='primary'
        >
          Done
        </Button>
      </Col>
    </CustomRow>
  )
}

export default CareSyncLink
