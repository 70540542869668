import { message } from 'antd'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import {
  DEBOUNCE_DELAY,
  DIANGOSIS_DEBOUNCE_DELAY,
  DIANGOSIS_ERROR_MESSAGE,
  DIANGOSIS_INITIAL_STATE
} from '../utils/constants'
import { findBookingByIDUpdate } from '../../../examination-utils/examination-lib'
import { MedPraxICD10Search } from '../../../../Scripting/utils/utils'
import { handleError } from '../../../../lib/utils'
import { readBookings } from '../../../../Bookings/utils/utils'

const useDiagnosis = ({ bookingData }) => {
  // State management using a single state object to reduce re-renders
  const [state, setState] = useState(DIANGOSIS_INITIAL_STATE)
  const { selectedDiagnoses, isSaveError, isLoading, icd10Data } = state

  // eslint-disable-next-line
  const updateDiagnoses = useCallback(
    debounce(async (updatedDiagnoses) => {
      setState((prev) => ({ ...prev, isLoading: true, isSaveError: false }))

      try {
        await findBookingByIDUpdate(bookingData._id, { diagnosis: updatedDiagnoses })
      } catch (error) {
        setState((prev) => ({ ...prev, isSaveError: true }))
        message.error(DIANGOSIS_ERROR_MESSAGE)
      } finally {
        setState((prev) => ({ ...prev, isLoading: false }))
      }
    }, DEBOUNCE_DELAY),
    [bookingData._id]
  )

  // eslint-disable-next-line
  const fetchIcd10Data = useCallback(
    debounce(async (query) => {
      if (!query) {
        setState((prev) => ({ ...prev, icd10Data: [] }))
        return
      }

      setState((prev) => ({ ...prev, isLoading: true }))
      try {
        const data = await MedPraxICD10Search(query)
        setState((prev) => ({ ...prev, icd10Data: data }))
      } catch (error) {
        message.error(handleError(error, true))
      } finally {
        setState((prev) => ({ ...prev, isLoading: false }))
      }
    }, DIANGOSIS_DEBOUNCE_DELAY),
    []
  )

  // Load existing diagnoses on mount
  useEffect(() => {
    const loadExistingDiagnoses = async () => {
      setState((prev) => ({ ...prev, isLoading: true }))
      try {
        const [bookingWithDiagnosis] = await readBookings({ _id: bookingData._id }, null, 'diagnosis')
        setState((prev) => ({
          ...prev,
          selectedDiagnoses: bookingWithDiagnosis?.diagnosis || []
        }))
      } catch (error) {
        message.error(handleError(error))
      } finally {
        setState((prev) => ({ ...prev, isLoading: false }))
      }
    }

    loadExistingDiagnoses()
  }, [bookingData._id])

  /**
   * Handlers for diagnosis selection and removal
   */
  const handleDiagnosisSelect = useCallback(
    (diagnosis) => {
      const parsedDiagnosis = JSON.parse(diagnosis)
      if (!selectedDiagnoses.some((d) => d.code === parsedDiagnosis.code)) {
        const updatedDiagnoses = [...selectedDiagnoses, parsedDiagnosis]
        setState((prev) => ({ ...prev, selectedDiagnoses: updatedDiagnoses }))
        updateDiagnoses(updatedDiagnoses)
      }
    },
    [selectedDiagnoses, updateDiagnoses]
  )

  const handleDiagnosisRemove = useCallback(
    (diagnosisToRemove) => {
      const updatedDiagnoses = selectedDiagnoses.filter((d) => d.code !== diagnosisToRemove.code)
      setState((prev) => ({ ...prev, selectedDiagnoses: updatedDiagnoses }))
      updateDiagnoses(updatedDiagnoses)
    },
    [selectedDiagnoses, updateDiagnoses]
  )

  return {
    selectedDiagnoses,
    icd10Data,
    isLoading,
    isSaveError,
    handleDiagnosisSelect,
    handleDiagnosisRemove,
    updateDiagnoses,
    fetchIcd10Data,
    setState
  }
}

export default useDiagnosis
