import { Checkbox, Col, Form, Input, Modal, Space, Spin, message } from 'antd'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { Radio } from 'antd'
import { readConfig } from '../../../../Admin/config/utils/utils'
import { generateOTP, handleError } from '../../../../lib/utils'
import { findAgiliteUser } from '../../../../Auth/utils/utils'
import { readClinics } from '../../../../Admin/clinics/utils/utils'
import { executeBooking, registerBooking } from '../../../../Bookings/utils/bpm-utils'
import { createBooking, sendOTP, updateBooking } from '../../../../Bookings/utils/utils'
import { sendWhatsAppMessage, sendWhatsAppOTPMessage } from '../../../../WhatsApp/utils'
import bookingsReducer from '../../../../Bookings/utils/booking-reducer'
import { updateAllBookingsState } from '../../../../lib/booking-utils'
import EmbeddedPayment from '../../../../PeachPayments/components/EmbeddedPayment'
import CustomRow from '../../../../reusable-components/CustomRow'
import BookingSummary from './widgets/MobBookingSummary'
import ServicesEnums from '../../../../Admin/services/utils/enums'
import HomeVisitCheckin from './home-visit-checkin'
import { handleVerifyAvailability } from '../../../../Bookings/utils/lib'
import PrevNextFloatingButtons from '../../../Reusable Components/Buttons/PrevNextFloatingButtons'
import Lottie from 'react-lottie'
import searchingDoctorsAnimation from '../../../../../assets/Animations/verifying-clinic-booking.json'
import MobMedicalHistoryLoadingOverlay from '../../../Medical History/Mobile/components/LoadingOverlay'

const MobClinicBookingFinalize = ({
  prevStep,
  bookingDetails,
  setBookingDetails,
  nextStep,
  isHomeVisit,
  isVirtualVisit,
  patient,
  isReceptionBooking,
  setReceptionLoadIndicator,
  onAfterSuccessfulBooking,
  targetBooking,
  onAfterFailedBooking,
  isScheduledBooking
}) => {
  const dispatch = useDispatch()
  // For checkin Questionairre
  const [checkinConfig, setCheckinConfig] = useState()
  const [currentSelection, setCurrentSelection] = useState()
  const [patientSelection, setPatientSelection] = useState([])
  const [patientInput, setPatientInput] = useState()
  const [questionarreLoading, setQuestionarreLoading] = useState(true)
  const [terms, setTerms] = useState()
  const [termsAgreedTo, setTermsAgreedTo] = useState(isReceptionBooking || isScheduledBooking)
  const [additionalInfo, setAdditionalInfo] = useState()
  const [clientForm] = Form.useForm()
  const [displayTerms, setDisplayTerms] = useState()
  const [loading, setLoading] = useState()
  const authState = useSelector((state) => state.auth)
  const state = useSelector((state) => state)
  const serviceInfo =
    state.services.data.find((item) => item._id === bookingDetails.service) ||
    state.virtualServices.data.find((item) => item._id === bookingDetails.service)
  const [mainPatient, setMainPatient] = useState(patient)
  const [isDependant, setIsDependant] = useState(false)
  const [verifying, setVerifying] = useState(false)

  // Payment States
  const [renderPayment, setRenderPayment] = useState(false)

  useEffect(() => {
    if (mainPatient) {
      if (!mainPatient.phoneNumber && !mainPatient.email) {
        handleFindMainPatient()
      }
    }

    // eslint-disable-next-line
  }, [])

  const handleFindMainPatient = async () => {
    let tmpMainPatient = null
    try {
      tmpMainPatient = await findAgiliteUser({ dependants: { $in: [mainPatient._id] } })

      if (tmpMainPatient) {
        setIsDependant(true)
        setMainPatient(tmpMainPatient)
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const getCheckinConfig = async () => {
    let tmpConfig = await readConfig()
    setCheckinConfig(tmpConfig[0].checkin)
    setQuestionarreLoading(false)
  }

  useEffect(() => {
    getCheckinConfig()
    handleSetTerms()
    // eslint-disable-next-line
  }, [])

  const handleSetTerms = () => {
    const tmpTerms = authState.terms.find((i) => {
      if (i.linkedPractice) {
        return i.linkedPractice === bookingDetails.clinicTermsRef
      } else {
        return null
      }
    })
    if (tmpTerms) {
      setTerms(tmpTerms)
    } else {
      setTerms(authState.terms[0])
    }
  }

  const validateBooking = () => {
    if ((isHomeVisit || isVirtualVisit) && !isScheduledBooking) {
      if (!currentSelection) {
        setLoading(false)
        if (setReceptionLoadIndicator) {
          setReceptionLoadIndicator(false)
        }
        return message.error('Please select a reason for your visit')
      }

      switch (currentSelection.doctorsText) {
        case 'Sick':
          if (patientSelection.length < 1) {
            setLoading(false)
            if (setReceptionLoadIndicator) {
              setReceptionLoadIndicator(false)
            }
            return message.error('Please select at least one Symptom')
          }
          break
        case 'Injury':
          if (patientSelection.length < 1) {
            setLoading(false)
            setReceptionLoadIndicator(false)
            return message.error('Please select at least one Injury Type')
          }
          break
        case 'Skin':
          if (patientSelection.length < 1) {
            setLoading(false)
            if (setReceptionLoadIndicator) {
              setReceptionLoadIndicator(false)
            }
            return message.error('Please select at least one Skin Ailment Type')
          }
          break
        case 'Aesthetics':
          if (patientSelection.length < 1) {
            setLoading(false)
            if (setReceptionLoadIndicator) {
              setReceptionLoadIndicator(false)
            }
            return message.error('Please select at least one Interest')
          }
          break
        case 'Sports Medicine':
          if (patientSelection.length < 1) {
            setLoading(false)
            if (setReceptionLoadIndicator) {
              setReceptionLoadIndicator(false)
            }
            return message.error('Please select at least one Option')
          }
          break
        default:
          break
      }
    }

    if (isVirtualVisit && !isScheduledBooking && !isReceptionBooking) {
      setRenderPayment(true)
    } else {
      if (isScheduledBooking) {
        handleUpdateBooking()
      } else {
        confirmBooking()
      }
    }
  }

  const confirmBooking = async (callpayGatewayReference) => {
    let tmpCheckinData = {}
    let otp = null
    let tmpBookings = []
    let tmpDashBoardBookings = []
    let processRecord = null
    let processUser = null
    let payload = JSON.parse(JSON.stringify(bookingDetails))
    let clinicData = null
    const bpmKey = clinicData?.bpmKey || 'booking_process'

    if (setReceptionLoadIndicator) {
      setReceptionLoadIndicator(false)
    }
    setLoading(true)

    if (isVirtualVisit) {
      let tmpResult = null
      tmpResult = await readClinics({ _id: bookingDetails.clinicRef }, isVirtualVisit)
      clinicData = tmpResult[0]
    } else {
      clinicData = state.clinics.data.find((i) => i._id === bookingDetails.clinicRef)
    }

    try {
      payload.medicalProfRef = payload.medicalProf._id
      payload = { ...payload, ...additionalInfo, status: { ...bookingDetails.status, checkedIn: true } }

      if (isHomeVisit || isVirtualVisit) {
        tmpCheckinData.ailment = currentSelection.doctorsText

        if (patientSelection.length > 0) {
          tmpCheckinData.patientSelection = patientSelection
        }

        if (patientInput) {
          tmpCheckinData.patientInput = patientInput
        }

        payload.checkinData = {
          ...tmpCheckinData
        }
      }

      otp = generateOTP(6)
      payload.otp = otp
      setBookingDetails({ ...bookingDetails, ...payload })

      if (isVirtualVisit) {
        payload.isVirtualVisit = true
      }

      // BPM
      if (patient) {
        processUser = `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`
        payload.userRef = patient._id
        setBookingDetails({ ...bookingDetails, ...payload })
      } else {
        processUser = `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}`
      }

      processRecord = await registerBooking(bpmKey, processUser)
      processRecord = await executeBooking(bpmKey, processRecord.recordId, 'submit', processUser, processRecord.key)

      if (isHomeVisit && !isVirtualVisit) {
        // If it is a Home Visit and not a Virtual Consultation, we need to skip the checkin step
        processRecord = await executeBooking(bpmKey, processRecord.recordId, 'submit', processUser, processRecord.key)
      } else if (isVirtualVisit) {
        // If Virtual Consultation, we need to skip the checkin and Nurse step
        processRecord = await executeBooking(
          bpmKey,
          processRecord.recordId,
          'submit_to_doctor',
          processUser,
          processRecord.key
        )
      }

      let newBooking = await createBooking({
        ...payload,
        processRef: processRecord.recordId,
        status: processRecord.processStage,
        callpayGatewayReference
      })

      if (authState.agiliteUser.phoneNumber) {
        // Not awaiting this as it is not critical
        sendWhatsAppMessage(authState.agiliteUser.phoneNumber, 'appointment_confirmation', [
          { type: 'text', text: `${authState.agiliteUser.firstName} ${authState.agiliteUser.lastName}` },
          { type: 'text', text: `${bookingDetails.medicalProf.firstName} ${bookingDetails.medicalProf.lastName}` },
          { type: 'text', text: `${dayjs(bookingDetails.bookingDate).format('MMM D, YYYY')}` },
          { type: 'text', text: `${bookingDetails.startTime}` },
          { type: 'text', text: `${clinicData.name}` },
          { type: 'text', text: `${clinicData.phoneNumber}` }
        ])

        if (!isVirtualVisit) {
          if (isReceptionBooking) {
            // Not awaiting this as it is not critical
            sendOTP(otp, { ...bookingDetails, ...mainPatient }, patient, isDependant, clinicData.name)
          } else {
            // Not awaiting this as it is not critical
            sendOTP(otp, { ...bookingDetails, ...authState.agiliteUser }, patient, isDependant, clinicData.name)
          }

          // Not awaiting this as it is not critical
          sendWhatsAppOTPMessage(authState.agiliteUser.phoneNumber, 'otp_authentication', otp)
        }
      }

      // Update Bookings State
      if (state.auth.agiliteUser.extraData.role.type === 'patient') {
        // Set temporary booking states to whatever it was previously plus the new booking
        tmpBookings = [...state.bookings.data, newBooking]
        tmpDashBoardBookings = [...state.bookings.dashboardData, newBooking]
        // Sort Bookings by date
        tmpBookings.sort(
          (a, b) =>
            new Date(dayjs(b.bookingDate).set('hours', b.startTime.slice(0, 2)).set('minutes', b.startTime.slice(-2))) -
            new Date(dayjs(a.bookingDate).set('hours', a.startTime.slice(0, 2)).set('minutes', a.startTime.slice(-2)))
        )
        // update the main bookings state for patient this will be in two plces:
        dispatch(bookingsReducer.actions.setRecords(tmpBookings))
        dispatch(bookingsReducer.actions.setDashboardRecords(tmpDashBoardBookings))
      } else {
        tmpDashBoardBookings = [...state.bookings.dashboardData, newBooking]
        dispatch(bookingsReducer.actions.setDashboardRecords(tmpDashBoardBookings))
      }

      message.success('Booking Successful')

      // Show next facet
      if (isReceptionBooking) {
        onAfterSuccessfulBooking()
      } else {
        nextStep()
      }
    } catch (e) {
      handleError(e)
      message.error('Unable to make booking')
    }

    if (setReceptionLoadIndicator) {
      setReceptionLoadIndicator(false)
    }
    setLoading(false)
  }

  const handleUpdateBooking = async () => {
    let updatedBookingRecord = null
    setLoading(true)
    const payload = {}

    // Build payload
    payload.service = bookingDetails.service
    payload.bookingDate = bookingDetails.bookingDate
    payload.medicalProfRef = bookingDetails.medicalProf._id
    payload.startTime = bookingDetails.startTime
    payload.endTime = bookingDetails.endTime
    payload.scheduledBy = authState.agiliteUser._id
    payload.isVirtualVisit = true

    try {
      updatedBookingRecord = await updateBooking(payload, { _id: targetBooking })
    } catch (error) {
      message.error(handleError(error))
    }
    setLoading(false)
    updateAllBookingsState(updatedBookingRecord)
    onAfterSuccessfulBooking({
      bookingDate: updatedBookingRecord.bookingDate,
      endTime: updatedBookingRecord.endTime,
      medicalProfRef: updatedBookingRecord.medicalProfRef,
      service: updatedBookingRecord.service,
      startTime: updatedBookingRecord.startTime
    })
  }

  const handleData = () => {
    setAdditionalInfo(clientForm.getFieldsValue())
  }

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked ? [...patientSelection, tag] : patientSelection.filter((t) => t !== tag)
    clientForm.setFieldsValue({
      patientSelection: []
    })
    setPatientSelection(nextSelectedTags)
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: searchingDoctorsAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <>
      {verifying ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1001,
            background: 'white'
          }}
        >
          <Lottie options={defaultOptions} height={300} width={300} style={{ marginBottom: 20 }} />
          <div
            style={{
              color: '#000',
              fontSize: '18px',
              fontWeight: 500
            }}
          >
            Confirming Booking...
          </div>
        </div>
      ) : null}
      {renderPayment ? (
        <EmbeddedPayment
          amount={serviceInfo.rate}
          patientRecord={authState.agiliteUser}
          paymentType={bookingDetails.paymentMethod}
          isAuthorization={true}
          callback={(merchantReference) => {
            confirmBooking(merchantReference)
            setRenderPayment(false)
          }}
        />
      ) : (
        <>
          {terms && !isReceptionBooking ? (
            <Modal
              title={state.clinics.data.find((i) => i._id === bookingDetails.clinicTermsRef).practiceName}
              open={displayTerms}
              onOk={() => {
                setDisplayTerms(false)
              }}
              onCancel={() => {
                setDisplayTerms(false)
              }}
            >
              <small>
                Last updated: <b>{dayjs(terms.dateModified).format('DD MMMM YYYY')}</b>
              </small>
              <div dangerouslySetInnerHTML={{ __html: terms.data }} />
            </Modal>
          ) : undefined}
          <Form
            onFinishFailed={() => {
              message.error('Please ensure all required information has been provided.')
            }}
            onFinish={() => {
              validateBooking()
            }}
            layout='vertical'
            form={clientForm}
            onFieldsChange={() => {
              handleData(clientForm.getFieldsValue())
            }}
            initialValues={{
              chiefComplaint: bookingDetails.chiefComplaint
            }}
          >
            <CustomRow className='basic-card' justify='center'>
              {isReceptionBooking ? undefined : (
                <Col span={24}>
                  <CustomRow>
                    <Col span={24}>
                      <BookingSummary
                        isScheduledBooking={isScheduledBooking}
                        booking={bookingDetails}
                        patient={patient}
                        serviceInfo={serviceInfo}
                        isVirtualVisit={isVirtualVisit}
                      />
                    </Col>
                  </CustomRow>
                </Col>
              )}
              {serviceInfo.type !== ServicesEnums.labels.VITALITY_HEALTH_CHECK && !isVirtualVisit ? (
                <Col span={24}>
                  <CustomRow>
                    <Col span={24}>
                      <p>
                        <b>Please provide us with a brief reason for your booking:*</b>
                      </p>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        style={{ margin: 0 }}
                        rules={[{ required: true, message: 'Please provide a reasoning for your booking' }]}
                        name='chiefComplaint'
                      >
                        <Input
                          style={{ padding: 12 }}
                          placeholder='e.g I am feeling dizzy. I hit my head. I cut myself.'
                          maxLength={50}
                        />
                      </Form.Item>
                    </Col>
                  </CustomRow>
                </Col>
              ) : undefined}
              {!isVirtualVisit ? (
                <Col span={24}>
                  <CustomRow>
                    <Col span={24}>
                      <p>
                        <b>Select your method of payment:*</b>
                      </p>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        style={{ margin: 0 }}
                        rules={[{ required: true, message: 'Please select a payment method' }]}
                        name='paymentMethod'
                      >
                        <Radio.Group options={bookingDetails.paymentMethods} optionType='button' buttonStyle='solid' />
                      </Form.Item>
                    </Col>
                  </CustomRow>
                </Col>
              ) : undefined}

              {(isHomeVisit || isVirtualVisit) && !isScheduledBooking ? (
                <Col span={24}>
                  {questionarreLoading ? (
                    <Spin />
                  ) : (
                    <>
                      <HomeVisitCheckin
                        loading={loading}
                        handleChange={handleChange}
                        bookingService={serviceInfo.checkin?.length > 0 ? serviceInfo.checkin : checkinConfig}
                        currentSelection={currentSelection}
                        setCurrentSelection={setCurrentSelection}
                        patientSelection={patientSelection}
                        setPatientSelection={setPatientSelection}
                        patientInput={patientInput}
                        setPatientInput={setPatientInput}
                      />
                    </>
                  )}
                </Col>
              ) : null}

              <Col span={24}>
                <CustomRow>
                  {!isVirtualVisit && !isReceptionBooking ? (
                    <Col span={24} style={{ marginBottom: 10 }}>
                      <>
                        <p>
                          <b>NOTE:</b>
                        </p>
                        <p>
                          If you do not <b>cancel</b> your appointment within <b>3 hours</b> of booking time, you will
                          be liable for a <b>R500</b> nurse and doctor's fee.
                        </p>
                      </>
                    </Col>
                  ) : undefined}
                  {isReceptionBooking || isScheduledBooking ? undefined : (
                    <Col span={24}>
                      <center>
                        <Space wrap>
                          <Checkbox
                            onChange={(e) => {
                              setTermsAgreedTo(e.target.checked)
                            }}
                          ></Checkbox>{' '}
                          <div>
                            I Agree to the{' '}
                            <u
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setDisplayTerms(true)
                              }}
                            >
                              Terms & Conditions
                            </u>
                          </div>
                        </Space>
                      </center>
                    </Col>
                  )}
                </CustomRow>
              </Col>
            </CustomRow>
          </Form>
        </>
      )}
      {loading ? <MobMedicalHistoryLoadingOverlay loading={true} text='Verifying Booking...' /> : null}
      {verifying || loading ? null : (
        <PrevNextFloatingButtons
          onNext={async () => {
            if (!termsAgreedTo) return message.error('Please agree to the terms and conditions')
            try {
              await handleVerifyAvailability(bookingDetails, setVerifying)
              clientForm.submit()
            } catch (e) {
              message.error(handleError(e))
            }
          }}
          nextText='Confirm'
          onPrev={() => {
            prevStep()
          }}
        />
      )}
    </>
  )
}

export default MobClinicBookingFinalize
