import { theme } from 'antd'
import React from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

const GoogleAddressMobile = (props) => {
  const { token } = theme.useToken()

  props.selectProps.styles = {
    dropdownIndicator: (baseStyles, state) => ({
      display: 'none'
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      borderColor: 'rgba(16, 178, 225, 0.15)',

      outline: 'none',
      height: 48,
      borderRadius: '6px',
      fontSize: '16px',
      padding: '0 12px'
    }),
    input: (provided) => ({
      ...provided,
      borderColor: 'rgba(16, 178, 225, 0.15)',
      boxShadow: 'none',
      fontSize: '16px'
    }),
    loadingIndicator: (baseStyles) => ({
      ...baseStyles,
      borderColor: 'rgba(16, 178, 225, 0.15)',
      outline: 'none'
    }),
    menu: (provided) => ({
      ...provided,

      position: 'relative',
      zIndex: 1,
      marginTop: '4px',
      borderRadius: '6px'
    }),
    noOptionsMessage: (provided) => ({
      ...provided,

      message: 'test'
    }),
    option: (provided) => ({
      ...provided,

      padding: '12px',
      fontSize: '16px'
    }),
    singleValue: (provided) => ({
      ...provided,
      outline: 'none'
    }),
    valueContainer: (provided) => ({
      ...provided
    })
  }

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_MAPS_API_KEY}
        {...props}

        // autocompletionRequest={{ componentRestrictions: { country: 'za' } }}
      />
    </div>
  )
}

export default GoogleAddressMobile
