import React from 'react'
import { Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import '../styling/TestBenefits.css'

const { Title } = Typography

const TestBenefits = ({ benefits }) => {
  return (
    <section className="test-detail-section test-benefits-card">
      <Title level={4}>Key Benefits</Title>
      <ul className="test-detail-benefits">
        {benefits.map((benefit, index) => (
          <li key={index}>
            <span className="benefit-icon">
              <FontAwesomeIcon icon={faCheckCircle} />
            </span>
            <span className="benefit-text">{benefit}</span>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default TestBenefits 