import React, { useState } from 'react'
import { Typography, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Lottie from 'react-lottie'
import dnaAnimation from '../../../../../../../assets/Animations/Dna.json'
import TestInfoModal from './TestInfoModal'
import '../../../styling/RecommendedTests.css'

const { Text } = Typography

// Demo test for placeholder
const demoTest = {
  id: 'ferritin-demo',
  testName: 'Ferritin Test',
  dueDate: new Date(),
  priority: 'Recommended',
  reason:
    'Given your recent health profile, monitoring iron storage levels is recommended. Ferritin tests help assess iron deficiency or overload, which can impact overall health and energy levels. Regular monitoring ensures optimal iron balance.',
  isDemo: true
}

const RecommendedTests = ({ tests, onSchedule }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [selectedTest, setSelectedTest] = useState(null)
  const [showInfoModal, setShowInfoModal] = useState(false)

  const handleInfoClick = (test, e) => {
    e.stopPropagation()
    setSelectedTest(test)
    setShowInfoModal(true)
  }

  // Only show demo test for now
  const displayTests = [demoTest]

  const dnaOptions = {
    loop: true,
    autoplay: true,
    animationData: dnaAnimation,
    speed: 0.5,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      progressiveLoad: true
    },
    segments: [0, 180]  // This will play only first 180 frames if available
  }

  return (
    <div className='cs-results-center'>
      <div className='cs-results-center__header'>
        <Text className='cs-results-center__title'>Recommended Tests</Text>
        <Button
          type='link'
          className='cs-results-center__collapse-btn'
          onClick={() => setIsCollapsed(!isCollapsed)}
          title={isCollapsed ? 'Show Tests' : 'Hide Results'}
        >
          <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronDown} />
        </Button>
      </div>

      <div className='cs-results-center__animation'>
        <Lottie 
          options={dnaOptions} 
          height={120} 
          width={200} 
          isStopped={false}
          isPaused={false}
          eventListeners={[
            {
              eventName: 'complete',
              callback: () => {
                return false
              },
            },
          ]}
        />
      </div>

      {!isCollapsed && (
        <div className='cs-results-center__tests'>
          {displayTests.map((test) => (
            <div key={test.id} className='cs-results-center__test-item'>
              <div className='cs-results-center__main-content'>
                <div className='cs-results-center__test-info'>
                  <div className='cs-results-center__name-row'>
                    <Text strong className='cs-results-center__test-name'>
                      {test.testName} {test.isDemo && '(Demo)'}
                    </Text>
                    <Button
                      type='text'
                      className='cs-results-center__info-btn'
                      onClick={(e) => handleInfoClick(test, e)}
                      icon={<FontAwesomeIcon icon={faInfoCircle} />}
                    />
                  </div>
                  <div className='cs-results-center__time-row'>
                    <Text type='secondary' className='cs-results-center__time'>
                      Due: {new Date(test.dueDate).toLocaleDateString()}
                    </Text>
                    <Button
                      type='primary'
                      size='small'
                      onClick={() => onSchedule(test)}
                      className='cs-results-center__book-btn'
                      disabled={test.isDemo}
                    >
                      Book Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <Text type='secondary' className='cs-results-center__description'>
        Our AI analyzes your complete medical history, including clinical data, age, gender, and ethnicity, to suggest
        preventative screening tests.
      </Text>

      <TestInfoModal isVisible={showInfoModal} onClose={() => setShowInfoModal(false)} test={selectedTest} />
    </div>
  )
}

export default RecommendedTests
