import React from 'react'
import { Modal, Typography, Button } from 'antd'

const { Text, Title, Paragraph } = Typography

const TestRecommendationModal = ({ isVisible, onClose, recommendation }) => {
  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
      title="AI Test Recommendation"
      centered
    >
      <div className="recommendation-modal__content">
        <Title level={4}>{recommendation?.name}</Title>
        <Paragraph>
          <strong>Why this test is important:</strong>
        </Paragraph>
        <Paragraph>{recommendation?.reason}</Paragraph>
        <Button type="primary" onClick={onClose} block>
          Got it
        </Button>
      </div>
    </Modal>
  )
}

export default TestRecommendationModal 