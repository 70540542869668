import React from 'react'
import { Modal, Form, Input, InputNumber, Switch, message } from 'antd'
import { createCareSyncLabTest, updateCareSyncLabTest } from '../utils/utils'
import { handleError } from '../../../../lib/utils'

const CareSyncLabTestsForm = ({ visible, onCancel, onSuccess, record }) => {
  const [form] = Form.useForm()
  const isEdit = !!record

  React.useEffect(() => {
    if (record) {
      form.setFieldsValue(record)
    }
  }, [record, form])

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()

      if (isEdit) {
        await updateCareSyncLabTest(record._id, values)
        message.success('Lab test updated successfully')
      } else {
        await createCareSyncLabTest(values)
        message.success('Lab test created successfully')
      }

      form.resetFields()
      onSuccess()
    } catch (error) {
      message.error(handleError(error, true))
    }
  }

  return (
    <Modal
      open={visible}
      title={isEdit ? `Edit Lab Test: ${record.name}` : 'Create Lab Test'}
      okText={isEdit ? 'Update' : 'Create'}
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={handleSubmit}
    >
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          availability: true,
          featured: false,
          isActive: true
        }}
      >
        <Form.Item name='isActive' label='Is Active?' valuePropName='checked'>
          <Switch checkedChildren='Yes' unCheckedChildren='No' />
        </Form.Item>

        <Form.Item name='availability' label='Availability' valuePropName='checked'>
          <Switch checkedChildren='Yes' unCheckedChildren='No' />
        </Form.Item>

        <Form.Item name='featured' label='Featured' valuePropName='checked'>
          <Switch checkedChildren='Yes' unCheckedChildren='No' />
        </Form.Item>

        <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Please enter the name' }]}>
          <Input />
        </Form.Item>

        <Form.Item name='category' label='Category' rules={[{ required: true, message: 'Please enter the category' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          name='description'
          label='Description'
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item name='price' label='Price' rules={[{ required: true, message: 'Please enter the price' }]}>
          <InputNumber
            min={0}
            precision={2}
            style={{ width: '100%' }}
            formatter={(value) => `R ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/R\s?|(,*)/g, '')}
          />
        </Form.Item>

        <Form.Item
          name='processingTime'
          label='Processing Time (hours)'
          rules={[{ required: true, message: 'Please enter the processing time' }]}
        >
          <InputNumber min={0} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CareSyncLabTestsForm
