const patHealthStatsEnums = {
  profileKeys: {
    CLINICAL_DATA_RECORDS: 'clinical_data_records'
  },
  routeKeys: {
    READ: 'read',
    UPDATE: 'update',
    CREATE: 'create'
  }
}

export default patHealthStatsEnums
