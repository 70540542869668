import { useState, useEffect } from 'react'
import { message, Modal, Col, theme } from 'antd'
import dayjs from 'dayjs'
import { handleError } from '../../../../lib/utils'
import { getDischems, updateScript } from '../../../utils/utils'
import { validateChronicIllnesses, validateScript } from '../../../utils/lib'
import { updatePatient } from '../../../../Medical Vault/medical-history/utils/utils'
import CustomRow from '../../../../reusable-components/CustomRow'

export const useScriptingRecord = ({ data, isCopy, getUserRef, refreshView, setIsEditMode, isNew, isEditMode }) => {
  const { token } = theme.useToken()
  const [script, setScript] = useState(data ? { ...data } : {})
  const [scriptBusy, setScriptBusy] = useState(false)
  const [bypassing, setBypassing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [previousScriptState, setPreviosScriptState] = useState(null)
  const [dischemModalOpen, setDischemModalOpen] = useState(false)
  const [dischemDeliveryApplicable, setDischemDeliveryApplicable] = useState(false)
  const [dischemType, setDischemType] = useState('C')
  const [dischemPaymentType, setDischemPaymentType] = useState('Card')
  const [dischems, setDischems] = useState([])
  const [selectedDischem, setSelectedDischem] = useState(null)

  useEffect(() => {
    setScript({ ...data })
  }, [isCopy, data])

  useEffect(() => {
    handleGetDischems()
  }, [])

  const handleGetDischems = async () => {
    try {
      const tmpDischems = await getDischems()
      setDischems(tmpDischems)
    } catch (e) {
      message.error(handleError(e))
    }
  }

  const handleUpdateScheduleData = (value, key) => {
    setScript((prev) => ({ ...prev, [key]: value }))
  }

  const confirmReadOnly = () => {
    if (isNew) return false
    if (!isEditMode) return true
    return false
  }

  const handleBypassNextView = async () => {
    setBypassing(true)
    try {
      const newRecord = await updateScript(script._id, {
        nextDue: dayjs(script.nextDue).add(script.scheduleInterval * 30, 'days')
      })
      setScript(newRecord)
      refreshView()
    } catch (e) {
      handleError(e)
    }
    setBypassing(false)
  }

  const handleSave = async () => {
    setSaving(true)
    if (!script.name) {
      setSaving(false)
      return message.error('Script name required.')
    }
    if (!script.scheduleInterval) {
      setSaving(false)
      return message.error('Script reminder interval required.')
    }
    
    const errorHandler = validateScript(script)
    if (errorHandler[0]) {
      setSaving(false)
      return message.error(errorHandler[0])
    }

    const chronicChanges = validateChronicIllnesses(data.patientChronicDetails, script, 'confirmation')
    if (chronicChanges?.length > 0) {
      Modal.confirm({
        title: 'Changes have been made to patient medical history. Please confirm the changes.',
        width: 950,
        content: (
          <CustomRow style={{ maxWidth: '100%' }}>
            <Col span={24}>
              {chronicChanges.map((message) => message)}
            </Col>
          </CustomRow>
        ),
        onOk: () => handleSaveExtended(),
        onCancel: () => {
          setSaving(false)
        },
        okText: 'Accept Changes',
        cancelText: 'Cancel',
        okButtonProps: { style: { background: token.colorPrimary } }
      })
    } else {
      handleSaveExtended()
    }
  }

  const handleSaveExtended = async () => {
    const medicalHistoryPayload = {
      chronicIllnesses: {
        updatedAt: dayjs(),
        list: [...validateChronicIllnesses(data.patientChronicDetails, script)]
      }
    }

    try {
      await updatePatient(medicalHistoryPayload, {
        userRef: getUserRef(script)
      })
      const newRecord = await updateScript(script._id, script)
      setIsEditMode(false)
      refreshView()
      setScript(newRecord)
    } catch (e) {
      message.error(handleError(e))
    }
    setSaving(false)
  }

  return {
    script,
    setScript,
    scriptBusy,
    bypassing,
    saving,
    dischemModalOpen,
    setDischemModalOpen,
    dischemDeliveryApplicable,
    setDischemDeliveryApplicable,
    dischemType,
    setDischemType,
    dischemPaymentType,
    setDischemPaymentType,
    dischems,
    selectedDischem,
    setSelectedDischem,
    handleUpdateScheduleData,
    handleBypassNextView,
    handleSave,
    confirmReadOnly
  }
}

export default useScriptingRecord 