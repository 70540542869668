import React, { useEffect } from 'react'
import { Form } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import '../styles/medicationCard.css'

const EstimatedQuantity = ({ quantity, suffix, onPropertyChange, index }) => {
  // Update the parent component with the estimated quantity and suffix
  useEffect(() => {
    if (onPropertyChange && quantity && suffix) {
      onPropertyChange(quantity, 'estimatedQuantity', index)
      onPropertyChange(suffix, 'quantitySuffix', index)
    }
  }, [quantity, suffix, onPropertyChange, index])

  return (
    <div className="field-container">
      <div className="field-label">Est. quantity</div>
      <div className="medication-field" style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <div className="medication-field-selected">
          <CheckOutlined />
          <span>{quantity} {suffix}</span>
        </div>
      </div>
    </div>
  )
}

export default EstimatedQuantity
