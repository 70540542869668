import React from 'react'
import { Typography, Space } from 'antd'
import Lottie from 'react-lottie'
// TODO: Add animation
// import spiralAnimation from '../../../../../../../assets/animations/Spiral AI Animation.json'

const { Title, Text } = Typography

const WelcomeStep = ({ firstName }) => {
  return (
    <div className='tour-step welcome-step' style={{ background: '#fff' }}>
      <div className='ai-animation'>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            // animationData: spiralAnimation,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={200}
          width={200}
        />
      </div>
      <Title level={3}>Welcome to CareSync, {firstName}!</Title>
      <Text className='feature-description'>
        Revolutionizing blood work through AI-powered insights and real-time doctor collaboration. Experience
        personalized healthcare that puts you in control of your wellness journey.
      </Text>

      <Space direction='vertical' size='middle' className='welcome-features'>
        <div className='feature-item'>
          <div className='feature-text'>
            <Text strong>Instant Doctor Connection</Text>
            <Text>Real-time result sharing with doctors who can immediately respond and prescribe if needed</Text>
          </div>
        </div>

        <div className='feature-item'>
          <div className='feature-text'>
            <Text strong>AI-Powered Health Plans</Text>
            <Text>Get personalized wellness plans based on your unique blood work results</Text>
          </div>
        </div>

        <div className='feature-item'>
          <div className='feature-text'>
            <Text strong>Seamless Medicine Access</Text>
            <Text>Prescriptions delivered or ready for collection at any Dischem pharmacy</Text>
          </div>
        </div>

        <div className='feature-item highlight-feature'>
          <div className='feature-text'>
            <Text strong>Secure Result Sharing</Text>
            <Text>Share results securely via WhatsApp with our network of specialists</Text>
          </div>
        </div>
      </Space>
    </div>
  )
}

export default WelcomeStep
