import React, { useState } from 'react'
import { Card, Typography, Progress, Tag, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faHeartPulse,
  faDroplet,
  faVirusCovid,
  faStethoscope,
  faLungs,
  faNotesMedical
} from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'
import '../../styling/HealthInsights.css'

const { Text, Title } = Typography

const HealthInsights = () => {
  const getMetricColor = (id) => {
    switch (id) {
      case 'health-score':
        return '#65D2D8'
      case 'test-completion':
        return '#B5D777'
      case 'immunity':
        return '#FDB462'
      case 'kidney':
        return '#EF8D9C'
      case 'respiratory':
        return '#65D2D8'
      default:
        return '#B5D777'
    }
  }

  const bloodworkTests = {
    basic: [
      {
        id: 'lipogram',
        name: 'Lipogram',
        description: 'Cholesterol profile',
        importance: 'high',
        lastResult: {
          date: '2024-01-15',
          status: 'normal', // normal, borderline, high, low
          score: 85
        }
      },
      {
        id: 'hba1c',
        name: 'HbA1c',
        description: 'Blood glucose control',
        importance: 'high',
        lastResult: null // Not completed
      }
    ],
    hormones: [
      {
        id: 'testosterone',
        name: 'Testosterone',
        description: 'Male hormone levels',
        importance: 'medium',
        recommendedFor: {
          gender: 'male',
          minAge: 40
        },
        lastResult: {
          date: '2024-01-15',
          status: 'low',
          score: 60
        }
      },
      {
        id: 'tsh',
        name: 'TSH',
        description: 'Thyroid function',
        importance: 'high',
        lastResult: {
          date: '2024-01-10',
          status: 'normal',
          score: 90
        }
      }
    ],
    wellness: [
      {
        id: 'cortisol',
        name: 'Cortisol',
        description: 'Stress hormone',
        importance: 'medium',
        lastResult: null
      },
      {
        id: 'vitaminD',
        name: 'Vitamin D',
        description: 'Bone health & immunity',
        importance: 'high',
        lastResult: {
          date: '2024-01-15',
          status: 'low',
          score: 65
        }
      }
    ]
  }

  const calculateBloodworkScore = () => {
    let totalScore = 0
    let totalTests = 0
    let completedTests = 0

    Object.values(bloodworkTests).forEach(category => {
      category.forEach(test => {
        if (isTestRecommended(test)) {
          totalTests++
          if (test.lastResult) {
            completedTests++
            totalScore += test.lastResult.score
          }
        }
      })
    })

    return {
      score: Math.round(totalScore / completedTests) || 0,
      completion: Math.round((completedTests / totalTests) * 100),
      testsCompleted: completedTests,
      totalTests: totalTests
    }
  }

  const isTestRecommended = (test) => {
    if (!test.recommendedFor) return true
    
    // Mock user data - replace with actual user data
    const user = {
      gender: 'male',
      age: 45
    }

    const { gender, minAge } = test.recommendedFor
    return (!gender || gender === user.gender) && 
           (!minAge || user.age >= minAge)
  }

  const renderTestCategory = (category, tests) => {
    return (
      <div className="cs-health-insights__category-container">
        <Title level={4} className="cs-health-insights__category-title">{category}</Title>
        <div className="cs-health-insights__tests-grid">
          {tests.map(test => (
            <div key={test.id} className="cs-health-insights__test">
              <div className="test-header">
                <Text strong>{test.name}</Text>
                {test.importance === 'high' && <Tag color="red">Important</Tag>}
              </div>
              <Text className="test-description">{test.description}</Text>
              {test.lastResult ? (
                <>
                  <Text className={`test-status test-status-${test.lastResult.status}`}>
                    Status: {test.lastResult.status}
                  </Text>
                  <Progress 
                    percent={test.lastResult.score} 
                    size="small"
                    status={test.lastResult.status === 'normal' ? 'success' : 'exception'}
                    showInfo={false}
                  />
                  <Text type="secondary" className="test-date">
                    Last tested: {test.lastResult.date}
                  </Text>
                </>
              ) : (
                <Button type="primary" size="middle">Schedule Test</Button>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderInsightContent = (insight) => {
    switch (insight.type) {
      case 'progress-circle':
        return (
          <div className="cs-health-insights__progress">
            <Progress 
              type="circle" 
              percent={insight.value} 
              strokeColor={getMetricColor(insight.id)}
              trailColor="rgba(16, 178, 225, 0.1)" 
            />
            <Text className="cs-health-insights__label">{insight.label}</Text>
          </div>
        )
      case 'progress-bar':
        return (
          <div className="cs-health-insights__progress">
            <Progress
              percent={insight.value}
              strokeColor={{
                '0%': '#10b2e1',
                '100%': '#e91e63'
              }}
              trailColor="rgba(16, 178, 225, 0.1)"
            />
            <Text type="secondary">{insight.label}</Text>
          </div>
        )
      default:
        return (
          <div className="cs-health-insights__info">
            <Text className="cs-health-insights__title">{insight.title}</Text>
            <Text className="cs-health-insights__value">{insight.value}</Text>
            <Text className="cs-health-insights__update">
              Last updated {insight.lastUpdated}
            </Text>
          </div>
        )
    }
  }

  const renderMetricCard = (insight) => {
    if (!insight) return null
    return (
      <Card 
        key={insight.id} 
        className="cs-health-insights__card"
        data-type={insight.id}
        style={{
          '--metric-color': getMetricColor(insight.id)
        }}
      >
        <div className="cs-health-insights__card-content">
          {renderInsightContent(insight)}
        </div>
      </Card>
    )
  }

  return (
    <motion.div 
      className="cs-health-insights"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className="cs-health-insights__grid">
        {/* Overall Bloodwork Score */}
        <div className="cs-health-insights__main-metric">
          <div className="cs-health-insights__progress">
            <Text className="cs-health-insights__score-title">Blood Score</Text>
            <Progress 
              type="circle" 
              percent={calculateBloodworkScore().score}
              strokeColor="#65D2D8"
              trailColor="rgba(16, 178, 225, 0.1)"
              format={() => (
                <div className="cs-health-insights__score-circle">
                  <FontAwesomeIcon icon={faDroplet} className="cs-health-insights__score-icon" />
                  <div className="cs-health-insights__score-value">
                    {calculateBloodworkScore().score}
                    <span>POINTS</span>
                  </div>
                </div>
              )}
            />
            <div className="cs-health-insights__score-details">
              <div className="cs-health-insights__score-status-container">
                <Text className="cs-health-insights__score-status">Good</Text>
                <Text className="cs-health-insights__score-completion">
                  {calculateBloodworkScore().testsCompleted} of {calculateBloodworkScore().totalTests} tests completed
                </Text>
              </div>
            </div>
          </div>
        </div>

        {/* Basic Tests */}
        {renderTestCategory('Basic Health Tests', bloodworkTests.basic)}

        {/* Hormone Tests */}
        {renderTestCategory('Hormone Panel', bloodworkTests.hormones)}
        
        {/* Wellness Tests */}
        {renderTestCategory('Wellness Markers', bloodworkTests.wellness)}
      </div>
    </motion.div>
  )
}

export default HealthInsights 