import { useState, useCallback } from 'react'
import { message } from 'antd'
import { createDataPointTemplate, deleteDataPointTemplate } from '../../../../../Data-Point-Templates/utils'
import { handleError } from '../../../../../lib/utils'
import { useDispatch } from 'react-redux'
import { ExamTemplatesReducer } from '../../reducers/reducers'

export const useTemplates = () => {
  const dispatch = useDispatch()
  const [activeTemplate, setActiveTemplate] = useState(null)
  const [isAddingTemplate, setIsAddingTemplate] = useState(false)
  const [newTemplateName, setNewTemplateName] = useState('')
  const [isDeletingTemplate, setIsDeletingTemplate] = useState(false)

  const handleSaveNewTemplate = useCallback(
    async (selectedKeys) => {
      if (!newTemplateName) {
        message.error('Please enter a template name')
        return
      }

      if (!selectedKeys.length) {
        message.error('Please select at least one data point')
        return
      }

      try {
        const newTemplate = await createDataPointTemplate({
          isActive: true,
          name: newTemplateName,
          keys: selectedKeys,
          isGlobal: false
        })
        setActiveTemplate(newTemplate)
        dispatch(ExamTemplatesReducer.actions.submitRecord(newTemplate))
        message.success('Template created successfully')
        setNewTemplateName('')
        return newTemplate
      } catch (error) {
        message.error(handleError(error))
        throw error
      }
    },
    [newTemplateName, dispatch]
  )

  const handleDeleteTemplate = useCallback(
    async (templateId) => {
      if (!templateId) {
        message.error('Invalid template ID')
        return
      }

      setIsDeletingTemplate(true)

      try {
        await deleteDataPointTemplate(templateId)
        
        // Update Redux store
        dispatch(ExamTemplatesReducer.actions.deleteRecord(templateId))
        
        // If the deleted template was active, reset active template
        if (activeTemplate && activeTemplate._id === templateId) {
          setActiveTemplate(null)
        }
        
        message.success('Template deleted successfully')
        return true
      } catch (error) {
        message.error(handleError(error))
        return false
      } finally {
        setIsDeletingTemplate(false)
      }
    },
    [activeTemplate, dispatch]
  )

  return {
    activeTemplate,
    setActiveTemplate,
    isAddingTemplate,
    setIsAddingTemplate,
    newTemplateName,
    setNewTemplateName,
    handleSaveNewTemplate,
    handleDeleteTemplate,
    isDeletingTemplate
  }
}
