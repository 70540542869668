import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CustomRow from '../../../reusable-components/CustomRow'
import { Button, Col, message, Modal, Space, Divider, Tooltip, theme } from 'antd'
import SickNoteWrapper from './Sick Notes/SickNoteWrapper'
import { DocumentViewer } from '../../../reusable-components/DocumentViewer'
import { useDocuments } from './hooks/useDocuments'
import { MODAL_STATES } from './utils/constants'
import DocumentsTable from './components/DocumentsTable'
import DocumentActions from './components/DocumentActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretLeft,
  faExclamation,
  faExclamationCircle,
  faMailForward,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import RefferalLetterCapture from './Referral letters/ReferralLetterCapture.js'
import { handleError } from '../../../lib/utils.js'
import { readReferralLetter, readSickNotes } from '../../examination-utils/examination-lib.js'
import ReferralLetterDisplay from '../referral-letter-display.js'
import SickNoteCapture from '../sick-note-capture.js'
import SickNoteDisplay from '../sick-note-display.js'

const DocumentsWrapper = ({ bookingData, userRef, setBookingData, noBooking }) => {
  const { token } = theme.useToken()
  const {
    documents,
    setDocuments,
    handleReadDocuments,
    handleDeleteDocument,
    loadingDocuments,
    selectedDocument,
    setSelectedDocument,
    selectionRequired,
    setSelectionRequired,
    toggleModalState,
    modalState,
    handleModalReset,
    searchQuery,
    setSearchQuery,
    dateRange,
    setDateRange
  } = useDocuments({ userRef: userRef ? userRef : bookingData.userRef })

  const [viewOldSickNotes, setViewOldSickNotes] = useState(false)
  const [viewOldReferralLetters, setViewOldReferralLetters] = useState(false)

  const handleSelection = useCallback(
    (documentType) => {
      toggleModalState(documentType)
    },
    [toggleModalState]
  )

  const handleAfterSuccess = (type, newRecord) => {
    const tmpDocuments = [...documents]
    tmpDocuments.unshift(newRecord)
    setDocuments(tmpDocuments)
    handleModalReset()
    setSelectionRequired(false)
    setSelectedDocument(newRecord.value)
  }

  return (
    <>
      <CustomRow gutter={selectedDocument ? [0, 0] : [12, 12]} style={{ marginTop: 12 }}>
        {selectedDocument ? (
          <>
            <div
              style={{
                display: 'flex',
                gap: 8,
                padding: 12,
                background: '#323639',
                width: '100%',
                justifyContent: 'space-between'
              }}
            >
              <Button onClick={() => setSelectedDocument(null)}>
                <FontAwesomeIcon icon={faCaretLeft} /> Back
              </Button>
              {/* <Button>
                Email <FontAwesomeIcon icon={faMailForward} />
              </Button> */}
            </div>
            <DocumentViewer base64={selectedDocument} />
          </>
        ) : (
          <>
            {Object.values(modalState).some(Boolean) ? (
              <>
                <Col span={24}>
                  <Button onClick={() => handleModalReset()}>
                    <FontAwesomeIcon icon={faCaretLeft} /> Back
                  </Button>
                </Col>
                {modalState[MODAL_STATES.REFERRAL] && (
                  <Col span={24}>
                    <RefferalLetterCapture
                      handleAfterSuccess={handleAfterSuccess}
                      setModalOpen={() => toggleModalState(MODAL_STATES.REFERRAL)}
                      bookingData={bookingData}
                    />
                  </Col>
                )}
                {modalState[MODAL_STATES.SICK_NOTE] && (
                  <Col span={24}>
                    <SickNoteWrapper
                      handleAfterSuccess={handleAfterSuccess}
                      bookingData={bookingData}
                      setModalOpen={() => toggleModalState(MODAL_STATES.SICK_NOTE)}
                    />
                  </Col>
                )}
                {modalState[MODAL_STATES.PDF_VIEWER] && <DocumentViewer base64={selectedDocument} />}
              </>
            ) : (
              <>
                <Col span={24}>
                  <DocumentActions
                    handleReadDocuments={handleReadDocuments}
                    loadingDocuments={loadingDocuments}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    setSelectionRequired={setSelectionRequired}
                    viewOldSickNotes={() => setViewOldSickNotes(true)}
                    viewOldReferralLetters={() => setViewOldReferralLetters(true)}
                    handleSelection={handleSelection}
                  />
                </Col>
                <Col span={24}>
                  <DocumentsTable
                    documents={documents}
                    loadingDocuments={loadingDocuments}
                    setSelectedDocument={setSelectedDocument}
                    handleDeleteDocument={handleDeleteDocument}
                  />
                </Col>
              </>
            )}
          </>
        )}
      </CustomRow>
      <Modal
        open={viewOldSickNotes || viewOldReferralLetters}
        onCancel={() => {
          setViewOldSickNotes(false)
          setViewOldReferralLetters(false)
        }}
        footer={null}
        title={viewOldSickNotes ? 'Legacy Sick Notes' : 'Legacy Referral Letters'}
      >
        {viewOldSickNotes && <SickNoteDisplay bookingData={bookingData} userRef={userRef} />}
        {viewOldReferralLetters && <ReferralLetterDisplay bookingData={bookingData} userRef={userRef} />}
      </Modal>
    </>
  )
}

DocumentsWrapper.propTypes = {
  bookingData: PropTypes.object.isRequired,
  setBookingData: PropTypes.func.isRequired,
  noBooking: PropTypes.bool
}

export default React.memo(DocumentsWrapper)
