import React from 'react'
import { Modal } from 'antd'
import '../styling/SpecimenModal.css'

const SpecimenModal = ({ isVisible, onClose, specimenCodes }) => {
  return (
    <Modal
      open={isVisible}
      footer={null}
      onCancel={onClose}
      centered
      closable={false}
      wrapClassName="specimen-modal-wrapper"
      maskStyle={{ backdropFilter: 'blur(10px)', background: 'rgba(0, 0, 0, 0.2)' }}
    >
      <div className="specimen-info-modal">
        <h3>Specimen Type Codes</h3>
        <div className="specimen-info-grid">
          {Object.entries(specimenCodes).map(([code, desc]) => (
            <div key={code} className="specimen-info-row">
              <span className="specimen-code">{code}</span>
              <span className="specimen-desc">{desc}</span>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default SpecimenModal 