import React, { useState } from 'react'
import { Button, Form, InputNumber } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { DURATION_OPTIONS } from '../constants/durationOptions'
import '../styles/medicationCard.css'

const DurationField = ({ 
  selectedDuration, 
  onSelect, 
  onClear,
  className 
}) => {
  const [inputValue, setInputValue] = useState(null)

  const handleCustomDurationChange = (value) => {
    setInputValue(value)
  }

  const handleCustomDurationComplete = () => {
    if (inputValue && inputValue > 0) {
      onSelect(inputValue.toString())
      setInputValue(null)
    }
  }

  return (
    <div className="field-container">
      <div className="field-label">Duration</div>
      <div className={`medication-field ${className || ''}`} style={{ border: className ? '1px solid #ff4d4f' : '1px solid #d9d9d9' }}>
        {!selectedDuration ? (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              alignItems: 'center'
            }}
          >
            {DURATION_OPTIONS.map((option) => (
              <Button
                key={option.value}
                type='text'
                onClick={() => onSelect(option.value)}
                style={{ padding: '4px 15px', height: 'auto' }}
              >
                {option.label}
              </Button>
            ))}
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <InputNumber
                style={{ width: 100 }}
                placeholder="Days..."
                min={1}
                max={365}
                value={inputValue}
                onChange={handleCustomDurationChange}
                onPressEnter={handleCustomDurationComplete}
                onBlur={handleCustomDurationComplete}
                controls={false}
              />
              <span>days</span>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px'
            }}
          >
            <div className='medication-field-selected'>
              <CheckOutlined />
              <span>
                {DURATION_OPTIONS.find((d) => d.value === selectedDuration)?.label || `${selectedDuration} days`}
              </span>
            </div>
            <Button type='text' size='small' onClick={onClear} style={{ padding: '0 4px', height: 'auto' }}>
              ×
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default DurationField
