import { useState, useEffect, useCallback } from 'react'
import { message } from 'antd'
import { handleError } from '../../../../lib/utils'
import { MedPraxICD10Search } from '../../../utils/utils'
import { debounce } from 'lodash'

export const useICD10 = () => {
  const [icd10Loading, setIcd10Loading] = useState(false)
  const [icd10Data, setIcd10Data] = useState([])

  const getICD10Data = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await MedPraxICD10Search(searchQuery)
        setIcd10Data(tmpData)
      } else {
        setIcd10Data([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
    setIcd10Loading(false)
  }

  const debouncedICD10Filter = useCallback(
    debounce((value) => {
      getICD10Data(value)
    }, 1000),
    []
  )

  const setICD10SearchFilter = (value) => {
    setIcd10Loading(true)
    debouncedICD10Filter(value)
  }

  return {
    icd10Loading,
    icd10Data,
    setICD10SearchFilter
  }
} 