import React from 'react'
import { Space, Switch } from 'antd'

const ScriptingTableControls = ({
  type,
  onlyDue,
  setOnlyDue,
  token
}) => {
  return (
    <Space style={{ width: '100%', justifyContent: 'flex-end', marginBottom: 24 }}>
      {type === 'schedule' && (
        <Space>
          <span style={{ color: token.colorTextSecondary }}>
            Show Due Only
          </span>
          <Switch 
            checked={onlyDue}
            onChange={setOnlyDue}
            style={{ 
              backgroundColor: onlyDue ? token.colorSuccess : undefined 
            }}
          />
        </Space>
      )}
    </Space>
  )
}

export default ScriptingTableControls 