import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Space } from 'antd'
import React from 'react'

const DataPointItem = ({ dataPoint, isSelected, onToggle, token, isLast }) => {
  return (
    <React.Fragment>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        padding: '12px 10px',
        borderBottom: isLast ? 'none' : '1px solid #f0f0f0'
      }}>
        <div style={{ fontSize: '14px' }}>{dataPoint.label}</div>
        <div
          style={{
            background: isSelected ? '#f5222d' : '#52c41a',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            color: '#ffffff',
            cursor: 'pointer'
          }}
          onClick={() => onToggle(dataPoint)}
        >
          <FontAwesomeIcon style={{ fontSize: 12 }} icon={isSelected ? faMinus : faPlus} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default DataPointItem
