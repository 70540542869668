import { Col, Empty, Row, Table, message } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { getSubscriptions } from '../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import SubscriptionsReducer from '../utils/reducer'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import CustomButton from '../../reusable-components/CustomButton'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import CustomRow from '../../reusable-components/CustomRow'
import { SubscriptionsTemplate } from '../utils/templates'
import { deviceDetect } from 'react-device-detect'

const SubscriptionsList = () => {
  const dispatch = useDispatch()
  const authState = useSelector((state) => state.auth)
  const subscriptionState = useSelector((state) => state.subscriptions)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleGetSubscriptions()
    // eslint-disable-next-line
  }, [])

  const handleGetSubscriptions = async () => {
    let tmpData = []

    setLoading(true)

    try {
      tmpData = await getSubscriptions({ userRef: authState.agiliteUser._id })
      dispatch(SubscriptionsReducer.actions.setSubscriptions(tmpData))
    } catch (e) {
      message.error(e)
    }

    setLoading(false)
  }

  return (
    <Row justify='center'>
      <Col span={24}>
        <ContainerCard title='Subscriptions'>
          <Row justify='center'>
            <Col span={24}>
              <CustomRow justify='center' className='basic-card'>
                {deviceDetect().isMobile ? undefined : (
                  <Col span={24}>
                    <CustomButton
                      text='Refresh'
                      size='small'
                      icon={faRefresh}
                      onClick={handleGetSubscriptions}
                      type='primary'
                      disabled={loading}
                    />
                  </Col>
                )}
                <Col span={24}>
                  <Table
                    showHeader={!deviceDetect().isMobile}
                    loading={loading}
                    columns={SubscriptionsTemplate().columnTemplate}
                    dataSource={subscriptionState.data}
                    locale={{ emptyText: <Empty description='No Subscriptions Found' /> }}
                  />
                </Col>
              </CustomRow>
            </Col>
          </Row>
        </ContainerCard>
      </Col>
    </Row>
  )
}

export default SubscriptionsList
