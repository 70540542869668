import {
  faCar,
  faChevronDown,
  faClock,
  faComment,
  faFileInvoice,
  faLocationDot,
  faPhone,
  faPrescription,
  faShare,
  faStar,
  faStethoscope
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { Space } from 'antd'
import store from '../../../../../store'

const formatDate = (dateStr, time) => {
  const date = new Date(dateStr)
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const dayName = days[date.getDay()]
  const day = date.getDate()
  const month = months[date.getMonth()]

  return `${dayName}, ${day} ${month} at ${time}`
}

const MobPatAppointmentsCard = ({ appointment }) => {
  const servicesState = useSelector((state) => state.services.data)
  const virtualServiceState = useSelector((state) => state.virtualServices.data)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleRate = () => {}

  const handleEmailBill = () => {}

  const handleFeedback = () => {}

  const handleShare = () => {}

  const getServiceName = () => {
    let service = servicesState.find((service) => service._id === appointment.service)

    if (!service) {
      service = virtualServiceState.find((service) => service._id === appointment.service)
    }
    return service ? service.name : 'Unknown Service'
  }

  const styles = {
    cardPreview: {
      cursor: 'pointer',
      position: 'relative',
      paddingBottom: '8px'
    },
    appointmentHeader: {
      textAlign: 'left',
      marginBottom: '24px',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    doctorInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    },
    doctorName: {
      fontSize: '20px',
      margin: 0,
      fontWeight: 600
    },
    specialty: {
      color: '#10B2E1',
      fontSize: '14px',
      marginTop: '4px'
    },
    appointmentType: {
      display: 'inline-block',
      background: 'rgba(16, 178, 225, 0.1)',
      color: '#10B2E1',
      padding: '4px 12px',
      borderRadius: '6px',
      fontSize: '13px'
    },
    detailItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    detailIcon: {
      color: '#10B2E1',
      fontSize: '14px'
    },
    detailValue: {
      fontSize: '14px'
    },
    expandButton: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      background: 'none',
      border: 'none',
      padding: '8px',
      cursor: 'pointer',
      transition: 'transform 0.3s ease'
    },
    expandedDetails: {
      borderTop: '1px solid rgba(16, 178, 225, 0.1)',
      marginTop: '16px',
      paddingTop: '20px',
      overflow: 'hidden'
    },
    medicalDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px'
    },
    detailSection: {
      display: 'flex',
      flexDirection: 'column',
      background: 'rgba(230, 243, 247, 0.03)',
      padding: '12px',
      borderRadius: '8px',
      position: 'relative'
    },
    content: {
      display: 'flex',
      flexDirection: 'column'
    },
    sectionIcon: {
      color: '#10B2E1',
      fontSize: '15px',
      width: '15px',
      height: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    medicationList: {
      listStyle: 'none',
      padding: '0 0 0 23px',
      margin: 0
    },
    medicationListItem: {
      fontSize: '14px',
      padding: '4px 0',
      borderBottom: '1px solid rgba(16, 178, 225, 0.1)'
    },
    screeningList: {
      listStyle: 'none',
      padding: '0 0 0 23px',
      margin: 0
    },
    screeningListItem: {
      fontSize: '14px',
      padding: '4px 0',
      borderBottom: '1px solid rgba(16, 178, 225, 0.1)'
    }
  }

  const clinic = store.getState().clinics.data.find((i) => i._id === appointment.clinicRef)

  return (
    <motion.div className='glass-appointment-card' initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }}>
      <div style={styles.cardPreview} onClick={() => setIsExpanded(!isExpanded)}>
        <div style={styles.appointmentHeader}>
          <div style={styles.doctorInfo}>
            <div>
              <h3
                style={styles.doctorName}
              >{`${appointment.medicalProfProfile.firstName} ${appointment.medicalProfProfile.lastName}`}</h3>
              <span style={styles.specialty}>{getServiceName()}</span>
            </div>
            <div style={styles.appointmentType}>{appointment.isVirtualVisit ? 'Virtual Visit' : 'In-Person'}</div>
          </div>
        </div>

        <Space>
          <FontAwesomeIcon icon={faStethoscope} style={styles.sectionIcon} />
          <h4>Chief Complaint</h4>
        </Space>
        <p>{appointment.chiefComplaint ? appointment.chiefComplaint : 'N/A'}</p>

        <div className='preview-details'>
          <div style={styles.detailItem}>
            <FontAwesomeIcon icon={faClock} style={styles.detailIcon} />
            <span style={styles.detailValue}>{formatDate(appointment.bookingDate, appointment.startTime)}</span>
          </div>
        </div>

        <button style={styles.expandButton} className={isExpanded ? 'expanded' : ''} aria-label='Toggle details'>
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
      </div>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            style={styles.expandedDetails}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div style={styles.medicalDetails}>
              {/* Chief Complaint instead of Diagnosis */}
              <div className='appointment-details'>
                {/* Location details */}
                <div className='detail-item with-action'>
                  <div className='detail-content'>
                    <div className='detail-header'>
                      <FontAwesomeIcon icon={faLocationDot} className='detail-icon' />
                      <span className='detail-label'>Location</span>
                    </div>
                    <span className='detail-value'>{clinic.name}</span>
                  </div>
                </div>

                {/* Travel details */}
                <div className='detail-item'>
                  <div className='detail-content'>
                    <div className='detail-header'>
                      <FontAwesomeIcon icon={faCar} className='detail-icon' />
                      <span className='detail-label'>Travel Details</span>
                    </div>
                    <span className='detail-value'>{clinic.physicalAddress}</span>
                  </div>
                </div>

                {/* Contact details */}
                <div className='detail-row'>
                  <div className='detail-item with-action'>
                    <div className='detail-content'>
                      <div className='detail-header'>
                        <FontAwesomeIcon icon={faPhone} className='detail-icon' />
                        <span className='detail-label'>Contact</span>
                      </div>
                      <span className='detail-value'>{clinic.phoneNumber}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Action Buttons */}
              {/* <div className='past-appointment-actions'>
                <button className='action-button rate' onClick={handleRate}>
                  <FontAwesomeIcon icon={faStar} />
                  <span>Rate Visit</span>
                </button>
                <button className='action-button email' onClick={handleEmailBill}>
                  <FontAwesomeIcon icon={faFileInvoice} />
                  <span>Email Bill</span>
                </button>
                <button className='action-button feedback' onClick={handleFeedback}>
                  <FontAwesomeIcon icon={faComment} />
                  <span>Feedback</span>
                </button>
                <button className='action-button share' onClick={handleShare}>
                  <FontAwesomeIcon icon={faShare} />
                  <span>Share Record</span>
                </button>
              </div> */}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default MobPatAppointmentsCard
