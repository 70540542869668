import React, { useState } from 'react'
import { DatePicker, theme } from 'antd'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { ContainerCard } from '../../reusable-components/AgiliteCards'
import '../style/macro-tracker.css'

const DesktopMacroTracker = ({ userRef, isDoctor }) => {
  const RangePicker = DatePicker.RangePicker
  const authState = useSelector((state) => state.auth)
  const [limits, setLimits] = useState(null)
  const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false)
  const [limitsLoading, setLimitsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [trackerData, setTrackerData] = useState([])
  const [todaysTrackers, setTodaysTrackers] = useState([])
  const [dateRange, setDateRange] = useState(dayjs().subtract(7, 'days'), dayjs())
  const [savedMeals, setSavedMeals] = useState([])

  // ... Rest of your existing AIMacroTrackerWrapper code ...
  // Copy all the functions and useEffects from the original file

  const { token } = theme.useToken()
  const latestMacroData = todaysTrackers.length > 0 ? todaysTrackers[todaysTrackers.length - 1] : null

  return (
    <ContainerCard>
      {/* Your existing JSX from AIMacroTrackerWrapper */}
    </ContainerCard>
  )
}

export default DesktopMacroTracker 