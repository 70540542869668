/**
 * Formats error messages from the Linktop device
 * @param {Error} error - The error object
 * @param {string} operation - The operation being performed
 * @returns {string} Formatted error message
 */
export const formatDeviceError = (error, operation = 'device operation') => {
  if (!error) return 'Unknown error'

  // Handle specific error types
  if (error.name === 'NotFoundError') {
    return 'Device not found. Make sure it is powered on and in range.'
  }

  if (error.name === 'NetworkError') {
    return 'Network error. Please check your connection and try again.'
  }

  if (error.name === 'NotAllowedError') {
    return 'Permission denied. Please allow Bluetooth access to use this feature.'
  }

  if (error.name === 'TimeoutError') {
    return 'Connection timed out. Please try again.'
  }

  // Format generic errors
  return `Error during ${operation}: ${error.message || 'Unknown error'}`
}

/**
 * Logs device errors consistently
 * @param {Error} error - The error object
 * @param {string} component - The component where the error occurred
 * @param {string} operation - The operation being performed
 */
export const logDeviceError = (error, component, operation) => {
  console.error(`[${component}] Error during ${operation}:`, error)

  // You could also implement remote logging here
  // sendToErrorLogging(error, component, operation);
}
