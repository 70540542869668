import React, { useEffect, useState } from 'react'
import CholesterolPlotting from './cholesterol-plotting'

const Cholesterol = ({ data }) => {
  const [rangeFilter, setRangeFilter] = useState(null)
  const [formattedData, setFormattedData] = useState([])

  useEffect(() => {
    let tmpData = data.concat()

    tmpData = tmpData.sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate))

    setFormattedData(tmpData)
  }, [data])

  return <CholesterolPlotting data={formattedData} rangeFilter={rangeFilter} setRangeFilter={setRangeFilter} />
}

export default Cholesterol
