import React, { useEffect, useState } from 'react'
import { Card, Space, Button, Form, Popconfirm, Tooltip, theme } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { hexToRGBA } from '../../../../../../lib/utils'
import { normalizePresentationCode } from '../utils/normalizers'
import { DOSAGE_FORMS } from '../constants/dosageForms'
import DosageFormField from './DosageFormField'
import RouteField from './RouteField'
import DoseField from './DoseField'
import FrequencyField from './FrequencyField'
import DurationField from './DurationField'
import EstimatedQuantity from './EstimatedQuantity'
import AdditionalFields from './AdditionalFields'
import '../styles/medicationCard.css'

const formatMedicationName = (name) => {
  if (!name) return ''
  
  // Split by spaces and handle each word
  return name.split(' ').map(word => {
    // Convert word to lowercase first
    word = word.toLowerCase()
    
    // Special case for words that should remain uppercase
    if (word.match(/^[A-Z0-9/]+$/)) {
      return word.toUpperCase()
    }
    
    // Capitalize first letter of each word, unless it's a lowercase conjunction/preposition
    if (!['and', 'with', 'in', 'of', 'for'].includes(word)) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
    
    return word
  }).join(' ')
}

const getScheduleTagColor = (schedule) => {
  const scheduleNum = parseInt(schedule)
  if (scheduleNum <= 3) return { background: '#f6ffed', color: '#237804', border: '1px solid #b7eb8f' }
  if (scheduleNum === 4) return { background: '#fffbe6', color: '#d46b08', border: '1px solid #ffe58f' }
  return { background: '#fff0f6', color: '#c41d7f', border: '1px solid #ffadd2' }
}

const getPackSizeUnit = (presentationCode) => {
  const normalizedForm = normalizePresentationCode(presentationCode)
  switch (normalizedForm) {
    case 'tablet':
      return 'tab'
    case 'capsule':
      return 'caps'
    case 'suspension':
    case 'syrup':
      return 'ml'
    case 'inhaler':
      return 'doses'
    case 'drops':
      return 'ml'
    case 'cream':
    case 'ointment':
      return 'g'
    case 'nasal_spray':
      return 'ml'
    default:
      return 'units'
  }
}

const MedicationForm = ({
  med,
  index,
  onRemove,
  onClose,
  onDosageChange,
  onPropertyChange,
  form,
  formState,
  handlers,
  icd10Props
}) => {
  const { token } = theme.useToken()

  const {
    showRoute,
    showDose,
    showFrequency,
    showDuration,
    showEstQuantity,
    selectedDosageForm,
    currentDosageForm,
    selectedRoute,
    selectedDose,
    selectedFrequency,
    selectedDuration,
    estimatedQuantity,
    quantitySuffix,
    getAvailableRoutes
  } = formState

  const {
    handleDosageFormSelect,
    handleDosageFormClear,
    handleRouteSelect,
    handleRouteClear,
    handleDoseSelect,
    handleDoseClear,
    handleDoseInputChange,
    handleDoseInputComplete,
    handleFrequencySelect,
    handleFrequencyClear,
    handleDurationSelect,
    handleDurationClear,
    handleRepeatChange,
    handleICD10Select
  } = handlers

  const [dosageEntries, setDosageEntries] = useState([{ type: 'Initial' }])

  // Initialize form with noSubstitute value from med object
  useEffect(() => {
    if (med) {
      form.setFieldsValue({
        noSubstitute: med.noSubstitute || false
      })
    }
  }, [med, form])

  // Clear all fields to the right of the changed field
  const clearFieldsToRight = (stage) => {
    switch(stage) {
      case 'dosageForm':
        form.setFieldsValue({
          route: undefined,
          dose: undefined,
          frequency: undefined,
          duration: undefined
        })
        handleRouteClear()
        handleDoseClear()
        handleFrequencyClear()
        handleDurationClear()
        break
      case 'route':
        form.setFieldsValue({
          dose: undefined,
          frequency: undefined,
          duration: undefined
        })
        handleDoseClear()
        handleFrequencyClear()
        handleDurationClear()
        break
      case 'dose':
        form.setFieldsValue({
          frequency: undefined,
          duration: undefined
        })
        handleFrequencyClear()
        handleDurationClear()
        break
      case 'frequency':
        form.setFieldsValue({
          duration: undefined
        })
        handleDurationClear()
        break
      default:
        break
    }
  }

  // Modified handlers to clear subsequent fields
  const handleDosageFormChange = (value) => {
    clearFieldsToRight('dosageForm')
    handleDosageFormSelect(value)
  }

  const handleRouteChange = (value) => {
    clearFieldsToRight('route')
    handleRouteSelect(value)
  }

  const handleDoseChange = (value) => {
    clearFieldsToRight('dose')
    handleDoseSelect(value)
  }

  const handleDoseInput = (value) => {
    clearFieldsToRight('dose')
    handleDoseInputComplete(value)
  }

  const handleFrequencyChange = (value) => {
    clearFieldsToRight('frequency')
    handleFrequencySelect(value)
  }

  // Add new dosage entry
  const handleAddDosageEntry = () => {
    setDosageEntries([...dosageEntries, { type: 'Followed by' }])
  }

  // Remove a dosage entry
  const handleRemoveDosageEntry = (entryIndex) => {
    const newEntries = dosageEntries.filter((_, i) => i !== entryIndex)
    setDosageEntries(newEntries)
  }

  // Add effect to update scriptingDosage when form fields change
  useEffect(() => {
    if (selectedDose && selectedFrequency && selectedDuration) {
      const dosageInstructions = dosageEntries.map((entry, i) => {
        let doseText = selectedDose
        
        // Get the appropriate unit based on dosage form
        if (['tablet', 'capsule'].includes(selectedDosageForm)) {
          const unit = selectedDose === '1' ? 'tablet' : 'tablets'
          doseText = `Take ${selectedDose} ${unit}`
        } else if (['suspension', 'syrup', 'solution'].includes(selectedDosageForm)) {
          doseText = `${selectedDose} ml`
        } else if (selectedDosageForm === 'inhaler') {
          const unit = selectedDose === '1' ? 'puff' : 'puffs'
          doseText = `${selectedDose} ${unit}`
        } else if (selectedDosageForm.includes('drops')) {
          const unit = selectedDose === '1' ? 'drop' : 'drops'
          doseText = `${selectedDose} ${unit}`
        } else {
          doseText = `${selectedDose} ${med.unitOfMeasure1 || 'mg'}`
        }

        // Only include the entry type if there are multiple entries
        const entryPrefix = dosageEntries.length > 1 ? `${entry.type}: ` : ''
        return `${entryPrefix}${doseText}, ${selectedFrequency} for ${selectedDuration} days`
      }).join('\nFollowed by: ')

      onDosageChange(dosageInstructions, index)
      
      // Also update the form field to ensure it's saved
      form.setFieldsValue({
        scriptingDosage: dosageInstructions
      })
    } else {
      onDosageChange(null, index)
    }
  }, [selectedDose, selectedFrequency, selectedDuration, selectedDosageForm, selectedRoute, med.unitOfMeasure1, index, onDosageChange, dosageEntries, form])

  // Determine which field is currently incomplete
  const getCurrentIncompleteField = () => {
    if (!selectedDosageForm) return 'dosageForm'
    if (showRoute && !selectedRoute) return 'route'
    if (showDose && !selectedDose) return 'dose'
    if (showFrequency && !selectedFrequency) return 'frequency'
    if (showDuration && !selectedDuration) return 'duration'
    return null
  }

  const isComplete = selectedDose && selectedFrequency && selectedDuration

  return (
    <div className={`medication-card ${isComplete ? 'medication-card-complete' : ''}`}>
      <Card
        size='small'
        style={{
          borderRadius: 0,
          border: 'none'
        }}
        headStyle={{
          background: med.isChronic ? hexToRGBA('#F39C12', 0.1) : hexToRGBA('#1890ff', 0.1),
          borderRadius: 0
        }}
        bodyStyle={{ padding: 12 }}
        title={
          <Space size={4}>
            <span>{`${index + 1}. ${formatMedicationName(med.name)} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
              med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
            }${selectedRoute ? ` (${selectedRoute})` : ''}${med.schedule ? `, ${med.schedule}` : ''} - ${
              form.getFieldValue('dosageForm') === 'other' 
                ? form.getFieldValue('customDosageForm')
                : DOSAGE_FORMS.find((f) => f.value === normalizePresentationCode(med.presentationCode))?.label || med.presentationCode
            }`}</span>
            {med.drugSchedule && (
              <div
                style={{
                  padding: '1px 6px',
                  borderRadius: '4px',
                  fontSize: '14px',
                  fontWeight: '600',
                  ...getScheduleTagColor(med.drugSchedule)
                }}
              >
                S{med.drugSchedule}
              </div>
            )}
            {med.dispensingVolumePacksize && (
              <div
                style={{
                  padding: '1px 6px',
                  borderRadius: '4px',
                  fontSize: '14px',
                  fontWeight: '600',
                  background: '#e6f4ff',
                  color: '#1677ff',
                  border: '1px solid #91caff'
                }}
              >
                {med.dispensingVolumePacksize}{getPackSizeUnit(med.presentationCode)}
              </div>
            )}
          </Space>
        }
        extra={
          <Space>
            <Popconfirm
              title='Confirm Remove'
              description="This can't be undone!"
              okText='Remove'
              cancelText='Keep'
              onConfirm={onRemove}
            >
              <Button>
                <Space>
                  <span style={{ color: '#ff4d4f' }}>Remove</span>
                  <FontAwesomeIcon style={{ cursor: 'pointer' }} color='red' icon={faTrash} />
                </Space>
              </Button>
            </Popconfirm>
            <Button onClick={() => {
              // Ensure all form values are properly saved before closing
              const formValues = form.getFieldsValue()
              
              // Get the current noSubstitute value from the form
              const noSubValue = formValues.noSubstitute !== undefined ? formValues.noSubstitute : (med.noSubstitute || false)
              
              // Explicitly update the noSubstitute property
              onPropertyChange(noSubValue, 'noSubstitute', index)
              
              if (selectedDose && selectedFrequency && selectedDuration) {
                const dosageInstructions = dosageEntries.map((entry, i) => {
                  let doseText = selectedDose
                  
                  // Get the appropriate unit based on dosage form
                  if (['tablet', 'capsule'].includes(selectedDosageForm)) {
                    const unit = selectedDose === '1' ? 'tablet' : 'tablets'
                    doseText = `Take ${selectedDose} ${unit}`
                  } else if (['suspension', 'syrup', 'solution'].includes(selectedDosageForm)) {
                    doseText = `${selectedDose} ml`
                  } else if (selectedDosageForm === 'inhaler') {
                    const unit = selectedDose === '1' ? 'puff' : 'puffs'
                    doseText = `${selectedDose} ${unit}`
                  } else if (selectedDosageForm.includes('drops')) {
                    const unit = selectedDose === '1' ? 'drop' : 'drops'
                    doseText = `${selectedDose} ${unit}`
                  } else {
                    doseText = `${selectedDose} ${med.unitOfMeasure1 || 'mg'}`
                  }
                  
                  // Only include the entry type if there are multiple entries
                  const entryPrefix = dosageEntries.length > 1 ? `${entry.type}: ` : ''
                  return `${entryPrefix}${doseText}, ${selectedFrequency} for ${selectedDuration} days`
                }).join('\nFollowed by: ')
                
                onDosageChange(dosageInstructions, index)
              }
              
              // Now close the form
              onClose()
            }}>
              <Space>
                <FontAwesomeIcon style={{ cursor: 'pointer' }} color='#52c41a' icon={faCheck} />
              </Space>
            </Button>
          </Space>
        }
      >
        {dosageEntries.map((entry, entryIndex) => (
          <div key={entryIndex}>
            <div style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '8px',
              marginBottom: '8px',
              marginTop: entryIndex > 0 ? '16px' : '0'
            }}>
              {dosageEntries.length > 1 && (
                <span style={{ 
                  margin: 0,
                  fontSize: '13px',
                  color: 'rgba(0, 0, 0, 0.65)'
                }}>{entry.type}</span>
              )}
              {entryIndex > 0 && (
                <Button
                  type="text"
                  danger
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => handleRemoveDosageEntry(entryIndex)}
                />
              )}
            </div>
            <div className="dosage-instructions-container">
              <div className="field-container">
                <DosageFormField
                  selectedDosageForm={selectedDosageForm}
                  onSelect={handleDosageFormChange}
                  onClear={() => {
                    clearFieldsToRight('dosageForm')
                    handleDosageFormClear()
                  }}
                  className={getCurrentIncompleteField() === 'dosageForm' ? 'medication-field-incomplete' : ''}
                />
              </div>

              {selectedDosageForm && (
                <div className="field-container">
                  <RouteField
                    selectedRoute={selectedRoute}
                    availableRoutes={getAvailableRoutes(currentDosageForm)}
                    onSelect={handleRouteChange}
                    onClear={() => {
                      clearFieldsToRight('route')
                      handleRouteClear()
                    }}
                    className={getCurrentIncompleteField() === 'route' ? 'medication-field-incomplete' : ''}
                  />
                </div>
              )}

              {selectedDosageForm && selectedRoute && (
                <div className="field-container">
                  <DoseField
                    selectedDose={selectedDose}
                    defaultDose={med?.strengthMetric1}
                    scriptingDosage={med?.scriptingDosage}
                    unitOfMeasure={med?.unitOfMeasure1}
                    dosageForm={selectedDosageForm}
                    onSelect={handleDoseChange}
                    onClear={() => {
                      clearFieldsToRight('dose')
                      handleDoseClear()
                    }}
                    onInputChange={handleDoseInputChange}
                    onInputComplete={handleDoseInput}
                    className={getCurrentIncompleteField() === 'dose' ? 'medication-field-incomplete' : ''}
                  />
                </div>
              )}

              {selectedDosageForm && selectedRoute && selectedDose && (
                <div className="field-container">
                  <FrequencyField
                    selectedFrequency={selectedFrequency}
                    onSelect={handleFrequencyChange}
                    onClear={() => {
                      clearFieldsToRight('frequency')
                      handleFrequencyClear()
                    }}
                    onOnceOffSelect={() => {
                      handleDurationSelect('1')
                    }}
                    className={getCurrentIncompleteField() === 'frequency' ? 'medication-field-incomplete' : ''}
                  />
                </div>
              )}

              {selectedDosageForm && selectedRoute && selectedDose && selectedFrequency && (
                <div className="field-container">
                  <DurationField
                    selectedDuration={selectedDuration}
                    onSelect={handleDurationSelect}
                    onClear={() => {
                      clearFieldsToRight('duration')
                      handleDurationClear()
                    }}
                    className={getCurrentIncompleteField() === 'duration' ? 'medication-field-incomplete' : ''}
                  />
                </div>
              )}

              {selectedDosageForm && selectedRoute && selectedDose && selectedFrequency && selectedDuration && showEstQuantity && (
                <div className="field-container">
                  <EstimatedQuantity 
                    quantity={estimatedQuantity} 
                    suffix={quantitySuffix} 
                    onPropertyChange={onPropertyChange}
                    index={index}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
        
        <div style={{ marginTop: '16px' }}>
          <Tooltip title="Add a new dosage entry">
            <Button 
              type="text"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={handleAddDosageEntry}
              style={{ 
                width: 24,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '8px 0',
                borderRadius: '50%',
                border: `1px solid ${token.colorPrimary}`,
                color: token.colorPrimary,
                fontSize: '12px'
              }}
            />
          </Tooltip>
        </div>

        <AdditionalFields
          onRepeatChange={(value) => {
            handleRepeatChange(value)
            onPropertyChange(value, 'rpt', index)
          }}
          onICD10Select={(value) => {
            handleICD10Select(value)
            onPropertyChange(value, 'icd', index)
          }}
          onNotesChange={(value) => {
            onPropertyChange(value, 'additionalNotes', index)
          }}
          onPropertyChange={onPropertyChange}
          form={form}
          med={med}
          index={index}
          {...icd10Props}
        />
      </Card>
    </div>
  )
}

export default MedicationForm
