import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faHeartPulse, faUser } from '@fortawesome/free-solid-svg-icons'
import './MobileNavBar.css'
import landingImage from '../../assets/images/landing-image.png'
import { useDispatch, useSelector } from 'react-redux'
import coreReducer from '../utils/reducer'
import CoreEnums from '../utils/enums'
import PatDashboardWrapper from '../../custom/Patient/Dashboard'
import { MetaServices } from '../../custom/Patient/AI Tools/Meta/components/MetaServices'
import { theme } from 'antd'
import MobHealthStatsWrapper from '../../custom/Patient/Health Stats/Mobile/Components/MobHealthStatsWrapper'
import PatProfile from '../../custom/Patient/Profile'
import { deviceDetect } from 'react-device-detect'
import PatCareSync from '../../custom/Patient/CareSync'

const MobNavBar = ({ onNavigate, currentPage }) => {
  const { token } = theme.useToken()
  const coreState = useSelector((state) => state.core)
  const dispatch = useDispatch()
  const handleNavigate = (key) => {
    let component = <>`</>
    switch (key) {
      case CoreEnums.tabKeys.PAT_CARE_SYNC:
        component = <PatCareSync />
        break
      case CoreEnums.tabKeys.HOME:
        component = <PatDashboardWrapper />
        break
      case CoreEnums.tabKeys.PERSONAL_DETAILS:
        component = <PatProfile />
        break
      case CoreEnums.tabKeys.PAT_HEALTH_STATS:
        component = <MobHealthStatsWrapper />
        break
      case CoreEnums.tabKeys.META_SERVICES:
        // component = <MetaServices />
        return
    }
    dispatch(
      coreReducer.actions.addTab({
        key: key,
        closable: true,
        label: 'Virtual Consultation',
        children: component
      })
    )
  }
  const navItems = [
    {
      icon: faHome,
      label: 'Home',
      key: CoreEnums.tabKeys.HOME
    },
    {
      icon: faHeartPulse,
      label: 'Health',
      key: CoreEnums.tabKeys.PAT_HEALTH_STATS
    },
    {
      icon: faUser,
      label: 'Profile',
      key: CoreEnums.tabKeys.PERSONAL_DETAILS
    }
  ]

  return (
    <div className='mobile-nav-bar' style={deviceDetect().isMobile ? { background: '#fff' } : {}}>
      {navItems.map((item, index) => (
        <button key={index} className='nav-item' onClick={() => handleNavigate(item.key)}>
          <FontAwesomeIcon
            style={{ color: coreState.tabNavigation.activeKey === item.key ? token.colorPrimary : '#000000' }}
            icon={item.icon}
          />
          <span style={{ color: coreState.tabNavigation.activeKey === item.key ? token.colorPrimary : '#000000' }}>
            {item.label}
          </span>
        </button>
      ))}

      <div style={{ position: 'relative' }} onClick={() => handleNavigate(CoreEnums.tabKeys.PAT_CARE_SYNC)}>
        {coreState.tabNavigation.activeKey === CoreEnums.tabKeys.PAT_CARE_SYNC ? (
          <div
            style={{
              animation: 'neonBorderPulse 1.5s ease-in-out infinite',
              position: 'absolute',
              height: 48,
              width: 48,
              left: -1.5,
              top: -1.5,
              borderRadius: '50%',
              boxShadow: `
                inset 0 0 10px #10B2E1,
                inset 0 0 20px rgba(233, 30, 99, 0.5),
                0 0 10px #10B2E1,
                0 0 20px #10B2E1,
                0 0 30px rgba(233, 30, 99, 0.5)
              `
            }}
          ></div>
        ) : null}
        <div
          style={{
            height: 45,
            position: 'relative',
            width: 45,
            borderRadius: '50%',
            padding: 4
          }}
        >
          <img style={{ height: '100%' }} src={landingImage} alt='META' />
        </div>
      </div>
    </div>
  )
}

export default MobNavBar
