import { Col, Row, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import AuthEnums from '../utils/enums'

// Components
import Landing from './landing'
import AgiliteSkeleton from '../../reusable-components/AgiliteSkeleton'
import CheckinWrapper from './checkin-wrapper'
import PaymentStatus from './payment-status'

const Router = () => {
  const authState = useSelector((state) => state.auth)
  const [pathArray, setPathArray] = useState([])
  const { token } = theme.useToken()

  useEffect(() => {
    setPathArray(window.location.pathname.split('/').filter((p) => p !== '') || [])
  }, [])

  return (
    <>
      {authState.loading ? (
        <Row style={{ backgroundColor: token.colorBgBase, minHeight: '100vh' }}>
          <Col span={24}>
            <Row justify='center' style={{ marginTop: 20 }}>
              <Col span={12}>
                <center>
                  <AgiliteSkeleton skActive={false} spinnerTip='Initializing Portal' />
                </center>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <>
          {pathArray.length === 2 ? (
            <Routes>
              <Route
                path={`${pathArray[0]}${AuthEnums.routes.ROOT}`}
                element={<Landing isLogin={true} pathArray={pathArray} setPathArray={setPathArray} />}
              />
              <Route
                path={`${pathArray[0]}${AuthEnums.routes.LOGIN}`}
                element={<Landing isLogin={true} pathArray={pathArray} setPathArray={setPathArray} />}
              />
              <Route path={`${pathArray[0]}${AuthEnums.routes.CHECKIN}`} element={<CheckinWrapper />} />
              <Route
                path={`${pathArray[0]}${AuthEnums.routes.REGISTER}`}
                element={<Landing pathArray={pathArray} setPathArray={setPathArray} />}
              />
              <Route path={`${pathArray[0]}${AuthEnums.routes.PAY_STATUS}`} element={<PaymentStatus />} />
              {/* Any other routes default to root */}
              <Route path='*' element={<Landing isLogin={true} pathArray={pathArray} setPathArray={setPathArray} />} />
            </Routes>
          ) : (
            <Routes>
              <Route
                path={AuthEnums.routes.ROOT}
                element={<Landing isLogin={true} pathArray={pathArray} setPathArray={setPathArray} />}
              />
              <Route
                path={AuthEnums.routes.LOGIN}
                element={<Landing isLogin={true} pathArray={pathArray} setPathArray={setPathArray} />}
              />
              <Route path={AuthEnums.routes.CHECKIN} element={<CheckinWrapper />} />
              <Route
                path={AuthEnums.routes.REGISTER}
                element={<Landing pathArray={pathArray} setPathArray={setPathArray} />}
              />
              <Route path={AuthEnums.routes.PAY_STATUS} element={<PaymentStatus />} />
              {/* Any other routes default to root */}
              <Route path='*' element={<Landing isLogin={true} pathArray={pathArray} setPathArray={setPathArray} />} />
            </Routes>
          )}
        </>
      )}
    </>
  )
}

export default Router
