import { 
  faHospital, 
  faClipboardCheck, 
  faUserNurse,
  faUserMd,
  faVial,
  faFileInvoice,
  faHeartPulse,
  faPrescription
} from '@fortawesome/free-solid-svg-icons'

export const initialSteps = [
  {
    id: 1,
    icon: faHospital,
    title: 'Arrival',
    description: 'Welcome to the facility',
    status: 'wait',
    estimatedTime: '09:30',
    actualTime: null,
    estimatedDuration: '5 mins',
    actualDuration: null,
    billing: {
      facilityFee: {
        amount: 250,
        description: 'Facility Access Fee',
        coveredByMedicalAid: true,
        provider: 'Metro Health Clinic'
      }
    }
  },
  {
    id: 2,
    icon: faClipboardCheck,
    title: 'Registration',
    description: 'Complete check-in process',
    status: 'wait',
    estimatedTime: '09:35',
    actualTime: null,
    estimatedDuration: '10 mins',
    actualDuration: null,
    billing: {}
  },
  {
    id: 3,
    icon: faUserNurse,
    title: 'Vitals Check',
    description: 'Basic health measurements',
    status: 'wait',
    estimatedTime: '09:45',
    actualTime: null,
    estimatedDuration: '15 mins',
    actualDuration: null,
    billing: {
      vitalsCheck: {
        amount: 150,
        description: 'Basic Health Screening',
        coveredByMedicalAid: true,
        provider: 'Nursing Services'
      }
    }
  },
  {
    id: 4,
    icon: faUserMd,
    title: 'Doctor Consultation',
    description: 'Meet with Dr. Wilson',
    status: 'wait',
    estimatedTime: '10:00',
    actualTime: null,
    estimatedDuration: '30 mins',
    actualDuration: null,
    requiresApproval: true,
    approval: {
      id: 'cons-1',
      title: 'Consultation Fee',
      amount: 850,
      provider: 'Dr. Sarah Wilson',
      coveredByMedicalAid: true,
      details: {
        description: 'Specialist consultation including:\n• Initial assessment\n• Medical history review\n• Treatment planning',
        recommendation: 'Required consultation with Dr. Wilson to assess your condition and plan treatment.',
        duration: 'Consultation duration: 30 minutes',
        importance: 'Essential for diagnosis and treatment'
      }
    },
    billing: {
      consultationFee: {
        amount: 850,
        description: 'Specialist Consultation',
        coveredByMedicalAid: true,
        provider: 'Dr. Sarah Wilson'
      }
    }
  },
  {
    id: 5,
    icon: faVial,
    title: 'Blood Tests',
    description: 'Comprehensive blood panel & cardiac markers',
    status: 'wait',
    estimatedTime: '10:30',
    actualTime: null,
    estimatedDuration: '20 mins',
    actualDuration: null,
    requiresApproval: true,
    approval: {
      id: 'lab-1',
      title: 'Comprehensive Blood Panel',
      amount: 1200,
      provider: 'Metro Lab Services',
      coveredByMedicalAid: true,
      details: {
        description: 'Full cardiac and metabolic panel including:\n• Cardiac markers (Troponin, CK-MB)\n• Complete blood count\n• Lipid profile\n• Electrolytes',
        recommendation: 'Highly recommended by Dr. Wilson to assess cardiac function and overall health status.',
        duration: 'Results available within 24-48 hours',
        importance: 'Critical for accurate diagnosis and treatment planning'
      }
    },
    billing: {
      labFee: {
        amount: 1200,
        description: 'Comprehensive Blood Panel',
        coveredByMedicalAid: true,
        provider: 'Metro Lab Services'
      }
    }
  },
  {
    id: 6,
    icon: faHeartPulse,
    title: 'ECG',
    description: 'Heart monitoring',
    status: 'wait',
    estimatedTime: '10:50',
    actualTime: null,
    estimatedDuration: '15 mins',
    actualDuration: null,
    requiresApproval: true,
    approval: {
      id: 'ecg-1',
      title: 'ECG Procedure',
      amount: 750,
      provider: 'Cardiology Department',
      coveredByMedicalAid: true,
      details: {
        description: 'Electrocardiogram procedure including:\n• 12-lead ECG\n• Rhythm analysis\n• Digital recording',
        recommendation: 'Recommended by Dr. Wilson to assess heart function and rhythm.',
        duration: 'Results available immediately after procedure',
        importance: 'Important for cardiac assessment'
      }
    },
    billing: {
      ecgFee: {
        amount: 750,
        description: 'Electrocardiogram',
        coveredByMedicalAid: true,
        provider: 'Cardiology Department'
      }
    }
  },
  {
    id: 7,
    icon: faPrescription,
    title: 'Pharmacy',
    description: 'Collect prescribed medication',
    status: 'wait',
    estimatedTime: '11:05',
    actualTime: null,
    estimatedDuration: '15 mins',
    actualDuration: null,
    billing: {
      prescription: {
        amount: 350,
        description: 'Prescribed Medication',
        coveredByMedicalAid: false,
        provider: 'Metro Pharmacy'
      }
    }
  },
  {
    id: 8,
    icon: faFileInvoice,
    title: 'Checkout',
    description: 'Complete your visit',
    status: 'wait',
    estimatedTime: '11:20',
    actualTime: null,
    estimatedDuration: '10 mins',
    actualDuration: null,
    billing: {}
  }
]

export const statusMessages = [
  'Doctor is reviewing your file',
  'Preparing consultation room',
  'Doctor will be with you soon',
  'Almost ready for you'
] 