import React, { useEffect, useState } from 'react'
import BmiGraph from './bmi-graph'

const Bmi = ({ data, gender }) => {
  const [formattedData, setFormattedData] = useState([])

  useEffect(() => {
    let tmpData = data.concat()

    tmpData = tmpData.sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate))

    setFormattedData(tmpData)
  }, [data])

  return <BmiGraph data={formattedData} />
}

export default Bmi
