import CoreEnums from '../../core/utils/enums'
import store from '../../store'

const userRole = () => store?.getState()?.auth.agiliteUser?.extraData.role.type
const userProfession = () => store?.getState()?.auth.agiliteUser?.extraData.profession
export const userClinics = () => store?.getState()?.auth.agiliteUser?.extraData.clinics

export const isReception = () => {
  const role = userRole()
  return role === CoreEnums.userRoles.RECEPTION
}

export const isAdmin = () => {
  const role = userRole()
  return role === CoreEnums.userRoles.ADMIN
}

export const isDoctor = () => {
  const role = userRole()
  const profession = userProfession()
  return role === CoreEnums.userRoles.MEDICAL_PROFESSIONAL && profession === CoreEnums.userProfessions.DOCTOR
}

export const isNurse = () => {
  const role = userRole()
  const profession = userProfession()
  return role === CoreEnums.userRoles.MEDICAL_PROFESSIONAL && profession === CoreEnums.userProfessions.NURSE
}

export const isPatient = () => {
  const role = userRole()
  return role === CoreEnums.userRoles.PATIENT
}
