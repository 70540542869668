import { Col } from 'antd'
import CustomRow from '../../reusable-components/CustomRow'
import dayjs from 'dayjs'

export const SubscriptionsTemplate = () => {
  return {
    columnTemplate: [
      {
        title: 'Record',
        key: 'column1',
        responsive: ['xs'],
        render: (text, record) => {
          return (
            <CustomRow gutter={[4, 4]} style={{ textAlign: 'center' }}>
              <Col span={24}>
                <h2>{record.name}</h2>
              </Col>
              <Col span={24}>
                <p>{record.description} </p>
              </Col>
              <Col span={24}>
                <p>
                  {dayjs(record.startDate).format('YYYY-MM-DD')} - {dayjs(record.endDate).format('YYYY-MM-DD')}
                </p>
              </Col>
            </CustomRow>
          )
        }
      },
      {
        responsive: ['md'],
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        responsive: ['md'],
        title: 'Description',
        dataIndex: 'description',
        key: 'description'
      },
      {
        responsive: ['md'],
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (date) => dayjs(date).format('YYYY-MM-DD')
      },
      {
        responsive: ['md'],
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (date) => dayjs(date).format('YYYY-MM-DD')
      },
      {
        responsive: ['md'],
        title: 'Is Active',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (text) => (text ? 'Yes' : 'No')
      }
    ]
  }
}
