const BookingEnums = {
  profileKeys: {
    BOOKINGS: 'bookings'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update',
    COUNT: 'count',
    FIND_BY_ID: 'find_by_id',
    FIND_ONE: 'find_one',
    AGGREGATE: 'aggregate'
  }
}

export default BookingEnums
