import React from 'react'
import MobHome from './Mobile'
import { deviceDetect } from 'react-device-detect'
import PatientDashboard from '../../Dashboard/Patient/patient-dashboard'

// Always Render this component first
// Main wrapper for controls

const PatDashboardWrapper = () => {
  if (deviceDetect().isMobile) return <MobHome />
  else return <PatientDashboard />
}

export default PatDashboardWrapper
