export const STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  OVERDUE: 'overdue'
}

export const STATUS_CONFIG = {
  [STATUS.ACTIVE]: {
    color: 'processing',
    text: 'Active'
  },
  [STATUS.COMPLETED]: {
    color: 'success',
    text: 'Completed'
  },
  [STATUS.OVERDUE]: {
    color: 'error',
    text: 'Overdue'
  }
}
