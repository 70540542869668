import React from 'react'
import { motion } from 'framer-motion'
import { ExclamationOutlined } from '@ant-design/icons'
import '../styles/PaymentCancelled.css'
import PrevNextFloatingButtons from '../../Patient/Reusable Components/Buttons/PrevNextFloatingButtons'

const CancelledPayment = ({ onRetry, onBack, reason }) => {
  return (
    <>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='payment-cancelled-container'>
        <div className='cancelled-card'>
          <div className='warning-icon-wrapper'>
            <div className='warning-circle'></div>
            <ExclamationOutlined className='warning-icon' />
          </div>

          <h2>Payment Cancelled</h2>
          <p className='cancel-message'>{reason || 'Your payment has been cancelled'}</p>
        </div>
      </motion.div>
      <PrevNextFloatingButtons
        nextOnly={false}
        prevOnly={false}
        nextText='Try Again'
        prevText='Back to Home'
        onNext={onRetry}
        onPrev={onBack}
      />
    </>
  )
}

export default CancelledPayment
