import { Space, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import MetaLogo from '../../assets/images/landing-image.png'
import './index.css'

const CustomLoadingIcon = ({
  loading = true,
  iconProps,
  spinProps,
  loadingText = 'Loading...',
  content,
  indicatorOnly
}) => {
  const [spaceWidth, setSpaceWidth] = useState(300) // Initial width
  const loadingTextRef = useRef(null)
  useEffect(() => {
    const calculateTextWidth = () => {
      if (loadingTextRef.current) {
        const textElement = document.createElement('span')
        textElement.style.visibility = 'hidden'
        textElement.style.position = 'absolute'
        textElement.style.whiteSpace = 'nowrap'
        textElement.innerText = loadingText

        document.body.appendChild(textElement)
        const textWidth = textElement.offsetWidth
        document.body.removeChild(textElement)

        setSpaceWidth(textWidth + 72) // Add padding or any additional width as needed
      }
    }

    calculateTextWidth()
  }, [loadingText])

  if (indicatorOnly) {
    return <img className='custom-indicator-flash' style={{ height: 72, width: 72 }} src={MetaLogo} alt='Indicator' />
  } else {
    return (
      <Spin
        spinning={loading}
        indicator={
          <Space style={{ marginTop: -36, marginLeft: -(spaceWidth / 2), width: spaceWidth }} direction='vertical'>
            <img className='custom-indicator-flash' style={{ height: 72, width: 72 }} src={MetaLogo} alt='Indicator' />
            <p id='loadingTextContainer'>{loadingText}</p>
          </Space>
        }
      >
        {content}
      </Spin>
    )
  }
}

export default CustomLoadingIcon
