import dayjs from 'dayjs'
import React from 'react'

const DayHeader = ({ date }) => {
  const dayName = dayjs(date).format('dddd') // Get the full day name (e.g., Tuesday)
  const formattedDate = dayjs(date).format('MMMM DD') // Get the formatted date (e.g., April 26)
  return (
    <div>
      <span>{formattedDate}</span> / <span>{dayName}</span>
    </div>
  )
}

export default DayHeader
