import { useState, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import { STORAGE_KEY, DATE_FORMAT } from '../config/constants'
import { STATUS } from '../config/taskStatus'
import { createTask, readTasks, updateTask } from '../utils/taskUtils'
import { useSelector } from 'react-redux'
import { readSystemUsers } from '../../../../../../Admin/system-users/utils/utils'
import { useNotifications } from '../../../context/NotificationsContext'

const useTasks = () => {
  const coreState = useSelector((state) => state.core)
  const authState = useSelector((state) => state.auth)
  const [tasks, setTasks] = useState([])
  const [medicalProfs, setMedicalProfs] = useState([])
  const [loadingTasks, setLoadingTasks] = useState(false)
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
  const { addNotification } = useNotifications()
  // Initialize tasks from localStorage or use default if empty
  useEffect(() => {
    handleFetchTasks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFetchTasks = async () => {
    setLoadingTasks(true)
    try {
      const tasks = await readTasks({
        $or: [{ 'audit.createdBy': authState.agiliteUser._id }, { assignee: authState.agiliteUser._id }]
      })
      const tmpMedicalProfs = await readSystemUsers({
        $and: [
          { 'extraData.role.type': { $ne: 'patient' } },
          { 'extraData.role.type': { $ne: null } },
          { 'extraData.entities': coreState.entity._id }
        ]
      })
      setMedicalProfs(tmpMedicalProfs)
      setTasks(tasks)
    } catch (error) {
      console.error('Error fetching tasks:', error)
    } finally {
      setLoadingTasks(false)
    }
  }

  // Save to localStorage whenever tasks change
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(tasks))
  }, [tasks])

  // Check for overdue tasks every minute
  useEffect(() => {
    const checkOverdueTasks = () => {
      const now = dayjs()
      setTasks((prevTasks) => {
        const hasChanges = prevTasks.some(
          (task) => task.status === STATUS.PENDING && dayjs(task.dueDate, DATE_FORMAT).isBefore(now, 'day')
        )

        if (!hasChanges) return prevTasks // Skip update if no changes needed

        return prevTasks.map((task) => {
          if (task.status === STATUS.PENDING && dayjs(task.dueDate, DATE_FORMAT).isBefore(now, 'day')) {
            return { ...task, status: STATUS.OVERDUE }
          }
          return task
        })
      })
    }

    // Initial check
    checkOverdueTasks()

    // Set up interval for checking
    const interval = setInterval(checkOverdueTasks, 60000)
    return () => clearInterval(interval)
  }, [])

  const handleCreateTask = async (values) => {
    try {
      const newTask = {
        ...values,
        status: 'active'
      }
      const createdTask = await createTask(newTask)
      setIsCreateModalVisible(false)
      setTasks((prev) => [
        ...prev,
        {
          ...createdTask,
          assignedBy: authState.agiliteUser,
          assignedTo: medicalProfs.find((i) => i._id === newTask.assignee)
        }
      ])
    } catch (e) {
      console.error('Error creating task:', e)
    }
  }

  const editTask = useCallback(
    (taskId, values) => {
      setTasks((prev) =>
        prev.map((task) =>
          task.id === taskId
            ? {
                ...task,
                ...values,
                modifiedAt: new Date().toISOString(),
                modifiedBy: authState.agiliteUser._id,
                dueDate: values.dueDate.format(DATE_FORMAT)
              }
            : task
        )
      )
    },
    [authState.agiliteUser._id]
  )

  const deleteTask = useCallback((taskId) => {
    setTasks((prev) => prev.filter((task) => task.id !== taskId))
  }, [])

  const handleCompleteTask = async (taskId) => {
    const tmpTasks = JSON.parse(JSON.stringify(tasks))
    const taskIndex = tmpTasks.findIndex((task) => task._id === taskId)

    try {
      const newRecord = await updateTask(taskId, { status: STATUS.COMPLETED })
      console.log('newRecord', newRecord)
      addNotification({
        title: 'Task Completed',
        description: `Task "${newRecord.description}" has been marked as complete.`
      })
      tmpTasks[taskIndex].status = STATUS.COMPLETED
      setTasks(tmpTasks)
    } catch (e) {
      console.error('Error completing task:', e)
    }
  }
  return {
    tasks,
    handleCreateTask,
    editTask,
    deleteTask,
    loadingTasks,
    medicalProfs,
    isCreateModalVisible,
    setIsCreateModalVisible,
    handleCompleteTask
  }
}

export default useTasks
