import React, { useState, useEffect } from 'react'
import { Table, Space, Button, message, Popconfirm, Card, Row, Col, theme } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons'
import { readCareSyncLabTests, deleteCareSyncLabTest, countCareSyncLabTests } from '../utils/utils'
import CareSyncLabTestsForm from './Form'
import { handleError } from '../../../../lib/utils'

const CareSyncLabTestsListView = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [editRecord, setEditRecord] = useState(null)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  })

  const fetchData = async (page = 1, pageSize = 30) => {
    setLoading(true)
    try {
      const data = await readCareSyncLabTests({}, {}, page, pageSize)
      const total = await countCareSyncLabTests({})
      setPagination({
        ...pagination,
        total: total
      })
      setData(data)
    } catch (error) {
      message.error(handleError(error, true))
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [])

  const handleDelete = async (record) => {
    try {
      await deleteCareSyncLabTest({ key: record.key })
      message.success('Lab test deleted successfully')
      fetchData(pagination.current, pagination.pageSize)
    } catch (error) {
      message.error(handleError(error, true))
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: true
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => `R ${price}`
    },
    {
      title: 'Processing Time',
      dataIndex: 'processingTime',
      key: 'processingTime',
      render: (time) => `${time} hours`
    },
    {
      title: 'Availability',
      dataIndex: 'availability',
      key: 'availability',
      render: (availability) => (availability ? 'Yes' : 'No')
    },
    {
      title: 'Featured',
      dataIndex: 'featured',
      key: 'featured',
      render: (featured) => (featured ? 'Yes' : 'No')
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => (isActive ? 'Active' : 'Inactive')
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            type='primary'
            icon={<EditOutlined />}
            onClick={() => {
              setEditRecord(record)
              setShowForm(true)
            }}
          />
          <Popconfirm
            title='Are you sure you want to delete this lab test?'
            onConfirm={() => handleDelete(record)}
            okText='Yes'
            cancelText='No'
          >
            <Button type='primary' danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      )
    }
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    fetchData(pagination.current, pagination.pageSize)
  }

  const { token } = theme.useToken()

  return (
    <Card style={{ margin: '10px 10px 0 10px' }}>
      <Row style={{ marginBottom: 16 }} justify='space-between'>
        <Col>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => {
              setEditRecord(null)
              setShowForm(true)
            }}
          >
            Add Lab Test
          </Button>
        </Col>
        <Col>
          <Button
            icon={<ReloadOutlined />}
            onClick={() => fetchData(pagination.current, pagination.pageSize)}
            loading={loading}
            type='primary'
            style={{ backgroundColor: token.colorSuccess }}
          >
            Refresh
          </Button>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={data}
        rowKey='_id'
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        bordered
        size='small'
      />

      {showForm && (
        <CareSyncLabTestsForm
          visible={showForm}
          onCancel={() => {
            setShowForm(false)
            setEditRecord(null)
          }}
          onSuccess={() => {
            setShowForm(false)
            setEditRecord(null)
            fetchData(pagination.current, pagination.pageSize)
          }}
          record={editRecord}
        />
      )}
    </Card>
  )
}

export default CareSyncLabTestsListView
