import React from 'react'
import { Typography, Card, Tag, Button } from 'antd'

const { Text, Title } = Typography

const mockTests = [
  {
    name: 'Complete Blood Count',
    price: 'R 450',
    tag: 'Most Popular'
  },
  {
    name: 'Cardiac Panel',
    price: 'R 850',
    tag: 'Recommended'
  },
  {
    name: 'Kidney Function',
    price: 'R 550',
    tag: 'Essential'
  }
]

const TestMenuStep = () => {
  return (
    <div className='tour-step test-menu-step'>
      <Title level={3}>Easy Test Booking</Title>
      <Text className='feature-description'>
        Browse and book tests directly. Our AI helps recommend the right tests for you.
      </Text>

      <div className='mock-interface'>
        <div className='mock-tests'>
          {mockTests.map((test, index) => (
            <Card key={index} className='mock-test-card'>
              <Tag color='blue' className='test-tag'>
                {test.tag}
              </Tag>
              <div className='test-info'>
                <Text strong>{test.name}</Text>
                <br />
                <Text className='test-price'>{test.price}</Text>
              </div>
              <Button type='primary' size='small' className='book-btn'>
                Book
              </Button>
            </Card>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TestMenuStep
