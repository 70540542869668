import React, { useEffect } from 'react'
import { Col, theme, Select } from 'antd'
import CustomRow from '../../../../reusable-components/CustomRow'
import RepeatHistory from './RepeatHistory'
import ScriptItems from './ScriptItems'
import DischemModal from './DischemModal'
import { useScriptingRecord } from '../hooks/useScriptingRecord'
import LoadingState from './LoadingState'
import ScriptingTool from '../../Scripting tool'
import { useMedications } from '../../Scripting tool/hooks/useMedications'
import MedicationList from './MedicationList'
import SidePanel from '../../Scripting tool/components/SidePanel'
import ScriptingTemplatesModal from '../../scripting-templates-modal'
import { SearchOutlined } from '@ant-design/icons'
import SearchBar from '../../Scripting tool/components/SearchBar'

const ScriptingRecordView = ({
  isCopy,
  setIsCopy,
  isEditMode,
  setIsEditMode,
  data,
  handleCreateScript,
  isNew,
  handleCancel,
  loading,
  handleGenerateScriptTemplate,
  printingScript,
  patientChronicDetails,
  setPatientChronicDetails,
  patientId,
  selectedPatient,
  refreshView,
  getUserRef
}) => {
  const { token } = theme.useToken()

  const {
    script,
    setScript,
    scriptBusy,
    setScriptBusy,
    bypassing,
    saving,
    dischemModalOpen,
    setDischemModalOpen,
    dischemDeliveryApplicable,
    setDischemDeliveryApplicable,
    dischemType,
    setDischemType,
    dischemPaymentType,
    setDischemPaymentType,
    dischems,
    selectedDischem,
    setSelectedDischem,
    handleUpdateScheduleData,
    handleBypassNextView,
    handleSave,
    confirmReadOnly
  } = useScriptingRecord({
    data,
    isCopy,
    getUserRef,
    refreshView,
    setIsEditMode,
    isNew,
    isEditMode
  })

  const {
    medsList,
    setMedsList,
    currentMed,
    setCurrentMed,
    handleAdd,
    handleRemove,
    handleScriptDosageChange,
    handleExtraPropertyChange,
    rptRepeat,
    setRptRepeat,
    rptICD,
    setRptICD,
    currentDescFocus,
    setCurrentDescFocus,
    showClipboard,
    setShowClipboard,
    searchFilter,
    setSearchFilter,
    nappiOptions
  } = useMedications(script?.items || [])

  // Update script when medications list changes
  useEffect(() => {
    if (medsList && setScript) {
      setScript((prev) => ({
        ...prev,
        items: medsList
      }))
    }
  }, [medsList, setScript])

  if (bypassing) {
    return <LoadingState message='Bypassing next due date...' />
  }

  return (
    <CustomRow justify='center'>
      <Col xs={24} sm={24} md={script?.onSchedule || isNew || isCopy || isEditMode ? 24 : 16}>
        <CustomRow gutter={[0, 0]}>
          {script.onSchedule && !isEditMode && !isNew && (
            <RepeatHistory
              script={script}
              handleBypassNextView={handleBypassNextView}
              handleGenerateScriptTemplate={handleGenerateScriptTemplate}
              loading={loading}
              printingScript={printingScript}
              data={data}
            />
          )}

          {isNew || isCopy || isEditMode ? (
            <Col span={24}>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 24 }}>
                <MedicationList
                  medsList={medsList}
                  currentMed={currentMed}
                  dischemDeliveryApplicable={dischemDeliveryApplicable}
                  selectedDischem={selectedDischem}
                  dischemType={dischemType}
                  setDischemModalOpen={setDischemModalOpen}
                  setCurrentDescFocus={setCurrentDescFocus}
                  onAdd={handleAdd}
                  onRemove={handleRemove}
                  onDosageChange={handleScriptDosageChange}
                  onPropertyChange={handleExtraPropertyChange}
                  onEditMed={setCurrentMed}
                  rptRepeat={rptRepeat}
                  setRptRepeat={setRptRepeat}
                  rptICD={rptICD}
                  setRptICD={setRptICD}
                  patientChronicDetails={patientChronicDetails}
                  showClipboard={showClipboard}
                  setShowClipboard={setShowClipboard}
                  searchFilter={searchFilter}
                  setSearchFilter={setSearchFilter}
                  nappiOptions={nappiOptions}
                  script={script}
                  isNew={isNew}
                  isEditMode={isEditMode}
                  isCopy={isCopy}
                  handleUpdateScheduleData={handleUpdateScheduleData}
                  confirmReadOnly={confirmReadOnly}
                  handleGenerateScriptTemplate={handleGenerateScriptTemplate}
                  handleCreateScript={handleCreateScript}
                  scriptBusy={scriptBusy}
                  loading={loading}
                  printingScript={printingScript}
                  selectedPatient={selectedPatient}
                  handleCancel={handleCancel}
                />

                <SidePanel
                  patientChronicDetails={patientChronicDetails}
                  setShowClipboard={setShowClipboard}
                  setDischemModalOpen={setDischemModalOpen}
                  handleCancel={handleCancel}
                  handleCreateScript={handleCreateScript}
                  handleSave={handleSave}
                  scriptBusy={scriptBusy}
                  loading={loading}
                  printingScript={printingScript}
                  saving={saving}
                  script={script}
                  isNew={isNew}
                  isCopy={isCopy}
                  isEditMode={isEditMode}
                  dischemDeliveryApplicable={dischemDeliveryApplicable}
                  selectedDischem={selectedDischem}
                  dischemType={dischemType}
                  dischemPaymentType={dischemPaymentType}
                />
              </div>
            </Col>
          ) : (
            <ScriptItems script={script} data={data} />
          )}
        </CustomRow>

        <DischemModal
          open={dischemModalOpen}
          onCancel={() => setDischemModalOpen(false)}
          dischemDeliveryApplicable={dischemDeliveryApplicable}
          setDischemDeliveryApplicable={setDischemDeliveryApplicable}
          dischemType={dischemType}
          setDischemType={setDischemType}
          dischemPaymentType={dischemPaymentType}
          setDischemPaymentType={setDischemPaymentType}
          dischems={dischems}
          selectedDischem={selectedDischem}
          setSelectedDischem={setSelectedDischem}
        />

        <ScriptingTemplatesModal
          items={medsList}
          setItems={setMedsList}
          open={showClipboard}
          setOpen={setShowClipboard}
        />
      </Col>
    </CustomRow>
  )
}

export default ScriptingRecordView
