const CompContrTemplates = {
  dataModel: {
    name: {
      type: 'input',
      label: 'Provide the clinic`s name:',
      placeholder: 'e.g. Meta-Clinic Mill Park',
      key: 'name',
      required: true,
      validationMsg: 'Please provide the clinic`s name'
    }
  },

  dataTemplate: () => {
    return {
      name: null
    }
  },

  columnTemplate: (editRecord, filters) => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '20%',
        key: 'name',
        sorter: (a, b) => {
          return a.name.localeCompare(b.name)
        }
      },
      {
        title: 'Linked Users',
        dataIndex: 'linkedUsers',
        width: '20%',
        key: 'linkedUsers',
        render: (array) => {
          return array.length
        }
      }
    ]
  }
}

export default CompContrTemplates
