import Agilite from 'agilite'
import { handleError } from '../../../../../../lib/utils'
import store from '../../../../../../../store'
import { readSystemUsers } from '../../../../../../Admin/system-users/utils/utils'

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: process.env.REACT_APP_AGILITE_API_KEY
})

export const createTask = (data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        data.audit = {
          createdBy: store.getState().auth.agiliteUser._id,
          createdAt: new Date().toISOString(),
          modifiedAt: new Date().toISOString()
        }
        let response = null

        response = await agilite.Connectors.execute('task_manager', 'create', {
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const updateTask = (recordId, data) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Connectors.execute('task_manager', 'update', {
          recordId,
          data: JSON.stringify(data)
        })
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}

export const readTasks = (qry) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null
        let userResponse = null

        response = await agilite.Connectors.execute('task_manager', 'read', {
          filter: JSON.stringify(qry),
          page: null,
          pageLimit: null
        })

        console.log('Response', response)

        userResponse = await readSystemUsers(
          {
            _id: { $in: [...response.data.map((i) => i.audit.createdBy), ...response.data.map((i) => i.assignee)] }
          },
          '',
          '',
          'firstName lastName _id'
        )

        response.data.forEach((i) => {
          i.assignedBy = userResponse.find((j) => j._id === i.audit.createdBy)
          i.assignedTo = userResponse.find((j) => j._id === i.assignee)
        })
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}
export const getPriorityColor = (priority) => {
  if (!priority) return '#faad14' // default to medium color
  const colors = {
    high: '#f5222d',
    medium: '#faad14',
    low: '#52c41a'
  }
  return colors[priority.toLowerCase()] || colors.medium
}

export const getDisplayName = (user) => {
  if (!user) return 'Unknown'
  if (typeof user === 'string') return user
  return user.name || user.username || user.email || 'Unknown'
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const filterTasks = (tasks, { showArchived = false, searchText = '' }) => {
  return tasks.filter((task) => {
    const matchesArchiveState = showArchived ? task.archived : !task.archived
    const matchesSearch = searchText ? task.description.toLowerCase().includes(searchText.toLowerCase()) : true
    return matchesArchiveState && matchesSearch
  })
}

export const sortTasks = (tasks, sortBy = 'dueDate') => {
  return [...tasks].sort((a, b) => {
    switch (sortBy) {
      case 'dueDate':
        return new Date(a.dueDate) - new Date(b.dueDate)
      case 'status':
        return a.status.localeCompare(b.status)
      default:
        return 0
    }
  })
}

export const canDeleteTask = (task, currentUser) => {
  if (!task || !currentUser) return false
  return task.audit.createdBy === currentUser.id || currentUser.isAdmin
}

export const getTaskCounts = (tasks) => {
  return {
    active: tasks.filter((task) => !task.archived).length,
    archived: tasks.filter((task) => task.archived).length,
    total: tasks.length
  }
}
