import React from 'react'
import MobBookingShortcuts from './components/MobBookingShortcuts'
import MobQuickButtons from './components/MobQuickButtons'
import PromoCarousel from '../../Appointments/Mobile/components/PromoCarousel'
import useMobileWrapper from './hooks/useMobileWrapper'
import './styling/MobileDashboard.css'
import { Col, Row } from 'antd'

const MobHome = () => {
  const { handleQuickButtonClick, headerTitle } = useMobileWrapper()

  return (
    <Row gutter={[16, 16]} justify='center'>
      <Col span={24}>
        <MobBookingShortcuts showSOS />
      </Col>

      <Col span={24}>
        <PromoCarousel />
      </Col>

      <Col span={24}>
        <MobQuickButtons onButtonClick={handleQuickButtonClick} />
      </Col>
    </Row>
  )
}

export default MobHome
