import React from 'react'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { hexToRGBA } from '../../../../../lib/utils'

const ModalHeader = ({ isAddingTemplate, activeTemplate, resetForm, token }) => {
  return (
    <div
      style={{
        width: '100%',
        padding: 8,
        background: hexToRGBA(token.colorPrimary, 0.2),
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        top: 0,
        left: 0
      }}
    >
      <h2>
        {isAddingTemplate
          ? 'NEW TEMPLATE'
          : `NEW DATA CAPTURE - ${activeTemplate ? activeTemplate.name.toUpperCase() : 'CUSTOM TEMPLATE'}`}
      </h2>
      <Button onClick={resetForm} style={{ float: 'right', fontSize: 24, background: 'transparent', border: 'none' }}>
        <FontAwesomeIcon icon={faXmarkCircle} color={token.colorError} />
      </Button>
    </div>
  )
}

export default ModalHeader
