const ScriptEnums = {
  profileKeys: {
    SCRIPTS: 'scripts',
    SCRIPT_TEMPLATES: 'script_templates',
    DISCHEMS: 'dischems'
  },
  routeKeys: {
    READ: 'read',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    COUNT: 'count'
  }
}

export default ScriptEnums
