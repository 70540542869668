import { Col, Row, Space, Spin, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartPulse } from '@fortawesome/free-solid-svg-icons'
import { getPulseStatus } from '../utils'
import GraphGraphic from '../../../../assets/svg/Group 29.svg'

const HeartRateSimple = ({ data, loading }) => {
  const [bpm, setBpm] = useState(0)
  const cardStyle = {
    borderRadius: 12,
    boxShadow: '0 0 30px 0 #ECEDEE',
    padding: 12,
    minHeight: '100%'
  }
  const colorPrimary = '#F62188'
  const colorSecondary = 'rgba(246, 32, 135, 0.15)'
  const spaceStyle = {
    alignItems: 'center'
  }
  const iconStyle = {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colorSecondary,
    borderRadius: 10
  }
  useEffect(() => {
    if (data?.examinationData?.checkinData?.pulse) {
      setBpm(data.examinationData.checkinData.pulse)
    }
  }, [data])

  return (
    <Row style={cardStyle} gutter={[0, 10]}>
      <Col span={24}>
        <Space style={spaceStyle}>
          <div style={iconStyle}>
            <FontAwesomeIcon size='2x' color={colorPrimary} icon={faHeartPulse} />
          </div>
          <span>Heart Rate</span>
        </Space>
      </Col>
      <Col span={24}>
        <Space>
          <div style={{ fontSize: 28 }}>{loading ? <Spin spinning /> : bpm}</div>{' '}
          <div style={{ color: '#A1A6AC' }}>bpm</div>
        </Space>
      </Col>
      <Col span={24}>
        {loading ? undefined : (
          <Tag color={colorSecondary} style={{ color: 'black' }}>
            {data?.examinationData?.checkinData?.pulse ? getPulseStatus(bpm) : 'No Readings'}
          </Tag>
        )}
      </Col>
      <Col span={24}>
        <img src={GraphGraphic} alt='graph' style={{ width: '100%' }} />
        {/* <SimpleAreaChartExample data={dummyData} colorSecondary={colorSecondary} colorPrimary={colorPrimary} /> */}
      </Col>
    </Row>
  )
}

export default HeartRateSimple
