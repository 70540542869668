import { Button, Card, Col, DatePicker, Divider, Empty, Popconfirm, Select, Space, message, theme } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import CustomRow from '../../../../reusable-components/CustomRow'
import { faMedkit, faPills, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined } from '@ant-design/icons'
import { handleError, hexToRGBA } from '../../../../lib/utils'
import { debounce } from 'lodash'
import { MedPraxICD10Search, ProductSearchAgilite } from '../../../../Scripting/utils/utils'
import AgiliteSkeleton from '../../../../reusable-components/AgiliteSkeleton'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

const MedHistChronicIllnesses = ({ saveFunction, loading, isDependant, dependantData, setFacet }) => {
  const chronicIllnessState = useSelector((state) => state.medicalHistory.patient.chronicIllnesses.list)
  const [nappiLoading, setNappiLoading] = useState(false)
  const [icd10Loading, setIcd10Loading] = useState(false)
  const [ICD10SearchFilter, setICD10SearchFilter] = useState('')
  const [nappiSearchFilter, setNappiSearchFilter] = useState('')
  const [icd10Data, setIcd10Data] = useState([])
  const [illnessList, setIllnessList] = useState(
    isDependant && dependantData ? dependantData.chronicIllnesses.list : chronicIllnessState
  )
  const [nappiData, setNappiData] = useState([])
  const { token } = theme.useToken()
  const handleSubmit = () => {
    let tmpData = {}
    tmpData.chronicIllnesses = {}
    tmpData.chronicIllnesses.list = illnessList
    saveFunction(tmpData)
  }

  useEffect(() => {
    sortIllnessesByDateDiagnosed()
    // eslint-disable-next-line
  }, [])

  const getNappiData = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await ProductSearchAgilite(searchQuery)
        const finalData = []

        for (const entry of tmpData) {
          finalData.push({
            type: entry.type,
            ...entry.data
          })
        }

        setNappiData(finalData)
      } else {
        setNappiData([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setNappiLoading(false)
  }
  const getICD10Data = async (searchQuery) => {
    try {
      if (searchQuery) {
        const tmpData = await MedPraxICD10Search(searchQuery)
        setIcd10Data(tmpData)
      } else {
        setIcd10Data([])
      }
    } catch (e) {
      message.error(handleError(e, true))
    }

    setIcd10Loading(false)
  }

  useEffect(() => {
    setIcd10Loading(true)
    debounceICD10Filter(ICD10SearchFilter)

    // eslint-disable-next-line
  }, [ICD10SearchFilter])

  useEffect(() => {
    setNappiLoading(true)
    debouncedNappiFilter(nappiSearchFilter)

    // eslint-disable-next-line
  }, [nappiSearchFilter])

  // eslint-disable-next-line
  const debounceICD10Filter = useCallback(
    debounce((value) => {
      getICD10Data(value)
    }, 1000),
    []
  )

  // eslint-disable-next-line
  const debouncedNappiFilter = useCallback(
    debounce((value) => {
      getNappiData(value)
    }, 1000),
    []
  )

  const handleRemoveIllness = (index) => {
    let tmpIllnesses = [...illnessList]
    tmpIllnesses.splice(index, 1)
    setIllnessList(tmpIllnesses)
  }

  const handleAddIllness = (value) => {
    let tmpValue = JSON.parse(value)
    let tmpIllnesses = [...illnessList]
    let existingIndex = tmpIllnesses.findIndex((i) => i.code === tmpValue.code)

    if (existingIndex === -1) {
      tmpIllnesses.unshift({ ...tmpValue, dateDiagnosed: undefined, medications: [] }) // Use unshift() to add to the beginning
    } else {
      return message.error('Illness already added')
    }
    setIllnessList(tmpIllnesses)
  }

  const handleUpdateIllness = (date, illnessIndex) => {
    let tmpIllnessesList = [...illnessList]
    tmpIllnessesList[illnessIndex] = {
      ...tmpIllnessesList[illnessIndex],
      dateDiagnosed: date
    }
    setIllnessList(tmpIllnessesList)
  }

  const handleRemoveMedication = (illnessIndex, medIndex) => {
    let tmpIllnesses = [...illnessList]
    tmpIllnesses[illnessIndex].medications.splice(medIndex, 1)
    setIllnessList(tmpIllnesses)
  }

  const handleAddMedication = (value, index) => {
    let tmpValue = JSON.parse(value)
    let tmpIllnesses = [...illnessList]

    let tmpMedications = tmpIllnesses[index].medications
    tmpMedications.push({
      med: {
        ...tmpValue
      },
      startDate: dayjs(),
      endDate: ''
    })
    tmpIllnesses[index].medications = tmpMedications

    setIllnessList(tmpIllnesses)
  }

  const handleUpdateMedication = (date, illnessIndex, medIndex, key) => {
    let tmpIllnesses = [...illnessList]
    tmpIllnesses[illnessIndex].medications[medIndex][key] = date
    setIllnessList(tmpIllnesses)
  }

  const sortIllnessesByDateDiagnosed = () => {
    let tmpArray = [...illnessList]
    tmpArray = tmpArray.sort((a, b) => new Date(b.dateDiagnosed) - new Date(a.dateDiagnosed))
    setIllnessList(tmpArray)
  }

  const [showSearchBar, setShowSearchBar] = useState(false)
  return (
    <CustomRow gutter={[0, 12]}>
      <div
        style={{
          width: '100%',
          padding: 8,
          background: hexToRGBA(token.colorWarning, 0.1),
          marginTop: 1
        }}
      >
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <Button
            style={{
              backgroundColor: token.colorError,
              color: 'white'
            }}
            onClick={() => {
              setFacet('')
            }}
            loading={loading}
          >
            Close
          </Button>
          <Button
            style={{
              backgroundColor: token.colorSuccess,
              color: 'white'
            }}
            onClick={() => {
              handleSubmit()
            }}
            loading={loading}
          >
            Save Changes
          </Button>
        </Space>
      </div>
      <Col span={24}>
        <div>
          <div style={{ marginTop: '24px', marginLeft: '12px' }}>
            When adding a chronic condition, include the medication you are on.
          </div>

          <div style={{ marginTop: showSearchBar ? '12px' : '12px' }}>
            <Col style={{ paddingLeft: '12px', paddingBottom: '10px', marginTop: showSearchBar ? '-10px' : '' }}>
              <Button
                type='primary'
                style={{ background: token.colorPrimary, marginTop: showSearchBar ? '10px' : '10px' }}
                onClick={() => setShowSearchBar(!showSearchBar)}
              >
                Add Condition
              </Button>
            </Col>
          </div>

          {showSearchBar && (
            <div
              style={{
                marginTop: '1px',
                padding: '8px 12px 8px 12px',
                display: 'flex',
                justifyContent: 'space-between',
                background: '#f5f5f5',
                alignItems: 'center'
              }}
            >
              <Select
                suffixIcon={<SearchOutlined />}
                value={null}
                notFoundContent={icd10Loading ? <AgiliteSkeleton skActive spinnerTip='Search...' /> : undefined}
                onSearch={(searchValue) => {
                  setICD10SearchFilter(searchValue)
                }}
                onSelect={(value) => {
                  handleAddIllness(value)
                }}
                showSearch
                loading={icd10Loading}
                options={
                  icd10Loading
                    ? []
                    : icd10Data.map((i) => {
                        return {
                          label: i.code + ' - ' + i.description,
                          value: JSON.stringify({ code: i.code, description: i.description })
                        }
                      })
                }
                style={{
                  width: '100%',
                  border: 'none!important',
                  background: 'transparent',
                  outline: 'none'
                }}
                placeholder='Search for illnesses to add...'
                maxLength={50}
              />
            </div>
          )}
        </div>

        {illnessList.map((illness, illnessIndex) => {
          return (
            <>
              <Card
                style={{ borderRadius: 0, border: 'none' }}
                bodyStyle={{ padding: 0 }}
                headStyle={{ background: token.colorPrimary, borderRadius: 0, color: '#fff' }}
                type='inner'
                size='default'
                title={
                  <div
                    style={{
                      width: '100%',
                      display: 'grid',
                      gap: 8,
                      paddingTop: 4,
                      paddingBottom: 4,
                      gridTemplateColumns: '1fr auto',
                      whiteSpace: 'wrap',
                      alignItems: 'center'
                    }}
                  >
                    <p>{`${'Condition:'} ${illness.description} ${illness.code}`.toUpperCase()}</p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                      }}
                    >
                      <Popconfirm
                        overlayStyle={{ maxWidth: '90%', width: 400 }}
                        title={`Confirm Removing ${illness.description}`}
                        description='This action can not be undone. Are you sure?'
                        okText='REMOVE'
                        cancelText='KEEP'
                        cancelButtonProps={{ type: 'primary' }}
                        okButtonProps={{ type: 'primary', style: { background: token.colorError } }}
                        onConfirm={() => {
                          handleRemoveIllness(illnessIndex)
                        }}
                      >
                        {' '}
                        <Button>
                          <FontAwesomeIcon icon={faTrash} style={{ color: 'red' }} />
                        </Button>
                      </Popconfirm>
                    </div>
                  </div>
                }
              >
                {/* <Col style={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
                  <Button
                    type='primary'
                    style={{
                      backgroundColor: 'green',
                      marginLeft: '10px',
                      height: '20px',
                      padding: '0px 5px',
                      lineHeight: '10px'
                    }}
                    icon={<PlusOutlined style={{ fontSize: '12px', verticalAlign: 'middle', lineHeight: '20px' }} />}
                  />
                   <h1 style={{ color: 'green', paddingLeft: '10px', background: '#f5f5f5' }}>
                    Medication for condition:
                  </h1> 
                </Col> */}
                <div
                  style={{
                    padding: '8px 12px 8px 12px',
                    display: 'flex',
                    background: '#f5f5f5',
                    alignItems: 'center'
                  }}
                >
                  <p
                    style={{
                      paddingRight: '5px'
                    }}
                  >
                    Year Diagnosed:
                  </p>
                  <DatePicker
                    picker='year'
                    onChange={(date) => {
                      handleUpdateIllness(date, illnessIndex, 'date')
                    }}
                    value={illness.dateDiagnosed ? dayjs(illness.dateDiagnosed) : undefined}
                  />
                </div>
                <div
                  style={{
                    padding: '8px 12px 8px 12px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    background: hexToRGBA(token.colorPrimary, 0.4),
                    alignItems: 'center'
                  }}
                >
                  <b>MEDICATIONS</b>
                </div>
                <div
                  style={{
                    padding: '8px 12px 8px 12px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    background: '#f5f5f5',
                    alignItems: 'center'
                  }}
                >
                  <Select
                    value={null}
                    notFoundContent={nappiLoading ? <AgiliteSkeleton skActive spinnerTip='Search...' /> : undefined}
                    onSearch={(searchValue) => {
                      setNappiSearchFilter(searchValue)
                    }}
                    suffixIcon={<SearchOutlined />}
                    onSelect={(value) => {
                      handleAddMedication(value, illnessIndex)
                      setNappiData([])
                    }}
                    showSearch
                    loading={nappiLoading}
                    options={
                      nappiLoading
                        ? []
                        : nappiData.map((med) => {
                            return {
                              label: `${med.name} ${med.strengthMetric1 ? med.strengthMetric1 : ''} ${
                                med.unitOfMeasure1 ? med.unitOfMeasure1 : ''
                              } ${med.presentationCode ? med.presentationCode : ''} - ${
                                med.standardPacksize ? med.standardPacksize : ''
                              }`,
                              value: JSON.stringify(med)
                            }
                          })
                    }
                    style={{
                      width: '100%',
                      border: 'none',
                      background: 'transparent',
                      outline: 'none'
                    }}
                    placeholder='Search for medications to add...'
                    maxLength={50}
                  />
                </div>

                {illness.medications.length === 0 ? (
                  <Empty
                    style={{ marginTop: 24, marginBottom: 24 }}
                    image={<FontAwesomeIcon icon={faPills} />}
                    description={`Use the search box above to find your chronic medications prescribed for ${illness.description}  and add it to your chronic medications history.`}
                  />
                ) : undefined}
                {illness.medications.map((medication, medIndex) => {
                  return (
                    <Card
                      size='small'
                      type='inner'
                      title={
                        <div
                          style={{
                            width: '100%',
                            display: 'grid',
                            gap: 8,
                            gridTemplateColumns: '1fr auto',
                            whiteSpace: 'wrap'
                          }}
                        >
                          <p>
                            {medIndex + 1}) {medication.med.name} {medication.med.strengthMetric1}{' '}
                            {medication.med.unitOfMeasure1} {medication.med.rxUnit}
                          </p>
                        </div>
                      }
                      style={{ borderRadius: 0 }}
                      headStyle={{
                        borderRadius: 0,
                        background: hexToRGBA(token.colorPrimary, 0.1),
                        paddingTop: 4,
                        paddingBottom: 4
                      }}
                    >
                      <Space style={{ width: '100%' }}>
                        <p>Start Date:</p>
                        <DatePicker
                          onChange={(date) => {
                            handleUpdateMedication(date, illnessIndex, medIndex, 'startDate')
                          }}
                          value={dayjs(medication.startDate ? medication.startDate : undefined)}
                        />
                      </Space>
                      <Space style={{ width: '100%', marginTop: 12, alignItems: 'center' }}>
                        <p>End Date:</p>
                        {medication.endDate ? (
                          <DatePicker
                            onChange={(date) => {
                              handleUpdateMedication(date, illnessIndex, medIndex, 'endDate')
                            }}
                            value={dayjs(medication.startDate ? medication.endDate : undefined)}
                          />
                        ) : (
                          <Popconfirm
                            overlayStyle={{ maxWidth: '90%', width: 400 }}
                            title={`End medication period for ${medication.med.name}`}
                            description='This action can not be undone.'
                            okText='END USE'
                            cancelText='CONTINUE USE'
                            cancelButtonProps={{ type: 'primary' }}
                            okButtonProps={{ type: 'primary', style: { background: token.colorError } }}
                            onConfirm={() => {
                              handleUpdateMedication(dayjs(), illnessIndex, medIndex, 'endDate')
                            }}
                          >
                            <Button>Click to end</Button>
                          </Popconfirm>
                        )}
                      </Space>
                      <Divider style={{ margin: '24px 0px 8px 0px' }} />
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <Popconfirm
                          overlayStyle={{ maxWidth: '90%', width: 400 }}
                          title={`Confirm Removing ${medication.med.name}`}
                          description='Once removed, this action can`t be undone.'
                          okText='REMOVE'
                          cancelText='KEEP'
                          cancelButtonProps={{ type: 'primary' }}
                          okButtonProps={{ type: 'primary', style: { background: token.colorError } }}
                          onConfirm={() => {
                            handleRemoveMedication(illnessIndex, medIndex)
                          }}
                        >
                          <Button style={{ border: 'none', background: 'transparent', color: token.colorError }}>
                            Remove
                          </Button>
                        </Popconfirm>
                      </div>
                    </Card>
                  )
                })}
              </Card>
              <div style={{ width: '100%', height: 10, background: '#ccc' }}></div>
            </>
          )
        })}
        {illnessList.length === 0 ? (
          <Empty
            style={{ marginTop: 24 }}
            image={<FontAwesomeIcon icon={faMedkit} />}
            description='Use the search box above to find your chronic illnesses by code or description and add it to your chronic illness history.'
          />
        ) : undefined}
        <center style={{ marginTop: 24, marginBottom: 24 }}>
          <Button
            style={{
              backgroundColor: token.colorSuccess,
              color: 'white',
              marginRight: 10
            }}
            onClick={() => {
              handleSubmit()
            }}
            loading={loading}
          >
            Save Changes
          </Button>
        </center>
      </Col>
    </CustomRow>
  )
}

export default MedHistChronicIllnesses
