import React, { memo, useState } from 'react'
import { Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInfoCircle,
  faLaptopMedical,
  faUser,
  faCheckCircle,
  faBolt,
  faCalendar
} from '@fortawesome/free-solid-svg-icons'
import '../styling/MobileVirtualVisits.css'
import MobInfoCarousel from './MobInfoCarousel'
import MobUberVirtual from './MobUberVirtual'
import MobTransparentButton from '../../../Reusable Components/Buttons/MobTransparentButton'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../../../core/utils/reducer'
import { random } from 'lodash'
import { hexToRGBA } from '../../../../lib/utils'

const MobVirtualVisitsIntro = memo(({ nextStep }) => {
  const dispatch = useDispatch()
  const [showInfo, setShowInfo] = useState(false)
  const [showUberVirtual, setShowUberVirtual] = useState(false)

  return (
    <div className='mobile-virtual-visits'>
      {showUberVirtual ? (
        <MobUberVirtual onBack={() => setShowUberVirtual(false)} />
      ) : (
        <>
          <div className='virtual-content'>
            <MobInfoCarousel />
          </div>

          <div className='virtual-buttons'>
            <div style={{ position: 'relative' }}>
              <MobTransparentButton
                text='Quick Connect'
                functionCall={() => {}}
                icon={faBolt}
                style={{ backgroundColor: hexToRGBA('#daa520', 0.1), color: 'goldenrod', borderColor: 'goldenrod' }}
                disabled={true}
              />
              <span
                style={{
                  position: 'absolute',
                  top: '-10px',
                  right: '-10px',
                  backgroundColor: '#FF6B6B',
                  color: 'white',
                  padding: '2px 6px',
                  borderRadius: '10px',
                  fontSize: '0.7rem',
                  fontWeight: 'bold'
                }}
              >
                Coming Soon
              </span>
            </div>

            {/* TODO: @Malachi, follow what this button is doing, you will see how I use the Agora Video Call Component */}
            {/* channelId is bookingId */}
            {/* userId is userRef */}
            {/* TODO: @Malachi, remove this button once you have tested the video call component */}
            {/* TODO: @Malachi, also send actual user doctor/patient info with this component */}
            {/* <MobTransparentButton
              text='Test Video Call Component'
              functionCall={() => {
                dispatch(
                  coreReducer.actions.addTab({
                    key: 'video-call',
                    closable: true,
                    label: 'Virtual Consultation',
                    children: <MobUberVirtual channelId={123456789} userId={'123456789'} />
                  })
                )
              }}
              icon={faVideo}
            /> */}

            <MobTransparentButton
              text='Schedule'
              functionCall={() => {
                nextStep()
              }}
              icon={faCalendar}
            />
          </div>

          {/* <Button
            className='info-button'
            icon={<FontAwesomeIcon icon={faInfoCircle} />}
            onClick={() => setShowInfo(true)}
          /> */}

          <Modal
            title='How Virtual Consultations Work'
            open={showInfo}
            onCancel={() => setShowInfo(false)}
            footer={null}
            className='info-modal'
            destroyOnClose
          >
            <div className='steps-list'>
              <div className='info-step'>
                <FontAwesomeIcon icon={faUser} className='step-icon' />
                <div className='step-text'>
                  <h4>Choose Your Doctor</h4>
                  <p>Select from available time slots with our qualified doctors</p>
                </div>
              </div>

              <div className='info-step'>
                <FontAwesomeIcon icon={faLaptopMedical} className='step-icon' />
                <div className='step-text'>
                  <h4>Join Video Call</h4>
                  <p>Connect with your doctor via secure video consultation</p>
                </div>
              </div>

              <div className='info-step'>
                <FontAwesomeIcon icon={faCheckCircle} className='step-icon' color='gold' />
                <div className='step-text'>
                  <h4>Get Care</h4>
                  <p>Receive diagnosis, prescriptions, and follow-up care as needed</p>
                </div>
              </div>

              <div className='info-note'>
                <FontAwesomeIcon icon={faInfoCircle} className='note-icon' />
                <p>
                  Consultations typically last 15-20 minutes. Have your medical history and any current symptoms ready
                  to discuss.
                </p>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  )
})

MobVirtualVisitsIntro.displayName = 'MobileVirtualVisitsIntro'
export default MobVirtualVisitsIntro
