import dayjs from 'dayjs'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts'
import { stringToColor } from '../../lib/utils'
import './graph-styles.css'

/**
 * StandardDataPointGraph component for rendering line charts of medical data points
 * @param {Object} props Component props
 * @param {Object} props.data Data object containing records array
 * @param {Object} props.dataTemplate Template defining data structure and display
 * @returns {JSX.Element} Line chart visualization
 */
export const StandardDataPointGraph = React.memo(
  ({ data, dataTemplate }) => {
    const DATE_FORMAT = 'DD-MM-YYYY'
    const [graphData, setGraphData] = useState([])

    // Define isSingleDataPoint early to avoid reference errors
    const isSingleDataPoint = data?.records?.length === 1

    // Sort records chronologically
    const sortGraphData = useMemo(
      () => (records) => records.sort((a, b) => dayjs(a.dateCreated).valueOf() - dayjs(b.dateCreated).valueOf()),
      []
    )

    // Transform raw data into chart-compatible format
    const handleSetGraphData = useCallback(() => {
      if (!data?.records?.length) {
        setGraphData([])
        return
      }

      try {
        const sortedRecords = sortGraphData([...data.records])
        const newGraphData = sortedRecords.map((record) => {
          const baseObject = {
            name: dayjs(record.dateCreated).format(DATE_FORMAT),
            timestamp: dayjs(record.dateCreated).valueOf(), // For proper sorting
            date: record.dateCreated // Keep original date for tooltip
          }

          if (Array.isArray(record.value)) {
            record.value.forEach(({ key, value }) => {
              if (key && value !== undefined) {
                baseObject[key] = Number(value) // Ensure numeric values
              }
            })
          } else if (record.key && record.value !== undefined) {
            baseObject[record.key] = Number(record.value)
          }

          return baseObject
        })

        // Compare new data with existing data to prevent unnecessary updates
        if (JSON.stringify(newGraphData) !== JSON.stringify(graphData)) {
          setGraphData(newGraphData)
        }
      } catch (error) {
        console.error('Error processing graph data:', error)
        setGraphData([])
      }
    }, [data?.records, sortGraphData, graphData, DATE_FORMAT])

    useEffect(() => {
      handleSetGraphData()
    }, [handleSetGraphData])

    // Add debugging logs - only in development and only when data changes
    useEffect(() => {
      if (process.env.NODE_ENV !== 'production') {
        console.debug('StandardDataPointGraph data changed:', {
          hasRecords: !!data?.records?.length,
          recordCount: data?.records?.length || 0,
          graphDataLength: graphData?.length || 0,
          dataKey: data?.key,
          isSingleDataPoint
        })
      }
      // Only depend on data.records to prevent unnecessary re-renders
    }, [data?.records?.length, data?.key, isSingleDataPoint])

    // Check if this is a blood pressure chart
    const isBloodPressureChart = useMemo(() => {
      return dataTemplate?.subFields?.some((field) => field.key === 'systolic' || field.key === 'diastolic')
    }, [dataTemplate])

    // Custom label component for data points
    const CustomLabel = useMemo(
      () =>
        ({ x, y, stroke, value, index, dataKey }) => {
          // Position labels above the dots with more space
          // For blood pressure, position systolic above and diastolic below
          let verticalOffset = -14 // Increased from -12 to -14 for more space

          if (isBloodPressureChart) {
            if (dataKey === 'systolic') {
              verticalOffset = -18 // Position systolic higher (increased from -16)
            } else if (dataKey === 'diastolic') {
              verticalOffset = 18 // Position diastolic lower (increased from 16)
            }
          }

          return (
            value && (
              <text
                x={x}
                y={y}
                dy={verticalOffset}
                fill={stroke}
                fontSize={13}
                textAnchor='middle'
                fontWeight='600' // Increased from 500 to 600
                className={`recharts-data-label ${dataKey}-label`}
                style={{
                  filter: 'drop-shadow(0px 0px 3px white)',
                  paintOrder: 'stroke fill', // Ensures stroke is behind the text
                  stroke: 'white',
                  strokeWidth: '2px'
                }}
              >
                {value}
              </text>
            )
          )
        },
      [isBloodPressureChart]
    )

    // Get a better color for a specific vital sign
    const getVitalSignColor = useCallback((key) => {
      const colorMap = {
        systolic: '#FF8800', // Orange for systolic
        diastolic: '#1976D2', // Bright blue for diastolic
        pulse: '#8E24AA', // Purple for pulse (changed from red)
        temperature: '#9C27B0', // Purple for temperature
        respiratoryRate: '#66BB6A', // Green for respiratory rate
        oxygenSaturation: '#29B6F6' // Light blue for oxygen
      }

      return colorMap[key] || stringToColor(key)
    }, [])

    // Generate Line components based on data structure
    const chartLines = useMemo(() => {
      if (!dataTemplate) return null

      if (dataTemplate.subFields?.length > 0) {
        return dataTemplate.subFields.map((subField, index) => {
          // Get a formatted name for the legend
          let displayName = subField.label || subField.key
          if (subField.key === 'oxygenSaturation') {
            displayName = 'Oxygen Saturation'
          } else if (subField.key === 'respiratoryRate') {
            displayName = 'Respiratory Rate'
          } else if (subField.key === 'systolic') {
            displayName = 'Systolic'
          } else if (subField.key === 'diastolic') {
            displayName = 'Diastolic'
          }

          return (
            <Line
              key={subField.key}
              type='monotone'
              dataKey={subField.key}
              name={displayName}
              stroke={getVitalSignColor(subField.key)}
              strokeWidth={2}
              activeDot={{
                r: 8,
                fill: getVitalSignColor(subField.key),
                stroke: '#fff',
                strokeWidth: 2,
                className: 'recharts-active-dot-custom'
              }}
              dot={{
                r: 4,
                fill: getVitalSignColor(subField.key),
                stroke: '#fff',
                strokeWidth: 1,
                className: 'recharts-dot-custom'
              }}
              label={<CustomLabel />}
              connectNulls
              animationDuration={750}
              animationEasing='ease-in-out'
              isAnimationActive={true}
            />
          )
        })
      }

      // Get a formatted name for the legend
      let displayName = dataTemplate.label || data?.key
      if (data?.key === 'oxygenSaturation') {
        displayName = 'Oxygen Saturation'
      } else if (data?.key === 'respiratoryRate') {
        displayName = 'Respiratory Rate'
      } else if (data?.key === 'pulse') {
        displayName = 'Pulse'
      } else if (data?.key === 'temperature') {
        displayName = 'Temperature'
      }

      return (
        <Line
          key={data?.key}
          type='monotone'
          dataKey={data?.key}
          name={displayName}
          stroke={getVitalSignColor(data?.key)}
          strokeWidth={2}
          activeDot={{
            r: 8,
            fill: getVitalSignColor(data?.key),
            stroke: '#fff',
            strokeWidth: 2,
            className: 'recharts-active-dot-custom'
          }}
          dot={{
            r: 4,
            fill: getVitalSignColor(data?.key),
            stroke: '#fff',
            strokeWidth: 1,
            className: 'recharts-dot-custom'
          }}
          label={<CustomLabel />}
          connectNulls
          animationDuration={750}
          animationEasing='ease-in-out'
        />
      )
    }, [dataTemplate, data, CustomLabel, getVitalSignColor])

    // Custom tooltip component
    const CustomTooltip = useCallback(
      ({ active, payload, label }) => {
        if (!active || !payload || !payload.length) {
          return null
        }

        const dataPoint = graphData.find((item) => item.name === label)
        const formattedDate = dataPoint ? dayjs(dataPoint.date).format('DD MMM YYYY HH:mm') : label

        return (
          <div
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              border: 'none',
              borderRadius: '4px',
              padding: '10px 14px',
              boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08)'
            }}
          >
            <p style={{ margin: '0 0 5px', fontWeight: 'bold', fontSize: '12px' }}>{formattedDate}</p>
            {payload.map((entry, index) => {
              // For blood tests, use the test name from dataTemplate.label
              // For vital signs, use specific display names
              let displayLabel

              // Special handling for blood pressure and other vital signs
              if (entry.dataKey === 'systolic') {
                displayLabel = 'Systolic'
              } else if (entry.dataKey === 'diastolic') {
                displayLabel = 'Diastolic'
              } else if (entry.dataKey === 'oxygenSaturation') {
                displayLabel = 'Oxygen Saturation'
              } else if (entry.dataKey === 'respiratoryRate') {
                displayLabel = 'Respiratory Rate'
              } else if (dataTemplate?.subFields?.length > 0) {
                // For multi-field data templates (like blood pressure)
                displayLabel =
                  dataTemplate.subFields?.find((field) => field.key === entry.dataKey)?.label || entry.dataKey
              } else {
                // For single-field data templates (like blood tests)
                displayLabel = dataTemplate?.label || entry.dataKey
              }

              // Format the value with the suffix if available
              const suffix = dataTemplate?.suffix || ''
              const formattedValue = `${entry.value}${suffix ? ` ${suffix}` : ''}`

              return (
                <p key={`item-${index}`} style={{ margin: '2px 0', fontSize: '12px' }}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      backgroundColor: entry.color,
                      marginRight: '5px'
                    }}
                  ></span>
                  <span style={{ fontWeight: '500' }}>{displayLabel}: </span>
                  <span>{formattedValue}</span>
                </p>
              )
            })}
          </div>
        )
      },
      [graphData, dataTemplate]
    )

    // Custom legend formatter
    const renderLegend = useCallback(
      (value, entry) => {
        // Use the entry.payload.name which comes from the Line component's name prop
        if (entry && entry.payload && entry.payload.name) {
          return (
            <span
              style={{
                color: entry.color,
                fontWeight: '500',
                fontSize: '14px', // Increased from 12px
                padding: '0 8px'
              }}
            >
              {entry.payload.name}
            </span>
          )
        }

        // Fallback formatting if name is not available
        let displayLabel = value

        // Special handling for common vital signs with camelCase keys
        if (value === 'oxygenSaturation') {
          displayLabel = 'Oxygen Saturation'
        } else if (value === 'respiratoryRate') {
          displayLabel = 'Respiratory Rate'
        }

        return (
          <span
            style={{
              color: getVitalSignColor(value),
              fontWeight: '500',
              fontSize: '14px', // Increased from 12px
              padding: '0 8px'
            }}
          >
            {displayLabel}
          </span>
        )
      },
      [getVitalSignColor]
    )

    // Calculate appropriate Y-axis domain - always define this hook
    const calculateYDomain = useMemo(() => {
      if (!graphData || graphData.length === 0) {
        return [0, 100] // Default domain if no data
      }

      let min = Infinity
      let max = -Infinity

      graphData.forEach((item) => {
        if (dataTemplate?.subFields?.length > 0) {
          dataTemplate.subFields.forEach((field) => {
            if (item[field.key] !== undefined) {
              min = Math.min(min, item[field.key])
              max = Math.max(max, item[field.key])
            }
          })
        } else if (item[data?.key] !== undefined) {
          min = Math.min(min, item[data.key])
          max = Math.max(max, item[data.key])
        }
      })

      // Add padding to the domain
      const padding = (max - min) * 0.15 // Increased padding for better label visibility
      // Add extra padding at the top and bottom for labels
      return [Math.max(0, min - padding), max + padding]
    }, [graphData, dataTemplate, data?.key])

    // For single data point, create a reference line - always define this hook
    const renderSinglePointReferenceLine = useMemo(() => {
      if (!isSingleDataPoint || !graphData || graphData.length === 0) {
        return null
      }

      // Get the value for the reference line
      try {
        // Only render the reference line if we have valid data
        return <ReferenceLine x={graphData[0].name} stroke='rgba(0,0,0,0.1)' strokeDasharray='3 3' />
      } catch (error) {
        console.error('Error rendering reference line:', error)
        return null
      }
    }, [isSingleDataPoint, graphData])

    // Early return after all hooks are defined
    if (!data?.records?.length || !dataTemplate) {
      return null
    }

    // Additional check for graphData after all hooks are defined
    if (!graphData || graphData.length === 0) {
      return null
    }

    const startDate = dayjs(data.records[0].dateCreated).format(DATE_FORMAT)
    const endDate = dayjs(data.records[data.records.length - 1].dateCreated).format(DATE_FORMAT)

    return (
      <ResponsiveContainer width='100%' height={280} style={{ background: 'white' }}>
        <LineChart
          data={graphData}
          margin={{
            top: 40, // Increased from 30 to 40 to accommodate larger labels
            right: 30,
            left: 20,
            bottom: 35 // Increased from 25 to 35 for better legend spacing
          }}
          className='vital-signs-chart'
        >
          <CartesianGrid strokeDasharray='3 3' stroke='rgba(0, 0, 0, 0.05)' />
          <XAxis
            dataKey='name'
            ticks={startDate !== endDate ? [startDate, endDate] : [startDate]}
            stroke='rgba(0, 0, 0, 0.65)'
            tick={{ fontSize: 12 }}
            tickLine={{ stroke: 'rgba(0, 0, 0, 0.15)' }}
            padding={{ left: 30, right: 30 }}
            axisLine={{ strokeWidth: 1.5 }}
            // For single data point, adjust the domain to center the point
            domain={isSingleDataPoint ? ['dataMin - 1', 'dataMax + 1'] : ['dataMin', 'dataMax']}
          />
          <YAxis
            domain={calculateYDomain}
            stroke='rgba(0, 0, 0, 0.65)'
            tick={{ fontSize: 12 }}
            tickLine={{ stroke: 'rgba(0, 0, 0, 0.15)' }}
            padding={{ top: 20, bottom: 20 }}
            axisLine={{ strokeWidth: 1.5 }}
            tickFormatter={(value) => {
              // Format all vital signs including temperature as integers
              return Math.round(value) // Round to integer for all vital signs
            }}
          />
          <Tooltip
            content={<CustomTooltip />}
            // Position the tooltip away from the data point
            offset={20}
            wrapperStyle={{ zIndex: 1000 }}
          />
          <Legend
            formatter={renderLegend}
            wrapperStyle={{
              paddingTop: 25,
              paddingBottom: 10,
              fontSize: '14px',
              marginTop: 10
            }}
            iconType='circle'
            iconSize={10} // Increased from 8
          />
          {renderSinglePointReferenceLine}
          {chartLines}
        </LineChart>
      </ResponsiveContainer>
    )
  },
  (prevProps, nextProps) => {
    // Custom comparison function for React.memo
    // Only re-render if the data records have changed
    if (!prevProps.data?.records && !nextProps.data?.records) {
      return true // Both are null/undefined, consider them equal
    }

    if (!prevProps.data?.records || !nextProps.data?.records) {
      return false // One is null/undefined but not the other
    }

    if (prevProps.data.records.length !== nextProps.data.records.length) {
      return false // Different number of records
    }

    // Check if the records are the same by comparing their IDs and values
    const prevIds = prevProps.data.records.map((r) => r.id || r._id).join(',')
    const nextIds = nextProps.data.records.map((r) => r.id || r._id).join(',')

    if (prevIds !== nextIds) {
      return false // Different record IDs
    }

    // If we get here, consider the props equal
    return true
  }
)
