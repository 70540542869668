import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Form, Select, Input, Checkbox } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { ADDITIONAL_NOTES_OPTIONS } from '../constants/additionalNotes'
import AgiliteSkeleton from '../../../../../../reusable-components/AgiliteSkeleton'
import '../styles/medicationCard.css'

const { TextArea } = Input

// Move formatSelectedInstruction before it's used
const formatSelectedInstruction = (value) => {
  switch (value) {
    case 'mornings':
      return 'Take in the morning'
    case 'afternoons':
      return 'Take in the afternoon'
    case 'evenings':
      return 'Take in the evening'
    case 'with food':
      return 'Take with food'
    case 'empty stomach':
      return 'Take on an empty stomach'
    default:
      return value
  }
}

const AdditionalFields = ({
  onRepeatChange,
  onICD10Select,
  icd10Loading,
  icd10Data,
  setICD10SearchFilter,
  form,
  onNotesChange,
  onPropertyChange,
  med,
  index
}) => {
  const [selectedInstructions, setSelectedInstructions] = useState([])
  const [additionalNotes, setAdditionalNotes] = useState('')
  const [repeatValue, setRepeatValue] = useState('No Repeat')
  const [icdValue, setIcdValue] = useState('')
  const textAreaRef = useRef(null)

  // Initialize form with existing values
  useEffect(() => {
    if (med) {
      if (med.additionalNotes) {
        setAdditionalNotes(med.additionalNotes)
      }

      // Set repeat value from med object
      const repeat = med?.repeat || med?.rpt || 'No Repeat'

      setRepeatValue(repeat)

      // Set ICD value from med object
      if (med.icd) {
        setIcdValue(med.icd)
      }

      // Set form values
      form.setFieldsValue({
        noSubstitute: med.noSubstitute || false,
        icd: med.icd || null
      })

      // Try to match existing notes with predefined instructions
      if (med.additionalNotes) {
        const matchedInstructions = ADDITIONAL_NOTES_OPTIONS.filter((option) => {
          const formattedInstruction = formatSelectedInstruction(option.value)
          return med.additionalNotes.toLowerCase().includes(formattedInstruction.toLowerCase())
        }).map((option) => option.value)

        setSelectedInstructions(matchedInstructions)
      }
    }
  }, [med, form])

  const handleInstructionsChange = useCallback(
    (values) => {
      const cleanValues = values.map((val) => (typeof val === 'string' ? val.trim() : val)).filter(Boolean)

      setSelectedInstructions(cleanValues)

      // Generate formatted instructions text
      const formattedInstructions = cleanValues.map(formatSelectedInstruction).filter(Boolean).join(', ')

      // Update state
      setAdditionalNotes(formattedInstructions)

      // Call callbacks
      onNotesChange?.(formattedInstructions)
      onPropertyChange?.(formattedInstructions, 'additionalNotes', index)
    },
    [formatSelectedInstruction, onNotesChange, onPropertyChange, index]
  )

  const handleNotesChange = useCallback(
    (e) => {
      const value = e.target.value
      setAdditionalNotes(value)

      onNotesChange?.(value)
      onPropertyChange?.(value, 'additionalNotes', index)
    },
    [onNotesChange, onPropertyChange, index]
  )

  const handleClearInstructions = useCallback(() => {
    setSelectedInstructions([])
    setAdditionalNotes('')

    onNotesChange?.('')
    onPropertyChange?.('', 'additionalNotes', index)
  }, [onNotesChange, onPropertyChange, index])

  const handleRepeatChange = useCallback(
    (value) => {
      setRepeatValue(value)
      onRepeatChange?.(value)
      onPropertyChange?.(value, 'repeat', index)
      onPropertyChange?.(value, 'rpt', index)
    },
    [onRepeatChange, onPropertyChange, index]
  )

  const handleICD10Change = useCallback(
    (value) => {
      if (!value) {
        setIcdValue(null)
        onICD10Select?.(null)
        onPropertyChange?.(null, 'icd', index)
        return
      }

      // Find the matching ICD data
      const matchingICD = icd10Data.find((i) => i.code === value)
      if (!matchingICD) {
        console.error('No matching ICD found for code:', value)
        return
      }

      // Create consistent ICD object structure
      const icdData = {
        code: matchingICD.code,
        description: matchingICD.description
      }

      setIcdValue(icdData)
      onICD10Select?.(icdData)
      onPropertyChange?.(icdData, 'icd', index)
    },
    [onICD10Select, onPropertyChange, index, icd10Data]
  )

  return (
    <div className='additional-fields-container'>
      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '12px',
          alignItems: 'flex-start',
          marginTop: '12px'
        }}
      >
        <div style={{ flex: 2 }}>
          <div style={{ display: 'flex', gap: '12px' }}>
            <div style={{ flex: 1 }}>
              <div className='field-label'>Additional Instructions</div>
              <Form.Item style={{ marginBottom: 8 }}>
                <Select
                  mode='multiple'
                  style={{ width: '100%' }}
                  placeholder='Select instructions (e.g., take in the morning, with food)'
                  options={ADDITIONAL_NOTES_OPTIONS}
                  value={selectedInstructions}
                  onChange={handleInstructionsChange}
                  allowClear
                  onClear={handleClearInstructions}
                  dropdownStyle={{ maxHeight: 400 }}
                  maxTagCount={3}
                  maxTagTextLength={20}
                />
              </Form.Item>
              <Checkbox
                checked={med?.noSubstitute || false}
                onChange={(e) => onPropertyChange?.(e.target.checked, 'noSubstitute', index)}
              >
                Do not substitute when dispensing
              </Checkbox>
            </div>

            <div style={{ flex: 1 }}>
              <div className='field-label'>Additional Notes</div>
              <TextArea
                ref={textAreaRef}
                value={additionalNotes}
                onChange={handleNotesChange}
                autoSize={{ minRows: 1, maxRows: 6 }}
                style={{ width: '100%' }}
                placeholder='Enter any additional notes here'
              />
            </div>
          </div>
        </div>

        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <div style={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}>
            <div>
              <div className='field-label'>Repeat</div>
              <Select style={{ width: 120 }} value={repeatValue} onChange={handleRepeatChange}>
                <Select.Option value='No Repeat'>No Repeat</Select.Option>
                <Select.Option value='1'>1</Select.Option>
                <Select.Option value='2'>2</Select.Option>
                <Select.Option value='3'>3</Select.Option>
                <Select.Option value='4'>4</Select.Option>
                <Select.Option value='5'>5</Select.Option>
              </Select>
            </div>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '12px' }}>
              <div>
                <div className='field-label'>ICD-10</div>
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  placeholder='Search ICD-10 code'
                  value={icdValue?.code}
                  loading={icd10Loading}
                  onSearch={setICD10SearchFilter}
                  onChange={handleICD10Change}
                  suffixIcon={<SearchOutlined />}
                  optionLabelProp='value'
                  notFoundContent={
                    icd10Loading ? (
                      <center>
                        <AgiliteSkeleton skActive spinnerTip='Searching...' />
                      </center>
                    ) : null
                  }
                  options={icd10Data?.map((i) => ({
                    label: `${i.code} - ${i.description}`,
                    value: i.code
                  }))}
                  filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase())}
                />
              </div>

              <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: 4 }}>
                  <div style={{ fontWeight: 500 }}>
                    SEP: R{med?.singleExitPrice ? med.singleExitPrice.toFixed(2) : 'N/A'}
                  </div>
                </div>
                {med?.standardPacksize ? (
                  <div style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.45)', marginTop: -4 }}>
                    *Based on a pack size of {med.standardPacksize}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AdditionalFields)
