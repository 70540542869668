import React from 'react'
import { Modal, Typography, Button, Spin, Alert } from 'antd'

const { Title, Paragraph } = Typography

const InitialModal = ({ isVisible, onClose, onProceed, isLoading, error }) => {
  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
      title="AI Health Analysis"
      centered
    >
      <div className="initial-modal__content">
        <Title level={4}>Personalized Health Journey</Title>
        <Paragraph>
          Let our AI analyze your health data to recommend essential blood tests. 
          These tests will help us create:
        </Paragraph>
        <ul>
          <li>Personalized workout plan</li>
          <li>Tailored activity schedule</li>
          <li>Custom health tracking metrics</li>
          <li>Individualized meal plan</li>
        </ul>
        <Paragraph>
          Complete the recommended tests to unlock your personalized health plan.
        </Paragraph>
        
        {error && (
          <Alert
            message="Error"
            description={error}
            type="error"
            style={{ marginBottom: 16 }}
          />
        )}
        
        <Button 
          type="primary" 
          onClick={onProceed} 
          loading={isLoading}
          disabled={isLoading}
          block
        >
          {isLoading ? 'Analyzing Your Health Data...' : 'Get Test Recommendations'}
        </Button>
      </div>
    </Modal>
  )
}

export default InitialModal 