import React, { useState, useEffect } from 'react'
import { Card, List, Button, Form, Badge, Modal, Tabs, Empty } from 'antd'
import { PlusOutlined, ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import TaskItem from './components/TaskItem'
import TaskFilters from './components/TaskFilters'
import CreateTaskModal from './components/CreateTaskModal'
import EditTaskModal from './components/EditTaskModal'
import PreAdmissionModal from '../PreAdmissionModal'
import useTasks from './hooks/useTasks'
import useTaskFilters from './hooks/useTaskFilters'
import { useNotifications } from '../../context/NotificationsContext'
import { CURRENT_USER } from './config/constants'
import { STATUS } from './config/taskStatus'
import styles from './styles/Tasks.module.css'

const { TabPane } = Tabs
const { confirm } = Modal

const CustomEmpty = ({ isCompleted }) => (
  <Empty
    image={null}
    description={
      <div className={styles.emptyState}>
        <CheckCircleOutlined className={styles.emptyIcon} />
        <span>
          {isCompleted
            ? 'No completed tasks yet. Tasks marked as done will appear here.'
            : "No active tasks. Click 'New Task' to create one!"}
        </span>
      </div>
    }
  />
)

function Tasks() {
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [selectedTask, setSelectedTask] = useState(null)
  const [createForm] = Form.useForm()
  const [editForm] = Form.useForm()
  const {
    tasks,
    handleCreateTask,
    editTask,
    completeTask,
    deleteTask,
    medicalProfs,
    isCreateModalVisible,
    setIsCreateModalVisible,
    handleCompleteTask
  } = useTasks()
  const { filters, handleFilterChange, filteredTasks } = useTaskFilters(tasks)

  // Ensure filters are set to default values when component mounts
  useEffect(() => {
    handleFilterChange('assignee', 'all')
    handleFilterChange('taskType', 'all')
    handleFilterChange('status', 'all')
  }, [handleFilterChange])

  const { addNotification } = useNotifications()

  const activeTasks = filteredTasks.filter((task) => task.status === STATUS.ACTIVE)
  const completedTasks = filteredTasks.filter((task) => task.status === STATUS.COMPLETED)

  const handleEditTask = (values) => {
    if (!selectedTask) return

    editTask(selectedTask.id, values)
    setIsEditModalVisible(false)
    setSelectedTask(null)
    editForm.resetFields()
    addNotification({
      title: 'Task Updated',
      description: `Task "${values.task}" has been updated successfully.`
    })
  }

  const handleEditClick = (task) => {
    setSelectedTask(task)
    setIsEditModalVisible(true)
  }

  const handleDeleteTask = (taskId) => {
    const task = tasks.find((t) => t.id === taskId)

    // Only allow deletion by task creator
    if (task.createdBy !== CURRENT_USER) {
      addNotification({
        title: 'Cannot Delete Task',
        description: 'Only the creator of a task can delete it.'
      })
      return
    }

    confirm({
      title: 'Are you sure you want to delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone. Tasks should only be deleted if created in error.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'No, Keep',
      onOk() {
        deleteTask(taskId)
        addNotification({
          title: 'Task Deleted',
          description: `Task "${task.task}" has been deleted.`
        })
      }
    })
  }

  const renderTaskList = (tasks, isCompleted = false) => (
    <List
      className={styles.tasksList}
      size='small'
      dataSource={tasks}
      locale={{ emptyText: <CustomEmpty isCompleted={isCompleted} /> }}
      renderItem={(task) => (
        <TaskItem
          key={task.id}
          task={task}
          onComplete={handleCompleteTask}
          onDelete={handleDeleteTask}
          onEdit={handleEditClick}
          canDelete={task.createdBy === 'You' || task.createdBy === CURRENT_USER}
        />
      )}
    />
  )

  return (
    <Card
      className={styles.miniTasksCard}
      title={
        <div className={styles.cardHeader}>
          <span>Tasks & Reminders</span>
          <Badge count={activeTasks.length} style={{ backgroundColor: '#52c41a' }} title='Active Tasks' />
        </div>
      }
      extra={
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => setIsCreateModalVisible(true)}
          className={styles.addButton}
        >
          New Task
        </Button>
      }
    >
      <TaskFilters medicalProfs={medicalProfs} filters={filters} onFilterChange={handleFilterChange} />

      <Tabs defaultActiveKey='active'>
        <TabPane
          tab={
            <span className={styles.tabWithBadge}>
              Active
              <Badge count={activeTasks.length} style={{ backgroundColor: '#52c41a', marginLeft: '8px' }} />
            </span>
          }
          key='active'
        >
          {renderTaskList(activeTasks, false)}
        </TabPane>
        <TabPane
          tab={
            <span className={styles.tabWithBadge}>
              Completed
              <Badge count={completedTasks.length} style={{ backgroundColor: '#8c8c8c', marginLeft: '8px' }} />
            </span>
          }
          key='completed'
        >
          {renderTaskList(completedTasks, true)}
        </TabPane>
      </Tabs>

      <CreateTaskModal
        medicalProfs={medicalProfs}
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
        onSubmit={handleCreateTask}
        form={createForm}
      />

      <EditTaskModal
        visible={isEditModalVisible}
        onCancel={() => {
          setIsEditModalVisible(false)
          setSelectedTask(null)
          editForm.resetFields()
        }}
        onSubmit={handleEditTask}
        form={editForm}
        initialValues={selectedTask}
      />
    </Card>
  )
}

export default Tasks
