import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Card, Col, Row, Space, theme, Tooltip, Button, Empty, Skeleton, Badge, Typography } from 'antd'
import { hexToRGBA } from '../../../../lib/utils'
import { StandardDataPointGraph } from '../../../examination-utils/standard-graph'
import '../styles/assessment.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeartPulse,
  faTemperatureHalf,
  faLungs,
  faDroplet,
  faChartLine,
  faInfoCircle,
  faWaveSquare,
  faStethoscope,
  faHospitalUser,
  faWifi
} from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import {
  VITAL_SIGN_REFERENCE_RANGES,
  getVitalSignStatus,
  formatVitalSignValue,
  getVitalSignReferenceText
} from '../utils/vital-signs-utils'

// Extend dayjs with relative time plugin
dayjs.extend(relativeTime)

const { Title } = Typography

// Constants
const SYMPTOM_TAG_COLOR = '#FF69B4' // Hot pink color for badges

/**
 * VitalSigns component for displaying and managing vital sign data
 * @param {Object} props - Component props
 * @param {Array<Object>} props.dataPoints - Array of data point templates
 * @param {Array<Object>} props.patientDataEntries - Array of patient vital sign entries
 * @param {Object} props.bookingData - Current booking data
 * @param {Function} props.setLinktopModalOpen - Function to open the Linktop modal
 * @returns {JSX.Element} VitalSigns component
 */
const VitalSigns = ({ dataPoints, patientDataEntries, bookingData, setLinktopModalOpen }) => {
  const [selectedVital, setSelectedVital] = useState(null)
  const { token } = theme.useToken()

  // Icons for each vital sign type
  const vitalIcons = {
    bloodPressure: faHeartPulse,
    pulse: faHeartPulse,
    temperature: faTemperatureHalf,
    respiratoryRate: faLungs,
    oxygenSaturation: faDroplet
  }

  // Memoized function to filter and sort vital data by timestamp
  const getVitalDataByKey = useCallback(
    (vitalKey) => {
      if (!patientDataEntries?.length) return []
      return patientDataEntries
        .filter((entry) => entry.key === vitalKey)
        .sort((a, b) => new Date(b.createdAt || b.dateCreated) - new Date(a.createdAt || a.dateCreated))
    },
    [patientDataEntries]
  )

  // Memoize individual vital data sets to prevent unnecessary recalculations
  const bloodPressureData = useMemo(() => getVitalDataByKey('bloodPressure'), [getVitalDataByKey])
  const pulseData = useMemo(() => getVitalDataByKey('pulse'), [getVitalDataByKey])
  const temperatureData = useMemo(() => getVitalDataByKey('temperature'), [getVitalDataByKey])
  const oxygenSaturationData = useMemo(() => getVitalDataByKey('oxygenSaturation'), [getVitalDataByKey])
  const respiratoryRateData = useMemo(() => getVitalDataByKey('respiratoryRate'), [getVitalDataByKey])

  // Memoized vital signs configuration
  const vitalsConfig = useMemo(
    () => [
      {
        title: 'Blood Pressure',
        key: 'bloodPressure',
        data: bloodPressureData,
        suffix: VITAL_SIGN_REFERENCE_RANGES.bloodPressure.unit,
        icon: vitalIcons.bloodPressure
      },
      {
        title: 'Pulse',
        key: 'pulse',
        data: pulseData,
        suffix: VITAL_SIGN_REFERENCE_RANGES.pulse.unit,
        icon: vitalIcons.pulse
      },
      {
        title: 'Temperature',
        key: 'temperature',
        data: temperatureData,
        suffix: VITAL_SIGN_REFERENCE_RANGES.temperature.unit,
        icon: vitalIcons.temperature
      },
      {
        title: 'Oxygen Saturation',
        key: 'oxygenSaturation',
        data: oxygenSaturationData,
        suffix: VITAL_SIGN_REFERENCE_RANGES.oxygenSaturation.unit,
        icon: vitalIcons.oxygenSaturation
      },
      {
        title: 'Respiratory Rate',
        key: 'respiratoryRate',
        data: respiratoryRateData,
        suffix: VITAL_SIGN_REFERENCE_RANGES.respiratoryRate.unit,
        icon: vitalIcons.respiratoryRate
      }
    ],
    [bloodPressureData, pulseData, temperatureData, oxygenSaturationData, respiratoryRateData, vitalIcons]
  )

  // Initialize and update selected vital
  useEffect(() => {
    // Only run this effect when vitalsConfig changes and we need to initialize or update selectedVital
    if (!selectedVital && vitalsConfig.length > 0) {
      // Find the first vital with data
      const vitalWithData = vitalsConfig.find((v) => v.data && v.data.length > 0)
      setSelectedVital(vitalWithData || vitalsConfig[0])
      return
    }

    // Only update the selected vital if it already exists and its data has changed
    if (selectedVital) {
      const updatedVital = vitalsConfig.find((vital) => vital.key === selectedVital.key)
      // Only update if the data has actually changed to prevent infinite loops
      if (updatedVital && JSON.stringify(updatedVital.data) !== JSON.stringify(selectedVital.data)) {
        setSelectedVital(updatedVital)
      }
    }
  }, [vitalsConfig, selectedVital])

  /**
   * Formats and returns the latest vital sign value with its unit and status
   * @param {Object} vital - Vital sign configuration object
   * @returns {Object} Formatted vital sign value and status
   */
  const formatVitalValue = (vital) => {
    if (!vital?.data?.length) return { text: 'No data', status: 'normal' }

    const latestReading = vital.data[0]
    let status = 'normal'

    if (vital.key === 'bloodPressure') {
      status = getVitalSignStatus(vital.key, latestReading.value)
      return {
        text: formatVitalSignValue(vital.key, latestReading.value),
        status,
        timestamp: latestReading.createdAt || latestReading.dateCreated,
        referenceText: getVitalSignReferenceText(vital.key)
      }
    } else {
      status = getVitalSignStatus(vital.key, latestReading.value)
      return {
        text: formatVitalSignValue(vital.key, latestReading.value),
        status,
        timestamp: latestReading.createdAt || latestReading.dateCreated,
        referenceText: getVitalSignReferenceText(vital.key)
      }
    }
  }

  const handleVitalSelection = (vital) => {
    if (vital.data?.length > 0) {
      setSelectedVital(vital)
    }
  }

  const renderVitalTabs = () => {
    return (
      <div className='vital-signs-tabs'>
        {vitalsConfig.map((vital) => {
          const isSelected = selectedVital?.key === vital.key
          const hasData = vital.data?.length > 0
          const formattedValue = formatVitalValue(vital)

          return (
            <div
              key={vital.key}
              onClick={() => handleVitalSelection(vital)}
              className={`vital-sign-tab ${isSelected ? 'active' : ''} ${!hasData ? 'disabled' : ''}`}
              style={isSelected ? { borderBottomColor: token.colorPrimary } : {}}
            >
              <div className='vital-sign-title'>
                <FontAwesomeIcon icon={vital.icon} style={{ marginRight: 6 }} />
                {vital.title}
              </div>
              <div className={`vital-sign-value ${formattedValue.status}`}>
                {formattedValue.text}
                {hasData && formattedValue.status !== 'normal' && (
                  <Badge status={formattedValue.status === 'danger' ? 'error' : 'warning'} style={{ marginLeft: 4 }} />
                )}
              </div>
              {hasData && formattedValue.timestamp && (
                <div style={{ fontSize: '11px', color: '#999', marginTop: 2 }}>
                  {dayjs(formattedValue.timestamp).fromNow()}
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  const renderEmptyState = () => {
    return (
      <div className='vital-signs-empty'>
        <div className='vital-signs-empty-icon'>
          <FontAwesomeIcon icon={faHeartPulse} />
        </div>
        <div className='vital-signs-empty-text'>No vital signs have been recorded for this patient yet.</div>
      </div>
    )
  }

  const renderReferenceRange = () => {
    if (!selectedVital || !selectedVital.data || selectedVital.data.length === 0) return null

    return (
      <div
        style={{
          padding: '8px 16px',
          fontSize: '12px',
          color: token.colorTextSecondary,
          borderTop: `1px solid ${token.colorBorderSecondary}`,
          background: token.colorBgContainer
        }}
      >
        {getVitalSignReferenceText(selectedVital.key)}
      </div>
    )
  }

  const hasAnyData = vitalsConfig.some((vital) => vital.data && vital.data.length > 0)

  // For debugging - Comment out to prevent excessive logging
  // console.log('VitalSigns rendering with data:', {
  //   patientDataEntries: patientDataEntries?.length,
  //   vitalsConfig: vitalsConfig.map(v => ({ key: v.key, dataLength: v.data?.length })),
  //   hasAnyData
  // })

  return (
    <Card
      styles={{
        header: {
          background: hexToRGBA(token.colorPrimary, 0.08),
          borderBottom: `1px solid ${hexToRGBA(token.colorPrimary, 0.1)}`,
          padding: '12px 16px'
        },
        body: {
          padding: '16px'
        }
      }}
      size='small'
      title={
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Space>
            <FontAwesomeIcon
              icon={faHeartPulse}
              style={{
                color: token.colorPrimary,
                fontSize: '16px'
              }}
            />
            <Title level={5} style={{ margin: 0 }}>
              Vital Signs
            </Title>
            {hasAnyData && (
              <Badge
                count={
                  vitalsConfig.filter((v) => v.data && v.data.length > 0 && v.data[0]?.bookingId === bookingData?._id)
                    .length
                }
                style={{
                  backgroundColor: SYMPTOM_TAG_COLOR,
                  marginLeft: 8
                }}
              />
            )}
          </Space>
          <Tooltip title='Connect to health monitoring device to automatically capture vital signs'>
            <Button
              type='primary'
              onClick={() => setLinktopModalOpen && setLinktopModalOpen(true)}
              icon={<FontAwesomeIcon icon={faWaveSquare} style={{ marginRight: 4 }} />}
            >
              Connect Device
            </Button>
          </Tooltip>
        </div>
      }
      className='vital-signs-card'
      style={{
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden'
      }}
    >
      <Row>
        <Col span={24}>{renderVitalTabs()}</Col>
        <Col span={24}>
          {hasAnyData
            ? selectedVital &&
              dataPoints?.find((point) => point.key === selectedVital.key) && (
                <>
                  <div className='vital-signs-graph-container'>
                    <StandardDataPointGraph
                      data={{ records: selectedVital.data, key: selectedVital.key }}
                      dataTemplate={dataPoints.find((point) => point.key === selectedVital.key)}
                    />
                  </div>
                  {renderReferenceRange()}
                </>
              )
            : renderEmptyState()}
        </Col>
      </Row>
    </Card>
  )
}

export default React.memo(VitalSigns)
