import React from 'react'
import { Space, Switch, Tooltip, Divider } from 'antd'

const TagExample = ({ children, style }) => (
  <div
    style={{
      padding: '1px 6px',
      borderRadius: '4px',
      fontSize: '12px',
      ...style
    }}
  >
    {children}
  </div>
)

const ScheduleExamples = () => (
  <Space size={4}>
    <TagExample style={{
      background: '#f6ffed',
      color: '#237804',
      border: '1px solid #b7eb8f'
    }}>S2</TagExample>
    <TagExample style={{
      background: '#fffbe6',
      color: '#d46b08',
      border: '1px solid #ffe58f'
    }}>S4</TagExample>
    <TagExample style={{
      background: '#fff0f6',
      color: '#c41d7f',
      border: '1px solid #ffadd2'
    }}>S5</TagExample>
  </Space>
)

const PackSizeExamples = () => (
  <Space size={4}>
    <TagExample style={{
      background: '#e6f4ff',
      color: '#1677ff',
      border: '1px solid #91caff'
    }}>30tab</TagExample>
    <TagExample style={{
      background: '#e6f4ff',
      color: '#1677ff',
      border: '1px solid #91caff'
    }}>100ml</TagExample>
    <TagExample style={{
      background: '#e6f4ff',
      color: '#1677ff',
      border: '1px solid #91caff'
    }}>20g</TagExample>
  </Space>
)

const ControlPanel = ({ rptRepeat, setRptRepeat, rptICD, setRptICD }) => {
  return (
    <Space style={{ padding: 8, borderBottom: '1px #ccc solid', width: '100%', justifyContent: 'flex-start' }}>
      <Space>
        <span>Repeat RPT: </span>
        <Switch
          checked={rptRepeat}
          onChange={(boolean) => {
            setRptRepeat(boolean)
          }}
        />
      </Space>
      <Divider type="vertical" />
      <Space>
        <span>Repeat ICD: </span>
        <Switch
          checked={rptICD}
          onChange={(boolean) => {
            setRptICD(boolean)
          }}
        />
      </Space>
      <Divider type="vertical" />
      <Space size={12}>
        <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Legend:</span>
        <Space>
          <span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: '12px' }}>Schedule:</span>
          <Tooltip title="Green: S1-S3, Yellow: S4, Pink: S5+">
            <div><ScheduleExamples /></div>
          </Tooltip>
        </Space>
        <Divider type="vertical" style={{ margin: '0 4px' }} />
        <Space>
          <span style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: '12px' }}>Pack Size:</span>
          <Tooltip title="Common units: tab (tablets), caps (capsules), ml (liquid), g (cream/ointment)">
            <div><PackSizeExamples /></div>
          </Tooltip>
        </Space>
      </Space>
    </Space>
  )
}

export default ControlPanel 