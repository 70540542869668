import React from 'react'
import { Typography, Card } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faLightbulb,
  faRobot,
  faChartLine
} from '@fortawesome/free-solid-svg-icons'
import './AIInsightsStep.css'

const { Text, Title } = Typography

const AIInsightsStep = () => {
  return (
    <div className="tour-step ai-insights-step">
      <Title level={3}>AI-Powered Health Insights</Title>
      <Text className="feature-description">
        Get personalized recommendations and insights from our AI assistant
      </Text>

      <div className="mock-interface">
        <Card className="mock-ai-card">
          <div className="ai-message">
            <FontAwesomeIcon icon={faRobot} className="ai-icon" />
            <div className="ai-content">
              <Text strong>Based on your recent blood work:</Text>
              <ul className="ai-recommendations">
                <li>
                  <FontAwesomeIcon icon={faLightbulb} />
                  <Text>Consider a Vitamin D test this month</Text>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChartLine} />
                  <Text>Your cholesterol levels show improvement</Text>
                </li>
              </ul>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default AIInsightsStep 