import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Table,
  theme,
  Tooltip
} from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import coreReducer from '../../../core/utils/reducer'
import { handleError } from '../../lib/utils'
import { readBillingRecords } from '../../Bookings/utils/utils'
import { readPatients } from '../../Admin/patients/utils/utils'
import CoreEnums from '../../../core/utils/enums'
import { createPatientPayment } from '../utils'

const PatientPaymentForm = ({ data }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [paymentData, setPaymentData] = useState(data)
  const [items, setItems] = useState([])
  const [renderForm, setRenderForm] = useState(false)
  const [patientLiableTotal, setPatientLiableTotal] = useState(0)

  useEffect(() => {
    handleGetBillingRecords()
    handleGetExtendedData()
    // eslint-disable-next-line
  }, [])

  const handleGetBillingRecords = async () => {
    let tmpItems = []

    setLoading(true)
    try {
      tmpItems = await readBillingRecords({ bookingRef: data.bookingRef })

      for (const entry of tmpItems) {
        entry.total = entry.total ? entry.total : '0'
        entry.total = entry.total ? entry.total : '0'
        entry.patient = entry.patient ? entry.patient : '0'
        entry.payment = entry.payment ? entry.payment : '0'
        entry.discount = entry.discount ? entry.discount : '0'
        entry.comments = entry.comments ? entry.comments : ''

        entry.patientBalance = entry.patient
        entry.oldPatient = entry.patient
        entry.oldMedicalAid = entry.medicalAid
      }

      setItems(tmpItems)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setLoading(false)
  }

  useEffect(() => {
    let tmpPatientLiableTotal = 0

    for (const entry of items) {
      tmpPatientLiableTotal += parseFloat(entry.patient)
    }

    setPatientLiableTotal(tmpPatientLiableTotal)
  }, [items])

  const handleGetExtendedData = async () => {
    let tmpData = null
    let patientRecord = null

    setFormLoading(true)
    try {
      await Promise.all([
        (async () => {
          tmpData = await readPatients({ _id: data.userRef }, {}, 'firstName lastName idNo medicalAid')
          patientRecord = tmpData.length > 0 ? tmpData[0] : null
        })()
      ])

      setPaymentData({
        ...data,
        patientRecord
      })
      setRenderForm(true)
    } catch (e) {
      message.error(handleError(e, true))
    }
    setFormLoading(false)
  }

  const handleAllocatePayment = () => {
    form
      .validateFields()
      .then(async () => {
        setSubmitLoading(true)
        try {
          await createPatientPayment({
            createdAt: new Date(),
            invoiceRef: data._id,
            userRef: data.userRef,
            userSchemeCode: paymentData.patientRecord.medicalAid.schemeCode,
            clinicRef: data.clinicRef,
            medicalProfRef: data.medicalProfRef,
            receiptNumber: form.getFieldValue('practiceReceiptNumber'),
            receiptDate: form.getFieldValue('receiptDate'),
            accStatus: form.getFieldValue('accountStatus'),
            paymentMethod: form.getFieldValue('paymentMethod'),
            paymentAmount: form.getFieldValue('paymentAmount'),
            items
          })
          message.success('Payment successfully allocated')
          handleCloseTab()
        } catch (error) {
          message.error(handleError(error, true))
        }
        setSubmitLoading(false)
      })
      .catch((e) => {})
  }

  const handleCloseTab = () => {
    dispatch(
      coreReducer.actions.closeTab({
        targetKey: `${CoreEnums.tabKeys.PATIENT_PAYMENTS}_new`,
        removeBreadcrumb: true
      })
    )
  }

  const [form] = Form.useForm()
  const { token } = theme.useToken()

  const handleFieldChange = (changedFields) => {
    try {
      if (!changedFields.paymentAmount) {
        for (const entry of items) {
          entry.payment = 0
        }
      }

      if (parseFloat(changedFields.paymentAmount) === patientLiableTotal) {
        // If the payment amount is equal to the total amount the patient is liable for
        // then set all payment values to the patient liable amounts
        for (const entry of items) {
          entry.payment = entry.patient
          entry.patientBalance = 0
        }
      }

      setPaymentData({ ...paymentData, ...changedFields })
      setItems([...items])
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleTableFieldChange = (prop, index, value) => {
    const tmpItems = JSON.parse(JSON.stringify(items))
    tmpItems[index][prop] = value

    try {
      // When the payment amount on a row is changed
      if (prop === 'payment') {
        const tmpPayment = value
        const tmpPatient = tmpItems[index].patient

        if (tmpPayment > tmpPatient) {
          throw new Error('Payment amount cannot be greater than the Patient Liable amount')
        } else {
          tmpItems[index].patientBalance = (tmpPatient - tmpPayment).toFixed(2)
        }
      }

      // When the discount amount on a row is changed
      if (prop === 'discount') {
        const tmpDiscount = value
        const tmpPatient = tmpItems[index].patient

        if (tmpDiscount > tmpPatient) {
          throw new Error('Discount amount cannot be greater than the Patient Liable amount')
        } else {
          tmpItems[index].patientBalance = (tmpPatient - tmpDiscount).toFixed(2)
        }
      }

      setItems(tmpItems)
    } catch (e) {
      message.error(e.message)
    }
  }

  const generateTotalsData = () => {
    const data = [
      {
        total: items.reduce((a, b) => a + parseFloat(b.total), 0).toFixed(2),
        medicalAid: items.reduce((a, b) => a + parseFloat(b.medicalAid), 0).toFixed(2),
        patient: items.reduce((a, b) => a + parseFloat(b.patient), 0).toFixed(2),
        payment: items.reduce((a, b) => a + parseFloat(b.payment), 0).toFixed(2),
        discount: items.reduce((a, b) => a + parseFloat(b.discount), 0).toFixed(2)
      }
    ]

    for (const entry of data) {
      for (const prop in entry) {
        if (isNaN(entry[prop])) {
          entry[prop] = 0
        }
      }
    }

    return data
  }

  return (
    <Row>
      <Col span={24}>
        <Card size='small' title={<span style={{ color: token.colorPrimary, fontSize: 18 }}>New Patient Payment</span>}>
          <Card
            size='small'
            bordered={false}
            title={<span style={{ color: token.colorSecondary, fontSize: 18 }}>Account Details</span>}
            type='inner'
            loading={formLoading}
          >
            {renderForm ? (
              <Form
                form={form}
                initialValues={{
                  ...paymentData,
                  account: paymentData.patientRecord
                    ? `${paymentData.patientRecord.idNo} - ${paymentData.patientRecord.firstName} ${paymentData.patientRecord.lastName}`
                    : '',
                  practiceReceiptNumber: dayjs(new Date()).format('YYYYMMDDHHmmss'),
                  receiptDate: dayjs(new Date())
                }}
                onValuesChange={handleFieldChange}
                layout='vertical'
              >
                <Row gutter={[12, 12]}>
                  <Col span={12}>
                    <Form.Item
                      name='account'
                      label='Account'
                      rules={[{ required: true, message: 'Please select an Account' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Row gutter={[12, 12]}>
                      <Col span={12}>
                        <Form.Item
                          name='accountStatus'
                          label='Account Status'
                          rules={[{ required: true, message: 'Please select an Account Status' }]}
                        >
                          <Select
                            options={[
                              { label: 'Normal', value: 'normal' },
                              { label: 'Suspended', value: 'suspended' },
                              { label: 'Hand Over', value: 'handOver' },
                              { label: 'Bad Debt', value: 'badDebt' }
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name='paymentMethod'
                          label='Payment Method'
                          rules={[{ required: true, message: 'Please select a Payment Method' }]}
                        >
                          <Select
                            options={[
                              { label: 'Bank Transfer (EFT)', value: 'eft' },
                              { label: 'Cash', value: 'cash' },
                              { label: 'Credit/Debit Card', value: 'card' },
                              { label: 'Online Credit/Debit Card', value: 'onlineCard' },
                              { label: 'Discount', value: 'discount' }
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={[12, 12]}>
                  <Col span={12}>
                    <Form.Item name='practiceReceiptNumber' label='Practice Receipt Number'>
                      <Input readOnly />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Row gutter={[12, 12]}>
                      <Col span={12}>
                        <Form.Item
                          name='receiptDate'
                          label='Receipt Date'
                          rules={[{ required: true, message: 'Please select a Receipt Date' }]}
                        >
                          <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name='paymentAmount'
                          label='Payment Amount'
                          rules={[{ required: true, message: 'Please provide a Payment Amount' }]}
                        >
                          <Input type='number' prefix='R' />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            ) : undefined}
          </Card>
          <Card
            size='small'
            bordered={false}
            type='inner'
            title={<span style={{ color: token.colorSecondary, fontSize: 18 }}>Invoice Items</span>}
            extra={
              <Button size='small' danger onClick={() => handleGetBillingRecords()}>
                <Space>Reset Values</Space>
              </Button>
            }
          >
            <Table
              pagination={false}
              size='small'
              loading={loading}
              tableLayout='fixed'
              columns={[
                { title: 'Item Type', dataIndex: 'type', key: 'type', width: '8%' },
                { title: 'Code', dataIndex: 'code', key: 'code', width: '5%' },
                { title: 'Description', dataIndex: 'name', key: 'description', width: '40%' },
                {
                  title: 'Total',
                  dataIndex: 'total',
                  key: 'total',
                  width: '6%',
                  render: (text) => <Input style={{ backgroundColor: '#ddd' }} value={text} readOnly />
                },
                {
                  title: 'Medical Aid',
                  dataIndex: 'medicalAid',
                  key: 'medicalAid',
                  width: '6%',
                  render: (text) => <Input style={{ backgroundColor: '#ddd' }} value={text} readOnly />
                },
                {
                  title: 'Patient Liable',
                  dataIndex: 'patientBalance',
                  key: 'patient',
                  width: '6%',
                  render: (text) => <Input style={{ backgroundColor: '#FCA19C' }} value={text} readOnly />
                },
                {
                  title: 'Payment',
                  dataIndex: 'payment',
                  key: 'payment',
                  width: '6%',
                  render: (text, record, index) => {
                    const paymentValue = form.getFieldValue('paymentAmount')

                    return (
                      <Tooltip open={paymentValue ? false : undefined} title='Please enter a Payment Amount first'>
                        <InputNumber
                          onKeyDown={(e) => {
                            const tmpItems = JSON.parse(JSON.stringify(items))
                            const currentItem = tmpItems[index]

                            switch (e.key.toUpperCase()) {
                              case 'M':
                                e.preventDefault()
                                if (parseFloat(currentItem.patientBalance) > 0) {
                                  currentItem.medicalAid = (
                                    parseFloat(currentItem.medicalAid) + parseFloat(currentItem.patientBalance)
                                  ).toFixed(2)
                                  currentItem.patientBalance = '0'
                                }
                                break
                              case 'P':
                                e.preventDefault()
                                if (parseFloat(currentItem.medicalAid) > 0) {
                                  currentItem.patientBalance = (
                                    parseFloat(currentItem.patientBalance) + parseFloat(currentItem.medicalAid)
                                  ).toFixed(2)
                                  currentItem.medicalAid = '0'
                                }
                                break
                              case 'D':
                                e.preventDefault()
                                if (parseFloat(currentItem.patientBalance) > 0) {
                                  currentItem.discount = (
                                    parseFloat(currentItem.discount) + parseFloat(currentItem.patientBalance)
                                  ).toFixed(2)
                                  currentItem.patientBalance = '0'
                                }
                                break
                              case 'R':
                                e.preventDefault()
                                currentItem.patientBalance = currentItem.oldPatient
                                currentItem.patient = currentItem.oldPatient
                                currentItem.medicalAid = currentItem.oldMedicalAid
                                currentItem.payment = '0'
                                currentItem.discount = '0'
                                break
                              case ' ': // Space key
                                e.preventDefault()
                                if (paymentValue && parseFloat(currentItem.patientBalance) > 0) {
                                  if (parseFloat(paymentValue) >= parseFloat(currentItem.patientBalance)) {
                                    currentItem.payment = currentItem.patientBalance
                                    currentItem.patientBalance = '0'
                                  }
                                }
                                break
                              default:
                                break
                            }

                            setItems(tmpItems)
                          }}
                          value={text}
                          onChange={(value) => {
                            if (paymentValue) {
                              handleTableFieldChange('payment', index, value)
                            }
                          }}
                        />
                      </Tooltip>
                    )
                  }
                },
                {
                  title: 'Discount',
                  dataIndex: 'discount',
                  key: 'discount',
                  width: '6%',
                  render: (text, record, index) => (
                    <Input value={text} onChange={(e) => handleTableFieldChange('discount', index, e.target.value)} />
                  )
                },
                {
                  title: 'Comments',
                  dataIndex: 'comments',
                  key: 'comments',
                  width: '10%',
                  render: (text, record, index) => (
                    <Input.TextArea
                      rows={1}
                      value={text}
                      onChange={(e) => handleTableFieldChange('comments', index, e.target.value)}
                    />
                  )
                }
              ]}
              dataSource={items}
              footer={() => {
                return (
                  <Table
                    showHeader={false}
                    size='small'
                    pagination={false}
                    columns={[
                      { width: '8%' },
                      { width: '5%' },
                      { width: '40%', render: () => <b style={{ float: 'right' }}>Total</b> },
                      { width: '6%', dataIndex: 'total', render: (text) => <b>{text}</b> },
                      { width: '6%', dataIndex: 'medicalAid', render: (text) => <b>{text}</b> },
                      { width: '6%', dataIndex: 'patient', render: (text) => <b>{text}</b> },
                      { width: '6%', dataIndex: 'payment', render: (text) => <b>{text}</b> },
                      { width: '6%', dataIndex: 'discount', render: (text) => <b>{text}</b> },
                      { width: '10%' }
                    ]}
                    dataSource={generateTotalsData()}
                  />
                )
              }}
            />
          </Card>
        </Card>
        <Row justify='space-around' style={{ marginTop: 10 }}>
          <Col>P = Move to Patient Liable</Col>
          <Col>M = Move to Medical Aid</Col>
          <Col>D = Discount</Col>
          <Col>R = Reset</Col>
          <Col>Space - Allocate</Col>
        </Row>
        <Row justify='center' style={{ marginTop: 10 }} gutter={[12, 12]}>
          <Col>
            <Button type='primary' onClick={() => handleAllocatePayment()} loading={submitLoading}>
              Allocate Payment
            </Button>
          </Col>
          <Col>
            <Button danger onClick={() => handleCloseTab()}>
              Close
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default PatientPaymentForm
