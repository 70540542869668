import React, { useState, memo } from 'react'
import {
  Row,
  Col,
  Form,
  Button,
  theme,
  Popconfirm,
  Switch,
  message,
  Input,
  Modal,
  Space,
  Card,
  Divider,
  Empty
} from 'antd'
/* import { UploadOutlined } from '@ant-design/icons' */

import { PrimaryCard } from '../../../reusable-components/AgiliteCards'

import { PlusOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import CheckinPreview from '../../services/components/ServiceCheckinPreview'

const _BasicForm = ({ handleUpdateConfig, configData, setConfigData }) => {
  const [checkinQuestions, setCheckinQuestions] = useState(configData.checkin || [])
  const [isPreview, setIsPreview] = useState()
  const [recordData, setRecordData] = useState()
  const [isSaved, setIsSaved] = useState(true)
  const [tmpQuestion, setTmpQuestion] = useState({ text: null, isNew: null })
  const [isNewQuestion, setIsNewQuestion] = useState()
  const [isModalOpen, setIsModalOpen] = useState()
  const checkinTemplate = {
    doctorsText: null,
    enableOptions: false,
    options: [],
    optionsHeading: null,
    otherOptionText: null,
    questionText: null,
    showOther: false
  }

  const { token } = theme.useToken()
  const [clientForm] = Form.useForm()

  const handleSubmit = async () => {
    const tmpConfigData = JSON.parse(JSON.stringify(configData))

    tmpConfigData.checkin = checkinQuestions

    setConfigData(tmpConfigData)
    handleUpdateConfig(tmpConfigData)
    setIsSaved(true)
  }

  const [form] = Form.useForm()

  const onFinish = () => {
    setIsSaved(false)
    const tmpCheckinTemplate = JSON.parse(JSON.stringify(checkinTemplate))
    for (var value in recordData) {
      tmpCheckinTemplate[value] = recordData[value]
    }
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    tmpCheckinQuestions.push(tmpCheckinTemplate)
    setCheckinQuestions(tmpCheckinQuestions)
    form.resetFields()
    setIsModalOpen(false)
  }

  const handleCheckinChange = (value, index, key) => {
    setIsSaved(false)
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    tmpCheckinQuestions[index][key] = value
    setCheckinQuestions(tmpCheckinQuestions)
  }

  const editCheckinOption = (questionIndex, optionIndex) => {
    setIsSaved(false)
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    setTmpQuestion({
      ...tmpCheckinQuestions[questionIndex].options[optionIndex],
      questionIndex: questionIndex,
      optionIndex: optionIndex,
      isNew: false
    })
    setIsNewQuestion(true)
  }
  const handleTmpQuestionChange = (action, value) => {
    const tmpTmpQuestion = JSON.parse(JSON.stringify(tmpQuestion))
    if (action === 'text') {
      tmpTmpQuestion.text = value
    }
    setTmpQuestion(tmpTmpQuestion)
  }

  const handleCheckinOptionSave = () => {
    setIsSaved(false)
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    const questionIndex = tmpQuestion.questionIndex
    const optionIndex = tmpQuestion.optionIndex
    delete tmpQuestion.questionIndex
    delete tmpQuestion.optionIndex
    delete tmpQuestion.isNew
    if (!tmpQuestion.text) {
      message.error('Please provide option text')
    } else {
      if (tmpCheckinQuestions[questionIndex].options[optionIndex]) {
        tmpCheckinQuestions[questionIndex].options[optionIndex] = tmpQuestion
        setCheckinQuestions(tmpCheckinQuestions)
        setTmpQuestion({ text: null })
        setIsNewQuestion(false)
      } else {
        tmpCheckinQuestions[questionIndex].options.push(tmpQuestion)
        setCheckinQuestions(tmpCheckinQuestions)
        setTmpQuestion({ text: null })
        setIsNewQuestion(false)
      }
    }
  }
  const addCheckinOption = (questionIndex) => {
    setTmpQuestion({
      ...tmpQuestion,
      questionIndex: questionIndex,
      isNew: true
    })
    setIsNewQuestion(true)
  }

  const deleteCheckinOption = (questionIndex, optionIndex) => {
    setIsSaved(false)
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    tmpCheckinQuestions[questionIndex].options.splice(optionIndex, 1)
    setCheckinQuestions(tmpCheckinQuestions)
  }

  const handleQuestionDelete = (questionIndex) => {
    setIsSaved(false)
    const tmpCheckinQuestions = JSON.parse(JSON.stringify(checkinQuestions))
    tmpCheckinQuestions.splice(questionIndex, 1)
    setCheckinQuestions(tmpCheckinQuestions)
  }
  return (
    <>
      <Modal
        width={'100vw'}
        title={<center>Checkin Questionnaire Preview</center>}
        closable={false}
        destroyOnClose
        maskClosable={false}
        open={isPreview}
        footer={false}
      >
        <CheckinPreview data={checkinQuestions} />
        <center style={{ marginTop: 12 }}>
          <Button
            onClick={() => {
              setIsPreview(false)
            }}
          >
            Close Preview
          </Button>
        </center>
      </Modal>
      <Modal
        title='Checkin Question Option'
        closable={false}
        destroyOnClose
        maskClosable={false}
        open={isNewQuestion}
        okText='Confirm'
        onOk={() => [handleCheckinOptionSave()]}
        onCancel={() => {
          setTmpQuestion({ text: null })
          setIsNewQuestion(false)
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            Option Text:
            <br />
            <p style={{ margin: '4px 0' }}>This is the text that will be displayed to the user for selection.</p>
          </Col>
          <Col span={24}>
            <TextArea
              onChange={(e) => {
                handleTmpQuestionChange('text', e.target.value)
              }}
              maxLength={50}
              value={tmpQuestion.text}
            />
          </Col>
        </Row>
      </Modal>
      <Modal footer={false} closable={false} destroyOnClose maskClosable={false} open={isModalOpen}>
        <Card type='inner' size='small' title='New Question'>
          <Form
            layout='vertical'
            form={form}
            onFieldsChange={() => {
              setRecordData(form.getFieldsValue())
            }}
            name='dynamic_form_complex'
            onFinish={onFinish}
            style={{
              maxWidth: 600
            }}
            autoComplete='off'
          >
            <Form.Item
              name='questionText'
              label={
                <Row>
                  <Col span={24}>
                    <b>Main Item Text</b>
                  </Col>
                  <Col span={24}>
                    <p style={{ margin: '4px 0' }}>Main selection item text prompting the users selection.</p>
                  </Col>
                </Row>
              }
              rules={[
                {
                  required: true,
                  message: 'Missing text'
                }
              ]}
            >
              <Input placeholder='(e.g) I am feeling ill.' />
            </Form.Item>
            <Divider style={{ borderColor: token.colorAccent1 }} />
            <Form.Item
              label={
                <Row>
                  <Col span={24}>
                    <b>Professional Text</b>
                  </Col>
                  <Col span={24}>
                    <p style={{ margin: '4px 0' }}>Indicator displayed to the doctor for the selection.</p>
                  </Col>
                </Row>
              }
              name='doctorsText'
              rules={[
                {
                  required: true,
                  message: 'Missing text'
                }
              ]}
            >
              <Input placeholder='(e.g) Sick.' />
            </Form.Item>
            <Divider style={{ borderColor: token.colorAccent1 }} />
            <Form.Item
              label={
                <Row>
                  <Col span={24}>
                    <b>Secondary Options:</b>
                  </Col>
                  <Col span={24}>
                    <p style={{ margin: '4px 0' }}>Enable or disabled the use of secondary options.</p>
                  </Col>
                </Row>
              }
              name='enableOptions'
            >
              <Switch defaultChecked={false} checkedChildren='Enabled' unCheckedChildren='Disabled' />
            </Form.Item>
            {form.getFieldsValue().enableOptions ? (
              <>
                <Divider style={{ borderColor: token.colorAccent1 }} />
                <Form.Item
                  label={
                    <Row>
                      <Col span={24}>
                        <b>Secondary Options Label</b>
                      </Col>
                      <Col span={24}>
                        <p style={{ margin: '4px 0' }}>
                          Choose an appropriate heading to prompt the user to select relevant options.
                        </p>
                      </Col>
                    </Row>
                  }
                  name='optionsHeading'
                  rules={[
                    {
                      required: true,
                      message: 'Missing text'
                    }
                  ]}
                >
                  <Input placeholder='(e.g) Please select your symptoms from the list.' />
                </Form.Item>
                <Divider style={{ borderColor: token.colorAccent1 }} />
                <Form.List name='options'>
                  {(fields, { add, remove }) => (
                    <>
                      <Row style={{ marginBottom: 12 }} gutter={[12, 12]}>
                        <Col span={24}>
                          {
                            <Row>
                              <Col span={24}>
                                <b>Secondary Options Items:</b>
                              </Col>
                              <Col span={24}>
                                <p style={{ margin: '4px 0' }}>
                                  Allow a user to select from a collection of options provided.
                                </p>
                              </Col>
                            </Row>
                          }
                        </Col>
                        {fields.map((field) => {
                          return (
                            <Col span={24}>
                              <Card
                                type='inner'
                                size='small'
                                extra={
                                  <Button
                                    onClick={() => {
                                      if (fields.length > 1) {
                                        remove(field.name)
                                      } else {
                                        message.error('You have to provide at least 1 option')
                                      }
                                    }}
                                  >
                                    <FontAwesomeIcon color={token.colorError} icon={faTrash} />
                                  </Button>
                                }
                                key={field.key}
                              >
                                <Row>
                                  <Col span={24}>
                                    <Form.Item
                                      {...field}
                                      label='Option text:'
                                      name={[field.name, 'text']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Missing text'
                                        }
                                      ]}
                                    >
                                      <Input placeholder='(e.g) Headaches' />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          )
                        })}
                      </Row>
                      <Form.Item>
                        <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                          Add options
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Divider style={{ borderColor: token.colorAccent1 }} />
                <Form.Item
                  label={
                    <Row>
                      <Col span={24}>
                        <b>Other Option:</b>
                      </Col>
                      <Col span={24}>
                        <p style={{ margin: '4px 0' }}>
                          Allow user to provide additional information via text field on selection.
                        </p>
                      </Col>
                    </Row>
                  }
                  name='showOther'
                >
                  <Switch defaultChecked={false} checkedChildren='Enabled' unCheckedChildren='Disabled' />
                </Form.Item>

                {form.getFieldsValue().showOther ? (
                  <>
                    <Divider style={{ borderColor: token.colorAccent1 }} />
                    <Form.Item
                      label={
                        <Row>
                          <Col span={24}>
                            <b>Additional Field Label</b>
                          </Col>
                          <Col span={24}>
                            <p style={{ margin: '4px 0' }}>
                              Provide an appropriate label prompting the user to fill in the field.
                            </p>
                          </Col>
                        </Row>
                      }
                      name='otherOptionText'
                      rules={[
                        {
                          required: true,
                          message: 'Missing text'
                        }
                      ]}
                    >
                      <TextArea placeholder='(e.g) List your symptoms.' maxLength={100} />
                    </Form.Item>
                  </>
                ) : null}
              </>
            ) : null}

            <Form.Item>
              <Row justify='center'>
                <Space wrap>
                  <Button
                    onClick={() => {
                      form.resetFields()
                      setIsModalOpen(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type='primary' htmlType='submit'>
                    Add Question
                  </Button>
                </Space>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Modal>
      <PrimaryCard title='Checkin Questionnaire'>
        <Form onFinish={handleSubmit} name='form' form={clientForm} layout='vertical'>
          <Row style={{ margin: '12px 0' }} justify={'center'}>
            <Space wrap>
              <Button
                type='primary'
                onClick={() => {
                  setIsModalOpen(true)
                }}
              >
                Add Question
              </Button>
              {/* {checkinQuestions.length > 0 ? (
                <Button
                  style={{ background: token.colorAccent1, color: 'white' }}
                  onClick={() => {
                    setIsPreview(true)
                  }}
                >
                  Preview
                </Button>
              ) : null} */}

              {!isSaved ? (
                <Button
                  type='primary'
                  style={{ background: token.colorSuccess }}
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Save
                </Button>
              ) : null}
            </Space>
          </Row>
          {checkinQuestions.map((question, index) => (
            <Card
              style={{ marginBottom: 8 }}
              title={`Question ${index + 1}`}
              extra={
                <Button
                  onClick={() => {
                    handleQuestionDelete(index)
                  }}
                >
                  <FontAwesomeIcon color={token.colorError} icon={faTrash} />
                </Button>
              }
              type='inner'
              size='small'
            >
              <Row>
                <Col span={24}>
                  <b>Main Item Text:</b>
                  <br></br>
                  <p style={{ margin: '4px 0' }}>Main selection item text prompting the users selection.</p>
                  <TextArea
                    onChange={(e) => {
                      handleCheckinChange(e.target.value, index, 'questionText')
                    }}
                    maxLength={50}
                    style={{ marginTop: 8, width: '100%' }}
                    value={question.questionText}
                  />
                </Col>
                <Divider style={{ borderColor: token.colorAccent1 }} />
                <Col span={24}>
                  <b>Professional Text:</b>
                  <br></br>
                  <p style={{ margin: '4px 0' }}>Indicator displayed to the doctor for the selection.</p>
                  <TextArea
                    onChange={(e) => {
                      handleCheckinChange(e.target.value, index, 'doctorsText')
                    }}
                    maxLength={50}
                    style={{ marginTop: 8, width: '100%' }}
                    value={question.doctorsText}
                  />
                </Col>
                <Divider style={{ borderColor: token.colorAccent1 }} />
                <Col span={24}>
                  <Row style={{ alignItems: 'center' }} justify='space-between'>
                    <Col>
                      <b>Secondary Options:</b>
                      <br />
                      <p style={{ margin: '4px 0' }}>Enable or disabled the use of secondary options.</p>
                    </Col>
                    <Col>
                      <Switch
                        checked={question.enableOptions}
                        checkedChildren='Enabled'
                        unCheckedChildren='Disabled'
                        onChange={(e) => {
                          handleCheckinChange(e, index, 'enableOptions')
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                {question.enableOptions ? (
                  <>
                    <Divider style={{ borderColor: token.colorAccent1 }} />
                    <Col span={24}>
                      <b>Secondary Options Label:</b>
                      <br></br>
                      <p style={{ margin: '4px 0' }}>
                        Provide an appropriate label prompting the user to select from the options.
                      </p>
                      <TextArea
                        onChange={(e) => {
                          handleCheckinChange(e.target.value, index, 'optionsHeading')
                        }}
                        maxLength={50}
                        style={{ marginTop: 8, width: '100%' }}
                        value={question.optionsHeading}
                      />
                    </Col>
                    <Divider style={{ borderColor: token.colorAccent1 }} />
                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <b>Secondary Options:</b>
                        </Col>
                        <Col span={24}>
                          <p style={{ margin: '4px 0' }}>
                            Allow a user to select from a collection of options provided.
                          </p>
                        </Col>
                        <Col span={24}>
                          <Space wrap style={{ marginTop: 12, width: '100%' }}>
                            {question.options?.map((sight, optionIndex) => (
                              <Popconfirm
                                title={'Action required'}
                                description='Please select an option'
                                okText='Edit'
                                cancelText='Delete'
                                onCancel={() => {
                                  deleteCheckinOption(index, optionIndex)
                                }}
                                onConfirm={() => {
                                  editCheckinOption(index, optionIndex)
                                }}
                              >
                                <Button>{sight.text}</Button>
                              </Popconfirm>
                            ))}
                            <Button
                              onClick={() => {
                                addCheckinOption(index)
                              }}
                            >
                              <FontAwesomeIcon color={token.colorSuccess} icon={faPlus} />
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                    <Divider style={{ borderColor: token.colorAccent1 }} />
                    <Col span={24}>
                      <Row gutter={[8, 8]} style={{ alignItems: 'center' }} justify='space-between'>
                        <Col>
                          <b>Other Option:</b>
                          <br />
                          <p style={{ margin: '4px 0' }}>
                            Allow user to provide additional information via text field on selection.
                          </p>
                        </Col>
                        <Col>
                          <Switch
                            checked={question.showOther}
                            checkedChildren='Enabled'
                            unCheckedChildren='Disabled'
                            onChange={(e) => {
                              handleCheckinChange(e, index, 'showOther')
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {question.showOther ? (
                      <>
                        <Divider style={{ borderColor: token.colorAccent1 }} />
                        <Col span={24}>
                          <Row>
                            <Col span={24}>
                              <b>Additional Field Label:</b>
                            </Col>
                            <Col span={24}>
                              <p style={{ margin: '4px 0' }}>
                                Provide an appropriate label prompting the user to fill in the field.
                              </p>
                            </Col>
                          </Row>
                          <TextArea
                            onChange={(e) => {
                              handleCheckinChange(e.target.value, index, 'otherOptionText')
                            }}
                            maxLength={50}
                            style={{ marginTop: 8, width: '100%' }}
                            value={question.otherOptionText}
                          />
                        </Col>
                      </>
                    ) : null}
                  </>
                ) : null}
              </Row>
            </Card>
          ))}
          {checkinQuestions.length === 0 ? (
            <Row justify={'center'}>
              <Empty
                description={
                  <span style={{ color: token.colorWarning }}>
                    No questions set - Default configuration will be used.
                  </span>
                }
              />
            </Row>
          ) : null}
          {checkinQuestions.length > 0 ? (
            <Row style={{ margin: '12px 0' }} justify={'center'}>
              <Space wrap>
                <Button
                  type='primary'
                  onClick={() => {
                    setIsModalOpen(true)
                  }}
                >
                  Add Question
                </Button>
                {/* <Button
                  style={{ background: token.colorAccent1, color: 'white' }}
                  onClick={() => {
                    setIsPreview(true)
                  }}
                >
                  Preview
                </Button> */}
                {!isSaved ? (
                  <Button
                    type='primary'
                    style={{ background: token.colorSuccess }}
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    Save
                  </Button>
                ) : null}
              </Space>
            </Row>
          ) : null}
        </Form>
      </PrimaryCard>
    </>
  )
}

const BasicForm = memo(_BasicForm)

export default BasicForm
