import { message } from 'antd'
import { handleError } from '../../../lib/utils'
import patMedHistReducer from '../utils/reducer'
import { updateFamMedHistory } from '../api/api'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useFamilyHistory = ({ setSaving }) => {
  const dispatch = useDispatch()
  const [selectedConditions, setSelectedConditions] = useState([])
  const { _id: recordId } = useSelector((state) => state.patMedHist.data)
  const { familyHistory } = useSelector((state) => state.patMedHist.data)
  useEffect(() => {
    if (familyHistory && Array.isArray(familyHistory.list)) {
      setSelectedConditions(familyHistory.list)
    } else {
      setSelectedConditions([]) // Fallback to an empty array if undefined
    }
    // eslint-disable-next-line
  }, [])

  const handleOptionClick = (condition) => {
    const isSelected = selectedConditions.includes(condition.value)

    const newSelectedConditions = isSelected
      ? selectedConditions.filter((value) => value !== condition.value)
      : [...selectedConditions, condition.value]

    setSelectedConditions(newSelectedConditions)
  }

  const handleSubmit = async () => {
    try {
      setSaving(true)
      const newRecord = await updateFamMedHistory(recordId, selectedConditions)
      dispatch(patMedHistReducer.actions.updateState(newRecord))
    } catch (error) {
      message.error(handleError(error))
    } finally {
      setSaving(false)
    }
  }

  return { handleOptionClick, handleSubmit, selectedConditions }
}

export default useFamilyHistory
