import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'antd'
import '../style/MobMedicalHistory.css'
import Templates from '../../../../Medical Vault/medical-history/utils/templates'
import MobMedicalHistoryForm from './MobMedicalHistoryForm'
// Import new mobile section components
import MobAllergies from './MobAllergies'
import MobMedicalHistoryLoadingOverlay from './LoadingOverlay'

const MobMedicalHistory = ({ isPatient, loading }) => {
  const [currentSection, setCurrentSection] = useState(null)
  const [fieldValues, setFieldValues] = useState(Templates.dataTemplate)
  const [form] = Form.useForm()
  const patMedicalHistory = useSelector((state) => state.patMedHist.data)

  if (loading) {
    return <MobMedicalHistoryLoadingOverlay text='Busy...' loading={loading} />
  }

  return (
    <>
      {currentSection ? (
        <Form
          form={form}
          className='mobile-medical-history-content'
          initialValues={fieldValues}
          onValuesChange={(_, allValues) => {
            const updatedValues = {
              ...patMedicalHistory,
              ...allValues
            }
            setFieldValues(updatedValues)
          }}
          preserve={true}
        >
          <MobAllergies clientForm={form} loading={loading} setFacet={setCurrentSection} />
        </Form>
      ) : (
        <div>
          <MobMedicalHistoryForm
            data={patMedicalHistory}
            setMedicalHistory={patMedicalHistory}
            fieldValues={fieldValues}
            onSelectSection={patMedicalHistory}
          />
        </div>
      )}
    </>
  )
}

export default MobMedicalHistory
