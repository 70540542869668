import { Button, Col, message, Modal, Row, Select, Tabs, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { handleError } from '../../../lib/utils'
import { addTerm, readTerms, updateTerm } from '../utils/utils'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import { ContainerCard } from '../../../reusable-components/AgiliteCards'
import { useSelector } from 'react-redux'

const TermsConditionsWrapper = () => {
  const [loading, setLoading] = useState(false)
  const [sections, setSections] = useState([])
  const [originalSections, setOriginalSections] = useState([])
  const [activeKey, setActiveKey] = useState(0)
  const clinicState = useSelector((state) => state.clinics.data)
  const { token } = theme.useToken()
  useEffect(() => {
    handleReadTerms('terms_conditions')
  }, [])

  const handleReadTerms = async () => {
    let data = []

    setLoading(true)

    try {
      data = await readTerms()
      setSections(data)
      setOriginalSections(data)
    } catch (e) {
      handleError(e, true)
    }

    setLoading(false)
  }

  const handleSaveTerms = async (index) => {
    let tmpSections = sections.concat()

    tmpSections[index].dateModified = new Date()
    setLoading(true)

    try {
      if (tmpSections[index].new) {
        delete tmpSections[index].new
        delete tmpSections[index].modified

        await addTerm(tmpSections[index])
        message.success('Successfully added ' + tmpSections[index].label)
      } else {
        delete tmpSections[index].modified

        await updateTerm(tmpSections[index]._id, tmpSections[index])

        message.success('Successfully updated ' + tmpSections[index].label)
      }

      setSections(tmpSections)
    } catch (e) {
      handleError(e, true)
    }

    setLoading(false)
  }

  const handleSectionAdd = () => {
    let tmpSections = sections.concat()
    let linkedPractice = ''

    Modal.confirm({
      title: 'Add Section',
      className: token.themeControl,
      cancelButtonProps: {
        danger: true
      },
      content: (
        <Row>
          <Col span={24}>Please link a practice:</Col>
          <Col span={24}>
            <Select
              placeholder='-Select Linked Practice-'
              style={{ width: '100%' }}
              options={clinicState.map((clinic) => {
                return {
                  label: clinic.name,
                  value: clinic._id
                }
              })}
              onChange={(value) => {
                linkedPractice = value
              }}
            />
          </Col>
        </Row>
      ),
      onOk: () => {
        tmpSections.push({
          label: clinicState.find((i) => i._id === linkedPractice).name,
          linkedPractice: linkedPractice,
          data: '',
          modified: true,
          new: true
        })

        setSections(tmpSections)
        setActiveKey(tmpSections.length - 1)
      },
      okText: 'Add'
    })
  }

  const handleSectionChange = (index, data) => {
    let tmpSections = sections.concat()

    tmpSections[index].modified = true
    tmpSections[index].data = data

    setSections(tmpSections)
  }

  const handleUndo = (index) => {
    let tmpSections = sections.concat()

    if (originalSections[index]) {
      tmpSections[index] = JSON.parse(JSON.stringify(originalSections[index]))
    } else {
      tmpSections[index] = { ...tmpSections[index], data: '' }
    }

    setSections(tmpSections)
  }

  const handleTabChange = (key) => {
    setActiveKey(key)
  }

  return (
    <ContainerCard title='Terms & Conditions' loading={loading}>
      <Tabs
        type='editable-card'
        onEdit={(targetKey, action) => {
          if (action === 'add') {
            handleSectionAdd()
          }
        }}
        onChange={handleTabChange}
        activeKey={activeKey}
        items={sections.map((section, index) => {
          return {
            label: section.label,
            key: index,
            closable: false,
            modified: false,
            children: (
              <>
                <ReactQuill
                  value={section.data}
                  onChange={(value) => {
                    if (value !== '<p><br></p>' && section.data !== value) {
                      handleSectionChange(index, value)
                    }
                  }}
                />
                {section.modified ? (
                  <Row justify='center' style={{ marginTop: 20 }} gutter={[12, 12]}>
                    <Col>
                      <Button
                        type='primary'
                        style={{ backgroundColor: token.colorSuccess }}
                        onClick={() => handleSaveTerms(index)}
                      >
                        Update
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={() => handleUndo(index)}>Undo Changes</Button>
                    </Col>
                  </Row>
                ) : undefined}
              </>
            )
          }
        })}
      />
    </ContainerCard>
  )
}

export default TermsConditionsWrapper
