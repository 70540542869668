import { useEffect, useState } from 'react'
import { getPatHealthStats } from '../api/api'
import patHealthStatsReducer from '../utils/reducer'
import { handleError } from '../../../lib/utils'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

const usePatientHealthStats = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const dispatch = useDispatch()
  const [loadingHealthMetrics, setLoadingHealthMetrics] = useState(false)
  const [loadingChronicMedications, setLoadingChronicMedications] = useState(false)
  const patHealthStatsState = useSelector((state) => state.patHealthStats.data)
  const [patHealthMetrics, setPatHealthMetrics] = useState([])

  console.log('patHealthStatsState', patHealthStatsState)
  useEffect(() => {
    if (patHealthStatsState) {
      setPatHealthMetrics(patHealthStatsState)
    }
  }, [patHealthStatsState])

  const getLatestReading = (data, key) => {
    if (!data) return null
    return data.filter((item) => item.key === key).sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))[0]
  }

  const transformHealthData = (data, key) => {
    if (!data) return []

    if (key === 'bloodPressure') {
      return data
        .filter((item) => item.key === 'bloodPressure')
        .map((item) => ({
          time: new Date(item.dateCreated).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
          systolic: item.value.find((v) => v.key === 'systolic')?.value,
          diastolic: item.value.find((v) => v.key === 'diastolic')?.value
        }))
        .sort((a, b) => new Date(a.time) - new Date(b.time))
    }

    // Handle other metrics normally
    return data
      .filter((item) => item.key === key)
      .map((item) => ({
        time: new Date(item.dateCreated).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
        value: item.value
      }))
      .sort((a, b) => new Date(a.time) - new Date(b.time))
  }

  useEffect(() => {
    if (!patHealthStatsState.data) {
      handleGetPatHealthStats()
    }
    // eslint-disable-next-line
  }, [])

  const handleGetPatHealthStats = async () => {
    setLoadingHealthMetrics(true)
    try {
      const response = await getPatHealthStats()
      dispatch(patHealthStatsReducer.actions.setState(response))
    } catch (error) {
      message.error(handleError(error, true))
    } finally {
      setLoadingHealthMetrics(false)
    }
  }

  const handleScroll = (e) => {
    const container = e.target
    const itemWidth = container.children[0].offsetWidth
    const scrollPosition = container.scrollLeft
    const newIndex = Math.round(scrollPosition / itemWidth)
    if (newIndex !== activeIndex) {
      setActiveIndex(newIndex)
    }
  }

  return {
    handleScroll,
    activeIndex,
    loadingHealthMetrics,
    loadingChronicMedications,
    patHealthStatsState,
    handleGetPatHealthStats,
    dispatch,
    setActiveIndex,
    setLoadingHealthMetrics,
    setLoadingChronicMedications,
    patHealthMetrics,
    transformHealthData,
    setPatHealthMetrics,
    getLatestReading
  }
}

export default usePatientHealthStats
