import React, { useState } from 'react'
import { Swiper } from 'antd-mobile'
import '../styling/PromoCarousel.css'

const adImages = {
  dental: require('../../../../../assets/images/AdCarousel/Dental.png'),
  dischem: require('../../../../../assets/images/AdCarousel/Dischem.png'),
  labTests: require('../../../../../assets/images/AdCarousel/Lab Tests.png'),
  medicalPlans: require('../../../../../assets/images/AdCarousel/medical plans.png'),
  virginActive: require('../../../../../assets/images/AdCarousel/Virgin Active.png'),
  virtualConsultations: require('../../../../../assets/images/AdCarousel/Virtual Consultations.png')
}

const promoData = Object.entries(adImages).map(([key, image], index) => ({
  id: index + 1,
  image: image
}))

const PromoCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleSlideChange = (index) => {
    setCurrentIndex(index)
  }

  return (
    <>
      <Swiper autoplay loop autoplayInterval={4000} indicator={() => null} onIndexChange={handleSlideChange}>
        {promoData.map((promo) => (
          <Swiper.Item key={promo.id}>
            <div className='promo-slide'>
              <img src={promo.image} alt={`Advertisement ${promo.id}`} />
            </div>
          </Swiper.Item>
        ))}
      </Swiper>
      <div className='promo-indicators'>
        {promoData.map((_, index) => (
          <div key={index} className={`indicator ${index === currentIndex ? 'active' : ''}`} />
        ))}
      </div>
    </>
  )
}

export default PromoCarousel
