import axios from 'axios'
import { AssistantEnums } from './assistants-enums'
import store from '../../../../../../store'
import { patientData } from './patient-data' // Import patient data

export const assistantReadThread = async (thread_id) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const response = await axios.post(AssistantEnums.routes.READ_THREAD, {
          thread_id
        })

        if (response?.data?.data) {
          // Find the last assistant message
          const assistantMessage = response.data.data.reverse().find((msg) => msg.role === 'assistant')

          if (assistantMessage?.content) {
            // Handle different content formats
            let content = assistantMessage.content

            if (Array.isArray(content)) {
              if (content[0]?.type === 'text') {
                content = content[0].text
              } else if (content[0]?.value) {
                content = content[0].value
              }
            }

            // Try to clean up any markdown formatting
            if (typeof content === 'string') {
              content = content
                .replace(/```json\n?/, '')
                .replace(/\n?```/, '')
                .trim()
            }

            resolve({
              data: {
                content: content
              }
            })
          } else {
            throw new Error('No assistant message found in thread')
          }
        } else {
          throw new Error('Invalid thread response format')
        }
      } catch (e) {
        console.error('Error reading thread:', e)
        reject(e)
      }
    })()
  })
}

export const assistantInitiate = async () => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const userRef = store.getState().auth.agiliteUser._id
        const entityRef = store.getState().core.entity._id

        // Create a more structured prompt
        const prompt = `
          You are a medical AI assistant. Based on the following patient data, recommend ONE diagnostic test that would be most beneficial.
          
          Patient Data:
          - Conditions: ${patientData.medicalHistory.conditions.map((c) => c.condition).join(', ')}
          - Recent Lab Results: HbA1c ${patientData.labResults.recent[0].result} (${
          patientData.labResults.recent[0].status
        })
          - Current Medications: ${patientData.medications.current.map((m) => m.name).join(', ')}
          - Recent Blood Sugar: ${patientData.vitals.recentReadings[0].bloodSugar}
          - Recent Blood Pressure: ${patientData.vitals.recentReadings[0].bloodPressure}
          
          Instructions:
          1. Analyze the patient's conditions and recent results
          2. Recommend ONE most important test to monitor their health
          3. Provide a clear reason for the recommendation
          4. Return ONLY a plain JSON response (no markdown, no code blocks) in this exact format:
          {"test":{"name":"Test Name Here","reason":"Detailed reason for recommendation here"}}

          Important: Do not wrap the JSON in markdown code blocks or any other formatting.
        `

        const response = await axios.post(AssistantEnums.routes.INITIATE_THREAD, {
          entityRef,
          userRef,
          assistant_id: AssistantEnums.assistant_ids.CARESYNC_SUGGEST,
          message: prompt
        })

        if (response.data && response.data.id) {
          resolve({
            thread_id: response.data.thread_id,
            run_id: response.data.id
          })
        } else {
          throw new Error('Invalid response format from AI service')
        }
      } catch (e) {
        console.error('AI initiation error:', e)
        reject(e)
      }
    })()
  })
}

export const assistantCheckRunStatus = async (thread_id, run_id) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      let response = null

      try {
        response = await axios.post(AssistantEnums.routes.CHECK_THREAD_STATUS, {
          thread_id,
          run_id
        })
        if (response?.data) {
          resolve(response.data)
        } else {
          throw new Error('There was an issue contacting your assistant.')
        }
      } catch (e) {
        reject(e)
      }
    })()
  })
}
