import { faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React from 'react'

const DictaphoneControls = ({
  buttonStyle,
  listening = false,
  startListening = () => {},
  stopListening = () => {},
  disabled,
  iconPosition = 'end',
  block
}) => {
  const iconStyle = {
    marginRight: iconPosition === 'start' ? '8px' : 0,
    marginLeft: iconPosition === 'end' ? '8px' : 0,
    width: '16px',
    display: 'inline-flex',
    justifyContent: 'center'
  }

  // Ensure button style has left alignment
  const enhancedButtonStyle = {
    ...buttonStyle,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }

  return (
    <>
      {!listening ? (
        <Button style={enhancedButtonStyle} disabled={listening || disabled} onClick={startListening} block={block}>
          {iconPosition === 'start' && <FontAwesomeIcon icon={faMicrophone} style={iconStyle} />}
          Dictate
          {iconPosition === 'end' && <FontAwesomeIcon icon={faMicrophone} style={iconStyle} />}
        </Button>
      ) : (
        <Button style={enhancedButtonStyle} disabled={!listening || disabled} danger onClick={stopListening} block={block}>
          {iconPosition === 'start' && <FontAwesomeIcon icon={faMicrophoneSlash} style={iconStyle} />}
          Dictate
          {iconPosition === 'end' && <FontAwesomeIcon icon={faMicrophoneSlash} style={iconStyle} />}
        </Button>
      )}
    </>
  )
}

export default DictaphoneControls
